import { Box, Heading } from "grommet";
import { Pagination, ViewContainer } from "../../../../components";
import { IconButton, InputAdornment, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { AccountBalance, AdminPanelSettings, Apartment, CardGiftcard, Delete, Event, LocalShipping, School, Search, Settings, Storefront } from "@mui/icons-material";
import { useInstanceSearch } from "../hooks";
import { useMemo, useState } from "react";
import { InstanceType } from "../../../../graphql/__generated__/graphql";
import { useWindowDimensions } from "../../../../hooks";
import { InstanceLinksDialog } from "../../common";

export const InstancesView: React.FC = () => {
	const { height } = useWindowDimensions();

	const [ search, setSearch ] = useState<{ name: string, domain: string; }>({
		name: "",
		domain: ""
	});

	const { instances } = useInstanceSearch(search);

	const pageSize = useMemo(() => {
		if(height > 760) return 6;
		if(height > 700) return 5;
		if(height > 660) return 4;
		return 3;
	}, [ height ]);

	return (
		<ViewContainer>
			<Box flex>
				<Box direction="row" justify="between">
					<Box justify="center">
						<Heading level="3" margin="none">
							Instances
						</Heading>
					</Box>
					<Box justify="center">
						<TextField
							label="Search"
							variant="outlined"
							value={search.name}
							onChange={e => setSearch({ ...search, name: e.target.value })}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton>
											<Search />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Box>
				</Box>
				<Pagination pageSize={pageSize}>
					{instances.map(instance => (
						<InstanceLinksDialog
							key={instance.id}
							domain={instance.domain}
							activatorButton={(
								<Box>
									<InstancePreviewListItem
										instance={instance}
									/>
								</Box>
							)}
						/>
					))}
				</Pagination>
			</Box>
		</ViewContainer>
	);
};

export const InstancePreviewListItem: React.FC<{
	instance: {
		id: string;
		name: string;
		type: string;
	};
}> = ({ instance }) => {
	const icon = useMemo(() => {
		switch(instance.type) {
			case InstanceType.Event: {
				return <Event />;
			}
			case InstanceType.Municipality: {
				return <AccountBalance />;
			}
			case InstanceType.Onboarding: {
				return <Settings />;
			}
			case InstanceType.Retail: {
				return <Storefront />;
			}
			case InstanceType.University: {
				return <School />;
			}
			case InstanceType.Admin: {
				return <AdminPanelSettings />;
			}
			case InstanceType.Hauler: {
				return <LocalShipping />;
			}
			case InstanceType.DisposalCenter: {
				return <Delete />;
			}
			case InstanceType.DonationCenter: {
				return <CardGiftcard />;
			}
			case InstanceType.Residence: {
				return <Apartment />;
			}
			case InstanceType.ManagementCompany: {
				return <Apartment />;
			}
			default: {
				return <Settings />;
			}
		}
	}, [ instance ]);

	return (
		<ListItemButton key={instance.id} divider style={{ minHeight: "80.8px" }}>
			<ListItemIcon>
				{icon}
			</ListItemIcon>
			<ListItemText
				primary={instance.name}
				secondary={instance.type}
			/>
		</ListItemButton>
	);
};