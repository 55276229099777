import { gql } from "@apollo/client";


export const Order_AddressFragment = gql`
	fragment Order_AddressFragment on Order {
		address {
			...AddressFragment
		}
	}
`;
