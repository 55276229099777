import React, { useMemo } from "react";
import { BasicListItem } from "../../../../../components";
import { useAdminScopedOrder } from "../../../../../graphql";
import { InstanceType } from "../../../../../graphql/__generated__/graphql";

export const OrderRefererListItem: React.FC<{
	orderId: string;
}> = ({ orderId }) => {
	const { referer, order, loading } = useAdminScopedOrder(orderId);

	const value = useMemo(() => {
		if(!order || !referer) return "Unknown";

		const { instance } = order;

		switch(instance?.type) {
			case InstanceType.University:
			case InstanceType.ManagementCompany: {
				if(!referer || !instance) return "Unknown";
				return `${referer.name} (${instance.name})`;
			}
			default: {
				return referer.name || "Unknown";
			}
		}
	}, [ order, referer ]);

	return (
		<BasicListItem
			label={referer.label || "Referer"}
			loading={loading}
			value={value || "Unknown"}
		/>
	);
};