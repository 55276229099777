import React, { useEffect, useMemo } from "react";
import { Box } from "grommet";
import { IconButton, List, Typography } from "@mui/material";
import { Header } from "../Header";
import { Main } from "../Main";
import { useWhitelabel, useWindowDimensions } from "../../hooks";
import { useSidebarShrink } from "./SidebarLayout";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectTheme, setTheme } from "../../store/theme";
import { ChevronLeft, ChevronRight, EmojiEvents, Stars } from "@mui/icons-material";
import { useAvatar } from "../../hooks/useAvatar";
import { defaultThemeState } from "../../provider";
import { useUser } from "../../auth";
import moment from "moment";

interface SidebarProps {
	hideAvatar?: boolean;
	primaryListItems: React.ReactNode[];
	secondaryListItems: React.ReactNode[];
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
	const dispatch = useAppDispatch();
	const shrink = useSidebarShrink();
	const theme = useAppSelector(selectTheme);
	const { user } = useUser();

	const { sizeIndex } = useWindowDimensions();

	const avatarSize = useMemo(() => {
		if(sizeIndex <= 2) {
			return "50px";
		}

		return "75px";
	}, [ sizeIndex ]);
	const avatar = useAvatar(avatarSize, avatarSize);

	if(shrink) {
		return (
			<Box
				flex
				pad="medium"
				width="100%"
				gap="medium"
				direction="row"
				background="#F5F5F5"
				className="footer"
				style={{
					position: "fixed",
					bottom: 0,
					zIndex: 10
				}}
				justify="end"
				id="bottom-navigation"
			>
				{props.primaryListItems}
			</Box>
		);
	}


	if(theme.collapseSidebar) {
		return (
			<Box flex pad="medium" height="100%" background="#F5F5F5" align="center" id="container">
				<List>
					{props.primaryListItems.map((item, index) => (
						React.cloneElement(item as React.ReactElement, { key: index })
					))}
				</List>
				<Box flex justify="end" align="center">
					<List>
						{props.secondaryListItems.map((item, index) => (
							React.cloneElement(item as React.ReactElement, { key: index })
						))}
					</List>
					<Box align={theme.collapseSidebar ? "center" : "end"}>
						<IconButton
							onClick={() => {
								dispatch(setTheme({
									...theme,
									collapseSidebar: !theme.collapseSidebar
								}));
							}}
						>
							{theme.collapseSidebar ? <ChevronRight fontSize="large" /> : <ChevronLeft fontSize="large" />}
						</IconButton>
					</Box>
				</Box>
			</Box>
		);
	}

	return (
		<Box flex pad="small" height="100%" background="#F5F5F5">
			<Box
				gap="small"
			>
				{!props.hideAvatar && (
					<Box direction="row" gap="small">
						<Box justify="center" width={avatarSize} height={avatarSize}>
							{avatar}
						</Box>
						<Box gap="small">
							<Box direction="row" gap="small" >
								<EmojiEvents sx={{ color: "gold" }} fontSize="small" />
								<Typography fontSize="small">
									Member since {moment(user?.createdAt).format("YYYY")}
								</Typography>
							</Box>
							<Box direction="row" gap="small">
								<Stars color="success" fontSize="small" />
								<Typography fontSize="small">
									57 points to next reward
								</Typography>
							</Box>
						</Box>
					</Box>
				)}
			</Box>
			<List >
				{props.primaryListItems.map((item, index) => (
					React.cloneElement(item as React.ReactElement, { key: index })
				))}
			</List>
			<Box flex justify="end">
				<List>
					{props.secondaryListItems.map((item, index) => (
						React.cloneElement(item as React.ReactElement, { key: index })
					))}
				</List>
				<Box align={theme.collapseSidebar ? "center" : "end"}>
					<IconButton
						onClick={() => {
							dispatch(setTheme({
								...theme,
								collapseSidebar: !theme.collapseSidebar
							}));
						}}
					>
						{theme.collapseSidebar ? <ChevronRight fontSize="large" /> : <ChevronLeft fontSize="large" />}
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};


export const LayoutController: React.FC<{
	children: React.ReactNode;
}> = (props) => {
	const { height } = useWindowDimensions();
	const branding = useAppSelector(selectTheme);
	const whitelabel = useWhitelabel();

	const [ headerTheme, setHeaderTheme ] = React.useState(branding);

	useEffect(() => {
		if(whitelabel) {
			setHeaderTheme({
				...branding
			});
		}
		else {
			setHeaderTheme({
				...defaultThemeState
			});
		}
	}, [ branding ]);

	return (
		<Box height={`${height}px`}>
			<Header
				theme={headerTheme}
			/>
			<Main>
				{props.children}
			</Main>
		</Box>
	);
};