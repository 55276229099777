import { gql } from "../__generated__";

export const FindScheduledPickups = gql(/* GraphQL */`
	query FindScheduledPickups {
		FindScheduledPickups {
			id
			scheduledDate
			assignedTo {
				id
				name
			}
			stopCount
			incomingProductCount
			market { id code name }
		}
	}
`);

export const FindInstancePickups = gql(/* GraphQL */`
	query FindPickups(
		$instanceId: String!,
		$after: DateTime
	) {
		FindPickups(
			completed:{ equals: false }
			scheduledDate:{ isAfter: $after}
			links:{
				partner:{
					instance: {
						id:{
							equals: $instanceId
						}
					}
				}
			}) {
			id
			ordersCount
			scheduledDate
			links {
				partner { 
					__typename
					... on Building {
						id
						name
					}
					... on ReuseOutlet {
						id
						name
					}
					...on ReferralPartner {
						id
						name
					}
				}
			}
		}
	}
`);

export const BeginPickup = gql(/* GraphQL */`
	mutation BeginPickup($pickupId: String!) {
		BeginPickup(pickupId: $pickupId) {
			id
		}
	}
`);

export const FindInstanceUpcomingPickups = gql(/* GraphQL */`
	query FindInstanceUpcomingPickups(
		$after: DateTime!
		$instanceId: String!
	) {
		FindPickups(
			cancelled: { equals: false }
			completed: { equals: false }
			scheduledDate: { isAfter: $after }
			links: {
				partner: {
					instance: {
						id: { equals: $instanceId }
					}
				}
			}
		) {
			...Pickup_HeaderFragment
		}
	}
`);