import { gql } from "@apollo/client";

export const Pickup_HeaderFragment = gql`
	fragment Pickup_HeaderFragment on Pickup {
		id
		createdAt
		updatedAt
		scheduled
		scheduledAt
		scheduledDate
		window {
			from
			to
			label
		}
		market {
			id
			code
		}
	}
`;

