import { gql } from "@apollo/client";
import { isReuseOutletFragment } from "./ReuseOutlet_HeaderFragment";
import { ReferralPartner_HeaderFragmentFragment } from "../../../__generated__/graphql";
import { isBuildingFragment } from "./Building_HeaderFragment";

export const ReferralPartner_HeaderFragment = gql`
	fragment ReferralPartner_HeaderFragment on ReferralPartner {
		id
		name
		__typename
	}
`;

export function isReferralPartnerFragment(value: { [ key: string ]: unknown; }): value is ReferralPartner_HeaderFragmentFragment {
	return value.__typename === "ReferralPartner" || isReuseOutletFragment(value) || isBuildingFragment(value);
}