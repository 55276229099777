import { gql } from "@apollo/client";


export const Order_PricingFragment = gql`
	fragment Order_PricingFragment on Order {
		id
		...Order_QuotingFragment
		...Order_EstimateFragment
	}
`;
