import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Shirt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m463.56 147.11c9.2266 90.414 71.188 145.35 136.17 146.12h0.33203c64.945-0.76172 126.88-55.691 136.2-146.04l93.266 29.719c55.055 17.508 79.16 42.012 122.01 81.602l162.71 150.07-116.59 142.65-75.988-69.934v-91.668c1.2656-24.734-36.52-24.734-35.266 0v669.8l-573.05-0.14844 0.007813-669.66c0.19922-9.9883-7.9336-18.133-17.922-17.922-9.7188 0.066406-17.586 8.1211-17.375 17.922v91.668l-75.895 69.801-116.59-142.56 162.62-150.01c42.934-39.586 67.027-64.16 122.09-81.668zm35.055-2.2109c33.613 7.1602 66.465 10.492 101.4 10.293 34.84 0.26562 67.559-3.0547 101.11-10.148-7.7734 75.148-55.961 112.23-101.24 112.85-45.277-0.625-93.48-37.719-101.27-113zm221.68-39.867c-3.2539-0.066406-6.4531 0.83984-9.293 2.5078-36.945 9.4414-71.734 12.641-111.61 12.574-39.852 0-75.266-3.3477-113.27-13.266-2.1484-0.83984-4.375-1.2656-6.6016-1.1875-2.625 0.066406-5.2812 0.69141-7.625 1.8789l-112.23 35.691c-61.602 19.574-92.922 50.133-135.35 89.293l-174.93 161.33c-6.8789 6.332-7.7188 16.945-1.7461 24.172l138.97 169.92c6.3867 7.9336 18.188 8.8125 25.691 1.8789l65.762-60.574v547.64c-0.066406 9.8008 7.9062 17.773 17.707 17.707h608.27c9.7188 0 17.641-7.9062 17.641-17.641v-547.74l65.828 60.625c7.5078 6.9336 19.238 6.0391 25.707-1.8789l138.95-169.92c5.8281-7.2383 5.0664-17.773-1.8008-24.105l-174.85-161.33c-42.508-39.094-73.762-69.801-135.36-89.375l-109.99-34.934c-2.9219-2.0664-6.332-3.1875-9.8672-3.2656z" fill-rule="evenodd"/>
</svg>
			</SvgIcon>
		);
	};