import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, from } from "@apollo/client";
import { AuthService } from "../../auth";
import React, { Fragment, ReactNode, useEffect } from "react";

export function createHttpLink(hostname: string) {
	const url = (window.location.hostname.includes("localhost") || window.location.hostname.includes("192.168"))
		? "/graphql"
		: `${hostname}/graphql`;

	console.debug("Using graphql endpoint: ", url);
	return new HttpLink({
		uri: url
	});
}

export function createAuthLink() {
	return new ApolloLink((operation, forward) => {
		const accessToken = AuthService.getAccessToken();

		operation.setContext(({ headers }: { headers: { [ key: string ]: string; }; }) => ({
			headers: {
				...headers,
				"X-Rego-GraphQL-Operation": operation.operationName,
				authorization: (accessToken) ? `Bearer ${AuthService.getAccessToken()}` : undefined
			}
		}));

		return forward(operation);
	});
}

function resolveAPIHost() {
	if(window.location.hostname.includes("localhost")) {
		return "local";
	}

	if(window.location.hostname.includes("uat-") || window.location.hostname.includes("uat.")) {
		return "https://uat-core-api.liverego.com";
	}

	if(window.location.hostname.includes("relay")) {
		return "https://relay-api.liverego.com";
	}

	return "https://core-api.liverego.com";
}

export const ApolloSetupProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const [ client, setClient ] = React.useState<ApolloClient<any> | null>(null);

	useEffect(() => {
		const httpLink = createHttpLink(resolveAPIHost());
		const authLink = createAuthLink();

		const client = new ApolloClient({
			link: from([ authLink, httpLink ]),
			cache: new InMemoryCache()
		});

		setClient(client);
	}, []);

	return (
		<Fragment>
			{client && (
				<ApolloProvider client={client}>
					{children}
				</ApolloProvider>
			)}
		</Fragment>
	);
};

import { __DEV__ } from "@apollo/client/utilities/globals";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

if(__DEV__) {
	console.debug("Loading dev messages");
	loadDevMessages();
	loadErrorMessages();
}