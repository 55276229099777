import { useMemo } from "react";
import { Pickup_HeaderFragmentFragment } from "../../graphql/__generated__/graphql";


export function useScheduledWindow(pickup?: Pickup_HeaderFragmentFragment | null) {
	return useMemo(() => {
		if(!pickup?.window) {
			return "Not Scheduled";
		}

		return pickup.window.label;
	}, [ pickup ]);
}
