import { useEffect } from "react";
import { UpdateAddressDTO } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCheckoutState, setAddress } from "../../../store/checkout";
import { useResident, useUser } from "../../../auth";

export function useCheckoutSessionAddress() {
	const dispatch = useAppDispatch();
	const { building } = useResident();
	const { address } = useAppSelector(selectCheckoutState);
	const { addresses } = useUser();

	function handleUpdate(dto: UpdateAddressDTO) {
		dispatch(setAddress({
			...dto,
			addressLineTwo: dto.addressLineTwo || "",
		}));
	}

	useEffect(() => {
		if(!address && building && addresses.length > 0) {
			const matched = addresses.find(a => a.addressLineOne === building.address.addressLineOne);
			if(matched) {
				dispatch(setAddress({
					...matched,
					addressLineTwo: matched.addressLineTwo || "",
				}));
			}
		}
	}, [ address, building ]);

	useEffect(() => {
		if(!address && building && addresses.length === 0) {
			dispatch(setAddress({
				...building.address,
				addressLineTwo: ""
			}));
		}
	}, [ address, addresses, building ]);

	return {
		address,
		update: handleUpdate
	};
}