import { useQuery } from "@apollo/client";
import { useAppSelector } from "../../../store";
import { selectCheckoutState } from "../../../store/checkout";
import { FetchCheckoutEstimatePreview } from "../../../graphql/documents/checkout/queries";
import { useInstance } from "../../../hooks";
import { useMemo } from "react";
import { useUser } from "../../../auth";

export function useCheckoutSessionEstimate() {
	const { user } = useUser();
	const { instance } = useInstance();
	const state = useAppSelector(selectCheckoutState);

	const variables = useMemo(() => {
		return {
			pickupId: state.pickup?.id,
			scheduledDate: state.scheduledDate,
			refererId: state.referer?.id || "",
			instanceId: instance?.id || "",
			products: state.products.map(product => ({
				typeId: product.type?.id || "",
				sizeId: product.size?.id || "",
				quantity: product.quantity,
				quantityDisassembly: product.quantityDisassembly
			}))
		};
	}, [ state, instance ]);

	const { data, loading } = useQuery(FetchCheckoutEstimatePreview, {
		skip: !state.referer || !instance || state.products.length === 0 || !user,
		variables: variables
	});

	return {
		loading,
		estimate: data?.FetchCheckoutEstimatePreview
	};
}