import { gql } from "../../../__generated__";

export const FetchAdminProduct = gql(/* GraphQL */ `
	query FetchAdminProduct(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			id
			...ProductFragment
			...Product_InventoryFragment
			...Product_ClassificationFragment
		}
	}
`);