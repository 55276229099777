import React, { useMemo } from "react";
import { useAppDispatch } from "../../../../../store";
import moment from "moment";
import { push } from "redux-first-history";
import { useCompletedOrders } from "../../../hooks";
import { useInstance } from "../../../../../hooks";
import { OrdersDataTable } from "./OrdersDataTable";
import { defaultColumns } from "./columns";
import { TabListItem } from "../../../../../components";
import { GridColDef } from "@mui/x-data-grid";

export const CompletedOrdersDataTable: React.FC<{
	columns?: GridColDef[];
}> = ({ columns }) => {
	const dispatch = useAppDispatch();
	const { instance } = useInstance();

	const completedAtCutOff = useMemo(() => {
		return moment().subtract(14, "days").toDate();
	}, []);

	const { orders, loading } = useCompletedOrders(
		instance?.id ?? "",
		completedAtCutOff
	);

	return (
		<OrdersDataTable
			loading={loading}
			columns={columns || defaultColumns}
			orders={orders}
			onRowSelect={(order) => {
				dispatch(push(`/admin/orders/${order.id}`));
			}} />
	);
};

export const CompletedOrdersTabFactory = (columns?: GridColDef[]): TabListItem => ({
	label: "Completed",
	value: "completed",
	component: (
		<CompletedOrdersDataTable columns={columns} />
	)
});