import React from "react";
import { SnackbarProvider } from "notistack";

export const AlertProvider: React.FC<{ children: React.ReactNode; }> = (props) => {
	return (
		<SnackbarProvider
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
			autoHideDuration={2500}
		>
			{props.children}
		</SnackbarProvider>
	);
};