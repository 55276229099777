import React from "react";
import { useWindowDimensions } from "../hooks";
import { DesktopOnlyView } from "../views";

export const DesktopOnlyGuard: React.FC<{
	children: React.ReactNode;
	additionalContent?: React.ReactNode;
}> = ({ children, additionalContent }) => {
	const { size } = useWindowDimensions();

	if(size === "small") {
		return (
			<DesktopOnlyView>
				{additionalContent}
			</DesktopOnlyView>
		);
	}

	return (
		<>
			{children}
		</>
	);
};
