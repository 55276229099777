import { gql } from "../../../__generated__";

export const CheckOnboardingStatus = gql(/* GraphQL */ `
	query CheckOnboardingStatus($onboardingId: String!) {
		CheckOnboardingStatus(onboardingId: $onboardingId) {
			started
			startedAt
			completed
			completedAt
			failed
			failedAt
			failedReason
		}
	}
`);