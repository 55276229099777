import { Alert, Button, Chip, Typography } from "@mui/material";
import { Box } from "grommet";
import React, { ReactNode, useCallback } from "react";
import { push } from "redux-first-history";
import { useWindowDimensions } from "../../../../hooks";
import { useAppDispatch } from "../../../../store";
import { DashboardActionCard } from "./DashboardActionCard";
import { useWastwAuditBeta, WasteAuditBetaDialog } from "./audit";

export const DashboardActionsContainer: React.FC = () => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	const container = useCallback((children: ReactNode | ReactNode[]) => {
		if(size === "xlarge") {
			return (
				<Box
					flex
					gap="small"
					height={"100%"}
					pad={{ left: "medium" }}
					overflow={{ vertical: "scroll" }}
					border={{ side: "left", color: "light-4" }}
				>
					{children}
				</Box>
			);
		}

		return (
			<Box
				width="100%"
				gap="small"
				direction="row"
				overflow={{ horizontal: "scroll" }}
				border={{ side: "top", color: "light-4" }}
				pad={{ top: "medium" }}
				style={{ minHeight: "220px" }}
			>
				{children}
			</Box>
		);
	}, [ size ]);

	const { isActive } = useWastwAuditBeta();

	return container([
		<DashboardActionCard
			key="waste-receipts"
			title="Waste Receipts"
			buttonTitle="Upload Receipts"
			description="Have waste receipts or tickets to upload? Upload them here to track your waste."
			onButtonClick={() => {
				dispatch(push("/admin/waste-reporting/upload"));
			}}
		/>,
		<DashboardActionCard
			key="waste-audits"
			title="Waste Audits"
			buttonTitle="Learn More"
			description="Looking to complete a waste audit? Our AI powered platform can help you get started."
			onButtonClick={() => {
				dispatch(push("/admin/audits"));
			}}
			button={isActive
				? (
					<Box width="100%">
						<Alert severity="success">
							You're on the list!
						</Alert>
					</Box>
				)
				: (
					<WasteAuditBetaDialog
						activatorButton={(
							<Button
								fullWidth
								size="small"
								color="primary"
								variant="contained"
							>
								Sign Up
							</Button>
						)}
					/>
				)
			}
			chip={
				<Chip
					label={(
						<Typography fontWeight="bold" variant="body2" margin="small">
							Beta
						</Typography>
					)}
					color="success"
				/>}
		/>,
		<DashboardActionCard
			key="certifications"
			title="Certifications"
			buttonTitle="Learn More"
			description="Looking to apply for a certification like TRUE or Blue Verified? Click below to learn more."
			onButtonClick={() => {
				window.open("https://www.liverego.com/certifications", "_blank");
			}}
			chip={(
				<Chip
					label={(
						<Typography fontWeight="bold" variant="body2" margin="small">
							Soon!
						</Typography>
					)}
					color="warning"
				/>
			)}
		/>
	]);
};