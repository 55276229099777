import { Box } from "grommet";
import React from "react";
import { AnswerType, Question, QuestionType } from "../../types";
import { Button, ButtonGroup } from "@mui/material";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";


export const QuestionResponseElement: React.FC<{
	question: Question<QuestionType>;
	value: string | number | boolean;
	onChange: (value: string | number | boolean) => void;
}> = ({ question, value, onChange }) => {

	if(question.type === QuestionType.NUMBER) {
		const response = question.response as AnswerType<QuestionType.NUMBER>;
		return (
			<Box>
				<TextFieldElement
					name={question.id}
					type="number"
					inputMode="numeric"
					required={response.required}
					validation={{
						required: question.response.required
							? "This field is required"
							: undefined,
						min: response.min,
						max: response.max
					}}
					InputProps={{
						value,
						onChange: (event) => {
							const value = event.target.value;
							onChange(value);
						}
					}} />
			</Box>
		);
	}

	if(question.type === QuestionType.CHOICE) {
		const response = question.response as AnswerType<QuestionType.CHOICE>;
		const options = response.options;

		if(options.length <= 5) {
			return (
				<Box>
					<ButtonGroup>
						{options.map((option) => (
							<Button
								fullWidth
								key={String(option.value)}
								variant={option.value === value
									? "contained"
									: "outlined"}
								onClick={() => {
									onChange(option.value);
								}}
							>
								{option.label}
							</Button>

						))}
					</ButtonGroup>
				</Box>
			);
		}
		else {
			return (
				<Box>
					<SelectElement
						name={question.id}
						options={options.map((option) => ({
							label: option.label,
							id: option.value
						}))}
						InputProps={{
							onChange: (event) => {
								onChange(event.target.value);
							}
						}} />
				</Box>
			);
		}
	}

	if(question.type === QuestionType.TEXT) {
		const response = question.response as AnswerType<QuestionType.TEXT>;
		return (
			<Box>
				<TextFieldElement
					name={question.id}
					type="text"
					required={response.required}
					validation={{
						required: question.response.required
							? "This field is required"
							: undefined,
						pattern: response.regex
					}}
					InputProps={{
						onChange: (event) => {
							const value = event.target.value;
							onChange(value);
						}
					}} />
			</Box>
		);

	}

	return null;
};
