import { gql } from "../../../__generated__";

export const CancelOrder = gql(/* GraphQL */ `
	mutation CancelOrder(
		$orderId: String!
		$reason: String!
	) {
		CancelOrder(
			orderId: $orderId
			reason: $reason
		) {
			id
		}
	}
`);