import { useEffect, useState } from "react";
import { AuthService } from "../auth";
import { useLazyQuery } from "@apollo/client";
import { FetchSelf } from "../../graphql";
import { useAppDispatch } from "../../store";
import { useRedirect } from "../../hooks/useRedirect";
import { useUser } from "./useUser";
import { useBeginLogin } from "./useBeginLogin";
import { useConfirmLogin } from "./useConfirmLogin";


export function useLogin() {
	const { redirect } = useRedirect();
	const dispatch = useAppDispatch();
	const { user } = useUser();
	const [ execute, { loading, data } ] = useLazyQuery(FetchSelf);
	const { beginLogin, isSendingCode, wasCodeSent, canSendCode, beginLoginError } = useBeginLogin();
	const { confirmLogin, isConfirming, wasSuccessful, accessToken, refreshToken, confirmLoginError } = useConfirmLogin();

	const [ state, setState ] = useState({
		username: "",
		instanceId: "",
		errorMessage: ""
	});

	useEffect(() => {
		if(accessToken) {
			AuthService.setAccessToken(accessToken);
		}
	}, [ accessToken ]);

	useEffect(() => {
		if(refreshToken) {
			AuthService.setRefreshToken(refreshToken);
		}
	}, [ refreshToken ]);

	function handleBeginLogin(username: string, instanceId: string) {
		console.debug("in handle begin login");
		beginLogin(username, instanceId);
		setState((state) => ({ ...state, username, instanceId }));
	}

	function handleConfirmLogin(
		code: string,
		callback: () => void
	) {
		console.debug("in handle confirm login");
		confirmLogin(
			code,
			state.username,
			state.instanceId,
			callback
		);
	}

	function handleResendCode() {
		console.debug("in handle resend code");
		beginLogin(state.username, state.instanceId);
	}

	useEffect(() => {
		execute().catch(err => {
			console.error("Failed to fetch user", err);
		});
	}, [ wasSuccessful, execute, dispatch ]);

	useEffect(() => {
		if(data?.FetchSelf) {
			AuthService.setUserId(data.FetchSelf.id);
		}
	}, [ data ]);

	return {
		user,
		wasCodeSent,
		canSendCode,
		wasSuccessful,
		username: state.username,
		redirect: redirect,
		instanceId: state.instanceId,
		beginLogin: handleBeginLogin,
		resendCode: handleResendCode,
		confirmLogin: handleConfirmLogin,
		loading: loading || isConfirming || isSendingCode,
		errorMessage: state.errorMessage || confirmLoginError || beginLoginError,
	};
}
