import { Avatar, Chip, IconButton, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { useWindowDimensions } from "../../../../hooks";
import { ContactType } from "../../../../graphql/__generated__/graphql";
import { Cancel, CheckCircle, Close, Email, PhoneIphone } from "@mui/icons-material";
import { formatPhoneNumber } from "../../../../helpers";
import { Box, Spinner } from "grommet";
import { useMemo } from "react";

interface ContactListItemProps {
	value: string;
	type: ContactType;
	isPrimary?: boolean;
	isVerified: boolean;
	isSelected?: boolean;
	isSelecting?: boolean;
	onSelect(): void;
	prohibitDelete?: true;
}

export const ContactListItem: React.FC<ContactListItemProps> = (props) => {
	const { size } = useWindowDimensions();

	const loading = useMemo(() => {
		return props.isSelecting;
	}, [ props.isSelecting ]);

	return (
		<List disablePadding>
			<ListItemButton divider sx={{ minHeight: "59px" }} selected={props.isSelected} onClick={props.onSelect}>
				<ListItemIcon>
					{props.type === ContactType.Email
						? (
							<Avatar>
								<Email />
							</Avatar>
						)
						: (
							<Avatar>
								<PhoneIphone />
							</Avatar>
						)}
				</ListItemIcon>
				<ListItemText
					primary={props.type === ContactType.Email
						? props.value
						: formatPhoneNumber(props.value)
					}
				/>
				{size !== "small" && (
					<ListItemSecondaryAction>
						<Box direction="row" justify="center" gap="small">
							<Box align="center" justify="center" flex>
								<Chip
									color={props.isVerified ? "success" : "error"}
									label={props.isVerified ? "Verified" : "Not Verified"}
									icon={props.isVerified ? <CheckCircle /> : <Cancel />}
								/>
							</Box>
							{(loading)
								? (
									<Spinner />
								)
								: (
									<IconButton color="error" disabled={props.prohibitDelete}>
										<Close />
									</IconButton>
								)}
						</Box>
					</ListItemSecondaryAction>
				)}
			</ListItemButton>
		</List>
	);
};