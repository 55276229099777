import { gql } from "@apollo/client";

export const WasteContainerAssignment_HeaderFragment = gql`
	fragment WasteContainerAssignment_HeaderFragment on WasteContainerAssignment {
		id
		name
		location
		volume
		volumeUnit
		disposition
	}
`;

