import { SvgIcon, SvgIconProps } from "@mui/material";

	export const CoffeeTable: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m200 450h125v362.5c0 34.516-27.984 62.5-62.5 62.5-34.52 0-62.5-27.984-62.5-62.5zm50 50v312.5c0 6.9023 5.5977 12.5 12.5 12.5s12.5-5.5977 12.5-12.5v-312.5z" fill-rule="evenodd"/>
  <path d="m125 475c0-13.809 11.191-25 25-25h900c13.809 0 25 11.191 25 25s-11.191 25-25 25h-900c-13.809 0-25-11.191-25-25z" fill-rule="evenodd"/>
  <path d="m575 600h-250v-50h550c13.809 0 25 11.191 25 25s-11.191 25-25 25h-250v175h-50z" fill-rule="evenodd"/>
  <path d="m875 800h-550v-50h550c13.809 0 25 11.191 25 25s-11.191 25-25 25z" fill-rule="evenodd"/>
  <path d="m875 450h125v362.5c0 34.516-27.984 62.5-62.5 62.5s-62.5-27.984-62.5-62.5zm50 50v312.5c0 6.9023 5.5977 12.5 12.5 12.5s12.5-5.5977 12.5-12.5v-312.5z" fill-rule="evenodd"/>
  <path d="m775 675c0 13.809-11.191 25-25 25s-25-11.191-25-25 11.191-25 25-25 25 11.191 25 25z"/>
  <path d="m475 675c0 13.809-11.191 25-25 25s-25-11.191-25-25 11.191-25 25-25 25 11.191 25 25z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};