import { Box } from "grommet";
import { useUser } from "../../../../auth";
import { useInstance, usePageTitle } from "../../../../hooks";
import { ViewContainer } from "../../../../components";
import { ActiveOrderSummaryList, CompletedOrderSummaryList } from "../../components";
import { TabController } from "../../../../components/Tab";

export const OrdersView: React.FC = () => {
	usePageTitle("Dashboard");
	const { user } = useUser();
	const { instance } = useInstance();

	return (
		<ViewContainer>
			<Box gap="small" flex>
				<TabController
					defaultValue="active"
					tabs={[
						{
							value: "active",
							label: "Active",
							component: <ActiveOrderSummaryList
								userId={user?.id || ""}
								instanceId={instance?.id || ""}
							/>
						},
						{
							value: "completed",
							label: "Completed",
							component: <CompletedOrderSummaryList
								userId={user?.id || ""}
								instanceId={instance?.id || ""}
							/>
						}
					]}
				/>
			</Box>
		</ViewContainer>
	);
};