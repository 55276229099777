import React, { Fragment } from "react";
import { SidebarRouteItem, OrdersListItem, PurchasesListItem } from "../../../components";
import { isNotNull } from "../../../helpers";
import { OrderDetailsView, OrdersView, PurchasesView, UniversityDashboard } from "../views";
import { DashboardListItemWithTasks } from "../components";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { useFeature } from "../../instance/hooks/useFeature";
import { useDashboardRouter } from "../hooks/useDashboardRouter";

export const UniversityRouter: React.FC = () => {
	return (
		<Fragment>
			{useUniversityRouter().toRouter()}
		</Fragment>
	);
};

export function useUniversityRouter() {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);

	return useDashboardRouter(
		[
			new SidebarRouteItem(
				"/",
				<UniversityDashboard />,
				<DashboardListItemWithTasks
					key="dashboard"
				/>,
				true
			),
			new SidebarRouteItem(
				"/orders",
				<OrdersView />,
				<OrdersListItem
					key="orders"
					absolutePath="/dashboard/orders"
				/>,
				true
			),
			isSalesEnabled ? new SidebarRouteItem(
				"/purchases",
				<PurchasesView />,
				<PurchasesListItem
					key="purchases"
					absolutePath="/dashboard/purchases"
				/>,
				true
			) : null
		].filter(isNotNull),
		"University",
		[],
		[ {
			path: "/orders/:orderId",
			element: <OrderDetailsView />
		} ],
		true
	);
}