import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { Instance_AuthenticatedFragmentFragment, Instance_HeaderFragmentFragment, Instance_PartnersPublicFragmentFragment } from "../graphql/__generated__/graphql";

export interface ApplicationState {
	isReady: boolean;
	isLoading: boolean;
	isDarkMode: boolean;
	instanceId: string;
	isPartyMode: boolean;
	offerPartyMode: boolean;
	selectedHaulerId: string;
	wasLoginAttempted: boolean;
	forceEnableWhitelabel: boolean;
	instance: Instance_AuthenticatedFragmentFragment | null;
	instancePublic: (Instance_HeaderFragmentFragment & Instance_PartnersPublicFragmentFragment) | null;
}

const initialState: ApplicationState = {
	instance: null,
	isReady: false,
	isLoading: false,
	isDarkMode: false,
	isPartyMode: false,
	offerPartyMode: false,
	instancePublic: null,
	selectedHaulerId: "",
	wasLoginAttempted: false,
	forceEnableWhitelabel: false,
	instanceId: localStorage.getItem("instanceId") || "",
};

export const applicationSlice = createSlice({
	name: "application",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setIsReady: (state, action: PayloadAction<boolean>) => {
			state.isReady = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setIsDarkMode: (state, action: PayloadAction<boolean>) => {
			state.isDarkMode = action.payload;
		},
		setInstanceId: (state, action: PayloadAction<string>) => {
			state.instanceId = action.payload;
			localStorage.setItem("instanceId", action.payload);
		},
		setIsPartyMode: (state, action: PayloadAction<boolean>) => {
			state.isPartyMode = action.payload;
		},
		setOfferPartyMode(state, action: PayloadAction<boolean>) {
			state.offerPartyMode = action.payload;
		},
		setInstance(state, action: PayloadAction<Instance_AuthenticatedFragmentFragment>) {
			state.instance = action.payload;
		},
		setInstancePublic(state, action: PayloadAction<(Instance_HeaderFragmentFragment & Instance_PartnersPublicFragmentFragment)>) {
			state.instancePublic = action.payload;
		},
		setWasLoginAttempted(state, action: PayloadAction<boolean>) {
			state.wasLoginAttempted = action.payload;
		},
		setForceEnableWhitelabel(state, action: PayloadAction<boolean>) {
			state.forceEnableWhitelabel = action.payload;
		},
		setSelectedHaulerId(state, action: PayloadAction<string>) {
			state.selectedHaulerId = action.payload;
		}
	}
});

export const {
	setIsReady,
	setIsLoading,
	setIsDarkMode,
	setInstance,
	setIsPartyMode,
	setOfferPartyMode,
	setInstancePublic,
	setInstanceId,
	setWasLoginAttempted,
	setSelectedHaulerId,
	setForceEnableWhitelabel
} = applicationSlice.actions;

export const selectIsPartyMode = (state: RootState): boolean => state.application.isPartyMode;
export const selectOfferPartyMode = (state: RootState): boolean => state.application.offerPartyMode;
export const selectWasLoginAttempted = (state: RootState): boolean => state.application.wasLoginAttempted;
export const selectInstance = (state: RootState): Instance_AuthenticatedFragmentFragment | null => state.application.instance;
export const selectInstancePublic = (state: RootState) => state.application.instancePublic;
export const selectIsReady = (state: RootState): boolean => state.application.isReady;
export const selectIsLoading = (state: RootState): boolean => state.application.isLoading;
export const selectIsDarkMode = (state: RootState): boolean => state.application.isDarkMode;
export const selectInstanceId = (state: RootState): string => state.application.instanceId;
export const selectForceEnableWhitelabel = (state: RootState): boolean => state.application.forceEnableWhitelabel;
export const selectSelectedHaulerId = (state: RootState): string => state.application.selectedHaulerId;

export default applicationSlice.reducer;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
///@ts-ignore
window.updateInstance = function () {
	const instanceId = prompt("Instance ID:");
	if(instanceId) {
		setInstanceId(instanceId);
		localStorage.setItem("instanceId", instanceId);
	}
};