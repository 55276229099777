import { SvgIcon, SvgIconProps } from "@mui/material";

	export const WineRack: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1000.3 365.2h-120.7c-6.0078 0.035156-11.07-4.4805-11.719-10.453-0.28125-2.5781-6.5156-63.773 23.18-96.844 12.328-13.758 28.758-20.719 48.82-20.719s36.492 7.0312 48.82 20.719c29.719 33.07 23.438 94.266 23.18 96.844-0.64453 5.9219-5.625 10.418-11.578 10.453zm-109.66-23.438h98.461c0.35156-16.805-1.3359-49.875-17.883-68.25-7.8047-8.6719-18.07-12.891-31.359-12.891-13.289 0-23.414 4.2188-31.289 12.914-15.562 17.273-18.305 49.172-17.906 68.227z"/>
  <path d="m939.63 259.24c-6.4727 0-11.719-5.2461-11.719-11.719v-62.367c0-6.4727 5.2461-11.719 11.719-11.719 6.4727 0 11.719 5.2461 11.719 11.719v62.367c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336z"/>
  <path d="m772.92 365.2h-120.7c-6.0078 0.035156-11.07-4.4805-11.719-10.453-0.28125-2.5781-6.5156-63.773 23.18-96.844 12.328-13.758 28.758-20.719 48.82-20.719s36.492 7.0312 48.82 20.719c29.719 33.07 23.438 94.266 23.18 96.844-0.64453 5.9219-5.625 10.418-11.578 10.453zm-109.66-23.438h98.461c0.35156-16.805-1.3359-49.875-17.883-68.25-7.8047-8.6719-18.07-12.891-31.359-12.891-13.289 0-23.414 4.2188-31.289 12.914-15.562 17.273-18.305 49.172-17.906 68.227z"/>
  <path d="m712.29 259.24c-6.4727 0-11.719-5.2461-11.719-11.719v-62.367c0-6.4727 5.2461-11.719 11.719-11.719 6.4727 0 11.719 5.2461 11.719 11.719v62.367c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336z"/>
  <path d="m547.92 365.2h-120.7c-6.0078 0.035156-11.07-4.4805-11.719-10.453-0.28125-2.5781-6.5156-63.773 23.18-96.844 12.328-13.758 28.758-20.719 48.82-20.719s36.492 7.0312 48.82 20.719c29.719 33.07 23.438 94.266 23.18 96.844-0.64453 5.9219-5.625 10.418-11.578 10.453zm-109.66-23.438h98.438c0.35156-16.805-1.3359-49.875-17.883-68.25-7.8047-8.6719-18.07-12.891-31.359-12.891s-23.391 4.2188-31.266 12.914c-15.562 17.273-18.305 49.172-17.906 68.227z"/>
  <path d="m487.29 259.24c-6.4727 0-11.719-5.2461-11.719-11.719v-62.367c0-6.4727 5.2461-11.719 11.719-11.719 6.4727 0 11.719 5.2461 11.719 11.719v62.367c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336z"/>
  <path d="m325.27 365.2h-120.7c-6.0078 0.035156-11.07-4.4805-11.719-10.453-0.28125-2.5781-6.5156-63.773 23.18-96.844 12.328-13.758 28.758-20.719 48.82-20.719s36.492 7.0312 48.82 20.719c29.719 33.07 23.438 94.266 23.18 96.844-0.64453 5.9219-5.625 10.418-11.578 10.453zm-109.64-23.438h98.438c0.35156-16.805-1.3359-49.875-17.883-68.25-7.8047-8.6719-18.07-12.891-31.359-12.891-13.289 0-23.414 4.2188-31.289 12.914-15.562 17.273-18.305 49.172-17.906 68.227z"/>
  <path d="m264.63 259.24c-6.4727 0-11.719-5.2461-11.719-11.719v-62.367c0-6.4727 5.2461-11.719 11.719-11.719 6.4727 0 11.719 5.2461 11.719 11.719v62.367c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336z"/>
  <path d="m1052.3 196.88h-902.34c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h902.34c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m1052.3 1096.9h-902.34c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h902.34c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m1052.3 1171.9h-902.34c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h902.34c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m1139.1 121.88h-1078.1c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859v-28.125c0-9.3242 3.7031-18.266 10.297-24.859s15.535-10.297 24.859-10.297h1078.1c9.3242 0 18.266 3.7031 24.859 10.297s10.297 15.535 10.297 24.859v28.125c0 9.3242-3.7031 18.266-10.297 24.859s-15.535 10.297-24.859 10.297zm-1078.1-75c-6.4727 0-11.719 5.2461-11.719 11.719v28.125c0 3.1094 1.2344 6.0898 3.4336 8.2852 2.1953 2.1992 5.1758 3.4336 8.2852 3.4336h1078.1c3.1094 0 6.0898-1.2344 8.2852-3.4336 2.1992-2.1953 3.4336-5.1758 3.4336-8.2852v-28.125c0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m150 1171.9h-75c-6.4727 0-11.719-5.2461-11.719-11.719v-1050c0-6.4727 5.2461-11.719 11.719-11.719h75c3.1094 0 6.0898 1.2344 8.2852 3.4336 2.1992 2.1953 3.4336 5.1758 3.4336 8.2852v1050c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336zm-63.281-23.438h51.562v-1026.6h-51.562z"/>
  <path d="m1127.3 1171.9h-75c-6.4727 0-11.719-5.2461-11.719-11.719v-1050c0-6.4727 5.2461-11.719 11.719-11.719h75c3.1094 0 6.0898 1.2344 8.2852 3.4336 2.1992 2.1953 3.4336 5.1758 3.4336 8.2852v1050c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336zm-63.281-23.438h51.562v-1026.6h-51.562z"/>
  <path d="m601.17 159.38h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m264.84 159.38h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m433.01 159.38h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m769.34 159.38h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m937.5 159.38h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m601.17 1134.4h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m264.84 1134.4h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m433.01 1134.4h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m769.34 1134.4h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m937.5 1134.4h-2.3438c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h2.3438c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m487.5 1094.5c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m262.5 1094.5c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m937.5 1094.5c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m712.5 1094.5c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m375 1096.9c-6.4727 0-11.719-5.2461-11.719-11.719v-672.66c0-6.4727 5.2461-11.719 11.719-11.719s11.719 5.2461 11.719 11.719v672.66c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336z"/>
  <path d="m600 1096.9c-6.4727 0-11.719-5.2461-11.719-11.719v-672.66c0-6.4727 5.2461-11.719 11.719-11.719s11.719 5.2461 11.719 11.719v672.66c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336z"/>
  <path d="m827.34 1096.9c-6.4727 0-11.719-5.2461-11.719-11.719v-672.66c0-6.4727 5.2461-11.719 11.719-11.719s11.719 5.2461 11.719 11.719v672.66c0 3.1094-1.2344 6.0898-3.4336 8.2852-2.1953 2.1992-5.1758 3.4336-8.2852 3.4336z"/>
  <path d="m1052.3 424.22h-900c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h900c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m1052.3 874.22h-900c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h900c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m487.5 867.19c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m262.5 867.19c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m937.5 867.19c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m712.5 867.19c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m1052.3 646.88h-900c-6.4727 0-11.719-5.2461-11.719-11.719s5.2461-11.719 11.719-11.719h900c6.4727 0 11.719 5.2461 11.719 11.719s-5.2461 11.719-11.719 11.719z"/>
  <path d="m487.5 644.53c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m262.5 644.53c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m262.5 595.31c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m487.5 595.31c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m712.5 595.31c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m937.5 644.53c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m712.5 644.53c-22.379 0-43.84-8.8906-59.664-24.711-15.82-15.824-24.711-37.285-24.711-59.664s8.8906-43.84 24.711-59.664c15.824-15.82 37.285-24.711 59.664-24.711s43.84 8.8906 59.664 24.711c15.82 15.824 24.711 37.285 24.711 59.664s-8.8906 43.84-24.711 59.664c-15.824 15.82-37.285 24.711-59.664 24.711zm0-145.31c-16.16 0-31.66 6.4219-43.09 17.848-11.426 11.43-17.848 26.93-17.848 43.09s6.4219 31.66 17.848 43.09c11.43 11.426 26.93 17.848 43.09 17.848s31.66-6.4219 43.09-17.848c11.426-11.43 17.848-26.93 17.848-43.09s-6.4219-31.66-17.848-43.09c-11.43-11.426-26.93-17.848-43.09-17.848z"/>
  <path d="m937.5 595.31c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m262.5 817.97c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m487.5 817.97c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m712.5 817.97c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m937.5 817.97c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m262.5 1045.3c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m487.5 1045.3c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m712.5 1045.3c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
  <path d="m937.5 1045.3c-9.3242 0-18.266-3.7031-24.859-10.297s-10.297-15.535-10.297-24.859 3.7031-18.266 10.297-24.859 15.535-10.297 24.859-10.297 18.266 3.7031 24.859 10.297 10.297 15.535 10.297 24.859-3.7031 18.266-10.297 24.859-15.535 10.297-24.859 10.297zm0-46.875c-4.7383 0-9.0117 2.8555-10.828 7.2344-1.8125 4.3789-0.80859 9.418 2.543 12.77 3.3516 3.3516 8.3906 4.3555 12.77 2.543 4.3789-1.8164 7.2344-6.0898 7.2344-10.828 0-3.1094-1.2344-6.0898-3.4336-8.2852-2.1953-2.1992-5.1758-3.4336-8.2852-3.4336z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};