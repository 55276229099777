import { gql } from "../../../__generated__";

export const FindMaterials = gql(/* GraphQL */ `
	query FindMaterials(
		$tags: iStringFilters
		$keywords: iStringFilters
	) {
		FindMaterials(
			tags: $tags
			keywords: $keywords
		) {
			id
			name
			tags
			keywords
			groupName
			volumeConversion {
				weightPerCubicYard
				weightPerCubicYardCampus
				weightPerCubicYardCommercial
				weightPerCubicYardMultiFamily
				weightPerCubicYardFoodService
			}
		}
	}
`);