import { Route, Routes } from "react-router";
import { UserProfileLayout } from "./layout";
import { PersonalInformationView } from "./views/PersonalInformationView";
import { PaymentMethodsView } from "./views";
import { useLoginGuard } from "../../auth";

export const UserProfileRouter: React.FC = () => {
	useLoginGuard();

	return (
		<Routes>
			<Route path="" element={<UserProfileLayout />}>
				<Route path="" element={<PersonalInformationView />} />
				<Route path="/payment-methods" element={<PaymentMethodsView />} />
				<Route path="/personal-information" element={<PersonalInformationView />} />
			</Route>
		</Routes>
	);
};