import { gql } from "@apollo/client";

export const Product_ClassificationFragment = gql`
	fragment Product_ClassificationFragment on Product {
		id
		type {
			...Type_DefaultFragment
		}
		size {
			...Size_DefaultFragment
		}
	}
`;