import { SvgIcon, SvgIconProps } from "@mui/material";

	export const TableLamp: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1008.4 593.01-147.57-462.5c-3.4727-10.883-13.582-18.27-25.004-18.27h-167.61v-5.707c0-38.789-31.559-70.352-70.359-70.352-38.789 0-70.352 31.559-70.352 70.352v5.707h-163.32c-11.422 0-21.531 7.3867-25.004 18.27l-147.57 462.5c-2.5508 7.9805-1.1328 16.703 3.8086 23.469 4.9414 6.7578 12.816 10.77 21.195 10.77h112.63v113.27c-23.863 7.8906-41.152 30.375-41.152 56.852 0 33.031 26.871 59.902 59.902 59.902s59.902-26.871 59.902-59.902c0-26.477-17.289-48.961-41.152-56.852v-113.28h150.32v366.55h-75c-50.477 0-94.453 34.164-106.94 83.07l-13.836 54.219c-2.0039 7.8516-0.26172 16.191 4.7109 22.598s12.621 10.148 20.73 10.148h504.62c8.1094 0 15.758-3.7422 20.73-10.148s6.7109-14.746 4.7109-22.598l-13.836-54.219c-12.488-48.906-56.461-83.07-106.94-83.07h-80.477l-0.007813-366.55h307.88c8.3789 0 16.254-4.0039 21.195-10.77 4.9375-6.7656 6.3555-15.48 3.8047-23.465zm-660.42 226.75c-12.352 0-22.402-10.051-22.402-22.402 0-12.344 10.051-22.395 22.402-22.395 12.352 0 22.402 10.051 22.402 22.395 0.007813 12.359-10.043 22.402-22.402 22.402zm232.03-713.23c0-9.8477 8.0039-17.852 17.852-17.852 9.8477 0 17.859 8.0117 17.859 17.852v5.707h-14.723-0.19531-1.582-0.19531-19.004l-0.003906-5.707zm232.05 983.32 5.4766 21.473h-437.04l5.4766-21.473c6.5391-25.641 29.594-43.551 56.07-43.551h313.95c26.473 0.003906 49.527 17.906 56.066 43.551zm-189.04-96.047h-53.469v-366.55h53.469zm-370.47-419.06 130.81-410h170.37 0.039062 88.207 0.039062 174.65l130.81 410z"/>
</svg>
			</SvgIcon>
		);
	};