import { gql } from "../../../__generated__";

export const FindUserHaulers = gql(/* GraphQL */ `
	query FindUserHaulers {
		FindUserHaulers {
			...Hauler_UsersFragment
			...Hauler_HeaderFragment
			...Hauler_RequestsFragment
		}
	}
`);