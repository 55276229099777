import { ListItemButton, ListItemText, ListItemSecondaryAction, IconButton, Typography, CircularProgress } from "@mui/material";
import { Box } from "grommet";
import { ProductListItemIcon } from "./ProductListItemIcon";
import { Close } from "@mui/icons-material";
import { Fragment, useMemo } from "react";
import { ProductFragmentGql } from "../../graphql";
import { ProductIntent } from "../../graphql/__generated__/graphql";
import { formatCurrency } from "../../helpers";
import { useSession } from "../../hooks";
import { useItemDisassemblyFee, useItemPickupFee } from "./ItemDialogController";

interface ProductListItemProps {
	sessionId: string;
	product: ProductFragmentGql;
	onSelect?: () => void;
	onDelete?: () => void;
	isDeleting?: boolean;
}

export const ProductCreationListItem: React.FC<ProductListItemProps> = (props) => {
	const { session, useCustomScheduleMargin } = useSession(props.sessionId);

	function handleDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.stopPropagation();
		props.onDelete?.();
	}

	const pickupFee = useItemPickupFee(
		session?.referer?.id ?? "",
		useCustomScheduleMargin,
		props.product.quantity,
		props.product.type?.id ?? "",
		props.product.size?.id ?? ""
	);

	const disassemblyFee = useItemDisassemblyFee(
		session?.referer?.id ?? "",
		useCustomScheduleMargin,
		props.product.quantityDisassembly,
		props.product.type?.id ?? ""
	);

	const totalFee = useMemo(() => {
		return pickupFee + disassemblyFee;
	}, [ pickupFee, disassemblyFee ]);

	const intent = useMemo(() => {
		if(props.product.intent) {
			switch(props.product.intent) {
				case ProductIntent.Resale: {
					return "Sell";
				}
				case ProductIntent.Disposal: {
					return "Junk";
				}
				case ProductIntent.Donation: {
					return "Donate";
				}
			}
		}

		return "";
	}, [ props.product ]);

	if(!props.product.type) return <></>;


	return (
		<ListItemButton divider onClick={props.onSelect}>
			<ProductListItemIcon
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				///@ts-expect-error
				product={props.product}
			/>
			<ListItemText>
				<Box>
					<Typography fontWeight="bold">{props.product.type.name} (x {props.product.quantity})</Typography>
					<Typography fontSize="small">{props.product.size?.name ?? "Standard"}</Typography>
				</Box>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small">
					{((totalFee && totalFee > 0) || false) && (
						<Box align="center" justify="center" margin={{ right: "small" }}>
							<Typography fontWeight="bold">{formatCurrency(totalFee)}</Typography>
						</Box>
					)}
					{/* {intent && (
						<Box justify="center">
							<Chip
								color="secondary"
								label={<Typography fontWeight="bold" variant="caption">{intent}</Typography>}
							/>
						</Box>
					)} */}
					{props.onDelete && (
						<IconButton onClick={handleDelete}>
							<Fragment>
								{props.isDeleting
									? (
										<CircularProgress />
									)
									: (
										<Close />
									)}
							</Fragment>

						</IconButton>
					)}
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton >
	);
};