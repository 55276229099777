import { gql } from "../../../__generated__";

export const UnassignPricingPolicy = gql(/* GraphQL */ `
	mutation UnassignPricingPolicy(
		$partnerId: String!
	) {
		UnassignPricingPolicy(
			partnerId: $partnerId
		) {
			id
		}
	}
`);