import React from "react";
import { Box, BoxExtendedProps, Heading, HeadingExtendedProps } from "grommet";

export const HeadingWithIcon: React.FC<{
	text: string | React.ReactNode;
	icon: React.ReactNode;
	props?: HeadingExtendedProps;
	containerProps?: BoxExtendedProps;
}> = ({ text, icon, props, containerProps }) => (
	<Box {...containerProps} direction="row" gap="small">
		<Box justify="center">
			{icon}
		</Box>
		<Box justify="center">
			<Heading {...props}>
				{text}
			</Heading>
		</Box>
	</Box>
);