import { gql } from "../../../__generated__";

export const FetchPricedItemType = gql(/* GraphQL */`
	query FetchPricedItemType(
		$typeId: String!
		$partnerId: String!
		$useCustomScheduleMargin: Boolean
	) {
		FetchItemType(typeId: $typeId) {
			id
			name
			index
			iconUrl
			pickupFee(
				partnerId: $partnerId
				useCustomScheduleMargin: $useCustomScheduleMargin
			)
			disassemblyFee(
				partnerId: $partnerId
				useCustomScheduleMargin: $useCustomScheduleMargin
			)
			sizes {
				id
				name
				index
				iconUrl
				pickupFee(
					partnerId: $partnerId
					useCustomScheduleMargin: $useCustomScheduleMargin
				)
				disassemblyFee(
					partnerId: $partnerId
					useCustomScheduleMargin: $useCustomScheduleMargin

				)
			}
			category {
				id
				name
				index
				iconUrl
			}
		}
	}
`);

