import { Fragment, useMemo, useState } from "react";
import { useUser } from "../../auth";
import { Checkbox, IconButton, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { Box, Grid, Spinner, Text } from "grommet";
import { Amex, Mastercard, Visa, CreditCard } from "grommet-icons";
import { padNumber, toProperCase } from "../../helpers";
import { useWindowDimensions } from "../../hooks";
import { DeletePaymentMethod, UserPaymentMethodGql, usePaymentMethodFragment } from "../../graphql";
import { Close } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

export function usePaymentMethod(paymentMethod?: UserPaymentMethodGql) {
	const { size } = useWindowDimensions();

	const formattedPaymentMethod = useMemo(() => {
		if(!paymentMethod) {
			return "";
		}

		switch(size) {
			case "small": {
				return `${toProperCase(paymentMethod?.brand)} ${paymentMethod?.last4}`;
			}
			default: {
				return `${toProperCase(paymentMethod?.brand)} ending in ${paymentMethod?.last4}`;
			}
		}
	}, [ paymentMethod, size ]);

	return {
		formatted: formattedPaymentMethod
	};
}

export const PaymentMethodListItemWithDelete: React.FC<{
	id: string;
	selected?: boolean;
	isSelecting?: boolean;
	onSelect?: () => void;
}> = (props) => {
	const snack = useSnackbar();
	const { refetch } = useUser();
	const [ isDeleting, setIsDeleting ] = useState(false);

	const [
		deletePaymentMethod,
		{ loading: isDeletingMutation }
	] = useMutation(DeletePaymentMethod);

	return (
		<PaymentMethodListItem
			{...props}
			isDeleting={isDeleting || isDeletingMutation}
			onDelete={() => {
				setIsDeleting(true);
				deletePaymentMethod({
					variables: {
						paymentMethodId: props.id
					}
				}).then(() => {
					setTimeout(() => {
						refetch().then(() => {
							snack.enqueueSnackbar("Payment method deleted.", {
								variant: "success"
							});
							setIsDeleting(false);
						});
					}, 1000);
				}).catch(err => {
					console.error(err);
					snack.enqueueSnackbar("We weren't able to delete your payment method. Please try again later.", {
						variant: "error"
					});
					setIsDeleting(false);
				});
			}}
		/>
	);
};

export const PaymentMethodListItem: React.FC<{
	id: string;
	selected?: boolean;
	isDeleting?: boolean;
	isSelecting?: boolean;
	onSelect?: () => void;
	onDelete?: () => void;
	showCheckboxSelection?: boolean;
}> = ({ id, selected, isSelecting, isDeleting, onSelect, onDelete, showCheckboxSelection }) => {
	const { data } = usePaymentMethodFragment(id);

	const cardIcon = useMemo(() => {
		switch(data.brand) {
			case "amex": {
				return <Amex color="plain" />;
			}
			case "mastercard": {
				return <Mastercard color="plain" />;
			}
			case "visa": {
				return <Visa color="plain" />;
			}
			default: {
				return <CreditCard color="brand" />;
			}
		}
	}, [ data.brand ]);

	const brandName = useMemo(() => {
		if(!data.brand) {
			return "";
		}

		return toProperCase(data.brand);
	}, [ data.brand ]);

	const { size } = useWindowDimensions();

	return (
		<ListItemButton divider selected={selected} onClick={onSelect}>
			<ListItemIcon>
				{cardIcon}
			</ListItemIcon>
			<ListItemText
				primary={brandName}
				secondary={
					<Text style={{ letterSpacing: size === "small" ? "2px" : "3px" }}>.... {data.last4}</Text>
				}
			/>
			<ListItemSecondaryAction>
				<Grid columns={[ "flex", "auto" ]} gap="small">
					<Box justify="center">
						<Text weight="bold" style={{ letterSpacing: "2px" }}>
							{padNumber(Number(data.expirationMonth))}/{data.expirationYear}
						</Text>
					</Box>
					<Box align="end" justify="center" width="40px">
						{(isSelecting || isDeleting) ? (
							<Spinner />
						) : (onDelete) ? (
							<IconButton color="error" onClick={onDelete}>
								<Close />
							</IconButton>
						) : (showCheckboxSelection) && (
							<Box justify="center">
								<Checkbox
									checked={selected}
								/>
							</Box>
						)}
					</Box>

				</Grid>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

interface PaymentMethodsListProps {
	selectedId?: string;
	isSelectingId?: string;
	onSelect?: (id: string) => void;
	showCheckboxSelection?: boolean;
}

export const PaymentMethodsList: React.FC<PaymentMethodsListProps> = (props) => {
	const { user } = useUser();

	const paymentMethods = useMemo(() => {
		return user?.paymentMethods || [];
	}, [ user ]);

	return (
		<Fragment>
			<List>
				{paymentMethods.map((method) => (
					<PaymentMethodListItem
						key={method.id}
						id={method.id}
						isSelecting={props.isSelectingId === method.id}
						selected={props.selectedId === method.id}
						onSelect={() => props.onSelect?.(method.id)}
						showCheckboxSelection={props.showCheckboxSelection}
					/>
				))}
				{paymentMethods.length === 0 && (
					<Box align="center" justify="center">
						<Text>No payment methods found.</Text>
					</Box>
				)}
			</List>
		</Fragment>
	);
};