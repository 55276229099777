import { gql } from "@apollo/client";

export const Product_UserFragment = gql`
	fragment Product_UserFragment on Product {
		user {
			...User_HeaderFragment
		}
	}
`;

export const Product_UserContactFragment = gql`
	fragment Product_UserContactFragment on Product {
		user {
			...User_HeaderFragment
			...User_ContactsFragment
		}
	}
`;