import { gql } from "@apollo/client";

export const WasteAudit_ContainersFragment = gql`
	fragment WasteAudit_ContainersFragment on WasteAudit {
		containers {
			id
			name
			location
			container {
				id
				name
				iconUrl
			}
			material {
				id
				name
				groupName
			}
			volume
			volumeUnit
			averageFillRate
			averageCollectionWeight
			weeklyCollectionWeight
			monthlyCollectionWeight
			maximumCollectionWeight
		}
	}
`;