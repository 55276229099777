import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectOnboardingState, upsertOnboardingState } from "../../../store/onboarding";
import { OnboardingFormContext } from "../types";

export function useOnboardingFormContext() {
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectOnboardingState);

	const formContext = useForm<OnboardingFormContext>({
		defaultValues: {
			...state
		}
	});

	const formValues = formContext.watch();

	useEffect(() => {
		if(formValues) {
			dispatch(upsertOnboardingState({
				...formValues
			}));
		}
	}, [ dispatch, formValues ]);

	return formContext;
}
