import { Grid, GridGapType } from "grommet";
import { Sizes, useWindowDimensions } from "../hooks";
import { useMemo } from "react";

export const CustomGrid: React.FC<{
	gap?: GridGapType;
	fullSizeColumns?: number,
	children: React.ReactNode[];
	breakpoint?: Sizes;
}> = (props) => {
	const { sizeIndex } = useWindowDimensions();
	const columns = useMemo(() => {
		if(props.breakpoint) {
			if(sizeIndex >= props.breakpoint) {
				return props.fullSizeColumns || 2;
			}
			else {
				return 1;
			}
		}
		else {
			return sizeIndex <= Sizes.MEDIUM ? 1 : 2;
		}
	}, [ props.fullSizeColumns, props.breakpoint, sizeIndex ]);

	return (
		<Grid gap={props.gap || "small"} columns={{ count: columns, size: "auto" }}>
			{props.children}
		</Grid>
	);
};