import { gql } from "@apollo/client";

export const Order_SourceFragment = gql`
	fragment Order_SourceFragment on Order {
		user {
			...User_HeaderFragment
		}
		referer {
			id
			name
		}
		instance {
			id
			name
			type
			domain
		}
		request {
			id
			unit
			room
			isBulkPickup
			productCount
		}
	}
`;