import { Box, Heading } from "grommet";
import { useLogin } from "../../../auth";
import { useMemo } from "react";
import { List, Typography } from "@mui/material";
import { PaymentMethodListItemWithDelete, ViewContainer } from "../../../components";
import { CreatePaymentMethodButton } from "../../payments/components/CreatePaymentMethodButton";
import { Add } from "@mui/icons-material";
import { usePageTitle, useWindowDimensions } from "../../../hooks";

export const PaymentMethodsView: React.FC = () => {
	usePageTitle("Payment Methods");
	const { user } = useLogin();
	const { size } = useWindowDimensions();

	const paymentMethods = useMemo(() => {
		return user?.paymentMethods ?? [];
	}, [ user ]);

	return (
		<ViewContainer>
			<Box gap="medium">
				<Box direction="row" justify="between">
					<Heading level="3" margin="none">
						Your Payment Methods
					</Heading>
					<Box>
						<CreatePaymentMethodButton
							size="small"
							color="success"
							variant="contained"
							label={size === "small" ? "Add New" : "Add Payment Method"}
							icon={<Add />}
						/>
					</Box>
				</Box>
				<List>
					{paymentMethods.map(paymentMethod => (
						<PaymentMethodListItemWithDelete
							id={paymentMethod.id}
							key={paymentMethod.id}
							isSelecting={false}
						/>
					))}
				</List>
				{paymentMethods.length === 0 && (
					<Box align="center" justify="center">
						<Typography fontWeight="bold">
							You have no payment methods. Add one to get started!
						</Typography>
					</Box>
				)}
			</Box>
		</ViewContainer>
	);
};