import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Bookshelf: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1054.5 54.445v1091.2c0 6.3164-1.082 12.383-3.0703 18.02h57.613c24.242 0 24.242 36.363 0 36.363h-1018.2c-24.242 0-24.242-36.363 0-36.363h57.609c-1.9844-5.6367-3.0664-11.703-3.0664-18.02v-1091.2c0-30.094 24.477-54.445 54.637-54.445h799.82c30.105 0 54.637 24.48 54.637 54.445zm-776.74 327.37 68.582-205.75c3.1758-9.5273 13.473-14.676 23-11.5 9.5273 3.1758 14.676 13.473 11.5 22.996l-64.75 194.25h88.941l68.582-205.75c3.1758-9.5273 13.473-14.676 23-11.5 9.5234 3.1758 14.672 13.473 11.496 22.996l-64.75 194.25h138.41v-345.45h-381.73c-10.117 0-18.273 8.1133-18.273 18.082v327.37zm601.96 0h138.41v-327.37c0-9.8672-8.2344-18.082-18.273-18.082h-381.73v345.45h223.26l68.582-205.75c3.1758-9.5273 13.473-14.676 23-11.5 9.5234 3.1758 14.672 13.473 11.5 22.996zm-575.35 381.82-49.406-214.09c-5.4531-23.621 29.98-31.797 35.434-8.1758l51.293 222.27h89.953l-49.406-214.09c-5.4531-23.621 29.98-31.797 35.43-8.1758l51.293 222.27h112.81v-345.45h-400v345.45zm459.22 0v-218.18c0-10.039 8.1406-18.18 18.18-18.18 10.043 0 18.184 8.1406 18.184 18.18v218.18h90.91v-218.18c0-10.039 8.1406-18.18 18.18-18.18 10.043 0 18.184 8.1406 18.184 18.18v218.18h90.91v-345.45h-400v345.45zm-581.82 36.363v345.62c0 9.9414 8.1406 18.02 18.273 18.02h381.73v-363.64zm436.37 0v363.64h381.73c10.125 0 18.273-8.0938 18.273-18.02v-345.62zm90.906 145.45c0-10.039 8.1406-18.18 18.184-18.18 10.039 0 18.18 8.1406 18.18 18.18v72.73c0 10.039-8.1406 18.18-18.18 18.18-10.043 0-18.184-8.1406-18.184-18.18zm-254.54 0c0-10.039 8.1406-18.18 18.18-18.18 10.043 0 18.184 8.1406 18.184 18.18v72.73c0 10.039-8.1406 18.18-18.184 18.18-10.039 0-18.18-8.1406-18.18-18.18z" fill-rule="evenodd"/>
</svg>
			</SvgIcon>
		);
	};