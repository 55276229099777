import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Boxes: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m762.59 702.24h-497.71c-4.2344 0-8.2969-1.6797-11.293-4.6758-2.9961-2.9961-4.6797-7.0586-4.6797-11.293v-497.71c0-4.2344 1.6836-8.2969 4.6797-11.289 2.9961-2.9922 7.0586-4.6719 11.293-4.668h497.71c4.2305 0 8.2891 1.6797 11.285 4.6719 2.9922 2.9922 4.6719 7.0547 4.6719 11.285v497.71c0 4.2344-1.6797 8.293-4.6719 11.289-2.9922 2.9922-7.0547 4.6797-11.285 4.6797zm-481.79-31.93h465.82v-465.78h-465.82z"/>
  <path d="m935.12 529.69c-4.2344 0.003906-8.2969-1.6758-11.289-4.6719-2.9961-2.9922-4.6758-7.0547-4.6719-11.289v-481.75h-481.75c-5.5977-0.15234-10.707-3.2305-13.465-8.1055-2.7578-4.8789-2.7578-10.844 0-15.719 2.7578-4.8789 7.8672-7.9531 13.465-8.1094h497.71c4.2344 0 8.2969 1.6836 11.293 4.6797 2.9961 2.9961 4.6797 7.0586 4.6797 11.293v497.71c0 4.2383-1.6836 8.2969-4.6797 11.293-2.9961 2.9922-7.0586 4.6719-11.293 4.668z"/>
  <path d="m264.88 204.53c-4.2344 0-8.2969-1.6836-11.289-4.6797-2.9961-2.9922-4.6797-7.0547-4.6797-11.293 0-4.2344 1.6836-8.2969 4.6797-11.289l172.54-172.54c4.0312-4.0312 9.9102-5.6094 15.418-4.1328 5.5117 1.4727 9.8164 5.7734 11.293 11.285 1.4766 5.5078-0.097656 11.387-4.1289 15.422l-172.55 172.55c-2.9844 3-7.0469 4.6875-11.281 4.6797z"/>
  <path d="m762.59 204.53c-4.2383 0-8.2969-1.6836-11.293-4.6797-2.9961-2.9922-4.6758-7.0547-4.6758-11.293 0-4.2344 1.6797-8.2969 4.6758-11.289l172.54-172.54c4.0312-4.0312 9.9102-5.6094 15.422-4.1328 5.5078 1.4727 9.8125 5.7734 11.289 11.285 1.4805 5.5078-0.09375 11.387-4.1289 15.422l-172.54 172.55c-2.9922 3-7.0547 4.6836-11.289 4.6797z"/>
  <path d="m762.59 702.24c-4.2383 0-8.2969-1.6836-11.293-4.6758-2.9961-2.9961-4.6758-7.0586-4.6758-11.293s1.6797-8.2969 4.6758-11.293l172.54-172.54c4.0586-3.9141 9.8789-5.3945 15.312-3.9023 5.4336 1.4922 9.6836 5.7383 11.176 11.172 1.4922 5.4375 0.007813 11.254-3.9062 15.312l-172.54 172.54c-2.9922 3-7.0547 4.6875-11.289 4.6797z"/>
  <path d="m513.73 204.53c-4.2344 0-8.2969-1.6836-11.289-4.6797-2.9961-2.9922-4.6758-7.0547-4.6758-11.293 0-4.2344 1.6797-8.2969 4.6758-11.289l172.54-172.54c4.0312-4.0312 9.9102-5.6094 15.418-4.1328 5.5117 1.4727 9.8164 5.7734 11.293 11.285 1.4766 5.5078-0.09375 11.387-4.1289 15.422l-172.54 172.55c-2.9922 3-7.0547 4.6836-11.293 4.6797z"/>
  <path d="m501.98 630h-130.56c-18.016-0.27734-34.547-10.047-43.477-25.695-8.9258-15.648-8.9258-34.852 0-50.5 8.9297-15.648 25.461-25.422 43.477-25.695h130.56c13.648-0.21094 26.809 5.0625 36.531 14.641 9.7266 9.5781 15.203 22.656 15.203 36.305 0 13.648-5.4766 26.727-15.203 36.305-9.7227 9.5742-22.883 14.852-36.531 14.641zm-130.56-69.961c-6.6797 0.16797-12.781 3.8281-16.074 9.6445-3.293 5.8125-3.293 12.93 0 18.742 3.293 5.8125 9.3945 9.4727 16.074 9.6406h130.56c6.6797-0.16797 12.781-3.8281 16.074-9.6406 3.2891-5.8125 3.2891-12.93 0-18.742-3.293-5.8164-9.3945-9.4766-16.074-9.6445z"/>
  <path d="m686.18 345.88h-102.16c-4.3086 0.12109-8.4844-1.5117-11.574-4.5156-3.0898-3.0078-4.8359-7.1367-4.8359-11.449s1.7461-8.4414 4.8359-11.449c3.0898-3.0039 7.2656-4.6367 11.574-4.5156h102.16c5.6016 0.15234 10.711 3.2266 13.469 8.1055 2.7539 4.875 2.7539 10.84 0 15.719-2.7578 4.875-7.8672 7.9531-13.469 8.1055z"/>
  <path d="m686.18 274.8h-102.16c-5.707 0-10.977-3.0469-13.832-7.9883-2.8516-4.9414-2.8516-11.027 0-15.969 2.8555-4.9453 8.125-7.9883 13.832-7.9883h102.16c5.707 0 10.98 3.043 13.832 7.9883 2.8555 4.9414 2.8555 11.027 0 15.969-2.8516 4.9414-8.125 7.9883-13.832 7.9883z"/>
  <path d="m1011.4 1200h-497.71c-4.2344 0-8.2969-1.6836-11.293-4.6797-2.9961-2.9922-4.6758-7.0547-4.6758-11.293v-497.76c0-4.2344 1.6875-8.2969 4.6797-11.289 2.9961-2.9922 7.0547-4.6719 11.289-4.6719h497.71c4.2305 0.003906 8.2891 1.6836 11.281 4.6758 2.9922 2.9922 4.6758 7.0508 4.6758 11.285v497.71c0.015625 4.2422-1.6602 8.3164-4.6562 11.32-2.9922 3.0039-7.0586 4.6953-11.301 4.6992zm-481.75-31.98h465.78v-465.78h-465.78z"/>
  <path d="m1184 1027.4c-4.2344 0-8.293-1.6797-11.285-4.6719-2.9922-2.9922-4.6758-7.0547-4.6758-11.285v-481.75h-232.89c-5.6016-0.15234-10.711-3.2305-13.469-8.1055-2.7539-4.8789-2.7539-10.844 0-15.719 2.7578-4.8789 7.8672-7.9531 13.469-8.1055h248.86c4.2422-0.015625 8.3203 1.6641 11.324 4.6602 3.0078 2.9961 4.6953 7.0664 4.6953 11.309v497.71c0 4.2422-1.6914 8.3125-4.6953 11.305-3.0078 2.9961-7.082 4.6719-11.324 4.6523z"/>
  <path d="m1011.4 702.24c-4.2383 0-8.2969-1.6836-11.293-4.6758-2.9922-2.9961-4.6758-7.0586-4.6758-11.293s1.6797-8.2969 4.6758-11.293l172.55-172.54c4.0352-4.0352 9.9141-5.6055 15.422-4.1289 5.5117 1.4766 9.8125 5.7812 11.285 11.293 1.4766 5.5078-0.10156 11.387-4.1328 15.418l-172.54 172.54c-2.9883 3-7.0547 4.6875-11.289 4.6797z"/>
  <path d="m1011.4 1200c-4.2344-0.003906-8.2891-1.6875-11.281-4.6797-2.9922-2.9961-4.6758-7.0508-4.6758-11.285-0.003906-4.2305 1.6758-8.293 4.6641-11.289l172.55-172.59c4.0352-4.0312 9.9141-5.6055 15.422-4.1289 5.5117 1.4766 9.8125 5.7812 11.285 11.293 1.4766 5.5078-0.10156 11.387-4.1328 15.418l-172.54 172.54c-2.9805 3.0156-7.0469 4.7188-11.289 4.7266z"/>
  <path d="m762.59 702.24c-4.2383 0-8.2969-1.6836-11.293-4.6758-2.9961-2.9961-4.6758-7.0586-4.6758-11.293s1.6797-8.2969 4.6758-11.293l172.54-172.54c4.0586-3.9141 9.8789-5.3945 15.312-3.9023 5.4336 1.4922 9.6836 5.7383 11.176 11.172 1.4922 5.4375 0.007813 11.254-3.9062 15.312l-172.54 172.54c-2.9922 3-7.0547 4.6875-11.289 4.6797z"/>
  <path d="m750.84 1127.7h-130.56c-18.203 0-35.023-9.7109-44.129-25.477-9.1016-15.762-9.1016-35.188 0-50.949 9.1055-15.766 25.926-25.477 44.129-25.477h130.56c18.203 0 35.023 9.7109 44.125 25.477 9.1016 15.762 9.1016 35.188 0 50.949-9.1016 15.766-25.922 25.477-44.125 25.477zm-130.56-69.973c-6.6797 0.16797-12.781 3.8281-16.074 9.6445-3.293 5.8125-3.293 12.93 0 18.742 3.293 5.8164 9.3945 9.4766 16.074 9.6445h130.56c6.6797-0.16797 12.781-3.8281 16.074-9.6445 3.293-5.8125 3.293-12.93 0-18.742-3.293-5.8164-9.3945-9.4766-16.074-9.6445z"/>
  <path d="m935.04 843.6h-102.16c-4.3086 0.11719-8.4844-1.5117-11.574-4.5195-3.0898-3.0078-4.8359-7.1367-4.8359-11.449s1.7461-8.4414 4.8359-11.445c3.0898-3.0078 7.2656-4.6367 11.574-4.5195h102.16c5.6016 0.15234 10.711 3.2305 13.469 8.1055 2.7539 4.8789 2.7539 10.844 0 15.719-2.7578 4.8789-7.8672 7.9531-13.469 8.1094z"/>
  <path d="m935.04 772.55h-102.16c-5.7031 0-10.977-3.043-13.832-7.9844-2.8516-4.9414-2.8516-11.031 0-15.973 2.8555-4.9414 8.1289-7.9844 13.832-7.9844h102.16c5.707 0 10.98 3.043 13.832 7.9844 2.8555 4.9414 2.8555 11.031 0 15.973-2.8516 4.9414-8.125 7.9844-13.832 7.9844z"/>
  <path d="m513.73 1200h-497.71c-4.2539 0.011719-8.3359-1.6719-11.34-4.6797-3.0078-3.0039-4.6914-7.0859-4.6797-11.34v-497.71c0.0039062-4.2344 1.6875-8.2969 4.6836-11.289 2.9922-2.9922 7.0547-4.6719 11.289-4.6719h497.76c4.2344 0.003906 8.293 1.6836 11.285 4.6758s4.6719 7.0508 4.6758 11.285v497.71c0.011719 4.2422-1.6641 8.3164-4.6562 11.32-2.9922 3.0039-7.0625 4.6953-11.305 4.6992zm-481.75-31.98h465.78v-465.78h-465.78z"/>
  <path d="m264.88 529.69h-76.32c-5.5977-0.15234-10.707-3.2305-13.465-8.1055-2.7578-4.8789-2.7578-10.844 0-15.719 2.7578-4.8789 7.8672-7.9531 13.465-8.1055h76.32c5.6016 0.15234 10.711 3.2266 13.469 8.1055 2.7539 4.875 2.7539 10.84 0 15.719-2.7578 4.875-7.8672 7.9531-13.469 8.1055z"/>
  <path d="m16.02 702.24c-4.2344 0-8.2969-1.6836-11.289-4.6758-2.9961-2.9961-4.6797-7.0586-4.6797-11.293s1.6836-8.2969 4.6758-11.293l172.54-172.54c2.9727-3.0898 7.0625-4.8477 11.352-4.8867 4.2852-0.042969 8.4102 1.6445 11.441 4.6758s4.7148 7.1523 4.6758 11.441c-0.039063 4.2852-1.8008 8.3789-4.8867 11.352l-172.55 172.54c-2.9883 3.0039-7.0469 4.6875-11.281 4.6797z"/>
  <path d="m253.13 1127.7h-130.56c-18.203 0-35.023-9.7109-44.125-25.477-9.1016-15.762-9.1016-35.188 0-50.949 9.1016-15.766 25.922-25.477 44.125-25.477h130.56c18.203 0 35.023 9.7109 44.125 25.477 9.1016 15.762 9.1016 35.188 0 50.949-9.1016 15.766-25.922 25.477-44.125 25.477zm-130.56-69.973h-0.003906c-6.6797 0.16797-12.781 3.8281-16.07 9.6445-3.293 5.8125-3.293 12.93 0 18.742 3.2891 5.8164 9.3906 9.4766 16.07 9.6445h130.56c6.6797-0.16797 12.781-3.8281 16.07-9.6445 3.293-5.8125 3.293-12.93 0-18.742-3.2891-5.8164-9.3906-9.4766-16.07-9.6445z"/>
  <path d="m437.32 843.6h-102.14c-4.3086 0.11719-8.4844-1.5117-11.574-4.5195-3.0938-3.0078-4.8359-7.1367-4.8359-11.449s1.7422-8.4414 4.8359-11.445c3.0898-3.0078 7.2656-4.6367 11.574-4.5195h102.14c4.3086-0.11719 8.4844 1.5117 11.574 4.5195 3.0898 3.0039 4.8359 7.1328 4.8359 11.445s-1.7461 8.4414-4.8359 11.449c-3.0898 3.0078-7.2656 4.6367-11.574 4.5195z"/>
  <path d="m437.32 772.55h-102.14c-5.707 0-10.98-3.043-13.832-7.9844-2.8516-4.9414-2.8516-11.031 0-15.973 2.8516-4.9414 8.125-7.9844 13.832-7.9844h102.14c5.707 0 10.98 3.043 13.832 7.9844 2.8516 4.9414 2.8516 11.031 0 15.973-2.8516 4.9414-8.125 7.9844-13.832 7.9844z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};