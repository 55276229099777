import { gql } from "@apollo/client";


export const ReuseOutlet_ScheduledPickupsFragment = gql`
	fragment ReuseOutlet_ScheduledPickupsFragment on ReuseOutlet {
		scheduledPickups {
			id
			scheduledDate
		}
	}
`;
