import { useMemo } from "react";
import { calculateDisassemblyFee, calculatePickupFee, calculcatePickupFeeComparison } from "../pricing";
import { useQuantity, useQuantityDisassembly } from "./useQuantity";
import { usePricedType } from "../../../hooks";
import { useCheckoutSession } from "./useCheckoutSession";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { useFeature } from "../../instance";

export function usePickupFee(
	typeId: string,
	sizeId: string
) {
	const session = useCheckoutSession();
	const { type } = usePricedType(
		typeId,
		session.referer?.id || "",
		session.isCustomSchedule
	);
	const quantity = useQuantity(typeId, sizeId);
	const quantityDisassembly = useQuantityDisassembly(typeId, sizeId);

	const pickupFee = useMemo(() => {
		return calculatePickupFee(type, typeId, sizeId);
	}, [ type, typeId, sizeId ]);

	const disassemblyFee = useMemo(() => {
		return calculateDisassemblyFee(type, typeId, sizeId);
	}, [ type, typeId, sizeId ]);

	const pickupFeeComparison = useMemo(() => {
		return calculcatePickupFeeComparison(type, typeId, sizeId);
	}, [ type, typeId, sizeId ]);

	const totalFee = useMemo(() => {
		return pickupFee + disassemblyFee;
	}, [ pickupFee, disassemblyFee ]);

	const totalFeeComparison = useMemo(() => {
		return pickupFeeComparison + disassemblyFee;
	}, [ pickupFeeComparison, disassemblyFee ]);

	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);

	if(!isUpfrontPricingEnabled) {
		return {
			totalFee: 0,
			totalFeeComparison: 0,
			pickupFee: 0,
			disassemblyFee: 0,
			pickupFeeComparison: 0,
			pickupFeeSum: 0,
			disassemblyFeeSum: 0,
			pickupFeeComparisonSum: 0
		};
	}

	return {
		totalFee,
		totalFeeComparison,
		pickupFee,
		disassemblyFee,
		pickupFeeComparison,
		pickupFeeSum: pickupFee * quantity,
		disassemblyFeeSum: disassemblyFee * quantityDisassembly,
		pickupFeeComparisonSum: pickupFeeComparison * quantity
	};
}