import { DocumentType, gql } from "../__generated__";

export const FindStorePartnerByInstance = gql(/* GraphQL */`
	query FindStorePartnerByInstance($instanceId: String!) {
		FindStorePartners(instanceId: $instanceId) {
			id
			name
			seller {
				id
				displayName
				allowsPickup
				allowsDelivery
				pickupInstructions
			}
			linkedPickups {
				id
				scheduledDate
			}
			instance {
				id
				name
				domain
			}
		}
	}
`);

export type StorePartnerGql = DocumentType<typeof FindStorePartnerByInstance>[ "FindStorePartners" ][ 0 ];

export const UpdatePartnerSeller = gql(/* GraphQL */`
	mutation UpdatePartnerSeller(
		$storeId: String!
		$displayName: String
		$allowsPickup: Boolean
		$allowsDelivery: Boolean
		$pickupInstructions: String
	) {
		UpdatePartnerSeller(
			storeId: $storeId
			displayName: $displayName
			allowsPickup: $allowsPickup
			allowsDelivery: $allowsDelivery
			pickupInstructions: $pickupInstructions
		) {
			id
		}
	}
`);

export const CreateStoreProduct = gql(/* GraphQL */ `
	mutation CreateStoreProduct(
		$storeId: String!
		$typeId: String!
		$sizeId: String
		$media: [CreateProductMediaDTO!]!
		$listing: CreateStoreProductListingInputDTO!
		$quantity: Int!
		$quantityDisassembly: Int!
	) {
		CreateStoreProduct(
			storeId: $storeId
			typeId: $typeId
			sizeId: $sizeId
			media: $media
			listing: $listing
			intent: RESALE
			quantity: $quantity
			quantityDisassembly: $quantityDisassembly
		) {
			id
		}
	}
`);