import { gql } from "@apollo/client";

export const ProductRelationshipsFragment = gql`
	fragment ProductRelationshipsFragment on Product {
		id
		user {
			id
		}
		order {
			id
		}
		outlet {
			id
		}
	}
`;