import React from "react";
import { Typography } from "@mui/material";
import { Box } from "grommet";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";
import { OrdersDataTable } from "./OrdersDataTable";
import { useSavedOrders } from "../../hooks/useSavedOrders";
import { defaultColumns } from "./columns";
import { TabListItem } from "../../../../../components";
import { GridColDef } from "@mui/x-data-grid";

export const SavedOrdersDataTable: React.FC<{
	columns?: GridColDef[];
}> = ({ columns }) => {
	const dispatch = useAppDispatch();
	const {
		orders, loading
	} = useSavedOrders();

	return (
		<OrdersDataTable
			loading={loading}
			columns={columns || defaultColumns}
			orders={orders}
			noRowsOverlay={(
				<Box flex align="center" justify="center">
					<Typography>
						No saved orders
					</Typography>
				</Box>
			)}
			onRowSelect={(order) => {
				dispatch(push(`/admin/orders/${order.id}`));
			}}
		/>
	);
};

export const SavedOrdersTabFactory = (columns?: GridColDef[]): TabListItem => ({
	label: "Saved",
	value: "saved",
	component: (
		<SavedOrdersDataTable
			columns={columns}
		/>
	)
});