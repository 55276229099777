import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

import { ProductDisposition } from "../graphql/__generated__/graphql";

export enum ContainerDisposition {
	COMPOST = ProductDisposition.Compost,
	RECYCLE = ProductDisposition.Recycle,
	LANDFILL = ProductDisposition.Landfill,
}

export interface WasteAuditMaterialIdentification {
	id: string;
	name: string;
	estimatedRatio: number;
	estimatatedWeightPounds: number;
	estimatedVolumeCubicFeet: number;
}

export interface WasteAuditState {
	mediaId: string;
	auditId: string;
	auditName: string;
	containerId: string;
	containerName: string;
	currentStepIndex: number;
	containerDisposition: ContainerDisposition | null;
	materials: WasteAuditMaterialIdentification[];
}

const initialState: WasteAuditState = {
	mediaId: "",
	auditId: "",
	auditName: "",
	containerId: "",
	containerName: "",
	currentStepIndex: 0,
	containerDisposition: null,
	materials: []
};

export const wasteAuditSlice = createSlice({
	name: "waste-audit",
	initialState,
	reducers: {
		setAuditId(state, action: PayloadAction<string>) {
			state.auditId = action.payload;
		},
		setAuditName(state, action: PayloadAction<string>) {
			state.auditName = action.payload;
		},
		setAuditDetails(state, action: PayloadAction<{ id: string; name: string; }>) {
			state.auditId = action.payload.id;
			state.auditName = action.payload.name;
		},
		setContainerName(state, action: PayloadAction<string>) {
			state.containerName = action.payload;
		},
		setContainerDisposition(state, action: PayloadAction<ContainerDisposition>) {
			state.containerDisposition = action.payload;
		},
		setContainerDetails(state, action: PayloadAction<{ containerName: string; containerDisposition: ContainerDisposition | null; }>) {
			state.containerName = action.payload.containerName;
			state.containerDisposition = action.payload.containerDisposition;
		},
		setCurrentStepIndex(state, action: PayloadAction<number>) {
			state.currentStepIndex = action.payload;
		},
		updateMaterialIdentification(state, action: PayloadAction<WasteAuditMaterialIdentification>) {
			state.materials = [
				...state.materials.filter(material => material.id !== action.payload.id),
				action.payload
			];
		},
		removeMaterialIdentification(state, action: PayloadAction<string>) {
			state.materials = [
				...state.materials.filter(material => material.id !== action.payload)
			];
		},
		setMediaId(state, action: PayloadAction<string>) {
			state.mediaId = action.payload;
		}
	}
});

export const {
	setMediaId,
	setAuditId,
	setAuditName,
	setAuditDetails,
	setContainerName,
	setCurrentStepIndex,
	setContainerDisposition,
	setContainerDetails,
	updateMaterialIdentification,
	removeMaterialIdentification
} = wasteAuditSlice.actions;

export const selectWasteAuditState = (state: RootState) => state.audit;

export default wasteAuditSlice.reducer;