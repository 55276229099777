import { SvgIcon, SvgIconProps } from "@mui/material";

	export const DresserWithMirror: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m843.25 662.03v-529.17c0-7.0977-5.7578-12.855-12.855-12.855h-460.8c-7.0977 0-12.855 5.7578-12.855 12.855v529.17c0 7.0977 5.7578 12.855 12.855 12.855h460.8c7.0977 0.003906 12.859-5.7539 12.859-12.852zm-25.715-12.859h-435.09v-503.45h435.09z"/>
  <path d="m981.43 717.74h-69.594v-602.03c0-35.441-28.844-64.285-64.285-64.285h-495.1c-35.441 0-64.285 28.844-64.285 64.285v602.03h-69.594c-35.441 0-64.285 28.844-64.285 64.285v336.55c0 16.535 13.461 29.996 30 29.996h80.188c16.539 0 30-13.461 30-30v-50.242h611.03l0.003906 50.242c0 16.539 13.461 30 30 30h80.207c16.539 0 30-13.461 30-30v-336.55c0-35.438-28.844-64.281-64.285-64.281zm38.57 64.285v98.152h-407.14v-136.72h368.57c21.262-0.003906 38.574 17.309 38.574 38.57zm-432.86 260.59h-407.14v-136.72h407.14zm-273.27-926.9c0-21.262 17.312-38.57 38.57-38.57h495.1c21.262 0 38.57 17.312 38.57 38.57v602.03h-572.24zm-133.88 666.31c0-21.262 17.312-38.57 38.57-38.57h368.57v136.72h-407.14zm88.762 336.54c0 2.3594-1.9258 4.2852-4.2852 4.2852h-80.191c-2.3594 0-4.2852-1.9258-4.2852-4.2852v-50.207h88.762zm344.09-212.68h407.14v136.72l-407.14 0.003907zm407.14 212.68c0 2.3594-1.9258 4.2852-4.2852 4.2852h-80.207c-2.3594 0-4.2852-1.9258-4.2852-4.2852v-50.207h88.777z"/>
  <path d="m448.59 798.97h-130.05c-7.0977 0-12.855 5.7578-12.855 12.855 0 7.0977 5.7578 12.855 12.855 12.855h130.04c7.0977 0 12.855-5.7578 12.855-12.855 0.003907-7.0977-5.7539-12.855-12.852-12.855z"/>
  <path d="m881.45 798.97h-130.05c-7.0977 0-12.855 5.7578-12.855 12.855 0 7.0977 5.7578 12.855 12.855 12.855h130.04c7.0977 0 12.855-5.7578 12.855-12.855 0.003906-7.0977-5.7578-12.855-12.852-12.855z"/>
  <path d="m318.55 987.12h130.04c7.0977 0 12.855-5.7578 12.855-12.855s-5.7578-12.855-12.855-12.855l-130.04-0.003906c-7.0977 0-12.855 5.7578-12.855 12.855 0 7.1016 5.7617 12.859 12.855 12.859z"/>
  <path d="m881.45 961.41h-130.05c-7.0977 0-12.855 5.7578-12.855 12.855 0 7.0977 5.7578 12.855 12.855 12.855h130.04c7.0977 0 12.855-5.7578 12.855-12.855 0.003906-7.0977-5.7578-12.855-12.852-12.855z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};