import React from "react";
import { PricingPolicyType } from "../../graphql/__generated__/graphql";
import { ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { PriceCheck } from "@mui/icons-material";
import { Box } from "grommet";
import { PricingPolicyTypeChip } from "./PricingPolicyTypeChip";

export const PricingPolicyListItem: React.FC<{
	name: string;
	type: PricingPolicyType;
	margin: number;
	onClick?: () => void;
}> = ({ name, type, onClick }) => {
	return (
		<ListItemButton divider onClick={onClick}>
			<ListItemIcon>
				<PriceCheck />
			</ListItemIcon>
			<ListItemText>
				<Typography fontWeight="bold">
					{name}
				</Typography>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small">
					<PricingPolicyTypeChip
						type={type} />
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
