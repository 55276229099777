import { gql } from "../../../__generated__";

export const FindWasteContainerInspection = gql(/* GraphQL */`
	query FindWasteContainerInspection(
		$missed: iBooleanFilters
		$missedAt: iDateFilters
		$processed: iBooleanFilters
		$processedAt: iDateFilters
		$scheduled: iBooleanFilters
		$scheduledAt: iDateFilters
		$scheduledFor: iDateFilters
		$inspected: iBooleanFilters
		$inspectedAt: iDateFilters
		$container: FindWasteContainerInputDTO
		$assignment: FindWasteContainerAssignmentInputDTO
		$includeContainerData: Boolean!
	) {
		FindWasteContainerInspection(
			missed: $missed
			missedAt: $missedAt
			processed: $processed
			processedAt: $processedAt
			scheduled: $scheduled
			scheduledAt: $scheduledAt
			scheduledFor: $scheduledFor
			inspected: $inspected
			inspectedAt: $inspectedAt
			container: $container
			assignment: $assignment
		) {
			...WasteContainerInspection_HeaderFragment
			...WasteContainerInspection_ContainerFragment @include(if: $includeContainerData)
		}
	}
`);