import React from "react";
import { Routes, Route } from "react-router";
import { usePageTitle } from "../../hooks";
import { CheckoutLayout } from "./layout";
import { CheckoutSessionController, CheckoutStepsController } from "./controller";

export const CheckoutRouter: React.FC = () => {
	usePageTitle("Schedule Pickup");
	return (
		<Routes>
			<Route path="/" element={(
				<CheckoutSessionController

				>
					<CheckoutLayout />
				</CheckoutSessionController>
			)}>
				<Route path="" element={<CheckoutStepsController />} />
			</Route>
		</Routes>
	);
};