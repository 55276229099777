import React, { ReactNode, useState } from "react";
import { useFrequentlyAskedQuestions, useWindowDimensions } from "../../hooks";
import { DialogWithActivatorButton } from "../Dialog";
import { AccordionController } from "../Accordion";
import { Typography } from "@mui/material";

export const FaqDialog: React.FC<{
	activatorButton: ReactNode;
}> = ({ activatorButton }) => {
	const { size } = useWindowDimensions();
	const faqs = useFrequentlyAskedQuestions();
	const [ expanded, setExpanded ] = useState("");

	return (
		<DialogWithActivatorButton
			disabledContentPadding
			activatorButton={activatorButton}
			title={size === "small" ? "FAQs" : "Frequently Asked Questions"}
		>
			{faqs.map((faq) => (
				<AccordionController
					name="faq"
					key={faq.question}
					summary={(
						<Typography fontWeight="bold">
							{faq.question}
						</Typography>
					)}
					summaryProps={{ sx: { padding: "6px", margin: "0px" } }}
					isExpanded={expanded === faq.question}
					onChange={(expanded) => setExpanded(expanded ? faq.question : "")}
					details={faq.answer}
				/>
			))}
		</DialogWithActivatorButton>
	);
};