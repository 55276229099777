import { Box, Grid, Heading } from "grommet";
import { useUser } from "../../../../auth";
import { ActionItemListItem, Pagination, ViewContainer } from "../../../../components";
import { useFullScreen, useInstance, usePageTitle, useWindowDimensions } from "../../../../hooks";
import { useDashboardTasks } from "../../hooks";
import { Typography } from "@mui/material";
import { ActiveOrderSummaryList, UniversityUpcomingPickupsList } from "../../components";
import { ScheduleNowButton } from "../residence";
import { useFeature } from "../../../instance";
import { InstanceFeature } from "../../../../graphql/__generated__/graphql";

export const UniversityAvailablePickupDatesContainer: React.FC = () => {
	const { size } = useWindowDimensions();
	const isCustomScheduleEnabled = useFeature(InstanceFeature.OrderCustomScheduleEnabled);

	return (
		<Box gap="small" flex height={size === "small" ? undefined : "medium"}>
			{size !== "small" && (
				<Box direction="row" justify="between">
					<Box justify="center">
						<Heading level="3" margin="none">
							Available Pickup Dates
						</Heading>
					</Box>
					<Box align="center">
						<ScheduleNowButton
							variant="outlined"
							size="small"
						/>
					</Box>
				</Box>
			)}
			{size !== "small" && (
				<UniversityUpcomingPickupsList />
			)}
			<Box align="center" justify="center" flex>
				<ScheduleNowButton
					listenForScheduleTrigger
					label="Schedule Your Pickup"
				/>
				{isCustomScheduleEnabled && (
					<Typography variant="caption">
						express pickups available
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export const UniversityDashboard: React.FC = () => {
	usePageTitle("Dashboard");
	const { user } = useUser();
	const { instance } = useInstance();
	const fullScreen = useFullScreen();
	const dashboardTasks = useDashboardTasks();

	return (
		<ViewContainer>
			<Box gap="medium">
				<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="small">
					<Box gap="small" flex>
						<Heading level={3} margin="none">
							Your Action Items
						</Heading>
						{dashboardTasks.length === 0 && (
							<Box align="center" justify="center" height="small">
								<Typography fontWeight="bold" fontSize="large">
									Nothing to do!
								</Typography>
							</Box>
						)}
						{dashboardTasks.length > 0 && (
							<Pagination
								pageSize={5}
							>
								{dashboardTasks.map(task => (
									<ActionItemListItem
										{...task}
									/>
								))}
							</Pagination>
						)}
					</Box>
					<UniversityAvailablePickupDatesContainer

					/>
				</Grid>
				<Box style={{ display: "block" }}>
					<ActiveOrderSummaryList
						userId={user?.id || ""}
						instanceId={instance?.id || ""}
					/>
					<Box height="20px" id="spacer">

					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};