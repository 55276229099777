import { DialogWithActivatorButton, VisuallyHiddenInput } from "../../../../components";
import { Anchor, Box, Image, Spinner } from "grommet";
import { Button, IconButton, InputAdornment, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Apartment, Discount, Edit, ScheduleSend } from "@mui/icons-material";
import moment, { Moment } from "moment-timezone";
import { useBuilding } from "../hooks";
import { LoadingButton } from "@mui/lab";
import { useToggleForceClose } from "../../components";
import { AutocompleteElement, CheckboxElement, DatePickerElement, FormContainer, SelectElement, TextFieldElement, TextareaAutosizeElement, useForm } from "react-hook-form-mui";
import { PickupLocation } from "../../../../graphql/__generated__/graphql";
import { LocalizationProvider } from "../../../../provider";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CreateOrderRequest, FindOrderRequests } from "../../../../graphql";
import { MediaLike } from "../../../../types";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { fileToBase64, keys } from "../../../../helpers";
import { useSnackbar } from "notistack";
import { useUser } from "../../../../auth";
import { FetchPromotionByCode, ValidatePromotion } from "../../../../graphql/documents/promotion";
import { useInstance } from "../../../../hooks";
import Fuse from "fuse.js";

interface ScheduleResidencePickupFormState {
	unit: string;
	roomNumber: string;
	addressSearch: string;
	isBulkPickup: boolean;
	location: PickupLocation;
	locationDetails: string;
	media: (MediaLike & { file: File; })[];
	productCount: number;
	fromLoadingDock: boolean;
	requestedDate: Moment | null;
	promotionCode: string;
	promotionCodeInput: string;
	requestedPickupId?: string;
}

export function usePromotion(code: string) {
	const { data, loading } = useQuery(FetchPromotionByCode, {
		skip: !code,
		variables: {
			code
		}
	});

	return {
		promotion: data?.FetchPromotionByCode || null,
		loading
	};
}

export function useValidatePromotion() {
	const snack = useSnackbar();

	const [
		execute,
		{ data, loading }
	] = useLazyQuery(ValidatePromotion);

	function validate(code: string) {
		execute({
			variables: {
				code
			}
		}).then(res => {
			if(res.data.ValidatePromotion) {
				snack.enqueueSnackbar("Promotion code applied", {
					variant: "success"
				});
			}
			else {
				snack.enqueueSnackbar("Invalid promotion code", {
					variant: "error"
				});
			}
		}).catch(err => {
			console.error("failed to validate promotion code", err);
			snack.enqueueSnackbar("Invalid promotion code", {
				variant: "error"
			});
		});
	}

	return {
		validate,
		valid: data?.ValidatePromotion || false,
		loading
	};
}

export const PromotionDetailsText: React.FC<{
	promotionCode: string;
}> = ({ promotionCode }) => {
	const { promotion } = usePromotion(promotionCode);

	if(!promotion) return <></>;

	return (
		<Typography>
			{promotionCode} | {promotion.description}
		</Typography>
	);
};

export const SelectedBuildingListItem: React.FC<{
	onEdit(): void;
	preventEdit?: boolean;
	building: NonNullable<ReturnType<typeof useBuilding>[ "building" ]>;
}> = ({ building, onEdit, preventEdit }) => {

	return (
		<List>
			<ListItemButton selected divider>
				<ListItemIcon>
					<Apartment color="primary" />
				</ListItemIcon>
				<ListItemText>
					<Typography fontWeight="bold">
						Building
					</Typography>
					<Typography variant="body2">
						{building.name}
					</Typography>
				</ListItemText>
			</ListItemButton>
			<ListItemSecondaryAction>
				{!preventEdit && (
					<IconButton
						onClick={onEdit}
					>
						<Edit color="warning" />
					</IconButton>
				)}
			</ListItemSecondaryAction>
		</List>
	);
};

export const SelectedUnitListItem: React.FC<{
	unitPrefix: string;
	unit: NonNullable<ReturnType<typeof useBuilding>[ "building" ]>[ "units" ][ number ];
	onEdit(): void;
}> = ({ unit, onEdit, unitPrefix }) => {
	return (
		<List>
			<ListItemButton selected divider>
				<ListItemIcon>
					<Apartment color="primary" />
				</ListItemIcon>
				<ListItemText>
					<Typography fontWeight="bold">
						{unitPrefix} {unit.unit}
					</Typography>
					<Typography variant="body2">
						{unit.bedrooms} bed | {unit.bathrooms} bath
					</Typography>
				</ListItemText>
			</ListItemButton>
			<ListItemSecondaryAction>
				<IconButton
					onClick={onEdit}
				>
					<Edit color="warning" />
				</IconButton>
			</ListItemSecondaryAction>
		</List>
	);
};

export const AdminSchedulePickupButton: React.FC<{
	flex?: boolean;
	buildingId?: string;
	location?: PickupLocation;
}> = ({ buildingId, location, flex }) => {
	const snack = useSnackbar();
	const { user } = useUser();
	const { instance } = useInstance();
	const [ isUploading, setIsUploading ] = useState(false);

	const buildings = useMemo(() => {
		return (instance?.partners || []).filter(p => p.__typename === "Building");
	}, [ instance ]);

	const [ buildingIdState, setBuildingIdState ] = useState<string>(buildingId || "");
	const { building, loading } = useBuilding(buildingIdState);
	const { forceClose, toggleForceClose } = useToggleForceClose();
	const formContext = useForm<ScheduleResidencePickupFormState>({
		defaultValues: {
			unit: "",
			addressSearch: "",
			location: location || PickupLocation.Default,
			locationDetails: "",
			requestedDate: null,
			fromLoadingDock: false,
			productCount: 0,
			media: [],
			promotionCode: "",
			promotionCodeInput: "",
			isBulkPickup: false,
			requestedPickupId: ""
		}
	});

	useEffect(() => {
		if(buildings.length === 1) {
			setBuildingIdState(buildings[ 0 ].id);
		}
	}, [ buildings, setBuildingIdState ]);

	const [ addressSearch, setAddressSearch ] = useState<string>("");
	const formValues = formContext.watch();

	useEffect(() => {
		if(formValues.isBulkPickup) {
			formContext.setValue("productCount", 1);
		}
	}, [ formValues.isBulkPickup ]);

	const unit = useMemo(() => {
		if(!building) return null;
		return building.units.find(unit => unit.unit === formValues.unit) || null;
	}, [ building, formValues.unit ]);

	useEffect(() => {
		const pickups = (building?.scheduledPickups || []);
		const pickupWithSameDate = pickups.find(pickup => moment(pickup.scheduledDate).isSame(formValues.requestedDate, "day"));
		if(pickupWithSameDate) {
			formContext.setValue("requestedPickupId", pickupWithSameDate.id);
		}
	}, [ building, formValues.requestedDate ]);

	const filteredBuildings = useMemo(() => {
		const fuse = new Fuse(buildings, {
			threshold: 0.3,
			includeMatches: true,
			includeScore: true,
			keys: [
				"name",
				"address.addressLineOne",
				"address.city",
				"address.state"
			]
		});

		if(!addressSearch || addressSearch.length < 3) return buildings;

		return fuse.search(addressSearch).map(result => result.item);
	}, [ buildings, addressSearch ]);

	const automcompleteOptions = useMemo(() => {
		return filteredBuildings.map(building => ({
			...building,
			label: building.name
		}));
	}, [ filteredBuildings ]);

	const { promotion, loading: isLoadingPromotion } = usePromotion(formValues.promotionCode);
	const { valid, loading: isValidatingPromotion, validate } = useValidatePromotion();

	useEffect(() => {
		if(valid) {
			formContext.setValue("promotionCode", formContext.getValues().promotionCodeInput);
		}
		else {
			formContext.setValue("promotionCode", "");
		}
	}, [ valid ]);

	const [
		create, { loading: createLoading }
	] = useMutation(CreateOrderRequest, {
		refetchQueries: [
			FindOrderRequests
		]
	});

	function handleSubmit() {
		if(!user) return;

		const {
			unit,
			roomNumber,
			isBulkPickup,
			media,
			productCount,
			requestedDate,
			location,
			promotionCode
		} = formContext.getValues();

		if(media.length === 0) {
			snack.enqueueSnackbar("Please upload at least one image", {
				variant: "error"
			});
			return;
		}

		create({
			variables: {
				isBulkPickup: isBulkPickup,
				unit: unit || "",
				room: roomNumber || "",
				productCount: productCount || 0,
				requestedDate,
				location,
				userId: user.id,
				refererId: buildingIdState || "",
				media: media.map(media => ({
					content: media.content,
					contentType: media.contentType,
					name: media.name
				})),
				promotionCode: promotionCode || undefined
			}
		}).then(() => {
			snack.enqueueSnackbar("Your request has been submitted", {
				variant: "success"
			});
			formContext.reset();
			toggleForceClose();
		}).catch(err => {
			console.error("failed to submit order request", err);
			snack.enqueueSnackbar("We ran into an issue submitting your request. Please try again later.", {
				variant: "error"
			});
		});
	}

	function handleUploadMedia(file: File) {
		setIsUploading(true);
		fileToBase64(file).then((base64) => {
			formContext.setValue("media", [
				...formContext.getValues().media,
				{
					file,
					content: base64,
					name: file.name,
					contentType: file.type
				}
			]);
		}).finally(() => {
			setIsUploading(false);
		});
	}

	const ref = createRef<HTMLInputElement>();

	return (
		<DialogWithActivatorButton
			forceClose={forceClose}
			title="Schedule Pickup"
			activatorButton={(
				<Button
					size="small"
					color="primary"
					variant="contained"
					fullWidth={flex}
				>
					Schedule Pickup
				</Button>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={toggleForceClose}
					>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						startIcon={<ScheduleSend />}
						loadingPosition="start"
						loading={loading || createLoading}
						disabled={loading || createLoading}
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Schedule
					</LoadingButton>
				</Box>
			)}
		>
			<LocalizationProvider>
				<FormContainer
					formContext={formContext}
				>
					<Box gap="medium">
						<Box gap="medium">
							{/* <Heading level="3" margin="none">
								Where are we picking up from?
							</Heading> */}
							{!buildingIdState && buildings.length > 0 && (
								<AutocompleteElement
									required
									name="addressSearch"
									label="Building / Address"
									options={automcompleteOptions}
									autocompleteProps={{
										onChange: (event, value) => {
											event.preventDefault();
											event.stopPropagation();

											if(value) {
												setBuildingIdState(value.id);
												formContext.setValue("unit", "");
											}
										}
									}}
									textFieldProps={{
										helperText: "Start typing to search for your building / property",
									}}
								/>
							)}
							<Box>
								{building && (
									<SelectedBuildingListItem
										building={building}
										onEdit={() => {
											setBuildingIdState("");
											formContext.setValue("unit", "");
										}}
										preventEdit={buildings.length <= 1}
									/>
								)}
								{unit && (
									<SelectedUnitListItem
										unit={unit}
										unitPrefix={building?.unitPrefix || ""}
										onEdit={() => {
											formContext.setValue("unit", "");
										}}
									/>
								)}
							</Box>
							{!location && (
								<PickupLocationElement
									unitPrefix={building?.unitPrefix || ""}
									location={formContext.watch("location")}
								/>
							)}
							{!unit && formValues.location === PickupLocation.Customer && building && building.units.length > 0 && (
								<AutocompleteElement
									name="unit"
									label="Unit"
									required
									options={building.units.map(u => u.unit)}
								/>
							)}
							{!unit && formValues.location === PickupLocation.Customer && building && building.units.length === 0 && (
								<TextFieldElement
									name="unit"
									label="Resident Unit"
									required
								/>
							)}
							{building?.roomDivided && formValues.location === PickupLocation.Customer && (
								<TextFieldElement
									name="roomNumber"
									label="Room Name / Number"
									required
								/>
							)}
						</Box>
						<Box gap="medium">
							<DatePickerElement
								label="Requested Date"
								name="requestedDate"
								required
								disablePast
								helperText="We will attempt to complete your pickup on or before this date."
								shouldDisableDate={(date) => {
									// return (moment().hours() >= 14)
									// 	? moment(date).isSameOrBefore(moment().add(1, "day").startOf("day"), "day")
									// 	: moment(date).isSameOrBefore(moment().startOf("day"), "day");
									return moment(date).isSameOrBefore(moment().startOf("day"), "day");
								}} />

							<TextFieldElement
								label="Total # of Items"
								name="productCount"
								type="number"
								required
								inputMode="numeric"
								validation={{
									min: {
										value: 1,
										message: "Must be at least 1"
									}
								}}
								disabled={formValues.isBulkPickup}
							/>
							{formValues.location === PickupLocation.Customer && (
								<Box>
									<CheckboxElement
										name="isBulkPickup"
										labelProps={{

											labelPlacement: "end"
										}}
										label="Whole room / unit pickup?"
										helperText="Please make note of any items present in the room / unit that are not to be picked up."
									/>
								</Box>
							)}
							<PickupInstructionsElement

							/>
							<Typography fontWeight="bold">
								Image(s)
							</Typography>
							{formContext.watch("media")?.map((media, index) => (
								<Box key={index} direction="row" gap="small" align="center">
									<PreviewMediaButton
										file={media.file}
									/>
									<Button
										size="small"
										color="error"
										variant="outlined"
										onClick={() => {
											formContext.setValue("media", formContext.getValues().media.filter((_, i) => i !== index));
										}}
									>
										Remove
									</Button>
								</Box>
							))}
							<LoadingButton
								onClick={() => {
									ref.current?.click();
								}}
								loading={isUploading}
								variant="outlined"
							>
								Upload Image
							</LoadingButton>
							<VisuallyHiddenInput
								ref={ref}
								type="file"
								accept="image/*"
								style={{ display: "none" }}
								onClick={(event) => {
									event.stopPropagation();
								}}
								onChange={(event) => {
									if(event.target.files) {
										for(const file of event.target.files) {
											handleUploadMedia(file);
										}
									}
								}}
							/>
							<Typography fontWeight="bold">
								Have a promo code?
							</Typography>
							<TextFieldElement
								name="promotionCodeInput"
								label="Promo Code"
								InputProps={{
									onBlur: () => {
										validate(formContext.getValues().promotionCodeInput);
									},
									endAdornment: (
										<Button
											color="primary"
											variant="contained"
											endIcon={isValidatingPromotion || isLoadingPromotion ? <Spinner /> : <Discount />}
											onClick={() => {
												validate(formContext.getValues().promotionCodeInput);
											}}
										>
											Apply
										</Button>
									)
								}}
								helperText={promotion && (
									<PromotionDetailsText promotionCode={promotion.code} />
								)}
							/>
						</Box>
					</Box>
				</FormContainer>
			</LocalizationProvider>
		</DialogWithActivatorButton>
	);
};

function resolvePickupLocationName(location: PickupLocation) {
	switch(location) {
		case PickupLocation.Default:
			return null;
		case PickupLocation.LoadingDock:
			return "Loading Dock / Other Building Location";
		case PickupLocation.Customer:
			return "Resident Unit";
	}
}

export const PickupLocationElement: React.FC<{
	unitPrefix: string;
	location: PickupLocation;
}> = ({ location, unitPrefix }) => {
	const options = keys(PickupLocation).map(key => ({
		label: resolvePickupLocationName(PickupLocation[ key ]),
		id: PickupLocation[ key ]
	})).filter(option => option.label !== null);

	return (
		<Box gap="medium">
			<SelectElement
				name="location"
				label="Pickup Location"
				options={options}
				validation={{
					validate: (value) => {
						if(value === PickupLocation.Default) {
							return "Please select an option";
						}
					}
				}}
			/>
		</Box>
	);
};

export const PickupInstructionsElement: React.FC = () => {
	return (
		<TextareaAutosizeElement
			name="pickupInstructions"
			label="Pickup Instructions"
			rows={3}
		/>
	);
};

export const LocationDetailsElement: React.FC<{
	location: PickupLocation;
	unitPrefix: string;
}> = ({ location, unitPrefix }) => {
	return (location === PickupLocation.Customer
		? (
			<TextFieldElement
				name="locationDetails"
				label={unitPrefix === "Room"
					? "Room #"
					: "Resident Unit"
				}
				required
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							{unitPrefix}
						</InputAdornment>
					)
				}}
			/>
		)
		: (
			<TextareaAutosizeElement
				name="locationDetails"
				label="Pickup Instructions"
				rows={3}
			/>
		)
	);
};

export const PreviewMediaButton: React.FC<{
	file: File;
}> = ({ file }) => {
	const src = URL.createObjectURL(file);

	return (
		<DialogWithActivatorButton
			title="Preview Image"
			activatorButton={(
				<Anchor
					target="_blank"
					label={file.name} />
			)}
		>
			<Box align="center" justify="center" flex>
				<Box width="medium" >
					<Image
						src={src}
						alt={file.name}
						fit="contain"
					/>
				</Box>
			</Box>
		</DialogWithActivatorButton>
	);
};