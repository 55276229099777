import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Ottoman: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="m1111.6 471.13-0.64453-0.53125 0.65234-0.53125c27.488-22.559 43.254-55.891 43.254-91.441 0-65.219-53.055-118.27-118.28-118.27l-436.59-0.003906h-436.59c-65.219 0-118.28 53.055-118.28 118.27 0 35.559 15.766 68.887 43.262 91.441l0.64453 0.53125-0.65234 0.53125c-27.488 22.551-43.254 55.883-43.254 91.441v133.18c0 55.434 38.379 101.94 89.918 114.7l-32.414 94.434c-2.7539 8.0156-1.4609 16.875 3.4648 23.781 4.9258 6.8984 12.887 10.996 21.367 10.996h76.875c8.6484 0 16.738-4.2617 21.637-11.391l78.426-114.23h591.19l78.426 114.23c4.8984 7.125 12.988 11.391 21.645 11.391h76.875c8.4844 0 16.434-4.0938 21.367-10.996 4.9258-6.9062 6.2188-15.766 3.4648-23.781l-32.414-94.434c51.539-12.758 89.918-59.266 89.918-114.7v-133.18c-0.007813-35.543-15.762-68.871-43.258-91.438zm-9.25-92.508c0 19.77-8.7656 38.301-24.051 50.852l-18.129 14.871h-240.77l0.003906-131.5h217.17c36.273 0 65.777 29.504 65.777 65.773zm-502.36 382.91h-181.93v-264.68h133.73l-7.7344 35.691c-3.6836 16.996 0.47266 34.508 11.387 48.039s27.156 21.301 44.543 21.301c17.387 0 33.621-7.7617 44.535-21.301 10.922-13.539 15.074-31.043 11.391-48.039l-7.7344-35.691h133.72v264.68zm9.8242-264.68 9.4492 43.629c1.2656 5.8594-0.15625 11.887-3.9219 16.559-3.7656 4.6719-9.3594 7.3438-15.352 7.3438s-11.586-2.6758-15.352-7.3438c-3.7656-4.6641-5.1992-10.703-3.9219-16.559l9.4492-43.629zm-9.8242-52.5h-181.93v-131.5h363.85v131.5zm-436.59-131.5h217.16v131.5h-240.76l-18.129-14.863c-15.293-12.547-24.059-31.078-24.059-50.859 0.007813-36.27 29.512-65.773 65.781-65.773zm-65.773 382.91v-133.19c0-19.777 8.7656-38.309 24.051-50.852l18.129-14.871h240.77v264.68h-217.17c-36.27 0-65.773-29.504-65.773-65.773zm92.887 191.4h-26.297l25.102-73.125h51.398zm818.95 0-50.203-73.125h51.398l25.102 73.125zm92.887-191.4c0 36.27-29.512 65.781-65.781 65.781h-217.17v-264.69h240.77l18.121 14.863c15.293 12.547 24.059 31.086 24.059 50.859z" />
</svg>
			</SvgIcon>
		);
	};