import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { useOrderRelationships } from "../../../hooks";

export const OrderRelationshipButton: React.FC<{
	label: string;
	orderId: string;
	disableClick?: boolean;
	resolvePath(relationships: ReturnType<typeof useOrderRelationships>): string | null;
}> = ({ orderId, resolvePath, label, disableClick }) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const relationships = useOrderRelationships(orderId);

	function handleClick(): void {
		const path = resolvePath(relationships);
		if(path) {
			dispatch(push(path));
		}
		else {
			snack.enqueueSnackbar("Could not find relationship", { variant: "error" });
		}
	}

	return (
		<LoadingButton
			size="small"
			loading={relationships.loading}
			color="primary"
			variant="contained"
			onClick={disableClick ? undefined : handleClick}
		>
			{label}
		</LoadingButton>
	);
};
