import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Table: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1160.6 371.84c-0.015625-1.5234-0.17188-3.0391-0.45703-4.5469-0.074219-0.40625-0.17969-0.78906-0.26953-1.1836-0.27734-1.1719-0.62891-2.3164-1.0742-3.4492-0.13672-0.34375-0.23828-0.69922-0.39062-1.043-0.51562-1.1836-1.1094-2.3555-1.8164-3.4727l-179.34-285c-4.8086-7.6367-13.191-12.27-22.215-12.27h-710.07c-9.0234 0-17.406 4.6367-22.215 12.27l-179.33 285c-0.70312 1.1172-1.2891 2.2812-1.8164 3.4727-0.14844 0.33594-0.25391 0.69141-0.39062 1.043-0.43359 1.1328-0.79688 2.2812-1.0742 3.4492-0.089844 0.39844-0.19531 0.78125-0.26953 1.1836-0.28516 1.5-0.44141 3.0156-0.45703 4.5469 0 0.097656-0.03125 0.19531-0.03125 0.29297v110.62c0 14.496 11.754 26.25 26.25 26.25h18.234v603.86c0 14.496 11.754 26.25 26.25 26.25h90.699c14.496 0 26.25-11.754 26.25-26.25v-603.85h30.555v419.45c0 14.496 11.754 26.25 26.25 26.25h90.699c14.496 0 26.25-11.754 26.25-26.25v-419.45h398.39v419.45c0 14.496 11.754 26.25 26.25 26.25h90.699c14.496 0 26.25-11.754 26.25-26.25l-0.003906-419.45h30.555v603.86c0 14.496 11.754 26.25 26.25 26.25h90.691c14.496 0 26.25-11.754 26.25-26.25l-0.003906-603.86h18.238c14.496 0 26.25-11.754 26.25-26.25v-110.62c-0.003906-0.10547-0.035156-0.19531-0.035156-0.30078zm-52.469 84.676h-1016.2v-58.125h507.38 1.5 507.37v58.125zm-848.67-343.12h681.08l146.3 232.5h-486.09-1.5-486.09zm-84.898 973.23h-38.199l0.003906-577.6h38.199zm173.75-184.4h-38.199v-393.2h38.199zm541.58 0h-38.199v-393.2h38.199zm173.75 184.4h-38.191v-577.6h38.191z"/>
</svg>
			</SvgIcon>
		);
	};