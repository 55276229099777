import { SvgIcon, SvgIconProps } from "@mui/material";

	export const BedAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1140 609.19h-13.312v-35.766c0-25.688-20.906-46.641-46.641-46.641h-13.922v-218.34c0-30.281-24.656-54.891-54.891-54.891h-822.47c-30.281 0-54.891 24.656-54.891 54.891v218.34h-13.922c-25.688 0-46.641 20.906-46.641 46.641v35.766h-13.312c-4.9688 0-9 4.0312-9 9v66.188c0 4.9688 4.0312 9 9 9h21.141v127.22c0 4.9688 4.0312 9 9 9h20.859v75.328c0 4.9688 4.0312 9 9 9h61.547c4.6875 0 8.5781-3.5625 8.9531-8.2031l6.6094-76.125h805.74l6.6094 76.125c0.42188 4.6406 4.3125 8.2031 8.9531 8.2031h61.547c4.9688 0 9-4.0312 9-9v-75.328h20.859c4.9688 0 9-4.0312 9-9v-127.22h21.141c4.9688 0 9-4.0312 9-9v-66.188c0-4.9688-4.0312-9-9-9zm-988.18-300.74c0-20.344 16.547-36.891 36.891-36.891h822.47c20.344 0 36.891 16.547 36.891 36.891v218.34h-85.031v-88.781c0-20.484-16.688-37.125-37.172-37.125h-261.52c-20.484 0-37.172 16.688-37.172 37.125v88.781h-54.609v-88.781c0-20.484-16.688-37.125-37.172-37.125h-261.52c-20.484 0-37.172 16.688-37.172 37.125v88.781h-85.031v-218.34zm793.26 218.34h-299.81v-88.781c0-10.547 8.5781-19.125 19.172-19.125h261.52c10.547 0 19.172 8.5781 19.172 19.125v88.781zm-390.42 0h-299.81v-88.781c0-10.547 8.5781-19.125 19.172-19.125h261.52c10.547 0 19.172 8.5781 19.172 19.125v88.781zm-463.36 46.641c0-15.797 12.844-28.641 28.641-28.641h960.14c15.797 0 28.641 12.844 28.641 28.641v35.766h-1017.4zm81.984 322.5h-44.297v-66.328h50.062zm897.71 0h-44.297l-5.7656-66.328h50.062zm29.859-84.328h-20.719s-0.09375-0.046874-0.14062-0.046874-0.09375 0.046874-0.14062 0.046874h-959.72s-0.09375-0.046874-0.14062-0.046874-0.09375 0.046874-0.14062 0.046874h-20.719v-118.22h1001.8v118.22zm30.141-136.22h-1062v-48.188h1062z"/>
</svg>
			</SvgIcon>
		);
	};