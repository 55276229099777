import { SvgIcon, SvgIconProps } from "@mui/material";

	export const CommercialRefrigerator: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m900 60h-600v1056h60v24h60v-24h360v24h60v-24h60zm-24 24v816h-552v-816zm-36 1008h-516v-168h552v168z"/>
  <path d="m444 456h360v24h-360z"/>
  <path d="m552 276h252v24h-252z"/>
  <path d="m444 636h360v24h-360z"/>
  <path d="m410.11 336.87 169.71-169.71 16.969 16.969-169.71 169.71z"/>
  <path d="m418.55 260.48 84.852-84.852 16.969 16.969-84.852 84.852z"/>
  <path d="m348 108v768h504v-768zm24 336h24v192h-24zm456 408h-456v-192h48v-240h-48v-288h456z"/>
  <path d="m360 948h180v24h-180z"/>
  <path d="m360 996h180v24h-180z"/>
  <path d="m360 1044h180v24h-180z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};