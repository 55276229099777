import React, { Fragment, useMemo } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Anchor, Box, CheckBox, Heading, Text } from "grommet";
import { useState } from "react";
import { Close, FileUpload, Save, Upload } from "@mui/icons-material";
import { CreateMediaDTO } from "./ItemDialogController";
import { useFullScreen } from "../../hooks";
import { ProductMediaContext } from "../../graphql/__generated__/graphql";
import { FormContainer, SelectElement, useForm } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { useDropzone } from "react-dropzone";
import { fileToBase64 } from "../../helpers";
import { ImagePreviewModal } from "./ImagePreviewModal";
import { useMediaContext } from "../../hooks";

interface MediaType {
	id: string;
	name: string;
	contentUrl: string,
	context: ProductMediaContext;
}

interface ProductImageUploadFormElementProps {
	isUploading: boolean;
	fullImageEnabled: boolean;
	media: MediaType[];
	setMedia(dto: CreateMediaDTO): Promise<boolean>;
}

export const ProductImageUploadFormElement: React.FC<ProductImageUploadFormElementProps> = (props) => {
	const [ isUploading, setIsUploading ] = useState<ProductMediaContext | null>(null);

	const [ hasTag, setHasTag ] = useState(false);
	const [ hasDefect, setHasDefect ] = useState(false);
	const [ previewMedia, setPreviewMedia ] = useState<MediaType | null>(null);

	function handleUploadMedia(context: ProductMediaContext, file: File) {
		setIsUploading(context);

		fileToBase64(file).then((base64Content) => {
			return props.setMedia({
				file,
				name: file.name,
				contentType: file.type,
				base64Content,
				context
			});
		}).finally(() => {
			setIsUploading(null);
		});
	}

	return (
		<Box margin="small" gap="medium">
			{previewMedia && (
				<ImagePreviewModal
					onClose={() => setPreviewMedia(null)}
					media={previewMedia}
				/>
			)}
			<Typography fontSize="large" fontWeight="bold">
				Item Images
			</Typography>
			{!props.fullImageEnabled && (
				<ProductMediaContextListItem
					media={props.media}
					context={ProductMediaContext.Default}
					isUploading={!!isUploading}
					setMedia={(file) => handleUploadMedia(ProductMediaContext.Default, file)}
					onSelect={(media) => setPreviewMedia(media)}
				/>
			)}
			{props.fullImageEnabled && (
				<Fragment>
					<ProductMediaContextListItem
						media={props.media}
						context={ProductMediaContext.Front}
						isUploading={isUploading === ProductMediaContext.Front}
						setMedia={(file) => handleUploadMedia(ProductMediaContext.Front, file)}
						onSelect={(media) => setPreviewMedia(media)}
					/>
					<ProductMediaContextListItem
						media={props.media}
						context={ProductMediaContext.Back}
						isUploading={isUploading === ProductMediaContext.Back}
						setMedia={(file) => handleUploadMedia(ProductMediaContext.Back, file)}
						onSelect={(media) => setPreviewMedia(media)}
					/>
					<ProductMediaContextListItem
						media={props.media}
						context={ProductMediaContext.LeftSide}
						isUploading={isUploading === ProductMediaContext.LeftSide}
						setMedia={(file) => handleUploadMedia(ProductMediaContext.LeftSide, file)}
						onSelect={(media) => setPreviewMedia(media)}
					/>
					<ProductMediaContextListItem
						media={props.media}
						context={ProductMediaContext.RightSide}
						isUploading={isUploading === ProductMediaContext.RightSide}
						setMedia={(file) => handleUploadMedia(ProductMediaContext.RightSide, file)}
						onSelect={(media) => setPreviewMedia(media)}
					/>
				</Fragment>
			)}
			<Box gap="small" pad="small">
				<CheckBox
					label={<Text weight="bold">Do you have access to the tag (or label)?</Text>}
					checked={hasTag}
					onChange={(event) => setHasTag(event.target.checked)}
				/>
				<CheckBox
					label={<Text weight="bold">Are there any damages (stains, rips, etc)?</Text>}
					checked={hasDefect}
					onChange={(event) => setHasDefect(event.target.checked)}
				/>
			</Box>
			{hasTag && (
				<ProductMediaContextListItem
					media={props.media}
					context={ProductMediaContext.Tag}
					isUploading={isUploading === ProductMediaContext.Tag}
					setMedia={(file) => handleUploadMedia(ProductMediaContext.Tag, file)}
					onSelect={(media) => setPreviewMedia(media)}
				/>
			)}
			{hasDefect && (
				<ProductMediaContextListItem
					media={props.media}
					context={ProductMediaContext.Defects}
					isUploading={isUploading === ProductMediaContext.Defects}
					setMedia={(file) => handleUploadMedia(ProductMediaContext.Defects, file)}
					onSelect={(media) => setPreviewMedia(media)}
				/>
			)}
		</Box>
	);
};

interface ProductMediaContextListItemProps {
	context: ProductMediaContext;
	media: MediaType[];
	setMedia(file: File): void;
	isUploading: boolean;
	onSelect(media: MediaType): void;
}

export const ProductMediaContextListItem: React.FC<ProductMediaContextListItemProps> = (props) => {
	const contexts = useMediaContext();
	const ref = React.createRef<HTMLInputElement>();

	const media = useMemo(() => {
		return props.media.filter(m => m.context === props.context);
	}, [ props.media, props.context ]);

	const label = useMemo(() => {
		return contexts.find(c => c.id === props.context)?.label;
	}, [ props.context, contexts ]);

	return (
		<ListItemButton
			divider
		>
			<ListItemText>
				<Typography fontWeight="bold">{label}</Typography>
				{media
					? (
						<Box>
							{media.map(media => (
								<Anchor
									key={media.id}
									onClick={() => props.onSelect(media)}
									label={media.name}
									size="small"
								/>
							))}
						</Box>
					)
					: (
						<Typography fontWeight="bold" variant="caption" color="error">Not Uploaded</Typography>
					)}
			</ListItemText>
			<ListItemSecondaryAction>
				<LoadingButton
					sx={{ zIndex: 2 }}
					loading={props.isUploading}
					color="primary"
					variant="outlined"
					onClick={(event) => {
						event.stopPropagation();
						ref.current?.click();
					}}
					endIcon={<FileUpload />}
					loadingPosition="end"
				>
					Upload
				</LoadingButton>
				<input
					type="file"
					ref={ref}
					accept="image/*"
					style={{ display: "none" }}
					onClick={(event) => {
						event.stopPropagation();
					}}
					onChange={(event) => {
						if(event.target.files) {
							props.setMedia(event.target.files[ 0 ]);
						}
					}}
				/>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

interface ImageUploadDialogProps {
	onClose(): void;
	context?: ProductMediaContext;
}

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out"
};

const focusedStyle = {
	borderColor: "#2196f3"
};

const acceptStyle = {
	borderColor: "#00e676"
};

const rejectStyle = {
	borderColor: "#ff1744"
};

export const ImageUploadDialog: React.FC<ImageUploadDialogProps> = (props) => {
	const fullScreen = useFullScreen();

	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
		isDragActive
	} = useDropzone({
		multiple: false,
		accept: { "image/*": [] }
	});

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);

	const useFormReturn = useForm({
		defaultValues: {
			context: props.context
		}
	});

	const formValues = useFormReturn.watch();

	function handleUploadImage() {
		//
	}

	const options = useMemo(() => {
		return [
			{
				label: "Back",
				id: ProductMediaContext.Back
			},
			{
				label: "Front",
				id: ProductMediaContext.Front
			},
			{
				label: "Left Side",
				id: ProductMediaContext.LeftSide
			},
			{
				label: "Right Side",
				id: ProductMediaContext.RightSide
			},
			{
				label: "Defects / Damages",
				id: ProductMediaContext.Defects
			},
			{
				label: "Tag",
				id: ProductMediaContext.Tag
			}
		];
	}, []);

	return (
		<Dialog
			open={true}
			fullWidth
			fullScreen={fullScreen}
		>
			<FormContainer formContext={useFormReturn} onSuccess={(data) => handleUploadImage()}>
				<DialogTitle>
					<Box direction="row" justify="between">
						<Box justify="center">
							<Text color="brand" weight="bold" size="large">Upload Image</Text>
						</Box>
						<IconButton onClick={props.onClose}>
							<Close />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<Box gap="small">
						<Box
							{...getRootProps()}
							border={{ color: "brand", side: "all", size: "small" }}
							round
							style={{
								minHeight: "200px"
							}}
						>
							<input name="mediaFiles" {...getInputProps()} style={{ ...getInputProps().style, border: undefined }} />
							<Box fill>
								<Box align="center">
									{
										isDragActive
											? (
												<Box align="center">
													<Heading level="5">
														Drop the files here ...
													</Heading>
												</Box>
											)
											: (
												<Box align="center" margin="small">
													<Heading level="5">
														Drag 'n' drop some files here, or click to select files
													</Heading>
												</Box>
											)
									}
								</Box>
								<Box align="center" justify="center" margin={{ top: "large" }}>
									<Box>
										<Upload />
									</Box>
								</Box>
							</Box>
						</Box>
						<SelectElement
							name="context"
							label="Image Type"
							options={options}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Box align="end" margin="small">
						<LoadingButton
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<Save />}
							loadingPosition="end"
							loading={true}
						>
							Save
						</LoadingButton>
					</Box>
				</DialogActions>
			</FormContainer>
		</Dialog >
	);
};
