import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FetchOrderRelationships } from "../../../graphql";

export function useOrderRelationships(orderId: string) {
	const snack = useSnackbar();

	const [
		fetchOrder, { data, loading }
	] = useLazyQuery(FetchOrderRelationships);

	useEffect(() => {
		if(orderId) {
			fetchOrder({
				variables: { orderId }
			}).catch(err => {
				console.error("Failed to fetch order relationships", err);
				snack.enqueueSnackbar("Failed to fetch order relationships", { variant: "error" });
			});
		}
	}, [ orderId ]);

	return {
		loading,
		orderId,
		userId: data?.FetchOrder?.user?.id ?? null,
		refererId: data?.FetchOrder?.referer?.id ?? null,
		instanceId: data?.FetchOrder?.instance?.id ?? null,
	};
}
