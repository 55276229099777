import { Anchor, Box, Heading, Text } from "grommet";
import React, { useMemo } from "react";
import { Button, ButtonGroup, List, Typography } from "@mui/material";
import { formatNumber } from "../../../helpers";
import { selectCalculatorState, setCalculatorState } from "../../../store/calculator";
import { useAppDispatch, useAppSelector } from "../../../store";
import { tips } from "../static";
import { WasteReductionTip } from ".";
import { HtmlTooltip } from "../../../components/HtmlTooltip";
import { useWasteScenario } from "../hooks";


export const WasteFootprintResults: React.FC = () => {
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectCalculatorState);
	const scenario = useWasteScenario();
	const { period } = state;

	const sortedTips = useMemo(() => {
		return Array.from(tips).sort((a, b) => {
			return b.amount(scenario) - a.amount(scenario);
		});
	}, [ scenario ]);

	return (
		<Box>
			<Box gap="medium">
				<Box gap="small" align="center" pad="small">
					<Box>
						<ButtonGroup>
							<Button
								variant={state.period === "daily" ? "contained" : "outlined"}
								onClick={() => {
									dispatch(setCalculatorState({
										period: "daily"
									}));
								}}
							>
								Daily
							</Button>
							<Button
								variant={state.period === "weekly" ? "contained" : "outlined"}
								onClick={() => {
									dispatch(setCalculatorState({
										period: "weekly"
									}));
								}}
							>
								Weekly
							</Button>
							<Button
								variant={state.period === "monthly" ? "contained" : "outlined"}
								onClick={() => {
									dispatch(setCalculatorState({
										period: "monthly"
									}));
								}}
							>
								Monthly
							</Button>
							<Button
								variant={state.period === "yearly" ? "contained" : "outlined"}
								onClick={() => {
									dispatch(setCalculatorState({
										period: "yearly"
									}));
								}}
							>
								Annual
							</Button>
						</ButtonGroup>
					</Box>
					<Heading
						level="3"
						color="black"
						margin="none"
					>
						{period === "daily"
							? "Your estimated daily waste footprint"
							: period === "weekly"
								? "Your estimated weekly waste footprint"
								: period === "monthly"
									? "Your estimated monthly waste footprint"
									: "Your estimated annual waste footprint"
						}
					</Heading>
					<Box align="center">
						<Heading level="2" margin="none">
							{formatNumber(scenario.totalWastePounds, 2)} lb(s)
						</Heading>
						<HtmlTooltip
							title={<React.Fragment>
								<Text>
									The average American generates <Text weight="bold">4.9 lbs</Text> of waste per day. This number is used as a baseline waste footprint, and then adjusted based on your answers.
								</Text>
							</React.Fragment>}
						>
							<Anchor href="#" color="accent-1">
								How is this calculated?
							</Anchor>
						</HtmlTooltip>
					</Box>
				</Box>
				<Box gap="medium">
					<Box align="center">
						<Heading level="3" margin="none">
							Waste Reduction Tips
						</Heading>
					</Box>
					<Box>
						{sortedTips.map((tip) => (
							<WasteReductionTip
								key={tip.name}
								{...tip} />
						))}
					</Box>
				</Box>
				<Box margin={{ top: "none", bottom: "small", horizontal: "small" }}>
					<Typography
						color="primary"
						fontSize="large"
						fontWeight="bold"
					>
						Your {state.period} footprint of {formatNumber(scenario.totalWastePounds, 2)} lb(s) is:
					</Typography>
					<List>
						{comparisons.map((comparison) => (
							<WasteComparison
								key={comparison.subject}
								{...comparison}
							/>
						))}
					</List>
				</Box>
			</Box>
		</Box>
	);
};

import tire from "../../../img/tire.png";
import brightFarms from "../../../img/bright_farms.png";
import { WasteComparison, WasteComparisonProps } from "./WasteComparison";

const comparisons: WasteComparisonProps[] = [
	{
		image: "https://webcdn.cox.com/ui/presentation/tsw/img/cox_logo.png",
		subject: "feet of coaxial cable",
		unitLabel: "0.8 oz(s)",
		unitSubject: "per foot",
		poundsPerUnit: .05
	},
	{
		image: tire,
		subject: "passenger car tires",
		unitLabel: "20 lb(s)",
		unitSubject: "each",
		poundsPerUnit: 20
	},
	{
		image: brightFarms,
		subject: "Bright Farms Spring Mix containers",
		unitLabel: "4 oz(s)",
		unitSubject: "each",
		poundsPerUnit: 4 / 16
	}
];