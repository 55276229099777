import React, { useMemo } from "react";
import { Box, Carousel, Heading, Image } from "grommet";
import { IconButton, List } from "@mui/material";
import { BasicListItem, DialogWithActivatorButton } from "..";
import { useClipboard } from "../../hooks/useClipboard";
import { CopyAll } from "@mui/icons-material";
import { useLazyAddressWithStopDetails } from "../../hooks";

export const AddressStopDetailsDialog: React.FC<{
	addressId: string;
	outletName?: string;
	activatorButton: React.ReactNode;
}> = ({ addressId, activatorButton, outletName }) => {
	const { copy } = useClipboard();
	const { address } = useLazyAddressWithStopDetails(addressId);

	const addressFormatted = useMemo(() => {
		return [
			address?.addressLineOne,
			[ address?.city, address?.state ].join(", "),
			address?.zipCode
		].join(" ");
	}, [ address ]);

	return (
		<DialogWithActivatorButton
			title="Stop Details"
			activatorButton={activatorButton}
		>
			<Box gap="medium">
				<List>
					{outletName && (
						<BasicListItem
							label="Destination Name"
							loading={false}
							value={outletName} />
					)}
					<BasicListItem
						label="Address"
						loading={false}
						allowMultiLine
						value={addressFormatted}
						action={<IconButton
							color="primary"
							onClick={() => copy(addressFormatted)}
						>
							<CopyAll />
						</IconButton>} />
					{!!address?.instructions && (
						<BasicListItem
							label="Instructions"
							loading={false}
							allowMultiLine
							value={address?.instructions || "Not Provided"}
						/>
					)}
					{!!address?.instructionsParking && (
						<BasicListItem
							allowMultiLine
							label="Parking Instructions"
							loading={false}
							value={address?.instructionsParking || "Not Provided"}
						/>
					)}
				</List>
				{(address?.instructionsMedia?.length || 0) > 0 && (
					<Box gap="medium">
						<Heading level={3} margin="none">
							Loading Dock Images
						</Heading>
						<Carousel
							fill
							controls="arrows"
						>
							{address?.instructionsMedia?.map((media) => (
								<Box height="medium" key={media.id}>
									<Image
										fit="contain"
										src={media.contentUrl} />
								</Box>
							))}
						</Carousel>
					</Box>
				)}
			</Box>
		</DialogWithActivatorButton>
	);
};
