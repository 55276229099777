import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Shelving: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1013.3 78.52h102.71c13.254 0 24.008 10.758 24.008 24.008v994.95c0 13.254-10.758 24.008-24.008 24.008h-102.71c-13.254 0-24.008-10.758-24.008-24.008v-46.105h-778.55v46.105c0 13.254-10.758 24.008-24.008 24.008h-102.71c-13.254 0-24.008-10.758-24.008-24.008v-994.95c0-13.254 10.758-24.008 24.008-24.008h102.71c13.254 0 24.008 10.758 24.008 24.008v118.09h778.55v-118.09c0-13.254 10.758-24.008 24.008-24.008zm-24.008 924.84v-27.172h-778.55v27.172zm0-75.191v-230.57h-778.55v230.57zm0-278.59v-27.156h-778.55v27.156zm0-75.176v-230.57h-778.55v230.57zm0-278.59v-27.172h-778.55v27.172zm102.71-169.27h-54.695v946.93h54.695zm-929.29 0h-54.695v946.93h54.695z"/>
</svg>
			</SvgIcon>
		);
	};