import React, { useMemo } from "react";
import { BasicListItem } from "../../../../../components";
import { useAdminScopedOrder } from "../../../../../graphql";
import { formatCurrency } from "../../../../../helpers";

export const OrderEstimateAmountListItem: React.FC<{
	orderId: string;
}> = ({ orderId }) => {
	const { order, loading } = useAdminScopedOrder(orderId);
	const estimate = useMemo(() => order?.lastEstimate, [ order ]);

	return (
		<BasicListItem
			label="Estimate Amount"
			loading={loading}
			value={estimate?.totalAmount !== undefined ? formatCurrency(estimate.totalAmount) : "Not Scheduled"}
		/>
	);
};