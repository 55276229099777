import { SvgIcon, SvgIconProps } from "@mui/material";

	export const SofaThreeSeat: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m311.04 481c12.586 0 22.816-10.23 22.816-22.809s-10.23-22.816-22.816-22.816c-12.578 0-22.809 10.23-22.809 22.816 0.003906 12.578 10.23 22.809 22.809 22.809z"/>
  <path d="m598.27 481c12.586 0 22.816-10.23 22.816-22.809s-10.23-22.816-22.816-22.816c-12.578 0-22.809 10.23-22.809 22.816 0.003907 12.578 10.234 22.809 22.809 22.809z"/>
  <path d="m885.5 481c12.586 0 22.816-10.23 22.816-22.809s-10.23-22.816-22.816-22.816c-12.578 0-22.809 10.23-22.809 22.816 0 12.578 10.23 22.809 22.809 22.809z"/>
  <path d="m1092.5 506.54h-28.695v-141.19c0-55.395-45.066-100.46-100.46-100.46h-148.88c-29.574 0-56.121 12.922-74.52 33.328-18.398-20.406-44.949-33.328-74.52-33.328h-132.52c-29.152 0-55.371 12.562-73.738 32.477-18.367-19.906-44.586-32.477-73.738-32.477h-148.88c-55.395 0-100.45 45.066-100.45 100.46v141.19h-28.703c-38.672 0-70.133 31.453-70.133 70.125v288.32c0 38.672 31.461 70.133 70.133 70.133h985.1c38.672 0 70.133-31.453 70.133-70.133l-0.007813-288.32c0.007813-38.672-31.445-70.125-70.125-70.125zm-278.02-189.15h148.88c26.445 0 47.961 21.516 47.961 47.961v142.68c-31.785 6.6602-55.738 34.891-55.738 68.625v55.727h-128c-22.484 0-43.402 6.6523-61.066 17.969v-285c0-26.445 21.52-47.961 47.965-47.961zm141.1 474h-189.05v-45.449c0-33.66 27.391-61.059 61.066-61.059h127.99zm-241.56-45.453v45.449l-228.12 0.003907v-45.449c0-33.66 27.391-61.059 61.059-61.059h106.02c33.652 0 61.039 27.395 61.039 61.055zm-280.62 0v45.449l-189.06 0.003907v-106.5h128c33.668-0.007813 61.066 27.387 61.066 61.047zm-189.06 82.953h189.05v53.73l-189.05-0.003907zm241.56 0h228.13v53.73l-228.13-0.003907zm280.62 0h189.05v53.73h-189.05zm-233.6-511.5h132.52c26.438 0 47.953 21.516 47.953 47.961v284.62c-17.52-11.07-38.203-17.578-60.426-17.578h-106.02c-22.477 0-43.402 6.6523-61.059 17.961v-285c0-2.3711-0.19531-4.6953-0.35156-7.0273 3.4219-23.117 23.336-40.934 47.379-40.934zm-296.36 0h148.88c24.051 0 43.965 17.812 47.387 40.934-0.16406 2.332-0.35156 4.6562-0.35156 7.0273v284.44c-17.461-10.957-38.039-17.398-60.137-17.398h-128v-55.727c0-33.734-23.949-61.965-55.734-68.625l0.003907-142.69c0.007812-26.445 21.516-47.961 47.953-47.961zm-146.78 547.6v-288.32c0-9.7188 7.9141-17.625 17.633-17.625h66.801c9.7188 0 17.633 7.9062 17.633 17.625v305.96l-84.434-0.003907c-9.7188 0-17.633-7.9102-17.633-17.629zm1020.4 0c0 9.7188-7.9141 17.633-17.633 17.633h-84.434v-305.96c0-9.7188 7.9141-17.625 17.633-17.625h66.801c9.7188 0 17.633 7.9062 17.633 17.625z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};