import { gql } from "@apollo/client";


export const User_ContactsFragment = gql`
	fragment User_ContactsFragment on User {
		...User_PrimaryContactFragment
		contacts {
			...Contact_HeaderFragment
		}
	}
`;
