import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Other: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1044 96c15.914 0 31.176 6.3203 42.426 17.574 11.254 11.25 17.574 26.512 17.574 42.426v888c0 15.914-6.3203 31.176-17.574 42.426-11.25 11.254-26.512 17.574-42.426 17.574h-888c-15.914 0-31.176-6.3203-42.426-17.574-11.254-11.25-17.574-26.512-17.574-42.426v-888c0-15.914 6.3203-31.176 17.574-42.426 11.25-11.254 26.512-17.574 42.426-17.574h888m0-36h-888c-25.461 0-49.879 10.113-67.883 28.117-18.004 18.004-28.117 42.422-28.117 67.883v888c0 25.461 10.113 49.879 28.117 67.883 18.004 18.004 42.422 28.117 67.883 28.117h888c25.461 0 49.879-10.113 67.883-28.117 18.004-18.004 28.117-42.422 28.117-67.883v-888c0-25.461-10.113-49.879-28.117-67.883-18.004-18.004-42.422-28.117-67.883-28.117z"/>
  <path d="m553.44 722.16v-49.199c0-76.078 4.4414-86.879 61.801-145.92 45.719-46.559 58.199-68.039 58.199-100.32 0-42.121-27.719-67.199-74.281-67.199-52.801 0-80.641 26.879-85.922 85.922h-55.68c4.5625-94.922 55.562-145.44 146.88-145.44 85.078 0 137.88 48 137.88 126.24 0 48-15.238 74.281-76.078 134.28-48 48-51 56.398-51 129.84v31.32zm81.48 127.2c-0.12891 13.652-5.6758 26.695-15.418 36.258-9.7461 9.5664-22.891 14.867-36.543 14.738-13.652-0.12891-26.695-5.6719-36.258-15.418-9.5625-9.7422-14.867-22.887-14.738-36.539 0.125-13.652 5.6719-26.695 15.414-36.258 9.7461-9.5664 22.887-14.867 36.543-14.742 13.621 0.22266 26.609 5.7969 36.156 15.523 9.543 9.7227 14.875 22.812 14.844 36.438z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};