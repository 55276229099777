import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Furnace: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m417.34 150h365.33c12.941 0 24.699 5.2891 33.219 13.805 8.5156 8.5195 13.805 20.277 13.805 33.219v805.96c0 12.941-5.2891 24.699-13.805 33.219-8.5195 8.5156-20.277 13.805-33.219 13.805h-365.33c-12.941 0-24.699-5.2891-33.219-13.805-8.5156-8.5195-13.805-20.277-13.805-33.219v-805.96c0-12.941 5.2891-24.699 13.805-33.219 8.5195-8.5156 20.277-13.805 33.219-13.805zm365.33 18.75h-365.33c-7.7656 0-14.832 3.1836-19.961 8.3125s-8.3125 12.195-8.3125 19.961v805.96c0 7.7656 3.1836 14.832 8.3125 19.961s12.195 8.3125 19.961 8.3125h365.33c7.7656 0 14.832-3.1836 19.961-8.3125s8.3125-12.195 8.3125-19.961v-805.96c0-7.7656-3.1836-14.832-8.3125-19.961s-12.195-8.3125-19.961-8.3125z"/>
  <path d="m820.31 570.62h-440.62v-18.75h440.62z"/>
  <path d="m426.86 245.12h37.598v18.75h-37.598z"/>
  <path d="m488.6 245.12h37.602v18.75h-37.602z"/>
  <path d="m550.33 245.12h37.602v18.75h-37.602z"/>
  <path d="m612.07 245.12h37.602v18.75h-37.602z"/>
  <path d="m673.8 245.12h37.602v18.75h-37.602z"/>
  <path d="m735.54 245.12h37.598v18.75h-37.598z"/>
  <path d="m426.86 321.75h37.598v18.75h-37.598z"/>
  <path d="m488.6 321.75h37.602v18.75h-37.602z"/>
  <path d="m550.33 321.75h37.602v18.75h-37.602z"/>
  <path d="m612.07 321.75h37.602v18.75h-37.602z"/>
  <path d="m673.8 321.75h37.602v18.75h-37.602z"/>
  <path d="m735.54 321.75h37.598v18.75h-37.598z"/>
  <path d="m426.86 398.38h37.598v18.754h-37.598z"/>
  <path d="m488.6 398.38h37.602v18.754h-37.602z"/>
  <path d="m550.33 398.38h37.602v18.754h-37.602z"/>
  <path d="m612.07 398.38h37.602v18.754h-37.602z"/>
  <path d="m673.8 398.38h37.602v18.754h-37.602z"/>
  <path d="m735.54 398.38h37.598v18.754h-37.598z"/>
  <path d="m426.86 475.01h37.598v18.75h-37.598z"/>
  <path d="m488.6 475.01h37.602v18.75h-37.602z"/>
  <path d="m550.33 475.01h37.602v18.75h-37.602z"/>
  <path d="m612.07 475.01h37.602v18.75h-37.602z"/>
  <path d="m673.8 475.01h37.602v18.75h-37.602z"/>
  <path d="m735.54 475.01h37.598v18.75h-37.598z"/>
  <path d="m598.94 718.37c1.7734 0.96875 5.793 5.3711 7.2109 7.0625 2.8398 3.3906 6.707 8.2461 11.082 14.098 22.625 30.266 60.926 89.773 56.215 129.47-3.6133 30.438-26.461 48.984-53.184 55.203-11.688 2.7227-24.133 3.0586-35.957 0.96094-12.02-2.1289-23.492-6.7695-33.035-13.965-42.789-32.273-25.824-81.566 2.9141-116.05 14.875-17.844 31.621-37.934 31.023-65.172-0.019531-0.91406-0.074219-1.8281-0.13672-2.7383-0.46875-7.4062 7.25-12.484 13.867-8.875zm3.2773 32.367-0.34766-0.46484c-5.3281 23.23-19.98 40.816-33.324 56.824-21.219 25.453-39.363 64-5.9922 89.168 7.0859 5.3438 15.77 8.8203 24.977 10.449 9.4023 1.6641 19.258 1.4062 28.488-0.74219 19.586-4.5586 36.285-17.75 38.828-39.164 3.9688-33.406-31.637-87.992-52.625-116.07z"/>
  <path d="m588.21 811.06c-0.45312-9.0703 11.23-13.582 16.844-6.0898 0.75 1 1.6133 2.1758 2.2891 3.2031 1.2852 1.9492 3.0234 4.7188 4.9766 8.0391 10.156 17.25 27.363 51.273 25.215 74.25-1.6992 18.184-12.516 29.648-25.457 34.059-5.7734 1.9688-11.992 2.4844-18.023 1.5234-6.082-0.97266-11.957-3.4414-16.977-7.4297-11.191-8.8906-18.129-25.055-13.238-48.445 1.7734-8.4766 6.3438-15.445 11.312-23.016 6.4844-9.8828 13.812-21.051 13.055-36.086zm30.723 77.715c1.3281-14.227-8.0703-35.953-16.527-51.988-3.1562 7.7773-7.5 14.402-11.578 20.613-3.9844 6.0703-7.6484 11.656-8.6758 16.574-3.1953 15.277 0.42187 25.102 6.5742 29.988 2.4492 1.9453 5.3086 3.1484 8.2617 3.6211 3.0078 0.48047 6.1484 0.21094 9.0859-0.78906 6.4883-2.2109 11.945-8.25 12.855-18.02z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};