import React, { useMemo, useState } from "react";
import { useBuilding } from "../../hooks";
import { useForm } from "react-hook-form";
import { DialogWithClose, Pagination, TabController } from "../../../../../components";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Avatar, Button, Checkbox, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Box, Spinner } from "grommet";
import { PropertyDetailsTab } from "./tabs/PropertyDetailsTab";
import { WasteContainersTab } from "./tabs";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";
import { formatPhoneNumber, isNotNull } from "../../../../../helpers";
import { usePartnerRoles } from "../../../hooks";
import { PartnerRoleGuard } from "../../../../../guards";
import { useInstance, usePartnerUsers, useWindowDimensions } from "../../../../../hooks";
import { Edit, Person } from "@mui/icons-material";
import Fuse from "fuse.js";
import { resolvePermissionName } from "./helpers";
import { ResultOf } from "@graphql-typed-document-node/core";
import { FetchPartnerUsers } from "../../../../../graphql";
import { useMutation } from "@apollo/client";
import { UpdateUserRole } from "../../../../../graphql";
import { PartnerRole } from "../../../../../graphql/__generated__/graphql";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { UsernameElement } from "../../../../auth/components";
import { InvitePartnerUser } from "../../../../../graphql/documents/partner/mutations/InvitePartnerUser";
import { useUser } from "../../../../../auth";

export interface PropertyDetailsFormContext {
	id: string;
	name: string;
	address: string;
}

export const PropertyDetailsDialog: React.FC<{
	buildingId: string;
	onClose: () => void;
}> = ({ buildingId, onClose }) => {
	const dispatch = useAppDispatch();
	const { building } = useBuilding(buildingId);

	const { roles } = usePartnerRoles(buildingId);

	const canViewPermissions = useMemo(() => {
		return roles.isGlobalAdmin || roles.isInstanceAdmin || roles.isPartnerAdmin;
	}, [ roles ]);

	const formContext = useForm<PropertyDetailsFormContext>({
		defaultValues: {
			id: buildingId,
			name: building?.name || "",
			address: building?.address.addressLineOne || "",
		}
	});

	function handleViewMore() {
		dispatch(push(`/admin/locations/${buildingId}`));
	}

	const isDemoEnabled = !!localStorage.getItem("ENABLE_DEMO");

	return (
		<DialogWithClose
			onClose={onClose}
			title="Location Details"
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
					{isDemoEnabled && (
						<Button
							color="primary"
							variant="contained"
							onClick={handleViewMore}
						>
							View More
						</Button>
					)}
				</Box>
			)}
			content={(
				<FormContainer
					formContext={formContext}
					FormProps={{
						style: { height: "100%" }
					}}
				>
					<Box
						style={{
							minHeight: "100%"
						}}
					>
						<TabController
							defaultValue="details"
							tabs={[
								{
									value: "details",
									label: "Details",
									component: (
										<PropertyDetailsTab
											buildingId={buildingId}
											formContext={formContext}
										/>
									)
								},
								{
									value: "containers",
									label: "Containers",
									component: (
										<WasteContainersTab
											buildingId={buildingId}
											formContext={formContext}
										/>
									)
								},
								canViewPermissions
									? {
										value: "users",
										label: "Users",
										component: (
											<PartnerRoleGuard
												partnerId={buildingId}
												resolver={(result) => result.isGlobalAdmin || result.isInstanceAdmin || result.isPartnerAdmin}
											>
												<PartnerUsersTab
													buildingId={buildingId}
												/>
											</PartnerRoleGuard>
										)
									}
									: null
							].filter(isNotNull)}
						/>
					</Box>
				</FormContainer>
			)}
		/>
	);
};

export const PartnerUsersTab: React.FC<{
	buildingId: string;
}> = ({ buildingId }) => {
	const { users } = usePartnerUsers(buildingId);

	const formContext = useForm({
		defaultValues: { search: "" }
	});

	const searchTerm = formContext.watch("search");

	const filtered = useMemo(() => {
		if(!searchTerm || searchTerm.length < 3) return users;

		const fuse = new Fuse(users, {
			threshold: 0.3,
			includeMatches: true,
			includeScore: true,
			keys: [
				"role",
				"user.firstName",
				"user.lastName",
				"user.primaryEmailAddress",
				"user.primaryPhoneNumber",
				"user.fullName",
			]
		});

		return fuse.search(searchTerm).map(result => result.item);
	}, [ users, searchTerm ]);

	function resolveContactInfo(user: typeof users[ 0 ]) {
		const { primaryEmailAddress, primaryPhoneNumber } = user.user;
		return primaryEmailAddress || formatPhoneNumber(primaryPhoneNumber);
	}

	const { size } = useWindowDimensions();

	const [ isInvitingUser, setIsInvitingUser ] = useState(false);
	const [ isUpdatingUser, setIsUpdatingUser ] = useState<
		ResultOf<typeof FetchPartnerUsers>[ "FetchPartnerUsers" ][ 0 ] | null
	>(null);

	const { user: userSelf } = useUser();

	return (
		<Box>
			{isUpdatingUser && (
				<UpdateUserRoleDialog
					partnerId={buildingId}
					user={isUpdatingUser}
					onClose={() => setIsUpdatingUser(null)}
				/>
			)}
			{isInvitingUser && (
				<InviteUserDialog
					partnerId={buildingId}
					onClose={() => setIsInvitingUser(false)}
				/>
			)}
			<Box direction="row" gap="small" justify="between" align="center">
				<Box width="60%">
					<FormContainer formContext={formContext}>
						<TextFieldElement
							fullWidth
							size="small"
							// size={size === "small" ? "small" : undefined}
							name="search"
							label="Search"
							placeholder="Search users..."
						/>
					</FormContainer>
				</Box>
				<Box>
					<Button
						color="primary"
						variant="contained"
						startIcon={size !== "small" && <Person />}
						onClick={() => setIsInvitingUser(true)}
					>
						Add User
					</Button>
				</Box>
			</Box>
			{filtered.length === 0 && (
				<Box height="small" align="center" justify="center">
					<Typography>
						no users found
					</Typography>
				</Box>

			)}
			<Pagination pageSize={5}>
				{filtered.map(user => (
					<ListItem
						divider
						key={user.user.id}
					>
						{size !== "small" && (
							<ListItemIcon>
								<Avatar />
							</ListItemIcon>
						)}
						<ListItemText>
							<Typography fontWeight="bold">
								{user.user.fullName}
							</Typography>
							<Typography
								variant="body2"
								className="text-max-1-line"
							>
								{resolveContactInfo(user)}
							</Typography>
						</ListItemText>
						<ListItemSecondaryAction>
							<Chip
								color="primary"
								deleteIcon={<Edit />}
								disabled={user.user.id === userSelf?.id}
								onDelete={(() => {
									setIsUpdatingUser(user);
								})}
								label={resolvePermissionName(user)}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</Pagination>
		</Box>
	);
};

export const UpdateUserRoleDialog: React.FC<{
	partnerId: string;
	user: ResultOf<typeof FetchPartnerUsers>[ "FetchPartnerUsers" ][ 0 ];
	onClose: () => void;
}> = ({ user, partnerId, onClose }) => {
	const [ role, setRole ] = useState(user.role);

	const [
		updateRole,
		{ loading: isUpdating }
	] = useMutation(UpdateUserRole, {
		refetchQueries: [
			FetchPartnerUsers
		]
	});

	const snack = useSnackbar();

	function handleUpdateRole(role: PartnerRole) {
		updateRole({
			variables: {
				userId: user.user.id,
				partnerId,
				role
			}
		}).then(() => {
			setRole(role);
			snack.enqueueSnackbar("Role updated successfully", {
				variant: "success"
			});
		}).catch(err => {
			console.error("Failed to update role", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", {
				variant: "error"
			});
		});
	}

	return (
		<DialogWithClose
			title={"Update Role"}
			onClose={onClose}
			actions={(
				<Box direction="row" justify="between" gap="small">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
				</Box>
			)}
			content={(
				<RolesList
					activeRole={role}
					isLoading={isUpdating}
					onSelect={handleUpdateRole}
					enableReportingRole
				/>
			)}
		/>
	);
};

export const InviteUserDialog: React.FC<{
	partnerId: string;
	onClose: () => void;
}> = ({ partnerId, onClose }) => {
	const { instance } = useInstance();
	const formContext = useForm({
		defaultValues: {
			firstName: "",
			lastName: "",
			username: "",
			role: PartnerRole.PartnerStandard
		}
	});

	const snack = useSnackbar();

	const [
		invite,
		{ loading: isInviting }
	] = useMutation(InvitePartnerUser, {
		refetchQueries: [ FetchPartnerUsers ]
	});

	function handleSubmit() {
		const { firstName, lastName, username, role } = formContext.getValues();

		if(!firstName || !lastName || !username || !role || !partnerId || !instance) {
			return;
		}

		invite({
			variables: {
				firstName,
				lastName,
				contact: username,
				role,
				partnerId,
				instanceId: instance.id,
				//FIXME: should be instance:member but login won't work otherwise ... will fix when the limited user account is enabled
				instanceRole: role === PartnerRole.PartnerAdmin
					? "instance:admin"
					: "instance:member"
			}
		}).then(() => {
			snack.enqueueSnackbar("User invited successfully", {
				variant: "success"
			});
			onClose();
		}).catch(err => {
			console.error("Failed to invite user", err);
			snack.enqueueSnackbar("We ran into an issue inviting the user. Please try again.", {
				variant: "error"
			});
		});
	}

	return (
		<DialogWithClose
			title="Invite User"
			onClose={onClose}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Submit
					</LoadingButton>
				</Box>
			)}
			content={(
				<FormContainer formContext={formContext}>
					<Box gap="medium">
						<TextFieldElement
							name="firstName"
							label="First Name"
							validation={{
								required: "Please enter the user's first name."
							}}
						/>
						<TextFieldElement
							name="lastName"
							label="Last Name"
							validation={{
								required: "Please enter the user's last name."
							}}
						/>
						<UsernameElement
							username={formContext.watch("username")}
						/>
					</Box>
					<RolesList
						activeRole={formContext.watch("role")}
						isLoading={false}
						onSelect={(role) => formContext.setValue("role", role)}
					/>
				</FormContainer>
			)}
		/>
	);
};

export const RolesList: React.FC<{
	isLoading: boolean;
	activeRole: PartnerRole;
	enableReportingRole?: boolean;
	onSelect: (role: PartnerRole) => void;
}> = ({ activeRole, isLoading, onSelect, enableReportingRole }) => {
	return (
		<List>
			<List>
				<RoleListItem
					label="Admin"
					description="Full access to this location"
					selected={activeRole === PartnerRole.PartnerAdmin}
					loading={isLoading}
					onSelect={() => onSelect(PartnerRole.PartnerAdmin)}
				/>
				<RoleListItem
					label="User"
					description="Can make changes to this location but cannot add or remove users"
					selected={activeRole === PartnerRole.PartnerStandard}
					loading={isLoading}
					onSelect={() => onSelect(PartnerRole.PartnerStandard)}
				/>
				{enableReportingRole && (
					<RoleListItem
						label="Reporting"
						description="Can view reports and data but cannot make changes"
						selected={activeRole === PartnerRole.PartnerReporting}
						loading={isLoading}
						onSelect={() => onSelect(PartnerRole.PartnerReporting)}
					/>
				)}
			</List>
		</List>
	);
};

export const RoleListItem: React.FC<{
	label: string;
	loading: boolean;
	description: string;
	selected: boolean;
	onSelect: () => void;
}> = ({ label, loading, description, selected, onSelect }) => {
	return (
		<ListItemButton
			divider
			onClick={onSelect}
			selected={selected}
		>
			<ListItemText>
				<Typography fontWeight="bold">
					{label}
				</Typography>
				<Typography variant="body2" style={{ maxWidth: "80%" }}>
					{description}
				</Typography>
			</ListItemText>
			<ListItemSecondaryAction>
				{loading ? (
					<Spinner />
				) : (
					<Checkbox
						checked={selected}
						onClick={onSelect}
					/>
				)}

			</ListItemSecondaryAction>
		</ListItemButton>
	);
};