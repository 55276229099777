import { ReactNode } from "react";
import { HotkeysProvider, useHotkeys } from "react-hotkeys-hook";

export const HotkeyProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
	return (
		<HotkeysProvider>
			<HotkeysController>
				{children}
			</HotkeysController>
		</HotkeysProvider>
	);
};

export const HotkeysController: React.FC<{ children: ReactNode; }> = ({ children }) => {
	useHotkeys("ctrl+shift+a", () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.forceGlobalAdmin();
	});

	return (
		<>
			{children}
		</>
	);
};