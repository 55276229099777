import { gql } from "@apollo/client";


export const Quote_SchedulingFragment = gql`
	fragment Quote_SchedulingFragment on Quote {
		...Quote_HeaderFragment
		lastEstimate {
			...EstimateFragment
		}
	}
`;
