import React from "react";
import { HeadingWithIcon, Pagination, ViewContainer } from "../../../../components";
import { Assessment } from "@mui/icons-material";
import { useWasteAudits } from "../hooks";
import { useInstance, useWindowDimensions } from "../../../../hooks";
import { Box } from "grommet";
import { Button, LinearProgress, Typography } from "@mui/material";
import { WasteAuditCreateDialog, WasteAuditListItem } from "../components";

export const AuditsView: React.FC = () => {
	const { instance } = useInstance();
	const { audits, loading } = useWasteAudits(
		instance?.id || ""
	);
	const { size } = useWindowDimensions();

	return (
		<ViewContainer>
			<Box gap="medium">
				<Box
					gap="small"
					style={{ minHeight: "32px" }}
				>
					<Box direction="row" justify="between" align="center">
						<HeadingWithIcon
							text="Waste Audits"
							props={{
								level: "3",
								margin: "none"
							}}
							icon={<Assessment color="primary" />}
						/>
						<Box>
							<WasteAuditCreateDialog
								activatorButton={(
									<Button
										startIcon={(
											<Assessment />
										)}
										variant="contained"
										size={size === "small" ? "small" : undefined}
									>
										Create New
									</Button>
								)}
							/>
						</Box>
					</Box>
					{loading && (
						<LinearProgress />
					)}
				</Box>
				{audits.length === 0 && !loading && (
					<Box height="small" align="center" justify="center" gap="small">
						<Typography variant="body2">
							no audits found
						</Typography>
						<WasteAuditCreateDialog
							activatorButton={(
								<Button
									size="small"
									color="primary"
									variant="contained"
								>
									Get Started
								</Button>
							)}
						/>
					</Box>
				)}
				<Pagination pageSize={5}>
					{audits.map(audit => (
						<WasteAuditListItem
							key={audit.id}
							auditId={audit.id}
							name={audit.name}
							createdAt={audit.createdAt}
							completed={audit.completed}
						/>
					))}
				</Pagination>
			</Box>
		</ViewContainer>
	);
};