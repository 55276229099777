import { Order_ExpandedFragmentFragment } from "../../../../graphql/__generated__/graphql";
import { recordRecentOrder, selectRecentOrders } from "../../../../store/personalization";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useQuery } from "@apollo/client";
import { FetchOrderExpandedMany } from "../../../../graphql";


export function useRecentOrders() {
	const dispatch = useAppDispatch();
	const recentOrders = useAppSelector(selectRecentOrders);

	const { data, loading } = useQuery(FetchOrderExpandedMany, {
		variables: { orderIds: recentOrders.map(o => o.id) }
	});

	function handleSelectOrder(order: Order_ExpandedFragmentFragment) {
		dispatch(recordRecentOrder(order.id));
	}

	return {
		loading,
		recentOrders,
		orders: data?.FetchOrderMany ?? [],
		toggle: handleSelectOrder
	};
}
