import React, { Fragment, useEffect, useMemo } from "react";
import { Button, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Anchor, Box, Grid, InfiniteScroll, Spinner } from "grommet";
import { FilterAlt, LocalShipping, TimeToLeave } from "@mui/icons-material";
import { useWindowDimensions } from "../../../hooks";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../store";
import { push } from "redux-first-history";
import { formatCurrency } from "../../../helpers";
import { useStoreProducts } from "../hooks/useStoreProducts";
import { ProductCard } from "../components";
import { FilterDialog } from "../components/filter/FilterDialog";
import { useMarkets } from "../../../hooks/useMarkets";
import { selectFilterDialogControl, selectMarket, setDisplayFilterDialog, setMarket } from "../../../store/shop";
import { ViewContainer } from "../../../components";

export const ShopFilterContainer: React.FC = () => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const filterDialogControl = useAppSelector(selectFilterDialogControl);

	function hideFilterDialog() {
		dispatch(setDisplayFilterDialog(""));
	}

	function showFilterDialog(filter: string) {
		dispatch(setDisplayFilterDialog(filter));
	}

	return (
		<Fragment>
			{!!filterDialogControl && (
				<FilterDialog onClose={hideFilterDialog} active={filterDialogControl} />
			)}
			{size !== "small" && (
				<Box justify="center" direction="row" gap="medium" flex align="center" height="xsmall" style={{ minHeight: "15px" }}>
					{/* <Anchor
							label="Category"
							color="black"
							onClick={() => setShowFilterDialog("category")}
						/>
						<Anchor
							label="Type"
							color="black"
							onClick={() => setShowFilterDialog("type")}
						/> */}
					<FilterAlt fontSize="small" />
					<Anchor
						label="Brand"
						color="black"
						onClick={() => showFilterDialog("brand")}
					/>
					{/* <Anchor
							label="Material"
							color="black"
							onClick={() => setShowFilterDialog("material")}
						/> */}
					<Anchor
						label="Price"
						color="black"
						onClick={() => showFilterDialog("price")}
					/>
					<Anchor
						label="Condition"
						color="black"
						onClick={() => showFilterDialog("condition")}
					/>
					<FilterAlt fontSize="small" />
				</Box>
			)}
		</Fragment>
	);
};

export const ShopView: React.FC = () => {
	const dispatch = useAppDispatch();
	const instance = useAppSelector(selectInstancePublic);

	const { markets } = useMarkets();
	const market = useAppSelector(selectMarket);

	useEffect(() => {
		if(!market) {
			if(markets.length) {
				const ATL = markets.find(m => m.code === "ATL");
				if(ATL) {
					dispatch(setMarket(ATL));
				}
			}
		}
	}, [ markets, market, dispatch ]);

	function showFilterDialog(filter: string) {
		dispatch(setDisplayFilterDialog(filter));
	}

	return (
		<ViewContainer>
			<Box gap="medium" flex>
				<ShopFilterContainer
				/>
				{instance && (
					<ProductGallery instanceId={instance.id} />
				)}
				<Box align="end" direction="row" justify="between" flex style={{ minHeight: "50px" }}>
					<Box>
						<Button
							color="primary"
							variant="outlined"
							startIcon={<FilterAlt />}
							onClick={() => showFilterDialog("any")}
						>
							Filters
						</Button>
					</Box>
					<Box>
						<Button
							color="primary"
							variant="contained"
							onClick={() => dispatch(push("/shop/cart"))}
						>
							Go To Checkout
						</Button>
					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};

export const ProductGallery: React.FC<{
	instanceId: string;
}> = ({ instanceId }) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	const columns = useMemo(() => {
		switch(size) {
			case "small": {
				return 1;
			}
			case "medium": {
				return 2;
			}
			case "large": {
				return 3;
			}
			case "xlarge": {
				return 4;
			}
		}
	}, [ size ]);

	const { products, fetchNextPage, limit, loading } = useStoreProducts(instanceId, 20);

	return (
		<Box id="product-gallery" style={{ display: "block" }} height="100%" overflow={{ vertical: "scroll" }}>
			<Grid columns={{ count: columns, size: "auto" }} gap="medium">
				<InfiniteScroll items={products} onMore={fetchNextPage} step={limit}>
					{(product: typeof products[ 0 ]) => (
						<ProductCard
							key={product.id}
							productId={product.id}
							title={product.listing?.title ?? ""}
							price={product.listing?.price ?? 0}
							priceCompare={product.listing?.priceCompare ?? 0}
							media={product.media}
							freeDelivery={product.listing?.deliveryFee === 0}
							quantity={product.inventory.quantity ?? 1}
							onClick={() => {
								dispatch(push(`/shop/product/${product.id}`));
							}}
						/>
					)}
				</InfiniteScroll>
			</Grid>
			{loading && (
				<Box flex align="center" justify="center">
					<Spinner size="large" />
				</Box>
			)}
			<Box align="center">
				<Typography fontWeight="bold">
					That's all for now
				</Typography>
			</Box>
		</Box>
	);
};

export const ProductPrice: React.FC<{ price: number; large?: boolean; }> = (props) => {
	const [ dollars, cents ] = useMemo(() => {
		return Number(props.price).toFixed(2).split(".");
	}, [ props.price ]);
	return (
		<Typography fontWeight="bold" variant={props.large ? "h5" : undefined}>
			${dollars}
			<Typography
				component="sup"
				fontSize="small"
				fontWeight="bold"
				sx={{ paddingLeft: "2px" }}
			>
				{cents}
			</Typography>
		</Typography>
	);
};

interface ProductDeliveryOptionsProps {
	allowsPickup: boolean;
	allowsDelivery: boolean;
	deliveryFee: number;
}

export const ProductDeliveryOptions: React.FC<ProductDeliveryOptionsProps> = (props) => {
	return (
		<Box gap="small">
			<Typography fontWeight="bold">Delivery & Pickup Options</Typography>
			<Box margin={{ horizontal: "medium" }}>
				<List disablePadding>
					<ListItem divider disableGutters disablePadding>
						<ListItemIcon>
							<LocalShipping />
						</ListItemIcon>
						<ListItemText
							primary="White Glove Delivery"
							secondary={props.allowsDelivery
								? props.deliveryFee === 0
									? ""
									: formatCurrency(props.deliveryFee)
								: "Not Offered"
							}
						/>
						{props.deliveryFee === 0 && (
							<Box align="end" justify="end">
								<Chip
									sx={{ borderRadius: "6x" }}
									color="success"
									label="Free"
								/>
							</Box>
						)}
					</ListItem>
					{props.allowsPickup && (
						<ListItem divider disableGutters disablePadding>
							<ListItemIcon>
								<TimeToLeave />
							</ListItemIcon>
							<ListItemText
								primary="Free Pickup"
								secondary="Free local pickup"
							/>
						</ListItem>
					)}
				</List>
			</Box>
		</Box>
	);
};