import { gql } from "@apollo/client";

export const Order_EstimateFragment = gql`
	fragment Order_EstimateFragment on Order {
		lastEstimate {
			...OrderEstimate_HeaderFragment
		}
		acceptedEstimate {
			...OrderEstimate_HeaderFragment
		}
	}
`;