import { useMemo, useState } from "react";
import { InstanceType } from "../../../graphql/__generated__/graphql";
import { AlreadyLoggedInGuard } from "../../../guards/AlreadyLoggedInGuard";
import { selectInstancePublic, useAppSelector } from "../../../store";
import { BuildingOnboarding } from "../residence/BuildingOnboarding";
import { ManagementCompanyOnboardingView } from "../management-company";
import { DefaultOnboarding } from "../donation";
import { PageNotAvailableView } from "../../../views";

export const RegisterController: React.FC = () => {
	const instance = useAppSelector(selectInstancePublic);
	const [ fallbackUrl, setFallbackUrl ] = useState<string>(localStorage.getItem("redirect") || "/dashboard");

	const component = useMemo(() => {
		switch(instance?.type) {
			case InstanceType.DonationCenter: {
				setFallbackUrl(localStorage.getItem("redirect") || "/dashboard?schedule=true");
				return (
					<DefaultOnboarding />
				);
			}
			case InstanceType.University: {
				setFallbackUrl(localStorage.getItem("redirect") || "/dashboard?schedule=true");
				return (
					<DefaultOnboarding />
				);
			}
			case InstanceType.Residence: {
				setFallbackUrl(localStorage.getItem("redirect") || "/dashboard?schedule=true");
				return (
					<BuildingOnboarding />
				);
			}
			case InstanceType.ManagementCompany: {
				setFallbackUrl(localStorage.getItem("redirect") || "/dashboard?schedule=true");
				return (
					<ManagementCompanyOnboardingView />
				);
			}
			case InstanceType.Reporting: {
				//SELF REGISTER DISABLED
				return (
					<PageNotAvailableView />
				);
			}
			case InstanceType.Onboarding: {
				return (
					<DefaultOnboarding
						hideLogo
					/>
				);
			}
			default: {
				return (
					<DefaultOnboarding />
				);
			}
		}
	}, [ instance ]);

	return (
		<AlreadyLoggedInGuard skipOnLocalhost fallbackUrl={fallbackUrl}>
			{component}
		</AlreadyLoggedInGuard>
	);
};