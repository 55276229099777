import { useQuery } from "@apollo/client";
import { FindInstanceUpcomingPickups } from "../../../../graphql";
import { useInstance } from "../../../../hooks";
import moment from "moment";

export function useUniversityUpcomingPickups() {
	const { instance } = useInstance();
	const { data, loading } = useQuery(FindInstanceUpcomingPickups, {
		variables: {
			after: moment().startOf("day").utc().toISOString(),
			instanceId: instance?.id ?? ""
		}
	});

	return {
		pickups: data?.FindPickups ?? [],
		loading,
	};
}