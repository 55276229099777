import React, { useMemo } from "react";
import { SidebarRouter, SidebarRouteItem, DashboardListItem, OrdersListItem, StoreMultilevelListItem, WasteReportingListItem } from "../../../components";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { isNotNull } from "../../../helpers";
import { useFeature } from "../../instance/hooks/useFeature";
import { StoreDashboardView, StoreProductsView, StorePurchasesView, ProductDetails } from "../views";
import { WasteReportingDashboardView } from "../common/views/WasteDiversionDashboard";
import { WasteTicketView } from "../common/views/WasteTickets";
import { OrderDetailsView, OrdersView, StoreSettingsView } from "../common";
import { DonationCenterDashboard } from "./views";

export const DonationCenterRouter: React.FC = () => {
	return useDonationCenterRouter().toRouter();
};

export function useDonationCenterRouter() {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);
	const isDonationEnabled = useFeature(InstanceFeature.DonationEnabled);
	const isWasteReportingEnabled = useFeature(InstanceFeature.WasteDiversionEnabled);

	const storeRoutes = useAdminStoreRoutes();
	const wasteReportingRoutes = useAdminWasteReportingRoutes();

	return useMemo(() => {
		return new SidebarRouter([
			new SidebarRouteItem(
				"/dashboard",
				<DonationCenterDashboard />,
				<DashboardListItem
					key="dashboard-list-item"
					absolutePath="/admin/dashboard"
				/>,
				true
			),
			isDonationEnabled ? new SidebarRouteItem(
				"/orders",
				<OrdersView />,
				<OrdersListItem
					key="orders-list-item"
					absolutePath="/admin/orders"
				/>,
				true
			) : null,
			// isDonationEnabled ? new SidebarRouteItem(
			// 	"/donors",
			// 	<DonorsView />,
			// 	<DonorsListItem absolutePath="/admin/donors" />,
			// 	true
			// ) : null,
			isWasteReportingEnabled ?
				new SidebarRouteItem(
					"/waste-reporting/*",
					<WasteReportingDashboardView />,
					<WasteReportingListItem
						key="waste-reporting-list-item"
						absolutePath="/admin/waste-reporting"
					/>,
					true
				) : null,
			isSalesEnabled ? new SidebarRouteItem(
				"/store/*",
				<StoreSettingsView />,
				<StoreMultilevelListItem
					absolutePath=""
					key="store-list-item"
				/>,
				true
			) : null
		].filter(isNotNull), "Donation Center", [
			{ from: "/", to: "/admin/dashboard" },
			isSalesEnabled ? { from: "/store", to: "/admin/store/dashboard" } : null,
			isWasteReportingEnabled ? { from: "/waste-reporting", to: "/admin/waste-reporting/dashboard" } : null
		].filter(isNotNull), [
			{ path: "/products/:productId", element: <ProductDetails permitEdit={false} /> },
			{ path: "/orders/:orderId", element: <OrderDetailsView permitEdit={false} permitViewPickup={false} /> },
			...storeRoutes,
			...wasteReportingRoutes
		]);

	}, [ isSalesEnabled, isDonationEnabled ]);
}

export function useAdminStoreRoutes() {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);

	return useMemo(() => {
		return isSalesEnabled ? [
			{ path: "/store/dashboard", element: <StoreDashboardView /> },
			{ path: "/store/settings", element: <StoreSettingsView /> },
			{ path: "/store/products", element: <StoreProductsView /> },
			{ path: "/store/purchases", element: <StorePurchasesView /> }
		] : [];
	}, [ isSalesEnabled ]);
}

export function useAdminWasteReportingRoutes() {
	const isWasteReportingEnabled = useFeature(InstanceFeature.WasteDiversionEnabled);

	return useMemo(() => {
		return isWasteReportingEnabled ? [
			{ path: "/waste-reporting/dashboard", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/share", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/download", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/upload", element: <WasteTicketView /> }
		] : [];
	}, [ isWasteReportingEnabled ]);
}