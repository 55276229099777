import { usePricingPolicy } from "../../../hooks";
import { useMemo } from "react";
import { isItemSizePricing, isItemTypePricing } from "../helpers";

export function usePricedItem(
	policyId: string,
	typeId: string,
	sizeId?: string
) {
	const { policy, loading } = usePricingPolicy(policyId);

	const pricing = useMemo(() => {
		if(policy) {
			if(sizeId) {
				const pricing = policy.pricing.filter(p => isItemSizePricing(p) && p.size.id === sizeId);

				const pickupFee = pricing.reduce((acc, p) => {
					return p.pickupFee + acc;
				}, 0);

				const disassemblyFee = pricing.reduce((acc, p) => {
					return p.disassemblyFee + acc;
				}, 0);

				return {
					pickupFee,
					disassemblyFee
				};
			}

			const pricing = policy.pricing.filter(p => (isItemTypePricing(p) && p.type.id === typeId));

			const pickupFee = pricing.reduce((acc, p) => {
				return p.pickupFee + acc;
			}, 0);

			const disassemblyFee = pricing.reduce((acc, p) => {
				return p.disassemblyFee + acc;
			}, 0);

			return {
				pickupFee,
				disassemblyFee
			};
		}

		return {
			pickupFee: 0,
			disassemblyFee: 0
		};
	}, [ policy, typeId, sizeId ]);

	return { ...pricing, loading, policy };
}
