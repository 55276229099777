import { gql } from "../../../__generated__";

export const FetchWasteContainerInspection = gql(/* GraphQL */`
	query FetchWasteContainerInspection(
		$inspectionId: String!
	) {
		FetchWasteContainerInspection(
			inspectionId: $inspectionId
		) {
			...WasteContainerInspection_MediaFragment
			...WasteContainerInspection_HeaderFragment
			...WasteContainerInspection_ContainerFragment
		}
	}
`);