import { Box } from "grommet";
import { ListItem, useTheme } from "@mui/material";
import { useClassification } from "../../../hooks";
import { useCallback, useMemo } from "react";
import { CountSelectorElement } from "./CountSelectorElement";
import { ItemNameElement } from "./ItemNameElement";
import { ItemPricingElement } from "./ItemPricingElement";
import { SizeSelectionListItem } from "./SizeSelectionListItem";
import { useAppDispatch, useAppSelector } from "../../../store";
import { findProductInState, incrementProduct, insertProduct, removeProduct, updateProduct } from "../../../store/checkout";
import { useSize, useType } from "../hooks";
import { generateTempProduct } from "../helpers";
import { Icon } from "@liverego/icons";
import { ItemSelectionContainer } from "./ItemSelectionContainer";

export function useIncrementProduct(typeId: string, sizeId: string) {
	const dispatch = useAppDispatch();
	const product = useAppSelector(findProductInState({ typeId, sizeId }));
	const { type, size } = useSize(typeId, sizeId);

	const insert = useCallback(() => {
		dispatch(
			insertProduct(generateTempProduct(
				type || { id: typeId, name: "", canDisassemble: false },
				size
			))
		);
	}, [ dispatch, typeId, sizeId ]);

	const remove = useCallback(() => {
		dispatch(removeProduct({ typeId, sizeId }));
	}, [ dispatch, typeId, sizeId ]);

	const increment = useCallback(() => {
		if(!product) {
			return insert();
		}

		dispatch(incrementProduct({ typeId, sizeId }));
	}, [ dispatch, insert, product, typeId, sizeId ]);

	const decrement = useCallback(() => {
		if(!product) return;
		if(product.quantity === 1) {
			return remove();
		}

		const updatedQuantity = Math.max(1, (product.quantity || 0) - 1);
		const updatedQuantityDisassembly = Math.min(updatedQuantity, product.quantityDisassembly || 0);

		dispatch(updateProduct({
			...product,
			quantity: updatedQuantity,
			quantityDisassembly: updatedQuantityDisassembly
		}));
	}, [ dispatch, typeId, sizeId, product, remove ]);

	const incrementDisassembly = useCallback(() => {
		if(!product) {
			return;
		}

		if(product.quantityDisassembly > 0 && product.quantityDisassembly >= product.quantity) {
			return;
		}

		dispatch(updateProduct({
			...product,
			quantityDisassembly: (product.quantityDisassembly || 0) + 1
		}));
	}, [ dispatch, product ]);

	const decrementDisassembly = useCallback(() => {
		if(!product) {
			return;
		}

		dispatch(updateProduct({
			...product,
			quantityDisassembly: Math.max(0, (product.quantityDisassembly || 0) - 1)
		}));
	}, [ dispatch, product ]);

	return {
		insert,
		remove,
		increment,
		decrement,
		incrementDisassembly,
		decrementDisassembly
	};
}

export const TypeSelectionListItem: React.FC<{
	typeId: string;
	selected?: boolean;
}> = ({ typeId, selected }) => {
	const {
		type,
		pickupFee,
		pickupFeeComparison,
		quantity
	} = useType(typeId);

	const {
		increment,
		decrement
	} = useIncrementProduct(typeId, "");
	const { types } = useClassification();

	const sizesOfType = useMemo(() => {
		return types.find((t) => t.id === type?.id)?.sizes ?? [];
	}, [ types ]);

	const theme = useTheme();

	return (
		<ListItem divider selected={selected} id={`type-${typeId}`}>
			<Box flex gap="small" id={`type-${typeId}`}>
				<ItemSelectionContainer
					icon={(
						<Icon
							url={type?.iconUrl || type?.category?.iconUrl || "@liverego/icons/Other"}
							sx={{ fontSize: "48px", fill: theme.palette.primary.main }}
						/>
					)}
				>
					<Box justify="center">
						<ItemNameElement name={type?.name || ""} />
						{sizesOfType.length <= 0 && (
							<ItemPricingElement
								totalCount={quantity}
								pickupFee={pickupFee}
								pickupFeeComparison={pickupFeeComparison}
							/>
						)}
					</Box>
					<Box flex align="end" justify="center">
						<CountSelectorElement
							count={quantity}
							increment={increment}
							decrement={decrement}
							hideButtons={sizesOfType.length > 0}
							disableButtons={sizesOfType.length > 0}
						/>
					</Box>
				</ItemSelectionContainer>
				{sizesOfType.length > 0 && (
					<Box>
						{sizesOfType.map((size) => (
							<SizeSelectionListItem
								key={size.id}
								typeId={typeId}
								sizeId={size.id}
							/>
						))}
					</Box>
				)}
			</Box>
		</ListItem>
	);
};
