import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { BeginLogin } from "../../graphql";

export function useBeginLogin() {
	const [ execute, { data, error, loading } ] = useMutation(BeginLogin);

	const [ state, setState ] = useState({
		wasCodeSent: false,
		canSendCode: false,
		errorMessage: ""
	});

	useEffect(() => {
		if(data && data.BeginLogin.wasSent) {
			setState((state) => ({
				...state,
				wasCodeSent: true,
				canSendCode: false,
				errorMessage: ""
			}));
		}
	}, [ data ]);

	useEffect(() => {
		if(error) {
			setState((state) => ({
				...state,
				wasCodeSent: false,
				canSendCode: false,
				errorMessage: "We ran into an issue logging you in"
			}));

			return;
		}

		if(data && !data.BeginLogin.wasSent) {
			setState((state) => ({
				...state,
				wasCodeSent: false,
				canSendCode: false,
				errorMessage: data.BeginLogin.message
			}));

			return;
		}
	}, [ data, error ]);

	useEffect(() => {
		if(state.wasCodeSent && !state.canSendCode) {
			const timer = setTimeout(() => {
				setState((state) => ({ ...state, canSendCode: true }));
			}, 15000);

			return () => clearTimeout(timer);
		}
	}, [ state.wasCodeSent, state.canSendCode ]);

	const beginLogin = useCallback((username: string, instanceId: string) => {
		execute({
			variables: {
				username,
				instanceId
			}
		}).catch(err => {
			console.error("Failed to begin login", err);
		});
	}, [ execute ]);

	return {
		beginLogin,
		isSendingCode: loading,
		wasCodeSent: state.wasCodeSent,
		canSendCode: state.canSendCode,
		beginLoginError: state.errorMessage
	};
}
