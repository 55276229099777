import { gql } from "@apollo/client";

export const Listing_HeaderFragment = gql`
	fragment Listing_HeaderFragment on Listing {
		id
		title
		description
		price
		priceCompare
		deliveryFee
		allowPickup
		allowDelivery
	}
`;