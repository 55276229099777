import { Box, Heading, Image, Text } from "grommet";
import { Bold, Customized } from "../../../../components";
import { selectInstancePublic, useAppSelector } from "../../../../store";
import React from "react";
import { useReferCustomizer } from "../../../../hooks/customizer/useCustomizer";

export const ResidenceDetailsContainer: React.FC<{
	partnerName: string;
}> = ({ partnerName }) => {
	const instance = useAppSelector(selectInstancePublic);

	const {
		welcomeContent,
		disableHeaderContent
	} = useReferCustomizer();

	return (
		<Box gap="medium" pad="small">
			{!disableHeaderContent && (
				<Box
					pad="medium"
					direction="row"
					justify="between"
					background={instance?.customizations.headerColor || undefined}
				>
					<Box justify="center">
						<Heading level="3" margin="none" color={instance?.customizations.accentColor || ""}>
							Welcome!
						</Heading>
					</Box>
					<Box width="small" style={{ maxHeight: "80px" }}>
						<Image
							fit="contain"
							src={instance?.customizations?.logoUrl || ""}
						/>
					</Box>
				</Box>
			)}
			<Customized
				content={welcomeContent}
			>
				<Text>
					<Bold size="large">{partnerName}</Bold> has partnered with Rego to help residents {(<Bold size="large">donate</Bold>)} or <Bold size="large">dispose</Bold> of used furniture more <Bold size="large">easily</Bold> and <Bold size="large">affordably</Bold>.
				</Text>
			</Customized>
		</Box>
	);
};
