import { gql } from "../../../__generated__";

export const CreatePricingPolicy = gql(/* GraphQL */ `
	mutation CreatePricingPolicy(
		$name: String!
		$margin: Float!
		$type: PricingPolicyType!
		$pricing: [ ItemPricingModelDTOUnion! ]!
		$partners: [ String! ]!
	) {
		CreatePricingPolicy(
			name: $name
			type: $type
			margin: $margin
			pricing: $pricing
			partners: $partners
		) {
			id
		}
	}
`);