import { Box } from "grommet";
import React, { useMemo } from "react";
import { BasicListItem } from "../../../../components";
import { IconButton } from "@mui/material";
import { CopyAll, PhoneIphoneSharp, Sms } from "@mui/icons-material";
import { useClipboard } from "../../../../hooks";
import { formatPhoneNumber, isDestinationStop, isOriginStop } from "../../../../helpers";
import { useStop } from "../../../../graphql";

export const StopPhoneNumberListItem: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	//For now we're going to assume that every product has a single email address
	const { copy } = useClipboard();
	const { stop, loading } = useStop(stopId);

	const phoneNumber = useMemo(() => {
		if(stop && isOriginStop(stop)) {
			return stop.incoming[ 0 ].user.primaryPhoneNumber ?? "";
		}

		if(stop && isDestinationStop(stop)) {
			const outlet = stop.outlet;
			return outlet?.contactPhone ?? "";
		}

		return "";
	}, [ stop ]);

	const phoneNumberFormatted = useMemo(() => {
		return phoneNumber ? formatPhoneNumber(phoneNumber) : "";
	}, [ phoneNumber ]);

	return (
		<BasicListItem
			label="Phone Number"
			loading={loading}
			value={phoneNumberFormatted || "Unknown"}
			icon={<PhoneIphoneSharp />}
			action={(
				<Box direction="row" gap="xsmall">
					{phoneNumber && (
						<IconButton onClick={() => phoneNumber && copy(phoneNumber)}>
							<CopyAll />
						</IconButton>
					)}
					{phoneNumber && (
						<IconButton href={`sms://+${phoneNumber}`}>
							<Sms />
						</IconButton>
					)}
				</Box>
			)} />
	);
};
