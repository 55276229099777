import { Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { Order_ExpandedFragmentFragment, OrderStatus } from "../../../../../graphql/__generated__/graphql";
import { SavedOrderCheckboxColumn } from "./SavedOrderCheckboxColumn";

export const checkboxSelectionColumn = (): GridColDef => ({
	field: "saved",
	headerName: "",
	disableColumnMenu: true,
	width: 60,
	align: "center",
	renderCell: (
		params
	) => {
		return (
			<SavedOrderCheckboxColumn
				id={(params.row as Order_ExpandedFragmentFragment).id}
			/>
		);
	}
});

export function customerNameColumn(fieldName: string): GridColDef {
	return {
		field: "user",
		headerName: fieldName,
		width: 200,
		valueGetter: (params) => {
			const order = params.row as Order_ExpandedFragmentFragment;
			return order.user?.fullName ?? "";
		}
	};
}

export function refererColumn(fieldName: string): GridColDef {
	return {
		field: "referer",
		headerName: fieldName,
		width: 300,
		valueGetter: (params) => {
			const order = params.row as Order_ExpandedFragmentFragment;
			return order.referer?.name ?? "";
		}
	};
}

export function orderItemCountColumn(): GridColDef {
	return {
		field: "productCount",
		headerName: "Total Items",
		width: 125,
		valueGetter: (params) => {
			const order = params.row as Order_ExpandedFragmentFragment;
			return order.productCount;
		}
	};
}

export function orderStatusColumn(): GridColDef {
	return {
		field: "status",
		width: 150,
		headerName: "Status",
		renderCell: (params) => {
			const order = params.row as Order_ExpandedFragmentFragment;

			switch(order.status) {
				case OrderStatus.Cancelled: {
					return (
						<Chip
							color="error"
							label={"Cancelled"}
						/>
					);
				}
				case OrderStatus.Pending: {
					return (
						<Chip
							color="warning"
							label={"Pending"}
						/>
					);
				}
				case OrderStatus.InProgress: {
					return (
						<Chip
							color="warning"
							label={"In Progress"}
						/>
					);
				}
				case OrderStatus.Submitted: {
					if(order.schedulePending && !order.scheduled) {
						return (
							<Chip
								color="warning"
								label={"Ready to Schedule"}
							/>
						);
					}

					if(order.scheduled) {
						return (
							<Chip
								color="success"
								label={"Scheduled"}
							/>
						);
					}

					return (
						<Chip
							color="warning"
							label={"Submitted"}
						/>
					);
				}
				case OrderStatus.Completed: {
					return (
						<Chip
							color="success"
							label={"Completed"}
						/>
					);
				}
			}
		}
	};
}

export const submittedDateColumn = (): GridColDef => ({
	field: "submittedAt",
	headerName: "Submitted Date",
	width: 200,
	valueGetter: (params) => {
		const order = params.row as Order_ExpandedFragmentFragment;
		if(!order.submittedAt) return "Not Submitted";
		return moment(order.submittedAt).format("MM/DD/YYYY");
	}
});

export const scheduledDateColumn = (): GridColDef => ({
	field: "scheduledAt",
	headerName: "Scheduled Date",
	width: 200,
	valueGetter: (params) => {
		const order = params.row as Order_ExpandedFragmentFragment;
		if(!order.pickup?.scheduledDate) return "Not Scheduled";
		return moment(order.pickup?.scheduledDate).format("MM/DD/YYYY");
	}
});

export const defaultColumns: GridColDef[] = [
	checkboxSelectionColumn(),
	customerNameColumn("Customer Name"),
	orderStatusColumn(),
	refererColumn("Donation Center"),
	orderItemCountColumn(),
	submittedDateColumn(),
	scheduledDateColumn()
];