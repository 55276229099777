import { useMemo } from "react";
import { BasicListItem } from "../../../../../components";
import { PickupLocation } from "../../../../../graphql/__generated__/graphql";
import { useOrderRequest } from "../../hooks";

export const PickupLocationListItem: React.FC<{
	loading: boolean;
	requestId: string;
}> = ({ loading: loadingParent, requestId }) => {
	const { request, loading } = useOrderRequest(requestId);

	const value = useMemo(() => {
		switch(request?.location) {
			case PickupLocation.Customer: {
				let value = "Resident Unit";
				if(request?.unit) {
					value += ` (${request.unit})`;
				}

				if(request?.room) {
					value += ` / Room (${request.room})`;
				}

				return value;
			}
			case PickupLocation.LoadingDock:
				return "Loading Dock";
			default:
				return "Other / Unknown";
		}
	}, [ request ]);

	return (
		<BasicListItem
			label={"Pickup Location"}
			value={value}
			loading={loading || loadingParent}
		/>
	);
};