import { DialogWithClose, SplashScreen, Step } from "../../../components";
import { InstanceType } from "../../../graphql/__generated__/graphql";
import { useAppSelector, selectInstancePublic, useAppDispatch } from "../../../store";
import { CheckoutAddressView } from "../views/CheckoutAddressView";
import { CheckoutAddressResidentView } from "../views/CheckoutAddressResidentView";
import { CheckoutStepProps } from "../types";
import { usePageTitle, useWindowDimensions } from "../../../hooks";
import { CheckoutAddressResidentSummary, CheckoutAddressSummary } from "../components";
import { useUser } from "../../../auth";
import { Fragment, useEffect, useMemo } from "react";
import { Button, Typography } from "@mui/material";
import { Box, Grid, Heading } from "grommet";
import { push } from "redux-first-history";

export const LoginRequiredDialog: React.FC<{
	onClose: () => void;
	overrideText?: string;
}> = ({ onClose, overrideText }) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	function handleLoginRedirect() {
		// Redirect to login
		localStorage.setItem("redirect", window.location.pathname + window.location.search);
		dispatch(push("/login"));
	}

	function handleRegisterRedirect() {
		// Redirect to register
		localStorage.setItem("redirect", window.location.pathname + window.location.search);
		dispatch(push("/register"));
	}

	return (
		<DialogWithClose
			onClose={onClose}
			title="Login to Continue"
			content={(
				<Box gap="medium" flex>
					<Typography>
						{overrideText || "Creating an account allows us to save your information so you can come back and view the status of your request"}
					</Typography>
					<Grid
						gap="medium"
						columns={{ count: size === "small" ? 1 : 2, size: "auto" }}
					>
						<Box gap="small">
							<Heading level="3" margin="none" textAlign="center">
								New Here?
							</Heading>
							<Button
								variant="contained"
								color="primary"
								onClick={handleRegisterRedirect}
							>
								Get Started
							</Button>
						</Box>
						<Box gap="small">
							<Heading level="3" margin="none" textAlign="center">
								Existing User?
							</Heading>
							<Button
								variant="contained"
								color="primary"
								onClick={handleLoginRedirect}
							>
								Login Now
							</Button>
						</Box>
					</Grid>
				</Box>
			)}
		/>
	);
};

export const LoginInteruptController: React.FC<{
	loginRequired: boolean;
	children: React.ReactNode;
	defaultRegister?: boolean;
	overrideText?: string;
}> = ({ children, loginRequired, defaultRegister, overrideText }) => {
	const { user } = useUser();

	useEffect(() => {
		if(loginRequired && !user) {
			// Show login dialog
		}
	}, [ user ]);

	const showDialog = useMemo(() => {
		return loginRequired && !user;
	}, [ user, loginRequired ]);

	return (
		<Fragment>
			{showDialog && (
				<LoginRequiredDialog overrideText={overrideText} onClose={() => {
					//
				}} />
			)}
			{children}
		</Fragment>
	);
};

export const CheckoutAddressController: React.FC<CheckoutStepProps> = (props) => {
	usePageTitle("Pickup Address");
	const instance = useAppSelector(selectInstancePublic);

	return (
		<LoginInteruptController loginRequired={props.loginRequired}>
			<SplashScreen visible={!instance}>
				{(() => {
					switch(instance?.type) {
						case InstanceType.Residence:
						case InstanceType.ManagementCompany: {
							return (
								<Step stepName="Location">
									<CheckoutAddressResidentView />
								</Step>
							);
						}
						default: {
							return (
								<Step stepName={props.stepName}>
									<CheckoutAddressView />
								</Step>
							);
						}
					}
				})()}
			</SplashScreen>
		</LoginInteruptController>
	);
};

export const CheckoutAddressSummaryController: React.FC = () => {
	usePageTitle("Pickup Address");
	const instance = useAppSelector(selectInstancePublic);

	return (
		<SplashScreen visible={!instance}>
			{(() => {
				switch(instance?.type) {
					case InstanceType.Residence: {
						return (
							<CheckoutAddressResidentSummary />
						);
					}
					default: {
						return (
							<CheckoutAddressSummary />
						);
					}
				}
			})()}
		</SplashScreen>
	);
};