import { useMemo } from "react";
import { InstanceType, Order_SourceFragmentFragment } from "../../../graphql/__generated__/graphql";


export function useRefererLabel({ instance }: Partial<Order_SourceFragmentFragment>) {
	const label = useMemo(() => {
		const type = instance?.type;
		if(!type) return "Referer";

		switch(type) {
			case InstanceType.Residence:
			case InstanceType.ManagementCompany: {
				return "Building";
			}
			case InstanceType.DonationCenter: {
				return "Donation Center";
			}
			default: {
				return "Referer";
			}
		}
	}, [ instance ]);

	return label;
}
