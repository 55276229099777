import { gql } from "../../../__generated__";

export const FetchAdminTasks = gql(/* GraphQL */`
	query FetchAdminTasks {
		FindOrderRequests(
			cancelled: { equals: false }
			converted: { equals: false }
		) {
			...OrderRequest_HeaderFragment
			...OrderRequest_SourceFragment
		}
		OrderSchedulePendingTasks: FindOrders(
			status: { equals: "SUBMITTED" }
			schedulePending: { equals: true }
		) {
			id
			submittedAt
			user {
				id
				firstName
				lastName
			}
		}
		OrderProcessingPendingTasks: FindOrders(
			status: { equals: "SUBMITTED" }
			scheduled: { equals: false }
			schedulePending: { equals: false }
			milestone: { not: "QUOTE_REQUESTED"}
		) {
			id
			submittedAt
			user {
				id
				firstName
				lastName
			}
		}
		OrderProductMissingDispositionTasks: FindOrdersWithProductMissingDisposition(
			status: { equals: "SUBMITTED" }
			scheduled: { equals: true }
		) {
			id
			submittedAt
			user {
				id
				firstName
				lastName
			}
		}
	}
`);