import { gql } from "@apollo/client";
import { Size_PricedFragmentFragment } from "../../../__generated__/graphql";

export const Size_PricedFragment = gql`
	fragment Size_PricedFragment on PricedItemSize {
		id
		name
		... @defer {
			pickupFee(partnerId: $partnerId)
		}
		... @defer {
			disassemblyFee(partnerId: $partnerId)
		}
		tags
		iconUrl
		index
	}
`;

export function isPricedItemSize(value: { [ key: string ]: unknown; }): value is Size_PricedFragmentFragment {
	return typeof value === "object" && value !== null && "pickupFee" in value && "disassemblyFee" in value;
}