import { SvgIcon, SvgIconProps } from "@mui/material";

export const SmallAppliance: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
				xmlns="http://www.w3.org/2000/svg">
				<g>
					<path
						d="m894.28 332.95 110.47-117.72c7.1641-7.6367 9.1133-18.789 4.9492-28.395-4.1641-9.6016-13.621-15.816-24.09-15.816h-53.273c-11.762-46.59-53.91-81.227-104.1-81.227h-98.422l-15.051-18.367c-18.621-22.738-46.172-35.781-75.57-35.781h-51.684c-29.398 0-56.949 13.043-75.57 35.781l-15.051 18.367h-81.148c-50.191 0-92.332 34.637-104.09 81.227h-22.23c-30.277 0-58.719 13.379-78.016 36.711s-27.113 53.773-21.434 83.52l38.871 203.66c9.0977 47.672 50.926 82.266 99.449 82.266h48.367l26.566 146.67c2.5938 14.34 8.0859 27.496 15.668 39.066-24.652 8.8594-43.98 29.512-50.504 56.168l-60.367 246.32c-5.8789 24-0.50391 48.906 14.746 68.355 15.254 19.441 38.168 30.586 62.879 30.586h455.41c24.711 0 47.633-11.145 62.887-30.594 15.246-19.441 20.625-44.348 14.746-68.348l-60.367-246.31c-6.5234-26.656-25.852-47.316-50.504-56.176 7.5898-11.574 13.074-24.727 15.676-39.066zm-43.582-30.27c-3.4648 3.6914-5.7812 8.3008-6.6992 13.281l-72.188 398.53c-4.582 25.32-26.586 43.695-52.32 43.695l-106.14 0.003906h-106.15c-25.734 0-47.738-18.375-52.32-43.695l-88.934-490.97h559.03zm-434.96-160.38h93.578c7.8594 0 15.316-3.5234 20.309-9.6133l22.926-27.977c8.6172-10.523 21.367-16.551 34.965-16.551h51.684c13.598 0 26.348 6.0312 34.965 16.551l22.926 27.977c4.9805 6.0898 12.434 9.6133 20.301 9.6133h110.86c20.812 0 38.961 11.625 48.293 28.727h-509.1c9.3281-17.109 27.48-28.727 48.293-28.727zm-87.453 382.38c-23.363 0-43.5-16.656-47.879-39.613l-38.871-203.66c-2.7305-14.324 1.0273-28.98 10.32-40.215 9.293-11.234 22.98-17.676 37.559-17.676h23.176l54.547 301.16-38.852-0.003907zm539.4 553.21c2.0156 8.2344 0.17188 16.777-5.0625 23.453s-13.094 10.492-21.578 10.492h-455.41c-8.4766 0-16.344-3.8242-21.57-10.492-5.2266-6.668-7.0781-15.219-5.0625-23.453l60.367-246.32c3.0156-12.301 13.965-20.895 26.633-20.895h334.69c12.66 0 23.617 8.5938 26.633 20.895z" />
					<path
						d="m613.34 935.14c-38.641 0-70.078 31.434-70.078 70.074 0 38.641 31.434 70.074 70.078 70.074 38.633 0 70.074-31.434 70.074-70.074-0.003907-38.641-31.434-70.074-70.074-70.074zm0 102.64c-17.969 0-32.578-14.609-32.578-32.574 0-17.961 14.609-32.574 32.578-32.574 17.961 0 32.574 14.609 32.574 32.574-0.003907 17.965-14.613 32.574-32.574 32.574z" />
					<path
						d="m612.64 892.46c12.586 0 22.816-10.23 22.816-22.809s-10.23-22.816-22.816-22.816c-12.578 0-22.809 10.23-22.809 22.816 0 12.574 10.23 22.809 22.809 22.809z" />
					<path
						d="m713.89 904.03c-12.578 0-22.809 10.23-22.809 22.816 0 12.578 10.23 22.809 22.809 22.809 12.586 0 22.816-10.23 22.816-22.809 0-12.586-10.238-22.816-22.816-22.816z" />
					<path
						d="m601.43 276.84h-108.78c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h108.78c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3945-18.75-18.75-18.75z" />
					<path
						d="m492.64 389.09h54.391c10.359 0 18.75-8.3906 18.75-18.75s-8.3906-18.75-18.75-18.75h-54.391c-10.359 0-18.75 8.3906-18.75 18.75 0 10.355 8.3945 18.75 18.75 18.75z" />
					<path
						d="m601.43 426.34h-108.78c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h108.78c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3945-18.75-18.75-18.75z" />
					<path
						d="m492.64 538.59h54.391c10.359 0 18.75-8.3906 18.75-18.75s-8.3906-18.75-18.75-18.75h-54.391c-10.359 0-18.75 8.3906-18.75 18.75s8.3945 18.75 18.75 18.75z" />
					<path
						d="m547.04 650.59h-54.391c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h54.391c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3945-18.75-18.75-18.75z" />
					<path
						d="m601.43 575.84h-108.78c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h108.78c10.359 0 18.75-8.3906 18.75-18.75s-8.3945-18.75-18.75-18.75z" />
					<path
						d="m512.79 904.03c-12.578 0-22.816 10.23-22.816 22.816 0 12.578 10.23 22.809 22.816 22.809 12.578 0 22.809-10.23 22.809-22.809 0-12.586-10.23-22.816-22.809-22.816z" />
				</g>
			</svg>
		</SvgIcon>
	);
};