import { List } from "@mui/material";
import { FragmentType } from "../../../../../graphql";
import { Pickup_HeaderFragmentFragment, Pickup_InvoiceFragmentFragment, Pickup_TrackingFragmentFragment } from "../../../../../graphql/__generated__/graphql";
import { BasicListItem, PickupIdListItem, PickupScheduledDateListItem, PickupScheduledWindowListItem } from "../../../../../components";
import { formatCurrency } from "../../../../../helpers";

export const PickupDetailsComponent: React.FC<{
	loading: boolean;
	pickup: FragmentType<
		Pickup_HeaderFragmentFragment &
		Pickup_TrackingFragmentFragment &
		Pickup_InvoiceFragmentFragment
	> | null;
}> = ({ pickup, loading }) => {
	return (
		<List>
			<PickupIdListItem
				pickup={pickup}
				loading={loading}
			/>
			<PickupScheduledDateListItem
				pickup={pickup}
				loading={loading}
			/>
			<PickupScheduledWindowListItem
				pickup={pickup}
				loading={loading}
			/>
			<BasicListItem
				label="Payout Amount"
				loading={loading}
				value={pickup?.invoice?.amount
					? formatCurrency(pickup.invoice.amount)
					: "Not Quoted"
				}
			/>
		</List>
	);
};