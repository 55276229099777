import { SvgIcon, SvgIconProps } from "@mui/material";

	export const MiniFridge: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m302.42 791.78c14.496 0 26.25-11.754 26.25-26.25v-292.73c0-14.496-11.754-26.25-26.25-26.25s-26.25 11.754-26.25 26.25v292.73c0 14.488 11.754 26.246 26.25 26.246z" />
		<path
			d="m942.28 34.785h-684.56c-56.895 0-103.18 46.289-103.18 103.18v808.53c0 45.531 29.676 84.195 70.672 97.852v44.477c0 42.121 34.262 76.387 76.387 76.387h1.6484c42.121 0 76.387-34.262 76.387-76.387v-39.148h440.75v39.148c0 42.121 34.262 76.387 76.387 76.387h1.6484c42.121 0 76.387-34.262 76.387-76.387v-44.477c41.004-13.656 70.68-52.328 70.68-97.859l0.003906-731.6v-76.922c-0.023437-56.895-46.312-103.18-103.21-103.18zm-735.24 103.18c0-27.945 22.738-50.684 50.676-50.684h684.56c27.953 0 50.684 22.738 50.684 50.684v50.672h-785.92zm120.09 950.86c0 13.172-10.719 23.887-23.887 23.887h-1.6484c-13.172 0-23.887-10.719-23.887-23.887v-39.148h49.418l-0.003906 39.148zm595.15 0c0 13.172-10.719 23.887-23.887 23.887h-1.6484c-13.172 0-23.887-10.719-23.887-23.887v-39.148h49.418l-0.003906 39.148zm70.68-142.33c0 27.953-22.734 50.684-50.684 50.684h-684.56c-27.945 0-50.676-22.734-50.676-50.684v-705.36h785.93l-0.003906 705.36z" />
		<path
			d="m600 415.09c62.348 0 109.36-26.594 109.36-61.867 0-35.27-47.016-61.867-109.36-61.867s-109.36 26.594-109.36 61.867 47.008 61.867 109.36 61.867zm0-86.234c44.715 0 71.863 16.926 71.863 24.367s-27.148 24.367-71.863 24.367-71.863-16.926-71.863-24.367c0-7.4375 27.148-24.367 71.863-24.367z" />
		<path
			d="m430.58 727.57c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h48.234l-48.441 48.441c-7.3281 7.3203-7.3281 19.191 0 26.512 0.12891 0.12891 0.27734 0.21094 0.40625 0.32812 0.12109 0.12891 0.20312 0.27734 0.32812 0.40625 3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l48.449-48.449v48.234c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-85.734l36.789-36.789v47.496c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-48.234l37.523 37.523v85.734c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-48.234l48.441 48.441c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883 0.12891-0.12891 0.21094-0.27734 0.32812-0.40625 0.12891-0.12109 0.27734-0.20313 0.40625-0.32813 7.3281-7.3281 7.3281-19.191 0-26.512l-48.441-48.441h48.234c10.359 0 18.75-8.3906 18.75-18.75 0-10.359-8.3906-18.75-18.75-18.75l-85.766 0.003906-37.523-37.523h48.234c10.359 0 18.75-8.3906 18.75-18.75s-8.3906-18.75-18.75-18.75h-47.496l36.789-36.789h85.734c10.359 0 18.75-8.3906 18.75-18.75s-8.3906-18.75-18.75-18.75h-48.234l48.441-48.441c7.3281-7.3281 7.3281-19.191 0-26.512-0.12109-0.12109-0.26172-0.19531-0.38281-0.31641-0.12891-0.13672-0.21875-0.29297-0.34375-0.42578-7.3281-7.3281-19.191-7.3281-26.512 0l-48.441 48.441v-48.227c0-10.359-8.3906-18.75-18.75-18.75s-18.75 8.3906-18.75 18.75v85.734l-36.789 36.797v-47.496c0-10.359-8.3906-18.75-18.75-18.75s-18.75 8.3906-18.75 18.75v48.234l-37.523-37.523-0.003907-85.734c0-10.359-8.3906-18.75-18.75-18.75-10.359 0-18.75 8.3906-18.75 18.75v48.234l-48.449-48.449c-7.3281-7.3281-19.191-7.3281-26.512 0-0.13672 0.13672-0.21875 0.29297-0.34375 0.42578-0.12109 0.11328-0.26172 0.19531-0.38281 0.31641-7.3281 7.3281-7.3281 19.199 0 26.512l48.441 48.441h-48.234c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h85.734l37.523 37.523h-48.227c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h47.496l-36.797 36.789z" />
	</g>
</svg>
			</SvgIcon>
		);
	};