import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Mattress: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m889.27 38.004h-578.55c-52.215 0-94.695 42.48-94.695 94.695v934.6c0 52.215 42.48 94.695 94.695 94.695h578.54c52.215 0 94.695-42.48 94.695-94.695l-0.003906-934.6c0.007812-52.215-42.477-94.695-94.688-94.695zm-620.74 94.695c0-23.266 18.93-42.195 42.195-42.195h578.54c23.273 0 42.195 18.93 42.195 42.195v813.86c0 23.273-18.922 42.195-42.195 42.195l-578.54-0.003906c-23.266 0-42.195-18.922-42.195-42.195zm620.74 976.8h-578.55c-23.266 0-42.195-18.922-42.195-42.195v-36.098c12.727 6.3672 27.023 10.043 42.195 10.043h578.54c15.172 0 29.469-3.6758 42.195-10.043v36.098c0.003906 23.266-18.926 42.195-42.191 42.195z" />
		<path
			d="m383.39 577.64c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3438 7.3086-7.3438 19.191-0.023437 26.512z" />
		<path
			d="m383.39 914.43c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3281 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3281 7.3281-19.191 0-26.512s-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3203 7.3281-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3438 7.3203-7.3438 19.184-0.023437 26.512z" />
		<path
			d="m562.25 914.43c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.23-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883 7.3281-7.3281 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3281 7.3281-19.191 0-26.512s-19.191-7.3281-26.512 0l-11.254 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3203 7.3281-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3203 7.3203-7.3203 19.184 0 26.512z" />
		<path
			d="m562.25 577.64c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.23-11.246 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883 7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.254 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3203 7.3125-7.3203 19.195 0 26.516z" />
		<path
			d="m471.71 746.03c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3281 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3281 7.3281-19.191 0-26.512-7.3281-7.3203-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3203 7.3281-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3477 7.3203-7.3477 19.184-0.023437 26.512z" />
		<path
			d="m383.39 240.58c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3438 7.3203-7.3438 19.191-0.023437 26.512z" />
		<path
			d="m562.25 240.58c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.23-11.234 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883 7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.254 11.23-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3203 7.3242-7.3203 19.195 0 26.516z" />
		<path
			d="m471.71 408.98c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3477 7.3164-7.3477 19.191-0.023437 26.512z" />
		<path
			d="m741.11 577.64c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3516 7.3086-7.3516 19.191-0.023437 26.512z" />
		<path
			d="m741.11 914.43c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3281 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3281 7.3281-19.191 0-26.512s-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3203 7.3281-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3516 7.3203-7.3516 19.184-0.023437 26.512z" />
		<path
			d="m652.78 746.03c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3281 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3281 7.3281-19.191 0-26.512-7.3281-7.3203-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3203 7.3281-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3438 7.3203-7.3438 19.184-0.023437 26.512z" />
		<path
			d="m741.11 240.58c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3516 7.3203-7.3516 19.191-0.023437 26.512z" />
		<path
			d="m652.78 408.98c3.6602 3.6602 8.4609 5.4883 13.262 5.4883 4.8008 0 9.5938-1.8281 13.262-5.4883l11.242-11.242 11.242 11.242c3.6602 3.6602 8.4609 5.4883 13.262 5.4883s9.5938-1.8281 13.262-5.4883c7.3281-7.3203 7.3281-19.191 0-26.512l-11.242-11.242 11.242-11.242c7.3281-7.3203 7.3281-19.191 0-26.512-7.3281-7.3281-19.191-7.3281-26.512 0l-11.242 11.242-11.242-11.242c-7.3281-7.3281-19.191-7.3281-26.512 0-7.3281 7.3203-7.3281 19.191 0 26.512l11.242 11.242-11.242 11.242c-7.3438 7.3164-7.3438 19.191-0.023437 26.512z" />
	</g>
</svg>
			</SvgIcon>
		);
	};