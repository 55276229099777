import React, { useMemo } from "react";
import { Pagination } from "../pagination";
import { PickupProductListItem } from "./PickupProductListItem";
import { FragmentType } from "../../graphql";
import { Pickup_IncomingFragmentFragment } from "../../graphql/__generated__/graphql";

export const PickupIncomingItemsContainer: React.FC<{
	loading: boolean;
	pickup: FragmentType<Pickup_IncomingFragmentFragment>;
}> = ({ pickup, loading }) => {

	const products = useMemo(() => {
		return pickup?.incomingProducts ?? [];
	}, [ pickup ]);

	return (
		<Pagination
			pageSize={4}
		>
			{products.map((product) => (
				<PickupProductListItem
					key={product.id}
					loading={loading}
					product={product}
				/>
			))}
		</Pagination>
	);
};
