import { useHauler } from "../hooks";
import { HaulerQuotesTabController } from "../components";
import { ViewContainer } from "../../../components";

export const HaulerQuotesView: React.FC = () => {
	const { hauler } = useHauler();

	return (
		<ViewContainer>
			{hauler && (
				<HaulerQuotesTabController
					haulerId={hauler.id}
				/>
			)}
		</ViewContainer>
	);
};