import { useMutation } from "@apollo/client";
import { Box, Spinner } from "grommet";
import { FindHaulerPendingQuoteRequests, RejectHaulerQuoteRequest } from "../../../../graphql";
import { Chip, IconButton, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { UpsertHaulerQuoteDialog } from "@features/hauler/components/quote/UpsertHaulerQuoteDialog";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

interface HaulerPendingQuoteRequestListItemProps {
	orderId: string;
	requestId: string;
	marketCode: string;
	productCount: number;
	customerNameFormatted: string;
}

export const HaulerPendingQuoteRequestListItem: React.FC<HaulerPendingQuoteRequestListItemProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const isAccepting = false;

	const [ isCreatingQuote, setIsCreatingQuote ] = useState(false);

	const [
		rejectRequest, { loading: isRejecting }
	] = useMutation(RejectHaulerQuoteRequest, {
		refetchQueries: [ FindHaulerPendingQuoteRequests ]
	});

	function handleRejectQuote() {
		rejectRequest({
			variables: {
				requestId: props.requestId
			}
		}).catch(err => {
			console.error("Failed to reject quote request: ", err);
			snack.enqueueSnackbar("We ran into an issue processing your request. Please try again,", {
				variant: "error"
			});
		});
	}
	return (
		<Fragment>
			{isCreatingQuote && (
				<UpsertHaulerQuoteDialog
					onClose={() => setIsCreatingQuote(false)}
					requestId={props.requestId}
					pickupOptions={[]}
				/>
			)}
			<ListItemButton
				divider
				onClick={() => {
					dispatch(push(`/hauler/orders/${props.orderId}`));
				}}
			>
				<ListItemText>
					<Box gap="xsmall">
						<Typography fontSize="18px" fontWeight="bold">{props.customerNameFormatted}</Typography>
						<Box direction="row" gap="small">
							<Typography variant="caption">2 - 3 stop(s)</Typography>
							<Typography variant="caption">|</Typography>
							<Typography variant="caption">{props.productCount} item(s)</Typography>
						</Box>
					</Box>
				</ListItemText>
				<ListItemSecondaryAction>
					<Box direction="row" gap="small">
						<Box align="center" justify="center">
							<Chip
								color="secondary"
								label={<Typography fontWeight="bold" variant="caption">{props.marketCode}</Typography>} />
						</Box>
						<Box direction="row">
							<Box align="center" justify="center">
								<IconButton
									color="success"
									onClick={(event) => {
										event.preventDefault();
										setIsCreatingQuote(true);
									}}
									disabled={isRejecting}
								>
									{(isAccepting)
										? (
											<Spinner />
										)
										: (
											<CheckCircle />
										)}
								</IconButton>
							</Box>
							<Box align="center" justify="center">
								<IconButton
									color="error"
									onClick={(event) => {
										event.preventDefault();
										handleRejectQuote();
									}}
									disabled={isRejecting || isAccepting}
								>
									{(isRejecting)
										? (
											<Spinner />
										)
										: (
											<Cancel />
										)}
								</IconButton>
							</Box>
						</Box>

					</Box>
				</ListItemSecondaryAction>
			</ListItemButton>
		</Fragment>
	);
};
