import { gql } from "@apollo/client";

export const Pickup_CombinedFragment = gql`
	fragment Pickup_CombinedFragment on Pickup {
		...Pickup_HeaderFragment
		...Pickup_AssignmentFragment
		...Pickup_TrackingFragment
		...Pickup_InvoiceFragment
		...Pickup_CountFragment
		...Pickup_StopsFragment
		...Pickup_IncomingFragment
		...Pickup_ActiveStopFragment
	}
`;