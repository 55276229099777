import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { DialogWithActivatorButton } from "../../../../../components";
import { useUserQuery } from "../../../../../hooks/useUserQuery";
import { useEffect } from "react";
import { Box } from "grommet";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { CopyAll, Email, PhoneIphoneSharp } from "@mui/icons-material";
import { useClipboard } from "../../../../../hooks";
import { formatPhoneNumber, isPhoneNumber } from "../../../../../helpers";
import { useToggleForceClose } from "../../../components";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";

export const UserDetailsDialog: React.FC<{
	userId: string;
	permitViewMore?: boolean;
	activatorButton: React.ReactNode;
}> = ({ userId, activatorButton, permitViewMore }) => {
	const { user } = useUserQuery(userId);

	const formContext = useForm({
		defaultValues: {
			firstName: "",
			lastName: "",
			primaryPhoneNumber: "",
			primaryEmailAddress: "",
		}
	});

	const { primaryEmailAddress, primaryPhoneNumber } = formContext.watch();

	useEffect(() => {
		if(user) {
			formContext.reset({
				firstName: user.firstName,
				lastName: user.lastName,
				primaryPhoneNumber: user.primaryPhoneNumber && isPhoneNumber(user.primaryPhoneNumber)
					? formatPhoneNumber(user.primaryPhoneNumber)
					: user.primaryPhoneNumber || "",
				primaryEmailAddress: user.primaryEmailAddress || "",
			});
		}
	}, [ user ]);

	const dispatch = useAppDispatch();
	const { copy } = useClipboard();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	return (
		<DialogWithActivatorButton
			title="User Details"
			forceClose={forceClose}
			activatorButton={activatorButton}
			actions={permitViewMore && (
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={toggleForceClose}
					>
						Close
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={(() => {
							dispatch(push(`/admin/users/${userId}`));
							toggleForceClose();
						})}
					>
						View More
					</Button>
				</Box>
			)}
		>
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<TextFieldElement
						name="firstName"
						label="First Name"
						disabled
					/>
					<TextFieldElement
						name="lastName"
						label="Last Name"
						disabled
					/>
					<TextFieldElement
						disabled
						name="primaryPhoneNumber"
						label="Phone Number"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<PhoneIphoneSharp />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => {
										copy(primaryPhoneNumber);
									}}>
										<CopyAll />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					<TextFieldElement
						disabled
						name="primaryEmailAddress"
						label="Email Address"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Email />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => {
										copy(primaryEmailAddress);
									}}>
										<CopyAll />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};