import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ChinaCabinet: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1089.6 110.41c-4.8828-7.2148-13.027-11.535-21.734-11.535h-223.82c-21.047 0-40.941-8.0703-56.055-22.711-24.945-24.203-57.832-37.531-92.594-37.531l-95.414 0.003906h-95.414c-34.762 0-67.648 13.328-92.602 37.531-15.105 14.648-35.004 22.711-56.047 22.711l-223.82-0.003906c-8.7148 0-16.859 4.3203-21.734 11.535-4.8828 7.2148-5.8711 16.387-2.6328 24.473l34.836 87c3.9883 9.9688 13.637 16.492 24.367 16.492h24.953v896.75c0 14.496 11.754 26.25 26.25 26.25h63.047c11.094 0 20.984-6.9766 24.719-17.414l34.738-97.207h518.68l34.738 97.207c3.7344 10.449 13.629 17.414 24.719 17.414h63.047c14.496 0 26.25-11.754 26.25-26.25v-114.62l0.003907-279.41v-502.73h24.945c10.734 0 20.387-6.5312 24.367-16.492l34.836-87c3.2344-8.0898 2.25-17.258-2.6328-24.473zm-489.62 127.96h101.04v476.48h-202.07v-476.48zm-277.84 755.88h-77.762v-226.91h202.07v226.91zm-77.762-381.64h82.281v102.23l-82.281-0.003906zm119.77 0h82.289v102.23l-82.289-0.003906zm82.293-37.5h-82.293v-197h82.289zm-119.79 0h-82.273v-197h82.281v197zm172.29 192.24h202.07v87.203h-202.07zm254.57-154.74h82.281v102.23h-82.281zm119.78 0h82.289v102.23h-82.289zm82.289-37.5h-82.289v-197h82.289zm-119.79 0h-82.281l-0.003906-197h82.281zm-82.277-234.5v-102.24h82.281v102.24zm-307.07 0h-82.293v-102.24h82.289zm-119.79 0h-82.273v-102.24h82.281v102.24zm-63.973 768.26h-18.301v-62.121h40.5zm236.27-201.83h202.07v87.203h-202.07zm254.57-26.25v-113.45h202.07v226.91h-202.07zm202.07 228.08h-18.301l-22.199-62.121h40.5zm0-768.26h-82.289v-102.24h82.289zm59.676-154.74h-830.56l-13.816-34.5h185.04c34.762 0 67.648-13.328 92.594-37.523 15.113-14.648 35.012-22.719 56.055-22.719l95.41 0.003906h95.422c21.047 0 40.941 8.0703 56.047 22.719 24.953 24.203 57.84 37.523 92.602 37.523h185.03z"/>
  <path d="m398.37 824.92c-10.359 0-18.75 8.3906-18.75 18.75v74.258c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-74.258c0-10.359-8.3945-18.75-18.75-18.75z"/>
  <path d="m801.64 824.92c-10.359 0-18.75 8.3906-18.75 18.75v74.258c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-74.258c0-10.359-8.3984-18.75-18.75-18.75z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};