import { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { replace } from "redux-first-history";
import { useAppDispatch } from "../../../../store";
import { Chip } from "@mui/material";
import { ScheduleSend } from "@mui/icons-material";
import { SchedulePickupController } from "..";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";

interface UserDashboardSchedulePickupButtonProps {
	orderId: string;
	isScheduled: boolean;
	isSchedulePending: boolean;
	canScheduleOrReschedule: boolean;
}

export const UserDashboardSchedulePickupButton: React.FC<UserDashboardSchedulePickupButtonProps> = (props) => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [ query ] = useSearchParams();

	const [ isScheduling, setIsScheduling ] = useState(false);

	useEffect(() => {
		if(query.get("schedule") === "true") {
			setIsScheduling(true);
			const newQuery = new URLSearchParams(query);
			newQuery.delete("schedule");
			dispatch(replace(location.pathname + "?" + newQuery.toString()));
		}
	}, [ query ]);

	useEffect(() => {
		if(query.get("redirect_status")) {
			setIsScheduling(true);
		}
	}, [ query ]);

	const action = useMemo(() => {
		if(!props.isScheduled && !props.isSchedulePending) {
			return (
				<Chip
					color="warning"
					label="Pending"
				/>
			);
		}
		if(props.isScheduled) {
			return (
				<LoadingButton
					size="small"
					color="primary"
					variant="contained"
					disabled={!props.canScheduleOrReschedule}
					onClick={() => setIsScheduling(true)}
					startIcon={<ScheduleSend />}
					loadingPosition="start"
				>
					Reschedule
				</LoadingButton>
			);
		}

		return (
			<LoadingButton
				size="small"
				color="primary"
				variant="contained"
				disabled={!props.canScheduleOrReschedule}
				onClick={() => setIsScheduling(true)}
				startIcon={<ScheduleSend />}
				loadingPosition="start"
			>
				Schedule
			</LoadingButton>
		);
	}, [ props ]);

	return (
		<SchedulePickupController
			forceOpen={isScheduling}
			activatorButton={action}
			orderId={props.orderId}
		/>
	);
};
