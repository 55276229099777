import React, { ReactNode, useMemo } from "react";
import { useLoginGuard } from "../auth/hooks/useLoginGuard";
import { useAuthorize } from "../auth/hooks/useAuthorize";
import { PageNotPermittedView } from "../views";
import { SplashScreen } from "../components";
import { AuthorizeGql, AuthorizePartnerGql } from "../graphql";
import { usePartnerRoles } from "../features/admin/hooks";

export const RoleGuard: React.FC<{
	children: ReactNode;
	resolver: (result: AuthorizeGql) => boolean;
}> = ({ children, resolver }) => {
	useLoginGuard();
	const { isAuthorized, loading } = useAuthorize(resolver);

	if(!loading && !isAuthorized) {
		return (
			<PageNotPermittedView />
		);
	}

	return (
		<SplashScreen visible={loading} >
			{children}
		</SplashScreen>
	);
};

export const PartnerRoleGuard: React.FC<{
	children: ReactNode;
	partnerId: string;
	resolver: (result: AuthorizePartnerGql) => boolean;
}> = ({ children, partnerId, resolver }) => {
	useLoginGuard();
	const { roles, loading } = usePartnerRoles(partnerId);

	const isAuthorized = useMemo(() => {
		if(!roles) {
			return false;
		}

		return resolver(roles);
	}, [ roles, resolver ]);

	if(!loading && !isAuthorized) {
		return (
			<PageNotPermittedView />
		);
	}

	return (
		<SplashScreen visible={loading} >
			{children}
		</SplashScreen>
	);
};