import { gql } from "@apollo/client";

export const ReferenceMedia_HeaderFragment = gql`
	fragment ReferenceMedia_HeaderFragment on ReferenceMedia {
		id
		name
		contentUrl
		contentType
		context
		createdAt
	}
`;