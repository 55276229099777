import { Box } from "grommet";
import { ReactNode, useMemo } from "react";
import { useWindowDimensions } from "../hooks";

export function useMainContentHeight() {
	const { headerContentHeight, height: clientHeight } = useWindowDimensions();

	const footerElements = useMemo(() => {
		return document.querySelectorAll(".footer");
	}, [ document.querySelectorAll(".footer") ]);

	const bottomMargin = useMemo(() => {
		return Array.from(footerElements).reduce((prev, el) => {
			return prev + el.clientHeight;
		}, 0);
	}, [ footerElements ]);

	const minHeight = useMemo(() => {
		return clientHeight - headerContentHeight - bottomMargin - 10;
	}, [ clientHeight, headerContentHeight, bottomMargin ]);

	return minHeight;
}

export const Main: React.FC<{ children: ReactNode; }> = (props) => {
	const minHeight = useMainContentHeight();

	return (
		<Box width="100%" style={{ height: `${minHeight}px` }} id="main" flex>
			{props.children}
		</Box>
	);
};