import { gql } from "@apollo/client";

export const Instance_HeaderFragment = gql`
	fragment Instance_HeaderFragment on Instance {
		id
		name
		type
		domain
		features
		...Instance_CustomizationsFragment
	}
`;