import { SvgIconProps } from "@mui/material";
import * as icons from "./icons";

export function resolveFromUrl(url: string): React.FC<SvgIconProps> | null {
	if(url.includes("@liverego/icons")) {
		const icon = url.split("@liverego/icons/")[ 1 ];
		if(icons[ icon as keyof typeof icons ]) {
			return icons[ icon as keyof typeof icons ];
		}
	}
	return null;
}