import { DocumentType, gql } from "../__generated__";

export const ListMarkets = gql(/* GraphQL */`
	query ListMarkets {
		ListMarkets {
			id
			code
			name
			displayName
		}
	}
`);

export type MarketGql = DocumentType<typeof ListMarkets>[ "ListMarkets" ][ number ];