import { useQuery } from "@apollo/client";
import { FetchWasteContainerAssignment } from "../../../../graphql";

export function useWasteContainerAssignment(assignmentId: string) {
	const { data, loading } = useQuery(FetchWasteContainerAssignment, {
		skip: !assignmentId,
		variables: {
			assignmentId
		}
	});

	return {
		assignment: data?.FetchWasteContainerAssignment || null,
		loading
	};
}