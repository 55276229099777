import { Box, Grid, Heading, Text } from "grommet";
import { ReactNode, useMemo } from "react";
import { useWindowDimensions } from "../../../../../hooks";
import { selectIsPartyMode, useAppSelector } from "../../../../../store";

interface DashboardCardProps {
	icon: JSX.Element;
	title: string;
	value: string;
	children?: ReactNode;
	className?: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
	const { sizeIndex } = useWindowDimensions();

	const shrink = useMemo(() => {
		return sizeIndex <= 2;
	}, [ sizeIndex ]);

	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<Box
			className={props.className}
			background="white" round elevation="medium" pad="small" hoverIndicator onClick={() => {
				//
			}}>
			<Grid gap="small" columns={[ "auto", "flex" ]}>
				<Box align="center" justify="center">
					{props.icon}
				</Box>
				<Box align="end" gap="small">
					<Text
						weight="bold"
						color="status-disabled"
						size={shrink ? "small" : "medium"}
					>
						{props.title}
					</Text>
					<Heading
						level={shrink ? 4 : 3}
						margin="none"
						color={isPartyMode ? "white" : "brand"}
						textAlign="end"
					>
						{props.value}
					</Heading>
				</Box>
			</Grid>
			{props.children}
		</Box>
	);
};