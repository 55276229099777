import React from "react";
import { SelectElement } from "react-hook-form-mui";
import { WasteContainerVolumeUnit } from "../../../../../graphql/__generated__/graphql";
import { volumeUnitEnumToFriendly } from "../../helpers";

export const VolumeUnitElement: React.FC = () => {
	return (
		<SelectElement
			name="volumeUnit"
			label="Container Volume Unit"
			helperText="What is the container measured in?"
			options={Object.values(WasteContainerVolumeUnit).map(unit => ({
				id: unit,
				label: volumeUnitEnumToFriendly(unit)
			}))}
		/>
	);
};
