import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions } from "@mui/material";
import { Box, Text } from "grommet";
import { useSnackbar } from "notistack";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement, CheckboxElement, ValidationRule } from "react-hook-form-mui";
import { useUser } from "../../../../auth";
import { UserContactGql, CreateContact, FetchSelf, BeginVerifyContact, VerifyContact } from "../../../../graphql";
import { ContactType } from "../../../../graphql/__generated__/graphql";
import { formatPhoneNumber, isEmailRegExp, isPhoneNumberRegExp } from "../../../../helpers";
import { useFullScreen } from "../../../../hooks";
import { Close, Save } from "@mui/icons-material";

interface ContactFormDialogProps {
	onClose(): void;
	type: ContactType;
	contact?: UserContactGql;
}

export const ContactFormDialog: React.FC<ContactFormDialogProps> = (props) => {
	const snack = useSnackbar();
	const { user, contacts } = useUser();
	const fulScreen = useFullScreen();

	const [ wasCodeSent, setWasCodeSent ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ contact, setContact ] = useState(props.contact);

	const useFormReturn = useForm({
		defaultValues: {
			type: props.type,
			code: "",
			value: props.contact?.value ?? "",
			isPrimary: props.contact?.isPrimary || true
		}
	});

	const formValues = useFormReturn.watch();

	const [
		createContactMutation,
		{ loading: isCreating, data: createContactData }
	] = useMutation(CreateContact, { refetchQueries: [ FetchSelf ] });

	function createContact() {
		createContactMutation({
			variables: {
				userId: user?.id ?? "",
				type: formValues.type,
				value: formValues.value,
				isPrimary: formValues.isPrimary
			}
		}).catch(err => {
			console.error("Failed to create contact", err);
			snack.enqueueSnackbar("We ran into an issue updating your contact information", {
				variant: "error"
			});
		});
	}

	useEffect(() => {
		if(createContactData?.CreateContact) {
			setContact(
				contacts.find(c => c.id === createContactData.CreateContact.id)
			);
		}
	}, [ contacts, createContactData ]);

	const [
		beginContactVerificationMutation,
		{ loading: isLoadingBeginVerification }
	] = useMutation(BeginVerifyContact);

	const beginContactVerification = useCallback((contactId: string) => {
		beginContactVerificationMutation({
			variables: {
				contactId: contactId
			}
		}).then(res => {
			const data = res.data?.BeginVerifyContact;
			if(data?.wasSent) {
				setWasCodeSent(true);
				return;
			}

			if(data?.wasSent === false) {
				setErrorMessage("Failed to send verification code");
				return;
			}
		}).catch(err => {
			console.error("Failed to send verification code", err);
			snack.enqueueSnackbar(
				"We ran into an issue sending a verification code",
				{ variant: "error" }
			);
		});
	}, [ beginContactVerificationMutation, snack ]);

	useEffect(() => {
		if(contact && !contact.isVerified) {
			beginContactVerification(contact.id);
		}
	}, [ contact, beginContactVerification ]);

	const [
		verifyContactMutation,
		{ loading: isVerifying }
	] = useMutation(VerifyContact, { refetchQueries: [ FetchSelf ] });

	function verifyContact() {
		if(!contact) return;

		verifyContactMutation({
			variables: {
				contactId: contact.id,
				code: formValues.code
			}
		}).then(res => {
			props.onClose();
		}).catch(err => {
			console.error("Failed to verify contact", err);
			snack.enqueueSnackbar("We weren't able to verify your contact", {
				variant: "error"
			});
		});
	}

	const isLoading = useMemo(() => {
		return isCreating || isVerifying || isLoadingBeginVerification;
	}, [ isCreating, isVerifying, isLoadingBeginVerification ]);

	const usernameFormatted = useMemo(() => {
		if(contact) {
			switch(props.type) {
				case ContactType.Email:
					return contact.value.toLowerCase();
				case ContactType.Phone:
					return formatPhoneNumber(contact.value);
			}
		}

		return "";
	}, [ contact, props.type ]);

	const usernameLabel = useMemo(() => {
		switch(props.type) {
			case ContactType.Email:
				return "Email Address";
			case ContactType.Phone:
				return "Phone Number";
		}
	}, [ props.type ]);

	const usernameValidation = useMemo((): ValidationRule<RegExp> => {
		return {
			value: (props.type === ContactType.Email)
				? isEmailRegExp
				: isPhoneNumberRegExp,
			message: `Please enter a valid ${usernameLabel.toLocaleLowerCase()}`
		};
	}, [ props.type, usernameLabel ]);

	function handleSubmit() {
		if(!contact) {
			createContact();
			return;
		}

		if(!wasCodeSent) {
			beginContactVerification(contact.id);
		}

		verifyContact();
	}

	return (
		<Dialog fullScreen={fulScreen} fullWidth open={true}>
			<FormContainer
				onSuccess={handleSubmit}
				formContext={useFormReturn}
			>
				<DialogTitle>
					<Box direction="row" justify="between">
						<Box align="center" justify="center" gap="small">
							<Typography fontWeight="bold" variant="h5">
								{props.contact
									? "Verify Contact"
									: "Create Contact"
								}
							</Typography>
						</Box>
						<IconButton onClick={props.onClose}>
							<Close />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<Box gap="medium">
						<Box gap="medium" pad="small">
							<TextFieldElement
								required
								name="value"
								label={usernameLabel}
								validation={{
									required: {
										value: true,
										message: "This field is required"
									},
									pattern: usernameValidation
								}}
							/>
							{wasCodeSent && (
								<TextFieldElement
									name="code"
									required
									label="Verification Code"
									validation={{
										required: {
											value: true,
											message: "This field is required"
										},
										pattern: {
											value: /^\d{6}$/,
											message: "Please enter a valid verification code"
										}
									}}
								/>
							)}
							{!props.contact && (
								<Box pad={{ left: "xsmall" }}>
									<CheckboxElement
										size="medium"
										name="isPrimary"
										label={<Text margin={{ left: "small" }} weight="bold">Make primary?</Text>}
									/>
								</Box>
							)}
							{wasCodeSent && (
								<Text>We just sent a 6 digit code to <Text weight="bold">{usernameFormatted}</Text>. Enter it to verify your {usernameLabel.toLocaleLowerCase()}.</Text>
							)}
							{errorMessage && (
								<Typography color="error">
									{errorMessage}
								</Typography>
							)}
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Box direction="row" justify="end" align="end" pad="small">
						<LoadingButton
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<Save />}
							loadingPosition="end"
							loading={isLoading}
						>
							Submit
						</LoadingButton>
					</Box>
				</DialogActions>
			</FormContainer>
		</Dialog>
	);
};
