import Constants from "../constants";
export var pieces = ["I", "J", "L", "O", "S", "T", "Z"];
export function splitIndexedPiece(piece) {
    var _a = piece.split("-"), p = _a[0], index = _a[1], rotation = _a[2];
    return [
        p,
        Number(index),
        Number(rotation)
    ];
}
export function extractPiece(piece) {
    return splitIndexedPiece(piece)[0];
}
export function extractIndex(piece) {
    return splitIndexedPiece(piece)[1];
}
export function extractRotation(piece) {
    return splitIndexedPiece(piece)[2];
}
export var isRotation = function (num) {
    return num >= 0 && num < Constants.ROTATION_COUNT;
};
export var getBlocks = function (piece) {
    switch (piece) {
        case "I":
            return [
                [
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 2 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 3 }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 3 }, { visible: true, index: 2 }, { visible: true, index: 1 }, { visible: true, index: 0 }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: false }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: true, index: 2 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: true, index: 1 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: true, index: 0 }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: true, index: 2 }, { visible: true, index: 3 }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ]
            ];
        case "J":
            return [
                [
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 3 }, { visible: true, index: 2 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: true, index: 3 }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 1 }, { visible: true, index: 0 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: true, index: 2 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ]
            ];
        case "L":
            return [
                [
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 1 }, { visible: true, index: 0 }, { visible: false }],
                    [{ visible: true, index: 3 }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: true, index: 3 }, { visible: true, index: 2 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: false }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: true, index: 2 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ]
            ];
        case "O":
            return [
                [
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ]
            ];
        case "S":
            return [
                [
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 0 }, { visible: true, index: 1 }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: true, index: 2 }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 3 }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: true, index: 3 }, { visible: true, index: 2 }, { visible: false }],
                    [{ visible: true, index: 1 }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 0 }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: true, index: 2 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ]
            ];
        case "T":
            return [
                [
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: true, index: 2 }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 3 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 3 }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 2 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: true, index: 3 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 1 }, { visible: true, index: 0 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: true, index: 2 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ]
            ];
        case "Z":
            return [
                [
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 0 }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 2 }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 2 }, { visible: true, index: 1 }, { visible: false }, { visible: false }],
                    [{ visible: true, index: 3 }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: true, index: 3 }, { visible: true, index: 2 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: true, index: 0 }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ],
                [
                    [{ visible: false }, { visible: false }, { visible: true, index: 3 }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 1 }, { visible: true, index: 2 }, { visible: false }],
                    [{ visible: false }, { visible: true, index: 0 }, { visible: false }, { visible: false }],
                    [{ visible: false }, { visible: false }, { visible: false }, { visible: false }]
                ]
            ];
        default: {
            throw new Error("Unhandled color case: ".concat(piece));
        }
    }
};
export var getRotationClassName = function (rotation) {
    if (rotation === undefined)
        return "";
    switch (rotation) {
        case 0:
            return "rotate-0";
        case 1:
            return "rotate-90";
        case 2:
            return "rotate-180";
        case 3:
            return "rotate-270";
        default: {
            var exhaustiveCheck = rotation;
            throw new Error("Unhandled rotation case: ".concat(exhaustiveCheck));
        }
    }
};
export var getClassName = function (piece, rotation) {
    switch (piece) {
        case "I-0":
            return "piece-i piece-i-".concat(0, " ").concat(getRotationClassName(rotation));
        case "I-1":
            return "piece-i piece-i-".concat(1, " ").concat(getRotationClassName(rotation));
        case "I-2":
            return "piece-i piece-i-".concat(2, " ").concat(getRotationClassName(rotation));
        case "I-3":
            return "piece-i piece-i-".concat(3, " ").concat(getRotationClassName(rotation));
        case "J-0":
            return "piece-j piece-j-".concat(0, " ").concat(getRotationClassName(rotation));
        case "J-1":
            return "piece-j piece-j-".concat(1, " ").concat(getRotationClassName(rotation));
        case "J-2":
            return "piece-j piece-j-".concat(2, " ").concat(getRotationClassName(rotation));
        case "J-3":
            return "piece-j piece-j-".concat(3, " ").concat(getRotationClassName(rotation));
        case "L-0":
            return "piece-l piece-l-".concat(0, " ").concat(getRotationClassName(rotation));
        case "L-1":
            return "piece-l piece-l-".concat(1, " ").concat(getRotationClassName(rotation));
        case "L-2":
            return "piece-l piece-l-".concat(2, " ").concat(getRotationClassName(rotation));
        case "L-3":
            return "piece-l piece-l-".concat(3, " ").concat(getRotationClassName(rotation));
        case "O-0":
            return "piece-o piece-o-".concat(0, " ").concat(getRotationClassName(rotation));
        case "O-1":
            return "piece-o piece-o-".concat(1, " ").concat(getRotationClassName(rotation));
        case "O-2":
            return "piece-o piece-o-".concat(2, " ").concat(getRotationClassName(rotation));
        case "O-3":
            return "piece-o piece-o-".concat(3, " ").concat(getRotationClassName(rotation));
        case "S-0":
            return "piece-s piece-s-".concat(0, " ").concat(getRotationClassName(rotation));
        case "S-1":
            return "piece-s piece-s-".concat(1, " ").concat(getRotationClassName(rotation));
        case "S-2":
            return "piece-s piece-s-".concat(2, " ").concat(getRotationClassName(rotation));
        case "S-3":
            return "piece-s piece-s-".concat(3, " ").concat(getRotationClassName(rotation));
        case "T-0":
            return "piece-t piece-t-".concat(0, " ").concat(getRotationClassName(rotation));
        case "T-1":
            return "piece-t piece-t-".concat(1, " ").concat(getRotationClassName(rotation));
        case "T-2":
            return "piece-t piece-t-".concat(2, " ").concat(getRotationClassName(rotation));
        case "T-3":
            return "piece-t piece-t-".concat(3, " ").concat(getRotationClassName(rotation));
        case "Z-0":
            return "piece-z piece-z-".concat(0, " ").concat(getRotationClassName(rotation));
        case "Z-1":
            return "piece-z piece-z-".concat(1, " ").concat(getRotationClassName(rotation));
        case "Z-2":
            return "piece-z piece-z-".concat(2, " ").concat(getRotationClassName(rotation));
        case "Z-3":
            return "piece-z piece-z-".concat(3, " ").concat(getRotationClassName(rotation));
        case "ghost":
            return "piece-preview";
        default: {
            throw new Error("Unhandled piece case: ".concat(piece));
        }
    }
};
