import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { useFeature } from "../../instance/hooks/useFeature";

export function useStorePermissions() {
	const isPermitted = true;
	const isEnabled = useFeature(InstanceFeature.SalesEnabled);

	const role: "admin" | "standard" | "reporting" = "admin";

	return {
		role,
		isEnabled,
		isPermitted
	};
}