import { SvgIcon, SvgIconProps } from "@mui/material";

	export const BuffetTable: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m1134.9 204.69h-1069.8c-14.496 0-26.25 11.754-26.25 26.25v103.54c0 14.496 11.754 26.25 26.25 26.25h17.504v608.34c0 14.496 11.754 26.25 26.25 26.25h63.047c11.094 0 20.984-6.9766 24.719-17.414l34.738-97.207h737.31l34.738 97.207c3.7344 10.449 13.629 17.414 24.719 17.414h63.047c14.496 0 26.25-11.754 26.25-26.25v-114.62l0.003906-493.73h17.504c14.496 0 26.25-11.754 26.25-26.25v-103.53c0.015625-14.508-11.742-26.25-26.234-26.25zm-1043.6 52.5h1017.3v51.039h-17.504l-982.32-0.003907h-17.504v-51.035zm482.43 103.53v110.21h-137.48l-301.19 0.003906v-110.21zm491.16 162.71v304.75h-274.95l0.003906-304.75zm-852.07 304.75h-77.762v-304.75h274.94v304.75zm249.68-9.8984v-107.07l98.273 53.535zm137.47-32.184 76.336 41.586h-152.67zm39.195-21.352 98.273-53.535v107.07zm-39.195-21.355-90.105-49.086h180.21zm137.47-86.586h-274.94v-133.38h274.93zm-584.08 286h-18.301v-62.121h40.5zm911.53 0h-18.301l-22.199-62.121h40.5zm0-471.88h-438.66v-110.21h438.65v110.21z" />
		<path
			d="m313.21 434.58h82.426c10.359 0 18.75-8.3906 18.75-18.75s-8.3906-18.75-18.75-18.75h-82.426c-10.359 0-18.75 8.3906-18.75 18.75s8.3945 18.75 18.75 18.75z" />
		<path
			d="m354.42 619.44c-10.359 0-18.75 8.3906-18.75 18.75v74.723c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-74.723c0-10.359-8.3945-18.75-18.75-18.75z" />
		<path
			d="m804.37 434.58h82.426c10.359 0 18.75-8.3906 18.75-18.75s-8.3906-18.75-18.75-18.75h-82.426c-10.359 0-18.75 8.3906-18.75 18.75s8.3945 18.75 18.75 18.75z" />
		<path
			d="m845.58 731.66c10.359 0 18.75-8.3906 18.75-18.75v-74.723c0-10.359-8.3906-18.75-18.75-18.75s-18.75 8.3906-18.75 18.75v74.723c0 10.355 8.4023 18.75 18.75 18.75z" />
	</g>
</svg>
			</SvgIcon>
		);
	};