import { Box } from "grommet";
import { AvailablePickupListItem } from "../../../../components";
import { LinearProgress, List } from "@mui/material";
import { useUpcomingPickups } from "../../hooks/useUpcomingPickups";
import moment, { Moment } from "moment-timezone";
import { PickupWindow } from "../../../../graphql/__generated__/graphql";
import { useMemo } from "react";

export const UpcomingPickupsList: React.FC<{
	selectedId?: string;
	selectedDate?: Moment | null;
	onSelectDate?(date: Moment | null): void;
	onSelectPickup?(pickupId: string, window: PickupWindow, date: Moment | null): void;
}> = ({ selectedId, selectedDate, onSelectDate, onSelectPickup }) => {
	const { pickups, loading } = useUpcomingPickups();

	const sorted = useMemo(() => {
		return Array.from(pickups).sort((a, b) => {
			return moment(a.scheduledDate).isBefore(b.scheduledDate) ? -1 : 1;
		});
	}, [ pickups ]);

	function handleSelect(pickup: typeof pickups[ 0 ]) {
		if(onSelectPickup) {
			onSelectPickup(pickup.id, pickup.window, pickup.scheduledDate ? moment(pickup.scheduledDate) : null);
		}

		if(onSelectDate) {
			onSelectDate(moment(pickup.scheduledDate));
		}
	}

	return (
		<Box gap="small" height="100%" style={{ maxHeight: "254px" }} overflow={{ vertical: "scroll" }}>
			{loading && <LinearProgress />}
			<List>
				{sorted.map((pickup) => (
					<AvailablePickupListItem
						key={pickup.id}
						pickupId={pickup.id}
						onSelect={() => handleSelect(pickup)}
						pickupDate={pickup.scheduledDate || ""}
						selected={moment(pickup.scheduledDate).isSame(selectedDate, "day") || selectedId === pickup.id}
					/>
				))}
			</List>
		</Box>
	);
};
