import { SvgIcon, SvgIconProps } from "@mui/material";

	export const VentHood: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1135.1 887.32h-120.48l-204.52-393.43c-0.39062-0.75781-0.86328-1.4531-1.3203-2.1602v-402.38c0-14.496-11.754-26.25-26.25-26.25h-365.13c-14.496 0-26.25 11.754-26.25 26.25v411.56l-200.87 386.41h-125.45c-14.496 0-26.25 11.754-26.25 26.25v197.09c0 14.496 11.754 26.25 26.25 26.25h1070.3c14.496 0 26.25-11.754 26.25-26.25v-197.09c0-14.496-11.754-26.25-26.25-26.25zm-691.46-771.73h312.63v364.15h-312.63zm-9.6211 416.65h336.84l184.58 355.08h-706zm-338.24 552.17c-2.6016 0-4.7109-2.1133-4.7109-4.7188v-135.16c0-2.6016 2.1133-4.7109 4.7109-4.7109h1008.4c2.6016 0 4.7109 2.1094 4.7109 4.7109v135.16c0 2.6016-2.1094 4.7188-4.7109 4.7188z"/>
  <path d="m834.39 960.98h-0.046875c-13.672 0-26.527 5.3164-36.211 14.977-9.6914 9.6758-15.031 22.547-15.047 36.234-0.015626 13.688 5.3086 26.566 14.977 36.254 9.6758 9.6914 22.547 15.031 36.234 15.047h0.046875c13.672 0 26.527-5.3164 36.211-14.977 9.6914-9.6758 15.031-22.547 15.047-36.234 0.015626-13.688-5.3086-26.566-14.977-36.254-9.6758-9.6953-22.539-15.035-36.234-15.047zm9.6758 60.988c-2.6016 2.5938-6.0508 4.0195-9.7188 4.0195h-0.015625c-3.668 0-7.125-1.4336-9.7188-4.0352-2.5938-2.6016-4.0195-6.0586-4.0195-9.7344 0-3.668 1.4336-7.125 4.0352-9.7188 2.6016-2.5938 6.0508-4.0195 9.7188-4.0195h0.015625c3.668 0 7.125 1.4336 9.7188 4.0352 2.5938 2.6016 4.0195 6.0586 4.0195 9.7344 0.007813 3.668-1.4336 7.125-4.0352 9.7188z"/>
  <path d="m975.05 960.98h-0.046875c-28.246 0-51.234 22.973-51.254 51.211-0.015625 13.688 5.3086 26.566 14.977 36.254 9.6758 9.6914 22.547 15.031 36.234 15.047h0.046875c13.672 0 26.527-5.3164 36.211-14.977 9.6914-9.6758 15.031-22.547 15.047-36.234s-5.3086-26.566-14.977-36.254c-9.6836-9.6953-22.547-15.035-36.238-15.047zm9.6719 60.988c-2.6016 2.5938-6.0508 4.0195-9.7188 4.0195h-0.015625c-3.668 0-7.125-1.4336-9.7188-4.0352-2.5938-2.6016-4.0195-6.0586-4.0195-9.7344 0.007812-7.582 6.1719-13.738 13.754-13.738h0.015625c3.668 0 7.125 1.4336 9.7188 4.0352 2.5938 2.6016 4.0195 6.0586 4.0195 9.7344 0 3.668-1.4297 7.125-4.0352 9.7188z"/>
  <path d="m365.66 960.98h-0.046875c-13.695 0.015626-26.559 5.3555-36.234 15.047-9.6758 9.6914-14.992 22.566-14.984 36.254s5.3555 26.559 15.047 36.234c9.6836 9.6602 22.539 14.977 36.211 14.977h0.046874c13.695-0.015625 26.559-5.3555 36.234-15.047 9.6758-9.6914 14.992-22.566 14.977-36.254-0.007812-13.688-5.3555-26.559-15.039-36.234-9.6758-9.6602-22.531-14.977-36.211-14.977zm9.7344 60.973c-2.5938 2.6016-6.0508 4.0352-9.7188 4.0352h-0.007813c-3.6758 0-7.125-1.4258-9.7188-4.0195s-4.0352-6.0508-4.0352-9.7188c0-3.6758 1.4258-7.1328 4.0195-9.7344 2.5938-2.6016 6.0508-4.0352 9.7188-4.0352h0.007812c3.668 0 7.125 1.4258 9.7188 4.0195 2.6016 2.5938 4.0352 6.0508 4.0352 9.7266 0 3.668-1.4258 7.125-4.0195 9.7266z"/>
  <path d="m225 960.98h-0.046875c-13.695 0.015626-26.559 5.3555-36.234 15.047-9.6758 9.6914-14.992 22.566-14.984 36.254v0.007812c0.03125 28.246 23.016 51.203 51.254 51.203h0.039063c13.695-0.015625 26.559-5.3555 36.234-15.047s14.992-22.566 14.984-36.254c-0.007813-13.688-5.3555-26.559-15.047-36.234-9.6641-9.6602-22.52-14.977-36.199-14.977zm9.7344 60.973c-2.5938 2.6016-6.0508 4.0352-9.7188 4.0352h-0.007813c-7.582 0-13.746-6.1641-13.754-13.746 0-3.668 1.4258-7.125 4.0195-9.7266 2.5938-2.6016 6.0508-4.0352 9.7188-4.0352h0.007812c3.6758 0 7.125 1.4258 9.7188 4.0195s4.0352 6.0508 4.0352 9.7188c0.007813 3.6758-1.418 7.1328-4.0195 9.7344z"/>
  <path d="m704.8 960.79h-209.59c-10.359 0-18.75 8.3906-18.75 18.75v65.137c0 10.359 8.3906 18.75 18.75 18.75h209.59c10.359 0 18.75-8.3906 18.75-18.75v-65.137c0-10.352-8.3906-18.75-18.75-18.75zm-18.75 65.137h-172.09v-27.637h172.09z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};