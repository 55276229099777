import { BasicListItem } from "../../../../../components";
import { usePickupFragment } from "../../../../../graphql";
import { Pickup_HeaderFragmentFragment } from "../../../../../graphql/__generated__/graphql";
import { useScheduledWindow } from "../../../../../hooks";

export const OrderPickupWindowListItem: React.FC<{
	orderId: string;
	pickupId: string;
	loading: boolean;
}> = ({ pickupId, loading }) => {
	const { data } = usePickupFragment(pickupId);
	const scheduledWindow = useScheduledWindow(data as Pickup_HeaderFragmentFragment);

	return (
		<BasicListItem
			label="Pickup Window"
			loading={loading}
			value={data.window && scheduledWindow
				? scheduledWindow
				: "Not Scheduled"
			}
		/>
	);
};
