import { useLazyQuery } from "@apollo/client";
import { useLogin } from "../../../auth";
import { FetchAdminTasks } from "../../../graphql";
import { Task } from "../../task";
import { useEffect, useMemo } from "react";

export function useAdminTasks() {
	const { user } = useLogin();

	const [
		fetch,
		{ data, loading },
	] = useLazyQuery(FetchAdminTasks);

	useEffect(() => {
		if(user) {
			fetch().catch(err => {
				console.error("Failed to fetch admin tasks", err);
			});
		}
	}, [ user ]);

	const tasks: Task[] = useMemo(() => {
		const tasks: Task[] = [];

		(data?.OrderSchedulePendingTasks ?? []).forEach(order => {
			tasks.push({
				id: order.id,
				title: `Schedule Pickup for ${order.user.firstName} ${order.user.lastName}`,
				label: `Order ${order.id} is ready to be scheduled`,
				icon: null,
				actionLabel: "Schedule Pickup",
				actionUrl: `/admin/orders/${order.id}?scheduling=true`,
				priority: 1
			});
		});

		(data?.OrderProcessingPendingTasks ?? []).forEach(order => {
			tasks.push({
				id: order.id,
				title: `Process Order for ${order.user.firstName} ${order.user.lastName}`,
				label: `Order ${order.id} was recently submitted and is ready to be processed.`,
				icon: null,
				actionLabel: "Complete Task",
				actionUrl: `/admin/orders/${order.id}?processing=true`,
				priority: 2
			});
		});

		(data?.OrderProductMissingDispositionTasks ?? []).forEach(order => {
			tasks.push({
				id: order.id,
				title: `Process Order for ${order.user.firstName} ${order.user.lastName}`,
				label: `Order ${order.id} was recently submitted and is ready to be processed.`,
				icon: null,
				actionLabel: "Complete Task",
				actionUrl: `/admin/orders/${order.id}?processing=true`,
				priority: 2
			});
		});

		(data?.FindOrderRequests ?? []).forEach(request => {
			tasks.push({
				id: request.id,
				title: `Process Order Request for ${request.referer.name}`,
				label: `Order Request ${request.id} was recently submitted and is ready to be processed.`,
				icon: null,
				actionLabel: "Complete Task",
				actionUrl: `/admin/order-requests/${request.id}`,
				priority: 2
			});
		});

		return tasks;
	}, [ data ]);

	return {
		loading,
		tasks: tasks.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
	};
}