import { Checkbox, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Heading, Box } from "grommet";
import React, { useEffect, useMemo, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { AccordionController } from "../../../components";
import { useWindowDimensions } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectOnboardingState, Disposition } from "../../../store/onboarding";
import { useOnboardingSteps, STEPS } from "../components";
import { OnboardingViewContainer } from "../components/OnboardingViewContainer";
import { useOnboardingMaterials } from "../hooks";
import { OnboardingFormContext } from "../types";

export const WasteProfileView: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const state = useAppSelector(selectOnboardingState);
	const { materials, loading } = useOnboardingMaterials();
	const { activeStepIndex } = useOnboardingSteps(formContext);

	const sorted = useMemo(() => {
		return Array.from(materials).sort((a, b) => a.name.localeCompare(b.name));
	}, [ materials ]);

	const primary = useMemo(() => {
		return sorted.filter(m => m.tags.includes("common"));
	}, [ sorted ]);

	const secondary = useMemo(() => {
		return sorted.filter(m => !m.tags.includes("common"));
	}, [ sorted ]);

	const dispositions = useMemo(() => {
		const values = Object.values(Disposition);
		return values.map((value) => {
			return {
				id: value,
				label: value
			};
		});
	}, []);

	function handleMaterialChange(
		material: string,
		dispositions: Disposition[]
	) {
		const existing = state.materials.find((m) => m.id === material);
		if(existing) {
			formContext.setValue("materials", state.materials.map((m) => {
				if(m.id === material) {
					return {
						...m,
						dispositions
					};
				}
				return m;
			}));
		}
		else {
			formContext.setValue("materials", [
				...state.materials,
				{
					id: material,
					dispositions
				}
			]);
		}
	}

	function handleMaterialCheckboxUpdate(
		material: string
	) {
		const existing = state.materials.find((m) => m.id === material);
		if(existing) {
			formContext.setValue("materials", state.materials.filter((m) => m.id !== material));
		}
		else {
			formContext.setValue("materials", [
				...state.materials,
				{
					id: material,
					dispositions: []
				}
			]);
		}
	}

	const childContext = useForm({
		defaultValues: state.materials.map((m) => ({
			id: m.id,
			dispositions: []
		}))
	});

	useEffect(() => {
		const values = childContext.getValues();

	}, [ state, childContext ]);

	const [ isPrimaryExpanded, setIsPrimaryExpanded ] = useState(false);
	const [ isSecondaryExpanded, setIsSecondaryExpanded ] = useState(false);

	return (
		<OnboardingViewContainer
			title={STEPS[ activeStepIndex ].label}
			subtitle={STEPS[ activeStepIndex ].description}
			loading={loading}
		>
			<AccordionController
				name="primary"
				isExpanded={isPrimaryExpanded}
				onChange={() => setIsPrimaryExpanded(!isPrimaryExpanded)}
				summary={(
					<Box>
						<Heading level="4" margin="none">
							Common Materials
						</Heading>
						<Typography variant="caption">
							Check the materials below you are interested in tracking
						</Typography>
					</Box>
				)}
				details={(
					<List>
						{primary.map((material) => (
							<ListItem
								divider
								key={material.id}
								style={{ minHeight: "75px" }}
							>
								<Box align="center" direction={"row"} flex gap="small">
									<ListItemText
										primary={
											<Typography fontWeight="bold">
												{material.name}
											</Typography>
										}
									/>
									<Box flex align="end">
										{/* <AutocompleteElement
												multiple
												showCheckbox
												name={`dispositions-${material.id}`}
												label="Waste Handling"
												options={dispositions}
												autocompleteProps={{
													size: "small",
													sx: { width: size === "small" ? undefined : "300px" },
													limitTags: 2,
													onChange: (event, value) => {
														console.log(value);
														// eslint-disable-next-line @typescript-eslint/no-explicit-any
														handleMaterialChange(material.id, value.map((v: any) => v.id));
													},
													fullWidth: size === "small"
												}}
											/> */}
										<Checkbox
											onChange={() => handleMaterialCheckboxUpdate(material.id)}
											checked={state.materials.some((m) => m.id === material.id)}
										/>
									</Box>
								</Box>
							</ListItem>
						))}
					</List>
				)}
			/>
			<AccordionController
				name="secondary"
				isExpanded={isSecondaryExpanded}
				onChange={() => setIsSecondaryExpanded(!isSecondaryExpanded)}
				summary={(
					<Box>
						<Heading level="4" margin="none">
							Less Common Materials
						</Heading>
						<Typography variant="caption">
							Check the materials below you are interested in tracking
						</Typography>
					</Box>
				)}
				details={(
					<List>
						{secondary.map((material) => (
							<ListItem
								divider
								key={material.id}
								style={{ minHeight: "75px" }}
							>
								<Box align="center" direction="row" flex gap="small">
									<ListItemText
										primary={
											<Typography fontWeight="bold">
												{material.name}
											</Typography>
										}
									/>
									<Box flex align="end">
										{/* <AutocompleteElement
											multiple
											showCheckbox
											name={`dispositions-${material.id}`}
											label="Waste Handling"
											options={dispositions}
											autocompleteProps={{
												size: "small",
												sx: { width: size === "small" ? undefined : "300px" },
												limitTags: 2,
												onChange: (event, value) => {
													console.log(value);
													// eslint-disable-next-line @typescript-eslint/no-explicit-any
													handleMaterialChange(material.id, value.map((v: any) => v.id));
												},
												fullWidth: size === "small"
											}}
										/> */}
										<Checkbox
											onChange={() => handleMaterialCheckboxUpdate(material.id)}
											checked={state.materials.some((m) => m.id === material.id)}
										/>
									</Box>
								</Box>
							</ListItem>
						))}
					</List>
				)}
			/>
		</OnboardingViewContainer>
	);
};