import { gql } from "@apollo/client";
import { ReuseOutlet_HeaderFragmentFragment } from "../../../__generated__/graphql";

export const ReuseOutlet_HeaderFragment = gql`
	fragment ReuseOutlet_HeaderFragment on ReuseOutlet {
		id
		name
		isNonprofit
		externalUrl
		contactEmail
		contactPhone
		terms
		policy
		referralMessage
		__typename
	}
`;

export function isReuseOutletFragment(value: { [ key: string ]: unknown; }): value is ReuseOutlet_HeaderFragmentFragment {
	return value.__typename === "ReuseOutlet";
}