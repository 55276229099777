import { Box, Text } from "grommet";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCheckoutState, setCutoffDate } from "../../../store/checkout";
import { useForm } from "react-hook-form";
import { CheckboxElement, FormContainer } from "react-hook-form-mui";
import { DateCalendar } from "@mui/x-date-pickers";
import { LocalizationProvider } from "../../../provider";
import moment from "moment-timezone";
import { StepControls } from "../../../components";
import { CheckoutTitleContainer, CheckoutViewContainer } from "../components";

export const CheckoutScheduleView: React.FC = () => {
	const { cutoffDate } = useAppSelector(selectCheckoutState);

	const formContext = useForm({
		defaultValues: {
			hasCutoffDate: !!cutoffDate,
			cutoffDate: cutoffDate || undefined
		}
	});

	useEffect(() => {
		if(cutoffDate) {
			formContext.setValue("hasCutoffDate", !!cutoffDate);
			formContext.setValue("cutoffDate", cutoffDate || undefined);
		}
	}, [ cutoffDate ]);

	const formValues = formContext.watch();
	const dispatch = useAppDispatch();

	return (
		<CheckoutViewContainer>
			<CheckoutTitleContainer
				heading="Pickup Date"
				subheading="We'll be in touch with scheduling options."
			/>
			<Box height="100%">
				<FormContainer formContext={formContext}>
					<CheckboxElement
						name="hasCutoffDate"
						label={(
							<Text weight="bold">
								Do you need everything out by a certain date?
							</Text>
						)}
					/>
				</FormContainer>
				{formContext.watch("hasCutoffDate") && (
					<LocalizationProvider>
						<DateCalendar
							disablePast
							shouldDisableDate={(date) => {
								return date.isSameOrBefore(moment(), "day");
							}}
							value={formContext.watch("cutoffDate")
								? moment(formContext.watch("cutoffDate"))
								: undefined
							}
							showDaysOutsideCurrentMonth
							onChange={(value) => {
								formContext.setValue("cutoffDate", value?.format("YYYY-MM-DD") || undefined);
								dispatch(setCutoffDate(value?.format("YYYY-MM-DD") || undefined));
							}}
						/>
					</LocalizationProvider>
				)}
				<Box flex justify="end">
					<StepControls
						name="checkout"
						isLoading={false}
						nextButtonLabel="Continue"
						canProceed={formValues.hasCutoffDate ? !!formValues.cutoffDate : true}
					/>
				</Box>
			</Box>
		</CheckoutViewContainer>
	);
};
