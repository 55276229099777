import React from "react";
import { Box } from "grommet";
import { LoadingButton } from "@mui/lab";
import { Edit } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { TextFieldElement, UseFormReturn } from "react-hook-form-mui";
import { PropertyDetailsFormContext } from "../PropertyDetailsDialog";

export const PropertyDetailsTab: React.FC<{
	buildingId: string;
	formContext: UseFormReturn<PropertyDetailsFormContext>;
}> = ({ buildingId, formContext }) => {
	const [ isSaving, setIsSaving ] = React.useState(false);
	const [ hasChanges, setHasChanges ] = React.useState(false);

	function handleSubmit(): void {
		setIsSaving(true);
		setHasChanges(false);

		setTimeout(() => {
			setIsSaving(false);
		}, 2000);
	}

	return (
		<Box gap="medium" flex>
			<TextFieldElement
				name="name"
				label="Location Name"
				onChange={() => setHasChanges(true)}
			/>
			<TextFieldElement
				name="address"
				label="Location Address"
				InputProps={{
					readOnly: true,
					onChange: () => setHasChanges(true),
					endAdornment: (
						<InputAdornment position="end">
							<IconButton>
								<Edit />
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
			<Box flex justify="end" align="end">
				<LoadingButton
					color="primary"
					variant="outlined"
					loading={isSaving}
					disabled={!hasChanges}
					onClick={formContext.handleSubmit(handleSubmit)}
				>
					Save Changes
				</LoadingButton>
			</Box>
		</Box>
	);
};