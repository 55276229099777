import { gql } from "../../../__generated__";

export const FetchUser = gql(/* GraphQL */ `
	query FetchUser($userId: String!) {
		FetchUser(userId: $userId) {
			id
			...User_HeaderFragment
		}
	}
`);

export const FetchUserWithContacts = gql(/* GraphQL */ `
	query FetchUserWithContacts($userId: String!) {
		FetchUser(userId: $userId) {
			id
			...User_ContactsFragment
			...User_ExpandedFragment
		}
	}
`);

export const FetchUserAdmin = gql(/* GraphQL */ `
	query FetchUserAdmin($userId: String!) {
		FetchUser(userId: $userId) {
			id
			...User_AdminFragment
		}
	}
`);

export const FindUsers = gql(/* GraphQL */ `
	query FindUsers(
		$firstName: iStringFilters
		$lastName: iStringFilters
		$contacts: FindContactDTO
	) {
		FindUsers(
			firstName: $firstName
			lastName: $lastName
			contacts: $contacts
		) {
			...User_ExpandedFragment
		}
	}
`);