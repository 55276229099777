import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import moment from "moment";
import { FindActiveOrders } from "../../../graphql";

export function useActiveOrders(
	userId: string,
	instanceId: string
) {
	const result = useQuery(FindActiveOrders, {
		variables: {
			userId,
			instanceId,
		},
		skip: !userId || !instanceId,
	});

	const orders = useMemo(() => {
		return Array.from(result.data?.FindActiveOrders || []).sort((a, b) => {
			if(!a.pickup) {
				return -1;
			}

			if(!b.pickup) {
				return 1;
			}

			return moment(a.pickup.scheduledDate).unix() - moment(b.pickup.scheduledDate).unix();
		});
	}, [ result ]);

	return {
		...result,
		orders
	};
}