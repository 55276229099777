import React, { useEffect } from "react";
import moment from "moment";
import { UseFormReturn } from "react-hook-form";
import { MultiSelectElement, ToggleButtonGroupElement } from "react-hook-form-mui";
import { Fragment } from "react/jsx-runtime";
import { DayOfWeek } from "../../../../../graphql/__generated__/graphql";
import { toProperCase } from "../../../../../helpers";
import { useWindowDimensions } from "../../../../../hooks";
import { WasteContainerState } from "../../types";

export const CollectionFrequencyElement: React.FC<{
	formContext: UseFormReturn<WasteContainerState>;
}> = ({ formContext }) => {
	const { size } = useWindowDimensions();

	const {
		collectionFrequency
	} = formContext.watch();

	useEffect(() => {
		const collectionFrequencySafe = Array.isArray(collectionFrequency) ? collectionFrequency : [];
		const sorted = Array.from(collectionFrequencySafe).sort((a, b) => {
			return moment().day(a).day() - moment().day(b).day();
		});

		if(sorted.every((day, index) => day === collectionFrequencySafe[ index ])) {
			return;
		}

		if(sorted.length > 0 && sorted !== collectionFrequencySafe) {
			formContext.setValue("collectionFrequency", sorted);
		}
	}, [ collectionFrequency, formContext ]);

	const collectionFrequencyOptions = Object.values(DayOfWeek).map(day => ({
		id: day,
		label: moment().day(toProperCase(day)).format("ddd")
	})).sort((a, b) => {
		return moment().day(a.label).day() - moment().day(b.label).day();
	});

	return (
		<Fragment>
			{size === "small" && (
				<MultiSelectElement
					showCheckbox
					name="collectionFrequency"
					label="Collection Frequency"
					options={collectionFrequencyOptions} />
			)}
			{size !== "small" && (
				<ToggleButtonGroupElement
					required
					fullWidth
					validation={{
						validate: value => {
							if(value === null || Array.isArray(value) && value.length === 0) {
								return "Please select at least one day";
							}
							return;
						}
					}}
					color="primary"
					name="collectionFrequency"
					options={collectionFrequencyOptions}
					helperText="Click to select the days this container is collected" />
			)}
		</Fragment>
	);
};
