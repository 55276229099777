import { gql } from "@apollo/client";

export const WasteContainerAssignment_ExtendedFragment = gql`
	fragment WasteContainerAssignment_ExtendedFragment on WasteContainerAssignment {
		...WasteContainerAssignment_HeaderFragment
		averageFillRate
        averageCollectionWeight
        weeklyCollectionWeight
        monthlyCollectionWeight
        maximumCollectionWeight
		partner { id name }
		material { id name groupName }
		container { id name type iconUrl }
		collectionFrequency { period dayOfWeek}
		inspections {
			id
			scheduled
			scheduledFor
			missed
			inspected
			processed
			fillRateDecimal
		}
	}
`;
