import React from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useBusinessType } from "../hooks";
import { BusinessTypeDefinition } from "../types";

export const BusinessTypeListItem: React.FC<{
	onClick(): void;
	definition: BusinessTypeDefinition;
}> = ({ onClick, definition }) => {
	const { businessType } = useBusinessType();
	return (
		<ListItemButton
			divider
			key={definition.id}
			selected={businessType === definition.id}
			onClick={onClick}
		>
			<ListItemIcon>
				{definition.icon}
			</ListItemIcon>
			<ListItemText
				primary={definition.label}
				secondary={definition.description}
			/>
		</ListItemButton>
	);
};
