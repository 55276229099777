import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../store";
import { push } from "redux-first-history";
import { useUser } from "../auth";
import { keys } from "../helpers";


export function useRedirect() {
	const dispatch = useAppDispatch();

	const redirect = useCallback((fallbackUrl: string, query?: Record<string, string>) => {
		if(localStorage.getItem("redirect")) {
			console.log("Redirecting to", localStorage.getItem("redirect"));
			const redirect = localStorage.getItem("redirect") ?? "";
			localStorage.removeItem("redirect");
			dispatch(push(redirect));
			return;
		}

		const params = new URLSearchParams(window.location.search);
		if(params.get("redirect")) {
			console.log("Redirecting to", params.get("redirect"));
			const redirect = params.get("redirect") ?? "";
			const updatedParams = new URLSearchParams(params);
			updatedParams.delete("redirect");
			dispatch(push(redirect + "?" + updatedParams.toString()));
		}
		else {
			console.log("Redirecting to", fallbackUrl);
			if(query) {
				keys(query).forEach(key => {
					params.set(key, query[ key ]);
				});
			}

			dispatch(push(fallbackUrl + "?" + params.toString()));
		}
	}, [ dispatch, window.location.search, localStorage.getItem("redirect") ]);

	return {
		redirect
	};
}

/**
 * Redirect to path if user is authenticated
 * 
 * Use this to skip login / register pages if user is already logged in
 * @param path 
 */
export function useAuthenticatedRedirect(path: string) {
	const dispatch = useAppDispatch();

	const { user } = useUser();

	useEffect(() => {
		if(user) dispatch(push(path));
	}, [ user ]);
}