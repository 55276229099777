import { InputAdornment, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useClassification } from "../../../hooks";
import React, { useEffect, useMemo } from "react";
import { useProductCategoryIcon } from "../../../hooks/useProductCategoryIcon";
import { Box, Spinner } from "grommet";
import { CheckboxElement, FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useMutation } from "@apollo/client";
import { FetchPricingPolicy, UpdatePricingPolicy } from "../../../graphql";
import { formatNumber } from "../../../helpers";
import { PricingPolicySizeListItem } from "./PricingPolicySizeListItem";
import { usePricedItem } from "../hooks/usePricedItem";

export interface PricingPolicyFormState {
	typeId: string;
	typeName: string;
	pickupFee: number;
	disassemblyFee: number;
	categoryId: string;
	categoryName: string;
	canDisassemble: boolean;
	enableDisassembly: boolean;
	sizes: {
		sizeId: string;
		sizeName: string;
	}[];
}

export const PricingPolicyTypeListItem: React.FC<{
	typeId: string;
	categoryId: string;
	categoryName: string;
	policyId: string;
}> = ({ typeId, policyId, categoryId, categoryName }) => {
	const { types } = useClassification();
	const icon = useProductCategoryIcon(categoryName);

	const { pickupFee, disassemblyFee, loading } = usePricedItem(policyId, typeId);

	const type = useMemo(() => {
		return types.find(t => t.id === typeId);
	}, [ types, typeId ]);

	const sizes = useMemo(() => {
		return type?.sizes || [];
	}, [ type ]);

	const formContext = useForm<PricingPolicyFormState>({
		defaultValues: {
			typeId,
			typeName: type?.name || "",
			pickupFee,
			disassemblyFee,
			sizes: sizes.map(size => ({
				sizeId: size.id,
				sizeName: size.name
			})),
			categoryId,
			categoryName,
			enableDisassembly: type?.canDisassemble || false,
			canDisassemble: type?.canDisassemble || false
		}
	});

	const values = formContext.watch();

	useEffect(() => {
		formContext.setValue("pickupFee", pickupFee);
	}, [ pickupFee ]);

	useEffect(() => {
		formContext.setValue("disassemblyFee", disassemblyFee);
	}, [ disassemblyFee ]);

	const [
		update, { loading: updateLoading }
	] = useMutation(UpdatePricingPolicy, {
		refetchQueries: [
			FetchPricingPolicy
		],
		awaitRefetchQueries: true
	});

	const anyLoading = useMemo(() => loading || updateLoading, [ loading, updateLoading ]);

	function handleSave() {
		const { enableDisassembly, canDisassemble, pickupFee, disassemblyFee } = values;
		update({
			variables: {
				policyId,
				pricing: [
					{
						typeId,
						canDisassemble,
						pickupFee: Number(pickupFee),
						disassemblyFee: enableDisassembly ? Number(disassemblyFee) : 0
					}
				]
			}
		});
	}

	return (
		<FormContainer formContext={formContext}>
			<ListItem divider>
				<Box gap="small" flex>
					<Box direction="row" align="center" flex>
						<ListItemIcon>
							{anyLoading ? <Spinner /> : icon}
						</ListItemIcon>
						<ListItemText
							primary={type?.name} />
						<Box align="end">
							<Box direction="row" gap="small">
								<TextFieldElement
									size="small"
									name="pickupFee"
									label="Pickup Fee"
									style={{ width: "200px" }}
									disabled={anyLoading}
									InputProps={{
										value: formatNumber(values.pickupFee || 0, 2),
										onBlur: formContext.handleSubmit(handleSave),
										onChange: (e) => {
											formContext.setValue("pickupFee", Number(e.target.value));
										},
										startAdornment: (
											<InputAdornment position="start">
												{anyLoading ? <Spinner /> : "$"}
											</InputAdornment>
										)
									}} />
								<TextFieldElement
									size="small"
									name="disassemblyFee"
									label="Disassembly Fee"
									style={{ width: "200px" }}
									disabled={!values.enableDisassembly || anyLoading}
									InputProps={{
										value: formatNumber(values.disassemblyFee || 0),
										onBlur: formContext.handleSubmit(handleSave),
										onChange: (e) => {
											formContext.setValue("disassemblyFee", Number(e.target.value));
										},
										startAdornment: (
											<InputAdornment position="start">
												{anyLoading ? <Spinner /> : "$"}
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<CheckboxElement
													name="enableDisassembly" />
											</InputAdornment>
										)
									}} />
							</Box>
						</Box>
					</Box>
					{sizes.map(size => (
						<PricingPolicySizeListItem
							key={size.id}
							sizeId={size.id}
							typeId={typeId}
							policyId={policyId}
							canDisassemble={values.canDisassemble}
							enableDisassembly={values.enableDisassembly}
						/>
					))}
				</Box>
			</ListItem>
		</FormContainer>
	);
};
