import { LinearProgress } from "@mui/material";
import { Box, Heading } from "grommet";
import { HaulerPickupRequestListItem } from "./HaulerPickupRequestListItem";
import { useMemo, useState } from "react";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";
import { LocalizationProvider } from "../../../../provider";
import { DateCalendar } from "@mui/x-date-pickers";
import { Moment } from "moment";
import moment from "moment";
import { Pagination } from "../../../../components";
import { FilterChip } from "../../../../components/FilterChip";
import { useWindowDimensions } from "../../../../hooks";
import { useScheduledPickups } from "../../hooks/useScheduledPickups";

interface HaulerPickupFilterState {
	from: Moment | undefined;
	to: Moment | undefined;
}

export const HaulerScheduledPickupsController: React.FC<{ haulerId: string; }> = (props) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	const [ filterState, setFilterState ] = useState<HaulerPickupFilterState>({
		from: undefined,
		to: undefined
	});

	const { data, loading } = useScheduledPickups();

	const pickups = useMemo(() => {
		return Array.from((data?.FindPickups ?? [])).sort((a, b) => {
			if(a.scheduledDate && b.scheduledDate) {
				return moment(a.scheduledDate).startOf("day").unix() - moment(b.scheduledDate).startOf("day").unix();
			}

			return 0;
		}).filter(pickup => {
			if(filterState.from && pickup.scheduledDate) {
				if(moment(pickup.scheduledDate).startOf("day").unix() < filterState.from.endOf("day").unix()) {
					return false;
				}
			}

			if(filterState.to && pickup.scheduledDate) {
				if(moment(pickup.scheduledDate).startOf("day").unix() > filterState.to.endOf("day").unix()) {
					return false;
				}
			}

			return true;
		});
	}, [ data, filterState ]);

	return (
		<Box flex margin="small">
			<Box gap="xsmall">
				<Box
					gap="small"
					direction={size === "small" ? "column" : "row"}
					justify={size === "small" ? undefined : "between"}>
					<Box justify="center">
						<Heading margin="none" level="3">
							Scheduled Pickups
						</Heading>
					</Box>
					<Box align="start" direction="row" gap="small">
						<FilterChip
							name="From"
							value={filterState.from?.format("MM/DD/YYYY") || ""}
							onClear={() => {
								setFilterState({
									...filterState,
									from: undefined
								});
							}}
							onChange={() => {
								//
							}}
							menuContent={(
								<Box>
									<LocalizationProvider>
										<DateCalendar
											value={filterState.from}
											onChange={(value) => {
												setFilterState({
													...filterState,
													from: value || undefined
												});
											}}
										/>
									</LocalizationProvider>
								</Box>
							)}
						/>
						<FilterChip
							name="To"
							value={filterState.to?.format("MM/DD/YYYY") || ""}
							onClear={() => {
								setFilterState({
									...filterState,
									to: undefined
								});
							}}
							onChange={() => {
								//
							}}
							menuContent={(
								<Box>
									<LocalizationProvider>
										<DateCalendar
											value={filterState.to}
											onChange={(value) => {
												setFilterState({
													...filterState,
													to: value || undefined
												});
											}}
										/>
									</LocalizationProvider>
								</Box>
							)}
						/>
					</Box>
				</Box>
				{loading && (
					<LinearProgress />
				)}
			</Box>
			<Box flex>
				<Pagination pageSize={5}>
					{pickups.map((pickup) => (
						<HaulerPickupRequestListItem
							key={pickup.id}
							onSelect={() => {
								dispatch(push(`/hauler/pickups/${pickup.id}`));
							}}
							pickupId={pickup.id}
							scheduledWindow={pickup.window}
							scheduledDate={pickup.scheduledDate ?? ""}
							stopCount={pickup.stopCount}
							productCount={pickup.incomingProductCount}
							marketCode={pickup.market.code}
							amountOffered={pickup.invoice?.amount ?? 0}
						/>
					))}
				</Pagination>
			</Box>
		</Box>
	);
};