import { useFragment } from "@apollo/client";
import { Pickup_HeaderFragmentFragment } from "../../__generated__/graphql";
import { Pickup_HeaderFragment } from "../movement";

export function usePickupFragment(pickupId: string) {
	return useFragment<Pickup_HeaderFragmentFragment>({
		fragment: Pickup_HeaderFragment,
		fragmentName: "Pickup_HeaderFragment",
		from: {
			__typename: "Pickup",
			id: pickupId
		}
	});
}
