import { Chip, Menu, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FilterAlt } from "@mui/icons-material";

interface FilterChipProps {
	name: string;
	value: string | number | boolean;
	onClear: () => void;
	onChange(value: string | number | boolean): void;
	menuContent: React.ReactNode;
	hideIcon?: boolean;
}

export const FilterChip: React.FC<FilterChipProps> = (props) => {
	const label = useMemo(() => {
		const components = [ props.name ];
		if(typeof props.value === "boolean") {
			components.push(":", props.value ? "Yes" : "No");
		}
		if(props.value) {
			components.push(":", props.value.toString());
		}

		return components.join(" ");
	}, [ props ]);

	const [ isSelected, setIsSelected ] = useState(false);

	const id = useMemo(() => {
		return `filter-chip-${props.name}`;
	}, [ props.name ]);

	useEffect(() => {
		setIsSelected(false);
	}, [ props.value ]);

	return (
		<Fragment>
			<Chip
				id={id}
				clickable
				label={(
					<Typography fontWeight="bold" variant="caption">
						{label}
					</Typography>
				)}
				color="primary"
				icon={props.hideIcon ? undefined : <FilterAlt fontSize="small" />}
				onClick={() => setIsSelected(true)}
				onDelete={props.value || props.value === false || props.value === 0 ? props.onClear : undefined} />
			<Menu
				open={isSelected}
				anchorEl={document.getElementById(id)}
				onClose={() => setIsSelected(false)}
			>
				{props.menuContent}
			</Menu>
		</Fragment>
	);
};
