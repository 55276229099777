import { SvgIcon, SvgIconProps } from "@mui/material";

	export const TableSimple: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1044 369h-888c-9.9375 0-18 8.0625-18 18v120c0 9.9375 8.0625 18 18 18h126v288c0 9.9375 8.0625 18 18 18s18-8.0625 18-18v-288h564v288c0 9.9375 8.0625 18 18 18s18-8.0625 18-18v-288h126c9.9375 0 18-8.0625 18-18v-120c0-9.9375-8.0625-18-18-18zm-18 120h-852v-84h852z"/>
</svg>
			</SvgIcon>
		);
	};