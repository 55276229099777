import { SvgIcon, SvgIconProps } from "@mui/material";

export const TrashCompactor: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
				<path d="m1140 0h-640c-15.914 0-31.176 6.3203-42.426 17.574-11.254 11.25-17.574 26.512-17.574 42.426v500h-320c-35.457-0.027344-69.109 15.641-91.91 42.797-22.801 27.152-32.418 63.004-26.258 97.926 6.1562 34.918 27.453 65.32 58.168 83.039v356.24c0 15.914 6.3203 31.176 17.574 42.426 11.25 11.254 26.512 17.574 42.426 17.574h1020c15.914 0 31.176-6.3203 42.426-17.574 11.254-11.25 17.574-26.512 17.574-42.426v-1080c0-15.914-6.3203-31.176-17.574-42.426-11.25-11.254-26.512-17.574-42.426-17.574zm-1020 600h320v160h-320c-28.582 0-54.992-15.246-69.281-40-14.293-24.754-14.293-55.246 0-80 14.289-24.754 40.699-40 69.281-40zm-20 540v-341.8c6.6055 1.1602 13.293 1.7617 20 1.8008h320v340c0.066406 6.8281 1.3125 13.594 3.6797 20h-323.68c-5.3047 0-10.391-2.1055-14.141-5.8594-3.7539-3.75-5.8594-8.8359-5.8594-14.141zm1060 0c0 5.3047-2.1055 10.391-5.8594 14.141-3.75 3.7539-8.8359 5.8594-14.141 5.8594h-640c-5.3047 0-10.391-2.1055-14.141-5.8594-3.7539-3.75-5.8594-8.8359-5.8594-14.141v-1080c0-5.3047 2.1055-10.391 5.8594-14.141 3.75-3.7539 8.8359-5.8594 14.141-5.8594h640c5.3047 0 10.391 2.1055 14.141 5.8594 3.7539 3.75 5.8594 8.8359 5.8594 14.141z" />
				<path d="m1080 80h-520c-10.609 0-20.781 4.2148-28.285 11.715-7.5 7.5039-11.715 17.676-11.715 28.285v960c0 10.609 4.2148 20.781 11.715 28.285 7.5039 7.5 17.676 11.715 28.285 11.715h520c10.609 0 20.781-4.2148 28.285-11.715 7.5-7.5039 11.715-17.676 11.715-28.285v-960c0-10.609-4.2148-20.781-11.715-28.285-7.5039-7.5-17.676-11.715-28.285-11.715zm-120 400h-80v-207.64l18.898 9.4609c6.3594 3.1562 11.707 8.0312 15.438 14.074 3.7305 6.0391 5.6914 13.008 5.6641 20.105-0.058594 20.738 8.1875 40.641 22.898 55.262l6 6c7.0977 7.0898 11.09 16.707 11.102 26.738zm-120 0h-40v-360h40zm240 40v60h-520v-60zm0 100v215.6l-62.82-12.559v-0.003907c-4.207-0.84766-8.0117-3.0703-10.82-6.3203l-94.199-110c-7.9453-9.3008-19.27-15.055-31.465-15.992-12.199-0.93359-24.266 3.0312-33.535 11.012l-109.16 93.66c-3.4844 2.9727-7.918 4.6055-12.5 4.6016h-67.141c-17.566-0.007812-34.23 7.7891-45.48 21.281l-52.879 63.457v-264.74zm0-140h-80v-76c0.058594-20.738-8.1875-40.641-22.898-55.262l-6-6c-7.0977-7.0898-11.09-16.707-11.102-26.738 0.066406-14.539-3.9453-28.805-11.578-41.176-7.6328-12.375-18.582-22.359-31.602-28.824l-36.82-18.359v-107.64h200zm-320-360v360h-200v-360zm-200 960v-132.74l83.602-100.32c3.6367-4.4023 9.0508-6.9492 14.758-6.9414h67.141c14.133 0 27.797-5.0586 38.52-14.262l109.18-93.578c2.5781-2.168 6.4141-1.8633 8.6211 0.67969l94.18 109.9c8.625 10.047 20.359 16.918 33.34 19.523l70.66 14.137v203.6z" />
				<path d="m140 700h40c7.1445 0 13.746-3.8125 17.32-10 3.5742-6.1875 3.5742-13.812 0-20-3.5742-6.1875-10.176-10-17.32-10h-40c-7.1445 0-13.746 3.8125-17.32 10-3.5742 6.1875-3.5742 13.812 0 20 3.5742 6.1875 10.176 10 17.32 10z" />
				<path d="m300 700h40c7.1445 0 13.746-3.8125 17.32-10 3.5742-6.1875 3.5742-13.812 0-20-3.5742-6.1875-10.176-10-17.32-10h-40c-7.1445 0-13.746 3.8125-17.32 10-3.5742 6.1875-3.5742 13.812 0 20 3.5742 6.1875 10.176 10 17.32 10z" />
				<path d="m691.06 922.1-40 20c-6.3945 3.1914-10.598 9.5547-11.031 16.691-0.42969 7.1328 2.9766 13.957 8.9414 17.898s13.578 4.4023 19.973 1.207l40-20c6.3945-3.1914 10.598-9.5547 11.031-16.691 0.42969-7.1328-2.9766-13.957-8.9414-17.898s-13.578-4.4023-19.973-1.207z" />
				<path d="m971.06 922.1-40 20c-6.3945 3.1914-10.598 9.5547-11.031 16.691-0.42969 7.1328 2.9766 13.957 8.9414 17.898s13.578 4.4023 19.973 1.207l40-20c6.3945-3.1914 10.598-9.5547 11.031-16.691 0.42969-7.1328-2.9766-13.957-8.9414-17.898s-13.578-4.4023-19.973-1.207z" />
				<path d="m888.94 842.1-40-20c-6.3945-3.1953-14.008-2.7344-19.973 1.207s-9.3711 10.766-8.9414 17.898c0.43359 7.1367 4.6367 13.5 11.031 16.691l40 20c6.3945 3.1953 14.008 2.7344 19.973-1.207s9.3711-10.766 8.9414-17.898c-0.43359-7.1367-4.6367-13.5-11.031-16.691z" />
				<path d="m848.94 982.1-40-20c-6.3945-3.1953-14.008-2.7344-19.973 1.207s-9.3711 10.766-8.9414 17.898c0.43359 7.1367 4.6367 13.5 11.031 16.691l40 20c6.3945 3.1953 14.008 2.7344 19.973-1.207s9.3711-10.766 8.9414-17.898c-0.43359-7.1367-4.6367-13.5-11.031-16.691z" />
			</svg>
		</SvgIcon>
	);
};