import React, { Fragment } from "react";
import { List } from "@mui/material";
import { OrderPickupDateListItem } from "./OrderPickupDateListItem";
import { OrderPickupAssignedToListItem } from "./OrderPickupAssignedToListItem";
import { OrderEstimateAmountListItem } from "./OrderEstimateAmountListItem";
import { OrderMilestoneListItem } from "./OrderMilestoneListItem";
import { OrderPickupWindowListItem } from "./OrderPickupWindowListItem";
import { useAdminScopedOrder } from "../../../../../graphql";

export const OrderPickupDetailsComponent: React.FC<{
	orderId: string;
	permitEdit?: boolean;
	permitViewPickup?: boolean;
	includeGlobalAdmin?: boolean;
}> = ({ orderId, permitEdit, includeGlobalAdmin: includeAdmin, permitViewPickup }) => {
	const { order, loading } = useAdminScopedOrder(orderId);

	return (
		<List>
			<OrderPickupDateListItem
				pickupId={order?.pickup?.id ?? ""}
				orderId={orderId}
				loading={loading}
				permitViewPickup={permitViewPickup}
			/>
			<OrderPickupWindowListItem
				pickupId={order?.pickup?.id ?? ""}
				orderId={orderId}
				loading={loading}
			/>
			<OrderPickupAssignedToListItem
				loading={loading}
				pickup={order?.pickup ?? null}
			/>
			{includeAdmin && (
				<GlobalAdminOrderPickupDetailsComponent
					orderId={orderId}
					permitEdit={permitEdit}
				/>
			)}
		</List>
	);
};

export const GlobalAdminOrderPickupDetailsComponent: React.FC<{
	orderId: string;
	permitEdit?: boolean;
}> = ({ orderId, permitEdit }) => {
	return (
		<Fragment>
			<OrderEstimateAmountListItem
				orderId={orderId}
			/>
			<OrderMilestoneListItem
				orderId={orderId}
				permitEdit={permitEdit}
			/>
		</Fragment>
	);
};