import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Desk: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m872.95 889.9h72.328c10.359 0 18.75-8.3906 18.75-18.75 0-10.359-8.3906-18.75-18.75-18.75h-72.328c-10.359 0-18.75 8.3906-18.75 18.75 0 10.355 8.3945 18.75 18.75 18.75z" />
		<path
			d="m872.95 670.52h72.328c10.359 0 18.75-8.3906 18.75-18.75s-8.3906-18.75-18.75-18.75h-72.328c-10.359 0-18.75 8.3906-18.75 18.75 0 10.355 8.3945 18.75 18.75 18.75z" />
		<path
			d="m872.95 451.13h72.328c10.359 0 18.75-8.3906 18.75-18.75 0-10.359-8.3906-18.75-18.75-18.75h-72.328c-10.359 0-18.75 8.3906-18.75 18.75 0 10.355 8.3945 18.75 18.75 18.75z" />
		<path
			d="m1134.9 192.91h-1069.8c-14.496 0-26.25 11.754-26.25 26.25v103.53c0 14.496 11.754 26.25 26.25 26.25h43.426v590.05c0 37.551 30.555 68.109 68.109 68.109 37.551 0 68.109-30.555 68.109-68.109l-0.003906-273.34h482.02v315.2c0 14.496 11.754 26.25 26.25 26.25h312.25c14.496 0 26.25-11.754 26.25-26.25v-631.91h43.426c14.496 0 26.25-11.754 26.25-26.25v-103.53c0-14.496-11.758-26.25-26.25-26.25zm-942.7 746.08c0 8.6016-6.9961 15.609-15.609 15.609-8.6094 0-15.609-7.0039-15.609-15.609l0.003906-590.05h31.207v590.05zm52.5-325.84v-264.21h482.02v264.21zm794.27 122.06h-259.75v-166.89h259.75zm0 219.38h-259.75v-166.88h259.75zm0-438.77h-259.75v-166.88h259.75zm69.684-219.38h-1017.3v-51.031h1017.3z" />
	</g>
</svg>
			</SvgIcon>
		);
	};