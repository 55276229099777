import React from "react";
import { Button, ButtonGroup, Fade } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { BeginPickup, FetchHaulerPickup } from "../../../../graphql";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

export const BeginPickupButton: React.FC<{
	pickupId: string;
}> = ({ pickupId }) => {
	const snack = useSnackbar();
	const [ isConfirming, setIsConfirming ] = React.useState(false);

	const [
		begin, { loading }
	] = useMutation(BeginPickup, {
		refetchQueries: [ FetchHaulerPickup ]
	});

	function handleBeginPickup(): void {
		begin({
			variables: {
				pickupId
			}
		}).catch(err => {
			console.error("Failed to begin pickup", err);
			snack.enqueueSnackbar(
				"We ran into an issue submitting your request. Please try again later.",
				{ variant: "error" }
			);
		}).finally(() => {
			setIsConfirming(false);
		});
	}

	return (
		<ButtonGroup>
			<LoadingButton
				fullWidth
				disabled={isConfirming}
				loading={loading}
				color="primary"
				variant="contained"
				onClick={() => {
					setIsConfirming(true);
				}}
				style={isConfirming ? undefined : {
					borderRadius: "4px"
				}}
			>
				On The Way?
			</LoadingButton>
			<Fade
				in={isConfirming}
				mountOnEnter
				unmountOnExit
			>
				<ButtonGroup>
					<Button
						color="error"
						variant="contained"
						onClick={() => {
							setIsConfirming(false);
						}}
						style={{
							borderTopLeftRadius: "0px",
							borderBottomLeftRadius: "0px"
						}}
					>
						<Cancel />
					</Button>
					<Button
						color="success"
						variant="contained"
						disabled={loading}
						onClick={handleBeginPickup}
					>
						<CheckCircle />
					</Button>
				</ButtonGroup>
			</Fade>
		</ButtonGroup>
	);
};
