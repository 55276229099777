import { gql } from "../../../__generated__";

export const FindOutletsByMarket = gql(/* GraphQL */ `
	query FindOutletsByMarket(
		$marketId: String!
	) {
		FindOutletsByMarket(
			marketId: $marketId
		) {
			...OutletFragment
		}
	}
`);
