import { Box } from "grommet";
import { DialogWithClose } from "../Dialog";
import { ProductMediaCarousel } from "./ProductMediaCarousel";

interface ProductImageDialogProps {
	onClose: () => void;
	media: { name: string; contentUrl: string; }[];
}

export const ProductImageDialog: React.FC<ProductImageDialogProps> = (props) => {
	return (
		<DialogWithClose
			title=""
			fullScreen
			onClose={props.onClose}
			content={(
				<Box
					height="100%"
				>
					<ProductMediaCarousel
						media={props.media}
					/>
				</Box>
			)} />
	);
};