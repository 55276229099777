import { gql } from "@apollo/client";


export const Quote_OrderFragment = gql`
	fragment Quote_OrderFragment on Quote {
		id
		order {
			...Order_HeaderFragment
		}
	}
`;
