import { useQuery } from "@apollo/client";
import { FetchBuilding } from "../../../../graphql";

export function useBuilding(buildingId?: string) {
	const { data, loading } = useQuery(FetchBuilding, {
		variables: {
			buildingId: buildingId || ""
		},
		skip: !buildingId
	});

	return {
		data,
		building: data?.FetchBuilding,
		loading
	};
}