import { useQuery } from "@apollo/client";
import React from "react";
import { EligibleHaulerListItem } from "./EligibleHaulerListItem";
import { FindHaulerPickupRequests } from "../../../../../graphql";

export const EligibleHaulerPickupRequestListItem: React.FC<{
	haulerId: string;
	haulerName: string;
	selected: boolean;
	onToggle(): void;
	pickupId: string;
}> = ({ selected, haulerId, haulerName, onToggle, pickupId }) => {
	const { data, loading } = useQuery(FindHaulerPickupRequests, {
		variables: {
			pickupId,
			haulerId
		}
	});

	return (
		<EligibleHaulerListItem
			selected={selected}
			haulerName={haulerName}
			onToggle={onToggle}
			loading={loading}
			requests={data?.FindHaulerPickupRequests || []} />
	);
};
