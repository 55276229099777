import React from "react";
import { TextFieldElement } from "react-hook-form-mui";

export const SuiteUnitElement: React.FC = () => {
	return (
		<TextFieldElement
			fullWidth
			name="addressLineTwo"
			label="Suite, Unit, etc. (Optional)"
		/>
	);
};