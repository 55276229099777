import { Box } from "grommet";
import { Button, Checkbox, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectFilters, setBrandFilter } from "../../../../store/shop";
import { useBrands } from "../../../product/hooks/useBrands";
import { ActiveFilterItemProps, FilterItem } from "./FilterItem";

export const BrandFilterItem: React.FC<ActiveFilterItemProps> = (props) => {
	const brands = useBrands();
	const dispatch = useAppDispatch();
	const { brandFilter } = useAppSelector(selectFilters);

	function toggleBrands(brand: string) {
		if(brandFilter.includes(brand)) {
			dispatch(setBrandFilter([ ...brandFilter.filter(c => c !== brand) ]));
		}
		else {
			dispatch(setBrandFilter([ ...brandFilter ?? [], brand ]));
		}
	}

	const brandFilterValue = useMemo(() => {
		if(brandFilter.length === 0) return "";
		if(brandFilter.length > 3) return `${brandFilter.length} selected`;
		return brandFilter.join(", ");
	}, [ brandFilter ]);

	return (
		<FilterItem
			title="Brand"
			value={brandFilterValue}
			expanded={props.active}
			toggle={props.setActive}
			details={(
				<Box>
					<List>
						{brands.map(brand => (
							<ListItemButton
								onClick={() => toggleBrands(brand.name)}
							>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={brandFilter.some((value) => value === brand.name)}
										tabIndex={-1}
										disableRipple />
								</ListItemIcon>
								<ListItemText primary={brand.name} />
							</ListItemButton>
						))}
					</List>
					<Box pad="small" align="start">
						<Button
							color="error"
							onClick={() => dispatch(setBrandFilter([]))}
						>
							Clear
						</Button>
					</Box>
				</Box>
			)} />
	);
};
