import { gql } from "../../../__generated__";

export const ProcessWasteTicket = gql(/* GraphQL */ `
	mutation ProcessWasteTicket(
		$date: DateTime!
		$ticketNumber: String!
		$wasteTicketId: String!
		$source: iWasteTicketSourceInput!
		$lineItems: [WasteTicketLineItemInputDTO!]!
	) {
		ProcessWasteTicket(
			date: $date
			ticketNumber: $ticketNumber
			source: $source
			lineItems: $lineItems
			wasteTicketId: $wasteTicketId
		) {
			id
		}
	}
`);