import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { FetchWasteReportQueryVariables } from "../graphql/__generated__/graphql";
import moment from "moment";

export type TimePeriodFilter =
	| "TODAY"
	| "YESTERDAY"
	| "THIS_MONTH"
	| "LAST_MONTH"
	| "LAST_3_MONTHS"
	| "LAST_6_MONTHS"
	| "YTD"
	| "LAST_YEAR"
	| "CUSTOM";

export type TimePeriodGroup =
	| "HOUR"
	| "DAY"
	| "WEEK"
	| "MONTH"
	| "QUARTER"
	| "DAY_OF_WEEK";

export interface ReportingState {
	timePeriod: TimePeriodFilter | null;
	timePeriodGroup: TimePeriodGroup;
	timePeriodCustomStart: string;
	timePeriodCustomEnd: string;
	zipCodes: string[];
	neighborhoods: string[];
	isMultifamily: boolean;
	buildings: string[];
	showGraph: boolean;
	wasteType: string[];
	filterVariables?: FetchWasteReportQueryVariables;
}

const initialState: ReportingState = {
	timePeriod: "THIS_MONTH",
	timePeriodGroup: "MONTH",
	timePeriodCustomStart: moment().startOf("month").format("YYYY-MM-DD"),
	timePeriodCustomEnd: moment().endOf("month").format("YYYY-MM-DD"),
	zipCodes: [],
	neighborhoods: [],
	isMultifamily: false,
	buildings: [],
	showGraph: false,
	wasteType: [],
	filterVariables: undefined
};

export const reportingSlice = createSlice({
	name: "reporting",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setShowGraph: (state, action: PayloadAction<boolean>) => {
			state.showGraph = action.payload;
		},
		setIsMultifamily: (state, action: PayloadAction<boolean>) => {
			state.isMultifamily = action.payload;
		},
		setTimePeriodFilter: (state, action: PayloadAction<TimePeriodFilter>) => {
			if(state.timePeriod !== action.payload) {
				state.timePeriod = action.payload;
			}
			else {
				state.timePeriod = null;
			}
		},
		setWasteTypeFilter: (state, action: PayloadAction<string | string[]>) => {
			const wasteType = action.payload;
			if(Array.isArray(wasteType)) {
				state.wasteType = wasteType;
			}
			else {
				if(state.wasteType.includes(wasteType)) {
					state.wasteType = state.wasteType.filter(z => z !== wasteType);
				}
				else {
					state.wasteType.push(wasteType);
				}
			}
		},
		setTimePeriodGroup: (state, action: PayloadAction<TimePeriodGroup>) => {
			state.timePeriodGroup = action.payload;
		},
		setBuildingFilter(state, action: PayloadAction<string | string[]>) {
			const building = action.payload;
			if(Array.isArray(building)) {
				state.buildings = building;
			}
			else {
				if(state.buildings.includes(building)) {
					state.buildings = [
						...state.buildings.filter(z => z !== building)
					];
				}
				else {
					state.buildings.push(building);
				}
			}
		},
		setZipCodeFilter(state, action: PayloadAction<string | string[]>) {
			const zipCode = action.payload;
			if(Array.isArray(zipCode)) {
				state.zipCodes = zipCode;
			}
			else {
				if(state.zipCodes.includes(zipCode)) {
					state.zipCodes = state.zipCodes.filter(z => z !== zipCode);
				}
				else {
					state.zipCodes.push(zipCode);
				}
			}
		},
		setNeighborhoodFilter(state, action: PayloadAction<string | string[]>) {
			//
		},
		setTimePeriodCustomStart(state, action: PayloadAction<string>) {
			state.timePeriodCustomStart = action.payload;
		},
		setTimePeriodCustomEnd(state, action: PayloadAction<string>) {
			state.timePeriodCustomEnd = action.payload;
		}
	}
});

export const {
	setShowGraph,
	setZipCodeFilter,
	setIsMultifamily,
	setBuildingFilter,
	setTimePeriodFilter,
	setNeighborhoodFilter,
	setTimePeriodGroup,
	setWasteTypeFilter,
	setTimePeriodCustomEnd,
	setTimePeriodCustomStart
} = reportingSlice.actions;

export const selectShowGraph = (state: RootState): boolean => state.reporting.showGraph;
export const selectBuildingFilter = (state: RootState): string[] => state.reporting.buildings;
export const selectWasteTypeFilter = (state: RootState): string[] => state.reporting.wasteType;
export const selectIsMultifamily = (state: RootState): boolean => state.reporting.isMultifamily;
export const selectNeighborhoodFilter = (state: RootState): string[] => state.reporting.neighborhoods;
export const selectZipCodeFilter = (state: RootState): string[] => state.reporting.zipCodes;
export const selectTimePeriodFilter = (state: RootState): TimePeriodFilter | null => state.reporting.timePeriod;
export const selectTimePeriodGroup = (state: RootState): TimePeriodGroup => state.reporting.timePeriodGroup;
export const selectTimePeriodCustom = (state: RootState): [ string, string ] => [ state.reporting.timePeriodCustomStart, state.reporting.timePeriodCustomEnd ];

export default reportingSlice.reducer;