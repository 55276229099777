import { Button, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useAppDispatch } from "../../store";
import { push } from "redux-first-history";
import { TaskAlt } from "@mui/icons-material";
import { Box } from "grommet";
import { useFullScreen, useWindowDimensions } from "../../hooks";
import { Task } from "../../features/task";


export const ActionItemListItem: React.FC<Task> = ({ title, actionUrl, actionLabel, label, icon }) => {
	const dispatch = useAppDispatch();
	const fullScreen = useFullScreen();
	const { sizeIndex } = useWindowDimensions();

	return (
		<ListItemButton divider onClick={() => {
			dispatch(push(actionUrl));
		}}>
			<ListItemIcon>
				<TaskAlt />
			</ListItemIcon>
			<ListItemText>
				<Box style={{ maxWidth: "500px" }}>
					<Typography fontWeight="bold">
						{title}
					</Typography>
					{sizeIndex >= 3 && (
						<Typography variant="caption">
							{label}
						</Typography>
					)}
				</Box>
			</ListItemText>
			{(actionLabel && actionUrl && !fullScreen) && (
				<ListItemSecondaryAction>
					<Button
						size="small"
						onClick={() => {
							dispatch(push(actionUrl));
						}}
						color="primary"
						variant="contained"
					>
						{actionLabel}
					</Button>
				</ListItemSecondaryAction>
			)}
		</ListItemButton>
	);
};
