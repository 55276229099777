import { gql } from "@apollo/client";

export const WasteContainerInspection_MediaFragment = gql`
	fragment WasteContainerInspection_MediaFragment on WasteContainerInspection {
		media {
			id
			name
			contentUrl
		}
	}
`;
