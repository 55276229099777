import { useLazyQuery } from "@apollo/client";
import { useHauler } from "./useHauler";
import { useEffect } from "react";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import { FetchScheduledPickups } from "../../../graphql";

export function useScheduledPickups() {
	const { hauler } = useHauler();
	const snack = useSnackbar();

	const [
		fetch,
		{ data, loading, error }
	] = useLazyQuery(FetchScheduledPickups);

	useEffect(() => {
		if(hauler) {
			fetch({
				variables: {
					haulerId: hauler.id,
					pastCutoffDate: moment().startOf("day").toISOString(),
					futureCutoffDate: moment().add(365, "days").endOf("day").toISOString()
				}
			}).catch(err => {
				console.error("Failed to fetch today's pickups", err);
				snack.enqueueSnackbar("Failed to fetch today's pickups", {
					variant: "error"
				});
			});
		}
	}, [ hauler ]);

	return {
		data,
		loading,
		error,
		pickups: Array.from(data?.FindPickups || []).sort((a, b) => {
			if(!a.scheduledDate) return -1;
			if(!b.scheduledDate) return 1;

			return moment(a.scheduledDate).diff(moment(b.scheduledDate));
		})
	};
}