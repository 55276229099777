import { gql } from "../../../__generated__";

export const FetchEstimatePreviewOnProductCreated = gql(/* GraphQL */ `
	query FetchEstimatePreviewOnProductCreated(
		$intent: ProductIntent!
		$orderId: String!
		$typeId: String!
		$sizeId: String
		$quantity: Int!
		$quantityDisassembly: Int!

	) {
		FetchEstimatePreviewOnProductCreated(
			intent: $intent
			orderId: $orderId
			typeId: $typeId
			sizeId: $sizeId
			quantity: $quantity
			quantityDisassembly: $quantityDisassembly
		) {
			...EstimatePreview_HeaderFragment
		}
	}
`);

export const FetchEstimatePreviewOnProductUpdated = gql(/* GraphQL */ `
	query FetchEstimatePreviewOnProductUpdated(
		$intent: ProductIntent!
		$orderId: String!
		$productId: String!
		$typeId: String!
		$sizeId: String
		$quantity: Int!
		$quantityDisassembly: Int!

	) {
		FetchEstimatePreviewOnProductUpdated(
			intent: $intent
			orderId: $orderId
			productId: $productId
			typeId: $typeId
			sizeId: $sizeId
			quantity: $quantity
			quantityDisassembly: $quantityDisassembly
		) {
			...EstimatePreview_HeaderFragment
		}
	}
`);