import { gql } from "../../../__generated__";

export const FetchPromotionByCode = gql(/* GraphQL */ `
	query FetchPromotionByCode(
		$code: String!
	) {
		FetchPromotionByCode(
			code: $code
		) {
			code
			fixedAmount
			percentageAmount
			description
			isExpired
			validAfter
			validBefore
		}
	}
`);
