import React from "react";
import { useForm } from "react-hook-form";
import { CityElement, DialogWithClose, StateZipElement, StreetAddressElement, SuiteUnitElement } from "../../../../../components";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Button } from "@mui/material";
import { Box } from "grommet";
import { useAppDispatch } from "../../../../../store";
import { useBuildings } from "../../hooks";
import { SimpleAddress, useAutocomplete } from "../../../../../hooks";
import { LoadingButton } from "@mui/lab";

export const CreatePropertyDialog: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const dispatch = useAppDispatch();
	const { create, isCreating } = useBuildings();

	const formContext = useForm({
		defaultValues: {
			name: "",
			addressLineOne: "",
			addressLineTwo: "",
			city: "",
			state: "",
			zipCode: "",
			lat: "",
			lng: "",
			timezone: ""
		}
	});

	useAutocomplete(
		document.getElementById("address-element") as HTMLInputElement,
		(address: SimpleAddress) => {
			const values = formContext.getValues();
			formContext.reset({
				...values,
				name: values.name || address.addressLineOne,
				addressLineOne: address.addressLineOne,
				city: address.city,
				state: address.state,
				zipCode: address.zipCode,
				lat: address.lat,
				lng: address.lng,
				timezone: address.timezone
			});
		}
	);

	function handleSubmit() {
		const { name, ...address } = formContext.getValues();
		create(name, {
			...address,
			floor: 1,
			hasElevator: false,
			hasParking: false,
			userId: ""
		}).then(() => {
			onClose();
		}).catch(err => {
			console.error("Failed to create building", err);
		});
	}

	return (
		<DialogWithClose
			onClose={onClose}
			title="Location Details"
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
					<LoadingButton
						color="primary"
						loading={isCreating}
						variant="contained"
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Submit
					</LoadingButton>
				</Box>
			)}
			content={(
				<FormContainer
					formContext={formContext}
					FormProps={{
						style: { height: "100%" }
					}}
				>
					<Box gap="medium">
						<StreetAddressElement />
						<TextFieldElement
							name="name"
							label="Location Name"
						/>
						<SuiteUnitElement />
						<CityElement />
						<StateZipElement />
					</Box>
				</FormContainer>
			)}
		/>
	);
};