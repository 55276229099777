import React, { useMemo } from "react";
import { CollectionPeriod, WasteContainerVolumeUnit } from "../../../../../../graphql/__generated__/graphql";
import { useToggleForceClose } from "../../../../components";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { WasteContainerState } from "../../../types";
import { useMutation } from "@apollo/client";
import { AssignWasteContainer, FetchBuildingReporting, GetReportingActionItems } from "../../../../../../graphql";
import { DialogWithActivatorButton } from "../../../../../../components";
import { Button, MobileStepper } from "@mui/material";
import { FormContainer } from "react-hook-form-mui";
import { WasteContainerDialogContent } from "./WasteContainerAssignmentDialogContent";
import { LoadingButton } from "@mui/lab";

export const CreateWasteContainerAssignmentDialog: React.FC<{
	activatorButton: React.ReactNode;
	partner?: { id: string; name: string; };
}> = ({ partner, activatorButton }) => {
	const totalSteps = 3;
	const snack = useSnackbar();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	const formContext = useForm<WasteContainerState>({
		defaultValues: {
			name: "",
			type: null,
			location: "",
			volume: 0,
			volumeUnit: WasteContainerVolumeUnit.Cy,
			disposition: null,
			partner: partner || null,
			materialId: "",
			extendedMaterialId: "",
			currentStepIndex: 0,
			collectionFrequency: []
		}
	});

	const {
		currentStepIndex,
	} = formContext.watch();

	function setCurrentStepIndex(index: number): void {
		formContext.setValue("currentStepIndex", index);
	}

	const hasNext = useMemo(() => {
		return currentStepIndex < totalSteps - 1;
	}, [ currentStepIndex ]);

	const hasBack = useMemo(() => {
		return currentStepIndex > 0;
	}, [ currentStepIndex ]);

	function handleBack(): void {
		if(!hasBack) return;
		setCurrentStepIndex(currentStepIndex - 1);
	}

	const [
		assignContainer,
		{ loading }
	] = useMutation(AssignWasteContainer, {
		refetchQueries: [
			FetchBuildingReporting,
			GetReportingActionItems
		]
	});

	function handleSubmit(): void {
		const {
			name,
			location,
			volume,
			volumeUnit,
			containerId,
			partner,
			materialId,
			extendedMaterialId,
			collectionFrequency,
			disposition
		} = formContext.getValues();

		if(!name || !disposition || !location || !containerId || !partner || !materialId || !volume || !volumeUnit || !collectionFrequency) {
			return;
		}

		assignContainer({
			variables: {
				name,
				location,
				containerId,
				volume: Number(volume),
				volumeUnit,
				collectionFrequency: {
					period: CollectionPeriod.Weekly,
					dayOfWeek: collectionFrequency
				},
				partnerId: partner?.id || "",
				materialId: extendedMaterialId || materialId,
				disposition
			}
		}).then(() => {
			toggleForceClose();
		}).catch(err => {
			console.error("Failed to create waste container assignment", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again later.", {
				variant: "error"
			});
		});
	}

	function handleNext(): void {
		if(!hasNext) {
			formContext.handleSubmit(handleSubmit)();
			return;
		}

		formContext.handleSubmit(() => {
			setCurrentStepIndex(currentStepIndex + 1);
		})();
	}

	return (
		<DialogWithActivatorButton
			forceClose={forceClose}
			title="Container Setup"
			activatorButton={activatorButton}
			actions={(
				<MobileStepper
					steps={totalSteps}
					position="static"
					activeStep={currentStepIndex}
					backButton={(
						<Button variant="outlined" onClick={handleBack} disabled={!hasBack || loading}>
							Back
						</Button>
					)}
					nextButton={(
						<LoadingButton
							loading={loading}
							variant="contained"
							onClick={handleNext}
							style={{ minWidth: "90px" }}
						>
							{hasNext ? "Next" : "Submit"}
						</LoadingButton>
					)}
				/>
			)}
		>
			<FormContainer formContext={formContext}>
				<WasteContainerDialogContent
					formContext={formContext}
				/>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};