import { gql } from "@apollo/client";

export const ProductMedia_HeaderFragment = gql`
	fragment ProductMedia_HeaderFragment on ProductMedia {
		id
		name
		context
		isPrimary
		createdAt
		updatedAt
		contentUrl
	}
`;

export const Product_MediaFragment = gql`
	fragment Product_MediaFragment on Product {
		id
		media {
			...ProductMedia_HeaderFragment
		}
	}
`;