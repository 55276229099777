import { SvgIcon, SvgIconProps } from "@mui/material";

	export const SectionalThreePiece: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m477.54 411.91h-261.33v-73.828h261.33zm-243.75-17.578h226.17v-38.672h-226.17z"/>
  <path d="m441.28 618.16h-188.81c-19.992 0-36.258-16.266-36.258-36.258v-187.57h261.33v187.57c0 19.992-16.266 36.258-36.258 36.258zm-207.49-206.25v169.99c0 10.301 8.3789 18.68 18.68 18.68h188.81c10.301 0 18.68-8.3789 18.68-18.68v-169.99z"/>
  <path d="m721.29 411.91h-261.33v-73.828h261.33zm-243.75-17.578h226.17v-38.672h-226.17z"/>
  <path d="m685.03 618.16h-188.81c-19.992 0-36.258-16.266-36.258-36.258v-187.57h261.33v187.57c0 19.992-16.266 36.258-36.258 36.258zm-207.49-206.25v169.99c0 10.301 8.3789 18.68 18.68 18.68h188.81c10.301 0 18.68-8.3789 18.68-18.68v-169.99z"/>
  <path d="m983.79 411.91h-280.08v-73.828h280.08zm-262.5-17.578h244.92v-38.672h-244.92z"/>
  <path d="m947.53 861.91h-207.56c-19.992 0-36.258-16.266-36.258-36.258v-431.32h280.08v431.32c0 19.992-16.266 36.258-36.258 36.258zm-226.24-450v413.74c0 10.301 8.3789 18.68 18.68 18.68h207.56c10.301 0 18.68-8.3789 18.68-18.68v-413.74z"/>
  <path d="m214.23 580.66h-53.461c-10.781 0-19.559-8.7773-19.559-19.559v-192.01c0-17.098 13.91-31.008 31.008-31.008h61.57v223.02c0 10.781-8.7773 19.559-19.559 19.559zm-42.012-225c-7.4062 0-13.43 6.0234-13.43 13.43v192.01c0 1.0898 0.89063 1.9805 1.9805 1.9805h53.461c1.0898 0 1.9805-0.89063 1.9805-1.9805v-205.44z"/>
  <path d="m1039.2 618.16h-53.461c-10.781 0-19.559-8.7773-19.559-19.559v-260.52h61.57c17.098 0 31.008 13.91 31.008 31.008v229.51c0 10.781-8.7773 19.559-19.559 19.559zm-55.441-262.5v242.94c0 1.0898 0.89063 1.9805 1.9805 1.9805h53.461c1.0898 0 1.9805-0.89063 1.9805-1.9805v-229.51c0-7.4062-6.0234-13.43-13.43-13.43z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};