import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Button, List } from "@mui/material";
import { Box, Text } from "grommet";
import { useMemo, useState, useEffect } from "react";
import { useUser } from "../../../../auth";
import { UpdatePrimaryContact, FetchSelf, UserContactGql } from "../../../../graphql";
import { ContactType } from "../../../../graphql/__generated__/graphql";
import { useFullScreen } from "../../../../hooks";
import { ContactFormDialog } from "./ContactFormDialog";
import { ContactListItem } from "./ContactListItem";
import { Add, Close, Save } from "@mui/icons-material";

interface SelectContactDialogProps {
	onClose(): void;
	switchToCreate(): void;
	type: ContactType;
}

export const SelectContactDialog: React.FC<SelectContactDialogProps> = (props) => {
	const fulScreen = useFullScreen();
	const { user, contacts: userContacts } = useUser();

	const contacts = useMemo(() => {
		return userContacts.filter(c => (c.type === props.type));
	}, [ userContacts, props.type ]);

	const [ isSelectingContact, setIsSelectingContact ] = useState("");
	const [ isVerifyingContact, setIsVerifyingContact ] = useState("");

	const [
		selectPrimaryContactMutation,
		{ loading: isSelecting }
	] = useMutation(UpdatePrimaryContact, { refetchQueries: [ FetchSelf ] });

	function selectContact(contact: UserContactGql) {
		if(contact.isVerified && contact.isPrimary) {
			return;
		}

		if(contact.isVerified) {
			setIsSelectingContact(contact.id);
			return;
		}

		setIsVerifyingContact(contact.id);
	}

	useEffect(() => {
		if(isSelectingContact) {
			selectPrimaryContactMutation({
				variables: {
					userId: user?.id ?? "",
					contactId: isSelectingContact
				}
			}).catch(err => {
				console.error("Failed to update primary contact", err);
			}).finally(() => {
				setIsSelectingContact("");
			});
		}
	}, [ isSelectingContact, user, selectPrimaryContactMutation ]);

	return (
		<Dialog fullScreen={fulScreen} fullWidth open={true}>
			{!!isVerifyingContact && (
				<ContactFormDialog
					type={props.type}
					contact={contacts.find(c => c.id === isVerifyingContact)!}
					onClose={() => setIsVerifyingContact("")}
				/>
			)}
			<DialogTitle>
				<Box direction="row" justify="between">
					<Box align="center" justify="center">
						<Typography variant="h5" fontWeight="bold">Update Contact</Typography>
					</Box>
					<IconButton onClick={props.onClose}>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<Box pad="small" gap="medium">
					<Box>
						<Text>Select your preferred contact method</Text>
					</Box>
					<List>
						{contacts.map((contact) => (
							<ContactListItem
								key={contact.id}
								type={contact.type}
								value={contact.value}
								isVerified={contact.isVerified}
								isPrimary={contact.isPrimary}
								isSelected={contact.isPrimary && contact.isVerified}
								isSelecting={isSelecting && isSelectingContact === contact.id}
								onSelect={() => selectContact(contact)}
							/>
						))}
					</List>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box direction="row" justify="end" align="end" pad="small" gap="small">
					<Button
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						onClick={() => props.switchToCreate()}
					>
						Create Contact
					</Button>
					<LoadingButton
						variant="contained"
						color="primary"
						loading={isSelecting}
						disabled={!contacts.some(c => c.isPrimary && c.isVerified)}
						endIcon={<Save />}
						onClick={() => props.onClose()}
					>
						Save
					</LoadingButton>
				</Box>
			</DialogActions>
		</Dialog>
	);
};