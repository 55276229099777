import { Box } from "grommet";
import React from "react";
import { IconButton, ListItemText, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

export const WasteReductionTipSummary: React.FC<{
	primary: string;
	secondary: string;
	reference?: string;
	icon: React.ReactNode;
}> = ({ primary, secondary, reference, icon }) => {
	return (
		<Box direction="row" gap="medium" flex>
			<Box justify="center" width="30px" id="icon-container">
				{icon}
			</Box>
			<Box flex align="end" justify="center">
				<ListItemText
					primary={<Box direction="row" gap="xxsmall">
						{reference && (
							<IconButton
								size="small"
								href={reference}
								target="_blank"
								style={{ padding: "0px" }}
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<Info fontSize="small" />
							</IconButton>
						)}
						<Typography fontWeight="bold">{primary}</Typography>
					</Box>}
					secondary={secondary} />
			</Box>
		</Box>
	);
};
