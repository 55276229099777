import React, { useMemo } from "react";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";
import { IconButton } from "@mui/material";
import moment from "moment-timezone";
import { Visibility } from "@mui/icons-material";
import { BasicListItem } from "../../../../../components";
import { useAdminScopedOrder, usePickupFragment } from "../../../../../graphql";

export const OrderPickupDateListItem: React.FC<{
	orderId: string;
	pickupId: string;
	loading: boolean;
	permitViewPickup?: boolean;
}> = ({ pickupId, loading, orderId, permitViewPickup }) => {
	const dispatch = useAppDispatch();
	const { data } = usePickupFragment(pickupId);

	const canViewPickup = useMemo(() => {
		return permitViewPickup;
	}, [ permitViewPickup ]);

	const { order } = useAdminScopedOrder(orderId);

	const scheduledDateValue = useMemo(() => {
		if(!data && !order) return "Not Scheduled";

		if(!data.id) {
			return order?.preferredDate
				? moment.tz(order.preferredDate, "UTC").hours(12).tz("America/New_York").format("dddd, MMMM Do")
				: "Not Scheduled";
		}

		return data?.id
			? moment.tz(data.scheduledDate ?? "", "UTC").hours(12).tz("America/New_York").format("dddd, MMMM Do")
			: "Not Scheduled";
	}, [ data, order ]);

	return (
		<BasicListItem
			label="Pickup Date"
			loading={loading}
			value={scheduledDateValue}
			action={canViewPickup && (
				<IconButton
					color="primary"
					onClick={() => {
						dispatch(push(`/admin/pickups/${data.id}`));
					}}
				>
					<Visibility />
				</IconButton>
			)} />
	);
};
