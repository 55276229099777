import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Barstool: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m545.27 584.65h-321.87c-7.8906 0-14.289-6.3984-14.289-14.289 0.023438-21.293 8.4922-41.703 23.551-56.762 15.055-15.055 35.469-23.523 56.762-23.547h189.82-0.003906c21.293 0.023438 41.707 8.4922 56.762 23.547 15.055 15.059 23.523 35.469 23.551 56.762 0 3.7891-1.5078 7.4258-4.1875 10.105-2.6797 2.6758-6.3125 4.1836-10.102 4.1836zm-305.57-28.574h289.28c-3.1172-10.789-9.6484-20.27-18.617-27.023-8.9688-6.7539-19.887-10.414-31.113-10.426h-189.82 0.003906c-11.227 0.011719-22.148 3.6719-31.113 10.426-8.9688 6.7539-15.504 16.234-18.617 27.023z"/>
  <path d="m473.47 411.34h-176.61c-18.012-0.019532-35.281-7.1836-48.016-19.922-12.738-12.734-19.902-30-19.922-48.012v-8.2578c0.019531-18.008 7.1836-35.277 19.922-48.012 12.734-12.738 30.004-19.902 48.016-19.922h176.61c18.012 0.019531 35.277 7.1836 48.016 19.922 12.734 12.734 19.898 30.004 19.918 48.012v8.2578c-0.019532 18.012-7.1836 35.277-19.918 48.012-12.738 12.738-30.004 19.902-48.016 19.922zm-176.61-115.54v-0.003907c-10.438 0.015626-20.441 4.1641-27.82 11.543-7.3789 7.3789-11.527 17.383-11.539 27.816v8.2578c0.011718 10.434 4.1602 20.438 11.539 27.816s17.383 11.531 27.82 11.543h176.61c10.434-0.011719 20.438-4.1641 27.816-11.543s11.531-17.383 11.543-27.816v-8.2578c-0.011719-10.434-4.1641-20.438-11.543-27.816-7.3789-7.3789-17.383-11.527-27.816-11.543z"/>
  <path d="m223.41 1018.8c-4.0508-0.003906-7.9141-1.7227-10.625-4.7383-2.7148-3.0117-4.0195-7.0352-3.5977-11.066l46.219-434.11c0.39062-3.7773 2.2695-7.2422 5.2188-9.6289 2.9492-2.3906 6.7305-3.5078 10.504-3.1055 3.7773 0.40234 7.2344 2.293 9.6172 5.2461 2.3789 2.957 3.4844 6.7422 3.0742 10.516l-46.215 434.11c-0.77344 7.2578-6.8945 12.77-14.195 12.777z"/>
  <path d="m540.31 1018.8c-7.293-0.015625-13.406-5.5234-14.18-12.777l-46.227-434.11c-0.41016-3.7734 0.69531-7.5586 3.0742-10.516 2.3828-2.9531 5.8398-4.8438 9.6172-5.2461 3.7734-0.40234 7.5508 0.71484 10.504 3.1055 2.9492 2.3867 4.8281 5.8516 5.2188 9.6289l46.219 434.11c0.42188 4.0312-0.88281 8.0547-3.5977 11.066-2.7109 3.0156-6.5742 4.7344-10.629 4.7383z"/>
  <path d="m517.22 832.71h-274.02c-7.8906 0-14.285-6.3984-14.285-14.289s6.3945-14.285 14.285-14.285h274.02c7.8906 0 14.285 6.3945 14.285 14.285s-6.3945 14.289-14.285 14.289z"/>
  <path d="m527.11 891.53h-290.17c-7.8906 0-14.289-6.3984-14.289-14.289s6.3984-14.289 14.289-14.289h290.17c7.8906 0 14.289 6.3984 14.289 14.289s-6.3984 14.289-14.289 14.289z"/>
  <path d="m351.46 518.62c-7.8906 0-14.289-6.3984-14.289-14.289v-107.29c0-7.8906 6.3984-14.285 14.289-14.285s14.289 6.3945 14.289 14.285v107.29c0 3.7891-1.5078 7.4258-4.1875 10.105-2.6797 2.6797-6.3125 4.1836-10.102 4.1836z"/>
  <path d="m416.87 518.62c-7.8906 0-14.285-6.3984-14.285-14.289v-107.29c0-7.8906 6.3945-14.285 14.285-14.285 7.8906 0 14.289 6.3945 14.289 14.285v107.29c0 3.7891-1.5039 7.4258-4.1836 10.105-2.6797 2.6797-6.3164 4.1836-10.105 4.1836z"/>
  <path d="m976.6 584.65h-321.87c-7.8906 0-14.289-6.3984-14.289-14.289 0.027344-21.293 8.4961-41.703 23.551-56.762 15.055-15.055 35.469-23.523 56.762-23.547h189.82-0.003906c21.293 0.023438 41.707 8.4922 56.762 23.547 15.059 15.059 23.527 35.469 23.551 56.762 0 3.7891-1.5039 7.4258-4.1836 10.105-2.6797 2.6758-6.3164 4.1836-10.105 4.1836zm-305.57-28.574h289.28c-3.1133-10.789-9.6484-20.27-18.617-27.023-8.9648-6.7539-19.887-10.414-31.113-10.426h-189.82 0.003906c-11.227 0.011719-22.145 3.6719-31.113 10.426-8.9688 6.7539-15.5 16.234-18.617 27.023z"/>
  <path d="m904.8 411.34h-176.62c-18.008-0.019532-35.277-7.1836-48.012-19.922-12.734-12.734-19.898-30.004-19.918-48.012v-8.2578c0.019531-18.008 7.1836-35.277 19.918-48.012 12.734-12.734 30.004-19.898 48.012-19.922h176.62c18.012 0.023437 35.277 7.1875 48.016 19.922 12.734 12.734 19.898 30.004 19.918 48.012v8.2578c-0.019531 18.008-7.1836 35.277-19.918 48.012-12.734 12.734-30.004 19.898-48.012 19.922zm-176.62-115.54v-0.003907c-10.434 0.015626-20.441 4.1641-27.816 11.543-7.3789 7.3789-11.531 17.383-11.543 27.816v8.2578c0.011719 10.434 4.1641 20.438 11.543 27.816 7.375 7.3789 17.383 11.531 27.816 11.543h176.62c10.434-0.011719 20.438-4.1641 27.816-11.543 7.3789-7.3789 11.527-17.383 11.539-27.816v-8.2578c-0.011719-10.434-4.1602-20.438-11.539-27.816-7.3789-7.3789-17.383-11.527-27.816-11.543z"/>
  <path d="m654.75 1018.8c-4.0508-0.003906-7.9141-1.7227-10.629-4.7383-2.7109-3.0117-4.0156-7.0352-3.5938-11.066l46.219-434.11c0.85547-7.8281 7.8828-13.492 15.715-12.656 7.832 0.83594 13.512 7.8477 12.699 15.684l-46.219 434.11c-0.77344 7.2578-6.8906 12.766-14.191 12.777z"/>
  <path d="m971.64 1018.8c-7.293-0.015625-13.406-5.5234-14.18-12.777l-46.238-434.11c-0.8125-7.8359 4.8672-14.848 12.699-15.684 7.832-0.83594 14.859 4.8281 15.715 12.656l46.219 434.11c0.42188 4.0312-0.88281 8.0547-3.5977 11.066-2.7109 3.0156-6.5742 4.7344-10.625 4.7383z"/>
  <path d="m948.55 832.71h-274.01c-7.8906 0-14.285-6.3984-14.285-14.289s6.3945-14.285 14.285-14.285h274.01c7.8906 0 14.289 6.3945 14.289 14.285s-6.3984 14.289-14.289 14.289z"/>
  <path d="m958.44 891.53h-290.17c-7.8906 0-14.285-6.3984-14.285-14.289s6.3945-14.289 14.285-14.289h290.17c7.8906 0 14.289 6.3984 14.289 14.289s-6.3984 14.289-14.289 14.289z"/>
  <path d="m782.79 518.62c-7.8906 0-14.285-6.3984-14.285-14.289v-107.29c0-7.8906 6.3945-14.285 14.285-14.285s14.289 6.3945 14.289 14.285v107.29c0 3.7891-1.5039 7.4258-4.1836 10.105-2.6797 2.6797-6.3164 4.1836-10.105 4.1836z"/>
  <path d="m848.2 518.62c-7.8906 0-14.289-6.3984-14.289-14.289v-107.29c0-7.8906 6.3984-14.285 14.289-14.285s14.285 6.3945 14.285 14.285v107.29c0 3.7891-1.5039 7.4258-4.1836 10.105-2.6797 2.6797-6.3125 4.1836-10.102 4.1836z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};