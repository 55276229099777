import { useQuery } from "@apollo/client";
import { FetchUserWithContacts } from "../graphql";

export function useUserQuery(userId?: string) {
	const { data, loading, error } = useQuery(FetchUserWithContacts, {
		variables: { userId: userId || "" },
		skip: !userId,
	});

	return {
		user: data?.FetchUser,
		primaryPhoneNumber: data?.FetchUser.primaryPhoneNumber || null,
		primaryEmailAddress: data?.FetchUser.primaryEmailAddress || null,
		contacts: data?.FetchUser.contacts || [],
		loading,
		error
	};
}