import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";
import { Pagination } from "../../../../components";
import { OrderSummaryListItem } from "./OrderSummaryListItem";

export const OrderSummaryList: React.FC<{
	loading: boolean;
	orders: string[];
}> = ({ orders }) => {
	const dispatch = useAppDispatch();
	return (
		<Pagination pageSize={4}>
			{orders.map((orderId) => {
				return (
					<OrderSummaryListItem
						key={orderId}
						orderId={orderId}
						onClick={() => {
							dispatch(push(`/dashboard/orders/${orderId}`));
						}}
					/>
				);
			})}
		</Pagination>
	);
};