import { Box, Grid, Text } from "grommet";
import { formatCurrency } from "../../../../helpers";
import { RequestQuote } from "@mui/icons-material";
import { Button, Divider } from "@mui/material";

interface PickupQuoteAccordionSummaryProps {
	total: number;
}

export const PickupQuoteAccordionSummary: React.FC<PickupQuoteAccordionSummaryProps> = (props) => {
	return (
		<Grid columns={[ "auto", "small", "flex" ]} gap="small">
			<Box align="center" justify="center">
				<RequestQuote />
			</Box>
			<Box justify="center">
				<Text weight="bold">
					Pickup Quote
				</Text>
			</Box>
			<Box justify="center">
				<Text>
					{formatCurrency(props.total || 0)}
				</Text>
			</Box>
		</Grid>
	);
};

interface PickupQuoteAccordionDetailsProps {
	tax: number;
	subtotal: number;
	discounts: {
		label: string;
		amount: number;
	}[];
	total: number;
	onClickConfirm: () => void;
}

export const PickupQuoteAccordionDetails: React.FC<PickupQuoteAccordionDetailsProps> = (props) => {
	return (
		<Box margin={{ vertical: "small", horizontal: "large" }} gap="medium">
			<Grid columns={[ "small", "flex" ]}>
				<Text weight="bold">
					Subtotal
				</Text>
				<Text textAlign="end">
					{formatCurrency(props.subtotal)}
				</Text>
			</Grid>
			{props.discounts.length > 0 && (
				<Box gap="small">
					<Grid columns={[ "small", "flex" ]}>
						<Text weight="bold">
							Discounts
						</Text>
						<Text textAlign="end">
							{formatCurrency(props.discounts.reduce((total, discount) => total + discount.amount, 0))}
						</Text>
					</Grid>
					{props.discounts.map(discount => (
						<Grid columns={[ "small", "flex" ]}>
							<Text margin={{ left: "small" }}>
								{discount.label}
							</Text>
							<Text textAlign="end">
								{formatCurrency(discount.amount)}
							</Text>
						</Grid>
					))}
				</Box>
			)}
			<Divider />
			<Grid columns={[ "small", "flex" ]}>
				<Text weight="bold">
					Tax
				</Text>
				<Text textAlign="end">
					{formatCurrency(props.tax)}
				</Text>
			</Grid>
			<Grid columns={[ "small", "flex" ]}>
				<Text weight="bold">
					Total
				</Text>
				<Text textAlign="end">
					{formatCurrency(props.total)}
				</Text>
			</Grid>
			<Box flex align="end" justify="end">
				<Button color="primary" variant="contained" onClick={props.onClickConfirm}>
					Confirm
				</Button>
			</Box>
		</Box>
	);
};