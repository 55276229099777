import { useMemo } from "react";
import { useAppSelector } from "../../../store";
import { selectCheckoutState } from "../../../store/checkout";
import { useClassification } from "../../../hooks";

export function useQuantity(
	typeId: string,
	sizeId: string
): number {
	const checkoutState = useAppSelector(selectCheckoutState);
	return useMemo(() => {
		return checkoutState.products.reduce((acc, t) => {
			if(t.type?.id === typeId && (!sizeId || t.size?.id === sizeId)) return acc + t.quantity;
			return acc;
		}, 0);
	}, [ typeId, sizeId, checkoutState ]);
}

export function useSizeQuantity(
	sizeId: string
): number {
	const checkoutState = useAppSelector(selectCheckoutState);
	return useMemo(() => {
		return checkoutState.products.reduce((acc, t) => {
			if(t.size?.id !== sizeId) return acc;
			return acc + t.quantity;
		}, 0);
	}, [ sizeId, checkoutState ]);
}

export function useTypeQuantity(
	typeId: string
): number {
	const checkoutState = useAppSelector(selectCheckoutState);
	return useMemo(() => {
		return checkoutState.products.reduce((acc, t) => {
			if(t.type?.id !== typeId) return acc;
			return acc + t.quantity;
		}, 0);
	}, [ typeId, checkoutState ]);
}

export function useTypeQuantityDisassembly(
	typeId: string
): number {
	const checkoutState = useAppSelector(selectCheckoutState);
	return useMemo(() => {
		return checkoutState.products.reduce((acc, t) => {
			if(t.type?.id !== typeId) return acc;
			return acc + t.quantityDisassembly;
		}, 0);
	}, [ typeId, checkoutState ]);
}

export function useSizeQuantityDisassembly(
	sizeId: string
): number {
	const checkoutState = useAppSelector(selectCheckoutState);
	return useMemo(() => {
		return checkoutState.products.reduce((acc, t) => {
			if(t.size?.id !== sizeId) return acc;
			return acc + t.quantityDisassembly;
		}, 0);
	}, [ sizeId, checkoutState ]);
}

export function useQuantityDisassembly(
	typeId: string,
	sizeId: string
): number {
	const checkoutState = useAppSelector(selectCheckoutState);
	return useMemo(() => {
		return checkoutState.products.reduce((acc, t) => {
			if(t.type?.id === typeId && (!sizeId || t.size?.id === sizeId)) return acc + t.quantityDisassembly;
			return acc;
		}, 0);
	}, [ typeId, sizeId, checkoutState ]);
}

export function useQuantityCategory(
	categoeryId: string
): number {
	const { types } = useClassification();
	const checkoutState = useAppSelector(selectCheckoutState);
	return useMemo(() => {
		return checkoutState.products.reduce((acc, t) => {
			const type = types.find(type => type.id === t.type?.id);
			if(!type) return acc;
			if(type.category?.id !== categoeryId) return acc;
			return acc + t.quantity;
		}, 0);
	}, [ categoeryId, checkoutState ]);
}