import React, { useMemo } from "react";
import { TabController, ViewContainer } from "../../../../components";
import { ActiveOrdersTabFactory, CompletedOrdersTabFactory, RecentlyViewedOrdersTabFactory, SavedOrdersTabFactory, defaultColumns } from "..";
import { useInstance } from "../../../../hooks";
import { InstanceType } from "../../../../graphql/__generated__/graphql";

export const OrdersView: React.FC = () => {
	const { instance } = useInstance();

	const nameFieldLabel = useMemo(() => {
		switch(instance?.type) {
			case InstanceType.Residence: {
				return "Resident Name";
			}
			case InstanceType.University: {
				return "Student Name";
			}
			case InstanceType.DonationCenter: {
				return "Donor Name";
			}
			default: {
				return "Customer Name";
			}
		}
	}, [ instance ]);

	const refererField = useMemo(() => {
		switch(instance?.type) {
			case InstanceType.Residence: {
				return "Building";
			}
			case InstanceType.DonationCenter: {
				return "Donation Center";
			}
			case InstanceType.ManagementCompany: {
				return "Building";
			}
			default: {
				return "Referer";
			}
		}
	}, [ instance ]);

	const columnOverride = useMemo(() => {
		return defaultColumns.map(c => ({
			...c,
			headerName: c.field === "user"
				? nameFieldLabel
				: c.field === "referer"
					? refererField
					: c.headerName
		}));
	}, [ refererField, nameFieldLabel ]);

	return (
		<ViewContainer>
			<TabController
				defaultValue="active"
				tabs={[
					ActiveOrdersTabFactory(columnOverride),
					CompletedOrdersTabFactory(columnOverride),
					RecentlyViewedOrdersTabFactory(columnOverride),
					SavedOrdersTabFactory(columnOverride)
				]}
			/>
		</ViewContainer>
	);
};