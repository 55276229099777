import { gql } from "../../../__generated__";

export const CreateAndSubmitOrder = gql(/* GraphQL */ `
	mutation CreateAndSubmitOrder(
		$userId: String!
		$refererId: String!
		$instanceId: String!
		$pickupId: String
		$scheduledDate: String!
		$scheduledWindow: PickupWindowInput
		$paymentMethodId: String
		$address: CreateAddressInputDTO!
		$products: [ CreateProductInputDTO! ]!
	) {
		CreateAndSubmitOrder(
			userId: $userId
			refererId: $refererId
			instanceId: $instanceId
			pickupId: $pickupId
			scheduledDate: $scheduledDate
			scheduledWindow: $scheduledWindow
			address: $address
			products: $products
			paymentMethodId: $paymentMethodId
		) {
			id
		}
	}
`);