import { gql } from "@apollo/client";

export const Hauler_HeaderFragment = gql`
	fragment Hauler_HeaderFragment on Hauler {
		id
		name
		permittedDays
		permittedWindows {
			to
			from
			label
		}
	}
`;