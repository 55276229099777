import { CreateManyHaulerPickupRequests, CreateManyHaulerQuoteRequests, FindEligibleHaulersByOrder, FindHaulerPickupRequests, useGlobalAdminScopedOrder } from "../../../../../graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ReactNode, useMemo, useState } from "react";
import React from "react";
import { DialogWithActivatorButton } from "../../../../../components";
import { Box, Text } from "grommet";
import { Button, InputAdornment, List, Typography } from "@mui/material";
import { FetchAdminTasks, FindHaulerQuoteRequests } from "../../../../../graphql";
import { LoadingButton } from "@mui/lab";
import { EligibleHaulerPickupRequestListItem } from "./EligibleHaulerPickupRequestListItem";
import { EligibleHaulerQuoteRequestListItem } from "./EligibleHaulerQuoteRequestListItem";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

export function useToggleForceClose() {
	const [ forceClose, setForceClose ] = useState(false);

	function toggle(): void {
		setForceClose(true);
		setTimeout(() => {
			setForceClose(false);
		}, 10);
	}

	return {
		forceClose,
		toggleForceClose: toggle
	};
}

export const GlobalAdminQuoteRequestDialog: React.FC<{
	orderId: string;
	activationButton: ReactNode;
}> = ({ orderId, activationButton }) => {
	const snack = useSnackbar();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	const [
		submit, { loading: isSubmitting }
	] = useMutation(CreateManyHaulerQuoteRequests, {
		refetchQueries: [
			FetchAdminTasks,
			FindHaulerQuoteRequests,
			FindEligibleHaulersByOrder
		]
	});

	const formContext = useForm({
		defaultValues: {
			amountOffered: 0,
		}
	});

	function handleSubmitRequest(haulers: string[]) {
		if(haulers.length === 0) {
			snack.enqueueSnackbar("Please select at least one hauler", {
				variant: "warning"
			});
			return;
		}

		submit({
			variables: {
				orderId,
				haulerIds: haulers
			}
		}).then((res) => {
			const failureCount = res.data?.CreateManyHaulerQuoteRequests?.errors?.length || 0;
			const successCount = res.data?.CreateManyHaulerQuoteRequests?.success?.length || 0;
			snack.enqueueSnackbar(`${successCount} requests submitted successfully. ${failureCount} failed.`, {
				variant: "success"
			});
			toggleForceClose();
		}).catch(err => {
			console.error("Failed to request hauler quotes for order", err);
			snack.enqueueSnackbar("Failed to request hauler quote for order", {
				variant: "error"
			});
		});
	}

	return (
		<HaulerRequestController
			orderId={orderId}
			pickupId={""}
			requestType="QUOTE_REQUEST"
			isSubmitting={isSubmitting}
			activatorButton={activationButton}
			forceClose={forceClose}
			toggleForceClose={toggleForceClose}
			handleSubmit={(haulers) => formContext.handleSubmit(() => handleSubmitRequest(haulers))()}
		/>
	);
};

export const GlobalAdminPickupRequestDialog: React.FC<{
	orderId: string;
	pickupId: string;
	activationButton: ReactNode;
}> = ({ pickupId, orderId, activationButton }) => {
	const snack = useSnackbar();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	const [
		submit, { loading: isSubmitting }
	] = useMutation(CreateManyHaulerPickupRequests, {
		refetchQueries: [
			FetchAdminTasks,
			FindHaulerPickupRequests,
			FindEligibleHaulersByOrder
		]
	});

	const formContext = useForm({
		defaultValues: {
			amountOffered: 0,
		}
	});

	function handleSubmitRequest(haulers: string[]) {
		const amountOffered = formContext.getValues().amountOffered;

		if(!amountOffered) {
			snack.enqueueSnackbar("Please enter an amount to offer", {
				variant: "warning"
			});
			return;
		}

		if(haulers.length === 0) {
			snack.enqueueSnackbar("Please select at least one hauler", {
				variant: "warning"
			});
			return;
		}

		submit({
			variables: {
				pickupId,
				amountOffered,
				haulerIds: haulers
			}
		}).then((res) => {
			const failureCount = res.data?.CreateManyHaulerPickupRequests?.errors?.length || 0;
			const successCount = res.data?.CreateManyHaulerPickupRequests?.success?.length || 0;
			snack.enqueueSnackbar(`${successCount} requests submitted successfully. ${failureCount} failed.`, {
				variant: "success"
			});
			toggleForceClose();
		}).catch(err => {
			console.error("Failed to request hauler quotes for order", err);
			snack.enqueueSnackbar("Failed to request hauler quote for order", {
				variant: "error"
			});
		});
	}

	return (
		<HaulerRequestController
			orderId={orderId}
			pickupId={pickupId}
			requestType="PICKUP_REQUEST"
			isSubmitting={isSubmitting}
			activatorButton={activationButton}
			forceClose={forceClose}
			toggleForceClose={toggleForceClose}
			handleSubmit={(haulers) => formContext.handleSubmit(() => handleSubmitRequest(haulers))()}
		>
			<Box>
				<FormContainer formContext={formContext}>
					<Box>
						<TextFieldElement
							name="amountOffered"
							required
							label="Amount Offered"
							type="number"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										$
									</InputAdornment>
								)
							}}
						/>
					</Box>
				</FormContainer>
			</Box>
		</HaulerRequestController>
	);
};

type HaulerRequestType =
	| "QUOTE_REQUEST"
	| "PICKUP_REQUEST";

export const HaulerRequestController: React.FC<{
	orderId: string;
	pickupId: string;
	children?: ReactNode;
	handleSubmit(haulers: string[]): void;
	isDisabled?: boolean;
	isSubmitting: boolean;
	activatorButton: ReactNode;
	requestType: HaulerRequestType;
	forceClose: boolean;
	toggleForceClose(): void;
}> = ({ orderId, pickupId, children, requestType, activatorButton, isSubmitting, isDisabled, handleSubmit, forceClose, toggleForceClose }) => {
	const { allProductsHaveDisposition } = useGlobalAdminScopedOrder(orderId);

	const { data: eligibleHaulersData, loading: eligibleHaulersLoading } = useQuery(FindEligibleHaulersByOrder, {
		variables: {
			orderId
		},
		skip: !orderId
	});

	const [ selectedHaulers, setSelectedHaulers ] = useState<{
		id: string,
		name: string;
	}[]>([]);

	function toggleHauler(haulerId: string) {
		const hauler = eligibleHaulersData?.FindEligibleHaulers?.find(h => h.id === haulerId);
		if(!hauler) return;

		if(selectedHaulers.some(h => h.id === haulerId)) {
			setSelectedHaulers(selectedHaulers.filter(h => h.id !== haulerId));
		}
		else {
			setSelectedHaulers([ ...selectedHaulers, hauler ]);
		}
	}

	const title = useMemo(() => {
		switch(requestType) {
			case "PICKUP_REQUEST": {
				return "Create Hauler Pickup Request";
			}
			case "QUOTE_REQUEST": {
				return "Request Hauler Quotes";
			}
		}
	}, [ requestType ]);

	return (
		<DialogWithActivatorButton
			title={title}
			forceClose={forceClose}
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="contained"
						onClick={() => toggleForceClose()}
					>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={isSubmitting}
						disabled={isDisabled || eligibleHaulersLoading || !allProductsHaveDisposition}
						onClick={() => handleSubmit(selectedHaulers.map(h => h.id))}
					>
						Submit
					</LoadingButton>
				</Box>
			)}
		>
			<Box gap="medium" flex height="100%">
				<Text size="large">
					Select the haulers you would like to notify
				</Text>
				<Box height="medium" overflow={{ vertical: "auto" }}>
					<List>
						{(eligibleHaulersData?.FindEligibleHaulers || []).map(hauler => {
							return (requestType === "QUOTE_REQUEST")
								? (
									<EligibleHaulerQuoteRequestListItem
										key={hauler.id}
										haulerId={hauler.id}
										haulerName={hauler.name}
										orderId={orderId}
										selected={selectedHaulers.some(h => h.id === hauler.id)}
										onToggle={() => toggleHauler(hauler.id)}
									/>
								)
								: (
									<EligibleHaulerPickupRequestListItem
										key={hauler.id}
										haulerId={hauler.id}
										haulerName={hauler.name}
										pickupId={pickupId}
										selected={selectedHaulers.some(h => h.id === hauler.id)}
										onToggle={() => toggleHauler(hauler.id)}
									/>
								);
						})}
					</List>
				</Box>
				{children}
				<Box flex justify="end">
					<Typography fontWeight="bold" color="error">
						{!allProductsHaveDisposition && "Warning: Not all products have been assigned an outlet"}
					</Typography>
				</Box>
			</Box>
		</DialogWithActivatorButton>
	);
};