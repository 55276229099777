import { Box } from "grommet";
import React, { useMemo } from "react";
import { Button } from "@mui/material";
import { DialogWithActivatorButton, ItemTypeElement } from "../../../../components";
import { HaulerProductExceptionType } from "../../../../graphql/__generated__/graphql";
import { AutocompleteElement, FormContainer, SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { DateCalendar } from "@mui/x-date-pickers";
import { LocalizationProvider } from "../../../../provider";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { FetchHaulerActionItems, FetchHaulerPickup, FetchStopDetailed, ReportProductDelayedException, ReportProductDivertedException, ReportProductException, ReportProductIncorrectException, ReportProductIncorrectQuantityException } from "../../../../graphql";
import { LoadingButton } from "@mui/lab";
import { useAcceptingOutlets } from "../../../../hooks";
import { useToggleForceClose } from "../../../admin/components";

export const HaulerProductExceptionDialog: React.FC<{
	stopId: string;
	haulerId: string;
	isOrigin: boolean;
	productId: string;
	movementId: string;
	activatorButton?: React.ReactNode;
}> = ({ stopId, productId, haulerId, isOrigin, movementId, activatorButton }) => {
	const snack = useSnackbar();
	const optionsDestination = [
		{
			label: "Drop off at a later date",
			id: HaulerProductExceptionType.DestinationDelayed
		},
		{
			label: "Item was not accepted",
			id: HaulerProductExceptionType.DestinationProductDeclined
		},
		{
			label: "Item dropped off at another location",
			id: HaulerProductExceptionType.DestinationDiverted
		}
	];

	const optionsOrigin = [
		{
			label: "Not available for pickup",
			id: HaulerProductExceptionType.OriginProductMissing
		},
		{
			label: "Unable to pickup this item",
			id: HaulerProductExceptionType.OriginUnable
		},
		{
			label: "Different item picked up",
			id: HaulerProductExceptionType.OriginIncorrectProduct
		},
		{
			label: "Different amount picked up",
			id: HaulerProductExceptionType.OriginIncorrectQuantity
		}
	];

	const options = useMemo(() => {
		return isOrigin ? optionsOrigin : optionsDestination;
	}, [ isOrigin ]);

	const formContext = useForm<{
		delayedUntil?: Date | null;
		reason: HaulerProductExceptionType;
		type?: { id: string; name: string; } | null;
		updatedQuantity?: number | null;
		outlet: { id: string; label: string; } | null;
	}>({
		defaultValues: undefined
	});

	const formValues = formContext.watch();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	const [
		reportProductException, { loading: isSubmitting }
	] = useMutation(ReportProductException, {
		refetchQueries: [ FetchHaulerPickup, FetchStopDetailed ]
	});

	const [
		reportProductDelayedException, { loading: isSubmittingDelayed }
	] = useMutation(ReportProductDelayedException, {
		refetchQueries: [ FetchHaulerPickup, FetchStopDetailed ]
	});

	const [
		reportProductDivertedException, { loading: isSubmittingDiverted }
	] = useMutation(ReportProductDivertedException, {
		refetchQueries: [ FetchHaulerPickup, FetchStopDetailed, FetchHaulerActionItems ]
	});

	const [
		reportProductIncorrectQuantityException, { loading: isSubmittingQuantity }
	] = useMutation(ReportProductIncorrectQuantityException, {
		refetchQueries: [ FetchHaulerPickup, FetchStopDetailed ]
	});

	const [
		reportProductIncorrectTypeException, { loading: isSubmittingType }
	] = useMutation(ReportProductIncorrectException, {
		refetchQueries: [ FetchHaulerPickup, FetchStopDetailed ]
	});

	const isAnySubmitting = useMemo(() => {
		return isSubmitting || isSubmittingDelayed || isSubmittingDiverted || isSubmittingQuantity || isSubmittingType;
	}, [ isSubmitting, isSubmittingDelayed, isSubmittingDiverted, isSubmittingQuantity, isSubmittingType ]);

	function handleSubmit(): void {
		if(formValues.reason === HaulerProductExceptionType.DestinationDelayed && !formValues.delayedUntil) {
			snack.enqueueSnackbar("Please select a date", {
				variant: "error"
			});
			return;
		}

		if(formValues.reason === HaulerProductExceptionType.DestinationDelayed) {
			reportProductDelayedException({
				variables: {
					stopId,
					haulerId,
					productId,
					pickupId: movementId,
					delayedUntil: moment(formValues.delayedUntil).toISOString()
				}
			}).then(() => {
				toggleForceClose();
			}).catch(err => {
				console.error("Failed to report product exception", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", {
					variant: "error"
				});
			});
			return;
		}
		else if(formValues.reason === HaulerProductExceptionType.DestinationDiverted) {
			if(!formValues.outlet) return;

			reportProductDivertedException({
				variables: {
					stopId,
					haulerId,
					productId,
					pickupId: movementId,
					outletId: formValues.outlet.id
				}
			}).then(() => {
				toggleForceClose();
			}).catch(err => {
				console.error("Failed to report product exception", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", {
					variant: "error"
				});
			});
		}
		else if(formValues.reason === HaulerProductExceptionType.OriginIncorrectQuantity) {
			if(!formValues.updatedQuantity) return;

			reportProductIncorrectQuantityException({
				variables: {
					stopId,
					haulerId,
					productId,
					pickupId: movementId,
					updatedQuantity: Number(formValues.updatedQuantity)
				}
			}).then(() => {
				toggleForceClose();
			}).catch(err => {
				console.error("Failed to report product exception", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", {
					variant: "error"
				});
			});
			return;
		}
		else {
			reportProductException({
				variables: {
					stopId,
					haulerId,
					productId,
					pickupId: movementId,
					reason: formValues.reason
				}
			}).then(() => {
				toggleForceClose();
			}).catch(err => {
				console.error("Failed to report product exception", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", {
					variant: "error"
				});
			});
		}
	}

	return (
		<DialogWithActivatorButton
			title="Confirm Details"
			forceClose={forceClose}
			activatorButton={activatorButton || (
				<Button variant="contained" color="error">
					{isOrigin ? "Not Pickup Up" : "Not Dropped Off"}
				</Button>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="contained" color="error" onClick={toggleForceClose}>
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						color="primary"
						loading={isAnySubmitting}
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Confirm
					</LoadingButton>
				</Box>
			)}
		>
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<SelectElement
						name="reason"
						required
						label="Select Reason"
						options={options} />
					{formValues.reason === HaulerProductExceptionType.DestinationDelayed && (
						<LocalizationProvider>
							<DateCalendar
								disablePast
								disabled={formContext.watch("reason") !== HaulerProductExceptionType.DestinationDelayed}
								value={formContext.watch("delayedUntil")}
								showDaysOutsideCurrentMonth
								onChange={(value) => {
									formContext.setValue("delayedUntil", value);
								}}
								shouldDisableDate={(date) => {
									return moment(date).isAfter(moment().add(5, "days"), "day");
								}} />
						</LocalizationProvider>
					)}
					{formValues.reason === HaulerProductExceptionType.DestinationDiverted && (
						<DestinationDivertedContainer
							productId={productId}
						/>
					)}
					{formValues.reason === HaulerProductExceptionType.OriginIncorrectProduct && (
						<ItemTypeElement
							// name="item"
							type={null}
							isLoading={false} />
					)}
					{formValues.reason === HaulerProductExceptionType.OriginIncorrectQuantity && (
						<TextFieldElement
							name="updatedQuantity"
							label="Updated Quantity"
							type="number"
							inputMode="numeric"
							required
						/>
					)}
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};

export const DestinationDivertedContainer: React.FC<{
	productId: string;
}> = ({ productId }) => {
	const { outlets, loading } = useAcceptingOutlets(productId);
	return (
		<AutocompleteElement
			required
			name="outlet"
			label="Where was this item diverted to?"
			loading={loading}
			options={outlets.map(outlet => ({
				id: outlet.id,
				label: outlet.name
			}))}
		/>
	);
};