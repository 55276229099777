import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Vanity: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m231.94 361.41v704.66c0 9.9492 8.0625 18 18 18h39.324v55.922h36v-55.922l549.47 0.023437-0.003907 55.898h36v-55.895h39.324c4.7656 0 9.3477-1.8945 12.73-5.2695 3.3828-3.3711 5.2695-7.957 5.2695-12.73v-704.66c0-1.8125-0.34766-3.5391-0.85156-5.1836 5.0898-22.223 7.8242-45.145 7.8242-68.363 0-9.9492-8.0625-18-18-18l-245.41-0.015625v-45.047c0-16.824 13.691-30.504 30.504-30.504h63.035v-36h-63.035c-36.672 0-66.504 29.832-66.504 66.504v45.035h-57.613v-209.87h-36v209.87h-57.613v-45.035c0-36.672-29.832-66.504-66.504-66.504h-63.035v36h63.035c16.812 0 30.504 13.68 30.504 30.504v45.035l-245.43-0.011719c-4.7656 0-9.3477 1.8945-12.73 5.2695-3.3828 3.3711-5.2695 7.957-5.2695 12.73 0 23.219 2.7344 46.141 7.8242 68.375-0.5 1.6797-0.84766 3.4102-0.84766 5.1836zm700.12 18.027v141.93l-664.12-0.003907v-141.95zm-664.12 177.92h314.06v490.73l-314.06-0.011719zm350.06 490.73v-490.73h314.06v490.74zm320.45-742.2c-0.83984 12.73-2.8555 25.223-5.4492 37.547l-666.01-0.023438c-2.5938-12.336-4.6094-24.828-5.4492-37.559z"/>
  <path d="m645.18 654.62h36v77.785h-36z"/>
  <path d="m518.82 654.62h36v77.785h-36z"/>
  <path d="m559.25 434.21h81.492v36h-81.492z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};