import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { ViewContainer } from "../../../../components";
import { Box, Grid, Heading, Text } from "grommet";
import { useClassification } from "../../../../hooks";
import { AutocompleteElement, FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { Button, Divider, IconButton } from "@mui/material";
import { Cancel, Flight, Scale } from "@mui/icons-material";
import { formatNumber } from "../../../../helpers";

interface State {
	items: { id: number, typeId: string; quantity: number; }[];
}

export const DashboardCard: React.FC<{ height?: string; background?: string; children?: ReactNode; }> = (props) => {
	return (
		<Box
			round
			flex
			pad="medium"
			elevation="medium"
			height={props.height ?? undefined}
			background={props.background ?? undefined}
		>
			{props.children}
		</Box>
	);
};

export const SustainabilityCalculator: React.FC = () => {
	const { types } = useClassification();
	const [ state, setState ] = useState<State>({
		items: [ {
			id: 0,
			typeId: "",
			quantity: 0
		} ]
	});

	const totalWeightDivertedPounds = useMemo(() => {
		return state.items.reduce((total, item) => {
			const type = types.find((type) => type.id === item.typeId);
			if(type) {
				return total + (type.assumptions.weightLowPounds + type.assumptions.weightHighPounds) / 2 * item.quantity;
			}

			return total;
		}, 0);
	}, [ state.items ]);

	const kgCo2ePerPound = 1.445;
	const kgCo2ePerFlightHour = 250;

	const kgCo2e = useMemo(() => {
		return totalWeightDivertedPounds * kgCo2ePerPound;
	}, [ totalWeightDivertedPounds, kgCo2ePerPound ]);

	const weightComparison = useMemo(() => {
		const weight = totalWeightDivertedPounds;
		if(weight >= 300000) {
			const compare = formatNumber(weight / 300000, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare}</Text> Blue Whale(s) worth of non recycled waste
				</Text>
			);
		}

		if(weight >= 12000) {
			const compare = formatNumber(weight / 12000, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare} T-Rex(s)</Text> worth of non recycled waste
				</Text>
			);
		}

		if(weight >= 5000) {
			const compare = formatNumber(weight / 5000, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare} Rhino(s)</Text> worth of non recycled waste
				</Text>
			);
		}

		if(weight >= 600) {
			const compare = formatNumber(weight / 600, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare} adult grizzly bear(s)</Text> worth of non recycled waste
				</Text>
			);
		}

		return (
			<Text>
				<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from (almost) an adult grizzly bear(s) worth of non recycled waste
			</Text>
		);
	}, [ totalWeightDivertedPounds ]);

	const emissionsSavings = useMemo(() => {
		if(kgCo2e) {
			const flightHours = kgCo2e / kgCo2ePerFlightHour;
			if(flightHours < 5) {
				const flightMinutes = flightHours * 60;
				return (
					<Text>
						<Text weight="bold" style={{ textDecoration: "underline" }}>The Air</Text> from <Text weight="bold">{formatNumber(flightMinutes, true)} minutes</Text> worth of flying in Kim K's private jet
					</Text>
				);
			}

			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Air</Text> from <Text weight="bold">{formatNumber(flightHours, true)} hours</Text> worth of flying in Kim K's private jet
				</Text>
			);
		}

		return 0;
	}, [ kgCo2e ]);

	return (
		<ViewContainer>
			<Box gap="medium">
				<Box direction="row" gap="medium">
					<Box gap="medium" flex>
						<Heading level="2" margin="none">
							Waste Diversion Calculator
						</Heading>
						{state.items.map((item) => (
							<ItemEntryRecord
								key={item.id}
								id={item.id}
								typeId={item.typeId}
								quantity={item.quantity}
								onChange={(value) => {
									const newItems = [ ...state.items ];
									const index = newItems.findIndex((item) => item.id === value.id);
									newItems[ index ] = value;
									setState({
										...state,
										items: newItems
									});
								}}
								onDelete={(id: number) => {
									const newItems = [ ...state.items ];
									const index = state.items.findIndex((i) => i.id === id);
									if(index > -1) {
										newItems.splice(index, 1);
										setState({
											...state,
											items: newItems
										});
									}
								}}
							/>
						))}
						<Box align="start">
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									setState({
										...state,
										items: [ ...state.items, {
											id: Math.max(...state.items.map((item) => item.id)) + 1,
											typeId: "",
											quantity: 0
										} ]
									});
								}}
							>
								Add
							</Button>
						</Box>
					</Box>
					<Divider orientation="vertical" />
					<Box gap="medium" style={{ display: "block", maxWidth: "500px" }}>
						<Box align="center">
							<Heading level="2" margin="none">
								You're Saving
							</Heading>
						</Box>
						<DashboardCard background="white">
							<Grid columns={[ "auto", "flex", "auto" ]} gap="small">
								<Box align="center" justify="center">
									<Flight />
								</Box>
								<Box>
									<Text>{emissionsSavings}</Text>
								</Box>
								<Box align="center" justify="center">
									<Text weight="bold">{formatNumber(kgCo2e, true)} kg</Text>
									<Text size="small">CO<sub>2</sub> emissions</Text>
								</Box>
							</Grid>
						</DashboardCard>
						<DashboardCard background="white">
							<Grid columns={[ "auto", "flex", "auto" ]} gap="small">
								<Box align="center" justify="center">
									<Scale />
								</Box>
								<Box>
									<Text>{weightComparison}</Text>
								</Box>
								<Box align="center" justify="center">
									<Text weight="bold">{formatNumber(totalWeightDivertedPounds, true)} lb(s)</Text>
									<Text size="small">of bulk waste</Text>
								</Box>
							</Grid>
						</DashboardCard>
					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};

export const ItemEntryRecord: React.FC<{
	id: number;
	typeId: string;
	quantity: number;
	onDelete(id: number): void;
	onChange?: (value: { id: number, typeId: string; quantity: number; }) => void;
}> = ({ id, typeId, quantity, onDelete, onChange }) => {
	const { types } = useClassification();

	const formContext = useForm({
		defaultValues: {
			type: types.find((type) => type.id === typeId) ?? null,
			quantity: quantity || 1
		}
	});

	function handleChange(): void {
		if(onChange) {
			onChange({
				id,
				typeId: formContext.getValues().type?.id ?? "",
				quantity: formContext.getValues().quantity
			});
		}
	}

	useEffect(() => {
		const typeFromForm = formContext.getValues("type");
		if(typeFromForm?.id !== typeId) {
			handleChange();
		}
	}, [ formContext.watch("type") ]);

	useEffect(() => {
		const quantityFromFrom = formContext.getValues("quantity");
		if(quantityFromFrom !== quantity) {
			handleChange();
		}
	}, [ formContext.watch("quantity") ]);

	return (
		<FormContainer formContext={formContext}>
			<Grid columns={[ "flex", "auto", "auto" ]} gap="small">
				<AutocompleteElement
					label="Item Type"
					name="type"
					required
					options={types.map(t => ({ id: t.id, label: t.name, category: t.category }))}
					autocompleteProps={{
						groupBy: (option) => {
							return option.category.name;
						},
						getOptionLabel: (option) => {
							return option.label ?? option.name;
						}
					}}
				/>
				<TextFieldElement
					name="quantity"
					type="number"
					inputMode="numeric"
				/>
				<Box align="center" justify="center">
					<IconButton color="error" onClick={() => {
						onDelete(id);
					}}>
						<Cancel />
					</IconButton>
				</Box>
			</Grid>
		</FormContainer>
	);
};