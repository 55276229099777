import { gql } from "../../../__generated__";

export const ListProductsForSale = gql(/* GraphQL */ `
	query ListProductsForSale(
 		$instanceId: String!
		$page: Int!
		$limit: Int!
		$brands: [String!]
		$priceMin: Float
		$priceMax: Float
		$conditions: [ProductCondition!]
 	) {
		ListProductsForSale(
			instanceId: $instanceId
			page: $page
			limit: $limit
			brands: $brands
			priceMin: $priceMin
			priceMax: $priceMax
			conditions: $conditions
		) {
			page
 			limit
 			total
 			nextPage
 			lastPage
 			previousPage
 			data {
	 			...ProductFragment
				...ProductListingFragment
 			}
		}
 	}
`);
