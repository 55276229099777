import { useLazyQuery, useMutation } from "@apollo/client";
import { FindStorePartnerByInstance, StorePartnerGql, UpdatePartnerSeller } from "../../../graphql";
import { useInstance } from "../../../hooks";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { FindProductsBySeller } from "../../../graphql";

export function useStorePartner() {
	const snack = useSnackbar();
	const { instance } = useInstance();

	const [
		findPartners,
		{ data, loading, error }
	] = useLazyQuery(FindStorePartnerByInstance);

	const [
		updatePartnerSeller,
		{ data: updatedPartner, loading: updatingPartner, error: errorUpdatingPartner }
	] = useMutation(UpdatePartnerSeller);

	function handleUpdatePartnerSeller(
		storeId: string,
		displayName?: string,
		allowsPickup?: boolean,
		allowsDelivery?: boolean,
		pickupInstructions?: string
	) {
		updatePartnerSeller({
			variables: {
				storeId,
				displayName,
				allowsPickup,
				allowsDelivery,
				pickupInstructions
			}
		}).catch(err => {
			console.error("Failed to update partner seller.", err);
			snack.enqueueSnackbar(
				"We ran into an issue saving your information. Please try again later.",
				{ variant: "error" }
			);
		});
	}

	useEffect(() => {
		if(instance) {
			findPartners({
				variables: {
					instanceId: instance.id
				}
			});
		}
	}, [ instance ]);

	useEffect(() => {
		if(error) {
			snack.enqueueSnackbar(
				"We ran into an issue loading your information. Please try again later.",
				{ variant: "error" }
			);
		}
	}, [ error ]);

	const [ selectedPartner, setSelectedPartner ] = useState<StorePartnerGql | null>(null);

	useEffect(() => {
		if(data?.FindStorePartners && !selectedPartner) {
			setSelectedPartner(data.FindStorePartners[ 0 ]);
		}
	}, [ data, selectedPartner ]);

	const [
		findProducts,
		{ data: products, loading: loadingProducts, error: errorProducts }
	] = useLazyQuery(FindProductsBySeller);

	useEffect(() => {
		if(selectedPartner) {
			findProducts({
				variables: {
					sellerId: selectedPartner.id
				}
			}).catch(err => {
				console.error("Failed to find products by seller.", err);
				snack.enqueueSnackbar(
					"We ran into an issue loading your information. Please try again later.",
					{ variant: "error" }
				);
			});
		}
	}, [ selectedPartner ]);

	return {
		isLoading: loading,
		isUpdating: updatingPartner,
		partners: data?.FindStorePartners || [],
		selectedPartner,
		products: products?.FindProducts || [],
		updatePartnerSeller: handleUpdatePartnerSeller,
		setSelectedPartner: (partner: StorePartnerGql) => setSelectedPartner(partner)
	};
}