import React, { ReactNode } from "react";
import { ArrowForwardIosSharp, ExpandMore } from "@mui/icons-material";
import { AccordionSummary as MuiAccordionSummary, AccordionDetails, Accordion as MuiAccordion, styled, AccordionSummaryProps, AccordionProps } from "@mui/material";
import { Box } from "grommet";

export const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		sx={{ padding: "10px" }}
		expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === "dark"
			? "rgba(255, 255, 255, .05)"
			: "rgba(0, 0, 0, .03)",
	flexDirection: "row",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

interface AccordionControllerProps {
	name: string;
	isExpanded: boolean;
	onChange: AccordionProps[ "onChange" ];
	summary: ReactNode;
	summaryProps?: AccordionSummaryProps;
	details: ReactNode;
	disabled?: boolean;
}

export const AccordionController: React.FC<AccordionControllerProps> = (props) => {
	return (
		<Accordion disabled={props.disabled} expanded={props.isExpanded} id={`panel-${props.name}`} onChange={props.onChange}>
			<AccordionSummary {...props.summaryProps} expandIcon={<ExpandMore />}>
				<Box justify="center" align="start" flex>
					{props.summary}
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: "12px" }}>
				{props.details}
			</AccordionDetails>
		</Accordion>
	);
};