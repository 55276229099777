import { CheckCircle } from "@mui/icons-material";
import { ListItemIcon, ListItemText, ListItemSecondaryAction, ListItemButton } from "@mui/material";
import { ListItemSkeleton } from "../../../../../components";

interface ProductDetailsListItemProps {
	loading?: boolean;
	primary: string;
	secondary: string;
	action?: React.ReactNode;
}

export const ProductDetailsListItem: React.FC<ProductDetailsListItemProps> = (props) => {
	return (
		<ListItemButton divider>
			<ListItemIcon>
				<CheckCircle />
			</ListItemIcon>
			<ListItemText
				primary={props.primary}
				secondary={<ListItemSkeleton loading={props.loading} content={props.secondary} />}
			/>
			{props.action && (
				<ListItemSecondaryAction>
					{props.action}
				</ListItemSecondaryAction>
			)}
		</ListItemButton>
	);
};