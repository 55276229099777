import { Box, Heading } from "grommet";
import React, { Fragment } from "react";
import { useSidebarShrink } from "../../../components";
import { List, Typography } from "@mui/material";
import { useAppSelector } from "../../../store";
import { selectCheckoutState } from "../../../store/checkout";
import { CheckoutAddressSummaryController } from "../controller";
import { useCheckoutSession, useCheckoutSessionEstimate } from "../hooks";
import { CheckoutSummaryProductListItem } from "./CheckoutSummaryProductListItem";

export const CheckoutSummarySidebar: React.FC<{
	shrink?: boolean;
}> = (props) => {
	const shrink = useSidebarShrink();
	const checkoutState = useAppSelector(selectCheckoutState);
	const session = useCheckoutSession();

	const { estimate } = useCheckoutSessionEstimate();

	return (
		<Box
			pad="small"
			height="100%"
			gap="small"
			background="rgb(245, 245, 245)"
			overflow={{ vertical: "scroll" }}
			style={{ display: shrink ? "none" : undefined }}
		>
			<Box align="center" margin="small">
				<Heading level="3" margin="none">
					Order Summary
				</Heading>
			</Box>
			<Box gap="small">
				{!!session.address && (
					<Fragment>
						<Typography fontWeight="bold" sx={{ marginLeft: "12px" }}>
							Pickup Address
						</Typography>
						<List>
							<CheckoutAddressSummaryController />
						</List>
					</Fragment>
				)}
				{checkoutState.products.length > 0 && (
					<Fragment>
						<Typography fontWeight="bold" sx={{ marginLeft: "12px" }}>
							Pickup Item(s)
						</Typography>
						<List>
							{checkoutState.products.map((product) => (
								<CheckoutSummaryProductListItem
									key={product.type?.id + (product.size?.id || "")}
									loading={false}
									typeId={product.type?.id || ""}
									sizeId={product.size?.id || ""}

								/>
							))}
						</List>
					</Fragment>
				)}
			</Box>
		</Box>
	);
};