import React, { useMemo } from "react";
import { SidebarRouter, SidebarRouteItem, DashboardListItem, OrdersListItem, WasteReportingListItem, SidebarListItem } from "../../../components";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { isNotNull } from "../../../helpers";
import { useFeature } from "../../instance/hooks/useFeature";
import { OrderDetailsView, ProductDetails, WasteReportingDashboardView, WasteTicketView } from "../common";
import { Apartment, Assessment, VerifiedUser } from "@mui/icons-material";
import { PropertiesView, DashboardView, OrdersView, AuditsView, CertificationsView, WasteContainerInspectionsView, LocationDetailsView } from "./views";
import { useActionItems } from "./hooks";
import { WasteAuditBetaDialog } from "./components";
import { AuditDetailsView } from "./views/AuditDetails";

export const ReportingRouter: React.FC = () => {
	return useReportingRouter().toRouter();
};

export function useReportingRouter() {
	const { tasks } = useActionItems();

	const isRemovalEnabled = useFeature(InstanceFeature.RemovalEnabled);
	const isDonationEnabled = useFeature(InstanceFeature.DonationEnabled);

	return useMemo(() => {
		return new SidebarRouter([
			new SidebarRouteItem(
				"/dashboard",
				<DashboardView />,
				<DashboardListItem
					key="dashboard-list-item"
					absolutePath="/admin/dashboard"
					notificationCount={tasks.length}
				/>,
				true
			),
			(isRemovalEnabled || isDonationEnabled) ? new SidebarRouteItem(
				"/orders",
				<OrdersView />,
				<OrdersListItem
					key="orders-list-item"
					absolutePath="/admin/orders"
				/>,
				true
			) : null,
			new SidebarRouteItem(
				"/location",
				<PropertiesView />,
				<SidebarListItem
					text="Locations"
					key="locations-list-item"
					icon={<Apartment fontSize="small" />}
					path={"/admin/location"}
				/>,
				true
			),
			new SidebarRouteItem(
				"/audits",
				<AuditsView />,
				<WasteAuditBetaDialog
					activatorButton={(
						<SidebarListItem
							text="Audits"
							key="audits-list-item"
							icon={<Assessment fontSize="small" />}
							path="/admin/audits"
						/>
					)}
				/>,
				true
			),
			new SidebarRouteItem(
				"/certifications",
				<CertificationsView />,
				<SidebarListItem
					text="Certifications"
					key="certifications-list-item"
					icon={<VerifiedUser fontSize="small" />}
					path="/admin/certifications"
					onClick={() => {
						window.open("https://www.liverego.com/certifications", "_blank");
					}}
				/>,
				true
			),
			new SidebarRouteItem(
				"/waste-reporting/*",
				<WasteReportingDashboardView />,
				<WasteReportingListItem
					key="waste-reporting"
					absolutePath="/admin/waste-reporting"
				/>,
				true
			)
		].filter(isNotNull), "Reporting", [
			{ from: "/waste-reporting", to: "/admin/waste-reporting/dashboard" },
			{ from: "/", to: "/admin/dashboard" }
		].filter(isNotNull), [
			{ path: "/audits/:auditId", element: <AuditDetailsView /> },
			{ path: "/orders/:orderId", element: <OrderDetailsView permitEdit={false} permitViewPickup={false} /> },
			{ path: "/products/:productId", element: <ProductDetails permitEdit={false} /> },
			{ path: "/waste-reporting/dashboard", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/upload", element: <WasteTicketView /> },
			{ path: "/container-inspections", element: <WasteContainerInspectionsView /> },
			{ path: "/locations/:locationId", element: <LocationDetailsView /> }
		]);
	}, [ tasks.length, isRemovalEnabled, isDonationEnabled ]);
}