import { gql } from "@apollo/client";

export const CancellationDetailsFragment = gql`
	fragment CancellationDetailsFragment on iOrderCancellationDetails {
		isCancellable
		isFreeCancellation
		minimumFreeCancellationHours
		minimumPartialCancellationHours
		maximumCancellationFee
		maximumCancellationFeePercentage
		currentCancellationFee
		currentCancellationFeePercentage
		freeCancellationBy
		partialCancellationFee
		partialCancellationFeePercentage
	}
`;

export const Order_CancellationDetailsFragment = gql`
	fragment Order_CancellationDetailsFragment on Order {
		cancellationDetails {
			...CancellationDetailsFragment
		}
	}
`;