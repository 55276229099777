import { SvgIcon, SvgIconProps } from "@mui/material";

export const EntertainmentCenter: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path d="m1162.5 862.5h-18.75v-281.25c0-11.25-7.5-18.75-18.75-18.75h-18.75v-318.75c0-11.25-7.5-18.75-18.75-18.75h-225c-11.25 0-18.75 7.5-18.75 18.75v318.75h-187.5v-37.5h131.25c11.25 0 18.75-7.5 18.75-18.75v-206.25c0-11.25-7.5-18.75-18.75-18.75h-375c-11.25 0-18.75 7.5-18.75 18.75v206.25c0 11.25 7.5 18.75 18.75 18.75h131.25v37.5h-187.5v-318.75c0-11.25-7.5-18.75-18.75-18.75h-225c-11.25 0-18.75 7.5-18.75 18.75v318.75h-18.75c-11.25 0-18.75 7.5-18.75 18.75v281.25h-18.75c-11.25 0-18.75 7.5-18.75 18.75v75c0 11.25 7.5 18.75 18.75 18.75h1125c11.25 0 18.75-7.5 18.75-18.75v-75c0-11.25-7.5-18.75-18.75-18.75zm-281.25-600h187.5v300h-187.5zm-450 56.25h337.5v168.75h-337.5zm150 206.25h37.5v37.5h-37.5zm-450-262.5h187.5v300h-187.5zm-37.5 337.5h1012.5v262.5h-1012.5zm1050 337.5h-1087.5v-37.5h1087.5z" />
					<path d="m337.5 637.5h-187.5c-11.25 0-18.75 7.5-18.75 18.75v150c0 11.25 7.5 18.75 18.75 18.75h187.5c11.25 0 18.75-7.5 18.75-18.75v-150c0-11.25-7.5-18.75-18.75-18.75zm-18.75 150h-150v-112.5h150z" />
					<path d="m1050 637.5h-187.5c-11.25 0-18.75 7.5-18.75 18.75v150c0 11.25 7.5 18.75 18.75 18.75h187.5c11.25 0 18.75-7.5 18.75-18.75v-150c0-11.25-7.5-18.75-18.75-18.75zm-18.75 150h-150v-112.5h150z" />
					<path d="m787.5 637.5h-375c-11.25 0-18.75 7.5-18.75 18.75v150c0 11.25 7.5 18.75 18.75 18.75h375c11.25 0 18.75-7.5 18.75-18.75v-150c0-11.25-7.5-18.75-18.75-18.75zm-356.25 37.5h337.5v37.5h-337.5zm337.5 112.5h-337.5v-37.5h337.5z" />
					<path d="m225 412.5c-31.875 0-56.25 24.375-56.25 56.25s24.375 56.25 56.25 56.25 56.25-24.375 56.25-56.25-24.375-56.25-56.25-56.25zm0 75c-11.25 0-18.75-7.5-18.75-18.75s7.5-18.75 18.75-18.75 18.75 7.5 18.75 18.75-7.5 18.75-18.75 18.75z" />
					<path d="m262.5 337.5c0 20.711-16.789 37.5-37.5 37.5s-37.5-16.789-37.5-37.5 16.789-37.5 37.5-37.5 37.5 16.789 37.5 37.5" />
					<path d="m975 412.5c-31.875 0-56.25 24.375-56.25 56.25s24.375 56.25 56.25 56.25 56.25-24.375 56.25-56.25-24.375-56.25-56.25-56.25zm0 75c-11.25 0-18.75-7.5-18.75-18.75s7.5-18.75 18.75-18.75 18.75 7.5 18.75 18.75-7.5 18.75-18.75 18.75z" />
					<path d="m1012.5 337.5c0 20.711-16.789 37.5-37.5 37.5s-37.5-16.789-37.5-37.5 16.789-37.5 37.5-37.5 37.5 16.789 37.5 37.5" />
				</g>
			</svg>
		</SvgIcon>
	);
};