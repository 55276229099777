import { gql } from "@apollo/client";

export const Building_ScheduledPickupsFragment = gql`
	fragment Building_ScheduledPickupsFragment on Building {
		scheduledPickups {
			id
			scheduledDate
			window {
				from
				to
				label
			}
		}
	}
`;