import { SvgIcon, SvgIconProps } from "@mui/material";

	export const SoundSystem: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m204.09 577.18c-12.578 0-22.816 10.23-22.816 22.816 0 12.578 10.23 22.809 22.816 22.809 12.586 0 22.816-10.23 22.816-22.809-0.007812-12.586-10.238-22.816-22.816-22.816z" />
		<path
			d="m204.09 510.14c-49.551 0-89.863 40.312-89.863 89.863s40.312 89.863 89.863 89.863c49.551 0 89.863-40.312 89.863-89.863s-40.312-89.863-89.863-89.863zm0 142.23c-28.875 0-52.363-23.488-52.363-52.363s23.488-52.363 52.363-52.363 52.363 23.488 52.363 52.363-23.488 52.363-52.363 52.363z" />
		<path
			d="m601.55 824.42c-12.586 0-22.816 10.23-22.816 22.809 0 12.586 10.23 22.816 22.816 22.816 12.578 0 22.809-10.23 22.809-22.816 0-12.578-10.238-22.809-22.809-22.809z" />
		<path
			d="m601.55 741.32c-58.402 0-105.91 47.512-105.91 105.91 0 58.402 47.512 105.91 105.91 105.91 58.395 0 105.91-47.512 105.91-105.91 0.003907-58.395-47.508-105.91-105.91-105.91zm0 174.32c-37.727 0-68.414-30.691-68.414-68.414 0-37.719 30.691-68.406 68.414-68.406 37.719 0 68.406 30.691 68.406 68.406 0.003907 37.723-30.688 68.414-68.406 68.414z" />
		<path
			d="m226.91 364.52c0 12.602-10.215 22.812-22.816 22.812-12.602 0-22.816-10.211-22.816-22.812s10.215-22.816 22.816-22.816c12.602 0 22.816 10.215 22.816 22.816" />
		<path
			d="m204.09 274.64c-49.551 0-89.863 40.312-89.863 89.863 0 49.551 40.312 89.863 89.863 89.863 49.551 0 89.863-40.312 89.863-89.863 0-49.543-40.312-89.863-89.863-89.863zm0 142.24c-28.875 0-52.363-23.488-52.363-52.371 0-28.875 23.488-52.363 52.363-52.363s52.363 23.488 52.363 52.363c0 28.883-23.488 52.371-52.363 52.371z" />
		<path
			d="m779.53 644.48h-355.82c-14.496 0-26.25 11.754-26.25 26.25 0 14.496 11.754 26.25 26.25 26.25h355.82c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m256.46 903.01h-104.74c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h104.75c10.359 0 18.75-8.3906 18.75-18.75-0.003906-10.359-8.4023-18.75-18.762-18.75z" />
		<path
			d="m1067.6 168.08h-143.44c-51.594 0-93.57 41.969-93.57 93.562v278.09h-461.25v-278.09c0-51.594-41.969-93.562-93.562-93.562h-143.45c-51.594 0-93.562 41.969-93.562 93.562v744.02c0 14.496 11.754 26.25 26.25 26.25h1069.9c14.496 0 26.25-11.754 26.25-26.25v-744.02c0-51.594-41.969-93.562-93.562-93.562zm-976.33 93.562c0-22.641 18.422-41.062 41.062-41.062h143.44c22.641 0 41.062 18.422 41.062 41.062v717.77h-225.56zm278.07 330.59h461.26v387.19h-461.26zm739.32 387.19h-225.57v-717.77c0-22.641 18.422-41.062 41.07-41.062h143.44c22.641 0 41.062 18.422 41.062 41.062z" />
		<path
			d="m995.92 577.18c-12.586 0-22.816 10.23-22.816 22.816 0 12.578 10.23 22.809 22.816 22.809 12.578 0 22.809-10.23 22.809-22.809 0-12.586-10.238-22.816-22.809-22.816z" />
		<path
			d="m995.92 510.14c-49.551 0-89.863 40.312-89.863 89.863s40.312 89.863 89.863 89.863 89.863-40.312 89.863-89.863-40.312-89.863-89.863-89.863zm0 142.23c-28.883 0-52.363-23.488-52.363-52.363s23.488-52.363 52.363-52.363 52.363 23.488 52.363 52.363-23.488 52.363-52.363 52.363z" />
		<path
			d="m995.92 341.7c-12.586 0-22.816 10.23-22.816 22.816s10.23 22.816 22.816 22.816c12.578 0 22.809-10.23 22.809-22.816s-10.238-22.816-22.809-22.816z" />
		<path
			d="m995.92 274.64c-49.551 0-89.863 40.312-89.863 89.863 0 49.551 40.312 89.863 89.863 89.863s89.863-40.312 89.863-89.863c0-49.543-40.312-89.863-89.863-89.863zm0 142.24c-28.883 0-52.363-23.488-52.363-52.371 0-28.875 23.488-52.363 52.363-52.363s52.363 23.488 52.363 52.363c0 28.883-23.488 52.371-52.363 52.371z" />
		<path
			d="m1048.3 903.01h-104.75c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h104.75c10.359 0 18.75-8.3906 18.75-18.75 0-10.359-8.3906-18.75-18.75-18.75z" />
		<path
			d="m773.19 778.3h-38.242c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h38.242c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3945-18.75-18.75-18.75z" />
		<path
			d="m773.19 880.84h-38.242c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h38.242c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3945-18.75-18.75-18.75z" />
		<path
			d="m468.14 778.3h-38.242c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h38.242c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3906-18.75-18.75-18.75z" />
		<path
			d="m468.14 880.84h-38.242c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h38.242c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3906-18.75-18.75-18.75z" />
	</g>
</svg>
			</SvgIcon>
		);
	};