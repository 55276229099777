import { Box, Heading } from "grommet";
import { FormContainer, SwitchElement, TextFieldElement, TextareaAutosizeElement, useForm } from "react-hook-form-mui";
import { PageNotAvailableView } from "../../../../views/NotAvailable";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { CopyAll, OpenInNew } from "@mui/icons-material";
import { useStorePartner } from "../../hooks/useStorePartner";
import { Fragment, useEffect } from "react";
import { useStorePermissions } from "../../hooks";
import { ViewContainer } from "../../../../components";
import { StoreSelectorComponent } from "../../components";
import "../../../../css/form.css";
import { LoadingButton } from "@mui/lab";
import { useClipboard } from "../../../../hooks";

export const StoreSettingsView: React.FC = () => {
	const { copy } = useClipboard();
	const { isEnabled } = useStorePermissions();
	const { selectedPartner, isUpdating, isLoading, updatePartnerSeller } = useStorePartner();

	const formContext = useForm({
		defaultValues: {
			displayName: "",
			url: "",
			allowsPickup: false,
			allowsDelivery: false,
			pickupInstructions: ""
		}
	});

	useEffect(() => {
		if(selectedPartner) {
			formContext.setValue("displayName", selectedPartner.seller.displayName);
			formContext.setValue("url", selectedPartner.instance.domain + "/shop");
			formContext.setValue("allowsPickup", selectedPartner.seller.allowsPickup);
			formContext.setValue("allowsDelivery", selectedPartner.seller.allowsDelivery);
			formContext.setValue("pickupInstructions", selectedPartner.seller.pickupInstructions);
		}
	}, [ selectedPartner ]);

	const formValues = formContext.watch();

	if(!isEnabled) {
		return (<PageNotAvailableView />);
	}

	function handleSubmit() {
		if(!selectedPartner) {
			return;
		}

		updatePartnerSeller(
			selectedPartner.id,
			formValues.displayName,
			formValues.allowsPickup,
			formValues.allowsDelivery,
			formValues.pickupInstructions
		);
	}

	return (
		<ViewContainer>
			<Box className="form-parent">
				<FormContainer formContext={formContext}>
					<Box gap="medium" flex>
						<Heading margin="none" level="3">
							Store Settings
						</Heading>
						<TextFieldElement
							required
							name="displayName"
							label="Display Name"
							helperText={`Sold by ${formValues.displayName}`}
						/>
						<TextFieldElement
							name="url"
							label="Store URL"
							disabled
							InputProps={{
								endAdornment: (
									<Fragment>
										{/* <InputAdornment position="end">
											<IconButton onClick={() => {
												snack.enqueueSnackbar(
													"Contact us to update your store URL",
													{ variant: "info" }
												);
											}}>
												<Edit />
											</IconButton>
										</InputAdornment> */}
										<InputAdornment position="end">
											<IconButton onClick={() => {
												copy(formValues.url);
											}}>
												<CopyAll />
											</IconButton>
										</InputAdornment>
										<InputAdornment position="end">
											<IconButton onClick={() => {
												window.open("http://" + formValues.url, "_blank");
											}}>
												<OpenInNew />
											</IconButton>
										</InputAdornment>
									</Fragment>
								)
							}}
						/>
						<Typography fontWeight="bold">
							Pickup & Delivery Options
						</Typography>
						<Box pad={{ left: "small" }} gap="small">
							<SwitchElement
								name="allowsPickup"
								label={(
									<Typography fontWeight="bold">
										Allow Free Store Pickup
									</Typography>
								)}
								switchProps={{

								}}
							/>
							{formValues.allowsPickup && (
								<TextareaAutosizeElement
									label="Pickup Instructions"
									name="pickupInstructions"
									rows={3}
									disabled={!formValues.allowsPickup}
									required={!!formValues.allowsPickup}
								/>
							)}
							<SwitchElement
								name="allowsDelivery"
								disabled
								label={(
									<Typography fontWeight="bold">
										Allow Delivery
									</Typography>
								)}
							/>
						</Box>
						<Box align="end" flex justify="end">
							<LoadingButton
								color="primary"
								variant="contained"
								loading={isLoading || isUpdating}
								onClick={formContext.handleSubmit(handleSubmit)}
							>
								Save Changes
							</LoadingButton>
						</Box>
						<StoreSelectorComponent />
					</Box>
				</FormContainer>
			</Box>
		</ViewContainer>
	);
};