import { useQuery } from "@apollo/client";
import { FindHaulerPendingQuoteRequests, FindHaulerPendingQuotes } from "../../../graphql";
import { useLazyHauler } from "./useHauler";

export function useHaulerQuoteRequests(haulerId: string) {
	const { data, loading, error } = useQuery(FindHaulerPendingQuoteRequests, {
		skip: !haulerId,
		variables: { haulerId: haulerId }
	});

	return {
		requests: data?.FindHaulerPendingQuoteRequests ?? [],
		loading,
		error
	};
}

export function useHaulerQuotes(haulerId: string) {
	const { hauler } = useLazyHauler(haulerId);

	const { data, loading } = useQuery(FindHaulerPendingQuotes, {
		skip: !hauler,
		variables: { haulerId }
	});

	return {
		loading,
		pendingPickupRequests: hauler?.pendingRequests ?? [],
		pendingQuoteRequests: data?.FindHaulerPendingQuotes ?? []
	};
}