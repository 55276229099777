import React from "react";
import { OnboardingStep } from "./OnboardingStep";
import { Text } from "grommet";

export const STEPS: OnboardingStep[] = [
	new OnboardingStep({
		id: "BUSINESS_INFORMATION",
		label: "Organization Information",
		description: "Let us know a little bit about your organization and industry",
		helperTitle: "Have multiple locations?",
		helperText: [
			"Enter the address for your primary location and we'll help you add more later",
			<br />,
			<br />,
			<Text>
				<Text weight="bold">Example:</Text> You're a college campus with 50+ locations. Enter your primary campus address here and we'll help you add the rest later
			</Text>
		]
	}),
	new OnboardingStep({
		id: "WASTE_PROFILE",
		label: "Waste Profile",
		description: "Give us an idea of the waste you generate and the waste types you would like to track",
		helperTitle: "Not sure what to select?",
		helperText: [
			"Select the waste types that are most common for your organization",
			<br />,
			<br />,
			"Need help? We can help you further identify your waste profile later"
		],
	}),
	new OnboardingStep({
		id: "REPORTING_CUSTOMIZATION",
		label: "Customize Reporting",
		description: "Customize your reporting to fit your needs",
		helperTitle: "Customized Reporting",
		helperText: [
			"Use these settings to customize the look and feel of your reporting",
			<br />,
			<br />,
			"To get you started, we automatically filled in some default settings. You can always change these later"
		]
	}),
	new OnboardingStep({
		id: "REVIEW",
		label: "Review & Submit",
		description: "Review your information and submit your onboarding application",
		helperTitle: "Almost there!",
		helperText: [
			"Review your information and submit your application",
			<br />,
			<br />,
			"Once you submit, it typically takes about 15 seconds to load your dashboard"
		]
	})
];