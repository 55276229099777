import { Box, Grid, Image } from "grommet";
import { useFullScreen } from "../../../hooks";
import { useMemo, useState } from "react";
import { ProductImageDialog, ProductMediaCarousel } from "../../../components";

export interface ProductDetailsMediaComponentProps {
	media: { name: string, contentUrl: string; file?: File, isPrimary?: boolean; }[];
}

export const ProductDetailsMediaComponent: React.FC<ProductDetailsMediaComponentProps> = (props) => {
	const fullScreen = useFullScreen();

	const primary = useMemo(() => {
		return props.media.find(m => m.isPrimary) ?? props.media[ 0 ];
	}, [ props.media ]);

	const [ showProductImageDialog, setShowProductImageDialog ] = useState(false);

	if(fullScreen) {
		return (
			<Box height="small">
				<ProductMediaCarousel
					media={props.media}
				/>
			</Box>
		);
	}

	return (
		<Box gap="small" flex>
			{showProductImageDialog && (
				<ProductImageDialog
					onClose={() => setShowProductImageDialog(false)}
					media={props.media}
				/>
			)}
			<Box background="light-2" height="medium">
				<Image
					fit="contain"
					src={primary?.contentUrl}
					onClick={() => setShowProductImageDialog(true)}
				/>
			</Box>
			<Grid columns={{ count: 4, size: "auto" }} gap="small">
				{props.media.slice(0, 4).map(media => (
					<Box key={media.name} flex>
						<Image
							fit="contain"
							src={media?.contentUrl}
							style={{ maxHeight: "200px" }}
							onClick={() => setShowProductImageDialog(true)}
						/>
					</Box>
				))}
			</Grid>
		</Box>
	);
};
