import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Box, Grid, Heading, Image } from "grommet";
import { useFullScreen, useInstance } from "../../../hooks";
import { useFeatureGuard } from "../../instance/hooks/useFeature";
import { InstanceFeature, ProductIntent } from "../../../graphql/__generated__/graphql";
import { useMutation } from "@apollo/client";
import { FetchCheckoutSession, UpsertCheckoutSession, isReferralPartnerFragment } from "../../../graphql";
import { useSnackbar } from "notistack";
import { useUser } from "../../../auth";
import { useAppDispatch } from "../../../store";
import { push } from "redux-first-history";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { setDefaultIntent } from "../../../store/schedule";
import greenCouchImage from "../../../img/green-couch.webp";
import { CheckCircle, LocalShipping, Sell, Weekend } from "@mui/icons-material";

export const SellView: React.FC = () => {
	const snack = useSnackbar();
	useFeatureGuard(InstanceFeature.SalesP2PEnabled, "/dashboard");

	const { user } = useUser();
	const { instance } = useInstance();
	const dispatch = useAppDispatch();

	const [
		upsertCheckoutSessionMutation,
		{ loading: upsertCheckoutSessionLoading, data: upsertCheckoutSessionData, error: upsertCheckoutSessionError }
	] = useMutation(UpsertCheckoutSession, { refetchQueries: [ FetchCheckoutSession ] });

	function handleSubmit(): void {
		if(!user) {
			dispatch(push(`/login?redirect=${window.location.pathname}`));
			return;
		}

		if(!instance) {
			snack.enqueueSnackbar(
				"We ran into an issue. Please try refreshing the page.",
				{ variant: "error" }
			);
			return;
		}

		const referralPartners = instance.partners.filter(isReferralPartnerFragment);
		//TODO: Ask user if multiple referral partners
		const referer = referralPartners[ 0 ];


		upsertCheckoutSessionMutation({
			variables: {
				userId: user?.id ?? "",
				refererId: referer.id,
				instanceId: instance.id
			}
		}).catch(err => {
			console.error("Failed to upsert checkout session", err);
		});
	}

	useEffect(() => {
		if(upsertCheckoutSessionError) {
			snack.enqueueSnackbar(
				"Something went wrong. Please try again.",
				{ variant: "error" }
			);
		}
	}, [ upsertCheckoutSessionError, snack ]);

	useEffect(() => {
		if(upsertCheckoutSessionData?.UpsertCheckoutSession.id) {
			dispatch(setDefaultIntent(ProductIntent.Resale));
			dispatch(push("/schedule?sid=" + upsertCheckoutSessionData.UpsertCheckoutSession.id));
		}
	}, [ upsertCheckoutSessionData, dispatch ]);

	const fullScreen = useFullScreen();

	return (
		<Box gap="medium" margin="large">
			<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} margin="large" gap="medium">
				<Box gap="medium">
					<Box>
						<Heading color="black">
							Ready to list something for sale?
						</Heading>
						<Typography>
							List your items for sale in just a few clicks. We'll handle the rest.
						</Typography>
					</Box>
					<Box flex align="start">
						<LoadingButton
							variant="contained"
							color="primary"
							size="large"
							onClick={handleSubmit}
							loading={upsertCheckoutSessionLoading}
						>
							Get Started
						</LoadingButton>
					</Box>
				</Box>
				{!fullScreen && (
					<Box height="medium">
						<Image
							fit="contain"
							src={greenCouchImage}
						/>
					</Box>
				)}
			</Grid>
			<Box gap="medium">
				<Box align="center">
					<Typography variant="h4">
						Need a few more reasons?
					</Typography>
				</Box>
				<Box margin={{ horizontal: "medium" }} align="center">
					<List disablePadding>
						<ListItem disableGutters>
							<ListItemIcon>
								<CheckCircle fontSize="large" color="primary" />
							</ListItemIcon>
							<ListItemText
								primary={<Typography fontWeight="bold">Easy (and free) to list</Typography>}
								secondary="Takes less than 5 minutes. Add your photos and details. We'll handle the rest."
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemIcon>
								<CheckCircle fontSize="large" color="primary" />
							</ListItemIcon>
							<ListItemText
								primary={<Typography fontWeight="bold">White glove delivery and pickup</Typography>}
								secondary="We streamline the pickup and delivery process."
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemIcon>
								<CheckCircle fontSize="large" color="primary" />
							</ListItemIcon>
							<ListItemText
								primary={<Typography fontWeight="bold">Fast and secure payments</Typography>}
								secondary="Once your item is delivered, you'll receive your payment within 2-3 business days."
							/>
						</ListItem>
					</List>
				</Box>
			</Box>
			<Box>
				<Box align="center" gap="medium">
					<Typography variant="h4">
						How it Works
					</Typography>
					<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="medium">
						<Box align="center" gap="small">
							<Box align="center" justify="center">
								<Weekend color="primary" fontSize="large" />
							</Box>
							<Heading margin="none" level={3} color="black">
								Create your listing
							</Heading>
							<Typography sx={{ maxWidth: "400px" }} textAlign="center">
								Upload your images and give us a few details on your item. We'll offer some price suggestions but the choice is yours.
							</Typography>
						</Box>
						<Box align="center" gap="small">
							<Box align="center" justify="center">
								<Sell color="primary" fontSize="large" />
							</Box>
							<Heading margin="none" level={3} color="black">
								It's a sale!
							</Heading>
							<Typography sx={{ maxWidth: "400px" }} textAlign="center">
								Once your item sells, we'll be in touch to schedule your white glove pickup service.
							</Typography>
						</Box>
						<Box align="center" gap="small">
							<Box align="center" justify="center">
								<LocalShipping color="primary" fontSize="large" />
							</Box>
							<Heading margin="none" level={3} color="black">
								Prepare for pickup
							</Heading>
							<Typography sx={{ maxWidth: "400px" }} textAlign="center">
								We'll let you know when the pickup team is on their way. They'll do a quick inspection and take your item away.
							</Typography>
						</Box>
						<Box align="center" gap="small">
							<Box align="center" justify="center">
								<Weekend color="primary" fontSize="large" />
							</Box>
							<Heading margin="none" level={3} color="black">
								Get paid!
							</Heading>
							<Typography sx={{ maxWidth: "400px" }} textAlign="center">
								They buyer pays at the time of purchase, and you're paid upon delivery. Depending on your payment method, it may take a few days.
							</Typography>
						</Box>
					</Grid>
				</Box>
			</Box>

		</Box>
	);
};