import { gql } from "@apollo/client";


export const ReferralPartner_ScheduledPickupsFragment = gql`
	fragment ReferralPartner_ScheduledPickupsFragment on ReferralPartner {
		scheduledPickups {
			id
			scheduledDate
		}
	}
`;
