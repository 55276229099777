import { VisuallyHiddenInput } from "..";
import { createRef } from "react";
import { CloudUpload } from "@mui/icons-material";
import { Box } from "grommet";
import { LoadingButton } from "@mui/lab";
import { ProductMediaCarousel } from "./ProductMediaCarousel";

export const ProductMediaUploadElement: React.FC<{
	loading: boolean;
	onFileUploaded: (file: File) => void;
	media: { name: string; contentUrl: string; file?: File; }[];
}> = ({ media, loading, onFileUploaded }) => {
	const ref = createRef<HTMLInputElement>();

	return (
		<Box gap="small">
			<Box height="small">
				<ProductMediaCarousel
					media={media} />
			</Box>
			<LoadingButton
				color="primary"
				variant="contained"
				loading={loading}
				endIcon={<CloudUpload />}
				loadingPosition="end"
				onClick={(event) => {
					event.stopPropagation();
					ref.current?.click();
				}}
			>
				Upload Image
				<VisuallyHiddenInput
					ref={ref}
					type="file"
					accept=".png, .jpg, .jpeg"
					style={{ display: "none" }}
					onClick={(event) => {
						event.stopPropagation();
					}}
					onChange={(event) => {
						if(event.target.files) {
							for(const file of event.target.files) {
								onFileUploaded(file);
							}
						}
					}} />
			</LoadingButton>
		</Box>
	);
};
