import { useMemo } from "react";
import { BasicListItem } from "../../../../../components";
import { InstanceType } from "../../../../../graphql/__generated__/graphql";
import { useOrderRequest } from "../../hooks";

export const RefererListItem: React.FC<{
	loading: boolean;
	requestId: string;
}> = ({ loading: loadingParent, requestId }) => {
	const { request, loading } = useOrderRequest(requestId);

	const label = useMemo(() => {
		switch(request?.instance?.type) {
			case InstanceType.Residence:
			case InstanceType.ManagementCompany: {
				return "Building";
			}
			case InstanceType.DonationCenter: {
				return "Donation Center";
			}
			default: {
				return "Referer";
			}
		}
	}, [ request ]);

	const value = useMemo(() => {
		if(!request) return "Unknown";
		const { referer, instance } = request;

		switch(instance?.type) {
			case InstanceType.University:
			case InstanceType.ManagementCompany: {
				if(!referer || !instance) return "Unknown";
				return `${referer.name} (${instance.name})`;
			}
			default: {
				return referer.name || "Unknown";
			}
		}
	}, [ request ]);

	return (
		<BasicListItem
			label={label}
			value={value}
			loading={loading || loadingParent}
		/>
	);
};