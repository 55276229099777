import { useEffect, useMemo } from "react";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../store";
import { push } from "redux-first-history";

export function useFeature(feature: InstanceFeature): boolean {
	const instance = useAppSelector(selectInstancePublic);

	const isEnabled = useMemo(() => {
		if(!instance) {
			return false;
		}

		return instance.features.includes(feature);
	}, [ instance, feature ]);

	return isEnabled;
}

export function useFeatureGuard(feature: InstanceFeature, fallbackUrl: string): boolean {
	const dispatch = useAppDispatch();
	const instance = useAppSelector(selectInstancePublic);

	const isEnabled = useMemo(() => {
		if(!instance) {
			return false;
		}

		return instance.features.includes(feature);
	}, [ instance, feature ]);

	useEffect(() => {
		if(instance && !instance.features.includes(feature)) {
			dispatch(push(fallbackUrl));
		}
	}, [ instance, feature, fallbackUrl, dispatch ]);

	return isEnabled;
}