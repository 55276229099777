import { CallSplit, LunchDining } from "@mui/icons-material";
import { useMemo } from "react";
import { formatNumber } from "../helpers";
import { sumLineItems, WasteReportingHook } from "../features/admin/common";
import { DashboardCard } from "../features/admin/common/components/reporting";
import { Box } from "grommet";
import { Typography } from "@mui/material";
import { useWindowDimensions } from "../hooks";

export function useLineItems(report: WasteReportingHook) {
	const lineItems = useMemo(() => {
		return report.lineItems || [];
	}, [ report ]);

	return sumLineItems(lineItems);
}

export const TotalWeightDivertedCard: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const { sizeIndex } = useWindowDimensions();
	const { totalWeightDivertedPounds } = useLineItems(report);

	const formatted = useMemo(() => {
		const value = Number(totalWeightDivertedPounds || 0);

		if(value >= 10000) {
			return `${formatNumber(value / 2000, true)}`;
		}

		return `${formatNumber(value, true)}`;
	}, [ report ]);

	const subject = useMemo(() => {
		const value = Number(totalWeightDivertedPounds || 0);

		if(value >= 10000) {
			return "ton(s)";
		}

		return "lb(s)";
	}, [ report ]);

	return (
		<DashboardCard
			icon={<CallSplit fontSize="large" />}
			title="Weight Diverted"
			value={formatted}
		>
			<Box direction="row" justify="end" align="end" flex>
				<Typography
					fontWeight="bold"
					textAlign="end"
					fontSize={sizeIndex <= 2 ? "small" : undefined}
				>
					{subject}
				</Typography>
			</Box>
		</DashboardCard>
	);
};

export const CheeseburgerEquivalent: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const { sizeIndex } = useWindowDimensions();
	const { totalWeightDivertedPounds } = useLineItems(report);
	const formatted = useMemo(() => {
		const value = Number(totalWeightDivertedPounds || 0);

		const poundsPerBurger = 0.25;

		return `${formatNumber(
			value / poundsPerBurger,
			true
		)}`;
	}, [ report ]);

	return (
		<DashboardCard
			icon={<LunchDining sx={{ color: "white" }} fontSize="large" />}
			title="Equivalent To"
			value={formatted}
			className="btn-grad"
		>
			<Box direction="row" justify="end" align="end" flex>
				<Typography
					fontWeight="bold"
					textAlign="end"
					color="white"
					fontSize={sizeIndex <= 2 ? "small" : undefined}
				>
					Cheeseburger(s)
				</Typography>
			</Box>
		</DashboardCard>
	);
};

export const PizzaEquivalent: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const { sizeIndex } = useWindowDimensions();
	const { totalWeightDivertedPounds } = useLineItems(report);
	const formatted = useMemo(() => {
		const value = Number(totalWeightDivertedPounds || 0);

		const poundsPerSlice = 0.22;

		return `${formatNumber(
			value / poundsPerSlice,
			true
		)}`;
	}, [ report ]);

	return (
		<DashboardCard
			icon={<LunchDining sx={{ color: "white" }} fontSize="large" />}
			title="Equivalent To"
			value={formatted}
			className="btn-grad"
		>
			<Box direction="row" justify="end" align="end" flex>
				<Typography
					fontWeight="bold"
					textAlign="end"
					color="white"
					fontSize={sizeIndex <= 2 ? "small" : undefined}
				>
					Slices of Pizza
				</Typography>
			</Box>
		</DashboardCard>
	);
};