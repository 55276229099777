import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "./layout";
import { Login, RegisterView } from "./views";

export const AuthRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<AuthLayout />}>
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<RegisterView />} />
			</Route>
		</Routes>
	);
};