import { Grid, Box } from "grommet";

export const ItemSelectionContainer: React.FC<{
	icon: React.ReactNode;
	children: [ React.ReactNode, React.ReactNode ];
}> = ({ icon, children }) => {
	return (
		<Grid columns={[ "auto", "flex", "auto" ]} gap="small">
			<Box align="center" justify="center">
				{icon}
			</Box>
			{children}
		</Grid>
	);
};
