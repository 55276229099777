import { usePageTitle } from "../../../hooks";
import { RegisterController } from "../controller";

export const RegisterView: React.FC = () => {
	usePageTitle("Register");
	return (
		<RegisterController

		/>
	);
};