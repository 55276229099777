import { gql } from "@apollo/client";

export const Order_ExpandedFragment = gql`
	fragment Order_ExpandedFragment on Order {
		...Order_HeaderFragment
		...Order_PickupFragment
		...Order_SourceFragment
		...Order_CancellationDetailsFragment
		address {
			...AddressFragment
		}
		products {
			...ProductFragment
		}
	}
`;