import { useQuery } from "@apollo/client";
import { FindWasteTickets } from "../../../../graphql";

export function usePendingWasteTickets() {
	const { data, loading } = useQuery(FindWasteTickets, {
		variables: {
			processed: { equals: false }
		}
	});

	return {
		loading,
		tickets: data?.FindWasteTickets || []
	};
}