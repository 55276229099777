import { gql } from "@apollo/client";

export const Product_StopConfirmationFragment = gql`
	fragment Product_StopConfirmationFragment on Product {
		id
		originStopConfirmed
		originStopConfirmedAt
		destinationStopConfirmed
		destinationStopConfirmedAt
		destinationStopDelayed
		destinationStopDelayedAt
		destinationStopDelayedUntil
	}
`;