import { DialogWithActivatorButton, Pagination } from "../../../../components";
import { Box, Heading } from "grommet";
import { Badge, IconButton, LinearProgress, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useInstanceBuilding, useActiveOrders } from "../hooks";
import { OrderListItem } from "./OrderListItem";
import { AdminSchedulePickupButton } from "./AdminSchedulePickupButton";
import { CheckCircle, Notifications } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { FindOrderRequests } from "../../../../graphql";
import React, { useMemo } from "react";
import moment from "moment";
import { OrderRequest_HeaderFragmentFragment, OrderRequest_SourceFragmentFragment, PickupLocation } from "../../../../graphql/__generated__/graphql";
import { useInstance, useWindowDimensions } from "../../../../hooks";
import { VariablesOf } from "@graphql-typed-document-node/core";

export function usePendingOrderRequests(buildingId?: string) {
	const { instance } = useInstance();

	const variables = useMemo((): VariablesOf<typeof FindOrderRequests> => ({
		cancelled: { equals: false },
		converted: { equals: false },
		referer: buildingId ? { id: { equals: buildingId } } : undefined,
		instance: !buildingId && instance ? { id: { equals: instance.id } } : undefined
	}), [ buildingId, instance ]);

	const { data, loading } = useQuery(FindOrderRequests, {
		skip: !buildingId && !instance,
		variables: variables
	});

	const requests = useMemo(() => {
		return data?.FindOrderRequests || [];
	}, [ data ]);

	return {
		loading,
		requests
	};
}

export const PendingOrderRequestsButton: React.FC<{
	buildingId?: string;
}> = ({ buildingId }) => {
	const { requests, loading } = usePendingOrderRequests(buildingId);
	if(requests.length === 0) return null;

	return (
		<DialogWithActivatorButton
			title="Pending Order Requests"
			activatorButton={(
				<Badge
					color="secondary"
					overlap="circular"
					badgeContent={requests.length}
				>
					<IconButton>
						<Notifications color="primary" />
					</IconButton>
				</Badge>
			)}
		>
			<Pagination pageSize={4}>
				{requests.map((request) => (
					<OrderRequestListItem
						key={request.id}
						request={request}
					/>
				))}
			</Pagination>
		</DialogWithActivatorButton>
	);
};

export const OrderRequestListItem: React.FC<{
	request: OrderRequest_HeaderFragmentFragment & OrderRequest_SourceFragmentFragment;
}> = ({ request }) => {
	const { size } = useWindowDimensions();

	const requestedOn = useMemo(() => {
		return `Requested on ${moment(request.createdAt).format("MMM D, YYYY")}`;
	}, [ request.createdAt ]);

	const locationString = useMemo(() => {
		switch(request.location) {
			case PickupLocation.Default: {
				return "";
			}
			case PickupLocation.LoadingDock: {
				return "Loading Dock";
			}
			case PickupLocation.Customer: {
				if(!request.unit) {
					return "Resident Unit";
				}
				if(request.room) {
					return `Unit ${request.unit} (Room ${request.room})`;
				}
			}
		}
	}, [ location ]);

	return (
		<ListItemButton divider>
			<ListItemIcon>
				<CheckCircle color="primary" />
			</ListItemIcon>
			<ListItemText>
				<Typography fontWeight="bold">
					{locationString}
				</Typography>
				<Typography variant="body2">
					{request.referer.name}
				</Typography>
			</ListItemText>
			{size !== "small" && (
				<ListItemSecondaryAction>
					<Typography textAlign="end">
						{moment(request.requestedDate).format("MMM D, YYYY")}
					</Typography>
					<Typography variant="body2" textAlign="end">
						{request.isBulkPickup
							? "Bulk Pickup / Cleanout"
							: `${request.productCount} item(s)`}
					</Typography>
				</ListItemSecondaryAction>
			)}
		</ListItemButton>
	);
};

export const ActiveOrdersContainer: React.FC = () => {
	const { building, loading: buildingLoading } = useInstanceBuilding();
	const { orders, loading: ordersLoading } = useActiveOrders(
		building?.id || ""
	);

	return (
		<Box flex gap="medium">
			<Box gap="small">
				<Box direction="row" justify="between" height="39px" >
					<Box justify="center">
						<Heading level="3" margin="none">
							Recent Orders
						</Heading>
					</Box>
					<Box direction="row" gap="small">
						<AdminSchedulePickupButton
							buildingId={building?.id || ""}
						/>
						<PendingOrderRequestsButton
							buildingId={building?.id || ""}
						/>
					</Box>
				</Box>
				{buildingLoading || ordersLoading && (
					<LinearProgress />
				)}
			</Box>
			<Box height="100%" style={{ display: "block" }}>
				{orders.length === 0 && (
					<Box align="center" justify="center">
						<Typography>
							nothing here yet
						</Typography>
					</Box>
				)}
				<Pagination
					pageSize={5}
				>
					{orders.map((order) => (
						<OrderListItem
							minHeight="80px"
							order={order}
							key={order.id}
							buildingPrefix={building?.unitPrefix || ""} />
					))}
				</Pagination>
			</Box>
		</Box>
	);
};
