import { gql } from "../../../__generated__";


export const FindHaulerPendingQuotes = gql(/* GraphQL */ `
	query FindHaulerPendingQuotes($haulerId: String!) {
		FindHaulerPendingQuotes(haulerId: $haulerId) {
			...Quote_HaulerFragment
		}
	}
`);
