import { useForm } from "react-hook-form";
import { FormContainer, SelectElement, TextFieldElement } from "react-hook-form-mui";
import { PricingPolicyType } from "../../graphql/__generated__/graphql";
import { Box } from "grommet";
import { usePricingPolicy } from "../../hooks";
import { keys } from "../../helpers";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { InputAdornment } from "@mui/material";
import { useSnackbar } from "notistack";

export const PricingPolicyForm: React.FC<{
	policyId?: string;
	actions?: React.ReactNode[];
	onCreated?: () => void;
}> = ({ policyId, onCreated, actions }) => {
	const snack = useSnackbar();
	const { policy, loading, create, update } = usePricingPolicy(policyId);

	const formContext = useForm({
		defaultValues: {
			name: policy?.name,
			type: policy?.type,
			margin: policy?.margin ? (policy.margin * 100).toFixed(2) : "",
			marginCustomSchedule: policy?.marginCustomSchedule ? (policy.marginCustomSchedule * 100).toFixed(2) : "",
			pricing: policy?.pricing,
			partners: policy?.partners
		}
	});

	useEffect(() => {
		if(policy) {
			formContext.reset({
				name: policy.name,
				type: policy.type,
				margin: policy.margin ? (policy.margin * 100).toFixed(2) : "",
				marginCustomSchedule: policy.marginCustomSchedule ? (policy.marginCustomSchedule * 100).toFixed(2) : "",
				pricing: policy.pricing,
				partners: policy.partners
			});
		}
	}, [ policy ]);

	function handleSubmit(): void {
		const margin = formContext.getValues().margin || 0;
		const marginFormatted = Number((Number(margin) / 100).toFixed(2));

		if(policy) {
			update({
				policyId: policy.id,
				name: formContext.getValues().name,
				type: formContext.getValues().type,
				margin: marginFormatted
			})?.then(() => {
				snack.enqueueSnackbar("Pricing policy updated", {
					variant: "success"
				});
			});
		}
		else {
			create({
				name: formContext.getValues().name || "",
				type: formContext.getValues().type || PricingPolicyType.Default,
				margin: marginFormatted,
				partners: [],
				pricing: []
			}).then(() => {
				onCreated?.();
				snack.enqueueSnackbar("Pricing policy created", {
					variant: "success"
				});
			});
		}
	}

	return (
		<FormContainer formContext={formContext}>
			<Box gap="medium" flex>
				<TextFieldElement
					label="Name"
					name="name"
					required
					disabled
				/>
				<SelectElement
					name="type"
					options={keys(PricingPolicyType).map(k => ({
						id: PricingPolicyType[ k ],
						label: k
					}))}
					required
				/>
				<TextFieldElement
					label="Margin"
					name="margin"
					helperText="Markup applied to quotes (when quoting enabled)"
					type="number"
					inputMode="decimal"
					required
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								%
							</InputAdornment>
						)
					}}
				/>
				<TextFieldElement
					label="Margin (Custom Scheduling)"
					name="marginCustomSchedule"
					helperText="Additional margin applied to custom scheduled pickup dates (when upfront scheduling enabled)"
					type="number"
					inputMode="decimal"
					required
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								%
							</InputAdornment>
						)
					}}
				/>
				<Box align="end" justify="end" gap="small" flex direction="row">
					{actions && actions.map(action => action)}
					<LoadingButton
						loading={loading}
						color="primary"
						variant="contained"
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Save
					</LoadingButton>
				</Box>
			</Box>
		</FormContainer>
	);
};