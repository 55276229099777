import React from "react";
import { useQuery } from "@apollo/client";
import { GetReportingActionItems } from "../../../../graphql";
import { useInstance } from "../../../../hooks";
import { useMemo } from "react";
import { Task } from "../../../task";
import { AddPhotoAlternate, CalendarMonth, Receipt } from "@mui/icons-material";

export function useActionItems() {
	const { instance } = useInstance();
	const { data, loading } = useQuery(GetReportingActionItems, {
		skip: !instance,
		variables: { instanceId: instance?.id || "" }
	});

	const tasks = useMemo((): Task[] => {
		const tasks: Task[] = [];
		const onboardingTasks = data?.GetOnboardingTasks;
		const pendingInspection = data?.FindWasteContainerInspection || [];

		if(!onboardingTasks?.hasCompletedOnboardingCall) {
			tasks.push({
				id: "onboarding-call",
				title: "Schedule Call",
				label: "Schedule an onboarding call with our team to make sure you're set up for success.",
				icon: (
					<CalendarMonth />
				),
				actionLabel: "Schedule Call",
				actionUrl: "https://calendly.com/josh-rego/30-minute-call",
				priority: 1
			});
		}

		if(!onboardingTasks?.hasUploadedWasteTicket) {
			tasks.push({
				id: "upload-waste-ticket",
				title: "Upload Document(s)",
				label: "Upload waste documents / receipts to get started tracking your waste.",
				icon: (
					<Receipt />
				),
				actionLabel: "Upload Now",
				actionUrl: "/admin/waste-reporting/upload",
				priority: 2
			});
		}

		if(pendingInspection.length > 0) {
			tasks.push({
				id: "pending-inspections",
				title: "Upload Container Images",
				label: "Upload images of your waste containers before collection.",
				icon: (
					<AddPhotoAlternate />
				),
				actionLabel: "Upload Image(s)",
				actionUrl: "/admin/container-inspections",
				priority: 3
			});
		}

		return tasks;
	}, [ data ]);

	return {
		tasks,
		loading
	};
}