import { Box } from "grommet";
import { Chip, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { formatCurrency } from "../../../../helpers";
import { Fragment } from "react";

interface HaulerPendingQuoteListItemProps {
	quoteId: string;
	marketCode: string;
	productCount: number;
	amount: number;
	customerNameFormatted: string;
	onClick?: () => void;
}

export const HaulerPendingQuoteListItem: React.FC<HaulerPendingQuoteListItemProps> = (props) => {
	return (
		<Fragment>
			<ListItemButton
				divider
				onClick={props.onClick}
			>
				<ListItemText>
					<Box gap="xsmall">
						<Typography fontSize="18px" fontWeight="bold">{props.customerNameFormatted}</Typography>
						<Box direction="row" gap="small">
							<Typography variant="caption">2 - 3 stop(s)</Typography>
							<Typography variant="caption">|</Typography>
							<Typography variant="caption">{props.productCount} item(s)</Typography>
						</Box>
					</Box>
				</ListItemText>
				<ListItemSecondaryAction>
					<Box direction="row" gap="small">
						<Box align="center" justify="center">
							<Chip
								color="secondary"
								label={<Typography fontWeight="bold" variant="caption">{props.marketCode}</Typography>} />
						</Box>
						<Box align="end" justify="center" width="80px">
							<Typography fontSize="large" fontWeight="bold">
								{formatCurrency(props.amount)}
							</Typography>
						</Box>
					</Box>
				</ListItemSecondaryAction>
			</ListItemButton>
		</Fragment>
	);
};
