import { List, ListSubheader } from "@mui/material";
import { useClassification } from "../../../hooks";
import React, { useMemo } from "react";
import { Box } from "grommet";
import { PricingPolicyTypeListItem } from "./PricingPolicyTypeListItem";


export const PricingPolicyCategoryListItem: React.FC<{
	policyId: string;
	categoryId: string;
	categoryName: string;
	searchFilter?: string;
}> = ({ categoryId, categoryName, searchFilter, policyId }) => {
	const { types } = useClassification();

	const typesOfCategory = useMemo(() => {
		return types.filter(t => t.category?.id === categoryId);
	}, [ types ]);

	const filteredTypes = useMemo(() => {
		if(!searchFilter) return typesOfCategory;
		return Array.from(typesOfCategory).filter(type => {
			return type.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
				type.name?.toLowerCase().includes(searchFilter.toLowerCase())
				|| categoryName.toLowerCase().includes(searchFilter.toLowerCase());
		}, [ searchFilter ]);
	}, [ typesOfCategory ]);

	if(filteredTypes.length === 0) {
		return <></>;
	}

	return (
		<Box>
			<ListSubheader sx={{ fontSize: "18px" }} key={categoryId}>{categoryName}</ListSubheader>
			<List>
				{filteredTypes.map(type => (
					<PricingPolicyTypeListItem
						key={type.id}
						typeId={type.id}
						policyId={policyId}
						categoryId={categoryId}
						categoryName={categoryName}
					/>
				))}
			</List>
		</Box>
	);
};
