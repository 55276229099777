import { InputAdornment } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";


export const UnitElement: React.FC<{
	unitPrefix: string;
}> = ({ unitPrefix }) => {
	return (
		<TextFieldElement
			name="unit"
			label="Unit"
			helperText="Just the number / letter part"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						{unitPrefix}
					</InputAdornment>
				)
			}}
			validation={{
				required: "Please enter your unit / apartment number."
			}} />
	);
};
