import { useSearchParams } from "react-router-dom";
import { IUtm } from "../graphql/__generated__/graphql";
import { useMemo } from "react";

export function useUTM(): IUtm {
	const [ params ] = useSearchParams();

	return useMemo(() => {
		return {
			source: params.get("utm_source") || "",
			medium: params.get("utm_medium") || "",
			campaign: params.get("utm_campaign") || "",
			content: params.get("utm_content") || "",
			term: params.get("utm_term") || ""
		};
	}, [ params ]);
}