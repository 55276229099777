import { gql } from "../../../__generated__";

export const UpsertWasteCalculatorSubmission = gql(/* GraphQL */ `
	mutation UpsertWasteCalculatorSubmission(
		$instanceId: String!
		$submission: WasteCalculatorSubmissionDTO!
	) {
		UpsertWasteCalculatorSubmission(instanceId: $instanceId, dto: $submission) {
			id
		}
	}
`);