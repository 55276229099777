import { SvgIcon, SvgIconProps } from "@mui/material";

	export const AirConditioner: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1155.5 234.38c0-17.406-6.9141-34.098-19.223-46.402-12.305-12.309-28.996-19.223-46.402-19.223h-979.69c-17.406 0-34.098 6.9141-46.402 19.223-12.309 12.305-19.223 28.996-19.223 46.402v325.78c0 17.406 6.9141 34.098 19.223 46.402 12.305 12.309 28.996 19.223 46.402 19.223h979.69c17.406 0 34.098-6.9141 46.402-19.223 12.309-12.305 19.223-28.996 19.223-46.402zm-1045.6-28.125h980.36c7.375-0.074219 14.477 2.8164 19.699 8.0234 5.2266 5.207 8.1406 12.297 8.0898 19.676v141.05h-1035.9v-141.05c-0.050781-7.3789 2.8633-14.469 8.0898-19.676 5.2227-5.207 12.324-8.0977 19.699-8.0234zm858.15 382.03h-738.28v-67.969c0-15.508 13.789-28.125 29.297-28.125h682.03c15.508 0 26.953 12.617 26.953 28.125zm122.21 0h-84.711v-67.969c0-36.184-28.27-65.625-64.453-65.625h-682.03c-36.184 0-66.797 29.441-66.797 65.625v67.969h-82.367c-15.508 0-27.789-13.715-27.789-29.223v-146.56h1035.9v146.56c0 15.508-12.281 29.223-27.789 29.223z"/>
  <path d="m201.56 318.75c4.9727 0 9.7422-1.9766 13.258-5.4922 3.5156-3.5156 5.4922-8.2852 5.4922-13.258v-18.75c0-6.6992-3.5742-12.887-9.375-16.238-5.8008-3.3477-12.949-3.3477-18.75 0-5.8008 3.3516-9.375 9.5391-9.375 16.238v18.75c0 4.9727 1.9766 9.7422 5.4922 13.258 3.5156 3.5156 8.2852 5.4922 13.258 5.4922z"/>
  <path d="m295.31 318.75c4.9727 0 9.7422-1.9766 13.258-5.4922 3.5156-3.5156 5.4922-8.2852 5.4922-13.258v-18.75c0-6.6992-3.5742-12.887-9.375-16.238-5.8008-3.3477-12.949-3.3477-18.75 0-5.8008 3.3516-9.375 9.5391-9.375 16.238v18.75c0 4.9727 1.9766 9.7422 5.4922 13.258 3.5156 3.5156 8.2852 5.4922 13.258 5.4922z"/>
  <path d="m389.06 318.75c4.9727 0 9.7422-1.9766 13.258-5.4922 3.5156-3.5156 5.4922-8.2852 5.4922-13.258v-18.75c0-6.6992-3.5742-12.887-9.375-16.238-5.8008-3.3477-12.949-3.3477-18.75 0-5.8008 3.3516-9.375 9.5391-9.375 16.238v18.75c0 4.9727 1.9766 9.7422 5.4922 13.258 3.5156 3.5156 8.2852 5.4922 13.258 5.4922z"/>
  <path d="m268.78 939.67c0 24.16 8.8555 36.98 15.969 47.281 5.625 8.1289 9.3242 13.504 9.3242 25.973h0.003907c0 6.6992 3.5742 12.891 9.375 16.238 5.8008 3.3516 12.949 3.3516 18.75 0 5.8008-3.3477 9.375-9.5391 9.375-16.238 0-24.16-8.8555-36.98-15.969-47.281-5.625-8.1289-9.3242-13.504-9.3242-25.973s3.7109-17.844 9.3281-25.977c7.1133-10.301 15.969-23.121 15.969-47.277s-8.8555-36.977-15.969-47.277c-5.625-8.1289-9.3242-13.504-9.3242-25.965 0-12.465 3.7109-17.836 9.3242-25.969 7.1172-10.301 15.969-23.121 15.969-47.277l-0.003906-0.003907c0-6.6953-3.5742-12.887-9.375-16.238-5.8008-3.3477-12.949-3.3477-18.75 0-5.8008 3.3516-9.375 9.543-9.375 16.238 0 12.465-3.7109 17.836-9.3242 25.969-7.1172 10.301-15.969 23.121-15.969 47.277s8.8555 36.977 15.969 47.277c5.625 8.1289 9.3242 13.504 9.3242 25.965 0 12.465-3.7109 17.84-9.3242 25.969-7.1172 10.309-15.973 23.129-15.973 47.289z"/>
  <path d="m418.69 939.67c0 24.16 8.8555 36.98 15.969 47.281 5.625 8.1289 9.3242 13.504 9.3242 25.973 0 6.6992 3.5742 12.891 9.375 16.238 5.8047 3.3516 12.949 3.3516 18.75 0 5.8047-3.3477 9.375-9.5391 9.375-16.238 0-24.16-8.8555-36.98-15.969-47.281-5.625-8.1328-9.3242-13.504-9.3242-25.973s3.7109-17.844 9.3242-25.977c7.1133-10.301 15.969-23.121 15.969-47.277s-8.8516-36.965-15.969-47.277c-5.625-8.1289-9.3242-13.5-9.3242-25.965 0-12.465 3.7109-17.84 9.3242-25.969 7.1133-10.301 15.969-23.121 15.969-47.277v-0.003907c0-6.6953-3.5703-12.887-9.375-16.238-5.8008-3.3477-12.945-3.3477-18.75 0-5.8008 3.3516-9.375 9.543-9.375 16.238 0 12.465-3.7109 17.84-9.3242 25.969-7.1133 10.301-15.969 23.121-15.969 47.277s8.8555 36.977 15.969 47.277c5.625 8.1289 9.3242 13.5 9.3242 25.965 0 12.465-3.7109 17.84-9.3242 25.969-7.1133 10.309-15.969 23.129-15.969 47.289z"/>
  <path d="m568.59 939.67c0 24.16 8.8633 36.984 15.98 47.289 5.625 8.1289 9.332 13.5 9.332 25.965 0 6.6992 3.5742 12.891 9.375 16.238 5.8008 3.3516 12.949 3.3516 18.75 0 5.8008-3.3477 9.375-9.5391 9.375-16.238 0-24.16-8.8633-36.984-15.98-47.289-5.625-8.1289-9.332-13.504-9.332-25.965 0-12.465 3.7109-17.84 9.332-25.969 7.1172-10.312 15.98-23.125 15.98-47.285 0-24.16-8.8555-36.977-15.969-47.277-5.625-8.1289-9.3242-13.5-9.3242-25.965 0-12.465 3.7109-17.84 9.3242-25.969 7.1133-10.301 15.969-23.121 15.969-47.277v-0.003907c0-6.6953-3.5742-12.887-9.375-16.238-5.8008-3.3477-12.949-3.3477-18.75 0-5.8008 3.3516-9.375 9.543-9.375 16.238 0 12.465-3.7109 17.84-9.3242 25.969-7.1133 10.301-15.969 23.121-15.969 47.277s8.8555 36.977 15.969 47.277c5.625 8.1289 9.3242 13.5 9.3242 25.965 0 12.465-3.7109 17.832-9.332 25.965-7.1172 10.309-15.98 23.133-15.98 47.293z"/>
  <path d="m718.52 939.67c0 24.16 8.8555 36.98 15.969 47.281 5.625 8.1289 9.3242 13.504 9.3242 25.973 0 6.6992 3.5742 12.891 9.375 16.238 5.8008 3.3516 12.949 3.3516 18.75 0 5.8008-3.3477 9.375-9.5391 9.375-16.238 0-24.16-8.8555-36.98-15.969-47.281-5.625-8.1328-9.3242-13.504-9.3242-25.973s3.7109-17.844 9.3242-25.977c7.1172-10.301 15.969-23.121 15.969-47.277s-8.8555-36.965-15.969-47.277c-5.625-8.1289-9.3242-13.5-9.3242-25.965 0-12.465 3.7109-17.84 9.3242-25.969 7.1133-10.301 15.969-23.121 15.969-47.277v-0.003907c0-6.6953-3.5742-12.887-9.375-16.238-5.8008-3.3477-12.949-3.3477-18.75 0-5.8008 3.3516-9.375 9.543-9.375 16.238 0 12.465-3.7109 17.84-9.3242 25.969-7.1133 10.301-15.969 23.121-15.969 47.277s8.8555 36.977 15.969 47.277c5.6133 8.1289 9.3242 13.5 9.3242 25.965 0 12.465-3.7109 17.84-9.3242 25.969-7.1172 10.309-15.969 23.129-15.969 47.289z"/>
  <path d="m868.43 939.67c0 24.16 8.8555 36.98 15.969 47.281 5.625 8.1289 9.3242 13.504 9.3242 25.973h0.003906c0 6.6992 3.5703 12.891 9.375 16.238 5.8008 3.3516 12.945 3.3516 18.75 0 5.8008-3.3477 9.375-9.5391 9.375-16.238 0-24.16-8.8555-36.98-15.969-47.281-5.625-8.1289-9.3242-13.504-9.3242-25.973s3.7109-17.844 9.3281-25.977c7.1133-10.301 15.969-23.121 15.969-47.277s-8.8555-36.977-15.969-47.277c-5.625-8.1289-9.3242-13.504-9.3242-25.965 0-12.465 3.7109-17.836 9.3242-25.969 7.1172-10.301 15.969-23.121 15.969-47.277l-0.003906-0.003907c0-6.6953-3.5742-12.887-9.375-16.238-5.8047-3.3477-12.949-3.3477-18.75 0-5.8047 3.3516-9.375 9.543-9.375 16.238 0 12.465-3.7109 17.836-9.3242 25.969-7.1172 10.301-15.969 23.121-15.969 47.277s8.8555 36.977 15.969 47.277c5.625 8.1289 9.3242 13.504 9.3242 25.965 0 12.465-3.7109 17.84-9.3242 25.969-7.1172 10.309-15.973 23.129-15.973 47.289z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};