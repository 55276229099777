import React from "react";
import { DashboardListItem } from "../../../components";
import { useDashboardTasks } from "../hooks";

export const DashboardListItemWithTasks: React.FC = () => {
	const dashboardTasks = useDashboardTasks();

	return (
		<DashboardListItem
			key="dashboard"
			absolutePath="/dashboard"
			notificationCount={dashboardTasks.length}
		/>
	);
};