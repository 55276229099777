import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ChestOfDrawers: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m678 510h-156c-8.5742 0-16.496 4.5742-20.785 12-4.2852 7.4258-4.2852 16.574 0 24 4.2891 7.4258 12.211 12 20.785 12h156c8.5742 0 16.496-4.5742 20.785-12 4.2852-7.4258 4.2852-16.574 0-24-4.2891-7.4258-12.211-12-20.785-12z" />
		<path
			d="m1044 186h-888c-6.3633 0-12.469 2.5273-16.969 7.0312-4.5039 4.5-7.0312 10.605-7.0312 16.969v648c0 6.3633 2.5273 12.469 7.0312 16.969 4.5 4.5039 10.605 7.0312 16.969 7.0312h54v108c0 6.3633 2.5273 12.469 7.0312 16.969 4.5 4.5039 10.605 7.0312 16.969 7.0312h114c5.1133 0.003906 10.09-1.6211 14.215-4.6445 4.1211-3.0195 7.1719-7.2812 8.707-12.156l36-115.2h385.08l36 115.2c1.5859 5.0586 4.7969 9.4492 9.1406 12.488 4.3398 3.0391 9.5664 4.5547 14.859 4.3125h114c6.3633 0 12.469-2.5273 16.969-7.0312 4.5039-4.5 7.0312-10.605 7.0312-16.969v-108h54c6.3633 0 12.469-2.5273 16.969-7.0312 4.5039-4.5 7.0312-10.605 7.0312-16.969v-648c0-6.3633-2.5273-12.469-7.0312-16.969-4.5-4.5039-10.605-7.0312-16.969-7.0312zm-864 264h840v168h-840zm0-216h840v168h-840zm150.84 732h-72.84v-84h99.238zm611.16 0h-72.84l-26.398-84h99.238zm78-132h-840v-168h840z" />
		<path
			d="m678 294h-156c-8.5742 0-16.496 4.5742-20.785 12-4.2852 7.4258-4.2852 16.574 0 24 4.2891 7.4258 12.211 12 20.785 12h156c8.5742 0 16.496-4.5742 20.785-12 4.2852-7.4258 4.2852-16.574 0-24-4.2891-7.4258-12.211-12-20.785-12z" />
		<path
			d="m522 774h156c8.5742 0 16.496-4.5742 20.785-12 4.2852-7.4258 4.2852-16.574 0-24-4.2891-7.4258-12.211-12-20.785-12h-156c-8.5742 0-16.496 4.5742-20.785 12-4.2852 7.4258-4.2852 16.574 0 24 4.2891 7.4258 12.211 12 20.785 12z" />
	</g>
</svg>
			</SvgIcon>
		);
	};