import { Box, Heading } from "grommet";
import { ViewContainer } from "../../../../components";
import { AdminSchedulePickupButton, PendingOrderRequestsButton } from "../../residence";
import { PickupLocation } from "../../../../graphql/__generated__/graphql";
import { useWindowDimensions } from "../../../../hooks";

export const DashboardView: React.FC = () => {
	const { size } = useWindowDimensions();

	return (
		<ViewContainer>
			<Box gap="small">
				<Box
					gap="small"
					justify="between"
					height={size === "small" ? undefined : "39px"}
					direction={size === "small" ? "column" : "row"}
				>
					<Box justify="center">
						<Heading level="3" margin={size === "small" ? "small" : "none"} textAlign={size === "small" ? "center" : undefined}>
							Management Dashboard
						</Heading>
					</Box>
					<Box
						flex={size === "small"}
						gap="small"
						direction="row"
					>
						<Box flex justify="center">
							<AdminSchedulePickupButton
								location={PickupLocation.Customer}
							/>
						</Box>
						<PendingOrderRequestsButton

						/>
					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};