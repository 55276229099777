import { Box } from "grommet";
import { ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useInstance } from "../../../../hooks";

export const SelectRefererListItem: React.FC<{
	refererId: string;
	onClick: () => void;
}> = ({ refererId, onClick }) => {
	const { instance } = useInstance();
	const partners = useMemo(() => {
		return (instance?.partners ?? []);
	}, [ instance ]);

	const referer = useMemo(() => partners.find(partner => partner.id === refererId), [ refererId, partners ]);

	const listItemText = useMemo(() => {
		switch(referer?.__typename) {
			case "Building":
			case "ReuseOutlet": {
				return (
					<ListItemText
						primary={referer.name}
						secondary={referer.address ? (
							<Box>
								<Typography variant="body2" color="text.secondary">
									{referer.address.addressLineOne}
								</Typography>
								<Typography variant="body2" color="text.secondary">
									{[ referer.address.city, referer.address.state ].join(", ")} {referer.address.zipCode}
								</Typography>
							</Box>
						) : undefined} />
				);
			}
			default: {
				return (
					<ListItemText
						primary={referer?.name} />
				);
			}
		}
	}, []);

	return (
		<ListItemButton
			divider
			onClick={onClick}
		>
			{listItemText}
			<ListItemSecondaryAction>
				{/* <Checkbox checked={selected?.id === outlet.id} /> */}
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
