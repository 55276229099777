import moment, { Moment } from "moment-timezone";
import { ProviderContext } from "notistack";
import { TimePeriodFilter } from "../store/reporting";



export function getStandardFormValidations() {
	return [
		(value: unknown) => {
			if(!value) {
				return {
					status: "error",
					message: "This field is required"
				};
			}
		}
	];
}

export function getNumberFormValidations() {
	return [
		(value: unknown) => {
			if(isNaN(Number(value))) {
				return {
					status: "error",
					message: "Please enter a number"
				};
			}
		}
	];
}

export function getOptionalNumberFormValidations() {
	return [
		(value: unknown) => {
			if(!value) return;

			if(isNaN(Number(value))) {
				return {
					status: "error",
					message: "Please enter a number"
				};
			}
		}
	];
}

export function copyToClipboard(value: string): Promise<void> {
	return navigator.clipboard.writeText(value);
}

export function handleCopyToClipboard(value: string, snack: ProviderContext): void {
	copyToClipboard(value)
		.then(() => {
			snack.enqueueSnackbar(`Copied ${value} to clipboard`, {
				variant: "success"
			});
		});
}

export function parseTimestampFromUTC(date: Date | string | null, timezone: string): string {
	if(!date) {
		return "";
	}
	return moment.tz(date, "UTC").tz(timezone).format("MM/DD/YYYY hh:mm A");
}

export function parseDateFromUTC(date: Date | string | null, timezone: string): string {
	if(!date) {
		return "";
	}
	return moment.tz(date, "UTC").tz(timezone).format("MM/DD/YYYY");
}

export function prettyPrintHour(time: string): string {
	return moment().set("hours", Number(time.split(":")[ 0 ])).set("minutes", Number(time.split(":")[ 1 ] ?? 0)).format("hh:mm A");
}

export function formatDateShort(date: Moment): string {
	return date.format("YYYY-MM-DD");
}

export const toProperCase = (str: string): string => {
	return str.replace(
		/\w\S*/g,
		function (txt: string) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
};

//export function dayOfWeekToNumber(dayOfWeek: DayOfWeek): number {
//	switch(dayOfWeek) {
//		case DayOfWeek.SUNDAY: {
//			return 0;
//		}
//		case DayOfWeek.MONDAY: {
//			return 1;
//		}
//		case DayOfWeek.TUESDAY: {
//			return 2;
//		}
//		case DayOfWeek.WEDNESDAY: {
//			return 3;
//		}
//		case DayOfWeek.THURSDAY: {
//			return 4;
//		}
//		case DayOfWeek.FRIDAY: {
//			return 5;
//		}
//		case DayOfWeek.SATURDAY: {
//			return 6;
//		}
//	}
//}

//export function numberToDayOfWeek(number: number): DayOfWeek {
//	switch(number) {
//		case 0: {
//			return DayOfWeek.SUNDAY;
//		}
//		case 1: {
//			return DayOfWeek.MONDAY;
//		}
//		case 2: {
//			return DayOfWeek.TUESDAY;
//		}
//		case 3: {
//			return DayOfWeek.WEDNESDAY;
//		}
//		case 4: {
//			return DayOfWeek.THURSDAY;
//		}
//		case 5: {
//			return DayOfWeek.FRIDAY;
//		}
//		case 6: {
//			return DayOfWeek.SATURDAY;
//		}
//		default: {
//			throw new Error(`Invalid weekday number [${number}]`);
//		}
//	}
//}

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2
});

export function formatCurrency(value: number): string {
	return currencyFormatter.format(value);
}

export function formatNumberHackathon(value: number, round?: boolean): string {
	value = round ? Math.round(value) : value;
	return value.toLocaleString("en-US");
}

interface HasWindow {
	scheduledWindow?: {
		date?: string;
		from?: number;
		to?: number;
		timezone?: string;
	} | null;
}

export function sortByScheduledWindow<T extends HasWindow>(a: T, b: T): number {
	if(a.scheduledWindow?.date && b.scheduledWindow?.date) {
		return moment(new Date(a.scheduledWindow.date)).unix() - moment(new Date(b.scheduledWindow.date)).unix();
	}

	if(a.scheduledWindow?.date) {
		return -1;
	}

	if(b.scheduledWindow?.date) {
		return 1;
	}

	return 0;
}

export function formatDateFromWindow(date: string, timezone: string): string {
	return (date)
		? moment.tz(date, timezone).format("MM/DD/YYYY")
		: "";
}

export function formatTimeFromWindow(from: number, to: number): string {
	return [
		moment().hours(from).minutes(0).format("h:mm A"),
		moment().hours(to).minutes(0).format("h:mm A")
	].join(" - ");
}

export function isPartnersSite(): boolean {
	return window.location.hostname.includes("partner.liverego");
}

export function isResidentSite(): boolean {
	return window.location.hostname.includes("resident.liverego");
}

export function isPropertyManagerSite(): boolean {
	return window.location.hostname.includes("manager.liverego");
}

export function filterByTimePeriod(orders: any[], timePeriod: TimePeriodFilter | null, timePeriodCustom: [ string, string ]): any[] {
	return orders.filter((order) => {
		switch(timePeriod) {
			case "THIS_MONTH":
				return moment(order.date).isSame(moment(), "month");
			case "LAST_MONTH":
				return moment(order.date).isSame(moment().subtract(1, "month"), "month");
			case "LAST_3_MONTHS":
				return moment(order.date).isBetween(moment().subtract(3, "month"), moment());
			case "LAST_6_MONTHS":
				return moment(order.date).isBetween(moment().subtract(6, "month"), moment());
			case "YTD":
				return moment(order.date).isBetween(moment().startOf("year"), moment());
			case "LAST_YEAR":
				return moment(order.date).isBetween(moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year"));
			case "CUSTOM":
				return moment(order.date).isBetween(moment(timePeriodCustom[ 0 ]), moment(timePeriodCustom[ 1 ]));
			default:
				return true;
		}
	});
}
