import { UseFormReturn } from "react-hook-form-mui";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { FetchEstimatePreviewOnProductCreatedQueryVariables } from "../../../graphql/__generated__/graphql";
import { FetchEstimatePreviewOnProductCreated } from "../../../graphql";
import { UpsertOrderProductFormValues } from "../components";

export function useCreateProductEstimatePreview(
	orderId: string,
	formContext: UseFormReturn<UpsertOrderProductFormValues>
) {
	const formValues = formContext.watch();

	const previewVariables = useMemo((): FetchEstimatePreviewOnProductCreatedQueryVariables & { skip: boolean; } => {
		return {
			orderId,
			intent: formValues.intent,
			quantity: formValues.quantity,
			quantityDisassembly: formValues.quantityDisassembly,
			typeId: formValues.type?.id || "",
			sizeId: formValues.size?.id || "",
			skip: !formValues.type || !formValues.quantity || formValues.quantity <= 0
		};

	}, [ formValues ]);

	const { data, loading } = useQuery(FetchEstimatePreviewOnProductCreated, {
		skip: previewVariables.skip,
		variables: previewVariables
	});

	const estimate = useMemo(() => {
		return data?.FetchEstimatePreviewOnProductCreated;
	}, [ data ]);

	return {
		loading,
		estimate
	};
}
