import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useInstance } from "../../hooks";
import { FetchBuildingByResident, FetchResidentByBuilding } from "../../graphql";
import { useUser } from "./useUser";

export function useResidentBuilding() {
	const { user } = useUser();
	const { instance } = useInstance();

	const { data: buildingData, loading: buildingLoading } = useQuery(FetchBuildingByResident, {
		skip: !instance || !user,
		variables: {
			instanceId: instance?.id || ""
		}
	});

	return {
		building: buildingData?.FetchBuildingByResident ?? null,
		loading: buildingLoading
	};
}

export function useResident() {
	const { user } = useUser();
	const { instance } = useInstance();

	const { data: buildingData, loading: buildingLoading } = useQuery(FetchBuildingByResident, {
		skip: !instance || !user,
		variables: {
			instanceId: instance?.id || ""
		}
	});

	const { data: residentData, loading: residentLoading, refetch: residentRefetch } = useQuery(FetchResidentByBuilding, {
		skip: !user || !buildingData?.FetchBuildingByResident,
		variables: {
			userId: user?.id || "",
			buildingId: buildingData?.FetchBuildingByResident?.id || ""
		}
	});

	const buildingAddress = useMemo(() => {
		if(!buildingData) {
			return "";
		}

		return buildingData.FetchBuildingByResident?.address;
	}, [ buildingData ]);

	function findMatchingAddress(addresses: { addressLineOne: string; addressLineTwo?: string | null; }[], buildingAddress: string) {
		return addresses.find(address => address.addressLineOne.toUpperCase() === buildingAddress.toUpperCase());
	}

	const isResident = useMemo(() => {
		if(!user || !buildingAddress) {
			return false;
		}

		const address = findMatchingAddress(user.addresses, buildingAddress.addressLineOne);
		return !!address;
	}, [ buildingAddress, user ]);

	const unit = useMemo(() => {
		if(!user || !buildingAddress) {
			return "";
		}

		const address = findMatchingAddress(user.addresses, buildingAddress.addressLineOne);
		return address?.addressLineTwo ?? "";
	}, [ buildingAddress, user ]);

	return {
		unit,
		user,
		loading: buildingLoading || residentLoading,
		isResident,
		resident: residentData?.FetchResidentByBuilding ?? null,
		building: buildingData?.FetchBuildingByResident ?? null,
	};
}
