import { ProductIntent } from "../../graphql/__generated__/graphql";
import { CheckoutProduct } from "../../store/checkout";
import { ProductLike } from "./types";

export function generateTempId(typeId: string, sizeId?: string): string {
	return [
		"temp",
		typeId,
		sizeId || "",
		Date.now()
	].filter(Boolean).join("|");
}

export function extractTypeIdFromTempId(id: string): string {
	return id.split("|")[ 1 ];
}

export function extractSizeIdFromTempId(id: string): string {
	return id.split("|")[ 2 ] || "";
}

export function isTempId(id: string): boolean {
	return id.startsWith("temp|");
}

export function generateTempProduct(
	type: ProductLike[ "type" ],
	size?: ProductLike[ "size" ],
	intent = ProductIntent.Donation,
	quantity = 1,
	quantityDisassembly = 0
): CheckoutProduct {
	return {
		id: generateTempId(type?.id || "", size?.id),
		intent,
		type,
		size,
		quantity,
		quantityDisassembly,
		media: [],
		reviewed: false
	};
}