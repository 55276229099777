import { useQuery } from "@apollo/client";
import React from "react";
import { FindHaulerQuoteRequests } from "../../../../../graphql";
import { EligibleHaulerListItem } from "./EligibleHaulerListItem";

export const EligibleHaulerQuoteRequestListItem: React.FC<{
	haulerId: string;
	haulerName: string;
	selected: boolean;
	orderId: string;
	onToggle(): void;
}> = ({ selected, haulerId, haulerName, orderId, onToggle }) => {
	const { data, loading } = useQuery(FindHaulerQuoteRequests, {
		variables: {
			orderId,
			haulerId
		}
	});

	return (
		<EligibleHaulerListItem
			selected={selected}
			haulerName={haulerName}
			onToggle={onToggle}
			loading={loading}
			requests={data?.FindHaulerQuoteRequests || []} />
	);
};