import { Box, Heading } from "grommet";
import { ViewContainer } from "../../../../components";

export const PurchasesView: React.FC = () => {
	return (
		<ViewContainer>
			<Box>
				<Heading level="3" margin="none">
					Purchases
				</Heading>
			</Box>
		</ViewContainer>
	);
};