import { SvgIcon, SvgIconProps } from "@mui/material";

	export const PoolTable: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1141.1 844.36h-176.73c-9.6406 0-17.453-7.8164-17.453-17.453 0-9.6406 7.8164-17.453 17.453-17.453h159.27v-628.37h-1047.3v628.36h781.09c9.6406 0 17.453 7.8164 17.453 17.453 0 9.6406-7.8164 17.453-17.453 17.453l-798.54 0.003906c-9.6406 0-17.453-7.8164-17.453-17.453l-0.003906-663.27c0-9.6406 7.8164-17.453 17.453-17.453h1082.2c9.6406 0 17.453 7.8164 17.453 17.453v663.27c0.003906 9.6367-7.8125 17.453-17.453 17.453z"/>
  <path d="m1071.3 774.55h-148.36c-9.6406 0-17.453-7.8164-17.453-17.453 0-9.6406 7.8164-17.453 17.453-17.453h130.91l-0.003906-488.73h-907.63v488.73h672c9.6406 0 17.453 7.8164 17.453 17.453 0 9.6406-7.8164 17.453-17.453 17.453l-689.46 0.003906c-9.6406 0-17.453-7.8164-17.453-17.453v-523.64c0-9.6406 7.8164-17.453 17.453-17.453h942.55c9.6406 0 17.453 7.8164 17.453 17.453v523.64c0 9.6406-7.8164 17.457-17.453 17.457z"/>
  <path d="m163.64 356.66c-15.32 0-30.406-4.0391-43.637-11.676-8.3477-4.8203-11.207-15.496-6.3867-23.844 4.8203-8.3477 15.5-11.207 23.844-6.3867 7.9297 4.5781 16.984 7 26.184 7 28.875 0 52.363-23.488 52.363-52.363 0-9.1992-2.418-18.25-7-26.184-4.8203-8.3477-1.9609-19.023 6.3867-23.844 8.3477-4.8164 19.023-1.9609 23.844 6.3867 7.6367 13.227 11.676 28.316 11.676 43.637 0 48.121-39.152 87.273-87.273 87.273z"/>
  <path d="m224.1 775.57c-2.9648 0-5.9648-0.75391-8.7109-2.3398-8.3477-4.8203-11.207-15.496-6.3867-23.844 4.5781-7.9297 7-16.984 7-26.184 0-28.871-23.488-52.363-52.363-52.363-9.1992 0-18.254 2.418-26.184 6.9961-8.3438 4.8242-19.023 1.9609-23.844-6.3867-4.8203-8.3477-1.9648-19.023 6.3867-23.844 13.227-7.6367 28.316-11.676 43.637-11.676 48.121 0 87.273 39.152 87.273 87.273 0 15.32-4.0391 30.41-11.676 43.637-3.2344 5.5977-9.1016 8.7305-15.133 8.7305z"/>
  <path d="m1036.4 356.66c-48.121 0-87.273-39.152-87.273-87.273 0-15.324 4.0391-30.414 11.68-43.637 4.8203-8.3438 15.496-11.207 23.848-6.3828 8.3438 4.8203 11.207 15.496 6.3828 23.844-4.5781 7.9258-7 16.98-7 26.18 0 28.875 23.492 52.363 52.363 52.363 9.1992 0 18.25-2.418 26.184-7 8.3477-4.8203 19.023-1.9609 23.844 6.3867s1.9609 19.023-6.3867 23.844c-13.23 7.6406-28.32 11.676-43.641 11.676z"/>
  <path d="m975.9 775.57c-6.0312 0-11.898-3.1289-15.133-8.7266-7.6406-13.227-11.68-28.316-11.68-43.641 0-48.121 39.152-87.273 87.273-87.273 15.32 0 30.41 4.0391 43.637 11.676 8.3477 4.8203 11.211 15.496 6.3867 23.844-4.8203 8.3477-15.5 11.207-23.844 6.3867-7.9297-4.5781-16.98-6.9961-26.184-6.9961-28.871 0-52.363 23.492-52.363 52.363 0 9.1992 2.418 18.254 7 26.176 4.8203 8.3477 1.9609 19.027-6.3828 23.848-2.75 1.5859-5.75 2.3438-8.7109 2.3438z"/>
  <path d="m667.63 775.57c-1.4414 0-2.9102-0.17969-4.3711-0.55469-9.332-2.4102-14.953-11.926-12.547-21.258 1.0938-4.2461 1.6523-8.6562 1.6523-13.098 0-28.871-23.492-52.363-52.363-52.363s-52.363 23.492-52.363 52.363c0 4.4414 0.55469 8.8477 1.6523 13.102 2.4062 9.3359-3.2148 18.852-12.547 21.258-9.332 2.4102-18.852-3.2109-21.258-12.547-1.8281-7.0977-2.7539-14.434-2.7539-21.809 0-48.121 39.152-87.273 87.273-87.273s87.273 39.152 87.273 87.273c0 7.3828-0.92578 14.719-2.7539 21.812-2.0352 7.8633-9.125 13.094-16.895 13.094z"/>
  <path d="m600 339.21c-48.121 0-87.273-39.152-87.273-87.273 0-7.375 0.92578-14.715 2.7539-21.809 2.4062-9.332 11.918-14.953 21.258-12.547 9.3359 2.4062 14.953 11.922 12.547 21.258-1.0938 4.2539-1.6523 8.6602-1.6523 13.102 0 28.871 23.492 52.363 52.363 52.363s52.363-23.492 52.363-52.363c0-4.4453-0.55469-8.8477-1.6523-13.094-2.4062-9.332 3.2109-18.852 12.543-21.258 9.3359-2.4102 18.855 3.2109 21.262 12.543 1.8281 7.0898 2.7539 14.43 2.7539 21.812 0.007813 48.113-39.145 87.266-87.266 87.266z"/>
  <path d="m303.27 670.84c-28.875 0-52.363-23.492-52.363-52.363 0-28.875 23.488-52.363 52.363-52.363s52.363 23.492 52.363 52.363c0 28.871-23.488 52.363-52.363 52.363zm0-69.82c-9.625 0-17.453 7.832-17.453 17.453 0 9.625 7.832 17.453 17.453 17.453 9.625 0 17.453-7.832 17.453-17.453 0-9.6211-7.8281-17.453-17.453-17.453z"/>
  <path d="m303.27 548.66c-28.875 0-52.363-23.488-52.363-52.363s23.488-52.363 52.363-52.363 52.363 23.488 52.363 52.363-23.488 52.363-52.363 52.363zm0-69.82c-9.625 0-17.453 7.832-17.453 17.453 0 9.625 7.832 17.453 17.453 17.453 9.625 0 17.453-7.832 17.453-17.453 0-9.6211-7.8281-17.453-17.453-17.453z"/>
  <path d="m425.45 618.48c-28.875 0-52.363-23.488-52.363-52.363s23.488-52.363 52.363-52.363 52.363 23.488 52.363 52.363-23.488 52.363-52.363 52.363zm0-69.816c-9.625 0-17.453 7.832-17.453 17.453 0 9.625 7.832 17.453 17.453 17.453 9.625 0 17.453-7.832 17.453-17.453 0.003906-9.625-7.8281-17.453-17.453-17.453z"/>
  <path d="m547.64 548.66c-28.875 0-52.363-23.488-52.363-52.363s23.488-52.363 52.363-52.363 52.363 23.488 52.363 52.363-23.488 52.363-52.363 52.363zm0-69.82c-9.625 0-17.453 7.832-17.453 17.453 0 9.625 7.832 17.453 17.453 17.453 9.625 0 17.453-7.832 17.453-17.453 0-9.6211-7.8281-17.453-17.453-17.453z"/>
  <path d="m425.45 478.84c-28.875 0-52.363-23.488-52.363-52.363s23.488-52.363 52.363-52.363 52.363 23.488 52.363 52.363-23.488 52.363-52.363 52.363zm0-69.816c-9.625 0-17.453 7.832-17.453 17.453 0 9.625 7.832 17.453 17.453 17.453 9.625 0 17.453-7.832 17.453-17.453 0.003906-9.6211-7.8281-17.453-17.453-17.453z"/>
  <path d="m303.27 426.48c-28.875 0-52.363-23.488-52.363-52.363s23.488-52.363 52.363-52.363 52.363 23.488 52.363 52.363-23.488 52.363-52.363 52.363zm0-69.816c-9.625 0-17.453 7.832-17.453 17.453 0 9.625 7.832 17.453 17.453 17.453 9.625 0 17.453-7.832 17.453-17.453 0-9.625-7.8281-17.453-17.453-17.453z"/>
  <path d="m1002.1 1052.8c-6.0469 0-12.137-0.80859-18.141-2.4414-17.977-4.8906-32.867-16.547-41.934-32.82l-313.95-564.61c-13.969-24.988-5.2422-57.164 19.469-71.691 24.703-14.527 57.066-6.5039 72.129 17.891l340.62 548.88c9.8164 15.848 12.758 34.531 8.2891 52.613-4.4688 18.086-15.777 33.246-31.84 42.684h0.003906c-10.695 6.2852-22.582 9.5-34.641 9.5zm-327.72-643.95c-3.1328 0-6.2852 0.80078-9.1445 2.4805-8.4766 4.9805-11.469 16.016-6.6719 24.594l313.96 564.64c4.4453 7.9844 11.762 13.707 20.594 16.109 8.8281 2.4062 18.039 1.1719 25.93-3.4688h0.003906c7.8867-4.6367 13.441-12.082 15.641-20.969 2.1953-8.8828 0.75-18.059-4.0703-25.84l-340.63-548.89c-3.4375-5.5625-9.4688-8.6484-15.613-8.6484z"/>
  <path d="m889.28 904.55c-5.9844 0-11.812-3.0781-15.062-8.6133-4.8867-8.3086-2.1094-19.004 6.2031-23.891l82.762-48.648c8.3086-4.8906 19.008-2.1094 23.891 6.2031 4.8867 8.3086 2.1094 19.004-6.2031 23.891l-82.762 48.648c-2.7773 1.6328-5.8242 2.4102-8.8281 2.4102z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};