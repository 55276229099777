import React from "react";
import { PricingPolicyType } from "../../graphql/__generated__/graphql";
import { Chip } from "@mui/material";

export const PricingPolicyTypeChip: React.FC<{
	type: PricingPolicyType;
}> = ({ type }) => {
	return (
		<Chip
			label={type}
			color="primary" />
	);
};