import { Box } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { AccordionController } from "../../../../components";
import React from "react";
import { useUser } from "../../../../auth";
import moment from "moment-timezone";
import { InstanceFeature, PickupWindow, Quote_SchedulingFragmentFragment } from "../../../../graphql/__generated__/graphql";
import { DateCalendarAccordionDetails, DateCalendarAccordionSummary } from "./DateCalendarAccordion";
import { PickupQuoteAccordionDetails, PickupQuoteAccordionSummary } from "./PickupQuoteAccordion";
import { PaymentMethodAccordionDetails, PaymentMethodAccordionSummary } from "./PaymentMethodAccordion";
import { PickupWindowAccordionDetails, PickupWindowAccordionSummary } from "./PickupWindowAccordion";
import { useMutation } from "@apollo/client";
import { FetchOrderUnified, ScheduleOrder, useSchedulingScopedOrder } from "../../../../graphql";
import { useFeature } from "../../../instance/hooks/useFeature";
import { SchedulePickupContainer } from "./SchedulePickupContainer";
import { useToggleForceClose } from "../../../admin/components";
import { useSnackbar } from "notistack";


export const ScheduleQuoteBasedPickupController: React.FC<{
	orderId: string;
	forceOpen?: boolean;
	activatorButton: React.ReactNode;
	expanded: string;
	setExpanded(panel: string): void;
}> = ({ orderId, activatorButton, expanded, setExpanded, forceOpen }) => {
	const { user } = useUser();
	const { order, pickup } = useSchedulingScopedOrder(orderId);
	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);

	const quotes = useMemo(() => {
		return (order?.quotes || []);
	}, [ order ]);

	const [ scheduledDate, setScheduledDate ] = useState<moment.Moment | null>(null);
	useEffect(() => {
		if(pickup?.scheduledDate) {
			setScheduledDate(moment(pickup.scheduledDate, "YYYY-MM-DD"));
			return;
		}

		if(order?.preferredDate) {
			setScheduledDate(moment(order.preferredDate, "YYYY-MM-DD"));
		}
	}, [ pickup, order ]);

	const [ selectedWindow, setSelectedWindow ] = useState<PickupWindow | undefined>();
	useEffect(() => {
		if(pickup?.window) {
			setSelectedWindow(pickup.window);
			return;
		}

		if(order?.preferredWindow) {
			setSelectedWindow(order.preferredWindow);
		}
	}, [ pickup, order ]);

	const selectedQuote = useMemo(() => {
		return quotes.filter(quote => {
			return quote.pickupOptions.some(option => {
				return moment(option.date, "YYYY-MM-DD").isSame(scheduledDate, "day");
			});
		}).sort((a, b) => (a.lastEstimate?.totalAmount || 0) - (b.lastEstimate?.totalAmount || 0))[ 0 ];
	}, [ scheduledDate, quotes ]);

	const selectedEstimate = useMemo(() => {
		return selectedQuote?.lastEstimate;
	}, [ selectedQuote ]);

	const [
		schedule, { loading: isScheduling }
	] = useMutation(ScheduleOrder, {
		refetchQueries: [ FetchOrderUnified ]
	});

	const paymentMethods = useMemo(() => {
		return user?.paymentMethods || [];
	}, [ user ]);

	const paymentMethod = useMemo(() => {
		return paymentMethods.find((method) => method.id === order?.paymentMethod?.id);
	}, [ order, user ]);

	const canSubmit = useMemo(() => {
		return !!scheduledDate && !!selectedQuote && !!selectedWindow && !!paymentMethod;
	}, [ scheduledDate, selectedQuote, selectedWindow, paymentMethod ]);

	const snack = useSnackbar();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	function handleSchedule(): void {
		if(!order) return;
		if(!scheduledDate) return;
		if(!selectedQuote) return;
		if(!selectedWindow) return;
		if(!selectedEstimate) return;

		const estimateId = selectedEstimate.id;

		schedule({
			variables: {
				orderId: order.id,
				estimateId,
				selectedDate: scheduledDate.toDate(),
				selectedWindow: {
					from: selectedWindow.from,
					to: selectedWindow.to,
					label: selectedWindow.label
				}
			}
		}).then(() => {
			toggleForceClose();
			snack.enqueueSnackbar("Your pickup has been scheduled.", { variant: "success" });
		}).catch(err => {
			console.error(`failed to generate estimate for order [${orderId}]`, err);
			snack.enqueueSnackbar("We ran into an issue saving your information.", { variant: "error" });
		});
	}

	return (
		<SchedulePickupContainer
			canSubmit={canSubmit}
			forceOpen={forceOpen}
			forceClose={forceClose}
			handleSchedule={handleSchedule}
			activatorButton={activatorButton}
			isReschedule={order?.pickup !== null}
			isScheduling={isScheduling}
		>
			<Box>
				<AccordionController
					name="date"
					isExpanded={expanded === "date"}
					onChange={() => setExpanded("date")}
					summary={(
						<DateCalendarAccordionSummary
							scheduledDate={scheduledDate} />
					)}
					details={(
						<DateCalendarAccordionDetails
							orderId={order?.id || ""}
							quotes={quotes as unknown as Quote_SchedulingFragmentFragment[]}
							onDateUpdated={(date) => {
								setExpanded("window");
								setScheduledDate(date);
							}}
							scheduledDate={scheduledDate} />
					)} />
				<AccordionController
					name="window"
					isExpanded={expanded === "window"}
					onChange={() => setExpanded("window")}
					disabled={!scheduledDate}
					summary={(
						<PickupWindowAccordionSummary
							scheduledDate={scheduledDate?.format("MM/DD/YYYY")}
							selectedWindow={selectedWindow} />
					)}
					details={(
						<PickupWindowAccordionDetails
							orderId={order?.id || ""}
							selectedQuote={selectedQuote}
							selectedWindow={selectedWindow}
							selectedDate={scheduledDate?.format("YYYY-MM-DD")}
							onWindowSelected={() => {
								setExpanded("payment");
							}} />
					)} />
				{(quotes.length > 0 || isUpfrontPricingEnabled) && (
					<AccordionController
						name="payment"
						isExpanded={expanded === "payment"}
						onChange={() => setExpanded("payment")}
						summary={(
							<PaymentMethodAccordionSummary
								paymentMethod={paymentMethod} />
						)}
						details={(
							<PaymentMethodAccordionDetails
								orderId={order?.id || ""}
								paymentMethodId={paymentMethod?.id || ""}
								onPaymentMethodSelected={() => {
									if(quotes.length > 0) {
										setExpanded("quote");
									}
									else {
										setExpanded("");
									}
								}} />
						)} />
				)}
				{selectedQuote && (
					<AccordionController
						name="quote"
						isExpanded={expanded === "quote"}
						onChange={() => setExpanded("quote")}
						summary={(
							<PickupQuoteAccordionSummary
								total={selectedQuote.lastEstimate?.totalAmount || 0} />
						)}
						details={(
							<PickupQuoteAccordionDetails
								tax={selectedQuote.lastEstimate?.taxAmount || 0}
								subtotal={selectedQuote.lastEstimate?.subtotalAmount || 0}
								discounts={(selectedQuote.lastEstimate?.discounts || []).map(discount => ({
									amount: discount.amount,
									label: discount.description
								}))}
								total={selectedQuote.lastEstimate?.totalAmount || 0}
								onClickConfirm={() => {
									setExpanded("");
								}} />
						)} />
				)}
			</Box>
		</SchedulePickupContainer>
	);
};
