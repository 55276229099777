import { useEffect, useMemo } from "react";

export function loadFromStorage<T extends object>(
	key: string,
	defaultValue: T,
): T {
	const item = localStorage.getItem(key);
	try {
		return (item)
			? JSON.parse(item) as T
			: defaultValue;
	}
	catch {
		return defaultValue;
	}
}

export function useSyncState<T extends object>(
	key: string,
	state: T,
) {
	const stateFromStorage = useMemo(() => {
		return loadFromStorage(key, state);
	}, [ key, state ]);

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [ key, state ]);

	return {
		state: stateFromStorage,
	};
}