import { useQuery } from "@apollo/client";
import { FindInstances } from "../../../../graphql";

export function useInstanceSearch({
	name,
	domain
}: Partial<{ name: string; domain: string; }>) {
	const { data, loading, error } = useQuery(FindInstances, {
		variables: {
			name: name ? {
				contains: name
			} : undefined,
			domain: domain ? {
				contains: domain
			} : undefined
		}
	});

	return {
		instances: data?.FindInstances || [],
		loading,
		error
	};
}