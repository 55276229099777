import React from "react";
import { List } from "@mui/material";
import { useStop } from "../../../../graphql";
import { HaulerProductIncomingStopListItem } from ".";

export const StopIncomingItemsComponent: React.FC<{
	stopId: string;
	haulerId: string;
	movementId: string;
}> = ({ stopId, haulerId, movementId }) => {
	const { stop } = useStop(stopId);

	return (
		<List>
			{stop?.incoming.map((product) => (
				<HaulerProductIncomingStopListItem
					key={product.id}
					stopId={stopId}
					product={product}
					haulerId={haulerId}
					movementId={movementId} />
			))}
		</List>
	);
};
