import { Pagination } from "../../../../components";
import { Box, Heading } from "grommet";
import { LinearProgress, Typography } from "@mui/material";
import React from "react";
import { useActiveOrders } from "../../hooks";
import { useInstance } from "../../../../hooks";
import { OrderListItem } from "./OrderListItem";

export const ActiveOrdersContainer: React.FC = () => {
	const { instance } = useInstance();
	const { orders, loading: ordersLoading } = useActiveOrders(
		instance?.id || ""
	);

	return (
		<Box flex gap="medium">
			<Box gap="small">
				<Box direction="row" justify="start" height="39px" >
					<Box justify="center">
						<Heading level="3" margin="none">
							Recent Orders
						</Heading>
					</Box>
				</Box>
				{ordersLoading && (
					<LinearProgress />
				)}
			</Box>
			<Box height="100%" style={{ display: "block" }}>
				{orders.length === 0 && (
					<Box align="center" justify="center">
						<Typography>
							nothing here yet
						</Typography>
					</Box>
				)}
				<Pagination
					pageSize={5}
				>
					{orders.map((order) => (
						<OrderListItem
							minHeight="80px"
							order={order}
							key={order.id}
						/>
					))}
				</Pagination>
			</Box>
		</Box>
	);
};
