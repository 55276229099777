import React from "react";
import { BasicListItem } from "../../../../../components";
import { Pickup_AssignmentFragmentFragment } from "../../../../../graphql/__generated__/graphql";

export const OrderPickupAssignedToListItem: React.FC<{
	loading: boolean;
	pickup: Pickup_AssignmentFragmentFragment | null;
}> = ({ pickup, loading }) => {
	return (
		<BasicListItem
			label="Assigned Hauler"
			loading={loading}
			value={pickup?.assignedTo?.name ?? "Not Assigned"}
		/>
	);
};