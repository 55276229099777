import { gql } from "../../../__generated__";

export const UpdateUserRole = gql(/* GraphQL */ `
	mutation UpdateUserRole(
		$userId: String!
		$partnerId: String!
		$role: PartnerRole!
	) {
		UpdateUserRole(
			userId: $userId
			partnerId: $partnerId
			role: $role
		) {
			id
		}
	}
`);