import { gql } from "../../../__generated__";


export const FetchUserHauler = gql(/* GraphQL */ `
	query FetchUserHauler {
		FetchUserHauler {
			...Hauler_UsersFragment
			...Hauler_HeaderFragment
			...Hauler_RequestsFragment
		}
	}
`);
