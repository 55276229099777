import React, { createRef, useEffect, useMemo } from "react";
import { Box, Image, Spinner } from "grommet";
import { UseFormReturn } from "react-hook-form";
import { OnboardingFormContext } from "../types";
import { CustomizationsPreviewContainer, HexColorPickerElement, OnboardingViewContainer, STEPS, useOnboardingSteps } from "../components";
import { Button, Typography, useTheme } from "@mui/material";
import { formatSubdomain } from "../helpers";
import { useWindowDimensions } from "../../../hooks";
import { VisuallyHiddenInput } from "../../../components";
import { useOnboardingMedia } from "../hooks";
import { fileToBase64 } from "../../../helpers";
import { useAppSelector } from "../../../store";
import { selectOnboardingState } from "../../../store/onboarding";
import { ProductMediaContext } from "../../../graphql/__generated__/graphql";
import moment from "moment";
import { DomainNameElement } from "../components/elements/DomainNameElement";

export const LogoUploadContainer: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const theme = useTheme();
	const { size } = useWindowDimensions();
	const inputRef = createRef<HTMLInputElement>();
	const { requestId } = useAppSelector(selectOnboardingState);

	const [ isUploadingAny, setIsUploadingAny ] = React.useState(false);
	const { isUploading, loading, upload, media } = useOnboardingMedia();

	function handleUploadClick() {
		inputRef.current?.click();
	}

	function handleUploadMedia(file: File) {
		setIsUploadingAny(true);
		fileToBase64(file).then((base64) => {
			return upload({
				content: base64,
				contentType: file.type,
				name: file.name,
				context: ProductMediaContext.Default,
				referenceId: requestId
			});
		}).finally(() => {
			setIsUploadingAny(false);
		});
	}

	const selectedMedia = useMemo(() => {
		const values = Array.from(media).sort((a, b) => moment(b.createdAt).diff(a.createdAt));
		return values[ 0 ];
	}, [ media ]);

	useEffect(() => {
		if(selectedMedia) {
			formContext.setValue("logoMediaId", selectedMedia.id);
		}
	}, [ formContext, selectedMedia ]);

	return (
		<Box gap="small">
			<Box direction="row" justify="between">
				<Box justify="center">
					<Typography fontWeight="bold" color={theme.palette.primary.main}>
						Logo (Optional)
					</Typography>
					<Typography variant="caption">
						Upload a logo to be displayed on your reports
					</Typography>
				</Box>
				<Box justify="center">
					<Button
						color="primary"
						variant="contained"
						onClick={handleUploadClick}
						size={size === "small" ? "small" : undefined}
					>
						Upload
					</Button>
					<VisuallyHiddenInput
						ref={inputRef}
						type="file"
						accept="image/*"
						style={{ display: "none" }}
						onClick={(event) => {
							event.stopPropagation();
						}}
						onChange={(event) => {
							if(event.target.files) {
								for(const file of event.target.files) {
									handleUploadMedia(file);
								}
							}
						}} />
				</Box>
			</Box>
			<Box border height="75px" width="250px" onClick={handleUploadClick}>
				{(isUploading || isUploadingAny || loading) && (
					<Box align="center" justify="center" height="100%">
						<Spinner />
					</Box>
				)}
				{selectedMedia && !isUploading && !isUploadingAny && !loading && (
					<Image
						fit="contain"
						src={selectedMedia.contentUrl}
						margin="small"
					/>
				)}
				{!selectedMedia && (
					<Box align="center" justify="center" height="100%">
						<Typography variant="caption">
							No logo uploaded
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export const ReportingCustomizationView: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const [ isExistingSubdomain, setIsExistingSubdomain ] = React.useState(false);
	const { businessName, subdomain } = formContext.watch();
	const { activeStepIndex } = useOnboardingSteps(formContext);

	useEffect(() => {
		if(businessName && !subdomain) {
			formContext.setValue("subdomain", formatSubdomain(businessName));
		}
	}, []);

	return (
		<OnboardingViewContainer
			title={STEPS[ activeStepIndex ].label}
			subtitle={STEPS[ activeStepIndex ].description}
			loading={false}
		>
			<Box gap="medium">
				<DomainNameElement
					readonly={false}
					formContext={formContext}
				/>
				<Box direction="row" gap="small">
					<HexColorPickerElement
						name="primaryColor"
						label="Primary Color"
						helperText="Your primary brand color"
						formContext={formContext}
					/>
					<HexColorPickerElement
						name="secondaryColor"
						label="Secondary Color"
						helperText="Your secondary color"
						formContext={formContext}
					/>
				</Box>
				<LogoUploadContainer formContext={formContext} />
			</Box>
			<CustomizationsPreviewContainer formContext={formContext} />
		</OnboardingViewContainer>
	);
};