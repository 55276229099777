import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { FormContainer, SelectElement, TextFieldElement } from "react-hook-form-mui";
import { DialogWithActivatorButton } from "../../../../../components";
import { Box } from "grommet";
import { useBuildings } from "../../hooks";

export const WasteAuditForm: React.FC<{
	title: string;
	buttonTitle: string;
	loading?: boolean;
	onSubmit: () => void;
	forceClose?: boolean;
	toggleForceClose?: () => void;
	activatorButton: React.ReactNode;
	formContext: UseFormReturn<{ name: string; partnerId: string; }>;
}> = ({ title, buttonTitle, loading, forceClose, formContext, activatorButton, toggleForceClose, onSubmit }) => {
	const { buildings } = useBuildings();

	return (
		<DialogWithActivatorButton
			activatorButton={activatorButton}
			forceClose={forceClose}
			title={title}
			onClose={toggleForceClose}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error" onClick={toggleForceClose}>
						Cancel
					</Button>
					<LoadingButton
						loading={loading}
						color="primary"
						variant="contained"
						onClick={formContext.handleSubmit(onSubmit)}
					>
						{buttonTitle}
					</LoadingButton>
				</Box>
			)}
		>
			<FormContainer
				formContext={formContext}
			>
				<Box gap="medium">
					<TextFieldElement
						fullWidth
						required
						name="name"
						label="Audit Title"
						helperText="Give your audit a name"
					/>
					<SelectElement
						required
						fullWidth
						name="partnerId"
						label="Location"
						options={buildings.map(building => ({
							id: building.id,
							label: building.name
						}))}
					/>
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};