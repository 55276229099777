import { gql } from "@apollo/client";

export const ProductListingFragment = gql`
	fragment ProductListingFragment on Product {
		brand {
			id
			name
		}
		materials {
			id
			name
		}
		condition
		listing {
			...Listing_HeaderFragment
		}
		inventory {
			quantity
		}
		seller {
			...SellerFragment
		}
	}
`;