import { FormContainer, UseFormReturn } from "react-hook-form-mui";
import { Box, Image } from "grommet";
import { Fragment, ReactNode } from "react";
import { selectInstancePublic, useAppSelector } from "../../../../store";
import { useHeaderColor } from "../../../../hooks";

export type RegistrationFormValues<T = Record<string, unknown>> = T & {
	username: string;
	code: string;
	firstName: string;
	lastName: string;
};

export const RegistrationForm: React.FC<{
	hideLogo?: boolean;
	elements: ReactNode[];
	elementRenderInterupt?: ReactNode;
	formContext: UseFormReturn<RegistrationFormValues>;
}> = ({ hideLogo, formContext, elements, elementRenderInterupt }) => {
	const instance = useAppSelector(selectInstancePublic);
	const headerColor = useHeaderColor();

	return (
		<FormContainer formContext={formContext}>
			<Box gap="medium">
				{!hideLogo && (
					<Box align="center" background={headerColor}>
						<Box align="center" justify="center" width="medium" margin={{ vertical: "small" }}>
							<Box style={{ maxHeight: "200px" }}>
								<Image
									fit="contain"
									src={instance?.customizations?.logoUrl || ""}
								/>
							</Box>
						</Box>
					</Box>
				)}
				{elementRenderInterupt && (
					<Fragment>
						{elementRenderInterupt}
					</Fragment>
				)}
				{!elementRenderInterupt && elements.map((element, index) => (
					<Box key={index}>
						{element}
					</Box>
				))}
			</Box>
		</FormContainer>
	);
};