import { gql } from "../../../__generated__";

export const FindOrderRequests = gql(/* GraphQL */ `
	query FindOrderRequests(
		$cancelled: iBooleanFilters
		$converted: iBooleanFilters
		$referer: FindReferralPartnerDTO
		$instance: FindInstanceDTO
	) {
		FindOrderRequests(
			referer: $referer
			instance: $instance
			cancelled: $cancelled
			converted: $converted
		) {
			...OrderRequest_HeaderFragment
			...OrderRequest_SourceFragment
		}
	}
`);