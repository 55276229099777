import React, { useMemo } from "react";
import { Box, Image, Header as GrommetHeader, Menu } from "grommet";
import { ThemeState } from "../store/theme";
import { selectInstancePublic, selectIsPartyMode, selectOfferPartyMode, setIsPartyMode, useAppDispatch, useAppSelector } from "../store";
import { useFeature } from "../features/instance/hooks/useFeature";
import { InstanceFeature, InstanceType } from "../graphql/__generated__/graphql";

import { useDashboardRedirect, useWindowDimensions } from "../hooks";
import { push } from "redux-first-history";
import { Button, FormControlLabel, IconButton, Switch } from "@mui/material";
import { Dashboard, Login, Menu as MenuIcon } from "@mui/icons-material";
import { useLogin } from "../auth";
import { useAvatar } from "../hooks/useAvatar";

export const Header: React.FC<{
	theme: ThemeState;
}> = ({ theme }) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	const { user } = useLogin();
	const avatar = useAvatar();

	const { redirect: dashboardRedirect } = useDashboardRedirect();

	const isPartyModeOffered = useAppSelector(selectOfferPartyMode);
	const isPartyModeFeatureEnabled = useFeature(InstanceFeature.PartyModeEnabled);

	const isPartyMode = useAppSelector(selectIsPartyMode);
	const isPartyModeEnabled = useMemo(() => {
		return isPartyModeOffered && isPartyModeFeatureEnabled;
	}, [ isPartyModeOffered, isPartyModeFeatureEnabled ]);

	const instancePublic = useAppSelector(selectInstancePublic);

	const shouldDisableHeaderNavigation = useMemo(() => {
		return instancePublic?.name === "Cox Enterprises" || instancePublic?.type === InstanceType.Onboarding;
	}, [ instancePublic ]);

	return (
		<GrommetHeader
			id="header"
			pad="small"
			gap="medium"
			background={theme.headerColor}
			direction="row"
			border={{ side: "bottom", color: "light-4" }}
		>
			<Box margin={{ left: "small" }} width="140px" style={{ minHeight: "80px", maxHeight: "80px" }} justify="center">
				<Image
					src={theme.logoUrl}
					fit="contain"
					onClick={() => {
						window.open("https://www.liverego.com", "_blank");
					}}
				/>
			</Box>
			{size === "small"
				? (
					<Box justify="end" margin="small" direction="row" gap="small">
						{isPartyModeEnabled && (
							<Switch
								color="secondary"
								checked={isPartyMode}
								onChange={() => dispatch(setIsPartyMode(!isPartyMode))}
							/>
						)}
						{!shouldDisableHeaderNavigation && (
							<Menu
								items={[
									{
										key: "dashboard",
										label: "Dashboard",
										onClick: () => {
											dashboardRedirect();
										}
									}
								]}
								dropProps={{ align: { top: "bottom", right: "right" } }}
								icon={<MenuIcon color="accent1" />}
							/>
						)}
						{user && (
							<IconButton color="accent1" onClick={() => dispatch(push("/profile"))}>
								{avatar}
							</IconButton>
						)}
						{(!user && !shouldDisableHeaderNavigation) && (
							<Button
								startIcon={<Login />}
								color={!theme.accentColor ? "primary" : "accent1"}
								variant="outlined"
								onClick={() => dispatch(push("/login"))}
							>
								Login
							</Button>
						)}
					</Box>
				)
				: (
					<Box direction="row" gap="medium" align="center" pad="small">
						{/* {links.map((link) => (
							<Link
								key={link.label}
								path={link.path}
							>
								{link.label}
							</Link>
						))} */}
						{isPartyModeEnabled && (
							<FormControlLabel
								control={(
									<Switch
										color="secondary"
										checked={isPartyMode}
										onChange={() => dispatch(setIsPartyMode(!isPartyMode))}
									/>
								)}
								label="Party Mode"
								labelPlacement="end"
							/>
						)}
						{(user && !shouldDisableHeaderNavigation) && (
							<Button
								color={!theme.accentColor ? "primary" : "accent1"}
								variant="outlined"
								startIcon={<Dashboard />}
								onClick={dashboardRedirect}
							// style={{ color: "#fff5f0" }}
							>
								Dashboard
							</Button>
						)}
						{(user) && (
							<IconButton
								color={!theme.accentColor ? "primary" : "accent1"}
								onClick={() => dispatch(push("/profile"))}
							>
								{avatar}
							</IconButton>
						)}
						{(!user && !shouldDisableHeaderNavigation) && (
							<Button
								startIcon={<Login />}
								color={!theme.accentColor ? "primary" : "accent1"}
								variant="outlined"
								onClick={() => dispatch(push("/login"))}
							>
								Login
							</Button>
						)}
					</Box>
				)
			}
		</GrommetHeader>
	);
};

export function useHeaderLinks() {
	const salesEnabled = useFeature(InstanceFeature.SalesEnabled);
	const removalEnabled = useFeature(InstanceFeature.RemovalEnabled);
	const donationEnabled = useFeature(InstanceFeature.DonationEnabled);
	const p2pSalesEnabled = useFeature(InstanceFeature.SalesP2PEnabled);

	const links = useMemo(() => {
		const results = [];

		if(salesEnabled) {
			results.push({ path: "/shop", label: "Shop" });
		}

		if(p2pSalesEnabled) {
			results.push({ path: "/sell", label: "Sell" });
		}

		if(removalEnabled) {
			results.push({ path: "/dashboard", label: "Junk Removal" });
		}

		if(donationEnabled) {
			results.push({ path: "/dashboard", label: "Donation Pickup" });
		}

		return results;
	}, [ salesEnabled, p2pSalesEnabled, removalEnabled, donationEnabled ]);

	return links;
}

export const HeaderError: React.FC<{
	theme: ThemeState;
}> = ({ theme }) => {
	return (
		<GrommetHeader
			id="header"
			pad="small"
			gap="medium"
			background={theme.headerColor}
			direction="row"
			border={{ side: "bottom", color: "light-4" }}
		>
			<Box margin={{ left: "small" }} width="140px" style={{ minHeight: "80px", maxHeight: "80px" }} justify="center">
				<Image src={theme.logoUrl} fit="contain" />
			</Box>
		</GrommetHeader>
	);
};