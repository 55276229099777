import { gql } from "../../../__generated__";

export const FindItemTypes = gql(/* GraphQL */ `
	query FindItemTypes($includeHidden: Boolean = false) {
		FindItemTypes(includeHidden: $includeHidden) {
			id
			name
			sizes {
				id
				name
				index
				iconUrl
			}
			category {
				id
				name
				index
				iconUrl
			}
			index
			iconUrl
			canDisassemble
			assumptions {
				weightLowPounds
				weightHighPounds
			}
			tags
			keywords
		}
	}
`);
