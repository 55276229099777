import { gql } from "../../../__generated__";

export const AssignProductOutlet = gql(/* GraphQL */ `
	mutation AssignProductOutlet(
		$productId: String!, 
		$outletId: String!
		$fallbackOutletId: String
	) {
		AssignProductOutlet(
			productId: $productId, 
			outletId: $outletId
			fallbackOutletId: $fallbackOutletId
		) {
			id
		}
	}
`);