import { useEffect } from "react";
import { selectInstance, selectInstancePublic, setInstance, setInstanceId, useAppDispatch, useAppSelector } from "../store";
import { useLazyQuery } from "@apollo/client";
import { FetchInstanceAuthenticated } from "../graphql";
import { InstanceType, Instance_AuthenticatedFragmentFragment } from "../graphql/__generated__/graphql";

export function useInstance() {
	const [ execute, { loading, error, data, refetch } ] = useLazyQuery(FetchInstanceAuthenticated);

	const dispatch = useAppDispatch();
	const instance = useAppSelector(selectInstance);
	const instancePublic = useAppSelector(selectInstancePublic);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	window.forceGlobalAdmin = () => {
		dispatch(setInstance({
			...instance,
			type: InstanceType.Admin
		} as Instance_AuthenticatedFragmentFragment));
	};

	useEffect(() => {
		if(!instance && instancePublic) {
			execute({ variables: { instanceId: instancePublic.id } });
		}
	}, [ instance, instancePublic ]);

	useEffect(() => {
		if(error) {
			console.error("Failed during instance fetch", error);
		}
	}, [ error ]);

	useEffect(() => {
		if(data) {
			dispatch(setInstance(data.FetchInstance));
			dispatch(setInstanceId(data.FetchInstance.id));
		}
	}, [ data ]);

	return {
		loading,
		refetch,
		instance
	};
}