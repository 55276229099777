import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ChairAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m870.71 627.47h-56.391v-143.02h19.547c24.703 0 44.859-20.156 44.859-44.859l0.046875-151.22c0-24.703-20.156-44.859-44.859-44.859l-467.76-0.046875c-24.703 0-44.859 20.156-44.859 44.859v151.08c0 24.703 20.156 44.859 44.859 44.859h19.547v143.16h-56.391c-24.703 0-44.859 20.156-44.859 44.859v36.703c0 24.703 20.156 44.859 44.859 44.859h17.156v150.94c0 28.547 23.297 51.844 51.844 51.844 28.547 0 51.844-23.297 51.844-51.844v-150.56h299.76l-0.046875 150.56c0 28.547 23.297 51.844 51.844 51.844s51.844-23.297 51.844-51.844v-150.56h17.156c24.703 0 44.859-20.156 44.859-44.859v-36.703c0.046875-25.031-20.109-45.188-44.859-45.188zm-504.56-167.02c-11.531 0-20.859-9.375-20.859-20.859l-0.046875-151.22c0-11.531 9.375-20.859 20.859-20.859h467.76c11.531 0 20.859 9.375 20.859 20.859v151.08c0 11.531-9.375 20.859-20.859 20.859h-467.72zm424.18 24v143.16h-380.63v-143.16zm-364.18 420.32c0 15.375-12.469 27.844-27.844 27.844s-27.844-12.469-27.844-27.844v-150.47h55.688zm403.4 0c0 15.375-12.469 27.844-27.844 27.844s-27.844-12.469-27.844-27.844v-150.47h55.688zm62.062-195.56c0 11.531-9.375 20.859-20.859 20.859h-541.45c-11.531 0-20.859-9.375-20.859-20.859v-36.703c0-11.531 9.375-20.859 20.859-20.859h541.4c11.531 0 20.859 9.375 20.859 20.859z"/>
  <path d="m522.84 330h154.45c6.6094 0 12-5.3906 12-12s-5.3906-12-12-12h-154.45c-6.6094 0-12 5.3906-12 12s5.2969 12 12 12z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};