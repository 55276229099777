import React from "react";
import { Box, Grid, Heading } from "grommet";
import {
	ViewActionBar,
	OrderRelationshipButton,
	OrderDetailsComponent,
	OrderProductsComponent,
	OrderPickupDetailsComponent,
	GlobalAdminOrderActionsComponent
} from "../../components";
import { useFullScreen, useWindowDimensions } from "../../../../hooks";
import { useRequiredPathParam } from "../../../../hooks";
import { Button, LinearProgress } from "@mui/material";
import { OrderLinksDialog, UserDetailsDialog } from "../../common";
import { useGlobalAdminScopedOrder } from "../../../../graphql";

export const GlobalAdminOrderDetailsView: React.FC = () => {
	const orderId = useRequiredPathParam("orderId", "/admin");
	const { order, loading } = useGlobalAdminScopedOrder(orderId);

	const fullScreen = useFullScreen();
	const { sizeIndex } = useWindowDimensions();

	return (
		<Box margin="medium" gap="small" flex>
			<Grid columns={{ count: sizeIndex < 3 ? 1 : 2, size: "auto" }} gap="medium">
				<Box>
					<Box gap="xsmall">
						<Heading level={3} margin="none">
							Order Details
						</Heading>
						{loading && (
							<LinearProgress />
						)}
					</Box>
					<OrderDetailsComponent
						orderId={order?.id || ""}
						enabledAdminActions={true}
					/>
				</Box>
				<Box>
					<Heading level={3} margin="none">
						Pickup Details
					</Heading>
					{loading && (
						<LinearProgress />
					)}
					<OrderPickupDetailsComponent
						orderId={order?.id || ""}
						permitEdit
						permitViewPickup
						includeGlobalAdmin
					/>
				</Box>
			</Grid>
			<Box>
				<Heading level={3} margin="none">
					Order Items
				</Heading>
				{loading && (
					<LinearProgress />
				)}
				<OrderProductsComponent
					permitAI
					permitEdit
					orderId={order?.id || ""}
				/>
			</Box>
			{(
				<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="medium">
					<GlobalAdminOrderActionsComponent
						orderId={order?.id || ""}
					/>
					{order && (
						<ViewActionBar title="Order Links">
							<UserDetailsDialog
								permitViewMore
								userId={order?.user?.id || ""}
								activatorButton={(
									<Button
										size="small"
										color="primary"
										variant="contained"
									>
										User
									</Button>
								)}
							/>
							<OrderLinksDialog
								orderId={order.id}
								activatorButton={(
									<Button
										size="small"
										color="primary"
										variant="contained"
									>
										Customer URLs
									</Button>
								)}
							/>
							<OrderRelationshipButton
								label="Referer"
								orderId={order.id}
								resolvePath={(relationships) => `/admin/partners/${relationships.refererId}`}
							/>
							<OrderRelationshipButton
								label="Instance"
								orderId={order.id}
								resolvePath={(relationships) => `/admin/instances/${relationships.instanceId}`}
							/>
						</ViewActionBar>
					)}
				</Grid>
			)}
		</Box>
	);
};