import { gql } from "@apollo/client";


export const Order_ProductsFragment = gql`
	fragment Order_ProductsFragment on Order {
		products {
			...ProductFragment
			totalQuantity
			inventory {
				quantity
			}
		}
	}
`;
