import { AddShoppingCart, RemoveShoppingCart } from "@mui/icons-material";
import { useMemo } from "react";
import { useCart } from "../../../cart/hooks/useCart";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../../../auth";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

interface AddToCartButtonProps {
	productId: string;
}

export const AddToCartButton: React.FC<AddToCartButtonProps> = (props) => {
	const { user } = useUser();
	const dispatch = useAppDispatch();
	const { isAdding, isInCart, isRemoving, addToCart, removeFromCart } = useCart();

	const inCart = useMemo(() => {
		return isInCart(props.productId);
	}, [ isInCart, props.productId ]);

	function handleClick(inner: any) {
		if(!user) {
			dispatch(push(`/login?redirect=${window.location.pathname}`));
		}
		return inner;
	}

	return (inCart)
		? (
			<LoadingButton
				color="primary"
				variant="contained"
				loading={isRemoving}
				endIcon={<RemoveShoppingCart />}
				onClick={() => handleClick(removeFromCart(props.productId))}
			>
				Remove from Cart
			</LoadingButton>
		)
		: (
			<LoadingButton
				color="primary"
				variant="contained"
				loading={isAdding}
				endIcon={<AddShoppingCart />}
				onClick={() => {
					handleClick(addToCart(props.productId));
				}}
			>
				Add to Cart
			</LoadingButton>
		);
};
