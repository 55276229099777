import { gql } from "@apollo/client";
import { Type_PricedFragmentFragment } from "../../../__generated__/graphql";

export const Type_PricedFragment = gql`
	fragment Type_PricedFragment on PricedItemType {
		id
		name
		category {
			id
			name
			tags
			keywords
		}
		canDisassemble
		... @defer {
			pickupFee(partnerId: $partnerId)
		}
		... @defer {
			disassemblyFee(partnerId: $partnerId)
		}
		assumptions {
			loadTimeMinutes
			unloadTimeMinutes
			weightLowPounds
			weightHighPounds
			cubicFeetLow
			cubicFeetHigh
		}
		tags
		keywords
		iconUrl
		index
	}
`;

export function isPricedItemType(value: { [ key: string ]: unknown; }): value is Type_PricedFragmentFragment {
	return typeof value === "object" && value !== null && "pickupFee" in value && "disassemblyFee" in value;
}