import { Box } from "grommet";
import { Chip, IconButton, SvgIconProps, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import React, { ReactElement } from "react";

export const SelectorButton: React.FC<{
	hidden: boolean;
	disabled: boolean;
	onClick(): void;
	icon: ReactElement;
	iconProps?: SvgIconProps;
}> = ({ hidden, disabled, onClick, icon, iconProps }) => {
	return (
		<IconButton
			onClick={onClick}
			disabled={disabled}
			sx={{ padding: 0 }}
		>
			{React.cloneElement(icon, {
				...iconProps,
				style: { visibility: hidden ? "hidden" : "visible" }
			})}
		</IconButton>
	);
};

export const CountSelectorElement: React.FC<{
	count: number;
	increment(): void;
	decrement(): void;
	hideButtons?: boolean;
	disableButtons?: boolean;
}> = ({ count, increment, decrement, disableButtons, hideButtons }) => {
	return (
		<Chip
			style={{ backgroundColor: hideButtons ? "transparent" : undefined }}
			label={(
				<Box direction="row" gap="small">
					<SelectorButton
						hidden={hideButtons || false}
						disabled={disableButtons || false}
						onClick={decrement}
						icon={<Remove />}
					/>
					<Box justify="center">
						<Typography
							fontWeight="bold"
							minWidth="25px"
							textAlign="center"
						>
							{count}
						</Typography>
					</Box>
					<SelectorButton
						hidden={hideButtons || false}
						disabled={disableButtons || false}
						onClick={increment}
						icon={<Add />}
					/>
				</Box>
			)} />
	);
};
