import { ReactElement, ReactNode, useContext, useEffect, useMemo } from "react";
import { PaginationContext } from "./PaginationProvider";
import { List, ListProps } from "@mui/material";

interface PaginationRendererProps {
	children: ReactNode[];
	pageSize?: number;
	listProps?: ListProps;
	overrideContainer?: ReactElement<{ children: ReactNode; }>;
}

export const PaginationRenderer: React.FC<PaginationRendererProps> = (props) => {
	const { page, pageSize, setPageSize } = useContext(PaginationContext);

	useEffect(() => {
		if(props.pageSize && props.pageSize !== pageSize) {
			setPageSize(props.pageSize);
		}
	}, [ props.pageSize, pageSize, setPageSize ]);

	const [ start, end ] = useMemo(() => {
		return [
			(page - 1) * pageSize,
			(page) * pageSize
		];
	}, [ page, pageSize ]);

	if(props.overrideContainer) {
		return (
			<props.overrideContainer.type {...props.overrideContainer.props}>
				{props.children.slice(start, end)}
			</props.overrideContainer.type>
		);
	}

	return (
		<List {...props.listProps}>
			{props.children.slice(start, end)}
		</List>
	);
};