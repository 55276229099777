import { LoadingButton } from "@mui/lab";
import { OrderStatus } from "../../../../../graphql/__generated__/graphql";
import { GlobalAdminPickupRequestDialog, GlobalAdminQuoteRequestDialog } from "./GlobalAdminRequestHaulerDialog";
import { ViewActionBar } from "../../common";
import { Button } from "@mui/material";
import { CancelOrderButton, CancelOrderConfirmationDialog } from "../../../../dashboard/components";
import { useMemo } from "react";
import { useGlobalAdminScopedOrder } from "../../../../../graphql";

export const GlobalAdminOrderActionsComponent: React.FC<{ orderId: string; }> = ({ orderId }) => {
	const { order } = useGlobalAdminScopedOrder(orderId);

	const skipCancellationFeeDialog = useMemo(() => {
		if(!order?.submittedAt || !order.scheduledAt) {
			return true;
		}

		return false;
	}, [ order ]);

	const isPickupAssigned = useMemo(() => {
		return !!order?.pickup?.id;
	}, [ order ]);

	const isPickupAssignedHauler = useMemo(() => {
		return !!order?.pickup?.assignedTo?.id;
	}, [ order ]);

	return (
		<ViewActionBar title="Order Actions">
			<CancelOrderButton
				orderId={order?.id || ""}
				isCancellable={order?.cancellationDetails?.isCancellable || false}
				cancellationDialog={skipCancellationFeeDialog ? CancelOrderConfirmationDialog : undefined}
			/>
			{(order && !isPickupAssigned) && (
				<GlobalAdminQuoteRequestDialog
					orderId={order.id}
					activationButton={(
						<Button
							size="small"
							color="primary"
							variant="contained"
						>
							Request Hauler
						</Button>
					)}
				/>
			)}
			{(order && isPickupAssigned && !isPickupAssignedHauler) && (
				<GlobalAdminPickupRequestDialog
					orderId={order.id}
					pickupId={order.pickup?.id || ""}
					activationButton={(
						<Button
							size="small"
							color="primary"
							variant="contained"
						>
							Request Hauler
						</Button>
					)}
				/>
			)}
			{(order?.status === OrderStatus.Submitted && order.schedulePending) && (
				<LoadingButton
					size="small"
					color="success"
					variant="contained"
					disabled={true}
					loading={false}
				>
					Schedule
				</LoadingButton>
			)}
			{(order?.scheduled && !order?.cancelledAt && !order?.completedAt) && (
				<LoadingButton
					size="small"
					color="success"
					variant="contained"
					disabled={true}
					loading={false}
				>
					Reschedule
				</LoadingButton>
			)}
		</ViewActionBar>
	);
};
