import { useQuery } from "@apollo/client";
import { useInstance } from "../../../../hooks";
import { FindWasteContainerInspection } from "../../../../graphql";
import moment from "moment";
import { FetchWasteContainerInspection } from "../../../../graphql/documents/reporting/queries/FetchWasteContainerInspection";

const cutoffDate = moment().subtract(30, "days").toDate();

export function useWasteContainerInspections() {
	const { instance } = useInstance();
	const { data, loading } = useQuery(FindWasteContainerInspection, {
		skip: !instance,
		variables: {
			scheduled: { equals: true },
			scheduledFor: { isAfter: cutoffDate },
			assignment: {
				partner: {
					instance: {
						id: { equals: instance?.id }
					}
				}
			},
			includeContainerData: true
		}
	});

	return {
		loading,
		inspections: data?.FindWasteContainerInspection || [],
	};
}

export function useWasteContainerInspection(
	inspectionId: string
) {
	const { data, loading } = useQuery(FetchWasteContainerInspection, {
		variables: {
			inspectionId
		},
		skip: !inspectionId
	});

	return {
		loading,
		inspection: data?.FetchWasteContainerInspection || null
	};
}