import { LoadingButton } from "@mui/lab";
import { useTheme, useMediaQuery, Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions } from "@mui/material";
import { Box, Grid } from "grommet";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement, AutocompleteElement, TextareaAutosizeElement, SelectElement } from "react-hook-form-mui";
import { States } from "../../helpers";
import { Close, Save } from "@mui/icons-material";
import { AddressLike, useAutocomplete } from "../../hooks";

export interface CreateAddressDTO {
	addressLineOne: string;
	addressLineTwo?: string | null | undefined;
	city: string;
	state: string;
	zipCode: string;
	floor: number;
	hasParking: boolean;
	hasElevator: boolean;
	instructions: string;
	instructionsParking: string;
	lat: string;
	lng: string;
	timezone: string;
}

export interface UpdateAddressDTO extends CreateAddressDTO {
	id: string;
}

export function isUpdateAddressDTO(dto: CreateAddressDTO | UpdateAddressDTO | null | undefined): dto is UpdateAddressDTO {
	return (dto as UpdateAddressDTO)?.id !== undefined;
}

interface AddressDialogProps {
	onClose: () => void;
	isUpdating: boolean;
	isCreating: boolean;
	address?: Partial<CreateAddressDTO | UpdateAddressDTO> | null;
	isResidenceAddress?: boolean;
	createAddress(address: CreateAddressDTO): void;
	updateAddress(address: UpdateAddressDTO): void;
}

export const AddressDialog: React.FC<AddressDialogProps> = (props) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const useFormReturn = useForm<CreateAddressDTO | UpdateAddressDTO>({
		defaultValues: {
			...props.address,
			lat: props.address?.lat ?? "",
			lng: props.address?.lng ?? "",
			floor: props.address?.floor ?? 1,
			hasParking: props.address?.hasParking ?? false,
			hasElevator: props.address?.hasElevator ?? false
		}
	});

	const formValues = useFormReturn.watch();

	function handleSave() {
		if(!isUpdateAddressDTO(formValues)) {
			props.createAddress(useFormReturn.getValues() as CreateAddressDTO);
		}
		else {
			props.updateAddress(useFormReturn.getValues() as UpdateAddressDTO);
		}
	}

	useAutocomplete(
		document.getElementById("address-element") as HTMLInputElement,
		(address: AddressLike) => {
			useFormReturn.setValue("addressLineOne", address.addressLineOne);
			useFormReturn.setValue("city", address.city);
			useFormReturn.setValue("state", address.state);
			useFormReturn.setValue("zipCode", address.zipCode);
			useFormReturn.setValue("lat", address.lat);
			useFormReturn.setValue("lng", address.lng);
			useFormReturn.setValue("timezone", address.timezone);
		}
	);

	return (
		<Dialog open fullWidth fullScreen={fullScreen}>
			<FormContainer
				formContext={useFormReturn}
				onSuccess={(data) => {
					handleSave();
				}}
			>
				<DialogTitle>
					<Box direction="row" justify="between">
						<Box align="center" justify="center">
							<Typography variant="h5">Address</Typography>
						</Box>
						<IconButton onClick={props.onClose}>
							<Close />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<Box gap="medium">
						<TextFieldElement
							required
							id="address-element"
							name="addressLineOne"
							label="Street Address"
							InputProps={{ disabled: props.isResidenceAddress }}
						/>
						<TextFieldElement
							name="addressLineTwo"
							label="Unit / Apartment #"
							InputProps={{ disabled: props.isResidenceAddress }}
						/>
						<TextFieldElement
							required
							name="city"
							label="City"
							InputProps={{ disabled: props.isResidenceAddress }}
						/>
						<Grid columns={{ count: 2, size: "auto" }} gap="small">
							<AutocompleteElement
								required
								options={States.getAbbreviations()}
								name="state"
								label="State"
								textFieldProps={{
									onChange: (event) => {
										event.target.value = event.target.value.toUpperCase();
										if(States.getAbbreviations().includes(event.target.value as any)) {
											useFormReturn.setValue("state", event.target.value);
											window.document.getElementById("zipCode")?.focus();
										}
									}
								}}
								autocompleteProps={{ disabled: props.isResidenceAddress }}
							/>
							<TextFieldElement
								required
								id="zipCode"
								name="zipCode"
								label="Zip Code"
								InputProps={{ inputMode: "numeric", type: "number", disabled: props.isResidenceAddress }}
								validation={{
									minLength: {
										message: "Please enter a valid zip code",
										value: 5
									},
									maxLength: {
										message: "Please enter a valid zip code",
										value: 5
									}
								}}
							/>
						</Grid>
						<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="small">
							<TextFieldElement
								name="floor"
								label="Floor"
								InputProps={{ inputMode: "numeric", type: "number" }}
							/>
							<SelectElement
								name="hasParking"
								label="Is there parking available?"
								options={[ { id: true, label: "Yes" }, { id: false, label: "No" } ]}
								InputProps={{ disabled: props.isResidenceAddress }}
							/>
							{(formValues.floor ?? 1) > 1 && (
								<SelectElement
									name="hasElevator"
									label="Is there elevator access?"
									options={[ { id: true, label: "Yes" }, { id: false, label: "No" } ]}
									InputProps={{ disabled: props.isResidenceAddress }}
								/>
							)}
						</Grid>
						<TextareaAutosizeElement
							name="instructions"
							label="Pickup Instructions"
							rows={2}
						/>
						<TextareaAutosizeElement
							name="instructionsParking"
							label="Parking Instructions"
							rows={2}
							InputProps={{ disabled: props.isResidenceAddress }}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Box align="end" margin="small">
						<LoadingButton
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<Save />}
							loadingPosition="end"
							loading={props.isCreating || props.isUpdating}
						>
							Save
						</LoadingButton>
					</Box>
				</DialogActions>
			</FormContainer>
		</Dialog>
	);
};