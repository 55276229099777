import { gql } from "../../../__generated__";

export const FetchWasteReport = gql(/* GraphQL */ `
	query FetchWasteReport(
		$date: iDateFilters!
		$type: FindTypeDTO
		$material: FindMaterialDTO
		$partner: FindPartnerDTO
		$instanceId: String!
	) {
		FetchWasteReport(
			date: $date
			instanceId: $instanceId
			type: $type
			material: $material
			partner: $partner
		) {
			...WasteReport_Header
			...WasteReport_LineItems
		}
	}
`);