import React, { Fragment, ReactNode, useCallback, useEffect, useMemo } from "react";
import { AccordionController, DialogWithClose, Pagination, ViewContainer, VisuallyHiddenInput } from "../../../../components";
import { useWasteContainerInspection, useWasteContainerInspections } from "../hooks";
import { Box, Heading, Image } from "grommet";
import { Alert, Button, Chip, IconButton, InputAdornment, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { AddAPhoto, AddTask, CalendarMonth, Cancel, CheckCircle, CloudUpload, HourglassTop } from "@mui/icons-material";
import moment from "moment-timezone";
import { useWindowDimensions } from "../../../../hooks";
import { fileToBase64, formatNumber } from "../../../../helpers";
import { ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { useMutation } from "@apollo/client";
import { CompleteWasteContainerInspection, FindWasteContainerInspection, GetReportingActionItems } from "../../../../graphql";
import { LoadingButton } from "@mui/lab";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { FetchWasteContainerInspection } from "../../../../graphql/documents/reporting/queries/FetchWasteContainerInspection";

export const WasteContainerInspectionsView: React.FC = () => {
	const { inspections } = useWasteContainerInspections();

	const groupedByPartner = useMemo(() => {
		return inspections.reduce((acc, inspection) => {
			const existing = acc.find((group) => group.partnerId === inspection.assignment.partner.id);
			if(existing) {
				existing.inspections.push(inspection);
				return acc;
			}

			acc.push({
				partnerId: inspection.assignment.partner.id,
				partnerName: inspection.assignment.partner.name,
				inspections: [ inspection ]
			});
			return acc;
		}, [] as { partnerId: string; partnerName: string, inspections: typeof inspections; }[]);
	}, [ inspections ]);

	const [ selectedPartner, setSelectedPartner ] = React.useState<string | null>(null);

	useEffect(() => {
		if(groupedByPartner.length === 1) {
			setSelectedPartner(groupedByPartner[ 0 ].partnerId);
		}
	}, [ groupedByPartner ]);

	const sortInspections = useCallback((inspectionsToSort: typeof inspections) => {
		const arrayCopy = Array.from(inspectionsToSort);

		return arrayCopy.sort((a, b) => {
			if(!a.inspected && !b.inspected) {
				return moment(a.scheduledFor).unix() - moment(b.scheduledFor).unix();
			}

			if(!a.inspected && b.inspected) {
				return -1;
			}

			if(a.inspected && !b.inspected) {
				return 1;
			}

			if(!a.processed && b.processed) {
				return -1;
			}

			if(a.processed && !b.processed) {
				return 1;
			}

			return moment(a.inspectedAt).unix() - moment(b.inspectedAt).unix();
		});
	}, []);

	return (
		<ViewContainer>
			<Box
				gap="medium"
				style={{
					height: "100%",
					display: "block"
				}}
			>
				<Box>
					<Heading level="3" margin="none">
						Waste Container Images
					</Heading>
					<Typography variant="caption">
						Make sure you upload your images before each container is collected (on the morning of, or night before)
					</Typography>
				</Box>
				<Box
					style={{
						height: "100%",
						display: "block"
					}}
					overflow={{ vertical: "scroll" }}
				>
					{groupedByPartner.map(group => (
						<AccordionController
							key={group.partnerId}
							name="partner"
							isExpanded={selectedPartner === group.partnerId}
							onChange={(e, expanded) => setSelectedPartner(expanded ? group.partnerId : null)}
							summary={(
								<Box direction="row" justify="between" flex width="100%">
									<Box>
										<Typography fontWeight="bold">
											{group.partnerName}
										</Typography>
									</Box>
									<Box>
										<Typography>
											{group.inspections.filter(i => !i.inspected).length} pending
										</Typography>
									</Box>
								</Box>
							)}
							details={(
								<Pagination
									pageSize={4}
								>
									{sortInspections(group.inspections).map(inspection => (
										<WasteContainerInspectionListItem
											key={inspection.id}
											inspection={inspection}
										/>
									))}
								</Pagination>
							)}
						/>
					))}
				</Box>
			</Box>
		</ViewContainer>
	);
};

export const WasteContainerInspectionSecondaryAction: React.FC<{
	missed: boolean;
	processed: boolean;
	inspected: boolean;
	fillRateDecimal: number;
	onClick: () => void;
}> = ({ missed, processed, inspected, fillRateDecimal, onClick }) => {
	const { size } = useWindowDimensions();
	const container = useCallback((children: ReactNode) => {
		if(size === "small") {
			return (
				<Box>
					{children}
				</Box>
			);
		}

		return (
			<ListItemSecondaryAction>
				{children}
			</ListItemSecondaryAction>
		);
	}, [ size ]);

	return container((
		<Box direction="row" gap="small" align="center">
			{inspected && !processed && (
				<Chip
					color="info"
					label="Uploaded"
					icon={(
						<CheckCircle />
					)}
				/>
			)}
			{missed && (
				<Chip
					color="error"
					label="Missed"
					icon={(
						<Cancel />
					)}
				/>
			)}
			{processed && fillRateDecimal && (
				<Typography fontWeight="bold">
					{formatNumber(fillRateDecimal * 100, true)}% full
				</Typography>
			)}
			{processed && (
				<Chip
					color="success"
					label="Processed"
					icon={(
						<AddTask />
					)}
				/>
			)}
			{!inspected && !missed && (
				<Button
					size="small"
					color="primary"
					variant="contained"
					onClick={onClick}
					fullWidth={size === "small"}
				>
					Upload Image
				</Button>
			)}
		</Box>
	));
};

export const WasteContainerInspectionDialog: React.FC<{
	onClose: () => void;
	inspectionId: string;
}> = ({ onClose, inspectionId }) => {
	const ref = React.createRef<HTMLInputElement>();
	const { inspection, loading } = useWasteContainerInspection(inspectionId);

	const [
		complete,
		{ loading: isUploading }
	] = useMutation(CompleteWasteContainerInspection, {
		refetchQueries: [
			GetReportingActionItems,
			FindWasteContainerInspection,
			FetchWasteContainerInspection
		]
	});

	function onFileUploaded(file: File): void {
		fileToBase64(file).then(base64Content => {
			return complete({
				variables: {
					inspectionId,
					media: {
						name: file.name,
						content: base64Content,
						contentType: file.type,
						context: ProductMediaContext.Default
					}
				}
			});
		});
	}

	const formContext = useForm({
		defaultValues: {
			scheduledFor: "",
			inspectedAt: "",
			calculatedFillRate: ""
		}
	});

	useEffect(() => {
		if(inspection) {
			formContext.reset({
				inspectedAt: inspection.inspectedAt
					? moment.tz(inspection.inspectedAt, "UTC").tz(moment.tz.guess()).format("dddd, MMMM Do hh:mm A")
					: "Not Processed",
				scheduledFor: inspection.scheduledFor
					? moment.tz(inspection.scheduledFor, "UTC").tz(moment.tz.guess()).format("dddd, MMMM Do")
					: "Not Scheduled",
				calculatedFillRate: inspection.fillRateDecimal
					? formatNumber(inspection.fillRateDecimal * 100, 2)
					: "Not Processed"
			});
		}
	}, [ formContext, inspection ]);

	const isTooEarly = useMemo(() => {
		const scheduledFor = inspection?.scheduledFor;
		if(!scheduledFor) return false;

		const maximumDate = moment().tz(moment.tz.guess()).add(1, "day").endOf("day");
		return moment.tz(scheduledFor, "UTC").tz(moment.tz.guess()).isAfter(maximumDate);
	}, [ inspection ]);

	return (
		<DialogWithClose
			title="Upload Image"
			onClose={onClose}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
				</Box>
			)}
			content={(
				<FormContainer formContext={formContext}>
					<Box gap="medium">
						{isTooEarly && (
							<Alert severity="error">
								It's too early to upload an image. Please wait until the day before or the day of the collection.
							</Alert>
						)}
						<TextFieldElement
							name="scheduledFor"
							label="Scheduled Collection Date"
							InputProps={{
								readOnly: true,
								startAdornment: (
									<InputAdornment position="start">
										<CalendarMonth color="primary" />
									</InputAdornment>
								)
							}}
							helperText="The date your container is scheduled for collection."
						/>
						<TextFieldElement
							name="calculatedFillRate"
							label="Calculated Fill Rate (%)"
							InputProps={{
								readOnly: true,
								startAdornment: (
									<InputAdornment position="start">
										{inspection?.processed
											? <AddTask color="primary" />
											: <HourglassTop color="primary" />
										}
									</InputAdornment>
								)
							}}
							helperText="The fill rate we calculated based on your image."
						/>
						{inspection?.inspectedAt && (
							<TextFieldElement
								name="inspectedAt"
								label="Completed Date"
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position="start">
											<CheckCircle color="primary" />
										</InputAdornment>
									)
								}}
								helperText="The date and time you uploaded your image."
							/>
						)}
						{!inspection?.inspected && (
							<Box gap="small">
								<Heading margin="none" level="3">
									Upload Image
								</Heading>
								<Typography variant="body2">
									Upload an image of your container before collection day and we'll automatically calculate the fill rate.
								</Typography>
							</Box>
						)}
						<Box gap="small">
							{!!inspection?.media && (
								<Box
									pad="small"
									height="small"
									background="light-1"
									align="center" justify="center"
								>
									<Image
										key={inspection.media.id}
										src={inspection.media.contentUrl}
										fit="contain"
									/>
								</Box>
							)}
							{!inspection?.media && (
								<Box
									round
									height="small"
									background="light-1"
									align="center" justify="center"
								>
									<IconButton
										onClick={() => {
											ref.current?.click();
										}}
										disabled={isTooEarly || isUploading || inspection?.inspected || false}
									>
										<AddAPhoto
											color="primary"
											fontSize="large"
										/>
									</IconButton>
								</Box>
							)}
							{!inspection?.inspected && (
								<LoadingButton
									color="primary"
									variant="contained"
									loading={isUploading}
									disabled={isTooEarly || inspection?.inspected || false}
									endIcon={<CloudUpload />}
									loadingPosition="end"
									onClick={(event) => {
										event.stopPropagation();
										ref.current?.click();
									}}
								>
									Upload Image
									<VisuallyHiddenInput
										ref={ref}
										type="file"
										accept=".png, .jpg, .jpeg"
										style={{ display: "none" }}
										onClick={(event) => {
											event.stopPropagation();
										}}
										onChange={(event) => {
											if(event.target.files) {
												for(const file of event.target.files) {
													onFileUploaded(file);
												}
											}
										}} />
								</LoadingButton>
							)}
						</Box>
					</Box>
				</FormContainer>
			)}
		/>
	);
};

export const WasteContainerInspectionListItem: React.FC<{
	inspection: ReturnType<typeof useWasteContainerInspections>[ "inspections" ][ number ];
}> = ({ inspection }) => {
	const { size } = useWindowDimensions();
	const [ isSelected, setIsSelected ] = React.useState(false);

	return (
		<Fragment>
			{isSelected && (
				<WasteContainerInspectionDialog
					inspectionId={inspection.id}
					onClose={() => setIsSelected(false)}
				/>
			)}
			<ListItemButton
				divider
				key={inspection.id}
				onClick={() => setIsSelected(true)}
			>
				<Box gap="medium" flex>
					<Box direction="row" gap="medium" align="center" width="100%">
						<ListItemIcon
							sx={{
								minWidth: size === "small" ? "0px" : undefined
							}}
						>
							<CalendarMonth />
						</ListItemIcon>
						<ListItemText>
							<Typography fontWeight="bold">
								{inspection.assignment.name}
							</Typography>
							<Typography variant="body2">
								Scheduled for {moment(inspection.scheduledFor).format("ddd, MMM Do")}
							</Typography>
						</ListItemText>
						{size !== "small" && (
							<WasteContainerInspectionSecondaryAction
								{...inspection}
								onClick={() => setIsSelected(true)}
							/>
						)}
					</Box>
					{size === "small" && (
						<WasteContainerInspectionSecondaryAction
							{...inspection}
							onClick={() => setIsSelected(true)}
						/>
					)}
				</Box>
			</ListItemButton>
		</Fragment>
	);
};