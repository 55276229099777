import React, { useCallback, useMemo } from "react";
import { DialogWithActivatorButton, Pagination, TabController, ViewContainer } from "../../../../components";
import { useInstance, useWindowDimensions } from "../../../../hooks";
import { InstanceType } from "../../../../graphql/__generated__/graphql";
import { useBuildings } from "../hooks";
import { useRecentOrders } from "../../common";
import { useActiveOrders, useCompletedOrders } from "../../hooks";
import { Box, List, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

export function resolveRefererField(instanceType?: InstanceType) {
	switch(instanceType) {
		case InstanceType.Residence: {
			return "Building";
		}
		case InstanceType.DonationCenter: {
			return "Donation Center";
		}
		case InstanceType.ManagementCompany: {
			return "Building";
		}
		default: {
			return "Referer";
		}
	}
}

export const OrdersView: React.FC = () => {
	const { instance } = useInstance();
	const buildings = useBuildings();

	const nameFieldLabel = useMemo(() => {
		switch(instance?.type) {
			case InstanceType.Residence: {
				return "Resident Name";
			}
			case InstanceType.DonationCenter: {
				return "Donor Name";
			}
			default: {
				return "Customer Name";
			}
		}
	}, [ instance ]);

	return (
		<ViewContainer>
			<TabController
				defaultValue="active"
				tabs={[
					{
						label: "Active",
						value: "active",
						component: <ActiveOrdersTab />
					},
					{
						label: "Completed",
						value: "completed",
						component: <CompletedOrdersTab />
					},
					{
						label: "Recent",
						value: "recent",
						component: <RecentOrdersTab />
					}
				]}
			/>
		</ViewContainer>
	);
};

interface GroupedOrdersType {
	date: string | null;
	referer: string;
	orders: ReturnType<typeof useActiveOrders>[ "orders" ];
}

export const ActiveOrdersTab: React.FC = () => {
	const { instance } = useInstance();
	const { grouped, loading } = withGrouping(useActiveOrders(instance?.id ?? ""));

	return (
		<Pagination pageSize={5}>
			{grouped.map((order) => (
				<GroupedOrderDialog
					group={order}
					key={order.referer + order.date}
					activatorButton={(
						<Box>
							<GroupedOrderListItem
								order={order}
							/>
						</Box>
					)}
				/>
			))}
		</Pagination>
	);
};

export const GroupedOrderListItem: React.FC<{
	order: GroupedOrdersType;
}> = ({ order }) => {
	return (
		<ListItemButton
			divider
		>
			<ListItemText>
				<Typography fontWeight="bold">
					{order.referer}
				</Typography>
				<Typography variant="body2">
					{order.date ?? "Not Scheduled"}
				</Typography>
			</ListItemText>
			<ListItemSecondaryAction>
				<Typography fontWeight="bold">
					{order.orders.length} scheduled
				</Typography>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

export const CompletedOrdersTab: React.FC = () => {
	const { instance } = useInstance();
	const { grouped, loading } = withGrouping(useCompletedOrders(instance?.id ?? ""));

	return (
		<Pagination pageSize={5}>
			{grouped.map((order) => (
				<GroupedOrderDialog
					key={order.referer + order.date}
					group={order}
					activatorButton={(
						<Box>
							<GroupedOrderListItem
								order={order}
							/>
						</Box>
					)}
				/>
			))}
		</Pagination>
	);
};

export const RecentOrdersTab: React.FC = () => {
	const { grouped, loading } = withGrouping(useRecentOrders());

	return (
		<Pagination pageSize={5}>
			{grouped.map((order) => (
				<GroupedOrderDialog
					group={order}
					key={order.referer + order.date}
					activatorButton={(
						<Box>
							<GroupedOrderListItem
								order={order}
							/>
						</Box>
					)}
				/>
			))}
		</Pagination>
	);
};

export function withGrouping(hook:
	| ReturnType<typeof useRecentOrders>
	| ReturnType<typeof useActiveOrders>
	| ReturnType<typeof useCompletedOrders>
) {
	const { orders, loading } = hook;
	const grouped = orders.reduce<GroupedOrdersType[]>((acc, order) => {
		const date = (order.pickup?.scheduledDate)
			? moment(order.pickup.scheduledDate).format("dddd, MMMM Do")
			: null;

		const referer = order.referer.name;

		const existing = acc.find((group) => group.date === date && group.referer === referer);
		if(existing) {
			existing.orders.push(order);
		}
		else {
			acc.push({
				date,
				referer,
				orders: [ order ]
			});
		}

		return acc;
	}, []);

	return { orders, loading, grouped };
}

export const GroupedOrderDialog: React.FC<{
	group: GroupedOrdersType;
	activatorButton: React.ReactNode;
}> = ({ group, activatorButton }) => {
	const dispatch = useAppDispatch();

	const handleOpen = useCallback(() => {
		if(group.orders.length === 1) {
			dispatch(push(`/admin/orders/${group.orders[ 0 ].id}`));
		}
	}, [ group ]);

	return (
		<DialogWithActivatorButton
			title="Orders"
			activatorButton={activatorButton}
			onOpen={handleOpen}
		>
			<List>
				{group.orders.map((order) => (
					<OrderListItem
						order={order}
						key={order.id}
					/>
				))}
			</List>
		</DialogWithActivatorButton>
	);
};

export const OrderListItem: React.FC<{
	order: GroupedOrdersType[ "orders" ][ 0 ];
}> = ({ order }) => {
	const dispatch = useAppDispatch();
	const handleClick = useCallback(() => {
		dispatch(push(`/admin/orders/${order.id}`));
	}, [ order ]);

	const primary = useMemo(() => {
		if(order.isCustomerPlaced) {
			let value = order.user.fullName;
			if(order.address.addressLineTwo) {
				//FIXME: Using building prefix
				value += ` (Unit ${order.address.addressLineTwo})`;
			}

			return value;
		}

		if(order.request) {
			let value = `Unit ${order.request.unit}`;
			if(order.request.room) {
				value += ` / Room ${order.request.room}`;
			}

			return value;
		}
	}, [ order ]);

	const secondary = useMemo(() => {
		return order.referer.name;
	}, [ order ]);

	const { size } = useWindowDimensions();

	return (
		<ListItemButton
			key={order.id}
			divider
			onClick={handleClick}
		>
			<ListItemText>
				<Typography fontWeight="bold">
					{primary}
				</Typography>
				<Typography variant="body2">
					{secondary}
				</Typography>
			</ListItemText>
			{size !== "small" && (
				<ListItemSecondaryAction>
					<Typography textAlign="end">
						{order.pickup?.scheduledDate
							? moment(order.pickup.scheduledDate).format("MMM D, YYYY")
							: "Not Scheduled"
						}
					</Typography>
					<Typography variant="body2" textAlign="end">
						{order.request && (
							order.request.isBulkPickup
								? "Bulk Pickup / Cleanout"
								: `${order.request.productCount} item(s)`
						)}
						{!order.request && (
							`${order.productCount} item(s)`
						)}

					</Typography>
				</ListItemSecondaryAction>
			)}
		</ListItemButton>
	);
};