import { Box } from "grommet";
import React from "react";
import { ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { formatCurrency } from "../../../helpers";
import { useSize } from "../hooks";
import { ProductIcon } from "../../../icons/useProductIcon";


export const CheckoutSummaryProductListItem: React.FC<{
	loading: boolean;
	typeId: string;
	sizeId: string;
	iconOverride?: React.ReactNode;
}> = ({ loading, iconOverride, typeId, sizeId }) => {
	const { size, type, quantity, pickupFeeSum } = useSize(typeId, sizeId);

	return (
		<ListItemButton divider>
			<ListItemIcon>
				<ProductIcon iconUrl={size?.iconUrl || type?.iconUrl || type?.category?.iconUrl || "@liverego/icons/Other"} />
			</ListItemIcon>
			<ListItemText>
				<Box>
					<Typography fontWeight="bold">{type?.name} (x {quantity})</Typography>
					<Typography fontSize="small">{size?.name ?? "Standard"}</Typography>
				</Box>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small">
					{((pickupFeeSum && pickupFeeSum > 0) || false) && (
						<Box align="center" justify="center" margin={{ right: "small" }}>
							<Typography fontWeight="bold">{formatCurrency(pickupFeeSum)}</Typography>
						</Box>
					)}
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
