import { Box, Grid, Heading } from "grommet";
import React from "react";
import { DirectionsMap, PickupIncomingItemsContainer, ViewContainer } from "../../../components";
import { HaulerPickupDetailsComponent, PickupRouteContainer } from "../components";
import { useRequiredPathParam, useWindowDimensions } from "../../../hooks";
import { useHauler, useLazyHaulerPickup } from "../hooks";
import { PickupDetailsContainer } from "../../../components/pickup/PickupDetailsContainer";

export const HaulerPickupDetailsView: React.FC = () => {
	const { sizeIndex } = useWindowDimensions();
	const { hauler } = useHauler();
	const pickupId = useRequiredPathParam("pickupId", "/hauler/dashboard");
	const { pickup, loading } = useLazyHaulerPickup(pickupId);

	return (
		<ViewContainer>
			<Box gap="medium">
				<Grid columns={{ count: sizeIndex <= 2 ? 1 : 2, size: "auto" }} gap="medium">
					<PickupDetailsContainer
						loading={loading}
						pickup={pickup}
					>
						<HaulerPickupDetailsComponent
							pickup={pickup}
							loading={loading}
							hauler={hauler}
						/>
					</PickupDetailsContainer>
					<Box>
						<Heading level="3" margin="none">
							Pickup Items
						</Heading>
						<PickupIncomingItemsContainer
							pickup={pickup}
							loading={loading}
						/>
					</Box>
				</Grid>
				<Grid columns={{ count: 1, size: "auto" }} gap="small">
					<Box>
						<PickupRouteContainer
							pickupId={pickup?.id ?? ""}
							stops={(pickup?.stops ?? []).map((stop) => ({
								id: stop.id,
								index: stop.index,
								incomingCount: stop.incomingCount,
								outgoingCount: stop.outgoingCount,
								outlet: stop.outlet || null,
								address: {
									addressLineOne: stop.address.addressLineOne,
									addressLineTwo: stop.address.addressLineTwo ?? "",
									city: stop.address.city,
									state: stop.address.state,
									zipCode: stop.address.zipCode
								}
							}))} />
					</Box>
					<Box flex align="center" justify="center" margin="small">
						<DirectionsMap
							stops={(pickup?.stops ?? []).map((stop) => ({
								index: stop.index,
								addressLineOne: stop.address.addressLineOne,
								addressLineTwo: stop.address.addressLineTwo ?? "",
								city: stop.address.city,
								state: stop.address.state,
								zipCode: stop.address.zipCode
							}))}
						/>
					</Box>
				</Grid>
			</Box>
		</ViewContainer>
	);
};
