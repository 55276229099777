import { WasteContainerVolumeUnit } from "../../../graphql/__generated__/graphql";

export function volumeUnitEnumToFriendly(volumeUnit: WasteContainerVolumeUnit): string {
	switch(volumeUnit) {
		case WasteContainerVolumeUnit.Cy: {
			return "Cubic Yards";
		}
		case WasteContainerVolumeUnit.Gl: {
			return "Gallons";
		}
		case WasteContainerVolumeUnit.Cf: {
			return "Cubic Feet";
		}
		case WasteContainerVolumeUnit.Lt: {
			return "Liters";
		}
		case WasteContainerVolumeUnit.Cm: {
			return "Cubic Meters";
		}
	}
}