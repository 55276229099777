import { useMutation, useQuery } from "@apollo/client";
import { FetchWasteAudit, FindWasteAudit, AssignWasteAudit, UnassignWasteAudit } from "../../../../graphql";
import { useState } from "react";

export function useWasteAudit(auditId: string) {
	const { data, loading } = useQuery(FetchWasteAudit, {
		variables: { auditId },
		skip: !auditId
	});

	return {
		audit: data?.FetchWasteAudit || null,
		loading
	};
}

export function useWasteAudits(
	instanceId: string,
	name?: string
) {
	const { data, loading } = useQuery(FindWasteAudit, {
		variables: {
			instance: { id: { equals: instanceId } },
			name: name ? { ilike: name } : undefined
		},
		skip: !instanceId
	});

	return {
		loading,
		audits: data?.FindWasteAudit || []
	};
}

export function useWasteAuditAssignment() {
	const [ isAssigningId, setIsAssigningId ] = useState<string>("");
	const [ isUnassigningId, setIsUnassigningId ] = useState<string>("");

	const [
		assign,
		{ loading: assignLoading }
	] = useMutation(AssignWasteAudit, {
		refetchQueries: [ FetchWasteAudit ]
	});

	const [
		unassign,
		{ loading: unassignLoading }
	] = useMutation(UnassignWasteAudit, {
		refetchQueries: [ FetchWasteAudit ]
	});

	function assignContainerToAudit(
		auditId: string,
		assignmentId: string
	) {
		setIsAssigningId(assignmentId);
		return assign({
			variables: { auditId, assignmentId }
		}).finally(() => {
			setIsAssigningId("");
		});
	}

	function unassignContainerFromAudit(
		auditId: string,
		assignmentId: string
	) {
		setIsUnassigningId(assignmentId);
		return unassign({
			variables: { auditId, assignmentId }
		}).finally(() => {
			setIsUnassigningId("");
		});
	}

	return {
		isAssigning: assignLoading,
		isAssigningId,
		isUnassigning: unassignLoading,
		isUnassigningId,
		assignContainerToAudit,
		unassignContainerFromAudit
	};
}