import { gql } from "../../../__generated__";

export const CreateInstanceOnboardingRequest = gql(/* GraphQL */ `
	mutation CreateInstanceOnboardingRequest(
		$businessName: String!
		$businessType: BusinessType!
		$tracking: InstanceOnboardingSourceInput!
		$materials: [ String! ]!
		$subdomain: String!
		$logoMediaId: String!
		$primaryColor: String!
		$secondaryColor: String!
		$address: CreateAddressInputDTO!
	) {
		CreateInstanceOnboardingRequest(
			businessName: $businessName
			businessType: $businessType
			tracking: $tracking
			materials: $materials
			subdomain: $subdomain
			logoMediaId: $logoMediaId
			primaryColor: $primaryColor
			secondaryColor: $secondaryColor
			address: $address
		) {
			id
			instance {
				id
				domain
			}
		}
	}
`);