import { gql } from "@apollo/client";

export const Order_GlobalAdminFragment = gql`
	fragment Order_GlobalAdminFragment on Order {
		milestone
		...Order_HeaderFragment
		...Order_PickupFragment
		...Order_SourceFragment
		...Order_AddressFragment
		...Order_EstimateFragment
		...Order_ProductsFragment
		...Order_CancellationDetailsFragment
	}
`;