import { Box, Heading, Spinner } from "grommet";
import React, { useMemo } from "react";
import { useLazyHaulerPickup } from "../hooks";
import { ListItemSkeleton, Pagination, ViewContainer } from "../../../components";
import { useTodaysPickups } from "../hooks/useTodaysPickups";
import { Chip, LinearProgress, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { formatCurrency } from "../../../helpers";
import moment from "moment";
import { useAppDispatch } from "../../../store";
import { push } from "redux-first-history";
import { useScheduledPickups } from "../hooks/useScheduledPickups";
import { useWindowDimensions } from "../../../hooks";

export const HaulerDashboard: React.FC = () => {
	return (
		<ViewContainer>
			<Box gap="medium" height="100%" overflow={{ vertical: "scroll" }} style={{ display: "block" }}>
				<HaulerTodaysPickupsContainer />
				<HaulerScheduledPickupsContainer />
			</Box>
		</ViewContainer>
	);
};

export const HaulerTodaysPickupsContainer: React.FC = () => {
	const dispatch = useAppDispatch();
	const { pickups, loading } = useTodaysPickups();

	return (
		<Box gap="small">
			<Box gap="small">
				<Heading level={"3"} margin="none">
					Today's Pickups
				</Heading>
				{loading && (
					<LinearProgress />
				)}
			</Box>
			{(!loading && pickups.length === 0) && (
				<Box height="small" align="center" justify="center">
					<Typography fontWeight="bold">
						Nothing scheduled for today
					</Typography>
				</Box>
			)}
			<Pagination pageSize={4}>
				{pickups.map(pickup => (
					<HaulerPickupListItem
						key={pickup.id}
						pickupId={pickup.id}
						onClick={() => {
							dispatch(push(`/hauler/pickups/${pickup.id}`));
						}}
					/>
				))}
			</Pagination>
		</Box>
	);
};

export const HaulerScheduledPickupsContainer: React.FC = () => {
	const dispatch = useAppDispatch();
	const { pickups, loading } = useScheduledPickups();

	return (
		<Box gap="small">
			<Box gap="small">
				<Heading level={"3"} margin="none">
					Scheduled Pickups
				</Heading>
				{loading && (
					<LinearProgress />
				)}
			</Box>
			{(!loading && pickups.length === 0) && (
				<Box height="small" align="center" justify="center">
					<Typography fontWeight="bold">
						Nothing scheduled yet
					</Typography>
				</Box>
			)}
			<Pagination pageSize={4}>
				{pickups.map(pickup => (
					<HaulerPickupListItem
						key={pickup.id}
						pickupId={pickup.id}
						onClick={() => {
							dispatch(push(`/hauler/pickups/${pickup.id}`));
						}}
					/>
				))}
			</Pagination>
		</Box>
	);
};

export const HaulerPickupListItem: React.FC<{
	pickupId: string;
	onClick?: () => void;
}> = ({ onClick, pickupId }) => {
	const { size } = useWindowDimensions();
	const { pickup, isStarted, loading, isCompleted } = useLazyHaulerPickup(pickupId);

	const formattedDate = useMemo(() => {
		if(!pickup?.scheduledDate) return "";
		const datePart = moment(pickup.scheduledDate, "YYYY-MM-DD").format("MMM Do, YYYY");

		if(pickup.window && size !== "small") {
			return `${datePart} (${pickup.window.label})`;
		}

		return datePart;
	}, [ pickup, size ]);

	return (
		<ListItemButton
			divider
			onClick={onClick}
		>
			{loading && (
				<ListItemIcon>
					<Spinner />
				</ListItemIcon>
			)}
			<ListItemText>
				<ListItemSkeleton
					loading={loading}
					content={(
						<Typography fontSize="18px" fontWeight="bold">{formattedDate}</Typography>
					)}
				/>
				<ListItemSkeleton
					loading={loading}
					content={(
						<Box direction="row" gap="small">
							<Typography variant="caption">{pickup?.stopCount} stop(s)</Typography>
							<Typography variant="caption">|</Typography>
							<Typography variant="caption">{pickup?.incomingProductCount} item(s)</Typography>
						</Box>
					)}
				/>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small" align="center">
					{!!pickup?.invoice?.amount && (
						<Typography fontWeight="bold">
							{formatCurrency(pickup.invoice.amount)}
						</Typography>
					)}
					{(isStarted && !isCompleted) && (
						<Chip
							label="In Progress"
							color="warning"
						/>
					)}
					{isCompleted && (
						<Chip
							label="Completed"
							color="success"
						/>
					)}
					{!isStarted && (
						<Chip
							label="Scheduled"
							color="primary"
						/>
					)}
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};