import React, { useMemo } from "react";
import { SidebarRouter, SidebarRouteItem, DashboardListItem, OrdersListItem, WasteReportingListItem } from "../../../components";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { isNotNull } from "../../../helpers";
import { useFeature } from "../../instance/hooks/useFeature";
import { WasteReportingDashboardView } from "../common/views/WasteDiversionDashboard";
import { useAdminStoreRoutes } from "../donation/router";
import { WasteTicketView } from "../common/views/WasteTickets";
import { OrderDetailsView, OrdersView, ProductDetails } from "../common";
import { UniversityAdminDashboard } from "./views";

export const UniversityRouter: React.FC = () => {
	return useUniversityRouter().toRouter();
};

export function useUniversityRouter() {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);

	const isRemovalEnabled = useFeature(InstanceFeature.RemovalEnabled);
	const isDonationEnabled = useFeature(InstanceFeature.DonationEnabled);

	const storeRoutes = useAdminStoreRoutes();

	return useMemo(() => {
		return new SidebarRouter([
			new SidebarRouteItem(
				"/dashboard",
				<UniversityAdminDashboard />,
				<DashboardListItem
					key="dashboard-list-item"
					absolutePath="/admin/dashboard"
				/>,
				true
			),
			(isRemovalEnabled || isDonationEnabled) ? new SidebarRouteItem(
				"/orders",
				<OrdersView />,
				<OrdersListItem
					key="orders-list-item"
					absolutePath="/admin/orders"
				/>,
				true
			) : null,
			new SidebarRouteItem(
				"/waste-reporting/*",
				<WasteReportingDashboardView />,
				<WasteReportingListItem
					key="waste-reporting"
					absolutePath="/admin/waste-reporting"
				/>,
				true
			),
		].filter(isNotNull), "Residence", [
			{ from: "/waste-reporting", to: "/admin/waste-reporting/dashboard" },
			{ from: "/", to: "/admin/dashboard" },
			isSalesEnabled ? { from: "/store", to: "/admin/store/dashboard" } : null
		].filter(isNotNull), [
			{ path: "/orders/:orderId", element: <OrderDetailsView permitEdit={false} permitViewPickup={false} /> },
			{ path: "/products/:productId", element: <ProductDetails permitEdit={false} /> },
			{ path: "/waste-reporting/dashboard", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/share", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/download", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/upload", element: <WasteTicketView /> },
			...storeRoutes
		]);
	}, [ isSalesEnabled, isDonationEnabled ]);
}