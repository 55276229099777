import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { ProductCondition } from "../graphql/__generated__/graphql";
import { MarketGql } from "../graphql";

export interface ShopState {
	wasFilterApplied: boolean;
	priceLowFilter: number | null;
	priceHighFilter: number | null;
	market: MarketGql | null;
	conditionFilter: ProductCondition[] | null;
	brandFilter: string[];
	materialsFilter: string[];
	cartQuantities: { [ key: string ]: number; };
	filterDialogControl: string;
}

const initialState: ShopState = {
	wasFilterApplied: false,
	market: null,
	conditionFilter: null,
	priceLowFilter: null,
	priceHighFilter: null,
	brandFilter: [],
	materialsFilter: [],
	cartQuantities: {},
	filterDialogControl: ""
};

export const shopSlice = createSlice({
	name: "shop",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setMarket: (state, action: PayloadAction<MarketGql | null>) => {
			state.market = action.payload;
		},
		setFiltersApplied: (state, action: PayloadAction<boolean>) => {
			state.wasFilterApplied = action.payload;
		},
		setConditionFilter: (state, action: PayloadAction<ProductCondition[] | null>) => {
			state.conditionFilter = action.payload;
		},
		setPriceLowFilter: (state, action: PayloadAction<number | null>) => {
			state.priceLowFilter = action.payload;
		},
		setPriceHighFilter: (state, action: PayloadAction<number | null>) => {
			state.priceHighFilter = action.payload;
		},
		setBrandFilter: (state, action: PayloadAction<string[]>) => {
			state.brandFilter = action.payload;
		},
		setMaterialsFilter: (state, action: PayloadAction<string[]>) => {
			state.materialsFilter = action.payload;
		},
		clearFilters: (state) => {
			state.conditionFilter = null;
			state.priceLowFilter = null;
			state.priceHighFilter = null;
			state.brandFilter = [];
			state.materialsFilter = [];
			state.wasFilterApplied = false;
		},
		updateCartQuantity: (state, action: PayloadAction<{ productId: string, quantity: number; }>) => {
			state.cartQuantities[ action.payload.productId ] = action.payload.quantity;
		},
		setHideFilterDialog: (state, action: PayloadAction<void>) => {
			action;
			state.filterDialogControl = "";
		},
		setDisplayFilterDialog: (state, action: PayloadAction<string>) => {
			state.filterDialogControl = action.payload;
		}
	}
});

export const {
	setMarket,
	clearFilters,
	setConditionFilter,
	setPriceLowFilter,
	setPriceHighFilter,
	setBrandFilter,
	setMaterialsFilter,
	setFiltersApplied,
	updateCartQuantity,
	setDisplayFilterDialog,
	setHideFilterDialog
} = shopSlice.actions;

export const selectFilters = (state: RootState) => ({
	wasFilterApplied: state.shop.wasFilterApplied,
	priceLowFilter: state.shop.priceLowFilter,
	priceHighFilter: state.shop.priceHighFilter,
	conditionFilter: state.shop.conditionFilter,
	brandFilter: state.shop.brandFilter,
	materialsFilter: state.shop.materialsFilter
});

export const selectMarket = (state: RootState) => state.shop.market;
export const selectCartQuantities = (state: RootState) => state.shop.cartQuantities;
export const selectFilterDialogControl = (state: RootState) => state.shop.filterDialogControl;

export default shopSlice.reducer;