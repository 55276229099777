import { useClipboard, useFullScreen, useRequiredPathParam } from "../../../hooks";
import { useStop } from "../../../graphql/documents/movement";
import { AddressDetailsDialog, BasicListItem, ViewContainer } from "../../../components";
import { Box, Grid, Heading } from "grommet";
import { HeadingWithLoading } from "./ActionItems";
import { Checkbox, IconButton, List } from "@mui/material";
import { CopyAll, Visibility } from "@mui/icons-material";
import { useMemo } from "react";
import { formatAddress } from "../../../helpers";
import { StopIncomingItemsComponent } from "../components/stop";
import { StopOutgoingItemsComponent } from "../components/stop/StopOutgoingItemsComponent";
import moment from "moment";

export const StopIdListItem: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	const { copy } = useClipboard();

	return (
		<BasicListItem
			label="Stop Id"
			value={stopId}
			action={(
				<IconButton
					color="primary"
					onClick={() => copy(stopId)}
				>
					<CopyAll />
				</IconButton>
			)}
		/>
	);
};

export const StopAddressListItem: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	const { stop } = useStop(stopId);

	const addressFormatted = useMemo(() => {
		const outlet = stop?.outlet;
		const address = stop?.address;

		if(outlet) {
			return outlet.name;
		}

		if(address) {
			return formatAddress(address);
		}

		return "Unknown";
	}, [ stop ]);

	return (
		<BasicListItem
			label="Address"
			value={addressFormatted}
			action={(
				<AddressDetailsDialog
					address={stop?.address || {}}
					activatorButton={(
						<IconButton
							color="primary"
						>
							<Visibility />
						</IconButton>
					)}
				/>
			)}
		/>
	);
};

export const StopStartedAtListItem: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	const { stop } = useStop(stopId);

	const started = useMemo(() => {
		if(!stop) {
			return false;
		}

		return stop.started;
	}, [ stop ]);

	const startedAt = useMemo(() => {
		if(!stop || !stop?.startedAt) {
			return "Not Started";
		}

		return moment.tz(stop.startedAt, "UTC").tz(moment.tz.guess()).format("MMMM Do YYYY, hh:mm A");
	}, [ stop ]);

	return (
		<BasicListItem
			label="Started"
			value={startedAt}
			action={(
				<Checkbox
					checked={started}
				/>
			)}
		/>
	);
};

export const StopCompletedAtListItem: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	const { stop } = useStop(stopId);

	const completed = useMemo(() => {
		if(!stop) {
			return false;
		}

		return stop.completed;
	}, [ stop ]);

	const completedAt = useMemo(() => {
		if(!stop || !stop?.completedAt) {
			return "Not Completed";
		}

		return moment.tz(stop.completedAt, "UTC").tz(moment.tz.guess()).format("MMMM Do YYYY, hh:mm A");
	}, [ stop ]);

	return (
		<BasicListItem
			label="Completed"
			value={completedAt}
			action={(
				<Checkbox
					checked={completed}
				/>
			)}
		/>
	);
};

export const StopDetailsView: React.FC = () => {
	const stopId = useRequiredPathParam("stopId", "/hauler/dashboard");
	const { stop, loading } = useStop(stopId);
	const fullScreen = useFullScreen();

	return (
		<ViewContainer>
			<Box gap="medium">
				<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="medium">
					<Box>
						<HeadingWithLoading loading={loading}>
							Stop Details
						</HeadingWithLoading>
						<List>
							<StopIdListItem
								stopId={stop?.id || ""}
							/>
							<StopAddressListItem
								stopId={stop?.id || ""}
							/>
						</List>
					</Box>
					<Box>
						<HeadingWithLoading loading={loading}>
							Stop Tracking
						</HeadingWithLoading>
						<List>
							<StopStartedAtListItem
								stopId={stop?.id || ""}
							/>
							<StopCompletedAtListItem
								stopId={stop?.id || ""}
							/>
						</List>
					</Box>
				</Grid>
				{(stop && stop.incoming.length > 0) && (
					<Box>
						<Heading level="3" margin="none">
							Pickup Items
						</Heading>
						<StopIncomingItemsComponent
							stopId={stop.id}
							movementId={stop.movement?.id || ""}
							haulerId={stop.movement?.assignedTo?.id || ""}
						/>
					</Box>
				)}
				{(stop && stop.outgoing.length > 0) && (
					<Box>
						<Heading level="3" margin="none">
							Dropoff Items
						</Heading>
						<StopOutgoingItemsComponent
							stopId={stop.id}
							movementId={stop.movement?.id || ""}
							haulerId={stop.movement?.assignedTo?.id || ""}
						/>
					</Box>
				)}
			</Box>
		</ViewContainer>
	);
};