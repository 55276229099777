import React from "react";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

export const BeginCheckoutButton: React.FC<{
	label?: string;
}> = ({ label }) => {
	const dispatch = useAppDispatch();

	function handleBeginCheckout() {
		dispatch(push("/checkout"));
	}

	return (
		<LoadingButton
			loading={false}
			color="primary"
			variant="contained"
			onClick={handleBeginCheckout}
		>
			{label || "Begin Order"}
		</LoadingButton>
	);
};
