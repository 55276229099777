import React, { useMemo } from "react";
import { SidebarRouter, SidebarRouteItem, WasteReportingListItem } from "../../../components";
import { isNotNull } from "../../../helpers";
import { WasteReportingDashboardView } from "../common/views/WasteDiversionDashboard";

export const RetailRouter: React.FC = () => {
	return useRetailRouter().toRouter();
};

export function useRetailRouter() {
	return useMemo(() => {
		return new SidebarRouter([
			new SidebarRouteItem(
				"/waste-reporting/*",
				<WasteReportingDashboardView />,
				<WasteReportingListItem
					key="waste-reporting"
					absolutePath="/admin/waste-reporting"
				/>,
				true
			)
		].filter(isNotNull), "Retail", [
			{ from: "/", to: "/admin/waste-reporting" },
			{ from: "/waste-reporting", to: "/admin/waste-reporting/dashboard" }
		].filter(isNotNull), [

		]);
	}, []);
}