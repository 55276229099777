import React from "react";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import { FetchHaulerPickup, FetchUserHauler, RejectHaulerPickupRequest } from "../../../../graphql";
import { useSnackbar } from "notistack";


export const RejectHaulerPickupRequestButton: React.FC<{
	requestId: string;
}> = ({ requestId }) => {
	const snack = useSnackbar();

	const [
		reject, { loading: isRejecting }
	] = useMutation(RejectHaulerPickupRequest, {
		variables: { requestId },
		refetchQueries: [ FetchUserHauler, FetchHaulerPickup ]
	});

	return (
		<LoadingButton
			color="error"
			variant="contained"
			loading={isRejecting}
			onClick={() => {
				reject().catch(err => {
					console.error("Failed to reject pickup request", err);
					snack.enqueueSnackbar(
						"We ran into an issue submitting your request.",
						{ variant: "error" }
					);
				});
			}}
		>
			Decline Pickup
		</LoadingButton>
	);
};
