import { useQuery } from "@apollo/client";
import { ListMarkets } from "../graphql";

export function useMarkets() {
	const { data, loading } = useQuery(ListMarkets);

	return {
		loading,
		markets: data?.ListMarkets ?? []
	};
}