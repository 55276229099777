import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";

export const StripeController: React.FC<{
	publicKey: string;
	clientSecret: string;
	children: React.ReactNode;
}> = ({ publicKey, clientSecret, children }) => {
	const stripe = useMemo(() => {
		return loadStripe(publicKey);
	}, [ publicKey ]);

	const stripeOptions: StripeElementsOptions = useMemo(() => {
		return {
			loader: "always",
			clientSecret: clientSecret,
			appearance: {

			}
		};
	}, [ clientSecret ]);

	return (
		<Elements stripe={stripe} options={stripeOptions}>
			{children}
		</Elements>
	);
};