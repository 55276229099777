import { gql } from "../../../__generated__";

export const FetchStop = gql(/* GraphQL */ `
	query FetchStop($stopId: String!) {
		FetchStop(stopId: $stopId) {
			...Stop_PreviewFragment
		}
	}
`);

export const FetchStopDetailed = gql(/* GraphQL */ `
	query FetchStopDetailed($stopId: String!) {
		FetchStop(stopId: $stopId) {
			...Stop_HeaderFragment
			...Stop_ProductsFragment
			...Stop_TrackingFragment
			...Stop_OriginDestinationFragment
			outlet {
				contactEmail
				contactPhone
			}
			movement {
				id
				scheduledDate
				window {
					from
					to
					label
				}
				assignedTo {
					id
					name
				}
			}
		}
	}
`);