import { InputAdornment } from "@mui/material";
import React, { useMemo } from "react";
import { TextFieldElement } from "react-hook-form-mui";
import { volumeUnitEnumToFriendly } from "../../helpers";
import { WasteContainerVolumeUnit } from "../../../../../graphql/__generated__/graphql";

export const VolumeElement: React.FC<{
	volumeUnit: WasteContainerVolumeUnit;
}> = ({ volumeUnit }) => {
	const volumeUnitFriendly = useMemo(() => {
		return volumeUnitEnumToFriendly(volumeUnit);
	}, [ volumeUnit ]);

	return (
		<TextFieldElement
			name="volume"
			label="Container Volume"
			helperText="What is the size of this container?"
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{volumeUnitFriendly}
					</InputAdornment>
				)
			}}
			validation={{
				validate: value => {
					if(value === null || value === "") {
						return "This field is required";
					}

					const asNumber = Number(value);
					if(isNaN(asNumber) || asNumber <= 0) {
						return "Please enter a valid number";
					}
				}
			}}
		/>
	);
};
