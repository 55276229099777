import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../store";
import { push } from "redux-first-history";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export function usePathParam(param: string) {
	const params = useParams();

	const paramValue = useMemo(() => {
		return params[ param ];
	}, [ params ]);

	return paramValue || "";
}

export function useRequiredPathParam(
	param: string,
	fallbackPath: string
) {
	const params = useParams();
	const dispatch = useAppDispatch();

	const paramValue = useMemo(() => {
		return params[ param ];
	}, [ params ]);

	useEffect(() => {
		if(!paramValue) {
			dispatch(push(fallbackPath));
		}
	}, [ paramValue, dispatch ]);

	return paramValue || "";
}

export function useRequiredQueryParam(
	param: string,
	fallbackPath: string
) {
	const dispatch = useAppDispatch();
	const [ query ] = useSearchParams();

	const value = useMemo(() => {
		return query.get(param) || "";
	}, [ query ]);

	useEffect(() => {
		if(!value) {
			dispatch(push(fallbackPath));
		}
	}, [ query ]);

	return value;
}