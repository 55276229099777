import { gql } from "@apollo/client";

export const Instance_PartnersFragment = gql`
	fragment Instance_PartnersFragment on Instance {
		partners {
			__typename
			... on Building {
				...Building_HeaderFragment
				...Building_AddressFragment
				...Building_ScheduledPickupsFragment
			}
			... on ReuseOutlet {
				...ReuseOutlet_HoursFragment
				...ReuseOutlet_HeaderFragment
				...ReuseOutlet_AddressFragment
				...ReuseOutlet_ScheduledPickupsFragment
			}
			... on StorePartner {
				id
				name
			}
			... on DisposalOutlet {
				id
				name
			}
			... on ReferralPartner {
				id
				name
				...ReferralPartner_ScheduledPickupsFragment
			}
			... on RecyclingOutlet {
				id
				name
			}
			... on Hauler {
				id
				name
			}
		}
	}
`;

export const Instance_PartnersPublicFragment = gql`
	fragment Instance_PartnersPublicFragment on Instance {
		partners {
			__typename
			... on Building {
				...Building_HeaderFragment
				...Building_AddressFragment
			}
			... on ReuseOutlet {
				...ReuseOutlet_HoursFragment
				...ReuseOutlet_HeaderFragment
				...ReuseOutlet_AddressFragment
			}
			... on StorePartner {
				id
				name
			}
			... on DisposalOutlet {
				id
				name
			}
			... on ReferralPartner {
				id
				name
				...ReferralPartner_ScheduledPickupsFragment
			}
			... on RecyclingOutlet {
				id
				name
			}
			... on Hauler {
				id
				name
			}
		}
	}
`;
