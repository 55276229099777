import { Box, Heading } from "grommet";
import { ViewContainer } from "../components";
import { useDashboardRedirect } from "../hooks";
import React, { useEffect } from "react";

export const PageNotPermittedView: React.FC = () => {
	return (
		<ViewContainer>
			<Box flex align="center" justify="center" gap="medium">
				<Heading level="2">
					You are not authorized to view this page
				</Heading>
			</Box>
		</ViewContainer>
	);
};

export const RootRedirectView: React.FC = () => {
	const { redirect } = useDashboardRedirect();

	useEffect(() => {
		redirect();
	}, [ redirect ]);

	return <></>;
};