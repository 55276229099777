import { useQuery } from "@apollo/client";
import { Box, Heading } from "grommet";
import { FindHaulerPendingQuoteRequests } from "../../../../graphql";
import { useEffect, useMemo } from "react";
import { Pagination } from "../../../../components";
import { useSnackbar } from "notistack";
import { HaulerPendingQuoteRequestListItem } from "./HaulerPendingQuoteRequestListItem";
import { LinearProgress, Typography } from "@mui/material";
import { useFullScreen } from "../../../../hooks";

interface HaulerPendingQuotesRequestControllerProps {
	haulerId: string;
}

export const HaulerPendingQuoteRequestsController: React.FC<HaulerPendingQuotesRequestControllerProps> = (props) => {
	const snack = useSnackbar();
	const { data, loading, error } = useQuery(FindHaulerPendingQuoteRequests, {
		variables: { haulerId: props.haulerId }
	});

	useEffect(() => {
		if(error) {
			console.error("Failed to fetch pending quote requests: ", error);
			snack.enqueueSnackbar("We ran into an issue loading your information.", {
				variant: "error"
			});
		}
	}, [ error ]);

	const requests = useMemo(() => {
		return data?.FindHaulerPendingQuoteRequests ?? [];
	}, [ data ]);

	const fullScreen = useFullScreen();

	return (
		<Box flex gap="small" margin={{ top: "small" }} style={{ display: fullScreen ? "block" : undefined }}>
			<Box>
				<Heading margin="none" level="3">
					Quote Requests
				</Heading>
				<Typography fontSize="small">
					Click on the name below to view the request details and submit a quote.
				</Typography>
			</Box>
			{loading && (
				<LinearProgress />
			)}
			{requests.length === 0 && (
				<Box flex align="center" justify="center">
					<Typography variant="body1">
						Nothing here yet
					</Typography>
				</Box>
			)}
			<Box flex>
				<Pagination
					pageSize={fullScreen ? 4 : 5}
				>
					{requests.map((request) => (
						<HaulerPendingQuoteRequestListItem
							key={request.id}
							requestId={request.id}
							orderId={request.order.id}
							marketCode={request.order.market.code}
							productCount={request.order.products.length}
							customerNameFormatted={`${request.order.user.firstName} ${request.order.user.lastName.charAt(0).toUpperCase()}.`}
						/>
					))}
				</Pagination>
			</Box>
		</Box>
	);
};