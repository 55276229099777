import React from "react";
import { Apartment, ContentPaste, Engineering, HelpCenter, Restaurant, School, Storefront } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectOnboardingState, setBusinessType } from "../../../store/onboarding";
import { BusinessTypeDefinition } from "../types";
import { BusinessType } from "../../../graphql/__generated__/graphql";

export const businessTypeDefinitions: BusinessTypeDefinition[] = [
	{
		icon: <Storefront />,
		id: BusinessType.Retail,
		label: "Retail",
		description: "Best for retail stores and shopping centers"
	},
	{
		icon: <School />,
		id: BusinessType.Education,
		label: "Education",
		description: "Best for schools, universities, lab spaces, and other educational institutions"
	},
	{
		icon: <ContentPaste />,
		id: BusinessType.Consulting,
		label: "Consultants and Advisors",
		description: "Best for consultants and advisors helping other organizations with sustainability and waste management"
	},
	{
		icon: <Apartment />,
		id: BusinessType.Multifamily,
		label: "Multifamily",
		description: "Best for ownership and management groups of multifamily properties"
	},
	{
		icon: <Apartment />,
		id: BusinessType.Commercial,
		label: "Commercial",
		description: "Best for ownership and management groups of commercial and office spaces"
	},
	{
		icon: <Restaurant />,
		id: BusinessType.FoodService,
		label: "Food Service",
		description: "Best for restaurants, cafes, and other food service establishments"
	},
	{
		icon: <Engineering />,
		id: BusinessType.Construction,
		label: "Construction",
		description: "Best for construction and project management teams."
	},
	{
		icon: <HelpCenter />,
		id: BusinessType.Other,
		label: "Other",
		description: "Another type of business."
	}
];

export function useBusinessType() {
	const dispatch = useAppDispatch();
	const { businessType } = useAppSelector(selectOnboardingState);

	const handleSetBusinessType = (type: BusinessType) => {
		dispatch(setBusinessType(type));
	};

	return {
		businessType,
		setBusinessType: handleSetBusinessType,
		types: businessTypeDefinitions
	};
}