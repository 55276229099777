import { gql } from "../../../__generated__";

export const FetchWasteContainerAssignment = gql(`
	query FetchWasteContainerAssignment(
		$assignmentId: String!
	) {
		FetchWasteContainerAssignment(
			assignmentId: $assignmentId
		) {
			...WasteContainerAssignment_ExtendedFragment
		}
	}
`);