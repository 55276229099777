import { SvgIcon, SvgIconProps } from "@mui/material";

	export const DumpsterAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1050 768.75c-10.352 0-18.75 8.3984-18.75 18.75v93.75h-37.5c-10.352 0-18.75 8.3984-18.75 18.75s8.3984 18.75 18.75 18.75h56.25c10.352 0 18.75-8.3984 18.75-18.75v-112.5c0-10.352-8.3984-18.75-18.75-18.75z"/>
 <path d="m1147.2 300.36-16.273-97.688c-4.9141-29.551-32.211-52.668-62.156-52.668h-937.5c-29.945 0-57.242 23.117-62.156 52.668l-16.273 97.688c-29.402 1.8008-52.82 26.043-52.82 55.895v37.5c0 31.012 25.238 56.25 56.25 56.25v487.5c0 31.012 25.238 56.25 56.25 56.25h37.5v37.5c0 10.352 8.3984 18.75 18.75 18.75h112.5c10.352 0 18.75-8.3984 18.75-18.75v-37.5h600v37.5c0 10.352 8.3984 18.75 18.75 18.75h112.5c10.352 0 18.75-8.3984 18.75-18.75v-37.5h37.5c31.012 0 56.25-25.238 56.25-56.25v-487.5c31.012 0 56.25-25.238 56.25-56.25v-37.5c0-29.852-23.418-54.094-52.82-55.895zm-1041.1-91.535c1.8906-11.363 13.668-21.32 25.18-21.32h937.5c11.512 0 23.289 9.957 25.18 21.32l2.6836 16.18h-271.61c-10.352 0-18.75 8.3984-18.75 18.75s8.3984 18.75 18.75 18.75h277.88l6.2422 37.5h-1018.2zm156.43 803.68h-75v-18.75h75zm750 0h-75v-18.75h75zm93.75-75c0 10.332-8.418 18.75-18.75 18.75h-975c-10.332 0-18.75-8.418-18.75-18.75v-487.5h37.5v112.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-112.5h75v37.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-37.5h75v112.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-112.5h75v37.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-37.5h75v18.75c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-18.75h75v37.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-37.5h75v112.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-112.5h75v37.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-37.5h75v112.5c0 10.352 8.3984 18.75 18.75 18.75s18.75-8.3984 18.75-18.75v-112.5h37.5zm56.25-543.75c0 10.332-8.418 18.75-18.75 18.75h-1087.5c-10.332 0-18.75-8.418-18.75-18.75v-37.5c0-10.332 8.418-18.75 18.75-18.75h1087.5c10.332 0 18.75 8.418 18.75 18.75z"/>
 <path d="m742.2 678.54c-4.9141-3.5234-11.176-4.4805-16.895-2.5859l-49.219 16.406c-9.8242 3.2812-15.133 13.895-11.852 23.719 3.2617 9.8047 13.855 15.188 23.719 11.852l21.227-7.0703c-12.148 48.992-56.492 85.387-109.18 85.387-39.918 0-77.211-21.562-97.312-56.27-5.1953-8.9609-16.648-12.02-25.633-6.8242-8.9609 5.1953-12.02 16.668-6.8242 25.633 26.793 46.234 76.52 74.961 129.77 74.961 82.707 0 150-67.293 150-150 0-6.0195-2.8867-11.68-7.8008-15.207z"/>
 <path d="m474.68 711.54 49.219-16.406c9.8242-3.2812 15.133-13.895 11.852-23.719-3.2617-9.8047-13.855-15.168-23.719-11.852l-21.227 7.0703c12.168-48.992 56.512-85.387 109.2-85.387 39.918 0 77.211 21.562 97.312 56.27 5.1953 8.9609 16.633 12.02 25.633 6.8242 8.9609-5.1953 12.02-16.668 6.8242-25.633-26.793-46.234-76.52-74.961-129.77-74.961-82.707 0-150 67.293-150 150 0 6.0195 2.8867 11.68 7.7812 15.207 3.2422 2.3438 7.0859 3.543 10.969 3.543 1.9883 0 3.9922-0.32031 5.9258-0.95703z"/>
 <path d="m249.19 530.44c-3.375 3.5625-5.4375 8.4375-5.4375 13.312s2.0625 9.75 5.4375 13.312c3.5625 3.375 8.4375 5.4375 13.312 5.4375s9.75-2.0625 13.312-5.4375c3.375-3.5625 5.4375-8.4375 5.4375-13.312s-2.0625-9.75-5.4375-13.312c-6.9375-6.9375-19.5-6.9375-26.625 0z"/>
 <path d="m924.19 530.44c-3.375 3.5625-5.4375 8.4375-5.4375 13.312 0 5.0625 2.0625 9.75 5.4375 13.312 3.5625 3.375 8.4375 5.4375 13.312 5.4375s9.75-2.0625 13.312-5.4375c3.375-3.5625 5.4375-8.4375 5.4375-13.312s-2.0625-9.75-5.4375-13.312c-6.9375-6.9375-19.5-6.9375-26.625 0z"/>
 <path d="m717.94 230.44c-3.375 3.5625-5.4375 8.4375-5.4375 13.312s2.0625 9.75 5.4375 13.312c3.5625 3.375 8.4375 5.4375 13.312 5.4375s9.75-2.0625 13.312-5.4375c3.375-3.5625 5.4375-8.4375 5.4375-13.312s-2.0625-9.75-5.4375-13.312c-6.9375-6.9375-19.5-6.9375-26.625 0z"/>
 <path d="m1036.7 699.19c-3.375 3.5625-5.4375 8.4375-5.4375 13.312s2.0625 9.75 5.4375 13.312c3.5625 3.375 8.4375 5.4375 13.312 5.4375s9.75-2.0625 13.312-5.4375c3.375-3.5625 5.4375-8.4375 5.4375-13.312s-2.0625-9.75-5.4375-13.312c-7.125-6.9375-19.5-6.9375-26.625 0z"/>
</svg>
			</SvgIcon>
		);
	};