import { Typography } from "@mui/material";
import { Anchor, Box, Text } from "grommet";
import { Fragment } from "react";
import { TextFieldElement } from "react-hook-form-mui";

export const ConfirmationCodeElement: React.FC<{
	username: string;
	wasCodeSent: boolean;
	canResendCode?: boolean;
	onResendCode?: () => void;
}> = (props) => {
	return (
		<Fragment>
			{props.wasCodeSent && (
				<Fragment>
					<TextFieldElement
						name="code"
						label="Confirmation Code"
						helperText="6 digit verification code (ex. 123456)"
						validation={{
							validate: (value) => {
								return /^[0-9]{6}$/.test(value) || "Please enter a valid 6 digit code.";
							}
						}}
						style={{ display: props.wasCodeSent ? undefined : "none" }}
					/>
					{props.username && (
						<Box pad="small">
							<span>
								<Typography>
									We just sent a 6 digit code to <Text weight="bold">{props.username.toLowerCase()}</Text>. Enter it above to continue.
								</Typography>
							</span>
						</Box>
					)}
					{props.canResendCode && (
						<Box pad="small">
							<Anchor
								label="Need another code?"
								onClick={props.onResendCode}
								style={{ fontSize: "18px" }}
							/>
						</Box>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};