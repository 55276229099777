import { gql } from "../../../__generated__";

export const FindReferenceMedia = gql(/* GraphQL */ `
	query FindReferenceMedia($referenceId: String!) {
		FindReferenceMedia(referenceId: $referenceId) {
			id
			name
			contentUrl
			contentType
			createdAt
		}
	}
`);