import { gql } from "../../../__generated__";

export const FindAcceptingOutlets = gql(/* GraphQL */`
	query FindAcceptingOutlets(
		$productId: String!
	) {
		FindAcceptingOutlets(
			productId: $productId
		) {
			...OutletFragment
			address {
				...AddressFragment
			}
		}
	}
`);