import { Button, Typography } from "@mui/material";
import { Box, Heading } from "grommet";
import React from "react";

export const DashboardActionCard: React.FC<{
	title: string;
	button?: React.ReactNode;
	buttonTitle: string;
	description: string;
	onButtonClick: () => void;
	chip?: React.ReactNode;
}> = ({ chip, title, buttonTitle, description, onButtonClick, button }) => {
	return (
		<Box
			round
			gap="small"
			width="100%"
			pad="medium"
			background="light-1"
			style={{ minHeight: "200px", minWidth: "250px" }}
		>
			<Box direction="row" justify="between" width="100%">
				<Box justify="center">
					<Heading level="4" margin="none">
						{title}
					</Heading>
				</Box>
				<Box justify="center">
					{!!chip && chip}
				</Box>
			</Box>
			<Typography variant="caption">
				{description}
			</Typography>
			<Box align="end" justify="end" flex>
				{button ||
					(<Button
						fullWidth
						size="small"
						color="primary"
						variant="contained"
						onClick={onButtonClick}
					>
						{buttonTitle}
					</Button>
					)}
			</Box>
		</Box>
	);
};