import { gql } from "../../../__generated__";

export const FindHaulerPendingQuoteRequests = gql(/* GraphQL */ `
	query FindHaulerPendingQuoteRequests($haulerId: String!) {
		FindHaulerPendingQuoteRequests(haulerId: $haulerId) {
			id
			createdAt
			order {
				id
				market { id code }
				productCount
				products {
					id
					type { id name }
					size { id name }
				}
				submittedAt
				preferredDate
				preferredWindow { from to label }
				user { firstName lastName }
			}
		}
	}
`);
