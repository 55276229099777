import React from "react";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { States } from "../../../helpers";
import { Box } from "grommet";

export const StateZipElement: React.FC = () => {
	return (
		<Box direction="row" gap="medium">
			<SelectElement
				fullWidth
				required
				name="state"
				label="State"
				options={States.getAbbreviations().map((state) => ({
					id: state,
					label: state
				}))}
			/>
			<TextFieldElement
				fullWidth
				required
				name="zipCode"
				label="Zip Code"
			/>
		</Box>
	);
};
