import React, { Fragment, useEffect, useMemo } from "react";
import { DialogWithActivatorButton, HeadingWithIcon, Pagination, TabController, ViewContainer, VisuallyHiddenInput } from "../../../../components";
import { Alert, Button, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Anchor, Box, Heading } from "grommet";
import { Receipt, WarningAmber } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { useInstance, useWindowDimensions } from "../../../../hooks";
import { CreateWasteTicket, FetchWasteTicket, FindPendingWasteTickets, FindProcessedWasteTickets, FindWasteTickets } from "../../../../graphql";
import { AutocompleteElement, DatePickerElement, FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import moment, { Moment } from "moment";
import { useBuildings } from "../../reporting/hooks";
import { LocalizationProvider } from "../../../../provider";
import { LoadingButton } from "@mui/lab";
import { fileToBase64, noop } from "../../../../helpers";
import { CreateMediaDto, ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { useSnackbar } from "notistack";
import { useToggleForceClose } from "../../components";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

export const UploadedWasteTicketsTab: React.FC = () => {
	const { wasteTickets, loading } = useProcessedWasteTickets();

	return (
		<WasteTicketTabContainer
			tickets={wasteTickets}
			loading={loading}
		/>
	);
};

export const WasteTicketTabContainer: React.FC<{
	loading: boolean;
	tickets: ReturnType<typeof useProcessedWasteTickets>[ "wasteTickets" ];
}> = ({ tickets, loading }) => {
	const { size, height } = useWindowDimensions();
	const pageSize = useMemo(() => {
		if(size === "small") {
			if(height < 750) return 2;
			if(height < 850) return 3;
			return 4;
		}

		if(height < 750) return 3;
		if(height < 800) return 4;
		return 5;
	}, [ height, size ]);

	function resolvePrimaryText(ticket: { processedAt?: string | null, createdAt: string; }) {
		if(!ticket.processedAt) {
			return `Started processing on ${moment(ticket.createdAt).format("dddd MMMM Do")} at ${moment(ticket.createdAt).format("hh:mm a")}`;
		}
		else {
			return `Processed on ${moment(ticket.processedAt).format("dddd MMMM Do")}`;
		}
	}

	return (
		<Fragment>
			{loading && (
				<Box flex align="center" justify="center">
					<CircularProgress
						size={48}
					/>
				</Box>
			)}
			{tickets.length === 0 && !loading && (
				<Box height="small" align="center" justify="center">
					<Typography variant="body2">
						nothing here yet
					</Typography>
				</Box>
			)}
			{tickets.length > 0 && !loading && (
				<Pagination pageSize={pageSize}>
					{tickets.map((ticket) => (
						<WasteTicketSummaryDialog
							key={ticket.id}
							ticketId={ticket.id}
							activatorButton={(
								<ListItemButton
									divider
									onClick={noop}
									key={ticket.id}
								>
									<ListItemText>
										<Box>
											<Typography fontWeight="bold">
												{resolvePrimaryText(ticket)}
											</Typography>
											<Typography variant="caption">
												Click for more details
											</Typography>
										</Box>
									</ListItemText>
								</ListItemButton>
							)}
						/>
					))}
				</Pagination>
			)}
		</Fragment>
	);
};

export const PendingWasteTicketsTab: React.FC = () => {
	const { wasteTickets, loading } = usePendingWasteTickets();

	return (
		<WasteTicketTabContainer
			tickets={wasteTickets}
			loading={loading}
		/>
	);
};

export function useWasteTicket(ticketId: string) {
	const { data, loading } = useQuery(FetchWasteTicket, {
		skip: !ticketId,
		variables: {
			wasteTicketId: ticketId
		}
	});

	return {
		wasteTicket: data?.FetchWasteTicket || null,
		loading
	};
}

export const WasteTicketSummaryDialog: React.FC<{
	ticketId: string;
	activatorButton: React.ReactNode;
}> = ({ ticketId, activatorButton }) => {
	const dispatch = useAppDispatch();
	const { forceClose, toggleForceClose } = useToggleForceClose();
	const { wasteTicket, loading } = useWasteTicket(ticketId);

	const formContext = useForm();

	useEffect(() => {
		formContext.reset({
			partner: wasteTicket?.partner?.name || "",
			ticketNumber: wasteTicket?.ticketNumber || "",
			date: wasteTicket?.date ? moment(wasteTicket.date).format("MM/DD/YYYY") : null,
			processedAt: wasteTicket?.processedAt ? moment(wasteTicket.processedAt).format("MM/DD/YYYY hh:mm A") : null
		});
	}, [ formContext, wasteTicket ]);

	const materials = useMemo(() => {
		return wasteTicket?.lineItems.reduce((acc, lineItem) => {
			const existing = acc.find(({ name }) => name === lineItem.material.name);
			if(existing) {
				existing.totalWeightDiverted += lineItem.totalWeightDivertedPounds;
				existing.totalWeightGenerated += lineItem.totalWeightGeneratedPounds;
				acc[ acc.indexOf(existing) ] = existing;
			}

			acc.push({
				name: lineItem.material.name,
				totalWeightDiverted: lineItem.totalWeightDivertedPounds,
				totalWeightGenerated: lineItem.totalWeightGeneratedPounds
			});

			return acc;
		}, [] as { name: string; totalWeightDiverted: number, totalWeightGenerated: number; }[]);
	}, [ wasteTicket ]);

	return (
		<DialogWithActivatorButton
			forceClose={forceClose}
			title="Waste Ticket Summary"
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="warning"
						variant="outlined"
						onClick={toggleForceClose}
					>
						Close
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={(() => {
							dispatch(push(`/admin/reporting/waste-tickets/${ticketId}`));
						})}
						style={{ visibility: "hidden" }}
					>
						View More
					</Button>
				</Box>
			)}
		>
			<Fragment>
				{loading && (
					<Box flex round align="center" justify="center" style={{ minHeight: "300px" }}>
						<CircularProgress
							color="primary"
							size={72}
						/>
					</Box>
				)}
				{!loading && (
					<FormContainer formContext={formContext}>
						<Box gap="medium">
							{!wasteTicket?.processed && (
								<Box direction="row" gap="small">
									<Alert
										severity="warning"
										icon={(
											<Box justify="center">
												<WarningAmber color="warning" fontSize="small" />
											</Box>
										)}
									>
										This document is currently being processed. More details will be added once processing is complete.
									</Alert>
								</Box>
							)}
							<TextFieldElement
								name="date"
								label="Document Date"
								InputProps={{
									readOnly: true
								}}
							/>
							{wasteTicket?.processedAt && (
								<TextFieldElement
									name="processedAt"
									label="Processed At"
									InputProps={{
										readOnly: true
									}}
								/>
							)}
							{wasteTicket?.partner && (
								<TextFieldElement
									name="partner"
									label="Location"
									InputProps={{
										readOnly: true
									}}
								/>
							)}
							<TextFieldElement
								name="ticketNumber"
								label="Document Number"
								InputProps={{
									readOnly: true
								}}
							/>
							<Box gap="small">
								<Typography
									variant="body2"
									fontSize="large"
									fontWeight="bold"
								>
									Uploaded Document(s)
								</Typography>
								<Divider />
								<List disablePadding>
									{wasteTicket?.media.map(({ id, name, contentUrl }, index) => (
										<ListItem key={id}>
											<ListItemIcon
												style={{
													minWidth: "20px"
												}}
											>
												<Typography fontWeight="bold">
													{index + 1}
												</Typography>
											</ListItemIcon>
											<ListItemText>
												<Anchor
													label={name}
													onClick={() => {
														window.open(contentUrl, "_blank");
													}}
												/>
											</ListItemText>
										</ListItem>
									))}
								</List>
							</Box>
							<Box gap="small">
								<Typography
									variant="body2"
									fontSize="large"
									fontWeight="bold"
								>
									Material Composition
								</Typography>
								<Divider />
								{materials && materials.length > 0 && (
									<List>
										{materials.map(material => (
											<ListItemButton
												key={material.name}
												divider
											>
												<ListItemText>
													<Typography fontWeight="bold">
														{material.name}
													</Typography>
													<Typography variant="body2">
														Generated: {material.totalWeightGenerated} lbs, Diverted: {material.totalWeightDiverted} lbs
													</Typography>
												</ListItemText>
												<ListItemSecondaryAction>
													<Typography fontWeight="bold" fontSize="large">
														{((material.totalWeightDiverted / material.totalWeightGenerated) * 100).toFixed(2)}% diverted
													</Typography>
												</ListItemSecondaryAction>
											</ListItemButton>
										))}
									</List>
								)}
							</Box>
							{!wasteTicket?.processed && (
								<Alert
									severity="warning"
									icon={(
										<Box justify="center">
											<WarningAmber color="warning" fontSize="small" />
										</Box>
									)}
								>
									Will be available once processing is complete
								</Alert>
							)}
						</Box>
					</FormContainer>
				)}
			</Fragment>
		</DialogWithActivatorButton>
	);
};

export function usePendingWasteTickets() {
	const { instance } = useInstance();

	const { data, loading } = useQuery(FindPendingWasteTickets, {
		skip: !instance,
		variables: {
			instanceId: instance?.id || ""
		}
	});

	return {
		wasteTickets: data?.FindWasteTickets || [],
		loading
	};
}

export function useProcessedWasteTickets() {
	const { instance } = useInstance();

	const { data, loading } = useQuery(FindProcessedWasteTickets, {
		skip: !instance,
		variables: {
			instanceId: instance?.id || ""
		}
	});

	return {
		wasteTickets: data?.FindWasteTickets || [],
		loading
	};
}

interface CreateWasteTicketDialogFormValues {
	date: Moment;
	partner: { id: string; label: string; } | null;
	media: (CreateMediaDto & { file: File; })[];
}

export const CreateWasteTicketDialog: React.FC<{
	activatorButton: React.ReactNode;
}> = ({ activatorButton }) => {
	const snack = useSnackbar();
	const { instance } = useInstance();
	const ref = React.useRef<HTMLInputElement>(null);
	const [ isUploading, setIsUploading ] = React.useState(false);

	const formContext = useForm<CreateWasteTicketDialogFormValues>({
		defaultValues: {
			date: moment(),
			partner: null,
			media: []
		}
	});

	const [
		create,
		{ loading }
	] = useMutation(CreateWasteTicket, {
		refetchQueries: [ FindPendingWasteTickets, FindProcessedWasteTickets, FindWasteTickets ]
	});

	const { buildings } = useBuildings();
	useEffect(() => {
		if(buildings.length === 1) {
			formContext.setValue("partner", { id: buildings[ 0 ].id, label: buildings[ 0 ].name });
		}
	}, [ buildings, formContext ]);

	function handleUpload(file: File) {
		setIsUploading(true);
		fileToBase64(file).then((base64) => {
			const existing = formContext.getValues("media");
			formContext.setValue("media", [
				...existing,
				{
					file,
					name: file.name,
					content: base64,
					contentType: file.type,
					context: ProductMediaContext.Default
				}
			]);
			setIsUploading(false);
		});
	}

	const { media } = formContext.watch();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	function handleSubmit() {
		if(media.length === 0) {
			snack.enqueueSnackbar("Please upload at least one document", {
				variant: "error"
			});
			return;
		}

		const { date, partner } = formContext.getValues();
		if(!date || !partner || !instance) return;

		create({
			variables: {
				media: media.map(({ content, contentType, name }) => ({
					content,
					contentType,
					name,
					context: ProductMediaContext.Default
				})),
				ticketNumber: "",
				date: date.hours(12).toDate(),
				partnerId: partner.id,
				instanceId: instance.id,
			}
		}).then(() => {
			snack.enqueueSnackbar("Successfully uploaded document(s)", {
				variant: "success"
			});
			toggleForceClose();
		}).catch(err => {
			console.error("failed to create waste ticket", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again later", {
				variant: "error"
			});
		});
	}

	return (
		<DialogWithActivatorButton
			title="Upload"
			forceClose={forceClose}
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="warning"
						variant="outlined"
						onClick={toggleForceClose}
					>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={loading}
						disabled={loading || isUploading}
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Upload
					</LoadingButton>
				</Box>
			)}
		>
			<LocalizationProvider>
				<FormContainer formContext={formContext}>
					<Box gap="medium">
						<AutocompleteElement
							required
							name="partner"
							label="Location"
							options={
								Array.from(buildings).sort((a, b) => a.name.localeCompare(b.name)).map((building) => ({
									id: building.id,
									label: building.name
								}))
							}
						/>
						<DatePickerElement
							name="date"
							required
							label="Document Date"
						/>
						<LoadingButton
							fullWidth
							variant="contained"
							loading={isUploading}
							onClick={() => ref.current?.click()}
						>
							Upload Document(s)
						</LoadingButton>
						<VisuallyHiddenInput
							type="file"
							ref={ref}
							onChange={(e) => {
								if(e.target.files) {
									for(const file of e.target.files) {
										handleUpload(file);
									}
								}
							}}
						/>
					</Box>
					{media.length > 0 && (
						<Box gap="small">
							<Heading margin="none" level="4" color="black">
								Uploaded Documents
							</Heading>
							<Box gap="small">
								{media.map(({ name, file }) => (
									<Anchor
										key={name}
										label={name}
										onClick={(() => {
											const url = URL.createObjectURL(file);
											window.open(url, "_blank");
										})}
									/>
								))}
							</Box>
						</Box>
					)}
				</FormContainer>
			</LocalizationProvider>
		</DialogWithActivatorButton>
	);
};

export const WasteTicketView: React.FC = () => {
	const { size } = useWindowDimensions();

	return (
		<ViewContainer>
			<Box gap="small" flex>
				<Box gap={size === "small" ? "medium" : "small"}>
					<Box direction="row" justify="between" align="center">
						<HeadingWithIcon
							text="Upload Documents"
							props={{
								level: "3",
								margin: "none"
							}}
							icon={<Receipt color="primary" />}
						/>
						<CreateWasteTicketDialog
							activatorButton={(
								<Button
									color="primary"
									variant="contained"
									startIcon={<Receipt />}
								>
									Upload
								</Button>
							)}
						/>
					</Box>
					<Typography variant="body2">
						Upload waste invoices or receipts you receive and our system will automatically process and upload the data to your waste dashboard
					</Typography>
				</Box>
				<TabController
					defaultValue="uploaded"
					tabs={[
						{
							label: "Processed",
							value: "uploaded",
							component: <UploadedWasteTicketsTab />
						},
						{
							label: "Pending",
							value: "pending",
							component: <PendingWasteTicketsTab />
						}
					]}
				/>
			</Box>
		</ViewContainer>
	);
};