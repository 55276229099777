import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { history, store } from "./store";
import { HistoryRouter, LayoutController } from "./components";
import { createRoot } from "react-dom/client";
import { AlertProvider, DefaultThemeProvider, DialogProvider } from "./provider";
import { AuthProvider } from "./provider/auth";
import { InstanceProvider } from "./provider/instance";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { MainRouter } from "./router";
import { ApolloSetupProvider } from "./provider/apollo";
import TagManager from "react-gtm-module";
import { HotkeyProvider } from "./provider/hotkey";

TagManager.initialize({
	gtmId: "GTM-WKFXR66F"
});

if(!window.location.hostname.includes("localhost")) {
	import("./reporting/datadog").catch(err => {
		console.error("Failed to load datadog", err);
	});
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<ApolloSetupProvider>
				<Provider store={store}>
					<AlertProvider>
						<HistoryRouter history={history}>
							<InstanceProvider>
								<DefaultThemeProvider>
									<AuthProvider>
										<HotkeyProvider>
											<DialogProvider>
												<LayoutController>
													<MainRouter />
												</LayoutController>
											</DialogProvider>
										</HotkeyProvider>
									</AuthProvider>
								</DefaultThemeProvider>
							</InstanceProvider>
						</HistoryRouter>
					</AlertProvider>
				</Provider>
			</ApolloSetupProvider>
		</ErrorBoundary>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();