import { gql } from "../../../__generated__";

export const RejectHaulerQuoteRequest = gql(/* GraphQL */ `
	mutation RejectHaulerQuoteRequest($requestId: String!) {
		RejectHaulerQuoteRequest(requestId: $requestId) { 
			id
		}
	}
`);

export const CreateHaulerQuote = gql(/* GraphQL */ `
	mutation CreateHaulerQuote(
		$requestId: String!, 
		$amount: Float!
		$pickupOptions: [ PickupDateOptionsInput! ]!
	) {
		CreateHaulerQuote(
			requestId: 
			$requestId, 
			amount: $amount
			pickupOptions: $pickupOptions
		) {
			id
		}
	}
`);

export const UpdateHaulerQuote = gql(/* GraphQL */ `
	mutation UpdateHaulerQuote(
		$quoteId: String!, 
		$amount: Float!, 
		$reason: String!
		$pickupOptions: [ PickupDateOptionsInput! ]!
	) {
		UpdateHaulerQuote(
			quoteId: $quoteId, 
			amount: $amount, 
			reason: $reason,
			pickupOptions: $pickupOptions
		) {
			id
		}
	}
`);

//export const CreateHaulerPickupRequests = gql(/* GraphQL */ `
//	mutation CreateHaulerPickupRequests($pickupId: String!) {
//		CreateHaulerPickupRequests(pickupId: $pickupId) {
//			id
//		}
//	}
//`);

export const CreateManyHaulerQuoteRequests = gql(/* GraphQL */ `
	mutation CreateManyHaulerQuoteRequests($orderId: String!, $haulerIds: [ String! ]!) {
		CreateManyHaulerQuoteRequests(orderId: $orderId, haulerIds: $haulerIds) {
			errors {
				orderId
				haulerId
			}
			success {
				orderId
				haulerId
				requestId
			}
		}
	}
`);

export const CreateManyHaulerPickupRequests = gql(/* GraphQL */ `
	mutation CreateManyHaulerPickupRequests(
		$pickupId: String!, 
		$haulerIds: [ String! ]!
		$amountOffered: Float!
	) {
		CreateManyHaulerPickupRequests(
			pickupId: $pickupId, 
			haulerIds: $haulerIds,
			amountOffered: $amountOffered
		) {
			errors {
				pickupId
				haulerId
			}
			success {
				pickupId
				haulerId
				requestId
			}
		}
	}
`);

export const AcceptHaulerPickupRequest = gql(/* GraphQL */ `
	mutation AcceptHaulerPickupRequest($requestId: String!) {
		AcceptHaulerPickupRequest(requestId: $requestId) {
			id
		}
	}
`);

export const RejectHaulerPickupRequest = gql(/* GraphQL */ `
	mutation RejectHaulerPickupRequest($requestId: String!) {
		RejectHaulerPickupRequest(requestId: $requestId) {
			id
		}
	}
`);

export const UpdateHauler = gql(/* GraphQL */ `
	mutation UpdateHauler(
		$haulerId: String!
		$permittedDays: [ DayOfWeek! ]
		$permittedWindows: [ PickupWindowInput! ]
	) {
		UpdateHauler(
			haulerId: $haulerId
			permittedDays: $permittedDays
			permittedWindows: $permittedWindows
		) {
			id
		}
	}
`);

export const ReportProductException = gql(/* GraphQL */ `
	mutation ReportProductException(
		$haulerId: String!
		$pickupId: String!
		$productId: String!
		$stopId: String!
		$reason: HaulerProductExceptionType!
	) {
		ReportProductException(
			haulerId: $haulerId
			pickupId: $pickupId
			productId: $productId
			stopId: $stopId
			reason: $reason
		)
	}
`);

export const ReportProductDelayedException = gql(/* GraphQL */ `
	mutation ReportProductDelayedException(
		$haulerId: String!
		$pickupId: String!
		$productId: String!
		$stopId: String!
		$delayedUntil: DateTime!
	) {
		ReportProductDelayedException(
			haulerId: $haulerId
			pickupId: $pickupId
			productId: $productId
			stopId: $stopId
			delayedUntil: $delayedUntil
			reason: DESTINATION_DELAYED
		)
	}
`);

export const ReportProductDivertedException = gql(/* GraphQL */ `
	mutation ReportProductDivertedException(
		$haulerId: String!
		$pickupId: String!
		$productId: String!
		$stopId: String!
		$outletId: String!
	) {
		ReportProductDivertedException(
			haulerId: $haulerId
			pickupId: $pickupId
			productId: $productId
			stopId: $stopId
			outletId: $outletId
			reason: DESTINATION_DIVERTED
		)
	}
`);

export const ReportProductIncorrectQuantityException = gql(/* GraphQL */ `
	mutation ReportProductIncorrectQuantityException(
		$haulerId: String!
		$pickupId: String!
		$productId: String!
		$stopId: String!
		$updatedQuantity: Float!
	) {
		ReportProductIncorrectQuantityException(
			haulerId: $haulerId
			pickupId: $pickupId
			productId: $productId
			stopId: $stopId
			updatedQuantity: $updatedQuantity
			reason: ORIGIN_INCORRECT_QUANTITY
		)
	}
`);

export const ReportProductIncorrectException = gql(/* GraphQL */ `
	mutation ReportProductIncorrectException(
		$haulerId: String!
		$pickupId: String!
		$productId: String!
		$stopId: String!
		$typeId: String!
		$sizeId: String
	) {
		ReportProductIncorrectException(
			haulerId: $haulerId
			pickupId: $pickupId
			productId: $productId
			stopId: $stopId
			typeId: $typeId
			sizeId: $sizeId
			reason: ORIGIN_INCORRECT_PRODUCT
		)
	}
`);