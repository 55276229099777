import { gql } from "@apollo/client";


export const WasteContainerInspection_ContainerFragment = gql`
	fragment WasteContainerInspection_ContainerFragment on WasteContainerInspection {
		container {
			...WasteContainer_HeaderFragment
		}
		assignment {
			...WasteContainerAssignment_HeaderFragment
			partner { id name }
		}
	}
`;
