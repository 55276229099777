import { gql } from "../../../__generated__";

export const FindAdminOrders = gql(/* GraphQL */`
	query FindAdminOrders(
		$status: iStringFilters
		$submittedAt: iDateFilters
		$scheduledAt: iDateFilters
		$cancelledAt: iDateFilters
		$completedAt: iDateFilters
	) {
		FindOrders(
			status: $status
			submittedAt: $submittedAt
			scheduledAt: $scheduledAt
			cancelledAt: $cancelledAt
			completedAt: $completedAt
		) {
			...Order_ExpandedFragment
		}
	}
`);