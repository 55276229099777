import { SvgIcon, SvgIconProps } from "@mui/material";

	export const EndTable: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1038.8 39.098h-877.68c-14.496 0-26.25 11.754-26.25 26.25v130.77c0 14.496 11.754 26.25 26.25 26.25h47.363v912.29c0 14.496 11.754 26.25 26.25 26.25h120.91c14.496 0 26.25-11.754 26.25-26.25v-95.961h436.13v95.961c0 14.496 11.754 26.25 26.25 26.25h120.91c14.496 0 26.25-11.754 26.25-26.25v-912.29h47.363c14.496 0 26.25-11.754 26.25-26.25v-130.77c0.007813-14.496-11.746-26.25-26.242-26.25zm-709.41 1069.3h-68.406v-448.63h68.406zm52.5-122.21v-326.42h436.13v326.41zm557.05 122.21h-68.406v-448.63h68.406zm0-501.13h-677.95v-384.91h677.95zm73.613-437.41h-825.18v-78.27h825.18z"/>
  <path d="m346.17 540.37h507.65c14.496 0 26.25-11.754 26.25-26.25v-198.59c0-14.496-11.754-26.25-26.25-26.25h-507.65c-14.496 0-26.25 11.754-26.25 26.25v198.59c0 14.496 11.75 26.25 26.25 26.25zm26.25-198.59h455.15v146.09h-455.15z"/>
  <path d="m600 392.01c-12.586 0-22.816 10.23-22.816 22.816s10.23 22.816 22.816 22.816c12.578 0 22.809-10.23 22.809-22.816 0-12.59-10.23-22.816-22.809-22.816z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};