import React, { useEffect } from "react";
import { ReactNode, useState } from "react";

export const PaginationContext = React.createContext({
	page: 1,
	pageSize: 10,
	setPage: (page: number) => { /* */ },
	setPageSize: (pageSize: number) => { /** */ }
});

export const PaginationProvider: React.FC<{ children: ReactNode; pageSize: number; }> = (props) => {
	const [ state, setState ] = useState({
		page: 1,
		pageSize: props.pageSize || 10
	});

	useEffect(() => {
		setState({
			...state,
			pageSize: props.pageSize
		});
	}, [ props.pageSize ]);

	return (
		<PaginationContext.Provider value={{
			...state,
			setPage: (page: number) => setState({ ...state, page }),
			setPageSize: (pageSize: number) => setState({ ...state, pageSize })
		}}>
			{props.children}
		</PaginationContext.Provider>
	);
};