import { gql } from "../../../__generated__";

export const FetchAdminPickup = gql(/* GraphQL */ `
	query FetchAdminPickup(
		$pickupId: String!
	) {
		FetchPickup(
			pickupId: $pickupId
		) {
			...Pickup_CombinedFragment
		}
	}
`);