import { Typography } from "@mui/material";
import { useWindowDimensions } from "../../../hooks";


export const ItemNameElement: React.FC<{ name: string; }> = ({ name }) => {
	const { size } = useWindowDimensions();
	return (
		<Typography
			fontWeight="bold"
			className="text-max-2-lines"
			maxWidth={size === "small" ? "170px" : undefined}
		>
			{name}
		</Typography>
	);
};
