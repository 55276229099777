import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Cabinet: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m461.76 530.86c-27.984 0-53.156 16.875-63.844 42.703-10.734 25.828-4.8281 55.547 14.953 75.328 19.781 19.734 49.5 25.688 75.328 14.953 25.828-10.688 42.656-35.906 42.656-63.844-0.046875-38.156-30.938-69.094-69.094-69.141zm0 92.156v0.046875c-9.3281 0-17.719-5.625-21.281-14.25s-1.5938-18.516 4.9688-25.125c6.6094-6.5625 16.5-8.5312 25.125-4.9688s14.203 11.953 14.203 21.281c0 12.703-10.312 23.016-23.016 23.062z"/>
  <path d="m738.24 669.14c27.984 0 53.156-16.875 63.844-42.703 10.734-25.828 4.8281-55.547-14.953-75.328-19.781-19.734-49.5-25.688-75.328-14.953-25.828 10.688-42.656 35.906-42.656 63.844 0.046875 38.156 30.938 69.094 69.094 69.141zm0-92.156v-0.046875c9.3281 0 17.719 5.625 21.281 14.25s1.5938 18.516-4.9688 25.125c-6.6094 6.5625-16.5 8.5312-25.125 4.9688s-14.203-11.953-14.203-21.281c0-12.703 10.312-23.016 23.016-23.062z"/>
  <path d="m1152.9 70.078h-1105.9c-12.75 0-23.062 10.312-23.062 23.062v1013.7c0 12.75 10.312 23.062 23.062 23.062h1105.9c6.1406 0 12-2.4375 16.312-6.75s6.75-10.172 6.75-16.312v-1013.7c0-6.1406-2.4375-12-6.75-16.312s-10.172-6.75-16.312-6.75zm-1082.9 46.078h506.86v967.69h-506.86zm1059.8 967.69h-506.86v-967.69h506.86z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};