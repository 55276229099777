import { gql } from "@apollo/client";

export const Quote_HaulerFragment = gql`
	fragment Quote_HaulerFragment on Quote {
		...Quote_HeaderFragment
		...Quote_AmountFragment
		...Quote_ChangeFragment
		order {
			id
			market { id code }
			products {
				id
				type { id name}
				size { id name}
			}
			productCount
			preferredDate
			preferredWindow { from to label }
			user { firstName lastName }
		}
	}
`;