import { SvgIcon, SvgIconProps } from "@mui/material";

export const Door: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path d="m876 140.4v918h-552v-918h552m21.602-20.398h-595.2v960h595.2z" />
					<path d="m804 213.6v771.6l-408-0.003906v-771.6h408m20.398-20.402h-448.8v813.6h448.8z" />
					<path d="m730.8 291.6v135.6l-261.6-0.003906v-135.6h261.6m20.398-20.402h-302.4v177.6h302.4z" />
					<path d="m730.8 531.6v135.6l-261.6-0.003906v-135.6h261.6m20.398-20.402h-302.4v177.6h302.4z" />
					<path d="m730.8 771.6v135.6l-261.6-0.003906v-135.6h261.6m20.398-20.402h-302.4v177.6h302.4z" />
					<path d="m422.4 618c-9.6016 0-18-8.3984-18-18s8.3984-18 18-18c9.6016 0 18 8.3984 18 18s-8.3984 18-18 18zm0-24c-3.6016 0-6 2.3984-6 6s2.3984 6 6 6c3.6016 0 6-2.3984 6-6s-2.3984-6-6-6z" />
				</g>
			</svg>
		</SvgIcon>
	);
};