import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Mirror: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m222 1080h756v-960h-756zm68.391-39.609 56.391-56.391h505.22l56.391 56.391zm68.391-96v-688.78h482.39v690h-482.39zm579.61 69.609-57.609-57.609v-711.61l57.609-57.609zm-27.609-854.39-56.391 56.391h-508.78l-56.391-56.391zm-649.18 27.609 57.609 57.609v711.61l-57.609 57.609z" />
		<path
			d="m507.61 424.78c-22.781 0-42-24-42-54 0-3.6094-3.6094-7.2188-7.2188-7.2188s-7.2188 3.6094-7.2188 7.2188c0 30-19.219 54-42 54-3.6094 0-7.2188 3.6094-7.2188 7.2188s3.6094 7.2188 7.2188 7.2188c22.781 0 42 24 42 54 0 3.6094 3.6094 7.2188 7.2188 7.2188s7.2188-3.6094 7.2188-7.2188c0-30 19.219-54 42-54 3.6094 0 7.2188-3.6094 7.2188-7.2188-0.046875-3.6094-3.6094-7.2188-7.2188-7.2188zm-50.391 36c-6-12-14.391-21.609-24-27.609 10.781-6 19.219-15.609 24-27.609 6 12 14.391 21.609 24 27.609-9.6094 4.8281-18 15.609-24 27.609z" />
		<path
			d="m604.78 350.39c-19.219 0-34.781-20.391-34.781-44.391 0-3.6094-3.6094-7.2188-7.2188-7.2188s-7.2188 3.6094-7.2188 7.2188c0 24-15.609 44.391-34.781 44.391-3.6094 0-7.2188 3.6094-7.2188 7.2188s3.6094 7.2188 7.2188 7.2188c19.219 0 34.781 20.391 34.781 44.391 0 3.6094 3.6094 7.2188 7.2188 7.2188s7.2188-3.6094 7.2188-7.2188c0-24 15.609-44.391 34.781-44.391 3.6094 0 7.2188-3.6094 7.2188-7.2188s-3.6094-7.2188-7.2188-7.2188zm-42 28.828c-4.7812-8.3906-10.781-15.609-19.219-21.609 7.2188-4.7812 14.391-12 19.219-21.609 4.7812 8.3906 10.781 15.609 19.219 21.609-8.3906 6-15.609 13.172-19.219 21.609z" />
		<path
			d="m604.78 486c-19.219 0-34.781-20.391-34.781-44.391 0-3.6094-3.6094-7.2188-7.2188-7.2188s-7.2188 3.6094-7.2188 7.2188c0 24-15.609 44.391-34.781 44.391-3.6094 0-7.2188 3.6094-7.2188 7.2188s3.6094 7.2188 7.2188 7.2188c19.219 0 34.781 20.391 34.781 44.391 0 3.6094 3.6094 7.2188 7.2188 7.2188s7.2188-3.6094 7.2188-7.2188c0-24 15.609-44.391 34.781-44.391 3.6094 0 7.2188-3.6094 7.2188-7.2188s-3.6094-7.2188-7.2188-7.2188zm-42 28.781c-4.7812-8.3906-10.781-15.609-19.219-21.609 7.2188-4.7812 14.391-12 19.219-21.609 4.7812 8.3906 10.781 15.609 19.219 21.609-8.3906 4.8281-15.609 12.047-19.219 21.609z" />
	</g>
</svg>
			</SvgIcon>
		);
	};