import { useLazyQuery, useMutation } from "@apollo/client";
import { AddProductToCart, FetchCartProducts, RemoveProductFromCart } from "../../../graphql";
import { useCallback, useEffect } from "react";
import { useUser } from "../../../auth";
import { useSnackbar } from "notistack";
import { Anchor } from "grommet";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Typography } from "@mui/material";
import { push } from "redux-first-history";
import { selectCartQuantities, updateCartQuantity } from "../../../store/shop";

export function useCart() {
	const { user } = useUser();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const quantities = useAppSelector(selectCartQuantities);

	const [
		fetchUserCartQuery,
		{ data: fetchUserCartData, loading: fetchUserCartLoading, error: fetchUserCartError }
	] = useLazyQuery(FetchCartProducts);

	useEffect(() => {
		if(user) {
			fetchUserCartQuery({ variables: { userId: user.id } }).catch(err => {
				console.error("Error while fetching user cart: ", err);
			});
		}
	}, [ user, fetchUserCartQuery ]);

	const [
		addProductToCartMutation,
		{ data: addProductToCartData, loading: addProductToCartLoading, error: addProductToCartError }
	] = useMutation(AddProductToCart, { refetchQueries: [ FetchCartProducts ] });

	useEffect(() => {
		if(addProductToCartData?.AddProductToCart?.id) {
			snack.enqueueSnackbar(
				<Typography>
					Successfully added to cart. <Anchor onClick={() => dispatch(push("/shop/checkout"))} color="white"> Checkout now?</Anchor>
				</Typography>,
				{ variant: "success" }
			);
		}
	}, [ addProductToCartData, snack ]);

	useEffect(() => {
		if(addProductToCartError) {
			snack.enqueueSnackbar(
				"We weren't able to add that product to your cart.",
				{ variant: "error" }
			);
		}
	}, [ addProductToCartError, snack ]);

	const [
		removeProductFromCartMutation,
		{ data: removeProductFromCartData, loading: removeProductFromCartLoading, error: removeProductFromCartError }
	] = useMutation(RemoveProductFromCart, { refetchQueries: [ FetchCartProducts ] });

	useEffect(() => {
		if(removeProductFromCartData?.RemoveProductFromCart?.id) {
			snack.enqueueSnackbar(
				"Product removed from cart",
				{ variant: "success" }
			);
		}
	}, [ removeProductFromCartData, snack ]);

	useEffect(() => {
		if(removeProductFromCartError) {
			snack.enqueueSnackbar(
				"We weren't able to remove that product from your cart.",
				{ variant: "error" }
			);
		}
	}, [ removeProductFromCartError, snack ]);

	const addToCart = useCallback((productId: string) => {
		return addProductToCartMutation({ variables: { userId: user?.id ?? "", productId } }).catch(err => {
			console.error(`Failed to add product [${productId}] to cart`, err);
		});
	}, [ addProductToCartMutation, user ]);

	const removeFromCart = useCallback((productId: string) => {
		return removeProductFromCartMutation({ variables: { userId: user?.id ?? "", productId } }).catch(err => {
			console.error(`Failed to remove product [${productId}] from cart`, err);
		});
	}, [ removeProductFromCartMutation, user ]);

	const isInCart = useCallback((productId: string) => {
		return fetchUserCartData?.FetchUserCartProducts?.some(product => product.id === productId) ?? false;
	}, [ fetchUserCartData ]);

	return {
		isInCart,
		addToCart,
		removeFromCart,
		isAdding: addProductToCartLoading,
		isRemoving: removeProductFromCartLoading,
		products: fetchUserCartData?.FetchUserCartProducts ?? [],
		quantities,
		updateProductQuantity: (productId: string, quantity: number): void => {
			dispatch(updateCartQuantity({ productId, quantity }));
		}
	};
}