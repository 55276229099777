import { gql } from "../../../__generated__";

export const FetchOrderRequest = gql(/* GraphQL */ `
	query FetchOrderRequest(
		$requestId: String!
	) {
		FetchOrderRequest(
			requestId: $requestId
		) {
			...OrderRequest_MediaFragment
			...OrderRequest_HeaderFragment
			...OrderRequest_SourceFragment
		}
	}
`);
