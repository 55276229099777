import { ApolloError, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Fragment, ReactNode, createRef, useState } from "react";
import React from "react";
import { DialogWithClose } from "../../../../../components";
import { Box } from "grommet";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import { OrderMilestone } from "../../../../../graphql/__generated__/graphql";
import { UpdateOrderMilestone, useGlobalAdminScopedOrder } from "../../../../../graphql";

export const GlobalAdminUpdateOrderMilestoneDialog: React.FC<{
	orderId: string;
	activationButton: ReactNode;
}> = ({ orderId, activationButton }) => {
	const snack = useSnackbar();
	const [ open, setOpen ] = useState(false);
	const buttonRef = createRef<HTMLButtonElement>();
	const { order, loading } = useGlobalAdminScopedOrder(orderId);

	const formContext = useForm({
		defaultValues: {
			milestone: order?.milestone || null
		}
	});

	const formValues = formContext.watch();

	const [
		updateMilestone, { loading: updateMilestoneLoading }
	] = useMutation(UpdateOrderMilestone);

	function handleSubmitRequest() {
		if(!formValues.milestone) {
			return;
		}

		updateMilestone({
			variables: {
				orderId,
				milestone: formValues.milestone
			}
		}).then(() => {
			setOpen(false);
		}).catch(err => {
			console.error("Failed to update milestone", err);
			if((err as ApolloError)?.message) {
				snack.enqueueSnackbar((err as ApolloError).message, {
					variant: "error"
				});
			}
		});
	}

	const options = Object.keys(OrderMilestone).map(key => ({
		id: OrderMilestone[ key as keyof typeof OrderMilestone ],
		label: key
	}));

	return (
		<Fragment>
			{React.cloneElement(activationButton as React.ReactElement, {
				ref: buttonRef,
				onClick: () => {
					setOpen(true);
				}
			})}
			{open && (
				<DialogWithClose
					title="Update Milestone"
					onClose={() => {
						setOpen(false);
					}}
					content={(
						<Box gap="medium" flex height="100%">
							<FormContainer formContext={formContext}>
								<Box>
									<SelectElement
										name="milestone"
										label="Milestone"
										options={options}
									/>
								</Box>
							</FormContainer>
						</Box>
					)}
					actions={(
						<Box direction="row" justify="between">
							<Button
								color="error"
								variant="contained"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
							<LoadingButton
								color="primary"
								variant="contained"
								onClick={handleSubmitRequest}
								disabled={updateMilestoneLoading}
								loading={updateMilestoneLoading || loading}
							>
								Submit
							</LoadingButton>
						</Box>
					)}
				/>
			)}
		</Fragment>
	);
};