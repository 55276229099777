import { Box, Heading, Page, PageContent } from "grommet";
import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useWindowDimensions } from "../../../hooks";
import { QuestionController } from "../components";
import { useSubmission } from "../hooks";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCalculatorState, setCalculatorState } from "../../../store/calculator";
import { CalculatorPeriod } from "../../../graphql/__generated__/graphql";
import { questions } from "../static";

export const WasteFootprintCalculator: React.FC = () => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const { submission } = useSubmission();
	const state = useAppSelector(selectCalculatorState);

	const [ isFirstLoad, setIsFirstLoad ] = React.useState(true);

	useEffect(() => {
		if(submission) {
			const updatedState = { ...state };
			if(isFirstLoad) {
				switch(submission.period) {
					case CalculatorPeriod.Daily: {
						updatedState.period = "daily";
						break;
					}
					case CalculatorPeriod.Weekly: {
						updatedState.period = "weekly";
						break;
					}
					case CalculatorPeriod.Monthly: {
						updatedState.period = "monthly";
						break;
					}
					case CalculatorPeriod.Yearly: {
						updatedState.period = "yearly";
						break;
					}
				}
				setIsFirstLoad(false);
			}

			for(const response of submission.responses) {
				const question = questions.find((q) => q.id === response.id);
				if(!question) continue;

				const pledgeKey = question.pledgeKey;
				if(pledgeKey && pledgeKey in updatedState) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					///@ts-ignore
					updatedState[ pledgeKey ] = response.pledge;
				}

				if(response.id in updatedState) {
					let parsedValue: string | number | boolean;

					if(response.value === "true" || response.value === "false") {
						parsedValue = response.value === "true";
					}
					else if(!isNaN(Number(response.value))) {
						parsedValue = Number(response.value);
					}
					else {
						parsedValue = response.value;
					}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					///@ts-ignore
					updatedState[ response.id ] = parsedValue;
				}
			}

			dispatch(setCalculatorState(updatedState));
		}
	}, [ submission ]);

	return (
		<Page kind="narrow" margin={{ top: "medium" }}>
			<PageContent pad={size === "small" ? "none" : undefined}>
				<Box gap="medium" style={{ display: "block" }}>
					<Box gap={size === "small" ? "small" : "xsmall"} pad={size === "small" ? "medium" : undefined}>
						<Heading level={size === "small" ? 2 : 3} margin="none">
							Waste Footprint Calculator
						</Heading>
						<Typography>
							According to the U.S. EPA, the average American generates 4.9 lbs. of trash daily. This calculator helps you estimate your household waste footprint and find ways to reduce the amount of waste you contribute to landfills.
						</Typography>
						<Typography>
							Landfills take up valuable land and are major sources of pollution. Join the Zero Waste to Landfill movement and pledge to reduce your waste footprint.
						</Typography>
					</Box>
					<Box style={{ display: "block" }}>
						<QuestionController />
					</Box>
				</Box>
			</PageContent>
		</Page>
	);
};