import { ListItemText } from "@mui/material";
import { Product_ClassificationFragmentFragment } from "../../graphql/__generated__/graphql";
import { FragmentType } from "../../graphql";
import { ListItemSkeleton } from "../List";

export const ProductListItemText: React.FC<{
	loading?: boolean;
	product?: FragmentType<Product_ClassificationFragmentFragment>;
}> = ({ product, loading }) => {

	return (!product) ? (
		<ListItemText />
	) : (
		<ListItemText
			primary={(
				<ListItemSkeleton
					loading={loading}
					content={product?.type?.name || "Unknown"}
				/>
			)}
			secondary={(
				<ListItemSkeleton
					loading={loading}
					content={product.size?.name || "Standard"}
				/>
			)}
		/>
	);
};

