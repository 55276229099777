import React from "react";
import { STEPS, useOnboardingSteps } from "../steps";
import { Box } from "grommet";
import { Button, MobileStepper } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { OnboardingFormContext } from "../../types";
import { FormContainer } from "react-hook-form-mui";
import { useWindowDimensions } from "../../../../hooks";
import { useAppSelector } from "../../../../store";
import { selectOnboardingState } from "../../../../store/onboarding";
import { LoadingButton } from "@mui/lab";

export const StepContentRenderer: React.FC<{
	steps: JSX.Element[];
	formContext: UseFormReturn<OnboardingFormContext>;
	handleSubmit: () => void;
	submitButtonLabel?: string;
}> = ({ steps, formContext, handleSubmit, submitButtonLabel }) => {
	const { size } = useWindowDimensions();
	const { isPolling, isSubmitting } = useAppSelector(selectOnboardingState);
	const { activeStepIndex, next, previous, hasNext } = useOnboardingSteps(
		formContext,
		handleSubmit
	);

	return (
		<FormContainer formContext={formContext}
			FormProps={{
				style: { height: "100%" }
			}}
		>
			<Box pad={{ horizontal: "medium", vertical: size === "small" ? "medium" : "small", bottom: "medium" }} height="100%" gap="small">
				<Box height="90%">
					{steps[ activeStepIndex ]}
				</Box>
				{size === "small" && (
					<MobileStepper
						steps={STEPS.length}
						position="static"
						activeStep={activeStepIndex}
						backButton={(
							<Button variant="outlined" onClick={() => previous()}>
								Back
							</Button>
						)}
						nextButton={(
							<Button variant="contained" onClick={() => next()}>
								{hasNext ? "Next" : submitButtonLabel || "Submit"}
							</Button>
						)}
					/>
				)}
				{size !== "small" && (
					<Box direction="row" justify="between" height="10%" align="end">
						<Box>
							{activeStepIndex > 0 && (
								<Button variant="outlined" onClick={() => previous()}>
									Previous
								</Button>
							)}
						</Box>
						<Box>
							<LoadingButton
								variant="contained"
								onClick={() => next()}
								loading={isPolling || isSubmitting}
							>
								{hasNext ? "Next" : submitButtonLabel || "Submit"}
							</LoadingButton>
						</Box>
					</Box>
				)}
			</Box>
		</FormContainer>
	);
};