import { FragmentType } from "../../graphql";
import { Product_ClassificationFragmentFragment } from "../../graphql/__generated__/graphql";
import { ListItemIcon, useTheme } from "@mui/material";
import { Spinner } from "grommet";
import { useMemo } from "react";
import { Icon } from "@liverego/icons";

interface ProductListItemIconProps {
	iconOverride?: React.ReactNode;
	loading?: boolean;
	product?: FragmentType<Product_ClassificationFragmentFragment>;
}

export const ProductListItemIcon: React.FC<ProductListItemIconProps> = ({
	loading,
	product,
	iconOverride
}) => {
	const theme = useTheme();
	const iconUrl = useMemo(() => {
		return product?.size?.iconUrl || product?.type?.iconUrl || product?.type?.category?.iconUrl || "@liverego/icons/Other";
	}, [ product ]);

	return (
		<ListItemIcon style={{ minWidth: "40px" }} >
			{(loading) ? (
				<Spinner />
			) : iconOverride || (!!iconUrl) ? (
				<Icon
					sx={{
						fontSize: "36px",
						fill: theme.palette.primary.main,
					}}
					url={iconUrl || "@liverego/icons/Other"}
				/>
			) : <Spinner />}
		</ListItemIcon>
	);
};