import React from "react";
import { useAppDispatch } from "../../../../../store";
import { Chip, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import moment from "moment-timezone";
import { push } from "redux-first-history";
import { useWindowDimensions } from "../../../../../hooks";

export const WasteAuditListItem: React.FC<{
	name: string;
	auditId: string;
	createdAt: string;
	completed: boolean;
}> = ({ name, auditId, completed, createdAt }) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	function handleClick(): void {
		dispatch(push(`/admin/audits/${auditId}`));
	}

	return (
		<ListItemButton
			divider
			onClick={handleClick}
		>
			<ListItemText
				primary={name || "Untitled Audit"}
				secondary={moment.tz(createdAt, "UTC").tz(moment.tz.guess()).format(
					size === "small"
						? "ddd, MMM Do YYYY"
						: "dddd, MMMM Do YYYY"
				)}
			/>
			<ListItemSecondaryAction>
				{completed && (
					<Chip
						color="success"
						label="Completed"
					/>
				)}
				{!completed && (
					<Chip
						color="warning"
						label="In Progress"
					/>
				)}
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};