import { gql } from "../../../__generated__";

export const FetchLeaderboard = gql(/* GraphQL */ `
	query FetchLeaderboard($gameType: String!) {
		FetchLeaderboard(gameType: $gameType) {
			username
			score
			createdAt
		}
	}
`);