import { Box, Spinner } from "grommet";
import React, { Fragment, ReactNode, useMemo, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";
import { AccordionController } from "../../../components";
import { CalculatorState, parseCalculatorResponseValue, parseTotalWeightDivertedPounds, parseTotalWeightGeneratedPounds, parseWasteImpact, parseWasteReduction, selectCalculatorState } from "../../../store/calculator";
import { useAppSelector } from "../../../store";
import { WasteScenario } from "../WasteScenario";
import { WasteReductionTipSummary } from "./WasteReductionTipSummary";
import { useSubmission, useWasteScenario } from "../hooks";
import { useWindowDimensions } from "../../../hooks";
import { questions } from "../static";
import { CalculatorPeriod } from "../../../graphql/__generated__/graphql";

export interface WasteReductionTipProps {
	name: string;
	title: string;
	icon: ReactNode;
	reference: string;
	pledgeKey: keyof CalculatorState;
	amount: (scenario: WasteScenario) => number;
	secondaryText: (scenario: WasteScenario) => string;
	pledgeDetailsText: (scenario: WasteScenario) => ReactNode;
	pledgeAdditionalText?: (scenario: WasteScenario) => ReactNode;
	fallbackMessageIfZero: (scenario: WasteScenario) => string;
}

export const WasteReductionTip: React.FC<WasteReductionTipProps> = (props) => {
	const scenario = useWasteScenario();
	const { size } = useWindowDimensions();
	const state = useAppSelector(selectCalculatorState);
	const [ isExpanded, setIsExpanded ] = useState(false);
	const { updateResponse, isUpdatingResponseId } = useSubmission();

	const fallbackMessageIfZero = useMemo(() => {
		return props.fallbackMessageIfZero(scenario);
	}, [ scenario ]);

	function handlePledge(checked: boolean) {
		const question = questions.find((q) => q.pledgeKey === props.pledgeKey);
		if(!question) return;

		const updatedState = { ...state };
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		/// @ts-ignore
		updatedState[ props.pledgeKey ] = checked;

		const updatedScenario = new WasteScenario(updatedState);

		updateResponse({
			id: question.id,
			pledge: checked,
			period: CalculatorPeriod.Daily,
			question: question.text,
			value: parseCalculatorResponseValue(updatedState[ question.id ]),
			weightImpact: parseWasteImpact(updatedScenario, question),
			weightReduction: parseWasteReduction(updatedScenario, question),
			totalWeightDivertedPounds: parseTotalWeightDivertedPounds(updatedScenario),
			totalWeightGeneratedPounds: parseTotalWeightGeneratedPounds(updatedScenario),
		});
	}

	const isLoading = useMemo(() => {
		const question = questions.find((q) => q.pledgeKey === props.pledgeKey);
		if(question && isUpdatingResponseId === question.id) {
			return true;
		}

		return false;
	}, [ isUpdatingResponseId, props.pledgeKey ]);

	return (
		<AccordionController
			name={props.name}
			summary={(
				<WasteReductionTipSummary
					icon={props.icon}
					primary={props.title}
					reference={props.reference}
					secondary={props.secondaryText(scenario)} />
			)}
			summaryProps={{ sx: { padding: "6px", margin: "0px" } }}
			details={(
				<Fragment>
					{fallbackMessageIfZero && (
						<Box>
							<Typography>
								{fallbackMessageIfZero}
							</Typography>
						</Box>
					)}
					{!fallbackMessageIfZero && (
						<Box direction="row" gap="small">
							<Box
								gap="small"
								style={{
									maxWidth: size === "small" ? "90%" : undefined
								}}
							>
								{props.pledgeDetailsText(scenario)}
								{props.pledgeAdditionalText && props.pledgeAdditionalText(scenario)}
							</Box>
							<Box justify="center" align="end">
								<Box justify="end">
									{isLoading && (
										<FormControl>
											<FormControlLabel
												control={<Spinner />}
												label="" />
										</FormControl>
									)}
									{!isLoading && (
										<FormControl>
											<FormControlLabel
												control={<Checkbox
													checked={(state[ props.pledgeKey ] as boolean) || false}
													onChange={(event) => {
														handlePledge(event.target.checked);
													}} />}
												label="" />
										</FormControl>
									)}
								</Box>
							</Box>
						</Box>
					)}
				</Fragment>
			)}
			isExpanded={isExpanded}
			onChange={() => setIsExpanded((prev) => !prev)} />
	);
};