import { SidebarLayout } from "../../components";
import { CheckoutSummarySidebar } from "./components";

export const CheckoutLayout: React.FC = () => {
	return (
		<SidebarLayout
			reverse
			sidebar={(
				<CheckoutSummarySidebar shrink />
			)}
			widthOverride="360px"
		/>
	);
};