import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

import { CheckoutSessionGql } from "../graphql";
import { ProductIntent } from "../graphql/__generated__/graphql";

export interface ScheduleState {
	referer: { id: string; name: string; } | null;
	session: CheckoutSessionGql | null;
	defaultIntent: ProductIntent | null;
	agreedToContactTerms: boolean;
}

const initialState: ScheduleState = {
	referer: null,
	session: null,
	defaultIntent: null,
	agreedToContactTerms: false
};

export const scheduleSlice = createSlice({
	name: "schedule",
	initialState,
	reducers: {
		setReferer(state, action: PayloadAction<{ id: string; name: string; }>) {
			state.referer = action.payload;
		},
		setDefaultIntent(state, action: PayloadAction<ProductIntent>) {
			state.defaultIntent = action.payload;
		},
		setCheckoutSession(state, action: PayloadAction<CheckoutSessionGql>) {
			state.session = action.payload;
		},
		setAgreedToContactTerms(state, action: PayloadAction<boolean>) {
			state.agreedToContactTerms = action.payload;
		}
	}
});

export const {
	setReferer,
	setDefaultIntent,
	setCheckoutSession,
	setAgreedToContactTerms
} = scheduleSlice.actions;

export const selectReferer = (state: RootState) => state.schedule.referer;
export const selectDefaultIntent = (state: RootState) => state.schedule.defaultIntent;
export const selectCheckoutSession = (state: RootState) => state.schedule.session;
export const selectAgreedToContactTerms = (state: RootState) => state.schedule.agreedToContactTerms;

export default scheduleSlice.reducer;