import { Box } from "grommet";
import React from "react";
import { StepController } from "../../../components";
import { CheckoutItemsView } from "../views/CheckoutItemsView";
import { CheckoutReviewView } from "../views/CheckoutReviewView";
import { CheckoutScheduleController } from "./Schedule";
import { CheckoutAddressController } from "./Address";

export const CheckoutStepsController: React.FC = () => {
	return (
		<Box
			height="100%"
			id="checkout-step-controller"
		>
			<StepController
				name="checkout"
			>
				<CheckoutItemsView
					stepName="Items"
					loginRequired={false}
				/>
				<CheckoutScheduleController
					stepName="Schedule"
					loginRequired={false}
				/>
				<CheckoutAddressController
					stepName="Address"
					loginRequired={true}
				/>
				<CheckoutReviewView
					stepName="Review"
					loginRequired={true}
				/>
			</StepController>
		</Box>
	);
};