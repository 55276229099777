import { gql } from "../../../__generated__";

export const FindBuildingsReporting = gql(/* GraphQL */ `
	query FindBuildingsReporting(
		$instanceId: String!
	) {
		FindBuildings(
			instance: { id: { equals: $instanceId } }
		) {
			...Building_WasteContainerFragment
		}
	}
`);