import { DatePickerElement } from "react-hook-form-mui";
import { LocalizationProvider } from "../../../../provider";

export const MoveOutDateElement: React.FC = () => {
	return (
		<LocalizationProvider>
			<DatePickerElement
				name="moveOutDate"
				label="Move Out Date"
				helperText="Optional"
			/>
		</LocalizationProvider>
	);
};
