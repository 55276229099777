import { Typography } from "@mui/material";
import { Box, Heading } from "grommet";
import React from "react";

export const OnboardingViewContainer: React.FC<{
	loading?: boolean;
	title: string;
	subtitle: string;
	children: React.ReactNode;
}> = ({ title, subtitle, children }) => {
	return (
		<Box gap="medium" height="100%" overflow={{ vertical: "auto" }} style={{ display: "block" }}>
			<Box>
				<Heading level="2" margin="none">
					{title}
				</Heading>
				<Typography>
					{subtitle}
				</Typography>
			</Box>
			{children}
		</Box>
	);
};