import React, { useMemo } from "react";
import { useWindowDimensions } from "../../../../hooks";
import { ViewContainer } from "../../../../components";
import { Box, Grid } from "grommet";
import { ActiveOrdersContainer, UpcomingPickupsContainer } from "../components";

export const UniversityAdminDashboard: React.FC = () => {
	const { size } = useWindowDimensions();

	const container = useMemo(() => {
		return size === "xlarge" ? (
			<Grid columns={[ "2/3", "1/3" ]} gap="small" height="100%">
				<ActiveOrdersContainer />
				<UpcomingPickupsContainer />
			</Grid>
		)
			: (
				<Box style={{ display: "block" }} height="100%" gap="small">
					<ActiveOrdersContainer />
					<UpcomingPickupsContainer />
				</Box>
			);
	}, [ size ]);

	return (
		<ViewContainer>
			{container}
		</ViewContainer>
	);
};