import { SvgIcon, SvgIconProps } from "@mui/material";

	export const CeilingFan: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m599.47 925.9c-10.359 0-18.75 8.3906-18.75 18.75v77.91c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-77.91c0-10.359-8.3945-18.75-18.75-18.75z" />
		<path
			d="m469.54 878.41-61.559 51.906c-7.9141 6.6758-8.918 18.504-2.2422 26.414 3.7109 4.4023 9.0078 6.668 14.348 6.668 4.2656 0 8.5586-1.4492 12.074-4.418l61.559-51.906c7.9141-6.6758 8.918-18.504 2.2422-26.414-6.6758-7.9258-18.512-8.9375-26.422-2.25z" />
		<path
			d="m730.45 878.41c-7.9062-6.668-19.746-5.6758-26.414 2.25-6.6758 7.9141-5.6719 19.746 2.25 26.414l61.551 51.906c3.5156 2.9688 7.8086 4.418 12.074 4.418 5.332 0 10.637-2.2656 14.34-6.668 6.6758-7.9141 5.6719-19.746-2.25-26.414z" />
		<path
			d="m1159.8 452.47c-4.4102-9-13.551-14.699-23.574-14.699l-435.5-0.003906c-0.17969 0-0.35156 0.050781-0.53125 0.050781-0.17969 0-0.35156-0.050781-0.53125-0.050781h-41.871l-0.003906-111.48h44.039c44.934 0 81.496-36.562 81.496-81.504v-59.844c0-14.496-11.754-26.25-26.25-26.25h-315.25c-14.496 0-26.25 11.754-26.25 26.25v59.844c0 44.941 36.562 81.504 81.504 81.504h44.039v111.48h-41.871l-435.51-0.003906c-10.02 0-19.164 5.6992-23.574 14.699-4.4023 8.9922-3.3086 19.719 2.8281 27.637l49.172 63.434c25.273 32.602 65.004 52.074 106.27 52.074l321.23 0.003906c6.6914 3.5039 13.883 6.1562 21.449 7.8242v33.031l-81.172-0.003906c-14.191 0-27.621 6.8633-35.941 18.352-8.3086 11.488-10.629 26.391-6.1953 39.848l9.3242 28.305c11.129 33.816 41.398 57.129 76.484 59.641v10.477c0 53.129 43.223 96.352 96.352 96.352h0.039063c53.121 0 96.352-43.223 96.352-96.352v-10.477c35.086-2.5117 65.355-25.824 76.477-59.633l9.3242-28.312c4.4414-13.461 2.1211-28.359-6.1875-39.848-8.3164-11.488-21.75-18.352-35.941-18.352h-82.23v-33.031c7.5664-1.6641 14.762-4.3203 21.449-7.8242h322.29c41.266 0 81-19.469 106.27-52.074l49.176-63.434c6.1406-7.918 7.2305-18.633 2.8281-27.633zm-486.39 66.285c0 18.887-15.367 34.246-34.246 34.246l-79.434-0.003906c-18.887 0-34.254-15.359-34.254-34.246v-28.477h147.93zm-205.34-273.97v-33.594h262.76v33.594c0 15.988-13.004 29.004-28.996 29.004h-204.77c-15.992 0-28.996-13.008-28.996-29.004zm110.54 81.504h41.684v111.48l-41.684-0.003906zm-444.96 185.07-16.359-21.098h355.69v28.477c0 8.4609 1.2734 16.621 3.5391 24.359h-278.1c-25.141 0.003907-49.359-11.863-64.766-31.738zm510.21 281.73c0 24.18-19.672 43.852-43.852 43.852h-0.039063c-24.18 0-43.852-19.672-43.852-43.852v-10.223h87.742zm79.109-86.52c-4.6875 14.227-17.918 23.797-32.91 23.797h-180.16c-15 0-28.223-9.5625-32.902-23.805l-5.7969-17.602h257.57zm-122.98-70.109h-21.375v-30.969h41.684v30.969zm466.33-125.1c-15.406 19.875-39.613 31.738-64.777 31.738l-279.16 0.003907c2.2656-7.7383 3.5391-15.891 3.5391-24.359v-28.477h356.75z" />
	</g>
</svg>
			</SvgIcon>
		);
	};