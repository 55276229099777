import { SvgIcon, SvgIconProps } from "@mui/material";

	export const OttomanAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="m1060 180h-920c-37.117 0.046875-72.699 14.812-98.941 41.059-26.246 26.242-41.012 61.824-41.059 98.941v420c0.039062 18.031 6.168 35.52 17.391 49.633 11.227 14.109 26.891 24.016 44.449 28.105l-58.961 147.4c-4.9297 12.32-3.4258 26.285 4.0078 37.277 7.4375 10.988 19.844 17.578 33.113 17.582h40c8.0039 0.027344 15.828-2.3594 22.453-6.8477 6.625-4.4883 11.742-10.871 14.688-18.312l69.961-174.84h825.8l70 174.86h-0.003906c2.9453 7.4336 8.0586 13.809 14.676 18.293 6.6172 4.4844 14.434 6.8711 22.426 6.8477h40c13.27 0 25.672-6.582 33.113-17.57 7.4414-10.988 8.9492-24.949 4.0273-37.27l-58.961-147.42c17.559-4.0938 33.215-14.004 44.438-28.113 11.219-14.109 17.344-31.598 17.383-49.625v-420c-0.046875-37.117-14.812-72.699-41.059-98.941-26.242-26.246-61.824-41.012-98.941-41.059zm-980 800h-40l64-160h40zm1040 0-64-160h40l64 160zm40-240c0 10.609-4.2148 20.781-11.715 28.285-7.5039 7.5-17.676 11.715-28.285 11.715h-1040c-10.609 0-20.781-4.2148-28.285-11.715-7.5-7.5039-11.715-17.676-11.715-28.285v-360h1120zm0-400h-1120v-20c0.027344-19.434 5.7148-38.441 16.371-54.695 10.656-16.254 25.816-29.051 43.629-36.824v51.52c0 7.1445 3.8125 13.746 10 17.32 6.1875 3.5742 13.812 3.5742 20 0 6.1875-3.5742 10-10.176 10-17.32v-60h120v60c0 7.1445 3.8125 13.746 10 17.32 6.1875 3.5742 13.812 3.5742 20 0 6.1875-3.5742 10-10.176 10-17.32v-60h120v60c0 7.1445 3.8125 13.746 10 17.32 6.1875 3.5742 13.812 3.5742 20 0 6.1875-3.5742 10-10.176 10-17.32v-60h120v60c0 7.1445 3.8125 13.746 10 17.32 6.1875 3.5742 13.812 3.5742 20 0 6.1875-3.5742 10-10.176 10-17.32v-60h120v60c0 7.1445 3.8125 13.746 10 17.32 6.1875 3.5742 13.812 3.5742 20 0 6.1875-3.5742 10-10.176 10-17.32v-60h120v60c0 7.1445 3.8125 13.746 10 17.32 6.1875 3.5742 13.812 3.5742 20 0 6.1875-3.5742 10-10.176 10-17.32v-60h120v60c0 7.1445 3.8125 13.746 10 17.32 6.1875 3.5742 13.812 3.5742 20 0 6.1875-3.5742 10-10.176 10-17.32v-51.52c17.812 7.7734 32.973 20.57 43.629 36.824 10.656 16.254 16.344 35.262 16.371 54.695z" />
</svg>
			</SvgIcon>
		);
	};