import { Box } from "grommet";
import { ViewContainer } from "../../../components";
import { Button } from "@mui/material";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../store";
import { HowItWorkContainer, ResidenceDetailsContainer } from "../components";
import { useInstance, usePageTitle } from "../../../hooks";
import { useMemo } from "react";

export const ManagementCompanyReferView: React.FC = () => {
	usePageTitle("Welcome");
	const dispatch = useAppDispatch();
	const { instance } = useInstance();

	const partnerName = useMemo(() => {
		return instance?.name ?? "Your Building";
	}, [ instance ]);

	return (
		<ViewContainer>
			<Box height="100%" align="center">
				<Box
					gap="medium"
					height="100%"
					overflow={{ vertical: "auto" }}
					style={{ maxWidth: "800px", display: "block" }}
				>
					<ResidenceDetailsContainer
						partnerName={partnerName}
					/>
					<HowItWorkContainer />
					<Box direction="row" justify="end">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								dispatch(push("/checkout"));
							}}
						>
							Get Started
						</Button>
					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};