import { Box } from "grommet";
import { useMemo } from "react";
import { IconButton, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { Login, Visibility } from "@mui/icons-material";
import { useHauler } from "../hooks";
import { AddressStopDetailsDialog } from "../../../components/address/AddressStopDetailsDialog";
import { useHaulerScopedOrder } from "../../../graphql";

export const HaulerOrderPickupDetails: React.FC<{ orderId: string; }> = ({ orderId }) => {
	const { hauler } = useHauler();
	const { order, loading } = useHaulerScopedOrder(orderId, hauler?.id ?? "");

	const addressPrimary = useMemo(() => {
		return [ order?.address?.addressLineOne, order?.address?.addressLineTwo ].filter((value) => !!value).join(" ");
	}, [ order ]);

	const addressSecondary = useMemo(() => {
		if(order?.address) {
			return `${order?.address?.city}, ${order?.address?.state} ${order?.address?.zipCode}`;
		}

		return "";
	}, [ order ]);

	return (
		<Box>
			<List>
				<AddressStopDetailsDialog
					addressId={order?.address?.id ?? ""}
					activatorButton={(
						<ListItemButton
							divider
						>
							<ListItemIcon>
								<Login />
							</ListItemIcon>
							<ListItemText
								primary={addressPrimary}
								secondary={addressSecondary}
							/>
							<ListItemSecondaryAction>
								<IconButton>
									<Visibility />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItemButton>
					)}
				/>
			</List>
		</Box>
	);
};
