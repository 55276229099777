import { useQuery } from "@apollo/client";
import { Box, Grid, Heading } from "grommet";
import { FindHaulerPendingQuoteRequests, useHaulerScopedOrder } from "../../../graphql";
import { useMemo, useState } from "react";
import { Button, IconButton, List } from "@mui/material";
import { BasicListItem, ViewContainer } from "../../../components";
import { useClipboard } from "../../../hooks/useClipboard";
import moment from "moment-timezone";
import { CopyAll } from "@mui/icons-material";
import { useHauler, useHaulerQuotes } from "../hooks";
import { HaulerOrderProductsComponent, UpsertHaulerQuoteDialog } from "../components";
import { useRequiredPathParam, useWindowDimensions } from "../../../hooks";
import { HaulerOrderDropoffDetails } from "../components/HaulerOrderDropoffDetails";
import { HaulerOrderPickupDetails } from "../components/HaulerOrderPickupDetails";
import { formatCurrency } from "../../../helpers";

export const HaulerOrderDetailsView: React.FC = () => {
	const { hauler } = useHauler();
	const orderId = useRequiredPathParam("orderId", "/hauler/dashboard");

	return (
		<Box margin="small" flex>
			{hauler && (<HaulerOrderDetails orderId={orderId || ""} haulerId={hauler.id} />)}
		</Box>
	);
};

export const SubmitQuoteButton: React.FC<{
	orderId: string;
	haulerId: string;
}> = ({ orderId, haulerId }) => {
	const { pendingQuoteRequests } = useHaulerQuotes(haulerId);

	const hasPendingQuote = useMemo(() => {
		return pendingQuoteRequests.some(r => r.order.id === orderId);
	}, [ orderId, pendingQuoteRequests ]);

	if(!hasPendingQuote) {
		return <></>;
	}

	return (
		<Button
			variant="contained"
			color="primary"
		>
			Submit Quote
		</Button>
	);
};

export const HaulerOrderDetails: React.FC<{ orderId: string; haulerId: string; }> = (props) => {
	const { copy } = useClipboard();
	const { sizeIndex } = useWindowDimensions();
	const { order, loading } = useHaulerScopedOrder(props.orderId, props.haulerId);

	const mostRecentQuote = useMemo(() => {
		return order?.haulerQuotes?.sort((a, b) => {
			return moment(a.createdAt).isBefore(moment(b.createdAt))
				? 1
				: -1;
		})[ 0 ];
	}, [ order ]);

	const {
		data: haulerPendingQuoteRequestsData, loading: haulerPendingQuoteRequestsLoading, error: haulerPendingQuoteRequestsError
	} = useQuery(FindHaulerPendingQuoteRequests, {
		variables: { haulerId: props.haulerId }
	});

	const hasPendingQuote = useMemo(() => {
		return haulerPendingQuoteRequestsData?.FindHaulerPendingQuoteRequests?.some((quoteRequest) => quoteRequest.order.id === props.orderId) ?? false;
	}, [ haulerPendingQuoteRequestsData, props.orderId ]);

	const [ isSubmittingQuote, setIsSubmittingQuote ] = useState(false);

	const completedDate = useMemo(() => {
		return (order?.completedAt)
			? moment.tz(order?.completedAt, moment.tz.guess()).format("dddd, MMMM Do")
			: "";
	}, [ order ]);

	const { size } = useWindowDimensions();

	return (
		<ViewContainer>
			<Box gap="small">
				{hasPendingQuote && isSubmittingQuote && (
					<UpsertHaulerQuoteDialog
						onClose={() => setIsSubmittingQuote(false)}
						requestId={haulerPendingQuoteRequestsData?.FindHaulerPendingQuoteRequests?.find((quoteRequest) => quoteRequest.order.id === props.orderId)?.id ?? ""}
						pickupOptions={[]}
					/>
				)}
				<Grid columns={{ count: sizeIndex <= 2 ? 1 : 2, size: "auto" }} gap="small">
					<Box>
						<Heading level="3" margin="none">
							Order Details
						</Heading>
						<List>
							<BasicListItem
								label="OrderId"
								loading={loading}
								value={order?.id ?? ""}
								action={
									<IconButton
										color="primary"
										onClick={() => copy(order?.id ?? "")}
									>
										<CopyAll />
									</IconButton>
								}
							/>
							<BasicListItem
								label="Submitted At"
								loading={loading}
								value={moment.tz(order?.submittedAt ?? "", "UTC").tz("America/New_York").format("dddd, MMMM Do, hh:mm A")}
							/>
							{completedDate && (
								<BasicListItem
									label="Completed At"
									loading={loading}
									value={completedDate}
								/>
							)}
							<BasicListItem
								label="Submitted By"
								loading={loading}
								value={order?.user?.fullName ?? ""}
							/>
						</List>
					</Box>
					<Box>
						<Heading level="3" margin="none">
							Quote Details
						</Heading>
						<Box>
							<List>
								<BasicListItem
									label="Quoted Amount"
									loading={loading}
									value={mostRecentQuote
										? formatCurrency(mostRecentQuote.amount)
										: "Not Quoted"}
									action={(
										<Box direction="row" gap="small">
											{hasPendingQuote && (
												<Button
													variant="contained"
													color="primary"
													onClick={() => setIsSubmittingQuote(true)}
												>
													{size === "small" ? "Submit" : "Submit Quote"}
												</Button>
											)}
										</Box>
									)}
								/>
							</List>
						</Box>
					</Box>
				</Grid>
				<Box>
					<Heading level="3" margin="none">
						Order Items
					</Heading>
					<HaulerOrderProductsComponent
						orderId={props.orderId}
					/>
				</Box>
				<Grid columns={{ count: sizeIndex <= 2 ? 1 : 2, size: "auto" }} gap="small">
					<Box>
						<Heading level="3" margin="none">
							Pickup Locations
						</Heading>
						<HaulerOrderPickupDetails
							orderId={props.orderId}
						/>
					</Box>
					<Box>
						<Heading level="3" margin="none">
							Dropoff Locations
						</Heading>
						<HaulerOrderDropoffDetails
							orderId={props.orderId}
						/>
					</Box>
				</Grid>
			</Box>
		</ViewContainer>
	);
};
