import { MapsHomeWork } from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import { LoadingListItemText } from "../List";
import { Box, Image } from "grommet";
import { useInstance } from "../../hooks";

export const BuildingListItem: React.FC<{
	loading?: boolean;
	buildingName: string;
	logoUrl?: string;
}> = (props) => {
	const { instance } = useInstance();

	return (
		<ListItemButton divider>
			<ListItemIcon>
				<MapsHomeWork />
			</ListItemIcon>
			<LoadingListItemText
				label="Your Building"
				value={props.buildingName}
				loading={props.loading}
			/>
			{props.logoUrl && (
				<ListItemSecondaryAction>
					<Box align="end" justify="center">
						<Box
							pad="small"
							style={{ maxWidth: "100px", maxHeight: "50px" }}
							background={instance?.customizations?.headerColor || undefined}
						>
							<Image
								fit="contain"
								src={props.logoUrl}
							/>
						</Box>
					</Box>
				</ListItemSecondaryAction>
			)}
		</ListItemButton>
	);
};