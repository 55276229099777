import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ComputerMonitor: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1106.9 24.672h-1013.7c-24.695 0.027344-48.367 9.8477-65.828 27.309-17.461 17.461-27.285 41.137-27.312 65.832v669.35c0.027344 24.691 9.8516 48.363 27.312 65.824s41.137 27.281 65.828 27.309h386.95l-45.859 194.4h-114.14c-7.3711 0.15234-14.469 2.8008-20.137 7.5117-5.6641 4.7148-9.5625 11.211-11.059 18.43l-8.2188 44.309c-1.582 7.5352 0.36719 15.379 5.2891 21.301 4.9219 5.918 12.277 9.2656 19.977 9.0898h588.12c7.6953 0.17969 15.055-3.1641 19.98-9.0859 4.9219-5.918 6.8711-13.762 5.293-21.297l-8.2305-44.316c-1.4922-7.2188-5.3906-13.715-11.059-18.43-5.6641-4.7109-12.766-7.3594-20.133-7.5117h-114.15l-45.855-194.4h386.95c24.691-0.027344 48.367-9.8477 65.828-27.309s27.281-41.133 27.312-65.824v-669.35c-0.027344-24.695-9.8516-48.371-27.312-65.832-17.461-17.461-41.137-27.281-65.828-27.309zm-1013.7 24.375h1013.7c18.23 0.019531 35.707 7.2734 48.598 20.164 12.895 12.891 20.145 30.367 20.168 48.602v612.92h-1151.2v-612.92c0.023438-18.234 7.2734-35.711 20.164-48.602 12.895-12.891 30.371-20.145 48.602-20.164zm786.77 1050c3.4336 0.28906 6.3203 2.6875 7.2305 6.0078l8.2305 44.316 11.98-2.2227-13.293 3.7852h-588.12l-1.3008-1.5625 8.2188-44.316c0.91016-3.3203 3.7969-5.7188 7.2305-6.0078zm-139.2-24.375h-281.43l45.855-194.4h189.72zm366.14-218.78h-1013.7c-18.23-0.019531-35.707-7.2695-48.598-20.16-12.891-12.887-20.145-30.367-20.168-48.594v-32.051h1151.2v32.051c-0.023438 18.227-7.2773 35.707-20.168 48.594-12.891 12.891-30.367 20.141-48.598 20.16z"/>
  <path d="m600 778.6c-7.1367 0-13.984 2.8359-19.031 7.8828s-7.8828 11.895-7.8828 19.031c0 7.1406 2.8359 13.984 7.8828 19.031 5.0469 5.0508 11.895 7.8828 19.031 7.8828 7.1367 0 13.984-2.832 19.031-7.8828 5.0469-5.0469 7.8828-11.891 7.8828-19.031-0.007813-7.1367-2.8477-13.977-7.8906-19.023-5.0469-5.043-11.887-7.8828-19.023-7.8906zm0 37.582v-0.003907c-4.3125 0-8.2031-2.5977-9.8516-6.582-1.6523-3.9844-0.73828-8.5742 2.3086-11.621 3.0508-3.0508 7.6406-3.9648 11.625-2.3125 3.9844 1.6484 6.582 5.5391 6.582 9.8516-0.007813 5.8867-4.7773 10.66-10.664 10.664z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};