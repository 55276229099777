import { Box, Heading } from "grommet";
import { Button, List, Typography, useTheme } from "@mui/material";
import { DialogWithActivatorButton } from "../../../components";
import { useToggleForceClose } from "../../admin/components";
import { useClassification, usePricedTypes } from "../../../hooks";
import { useEffect, useMemo } from "react";
import { formatCurrency } from "../../../helpers";
import { TypeSelectionListItem } from "./TypeSelectionListItem";
import { CategoryLike } from "../types";
import { useAppSelector } from "../../../store";
import { selectCheckoutState } from "../../../store/checkout";
import { useCheckoutSession } from "../hooks";
import { useFeature } from "../../instance";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { calculatePickupFee, calculcatePickupFeeComparison } from "../pricing";

export function useCheckoutPricing() {
	const checkoutState = useAppSelector(selectCheckoutState);
	const session = useCheckoutSession();

	const { types } = usePricedTypes(
		session.referer?.id || "",
		session.isCustomSchedule
	);

	const totalFee = useMemo(() => {
		return checkoutState.products.reduce((acc, product) => {
			if(!product.type) return acc;
			const fee = calculatePickupFee(
				types.find(t => t.id === product.type?.id) || null,
				product.type?.id || "",
				product.size?.id || ""
			) ?? 0;
			return acc + (fee * product.quantity);
		}, 0);
	}, [ checkoutState, types ]);

	const totalFeeComparison = useMemo(() => {
		return checkoutState.products.reduce((acc, product) => {
			if(!product.type) return acc;
			const fee = calculcatePickupFeeComparison(
				types.find(t => t.id === product.type?.id) || null,
				product.type?.id || "",
				product.size?.id || ""
			) ?? 0;
			return acc + (fee * product.quantity);
		}, 0);
	}, [ checkoutState, types ]);

	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);

	return (isUpfrontPricingEnabled)
		? { totalFee, totalFeeComparison }
		: { totalFee: 0, totalFeeComparison: 0 };
}

export function useCheckoutPricedTypes() {
	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);

	const session = useCheckoutSession();
	const { types, loading } = usePricedTypes(
		session?.referer?.id || "",
		session.isCustomSchedule
	);

	return {
		types: isUpfrontPricingEnabled
			? types
			: [],
		loading
	};
}

export const TypeSelectionDialog: React.FC<{
	category: CategoryLike;
	selectedTypes: string[];
	onClose?: () => void;
	activatorButton: React.ReactNode;
	activatorButtonIntercept?: () => boolean;
}> = ({ category, activatorButton, activatorButtonIntercept, selectedTypes, onClose }) => {
	const { forceClose, toggleForceClose } = useToggleForceClose();

	const { types } = useClassification();
	const typesOfCategory = useMemo(() => {
		return types.filter((type) => type.category.id === category.id);
	}, [ types ]);

	const {
		totalFee,
		totalFeeComparison
	} = useCheckoutPricing();

	const theme = useTheme();

	useEffect(() => {
		if(selectedTypes.length > 0) {
			setTimeout(() => {
				const first = selectedTypes[ 0 ];
				const element = document.getElementById(`type-${first}`);
				if(element) {
					element.scrollIntoView();
				}
			}, 1000);
		}
		//Figure out a way to force scoll to top on open
		// else {
		// 	setTimeout(() => {
		// 		const element = document.getElementById("dialog-content-root");
		// 		if(element) {
		// 			element.scrollTop = 0;
		// 		}
		// 	}, 1000);
		// }
	}, [ selectedTypes ]);

	return (
		<DialogWithActivatorButton
			onClose={onClose}
			forceClose={forceClose}
			disabledContentPadding
			activatorButtonIntercept={activatorButtonIntercept}
			activatorButton={activatorButton}
			title="Select Items"
			actions={(
				<Box direction="row" justify="between">
					<Box justify="center" direction="row" gap="small">
						{!!totalFee && (
							<Box justify="center">
								<Typography
									fontWeight="bold"
									style={{ color: theme.palette.success.main }}
								>
									{formatCurrency(totalFee)}
								</Typography>
							</Box>
						)}
						{!!totalFeeComparison && (
							<Box justify="center">
								<Typography sx={{ textDecoration: "line-through" }}>
									{formatCurrency(totalFeeComparison)}
								</Typography>
							</Box>
						)}
					</Box>
					<Button
						color="primary"
						variant="contained"
						onClick={toggleForceClose}
					>
						Save
					</Button>
				</Box>
			)}
		>
			<Box gap="small" id="dialog-content-root">
				<Box margin={{ top: "small", horizontal: "medium" }}>
					<Heading level="3" margin="none">
						{category.name}
					</Heading>
				</Box>
				<List disablePadding>
					{Array.from(typesOfCategory).sort((a, b) => (b.sizes || []).length - (a.sizes || []).length).map((type) => (
						<TypeSelectionListItem
							key={type.id}
							typeId={type.id}
							selected={selectedTypes.includes(type.id)}
						/>
					))}
				</List>
			</Box>
		</DialogWithActivatorButton>
	);
};
