import { gql } from "@apollo/client";

export const OutletFragment = gql`
	fragment OutletFragment on Outlet {
		id
		name
		disposition
		address {
			...AddressFragment
		}
	}
`;