import { gql } from "@apollo/client";

export const PaymentMethodFragment = gql`
	fragment PaymentMethodFragment on PaymentMethod {
		id
		type
		brand
		last4
		expirationYear
		expirationMonth
		isPrimary
	}
`;