import { gql } from "../../../__generated__";

export const FindPricedItemTypes = gql(/* GraphQL */`
	query FindPricedItemTypes(
		$partnerId: String!
		$useCustomScheduleMargin: Boolean
	) {
		FindItemTypes {
			id
			name
			index
			iconUrl
			pickupFee(
				partnerId: $partnerId
				useCustomScheduleMargin: $useCustomScheduleMargin
			)
			disassemblyFee(
				partnerId: $partnerId
				useCustomScheduleMargin: $useCustomScheduleMargin
			)
			sizes {
				id
				name
				index
				iconUrl
				pickupFee(
					partnerId: $partnerId
					useCustomScheduleMargin: $useCustomScheduleMargin
				)
				disassemblyFee(
					partnerId: $partnerId
					useCustomScheduleMargin: $useCustomScheduleMargin

				)
			}
			category {
				id
				name
				index
				iconUrl
			}
			canDisassemble
			assumptions {
				weightLowPounds
				weightHighPounds
			}
			tags
			keywords
		}
	}
`);

