import { Box, BoxExtendedProps, Grid } from "grommet";
import { RoundType } from "grommet/utils";
import React, { ReactElement, Fragment, useEffect, useMemo, useCallback } from "react";
import { Button, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { replace } from "redux-first-history";
import { LoadingButton } from "@mui/lab";
import { selectSteps, updateStep } from "../store/steps";
import { useWindowDimensions } from "../hooks";

export const useSteps = (name: string) => {
	const dispatch = useAppDispatch();
	const [ params ] = useSearchParams();
	const { current, total, id } = useAppSelector(selectSteps(name));

	const hasPrev = useMemo(() => {
		return current !== 0;
	}, [ current ]);

	const hasNext = useMemo(() => {
		return current < (total - 1);
	}, [ current, total ]);

	const next = useCallback(() => {
		if(hasNext) {
			const updatedParams = new URLSearchParams(params);
			updatedParams.set(id, String(current + 1));
			dispatch(replace(window.location.pathname + "?" + updatedParams.toString()));
		}
	}, [ id, current, hasNext, params, dispatch ]);

	const prev = useCallback((): void => {
		if(hasPrev) {
			const updatedParams = new URLSearchParams(params);
			updatedParams.set(id, String(current - 1));
			dispatch(replace(window.location.pathname + "?" + updatedParams.toString()));
		}
	}, [ id, current, hasPrev, params, dispatch ]);

	const indexFromQuery = useMemo(() => {
		const index = params.get(id);
		if(isNaN(Number(index))) {
			return 0;
		}

		return Number(index);
	}, [ id, params ]);

	const force = useCallback((index: number) => {
		if(index === current) return;

		const updatedParams = new URLSearchParams(params);
		updatedParams.set(id, String(index));
		dispatch(replace(window.location.pathname + "?" + updatedParams.toString()));

		dispatch(updateStep({
			name,
			total,
			current: index
		}));
	}, [ id, params, current, dispatch, name, total ]);

	useEffect(() => {
		if(id && !params.get(id)) {
			const updatedParams = new URLSearchParams(params);
			updatedParams.set(id, String(current));
			dispatch(replace(window.location.pathname + "?" + updatedParams.toString()));
		}
	}, [ params, id, dispatch, current ]);

	useEffect(() => {
		if(indexFromQuery !== current) {
			force(indexFromQuery);
		}
	}, [ indexFromQuery, force, current ]);

	return {
		id,
		name,
		total,
		current,
		isFirst: current === 0,
		isLast: current === Math.max((total - 1), 0),
		hasNext,
		hasPrev,
		next,
		prev,
		force
	};
};

export interface StepProps {
	stepName: string;
	children: ReactElement;
}

export const Step: React.FC<StepProps> = (props) => {
	return (
		<Fragment>
			{props.children}
		</Fragment>
	);
};


interface StepControllerProps {
	id?: string;
	name: string;
	dedup?: boolean;
	suppressProgress?: boolean;
	children: ReactElement<StepProps>[];
	onStepChange?: (index: number) => void;
	props?: BoxExtendedProps;
}

export const StepController: React.FC<StepControllerProps> = (props) => {
	const dispatch = useAppDispatch();
	const { current, total, force } = useSteps(props.name);

	useEffect(() => {
		if(props.onStepChange) {
			props.onStepChange(current);
		}
	}, [ current, props ]);

	useEffect(() => {
		if(total !== props.children.length) {
			dispatch(updateStep({
				current,
				name: props.name,
				total: props.children.length
			}));
		}
	}, [ props, current, total, dispatch ]);

	function getRoundType(index: number): RoundType {
		if(index === 0) {
			return { corner: "left" };
		}

		if(index === props.children.length - 1) {
			return { corner: "right" };
		}

		return false;
	}

	const { size } = useWindowDimensions();
	const theme = useTheme();

	const mappedSteps = useMemo(() => {
		const labels = props.children.map(step => step.props.stepName);
		if(!props.dedup) return labels;

		const uniqueLabels = new Set(labels);
		return Array.from(uniqueLabels);

	}, [ props.children, props.dedup ]);

	const currentMapping = useMemo(() => {
		const labels = props.children.map(step => step.props.stepName);
		return labels.map(label => {
			const index = mappedSteps.indexOf(label);
			return index;
		});
	}, [ props.children ]);

	return (
		<Box
			flex
			margin={!props.suppressProgress ? "small" : undefined}
			{...props.props}
		>
			{!props.suppressProgress && (
				<Box margin="small" align="center" gap="small">
					<Grid columns={{ count: mappedSteps.length, size: "auto" }} fill="horizontal" gap="xsmall">
						{mappedSteps.map((step, index) => {
							return (
								<Box
									align="center"
									pad="xxsmall"
									round={getRoundType(index)}
									background={index <= currentMapping[ current ] ? theme.palette.primary : "light-2"}
									onClick={index < currentMapping[ current ] && !props.dedup ? () => force(index) : undefined}
									key={index}
								>
									{step
										? (
											<Typography
												fontWeight={current === index ? "bold" : undefined}
												fontSize={size === "small" ? "16px" : undefined}
											>
												{step}
											</Typography>
										)
										: (
											<Typography fontWeight={current === index ? "bold" : undefined}>
												Step {index + 1}
											</Typography>
										)}

								</Box>
							);
						})}
					</Grid>
				</Box>
			)}
			{props.children[ current ]}
		</Box>
	);

};


interface StepControlsProps {
	name: string;
	nextButtonLabel?: string;
	nextButtonHidden?: boolean;
	nextButtonIntercept?: () => void;
	submitButtonLabel?: string;
	submitButtonHidden?: boolean;
	previousButtonLabel?: string;
	previousButtonIntercept?: () => void;
	canProceed: boolean;
	onSubmit?: () => void;
	isLoading: boolean;
	additionalButtons?: React.ReactNode | React.ReactNode[];
}

export const StepControls: React.FC<StepControlsProps> = (props) => {
	const { next, hasNext, prev, hasPrev } = useSteps(props.name);

	return (
		<Box style={{ display: "block" }}>
			<Box justify="between" gap="small" direction="row">
				<Box align="start">
					{hasPrev && (
						<Button
							color="error"
							variant="outlined"
							onClick={props.previousButtonIntercept
								? props.previousButtonIntercept
								: prev}
						>
							{props.previousButtonLabel || "Back"}
						</Button>
					)}
				</Box>
				<Box align="end" gap="small" direction="row">
					{props.additionalButtons}
					{(hasNext && !props.nextButtonHidden) && (
						<LoadingButton
							loading={props.isLoading}
							variant="contained"
							color="primary"
							onClick={props.nextButtonIntercept
								? props.nextButtonIntercept
								: next
							}
							disabled={!props.canProceed}
						>
							{props.nextButtonLabel || "Next"}
						</LoadingButton>
					)}
					{!hasNext && !props.submitButtonHidden && (
						<LoadingButton
							loading={props.isLoading}
							variant="contained"
							color="primary"
							onClick={() => props.onSubmit?.()}
							disabled={!props.canProceed}
							type="submit"
						>
							{props.submitButtonLabel || "Submit"}
						</LoadingButton>
					)}
				</Box>
			</Box>
		</Box>
	);
};