import { Box, Heading } from "grommet";
import React from "react";
import { Chip, Typography } from "@mui/material";
import { FragmentType } from "../../graphql";
import { Pickup_HeaderFragmentFragment, Pickup_TrackingFragmentFragment } from "../../graphql/__generated__/graphql";

export const PickupDetailsContainer: React.FC<{
	loading: boolean;
	children: React.ReactNode;
	pickup: FragmentType<Pickup_HeaderFragmentFragment & Pickup_TrackingFragmentFragment> | null;
}> = ({ pickup, children }) => {
	return (
		<Box>
			<Box direction="row" justify="between">
				<Heading level="3" margin="none">
					Pickup Details
				</Heading>
				{pickup?.started && !pickup?.completed && (
					<Box justify="center" align="center">
						<Chip
							label={(
								<Typography variant="body2">
									In Progress
								</Typography>
							)}
							color="warning" />
					</Box>
				)}
				{pickup?.completed && (
					<Box justify="center" align="center">
						<Chip
							label={(
								<Typography variant="body2">
									Completed
								</Typography>
							)}
							color="success" />
					</Box>
				)}
			</Box>
			{children}
		</Box>
	);
};

/**
 * 
 * <HaulerPickupDetailsComponent
				pickup={pickup}
				loading={loading}
			/>
 * 
 */