import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import React from "react";

export interface FilterItemProps {
	title: string;
	value: string;
	details: React.ReactNode;
	expanded?: boolean;
	toggle(expanded: boolean): void;
}

export interface ActiveFilterItemProps {
	active: boolean;
	setActive: (active: boolean) => void;
}

export const FilterItem: React.FC<FilterItemProps> = (props) => {
	return (
		<Accordion
			disableGutters
			sx={{ padding: 0 }}
			expanded={props.expanded}
			onChange={(event, expanded) => {
				props.toggle(expanded);
			}}
		>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Typography sx={{ width: "33%", flexShrink: 0 }}>
					{props.title}
				</Typography>
				<Typography
					sx={{ color: "text.secondary", overflow: "clip" }}
				>{props.value}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{props.details}
			</AccordionDetails>
		</Accordion>
	);
};
