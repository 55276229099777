import { Apartment, Delete, ExpandLess, ExpandMore, HighlightOff, Home, Mail, Schedule } from "@mui/icons-material";
import { Box, Text } from "grommet";
import { buildings } from "../data";
import { neighborhoods } from "../data";
import { regions } from "../data/regions";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useInstance, useInstanceBuilding, useReportingMaterials, useWindowDimensions } from "../../hooks";
import { selectBuildingFilter, selectNeighborhoodFilter, selectTimePeriodCustom, selectTimePeriodFilter, selectWasteTypeFilter, selectZipCodeFilter, setBuildingFilter, setNeighborhoodFilter, setTimePeriodCustomEnd, setTimePeriodCustomStart, setTimePeriodFilter, setWasteTypeFilter, setZipCodeFilter } from "../../store/reporting";
import moment from "moment-timezone";
import { LocalizationProvider } from "../../provider";
import { DatePicker } from "@mui/x-date-pickers";
import { useAppDispatch, useAppSelector } from "../../store";
import { InstanceType } from "../../graphql/__generated__/graphql";

interface TimePeriodFilterRecordProps {
	label: string;
	selected: boolean;
	onClick: () => void;
}

export const TimePeriodFilterRecord: React.FC<TimePeriodFilterRecordProps> = ({ label, selected, onClick }) => {
	return (
		<ListItemButton selected={selected} onClick={onClick}>
			<ListItemText>
				<Typography fontWeight="bold">
					{label}
				</Typography>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box align="center" justify="center">
					{selected && (
						<HighlightOff />
					)}
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

interface FilterRecordProps {
	value: string;
	selected: boolean;
	onClick(): void;
}

export const FilterRecord: React.FC<FilterRecordProps> = (props) => {
	return (
		<ListItemButton selected={props.selected} onClick={props.onClick} divider>
			<ListItemText>
				<Typography fontWeight="bold">
					{props.value}
				</Typography>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box align="center" justify="center">
					{props.selected && (
						<HighlightOff />
					)}
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

export const ZipCodeFilter: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const zipCodeFilter = useAppSelector(selectZipCodeFilter);

	const { sizeIndex } = useWindowDimensions();
	const shrink = useMemo(() => {
		return sizeIndex <= 2;
	}, [ sizeIndex ]);

	return (
		<Box gap="small">
			<Box direction="row" gap="small">
				<Mail fontSize={shrink ? undefined : "large"} />
				<Box justify="center">
					<Text weight="bold">Zip Code</Text>
				</Box>
				<Box align="end" justify="center" flex>
					{zipCodeFilter.length > 0 && (
						<Text>{zipCodeFilter.length} selected</Text>
					)}
				</Box>
			</Box>
			<Box round border height="small" overflow={{ vertical: "scroll" }} pad="small">
				{regions.map(region => (
					<FilterRecord
						key={region.zipCode}
						value={region.zipCode}
						onClick={() => {
							dispatch(setZipCodeFilter(region.zipCode));
						}}
						selected={zipCodeFilter.includes(region.zipCode)}
					/>
				))}
			</Box>
		</Box>
	);
};

export const NeighborhoodFilter: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const neighborhoodFilter = useAppSelector(selectNeighborhoodFilter);

	const { sizeIndex } = useWindowDimensions();
	const shrink = useMemo(() => {
		return sizeIndex <= 2;
	}, [ sizeIndex ]);

	return (
		<Box gap="small">
			<Box direction="row" gap="small">
				<Home fontSize={shrink ? undefined : "large"} />
				<Box justify="center">
					<Text weight="bold">Neighborhood</Text>
				</Box>
				<Box align="end" justify="center" flex>
					{neighborhoodFilter.length > 0 && (
						<Text>{neighborhoodFilter.length} selected</Text>
					)}
				</Box>
			</Box>
			<Box round border height="small" overflow={{ vertical: "scroll" }} pad="small">
				{neighborhoods.map(region => (
					<FilterRecord
						key={region.name}
						value={region.name}
						onClick={() => {
							dispatch(setNeighborhoodFilter(region.name));
						}}
						selected={neighborhoodFilter.includes(region.name)}
					/>
				))}
			</Box>
		</Box>
	);
};

export const WasteTypeFilter: React.FC<{
	active: boolean;
	onClick: () => void;
}> = ({ active, onClick }) => {
	const { materials, loading } = useReportingMaterials();

	const dispatch = useAppDispatch();
	const wasteTypeFilter = useAppSelector(selectWasteTypeFilter);

	const { sizeIndex } = useWindowDimensions();
	const shrink = useMemo(() => {
		return sizeIndex <= 2;
	}, [ sizeIndex ]);

	return (
		<WasteReportingFilterListItem
			title="Material Type"
			icon={<Delete fontSize={shrink ? undefined : "large"} />}
			active={active}
			onClick={onClick}
			secondary={!!wasteTypeFilter.length && (
				<Box justify="center">
					<Typography variant="caption">
						{wasteTypeFilter.length} selected
					</Typography>
				</Box>
			)}
			content={(
				<Box overflow={{ vertical: "scroll" }} height="200px">
					<List>
						{materials.map(material => (
							<FilterRecord
								key={material.id}
								value={material.name}
								onClick={() => {
									dispatch(setWasteTypeFilter(material.id));
								}}
								selected={wasteTypeFilter.includes(material.id)}
							/>
						))}
					</List>
				</Box>
			)}
		/>
	);
};

export const BuildingFilter: React.FC<{
	active: boolean;
	onClick: () => void;
}> = ({ active, onClick }) => {
	const dispatch = useAppDispatch();
	const buildingFilter = useAppSelector(selectBuildingFilter);

	const { sizeIndex } = useWindowDimensions();
	const shrink = useMemo(() => {
		return sizeIndex <= 2;
	}, [ sizeIndex ]);

	return (
		<WasteReportingFilterListItem
			title="Building"
			icon={<Apartment fontSize={shrink ? undefined : "large"} />}
			active={active}
			onClick={onClick}
			secondary={!!buildingFilter.length && (
				<Box justify="center">
					<Typography variant="caption">
						{buildingFilter.length} selected
					</Typography>
				</Box>
			)}
			content={(
				<Box overflow={{ vertical: "scroll" }} height="200px">
					<List>
						{buildings.map(name => (
							<FilterRecord
								key={name}
								value={name}
								onClick={() => {
									dispatch(setBuildingFilter(name));
								}}
								selected={buildingFilter.includes(name)}
							/>
						))}
					</List>
				</Box>
			)}
		/>
	);
};

interface FilterContainerProps {
	timePeriodFilter?: false;
	zipCodeFilter?: false;
	neighborhoodFilter?: false;
	buildingsFilter?: false;
	wasteTypeFilter?: false;
}

export function useBuildingFilter() {
	const { instance } = useInstance();
	const { building, loading } = useInstanceBuilding();

	const isBuildingFilterEnabled = useMemo(() => {
		const type = instance?.type;
		return type === InstanceType.Residence || type === InstanceType.ManagementCompany;
	}, [ instance ]);

	return {
		loading,
		buildings: building
			? [ building ]
			: [],
		enabled: isBuildingFilterEnabled
	};
}

export function useMaterialFilter() {
	const { loading, materials } = useReportingMaterials();

	return {
		loading,
		materials,
		enabled: true
	};
}

export function useReportingFilters() {

	return {
		materials: useMaterialFilter(),
		buildings: useBuildingFilter(),
	};
}

export const FilterContainer: React.FC = () => {
	const [ active, setActive ] = useState("");

	function toggleActive(name: string) {
		setActive(active === name ? "" : name);
	}

	const {
		materials,
		buildings
	} = useReportingFilters();

	return (
		<Box
			gap="small"
			height="100%"
			overflow={{ vertical: "hidden" }}
		>
			<Box flex>
				<TimePeriodFilter
					active={active === "timePeriod"}
					onClick={() => toggleActive("timePeriod")}
				/>
				{buildings.enabled && (
					<BuildingFilter
						active={active === "buildings"}
						onClick={() => toggleActive("buildings")}
					/>
				)}
				{materials.enabled && (
					<WasteTypeFilter
						active={active === "wasteType"}
						onClick={() => toggleActive("wasteType")}
					/>
				)}
			</Box>
			{/* {!active && (
				<Box flex justify="end">
					<FormControl>
						<FormControlLabel
							label="Show Graph"
							control={(
								<Switch
									checked={showGraph}
									onChange={(event) => {
										dispatch(setShowGraph(event.target.checked));
									}}
								/>
							)}
						/>
					</FormControl>
				</Box>
			)} */}
		</Box>
	);
};

export const WasteReportingFilterListItem: React.FC<{
	active: boolean;
	onClick: () => void;
	title: string;
	icon: ReactNode;
	content: ReactNode;
	secondary?: ReactNode;
}> = ({ active, onClick, title, icon, content, secondary }) => {
	return (
		<Box>
			<ListItemButton onClick={onClick} divider>
				<ListItemIcon>
					{icon}
				</ListItemIcon>
				<ListItemText>
					<Typography fontSize="large" fontWeight="bold">
						{title}
					</Typography>
				</ListItemText>
				<ListItemSecondaryAction>
					<Box direction="row" gap="small">
						{secondary && (
							<Fragment>
								{secondary}
							</Fragment>
						)}
						<Box align="center" justify="center">
							{active && (
								<ExpandLess />
							)}
							{!active && (
								<ExpandMore />
							)}
						</Box>
					</Box>
				</ListItemSecondaryAction>
			</ListItemButton>
			<Collapse in={active} timeout="auto" unmountOnExit>
				{content}
			</Collapse>
		</Box>
	);
};

export const TimePeriodFilter: React.FC<{
	active: boolean;
	onClick: () => void;
}> = ({ active, onClick }) => {
	const dispatch = useAppDispatch();
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);
	const [ start, end ] = useAppSelector(selectTimePeriodCustom);

	const { sizeIndex } = useWindowDimensions();
	const shrink = useMemo(() => {
		return sizeIndex <= 2;
	}, [ sizeIndex ]);

	useEffect(() => {
		console.log("Start", start);
		console.log("End", end);
	}, [ start, end ]);

	return (
		<WasteReportingFilterListItem
			active={active}
			onClick={onClick}
			title="Time Period"
			icon={<Schedule fontSize={shrink ? undefined : "large"} />}
			content={(
				<Box overflow={{ vertical: "scroll" }} height="200px">
					<List>
						<TimePeriodFilterRecord
							label="Today"
							selected={timePeriodFilter === "TODAY"}
							onClick={() => dispatch(setTimePeriodFilter("TODAY"))}
						/>
						<TimePeriodFilterRecord
							label="Yesterday"
							selected={timePeriodFilter === "YESTERDAY"}
							onClick={() => dispatch(setTimePeriodFilter("YESTERDAY"))}
						/>
						<TimePeriodFilterRecord
							label="This Month"
							selected={timePeriodFilter === "THIS_MONTH"}
							onClick={() => dispatch(setTimePeriodFilter("THIS_MONTH"))}
						/>
						<TimePeriodFilterRecord
							label="Last Month"
							selected={timePeriodFilter === "LAST_MONTH"}
							onClick={() => dispatch(setTimePeriodFilter("LAST_MONTH"))}
						/>
						<TimePeriodFilterRecord
							label="Last 3 Months"
							selected={timePeriodFilter === "LAST_3_MONTHS"}
							onClick={() => dispatch(setTimePeriodFilter("LAST_3_MONTHS"))}
						/>
						<TimePeriodFilterRecord
							label="Last 6 Months"
							selected={timePeriodFilter === "LAST_6_MONTHS"}
							onClick={() => dispatch(setTimePeriodFilter("LAST_6_MONTHS"))}
						/>
						<TimePeriodFilterRecord
							label="This Year"
							selected={timePeriodFilter === "YTD"}
							onClick={() => dispatch(setTimePeriodFilter("YTD"))}
						/>
						<TimePeriodFilterRecord
							label="Last Year"
							selected={timePeriodFilter === "LAST_YEAR"}
							onClick={() => dispatch(setTimePeriodFilter("LAST_YEAR"))}
						/>
						<TimePeriodFilterRecord
							label="Custom"
							selected={timePeriodFilter === "CUSTOM"}
							onClick={() => dispatch(setTimePeriodFilter("CUSTOM"))}
						/>
					</List>
					{timePeriodFilter === "CUSTOM" && (
						<LocalizationProvider>
							<Box direction="row" justify="center" gap="medium">
								<DatePicker
									label="Start Date"
									value={start ? moment(start, "YYYY-MM-DD") : null}
									onChange={(date) => {
										if(date) {
											dispatch(setTimePeriodCustomStart(date.format("YYYY-MM-DD")));
										}
									}}
								/>
								<DatePicker
									label="End Date"
									value={end ? moment(end, "YYYY-MM-DD") : null}
									onChange={(date) => {
										if(date) {
											dispatch(setTimePeriodCustomEnd(date.format("YYYY-MM-DD")));
										}
									}}
								/>
							</Box>
						</LocalizationProvider>
					)}
				</Box>
			)}
		/>
	);
};