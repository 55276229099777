import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { Box, Heading } from "grommet";
import { UseFormReturn } from "react-hook-form";
import { TextFieldElement, SelectElement } from "react-hook-form-mui";
import { useBuildings } from "../../../../hooks";
import { WasteContainerState } from "../../../../types";

export const WasteContainerDetailsStep: React.FC<{
	formContext: UseFormReturn<WasteContainerState>;
}> = ({ formContext }) => {
	const { buildings } = useBuildings();

	const options = useMemo(() => {
		return Array.from(buildings).sort((a, b) => a.name.localeCompare(b.name)).map(building => ({
			id: building.id,
			label: building.name
		}));
	}, [ buildings ]);

	return (
		<Box gap="medium">
			<Box>
				<Heading level="3" margin="none">
					Container Details
				</Heading>
				<Typography variant="caption">
					Answer a few questions about this container and its location
				</Typography>
			</Box>
			<TextFieldElement
				name="name"
				required
				label="Container Name / Description"
				helperText="Give this container a name"
			/>
			<TextFieldElement
				name="location"
				label="Location"
				helperText="Where is this container located? (ex. Loading Dock, Parking Lot)"
			/>
			<SelectElement
				required
				name="partner"
				label="Location"
				options={options}
			/>
		</Box>
	);
};