import { gql } from "../../../__generated__";

export const FetchPricingPolicy = gql(/* GraphQL */ `
	query FetchPricingPolicy($policyId: String!) {
		FetchPricingPolicy(policyId: $policyId) {
			...PricingPolicy_HeaderFragment
			...PricingPolicy_PricingFragment
			...PricingPolicy_PartnersFragment
		}
	}
`);