import { gql } from "@apollo/client";


export const Pickup_ActiveStopFragment = gql`
	fragment Pickup_ActiveStopFragment on Pickup {
		id
		activeStop {
			...Stop_PreviewFragment
			...Stop_ProductsFragment
		}
	}
`;
