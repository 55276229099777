import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useAppSelector } from "../../../../store";
import { CreateReferenceMedia, FetchReferenceMedia, FindReferenceMedia } from "../../../../graphql/documents/media";
import { selectWasteAuditState } from "../../../../store/audit";
import { CreateReferenceMediaMutationVariables } from "../../../../graphql/__generated__/graphql";

export function useReferenceMedia(mediaId: string) {
	const { data, loading } = useQuery(FetchReferenceMedia, {
		skip: !mediaId,
		variables: { mediaId },
	});

	return {
		loading,
		media: data?.FetchReferenceMedia,
	};
}

export function useWasteAuditMedia() {
	const snack = useSnackbar();
	const { auditId } = useAppSelector(selectWasteAuditState);

	const { data, loading } = useQuery(FindReferenceMedia, {
		skip: !auditId,
		variables: { referenceId: auditId },
	});

	const [
		upload,
		{ loading: isUploading },
	] = useMutation(CreateReferenceMedia, {
		fetchPolicy: "network-only",
		refetchQueries: [
			FindReferenceMedia
		]
	});

	function handleUpload(dto: CreateReferenceMediaMutationVariables) {
		return upload({
			variables: {
				...dto,
				referenceId: auditId,
			},
		}).then(res => {
			return res.data;
		}).catch(err => {
			console.error("Failed to upload media", err);
			snack.enqueueSnackbar("We ran into an issue uploading your image", {
				variant: "error",
			});
		});
	}

	return {
		loading,
		isUploading,
		upload: handleUpload,
		media: data?.FindReferenceMedia || [],
	};
}