import { Box } from "grommet";
import { DialogWithClose } from "../../../../components/Dialog";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch } from "../../../../store";
import { clearFilters, setFiltersApplied } from "../../../../store/shop";
import { PriceFilterItem } from "./PriceFilterItem";
import { ConditionFilterItem } from "./ConditionFilterItem";
import { BrandFilterItem } from "./BrandFilterItem";

interface FilterDialogProps {
	onClose: () => void;
	active?: string;
}

export const FilterDialog: React.FC<FilterDialogProps> = (props) => {
	const dispatch = useAppDispatch();
	const [ active, setActive ] = useState(props.active);

	function handleReset(): void {
		dispatch(clearFilters());
	}

	function handleApply(): void {
		dispatch(setFiltersApplied(false));
		props.onClose();
	}

	return (
		<DialogWithClose
			title="Filter"
			disabledContentPadding
			onClose={props.onClose}
			content={(
				<Box>
					<ConditionFilterItem
						active={active === "condition"}
						setActive={(active) => setActive(active ? "condition" : "")}
					/>
					<PriceFilterItem
						active={active === "price"}
						setActive={(active) => setActive(active ? "price" : "")}
					/>
					<BrandFilterItem
						active={active === "brand"}
						setActive={(active) => setActive(active ? "brand" : "")}
					/>
					{/* <MaterialFilterItem
						active={active === "material"}
						setActive={(active) => setActive(active ? "material" : "")}
					/> */}
				</Box>
			)}
			actions={(
				<Box justify="between" direction="row">
					<Button color="error" onClick={handleReset}>
						Reset
					</Button>
					<Button color="success" variant="contained" onClick={handleApply}>
						Apply
					</Button>
				</Box>
			)}
		/>
	);
};