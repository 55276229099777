import { useLazyQuery } from "@apollo/client";
import { useHauler } from "./useHauler";
import { useEffect } from "react";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import { FetchTodaysPickups } from "../../../graphql";

export function useTodaysPickups() {
	const { hauler } = useHauler();
	const snack = useSnackbar();

	const [
		fetch,
		{ data, loading, error }
	] = useLazyQuery(FetchTodaysPickups);

	useEffect(() => {
		if(hauler) {
			fetch({
				variables: {
					haulerId: hauler.id,
					startOfDayBoundary: moment().startOf("day").format("YYYY-MM-DD"),
					endOfDayBoundary: moment().endOf("day").format("YYYY-MM-DD")
				}
			}).catch(err => {
				console.error("Failed to fetch today's pickups", err);
				snack.enqueueSnackbar("Failed to fetch today's pickups", {
					variant: "error"
				});
			});
		}
	}, [ hauler ]);

	return {
		data,
		loading,
		error,
		pickups: data?.FindPickups || []
	};
}