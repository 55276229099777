import { SvgIcon, SvgIconProps } from "@mui/material";

	export const DresserAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m149.28 1040.9h31.129l-0.003906 99.133h36v-99.133h764.94v99.133h36v-99.133h33.359c41.891 0 75.973-34.078 75.973-75.973v-828.92c0-41.895-34.078-75.973-75.961-75.973h-901.44c-41.883 0-75.961 34.078-75.961 75.973v828.94c0 41.879 34.078 75.957 75.961 75.957zm901.44-36h-901.44c-22.031 0-39.973-17.93-39.973-39.973v-238.98h981.36v238.99c0.011719 22.035-17.914 39.961-39.949 39.961zm-941.4-314.95v-278.95h981.36v278.95zm39.961-593.91h901.44c22.031 0 39.973 17.93 39.973 39.973v238.99h-981.37v-238.99c0-22.043 17.926-39.973 39.961-39.973z"/>
  <path d="m535.21 217.48h129.59v36h-129.59z"/>
  <path d="m535.21 532.44h129.59v36h-129.59z"/>
  <path d="m535.21 847.39h129.59v36h-129.59z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};