import React from "react";
import { TabContext, TabList, TabListProps } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "grommet";
import { ReactNode, useState } from "react";
import { useWindowDimensions } from "../hooks";

export interface TabListItem {
	value: string;
	label: string;
	component: ReactNode;
}

export const TabController: React.FC<{
	tabs: TabListItem[];
	defaultValue?: string;
	tabVariant?: TabListProps[ "variant" ];
}> = ({ tabs, defaultValue, tabVariant }) => {
	const { size } = useWindowDimensions();
	const [ value, setValue ] = useState(defaultValue || "");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<TabContext value={value}>
			<Box gap={size === "small" ? "large" : "medium"} flex>
				<TabList onChange={handleChange} variant={tabVariant || "scrollable"}>
					{tabs.map((tab) => (
						<Tab
							key={tab.value}
							value={tab.value}
							label={tab.label}
						/>
					))}
				</TabList>
				{tabs.find((tab) => tab.value === value)?.component}
			</Box>
		</TabContext>
	);
};