import { useMemo } from "react";
import { useClassification } from "../../../hooks";
import { useAppSelector } from "../../../store";
import { findProductInState } from "../../../store/checkout";
import { usePickupFee } from "./usePickupFee";

export function useType(typeId: string) {
	const { pickupFee, disassemblyFee, pickupFeeComparison } = usePickupFee(typeId, "");
	const { types, loading } = useClassification();
	const product = useAppSelector(findProductInState({ typeId, sizeId: "" }));

	const type = useMemo(() => {
		return types.find(t => t.id === typeId);
	}, [ typeId, types ]);

	return {
		type,
		loading,
		pickupFee,
		disassemblyFee,
		pickupFeeComparison,
		quantity: product?.quantity || 0,
		quantityDisassembly: product?.quantityDisassembly || 0,
		pickupFeeSum: pickupFee * (product?.quantity || 0),
		disassemblyFeeSum: disassemblyFee * (product?.quantityDisassembly || 0),
		pickupFeeComparisonSum: pickupFeeComparison * (product?.quantity || 0)
	};
}