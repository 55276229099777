import { SvgIcon, SvgIconProps } from "@mui/material";

	export const BedsideTable: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m600 409.8c-12.578 0-22.809 10.23-22.809 22.816 0 12.586 10.23 22.816 22.809 22.816 12.586 0 22.816-10.23 22.816-22.816 0-12.586-10.238-22.816-22.816-22.816z" />
		<path
			d="m600 691.26c-12.578 0-22.809 10.23-22.809 22.809 0 12.586 10.23 22.816 22.809 22.816 12.586 0 22.816-10.23 22.816-22.816 0-12.582-10.238-22.809-22.816-22.809z" />
		<path
			d="m292.3 548.05h615.41c14.496 0 26.25-11.754 26.25-26.25v-178.37c0-14.496-11.754-26.25-26.25-26.25h-615.41c-14.496 0-26.25 11.754-26.25 26.25v178.36c0 14.5 11.754 26.258 26.25 26.258zm26.25-178.37h562.91v125.86h-562.91z" />
		<path
			d="m292.3 829.51h615.41c14.496 0 26.25-11.754 26.25-26.25v-178.37c0-14.496-11.754-26.25-26.25-26.25h-615.41c-14.496 0-26.25 11.754-26.25 26.25v178.37c0 14.488 11.754 26.25 26.25 26.25zm26.25-178.37h562.91v125.87h-562.91z" />
		<path
			d="m1131.2 75.531h-1062.5c-14.496 0-26.25 11.754-26.25 26.25v134.39c0 14.496 11.754 26.25 26.25 26.25h78.719v835.79c0 14.496 11.754 26.25 26.25 26.25h88.051c11.094 0 20.984-6.9766 24.719-17.414l50.984-142.66h525.05l50.984 142.66c3.7344 10.449 13.629 17.414 24.719 17.414h88.051c14.496 0 26.25-11.754 26.25-26.25v-160.08l0.003906-675.71h78.719c14.496 0 26.25-11.754 26.25-26.25v-134.39c-0.007812-14.496-11.758-26.25-26.258-26.25zm-887.98 996.44h-43.305v-107.58h81.75zm756.76 0h-43.305l-38.445-107.58h81.75zm0-160.08h-800.06v-649.46h800.06zm104.97-701.96h-1010v-81.895h1010l-0.003906 81.895z" />
	</g>
</svg>
			</SvgIcon>
		);
	};