import { gql } from "../../../__generated__";

export const FindHaulerPickupRequests = gql( /* GraphQL */ `
	query FindHaulerPickupRequests(
		$pickupId: String
		$haulerId: String!
	) {
		FindHaulerPickupRequests(pickupId: $pickupId, haulerId: $haulerId) {
			id
			createdAt
		}
	}
`);