import { gql } from "../../../__generated__";

export const FetchPickup = gql(/* GraphQL */ `
	query FetchPickup(
		$pickupId: String!
	) {
		FetchPickup(
			pickupId: $pickupId
		) {
			id
			...Pickup_HeaderFragment
			...Pickup_TrackingFragment
		}
	}
`);