import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import applicationReducer from "./application";
import scheduleReducer from "./schedule";
import themeReducer from "./theme";
import stepsReducer from "./steps";
import shopReducer from "./shop";
import personalizationReducer from "./personalization";
import paymentReducer from "./payment";
import reportingReducer from "./reporting";
import calculatorReducer from "./calculator";
import checkoutReducer from "./checkout";
import onboardingReducer from "./onboarding";
import wasteAuditReducer from "./audit";

const {
	createReduxHistory,
	routerMiddleware,
	routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
	reducer: {
		audit: wasteAuditReducer,
		steps: stepsReducer,
		theme: themeReducer,
		checkout: checkoutReducer,
		application: applicationReducer,
		schedule: scheduleReducer,
		router: routerReducer,
		shop: shopReducer,
		payment: paymentReducer,
		reporting: reportingReducer,
		onboarding: onboardingReducer,
		calculator: calculatorReducer,
		personalization: personalizationReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(routerMiddleware)
});

export const history = createReduxHistory(store);
export * from "./hooks";
export * from "./application";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;