import { useEffect, useMemo } from "react";
import { DialogWithActivatorButton } from "..";
import { FormContainer, TextFieldElement, TextareaAutosizeElement, useForm } from "react-hook-form-mui";
import { Box, Grid } from "grommet";
import { AddressFragmentFragment } from "../../graphql/__generated__/graphql";

export const AddressDetailsDialog: React.FC<{
	address: Partial<AddressFragmentFragment>;
	activatorButton: React.ReactNode;
}> = ({ address, activatorButton }) => {
	const formContext = useForm({
		defaultValues: {
			...address,
			instructions: address.instructions || "",
			instructionsParking: address.instructionsParking || "",
		},
	});

	useEffect(() => {
		formContext.reset({
			...address
		});
	}, [ address ]);

	const markers = useMemo(() => {
		if(!address.lat || !address.lng) return [];

		return [ {
			lat: parseFloat(address.lat),
			lng: parseFloat(address.lng),
		} ];
	}, []);

	return (
		<DialogWithActivatorButton
			title="Address Details"
			activatorButton={activatorButton}
		>
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<TextFieldElement
						disabled
						label="Street Address"
						name="addressLineOne" />
					<TextFieldElement
						disabled
						label="Unit / Apt #"
						name="addressLineTwo" />
					<TextFieldElement
						disabled
						label="City"
						name="city" />
					<Grid columns={{ count: 2, size: "auto" }} gap="medium">
						<TextFieldElement
							disabled
							label="State"
							name="state" />
						<TextFieldElement
							disabled
							label="Zip Code"
							name="zipCode" />
					</Grid>
					{formContext.watch("instructions") && (
						<TextareaAutosizeElement
							disabled
							label="Instructions"
							rows={2}
							name="instructions" />
					)}
					{formContext.watch("instructionsParking") && (
						<TextareaAutosizeElement
							disabled
							label="Parking Instructions"
							rows={2}
							name="instructionsParking" />
					)}

					{/* <Box height="small">
                <Map
                    markers={markers}
                />
            </Box> */}
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};
