import { Box, Heading } from "grommet";
import moment, { Moment } from "moment-timezone";
import React, { useState } from "react";
import { Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, Typography } from "@mui/material";
import { Share } from "@mui/icons-material";
import { useDonationCenterDashboard } from "../../hooks/useDonationCenterDashboard";
import { TotalDonationsCard } from "./TotalDonationsCard";
import { TotalItemsDonatedCard } from "./TotalItemsDonatedCard";
import { TimePeriod } from "../../../../../types";
import { DialogWithClose } from "../../../../../components";
import { useFullScreen } from "../../../../../hooks";
import { DatePicker } from "@mui/x-date-pickers";

export const TimePeriodCustomDateDialog: React.FC<{
	from: moment.Moment;
	to: moment.Moment;
	onClose: () => void;
	onSubmit: (from: moment.Moment, to: moment.Moment) => void;
}> = ({ onClose, onSubmit, from, to }) => {
	const fullScreen = useFullScreen();

	const [ { fromState, toState }, setState ] = useState({
		fromState: from,
		toState: to
	});

	function handleSubmit() {
		onSubmit(fromState, toState);
	}

	return (
		<DialogWithClose
			title="Custom Time Period"
			onClose={onClose}
			content={(
				<Box direction="row" gap="small" justify="center">
					<DatePicker
						label="From"
						value={moment(fromState)}
						onChange={(date) => {
							setState({
								fromState: date || fromState,
								toState
							});
						}}
						shouldDisableDate={(date) => {
							if(date.isAfter(toState)) {
								return true;
							}

							return false;
						}}
					/>
					<DatePicker
						label="To"
						value={moment(toState)}
						onChange={(date) => {
							setState({
								fromState,
								toState: date || toState
							});
						}}
						shouldDisableDate={(date) => {
							if(date.isBefore(fromState)) {
								return true;
							}

							return false;
						}}
					/>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error" onClick={onClose}>
						Cancel
					</Button>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						Submit
					</Button>
				</Box>
			)}
		/>
	);
};

export const DonationCenterReportingComponent: React.FC = (props) => {
	const [ { from, to, type }, setTimePeriod ] = useState({
		from: moment().startOf("month"),
		to: moment().endOf("month"),
		type: TimePeriod.THIS_MONTH
	});

	const [ isUpdatingCustomDate, setIsUpdatingCustomDate ] = useState(false);
	const { data, loading } = useDonationCenterDashboard(from, to);

	function handleUpdatePeriod(period: TimePeriod) {
		switch(period) {
			case TimePeriod.CUSTOM:
				break;
			case TimePeriod.THIS_MONTH:
				setTimePeriod({
					from: moment().startOf("month"),
					to: moment().endOf("month"),
					type: TimePeriod.THIS_MONTH
				});
				break;
			case TimePeriod.LAST_MONTH:
				setTimePeriod({
					from: moment().subtract(1, "month").startOf("month"),
					to: moment().subtract(1, "month").endOf("month"),
					type: TimePeriod.LAST_MONTH
				});
				break;
			case TimePeriod.THIS_YEAR:
				setTimePeriod({
					from: moment().startOf("year"),
					to: moment().endOf("year"),
					type: TimePeriod.THIS_YEAR
				});
				break;
			case TimePeriod.LAST_YEAR:
				setTimePeriod({
					from: moment().subtract(1, "year").startOf("year"),
					to: moment().subtract(1, "year").endOf("year"),
					type: TimePeriod.LAST_YEAR
				});
				break;
		}
	}

	return (
		<Box gap="medium" style={{ display: "block" }}>
			{isUpdatingCustomDate && (
				<TimePeriodCustomDateDialog
					from={from}
					to={to}
					onClose={() => setIsUpdatingCustomDate(false)}
					onSubmit={(from, to) => {
						setTimePeriod({
							from,
							to,
							type: TimePeriod.CUSTOM
						});
						setIsUpdatingCustomDate(false);
					}}
				/>
			)}
			<Box gap="medium">
				<Box gap="xsmall">
					<Box direction="row" justify="between">
						<Box justify="center">
							<Heading level="3" margin="none">
								Reporting
							</Heading>
						</Box>
						<Box justify="center">
							<Button
								color="primary"
								variant="outlined"
								startIcon={<Share />}
							>
								Export
							</Button>
						</Box>
					</Box>
					{loading && (
						<LinearProgress />
					)}
				</Box>
				<Box>
					<FormControl fullWidth>
						<InputLabel id="time-period-label">Time Period</InputLabel>
						<Select
							label="Time Period"
							labelId="time-period-label"
							value={type}
							onChange={(e) => {
								console.log(e.target.value);
								handleUpdatePeriod(e.target.value as TimePeriod);
							}}
						>
							<MenuItem
								onClick={(() => {
									setIsUpdatingCustomDate(true);
								})}
								value={TimePeriod.CUSTOM}>Custom
							</MenuItem>
							<MenuItem value={TimePeriod.THIS_MONTH}>This Month</MenuItem>
							<MenuItem value={TimePeriod.LAST_MONTH}>Last Month</MenuItem>
							<MenuItem value={TimePeriod.THIS_YEAR}>This Year</MenuItem>
							<MenuItem value={TimePeriod.LAST_YEAR}>Last Year</MenuItem>
						</Select>
					</FormControl>
					<Box align="center">
						<Typography variant="caption">
							{from.format("MM/DD/YYYY")} - {to.format("MM/DD/YYYY")}
						</Typography>
					</Box>
				</Box>
			</Box>
			{/* <TotalDonorsCard
				value={data?.totalDonations ?? 0}
			/> */}
			<TotalDonationsCard
				value={data?.totalDonations ?? 0}
			/>
			<TotalItemsDonatedCard
				value={data?.totalItemsDonated ?? 0}
			/>
		</Box>
	);
};