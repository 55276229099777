import React from "react";
import { useContainers } from "../../../../hooks";
import { Typography } from "@mui/material";
import { Box, Heading, Grid } from "grommet";
import { UseFormReturn } from "react-hook-form";
import { WasteContainerState } from "../../../../types";
import { WasteContainerListItem } from "../../../container";
import { CollectionFrequencyElement, VolumeElement, VolumeUnitElement } from "../../../form";

export const WasteContainerSizeStep: React.FC<{
	formContext: UseFormReturn<WasteContainerState>;
}> = ({ formContext }) => {
	const {
		containerId,
		volumeUnit,
	} = formContext.watch();

	const { containers } = useContainers();

	return (
		<Box gap="medium">
			<Box>
				<Heading level="3" margin="none">
					Container Size
				</Heading>
				<Typography variant="caption">
					Answer a few questions about the size of this container
				</Typography>
			</Box>
			<VolumeElement
				volumeUnit={volumeUnit}
			/>
			<VolumeUnitElement />
			<Heading level="3" margin="none">
				Container Type
			</Heading>
			<Grid
				columns={{ count: containers.length, size: "auto" }}
			>
				{containers.map(container => (
					<WasteContainerListItem
						key={container.id}
						container={container}
						selected={container.id === containerId}
						onClick={() => {
							formContext.setValue("containerId", container.id);
						}}
					/>
				))}
			</Grid>
			<Box>
				<Heading level="3" margin="none">
					Collection Frequency
				</Heading>
				<Typography variant="caption">
					How often is this container collected?
				</Typography>
			</Box>
			<CollectionFrequencyElement
				formContext={formContext}
			/>
		</Box>
	);
};