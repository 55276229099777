import { Box } from "grommet";
import React, { useMemo } from "react";
import { ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { push } from "redux-first-history";
import moment from "moment-timezone";
import { useAppDispatch } from "../../../../store";
import { useOrderStatusChip } from "../../common";
import { OrderStatus, Order_HeaderFragmentFragment, Order_PickupFragmentFragment, Order_UserResidentFragmentFragment } from "../../../../graphql/__generated__/graphql";

export const OrderListItem: React.FC<{
	minHeight?: string;
	order: Order_HeaderFragmentFragment & Order_PickupFragmentFragment & Order_UserResidentFragmentFragment;
}> = ({ order, minHeight }) => {
	const dispatch = useAppDispatch();
	const chip = useOrderStatusChip(order.id);

	const subtitle = useMemo(() => {
		switch(order.status) {
			case OrderStatus.Cancelled: {
				return `Cancelled on ${moment.tz(order.cancelledAt, "UTC").format("MM/DD/YYYY")}`;
			}
			case OrderStatus.Completed: {
				return `Completed on ${moment.tz(order.completedAt, "UTC").format("MM/DD/YYYY")}`;
			}
			case OrderStatus.Pending: {
				return "Currently Pending";
			}
			case OrderStatus.InProgress: {
				return `Started at ${moment.tz(order.startedAt, "UTC").format("hh:mm A")}`;
			}
			case OrderStatus.Submitted: {
				if(!order.schedulePending && !order.scheduled) {
					return "Processing Order";
				}

				if(order.schedulePending) {
					return "Ready to Schedule";
				}

				if(order.scheduled) {
					return `${moment(order.pickup?.scheduledDate).format("MM/DD/YYYY")}`;
				}

				return "Submitted";
			}
		}
	}, [ order ]);

	return (
		<ListItemButton
			divider
			onClick={() => {
				dispatch(push(`/admin/orders/${order.id}`));
			}}
			style={{ minHeight: minHeight || "78.3px" }}
		>
			<ListItemText>
				<Box>
					<Typography fontWeight="bold">
						{order.user.fullName}
					</Typography>
					<Typography variant="caption">
						{/* Click to view more details */}
						{subtitle} | {order.productCount} item(s)
					</Typography>
				</Box>
			</ListItemText>
			<ListItemSecondaryAction>
				{chip}
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
