import { gql } from "@apollo/client";

export const WasteReport_HeaderFragment = gql`
	fragment WasteReport_Header on WasteReportFilter {
		targetDiversionRate
		totalWeightReusedPounds
		totalWeightDonatedPounds
		totalWeightRecycledPounds
		totalWeightCompostedPounds
		totalWeightDivertedPounds
		totalWeightLandfilledPounds
		totalWeightIncineratedPounds
		totalWeightGeneratedPounds
	}
`;

