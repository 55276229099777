import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Carpet: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1125 234.28c0-14.496-11.754-26.25-26.25-26.25h-681.01c-17.023-77.633-86.477-135.94-169.36-135.94-95.613 0-173.39 77.566-173.39 172.91v719.88c0 2.0859 0.30078 4.0859 0.76562 6.0312 7.5977 82.68 73.793 148.69 156.71 156.25 1.9414 0.45703 3.9375 0.75781 6.0156 0.75781h641.58c0.17188 0 0.33594-0.046875 0.51172-0.050781 1.5391-0.03125 3.0742-0.15625 4.582-0.44922 0.83984-0.16406 1.6289-0.48828 2.4453-0.73438 0.81641-0.24609 1.6484-0.41406 2.4453-0.74219 0.89844-0.36719 1.7266-0.89062 2.5742-1.3594 0.65234-0.35156 1.3359-0.62891 1.957-1.043 1.4336-0.95312 2.7812-2.0469 4.0195-3.2852l218.68-217.97c1.125-1.1172 2.0938-2.3477 2.9766-3.6133 0.26172-0.375 0.48828-0.76562 0.72656-1.1484 0.60937-0.97656 1.1484-1.9805 1.6133-3.0156 0.1875-0.42188 0.38281-0.82422 0.55469-1.2539 0.55469-1.418 1.0195-2.8594 1.3203-4.3438 0.007812-0.03125 0.015625-0.050782 0.023437-0.082032 0.30078-1.5078 0.42188-3.0469 0.45703-4.5898 0-0.17969 0.050781-0.35937 0.050781-0.53906l0.003906-649.41zm-52.5 623.16h-192.43c-14.496 0-26.25 11.754-26.25 26.25v191.72h-481.16c30.367-31.164 49.125-73.637 49.125-120.41v-694.47h650.72zm-166.18 181v-128.5h128.92l-69.668 69.441zm-657.93-913.84c66.66 0 120.89 54.016 120.89 120.41v586.22c-31.262-30.367-73.906-49.125-120.89-49.125s-89.633 18.758-120.89 49.125l-0.003906-586.23c0.007812-66.391 54.242-120.41 120.89-120.41zm-120.89 830.41c0-66.398 54.234-120.41 120.89-120.41 66.66 0 120.89 54.016 120.89 120.41 0 66.398-54.227 120.41-120.89 120.41-66.656 0-120.89-54.023-120.89-120.41z"/>
  <path d="m326.37 955c0-42.891-34.98-77.789-77.977-77.789-42.996 0-77.977 34.898-77.977 77.789s34.98 77.789 77.977 77.789c42.996 0.003906 77.977-34.902 77.977-77.789zm-118.46 0c0-22.215 18.156-40.289 40.477-40.289 22.32 0 40.477 18.074 40.477 40.289s-18.156 40.289-40.477 40.289c-22.316 0.003906-40.477-18.074-40.477-40.289z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};