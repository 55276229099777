import { gql } from "@apollo/client";

export const EstimateFragment = gql`
	fragment EstimateFragment on Estimate {
		id
		subtotalAmount
		creditAmount
		taxAmount
		discountAmount
		totalAmount
		discounts {
			amount
			description
		}
	}
`;

export const OrderEstimate_HeaderFragment = gql`
	fragment OrderEstimate_HeaderFragment  on OrderEstimate {
		id
		subtotalAmount
		creditAmount
		taxAmount
		discountAmount
		totalAmount
		discounts {
			amount
			description
		}
	}
`;