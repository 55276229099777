import { gql } from "@apollo/client";

export const Building_AddressFragment = gql`
	fragment Building_AddressFragment on Building {
		address {
			...Address_HeaderFragment
			...Address_MarketFragment
			...Address_InstructionsFragment
			...Address_GeolocationFragment
		}
		units {
			id
			unit
			bedrooms
			bathrooms
		}
	}
`;
