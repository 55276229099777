import { AutocompleteElement, TextFieldElement, ToggleButtonGroupElement } from "react-hook-form-mui";
import { Box, Text } from "grommet";
import { Divider, InputAdornment, Slider, Typography } from "@mui/material";
import { useBrands } from "../../features/product/hooks/useBrands";
import { useMaterials } from "../../features/product/hooks/useMaterials";
import { LabeledCondition, labeledConditions } from "../../features/product/hooks/useConditions";
import { useEffect, useMemo, useState } from "react";

interface ProductListingFormElementProps {
	price: number;
	priceCompare: number;
	condition: LabeledCondition | null;
	allowPickup: boolean;
	toggleAllowPickup: (value: boolean) => void;
	setBrand(name: string): void;
	setPrice(price: number): void;
}

export const ProductListingFormElement: React.FC<ProductListingFormElementProps> = (props) => {
	const brands = useBrands();
	const materials = useMaterials();

	const priceSuggestions = useMemo(() => {
		if(!props.condition) return null;
		if(!props.priceCompare) return null;

		const conditionAdjustment = props.condition?.valueAdjustment ?? 1;
		const medianPrice = Math.round(props.priceCompare * conditionAdjustment);

		return [
			Math.round(medianPrice * 0.90),
			medianPrice,
			Math.round(medianPrice * 1.10)
		];
	}, [ props.condition, props.priceCompare ]);

	const [ suggestedPrice, setSuggestedPrice ] = useState<number | null>(null);

	useEffect(() => {
		if(priceSuggestions) {
			setSuggestedPrice(priceSuggestions[ 1 ]);
		}
	}, [ priceSuggestions ]);

	return (
		<Box margin="small" gap="medium">
			<Text weight="bold">Listing Details</Text>
			<TextFieldElement
				required
				label="Listing Title"
				name="listingTitle"
				helperText="This is the title of your listing. Just a few words to describe your item."
			/>
			<TextFieldElement
				label="Listing Description"
				name="listingDescription"
				helperText="Optional. Providing more information about your item can help it to sell faster. Be sure to include any details that you think buyers would want to know."
			/>
			<AutocompleteElement
				label="Item Condition"
				name="condition"
				required
				options={labeledConditions}
				textFieldProps={{
					helperText: "Let us know the condition of your item."
				}}
			/>
			<Typography fontWeight="bold">
				Item Pricing
			</Typography>
			<TextFieldElement
				label="Retail Price"
				name="listingPriceCompare"
				type="number"
				inputMode="numeric"
				helperText="Optional. This is the price you originally paid. This can help us provide you with a suggested listing price."
				InputProps={{
					startAdornment: (<InputAdornment position="start">$</InputAdornment>)
				}}
			/>
			<TextFieldElement
				label="Listing Price"
				name="listingPrice"
				type="number"
				inputMode="numeric"
				helperText="This is the price that you want to sell your item for. You can change this later if you want."
				InputProps={{
					startAdornment: (<InputAdornment position="start">$</InputAdornment>)
				}}
			/>
			{priceSuggestions && (
				<Box margin={{ horizontal: "small" }}>
					<Box align="center">
						<Typography fontWeight="bold">
							Pricing Suggestion
						</Typography>
					</Box>
					<Box direction="row" justify="between" flex align="end">
						<Typography variant="caption" fontWeight="bold">
							(Sell faster)
						</Typography>
						<Typography variant="caption" fontWeight="bold">
							(Sell slower)
						</Typography>
					</Box>
					<Box margin={{ horizontal: "medium" }}>
						<Slider
							min={priceSuggestions[ 0 ]}
							max={priceSuggestions[ 2 ]}
							marks={priceSuggestions.map(t => ({
								value: t,
								label: <Typography fontWeight="bold" variant="caption">${t}</Typography>
							}))}
							valueLabelDisplay="auto"
							slotProps={{

							}}
							value={suggestedPrice ?? 0}
							onChange={(event, value) => {
								setSuggestedPrice(value as number);
								props.setPrice(value as number);
							}}
							defaultValue={priceSuggestions[ 1 ]}
						/>
					</Box>
				</Box>
			)}
			<Typography fontWeight="bold">
				Brand and Materials
			</Typography>
			<AutocompleteElement
				label="Item Brand"
				name="brand"
				options={brands.map(t => t.name)}
				textFieldProps={{
					onChange: (event) => {
						props.setBrand(event.target.value);
					},
					helperText: "Optional. If you don't see your brand in the list, go ahead and enter your own."
				}}
				autocompleteProps={{
					freeSolo: true,
					onChange: (event, value) => {
						props.setBrand(value);
					},
					groupBy: option => String(option).charAt(0).toUpperCase()
				}}
			/>
			<AutocompleteElement
				label="Item Materials"
				name="materials"
				multiple
				options={materials.map(t => t.name)}
				textFieldProps={{
					helperText: "Optional. Let us know if your item has any of these materials."
				}}
				showCheckbox
				autocompleteProps={{
					isOptionEqualToValue: (option, value) => {
						return option === value;
					}
				}}
			/>
			<Box gap="xsmall">
				<Typography fontWeight="bold">
					Are you okay with buyers picking up this item from your home?
				</Typography>
				<Typography variant="caption">
					Optional. Delivery options are available, but some buyers may prefer to pickup on their own to save on the delivery fee.
				</Typography>
			</Box>
			<ToggleButtonGroupElement
				name="allowPickup"
				exclusive
				color="primary"
				size="large"
				value={props.allowPickup}
				onChange={(event, value) => props.toggleAllowPickup(value)}
				options={[
					{
						id: "true",
						label: "Yes",
					},
					{
						id: "false",
						label: "No"
					}
				]}
			/>
			<Divider />
		</Box>
	);
};
