import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Dryer: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m667.27 831.01c102.21 0 185.36-83.152 185.36-185.36 0-102.21-83.152-185.36-185.36-185.36-102.21 0-185.36 83.145-185.36 185.36 0 102.21 83.152 185.36 185.36 185.36zm0-333.23c81.531 0 147.86 66.328 147.86 147.86 0 81.531-66.328 147.86-147.86 147.86-81.531 0-147.86-66.328-147.86-147.86 0.003906-81.516 66.332-147.86 147.86-147.86z" />
		<path
			d="m1040.8 38.676h-747.15c-49.574 0-89.926 40.273-89.926 89.852v603.45h52.5l-0.003906-449.92h822v689.62c0 20.551-16.801 37.352-37.426 37.352h-593.77l-6.5234 52.5h560.48v47.176h-589.11c3.3906-7.6719 5.7734-15.906 6.8633-24.594l2.8281-22.582h0.046875l6.0312-48.504 12.426-99.211c0.9375-7.4766-1.3789-14.992-6.3672-20.641-4.9805-5.6406-12.148-8.8789-19.68-8.8789h-13.762c0.49609-2.9414 0.81641-5.9336 0.81641-9.0078 0-13.477-4.9961-25.793-13.191-35.266 8.1914-9.4727 13.191-21.781 13.191-35.258 0-29.781-24.227-54.008-54.008-54.008l-90.836 0.003906v-0.03125h-52.5v0.03125h-35.527c-29.781 0-54.008 24.227-54.008 54.008 0 13.477 4.9961 25.785 13.191 35.258-8.1914 9.4727-13.191 21.789-13.191 35.266 0 3.0742 0.31641 6.0742 0.81641 9.0078h-19.566c-7.5312 0-14.699 3.2383-19.68 8.8789-4.9883 5.6484-7.3047 13.164-6.3672 20.641l21.336 170.29c5.4961 44.016 43.109 77.211 87.48 77.211h153.03c0.82422 0 1.6289-0.089844 2.4453-0.11328h693.54c14.551 0 26.25-11.773 26.25-26.25v-74.324c43.648-6.2266 77.25-43.727 77.25-88.949v-843.15c0-49.574-40.348-89.852-89.922-89.852zm-872.61 749.58h178.86c9.1055 0 16.508 7.4023 16.508 16.508s-7.4023 16.508-16.508 16.508h-178.86c-9.1055 0-16.508-7.4023-16.508-16.508 0.003906-9.1055 7.4062-16.508 16.508-16.508zm0 70.523h178.86c9.1055 0 16.508 7.4102 16.508 16.516s-7.4023 16.508-16.508 16.508l-178.86-0.003907c-9.1055 0-16.508-7.4023-16.508-16.508 0.003906-9.1016 7.4062-16.512 16.508-16.512zm-25.379 218.8-17.641-140.78h259.09l-17.641 140.78c-2.2344 17.812-17.445 31.238-35.391 31.238h-153.02c-17.949 0-33.168-13.426-35.395-31.238zm113.41-848.03v-101.03c0-20.625 16.801-37.352 37.426-37.352h747.15c20.625 0 37.426 16.727 37.426 37.352v101.03z" />
		<path
			d="m663.7 936.8c108.19 0 202.73-59.34 252.95-147.14h44.844c37.352 0 67.738-30.391 67.738-67.738v-152.75c0-37.352-30.391-67.738-67.738-67.738h-44.844c-50.219-87.801-144.75-147.14-252.95-147.14-160.6 0-291.25 130.65-291.25 291.25-0.003906 160.59 130.65 291.26 291.25 291.26zm297.79-382.87c8.3984 0 15.238 6.8398 15.238 15.238v152.74c0 8.3984-6.8398 15.238-15.238 15.238h-21.375c9.5781-28.828 14.844-59.609 14.844-91.613s-5.2656-62.781-14.844-91.613l21.375 0.003906zm-297.79-147.13c131.66 0 238.75 107.1 238.75 238.75 0 131.66-107.1 238.75-238.75 238.75-131.66 0-238.75-107.1-238.75-238.75-0.003906-131.65 107.1-238.75 238.75-238.75z" />
		<path
			d="m486.25 112.9c-26.168 0-47.453 21.285-47.453 47.453 0 26.168 21.285 47.453 47.453 47.453 26.168 0 47.453-21.285 47.453-47.453 0-26.168-21.277-47.453-47.453-47.453zm0 57.402c-5.4844 0-9.9531-4.4609-9.9531-9.9531 0-5.4883 4.4688-9.9531 9.9531-9.9531s9.9531 4.4609 9.9531 9.9531c0 5.4922-4.4609 9.9531-9.9531 9.9531z" />
		<path
			d="m364.64 112.9c-26.168 0-47.453 21.285-47.453 47.453 0 26.168 21.285 47.453 47.453 47.453s47.453-21.285 47.453-47.453c-0.003906-26.168-21.289-47.453-47.453-47.453zm0 57.402c-5.4844 0-9.9531-4.4609-9.9531-9.9531 0-5.4883 4.4688-9.9531 9.9531-9.9531s9.9531 4.4609 9.9531 9.9531c-0.003906 5.4922-4.4727 9.9531-9.9531 9.9531z" />
		<path
			d="m607.88 112.9c-26.168 0-47.453 21.285-47.453 47.453 0 26.168 21.285 47.453 47.453 47.453 26.168 0 47.453-21.285 47.453-47.453 0-26.168-21.285-47.453-47.453-47.453zm0 57.402c-5.4844 0-9.9531-4.4609-9.9531-9.9531 0-5.4883 4.4688-9.9531 9.9531-9.9531 5.4844 0 9.9531 4.4609 9.9531 9.9531 0 5.4922-4.4648 9.9531-9.9531 9.9531z" />
		<path
			d="m1003 112.9h-216.02c-10.359 0-18.75 8.3906-18.75 18.75v57.406c0 10.359 8.3906 18.75 18.75 18.75h216.02c10.359 0 18.75-8.3906 18.75-18.75v-57.406c0-10.359-8.3945-18.75-18.75-18.75zm-18.75 57.402h-178.52v-19.906h178.52z" />
	</g>
</svg>
			</SvgIcon>
		);
	};