import { SvgIcon, SvgIconProps } from "@mui/material";

	export const CoatRack: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m940.79 1034.5-216.22-79.695c-10.469-3.8711-19.281-11.324-24.816-21.008-7.0508-12.328-17.348-22.141-29.406-28.777v-88.77h-52.5v79.246h-35.699l-0.003906-79.246h-52.5v88.77c-12.059 6.6445-22.359 16.453-29.398 28.777-5.5352 9.6758-14.348 17.137-24.809 21l-216.23 79.703c-34.328 12.66-54.148 47.902-47.121 83.805 2.7656 14.145 11.488 26.414 23.941 33.676 12.449 7.2734 27.441 8.8281 41.102 4.2617l252.52-84.105v60.457c0 14.477 11.699 26.25 26.25 26.25h88.199c14.551 0 26.25-11.773 26.25-26.25v-60.457l252.52 84.113c5.1602 1.7188 10.5 2.5664 15.824 2.5664 8.7969 0 17.527-2.3164 25.281-6.832 12.441-7.2656 21.156-19.539 23.918-33.676 7.0273-35.906-12.777-71.148-47.105-83.809zm-295.05-20.492h-45.031-1.4102-45.031c-10.77 0-21.398 1.7188-31.598 5.1289l-259.41 86.398c-0.66797-9.5117 4.957-18.391 14.113-21.766l216.24-79.711c22.035-8.1289 40.574-23.828 52.223-44.211 4.1758-7.3125 12-11.852 20.414-11.852h67.5c8.4141 0 16.246 4.5391 20.422 11.852 11.648 20.387 30.195 36.082 52.23 44.219l216.23 79.703c9.1641 3.375 14.781 12.262 14.113 21.766l-259.4-86.398c-10.207-3.4102-20.844-5.1289-31.598-5.1289zm-63.594 92.34v-39.84h17.145 1.4102 17.145v39.84z" />
		<path
			d="m441.12 323.57h88.527v421.43l-121.44-0.003907c-14.496 0-26.25 11.754-26.25 26.25 0 14.496 11.754 26.25 26.25 26.25h383.57c14.496 0 26.25-11.754 26.25-26.25 0-14.496-11.754-26.25-26.25-26.25h-121.43v-421.43h88.531c87.766 0 159.16-71.398 159.16-159.16v-41.055c0-14.496-11.754-26.25-26.25-26.25s-26.25 11.754-26.25 26.25v41.055c0 57.742-46.148 104.8-103.48 106.5 25.523-28.238 41.219-65.527 41.219-106.5v-41.055c0-14.496-11.754-26.25-26.25-26.25s-26.25 11.754-26.25 26.25v41.055c0 49.746-34.281 91.531-80.43 103.26v-156.07c0-38.852-31.574-70.426-70.352-70.426-38.773 0-70.352 31.574-70.352 70.426v156.07c-46.148-11.738-80.43-53.512-80.43-103.26v-41.055c0-14.496-11.754-26.25-26.25-26.25s-26.25 11.754-26.25 26.25v41.055c0 40.973 15.699 78.254 41.219 106.5-57.328-1.7031-103.48-48.758-103.48-106.5v-41.055c0-14.496-11.754-26.25-26.25-26.25-14.496 0-26.25 11.754-26.25 26.25v41.055c0 87.758 71.402 159.16 159.16 159.16zm158.88-229.89c9.8242 0 17.852 8.0234 17.852 17.926v633.39h-35.699l-0.003906-633.39c0-9.9023 8.0273-17.926 17.852-17.926z" />
	</g>
</svg>
			</SvgIcon>
		);
	};