import { gql } from "../../../__generated__";

export const FetchEstimatePreviewOnSchedule = gql(/* GraphQL */ `
	query FetchEstimatePreviewOnSchedule(
		$orderId: String!
		$pickupId: String
	) {
		FetchEstimatePreviewOnSchedule(
			orderId: $orderId
			pickupId: $pickupId
		) {
			...EstimatePreview_HeaderFragment
		}
	}
`);

export const GenerateEstimateOnSchedule = gql(/* GraphQL */ `
	mutation GenerateEstimateOnSchedule(
		$orderId: String!
		$pickupId: String
	) {
		GenerateEstimateOnSchedule(
			orderId: $orderId
			pickupId: $pickupId
		) {
			...OrderEstimate_HeaderFragment
		}
	}
`);