import { Route, Routes } from "react-router";
import { ShopView, SellerDashboardView, PurchaseCartView, ProductDetailsView } from "./views";

export const ShopRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="" element={<ShopView />} />
			<Route path="dashboard" element={<SellerDashboardView />} />
			<Route path="cart" element={<PurchaseCartView />} />
			<Route path="/product/:productId" element={<ProductDetailsView />} />
		</Routes>
	);
};