import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Sideboard: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m1156.8 287.87c-4.8594-7.3359-13.074-11.746-21.879-11.746l-1069.8 0.003906c-8.7969 0-17.016 4.4102-21.879 11.746-4.8594 7.3359-5.7305 16.621-2.3008 24.719l41.684 98.641v486.39c0 14.496 11.754 26.25 26.25 26.25l108.51-0.007813c14.496 0 26.25-11.754 26.25-26.25v-82.262h712.8v82.262c0 14.496 11.754 26.25 26.25 26.25h108.52c14.496 0 26.25-11.754 26.25-26.25v-486.39l41.684-98.633c3.4219-8.1016 2.5586-17.387-2.3008-24.723zm-939.45 474.99h-82.262v-31.621h929.83v31.621zm354.94-330.7v246.58h-328.69v-246.58zm384.11 0v246.58h-331.61v-246.58zm108.52 246.58h-56.016l-0.003906-246.58h56.016zm-873.82 0h-56.016l-0.003907-246.58h56.016zm904.22-350.11-21.562 51.039-91.113-0.003907h-856.41l-21.57-51.039zm-904.22 542.74h-56.016v-56.012h56.016zm873.82 0h-56.016v-56.012h56.016z" />
		<path
			d="m531.25 555.46c0 12.598-10.211 22.809-22.805 22.809-12.598 0-22.809-10.211-22.809-22.809 0-12.598 10.211-22.809 22.809-22.809 12.594 0 22.805 10.211 22.805 22.809" />
		<path
			d="m691.55 578.27c12.586 0 22.816-10.23 22.816-22.816 0-12.586-10.23-22.816-22.816-22.816-12.578 0-22.809 10.23-22.809 22.816 0 12.586 10.238 22.816 22.809 22.816z" />
	</g>
</svg>
			</SvgIcon>
		);
	};