import { gql } from "../../../__generated__";

export const FetchReferenceMedia = gql(/* GraphQL */ `
	query FetchReferenceMedia($mediaId: String!) {
		FetchReferenceMedia(mediaId: $mediaId) {
			id
			name
			contentUrl
			contentType
			createdAt
		}
	}
`);