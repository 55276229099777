import { gql } from "../../../__generated__";

export const FindWasteTickets = gql(/* GraphQL */ `
	query FindWasteTickets(
		$date: iDateFilters
		$ticketNumber: iStringFilters
		$processed: iBooleanFilters
		$processedAt: iDateFilters
		$partner: FindPartnerDTO
		$instance: FindInstanceDTO
	) {
		FindWasteTickets(
			date: $date
			ticketNumber: $ticketNumber
			processed: $processed
			processedAt: $processedAt
			partner: $partner
			instance: $instance
		) {
			...WasteTicket_HeaderFragment
		}
	}
`);

export const FindPendingWasteTickets = gql(/* GraphQL */ `
	query FindPendingWasteTickets($instanceId: String!) {
		FindWasteTickets(
			processed: { equals: false }
			instance: { id: { equals: $instanceId } }
		) {
			...WasteTicket_HeaderFragment
		}
	}
`);

export const FindProcessedWasteTickets = gql(/* GraphQL */ `
	query FindProcessedWasteTickets($instanceId: String!) {
		FindWasteTickets(
			processed: { equals: true }
			instance: { id: { equals: $instanceId } }
		) {
			...WasteTicket_HeaderFragment
		}
	}
`);

export const FindWasteTicketsByPartner = gql(/* GraphQL */ `
	query FindWasteTicketsByPartner($partnerId: String!) {
		FindWasteTicketsByPartner(partnerId: $partnerId) {
			...WasteTicket_HeaderFragment
		}
	}
`);

export const FindWasteTicketsByInstance = gql(/* GraphQL */ `
	query FindWasteTicketsByInstance($instanceId: String!) {
		FindWasteTicketsByInstance(instanceId: $instanceId) {
			...WasteTicket_HeaderFragment
		}
	}
`);