import React from "react";
import { Email, PhoneIphoneSharp, Textsms } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ReactNode } from "react";
import { config } from "../../config";

export const ContactButton: React.FC<{
	href: string;
	label: string;
	icon: ReactNode;
}> = ({ label, href, icon }) => {
	return (
		<Button
			variant="contained"
			color="secondary"
			endIcon={icon}
			href={href}
		>
			{label}
		</Button>
	);
};

export const EmailUsButton: React.FC = () => {
	return (
		<ContactButton
			href={`mailto:${config.defaultEmail}`}
			label="Email Us"
			icon={<Email />}
		/>
	);
};

export const CallUsButton: React.FC = () => {
	return (
		<ContactButton
			href={`tel:${config.defaultPhone}`}
			label="Call Us"
			icon={<PhoneIphoneSharp />}
		/>
	);
};

export const TextUsButton: React.FC = () => {
	return (
		<ContactButton
			href={`sms:${config.defaultPhone}`}
			label="Text Us"
			icon={<Textsms />}
		/>
	);
};