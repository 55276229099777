import { gql } from "../../../__generated__";

export const CompleteWasteContainerInspection = gql(/* GraphQL */`
	mutation CompleteWasteContainerInspection(
		$inspectionId: String!
		$media: CreateMediaDTO!
	) {
		CompleteWasteContainerInspection(
			inspectionId: $inspectionId
			media: $media
		) {
			id
		}
	}
`);