import React, { useMemo } from "react";
import { BasicListItem } from "../../../../components";
import { LocalParking } from "@mui/icons-material";
import { useStop } from "../../../../graphql";

export const StopParkingInstructionsComponent: React.FC<{
	stopId: string;
	hideIfNotProvided?: boolean;
}> = ({ stopId, hideIfNotProvided }) => {
	const { stop } = useStop(stopId);

	const address = useMemo(() => {
		return stop?.address || null;
	}, [ stop ]);

	if(!address?.instructionsParking && hideIfNotProvided) {
		return <></>;
	}

	return (
		<BasicListItem
			allowMultiLine
			icon={<LocalParking />}
			label="Parking Instructions"
			value={address?.instructionsParking || "Not Provided"} />
	);
};
