import { Box } from "grommet";
import { Button, Divider, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectFilters, setPriceHighFilter, setPriceLowFilter } from "../../../../store/shop";
import { formatCurrency } from "../../../../helpers";
import { ActiveFilterItemProps, FilterItem } from "./FilterItem";

export const PriceFilterItem: React.FC<ActiveFilterItemProps> = (props) => {
	const dispatch = useAppDispatch();
	const { priceHighFilter, priceLowFilter } = useAppSelector(selectFilters);

	const priceFilterValue = useMemo(() => {
		if(priceLowFilter && priceHighFilter) {
			return `${formatCurrency(priceLowFilter)} - ${formatCurrency(priceHighFilter)}`;
		}

		if(priceHighFilter && !priceLowFilter) return `Under ${formatCurrency(priceHighFilter)}`;
		if(!priceHighFilter && priceLowFilter) return `Over ${formatCurrency(priceLowFilter)}`;

		return "";
	}, [ priceHighFilter, priceLowFilter ]);

	const renderComponent = useMemo(() => (
		<Box>
			<Box direction="row" justify="between" gap="medium" margin={{ horizontal: "medium" }}>
				<Box>
					<TextField
						label="Price Min"
						value={priceLowFilter}
						InputProps={{
							startAdornment: "$"
						}}
						onChange={(event) => {
							const sanitized = event.target.value.replace(/[^0-9,.]/g, "");
							dispatch(setPriceLowFilter(Number(sanitized)));
						}} />
				</Box>
				<Box flex align="center" justify="center">
					<Divider />
				</Box>
				<Box>
					<TextField
						label="Price Max"
						value={priceHighFilter}
						InputProps={{
							startAdornment: "$"
						}}
						onChange={(event) => {
							const sanitized = event.target.value.replace(/[^0-9,.]/g, "");
							dispatch(setPriceHighFilter(Number(sanitized)));
						}} />
				</Box>
			</Box>
			<Box pad="small" align="start">
				<Button
					color="error"
					onClick={() => {
						dispatch(setPriceLowFilter(null));
						dispatch(setPriceHighFilter(null));
					}}
				>
					Clear
				</Button>
			</Box>
		</Box>
	), [ priceHighFilter, priceLowFilter, dispatch, priceFilterValue ]);

	return (
		<FilterItem
			title="Price"
			value={priceFilterValue}
			details={renderComponent}
			expanded={props.active}
			toggle={props.setActive} />
	);
};
