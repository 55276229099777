import { SvgIcon, SvgIconProps } from "@mui/material";

	export const KitchenSink: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m284.98 340.39c6.6289 0 12-5.3672 12-12v-103.76c0-6.6328-5.3711-12-12-12h-45.777c-35.223 0-63.871 28.652-63.871 63.879s28.652 63.879 63.871 63.879zm-85.648-63.879c0-21.984 17.891-39.879 39.871-39.879h33.773v79.758h-33.773c-21.98 0-39.871-17.895-39.871-39.879z"/>
  <path d="m553.97 340.39c35.223 0 63.871-28.652 63.871-63.879s-28.652-63.879-63.871-63.879h-45.773c-6.6289 0-12 5.3672-12 12v103.76c0 6.6328 5.3711 12 12 12zm-33.773-103.76h33.773c21.984 0 39.871 17.895 39.871 39.879s-17.891 39.879-39.871 39.879h-33.773z"/>
  <path d="m971.74 387.68h-511.28v-160c0-6.6328-5.3711-12-12-12h-103.75c-6.6289 0-12 5.3672-12 12v160h-104.45c-59.695 0-108.26 48.562-108.26 108.25v380.13c0 59.695 48.562 108.26 108.26 108.26h743.48c59.695 0 108.26-48.562 108.26-108.26v-380.13c0-59.684-48.562-108.25-108.26-108.25zm-575.15 153.66c22.883 0 42.832-12.195 54.113-30.328h59.797c19.852 0 36 16.148 36 36v279.11c0 19.852-16.148 36-36 36h-227.83c-19.852 0-36-16.148-36-36v-279.11c0-19.852 16.148-36 36-36h59.801c11.285 18.133 31.238 30.328 54.117 30.328zm-39.879-301.65h79.754v237.77c0 21.984-17.891 39.879-39.871 39.879-21.988 0-39.879-17.895-39.879-39.879zm699.29 636.38c0 46.465-37.797 84.258-84.258 84.258h-743.48c-46.461 0-84.258-37.793-84.258-84.258v-380.13c0-46.453 37.797-84.246 84.258-84.246h104.45v65.777c0 3.2695 0.48828 6.4102 0.96484 9.5508h-51.004c-33.082 0-60 26.918-60 60v279.11c0 33.082 26.918 60 60 60h227.83c33.082 0 60-26.918 60-60l0.003906-279.11c0-33.082-26.918-60-60-60h-51.004c0.47656-3.1406 0.96484-6.2812 0.96484-9.5508v-65.777h511.28c46.461 0 84.258 37.793 84.258 84.246z"/>
  <path d="m396 774.29c48.691 0 88.301-39.609 88.301-88.301 0-48.691-39.609-88.301-88.301-88.301s-88.301 39.609-88.301 88.301c0 48.691 39.609 88.301 88.301 88.301zm0-152.6c35.453 0 64.301 28.84 64.301 64.301 0 35.461-28.848 64.301-64.301 64.301s-64.301-28.84-64.301-64.301c0-35.461 28.848-64.301 64.301-64.301z"/>
  <path d="m917.33 487.01h-227.83c-33.082 0-60 26.918-60 60v279.11c0 33.082 26.918 60 60 60h227.83c33.082 0 60-26.918 60-60v-279.11c0.003906-33.082-26.914-60-59.996-60zm36 339.11c0 19.852-16.148 36-36 36h-227.83c-19.852 0-36-16.148-36-36v-279.11c0-19.852 16.148-36 36-36h227.83c19.852 0 36 16.148 36 36v279.11z"/>
  <path d="m883.22 584.2h-159.62c-6.6289 0-12 5.3672-12 12s5.3711 12 12 12h159.62c6.6289 0 12-5.3672 12-12s-5.3711-12-12-12z"/>
  <path d="m883.22 673.99h-159.62c-6.6289 0-12 5.3672-12 12 0 6.6328 5.3711 12 12 12h159.62c6.6289 0 12-5.3672 12-12 0-6.6328-5.3711-12-12-12z"/>
  <path d="m883.22 763.78h-159.62c-6.6289 0-12 5.3672-12 12s5.3711 12 12 12h159.62c6.6289 0 12-5.3672 12-12s-5.3711-12-12-12z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};