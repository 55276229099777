import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Vase: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m963 523.87c-17.625-24.188-40.312-45.188-62.25-65.438-54.938-50.625-102.38-94.5-78.188-180.37 7.5-26.438 21.75-51.75 42.375-75.188 5.625-6.5625 8.4375-14.438 9-22.5 26.812-7.125 46.875-31.5 46.875-60.75 0-34.875-28.125-63-62.625-63h-515.81c-34.5 0-62.625 28.312-62.625 63 0 29.062 19.688 53.25 46.125 60.562 0.375 8.25 3.1875 16.125 9 22.688 20.625 23.438 34.875 48.938 42.375 75.188 24.375 86.062-23.062 129.74-78.188 180.56-21.938 20.25-44.812 41.25-62.25 65.438-55.875 77.062-86.812 172.31-86.812 268.31 0 127.69 53.625 250.31 147.37 336.37 10.5 9.5625 24.188 14.812 38.812 14.812h528c14.625 0 28.312-5.25 38.812-14.812 93.375-86.062 147-208.69 147-336.37 0-96-30.938-191.26-87-268.5zm-645.56-404.63c0-14.062 11.25-25.5 25.125-25.5h515.81c13.875 0 25.125 11.438 25.125 25.5s-11.25 25.5-25.125 25.5h-5.25c-4.875-2.25-10.125-3.9375-15.75-3.9375h-474.74c-5.625 0-10.875 1.5-15.75 3.9375h-4.3125c-13.875 0.1875-25.125-11.25-25.125-25.5zm-50.062 426.56c15.375-21.188 35.812-39.938 57.375-59.812 55.875-51.562 119.44-110.06 88.688-218.26-9-31.875-25.875-62.062-50.812-89.25l474.19-0.5625c-24.375 27.75-41.25 57.938-50.25 89.812-30.562 108.19 32.812 166.69 88.688 218.26 21.562 19.875 42 38.812 57.375 59.812 51.562 70.875 79.875 158.44 79.875 246.56 0 76.125-20.812 150.19-59.062 214.5h-706.87c-38.25-64.125-59.062-138.37-59.062-214.5 0-88.125 28.312-175.69 79.875-246.56zm609.94 555.37c-3.5625 3.375-8.25 5.0625-13.312 5.0625h-528c-5.0625 0-9.9375-1.6875-13.312-5.0625-18.938-17.438-35.812-36.562-51-56.812h656.81c-15.188 20.438-32.25 39.562-51.188 56.812z"/>
</svg>
			</SvgIcon>
		);
	};