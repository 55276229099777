import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ConfirmLogin } from "../../graphql";
import { AccessTokenResponseDto } from "../../graphql/__generated__/graphql";


export function useConfirmLogin() {
	const [ execute, { data, error, loading } ] = useMutation(ConfirmLogin);

	const [ state, setState ] = useState({
		errorMessage: "",
		wasSuccessful: false,
		accessToken: "",
		refreshToken: ""
	});

	const confirmLogin = useCallback((
		code: string,
		username: string,
		instanceId: string,
		callback: (response: Omit<AccessTokenResponseDto, "tokenType">) => void
	) => {
		execute({
			variables: {
				code,
				username,
				instanceId
			}
		}).then(res => {
			if(res.data?.Login) {
				callback(res.data.Login);
			}
		}).catch(err => {
			console.error("Failed to confirm login", err);
		});
	}, [ execute ]);

	useEffect(() => {
		if(data?.Login.accessToken) {
			setState((state) => ({
				...state,
				wasSuccessful: true,
				errorMessage: "",
				accessToken: data.Login.accessToken,
				refreshToken: data.Login.refreshToken
			}));
		}
	}, [ data ]);

	return {
		confirmLogin,
		isConfirming: loading,
		accessToken: state.accessToken,
		refreshToken: state.refreshToken,
		wasSuccessful: state.wasSuccessful,
		confirmLoginError: error ? "We ran into an issue logging you in. Confirm your code is correct and try again." : "",
	};
}
