import { recordSavedOrder, removeSavedOrder, selectSavedOrders } from "../../../../store/personalization";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useQuery } from "@apollo/client";
import { FetchOrderExpandedMany } from "../../../../graphql";


export function useSavedOrders() {
	const dispatch = useAppDispatch();
	const savedOrders = useAppSelector(selectSavedOrders);

	const { data, loading } = useQuery(FetchOrderExpandedMany, {
		variables: { orderIds: savedOrders.map(o => o.id) }
	});

	function toggleSelectedOrder(order: { id: string; }) {
		if(savedOrders.some(o => o.id === order.id)) {
			dispatch(removeSavedOrder(order.id));
		}
		else {
			dispatch(recordSavedOrder(order.id));
		}
	}

	function handleSelection(selection: string[]) {
		for(const order of savedOrders) {
			if(!selection.some(s => s === order.id)) {
				dispatch(removeSavedOrder(order.id));
			}
		}

		for(const id of selection) {
			if(!savedOrders.some(o => o.id === id)) {
				dispatch(recordSavedOrder(id));
			}
		}
	}

	return {
		savedOrders,
		loading,
		orders: data?.FetchOrderMany ?? [],
		toggle: toggleSelectedOrder,
		toggleSelection: handleSelection
	};
}
