import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { useSnackbar } from "notistack";
import { BeginOnboardingMutation, BeginBuildingOnboardingMutation } from "../../../graphql/__generated__/graphql";
import { BeginOnboardingDocument, BeginOnboardingMutationType, BeginOnboardingVariables } from "./types";
import { useOnboardingNotifications } from "./useOnboardingNotifications";

export function isBeginOnboardingMutation(
	mutation: BeginOnboardingMutation | BeginBuildingOnboardingMutation | null | undefined
): mutation is BeginOnboardingMutation {
	return !!(mutation as BeginOnboardingMutation)?.BeginOnboarding;
}

export function isBeginBuildingOnboardingMutation(
	mutation: BeginOnboardingMutation | BeginBuildingOnboardingMutation | null | undefined
): mutation is BeginBuildingOnboardingMutation {
	return !!(mutation as BeginBuildingOnboardingMutation)?.BeginBuildingOnboarding;
}

export function useBeginOnboarding<T extends BeginOnboardingDocument>(document: T) {
	const snack = useSnackbar();
	const [
		execute, { loading, error, data }
	] = useMutation<
		BeginOnboardingMutationType,
		BeginOnboardingVariables
	>(document);

	const hasError = useMemo(() => {
		if(isBeginOnboardingMutation(data)) {
			return !!data.BeginOnboarding.hasError;
		}

		if(isBeginBuildingOnboardingMutation(data)) {
			return !!data.BeginBuildingOnboarding.hasError;
		}

		return false;
	}, [ data ]);

	const wasCodeSent = useMemo(() => {
		if(isBeginOnboardingMutation(data)) {
			return data.BeginOnboarding.wasCodeSent;
		}

		if(isBeginBuildingOnboardingMutation(data)) {
			return data.BeginBuildingOnboarding.wasCodeSent;
		}

		return false;
	}, [ data ]);

	const exists = useMemo(() => {
		if(isBeginOnboardingMutation(data)) {
			return data.BeginOnboarding.exists;
		}

		if(isBeginBuildingOnboardingMutation(data)) {
			return data.BeginBuildingOnboarding.exists;
		}

		return false;
	}, [ data ]);

	const message = useMemo(() => {
		if(isBeginOnboardingMutation(data)) {
			return data.BeginOnboarding.message;
		}

		if(isBeginBuildingOnboardingMutation(data)) {
			return data.BeginBuildingOnboarding.message;
		}

		return "";
	}, [ data ]);

	const isSendingCode = useMemo(() => {
		return loading;
	}, [ loading ]);

	const [ canResendCode, setCanResendCode ] = useState(false);

	useEffect(() => {
		if(wasCodeSent) {
			const timeout = setTimeout(() => {
				setCanResendCode(true);
			}, 1000 * 10);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [ wasCodeSent ]);

	useOnboardingNotifications(
		hasError,
		exists,
		message
	);

	const [ cachedVariables, setCachedVariables ] = useState<VariablesOf<T> | null>(null);

	function begin(variables: VariablesOf<T>) {
		setCachedVariables(variables);
		execute({
			variables
		}).catch(err => {
			console.error("Error during begin onboarding: ", err);
			snack.enqueueSnackbar("We ran into an issue submitting your information. Please try again.", {
				variant: "error"
			});
		});
	}

	function resendCode() {
		if(!cachedVariables) return;
		execute({
			variables: cachedVariables
		}).catch(err => {
			console.error("Error during begin building onboarding: ", err);
			snack.enqueueSnackbar("We ran into an issue submitting your information. Please try again.", {
				variant: "error"
			});
		});
	}

	return {
		begin,
		resendCode,
		isSendingCode,
		loading,
		error,
		hasError,
		wasCodeSent,
		canResendCode
	};
}
