import { gql } from "@apollo/client";

export const User_MembershipsFragment = gql`
	fragment User_MembershipsFragment on User {
		memberships {
			id
			role
			instance { id name type }
		}
	}
`;

