import { CalendarMonth } from "@mui/icons-material";
import { Box, Grid, Heading, Text } from "grommet";
import { useWindowDimensions } from "../../../../hooks";
import { LocalizationProvider } from "../../../../provider";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { useMutation } from "@apollo/client";
import { FetchOrderUnified, UpdateOrderPreferredDate } from "../../../../graphql";
import { Moment } from "moment";
import { Fragment, useMemo, useState } from "react";
import { Button, Typography } from "@mui/material";
import { InstanceFeature, PickupWindow, Quote_SchedulingFragmentFragment } from "../../../../graphql/__generated__/graphql";
import moment from "moment";
import { UpcomingPickupsList } from "../pickup";
import { useUpcomingPickups } from "../../hooks/useUpcomingPickups";
import { useFeature } from "../../../instance";

interface DateCalendarAccordionSummaryProps {
	scheduledDate: moment.Moment | null;
}

export const DateCalendarAccordionSummary: React.FC<DateCalendarAccordionSummaryProps> = (props) => {
	const { size } = useWindowDimensions();

	const formatted = useMemo(() => {
		if(!props.scheduledDate) return "";
		return (size === "small")
			? props.scheduledDate?.format("MMM Do")
			: props.scheduledDate?.format("dddd, MMMM Do");
	}, [ props.scheduledDate, size ]);

	return (
		<Grid columns={[ "auto", "small", "flex" ]} gap="small">
			<Box align="center" justify="center">
				<CalendarMonth />
			</Box>
			<Box justify="center">
				<Text weight="bold">
					Scheduled Date
				</Text>
			</Box>
			<Box justify="center">
				{formatted && (
					<Typography>
						{formatted}
					</Typography>
				)}
				{!formatted && (
					<Typography color="error">
						Not Selected
					</Typography>
				)}
			</Box>
		</Grid>
	);
};

export const ScheduledPickupsAccordionDetails: React.FC<{
	pickupId: string;
	scheduledDate: moment.Moment | null;
	onPickupSelected: (pickupId: string, window: PickupWindow, date: Moment | null) => void;
	onCustomPickupSelected: (date: moment.Moment) => void;
}> = (props) => {
	const { pickups, loading } = useUpcomingPickups();
	const [ isCustomDateSelection, setIsCustomDateSelection ] = useState(false);

	function handleSelect(date: Moment | null) {
		if(!date) return;
		const foundExisting = pickups.find(pickup => moment(pickup.scheduledDate).isSame(date, "day"));

		if(foundExisting) {
			props.onPickupSelected(foundExisting.id, foundExisting.window, date);
		}
		else {
			props.onCustomPickupSelected(date);
		}
	}

	const isCustomScheduleEnabled = useFeature(InstanceFeature.OrderCustomScheduleEnabled);

	return (
		<Box gap="medium">
			{isCustomDateSelection && (
				<Box gap="medium">
					<Box gap="small">
						<Heading level="3" margin="none">
							Select a Date
						</Heading>
						<Text>
							Select your preferred pickup date.
						</Text>
					</Box>
					<LocalizationProvider>
						<DateCalendar
							disablePast
							value={props.scheduledDate}
							showDaysOutsideCurrentMonth
							onChange={handleSelect}
							shouldDisableDate={(day) => day.startOf("day") <= moment().startOf("day")}
						/>
					</LocalizationProvider>
				</Box>
			)}
			{!isCustomDateSelection && (
				<Box gap="medium">
					<UpcomingPickupsList
						selectedId={props.pickupId}
						onSelectPickup={props.onPickupSelected}
					/>
					{isCustomScheduleEnabled && (
						<Fragment>
							<Box gap="small" align="center">
								<Heading level="3" margin="none">
									None of these dates work for you?
								</Heading>
							</Box>
							<Box align="center">
								<Button
									color="primary"
									variant="contained"
									onClick={() => setIsCustomDateSelection(true)}
								>
									Schedule Express Pickup
								</Button>
							</Box>
						</Fragment>
					)}
				</Box>
			)}
		</Box>
	);
};

interface DateCalendarAccordionDetailsProps {
	orderId: string;
	quotes: Quote_SchedulingFragmentFragment[];
	scheduledDate: moment.Moment | null;
	onDateUpdated: (date: moment.Moment | null) => void;
}

export const DateCalendarAccordionDetails: React.FC<DateCalendarAccordionDetailsProps> = (props) => {
	const [
		updatePreferredDate,
		{ loading: updatePreferredDateLoading }
	] = useMutation(UpdateOrderPreferredDate, {
		refetchQueries: [ FetchOrderUnified ]
	});

	function handleUpdateDate(date: Moment | null): void {
		if(!date) return;
		updatePreferredDate({
			variables: {
				orderId: props.orderId,
				date: date.format("YYYY-MM-DD")
			}
		}).then(() => {
			props.onDateUpdated(date);
		});
	}

	return (
		<Box margin="small">
			<LocalizationProvider>
				<DateCalendar
					disablePast
					disabled={updatePreferredDateLoading}
					value={props.scheduledDate}
					showDaysOutsideCurrentMonth
					onChange={handleUpdateDate}
					shouldDisableDate={(day) => {
						if(day.startOf("day") <= moment().startOf("day")) return true;
						return !props.quotes.some(quote => {
							return quote.pickupOptions.some((option) => {
								return day.isSame(moment(option.date), "day");
							});
						});
					}}
					slots={{
						day: CustomDayWithPrice as any
					}}
					slotProps={{
						day: {
							quotes: props.quotes
						} as any
					}}
				/>
			</LocalizationProvider>
		</Box>
	);
};

function CustomDayWithPrice(props: PickersDayProps<Moment> & { quotes: Quote_SchedulingFragmentFragment[]; }) {
	const { day, disabled } = props;

	const amount = useMemo(() => {
		const pricing = props.quotes.filter(quote => {
			return quote.pickupOptions.some(option => {
				return moment(option.date, "YYYY-MM-DD").isSame(day, "day");
			});
		}).sort((a, b) => (a.lastEstimate?.totalAmount || 0) - (b.lastEstimate?.totalAmount || 0))[ 0 ]?.lastEstimate;

		if(!pricing) {
			return "";
		}

		if(day.startOf("day") <= moment().startOf("day")) {
			return "";
		}

		return `$${pricing.totalAmount.toFixed(0)}`;
	}, [ props.quotes, day ]);

	return (
		<PickersDay {...props} style={{ minHeight: "40px", minWidth: "40px" }}>
			<Box>
				<Typography variant="caption" fontSize="14px" lineHeight="normal" color={disabled ? "gray" : undefined}>
					{day.format("D")}
				</Typography>
				<Typography variant="caption" fontSize="12px" lineHeight="normal">
					{amount}
				</Typography>
			</Box>
		</PickersDay>
	);
}