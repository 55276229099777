import { SvgIcon, SvgIconProps } from "@mui/material";

	export const DiningTable: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m447.98 661.78c0-14.477-11.773-26.25-26.25-26.25h-35.773l-0.67578 0.074218-13.125 52.426h23.324v40.273h-33.449l-13.125 52.5h46.582v77.023l-65.859 0.003906-9.375 37.5h75.234v103.27c0 14.496 11.754 26.25 26.25 26.25s26.25-11.754 26.25-26.25v-244.08c0-0.03125-0.007813-0.050782-0.007813-0.082032z"/>
  <path d="m870.38 857.83h-65.859v-77.023h46.582l-13.125-52.5h-33.449v-40.273h23.324l-13.125-52.426-0.67578-0.074219h-35.773c-14.477 0-26.25 11.773-26.25 26.25v92.648c0 0.03125-0.007813 0.058593-0.007813 0.097656v244.08c0 14.496 11.754 26.25 26.25 26.25s26.25-11.754 26.25-26.25v-103.27l75.234-0.003906z"/>
  <path d="m1133.6 175.15c-14.496 0-26.25 11.754-26.25 26.25v434.13h-107.32l-15.84-108.45h52.656c14.496 0 26.25-11.746 26.25-26.25v-103.53c0-14.496-11.754-26.25-26.25-26.25h-873.77c-14.496 0-26.25 11.754-26.25 26.25v103.54c0 14.496 11.754 26.25 26.25 26.25h52.656l-15.84 108.45h-107.32v-434.14c0-14.496-11.754-26.25-26.25-26.25s-26.25 11.754-26.25 26.25v797.2c0 14.496 11.754 26.25 26.25 26.25s26.25-11.754 26.25-26.25v-103.27h69.391l-14.527 99.48c-1.1016 7.5469 1.1328 15.195 6.1211 20.961s12.234 9.082 19.852 9.082h74.527c12.039 0 22.539-8.1992 25.461-19.867l27.457-109.65h0.007813l4.9062-19.629 28.352-113.21 8.5938-34.184h-0.039062l10.086-40.273 0.69141-2.7461 16.77-66.969 46.605-4.9414c32.543-3.457 60.668-23.016 75.227-52.328l16.875-33.969h182.09l16.867 33.969c14.559 29.301 42.676 48.871 75.227 52.328l46.605 4.9414 16.086 64.238 1.3633 5.4766 10.086 40.273-0.035156-0.003906 8.5586 34.051 30.113 120.26 3.1797 12.711h0.007813l27.457 109.65c2.9336 11.676 13.426 19.867 25.469 19.867h74.527c7.6289 0 14.863-3.3164 19.852-9.082 4.9883-5.7656 7.2227-13.418 6.1133-20.961l-14.527-99.48h69.391v103.27c0 14.496 11.754 26.25 26.25 26.25s26.25-11.754 26.25-26.25v-797.2c0-14.5-11.762-26.25-26.25-26.25zm-26.266 512.88v40.273h-93.773l-5.8789-40.273zm-1014.8 0h99.645l-5.8789 40.273h-93.766zm-0.015625 169.8v-77.023h86.117l-11.25 77.023zm352.46-320.13c-6.5312 13.141-19.148 21.914-33.742 23.469l-64.844 6.8789c-10.957 1.1641-20.016 9.0391-22.695 19.727l-96.293 384.59h-23.676l65.016-445.27h181.51zm262.26-63.121h-517.95v-51.039h821.25v51.039zm288.86 497.77h-23.676l-96.293-384.59c-2.6758-10.688-11.738-18.562-22.695-19.727l-64.836-6.8789c-14.602-1.5508-27.227-10.328-33.75-23.469l-5.2734-10.621h181.5zm36.328-114.52-11.25-77.023h86.113v77.023z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};