import { useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { useSnackbar } from "notistack";
import { ConfirmOnboardingMutation, ConfirmBuildingOnboardingMutation } from "../../../graphql/__generated__/graphql";
import { ConfirmOnboardingDocument, ConfirmOnboardingMutationType, ConfirmOnboardingVariables } from "./types";
import { useUser } from "../../../auth";

export function isConfirmOnboardingMutation(
	mutation: ConfirmOnboardingMutation | ConfirmBuildingOnboardingMutation | null | undefined
): mutation is ConfirmOnboardingMutation {
	return !!(mutation as ConfirmOnboardingMutation)?.ConfirmOnboarding;
}

export function isConfirmBuildingOnboardingMutation(
	mutation: ConfirmOnboardingMutation | ConfirmBuildingOnboardingMutation | null | undefined
): mutation is ConfirmBuildingOnboardingMutation {
	return !!(mutation as ConfirmBuildingOnboardingMutation)?.ConfirmBuildingOnboarding;
}

export function useConfirmOnboarding<T extends ConfirmOnboardingDocument>(document: T) {
	const snack = useSnackbar();
	const { updateLogin } = useUser();
	const [
		execute, { loading, error, data }
	] = useMutation<
		ConfirmOnboardingMutationType,
		ConfirmOnboardingVariables
	>(document);

	const accessToken = useMemo(() => {
		if(isConfirmOnboardingMutation(data)) {
			return data.ConfirmOnboarding.accessToken || "";
		}

		if(isConfirmBuildingOnboardingMutation(data)) {
			return data.ConfirmBuildingOnboarding.accessToken || "";
		}

		return "";
	}, [ data ]);

	const refreshToken = useMemo(() => {
		if(isConfirmOnboardingMutation(data)) {
			return data.ConfirmOnboarding.refreshToken || "";
		}

		if(isConfirmBuildingOnboardingMutation(data)) {
			return data.ConfirmBuildingOnboarding.refreshToken || "";
		}

		return "";
	}, [ data ]);

	const wasConfirmSuccessful = useMemo(() => {
		return !!accessToken;
	}, [ accessToken ]);

	useEffect(() => {
		if(accessToken && refreshToken) {
			updateLogin(accessToken, refreshToken);
		}
	}, [ accessToken, refreshToken ]);

	function confirm(variables: VariablesOf<T>) {
		execute({
			variables
		}).catch(err => {
			console.error("Error during confirm onboarding: ", err);
			snack.enqueueSnackbar("We ran into an issue submitting your information. Please try again.", {
				variant: "error"
			});
		});
	}

	return {
		error,
		loading,
		confirm,
		accessToken,
		refreshToken,
		wasConfirmSuccessful
	};
}
