import React, { useMemo } from "react";
import { ReactNode, useEffect } from "react";
import { useResumeLogin, useUser } from "../auth";
import { SplashScreen } from "../components";
import { selectWasLoginAttempted, useAppSelector } from "../store";

export const AuthProvider: React.FC<{ children: ReactNode; }> = (props) => {
	const { user } = useUser();
	const { loading, refreshLogin } = useResumeLogin();
	const wasLoginAttempted = useAppSelector(selectWasLoginAttempted);

	const [ doRefresh, setDoRefresh ] = React.useState(false);

	useEffect(() => {
		if(!doRefresh && !wasLoginAttempted) {
			setDoRefresh(true);
		}
	}, [ wasLoginAttempted, doRefresh ]);

	useEffect(() => {
		if(doRefresh) {
			console.debug("Attempting to refresh login");
			refreshLogin();
		}
	}, [ doRefresh, refreshLogin ]);

	const wasUserLoadingCompleted = useMemo(() => {
		console.log("wasUserLoadingCompleted", wasLoginAttempted, loading, user);
		return ((wasLoginAttempted && !loading) || !!user);
	}, [ user, wasLoginAttempted, loading ]);

	return (
		<SplashScreen visible={!wasUserLoadingCompleted}>
			{props.children}
		</SplashScreen>
	);
};