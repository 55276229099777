import React, { useMemo } from "react";
import { DialogWithActivatorButton, Pagination, ViewContainer } from "../../../../components";
import { Button, Checkbox, LinearProgress, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Box, Heading, Spinner } from "grommet";
import { usePricingPolicy, useRequiredPathParam } from "../../../../hooks";
import { PricingPolicyForm } from "../../../../components/pricing/PricingPolicyForm";
import { useMutation, useQuery } from "@apollo/client";
import { AssignPricingPolicy, FindPartners, UnassignPricingPolicy } from "../../../../graphql";
import { PricingPolicyCategoryContainer } from "../../../pricing";
import { DesktopOnlyGuard } from "../../../../guards";

export const PricingPolicyDetailsView: React.FC = () => {
	const policyId = useRequiredPathParam("policyId", "/admin/pricing-policies");
	const { loading } = usePricingPolicy(policyId);

	return (
		<ViewContainer>
			<Box gap="medium">
				<Box gap="xsmall">
					<Heading margin="none" level="2">
						Pricing Policy Details
					</Heading>
					{loading && (
						<LinearProgress />
					)}
				</Box>
				<PricingPolicyForm
					policyId={policyId}
					actions={[
						<ViewPricingPolicyPartnersButton
							policyId={policyId}
						/>,
						<ViewPricingDetailsButton
							policyId={policyId}
						/>
					]}
				/>
			</Box>
		</ViewContainer>
	);
};

export const ViewPricingDetailsButton: React.FC<{
	policyId: string;
}> = ({ policyId }) => {
	const [ searchFilter, setSearchFilter ] = React.useState("");

	return (
		<DialogWithActivatorButton
			title="Pricing"
			fullScreen
			activatorButton={(
				<Button
					variant="outlined"
					color="primary"
				>
					Pricing
				</Button>
			)}
		>
			<Box gap="small">
				{/* <FormContainer>
					<TextFieldElement
						name="searchFilter"
						label="Search"
						value={searchFilter}
						onChange={e => setSearchFilter(e.target.value)}
					/>
				</FormContainer> */}
				<DesktopOnlyGuard>
					<PricingPolicyCategoryContainer
						policyId={policyId}
						searchFilter={searchFilter}
					/>
				</DesktopOnlyGuard>
			</Box>
		</DialogWithActivatorButton>
	);
};

export const ViewPricingPolicyPartnersButton: React.FC<{
	policyId: string;
}> = ({ policyId }) => {
	return (
		<DialogWithActivatorButton
			title="Assigned Partners"
			activatorButton={(
				<Button
					variant="outlined"
					color="primary"
				>
					Partners
				</Button>
			)}
		>
			<PricingPolicyPartnersContainer
				policyId={policyId}
			/>
		</DialogWithActivatorButton>
	);
};

export const PricingPolicyPartnersContainer: React.FC<{
	policyId: string;
}> = ({ policyId }) => {
	const { data, loading } = useQuery(FindPartners);

	return (
		<Box flex>
			<Pagination pageSize={5} listProps={{ disablePadding: true }}>
				{data?.FindPartners?.map(p => (
					<PricingPolicyPartnerListItem
						key={p.id}
						policyId={policyId}
						partnerId={p.id}
						partnerName={p.name}
					/>
				)) || []}
			</Pagination>
		</Box>
	);
};

export const PricingPolicyPartnerListItem: React.FC<{
	policyId: string;
	partnerId: string;
	partnerName: string;
}> = ({ partnerId, partnerName, policyId }) => {
	const { policy, loading } = usePricingPolicy(policyId);

	const isEnabled = useMemo(() => {
		return policy?.partners?.some(p => p.id === partnerId);
	}, [ policy, partnerId ]);

	const [
		assign,
		{ loading: assignLoading }
	] = useMutation(AssignPricingPolicy, {
		refetchQueries: [ "FetchPricingPolicy" ]
	});

	const [
		unassign,
		{ loading: unassignLoading }
	] = useMutation(UnassignPricingPolicy, {
		refetchQueries: [ "FetchPricingPolicy" ]
	});

	function toggle(): void {
		if(isEnabled) {
			unassign({
				variables: {
					partnerId
				}
			});
		}
		else {
			assign({
				variables: {
					partnerId,
					policyId
				}
			});
		}
	}

	return (
		<ListItemButton divider onClick={toggle}>
			<ListItemIcon>
				{(loading || assignLoading || unassignLoading) ? (
					<Spinner />
				) : (
					<Checkbox
						checked={isEnabled}
					/>
				)}
			</ListItemIcon>
			<ListItemText>
				<Typography fontWeight="bold">
					{partnerName}
				</Typography>
			</ListItemText>
		</ListItemButton>
	);
};