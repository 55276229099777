import { Apartment } from "@mui/icons-material";
import { ListItemButton, ListItemIcon } from "@mui/material";
import { LoadingListItemText } from "../List";

export const ResidentUnitListItem: React.FC<{
	unit: string;
	unitPrefix: string;
	loading?: boolean;
}> = ({ unit, unitPrefix, loading }) => {
	return (
		<ListItemButton divider>
			<ListItemIcon>
				<Apartment />
			</ListItemIcon>
			<LoadingListItemText
				label="Your Unit"
				value={unit ? `${unitPrefix} ${unit}` : "Not Found"}
				loading={loading} />
		</ListItemButton>
	);
};

