import * as tf from "@tensorflow/tfjs";
import { useEffect, useState } from "react";

export function useTensorflow() {

	const [ hasError, setHasError ] = useState(false);
	const [ isReady, setReady ] = useState(false);

	useEffect(() => {
		(async () => {
			console.debug("initializing Tensorflow");
			const wasSuccessful = await tf.ready()
				.then(() => true)
				.catch(err => {
					console.error("Failed during tensorflow init", err);
					return false;
				});

			if(wasSuccessful) {
				setReady(true);
				console.debug("Tensorflow ready with backend: " + tf.getBackend());
			}
			else {
				setHasError(true);
			}
		})();

		return () => {
			tf.dispose();
		};
	}, []);

	return {
		isReady,
		hasError
	};
}

export default useTensorflow;