import { gql } from "../../../__generated__";

export const FindActiveOrders = gql(/* GraphQL */ `
	query FindActiveOrders(
		$userId: String!
		$instanceId: String!
	) {
		FindActiveOrders(
			userId: $userId
			instanceId: $instanceId
		) {
			...Order_UserCombinedFragment
		}
	}
`);