import { useWindowDimensions } from "../../../../hooks";
import { AvailablePickupListItem, Pagination } from "../../../../components";
import { LinearProgress } from "@mui/material";
import { useMemo } from "react";
import { useUniversityUpcomingPickups } from "../../../dashboard/components";
import { Box, Heading } from "grommet";

export const UpcomingPickupsContainer: React.FC = () => {
	const { size, height } = useWindowDimensions();
	const { pickups: pickupsUnsorted, loading } = useUniversityUpcomingPickups();
	const pickups = useMemo(() => {
		return [ ...(pickupsUnsorted || []) ].sort((a, b) => {
			if(!a.scheduledDate) return 1;
			if(!b.scheduledDate) return -1;

			return a.scheduledDate > b.scheduledDate ? 1 : -1;
		});
	}, [ pickupsUnsorted ]);

	const pageSize = useMemo(() => {
		if(height > 700) return 5;
		if(height > 600) return 4;
		if(height > 500) return 3;
		return 3;
	}, [ height ]);

	return (
		<Box
			flex
			gap="medium"
			border={size !== "xlarge" ? undefined : { side: "left", color: "lightgrey" }} pad={{ left: "small" }}
		>
			<Box gap="small" height="39px">
				<Box justify="center" fill="vertical">
					<Heading level="3" margin="none">
						Upcoming Pickup Dates
					</Heading>
				</Box>
				{loading && (
					<LinearProgress />
				)}
			</Box>
			<Box height="100%">
				<Pagination
					pageSize={pageSize}
					forceContainerGridDisplay={size !== "xlarge"}
				>
					{pickups.map(pickup => (
						<AvailablePickupListItem
							key={pickup.id}
							pickupId={pickup.id}
							pickupDate={pickup.scheduledDate || ""}
							selected={false}
							loading={loading}
							minHeight="80px"
							onSelect={() => {
								//	
							}} />
					))}
				</Pagination>
			</Box>
		</Box>
	);
};
