import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { FetchUserHauler, FindUserHaulers } from "../../../graphql";
import { selectSelectedHaulerId, setSelectedHaulerId, useAppDispatch, useAppSelector } from "../../../store";

export function useHauler() {
	const dispatch = useAppDispatch();
	const haulerId = useAppSelector(selectSelectedHaulerId);
	const { data, loading, error } = useQuery(FindUserHaulers);

	useEffect(() => {
		if(!data || !data.FindUserHaulers) return;

		if(data.FindUserHaulers.length === 0) {
			console.error("No haulers found");
			return;
		}

		if(data.FindUserHaulers.length >= 1) {
			if(haulerId) return;
			dispatch(setSelectedHaulerId(data.FindUserHaulers[ 0 ].id));
			return;
		}
	}, [ data, haulerId ]);

	const hauler = useMemo(() => {
		return (data?.FindUserHaulers ?? []).find(h => h.id === haulerId) ?? null;
	}, [ data, haulerId ]);

	return {
		hauler,
		haulers: data?.FindUserHaulers ?? [],
		loading,
		error
	};
}

export function useLazyHauler(haulerId: string) {
	const [ fetch, { data, loading, error } ] = useLazyQuery(FetchUserHauler);

	const hauler = useMemo(() => {
		return data?.FetchUserHauler ?? null;
	}, [ data ]);

	useEffect(() => {
		if(haulerId) {
			fetch({ variables: {} }).catch(err => {
				console.error("Failed to fetch hauler", err);
			});
		}
	}, [ haulerId ]);

	return {
		data,
		hauler,
		loading,
		error
	};

}