import { useQuery } from "@apollo/client";
import { FindMaterials } from "../graphql";

export function useReportingMaterials() {
	const { data, loading } = useQuery(FindMaterials, {
		variables: {
			tags: { arrayContains: [ "reporting" ] }
		}
	});

	return {
		materials: data?.FindMaterials || [],
		loading
	};
}