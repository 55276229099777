import { SvgIconProps } from "@mui/material";
import { resolveFromUrl } from "./helpers";

export const Icon: React.FC<SvgIconProps & { url: string; }> = (props) => {
	const IconComponent = resolveFromUrl(props.url);

	if(IconComponent) {
		return <IconComponent {...props} />;
	}

	return null;
};
