import { useCallback, useMemo } from "react";
import { Question, QuestionType } from "../types";
import { selectCalculatorState, setCalculatorState } from "../../../store/calculator";
import { useAppDispatch, useAppSelector } from "../../../store";


export function useQuestion(
	question: Question<QuestionType>
) {
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectCalculatorState);

	const value = useMemo(() => {
		return state[ question.id ];
	}, [ state ]);

	const onChange = useCallback((value: string | number | boolean) => {
		dispatch(setCalculatorState({
			[ question.id ]: value
		}));
	}, [ question.id ]);

	return {
		value,
		onChange
	};
}
