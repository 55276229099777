import React, { useMemo } from "react";
import { SidebarRouter, SidebarRouteItem, OrdersListItem, PricingPoliciesListItem, ConfigurationListItem, InstancesListItem } from "../../../components";
import { isNotNull } from "../../../helpers";
import { ProductDetails } from "../views";
import { GlobalAdminOrderDetailsView } from "./views/OrderDetails";
import { GlobalAdminDashboardListItem } from "../components";
import { GlobalAdminDashboardView, GlobalAdminOrdersView, GlobalAdminPickupDetailsView, InstancesView, PricingPoliciesView, PricingPolicyDetailsView } from "./views";
import { ConfigurationView } from "./views/Configuration";
import { OrderRequestDetailsView } from "./views/OrderRequestDetails";
import { UserDetailsView } from "./views/UserDetails";
import { RoleGuard } from "../../../guards";
import { WasteTicketsView } from "./views/WasteTicketsView";

export const GlobalAdminRouter: React.FC = () => {
	const router = useMemo(() => {
		return new SidebarRouter(
			[
				new SidebarRouteItem(
					"/dashboard",
					<GlobalAdminDashboardView />,
					<GlobalAdminDashboardListItem
						key="dashboard-list-item"
					/>,
					true
				),
				new SidebarRouteItem(
					"/orders",
					<GlobalAdminOrdersView
						key="orders-view"
					/>,
					<OrdersListItem
						key="orders-list-item"
						absolutePath="/admin/orders"
					/>,
					true
				),
				// new SidebarRouteItem(
				// 	"/purchases",
				// 	<PurchasesView />,
				// 	<PurchasesListItem
				// 		key="purchases-list-item"
				// 		absolutePath="/admin/purchases"
				// 	/>,
				// 	true
				// ),
				new SidebarRouteItem(
					"/pricing-policies",
					<PricingPoliciesView />,
					<PricingPoliciesListItem
						key="pricing-policies-list-item"
						absolutePath="/admin/pricing-policies"
					/>,
					true
				),
				new SidebarRouteItem(
					"/configuration",
					<ConfigurationView />,
					<ConfigurationListItem
						key="configuration-list-item"
						absolutePath="/admin/configuration"
					/>,
					true
				),
				new SidebarRouteItem(
					"/instances",
					<InstancesView />,
					<InstancesListItem
						key="instances-list-item"
						absolutePath="/admin/instances"
					/>,
					true
				)
			].filter(isNotNull),
			"Global Admin",
			[
				{ from: "/", to: "/admin/dashboard" }
			],
			[
				{ path: "/users/:userId", element: <UserDetailsView /> },
				{ path: "/order-requests/:requestId", element: <OrderRequestDetailsView /> },
				{ path: "/orders/:orderId", element: <GlobalAdminOrderDetailsView /> },
				{ path: "/pickups/:pickupId", element: <GlobalAdminPickupDetailsView /> },
				{ path: "/products/:productId", element: <ProductDetails permitEdit /> },
				{ path: "/pricing-policies/:policyId", element: <PricingPolicyDetailsView /> },
				{ path: "/waste-tickets", element: <WasteTicketsView /> }
			]).toRouter();
	}, []);
	return (
		<RoleGuard
			resolver={result => result.isGlobalAdmin}
		>
			{router}
		</RoleGuard>
	);
};