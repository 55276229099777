import { Event } from "@mui/icons-material";
import { ListItemButton, ListItemIcon } from "@mui/material";
import { LoadingListItemText } from "../List";
import moment from "moment-timezone";
import { useMemo } from "react";

export const ResidentMoveOutDateListItem: React.FC<{
	loading?: boolean;
	moveOutDate: Date | null;
}> = ({ loading, moveOutDate }) => {
	const formatted = useMemo(() => {
		if(moveOutDate) {
			return moment(moveOutDate).format("dddd, MMMM Do");
		}
		return "Not Found";
	}, [ moveOutDate ]);

	return (
		<ListItemButton divider>
			<ListItemIcon>
				<Event />
			</ListItemIcon>
			<LoadingListItemText
				label="Your Move Out Date"
				value={formatted}
				loading={loading}
			/>
		</ListItemButton>
	);
};