import { Box } from "grommet";
import { PaginationProvider } from "./PaginationProvider";
import { PaginationRenderer } from "./PaginationRenderer";
import { PaginationControls } from "./PaginationControls";
import { ListProps } from "@mui/material";
import React, { ReactElement, ReactNode, createRef, useEffect } from "react";

export interface PaginationProps {
	pageSize?: number;
	additionalControls?: React.ReactNode;
	children: React.ReactNode[];
	listProps?: ListProps;
	forceContainerGridDisplay?: boolean;
	overrideContainer?: ReactElement<{ children: ReactNode; }>;
}

export const Pagination: React.FC<PaginationProps> = (props) => {
	const containerRef = createRef<HTMLDivElement>();

	useEffect(() => {
		if(props.forceContainerGridDisplay) {
			containerRef.current?.style.setProperty("display", "inline-block");
		}
		else {
			containerRef.current?.style.setProperty("display", "flex");
		}
	}, [ containerRef, props.forceContainerGridDisplay ]);

	return (
		<PaginationProvider pageSize={props.pageSize || 10}>
			<Box
				ref={containerRef}
				gap="medium"
				height="100%"
				id="pagination-container"
			>
				<Box id="pagination-renderer">
					<PaginationRenderer listProps={props.listProps} overrideContainer={props.overrideContainer}>
						{props.children}
					</PaginationRenderer>
				</Box>
				<Box flex direction="row" align="end" justify="between" id="pagination-controls">
					<Box>
						{props.additionalControls}
					</Box>
					<PaginationControls
						count={props.children.length}
					/>
				</Box>
			</Box>
		</PaginationProvider>
	);
};