import { useMemo } from "react";
import { useClassification } from "../../hooks";
import { TextFieldElement } from "react-hook-form-mui";
import { Box, Text } from "grommet";
import { Divider } from "@mui/material";
import { ProductIntent } from "../../graphql/__generated__/graphql";
import { ItemTypeElement } from "./ItemTypeElement";
import { ItemSizeElement } from "./ItemSizeElement";

interface ProductDetailsFormElementProps {
	size: { id: string, name: string; } | null;
	type: { id: string, name: string, canDisassemble: boolean; } | null;
	quantity: number;
	quantityDisassembly: number;
	intent: ProductIntent | null;
}

export const ProductDetailsFormElement: React.FC<ProductDetailsFormElementProps> = (props) => {
	const { types } = useClassification();

	const sizeOptions = useMemo(() => {
		return types.find(t => t.id === props.type?.id)?.sizes ?? [];
	}, [ props.type, types ]);

	return (
		<Box margin="small" gap="medium">
			<Text weight="bold">Item Details</Text>
			<ItemTypeElement />
			{(props.type && (sizeOptions.length ?? 0) > 0) && (
				<ItemSizeElement
					type={props.type}
					size={props.size}
				/>
			)}
			<TextFieldElement
				required
				name="quantity"
				label="Quantity"
				type="number"
				inputMode="numeric"
				validation={{
					min: { value: 1, message: "Quantity cannot be less than 1" }
				}}
			/>
			{!!(props.type?.canDisassemble) && (
				<TextFieldElement
					name="quantityDisassembly"
					label="How many require disassembly?"
					type="number"
					inputMode="numeric"
					validation={{
						min: { value: 0, message: "Disassembly count cannot be less than 0" },
						max: { value: props.quantity, message: "Disassembly count cannot be greater than quantity" }
					}}
				/>
			)}
			<Divider />
		</Box>
	);
};
