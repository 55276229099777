import { gql } from "../../../__generated__";

export const FetchProductWithListing = gql(/* GraphQL */ `
	query FetchProductWithListing(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...ProductFragment
			...ProductListingFragment
		}
	}
`);