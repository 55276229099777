import { CopyAll, Edit, SmartToy } from "@mui/icons-material";
import { IconButton, List } from "@mui/material";
import { useClipboard } from "../../../../../hooks/useClipboard";
import { ProductDetailsListItem } from "./AdminProductDetailsListItem";
import { useProduct } from "../../../hooks/useProduct";
import { UpdateProductOutletDialog } from "./UpdateProductOutletDialog";
import { UpdateProductTypeDialog } from "./UpdateProductTypeDialog";
import { FetchAdminProduct, FetchProductV1 } from "../../../../../graphql";
import { BasicListItem } from "../../../../../components";
import { Fragment } from "react";
import { Detection } from "../../../../../app/tensorflow/useCoco";

export const AdminProductDetailsContainer: React.FC<{
	productId: string;
	permitEdit: boolean;
	bestDetection?: Detection;
}> = ({ productId, bestDetection, permitEdit }) => {
	const { copy } = useClipboard();
	const { loading, product } = useProduct(productId);

	return (
		<Fragment>
			<List>
				<ProductDetailsListItem
					loading={loading}
					primary="Item ID"
					secondary={productId}
					action={(
						<IconButton
							color="primary"
							onClick={() => copy(productId)}
						>
							<CopyAll />
						</IconButton>
					)}
				/>
				<ProductDetailsListItem
					loading={loading}
					primary="Item Type"
					secondary={product?.type?.name || "Not Assigned"}
					action={permitEdit && (
						<UpdateProductTypeDialog
							productId={product?.id || ""}
							type={(product?.type) ?? null}
							size={(product?.size) ?? null}
							activationButton={(
								<IconButton color="primary">
									<Edit />
								</IconButton>
							)}
						/>
					)}
				/>
				{product?.size?.name && (
					<ProductDetailsListItem
						loading={loading}
						primary="Item Size"
						secondary={product?.size?.name || "Not Assigned"}
						action={permitEdit && (
							<UpdateProductTypeDialog
								productId={product?.id || ""}
								type={(product?.type) ?? null}
								size={(product?.size) ?? null}
								activationButton={(
									<IconButton color="primary">
										<Edit />
									</IconButton>
								)}
								refetch={[
									FetchProductV1,
									FetchAdminProduct,
								]}
							/>
						)}
					/>
				)}
				{permitEdit && (
					<ProductDetailsListItem
						loading={loading}
						primary="Disposition"
						secondary={product?.disposition || "Not Assigned"}
						action={permitEdit && (
							<UpdateProductOutletDialog
								productId={productId}
								outlet={product?.outlet ?? null}
								fallbackOutlet={product?.fallbackOutlet ?? null}
								activationButton={(
									<IconButton color="primary">
										<Edit />
									</IconButton>
								)}
							/>
						)}
					/>
				)}
				{permitEdit && (
					<ProductDetailsListItem
						loading={loading}
						primary="Assigned Outlet"
						secondary={product?.outlet?.name || "Not Assigned"}
						action={permitEdit && (
							<UpdateProductOutletDialog
								productId={productId}
								outlet={product?.outlet ?? null}
								fallbackOutlet={product?.fallbackOutlet ?? null}
								activationButton={(
									<IconButton color="primary">
										<Edit />
									</IconButton>
								)}
							/>
						)}
					/>
				)}
				{bestDetection && (
					<BasicListItem
						icon={<SmartToy />}
						label="AI Detection"
						value={bestDetection.name}
					/>
				)}
			</List>
		</Fragment>
	);
};