import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box, Spinner, Text } from "grommet";
import { useLocation } from "react-router";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { StripeController } from "../controller";

export const CreatePaymentMethodForm: React.FC<{
	publicKey: string;
	clientSecret: string;
}> = ({ publicKey, clientSecret }) => {
	const location = useLocation();
	return (
		<StripeController clientSecret={clientSecret} publicKey={publicKey}>
			<Box margin="medium">
				<RegoPaymentElement
					returnUrl={`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`}
				/>
			</Box>
		</StripeController>
	);
};

interface RegoPaymentElementProps {
	returnUrl: string;
}

export const RegoPaymentElement: React.FC<RegoPaymentElementProps> = (props) => {
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ loading, setLoading ] = useState(false);
	const [ isStripeLoading, setIsStripeLoading ] = useState(true);

	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		if(!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setLoading(true);
		const { error } = await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: props.returnUrl
			},
		});


		if(error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			setErrorMessage(error.message ?? "");
		}
		else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}

		setLoading(false);
	};

	return (
		<Box gap="medium">
			{isStripeLoading && (
				<Box height="small" align="center" justify="center">
					<Spinner size="large" />
				</Box>
			)}
			<Box gap="small">
				<PaymentElement
					onReady={(() => {
						setIsStripeLoading(false);
					})}
				/>
				{errorMessage && (
					<Text weight="bold">
						{errorMessage}
					</Text>
				)}
			</Box>
			<LoadingButton
				type="submit"
				color="primary"
				variant="contained"
				loading={loading}
				onClick={handleSubmit}
			>
				Add Payment Method
			</LoadingButton>
		</Box>
	);
};