import { useQuery } from "@apollo/client";
import { FetchAdminPickup } from "../../../../graphql";

export function usePickup(pickupId: string) {
	const { data, loading } = useQuery(FetchAdminPickup, {
		skip: !pickupId,
		variables: {
			pickupId
		}
	});

	return {
		data,
		loading,
		pickup: data?.FetchPickup || null
	};
}