import { gql } from "@apollo/client";

export const ReuseOutlet_HoursFragment = gql`
	fragment ReuseOutlet_HoursFragment on ReuseOutlet {
		hours {
			MONDAY {
				open
				from
				to
			}
			TUESDAY {
				open
				from
				to
			}
			WEDNESDAY {
				open
				from
				to
			}
			THURSDAY {
				open
				from
				to
			}
			FRIDAY {
				open
				from
				to
			}
			SATURDAY {
				open
				from
				to
			}
			SUNDAY {
				open
				from
				to
			}
		}
	}
`;