import React from "react";
import { Box } from "grommet";
import { useBuilding } from "../../../hooks";
import { UseFormReturn } from "react-hook-form";
import { Button, Typography } from "@mui/material";
import { Pagination } from "../../../../../../components";
import { PropertyDetailsFormContext } from "../PropertyDetailsDialog";
import { CreateWasteContainerAssignmentDialog, WasteContainerAssignmentListItem } from "../../assignment";

export const WasteContainersTab: React.FC<{
	buildingId: string;
	formContext: UseFormReturn<PropertyDetailsFormContext>;
}> = ({ buildingId }) => {
	const { building } = useBuilding(buildingId);

	return (
		<Box gap="medium" flex>
			{(building?.containers || []).length > 0
				? (
					<Box flex>
						<Pagination
							pageSize={4}
							additionalControls={(
								<CreateWasteContainerAssignmentDialog
									partner={building
										? { id: building.id, name: building.name }
										: undefined
									}
									activatorButton={(
										<Button
											variant="outlined"
											color="primary"
										>
											Add Waste Container
										</Button>
									)}
								/>
							)}
						>
							{(building?.containers || []).map(container => (
								<WasteContainerAssignmentListItem
									key={container.id}
									buildingId={buildingId}
									assignmentId={container.id}
								/>
							))}
						</Pagination>
					</Box>
				)
				: (
					<Box height="small" align="center" justify="center" gap="small">
						<Typography>
							No waste containers assigned to this location.
						</Typography>
						<CreateWasteContainerAssignmentDialog
							partner={building
								? { id: building.id, name: building.name }
								: undefined
							}
							activatorButton={(
								<Button
									variant="outlined"
									color="primary"
								>
									Add Waste Container
								</Button>
							)}
						/>
					</Box>
				)
			}
		</Box>
	);
};