import { PartnerRole } from "../../../../../graphql/__generated__/graphql";

export function resolvePermissionName(user: { role: PartnerRole; }) {
	switch(user.role) {
		case PartnerRole.PartnerAdmin: {
			return "Admin";
		}
		case PartnerRole.PartnerReporting: {
			return "Reporting";
		}
		default: {
			return "User";
		}
	}
}