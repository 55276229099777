import { gql } from "../../../__generated__";

export const FetchAddress = gql(/* GraphQL */`
	query FetchAddress($addressId: String!) {
		FetchAddress(addressId: $addressId) {
			id
			...AddressFragment
		}
	}
`);

export const FetchAddressStopDetails = gql(/* GraphQL */`
	query FetchAddressStopDetails($addressId: String!) {
		FetchAddress(addressId: $addressId) {
			id
			...AddressFragment
			instructionsMedia {
				id
				name
				contentUrl
			}
		}
	}
`);