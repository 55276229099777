import { CheckCircle } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Skeleton, Typography } from "@mui/material";
import { Box } from "grommet";
import React, { Fragment } from "react";

interface ListItemSkeletonProps {
	loading?: boolean;
	content: React.ReactNode | string;
}

export const ListItemSkeleton: React.FC<ListItemSkeletonProps> = (props) => {
	return (props.loading)
		? (
			<Box width="small">
				<Skeleton />
			</Box>
		)
		: (
			<Fragment>
				{props.content}
			</Fragment>
		);
};

interface BasicListItemProps {
	label: string | React.ReactNode;
	value: string | React.ReactNode;
	action?: React.ReactNode;
	loading?: boolean;
	chip?: React.ReactNode;
	hideIcon?: boolean;
	icon?: React.ReactNode;
	allowMultiLine?: boolean;
}

export const BasicListItem: React.FC<BasicListItemProps> = (props) => {
	return (
		<ListItem divider style={{ minHeight: "80px" }}>
			{!props.hideIcon && (
				<ListItemIcon >
					{props.icon || (<CheckCircle color="secondary" />)}
				</ListItemIcon>
			)}
			<ListItemText>
				<Typography className="text-max-1-line" fontWeight="bold">
					{props.label}
				</Typography>
				{props.loading
					? (
						<Box width="small">
							<Skeleton />
						</Box>
					)
					: (
						<Typography variant="body2" className={props.allowMultiLine ? undefined : "text-max-1-line"} >
							{props.value}
						</Typography>
					)
				}
			</ListItemText>
			{props.action && (
				<ListItemSecondaryAction>
					<Box direction="row" gap="small">
						{props.chip && (
							<Box justify="center">
								{props.chip}
							</Box>
						)}
						<Box justify="center" flex>
							{props.action}
						</Box>
					</Box>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};

export const LoadingListItemText: React.FC<{
	label: string;
	value: string;
	loading?: boolean;
}> = (props) => {
	return (
		<ListItemText
			primary={props.label}
			secondary={props.loading ? (
				<Box width="small">
					<Skeleton />
				</Box>
			) : props.value}
		/>
	);
};