import { Box, Heading, Text } from "grommet";
import { useWindowDimensions } from "../../../../hooks";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Filter1, Filter2, Filter3 } from "@mui/icons-material";
import { useReferCustomizer } from "../../../../hooks/customizer/useCustomizer";
import { Customized } from "../../../../components";


export const HowItWorkContainer: React.FC = () => {
	const { size } = useWindowDimensions();

	const {
		stepOneContent,
		stepTwoContent,
		stepThreeContent
	} = useReferCustomizer();

	return (
		<Box>
			<Box align="center">
				<Heading level={size === "small" ? 2 : 3} margin="none">
					How it Works?
				</Heading>
			</Box>
			<List>
				<ListItem>
					<ListItemIcon>
						<Filter1 />
					</ListItemIcon>
					<ListItemText>
						<Customized
							content={stepOneContent}
						>
							<Text>
								<Text weight="bold">Select which item(s) you want picked up</Text> (like furniture, clothing, electronics, etc.)
							</Text>
						</Customized>
					</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<Filter2 />
					</ListItemIcon>
					<ListItemText>
						<Customized
							content={stepTwoContent}
						>
							<Text>
								<Text weight="bold">Select your preferred pickup date</Text> (directly from your unit ... no need to carry everything downstairs!)
							</Text>
						</Customized>
					</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<Filter3 />
					</ListItemIcon>
					<ListItemText>
						<Customized
							content={stepThreeContent}
						>
							<Text>
								<Text weight="bold">Become a sustainability MVP</Text> by helping provide items to local non-profits instead of landfills (or sidewalks)!
							</Text>
						</Customized>
					</ListItemText>
				</ListItem>
			</List>
		</Box>
	);
};
