import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ElectricHeater: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m673.23 254.93h-236.62c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.62c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m673.23 365.56h-236.62c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.62c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m673.23 476.18h-236.62c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.62c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m673.23 586.81h-236.62c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.62c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m1022.9 254.93h-236.63c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.63c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m1022.9 365.56h-236.63c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.63c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m1022.9 476.18h-236.63c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.63c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m1022.9 586.81h-236.63c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h236.63c14.496 0 26.25-11.754 26.25-26.25 0-14.5-11.754-26.25-26.25-26.25z" />
		<path
			d="m1091.4 119.28h-723.33c-38.984 0-70.703 31.719-70.703 70.703v16.246h-31.605c-28.387 0-51.48 23.094-51.48 51.48v32.633c0 28.387 23.094 51.48 51.48 51.48h31.605v496.25l-78.645-0.003906c-28.328 0-51.375-23.047-51.375-51.383v-220.51c0-1.4414-0.19531-2.8281-0.50391-4.1719 37.852-5.9336 66.93-38.672 66.93-78.156v-62.379h6.7578c10.359 0 18.75-8.3906 18.75-18.75 0-10.359-8.3906-18.75-18.75-18.75h-34.883v-37.164c0-10.359-8.3906-18.75-18.75-18.75s-18.75 8.3906-18.75 18.75v37.164h-39.082v-37.164c0-10.359-8.3906-18.75-18.75-18.75s-18.75 8.3906-18.75 18.75v37.164h-34.902c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h6.7578v62.379c0 39.488 29.086 72.234 66.93 78.156-0.30859 1.3438-0.50391 2.7305-0.50391 4.1719v220.49c0 49.004 39.871 88.883 88.875 88.883h78.645v37.746c0 38.984 31.719 70.703 70.703 70.703h16.957v45.449c0 28.266 22.996 51.262 51.262 51.262h46.688c28.266 0 51.262-22.996 51.262-51.262v-45.449h390.98v45.449c0 28.266 22.996 51.262 51.262 51.262h46.688c28.266 0 51.262-22.996 51.262-51.262v-45.449h16.965c38.984 0 70.703-31.719 70.703-70.703v-723.32c0-38.988-31.715-70.703-70.703-70.703zm-990.48 364.57v-62.379h95.359v62.379c0 22.996-18.711 41.715-41.707 41.715h-11.941c-23-0.007812-41.711-18.719-41.711-41.715zm165.87-194.54v-30.586h30.586v30.586zm214.95 738.91h-44.211v-44.211h44.211zm540.19 0h-44.211v-44.211h44.211zm87.668-114.91c0 10.035-8.168 18.203-18.203 18.203h-723.33c-10.035 0-18.203-8.168-18.203-18.203v-723.32c0-10.035 8.168-18.203 18.203-18.203h723.32c10.035 0 18.203 8.168 18.203 18.203l-0.003906 723.32z" />
	</g>
</svg>
			</SvgIcon>
		);
	};