import { Box, Heading } from "grommet";
import { ViewContainer } from "../components";

export const DesktopOnlyView: React.FC<{
	children?: React.ReactNode;
}> = ({ children }) => {
	return (
		<ViewContainer>
			<Box flex align="center" justify="center" gap="medium">
				<Heading level="2">
					This page is not available on mobile
				</Heading>
				{children}
			</Box>
		</ViewContainer>
	);
};