import { InputAdornment, ListItemIcon, ListItemText } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Box, Spinner } from "grommet";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { Add } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { FetchPricingPolicy, UpdatePricingPolicy } from "../../../graphql";
import { usePricedItem } from "../hooks/usePricedItem";
import { useClassification } from "../../../hooks";

export const PricingPolicySizeListItem: React.FC<{
	sizeId: string;
	typeId: string;
	policyId: string;
	canDisassemble: boolean;
	enableDisassembly: boolean;
}> = ({ policyId, typeId, sizeId, canDisassemble, enableDisassembly }) => {
	const { types } = useClassification();
	const { pickupFee, disassemblyFee, loading } = usePricedItem(policyId, typeId, sizeId);

	const formContext = useForm({
		defaultValues: {
			pickupFee,
			disassemblyFee
		}
	});

	const size = useMemo(() => {
		const type = types.find(t => t.id === typeId);
		return type?.sizes.find(s => s.id === sizeId);
	}, [ types, sizeId ]);

	useEffect(() => {
		formContext.setValue("pickupFee", pickupFee);
	}, [ pickupFee ]);

	useEffect(() => {
		formContext.setValue("disassemblyFee", disassemblyFee);
	}, [ disassemblyFee ]);

	const [
		update, { loading: updateLoading }
	] = useMutation(UpdatePricingPolicy, {
		refetchQueries: [
			FetchPricingPolicy
		],
		awaitRefetchQueries: true
	});

	const anyLoading = useMemo(() => loading || updateLoading, [ loading, updateLoading ]);

	function handleSave() {
		if(!size) return;
		const { pickupFee, disassemblyFee } = formContext.getValues();

		update({
			variables: {
				policyId,
				pricing: [
					{
						sizeId,
						canDisassemble: canDisassemble,
						pickupFee: Number(pickupFee),
						disassemblyFee: enableDisassembly ? Number(disassemblyFee) : 0
					}
				]
			}
		});
	}

	return (
		<Box direction="row" align="center" flex>
			<ListItemIcon>
				{anyLoading ? <Spinner /> : <Add />}
			</ListItemIcon>
			<ListItemText
				primary={size?.name} />
			<Box align="end">
				<FormContainer formContext={formContext}>
					<Box direction="row" gap="small">
						<TextFieldElement
							size="small"
							name="pickupFee"
							disabled={anyLoading}
							label="Pickup Fee"
							style={{ width: "200px" }}
							InputProps={{
								//value: formatNumber(Number(pickupFeeFromForm || 0), 2),
								onBlur: formContext.handleSubmit(handleSave),
								startAdornment: (
									<InputAdornment position="start">
										{anyLoading ? <Spinner /> : "$"}
									</InputAdornment>
								)
							}}
						/>
						<TextFieldElement
							size="small"
							disabled={!enableDisassembly || anyLoading}
							name="disassemblyFee"
							label="Disassembly Fee"
							style={{ width: "200px" }}
							InputProps={{
								//value: formatNumber(Number(disassemblyFeeFromForm || 0), 2),
								onBlur: formContext.handleSubmit(handleSave),
								startAdornment: (
									<InputAdornment position="start">
										{anyLoading ? <Spinner /> : "$"}
									</InputAdornment>
								)
							}}
						/>
					</Box>
				</FormContainer>
			</Box>
		</Box>
	);
};
