import { Box, Grid, Heading, Image } from "grommet";
import { DialogWithClose, ViewContainer } from "../../../components";
import "../css/game.css";
import "../img/tetris";

import Tetris from "@liverego/tetris";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Button, Typography, useTheme } from "@mui/material";
import ProfanityFilter from "bad-words";

export const UsernameDialog: React.FC<{
	onSubmit: (username: string, emailAddress: string) => void;
}> = ({ onSubmit }) => {
	const formContext = useForm({
		defaultValues: {
			username: "",
			emailAddress: ""
		}
	});

	return (
		<DialogWithClose
			title="Welcome"
			onClose={() => {
				//
			}}
			actions={(
				<Box direction="row" justify="end">
					<Button
						color="primary"
						variant="contained"
						onClick={formContext.handleSubmit(({ username, emailAddress }) => onSubmit(username, emailAddress))}
					>
						Play
					</Button>
				</Box>
			)}
			content={(
				<FormContainer formContext={formContext}>
					<Box gap="medium">
						<Heading margin="none" level="3">
							Enter your username
						</Heading>
						<TextFieldElement
							name="username"
							label="Username"
							required
						/>
						<TextFieldElement
							name="emailAddress"
							label="Email Address"
							required
						/>
					</Box>
				</FormContainer>
			)}
		/>
	);
};

import tetrisLogo from "../img/tetris_logo.png";
import regoWhiteLogo from "../../../img/rego_white_logo_transparent.png";
import { useWindowDimensions } from "../../../hooks";
import { isEmailAddress } from "../../../helpers";
import { useMutation, useQuery } from "@apollo/client";
import { FetchLeaderboard } from "../../../graphql/documents/game";
import { GameType } from "../../../graphql/__generated__/graphql";
import { RecordLeaderboardScore } from "../../../graphql/documents/game/mutation/RecordLeaderboardScore";

interface LeaderboardRecord {
	username: string;
	score: number;
}

interface LoginData {
	username: string;
	emailAddress: string;
	firstName: string;
	lastName: string;
}

interface LoginScreenProps {
	onSubmit(data: LoginData): void;
}

export const LoginScreen: React.FC<LoginScreenProps> = ({
	onSubmit
}) => {
	const { data, loading } = useQuery(FetchLeaderboard, {
		variables: {
			gameType: GameType.Tetris
		}
	});

	const leaderboard = useMemo(() => {
		return data?.FetchLeaderboard ?? [];
	}, [ data ]);

	const { size } = useWindowDimensions();

	const formContext = useForm({
		defaultValues: {
			username: "",
			emailAddress: "",
			firstName: "",
			lastName: ""
		}
	});

	return (
		<ViewContainer backgroundColor="#C3D4F0">
			<Box height="100%" width="100%" align="center">
				<Box align="center" width="800px" gap="medium" flex>
					<Box
						width="100%"
						style={{ backgroundColor: "#656EA5" }}
						direction="row"
						justify="between"
					>
						<Box pad={{ vertical: "small", horizontal: "medium" }} justify="center">
							<Image
								src={regoWhiteLogo}
								width="200px"
							/>
						</Box>
						<Box pad={{ vertical: "small", horizontal: "medium" }}>
							<Image
								src={tetrisLogo}
								width="150px"
							/>
						</Box>
					</Box>
					<Box align="start" alignSelf="start">
						<Typography
							fontWeight="bold"
						>
							Help us measure the amount of waste diverted by organizing items into lines. Winner gets a gift card and Rego swag!
						</Typography>
					</Box>
					<Box
						width="100%"
						gap="medium"
						justify="between"
						flex={size === "small"}
						style={{ display: size === "small" ? "block" : undefined }}
						direction={size === "small" ? "column" : "row"}>
						<Box flex gap="medium">
							<FormContainer formContext={formContext}>
								<Box gap="medium">
									<Grid columns={{ count: 2, size: "auto" }} gap="small">
										<TextFieldElement
											name="firstName"
											label="First Name"
											required
										/>
										<TextFieldElement
											name="lastName"
											label="Last Name"
											required
										/>
									</Grid>
									<TextFieldElement
										name="username"
										label="Username (Display Name)"
										required
										validation={{
											validate: (value: unknown) => {
												console.log(value);
												if(!value) {
													return "Username is required";
												}

												if(String(value).length > 16) {
													return "Username must be 16 characters or less";
												}

												if(new ProfanityFilter().isProfane(String(value))) {
													return "That username is not available";
												}

												return;
											}
										}}
									/>
									<TextFieldElement
										name="emailAddress"
										label="Email Address"
										required
										validation={{
											validate: (value: unknown) => {
												console.log(value);
												if(!value) {
													return "Email is required";
												}

												if(!isEmailAddress(String(value))) {
													return "Please enter a valid email address";
												}

												return;
											}
										}}
									/>
								</Box>
							</FormContainer>
							<Box justify="center" align="center" flex>
								<Button
									fullWidth
									size="large"
									color="warning"
									variant="contained"
									onClick={formContext.handleSubmit(({ username, emailAddress, firstName, lastName }) => {
										onSubmit({ username, emailAddress, firstName, lastName });
									})}
								>
									Play
								</Button>
							</Box>
						</Box>
						<Box
							width={size !== "small" ? "300px" : "100%"}
							style={{ backgroundColor: "#CFFFE8" }} pad="small" gap="medium">
							<Box align="center">
								<Heading level="3" margin="none">
									Leaderboard
								</Heading>
							</Box>
							<Box overflow={{ vertical: "scroll" }} flex pad={{ horizontal: "small" }} gap="xsmall">
								{leaderboard.length === 0 && (
									<Box align="center" justify="center" pad="medium">
										<Typography>
											No entries yet
										</Typography>
									</Box>
								)}
								{leaderboard.map((record, i) => (
									<Grid key={record.username} columns={[ "auto", "auto", "flex", "auto" ]}>
										<Box align="end" >
											<Typography fontWeight="bold" sx={{ marginRight: "4px" }}>
												{i + 1}.
											</Typography>
										</Box>
										<Typography fontWeight="bold">
											{record.username}
										</Typography>
										<Box></Box>
										<Typography fontWeight="bold">
											{record.score}
										</Typography>
									</Grid>
								))}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};

export const TetrisView = () => {
	const [ score, setScore ] = React.useState(0);
	const [ username, setUsername ] = React.useState("");
	const [ emailAddress, setEmailAddress ] = React.useState("");
	const theme = useTheme();
	const [ data, setData ] = React.useState<LoginData | null>(null);

	const [
		record,
		{ loading: recordLoading }
	] = useMutation(RecordLeaderboardScore, {
		refetchQueries: [ FetchLeaderboard ]
	});

	if(!data) {
		return (
			<LoginScreen
				onSubmit={(data) => {
					setData(data);
				}}
			/>
		);
	}

	function handleRecordScore(score: number) {
		if(!data) return;

		record({
			variables: {
				firstName: data.firstName,
				lastName: data.lastName,
				username: data.username,
				emailAddress: data.emailAddress,
				score,
				gameType: GameType.Tetris
			}
		});
	}

	return (
		<ViewContainer backgroundColor="#C3D4F0">
			<Box align="center" justify="center" height="100%" width="100%">
				<Box style={{ maxWidth: "600px" }}>
					<Tetris
						keyboardControls={{
							// Default values shown here. These will be used if no
							// `keyboardControls` prop is provided.
							down: "MOVE_DOWN",
							left: "MOVE_LEFT",
							right: "MOVE_RIGHT",
							space: "HARD_DROP",
							z: "FLIP_COUNTERCLOCKWISE",
							x: "FLIP_CLOCKWISE",
							up: "FLIP_CLOCKWISE",
							p: "TOGGLE_PAUSE"
						}}
						hooks={{
							onGameEnd: (points) => {
								handleRecordScore(points);
							}
						}}
					>
						{({
							NextPiece,
							HeldPiece,
							Gameboard,
							PieceQueue,
							points,
							linesCleared,
							state,
							controller,
						}) => (
							// <div>
							// 	<HeldPiece />
							// 	<div>
							// 		<p>Points: {points}</p>
							// 		<p>Lines Cleared: {linesCleared}</p>
							// 	</div>
							// 	<Gameboard />
							// 	<PieceQueue />
							// 	{state === "LOST" && (
							// 		<div>
							// 			<h2>Game Over</h2>
							// 			<button onClick={controller.restart}>New game</button>
							// 		</div>
							// 	)}
							// </div>
							<Box
								width="100%"
								height="100%"
							>
								<Box direction="row" gap="small" flex>
									<Box border={{ size: "medium", color: "#6879A4" }}>
										<Gameboard />
									</Box>
									<Box flex gap="small">
										<Box
											pad="small"
											align="center"
											justify="center"
											border={{ size: "medium", color: "#6879A4" }}>
											<Box>
												<NextPiece />
											</Box>
										</Box>
										<Box
											pad="small"
											direction="row"
											justify="between"
											align="center"
											border={{ size: "medium", color: "#6879A4" }}
										>
											<Box justify="center">
												<Typography
													className="futura"
													fontSize="large"
												>
													Pounds Diverted
												</Typography>
											</Box>
											<Box justify="center">
												<Typography className="futura">
													{points}
												</Typography>
											</Box>
										</Box>
										<Box
											pad="small"
											direction="row"
											justify="between"
											align="center"
											border={{ size: "medium", color: "#6879A4" }}
										>
											<Box justify="center">
												<Typography
													className="futura"
													fontSize="large"
												>
													Lines Cleared
												</Typography>
											</Box>
											<Box justify="center">
												<Typography className="futura">
													{linesCleared}
												</Typography>
											</Box>
										</Box>
										<Box direction="row" justify="between">
											<Box>
												<Button
													size="large"
													sx={{
														color: "black",
														fontFamily: "Futura"
													}}
													onClick={controller.restart}
												>
													New Game
												</Button>
											</Box>
											{state === "LOST" && (
												<Box justify="center" align="center">
													<Button
														size="large"
														color="error"
														sx={{
															color: theme.palette.error.main,
															fontFamily: "Futura"
														}}

													>
														GAME OVER
													</Button>
												</Box>
											)}
											{state !== "LOST" && (
												<Box justify="center" align="center">
													<Button
														size="large"
														sx={{
															color: "black",
															fontFamily: "Futura"
														}}
														onClick={state === "PAUSED"
															? controller.resume
															: controller.pause
														}
													>
														{state === "PAUSED" ? "RESUME | P" : "PAUSE | P"}
													</Button>
												</Box>
											)}
										</Box>
										<Box flex justify="end">
											<Box style={{ backgroundColor: "#656EA5" }} pad="medium" flex>
												<Image
													src={regoWhiteLogo}
												/>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						)}
					</Tetris>
				</Box>
			</Box>
		</ViewContainer>
	);
};