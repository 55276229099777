import { List, ListItem, ListItemText } from "@mui/material";
import { useMemo } from "react";
import { toProperCase } from "../../../helpers";

interface ProductDetailsListProps {
	category: string;
	type: string;
	size?: string;
	condition: string;
}

export const ProductDetailsList: React.FC<ProductDetailsListProps> = (props) => {
	const typeName = useMemo(() => {
		if(props.size) {
			return `${props.type}, ${props.size}`;
		}

		return props.type;
	}, [ props ]);

	return (
		<List disablePadding>
			<ListItem divider disableGutters disablePadding>
				<ListItemText
					primary="Category"
					secondary={props.category} />
			</ListItem>
			<ListItem divider disableGutters disablePadding>
				<ListItemText
					primary="Type"
					secondary={typeName} />
			</ListItem>
			<ListItem divider disableGutters disablePadding>
				<ListItemText
					primary="Condition"
					secondary={toProperCase(props.condition)} />
			</ListItem>
		</List>
	);
};
