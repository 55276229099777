import { gql } from "../../../__generated__";


export const FetchHaulerPickup = gql(/* GraphQL */ `
	query FetchHaulerPickup($pickupId: String!) {
		FetchPickup(pickupId: $pickupId) {
			...Pickup_CountFragment
			...Pickup_HeaderFragment
			...Pickup_InvoiceFragment
			...Pickup_TrackingFragment
			...Pickup_AssignmentFragment
			market {
				id
				name
				code
			}
			incomingProducts {
				...Product_StopDetailedFragment
			}
			stops {
				...Stop_CombinedFragment
			}
			activeStop {
				...Stop_CombinedFragment
			}
		}
	}
`);
