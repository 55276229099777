import React from "react";
import { viewMatrix } from "../models/Game";
import { getClassName, splitIndexedPiece } from "../models/Piece";
import { Context } from "../context";
export default function GameboardView() {
    var game = React.useContext(Context);
    var matrix = viewMatrix(game);
    return (React.createElement("table", { className: "game-board" },
        React.createElement("tbody", null, matrix.map(function (row, i) {
            var blocksInRow = row.map(function (block, j) {
                var classString = "game-block ";
                if (block) {
                    if (block === "ghost") {
                        classString += getClassName("ghost", 0);
                    }
                    else {
                        var _a = splitIndexedPiece(block), piece = _a[0], index = _a[1], rotation = _a[2];
                        classString += getClassName("".concat(piece, "-").concat(index), rotation);
                    }
                }
                else {
                    classString += "block-empty";
                }
                return React.createElement("td", { key: j, className: classString });
            });
            return React.createElement("tr", { key: i }, blocksInRow);
        }))));
}
