import { gql } from "../../../__generated__";

export const FetchPartnerUsers = gql(/* GraphQL */ `
	query FetchPartnerUsers($partnerId: String!) {
		FetchPartnerUsers(partnerId: $partnerId) {
			id
			role
			user {
				...User_HeaderFragment
				primaryPhoneNumber
				primaryEmailAddress
			}
		}
	}
`);