import { gql } from "../../../__generated__";

export const FetchHaulerActionItems = gql(/* GraphQL */ `
	query FetchHaulerActionItems(
		$haulerId: String!
	) {
		ListProductsPendingDestinationStop(
			haulerId: $haulerId
		) {
			id
			destinationStopDelayed
			destinationStopDelayedAt
			destinationStopDelayedUntil
			destinationStopConfirmed
			destinationStopConfirmedAt
			type {
				id
				name
			}
			totalQuantity
			destinationStop {
				id
			}
		}
	}
`);