import { useFeature } from "../../instance/hooks/useFeature";
import { InstanceFeature, InstanceType } from "../../../graphql/__generated__/graphql";
import { useBuildingPickups } from "./useBuildingPickups";
import { useInstance } from "../../../hooks";
import { useUniversityUpcomingPickups } from "../components";

export function useUpcomingPickups() {
	const { instance } = useInstance();
	const isUpfrontSchedulingEnabled = useFeature(InstanceFeature.OrderUpfrontScheduleEnabled);

	const buildingPickups = useBuildingPickups();
	const universityPickups = useUniversityUpcomingPickups();

	return (instance?.type === InstanceType.University)
		? universityPickups
		: (isUpfrontSchedulingEnabled)
			? buildingPickups
			: { loading: false, pickups: [] };
}