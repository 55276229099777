import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../../../store";
import { selectOnboardingState } from "../../../../store/onboarding";
import { STEPS } from "./steps";
import { UseFormReturn } from "react-hook-form";
import { OnboardingFormContext } from "../../types";

export function useOnboardingSteps(
	formContext: UseFormReturn<OnboardingFormContext>,
	onSubmit?: () => void
) {
	const state = useAppSelector(selectOnboardingState);

	const activeStepIndex = useMemo(() => {
		return STEPS.findIndex((step) => step.id === state.activeStep);
	}, [ state.activeStep ]);

	const submitHandler = useCallback(() => {
		if(!onSubmit) return;
		formContext.handleSubmit(onSubmit)();
	}, [ formContext, onSubmit ]);

	const hasNext = useMemo(() => {
		return activeStepIndex < STEPS.length - 1;
	}, [ activeStepIndex ]);

	const hasPrevious = useMemo(() => {
		return activeStepIndex > 0;
	}, [ activeStepIndex ]);

	const previousStep = useCallback(() => {
		if(activeStepIndex === 0) {
			return;
		}

		formContext.setValue("activeStep", STEPS[ activeStepIndex - 1 ].id);
	}, [ activeStepIndex, formContext ]);

	const nextStep = useCallback(() => {
		console.log("nextStep");
		if(!hasNext) {
			return submitHandler();
		}

		function dispatchNextStep() {
			formContext.setValue("activeStep", STEPS[ activeStepIndex + 1 ].id);
		}

		formContext.handleSubmit(dispatchNextStep)();
	}, [ activeStepIndex, formContext, hasNext, submitHandler ]);

	return {
		hasNext,
		hasPrevious,
		next: nextStep,
		previous: previousStep,
		submit: submitHandler,
		activeStep: state.activeStep,
		activeStepIndex
	};
}