import { gql } from "@apollo/client";

export const QuoteFragment = gql`
	fragment QuoteFragment on Quote {
		id
		amount
		amountOriginal
		amountAdditional
		hauler {
			id
			name
		}
		lastEstimate {
			...EstimateFragment
		}
	}
`;