import { Button, IconButton, InputAdornment, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, useTheme } from "@mui/material";
import { Box, Carousel, Heading, Image } from "grommet";
import React, { createRef, useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { findProductInState, selectCheckoutState, updateProduct } from "../../../store/checkout";
import { ProductIcon } from "../../../icons/useProductIcon";
import { DialogWithActivatorButton, useSteps } from "../../../components";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useCheckoutMedia, useProduct, useSize } from "../hooks";
import { formatCurrency, isNotNull, noop } from "../../../helpers";
import { Add, Backup, FileUpload, Remove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useToggleForceClose } from "../../admin/components";
import { useSnackbar } from "notistack";
import { useIncrementProduct } from "../components";
import { useConfirmationDialog } from "../../../provider";
import { useWindowDimensions } from "../../../hooks";

export const ItemReviewContainer: React.FC = (props) => {
	const state = useAppSelector(selectCheckoutState);
	const { prev } = useSteps("checkout-items");

	useEffect(() => {
		if(!prev) return;

		if(state.products.length === 0) {
			prev();
		}
	}, [ state, prev ]);

	return (
		<Box height="100%" overflow={{ vertical: "scroll" }}>
			<List>
				{state.products.map((product) => (
					<ItemReviewListItem
						key={product.id}
						typeId={product.type?.id || ""}
						sizeId={product.size?.id || ""}
					/>
				))}
			</List>
		</Box>
	);
};

export const ItemReviewListItem: React.FC<{
	typeId: string;
	sizeId: string;
	hideActions?: boolean;
}> = ({ typeId, sizeId, hideActions }) => {
	const { size: screenSize } = useWindowDimensions();
	const { type, size, quantity } = useSize(typeId, sizeId);
	const product = useAppSelector(findProductInState({ typeId, sizeId }));

	return (
		<ItemReviewDialog typeId={typeId} sizeId={sizeId}>
			<ListItemButton
				style={{ border: "1px solid #e0e0e0", borderRadius: "4px", marginBottom: "8px" }}
			>
				<ListItemIcon>
					<ProductIcon iconUrl={size?.iconUrl || type?.iconUrl || type?.category?.iconUrl || "@liverego/icons/Other"} />
				</ListItemIcon>
				<ListItemText>
					<Typography fontWeight="bold">
						{screenSize !== "small"
							? type?.name + ` (x${quantity})`
							: type?.name
						}
					</Typography>
					<Typography
						variant="body2"
					>
						{screenSize === "small"
							? (size?.name || "Standard") + ` (x${quantity})`
							: size?.name || "Standard"
						}
					</Typography>
				</ListItemText>
				{!hideActions && (
					<ListItemSecondaryAction>
						<Button
							variant="contained" size="small"
							color={product?.reviewed ? "success" : "warning"}
						>
							{product?.reviewed ? "Reviewed" : "Upload"}
						</Button>
					</ListItemSecondaryAction>
				)}
			</ListItemButton>
		</ItemReviewDialog>
	);
};

export const ItemReviewDialog: React.FC<{
	typeId: string;
	sizeId: string;
	children: React.ReactNode;
}> = ({ typeId, sizeId, children }) => {
	const { forceClose, toggleForceClose } = useToggleForceClose();
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectCheckoutState);
	const { totalFee, totalFeeComparison } = useSize(typeId, sizeId);

	const snack = useSnackbar();
	const ref = createRef<HTMLInputElement>();
	const { isUploading, uploadMedia, assignExistingMedia, product } = useProduct(typeId, sizeId);

	const formContext = useForm({
		defaultValues: {
			quantity: product?.quantity || 1,
			quantityDisassembly: product?.quantityDisassembly || 0
		}
	});

	useEffect(() => {
		if(product) {
			formContext.setValue("quantity", product.quantity);
			formContext.setValue("quantityDisassembly", product.quantityDisassembly);
		}
	}, [ product ]);

	const { media } = useCheckoutMedia();

	const [ uploadedMedia, allUploadedMedia ] = useMemo(() => {
		return [
			(product?.media || []).map(mediaId => media.find(m => m.id === mediaId)).filter(isNotNull),
			state.products.flatMap(p => p.media)
		];
	}, [ product, media ]);

	const theme = useTheme();

	function handleSubmit() {
		if(!product) return;
		if(uploadedMedia.length === 0) {
			snack.enqueueSnackbar("Please upload an image", {
				variant: "warning"
			});
			return;
		}

		dispatch(updateProduct({
			...product,
			reviewed: true
		}));

		toggleForceClose();
	}

	const {
		increment,
		decrement,
		incrementDisassembly,
		decrementDisassembly
	} = useIncrementProduct(typeId, sizeId);

	const { showDialog } = useConfirmationDialog();

	const handleDecrement = useCallback(() => {
		if(!product) return;
		const { quantity } = product;

		if(quantity === 1) {
			showDialog({
				title: "Remove Item",
				content: "Are you sure you want to remove this item?",
				onCancel: noop,
				onConfirm: decrement
			});
			return;
		}

		decrement();
	}, [ product, showDialog, decrement ]);

	return (
		<DialogWithActivatorButton
			title="Review Item"
			forceClose={forceClose}
			activatorButton={children}
			actions={(
				<Box direction="row" justify="between">
					<Box justify="center" direction="row" gap="small">
						{!!totalFee && (
							<Box justify="center">
								<Typography
									fontWeight="bold"
									style={{ color: theme.palette.success.main }}
								>
									{formatCurrency(totalFee)}
								</Typography>
							</Box>
						)}
						{!!totalFeeComparison && (
							<Box justify="center">
								<Typography sx={{ textDecoration: "line-through" }}>
									{formatCurrency(totalFeeComparison)}
								</Typography>
							</Box>
						)}
					</Box>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Save
					</Button>
				</Box>
			)}
		>
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<TextFieldElement
						name="quantity"
						label="How many need to be pickup up?"
						required
						InputProps={{
							value: product?.quantity || 1,
							endAdornment: (
								<InputAdornment position="end">
									<Box direction="row" gap="small">
										<IconButton
											onClick={handleDecrement}
										>
											<Remove />
										</IconButton>
										<IconButton
											onClick={increment}
										>
											<Add />
										</IconButton>
									</Box>
								</InputAdornment>
							)
						}}
					/>
					<TextFieldElement
						label="How many require disassembly?"
						name="quantityDisassembly"
						required
						InputProps={{
							value: product?.quantityDisassembly || 0,
							endAdornment: (
								<InputAdornment position="end">
									<Box direction="row" gap="small">
										<IconButton
											onClick={decrementDisassembly}
										>
											<Remove />
										</IconButton>
										<IconButton
											onClick={incrementDisassembly}
										>
											<Add />
										</IconButton>
									</Box>
								</InputAdornment>
							)
						}}
					/>
					<Box gap="small">
						<Heading level="3" margin="none">
							Upload Images
						</Heading>
						<Typography variant="body2">
							Take a photo of the front of your item. Please make sure the item is framed so that nothing is obscuring the item.
						</Typography>
						<Box>
							<Box
								onClick={() => {
									uploadedMedia.length === 0 && ref.current?.click();
								}}
								height="250px"
								style={{
									borderColor: "#e0e0e0",
									borderTop: "1px dashed #e0e0e0",
									borderLeft: "1px dashed #e0e0e0",
									borderRight: "1px dashed #e0e0e0",
									borderBottom: "none",
									backgroundColor: theme.palette.grey[ 100 ]
								}}
							>
								{uploadedMedia.length === 0 && (
									<Box align="center" justify="center" flex>
										<Backup
											sx={{ fontSize: "200px", opacity: 0.2 }}
										/>
									</Box>
								)}
								{uploadedMedia.length > 0 && (
									<ScrollToLastCarosel
										uploadedMedia={uploadedMedia}
									/>
								)}
							</Box>
							<Box
								style={{
									border: "1px dashed #e0e0e0",
								}}
								pad="small"
								align="center"
								justify="center"
							>
								<LoadingButton
									sx={{ zIndex: 2 }}
									loading={isUploading}
									onClick={() => {
										ref.current?.click();
									}}
									endIcon={<FileUpload />}
									loadingPosition="end"
									variant="outlined"
								>
									Upload Image
								</LoadingButton>
								<input
									type="file"
									ref={ref}
									accept=".png, .jpg, .jpeg"
									style={{ display: "none" }}
									onClick={(event) => {
										event.stopPropagation();
									}}
									onChange={(event) => {
										if(event.target.files) {
											uploadMedia(Array.from(event.target.files));
										}
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};

export const ScrollToLastCarosel: React.FC<{
	uploadedMedia: { contentUrl: string; }[];
}> = ({ uploadedMedia }) => {
	const index = useMemo(() => {
		return uploadedMedia.length - 1;
	}, [ uploadedMedia ]);

	return (
		<Carousel
			id="carousel"
			activeChild={index}
		>
			{uploadedMedia.map((media) => (
				<Box height="100%" width="100%">
					<Image
						fit="contain"
						src={media.contentUrl}
					/>
				</Box>
			))}
		</Carousel>
	);
};