import React, { useMemo } from "react";
import { BasicListItem } from "../../../../components";
import { EditNote } from "@mui/icons-material";
import { useStop } from "../../../../graphql";

export const StopInstructionsComponent: React.FC<{
	stopId: string;
	isOriginStop: boolean;
	hideIfNotProvided?: boolean;
}> = ({ stopId, isOriginStop, hideIfNotProvided }) => {
	const { stop } = useStop(stopId);

	const address = useMemo(() => {
		return stop?.address || null;
	}, [ stop ]);

	if(!address?.instructionsParking && hideIfNotProvided) {
		return <></>;
	}

	return (
		<BasicListItem
			allowMultiLine
			icon={<EditNote />}
			value={address?.instructions || "Not Provided"}
			label={isOriginStop ? "Pickup Instructions" : "Dropoff Instructions"}
		/>
	);
};
