import { useQuery } from "@apollo/client";
import { FetchOrderRequest } from "../../../../graphql";

export function useOrderRequest(requestId: string) {
	const { data, loading } = useQuery(FetchOrderRequest, {
		skip: !requestId,
		variables: { requestId }
	});

	return {
		loading,
		request: data?.FetchOrderRequest || null
	};
}