import { keys } from "../../../helpers";
import { Drink } from "../../../icons";
import { Frequency, Question, QuestionType } from "../types";
import { Checkroom, Fastfood, Home, Inventory, LocalDrink, LunchDining, Note, ShoppingBag, Weekend } from "@mui/icons-material";

export const questions: Question<QuestionType>[] = [
	{
		id: "householdSize",
		text: "How many people live in your household?",
		type: QuestionType.NUMBER,
		response: {
			min: 1,
			required: true
		},
		icon: <Home />,
		weightImpactKey: "dailyWastePerPersonBaselinePounds"
	},
	{
		id: "responsePlasticBagFrequency",
		text: "How many times per week do you use plastic bags when shopping?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				...keys(Frequency).map((key) => ({
					value: Frequency[ key ] as string,
					label: Frequency[ key ],
				}))
			],
			required: true
		},
		icon: <ShoppingBag />,
		pledgeKey: "pledgeEliminatePlasticBags",
		weightImpactKey: "dailyImpactFromPlasticBagsPounds",
		weightReductionKey: "dailyWasteReductionFromPlasticBagsPounds"
	},
	{
		id: "responseDisposeFoodWasteFrequency",
		text: "How many times per week do you throw away leftovers from your fridge?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				...keys(Frequency).map((key) => ({
					value: Frequency[ key ] as string,
					label: key === "NEVER" ? "I Compost / 0" : Frequency[ key ],
				}))
			],
			required: true
		},
		icon: <LunchDining />,
		pledgeKey: "pledgeCompostReduceFoodWaste",
		weightImpactKey: "dailyImpactFromFoodWastePounds",
		weightReductionKey: "dailyWasteReductionFromFoodWastePounds"
	},
	{
		id: "responseTakeoutFrequency",
		text: "How many times per week do you order takeout?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				...keys(Frequency).map((key) => ({
					value: Frequency[ key ] as string,
					label: Frequency[ key ],
				}))
			],
			required: true
		},
		icon: <Fastfood />,
		pledgeKey: "pledgeReduceTakeoutWaste",
		weightImpactKey: "dailyImpactFromTakeoutPounds",
		weightReductionKey: "dailyWasteReductionFromTakeoutPounds"
	},
	{
		id: "responseClothingPurchaseFrequency",
		text: "How many times per month do you purchase new clothing?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				...keys(Frequency).map((key) => ({
					value: Frequency[ key ] as string,
					label: key === "NEVER" ? "I Buy Used / 0" : Frequency[ key ],
				}))
			],
			required: true
		},
		icon: <Checkroom />,
		pledgeKey: "pledgeReduceClothingWaste",
		weightImpactKey: "dailyImpactFromClothingWastePounds",
		weightReductionKey: "dailyWasteReductionFromClothingReusePounds"
	},
	{
		id: "responseFurniturePurchaseFrequency",
		text: "How many times per month do you purchase new furniture, decor, or other items for your home?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				...keys(Frequency).map((key) => ({
					value: Frequency[ key ] as string,
					label: key === "NEVER" ? "I Buy Used / 0" : Frequency[ key ],
				}))
			],
			required: true
		},
		icon: <Weekend />,
		pledgeKey: "pledgeFurnitureReuse",
		weightImpactKey: "dailyImpactFromFurnitureWastePounds",
		weightReductionKey: "dailyWasteReductionFromFurnitureReusePounds"
	},
	{
		id: "responsePackageFrequency",
		text: "How many times per month do you receive packages from places like Amazon?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				...keys(Frequency).map((key) => ({
					value: Frequency[ key ] as string,
					label: Frequency[ key ],
				}))
			],
			required: true
		},
		icon: <Inventory />,
		pledgeKey: "pledgeReducePaperCardboardWaste"
	},
	{
		id: "responsePlasticBottleFrequency",
		text: "How many plastic water/drink bottles do you use per week?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				...keys(Frequency).map((key) => ({
					value: Frequency[ key ] as string,
					label: Frequency[ key ],
				}))
			],
			required: true
		},
		icon: <LocalDrink />,
		pledgeKey: "pledgeEliminatePlasticBottles",
		weightImpactKey: "dailyImpactFromPlasticBottlesPounds",
		weightReductionKey: "dailyWasteReductionFromPlasticBottlesPounds"
	},
	{
		id: "responseRecycleGlassAluminum",
		text: "Do you recycle glass and aluminum?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				{ label: "No", value: false },
				{ label: "Yes", value: true }
			],
			required: true
		},
		icon: <Drink />,
		pledgeKey: "pledgeRecycleGlassAluminum"
	},
	{
		id: "responseRecyclePaperPlastic",
		text: "Do you recycle paper and cardboard?",
		type: QuestionType.CHOICE,
		response: {
			options: [
				{ label: "No", value: false },
				{ label: "Yes", value: true }
			],
			required: true
		},
		icon: <Note />
	}
];