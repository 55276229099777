import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { Fragment } from "react";
import { Order_ExpandedFragmentFragment } from "../../../../../graphql/__generated__/graphql";
import { useSavedOrders } from "../../hooks";

export const OrdersDataTable: React.FC<{
	loading: boolean;
	columns: GridColDef[];
	orders: Order_ExpandedFragmentFragment[];
	noRowsOverlay?: React.ReactNode;
	onRowSelect?: (order: Order_ExpandedFragmentFragment) => void;
}> = ({ loading, columns, orders, noRowsOverlay, onRowSelect }) => {
	const {
		savedOrders,
	} = useSavedOrders();

	return (
		<DataGrid
			style={{ height: "100%" }}
			loading={loading}
			autoPageSize
			columns={columns}
			rows={orders.map(order => ({ ...order, saved: savedOrders.some(o => o.id === order.id) }))}
			onRowClick={(params) => {
				onRowSelect?.(params.row as Order_ExpandedFragmentFragment);
			}}
			slots={{
				noResultsOverlay: () => (
					<Fragment>{noRowsOverlay}</Fragment>
				)
			}}
		/>
	);
};
