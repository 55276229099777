import { useSnackbar } from "notistack";
import { CreateReferenceMedia, FindReferenceMedia } from "../../../graphql/documents/media";
import { useMutation, useQuery } from "@apollo/client";
import { useAppSelector } from "../../../store";
import { selectOnboardingState } from "../../../store/onboarding";
import { CreateReferenceMediaMutationVariables } from "../../../graphql/__generated__/graphql";

export function useOnboardingMedia() {
	const snack = useSnackbar();
	const { requestId } = useAppSelector(selectOnboardingState);

	const { data, loading } = useQuery(FindReferenceMedia, {
		skip: !requestId,
		variables: { referenceId: requestId },
	});

	const [
		upload,
		{ loading: isUploading },
	] = useMutation(CreateReferenceMedia, {
		fetchPolicy: "network-only",
		refetchQueries: [
			FindReferenceMedia
		]
	});

	function handleUpload(dto: CreateReferenceMediaMutationVariables) {
		return upload({
			variables: {
				...dto,
				referenceId: requestId,
			},
		}).then(res => {
			return res.data;
		}).catch(err => {
			console.error("Failed to upload media", err);
			snack.enqueueSnackbar("We ran into an issue uploading your image", {
				variant: "error",
			});
		});
	}

	return {
		loading,
		isUploading,
		upload: handleUpload,
		media: data?.FindReferenceMedia || [],
	};
}