import { Alert, Avatar, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from "@mui/material";
import { Box, Grid, Heading } from "grommet";
import React, { useMemo } from "react";
import { WasteContainerState } from "../../../types";
import { UseFormReturn } from "react-hook-form";
import { useWasteContainerAssignment } from "../../../hooks";
import moment from "moment";
import { Gauge } from "@mui/x-charts";
import { useWindowDimensions } from "../../../../../../hooks";
import { formatNumber } from "../../../../../../helpers";

export const WeightReportingTab: React.FC<{
	assignmentId: string;
	formContext: UseFormReturn<WasteContainerState>;
}> = ({ assignmentId, formContext }) => {
	const theme = useTheme();
	const { size } = useWindowDimensions();
	const { assignment } = useWasteContainerAssignment(assignmentId);

	const averageFillRatePercentage = useMemo(() => {
		const value = Number(assignment?.averageFillRate || 0);
		return Math.round(value * 100);
	}, [ assignment ]);

	const weeklyCollectionWeightFormatted = useMemo(() => {
		const value = Number(assignment?.weeklyCollectionWeight || 0);
		return [
			formatNumber(value, true),
			"lb(s)"
		].join(" ");
	}, [ assignment ]);

	return (
		<Box gap="medium">
			{assignment?.averageFillRate === 0 && (
				<MissingDataContent assignmentId={assignmentId} />
			)}
			{assignment?.averageFillRate !== 0 && (
				<Box>
					<Grid columns={{ count: size === "small" ? 1 : 2, size: "auto" }} gap="medium">
						<Paper
							variant="outlined"
							sx={{ padding: theme.spacing(2) }}
						>
							<Box align="center" gap="medium">
								<Gauge
									height={100}
									value={averageFillRatePercentage}
									startAngle={-90}
									endAngle={90}
									text={(value) => `${value.value}%`}
								/>
								<Heading margin="none" level="5" >
									Average Fill Rate (%)
								</Heading>
							</Box>
						</Paper>
						<Paper
							variant="outlined"
							sx={{ padding: theme.spacing(2) }}
						>
							<Box height="100%" gap="medium">
								{/* <Box align="center">
									<ButtonGroup>
										<ToggleButton value="weekly">
											Weekly
										</ToggleButton>
										<ToggleButton value="monthly">
											Monthly
										</ToggleButton>
									</ButtonGroup>
								</Box> */}
								<Box flex align="center" justify="center">
									<Heading level="2" margin="none" color={theme.palette.primary.main}>
										{weeklyCollectionWeightFormatted}
									</Heading>
								</Box>
								<Box align="center">
									<Heading margin="none" level="5">
										Weekly Collection Weight
									</Heading>
								</Box>
							</Box>
						</Paper>
					</Grid>
				</Box>
			)}
		</Box>
	);
};

const MissingDataContent: React.FC<{
	assignmentId: string;
}> = ({ assignmentId }) => {
	const { assignment } = useWasteContainerAssignment(assignmentId);

	const inspections = useMemo(() => {
		return (assignment?.inspections || []).filter(i => !i.inspected);
	}, [ assignment ]);

	const theme = useTheme();

	return (
		<Box gap="small">
			<Alert severity="warning">
				No weight reporting data available for this container.
			</Alert>
			<Typography variant="body2">
				Finish uploading images of your containers on collection day to view weight reporting data.
			</Typography>
			<Heading level="4" margin="none">
				Scheduled Collection Date(s)
			</Heading>
			<List>
				{inspections.map((inspection, index) => (
					<ListItem
						divider
						key={inspection.id}
					>
						<ListItemIcon>
							<Avatar
								variant="circular"
								sx={{ backgroundColor: theme.palette.primary.light }}
							>
								<Heading margin="none" level="4" color={theme.palette.primary.contrastText}>
									{index + 1}
								</Heading>
							</Avatar>
						</ListItemIcon>
						<ListItemText>
							<Typography fontWeight="bold">
								{moment(inspection.scheduledFor).format("MMM Do, YYYY")}
							</Typography>
						</ListItemText>
					</ListItem>
				))}
			</List>
		</Box>
	);
};