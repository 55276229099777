import { AuthService } from "../auth";
import { useQuery } from "@apollo/client";
import { FetchSelf } from "../../graphql";

export function useUser() {
	const { data, loading, refetch } = useQuery(FetchSelf);

	return {
		loading,
		refetch,
		user: data?.FetchSelf ?? null,
		contacts: Array.from((data?.FetchSelf?.contacts ?? [])).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
		addresses: data?.FetchSelf?.addresses ?? [],
		updateLogin(accessToken: string, refreshToken: string) {
			console.debug("updating login with new access and refresh tokens");
			AuthService.setAccessTokenComponents(
				accessToken,
				refreshToken
			);

			console.debug("refetching user data");
			refetch().catch(err => {
				console.error("Failed to fetch user", err);
			});
		}
	};
}
