import { Box, Heading } from "grommet";
import { PageNotAvailableView } from "../../../../views/NotAvailable";
import { useSnackbar } from "notistack";
import { useStorePartner } from "../../hooks/useStorePartner";
import { useStorePermissions } from "../../hooks";
import { StoreSelectorComponent } from "../../components";
import { ViewContainer } from "../../../../components";


export const StoreDashboardView: React.FC = () => {
	const snack = useSnackbar();
	const { role, isPermitted, isEnabled } = useStorePermissions();
	const { partners, selectedPartner, setSelectedPartner, isLoading } = useStorePartner();

	if(!isEnabled) {
		return (<PageNotAvailableView />);
	}

	return (
		<ViewContainer>
			<Box gap="medium" flex>
				<Heading margin="none" level="3">
					Store Dashboard
				</Heading>
				<StoreSelectorComponent />
			</Box>
		</ViewContainer>
	);
};