import { Box, CheckBox, Grid, Page, PageContent, Text } from "grommet";
import { StepControls } from "../../../components";
import { ContactType } from "../../../graphql/__generated__/graphql";
import { useFullScreen, useInstance } from "../../../hooks";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Autorenew, Warning } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { formatPhoneNumber } from "../../../helpers";
import { useUser } from "../../../auth";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectAgreedToContactTerms, setAgreedToContactTerms } from "../../../store/schedule";
import { ContactFormController } from "../../user";

export default function ContactView(props: { sessionId: string; }) {
	const { user, contacts } = useUser();
	const dispatch = useAppDispatch();
	const fullScreen = useFullScreen();
	const { instance } = useInstance();

	const agreedToTerms = useAppSelector(selectAgreedToContactTerms);

	const primaryEmail = useMemo(() => {
		return contacts.find(c => c.type === ContactType.Email && c.isPrimary);
	}, [ contacts ]);

	const primaryPhone = useMemo(() => {
		return contacts.find(c => c.type === ContactType.Phone && c.isPrimary);
	}, [ contacts ]);

	const [ isContactFormOpen, setIsContactFormOpen ] = useState<ContactType | null>(null);
	const contactFormType = useMemo(() => {
		switch(isContactFormOpen) {
			case ContactType.Email: {
				if(contacts.some(c => c.type === ContactType.Email)) {
					return "SELECT";
				}
				else {
					return "CREATE";
				}
			}
			case ContactType.Phone: {
				if(contacts.some(c => c.type === ContactType.Phone)) {
					return "SELECT";
				}
				else {
					return "CREATE";
				}
			}
			default: {
				return "CREATE";
			}
		}
	}, [ isContactFormOpen, contacts ]);

	function handleClickEmail() {
		setIsContactFormOpen(ContactType.Email);
	}

	function handleClickPhone() {
		setIsContactFormOpen(ContactType.Phone);
	}

	const canProceed = useMemo(() => {
		return agreedToTerms && !!primaryEmail?.isVerified && !!primaryPhone?.isVerified;
	}, [ agreedToTerms, primaryEmail, primaryPhone ]);

	return (
		<Page kind="narrow">
			{isContactFormOpen && (
				<ContactFormController
					type={isContactFormOpen}
					kind={contactFormType}
					onClose={() => setIsContactFormOpen(null)}
				/>
			)}
			<PageContent>
				<Box flex gap="medium">
					<Box gap="small">
						<Text weight="bold" size="large">Contact Details</Text>
						<Text>We'll only use your contact information to contact you about your order.</Text>
					</Box>
					<Box gap="small">
						<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="small">
							<TextField
								name="firstName"
								label="First Name"
								disabled
								value={user?.firstName ?? ""}
							/>
							<TextField
								name="lastName"
								label="Last Name"
								disabled
								value={user?.lastName ?? ""}
							/>
							<TextField
								name="email"
								label="Email Address"
								onClick={handleClickEmail}
								value={primaryEmail?.value ?? ""}
								error={!primaryEmail?.isVerified}
								helperText={(primaryEmail && !primaryEmail.isVerified) &&
									"This email address is not verified"
								}
								InputProps={{
									endAdornment: (primaryEmail && !primaryEmail.isVerified)
										? (
											<Tooltip title="Not Verified">
												<Warning color="error" />
											</Tooltip>
										)
										: (
											<InputAdornment position="end">
												<IconButton>
													<Autorenew />
												</IconButton>
											</InputAdornment>
										)
								}}
							/>
							<TextField
								name="phone"
								label="Phone Number"
								onClick={handleClickPhone}
								value={(primaryPhone?.value)
									? formatPhoneNumber(primaryPhone.value)
									: ""
								}
								error={!primaryPhone?.isVerified}
								helperText={(primaryPhone && !primaryPhone.isVerified) &&
									"This phone number is not verified"
								}
								InputProps={{
									endAdornment: (primaryPhone && !primaryPhone.isVerified)
										? (
											<Tooltip title="Not Verified">
												<Warning color="error" />
											</Tooltip>
										)
										: (
											<InputAdornment position="end">
												<IconButton>
													<Autorenew />
												</IconButton>
											</InputAdornment>
										)
								}}
							/>
						</Grid>
						<Text weight="bold" margin="xsmall">
							By providing this information, I authorize Rego to contact me at the phone number and email address provided for status updates and other account related notifications.
						</Text>
						<CheckBox
							label={<Text weight="bold">I Agree</Text>}
							checked={agreedToTerms}
							onChange={() => {
								dispatch(setAgreedToContactTerms(!agreedToTerms));
							}}
						/>
					</Box>
					<StepControls
						name="schedule"
						isLoading={false}
						submitButtonLabel="Submit"
						canProceed={canProceed}
					/>
				</Box>
			</PageContent>
		</Page>
	);
}