import { SvgIcon, SvgIconProps } from "@mui/material";

	export const DiningChair: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="m833.21 658.67h-15.684l48.719-308.85c12.301-77.969-10.059-157.1-61.344-217.11-51.285-60-125.97-94.418-204.9-94.418-78.938 0-153.62 34.418-204.91 94.426-51.281 60.008-73.648 139.14-61.348 217.11l48.719 308.85h-15.684c-42.848 0-77.707 34.859-77.707 77.707v68.422c0 14.496 11.754 26.25 26.25 26.25h38.723v304.39c0 14.496 11.754 26.25 26.25 26.25h93.219c14.496 0 26.25-11.754 26.25-26.25v-72.613h200.47v72.613c0 14.496 11.754 26.25 26.25 26.25h93.219c14.496 0 26.25-11.754 26.25-26.25v-304.39h38.723c14.496 0 26.25-11.754 26.25-26.25v-68.422c-0.007813-42.848-34.871-77.715-77.719-77.715zm-100.62 0 84.449-351.94c0.050782 11.602-0.80859 23.273-2.6484 34.922l-50.012 317.02h-31.789zm32.406-491.85c14.152 16.559 25.492 34.949 33.988 54.473l-104.95 437.38h-24.398l70.109-516.92c9.0156 7.5977 17.477 15.969 25.254 25.074zm-196.78 491.85-73.328-540.68c26.43-14.641 55.801-23.676 86.363-26.316v566.99zm50.535-566.99c30.562 2.6406 59.934 11.676 86.363 26.316l-73.328 540.68h-13.035zm-183.75 75.148c7.7852-9.1055 16.238-17.484 25.262-25.078l70.102 516.92h-24.391l-104.96-437.38c8.5-19.516 19.84-37.906 33.984-54.461zm-49.395 174.83c-1.8359-11.656-2.707-23.34-2.6484-34.949l84.457 351.98h-31.801zm61.656 767.55h-40.719v-278.14h40.719zm52.5-98.863v-179.28h200.47v179.28zm293.69 98.863h-40.719v-278.14h40.719zm64.973-330.64h-516.85v-42.172c0-13.906 11.309-25.207 25.207-25.207h46.289c0.03125 0 0.050782 0.007812 0.082032 0.007812s0.058594-0.007812 0.089844-0.007812h373.51c0.03125 0 0.058594 0.007812 0.089844 0.007812s0.050781-0.007812 0.082031-0.007812h46.289c13.906 0 25.207 11.309 25.207 25.207z" />
</svg>
			</SvgIcon>
		);
	};