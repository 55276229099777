import { gql } from "@apollo/client";

export const WasteTicket_HeaderFragment = gql`
	fragment WasteTicket_HeaderFragment on WasteTicket {
		id
		date
		createdAt
		processed
		processedAt
		ticketNumber
	}
`;