import { useQuery } from "@apollo/client";
import { BrandGql, FindBrands } from "../../../graphql";
import { useEffect, useState } from "react";

export function useBrands() {
	const { data, error } = useQuery(FindBrands);
	const [ brands, setBrands ] = useState<BrandGql[]>([]);

	useEffect(() => {
		if(data) {
			setBrands([ ...data.FindBrands ].sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)));
		}
	}, [ data ]);

	useEffect(() => {
		if(error) {
			console.error("Failed to list brands", error);
		}
	}, [ error ]);

	return brands;
}