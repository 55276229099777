import { gql } from "../../../__generated__";

export const FindOrders = gql(/* GraphQL */ `
	query FindOrders(
		$refererId: String
		$instanceId: String
		$status: iStringFilters
		$user: FindUserDTO
		$createdAt: iDateFilters
		$cancelledAt: iDateFilters
		$submittedAt: iDateFilters
		$completedAt: iDateFilters
	) {
		FindOrders(
			user: $user
			referer:{id:{equals: $refererId}}
			instance:{id:{equals: $instanceId}}
			status: $status
			createdAt: $createdAt
			cancelledAt: $cancelledAt
			submittedAt: $submittedAt
			completedAt: $completedAt
		) {
			...Order_HeaderFragment
		}
	}
`);

export const FindOrdersV2 = gql(/* GraphQL */ `
	query FindOrdersV2(
		$referer: FindReferralPartnerDTO
		$instance: FindInstanceDTO
		$status: iStringFilters
		$user: FindUserDTO
		$createdAt: iDateFilters
		$cancelledAt: iDateFilters
		$submittedAt: iDateFilters
		$completedAt: iDateFilters
	) {
		FindOrders(
			user: $user
			referer: $referer
			instance: $instance
			status: $status
			createdAt: $createdAt
			cancelledAt: $cancelledAt
			submittedAt: $submittedAt
			completedAt: $completedAt
		) {
			...Order_HeaderFragment
		}
	}
`);

export const FindExpandedOrders = gql(/* GraphQL */ `
	query FindExpandedOrders(
		$status: iStringFilters
		$submittedAt: iDateFilters
		$scheduledAt: iDateFilters
	) {
		FindOrders(
			status: $status
			submittedAt: $submittedAt
			scheduledAt: $scheduledAt
		) {
			...Order_ExpandedFragment
		}
	}
`);

export const FindUserOrders = gql(/* GraphQL */ `
	query FindUserOrders(
		$userId: String!
		$instanceId: String!
		$scheduledAt: iDateFilters
		$pickup: FindPickupDTO
	) {
		FindUserOrders(
			userId: $userId
			scheduledAt: $scheduledAt
			pickup: $pickup
			instance:{id:{equals: $instanceId}}
		) {
			...Order_ExpandedFragment
		}
	}
`);