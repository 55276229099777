import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Credenza: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1141.4 418.43h-1080.8v298.37h107.27l-9.9492 48c-0.75781 3.6133-0.058593 7.3086 1.9805 10.402 2.0391 3.0977 5.1484 5.207 8.7461 5.9531 1.3555 0.27734 2.5938 0.42188 3.7188 0.42188 5.8203 0 8.8906-3.6367 11.797-9.4688l27.145-55.309h777.39l25.367 52.055 1.6914 3.0977c3 5.9766 6.0977 9.6367 11.914 9.6367 1.1289 0 2.3516-0.14453 3.6953-0.42188 3.625-0.74219 6.7422-2.8555 8.7734-5.9531 2.0273-3.0977 2.7227-6.793 1.9688-10.379l-9.9609-48.023h109.25l0.007812-298.38zm-540.9 83.469h260.57v59.773h-260.57zm0 71.617h260.57v59.762h-260.57zm0 71.629h260.57v59.785h-260.57zm260.57-155.1h-260.57v-59.762h-11.855v274.64l-251.2 0.003907v-274.66h523.62zm-788.62-59.77h253.15v274.66h-253.15zm103.1 332.9-1.8828 3.4453c-1.0078 2.0391-1.5977 2.8789-1.5586 3.0977-0.20312-0.023438-0.53906-0.070312-1.0547-0.19141-0.69531-0.14453-1.0781-0.58984-1.2461-0.83984-0.16797-0.25391-0.42188-0.76953-0.27734-1.4766l10.453-50.41h18.156zm854.96 4.0547c0.14453 0.68359-0.12109 1.1992-0.27734 1.4531s-0.55078 0.70703-1.2617 0.86328c-0.38281 0.070313-0.67188 0.12109-0.80469 0.16797-0.3125-0.42188-0.92578-1.332-1.8945-3.2773l-24.348-49.645h18.156zm99.035-62.301h-256.62v-274.66h256.62z"/>
</svg>
			</SvgIcon>
		);
	};