import { gql } from "../../../__generated__";

export const UpdateProductFragment = gql(/* GraphQL */ `
	mutation UpdateProductFragment(
		$fragmentId: String!
		$typeId: String
		$sizeId: String
		$quantity: Int
		$quantityDisassembly: Int
		$condition: ProductCondition
		$intent: ProductIntent
		$listingTitle: String
		$listingDescription: String
		$listingPrice: Float
		$listingPriceCompare: Float
		$allowPickup: Boolean
		$brand: String
		$materials: [String!]
	) {
		UpdateProductFragment(
			fragmentId: $fragmentId
			typeId: $typeId
			sizeId: $sizeId
			quantity: $quantity
			quantityDisassembly: $quantityDisassembly
			condition: $condition
			intent: $intent
			listingTitle: $listingTitle
			listingDescription: $listingDescription
			listingPrice: $listingPrice
			listingPriceCompare: $listingPriceCompare
			allowPickup: $allowPickup
			brand: $brand
			materials: $materials
		) {
			id
		}
	}
`);
