import { useMemo } from "react";
import { replace } from "redux-first-history";
import { InstanceType } from "../graphql/__generated__/graphql";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../store";
import { useUser } from "../auth";

export function useDashboardRedirect() {
	const dispatch = useAppDispatch();
	const { user } = useUser();
	const instance = useAppSelector(selectInstancePublic);

	const redirectLink = useMemo(() => {
		if(!instance) return "/dashboard";

		//TODO: Need to inspect instance:role
		switch(instance.type) {
			case InstanceType.Admin: {
				return "/admin/dashboard";
			}
			case InstanceType.Hauler: {
				return "/hauler/dashboard";
			}
			default: {
				console.log("User instance role: ", user);
				if(user?.instanceRole === "instance:admin") {
					return "/admin/dashboard";
				}

				if(user?.instanceRole === "instance:manager") {
					return "/admin/dashboard";
				}

				return "/dashboard";
			}
		}
	}, [ user, instance ]);

	return {
		redirect: () => {
			if(!redirectLink) return;
			dispatch(replace(redirectLink));
		}
	};
}