import { gql } from "@apollo/client";


export const WasteReport_LineItemsFragment = gql`
	fragment WasteReport_LineItems on WasteReportFilter {
		lineItems {
			date
			annualized
			totalWeightDivertedPounds
			totalWeightGeneratedPounds
		}
	}
`;
