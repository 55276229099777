
export const regions = [
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45202", "population": 15483 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45203", "population": 2236 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45204", "population": 6972 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45205", "population": 19389 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45206", "population": 10745 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45207", "population": 7974 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45208", "population": 17614 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45209", "population": 9605 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45211", "population": 36111 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45212", "population": 22253 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45213", "population": 12013 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45214", "population": 8738 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45215", "population": 29623 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45216", "population": 9570 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45217", "population": 6566 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45219", "population": 16526 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45220", "population": 15313 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45223", "population": 12360 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45224", "population": 19837 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45225", "population": 9040 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45226", "population": 5476 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45227", "population": 17486 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45229", "population": 13020 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45230", "population": 27536 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45232", "population": 6358 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45233", "population": 15808 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45237", "population": 20021 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45238", "population": 45760 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45239", "population": 28315 },
	{ "state": "OH", "city": "Cincinnati", "zipCode": "45248", "population": 24915 }
];