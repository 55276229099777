import { Anchor, Box } from "grommet";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../store";
import { UseFormReturn, useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { push } from "redux-first-history";
import { LoadingButton } from "@mui/lab";
import { BaseDialog } from "../../../components";
import { useEffect } from "react";
import { useInstanceBuildingPublic, useRedirect } from "../../../hooks";
import { Moment } from "moment-timezone";
import { BeginBuildingOnboarding, ConfirmBuildingOnboarding } from "../../../graphql";
import { RegistrationFormValues, RegistrationForm, UsernameElement, UnitElement, FirstNameElement, LastNameElement, MoveOutDateElement, ConfirmationCodeElement } from "../components";
import { useBeginOnboarding, useConfirmOnboarding } from "../hooks";

type BuildingOnboardingState = RegistrationFormValues<{
	unit: string;
	moveInDate: Moment | null;
	moveOutDate: Moment | null;
}>;

export const BuildingOnboarding: React.FC = () => {
	const dispatch = useAppDispatch();
	const { redirect } = useRedirect();
	const instance = useAppSelector(selectInstancePublic);
	const formContext = useForm<BuildingOnboardingState>({
		defaultValues: {
			unit: "",
			username: "",
			code: "",
			firstName: "",
			lastName: "",
			moveInDate: null,
			moveOutDate: null
		}
	});

	const formValues = formContext.watch();
	const { building } = useInstanceBuildingPublic();

	const {
		wasCodeSent,
		canResendCode,
		resendCode,
		isSendingCode,
		begin,
	} = useBeginOnboarding(BeginBuildingOnboarding);

	const {
		wasConfirmSuccessful,
		accessToken,
		refreshToken,
		loading: confirmLoading,
		confirm
	} = useConfirmOnboarding(ConfirmBuildingOnboarding);

	function handleSubmit() {
		if(!instance) return;
		if(!building) return;
		const { unit, username, firstName, lastName, code, moveInDate, moveOutDate } = formValues;

		if(wasCodeSent) {
			confirm({
				instanceId: instance.id,
				buildingId: building.id,
				username,
				unit,
				code,
				firstName,
				lastName,
				moveInDate,
				moveOutDate,
			});
			return;
		}

		begin({
			instanceId: instance.id,
			buildingId: building.id,
			username,
			unit
		});
	}

	useEffect(() => {
		if(wasConfirmSuccessful && accessToken && refreshToken) {
			dispatch(push("/dashboard"));
			redirect("/dashboard", {
				schedule: "true"
			});
		}
	}, [ wasConfirmSuccessful, accessToken, refreshToken ]);

	return (
		<Box>
			<BaseDialog
				title="Registration"
				content={(
					<RegistrationForm
						formContext={formContext as unknown as UseFormReturn<RegistrationFormValues>}
						elements={[
							<Typography>
								Enter your email address or phone number to get started.
							</Typography>,
							<UsernameElement
								disabled={wasCodeSent}
								username={formContext.watch("username")}
							/>,
							!wasCodeSent && <UnitElement
								unitPrefix={building?.unitPrefix || ""}
							/>,
							!wasCodeSent && <FirstNameElement />,
							!wasCodeSent && <LastNameElement />,
							!wasCodeSent && <MoveOutDateElement />,
							<ConfirmationCodeElement
								wasCodeSent={wasCodeSent}
								canResendCode={canResendCode}
								onResendCode={resendCode}
								username={formContext.watch("username")}
							/>
						].filter(Boolean)}
					/>
				)}
				actions={(
					<Box direction="row" justify="between">
						<Box align="start" justify="center">
							<Typography>
								<Anchor onClick={() => dispatch(push("/login"))}>Switch to login?</Anchor>
							</Typography>
						</Box>
						<Box align="end" justify="center">
							<LoadingButton
								color="primary"
								variant="contained"
								loading={isSendingCode || confirmLoading}
								onClick={formContext.handleSubmit(handleSubmit)}
							>
								Submit
							</LoadingButton>
						</Box>
					</Box>
				)}
			/>
		</Box>
	);
};