import { gql } from "../../../__generated__";

export const UpdateAddress = gql(/* GraphQL */ `
	mutation UpdateAddress(
		$addressId: String!
		$addressLineOne: String!
		$addressLineTwo: String
		$city: String!
		$state: String!
		$zipCode: String!
		$floor: Int!
		$hasParking: Boolean!
		$hasElevator: Boolean!
		$instructions: String
		$instructionsParking: String
		$lat: String!
		$lng: String!
		$timezone: String!
	) {
		UpdateAddress(
			addressId: $addressId
			addressLineOne: $addressLineOne
			addressLineTwo: $addressLineTwo
			city: $city
			state: $state
			zipCode: $zipCode
			floor: $floor
			hasParking: $hasParking
			hasElevator: $hasElevator
			instructions: $instructions
			instructionsParking: $instructionsParking
			lat: $lat
			lng: $lng
			timezone: $timezone
		) {
			id
		}
	}
`);