import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { push } from "redux-first-history";
import { CreateWasteAudit, FindWasteAudit } from "../../../../../graphql";
import { useInstance } from "../../../../../hooks";
import { useAppDispatch } from "../../../../../store";
import { useToggleForceClose } from "../../../components";
import { WasteAuditForm } from "./WasteAuditForm";

export const WasteAuditCreateDialog: React.FC<{
	activatorButton: React.ReactNode;
}> = ({ activatorButton }) => {
	const dispatch = useAppDispatch();
	const { instance } = useInstance();
	const { forceClose, toggleForceClose } = useToggleForceClose();
	const formContext = useForm({
		defaultValues: {
			name: "",
			partnerId: ""
		}
	});

	const [
		create,
		{ loading, data }
	] = useMutation(CreateWasteAudit, {
		refetchQueries: [ FindWasteAudit ]
	});

	useEffect(() => {
		if(data?.CreateWasteAudit.id) {
			dispatch(push(`/admin/audits/${data.CreateWasteAudit.id}`));
		}
	}, [ data, dispatch ]);

	function handleSubmit() {
		const { name, partnerId } = formContext.getValues();
		if(!name || !instance || !partnerId) return;

		create({
			variables: {
				name,
				partnerId: partnerId,
				instanceId: instance.id
			}
		});
	}

	return (
		<WasteAuditForm
			buttonTitle="Create"
			title="Waste Audit"
			loading={loading}
			onSubmit={handleSubmit}
			forceClose={forceClose}
			toggleForceClose={toggleForceClose}
			activatorButton={activatorButton}
			formContext={formContext}
		/>
	);
};