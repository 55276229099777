import React, { Fragment, ReactNode, useCallback } from "react";
import { Box, Grid } from "grommet";
import { HeadingWithIcon, Pagination, ViewContainer } from "../../../../components";
import { useFullScreen, useWindowDimensions } from "../../../../hooks";
import { Button, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { TaskAlt } from "@mui/icons-material";
import { DashboardActionsContainer } from "../components";
import { useActionItems } from "../hooks";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

export const DashboardView: React.FC = () => {
	const { size } = useWindowDimensions();

	const container = useCallback((children: ReactNode) => {
		if(size === "xlarge") {
			return (
				<Grid
					height="100%"
					columns={[ "2/3", "1/3" ]}
					style={{ overflow: "scroll" }}
				>
					{children}
				</Grid>
			);
		}

		return (
			<Box
				height="100%"
				overflow={{ vertical: "scroll" }}
			>
				{children}
			</Box>
		);
	}, [ size ]);

	const dispatch = useAppDispatch();
	const fullScreen = useFullScreen();
	const { tasks, loading } = useActionItems();

	return (
		<ViewContainer>
			{container(
				<Fragment>
					<Box
						gap="medium"
						height="100%"
						style={{ minHeight: "auto", display: "block" }}
					>
						<HeadingWithIcon
							text="Action Items"
							props={{
								level: "3",
								margin: "none"
							}}
							icon={<TaskAlt color="primary" />}
						/>
						{(tasks.length === 0 || loading) && (
							<Box
								flex
								align="center"
								justify="center"
								style={{ minHeight: "200px" }}
							>
								<Typography>
									{loading ? "Loading..." : "nothing to do!"}
								</Typography>
							</Box>
						)}
						{tasks.length > 0 && !loading && (
							<Box
								id="parent-tasks-container"
								pad={{ right: size === "xlarge" ? "medium" : undefined }}
							>
								<Pagination
									pageSize={3}
								>
									{tasks.map(task => (
										<ListItemButton
											key={task.id}
											divider
											onClick={() => {
												if(task.actionUrl.includes("http")) {
													window.open(task.actionUrl, "_blank");
													return;
												}

												dispatch(push(task.actionUrl));
											}}>
											<ListItemIcon>
												{task.icon || <TaskAlt />}
											</ListItemIcon>
											<ListItemText>
												<Box style={{ maxWidth: "500px" }}>
													<Typography fontWeight="bold">
														{task.title}
													</Typography>
													<Typography variant="caption" style={{ maxWidth: "350px" }}>
														{task.label}
													</Typography>
												</Box>
											</ListItemText>
											{(task.actionLabel && task.actionUrl && !fullScreen) && (
												<ListItemSecondaryAction>
													<Button
														size="small"
														onClick={() => {
															dispatch(push(task.actionUrl));
														}}
														color="primary"
														variant="contained"
													>
														{task.actionLabel}
													</Button>
												</ListItemSecondaryAction>
											)}
										</ListItemButton>
									))}
								</Pagination>
							</Box>
						)}
					</Box>
					<DashboardActionsContainer />
				</Fragment>
			)}
		</ViewContainer>
	);
};