import { useLazyQuery } from "@apollo/client";
import { FetchHaulerPickup } from "../../../graphql";
import { useEffect, useMemo } from "react";

export function useLazyHaulerPickup(pickupId: string) {
	const [
		fetch,
		{ data, loading, error }
	] = useLazyQuery(FetchHaulerPickup);

	useEffect(() => {
		fetch({ variables: { pickupId } }).catch(err => {
			console.error(err);
		});
	}, [ pickupId, fetch ]);

	const pickup = useMemo(() => {
		return data?.FetchPickup || null;
	}, [ data ]);

	return {
		data,
		loading,
		error,
		pickup,
		isStarted: !!pickup?.started,
		//isCancelled: !!pickup?.cancelled,
		isCompleted: !!pickup?.completed,
		isInProgress: !!pickup?.started && !pickup?.completed,
	};
}