import { CalendarMonth, EventAvailable } from "@mui/icons-material";
import { Box, Grid, Spinner, Text } from "grommet";
import { useWindowDimensions } from "../../../../hooks";
import { PickupWindow, Quote_SchedulingFragmentFragment } from "../../../../graphql/__generated__/graphql";
import { List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { FetchOrderUnified, UpdateOrderPreferredWindow } from "../../../../graphql";
import { useMemo, useState } from "react";
import moment from "moment";

interface PickupWindowAccordionSummaryProps {
	scheduledDate?: string;
	selectedWindow?: PickupWindow;
}

export const PickupWindowAccordionSummary: React.FC<PickupWindowAccordionSummaryProps> = (props) => {
	const { size } = useWindowDimensions();

	const formatted = useMemo(() => {
		if(!props.scheduledDate || !props.selectedWindow) return "";

		return size === "small"
			? props.selectedWindow?.label?.replace(/(AM|PM)/g, "").replace(/:00/g, "")
			: props.selectedWindow?.label;
	}, [ props.scheduledDate, props.selectedWindow, size ]);

	return (
		<Grid columns={[ "auto", "small", "flex" ]} gap="small">
			<Box align="center" justify="center">
				<EventAvailable />
			</Box>
			<Box justify="center">
				<Text weight="bold">
					Pickup Window
				</Text>
			</Box>
			{!props.scheduledDate && (
				<Typography color="error">
					Please select a date
				</Typography>
			)}
			{(props.scheduledDate && !formatted) && (
				<Typography color="error">
					Not Selected
				</Typography>
			)}
			{formatted && (
				<Box justify="center">
					<Typography>
						{formatted}
					</Typography>
				</Box>
			)}
		</Grid>
	);
};

interface PickupWindowAccordionDetailsProps {
	orderId: string;
	selectedDate?: string;
	selectedQuote?: Quote_SchedulingFragmentFragment;
	selectedWindow?: PickupWindow;
	onWindowSelected: (window: PickupWindow) => void;
}

export const PickupWindowAccordionDetails: React.FC<PickupWindowAccordionDetailsProps> = (props) => {
	const [ isSelecting, setIsSelecting ] = useState("");

	const [
		updatePreferredWindow,
		{ loading: updatePreferredWindowLoading }
	] = useMutation(UpdateOrderPreferredWindow, {
		refetchQueries: [ FetchOrderUnified ]
	});

	function selectWindow(window: PickupWindow): void {
		setIsSelecting(window.label);
		updatePreferredWindow({
			variables: {
				orderId: props.orderId,
				window: {
					from: window.from,
					to: window.to,
					label: window.label
				}
			}
		}).then(() => {
			props.onWindowSelected(window);
		}).finally(() => {
			setIsSelecting("");
		});
	}

	const windows = useMemo(() => {
		return (props.selectedQuote?.pickupOptions || []).filter(option => {
			return moment(option.date).isSame(moment(props.selectedDate), "day");
		}).flatMap(option => option.windows).sort((a, b) => {
			return Number(a.from) - Number(b.from);
		});
	}, [ props.selectedQuote, props.selectedDate ]);

	return (
		<Box margin="small">
			<List>
				{windows.map((window) => (
					<ListItemButton
						key={window.label}
						disabled={updatePreferredWindowLoading}
						selected={props.selectedWindow?.label === window.label}
						onClick={() => selectWindow(window)}
					>
						<ListItemIcon>
							<CalendarMonth />
						</ListItemIcon>
						<ListItemText>
							<Typography fontWeight="bold">
								{window.label}
							</Typography>
						</ListItemText>
						<ListItemSecondaryAction>
							{isSelecting === window.label && (
								<Spinner />
							)}
						</ListItemSecondaryAction>
					</ListItemButton>
				))}
			</List>
		</Box>
	);
};