import { Box, Grid } from "grommet";
import React, { useMemo } from "react";
import { CategoryListItem, TypeSelectionDialog } from "../components";
import { useClassification, useWindowDimensions } from "../../../hooks";
import Fuse from "fuse.js";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Fade, IconButton, InputAdornment, LinearProgress, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Popper, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { findProductInState, selectCheckoutState, selectProductSearchTerm, setProductSearchTerm } from "../../../store/checkout";
import { Icon } from "@liverego/icons";
import { Close } from "@mui/icons-material";

export const ItemSelectionStep: React.FC = () => {
	const { size } = useWindowDimensions();

	const columns = useMemo(() => {
		switch(size) {
			case "small": {
				return 3;
			}
			case "medium": {
				return 3;
			}
			case "large": {
				return 4;
			}
			case "xlarge": {
				return 5;
			}
		}
	}, [ size ]);

	const { types, loading } = useClassification();

	const categories = useMemo(() => {
		const mapped = types.map((type) => {
			return type.category;
		});

		return mapped.filter((value, index, self) => {
			return self.indexOf(value) === index;
		}).sort((a, b) => a.index - b.index);
	}, [ types ]);

	const dispatch = useAppDispatch();
	const searchTerm = useAppSelector(selectProductSearchTerm);

	return (
		<Box gap="small">
			{(loading) && (
				<LinearProgress />
			)}
			{size === "small" && (
				<Box style={{ display: "contents" }} margin={{ bottom: "medium" }}>
					<FormContainer>
						<TextFieldElement
							name="search"
							label="Search"
							id="search-element"
							InputProps={{
								value: searchTerm,
								onChange: (event) => {
									dispatch(setProductSearchTerm(event.target.value));
								},
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => dispatch(setProductSearchTerm(""))}>
											<Close />
										</IconButton>
									</InputAdornment>
								)
							}}
							fullWidth={size === "small"}
						/>
					</FormContainer>
					<SearchSugesstions anchor={document.getElementById("search-element")} />
				</Box>
			)}
			<Box
				align="center"
				margin={{ top: "small" }}
				overflow={{ vertical: "scroll" }}
			>
				<Grid
					gap="small"
					margin={{ top: "small" }}
					width="100%"
					justify="center"
					justifyContent="between"
					columns={{ count: columns, size: "auto" }}
				>
					{categories.map((category) => (
						<CategoryListItem
							key={category.id}
							category={category}
						/>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

export const SearchSugesstions: React.FC<{
	anchor: HTMLElement | null;
}> = ({ anchor }) => {
	const { types } = useClassification();
	const searchTerm = useAppSelector(selectProductSearchTerm);

	const suggestions = useMemo(() => {
		if(!searchTerm) return types;

		const fuse = new Fuse(types, {
			threshold: 0.3,
			includeMatches: true,
			includeScore: true,
			keys: [
				"name",
				"keywords",
				"sizes.name",
				"sizes.keywords",
				"category.name",
				"category.keywords"
			]
		});

		return fuse.search(searchTerm).map(result => result.item);
	}, [ types, searchTerm ]);

	return (
		<Popper
			style={{ zIndex: 2 }}
			placement="bottom-start"
			id={"search-suggestions"} open={suggestions.length > 0 && searchTerm.length >= 2} anchorEl={anchor} transition>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<Box
						background="white"
						elevation="small"
						margin={{ top: "small" }}
						border
						overflow={{ vertical: "scroll" }}
						style={{ minWidth: anchor?.clientWidth ? `${anchor.clientWidth}px` : undefined }}
					>
						<List disablePadding>
							{suggestions.map((suggestion) => (
								<SearchSuggestion key={suggestion.id} type={suggestion} />
							))}
						</List>
					</Box>
				</Fade>
			)}
		</Popper>
	);
};

export const SearchSuggestion: React.FC<{
	type: ReturnType<typeof useClassification>[ "types" ][ 0 ];
}> = ({ type }) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const product = useAppSelector(findProductInState({ typeId: type.id, sizeId: "" }));
	const state = useAppSelector(selectCheckoutState);

	const quantity = useMemo(() => {
		return state.products.reduce((acc, product) => {
			return (product.type?.id === type.id) ? acc + product.quantity : acc;
		}, 0);
	}, [ state ]);

	return (
		<TypeSelectionDialog
			activatorButton={(
				<ListItemButton divider>
					<ListItemIcon>
						<Icon
							url={type.iconUrl || type?.category?.iconUrl || "@liverego/icons/Other"}
							sx={{ fill: theme.palette.primary.main }}
						/>
					</ListItemIcon>
					<ListItemText>
						<Typography fontSize="small" fontWeight="bold">
							{type.name}
						</Typography>
					</ListItemText>
					<ListItemSecondaryAction>
						{(quantity) > 0 && (
							<Typography fontWeight="bold">
								(x{quantity})
							</Typography>
						)}
					</ListItemSecondaryAction>
				</ListItemButton>
			)}
			category={type.category}
			selectedTypes={[ type.id ]}
			onClose={() => {
				dispatch(setProductSearchTerm(""));
			}}
		/>
	);
};