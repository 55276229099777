import { SvgIcon, SvgIconProps } from "@mui/material";

	export const SectionalFourPiece: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m486.91 383.79h-261.33v-92.578h261.33zm-243.75-17.578h226.17v-57.422h-226.17z"/>
  <path d="m450.66 590.04h-188.81c-19.992 0-36.258-16.266-36.258-36.258v-187.57h261.33v187.57c0 19.992-16.266 36.258-36.258 36.258zm-207.49-206.25v169.99c0 10.301 8.3789 18.68 18.68 18.68h188.81c10.301 0 18.68-8.3789 18.68-18.68v-169.99z"/>
  <path d="m730.66 383.79h-261.33v-92.578h261.33zm-243.75-17.578h226.17v-57.422h-226.17z"/>
  <path d="m694.41 590.04h-188.81c-19.992 0-36.258-16.266-36.258-36.258v-187.57h261.33v187.57c0 19.992-16.266 36.258-36.258 36.258zm-207.49-206.25v169.99c0 10.301 8.3789 18.68 18.68 18.68h188.81c10.301 0 18.68-8.3789 18.68-18.68v-169.99z"/>
  <path d="m223.61 571.29h-53.461c-10.781 0-19.559-8.7773-19.559-19.559v-229.51c0-17.098 13.91-31.008 31.008-31.008h61.57v260.52c0 10.781-8.7773 19.559-19.559 19.559zm-42.012-262.5c-7.4062 0-13.43 6.0234-13.43 13.43v229.51c0 1.0898 0.89063 1.9805 1.9805 1.9805h53.461c1.0898 0 1.9805-0.89063 1.9805-1.9805v-242.94z"/>
  <path d="m974.41 383.79h-261.33v-92.578h261.33zm-243.75-17.578h226.17v-57.422h-226.17z"/>
  <path d="m938.16 590.04h-188.81c-19.992 0-36.258-16.266-36.258-36.258v-187.57h261.33v187.57c0 19.992-16.266 36.258-36.258 36.258zm-207.49-206.25v169.99c0 10.301 8.3789 18.68 18.68 18.68h188.81c10.301 0 18.68-8.3789 18.68-18.68v-169.99z"/>
  <path d="m1049.4 833.79h-92.578v-542.58h61.57c17.098 0 31.008 14.285 31.008 31.828zm-75-17.578h57.422v-493.17c0-7.8633-6.0234-14.25-13.43-14.25h-43.992z"/>
  <path d="m974.41 833.79h-187.57c-19.992 0-36.258-16.266-36.258-36.258v-188.81c0-19.992 16.266-36.258 36.258-36.258h187.57zm-187.57-243.75c-10.301 0-18.68 8.3789-18.68 18.68v188.81c0 10.301 8.3789 18.68 18.68 18.68h169.99v-226.17z"/>
  <path d="m1018.4 908.79h-229.51c-10.781 0-19.559-8.7773-19.559-19.559v-53.461c0-10.781 8.7773-19.559 19.559-19.559h260.52v61.57c0 17.098-13.91 31.008-31.008 31.008zm-229.51-75c-1.0898 0-1.9805 0.89063-1.9805 1.9805v53.461c0 1.0898 0.89063 1.9805 1.9805 1.9805h229.51c7.4062 0 13.43-6.0234 13.43-13.43v-43.992z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};