import { useSnackbar } from "notistack";

export function useClipboard() {
	const snack = useSnackbar();

	function handleCopy(text: string) {
		navigator.clipboard.writeText(text).then(() => {
			snack.enqueueSnackbar(`Copied ${text} to clipboard`, {
				variant: "success"
			});
		});
	}
	return {
		copy: handleCopy
	};
}