import React, { useEffect } from "react";
import { CopyAll } from "@mui/icons-material";
import { InputAdornment, IconButton, Button } from "@mui/material";
import { Box } from "grommet";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { DialogWithActivatorButton } from "../../../../components";
import { useClipboard } from "../../../../hooks";
import { useToggleForceClose } from "../../components";

export const InstanceLinksDialog: React.FC<{
	domain: string;
	activatorButton: React.ReactNode;
}> = ({ domain, activatorButton }) => {
	const formContext = useForm({
		defaultValues: {
			checkoutUrl: "",
			registrationUrl: "",
			dashboardUrl: "",
			onboardingUrl: "",
			paymentMethodUrl: ""
		}
	});

	const { checkoutUrl, dashboardUrl, onboardingUrl, registrationUrl, paymentMethodUrl } = formContext.watch();

	useEffect(() => {
		formContext.reset({
			onboardingUrl: `https://${domain}/refer`,
			checkoutUrl: `https://${domain}/checkout`,
			registrationUrl: `https://${domain}/register`,
			dashboardUrl: `https://${domain}/dashboard/`,
			paymentMethodUrl: `https://${domain}/profile/payment-methods`
		});
	}, [ domain, formContext ]);

	const { copy } = useClipboard();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	return (
		<DialogWithActivatorButton
			title="Customer URLs"
			forceClose={forceClose}
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="start">
					<Button
						color="error"
						variant="outlined"
						onClick={toggleForceClose}
					>
						Close
					</Button>
				</Box>
			)}
		>
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<TextFieldElement
						name="onboardingUrl"
						label="Onboarding / Landing URL"
						disabled
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => {
										copy(onboardingUrl);
									}}>
										<CopyAll />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					<TextFieldElement
						name="checkoutUrl"
						label="Checkout URL"
						disabled
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => {
										copy(checkoutUrl);
									}}>
										<CopyAll />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					<TextFieldElement
						name="registrationUrl"
						label="Registration URL"
						disabled
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => {
										copy(registrationUrl);
									}}>
										<CopyAll />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					<TextFieldElement
						name="dashboardUrl"
						label="Dashboard URL"
						disabled
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => {
										copy(dashboardUrl);
									}}>
										<CopyAll />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					<TextFieldElement
						name="paymentMethodUrl"
						label="Payment Method URL"
						disabled
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => {
										copy(paymentMethodUrl);
									}}>
										<CopyAll />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};