import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { FetchHaulerActionItems } from "../../../graphql/documents/hauler/queries";
import { Task } from "../../task";
import { useHaulerQuoteRequests } from "./useHaulerQuotes";
import { useHauler } from "./useHauler";
import { formatCurrency } from "../../../helpers";

export function useHaulerTasks(haulerId: string) {
	const { data, loading } = useQuery(FetchHaulerActionItems, {
		skip: !haulerId,
		variables: { haulerId }
	});

	const { requests } = useHaulerQuoteRequests(haulerId);
	const { hauler } = useHauler();

	const tasks = useMemo((): Task[] => {
		const tasks: Task[] = [];
		tasks.push(
			...(data?.ListProductsPendingDestinationStop || []).map(product => {
				return {
					id: product.id,
					title: `Confirm Dropoff for ${product.type?.name || "Item"}` + (product.totalQuantity > 1 ? ` (X ${product.totalQuantity})` : ""),
					label: "Confirm item was dropped of at destination",
					actionLabel: "Confirm",
					actionUrl: product.destinationStop && `/hauler/stops/${product.destinationStop.id}`,
				} as Task;
			})
		);

		for(const request of requests) {
			tasks.push({
				id: request.id,
				title: "Submit Quote",
				label: `Review and respond to a quote request for ${request.order.productCount} item(s)`,
				actionLabel: "Review",
				actionUrl: `/hauler/orders/${request.order.id}`
			});
		}

		const pickupRequests = hauler?.pendingRequests || [];
		for(const request of pickupRequests) {
			tasks.push({
				id: request.id,
				title: "Confirm Pickup",
				label: `Review and confirm a pickup request for ${formatCurrency(request.amountOffered)}`,
				actionLabel: "Confirm",
				actionUrl: `/hauler/pickups/${request.pickup.id}`
			});
		}

		return tasks;
	}, [ data, requests ]);

	return { loading, tasks };
}