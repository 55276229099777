import React from "react";
import { IconButton } from "@mui/material";
import { BasicListItem } from "../../../../../components";
import { GlobalAdminUpdateOrderMilestoneDialog } from "../../global";
import { Edit } from "@mui/icons-material";
import { useGlobalAdminScopedOrder } from "../../../../../graphql";

export const OrderMilestoneListItem: React.FC<{
	orderId: string;
	permitEdit?: boolean;
}> = ({ orderId, permitEdit }) => {
	const { order, loading } = useGlobalAdminScopedOrder(orderId);

	return (
		<BasicListItem
			label="Milestone"
			loading={loading}
			value={order?.milestone || "Unknown"}
			action={permitEdit && (
				<GlobalAdminUpdateOrderMilestoneDialog
					orderId={orderId}
					activationButton={(
						<IconButton >
							<Edit />
						</IconButton>
					)}
				/>
			)}
		/>
	);
};