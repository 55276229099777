import { ChairAlt, TableRestaurant, Weekend, Bed, Category, Kitchen, Tv, Inventory, Light } from "@mui/icons-material";
import { useMemo } from "react";

export function useProductCategoryIcon(category: string) {
	return useMemo(() => {
		switch(category) {
			case "Chairs, Stools, and Benches": {
				return <ChairAlt color="primary" />;
			}
			case "Tables": {
				return <TableRestaurant color="primary" />;
			}
			case "Desks and Computer Desks": {
				return <TableRestaurant color="primary" />;
			}
			case "Couches, Sofas and Sectionals": {
				return <Weekend color="primary" />;
			}
			case "Mattresses, Bed Frames, and Box Springs": {
				return <Bed color="primary" />;
			}
			case "Appliances": {
				return <Kitchen color="primary" />;
			}
			case "Electronics": {
				return <Tv color="primary" />;
			}
			case "Bookcases and Shelving": {
				return <Inventory color="primary" />;
			}
			case "Storage and Cabinets": {
				return <Inventory color="primary" />;
			}
			case "Television Stands and Entertainment Centers": {
				return <Tv color="primary" />;
			}
			case "Lighting and Light Fixtures": {
				return <Light color="primary" />;
			}
			default: {
				return <Category color="primary" />;
			}
		}
	}, [ category ]);
}
