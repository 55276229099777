import { useInstance } from "../../../../hooks";
import { useMutation, useQuery } from "@apollo/client";
import { CreateBuilding, FindBuildingsReporting } from "../../../../graphql";
import { CreateAddressInputDto } from "../../../../graphql/__generated__/graphql";

export function useBuildings() {
	const { instance } = useInstance();

	const { data, loading } = useQuery(FindBuildingsReporting, {
		skip: !instance,
		variables: {
			instanceId: instance?.id || "",
		}
	});

	const [
		create,
		{ loading: isCreating }
	] = useMutation(CreateBuilding, {
		refetchQueries: [ FindBuildingsReporting ]
	});

	function handleCreate(
		name: string,
		address: CreateAddressInputDto
	) {
		if(!instance) return Promise.reject();
		return create({
			variables: {
				name,
				address,
				instanceId: instance?.id || ""
			}
		});
	}

	return {
		loading,
		isCreating,
		create: handleCreate,
		buildings: data?.FindBuildings || [],
	};
}