import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Television: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m296.39 944.39c-8.3906-4.7812-19.219-1.2188-24 6l-25.172 45.609h-58.781l16.781-28.781c4.7812-8.3906 1.2188-19.219-6-24-8.3906-4.7812-19.219-1.2188-24 6l-31.219 56.391c-2.3906 4.7812-2.3906 12 0 16.781 3.6094 4.7812 8.3906 8.3906 14.391 8.3906h98.391c6 0 12-3.6094 14.391-8.3906l31.219-55.219c4.8281-8.3906 2.3906-17.953-6-22.781z"/>
  <path d="m1024.8 950.39c-4.7812-8.3906-15.609-10.781-24-6s-10.781 15.609-6 24l16.781 28.781h-58.781l-26.391-45.609c-4.7812-8.3906-15.609-10.781-24-6s-10.781 15.609-6 24l31.219 55.219c3.6094 4.7812 8.3906 8.3906 14.391 8.3906h98.391c6 0 12-3.6094 14.391-8.3906 3.6094-4.7812 3.6094-12 0-16.781z"/>
  <path d="m1092 169.22h-984c-43.219 0-78 34.781-78 79.219v578.39c0 43.219 34.781 78 78 78l984-0.046875c43.219 0 78-34.781 78-78v-578.39c0-44.391-34.781-79.172-78-79.172zm43.219 656.39c0 24-20.391 44.391-44.391 44.391h-982.82c-24 0-44.391-20.391-44.391-44.391v-577.22c1.1719-25.172 20.391-44.391 44.391-44.391h984c24 0 44.391 20.391 44.391 44.391v577.22z"/>
  <path d="m139.22 312 33.609-33.609c7.2188-7.2188 7.2188-18 0-24-7.2188-7.2188-18-7.2188-24 0l-33.609 33.609c-7.2188 7.2188-7.2188 18 0 24 7.1719 6 16.781 6 24 0z"/>
  <path d="m238.78 259.22-118.78 118.78c-7.2188 7.2188-7.2188 18 0 24 7.2188 7.2188 18 7.2188 24 0l118.78-118.78c7.2188-7.2188 7.2188-18 0-24-6-7.2188-16.781-7.2188-24 0z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};