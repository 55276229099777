import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { RecentItemRecord, UserPersonalizationService } from "../app";

export interface ApplicationState {
	savedOrders: RecentItemRecord[];
	recentOrders: RecentItemRecord[];
	savedPurchases: RecentItemRecord[];
	recentPurchases: RecentItemRecord[];
}

const initialState: ApplicationState = {
	savedOrders: UserPersonalizationService.getSavedOrders(),
	recentOrders: UserPersonalizationService.getRecentOrders(),
	savedPurchases: UserPersonalizationService.getSavedPurchases(),
	recentPurchases: UserPersonalizationService.getRecentPurchases()
};

export const personalizationSlice = createSlice({
	name: "personalization",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		removeSavedOrder(state, action: PayloadAction<string>) {
			UserPersonalizationService.removeSavedOrder(action.payload);
			state.savedOrders = UserPersonalizationService.getSavedOrders();
		},
		recordSavedOrder(state, action: PayloadAction<string>) {
			UserPersonalizationService.recordSavedOrder(action.payload);
			state.savedOrders = UserPersonalizationService.getSavedOrders();
		},
		recordRecentOrder(state, action: PayloadAction<string>) {
			UserPersonalizationService.recordRecentOrder(action.payload);
			state.recentOrders = UserPersonalizationService.getRecentOrders();
		},
		recordSavedPurchase(state, action: PayloadAction<string>) {
			UserPersonalizationService.recordSavedPurchase(action.payload);
			state.savedPurchases = UserPersonalizationService.getSavedPurchases();
		},
		recordRecentPurchase(state, action: PayloadAction<string>) {
			UserPersonalizationService.recordRecentPurchase(action.payload);
			state.recentPurchases = UserPersonalizationService.getRecentPurchases();
		}
	}
});

export const {
	recordSavedOrder,
	removeSavedOrder,
	recordRecentOrder,
	recordSavedPurchase,
	recordRecentPurchase
} = personalizationSlice.actions;

export const selectSavedOrders = (state: RootState): RecentItemRecord[] => state.personalization.savedOrders;
export const selectRecentOrders = (state: RootState): RecentItemRecord[] => state.personalization.recentOrders;
export const selectSavedPurchases = (state: RootState): RecentItemRecord[] => state.personalization.savedPurchases;
export const selectRecentPurchases = (state: RootState): RecentItemRecord[] => state.personalization.recentPurchases;

export default personalizationSlice.reducer;