import { Button } from "@mui/material";
import { DialogWithActivatorButton } from "../Dialog";
import { PricingPolicyForm } from "./PricingPolicyForm";
import { useEffect, useState } from "react";

export const CreatePricingPolicyButton: React.FC = () => {
	const [ wasCreated, setWasCreated ] = useState(false);

	useEffect(() => {
		if(wasCreated) {
			setTimeout(() => {
				setWasCreated(false);
			}, 10);
		}
	}, [ wasCreated ]);

	return (
		<DialogWithActivatorButton
			title="Create Pricing Policy"
			forceClose={wasCreated}
			activatorButton={(
				<Button
					color="primary"
					variant="outlined"
				>
					Create New
				</Button>
			)}
		>
			<PricingPolicyForm
				policyId=""
				onCreated={() => {
					setWasCreated(true);
				}}
			/>
		</DialogWithActivatorButton>
	);
};