import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { useState } from "react";
import { HaulerScheduledPickupsController } from "./HaulerScheduledPickupsController";
import { HaulerPickupRequestsController } from "./HaulerPickupRequestsController";

export const HaulerPickupsTabController: React.FC<{ haulerId: string; }> = (props) => {
	const [ value, setValue ] = useState("1");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<TabContext value={value}>
			<TabList onChange={handleChange}>
				<Tab value="1" label="Requests" />
				<Tab value="2" label="Scheduled" />
			</TabList>
			{(value === "1") && (
				<HaulerPickupRequestsController />
			)}
			{(value === "2") && (
				<HaulerScheduledPickupsController haulerId={props.haulerId} />
			)}
		</TabContext>
	);
};