import { Box } from "grommet";
import { useMemo } from "react";
import { List } from "@mui/material";
import { useHauler } from "../hooks";
import { OutletDetailsListItem } from "./OutletDetailsListItem";
import { useHaulerScopedOrder } from "../../../graphql";

export const HaulerOrderDropoffDetails: React.FC<{ orderId: string; }> = ({ orderId }) => {
	const { hauler } = useHauler();
	const { order } = useHaulerScopedOrder(orderId, hauler?.id ?? "");

	const outlets = useMemo(() => {
		const products = order?.products ?? [];
		const outlets = products.filter(p => p.outlet).map(p => p.outlet).filter(Boolean);
		return outlets.filter((outlet, index) => outlets.findIndex((o) => o?.id === outlet?.id) === index);
	}, [ order ]);

	return (
		<Box>
			<List>
				{outlets.map(outlet => (
					<OutletDetailsListItem
						key={outlet?.id ?? ""}
						outletId={outlet?.id ?? ""}
					/>
				))}
			</List>
		</Box>
	);
};
