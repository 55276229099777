import { DialogWithActivatorButton, Pagination, ViewContainer } from "../../../../components";
import { useAdminTasks } from "../../hooks/useAdminTasks";
import { Button, Divider, LinearProgress, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";
import { Email, Person, PhoneIphoneSharp, Search, TaskAlt } from "@mui/icons-material";
import { Box, Heading } from "grommet";
import { useFullScreen, useWindowDimensions } from "../../../../hooks";
import { useToggleForceClose } from "../../components";
import { useForm } from "react-hook-form";
import { FormContainer, SelectElement, TextFieldElement } from "react-hook-form-mui";
import { useLazyQuery } from "@apollo/client";
import { ContactType, FindUsersQueryVariables } from "../../../../graphql/__generated__/graphql";
import { FindUsers } from "../../../../graphql";
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo } from "react";
import { formatPhoneNumber } from "../../../../helpers";

type SearchType = "firstName" | "lastName" | "email" | "phone";

export const SearchUserDialog: React.FC = () => {
	const { forceClose, toggleForceClose } = useToggleForceClose();

	const formContext = useForm<{ searchType: SearchType, searchValue: string; cacheBreaker: number; }>({
		defaultValues: {
			searchType: "firstName",
			searchValue: "",
			cacheBreaker: 0
		}
	});

	const formValues = formContext.watch();

	useEffect(() => {
		formContext.reset({
			searchType: formValues.searchType,
			searchValue: "",
			cacheBreaker: Date.now()
		});
	}, [ formValues.searchType ]);

	const [ search, { data, loading, refetch } ] = useLazyQuery(FindUsers, {
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only"
	});

	function handleSearch() {
		let variables: FindUsersQueryVariables;
		const { searchType, searchValue } = formContext.getValues();

		switch(searchType) {
			case "firstName": {
				variables = {
					firstName: {
						contains: searchValue
					}
				};
				break;
			}
			case "lastName": {
				variables = {
					lastName: {
						contains: searchValue
					}
				};
				break;
			}
			case "email": {
				variables = {
					contacts: {
						type: {
							equals: ContactType.Email
						},
						value: {
							contains: searchValue
						}
					}
				};
				break;
			}
			case "phone": {
				variables = {
					contacts: {
						type: {
							equals: ContactType.Phone
						},
						value: {
							contains: searchValue
						}
					}
				};
				break;
			}
		}

		if(data?.FindUsers) {
			refetch({ ...variables });
		}
		else {
			search({
				variables: { ...variables }
			});
		}
	}

	const users = useMemo(() => {
		return data?.FindUsers || [];
	}, [ data ]);

	const dispatch = useAppDispatch();

	return (
		<DialogWithActivatorButton
			title="Search Users"
			forceClose={forceClose}
			activatorButton={(
				<Button
					size="small"
					color="secondary"
					variant="contained"
					startIcon={
						<Search />
					}
				>
					Search
				</Button>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="contained"
						onClick={toggleForceClose}
					>
						Cancel
					</Button>
					<LoadingButton
						size="small"
						color="primary"
						variant="contained"
						loading={loading}
						onClick={formContext.handleSubmit(handleSearch)}
					>
						Search
					</LoadingButton>
				</Box>
			)}
		>
			<Box gap="medium" flex>
				<FormContainer formContext={formContext}>
					<Box gap="medium">
						<SelectElement
							required
							name="searchType"
							label="Search By"
							options={[
								{ label: "First Name", id: "firstName" },
								{ label: "Last Name", id: "lastName" },
								{ label: "Email Address", id: "email" },
								{ label: "Phone Number", id: "phone" }
							]}
						/>
						<TextFieldElement
							name="searchValue"
							label="Search Value"
							required
							InputProps={{
								onKeyDown: (e) => {
									if(e.key === "Enter") {
										formContext.handleSubmit(handleSearch)();
									}
								}
							}}
						/>
					</Box>
				</FormContainer>
				<Divider />
				{users.length === 0 && (
					<Box height="small" justify="center" align="center">
						<Typography>
							No results found
						</Typography>
					</Box>
				)}
				{users.length > 0 && (
					<Pagination pageSize={4}>
						{users.map(user => (
							<ListItemButton
								divider
								onClick={() => {
									dispatch(push(`/admin/users/${user.id}`));
								}}
							>
								<ListItemIcon>
									<Person />
								</ListItemIcon>
								<ListItemText>
									<Typography fontWeight="bold">
										{user.firstName} {user.lastName}
									</Typography>
									<Box>
										<Box direction="row" gap="small" align="center">
											<Email sx={{ fontSize: "16px" }} />
											<Typography variant="body2">
												{user.primaryEmailAddress
													? user.primaryEmailAddress
													: "No primary email address"
												}
											</Typography>
										</Box>
										<Box direction="row" gap="small" align="center">
											<PhoneIphoneSharp sx={{ fontSize: "16px" }} />
											<Typography variant="body2">
												{user.primaryPhoneNumber
													? formatPhoneNumber(user.primaryPhoneNumber)
													: "No primary phone number"
												}
											</Typography>
										</Box>
									</Box>
								</ListItemText>
							</ListItemButton>
						))}
					</Pagination>
				)}
			</Box>
		</DialogWithActivatorButton>
	);
};

export const GlobalAdminDashboardView: React.FC = () => {
	const { loading, tasks } = useAdminTasks();
	const dispatch = useAppDispatch();

	const { sizeIndex } = useWindowDimensions();
	const fullScreen = useFullScreen();

	return (
		<ViewContainer>
			<Box gap="medium" height="100%">
				<Box>
					<Box direction="row" justify="between">
						<Box justify="center">
							<Heading level={3} margin="none">
								Pending Tasks
							</Heading>
						</Box>
						<Box justify="center">
							<SearchUserDialog

							/>
						</Box>
					</Box>
					{loading && (
						<LinearProgress />
					)}
				</Box>
				{tasks.length === 0 && (
					<Box align="center" justify="center">
						<Typography fontWeight="bold" fontSize="large">
							Nothing to do!
						</Typography>
					</Box>
				)}
				<Pagination
					pageSize={4}
				>
					{tasks.map(task => (
						<ListItemButton
							key={task.id}
							divider
							onClick={() => {
								dispatch(push(task.actionUrl));
							}}>
							<ListItemIcon>
								<TaskAlt />
							</ListItemIcon>
							<ListItemText>
								<Box style={{ maxWidth: "500px" }}>
									<Typography fontWeight="bold">
										{task.title}
									</Typography>
									{sizeIndex >= 3 && (
										<Typography variant="caption">
											{task.label}
										</Typography>
									)}
								</Box>
							</ListItemText>
							{(task.actionLabel && task.actionUrl && !fullScreen) && (
								<ListItemSecondaryAction>
									<Button
										size="small"
										onClick={() => {
											dispatch(push(task.actionUrl));
										}}
										color="primary"
										variant="contained"
									>
										{task.actionLabel}
									</Button>
								</ListItemSecondaryAction>
							)}
						</ListItemButton>
					))}
				</Pagination>
			</Box>
		</ViewContainer>
	);
};