import { Checkroom, Fastfood, Note, Restaurant, ShoppingBag, WaterDrop, Weekend } from "@mui/icons-material";
import { Text } from "grommet";
import { WasteScenario } from "../WasteScenario";
import { formatNumber } from "../../../helpers";
import { WasteReductionTipProps } from "../components";
import { Drink } from "../../../icons";

export const tips: WasteReductionTipProps[] = [
	{
		name: "durableGoods",
		icon: <Weekend />,
		title: "Furniture / Appliances",
		pledgeKey: "pledgeFurnitureReuse",
		reference: "https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/durable-goods-product-specific-data",
		secondaryText: (scenario: WasteScenario) => {
			const rate = .195;
			return `${formatNumber(rate * 100)}% of waste in the United States comes from durable goods like furniture and appliances.`;
		},
		pledgeDetailsText: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromFurnitureReusePounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> repair or donate my furniture and appliances and reduce my footprint by up to {formatNumber(amount, 2)} lb(s).
				</Text>
			);
		},
		pledgeAdditionalText: (scenario: WasteScenario) => {
			return (
				<Text>
					Buying used instead of new is also a great way to reduce waste.
				</Text>
			);
		},
		fallbackMessageIfZero: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromFurnitureReusePounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your furniture and appliance waste!";
			}

			return "";
		},
		amount: (scenario: WasteScenario) => {
			return scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromFurnitureReusePounds)
			);
		}
	},
	{
		name: "food",
		icon: <Restaurant />,
		title: "Food Waste",
		reference: "https://www.rts.com/resources/guides/food-waste-america/",
		pledgeKey: "pledgeCompostReduceFoodWaste",
		secondaryText: (scenario: WasteScenario) => {
			return "Americans throw away roughly 60 Million tons of food waste every year. That's almost 40% of the total U.S. food supply.";
		},
		pledgeDetailsText: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromFoodWastePounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> compost, donate or reduce my food waste and help reduce my footprint by up to {formatNumber(amount, 2)} pounds(s).
				</Text>
			);
		},
		fallbackMessageIfZero: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromFoodWastePounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your food waste!";
			}

			return "";
		},
		amount: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromFoodWastePounds)
			);

			return amount;
		}
	},
	{
		name: "clothing",
		title: "Clothing Waste",
		icon: <Checkroom />,
		reference: "https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/textiles-material-specific-data",
		pledgeKey: "pledgeReduceClothingWaste",
		amount: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromClothingReusePounds)
			);

			return amount;
		},
		secondaryText: (scenario: WasteScenario) => {
			return "Americans on average throw away 17 million tons of clothing per year, with only about 15% being recycled";
		},
		pledgeDetailsText: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromClothingReusePounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> repurpose, recycle, or donate unwanted clothing and avoid returns (if possible) to reduce my footprint by up to {formatNumber(amount, 2)} pounds(s).
				</Text>
			);
		},
		pledgeAdditionalText: (scenario: WasteScenario) => {
			return (
				<Text>
					Buying used and thrifting are also great ways to reduce waste.
				</Text>
			);
		},
		fallbackMessageIfZero: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromClothingReusePounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your clothing waste!";
			}

			return "";
		}
	},
	{
		name: "plastic-bottles",
		icon: <WaterDrop />,
		title: "Plastic Bottles",
		reference: "https://www.earthday.org/fact-sheet-single-use-plastics/",
		//reference: "https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/plastics-material-specific-data",
		pledgeKey: "pledgeEliminatePlasticBottles",
		amount: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPlasticBottlesPounds)
			);

			return amount;
		},
		secondaryText: (scenario: WasteScenario) => {
			return "While individually small, the average American uses 156 plastic water bottles per year, contributing to nearly 25 million tons of plastic bottle waste.";
		},
		pledgeDetailsText: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPlasticBottlesPounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> use a reusable water bottle and avoid single use plastic bottles to reduce my footprint by up to {formatNumber(amount, 2)} pounds(s).
				</Text>
			);
		},
		"fallbackMessageIfZero": (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPlasticBottlesPounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your plastic bottle waste!";
			}

			return "";
		}
	},
	{
		name: "plastic-bags",
		icon: <ShoppingBag />,
		title: "Plastic Bags",
		//https://www.biologicaldiversity.org/programs/population_and_sustainability/sustainability/plastic_bag_facts.html
		reference: "https://www.earthday.org/fact-sheet-single-use-plastics/",
		pledgeKey: "pledgeEliminatePlasticBags",
		amount: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPlasticBagsPounds)
			);

			return amount;
		},
		secondaryText: (scenario: WasteScenario) => {
			return "Americans use an average of 365 plastic bags per year";
		},
		pledgeDetailsText: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPlasticBagsPounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> use reusable bags and avoid single use plastic bags to reduce my footprint by up to {formatNumber(amount, 2)} pounds(s).
				</Text>
			);
		},
		fallbackMessageIfZero: (scenario: WasteScenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPlasticBagsPounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your plastic bag waste!";
			}

			return "";
		}
	},
	{
		name: "takeout",
		title: "Takeout and Packaging Waste",
		icon: <Fastfood />,
		reference: "https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/containers-and-packaging-product-specific-data",
		pledgeKey: "pledgeReduceTakeoutWaste",
		amount: (scenario) => {
			return scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromTakeoutPounds)
			);
		},
		secondaryText: (scenario) => {
			return "Roughly 28.1% of total waste in the US comes from containers and packaging amounting to 82.2 million tons of generation";
		},
		pledgeDetailsText: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromTakeoutPounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> choose reusable packaging (if available) and skip the request for single use utensils to reduce my footprint by up to {formatNumber(amount, 2)} pounds(s).
				</Text>
			);
		},
		fallbackMessageIfZero: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromTakeoutPounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your takeout waste!";
			}

			return "";
		}
	},
	{
		name: "paper-paperboard",
		title: "Paper and Cardboard",
		icon: <Note />,
		reference: "https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/paper-and-paperboard-material-specific-data",
		pledgeKey: "pledgeReducePaperCardboardWaste",
		amount: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPaperCardboardPounds)
			);

			return amount;
		},
		secondaryText: (scenario) => {
			return "Over 67 million tons of paper and paperboard are generated in the US annually, with 68.3% being recycled (the highest among MSW)";
		},
		pledgeDetailsText: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPaperCardboardPounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> recycle paper and cardboard and reduce my footprint by up to {formatNumber(amount, 2)} pounds(s).
				</Text>
			);
		},
		fallbackMessageIfZero: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromPaperCardboardPounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your paper and cardboard waste!";
			}

			return "";
		}
	},
	{
		name: "glass-aluminum",
		title: "Glass and Aluminum",
		icon: <Drink />,
		reference: "https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/glass-material-specific-data",
		pledgeKey: "pledgeRecycleGlassAluminum",
		amount: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromGlassAluminumPounds)
			);

			return amount;
		},
		secondaryText: (scenario) => {
			return "Over 16 tons of glass and aluminum waste are generated in the US annually.";
		},
		pledgeDetailsText: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromGlassAluminumPounds)
			);

			return (
				<Text>
					<Text weight="bold">I pledge to ...</Text> recycle glass and aluminum to reduce my footprint by up to {formatNumber(amount, 2)} pounds(s).
				</Text>
			);
		},
		fallbackMessageIfZero: (scenario) => {
			const amount = scenario.toHousehold(
				scenario.toPeriod(scenario.dailyWasteReductionFromGlassAluminumPounds)
			);

			if(amount <= 0) {
				return "Looks like you're already doing a great job with limiting your glass and aluminum waste!";
			}

			return "";
		}
	}
];