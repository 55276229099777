import { useQuery } from "@apollo/client";
import { FindInstanceOrders } from "../../../graphql";
import { OrderStatus } from "../../../graphql/__generated__/graphql";

export function useCompletedOrders(
	instanceId: string,
	completedAtAfter?: Date,
	completedAtBefore?: Date
) {
	const { data, loading, error } = useQuery(FindInstanceOrders, {
		variables: {
			instanceId,
			completedAt: (completedAtAfter || completedAtBefore) ? {
				isAfter: completedAtAfter,
				isBefore: completedAtBefore
			} : undefined,
			submittedAt: undefined,
			scheduledAt: undefined,
			status: { equals: OrderStatus.Completed }
		},
		skip: !instanceId
	});

	return {
		loading,
		orders: data?.FindInstanceOrders ?? [],
		error
	};
}

export function useActiveOrders(
	instanceId: string,
	submittedAtAfter?: Date,
	submittedAtBefore?: Date
) {
	const { data, loading, error } = useQuery(FindInstanceOrders, {
		variables: {
			instanceId,
			completedAt: {
				isNull: true
			},
			cancelledAt: {
				isNull: true
			},
			scheduledAt: undefined,
			submittedAt: (submittedAtAfter || submittedAtBefore) ? {
				isAfter: submittedAtAfter,
				isBefore: submittedAtBefore
			} : undefined
		},
		skip: !instanceId
	});

	return {
		loading,
		orders: data?.FindInstanceOrders ?? [],
		error
	};
}