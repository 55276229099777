import { Box, Heading } from "grommet";
import { useCart } from "../../cart/hooks/useCart";
import { Button, List, Typography } from "@mui/material";
import { useAppDispatch } from "../../../store";
import { push } from "redux-first-history";
import { CartProductListItem } from "../components/cart/CartProductListItem";
import { useLoginGuard } from "../../../auth";
import { useMemo } from "react";
import { ViewContainer } from "../../../components";

export const PurchaseCartView: React.FC = () => {
	useLoginGuard();
	const dispatch = useAppDispatch();
	const { products, quantities } = useCart();

	const subtotal = useMemo(() => {
		return products.reduce((accum, product) => {
			return accum + (product.listing?.price ?? 0);
		}, 0);
	}, [ products ]);

	const tax = useMemo(() => {
		return subtotal * 0.089;
	}, [ subtotal ]);

	const deliveryFee = useMemo(() => {
		return products.reduce((accum, product) => {
			return accum + (product.listing?.deliveryFee ?? 0);
		}, 0);
	}, [ products ]);

	const total = useMemo(() => {
		return subtotal + tax + deliveryFee;
	}, [ subtotal, tax, deliveryFee ]);

	return (
		<ViewContainer>
			<Box style={{ display: "block" }} height="100%">
				<Box direction="row" gap="medium">
					<Box flex>
						<Box flex>
							<Box align="center">
								<Heading level={2} margin="small" color="brand">Your Cart</Heading>
							</Box>
							{products.length === 0 && (
								<Box height="medium" align="center" justify="center" gap="large">
									<Typography fontWeight="bold" variant="h6">
										Nothing in your cart yet
									</Typography>
									<Button
										variant="contained"
										color="primary"
										onClick={() => dispatch(push("/shop"))}
									>
										Continue Shopping
									</Button>
								</Box>
							)}
							<List>
								{products.map(product => (
									<CartProductListItem
										key={product.id}
										productId={product.id}
										title={product.listing?.title ?? ""}
										price={product.listing?.price ?? 0}
										thumbnail={product.media[ 0 ]?.contentUrl ?? ""}
										quantity={quantities[ product.id ] ?? 1}
										quantityAvailable={product.inventory.quantity}
									/>
								))}
							</List>
							<Box direction="row" justify="between" margin={{ horizontal: "small", vertical: "medium" }}>
								<Box>
									<Button
										variant="outlined"
										color="primary"
										onClick={() => dispatch(push("/shop"))}
									>
										Go Back
									</Button>
								</Box>
								<Box>
									<Button
										variant="contained"
										color="primary"
										onClick={() => dispatch(push("/shop"))}
									>
										Continue
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};