import { Button } from "@mui/material";
import { Box, Grid, Text } from "grommet";
import { Sizes, useFullScreen, useWindowDimensions } from "../../../hooks";
import { ProductPrice, ProductDeliveryOptions } from "./Shop";
import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import { FetchProductWithListing } from "../../../graphql";
import { useEffect, useMemo } from "react";
import { SplashScreen, ViewContainer } from "../../../components";
import { ProductCondition } from "../../../graphql/__generated__/graphql";
import { AddToCartButton, ProductDetailsList, ProductDetailsMediaComponent } from "../components";
import "../components/ProductCard.css";

export const ProductDetailsView: React.FC = (props) => {
	const fullScreen = useFullScreen();
	const { sizeIndex } = useWindowDimensions();

	const { productId } = useParams();

	const [
		fetchProductQuery,
		{ data: productData, loading: isProductLoading, error: isProductError }
	] = useLazyQuery(FetchProductWithListing);

	useEffect(() => {
		if(productId) {
			fetchProductQuery({
				variables: {
					productId
				}
			}).catch(err => {
				console.error("Failed to load product details", err);
			});
		}
	}, [ productId, fetchProductQuery ]);

	const product = useMemo(() => {
		if(productData) {
			return productData.FetchProduct;
		}

		return null;
	}, [ productData ]);

	const isSplashScreenVisible = useMemo(() => {
		return isProductLoading;
	}, [ isProductLoading ]);

	return (
		<SplashScreen visible={isSplashScreenVisible}>
			<ViewContainer>
				<Box gap="large" style={{ display: "block" }} overflow={{ vertical: "scroll" }}>
					<Box gap="medium" style={{ display: "block" }} overflow={{ vertical: "scroll" }}>
						<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="medium">
							<ProductDetailsMediaComponent
								media={product?.media ?? []}
							/>
							<Box gap="small">
								<Box gap="small">
									{/* {!!product?.seller && (
										<Box direction="row" justify="end" flex>
											<ListItem disableGutters disablePadding>
												<ListItemAvatar>
													<Avatar sx={{ background: BRAND }}>
														{product?.seller?.displayName?.charAt(0).toUpperCase()}
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary="For Sale By"
													secondary={product?.seller?.displayName} />
											</ListItem>
										</Box>
									)} */}
									<Box direction="row" justify="between" gap="medium" flex>
										<Box justify="start" fill="horizontal">
											<Text weight="bold" size="large" className="text-max-2-lines" style={{ width: "80%" }}>
												{product?.listing?.title}
											</Text>
										</Box>
										<Box justify="start" align="end" flex style={{ maxWidth: "100px" }}>
											<ProductPrice
												price={product?.listing?.price ?? 0}
												large
											/>
										</Box>
									</Box>
									<Box>
										<Text className="text-max-4-lines" style={{ width: "100%" }}>
											{product?.listing?.description}
										</Text>
									</Box>
								</Box>
								<Box>
									<ProductDetailsList
										type={product?.type?.name ?? ""}
										size={product?.size?.name ?? ""}
										category={product?.type?.category?.name ?? ""}
										condition={product?.condition ?? ProductCondition.Unknown}
									/>
								</Box>
								{sizeIndex > Sizes.LARGE && (
									<ProductDeliveryOptions
										allowsPickup={product?.listing?.allowPickup ?? false}
										allowsDelivery={product?.listing?.allowDelivery ?? false}
										deliveryFee={product?.listing?.deliveryFee ?? 0} />
								)}
								{!fullScreen && (
									<Box justify="end" style={{ minHeight: "60px" }}>
										<Box direction="row" justify="end" align="end" gap="small" margin={{ horizontal: "small" }} flex>
											<Button
												color="primary"
												variant="outlined"
											>
												Buy Now
											</Button>
											<AddToCartButton
												productId={product?.id ?? ""}
											/>
										</Box>
									</Box>
								)}
							</Box>
						</Grid>
					</Box>
					{sizeIndex <= Sizes.LARGE && (
						<ProductDeliveryOptions
							allowsPickup
							allowsDelivery
							deliveryFee={product?.listing?.deliveryFee ?? 0} />
					)}
					{fullScreen && (
						<Box direction="row" align="center" justify="between" gap="medium" margin="small" style={{ minHeight: "60px" }}>
							<Box>
								<Button
									color="primary"
									variant="outlined"
								>
									Buy Now
								</Button>
							</Box>
							<Box>
								<AddToCartButton
									productId={product?.id ?? ""} />
							</Box>
						</Box>
					)}
				</Box>
			</ViewContainer>
		</SplashScreen>
	);
};
