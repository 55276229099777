import { Box } from "grommet";
import React, { useMemo } from "react";
import { ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { OrderStatus, Order_HeaderFragmentFragment, Order_PickupFragmentFragment, Order_SourceFragmentFragment } from "../../../../../graphql/__generated__/graphql";
import { useOrderStatusChip } from "../../../common";
import moment from "moment-timezone";
import { toProperCase } from "../../../../../helpers";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";

export const DonationCenterOrderListItem: React.FC<{
	order: Order_HeaderFragmentFragment & Order_PickupFragmentFragment & Order_SourceFragmentFragment;
}> = ({ order }) => {
	const dispatch = useAppDispatch();
	const chip = useOrderStatusChip(order.id);

	const title = useMemo(() => {
		switch(order.status) {
			case OrderStatus.Cancelled: {
				return `Cancelled on ${moment.tz(order.cancelledAt, "UTC").format("MM/DD/YYYY")}`;
			}
			case OrderStatus.Completed: {
				return `Completed on ${moment.tz(order.completedAt, "UTC").format("MM/DD/YYYY")}`;
			}
			case OrderStatus.Pending: {
				return "Currently Pending";
			}
			case OrderStatus.InProgress: {
				return "In Progress";
			}
			case OrderStatus.Submitted: {
				if(!order.schedulePending && !order.scheduled) {
					return "Processing Order";
				}

				if(order.schedulePending) {
					return "Ready to Schedule";
				}

				if(order.scheduled) {
					return `Scheduled for ${moment(order.pickup?.scheduledDate).format("MM/DD/YYYY")}`;
				}

				return "Submitted";
			}
		}
	}, [ order ]);

	return (
		<ListItemButton
			divider
			onClick={() => {
				dispatch(push(`/admin/orders/${order.id}`));
			}}
		>
			<ListItemText>
				<Box>
					<Typography fontWeight="bold">
						{toProperCase(order.user?.fullName)}
					</Typography>
					<Typography variant="caption">
						Click to view more details
					</Typography>
				</Box>
			</ListItemText>
			<ListItemSecondaryAction>
				{chip}
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
