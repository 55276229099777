import React from "react";
import { Route, Routes } from "react-router";
import { AuthRouter } from "./features/auth";
import { ScheduleRouter } from "./features/schedule";
import { ShopRouter } from "./features/shop";
import { SellView } from "./features/dashboard/views/Sell";
import { HaulerRouter } from "./features/hauler/router";
import { AdminRouter } from "./features/admin/router";
import { UserProfileRouter } from "./features/user/router";

//There's a circular ref issue here so it can't be imported from barrel
import { DashboardBaseRouter } from "./features/dashboard/router/Dashboard";
import { RootRedirectView } from "./views";
import { ReferController } from "./features/refer";
import { useFeature } from "./features/instance/hooks/useFeature";
import { InstanceFeature, InstanceType } from "./graphql/__generated__/graphql";
import { selectInstancePublic, useAppSelector } from "./store";
import { useMemo } from "react";
import { CalculatorRouter } from "./features/calculator";
import { CheckoutRouter } from "./features/checkout/router";
import { TetrisView } from "./features/onboarding/views/Game";
import { OnboardingRouter } from "./features/onboarding";

export const CoxEnterprisesRouterOverride: React.FC = () => {
	return (
		<Routes>
			<Route path="/*" element={<MainRouter />} />
		</Routes>
	);
};

export const MainRouter: React.FC = () => {
	const instance = useAppSelector(selectInstancePublic);

	const isStoreEnabled = useFeature(InstanceFeature.SalesEnabled);
	const isP2PSalesEnabled = useFeature(InstanceFeature.SalesP2PEnabled);
	const isHaulerEnabled = useMemo(() => instance?.type === InstanceType.Hauler || window.location.hostname.includes("localhost"), [ instance ]);

	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);
	const isRemovalEnabled = useFeature(InstanceFeature.RemovalEnabled);
	const isDonationEnabled = useFeature(InstanceFeature.DonationEnabled);

	const isCustomerEnabled = useMemo(() => {
		return isSalesEnabled || isRemovalEnabled || isDonationEnabled;
	}, [ isSalesEnabled, isRemovalEnabled, isDonationEnabled ]);

	const isOnboardingInstance = useMemo(() => {
		return instance?.type === InstanceType.Onboarding;
	}, [ instance ]);

	return (
		<Routes>
			{isOnboardingInstance && (
				<Route path="game" element={<TetrisView />} />
			)}
			{(isOnboardingInstance || window.location.hostname.includes("localhost")) && <Route path="onboarding/*" element={<OnboardingRouter />} />}
			<Route path="" element={<RootRedirectView />} />
			<Route path="/*" element={<AuthRouter />} />
			<Route path="/profile/*" element={<UserProfileRouter />} />
			<Route path="/admin/*" element={<AdminRouter />} />
			<Route path="/schedule/*" element={<ScheduleRouter />} />
			<Route path="/checkout/*" element={<CheckoutRouter />} />
			<Route path="/dashboard/*" element={<DashboardBaseRouter />} />
			<Route path="/refer/*" element={<ReferController />} />
			<Route path="/calculator/*" element={<CalculatorRouter />} />
			{isP2PSalesEnabled && <Route path="/sell" element={<SellView />} />}
			{isStoreEnabled && <Route path="/store/*" element={<ShopRouter />} />}
			{isHaulerEnabled && <Route path="/hauler/*" element={<HaulerRouter />} />}
			{isCustomerEnabled && <Route path="/dashboard/*" element={<DashboardBaseRouter />} />}
		</Routes>
	);
};