import React, { useMemo } from "react";
import { IconButton, List } from "@mui/material";
import { BasicListItem } from "../../../../components";
import { CopyAll } from "@mui/icons-material";
import { useClipboard } from "../../../../hooks/useClipboard";
import { formatCurrency, parseDateFromUTC } from "../../../../helpers";
import { useTimezone } from "../../../../hooks/useTimezone";
import { UserDashboardSchedulePickupButton } from "..";
import { CancelOrderButton, CancelOrderConfirmationDialog } from "./CancelOrderDialog";
import moment from "moment";
import { useUnifiedOrder } from "../../../../graphql";

interface OrderDetailsProps {
	orderId: string;
}

export const OrderDetails: React.FC<OrderDetailsProps> = (props) => {
	const { copy } = useClipboard();

	const timezone = useTimezone();
	const { order, loading } = useUnifiedOrder(props.orderId, [ "user" ]);

	const skipCancellationFeeDialog = useMemo(() => {
		if(!order?.submittedAt || !order.scheduledAt) {
			return true;
		}

		if(order?.acceptedEstimate?.totalAmount === 0) {
			return true;
		}

		return false;
	}, [ order ]);

	const canScheduleOrReschedule = useMemo(() => {
		if(order?.completedAt) {
			return false;
		}

		if(order?.cancelledAt) {
			return false;
		}

		if(order?.startedAt) {
			return false;
		}

		return true;
	}, [ order ]);

	return (
		<List>
			<BasicListItem
				label="Order Number"
				value={order?.number}
				loading={loading}
				action={(
					<IconButton
						color="primary"
						onClick={() => copy(order?.number || "")}
					>
						<CopyAll />
					</IconButton>
				)} />
			<BasicListItem
				label="Submitted Date"
				loading={loading}
				value={order?.submittedAt && parseDateFromUTC(order.submittedAt, timezone)}
				action={order?.cancellationDetails && (
					<CancelOrderButton
						orderId={props.orderId}
						isCancellable={order?.cancellationDetails?.isCancellable}
						cancellationDialog={skipCancellationFeeDialog ? CancelOrderConfirmationDialog : undefined}
					/>
				)}
			/>
			{order?.schedulePending && (
				<BasicListItem
					label="Scheduled Date"
					loading={loading}
					value={"Not Scheduled"}
					action={
						<UserDashboardSchedulePickupButton
							orderId={props.orderId}
							isScheduled={order?.scheduled}
							isSchedulePending={order?.schedulePending || false}
							canScheduleOrReschedule={canScheduleOrReschedule}
						/>
					}
				/>
			)}
			{order?.scheduled && (
				<BasicListItem
					label="Scheduled Date"
					loading={loading}
					value={order?.pickup?.scheduledDate ? moment(order.pickup.scheduledDate).format("MM/DD/YYYY") : "Not Scheduled"}
					action={
						<UserDashboardSchedulePickupButton
							orderId={props.orderId}
							isScheduled={order?.scheduled}
							isSchedulePending={order?.schedulePending || false}
							canScheduleOrReschedule={canScheduleOrReschedule}
						/>
					}
				/>
			)}
			{!!(order?.acceptedEstimate?.totalAmount) && (
				<BasicListItem
					label="Pickup Quote"
					loading={loading}
					value={formatCurrency(order.acceptedEstimate.totalAmount)}
				/>
			)}
		</List>
	);
};
