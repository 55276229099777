import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ShirtAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m872.39 241.6c-4.5469-8.8594-12.703-15.234-22.406-17.438l-116.77-26.578c-9.2344-2.0625-18.938-0.1875-26.672 5.2969-7.7812 5.4844-12.891 14.109-13.969 23.578-6.6094 56.484-32.062 79.547-88.312 79.547-55.781 0-81.234-23.062-87.844-79.547-1.125-9.4688-6.1875-18.047-13.969-23.578-7.7344-5.4844-17.438-7.3594-26.672-5.2969l-116.81 26.578c-9.7031 2.2031-17.859 8.5781-22.406 17.391l-139.5 272.48c-6.0469 11.812-4.6406 26.016 3.5625 36.234 41.062 50.953 94.594 74.391 159.19 69.984l-9.6562 313.18c-0.46875 14.766 8.4844 27.891 22.266 32.672 62.672 21.609 140.86 22.312 217.13 22.312h22.641c78.422-0.14062 159.61 0.046876 224.39-22.312 13.734-4.7344 22.688-17.859 22.266-32.672l-9.6562-313.18c64.641 4.4531 118.17-19.078 159.19-70.031 8.2031-10.219 9.6094-24.375 3.5625-36.188zm-23.203 342.56c-0.46875-0.046875-0.9375 0.046875-1.4062 0.046875l-5.9531-157.31c-0.375-9.9375-8.5312-17.578-18.656-17.297-9.9375 0.375-17.672 8.7188-17.297 18.656l7.1719 190.26 9.6562 314.21c-58.828 19.688-135.74 19.5-210.56 19.641h-14.906c-75-0.14063-152.21 0.046874-211.03-19.641l9.6562-314.21 7.1719-190.26c0.375-9.9375-7.3594-18.281-17.297-18.656-10.031-0.23437-18.281 7.3594-18.656 17.297l-5.9531 157.31c-0.46875 0-0.9375-0.09375-1.4062-0.046875-53.391 4.3594-95.953-13.641-129.94-55.078l138.28-270.05 112.88-25.688c9.4219 73.125 49.781 108.7 123.74 108.7 73.547 0 113.86-35.578 123.28-108.7l112.88 25.688 138.28 270.05c-33.984 41.438-76.547 59.484-129.94 55.078z"/>
  <path d="m760.03 449.48h-104.2c-9.9375 0-18 8.0625-18 18v38.062c0 38.672 31.453 70.078 70.078 70.078 38.672 0 70.078-31.453 70.078-70.078v-38.062c0.046875-9.9375-8.0156-18-17.953-18zm-18 56.016c0 18.797-15.281 34.078-34.078 34.078s-34.078-15.281-34.078-34.078v-20.062h68.203z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};