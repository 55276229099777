import { CallUsButton, DialogWithActivatorButton, TextUsButton } from "../../../../components";
import { Typography } from "@mui/material";
import { Box } from "grommet";

export const AddProductBlockedDialog: React.FC<{
	activatorButton: React.ReactNode;
}> = ({ activatorButton }) => {
	return (
		<DialogWithActivatorButton
			title="Add Item"
			activatorButton={activatorButton}
		>
			<Box gap="medium">
				<Typography fontSize="large">
					Need to add something to your order? Get in touch with us to make changes to your order.
				</Typography>
				<Box gap="small" margin={{ horizontal: "large" }}>
					<CallUsButton />
					<TextUsButton />
				</Box>
			</Box>
		</DialogWithActivatorButton>
	);
};
