import { ProductCondition } from "../../../graphql/__generated__/graphql";

export const labeledConditions = Object.values(ProductCondition).map((condition) => {
	switch(condition) {
		case ProductCondition.New: {
			return { id: condition, label: "New", valueAdjustment: 0.65, index: 0 };
		}
		case ProductCondition.Good: {
			return { id: condition, label: "Good", valueAdjustment: 0.65, index: 1 };
		}
		case ProductCondition.Acceptable: {
			return { id: condition, label: "Acceptable", valueAdjustment: 0.45, index: 2 };
		}
		case ProductCondition.Unknown: {
			return { id: condition, label: "Unknown", valueAdjustment: 0.0, index: 3 };
		}
		case ProductCondition.Worn: {
			return { id: condition, label: "Worn", valueAdjustment: 0.30, index: 4 };
		}
	}
});

export function resolveLabeledCondition(condition: ProductCondition): LabeledCondition {
	return labeledConditions.find(c => c.id === condition) || labeledConditions[ 0 ];
}

export type LabeledCondition = typeof labeledConditions[ number ];