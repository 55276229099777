import { gql } from "../../../__generated__";

export const UpdateProductType = gql(/* GraphQL */ `
	mutation UpdateProductType(
		$productId: String!
		$typeId: String!
		$sizeId: String
	) {
		UpdateProductType(
			productId: $productId
			typeId: $typeId
			sizeId: $sizeId
		) {
			id
		}
	}
`);