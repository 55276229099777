import React, { Fragment, ReactNode, createRef, useMemo, useState } from "react";
import { Box, Spinner } from "grommet";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { AssignProductOutlet, FetchAdminOrder, FetchAdminProduct, FetchExpandedOrder, FetchProductV1, FindAcceptingOutlets, FindOutletsByMarket } from "../../../../../graphql";
import { DialogWithClose } from "../../../../../components";
import { AutocompleteElement, FormContainer, useForm } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { ProductDisposition } from "../../../../../graphql/__generated__/graphql";

export const UpdateProductOutletDialog: React.FC<{
	productId: string;
	outlet: { id: string; name: string; disposition: ProductDisposition; } | null;
	fallbackOutlet: { id: string; name: string; disposition: ProductDisposition; } | null;
	activationButton: ReactNode;
}> = ({ productId, outlet, fallbackOutlet, activationButton: button }) => {
	const snack = useSnackbar();
	const [ open, setOpen ] = useState(false);

	const buttonRef = createRef<HTMLButtonElement>();

	const {
		data, loading
	} = useQuery(FindAcceptingOutlets, {
		variables: { productId }
	});

	const [
		assignOutlet, { loading: assignOutletLoading }
	] = useMutation(AssignProductOutlet, {
		refetchQueries: [
			FetchProductV1,
			FetchAdminProduct,
			FetchAdminOrder,
			FetchExpandedOrder
		]
	});

	const outlets = useMemo(() => {
		return (data?.FindAcceptingOutlets ?? []).map(outlet => ({
			id: outlet.id,
			name: outlet.name,
			disposition: outlet.disposition,
			label: outlet.name
		})).sort((a, b) => a.disposition.charCodeAt(0) - b.disposition.charCodeAt(0));
	}, [ data ]);

	const formContext = useForm({
		defaultValues: {
			outlet,
			fallbackOutlet
		}
	});

	function handleSave(): void {
		const outletId = formContext.getValues("outlet")?.id;
		if(!outletId) return;

		assignOutlet({
			variables: {
				outletId,
				productId,
				fallbackOutletId: formContext.getValues("fallbackOutlet")?.id
			}
		}).then(() => {
			setOpen(false);
		}).catch(err => {
			console.error(err);
			snack.enqueueSnackbar("Error assigning outlet", { variant: "error" });
		});
	}

	const isFallbackOutletRequired = useMemo(() => {
		return [
			ProductDisposition.Donation,
			ProductDisposition.Reuse,
			ProductDisposition.Resale
		].includes(formContext.watch("outlet")?.disposition as ProductDisposition);
	}, [ formContext.watch("outlet") ]);

	return (
		<Fragment>
			{React.cloneElement(button as React.ReactElement, {
				ref: buttonRef,
				onClick: (event: React.MouseEvent) => {
					setOpen(true);
				}
			})}
			{open && (
				<DialogWithClose
					title="Update Product"
					onClose={() => {
						setOpen(false);
					}}
					content={(
						<FormContainer formContext={formContext}>
							<Box gap="medium">
								<AutocompleteElement
									label="Assigned Outlet"
									name="outlet"
									required
									options={outlets}
									autocompleteProps={{
										groupBy: (option) => {
											return option.disposition;
										},
										getOptionLabel: (option) => {
											return option.label ?? option.name;
										},
									}}
									loading={loading}
									loadingIndicator={(
										<Spinner />
									)}
								/>
								{isFallbackOutletRequired && (
									<AutocompleteElement
										label="Fallback Outlet"
										name="fallbackOutlet"
										required
										options={outlets.filter(o => o.disposition === ProductDisposition.Disposal)}
										autocompleteProps={{
											getOptionLabel: (option) => {
												return option.label ?? option.name;
											},
										}}
										loading={loading}
										loadingIndicator={(
											<Spinner />
										)}
									/>
								)}
							</Box>
						</FormContainer>
					)}
					actions={(
						<Box direction="row" justify="between">
							<Button
								color="error"
								variant="outlined"
								onClick={() => {
									setOpen(false);
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								color="primary"
								variant="contained"
								loading={assignOutletLoading}
								onClick={formContext.handleSubmit(handleSave)}
							>
								Save
							</LoadingButton>
						</Box>
					)} />
			)}
		</Fragment>
	);
};

interface OutletLike {
	id: string;
	name: string;
	disposition: ProductDisposition;
	label: string;
}

export const OutletElement: React.FC<{
	marketId: string;
	isFallback?: boolean;
	value?: OutletLike;
	onChange?: (event: React.SyntheticEvent, value: OutletLike | null) => void;
}> = ({ marketId, isFallback, value, onChange }) => {

	const {
		data, loading
	} = useQuery(FindOutletsByMarket, {
		skip: !marketId,
		variables: { marketId }
	});

	const outlets = useMemo(() => {
		return (data?.FindOutletsByMarket ?? []).map(outlet => ({
			id: outlet.id,
			name: outlet.name,
			disposition: outlet.disposition,
			label: outlet.name
		})).filter((outlet) => isFallback ? outlet.disposition === ProductDisposition.Disposal : true).sort((a, b) => a.disposition.charCodeAt(0) - b.disposition.charCodeAt(0));
	}, [ data ]);

	return (
		<AutocompleteElement
			label={isFallback ? "Fallback Outlet" : "Outlet"}
			name={isFallback ? "fallbackOutlet" : "outlet"}
			required
			options={outlets}
			autocompleteProps={{
				onChange,
				value: onChange ? value : undefined,
				groupBy: (option) => {
					return option.disposition;
				},
				getOptionLabel: (option) => {
					return option.label ?? option.name;
				},
			}}
			loading={loading}
			loadingIndicator={(
				<Spinner />
			)}
		/>
	);
};