import React from "react";
import { Box, Spinner } from "grommet";
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

export const EligibleHaulerListItem: React.FC<{
	selected: boolean;
	haulerName: string;
	onToggle(): void;
	loading: boolean;
	requests: { createdAt: Date; }[];
}> = ({ selected, haulerName, onToggle, loading, requests }) => {
	return (
		<ListItemButton selected={selected} onClick={onToggle} disabled={requests.length > 0}>
			<ListItemIcon>
				<Checkbox
					edge="start"
					checked={selected}
					tabIndex={-1}
					disableRipple />
			</ListItemIcon>
			<ListItemText>
				<Typography fontWeight="bold">
					{haulerName}
				</Typography>
			</ListItemText>
			<Box>
				{loading && (
					<Spinner />
				)}
				{requests.length > 0 && (
					<Typography color="error">
						Requested on {new Date(requests[ 0 ].createdAt).toLocaleDateString()}
					</Typography>
				)}
			</Box>
		</ListItemButton>
	);
};
