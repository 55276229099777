import React from "react";
import { Checkbox } from "@mui/material";
import { useSavedOrders } from "../../hooks/useSavedOrders";

export const SavedOrderCheckboxColumn: React.FC<{
	id: string;
}> = ({ id }) => {
	const { savedOrders, toggle } = useSavedOrders();

	function handleClick(event: React.MouseEvent<HTMLElement>) {
		event.stopPropagation();
		toggle({ id });
	}

	return (
		<Checkbox
			checked={savedOrders.some(o => o.id === id)}
			onClick={handleClick} />
	);
};
