import { gql } from "../__generated__";

export const FetchCartProducts = gql(/* GraphQL */ `
	query FetchUserCartProducts($userId: String!) {
		FetchUserCartProducts(userId: $userId) {
			id
			type {
				id
				name
				category {
					id
					name
				}
			}
			size {
				id
				name
			}
			listing {
				id
				title
				description
				price
				priceCompare
				deliveryFee
				allowPickup
				allowDelivery
			}
			inventory {
				quantity
			}
			media {
				id
				name
				contentUrl
			}
		}
	}
`);

export const AddProductToCart = gql(/* GraphQL */ `
	mutation AddProductToCart($userId: String!, $productId: String!) {
		AddProductToCart(userId: $userId, productId: $productId) {
			id
		}
	}
`);

export const RemoveProductFromCart = gql(/* GraphQL */ `
	mutation RemoveProductFromCart($userId: String!, $productId: String!) {
		RemoveProductFromCart(userId: $userId, productId: $productId) {
			id
		}
	}
`);