import { Box, Carousel, Image } from "grommet";
import { DialogWithActivatorButton } from "../../../../components";
import React, { useState } from "react";
import { ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { Typography } from "@mui/material";

export const ProductMediaDialog: React.FC<{
	media: { contentUrl: string; name: string; context: ProductMediaContext; }[];
	activatorButton: React.ReactNode;
	showContextInActions?: boolean;
}> = (props) => {
	const [ { name, context }, setState ] = useState({
		name: props.media?.[ 0 ]?.name || "",
		context: props.media?.[ 0 ]?.context || ProductMediaContext.Default
	});

	return (
		<DialogWithActivatorButton
			title="Images"
			activatorButton={props.activatorButton}
			actions={props.showContextInActions && (
				<Typography>
					{name} ({context})
				</Typography>
			)}
		>
			<Box flex>
				<Carousel
					fill
					controls="arrows"
					onChild={(index) => {
						setState({
							name: props.media[ index ].name,
							context: props.media[ index ].context
						});
					}}
				>
					{props.media.map((media) => (
						<Box align="center" justify="center" key={media.name}>
							<Box width="medium">
								<Image
									src={media.contentUrl}
									fit="contain"
								/>
							</Box>
						</Box>
					))}
				</Carousel>
			</Box>
		</DialogWithActivatorButton>
	);
};
