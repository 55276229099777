import { SvgIcon, SvgIconProps } from "@mui/material";

export const LampFloor: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" >
				<defs>
					<filter id="d" x="0%" y="0%" width="100%" height="100%">
						<feColorMatrix in="SourceGraphic" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
					</filter>
					<mask id="b">
						<g filter="url(#d)">
							<rect width="1200" height="1200" fill-opacity=".9"/>
						</g>
					</mask>
					<clipPath id="c">
						<rect width="1200" height="1200"/>
					</clipPath>
					<g id="a" clip-path="url(#c)">
						<path d="m760 571.52v-171.52h108l-96.801-360h-302.4l-96.801 360h228v720h-80v40h200v-40h-80v-720h80v171.56l-39.977 23.074-0.023438 69.234 59.977 34.594 60-34.633 0.023438-69.23zm-261.14-491.52h242.28l75 280h-392.28zm261.14 537.14-0.007812 23.098-20 11.559-19.992-11.543 0.007812-23.102 20-11.555z" fill-rule="evenodd"/>
					</g>
				</defs>
				<use mask="url(#b)"/>
			</svg>
		</SvgIcon>
	);
};