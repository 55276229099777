import { gql } from "@apollo/client";

export const Size_DefaultFragment = gql`
	fragment Size_DefaultFragment on ItemSize {
		id
		name
		iconUrl
		index
		tags
	}
`;