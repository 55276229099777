import { SvgIcon, SvgIconProps } from "@mui/material";

	export const StandingDesk: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g fill-rule="evenodd">
  <path d="m75 300c0-13.809 11.191-25 25-25h1e3c13.805 0 25 11.191 25 25v100c0 13.809-11.195 25-25 25h-1e3c-13.809 0-25-11.191-25-25zm50 25v50h950v-50z"/>
  <path d="m628.73 386.86c4.5547-7.3672 12.602-11.855 21.266-11.855h350c8.6641 0 16.711 4.4883 21.266 11.855 4.5547 7.3711 4.9688 16.574 1.0938 24.324l-50 100c-4.2344 8.4688-12.891 13.82-22.359 13.82h-250c-9.4688 0-18.125-5.3516-22.359-13.82l-50-100c-3.875-7.75-3.4609-16.953 1.0938-24.324zm61.715 38.145 25 50h219.1l25-50z"/>
  <path d="m125 400c0-13.809 11.191-25 25-25h100c13.809 0 25 11.191 25 25v500c0 13.805-11.191 25-25 25h-100c-13.809 0-25-11.195-25-25zm50 25v450h50v-450z"/>
  <path d="m1025 875v-475h50v500c0 13.805-11.195 25-25 25h-100c-13.805 0-25-11.195-25-25v-400h50v375z"/>
  <path d="m75 900c0-13.805 11.191-25 25-25h200c13.809 0 25 11.195 25 25s-11.191 25-25 25h-200c-13.809 0-25-11.195-25-25z"/>
  <path d="m875 900c0-13.805 11.195-25 25-25h200c13.805 0 25 11.195 25 25s-11.195 25-25 25h-200c-13.805 0-25-11.195-25-25z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};