import { useCallback, useEffect } from "react";
import { AuthService } from "../auth";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FetchSelf, RefreshLogin } from "../../graphql";
import { selectWasLoginAttempted, setWasLoginAttempted, useAppDispatch, useAppSelector } from "../../store";


export function useResumeLogin() {
	const dispatch = useAppDispatch();
	const [ execute, { loading } ] = useLazyQuery(FetchSelf);
	const wasLoginAttempted = useAppSelector(selectWasLoginAttempted);
	const [
		mutation, { data: tokenRefreshData, loading: isRefreshingToken }
	] = useMutation(RefreshLogin, {
		update: (cache, result) => {
			cache.evict({
				fieldName: "id"
			});
		}
	});

	useEffect(() => {
		if(tokenRefreshData) {
			const { accessToken, refreshToken } = tokenRefreshData.RefreshLogin;
			AuthService.setAccessToken(accessToken);
			AuthService.setRefreshToken(refreshToken);

			execute().then(res => {
				if(res.data && res.data.FetchSelf) {
					AuthService.setUserId(res.data.FetchSelf.id);
				}
			});
		}
	}, [ tokenRefreshData ]);

	const refreshLogin = useCallback(() => {
		if(wasLoginAttempted) {
			console.debug("login was already attempted ... skipping");
			return;
		}

		console.debug("in useCallback ... attempting to resume login ... checking for local storage");
		dispatch(setWasLoginAttempted(true));
		const userId = AuthService.getUserId();
		const accessToken = AuthService.getAccessToken();
		const refreshToken = AuthService.getRefreshToken();

		if(userId && accessToken && refreshToken) {
			console.debug("in useEffect ... attempting to resume login ... calling mutation");
			return mutation({
				variables: {
					userId,
					accessToken,
					refreshToken
				}
			}).catch(err => {
				console.error("Failed to refresh access token", err);
				AuthService.setAccessToken("");
				AuthService.setRefreshToken("");
			});
		}
	}, [ wasLoginAttempted, dispatch, mutation ]);

	return {
		refreshLogin,
		loading: loading || isRefreshingToken
	};
}
