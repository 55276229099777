import { SvgIcon, SvgIconProps } from "@mui/material";

	export const FlowerPot: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m600 94.801c-20.398 0-501.84 1.1992-501.84 112.08v251.52c0 25.199 24.961 44.641 63.359 59.762l79.68 470.88c11.281 67.441 64.801 116.16 127.2 116.16h463.68c62.16 0 115.68-48.961 127.2-116.16l79.922-471.12c38.398-15.121 63.121-34.559 63.121-59.52v-251.52c0-110.88-481.44-112.08-501.84-112.08zm0 43.441c281.52 0 443.28 43.441 458.16 68.641-14.879 25.199-176.64 68.641-458.16 68.641s-447.12-44.398-458.64-67.199v-0.48047c11.52-25.199 173.52-69.602 458.64-69.602zm316.32 843.6c-7.9219 46.32-43.441 79.922-84.48 79.922l-463.68-0.003906c-41.039 0-76.559-33.602-84.48-80.16l-75.84-448.8c143.28 36.961 378 37.68 392.16 37.68s249.12-0.71875 392.4-37.68l-76.078 449.04zm98.879-500.64s-1.6797 0.48047-2.6406 0.96094c-67.922 22.078-208.56 44.879-412.56 44.879-285.84 0-448.08-44.879-458.64-68.641v-200.64c118.8 60.48 441.84 61.199 458.64 61.199s339.84-0.96094 458.64-61.199v199.68c-2.8789 6.9609-17.762 15.359-43.441 24z"/>
</svg>
			</SvgIcon>
		);
	};