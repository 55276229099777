import { gql } from "@apollo/client";

export const Order_PickupFragment = gql`
	fragment Order_PickupFragment on Order {
		scheduled
		schedulePending
		pickup {
			id
			...Pickup_HeaderFragment
			...Pickup_TrackingFragment
			...Pickup_AssignmentFragment
		}
		...Order_PickupPreferencesFragment
	}
`;

export const Order_PickupPreferencesFragment = gql`
	fragment Order_PickupPreferencesFragment on Order {
		preferredDate
		preferredWindow {
			from
			to
			label
		}
	}
`;