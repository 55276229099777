import { gql } from "@apollo/client";

export const Order_AdminFragment = gql`
	fragment Order_AdminFragment on Order {
		...Order_HeaderFragment
		...Order_PickupFragment
		...Order_SourceFragment
		...Order_AddressFragment
		...Order_EstimateFragment
		...Order_ProductsFragment
	}
`;

export const Order_HaulerFragment = gql`
	fragment Order_HaulerFragment on Order {
		haulerQuotes: quotes(haulerId: $haulerId) {
			...Quote_HeaderFragment
			...Quote_AmountFragment
		}
		...Order_HeaderFragment
		...Order_PickupFragment
		...Order_SourceFragment
		...Order_AddressFragment
		...Order_EstimateFragment
		...Order_ProductsFragment
		...Order_HeaderFragment
	}
`;

export const Order_SchedulingFragment = gql`
	fragment Order_SchedulingFragment on Order {
		paymentMethod { id }
		quotes {
			...Quote_SchedulingFragment
		}
		...Order_HeaderFragment
		...Order_PickupFragment
		...Order_SourceFragment
		...Order_AddressFragment
		...Order_EstimateFragment
		...Order_ProductsFragment
		...Order_PickupPreferencesFragment
	}
`;

export const Order_HaulerQuotingFragment = gql`
	fragment Order_HaulerQuotingFragment on Order {
		...Order_HeaderFragment
		...Order_PickupFragment
		...Order_SourceFragment
		...Order_AddressFragment
		...Order_EstimateFragment
		...Order_ProductsFragment
	}
`;