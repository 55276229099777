import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FetchPricingPolicy, CreatePricingPolicy, UpdatePricingPolicy, FindPricingPolicies } from "../graphql";
import { CreatePricingPolicyMutationVariables, UpdatePricingPolicyMutationVariables } from "../graphql/__generated__/graphql";

export function usePricingPolicy(policyId?: string) {
	const snack = useSnackbar();
	const { data, loading } = useQuery(FetchPricingPolicy, {
		skip: !policyId,
		variables: {
			policyId: policyId || ""
		}
	});

	const [
		create,
		{ loading: createLoading }
	] = useMutation(CreatePricingPolicy, {
		refetchQueries: [
			FindPricingPolicies
		]
	});

	function handleCreate({
		name,
		type,
		margin,
		pricing,
		partners
	}: CreatePricingPolicyMutationVariables) {
		return create({
			variables: {
				name,
				type,
				margin,
				pricing,
				partners
			}
		}).catch(err => {
			console.error("Failed to create pricing policy", err);
			snack.enqueueSnackbar("We ran into an issue saving your information", {
				variant: "error"
			});
		});
	}

	function handleUpdate({
		name,
		type,
		margin,
		pricing
	}: UpdatePricingPolicyMutationVariables) {
		if(!policyId) {
			console.error("No policy id found");
			return;
		}

		return update({
			variables: {
				policyId,
				name,
				type,
				margin,
				pricing
			}
		}).catch(err => {
			console.error("Failed to update pricing policy", policyId, err);
			snack.enqueueSnackbar("We ran into an issue saving your information", {
				variant: "error"
			});
		});
	}

	const [
		update,
		{ loading: updateLoading }
	] = useMutation(UpdatePricingPolicy, {
		refetchQueries: [
			FetchPricingPolicy,
			FindPricingPolicies
		]
	});

	return {
		policy: data?.FetchPricingPolicy,
		loading: loading || createLoading || updateLoading,
		create: handleCreate,
		update: handleUpdate
	};
}