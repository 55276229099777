import { CardActionArea, Chip, Divider, Stack, Typography } from "@mui/material";
import { Anchor, Box, Carousel, Grid, Image } from "grommet";
import { useMemo, useState } from "react";
import { formatCurrency } from "../../../helpers";
import { AddToCartIcon } from "./cart/AddToCartIcon";
import "./ProductCard.css";
import { ProductImageDialog } from "../../../components";
import { usePrimaryColor } from "../../../hooks";

export interface ProductCardProps {
	onClick?: () => void;
	hideCartIcon?: boolean;
	freeDelivery?: boolean;
	productId: string;
	title: string;
	price: number;
	priceCompare?: number;
	media: { name: string; contentUrl: string; }[];
	quantity?: number;
}

export const ProductCard: React.FC<ProductCardProps> = (props) => {
	const BRAND = usePrimaryColor();

	const retailDiscount = useMemo(() => {
		if(props.price && props.priceCompare) {
			return Math.round((1 - (props.price / props.priceCompare)) * 100);
		}
	}, [ props.price, props.priceCompare ]);

	const [ showProductImageDialog, setShowProductImageDialog ] = useState(false);

	return (
		<Box margin={{ vertical: "small" }}>
			{showProductImageDialog && (
				<ProductImageDialog
					onClose={() => setShowProductImageDialog(false)}
					media={props.media}
				/>
			)}
			<CardActionArea disableRipple disableTouchRipple>
				{!props.hideCartIcon && (
					<AddToCartIcon
						productId={props.productId}
						sx={{ position: "absolute", bottom: 8, right: 8 }}
					/>
				)}
				{(props.freeDelivery) && (
					<Box style={{ position: "absolute", top: 0, left: 0, zIndex: 10 }}>
						<Chip
							label="Free Delivery"
							sx={{ borderRadius: 0, color: "white", background: BRAND }}
						/>
					</Box>
				)}
				<Grid rows={[ "2/3", "1/3" ]} height="medium">
					<Box
						flex
						align="center"
						justify="center"
						background="#f8f5f2"
					>
						<Carousel wrap>
							{props.media.map(media => (
								<Image
									key={media.name}
									fit="contain"
									src={media.contentUrl}
									onClick={(event) => {
										event.stopPropagation();
										setShowProductImageDialog(true);
									}}
								/>
							))}
						</Carousel>
					</Box>
					<Box pad="small" gap="xsmall" onClick={props.onClick}>
						<Box>
							<Typography fontWeight="bold">
								<Anchor
									color="black"
									label={props.title}
									onClick={props.onClick}
									className="product-card-title"
								/>
							</Typography>
						</Box>
						<Box flex justify="end" gap="xxsmall">
							<Stack
								direction="row"
								divider={
									<Box align="center" justify="center">
										<Divider sx={{ maxHeight: 20 }} orientation="vertical" />
									</Box>
								}
								spacing={1}
							>
								<Box justify="center">
									<Typography variant="body2" fontWeight="bold">
										{formatCurrency(props.price)}
									</Typography>
								</Box>
								<Box justify="center">
									<Typography variant="body2" fontWeight="bold">
										Qty: {props.quantity ?? 1}
									</Typography>
								</Box>
							</Stack>
							{props.priceCompare && (
								<Stack
									direction="row"
									divider={
										<Box align="center" justify="center">
											<Divider sx={{ maxHeight: 20 }} orientation="vertical" />
										</Box>
									}
									spacing={1}
								>
									<Box justify="center">
										<Typography variant="caption">
											Est. Retail: {formatCurrency(props.priceCompare)}
										</Typography>
									</Box>
									<Box justify="center">
										<Typography variant="caption">
											{retailDiscount}% Off
										</Typography>
									</Box>
								</Stack>
							)}
						</Box>
					</Box>
				</Grid>
			</CardActionArea>
			<Divider />
		</Box>
	);
};