import { useState, useEffect, useMemo } from "react";

export enum Sizes {
	SMALL = 0,
	MEDIUM = 1,
	LARGE = 2,
	XLARGE = 3
}

type WindowSize =
	| "small"
	| "medium"
	| "large"
	| "xlarge";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;

	const size = ((): WindowSize => {
		if(width >= 1200) {
			return "xlarge";
		}

		if(width >= 900) {
			return "large";
		}

		if(width >= 600) {
			return "medium";
		}

		return "small";
	})();

	return {
		size,
		width,
		height,
		sizeIndex: Sizes[ size.toUpperCase() as keyof typeof Sizes ]
	};
}

export function useWindowDimensions() {
	const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions());

	const footerContentHeight = useMemo(() => {
		return document.getElementById("footer")?.clientHeight ?? 0;
	}, [ document.getElementById("footer")?.clientHeight ]);

	const headerContentHeight = useMemo(() => {
		return document.getElementById("header")?.clientHeight ?? 0;
	}, [ document.getElementById("header")?.clientHeight ]);

	const mainContentHeight = useMemo(() => {
		const currentViewport = windowDimensions.height;
		return currentViewport - headerContentHeight - footerContentHeight;
	}, [ windowDimensions.height, footerContentHeight, headerContentHeight ]);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setWindowDimensions(getWindowDimensions());
		}, 100);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return {
		...windowDimensions,
		mainContentHeight,
		headerContentHeight,
		footerContentHeight
	};
}