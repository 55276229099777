"use client";
import { Anchor, Box, Form, FormField, Heading, Page, PageContent, Text, TextInput } from "grommet";
import { Link } from "@components/Navigation";
import React, { useEffect, useMemo } from "react";
import { formRequiredValidationWithLabel, isEmailAddress, isPhoneNumber } from "../../../helpers";
import { selectInstanceId, useAppSelector } from "../../../store";
import { LoginOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useLogin } from "../../../auth/hooks/useLogin";
import { usePageTitle } from "../../../hooks";

export const Login: React.FC = () => {
	usePageTitle("Login");
	const instanceId = useAppSelector(selectInstanceId);

	const [ code, setCode ] = React.useState("");
	const [ username, setUsername ] = React.useState("");

	const { user, wasCodeSent, beginLogin, confirmLogin, canSendCode, errorMessage, resendCode, loading, redirect } = useLogin();

	function successfulLoginCallback(): void {
		redirect("/dashboard");
	}

	useEffect(() => {
		if(user) {
			successfulLoginCallback();
		}
	}, [ user ]);

	const usernameFormatted = useMemo(() => {
		if(isPhoneNumber(username)) {
			return username.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
		}

		if(isEmailAddress(username)) {
			return username.toLowerCase();
		}

		return "";
	}, [ username ]);

	function handleSubmit() {
		console.debug("in handleSubmit");
		if(wasCodeSent) {
			confirmLogin(
				code,
				() => {
					console.debug("Successful login");
				}
			);
		}
		else {
			console.debug("Calling beginLogin for username:", username, "with instance:", instanceId);
			beginLogin(username, instanceId);
			document.getElementById("code-field")?.focus();
		}
	}

	function handleResendCode() {
		if(!canSendCode) return;
		resendCode();
	}

	function sanitizeUsername() {
		if(isPhoneNumber(username.replace(/\D/g, ""))) {
			setUsername(username.replace(/\D/g, ""));
		}
	}

	return (
		<Page kind="narrow">
			<PageContent>
				<Box align="center" justify="center" margin="small">
					<Box
						round
						pad="large"
						width="500px"
						elevation="medium"
						background="white"
					>
						<Form
							value={{ code, username }}
							onSubmit={handleSubmit}
							onChange={(nextValue) => {
								setCode(nextValue.code);
								setUsername(nextValue.username);
							}}
							validate="submit"
						>
							<Box gap="medium">
								<Box align="center" gap="small">
									<Heading level="2" margin="none">Account Login</Heading>
									<Text>
										Enter your email or phone number to login to your account.
									</Text>
								</Box>
								<Box>
									<FormField
										label="Username"
										contentProps={{
											border: undefined,
											focusIndicator: false
										}}
										onBlur={sanitizeUsername}
										onPaste={sanitizeUsername}
										name="username"
										validate={[
											(value: string) => {
												if(isPhoneNumber(String(value)) || isEmailAddress(String(value))) {
													return undefined;
												}

												return "Please enter a valid email address or phone number";
											},
											formRequiredValidationWithLabel("Username")
										]}
									>
										<TextInput
											name="username"
											placeholder="Email / Phone Number"
											focusIndicator={false}
										/>
									</FormField>
									{wasCodeSent && (
										<FormField
											label="Code"
											contentProps={{
												border: undefined,
												focusIndicator: false
											}}
											name="code"
											id="code-field"
											inputMode="numeric"
											validate={wasCodeSent ? [
												formRequiredValidationWithLabel("Code"),
												(value: string) => {
													if(/\d{6}/.test(value)) {
														return undefined;
													}

													return "Please enter a valid login code";
												}
											] : undefined}
										>
											<TextInput
												name="code"
												placeholder="Code"
												focusIndicator={false}
												disabled={!wasCodeSent}
											/>
										</FormField>
									)}
									<Box gap="small">
										{errorMessage && (
											<Text color="status-critical">{errorMessage}</Text>
										)}
										{wasCodeSent && (
											<Text>We just sent a 6 digit code to <Text weight="bold">{usernameFormatted}</Text>. Enter it to complete your login.</Text>
										)}
										{wasCodeSent && canSendCode && (
											<Anchor
												onClick={handleResendCode}
												label="Need a new code?"
											/>
										)}
									</Box>
								</Box>
								<Box>
									<RegoLoadingButton
										isLoading={loading}
										label="Login"
										submit
									/>
								</Box>
								<Box align="center" justify="end">
									<Text>
										Don't have an account? <Link color="black" path={`/register${window.location.search}`}>Sign Up</Link>
									</Text>
								</Box>
							</Box>
						</Form>
					</Box>
				</Box>
			</PageContent>
		</Page>
	);
};

interface LoadingButtonProps {
	label: string;
	isLoading: boolean;
	onClick?: () => void;
	submit?: boolean;
}

export const RegoLoadingButton: React.FC<LoadingButtonProps> = (props) => {
	return (
		<LoadingButton
			loading={props.isLoading}
			onClick={props.onClick}
			endIcon={<LoginOutlined />}
			loadingPosition="end"
			disabled={props.isLoading}
			variant="contained"
			color="primary"
			type="submit"
		>
			{props.label}
		</LoadingButton>
	);
};

export default Login;