import { gql } from "../../../__generated__";


export const GetReportingActionItems = gql(/* GraphQL */ `
	query GetReportingActionItems($instanceId: String!) {
		GetOnboardingTasks(instanceId: $instanceId) {
			hasCompletedInterests
			hasUploadedWasteTicket
			hasCompletedWasteProfile
			hasCompletedOnboardingCall
			hasCompletedWasteAuditBetaSignup
		}
		FindWasteContainerInspection(
			missed: { equals: false }
			inspected: { equals: false }
			assignment: {
				partner: {
					instance: {
						id: { equals: $instanceId }
					}
				}
			}
		) {
			id
			scheduled
			scheduledFor
			assignment { 
				id 
				name 
				location 
				partner { id name }
			}
		}
	}
`);
