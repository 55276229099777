import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { Frequency, Question, QuestionType, TimePeriod, WasteScenario } from "../features/calculator";
import { CalculatorPeriod, WasteCalculatorSubmissionDto } from "../graphql/__generated__/graphql";
import { questions } from "../features/calculator/static";

export interface CalculatorState {
	period: TimePeriod;
	householdSize: number;
	pledgeFurnitureReuse: boolean;
	pledgeRecycleGlassAluminum: boolean;
	pledgeEliminatePlasticBags: boolean;
	pledgeEliminatePlasticBottles: boolean;
	pledgeCompostReduceFoodWaste: boolean;
	pledgeReduceTakeoutWaste: boolean;
	pledgeReduceClothingWaste: boolean;
	pledgeReducePaperCardboardWaste: boolean;
	responseRecyclePaperPlastic: boolean;
	responseRecycleGlassAluminum: boolean;
	responsePackageFrequency: Frequency;
	responseTakeoutFrequency: Frequency;
	responsePlasticBagFrequency: Frequency;
	responseTakeoutCoffeeFrequency: Frequency;
	responsePlasticBottleFrequency: Frequency;
	responseDisposeFoodWasteFrequency: Frequency | "compost";
	responseClothingPurchaseFrequency: Frequency | "used";
	responseFurniturePurchaseFrequency: Frequency | "used";
}

const initialState: CalculatorState = {
	period: "daily",
	householdSize: 1,
	pledgeFurnitureReuse: false,
	pledgeReduceClothingWaste: false,
	pledgeRecycleGlassAluminum: false,
	pledgeEliminatePlasticBags: false,
	pledgeEliminatePlasticBottles: false,
	pledgeCompostReduceFoodWaste: false,
	pledgeReduceTakeoutWaste: false,
	pledgeReducePaperCardboardWaste: false,
	responseRecyclePaperPlastic: false,
	responseRecycleGlassAluminum: false,
	responsePackageFrequency: Frequency.SOMETIMES,
	responseTakeoutFrequency: Frequency.SOMETIMES,
	responsePlasticBagFrequency: Frequency.SOMETIMES,
	responseTakeoutCoffeeFrequency: Frequency.SOMETIMES,
	responsePlasticBottleFrequency: Frequency.SOMETIMES,
	responseDisposeFoodWasteFrequency: Frequency.SOMETIMES,
	responseClothingPurchaseFrequency: Frequency.SOMETIMES,
	responseFurniturePurchaseFrequency: Frequency.SOMETIMES
};

export const calculatorSlice = createSlice({
	name: "calculator",
	initialState,
	reducers: {
		setCalculatorState: (state, action: PayloadAction<Partial<CalculatorState>>) => {
			return { ...state, ...action.payload };
		}
	}
});

export const {
	setCalculatorState
} = calculatorSlice.actions;

export const selectCalculatorState = (state: RootState) => state.calculator;

function marshallTimePeriod(period: TimePeriod): CalculatorPeriod {
	switch(period) {
		case "daily": return CalculatorPeriod.Daily;
		case "weekly": return CalculatorPeriod.Weekly;
		case "monthly": return CalculatorPeriod.Monthly;
		case "yearly": return CalculatorPeriod.Yearly;
	}
}

export function parseWasteImpact(scenario: WasteScenario, question: Question<QuestionType>): number {
	if(!question.weightImpactKey) return 0;

	const { impact, expected } = scenario[ question.weightImpactKey ] as { impact: number, expected: number; };
	return Number((impact || 0).toFixed(2));
}

export function parseWasteReduction(scenario: WasteScenario, question: Question<QuestionType>): number {
	if(!question.weightReductionKey) return 0;
	const reduction = (scenario[ question.weightReductionKey ] as number || 0);
	return Number(reduction.toFixed(2));
}

export function parseCalculatorResponseValue(value: string | number | boolean): string {
	if(typeof value === "boolean") {
		return value ? "true" : "false";
	}

	return String(value);
}

export function parseTotalWeightDivertedPounds(scenario: WasteScenario): number {
	return Number(scenario.toHousehold(scenario.dailyWasteReductionFromPledgesPounds).toFixed(2));
}

export function parseTotalWeightGeneratedPounds(scenario: WasteScenario): number {
	return Number(scenario.toHousehold(scenario.dailyWastePerPersonPounds).toFixed(2));
}

export const selectCalculatorSubmission = (scenario: WasteScenario) => (state: RootState): WasteCalculatorSubmissionDto => {
	const { period } = state.calculator;

	return {
		period: marshallTimePeriod(period),
		totalWeightDivertedPounds: parseTotalWeightDivertedPounds(scenario),
		totalWeightGeneratedPounds: parseTotalWeightGeneratedPounds(scenario),
		responses: questions.map((question) => {
			return {
				id: question.id,
				value: parseCalculatorResponseValue(state.calculator[ question.id ] ?? ""),
				question: question.text,
				period: CalculatorPeriod.Daily,
				weightImpact: parseWasteImpact(scenario, question),
				weightReduction: parseWasteReduction(scenario, question),
				pledge: !!question.pledgeKey && (state.calculator[ question.pledgeKey ] as boolean || false),
				totalWeightDivertedPounds: parseTotalWeightDivertedPounds(scenario),
				totalWeightGeneratedPounds: parseTotalWeightGeneratedPounds(scenario)
			};
		})
	};
};



export default calculatorSlice.reducer;