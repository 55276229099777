import { gql } from "@apollo/client";

export const OrderRequest_SourceFragment = gql`
	fragment OrderRequest_SourceFragment on OrderRequest {
		referer {
			id
			name
			market {
				id
				name
			}
		}
		instance {
			id
			name
			type
		}
	}
`;