import { IconButton } from "@mui/material";
import { useMemo } from "react";
import { useHauler } from "../../hooks";
import { ProductMediaDialog } from "../product";
import { ImageSearch } from "@mui/icons-material";
import { Pagination, ProductListItemProductFragment, ProductListItemV1 } from "../../../../components";
import { useHaulerScopedOrder } from "../../../../graphql";

export const HaulerOrderProductsComponent: React.FC<{
	orderId: string;
}> = ({ orderId }) => {
	const { hauler } = useHauler();
	const { order, loading } = useHaulerScopedOrder(orderId, hauler?.id ?? "");

	const products = useMemo(() => {
		return order?.products ?? [];
	}, [ order ]);

	return (
		<Pagination pageSize={5}>
			{products.map((product) => (
				<ProductMediaDialog
					media={product.media}
					activatorButton={(
						<ProductListItemV1
							loading={loading}
							product={product as unknown as ProductListItemProductFragment}
							onSelect={() => {
								//
							}}
						>
							<IconButton >
								<ImageSearch />
							</IconButton>
						</ProductListItemV1>
					)}
				/>
			))}
		</Pagination>
	);
};