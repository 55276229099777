import { Box, Heading } from "grommet";
import React, { useEffect, useMemo, useState } from "react";
import { useReferers } from "../controller";
import { Button, List, Typography } from "@mui/material";
import { LocalizationProvider } from "../../../provider";
import { DateCalendar, PickersCalendarHeader } from "@mui/x-date-pickers";
import moment, { Moment } from "moment-timezone";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCheckoutState, setCustomSchedule, setPickup, setScheduledDate } from "../../../store/checkout";
import { AvailablePickupListItem, StepControls } from "../../../components";
import { CheckoutTitleContainer, CheckoutViewContainer } from "../components";
import { useCheckoutCustomizer } from "../../../hooks/customizer/useCustomizer";

export function dateStringToMoment(date: string) {
	return moment.tz(date, "YYYY-MM-DD", moment.tz.guess());
}

export function momentToDateString(date: moment.Moment) {
	return date.format("YYYY-MM-DD");
}

export const CheckoutScheduleUpfrontView: React.FC = () => {
	const { referer } = useReferers();
	const dispatch = useAppDispatch();

	const scheduledPickups = useMemo(() => {
		return referer?.scheduledPickups || [];
	}, [ referer ]);

	const {
		pickup,
		scheduledDate,
		isCustomSchedule,
		isCustomScheduleEnabled
	} = useAppSelector(selectCheckoutState);

	function updateScheduledDate(date: moment.Moment) {
		const dateString = momentToDateString(date);

		const existingPickup = scheduledPickups.find(p => p.scheduledDate === dateString);
		if(existingPickup) {
			dispatch(setPickup(existingPickup));
			dispatch(setScheduledDate(dateString));
		}
		else {
			dispatch(setPickup(null));
			dispatch(setScheduledDate(dateString));
		}
	}

	function updateCustomSchedule(value: boolean) {
		dispatch(setCustomSchedule(value));
	}

	const [ month, setMonth ] = useState(moment().startOf("day"));
	const filteredPickups = useMemo(() => {
		return scheduledPickups
			.filter(p => dateStringToMoment(p.scheduledDate || "").isSame(month, "month"))
			.sort((a, b) => dateStringToMoment(a.scheduledDate || "").diff(dateStringToMoment(b.scheduledDate || "")));
	}, [ scheduledPickups, month ]);

	useEffect(() => {
		const nextAvailablePickup = scheduledPickups.reduce((acc, curr) => {
			if(acc) {
				return (dateStringToMoment(curr.scheduledDate || "").isBefore(dateStringToMoment(acc.scheduledDate || "")))
					? curr
					: acc;
			}
			else {
				return curr;
			}
		}, null as any);

		if(nextAvailablePickup) {
			setMonth(dateStringToMoment(nextAvailablePickup.scheduledDate).startOf("month"));
		}
	}, [ scheduledPickups ]);

	const {
		schedule
	} = useCheckoutCustomizer();

	return (
		<CheckoutViewContainer>
			<CheckoutTitleContainer
				heading={schedule.heading || "Pickup Date"}
				subheading={schedule.subheading || "Select a pickup date to continue."}
			/>
			<Box height="100%">
				{isCustomSchedule && (
					<LocalizationProvider>
						<DateCalendar
							disablePast
							shouldDisableDate={(date) => {
								return date.isSameOrBefore(moment(), "day");
							}}
							value={dateStringToMoment(scheduledDate)}
							showDaysOutsideCurrentMonth
							onChange={(value) => {
								if(value) {
									updateScheduledDate(value);
								}
							}}
						/>
					</LocalizationProvider>
				)}
				{!isCustomSchedule && (
					<Box margin="small" gap="medium" flex>
						<Box gap="small">
							<LocalizationProvider>
								<PickersCalendarHeader
									view="day"
									views={[ "month" ]}
									currentMonth={month}
									onMonthChange={(value: Moment) => setMonth(value)}
									reduceAnimations={false}
									timezone={moment.tz.guess()}
									minDate={moment().startOf("day")}
									maxDate={moment().add(3, "months").endOf("day")}
								/>
							</LocalizationProvider>
							<Box flex overflow={{ vertical: "auto" }} style={{ maxHeight: "300px" }}>
								{filteredPickups.length === 0 && (
									<Box align="center">
										<Typography>
											No available pickup dates for this month.
										</Typography>
									</Box>
								)}
								{filteredPickups.length > 0 && (
									<List>
										{filteredPickups.filter(p => dateStringToMoment(p.scheduledDate || "").isSame(month, "month")).map((item, index) => (
											<AvailablePickupListItem
												key={item.id}
												pickupId={item.id}
												pickupDate={item.scheduledDate || ""}
												onSelect={() => {
													updateScheduledDate(dateStringToMoment(item.scheduledDate || ""));
												}}
												selected={item.id === pickup?.id}
											/>
										))}
									</List>
								)}
							</Box>
						</Box>
						{isCustomScheduleEnabled && (
							<Box>
								<Heading level="3" margin="none">
									None of these dates work for you?
								</Heading>
								<Typography>
									Schedule an express pickup on your preferred date.
								</Typography>
							</Box>
						)}
						{isCustomScheduleEnabled && (
							<Box align="center" gap="xsmall">
								<Button
									color="primary"
									variant="contained"
									onClick={() => updateCustomSchedule(true)}
								>
									Schedule Express Pickup
								</Button>
								<Typography variant="caption">
									additional fees may apply
								</Typography>
							</Box>
						)}
					</Box>
				)}
				<Box justify="end">
					<StepControls
						name="checkout"
						isLoading={false}
						canProceed={!!scheduledDate || !!pickup}
					/>
				</Box>
			</Box>
		</CheckoutViewContainer>
	);
};
