import { useMemo } from "react";
import { ProductMediaContext } from "../graphql/__generated__/graphql";


export const useMediaContext = () => {
	const options = useMemo(() => {
		return [
			{
				label: "Back",
				id: ProductMediaContext.Back
			},
			{
				label: "Front",
				id: ProductMediaContext.Front
			},
			{
				label: "Left Side",
				id: ProductMediaContext.LeftSide
			},
			{
				label: "Right Side",
				id: ProductMediaContext.RightSide
			},
			{
				label: "Defects / Damages",
				id: ProductMediaContext.Defects
			},
			{
				label: "Tag",
				id: ProductMediaContext.Tag
			},
			{
				label: "Image",
				id: ProductMediaContext.Default
			}
		];
	}, []);

	return options;
};
