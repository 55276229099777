
export interface ItemLike {
	id: string;
	name: string;
}

export interface ItemPricingLike {
	id: string;
	name: string;
	pickupFee: number;
	disassemblyFee?: number;
	sizes?: ItemPricingLike[];
}

export function calculatePickupFee(
	type: ItemPricingLike | null,
	typeId: string,
	sizeId: string
) {
	const feeFromType = type?.pickupFee ?? 0;
	const feeFromSize = (sizeId)
		? type?.sizes?.find((s) => s.id === sizeId)?.pickupFee ?? 0
		: 0;

	return feeFromType + feeFromSize;
}

export function calculateDisassemblyFee(
	type: ItemPricingLike | null,
	typeId: string,
	sizeId: string
) {
	const feeFromType = type?.disassemblyFee ?? 0;
	const feeFromSize = (sizeId)
		? type?.sizes?.find((s) => s.id === sizeId)?.disassemblyFee ?? 0
		: 0;

	return feeFromType + feeFromSize;
}

export function calculcatePickupFeeComparison(
	type: ItemPricingLike | null,
	typeId: string,
	sizeId: string
) {
	const fee = calculatePickupFee(type, typeId, sizeId);
	return Math.round(fee * 1.3);
}
