import React, { useMemo } from "react";
import { SidebarRouter, SidebarRouteItem, DashboardListItem, OrdersListItem, StoreMultilevelListItem, WasteReportingListItem } from "../../../components";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { isNotNull } from "../../../helpers";
import { useFeature } from "../../instance/hooks/useFeature";
import { WasteReportingDashboardView } from "../common/views/WasteDiversionDashboard";
import { useAdminStoreRoutes } from "../donation/router";
import { WasteTicketView } from "../common/views/WasteTickets";
import { OrderDetailsView, OrdersView, ProductDetails } from "../common";
import { SustainabilityCalculator } from "./views/Calculator";
import { ResidenceAdminDashboardView } from "./views";
import { StoreSettingsView } from "../common/views/StoreSettings";

export const ResidenceRouter: React.FC = () => {
	return useResidenceRouter().toRouter();
};

export function useResidenceRouter() {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);

	const isRemovalEnabled = useFeature(InstanceFeature.RemovalEnabled);
	const isDonationEnabled = useFeature(InstanceFeature.DonationEnabled);

	const storeRoutes = useAdminStoreRoutes();

	return useMemo(() => {
		return new SidebarRouter([
			new SidebarRouteItem(
				"/dashboard",
				<ResidenceAdminDashboardView />,
				<DashboardListItem
					key="dashboard-list-item"
					absolutePath="/admin/dashboard"
				/>,
				true
			),
			(isRemovalEnabled || isDonationEnabled) ? new SidebarRouteItem(
				"/orders",
				<OrdersView />,
				<OrdersListItem
					key="orders-list-item"
					absolutePath="/admin/orders"
				/>,
				true
			) : null,
			// new SidebarRouteItem(
			// 	"/residents",
			// 	<ResidentsView />,
			// 	<ResidentsListItem absolutePath="/admin/residents" />,
			// 	true
			// ),
			new SidebarRouteItem(
				"/waste-reporting/*",
				<WasteReportingDashboardView />,
				<WasteReportingListItem
					key="waste-reporting"
					absolutePath="/admin/waste-reporting"
				/>,
				true
			),
			isSalesEnabled ? new SidebarRouteItem(
				"/store/*",
				<StoreSettingsView />,
				<StoreMultilevelListItem absolutePath="" />,
				true
			) : null
		].filter(isNotNull), "Residence", [
			{ from: "/waste-reporting", to: "/admin/waste-reporting/dashboard" },
			{ from: "/", to: "/admin/dashboard" },
			isSalesEnabled ? { from: "/store", to: "/admin/store/dashboard" } : null
		].filter(isNotNull), [
			{ path: "/orders/:orderId", element: <OrderDetailsView permitEdit={false} permitViewPickup={false} /> },
			{ path: "/products/:productId", element: <ProductDetails permitEdit={false} /> },
			{ path: "/calculator", element: <SustainabilityCalculator /> },
			{ path: "/waste-reporting/dashboard", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/share", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/download", element: <WasteReportingDashboardView /> },
			{ path: "/waste-reporting/upload", element: <WasteTicketView /> },
			...storeRoutes
		]);
	}, [ isSalesEnabled, isDonationEnabled ]);
}