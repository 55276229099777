import { InternalRefetchQueriesInclude, useMutation, useQuery } from "@apollo/client";
import { gql } from "../../../__generated__";
import { CancelOrder } from "../mutations";
import { useSnackbar } from "notistack";
import { canUpdateOrder } from "../../../../features/dashboard/helpers";
import { Order_AdminFragmentFragment, Order_GlobalAdminFragmentFragment, Order_HaulerFragmentFragment, Order_HaulerQuotingFragmentFragment, Order_HeaderFragmentFragment, Order_SchedulingFragmentFragment, Order_SourceFragmentFragment, Order_UserFragmentFragment } from "../../../__generated__/graphql";
import { useRefererLabel } from "../../../../features/admin/hooks/useRefererLabel";

export const FetchOrder = gql(/* GraphQL */`
	query FetchOrder($orderId: String!) {
		FetchOrder(orderId: $orderId) {
			id
			...Order_HeaderFragment @nonreactive
		}
	}
`);

export const FetchOrderUserCombined = gql(/* GraphQL */`
	query FetchOrderUserCombined($orderId: String!) {
		FetchOrder(orderId: $orderId) {
			id
			...Order_UserCombinedFragment @nonreactive
		}
	}
`);

export const FetchExpandedOrder = gql(/* GraphQL */`
	query FetchExpandedOrder($orderId: String!) {
		FetchOrder(orderId: $orderId) {
			id
			...Order_ExpandedFragment @nonreactive
		}
	}
`);

export const FetchAdminOrder = gql(/* GraphQL */`
	query FetchAdminOrder($orderId: String!) {
		FetchOrder(orderId: $orderId) {
			id
			milestone
			...Order_ExpandedFragment @nonreactive
			quotes {
				id
				...QuoteFragment @nonreactive
			}
			lastEstimate {
				...OrderEstimate_HeaderFragment 
			}
		}
	}
`);

export const FetchOrderUnified = gql(/* GraphQL */`
	query FetchOrderUnified(
		$orderId: String!
		$includeUser: Boolean!
		$includeAdmin: Boolean!
		$includeHauler: Boolean!
		$includeScheduling: Boolean!
		$includeGlobalAdmin: Boolean!
		$includeHaulerQuoting: Boolean!
		$haulerId: String
	) {
		FetchOrder(orderId: $orderId) {
			...Order_HeaderFragment
			...Order_UserFragment @include(if: $includeUser)
			...Order_AdminFragment @include(if: $includeAdmin)
			...Order_HaulerFragment @include(if: $includeHauler)
			...Order_SchedulingFragment @include(if: $includeScheduling)
			...Order_GlobalAdminFragment @include(if: $includeGlobalAdmin)
			...Order_HaulerQuotingFragment @include(if: $includeHaulerQuoting)
		}
	}
`);

type RequestScope =
	| "header"
	| "user"
	| "admin"
	| "hauler"
	| "scheduling"
	| "global-admin"
	| "hauler-quoting";

interface UnifiedOrderOptions {
	haulerId?: string;
}

export function useUnifiedOrder(
	orderId: string,
	scopes: RequestScope[],
	options?: UnifiedOrderOptions
) {
	const snack = useSnackbar();

	const { data, error, loading, refetch } = useQuery(FetchOrderUnified, {
		skip: !orderId,
		variables: {
			orderId,
			haulerId: options?.haulerId,
			includeUser: scopes.includes("user"),
			includeAdmin: scopes.includes("admin"),
			includeHauler: scopes.includes("hauler"),
			includeScheduling: scopes.includes("scheduling"),
			includeGlobalAdmin: scopes.includes("global-admin"),
			includeHaulerQuoting: scopes.includes("hauler-quoting")
		}
	});

	const [
		cancel,
		{ loading: isCancelling }
	] = useMutation(CancelOrder, {
		refetchQueries: [ FetchOrderUnified ]
	});

	function handleCancel(
		reason: string,
		refetchQueries?: InternalRefetchQueriesInclude
	) {
		return cancel({
			variables: { orderId, reason },
			refetchQueries: Array.isArray(refetchQueries)
				? [ ...refetchQueries, FetchOrderUnified ]
				: [ FetchOrderUnified ]
		}).then(res => {
			snack.enqueueSnackbar("Your order was successfully cancelled", { variant: "success" });
			return res;
		}).catch(err => {
			console.error(`failed to cancel order [${orderId}]`, err);
			snack.enqueueSnackbar(
				"We ran into an issue cancelling your order",
				{ variant: "error" }
			);
		});
	}

	return {
		data,
		error,
		loading,
		refetch,
		order: data?.FetchOrder,
		pickup: data?.FetchOrder?.pickup,
		handleCancel,
		referer: {
			...data?.FetchOrder?.referer,
			label: useRefererLabel((data?.FetchOrder || {}) as Order_SourceFragmentFragment)
		},
		isCancelling,
		isStarted: data?.FetchOrder?.startedAt !== null,
		isCancelled: data?.FetchOrder?.cancelledAt !== null,
		isCompleted: data?.FetchOrder?.completedAt !== null,
		isSubmitted: data?.FetchOrder?.submittedAt !== null,
		isEditable: data?.FetchOrder && canUpdateOrder(data.FetchOrder),
		isCancellable: data?.FetchOrder && data.FetchOrder.cancelledAt === null,
		allProductsHaveDisposition: data?.FetchOrder?.products?.every(p => !!p.disposition) ?? false
	};
}


export function useTypedUnifiedOrder<T extends Order_HeaderFragmentFragment>(
	orderId: string,
	scopes: RequestScope[],
	options?: UnifiedOrderOptions
) {
	const { order, ...rest } = useUnifiedOrder(
		orderId,
		scopes,
		options
	);

	return {
		order: order as unknown as T,
		...rest
	};
}

export function useHeaderScopedOrder(orderId: string) {
	return useTypedUnifiedOrder<Order_HeaderFragmentFragment>(orderId, [ "header" ]);
}

export function useUserScopedOrder(orderId: string) {
	return useTypedUnifiedOrder<Order_UserFragmentFragment>(orderId, [ "user" ]);
}

export function useAdminScopedOrder(orderId: string) {
	return useTypedUnifiedOrder<Order_AdminFragmentFragment>(orderId, [ "admin" ]);
}

export function useHaulerScopedOrder(orderId: string, haulerId: string) {
	return useTypedUnifiedOrder<Order_HaulerFragmentFragment>(orderId, [ "hauler" ], { haulerId });
}

export function useSchedulingScopedOrder(orderId: string) {
	return useTypedUnifiedOrder<Order_SchedulingFragmentFragment>(orderId, [ "scheduling" ]);
}

export function useGlobalAdminScopedOrder(orderId: string) {
	return useTypedUnifiedOrder<Order_GlobalAdminFragmentFragment>(orderId, [ "global-admin" ]);
}

export function useHaulerQuotingScopedOrder(orderId: string) {
	return useTypedUnifiedOrder<Order_HaulerQuotingFragmentFragment>(orderId, [ "hauler-quoting" ]);
}