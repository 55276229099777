import React from "react";
import { Box, Grid, Heading } from "grommet";
import { LinearProgress } from "@mui/material";
import { OrderDetailsComponent, OrderProductsComponent, OrderPickupDetailsComponent } from "../../components";
import { useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { useOrderStatusChip } from "../hooks/useOrderStatusChip";
import { useAdminScopedOrder } from "../../../../graphql";

export const OrderDetailsView: React.FC<{
	permitEdit: boolean;
	permitViewPickup: boolean;
}> = ({ permitEdit, permitViewPickup }) => {
	const orderId = useRequiredPathParam(
		"orderId",
		"/admin"
	);

	const { sizeIndex } = useWindowDimensions();
	const { loading } = useAdminScopedOrder(orderId || "");
	const orderStatusChip = useOrderStatusChip(orderId);

	return (
		<Box margin="medium" gap="small" flex>
			<Grid columns={{ count: sizeIndex < 3 ? 1 : 2, size: "auto" }} gap="medium">
				<Box>
					{/* THIS SHOULD BE COMBINED INTO OrderDetailsComponent */}
					<Box gap="xsmall">
						<Box direction="row" justify="between" align="center">
							<Heading level={3} margin="none">
								Order Details
							</Heading>
							{orderStatusChip && (
								<Box>
									{orderStatusChip}
								</Box>
							)}
						</Box>
						{loading && (
							<LinearProgress />
						)}
					</Box>
					<OrderDetailsComponent
						orderId={orderId || ""}
					/>
				</Box>
				<Box>
					{/* THIS SHOULD BE COMBINED INTO OrderPickupDetailsComponent */}
					<Heading level={3} margin="none">
						Pickup Details
					</Heading>
					{loading && (
						<LinearProgress />
					)}
					<OrderPickupDetailsComponent
						permitEdit={false}
						permitViewPickup={permitViewPickup}
						orderId={orderId || ""}
					/>
				</Box>
			</Grid>
			<Box>
				<Heading level={3} margin="none">
					Order Items
				</Heading>
				{loading && (
					<LinearProgress />
				)}
				<OrderProductsComponent
					permitEdit={permitEdit}
					orderId={orderId || ""}
				/>
			</Box>
		</Box>
	);
};