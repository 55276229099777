import * as cocossd from "@tensorflow-models/coco-ssd";
import React, { createRef, useCallback, useEffect, useMemo, useState } from "react";
import useTensorflow from "./useTensorflow";
import { useQuery } from "@apollo/client";
import { FetchProductMedia } from "../../graphql";

export function useCoco() {
	const tensorflow = useTensorflow();
	const [ coco, setCoco ] = useState<cocossd.ObjectDetection | null>(null);
	useEffect(() => {
		(async () => {
			if(tensorflow.isReady) {
				console.debug("got tensorflow ready ... loading coco-ssd");
				const initialized = await cocossd.load().catch(err => {
					console.error("Error loading coco-ssd", err);
					return null;
				});

				console.debug("coco loaded");
				setCoco(initialized);

				return () => {
					initialized?.dispose();
				};
			}
		})();
	}, [ tensorflow.isReady ]);

	return coco;
}

export default useCoco;

const detectionMap = new Map<string, Detection[]>();

export function useProductAI(productId: string) {
	const { detect } = useDetection();
	const elementRef = createRef<HTMLImageElement>();
	const { data, loading } = useQuery(FetchProductMedia, {
		skip: !productId,
		variables: { productId }
	});

	const [ detections, setDetections ] = useState<Detection[]>([]);

	function upsertDetections(detections: Detection[]) {
		setDetections((old) => {
			const newDetections = [ ...old ];
			newDetections.push(...detections);
			return newDetections;
		});
	}

	const media = useMemo(() => {
		return data?.FetchProduct.media || [];
	}, [ data ]);

	useEffect(() => {
		if(!elementRef.current) {
			return;
		}

		(async () => {
			for(const image of media) {
				const element = elementRef.current;
				if(!element) continue;

				const [ path, query ] = image.contentUrl.split("?");
				element.src = path + "?" + query + "&t=" + Date.now() + "&r=" + Math.random();

				await new Promise<void>((r, j) => {
					function loaded(event: any): any {
						if(!element) return;

						detect(element, { ...image }).then(detections => {
							console.log("Detections: ", detections);
							upsertDetections(detections);
						}).catch(err => {
							console.error("Failed to detect", err);
						}).finally(() => {
							element.removeEventListener("load", loaded);
							r();
						});
					}

					element.addEventListener("load", loaded);
				});
			}
		})();
	}, [ media ]);

	const productDetections = useMemo(() => {
		if(productId) {
			const detections = detectionMap.get(productId);
			if(detections) {
				return detections;
			}
		}

		return detections;
	}, [ productId, detections ]);

	const bestDetection = useMemo(() => {
		return [ ...detections ].sort((a, b) => b.score - a.score)[ 0 ] || null;
	}, [ detections ]);

	const element = useMemo(() => {
		return (productId) ? (
			<img
				id="image-preview"
				ref={elementRef}
				crossOrigin="anonymous"
				style={{ display: "none" }}
			/>
		) : null;
	}, []);

	return {
		element,
		elementRef,
		loading,
		bestDetection,
		detections: productDetections
	};
}

export interface Detection {
	x: number;
	y: number;
	width: number;
	height: number;
	score: number;
	name: string;
	imageId?: string;
	imageName?: string;
	imageUrl?: string;
}

export function useDetection() {
	const coco = useCoco();

	const detect = useCallback(async (element: HTMLImageElement | HTMLCanvasElement, media: Partial<{ id: string, name: string, contentUrl: string; }>): Promise<Detection[]> => {
		if(!coco) throw new Error("Coco is not ready");
		return (await coco.detect(element)).map(d => {
			return {
				x: d.bbox[ 0 ],
				y: d.bbox[ 1 ],
				width: d.bbox[ 2 ],
				height: d.bbox[ 3 ],
				score: d.score,
				name: d.class,
				imageId: media.id,
				imageName: media.name,
				imageUrl: media.contentUrl
			};
		});
	}, [ coco ]);

	return {
		detect,
		isReady: !!coco
	};
}