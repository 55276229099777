import { gql } from "@apollo/client";


export const PricingPolicy_PricingFragment = gql(/* GraphQL */ `
	fragment PricingPolicy_PricingFragment on PricingPolicy {
		pricing {
			__typename
			... on ItemTypePricing {
				pickupFee
				disassemblyFee
				canDisassemble
				type {
					id
					name
				}
			}
			... on ItemSizePricing {
				pickupFee
				disassemblyFee
				canDisassemble
				size {
					id
					name
				}
			}
		}
	}
`);
