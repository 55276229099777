import { gql } from "../../../__generated__";

export const FetchWasteContainer = gql(`
	query FetchWasteContainer(
		$containerId: String!
	) {
		FetchWasteContainer(
			containerId: $containerId
		) {
			...WasteContainer_HeaderFragment
		}
	}
`);