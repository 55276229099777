import { Box } from "grommet";
import { ViewContainer } from "../../../components";
import { ReactNode } from "react";

export const ReferViewContainer: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	return (
		<ViewContainer>
			<Box height="100%" align="center">
				{children}
			</Box>
		</ViewContainer>
	);
};