import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Armoire: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m920 1080h-600v80h-40v-80h-80v-1e3h840v1e3h-80v80h-40zm-680-960h360v920h-360zm400 0h360v920h-360zm40 400h40v120h-40zm-160 0h40v120h-40z" fill-rule="evenodd"/>
</svg>
			</SvgIcon>
		);
	};