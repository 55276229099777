import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCheckoutState } from "../../../store/checkout";
import { fileToBase64 } from "../../../helpers";
import { ProductMediaContext } from "../../../graphql/__generated__/graphql";


export function useCheckoutSession() {
	const state = useAppSelector(selectCheckoutState);

	return {
		...state
	};
}


interface UploadMediaPayload {
	file: File;
	productId: string;
	isPrimary?: boolean;
	context: ProductMediaContext;
}

export function useCheckoutSessionMutations() {
	const dispatch = useAppDispatch();
	const session = useCheckoutSession();

	const uploadMediaAction = useCallback(
		(data: UploadMediaPayload) => {
			return fileToBase64(data.file).then(content => {
				return {
					id: "",
					name: data.file.name,
					content,
					isPrimary: data.isPrimary || false,
					contentType: data.file.type,
					contentUrl: URL.createObjectURL(data.file),
					context: data.context,
					productId: data.productId
				};
			});
		},
		[ dispatch ]
	);

	return {
		uploadMedia: uploadMediaAction
	};
}