import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { useProductRelationships } from "../../../hooks/useProductRelationships";


export const ProductRelationshipButton: React.FC<{
	label: string;
	productId: string;
	resolvePath(relationships: ReturnType<typeof useProductRelationships>): string | null;
}> = ({ productId, resolvePath, label }) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const relationships = useProductRelationships(productId);

	function handleClick(): void {
		const path = resolvePath(relationships);
		if(path) {
			dispatch(push(path));
		}
		else {
			snack.enqueueSnackbar("Could not find relationship", { variant: "error" });
		}
	}

	return (
		<LoadingButton
			size="small"
			loading={relationships.loading}
			color="primary"
			variant="contained"
			onClick={handleClick}
		>
			{label}
		</LoadingButton>
	);
};
