import { Box } from "grommet";
import React, { useMemo } from "react";
import { CheckoutStepProps } from "../types";
import { CheckoutViewContainer } from "../components";
import { CheckoutTitleContainer } from "../components";
import { StepController, useSteps } from "../../../components";
import { ItemSelectionStep, SearchSugesstions } from "../steps/ItemSelectionStep";
import { ItemReviewContainer } from "../steps/ItemReviewStep";
import { ItemDetailsDialog } from "../components/ItemDetailsDialog";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCheckoutState, selectProductSearchTerm, setProductSearchTerm } from "../../../store/checkout";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useWindowDimensions } from "../../../hooks";
import { Close } from "@mui/icons-material";
import { LoginInteruptController } from "../controller";

export const CheckoutItemsView: React.FC<CheckoutStepProps> = (props) => {
	const state = useAppSelector(selectCheckoutState);
	const [ itemDetailsDialogOpen, setItemDetailsDialogOpen ] = React.useState(false);

	const { next: nextParent, prev: prevParent } = useSteps("checkout");
	const { hasNext, hasPrev, next, prev, current } = useSteps("checkout-items");

	const canProceed = useMemo(() => {
		switch(current) {
			case 0: {
				return state.products.length > 0;
			}
			case 1: {
				return state.products.every(p => p.reviewed);
			}
			default: {
				return false;
			}
		}
	}, [ state, current ]);

	const containerSubheading = useMemo(() => {
		return (hasNext)
			? "Select the items you would like picked up."
			: "Confirm your item(s) details and upload image(s).";
	}, [ hasNext, hasPrev ]);

	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const searchTerm = useAppSelector(selectProductSearchTerm);

	return (
		<LoginInteruptController loginRequired={props.loginRequired}>
			<Box flex id="checkout-items-view">
				<CheckoutViewContainer>
					{itemDetailsDialogOpen && (
						<ItemDetailsDialog
							onClose={() => {
								setItemDetailsDialogOpen(false);
							}}
							onSubmit={() => {
								setItemDetailsDialogOpen(false);
							}}
							state={{}}
						/>
					)}
					<CheckoutTitleContainer
						heading="Your Items"
						subheading={containerSubheading}
						sideContent={size !== "small"
							&& hasNext
							&& (
								<Box flex justify="center">
									<FormContainer>
										<TextFieldElement
											name="search"
											label="Search"
											id="search-element"
											InputProps={{
												value: searchTerm,
												onChange: (event) => {
													dispatch(setProductSearchTerm(event.target.value));
												},
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={() => dispatch(setProductSearchTerm(""))}>
															<Close />
														</IconButton>
													</InputAdornment>
												)
											}}
											fullWidth
										/>
									</FormContainer>
									<SearchSugesstions anchor={document.getElementById("search-element")} />
								</Box>
							)}
					/>
					<StepController
						suppressProgress
						name="checkout-items"
					>
						<ItemSelectionStep

						/>
						<ItemReviewContainer

						/>
					</StepController>
				</CheckoutViewContainer>
				<Box direction="row" justify="between" style={{ minHeight: "40px" }} margin={{ top: "small", horizontal: "medium" }}>
					<Button
						variant="outlined"
						color="primary"
						disabled={!hasPrev}
						onClick={prev}
					>
						Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={!canProceed}
						onClick={hasNext ? next : nextParent}
					>
						Continue
					</Button>
				</Box>
			</Box>
		</LoginInteruptController>
	);
};