import { InstanceType } from "../../../graphql/__generated__/graphql";
import { Fragment, useMemo } from "react";
import { DonationCenterReferView, ManagementCompanyReferView, ResidenceReferView } from "../views";
import { selectInstancePublic, useAppSelector } from "../../../store";
import { UniversityReferView } from "../views/UniversityRefer";

export const ReferController: React.FC = () => {
	const instance = useAppSelector(selectInstancePublic);

	const component = useMemo(() => {
		switch(instance?.type) {
			case InstanceType.DonationCenter: {
				return <DonationCenterReferView />;
			}
			case InstanceType.Residence: {
				return <ResidenceReferView />;
			}
			case InstanceType.University: {
				return <UniversityReferView />;
			}
			case InstanceType.ManagementCompany: {
				return <ManagementCompanyReferView />;
			}
			default: {
				return null;
			}
		}
	}, [ instance ]);

	return (
		<Fragment>
			{component}
		</Fragment>
	);
};