import { Box } from "grommet";
import { ViewContainer } from "../../../../components";
import { HeadingWithLoading } from "../../../hauler/views";

export const ConfigurationView: React.FC = () => {
	return (
		<ViewContainer>
			<Box>
				<HeadingWithLoading loading={false}>
					Configuration
				</HeadingWithLoading>
			</Box>
		</ViewContainer>
	);
};