import React, { Fragment } from "react";
import { List } from "@mui/material";
import { DialogWithClose } from "../../../components";
import { useBusinessType } from "../hooks";
import { BusinessTypeListItem } from "./BusinessTypeListItem";
import { BusinessType } from "../../../graphql/__generated__/graphql";

export const BusinessTypeDialog: React.FC<{
	open: boolean;
	onClose: () => void;
	onSelect: (type: BusinessType) => void;
}> = ({ open, onClose, onSelect }) => {
	const { setBusinessType, types } = useBusinessType();

	return (
		<Fragment>
			{open && (
				<DialogWithClose
					onClose={onClose}
					title="Select Business Type"
					content={(
						<List>
							{types.map((type) => (
								<BusinessTypeListItem
									key={type.id}
									definition={type}
									onClick={() => {
										setBusinessType(type.id);
										onSelect(type.id);
										onClose();
									}}
								/>
							))}
						</List>
					)}
				/>
			)}
		</Fragment>
	);
};