import React, { ReactNode } from "react";
import "../css/loader.css";
import { Box, Image, Spinner } from "grommet";
import { useAppSelector } from "../store";
import { selectTheme } from "../store/theme";
import { Dialog, DialogContent } from "@mui/material";

interface LoaderProps {
	visible: boolean;
	children: React.ReactNode;
}

const Loader: React.FC<LoaderProps> = (props) => {
	const branding = useAppSelector(selectTheme);

	if(props.visible) {
		return (
			<Box pad="large" margin="medium" align="center" justify="center">
				<img src={branding.logoUrl} className="loader" alt="Loading"></img>
				<div className="splash-screen">
					<div className="loading-dot">.</div>
				</div>
			</Box>
		);
	}

	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	);
};

export const SplashScreen: React.FC<{ visible: boolean; children: ReactNode; delayRender?: boolean; }> = (props) => {
	const theme = useAppSelector(selectTheme);

	return (
		<React.Fragment>
			<Dialog open={props.visible} fullScreen fullWidth maxWidth="xl" key="dialog">
				<DialogContent sx={{ display: "flex" }}>
					<Box align="center" justify="center" flex gap="medium">
						<Box round pad="large" background={theme.headerColor}>
							<Box width="small">
								<Image src={theme.logoUrl} />
							</Box>
						</Box>
						<Spinner size="large" color={theme.primaryColor} />
					</Box>
				</DialogContent>
			</Dialog>
			{props.visible && props.delayRender
				? (
					<React.Fragment key="stub" />
				)
				: (
					<React.Fragment key="children">
						{props.children}
					</React.Fragment>
				)
			}
		</React.Fragment>
	);
};