import { ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { useWindowDimensions } from "../../../../../hooks";
import { Box, Spinner } from "grommet";
import { Icon } from "@liverego/icons";
import { WrapSkeleton } from "../../../../../components";
import { volumeUnitEnumToFriendly } from "../../helpers";
import { WasteContainerDetailsDialog } from "./WasteContainerAssignmentDialog";
import { useWasteContainerAssignment } from "../../hooks";

export const WasteContainerAssignmentListItem: React.FC<{
	buildingId: string;
	assignmentId: string;
}> = ({ assignmentId }) => {
	const theme = useTheme();
	const { size } = useWindowDimensions();

	const [ isDialogOpen, setIsDialogOpen ] = React.useState(false);
	const { assignment, loading } = useWasteContainerAssignment(assignmentId);

	return (
		<Fragment>
			{isDialogOpen && (
				<WasteContainerDetailsDialog
					onClose={() => setIsDialogOpen(false)}
					assignmentId={assignmentId}
				/>
			)}
			<ListItemButton
				divider
				onClick={() => setIsDialogOpen(true)}
			>
				<ListItemIcon>
					{loading
						? (
							<Spinner />
						)
						: (
							<Icon
								url={assignment?.container.iconUrl || ""}
								fill={theme.palette.primary.main}
							/>
						)}
				</ListItemIcon>
				<ListItemText>
					<WrapSkeleton isLoading={loading}>
						<Typography fontWeight="bold">
							{assignment?.name}
						</Typography>
					</WrapSkeleton>
					<WrapSkeleton isLoading={loading}>
						<Typography>
							{assignment?.location}
						</Typography>
					</WrapSkeleton>
				</ListItemText>
				{size !== "small" && (
					<ListItemSecondaryAction>
						<Box gap="xsmall" direction="row">
							<Typography fontWeight="bold">
								{assignment?.volume}
							</Typography>
							<Typography fontWeight="bold">
								{assignment?.volumeUnit && volumeUnitEnumToFriendly(assignment?.volumeUnit)}(s)
							</Typography>
						</Box>
					</ListItemSecondaryAction>
				)}
			</ListItemButton>
		</Fragment>
	);
};