import { Typography } from "@mui/material";
import { Product_InventoryFragmentFragment } from "../../graphql/__generated__/graphql";
import { FragmentType } from "../../graphql";
import { Box } from "grommet";
import { useMemo } from "react";

export const ProductListItemQuantity: React.FC<{
	loading?: boolean;
	product?: FragmentType<Product_InventoryFragmentFragment>;
}> = ({ product }) => {

	const paddedQuantity = useMemo(() => {
		if(!product) return "";

		const quantity = Number(product.inventory?.quantity || 1);

		if(quantity < 10) {
			return `0${quantity}`;
		}

		return quantity.toString();
	}, [ product ]);

	if(!product) return null;

	return (
		<Box justify="center">
			<Typography fontWeight="bold">
				(x{paddedQuantity})
			</Typography>
		</Box>
	);
};

