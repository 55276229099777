import { gql } from "../../__generated__";

export const UpdateOrderPreferredDate = gql(/* GraphQL */`
	mutation UpdateOrderPreferredDate(
		$orderId: String!
		$date: String!
	) {
		UpdateOrderPreferredDate(
			orderId: $orderId
			date: $date
		) {
			id
		}
	}
`);

export const UpdateOrderPreferredWindow = gql(/* GraphQL */`
	mutation UpdateOrderPreferredWindow(
		$orderId: String!
		$window: PickupWindowInput!
	) {
		UpdateOrderPreferredWindow(
			orderId: $orderId
			window: $window
		) {
			id
		}
	}
`);

export const UpdateOrderPaymentMethod = gql(/* GraphQL */`
	mutation UpdateOrderPaymentMethod(
		$orderId: String!
		$paymentMethodId: String!
	) {
		UpdateOrderPaymentMethod(
			orderId: $orderId
			paymentMethodId: $paymentMethodId
		) {
			id
		}
	}
`);

export const FetchUserOrder = gql(/* GraphQL */`
	query FetchUserOrder($orderId: String!) {
		FetchOrder(orderId: $orderId) {
			...Order_HeaderFragment
			id
			createdAt
			updatedAt
			submittedAt
			completedAt
			scheduled
			schedulePending
			products {
				...Product_HeaderFragment
				...Product_ClassificationFragment
				outlet { id name __typename }
			}
			pickup {
				id
				scheduledDate
				assignedTo {
					id
					name
				}
			}
			acceptedEstimate {
				id
				totalAmount
			}
			cancellationDetails {
				isCancellable
				isFreeCancellation
				minimumFreeCancellationHours
				minimumPartialCancellationHours
				maximumCancellationFee
				maximumCancellationFeePercentage
				currentCancellationFee
				currentCancellationFeePercentage
				freeCancellationBy
				partialCancellationFee
				partialCancellationFeePercentage
			}
		}
	}
`);

export const ScheduleOrder = gql(/* GraphQL */`
	mutation ScheduleOrder(
		$orderId: String!
		$estimateId: String!
		$selectedDate: DateTime!
		$selectedWindow: PickupWindowInput!
	) {
		ScheduleOrder(
			orderId: $orderId
			estimateId: $estimateId
			selectedDate: $selectedDate
			selectedWindow:	$selectedWindow
		) {
			id
		}
	}
`);

export const ScheduleOrderWithPickup = gql(/* GraphQL */`
	mutation ScheduleOrderWithPickup(
		$orderId: String!
		$pickupId: String!
		$estimateId: String!
	) {
		ScheduleOrderWithPickup(
			orderId: $orderId
			pickupId: $pickupId
			estimateId: $estimateId
		) {
			id
		}
	}
`);

export const FetchOrderForHauler = gql(/* GraphQL */`
	query FetchOrderForHauler(
		$orderId: String!
		$haulerId: String!
	) {
		FetchOrder(
			orderId: $orderId
		) {
			...Order_HeaderFragment
			submittedAt
			completedAt
			cancelledAt
			preferredDate
			products {
				...Product_HeaderFragment
				...Product_InventoryFragment
				...Product_ClassificationFragment
				outlet {
					...OutletFragment
				}
				media {
					id
					name
					context
					contentUrl
				}
			}
			market { id code name }
			user {
				...User_HeaderFragment
			}
			pickup { id scheduledDate scheduled completed}
			address {
				...AddressFragment
			}
			quotes(haulerId: $haulerId) {
				...Quote_HeaderFragment
				...Quote_AmountFragment
			}
		}
	}
`);

export const FindOrdersByReferer = gql(/* GraphQL */`
	query FindOrdersByReferer(
		$status: iStringFilters
		$refererId: String!
		$completedAt: iDateFilters
	) {
		FindOrdersByReferer(
			status: $status
			refererId: $refererId
			completedAt: $completedAt
		) {
			id
			user {
				...User_HeaderFragment
				resident(buildingId: $refererId) {
					id
					unit
					moveInDate
					moveOutDate
				}
			}
			status
			pickup {
				id
				scheduledDate
			}
			productCount
			startedAt
			cancelledAt
			submittedAt
			completedAt
		}
	}
`);