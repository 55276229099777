import { useQuery } from "@apollo/client";
import { FindWasteContainer } from "../../../../graphql";

export function useContainers() {
	const { data, loading } = useQuery(FindWasteContainer, {

	});

	return {
		loading,
		containers: data?.FindWasteContainer || []
	};
}