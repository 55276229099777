import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { WasteContainerVolumeUnit } from "../../../../../graphql/__generated__/graphql";
import { DialogWithClose, TabController } from "../../../../../components";
import { Box } from "grommet";
import { Button } from "@mui/material";
import { FormContainer } from "react-hook-form-mui";
import { ContainerDetailsTab, WeightReportingTab } from "./tabs";
import { WasteContainerState } from "../../types";
import { useWasteContainerAssignment } from "../../hooks";

export const WasteContainerDetailsDialog: React.FC<{
	onClose: () => void;
	assignmentId: string;
}> = ({ assignmentId, onClose }) => {
	const { assignment, loading } = useWasteContainerAssignment(assignmentId);

	const formContext = useForm<WasteContainerState>({

	});

	const { materialId } = formContext.watch();
	useEffect(() => {
		console.log(materialId);
	}, [ materialId ]);

	useEffect(() => {
		if(assignment) {
			formContext.reset({
				disposition: assignment?.disposition || "",
				materialId: assignment?.material?.id || "",
				volume: assignment?.volume || 0,
				volumeUnit: assignment?.volumeUnit || WasteContainerVolumeUnit.Cy,
				collectionFrequency: assignment?.collectionFrequency?.dayOfWeek || [],
			});
		}
	}, [ assignment, formContext ]);

	return (
		<DialogWithClose
			onClose={onClose}
			title="Waste Container Details"
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
				</Box>
			)}
			content={(
				<FormContainer formContext={formContext}>
					<TabController
						tabVariant="scrollable"
						defaultValue="details"
						tabs={[
							{
								value: "details",
								label: "Details",
								component: (
									<ContainerDetailsTab
										formContext={formContext}
										assignmentId={assignmentId}
									/>
								)
							},
							{
								value: "reporting",
								label: "Weight Reporting",
								component: (
									<WeightReportingTab
										formContext={formContext}
										assignmentId={assignmentId}
									/>
								)
							}
						]}
					/>
				</FormContainer>
			)}
		/>
	);
};