import React, { Fragment, useMemo, useState } from "react";
import { Box, Grid, Heading, Text } from "grommet";
import { CallUsButton, DialogWithClose, TextUsButton } from "../../../../components";
import { Button, InputAdornment, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { formatCurrency, parseDateFromUTC } from "../../../../helpers";
import { useTimezone } from "../../../../hooks/useTimezone";
import { FetchUserOrder, useUnifiedOrder } from "../../../../graphql";

export const CancelOrderDialog: React.FC<{ orderId: string; onClose(): void; }> = (props) => {
	const timezone = useTimezone();
	const { order, isCancelling, handleCancel: cancel } = useUnifiedOrder(props.orderId, [ "user" ]);

	const cancellationDetails = useMemo(() => {
		return order?.cancellationDetails;
	}, [ order ]);

	const freeCancellationDateFormatted = useMemo(() => {
		return parseDateFromUTC(cancellationDetails?.freeCancellationBy, timezone);
	}, [ cancellationDetails?.freeCancellationBy, timezone ]);

	const formContext = useForm({
		defaultValues: {
			freeCancellationDateFormatted,
			cancellationFee: cancellationDetails?.currentCancellationFee
		}
	});

	function handleCancel(): void {
		cancel(
			"User Requested",
			[ FetchUserOrder ]
		).then(() => {
			props.onClose();
		});
	}

	return (
		<DialogWithClose
			title="Cancel Order"
			onClose={props.onClose}
			content={(
				<Box>
					<FormContainer formContext={formContext}>
						<Box gap="medium">
							<Text margin="small">
								You may cancel for free any time before {freeCancellationDateFormatted}. After that, you will be charged a cancellation fee of {formatCurrency(cancellationDetails?.partialCancellationFee || 0)}. If you cancel within {cancellationDetails?.minimumPartialCancellationHours || 0} hours of your scheduled pickup, you will be charged the full amount of your order.
							</Text>
							<Grid columns={{ count: 2, size: "auto" }} gap="small">
								<TextFieldElement
									label="Cancellation Fee"
									name="cancellationFee"
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												$
											</InputAdornment>
										),
										value: (formContext.watch("cancellationFee") || 0).toFixed(2)
									}}
								/>
								<TextFieldElement
									label="Free Cancellation By"
									name="freeCancellationDateFormatted"
									InputProps={{
										readOnly: true
									}}
								/>
							</Grid>
						</Box>
					</FormContainer>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<LoadingButton variant="outlined" color="primary" onClick={props.onClose}>
						Don't Cancel
					</LoadingButton>
					<LoadingButton loading={isCancelling} variant="contained" color="error" onClick={handleCancel}>
						Cancel
					</LoadingButton>
				</Box>
			)}
		/>
	);
};

interface CancelOrderButtonProps {
	orderId: string;
	isCancellable: boolean;
	cancellationDialog?: React.FC<{ orderId: string, onClose(): void; }>;
}

export const CancelOrderButton: React.FC<CancelOrderButtonProps> = (props) => {
	const [ isCancelling, setIsCancelling ] = useState(false);

	return (
		<Fragment>
			{(isCancelling && !!props.cancellationDialog) && (
				<props.cancellationDialog
					orderId={props.orderId}
					onClose={() => setIsCancelling(false)}
				/>
			)}
			{(isCancelling && !props.cancellationDialog) && (
				<CancelOrderDialog
					orderId={props.orderId}
					onClose={() => setIsCancelling(false)}
				/>
			)}
			<Button
				size="small"
				disabled={!props.isCancellable}
				color="error"
				variant="contained"
				onClick={() => setIsCancelling(true)}
			>
				Cancel
			</Button>
		</Fragment>
	);
};

export const CancelOrderConfirmationDialog: React.FC<{ orderId: string; onClose(): void; }> = (props) => {
	const { isCancelling, handleCancel: cancel } = useUnifiedOrder(props.orderId, [ "user" ]);

	function handleCancel(): void {
		cancel(
			"User Requested",
			[ FetchUserOrder ]
		).then(() => {
			props.onClose();
		});
	}

	return (
		<DialogWithClose
			title="Cancel Order"
			onClose={props.onClose}
			content={(
				<Box gap="medium">
					<Typography margin="small" fontSize="large">
						Are you sure you want to cancel this order? You will not be charged a cancellation fee.
					</Typography>
					<Box align="center">
						<Heading level="4" margin="none" textAlign="center">
							Need some help instead?
						</Heading>
					</Box>
					<Box gap="small" margin={{ horizontal: "large" }}>
						<TextUsButton />
						<CallUsButton />
					</Box>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<LoadingButton variant="outlined" color="primary" onClick={props.onClose}>
						Don't Cancel
					</LoadingButton>
					<LoadingButton
						loading={isCancelling}
						variant="contained"
						color="error"
						onClick={handleCancel}
					>
						Cancel
					</LoadingButton>
				</Box>
			)}
		/>
	);
};