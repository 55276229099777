import { SvgIcon, SvgIconProps } from "@mui/material";

	export const SofaLoveseatAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1098 396.61h-66v-51.703c0-39.703-32.297-72-72-72h-300c-25.078 0-47.062 12.844-60 32.297-12.938-19.453-34.922-32.297-60-32.297h-300c-39.703 0-72 32.297-72 72v51.703h-66c-13.219 0-24 10.781-24 24v396c0 13.219 10.781 24 24 24h66.469v50.625c0 19.781 16.219 36 36 36h42c19.781 0 36-16.219 36-36v-50.625h633.24v50.391c0 19.781 16.219 36 36 36h42c19.781 0 36-16.219 36-36v-50.391h68.297c13.219 0 24-10.781 24-24v-396c0-13.219-10.781-24-24-24zm-839.53 494.53c0 6.6094-5.3906 12-12 12h-42c-6.6094 0-12-5.3906-12-12v-50.156h66zm747.24-0.14062c0 6.6094-5.3906 12-12 12h-42c-6.6094 0-12-5.3906-12-12v-50.156h66zm-393.71-546.14c0-26.531 21.469-48 48-48h300c26.531 0 48 21.469 48 48v250.78c-12.844-11.391-29.625-18.375-48-18.375h-300c-18.469 0-35.297 7.0781-48 18.469v-250.92zm0.14062 303.61h0.375s0-0.9375-0.14062-1.6875c1.3125-25.312 22.078-45.469 47.766-45.469h300c25.688 0 46.453 20.297 47.766 45.609 0 0.46875-0.14062 1.0781-0.14062 1.5469h0.375v0.84375c0 26.531-21.469 48-48 48h-300c-26.531 0-48-21.469-48-48zm-12.141 40.453c1.9219 2.8594 4.2188 5.625 6.4688 8.2969h-12.938c2.3906-2.625 4.5469-5.3906 6.4688-8.2969zm-408-292.31v-51.703c0-26.531 21.469-48 48-48h300c26.531 0 48 21.469 48 48v250.78c-12.844-11.391-29.625-18.375-48-18.375h-300c-18.469 0-35.297 7.0781-48 18.469v-199.22zm0.14062 251.86h0.375s0-0.9375-0.14062-1.6875c1.3125-25.312 22.078-45.469 47.766-45.469h300c25.688 0 46.453 20.297 47.766 45.609 0 0.46875-0.14062 1.0781-0.14062 1.5469h0.375v0.84375c0 26.531-21.469 48-48 48h-300c-26.531 0-48-21.469-48-48zm905.86 168.14h-996v-396h66v228.75c0 39.703 32.297 72 72 72h720c39.703 0 72-32.297 72-72 0-0.9375 0-1.7812-0.14062-2.7656 0-0.60938 0.14062-1.0781 0.14062-1.6875v-224.29h66z"/>
</svg>
			</SvgIcon>
		);
	};