import { Box } from "grommet";
import React from "react";
import { IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, Typography } from "@mui/material";
import { NavigateNext, Numbers } from "@mui/icons-material";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";

export const TotalDonorsCard: React.FC<{
	value: number;
}> = ({ value }) => {
	const dispatch = useAppDispatch();
	return (
		<ListItemButton
			style={{
				borderRadius: "8px"
			}}
			selected
			onClick={() => {
				dispatch(push("/admin/orders"));
			}}
		>
			<ListItemIcon>
				<Numbers color="primary" />
			</ListItemIcon>
			<Box width="small">
				<Typography variant="h5" color="primary">
					{value}
				</Typography>
				<Typography fontWeight="bold">
					Total Donors
				</Typography>
			</Box>
			<ListItemSecondaryAction>
				<IconButton>
					<NavigateNext />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
