import React from "react";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { Step } from "../../../components";
import { CheckoutStepProps } from "../types";
import { useFeature } from "../../instance";
import { usePageTitle } from "../../../hooks";
import { CheckoutScheduleUpfrontView, CheckoutScheduleView } from "../views";
import { LoginInteruptController } from "./Address";

export const CheckoutScheduleController: React.FC<CheckoutStepProps> = (props) => {
	usePageTitle("Schedule Pickup");
	const isUpfrontSchedulingEnabled = useFeature(InstanceFeature.OrderUpfrontScheduleEnabled);

	return (
		<LoginInteruptController loginRequired={props.loginRequired}>
			{(isUpfrontSchedulingEnabled)
				? (
					<Step stepName={props.stepName}>
						<CheckoutScheduleUpfrontView />
					</Step>
				)
				: (
					<Step stepName={props.stepName}>
						<CheckoutScheduleView />
					</Step>
				)}
		</LoginInteruptController>
	);
};