import React from "react";
import { Stop_HeaderFragmentFragment } from "../../../../graphql/__generated__/graphql";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import { CompleteStop, FetchHaulerPickup, FetchStop, FetchStopDetailed } from "../../../../graphql";


export const CompleteStopButton: React.FC<{
	stop: Stop_HeaderFragmentFragment;
}> = ({ stop }) => {
	const [
		complete, { loading }
	] = useMutation(CompleteStop, {
		refetchQueries: [ FetchHaulerPickup, FetchStopDetailed, FetchStop ]
	});

	function handleSubmit(): void {
		complete({
			variables: {
				stopId: stop.id
			}
		}).catch(err => {
			console.error(err);
		});
	}

	return (
		<LoadingButton
			color="primary"
			variant="contained"
			loading={loading}
			onClick={handleSubmit}
		>
			Complete Stop
		</LoadingButton>
	);
};
