import { Spinner, Text } from "grommet";
import { useMemo, useState } from "react";
import { Chip, IconButton } from "@mui/material";
import React from "react";
import { Cancel } from "@mui/icons-material";
import { PartnerRole } from "../../../../graphql/__generated__/graphql";
import { BasicListItem } from "../../../../components";

interface HaulerUserListItemProps {
	firstName: string;
	lastName: string;
	role: PartnerRole;
	primaryEmail?: string;
}

export const HaulerUserListItem: React.FC<HaulerUserListItemProps> = (props) => {
	const [ isRemoving, setIsRemoving ] = useState(false);

	const roleChip = useMemo(() => {
		switch(props.role) {
			case PartnerRole.PartnerAdmin: {
				return (
					<Chip
						label="Admin"
						color="primary" />
				);
			}
			case PartnerRole.PartnerStandard: {
				return (
					<Chip
						label="Standard"
						color="secondary" />
				);
			}
		}
	}, [ props.role ]);

	return (
		<BasicListItem
			hideIcon
			label={(
				<Text weight="bold">
					{props.firstName} {props.lastName}
				</Text>
			)}
			value={props.primaryEmail}
			chip={roleChip}
			action={(
				<IconButton>
					{isRemoving ? (
						<Spinner />
					) : (
						<Cancel color="error" />
					)}
				</IconButton>
			)}
		/>
	);
};
