import { gql } from "../../../__generated__";

export const UpdateOrderMilestone = gql(/* GraphQL */`
	mutation UpdateOrderMilestone(
		$orderId: String!
		$milestone: OrderMilestone!
	) {
		UpdateOrderMilestone(
			orderId: $orderId
			milestone: $milestone
		) {
			id
			milestone
		}
	}
`);