import React from "react";
import { Anchor, Box, Heading } from "grommet";
import { DialogWithClose, ViewContainer } from "../../../../components";
import { PageNotAvailableView } from "../../../../views/NotAvailable";
import { useStorePartner } from "../../hooks/useStorePartner";
import { useStorePermissions } from "../../hooks";
import { StoreSelectorComponent } from "../../components";
import { Button, InputAdornment, List, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import { Fragment, createRef, useEffect, useMemo, useState } from "react";
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import { useClassification, useWindowDimensions } from "../../../../hooks";
import { AutocompleteElement, FormContainer, RadioButtonGroup, TextFieldElement, TextareaAutosizeElement, useForm } from "react-hook-form-mui";
import { labeledConditions } from "../../../product/hooks/useConditions";
import { useBrands } from "../../../product/hooks/useBrands";
import { ListingType, ListingVisibility, ProductCondition, ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { fileToBase64, formatCurrency } from "../../../../helpers";
import { ImagePreviewModal } from "@components/product/ImagePreviewModal";
import { FileUpload } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { CreateStoreProduct } from "../../../../graphql";

export const StoreProductsView: React.FC = () => {
	const { size } = useWindowDimensions();
	const { isEnabled } = useStorePermissions();
	const { products, selectedPartner } = useStorePartner();

	const [ value, setValue ] = useState("1");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const [ isProductDialogOpen, setIsProductDialogOpen ] = useState<boolean | string>("");

	if(!isEnabled) {
		return (<PageNotAvailableView />);
	}

	return (
		<ViewContainer>
			{(isProductDialogOpen && selectedPartner) && (
				<UpsertStoreProductDialog
					storeId={selectedPartner.id}
					onClose={() => setIsProductDialogOpen(false)}
				// product={isProductDialogOpen === "create" ? undefined : products.find((product) => product.id === isProductDialogOpen)}
				/>
			)}
			<Box gap="medium" flex>
				<Box direction="row" justify="between">
					<Box>
						<Heading margin="none" level="3">
							Store Products
						</Heading>
					</Box>
					<Box>
						<Button
							size="small"
							color="secondary"
							variant="contained"
							onClick={() => setIsProductDialogOpen("create")}
						>
							Add Product
						</Button>
					</Box>
				</Box>
				<Box>
					<Box align={size === "small" ? "center" : undefined}>
						<TabContext value={value}>
							<TabList onChange={handleChange}>
								<Tab value="1" label="For Sale (5)" />
								<Tab value="2" label="Hidden" />
								<Tab value="3" label="Out of Stock" />
							</TabList>
						</TabContext>
					</Box>
				</Box>
				<Box>
					<List>
						{products.map((product) => (
							<ListItemButton key={product.id}>
								<ListItemText
									primary={product.listing?.title}
								/>
							</ListItemButton>
						))}
					</List>
				</Box>
				<StoreSelectorComponent />
			</Box>
		</ViewContainer>
	);
};

interface CreateStoreProductDTO {
	title: string;
	description: string;
	typeId: string;
	sizeId?: string;
	price: number;
	priceCompare?: number;
	brand?: { id: string; label: string; };
	brandOther?: string;
	condition?: ProductCondition;
	media?: {
		name: string;
		description: string;
		isPrimary: boolean;
		context: ProductMediaContext;
		content: string;
		contentType: string;
		contentUrl: string;
	}[];
	quantity: number;
}

export function useStoreProductUpsert(storeId: string) {
	const [
		createStoreProduct,
		{ loading: isCreating }
	] = useMutation(CreateStoreProduct);

	function handleCreateProduct(
		seller: { id: string; allowsDelivery: boolean; allowsPickup: boolean; },
		product: CreateStoreProductDTO
	) {
		createStoreProduct({
			variables: {
				storeId,
				quantity: product.quantity,
				quantityDisassembly: 0,
				typeId: product.typeId,
				sizeId: product.sizeId,
				media: product.media?.map(m => ({
					name: m.name,
					description: m.description,
					isPrimary: m.isPrimary,
					context: m.context,
					content: m.content,
					contentType: m.contentType
				})) || [],
				listing: {
					title: product.title,
					description: product.description,
					price: product.price,
					priceCompare: product.priceCompare || 0,
					allowDelivery: seller.allowsDelivery,
					allowPickup: seller.allowsPickup,
					deliveryFee: 0,
					type: ListingType.Sale,
					visibility: ListingVisibility.Public
				}
			}
		});
	}

	return {
		isCreating,
		create: handleCreateProduct
	};
}

export const UpsertStoreProductDialog: React.FC<{
	onClose(): void;
	storeId: string;
	product?: CreateStoreProductDTO;
}> = (props) => {
	const brands = useBrands();
	const { types } = useClassification();
	const { selectedPartner } = useStorePartner();

	const formContext = useForm({
		defaultValues: {
			title: props.product?.title,
			description: props.product?.description,
			type: types.find(t => t.id === props.product?.typeId),
			size: types.find(t => t.id === props.product?.sizeId)?.sizes?.find(s => s.id === props.product?.sizeId),
			quantity: props.product?.quantity,
			price: props.product?.price,
			priceCompare: props.product?.priceCompare,
			brand: props.product?.brand?.label || props.product?.brandOther || "",
			condition: labeledConditions.find(c => c.id === props.product?.condition),
			media: props.product?.media || [],
			primaryMedia: props.product?.media?.find(m => m.isPrimary)?.name
		}
	});

	const { create, isCreating } = useStoreProductUpsert(props.storeId);
	const formValues = formContext.watch();

	useEffect(() => {
		if(formValues.type) {
			formContext.setValue("size", undefined);
		}
	}, [ formContext, formValues.type ]);

	const sizeOptions = useMemo(() => {
		return types.find(t => t.id === formValues.type?.id)?.sizes ?? [];
	}, [ formValues.type, types ]);

	function handleSubmit() {
		if(!selectedPartner?.seller) return;
		if(!formValues.type) return;
		create(
			selectedPartner.seller,
			{
				title: formValues.title || "",
				description: formValues.description || "",
				typeId: formValues.type?.id || "",
				sizeId: formValues.size?.id || "",
				price: Number(formValues.price),
				priceCompare: formValues.priceCompare ? Number(formValues.priceCompare) : undefined,
				brand: formValues.brand ? { id: "", label: formValues.brand } : undefined,
				condition: formValues.condition?.id,
				media: formValues.media.map(m => ({
					...m,
					isPrimary: m.name === formValues.primaryMedia
				})),
				quantity: Number(formValues.quantity)
			}
		);
	}

	function handleUploadMedia(
		context: ProductMediaContext,
		file: File
	): void {
		fileToBase64(file).then((base64) => {
			formContext.setValue("media", [
				...formValues.media,
				{
					isPrimary: false,
					context,
					description: "",
					name: file.name,
					content: base64,
					contentUrl: URL.createObjectURL(file),
					contentType: file.type,
				}
			]);
		});
	}

	const [ previewMedia, setPreviewMedia ] = useState<typeof formValues.media[ 0 ] | null>(null);

	const savings = useMemo(() => {
		const price = Number(formValues.price);
		const priceCompare = Number(formValues.priceCompare);
		return formatCurrency(priceCompare - price);
	}, [ formValues.price, formValues.priceCompare ]);

	const savingsPercentage = useMemo(() => {
		const price = Number(formValues.price);
		const priceCompare = Number(formValues.priceCompare);

		const percentChange = Math.abs((price - priceCompare) / priceCompare) * 100;
		return percentChange.toFixed(0) + "%";
	}, [ formValues.price, formValues.priceCompare ]);

	return (
		<DialogWithClose
			onClose={props.onClose}
			title={props.product ? "Update Product" : "Create Product"}
			content={(
				<FormContainer formContext={formContext}>
					<Box gap="medium">
						<Typography fontWeight="bold">
							Listing Details
						</Typography>
						<TextFieldElement
							required
							name="title"
							label="Listing Title"
							helperText="A brief title for your listing"
						/>
						<TextareaAutosizeElement
							rows={3}
							required
							name="description"
							label="Listing Description"
							helperText="A more detailed description of your listing"
						/>
						<Typography fontWeight="bold">
							Pricing Details
						</Typography>
						<TextFieldElement
							required
							name="price"
							label="Listing Price"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										$
									</InputAdornment>
								)
							}}
							validation={{
								validate: (value: unknown) => {
									if(isNaN(Number(value))) {
										return "Price must be a number";
									}
								}
							}}
						/>
						<TextFieldElement
							name="priceCompare"
							label="Compare / Retail Price"
							helperText={
								formValues.price && formValues.priceCompare
									? `You save ${savings} (${savingsPercentage})`
									: undefined
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										$
									</InputAdornment>
								)
							}}
							validation={{
								validate: (value: unknown) => {
									if(!value) return;
									if(isNaN(Number(value))) {
										return "Price must be a number";
									}
								}
							}}
						/>
						<Typography fontWeight="bold">
							Product Categorization
						</Typography>
						<AutocompleteElement
							label="Item Type"
							name="type"
							required
							options={types.map(t => ({ id: t.id, label: t.name, category: t.category, sizes: t.sizes ?? [] }))}
							autocompleteProps={{
								groupBy: (option) => {
									return option.category.name;
								},
								getOptionLabel: (option) => {
									return option.label ?? option.name;
								}
							}}
						/>
						{(formValues.type && (sizeOptions.length ?? 0) > 0) && (
							<AutocompleteElement
								label="Item Size"
								name="size"
								required
								options={sizeOptions.map((size) => ({ id: size.id, label: size.name }))}
								autocompleteProps={{
									getOptionLabel: (option) => option.label ?? option.name,
								}}
							/>
						)}
						{formValues.type?.category?.name && (
							<TextFieldElement
								name="category"
								label="Item Category"
								InputProps={{
									value: formValues.type?.category?.name,
									disabled: true
								}}
							/>
						)}
						<AutocompleteElement
							label="Item Condition"
							name="condition"
							required
							options={labeledConditions}
						/>
						<AutocompleteElement
							label="Item Brand"
							name="brand"
							options={brands.map(t => t.name)}
							textFieldProps={{
								helperText: "Optional. If you don't see your brand in the list, go ahead and enter your own."
							}}
							autocompleteProps={{
								freeSolo: true,
								onChange: (event, value) => {
									if(brands.find(t => t.name === value)) {
										formContext.setValue("brand", brands.find(t => t.name === value)?.name || "");
									}
									else {
										formContext.setValue("brand", value);
									}
								},
								groupBy: option => String(option).charAt(0).toUpperCase()
							}}
						/>
						<Typography fontWeight="bold">
							Inventory Details
						</Typography>
						<TextFieldElement
							required
							name="quantity"
							label="Quantity"
							type="number"
							inputMode="numeric"
							validation={{
								min: { value: 1, message: "Quantity cannot be less than 1" }
							}}
						/>
						<Typography fontWeight="bold">
							Item Images
						</Typography>
						<RadioButtonGroup
							label="Primary Image"
							name="primaryMedia"
							options={formValues.media.map(media => ({
								id: media.name,
								label: (
									<Anchor onClick={() => setPreviewMedia(media)}>
										{media.name}
									</Anchor>
								)
							}))}
							helperText="Select the primary image for your listing"
						/>
						<Box align="end">
							<MediaUploadButton
								isUploading={false}
								onFile={(file) => {
									handleUploadMedia(ProductMediaContext.Default, file);
								}}
							/>
						</Box>
						{previewMedia && (
							<ImagePreviewModal
								onClose={() => setPreviewMedia(null)}
								media={{
									name: previewMedia.name,
									contentUrl: previewMedia.contentUrl,
								}}
							/>
						)}
					</Box>
				</FormContainer>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
					>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={isCreating}
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Save
					</LoadingButton>
				</Box>
			)}
		/>
	);
};

export const MediaUploadButton: React.FC<{
	isUploading: boolean;
	onFile(file: File): void;
}> = (props) => {
	const ref = createRef<HTMLInputElement>();

	return (
		<Fragment>
			<LoadingButton
				sx={{ zIndex: 2 }}
				loading={props.isUploading}
				color="primary"
				variant="outlined"
				onClick={(event) => {
					event.stopPropagation();
					ref.current?.click();
				}}
				endIcon={<FileUpload />}
				loadingPosition="end"
			>
				Upload
			</LoadingButton>
			<input
				type="file"
				ref={ref}
				accept="image/*"
				style={{ display: "none" }}
				onClick={(event) => {
					event.stopPropagation();
				}}
				onChange={(event) => {
					if(event.target.files) {
						props.onFile(event.target.files[ 0 ]);
					}
				}}
			/>
		</Fragment>
	);
};