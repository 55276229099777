import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Bench: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m1098.2 594.14-42.711-22.434v-292.79c0-39.801-32.379-72.188-72.188-72.188s-72.188 32.387-72.188 72.188v9.6016h-622.19v-9.6016c0-39.801-32.387-72.188-72.188-72.188s-72.188 32.387-72.188 72.188v292.79l-42.711 22.434c-38.422 20.176-62.289 59.664-62.289 103.07v235.56c0 33.375 27.156 60.523 60.531 60.523h999.87c33.375 0 60.531-27.148 60.531-60.523l0.003906-235.57c0-43.402-23.875-82.891-62.289-103.05zm-138.74 95.359c0 11.129-9.0508 20.191-20.184 20.191h-678.51c-11.129 0-20.191-9.0586-20.191-20.191 0-11.129 9.0586-20.191 20.191-20.191h678.51c11.133-0.003907 20.184 9.0547 20.184 20.191zm-48.344-348.48v275.79h-622.19v-275.79zm-819.06 591.74v-235.56c0-23.828 13.102-45.504 34.191-56.578l56.762-29.82c8.6328-4.5312 14.047-13.477 14.047-23.234v-308.65c0-10.852 8.8359-19.688 19.688-19.688s19.688 8.8359 19.688 19.688v342.18c-28.133 10.043-48.352 36.871-48.352 68.414 0 31.547 20.227 58.371 48.352 68.414v182.87h-136.34c-4.4336 0-8.0352-3.6094-8.0352-8.0312zm196.88-170.57h622.18v178.6h-622.18zm819.06 170.57c0 4.4258-3.6016 8.0234-8.0312 8.0234h-136.34v-182.87c28.125-10.043 48.344-36.879 48.344-68.414 0-31.539-20.219-58.371-48.344-68.414v-342.17c0-10.852 8.8359-19.688 19.688-19.688s19.688 8.8359 19.688 19.688v308.66c0 9.75 5.4062 18.703 14.039 23.234l56.762 29.82c21.098 11.078 34.199 32.754 34.199 56.578z" />
		<path
			d="m563.42 791.44h-226.52c-10.359 0-18.75 8.3906-18.75 18.75v82.59c0 10.359 8.3906 18.75 18.75 18.75h226.51c10.359 0 18.75-8.3906 18.75-18.75v-82.59c0.007813-10.352-8.3906-18.75-18.742-18.75zm-18.75 82.59h-189.02v-45.09h189.01v45.09z" />
		<path
			d="m863.63 791.44h-226.51c-10.359 0-18.75 8.3906-18.75 18.75v82.59c0 10.359 8.3906 18.75 18.75 18.75h226.51c10.359 0 18.75-8.3906 18.75-18.75v-82.59c0-10.352-8.3945-18.75-18.75-18.75zm-18.75 82.59h-189.01v-45.09h189.01z" />
	</g>
</svg>
			</SvgIcon>
		);
	};