import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useLoginGuard } from "../../auth";
import { useInstance, usePageTitle } from "../../hooks";
import { InstanceType } from "../../graphql/__generated__/graphql";
import { GlobalAdminRouter } from "./global-admin";
import { DisposalCenterRouter } from "./disposal";
import { DonationCenterRouter } from "./donation";
import { ResidenceRouter } from "./residence";
import { SplashScreen } from "../../components";
import { setForceEnableWhitelabel, useAppDispatch } from "../../store";
import { RetailRouter } from "./retail";
import { ManagementCompanyRouter } from "./management-company";
import { EventRouter } from "./event";
import { RoleGuard } from "../../guards";
import { UniversityRouter } from "./university";
import { ReportingRouter } from "./reporting";

export const AdminRouter: React.FC = () => {
	useLoginGuard();
	usePageTitle("Admin Dashboard");
	const dispatch = useAppDispatch();
	const { instance, loading } = useInstance();

	useEffect(() => {
		switch(instance?.type) {
			case InstanceType.Admin: {
				return () => {
					// Do nothing
				};
			}
			default: {
				dispatch(setForceEnableWhitelabel(true));

				return () => {
					dispatch(setForceEnableWhitelabel(false));
				};
			}
		}
	}, [ instance, dispatch ]);

	const routeInstanceType = useCallback((type: InstanceType) => {
		switch(type) {
			case InstanceType.Admin: {
				return (
					<GlobalAdminRouter />
				);
			}
			case InstanceType.Event: {
				return (
					<EventRouter />
				);
			}
			case InstanceType.Retail: {
				return (
					<RetailRouter />
				);
			}
			case InstanceType.DisposalCenter: {
				return (
					<DisposalCenterRouter />
				);
			}
			case InstanceType.DonationCenter: {
				return (
					<DonationCenterRouter />
				);
			}
			case InstanceType.ManagementCompany: {
				return (
					<ManagementCompanyRouter />
				);
			}
			case InstanceType.Municipality: {
				return null;
			}
			case InstanceType.Residence: {
				return (
					<ResidenceRouter />
				);
			}
			case InstanceType.University: {
				return (
					<UniversityRouter />
				);
			}
			case InstanceType.Reporting: {
				return (
					<ReportingRouter />
				);
			}
			default: {
				return null;
			}
		}
	}, [ instance ]);

	const [ router, setRouter ] = useState<ReactNode | null>(null);
	useEffect(() => {
		if(instance) {
			setRouter(routeInstanceType(instance.type));
		}
	}, [ instance ]);

	return (
		<SplashScreen visible={loading || !instance}>
			<RoleGuard
				resolver={result => result.isInstanceManager || result.isInstanceAdmin || result.isGlobalAdmin || (instance?.type === InstanceType.Reporting && result.isInstanceMember)}
			>
				{router ? router : null}
			</RoleGuard>

		</SplashScreen>
	);
};