import { TextFieldElement } from "react-hook-form-mui";

export const LastNameElement: React.FC = () => {
	return (
		<TextFieldElement
			name="lastName"
			label="Last Name"
			validation={{
				required: "Please enter your last name."
			}}
		/>
	);
};