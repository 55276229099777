import { useQuery } from "@apollo/client";
import { FindAcceptingOutlets } from "../../graphql";

export function useAcceptingOutlets(productId: string) {
	const {
		data, loading
	} = useQuery(FindAcceptingOutlets, {
		skip: !productId,
		variables: { productId }
	});

	return {
		loading,
		outlets: (data?.FindAcceptingOutlets ?? []).map(outlet => ({
			id: outlet.id,
			name: outlet.name,
			disposition: outlet.disposition,
			label: outlet.name
		})).sort((a, b) => a.disposition.charCodeAt(0) - b.disposition.charCodeAt(0))
	};
}