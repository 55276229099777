import React from "react";
import { useResident } from "../../../auth";
import { Box } from "grommet";
import { List } from "@mui/material";
import { AddressDialog, BuildingAddressListItem, StepControls } from "../../../components";
import { useCheckoutSessionAddress } from "../hooks";
import { CheckoutViewContainer } from "../components/CheckoutViewContainer";
import { CheckoutTitleContainer } from "../components/CheckoutAddressTitleContainer";

export const CheckoutAddressResidentView: React.FC = () => {
	const { resident, building } = useResident();
	const [ isDialogOpen, setIsDialogOpen ] = React.useState(false);

	const {
		update,
		address,
	} = useCheckoutSessionAddress();

	return (
		<CheckoutViewContainer>
			<CheckoutTitleContainer
				heading="Pickup Address"
				subheading="Click the edit icon to update your pickup instructions and notes"
			/>
			{isDialogOpen && (
				<AddressDialog
					address={address}
					isCreating={false}
					isUpdating={false}
					onClose={() => {
						setIsDialogOpen(false);
					}}
					createAddress={() => {
						//
					}}
					updateAddress={(address) => {
						update(address);
						setIsDialogOpen(false);
					}}
					isResidenceAddress
				/>
			)}
			<Box>
				<List>
					<BuildingAddressListItem
						selected
						isLoading={false}
						addressId={address?.id || ""}
						unit={resident?.unit || ""}
						unitPrefix={building?.unitPrefix || ""}
						buildingName={building?.name || ""}
						onSelect={(() => {
							//
						})}
						onDelete={(() => {
							//
						})}
						onEdit={(() => {
							setIsDialogOpen(true);
						})}
					/>
				</List>
			</Box>
			<Box flex justify="end">
				<StepControls
					name="checkout"
					nextButtonLabel="Continue"
					canProceed={true}
					isLoading={false}
				/>
			</Box>
		</CheckoutViewContainer>
	);
};