import { Box } from "grommet";
import { ViewContainer } from "../../../../components";
import React from "react";
import { LocalizationProvider } from "../../../../provider";
import { Divider } from "@mui/material";
import "./helper.css";
import { DonationCenterActiveOrdersComponent } from "../components/order/DonationCenterActiveOrdersComponent";
import { DonationCenterReportingComponent } from "../components/reporting/DonationCenterReportingComponent";
import { useFullScreen } from "../../../../hooks";

export const DonationCenterDashboard: React.FC = () => {
	const fullScreen = useFullScreen();

	return (
		<LocalizationProvider>
			<ViewContainer>
				<Box direction={fullScreen ? "column" : "row"} gap="medium" flex style={{ display: fullScreen ? "block" : undefined }}>
					<DonationCenterActiveOrdersComponent

					/>
					<Box>
						<Divider orientation={fullScreen ? "horizontal" : "vertical"} />
					</Box>
					<DonationCenterReportingComponent

					/>
				</Box>
			</ViewContainer>
		</LocalizationProvider>
	);
};