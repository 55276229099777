import React, { ReactNode, useMemo } from "react";
import { Box } from "grommet";
import { useWindowDimensions } from "../../../hooks";

export const CheckoutViewContainer: React.FC<{ children: ReactNode; disableOverflow?: boolean; }> = ({ children, disableOverflow }) => {
	const sidebarWidth = 360;
	const { width } = useWindowDimensions();

	const shouldCenter = useMemo(() => {
		return (width - sidebarWidth) > 800;
	}, [ width ]);

	return (
		<Box
			margin="small"
			height="100%"
			id="checkout-view-container"
			align={shouldCenter ? "center" : undefined}
		>
			<Box
				gap="medium"
				height="100%"
				overflow={disableOverflow ? undefined : { vertical: "scroll" }}
				style={{ maxWidth: "800px", minWidth: shouldCenter ? "800px" : undefined }}
			>
				{children}
			</Box>
		</Box>
	);
};
