import { Route, Routes } from "react-router-dom";
import { HaulerLayout } from "./layout";
import { HaulerDashboard, HaulerQuotesView, HaulerSettings, HaulerPickupDetailsView, ActionItemsView } from "./views";
import { Redirect } from "../../components";
import { useLoginGuard } from "../../auth";
import { HaulerPickupsView } from "./views/HaulerPickupsView";
import { HaulerOrderDetailsView } from "./views/HaulerOrderDetails";
import { usePageTitle } from "../../hooks";
import { HaulerGuard } from "./guards";
import { StopDetailsView } from "./views/StopDetails";

export const HaulerRouter: React.FC = () => {
	usePageTitle("Hauler Dashboard");
	useLoginGuard();

	return (
		<HaulerGuard>
			<Routes>
				<Route path="/" element={< HaulerLayout />}>
					<Route path="" element={<Redirect to="/hauler/dashboard" />} />
					<Route path="/dashboard" element={<HaulerDashboard />} />
					<Route path="/pickups/*" element={<HaulerPickupsView />} />
					<Route path="/quotes/*" element={<HaulerQuotesView />} />
					<Route path="/pickups/:pickupId" element={<HaulerPickupDetailsView />} />
					<Route path="/settings" element={<HaulerSettings />} />
					<Route path="/actions" element={<ActionItemsView />} />
					<Route path="/stops/:stopId" element={<StopDetailsView />} />
					<Route path="/orders/:orderId" element={<HaulerOrderDetailsView />} />
				</Route>
			</Routes>
		</HaulerGuard>
	);
};