import { Box, Page, PageContent, Text } from "grommet";
import React, { useCallback, useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { AssignCheckoutSessionAddress, CreateCheckoutSessionAddress, FetchCheckoutSession, FetchSelf, UpdateAddress, UserAddressGql } from "../../../graphql";
import { LoadingButton } from "@mui/lab";
import { AddressDialog, CreateAddressDTO, StandardAddressListItem, StepControls, UpdateAddressDTO } from "../../../components";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { List } from "@mui/material";
import { useUser } from "../../../auth";
import { useSession } from "../../../hooks";
import moment from "moment-timezone";

export default function AddressView(props: { sessionId: string; }) {
	const snack = useSnackbar();
	const { user, addresses } = useUser();
	const { address } = useSession(props.sessionId);
	const [ selected, setSelected ] = useState<UserAddressGql | null>(null);
	const [ isAddressDialogOpen, setIsAddressDialogOpen ] = useState(false);

	const [
		createCheckoutSessionAddress,
		{ loading: isCreatingAddress, error: createAddressError },
	] = useMutation(CreateCheckoutSessionAddress, {
		refetchQueries: [ FetchCheckoutSession, FetchSelf ]
	});

	const [
		updateAddressMutation,
		{ loading: isUpdatingAddress, error: updateAddressError }
	] = useMutation(UpdateAddress, {
		refetchQueries: [ FetchSelf, FetchCheckoutSession ]
	});

	useEffect(() => {
		if(createAddressError) {
			snack.enqueueSnackbar(
				"We ran into an issue saving your address.",
				{ variant: "error" }
			);
		}
	}, [ snack, createAddressError ]);

	const createAddress = useCallback((address: CreateAddressDTO) => {
		return createCheckoutSessionAddress({
			variables: {
				userId: user?.id ?? "",
				sessionId: props.sessionId,
				addressLineOne: address.addressLineOne,
				addressLineTwo: address.addressLineTwo,
				city: address.city,
				state: address.state,
				zipCode: address.zipCode,
				instructions: address.instructions,
				instructionsParking: address.instructionsParking,
				floor: isNaN(address.floor) ? 1 : Number(address.floor),
				hasParking: address.hasParking,
				hasElevator: address.hasElevator,
				lat: address.lat,
				lng: address.lng,
				timezone: moment.tz.guess()
			}
		});
	}, [ createCheckoutSessionAddress, props.sessionId, user ]);

	const updateAddress = useCallback((address: UpdateAddressDTO) => {
		return updateAddressMutation({
			variables: {
				addressId: selected?.id ?? "",
				addressLineOne: address.addressLineOne,
				addressLineTwo: address.addressLineTwo,
				city: address.city,
				state: address.state,
				zipCode: address.zipCode,
				instructions: address.instructions,
				instructionsParking: address.instructionsParking,
				floor: isNaN(address.floor) ? 1 : Number(address.floor),
				hasParking: address.hasParking,
				hasElevator: address.hasElevator,
				lng: address.lng,
				lat: address.lat,
				timezone: moment.tz.guess()
			}
		});
	}, [ updateAddressMutation, selected ]);

	const [
		assignCheckoutSessionAddress,
		{ loading: isAssigningAddress, error: assigningAddressError }
	] = useMutation(AssignCheckoutSessionAddress, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	useEffect(() => {
		if(assigningAddressError) {
			snack.enqueueSnackbar(
				"We ran into an issue saving your address.",
				{ variant: "error" }
			);
		}
	}, [ snack, assigningAddressError ]);

	const assignAddress = useCallback((addressId: string) => {
		return assignCheckoutSessionAddress({
			variables: {
				sessionId: props.sessionId,
				addressId: addressId
			}
		});
	}, [ assignCheckoutSessionAddress, props.sessionId ]);

	const [ assigningAddressId, setAssigningAddressId ] = useState<string>("");

	useEffect(() => {
		if(assigningAddressId) {
			assignAddress(assigningAddressId).finally(() => {
				setAssigningAddressId("");
			});
		}
	}, [ assigningAddressId, assignAddress ]);

	return (
		<Page kind="narrow">
			<PageContent>
				<Box margin="small" gap="medium">
					{/* <Box id="map" height="medium">

					</Box> */}
					<Box gap="small">
						<Text size="large" weight="bold">Pickup Address</Text>
						<Text>Select an existing address or create a new one to continue.</Text>
					</Box>
					<List>
						{addresses.map((item) => (
							<StandardAddressListItem
								key={item.id}
								address={item}
								isLoading={isAssigningAddress && item.id === assigningAddressId}
								selected={address?.id === item.id}
								onEdit={() => {
									setSelected(item);
									setIsAddressDialogOpen(true);
								}}
								onSelect={() => {
									setAssigningAddressId(item.id);
								}}
								onDelete={() => { /* */ }}
							/>
						))}
					</List>
					<StepControls
						name="schedule"
						isLoading={isAssigningAddress || isCreatingAddress}
						nextButtonLabel="Continue"
						canProceed={!!address}
						additionalButtons={(
							<LoadingButton
								loading={isCreatingAddress}
								startIcon={<Add />}
								variant="outlined"
								color="primary"
								onClick={() => {
									setSelected(null);
									setIsAddressDialogOpen(true);
								}}
							>
								Add Address
							</LoadingButton>
						)}
					/>
				</Box>
				{isAddressDialogOpen && (
					<AddressDialog
						address={selected ?? undefined}
						onClose={() => setIsAddressDialogOpen(false)}
						isCreating={isCreatingAddress}
						isUpdating={isCreatingAddress}
						createAddress={(address) => {
							createAddress(address).finally(() => {
								setIsAddressDialogOpen(false);
							});
						}}
						updateAddress={(address) => {
							updateAddress(address).finally(() => {
								setIsAddressDialogOpen(false);
							});
						}}
					/>
				)}
			</PageContent>
		</Page>
	);
}
