import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Lighting: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m300 1143.8h600c24.855-0.027344 48.684-9.9141 66.258-27.492 17.578-17.574 27.465-41.402 27.492-66.258v-562.5h18.75c15.844 0 30.949-6.6797 41.609-18.398 10.66-11.723 15.887-27.395 14.391-43.164-1.4961-15.773-9.5742-30.184-22.25-39.688l-412.5-309.38c-9.7578-7.2578-21.59-11.176-33.75-11.176s-23.992 3.918-33.75 11.176l-412.5 309.38c-12.676 9.5039-20.754 23.914-22.25 39.688-1.4961 15.77 3.7305 31.441 14.391 43.164 10.66 11.719 25.766 18.398 41.609 18.398h18.75v562.5c0.027344 24.855 9.9141 48.684 27.492 66.258 17.574 17.578 41.402 27.465 66.258 27.492zm656.25-93.75c-0.015625 14.914-5.9492 29.211-16.492 39.758-10.547 10.543-24.844 16.477-39.758 16.492h-600c-14.914-0.015625-29.211-5.9492-39.758-16.492-10.543-10.547-16.477-24.844-16.492-39.758v-562.5h337.5v131.25h-18.75c-4.9727 0-9.7422 1.9766-13.258 5.4922-3.5156 3.5156-5.4922 8.2852-5.4922 13.258v87.688c-36.172 17.16-62.531 49.887-71.594 88.879-9.0625 38.992 0.16797 79.992 25.066 111.34 24.898 31.348 62.746 49.617 102.78 49.617s77.879-18.27 102.78-49.617c24.898-31.348 34.129-72.348 25.066-111.34-9.0625-38.992-35.422-71.719-71.594-88.879v-87.688c0-4.9727-1.9766-9.7422-5.4922-13.258-3.5156-3.5156-8.2852-5.4922-13.258-5.4922h-18.75v-131.25h337.5zm-356.25-300c24.863 0 48.711 9.8789 66.293 27.457 17.578 17.582 27.457 41.43 27.457 66.293s-9.8789 48.711-27.457 66.293c-17.582 17.578-41.43 27.457-66.293 27.457s-48.711-9.8789-66.293-27.457c-17.578-17.582-27.457-41.43-27.457-66.293 0.027344-24.855 9.9141-48.684 27.492-66.258 17.574-17.578 41.402-27.465 66.258-27.492zm0-37.5c-6.2734 0.007812-12.539 0.46094-18.75 1.3633v-57.613h37.5v57.613c-6.2109-0.90234-12.477-1.3555-18.75-1.3633zm-412.5-262.5c-5.2812 0-10.316-2.2266-13.871-6.1328-3.5508-3.9062-5.293-9.1328-4.7969-14.387 0.5-5.2578 3.1914-10.062 7.418-13.23l412.5-309.38c3.25-2.418 7.1953-3.7266 11.25-3.7266s8 1.3086 11.25 3.7266l412.5 309.38c4.2266 3.168 6.918 7.9727 7.418 13.23 0.49609 5.2539-1.2461 10.48-4.7969 14.387-3.5547 3.9062-8.5898 6.1328-13.871 6.1328z"/>
  <path d="m393.75 806.25h-37.5c-6.6992 0-12.887 3.5742-16.238 9.375-3.3477 5.8008-3.3477 12.949 0 18.75 3.3516 5.8008 9.5391 9.375 16.238 9.375h37.5c6.6992 0 12.887-3.5742 16.238-9.375 3.3477-5.8008 3.3477-12.949 0-18.75-3.3516-5.8008-9.5391-9.375-16.238-9.375z"/>
  <path d="m806.25 843.75h37.5c6.6992 0 12.887-3.5742 16.238-9.375 3.3477-5.8008 3.3477-12.949 0-18.75-3.3516-5.8008-9.5391-9.375-16.238-9.375h-37.5c-6.6992 0-12.887 3.5742-16.238 9.375-3.3477 5.8008-3.3477 12.949 0 18.75 3.3516 5.8008 9.5391 9.375 16.238 9.375z"/>
  <path d="m440.9 692.42c3.5078 3.5508 8.2891 5.5586 13.281 5.5742 4.9922 0.011718 9.7852-1.9648 13.316-5.4922 3.5273-3.5312 5.5039-8.3242 5.4883-13.316-0.015625-4.9922-2.0195-9.7734-5.5703-13.281l-26.516-26.527c-4.7383-4.7383-11.645-6.5898-18.117-4.8555s-11.531 6.793-13.266 13.266 0.11719 13.379 4.8555 18.117z"/>
  <path d="m759.1 957.58c-3.5078-3.5508-8.2891-5.5586-13.281-5.5742-4.9922-0.011718-9.7852 1.9648-13.316 5.4922-3.5273 3.5312-5.5039 8.3242-5.4883 13.316 0.015625 4.9922 2.0195 9.7734 5.5703 13.281l26.516 26.527c4.7383 4.7383 11.645 6.5898 18.117 4.8555s11.531-6.793 13.266-13.266-0.11719-13.379-4.8555-18.117z"/>
  <path d="m440.9 957.58-26.527 26.516c-4.7383 4.7383-6.5898 11.645-4.8555 18.117s6.793 11.531 13.266 13.266 13.379-0.11719 18.117-4.8555l26.516-26.516c3.5508-3.5078 5.5547-8.2891 5.5703-13.281s-1.9609-9.7852-5.4883-13.316c-3.5312-3.5273-8.3242-5.5039-13.316-5.4922-4.9922 0.015625-9.7734 2.0234-13.281 5.5742z"/>
  <path d="m745.84 697.91c4.9727 0.007812 9.7422-1.9688 13.258-5.4883l26.523-26.516c4.7383-4.7383 6.5898-11.645 4.8555-18.117s-6.793-11.531-13.266-13.266-13.379 0.11719-18.117 4.8555l-26.516 26.516c-3.5156 3.5156-5.4922 8.2852-5.4922 13.258 0 4.9727 1.9766 9.7422 5.4922 13.258 3.5195 3.5156 8.2891 5.4922 13.262 5.4922z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};