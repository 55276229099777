import { gql } from "../../../__generated__";

export const ConfirmOnboarding = gql(/* GraphQL */`
	mutation ConfirmOnboarding(
		$instanceId: String!
		$username: String!
		$firstName: String!
		$lastName: String!
		$code: String!
	) {
		ConfirmOnboarding(
			instanceId: $instanceId
			username: $username
			firstName: $firstName
			lastName: $lastName
			code: $code
		) {
			accessToken
			refreshToken
			expiresIn
		}
	}
`);

export const ConfirmBuildingOnboarding = gql(/* GraphQL */`
	mutation ConfirmBuildingOnboarding(
		$instanceId: String!
		$buildingId: String!
		$username: String!
		$firstName: String!
		$lastName: String!
		$moveInDate: DateTime
		$moveOutDate: DateTime
		$unit: String!
		$code: String!
	) {
		ConfirmBuildingOnboarding(
			instanceId: $instanceId
			buildingId: $buildingId
			username: $username
			firstName: $firstName
			lastName: $lastName
			moveInDate: $moveInDate
			moveOutDate: $moveOutDate
			unit: $unit, 
			code: $code
		) {
			accessToken
			refreshToken
			expiresIn
		}
	}
`);