import React, { Fragment, useMemo } from "react";
import { SidebarRouter, SidebarRouteItem, OrdersListItem, PurchasesListItem } from "../../../components";
import { isNotNull } from "../../../helpers";
import { OrderDetailsView, OrdersView, PurchasesView, ResidenceDashboardView } from "../views";
import { DashboardListItemWithTasks } from "../components";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { useFeature } from "../../instance/hooks/useFeature";

export const ResidenceRouter: React.FC = () => {
	return (
		<Fragment>
			{useResidenceRouter().toRouter()}
		</Fragment>
	);
};

export function useResidenceRouter() {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);

	return useMemo(() => {
		return new SidebarRouter(
			[
				new SidebarRouteItem(
					"/",
					<ResidenceDashboardView />,
					<DashboardListItemWithTasks
						key="dashboard"
					/>,
					true
				),
				new SidebarRouteItem(
					"/orders",
					<OrdersView />,
					<OrdersListItem
						key="orders"
						absolutePath="/dashboard/orders"
					/>,
					true
				),
				isSalesEnabled ? new SidebarRouteItem(
					"/purchases",
					<PurchasesView />,
					<PurchasesListItem
						key="purchases"
						absolutePath="/dashboard/purchases"
					/>,
					true
				) : null
			].filter(isNotNull),
			"Residence",
			[],
			[ {
				path: "/orders/:orderId",
				element: <OrderDetailsView />
			} ],
			true
		);
	}, []);
}