import { Box, Heading } from "grommet";
import { Pagination, ViewContainer } from "../../../components";
import { Button, LinearProgress, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useHauler, useHaulerTasks } from "../hooks";
import { useAppDispatch } from "../../../store";
import { push } from "redux-first-history";
import { TaskAlt } from "@mui/icons-material";
import { useFullScreen, useWindowDimensions } from "../../../hooks";

export const HeadingWithLoading: React.FC<{
	loading: boolean;
	children: React.ReactNode;
}> = ({ loading, children }) => (
	<Box>
		<Heading level="3" margin="none">
			{children}
		</Heading>
		{loading && (
			<LinearProgress />
		)}
	</Box>
);

export const ActionItemsView: React.FC = () => {
	const { hauler } = useHauler();
	const dispatch = useAppDispatch();
	const fullScreen = useFullScreen();
	const { sizeIndex } = useWindowDimensions();
	const { tasks, loading } = useHaulerTasks(hauler?.id ?? "");

	return (
		<ViewContainer>
			<Box>
				<HeadingWithLoading
					loading={loading}
				>
					Action Items
				</HeadingWithLoading>
			</Box>
			{tasks.length === 0 && (
				<Box align="center" justify="center">
					<Typography fontWeight="bold" fontSize="large">
						Nothing to do!
					</Typography>
				</Box>
			)}
			<Pagination
				pageSize={5}
			>
				{tasks.map(task => (
					<ListItemButton divider onClick={() => {
						dispatch(push(task.actionUrl));
					}}>
						<ListItemIcon>
							<TaskAlt />
						</ListItemIcon>
						<ListItemText>
							<Box style={{ maxWidth: "500px" }}>
								<Typography fontWeight="bold">
									{task.title}
								</Typography>
								{sizeIndex >= 3 && (
									<Typography variant="caption">
										{task.label}
									</Typography>
								)}
							</Box>
						</ListItemText>
						{(task.actionLabel && task.actionUrl && !fullScreen) && (
							<ListItemSecondaryAction>
								<Button
									size="small"
									onClick={() => {
										dispatch(push(task.actionUrl));
									}}
									color="primary"
									variant="contained"
								>
									{task.actionLabel}
								</Button>
							</ListItemSecondaryAction>
						)}
					</ListItemButton>
				))}
			</Pagination>
		</ViewContainer>
	);
};