import { List, ListSubheader } from "@mui/material";
import { useClassification } from "../../../hooks";
import React, { useMemo } from "react";
import { Box } from "grommet";
import { PricingPolicyCategoryListItem } from "./PricingPolicyCategoryListItem";

export const PricingPolicyCategoryContainer: React.FC<{
	policyId: string;
	searchFilter?: string;
}> = ({ searchFilter, policyId }) => {
	const { types } = useClassification();

	const categories = useMemo(() => {
		const mapped = types.map(t => t.category);
		return mapped.filter((category, index) => mapped.findIndex((c) => c?.id === category?.id) === index);
	}, [ types ]);

	return (
		<Box height="100%" overflow={{ vertical: "scroll" }}>
			<List subheader={
				<ListSubheader />
			}>
				{categories.map(category => (
					<PricingPolicyCategoryListItem
						key={category?.id ?? ""}
						policyId={policyId}
						categoryId={category?.id ?? ""}
						categoryName={category?.name ?? ""}
						searchFilter={searchFilter}
					/>
				))}
			</List>
		</Box>
	);
};
