import { Box } from "grommet";
import { Badge, Typography, useTheme } from "@mui/material";
import { CategoryLike } from "../types";
import { TypeSelectionDialog } from "./TypeSelectionDialog";
import { useQuantityCategory } from "../hooks";
import { Icon } from "@liverego/icons";
import { useMemo, useState } from "react";

export const CategoryListItem: React.FC<{
	category: CategoryLike;
}> = ({ category }) => {
	const theme = useTheme();
	const count = useQuantityCategory(category.id);

	const fillColor = useMemo(() => {
		if(theme.palette.primary.main === theme.palette.accent1.main) {
			return "white";
		}

		return theme.palette.primary.main;
	}, [ theme ]);

	const [ isHovering, setIsHovering ] = useState(false);

	return (
		<Box width="100%" gap="small">
			<Box align="center">
				<Box
					width="124px"
					align="center"
					justify="center"
					style={{ position: "relative" }}
				>
					<TypeSelectionDialog
						category={category}
						selectedTypes={[]}
						activatorButton={(
							<Box
								round
								pad="small"
								elevation="small"
								background="accent-2"
								align="center"
								justify="center"
								hoverIndicator
								onClick={(() => {
									//
								})}
								onMouseOver={() => {
									setIsHovering(true);
								}}
								onMouseLeave={() => {
									setIsHovering(false);
								}}
							>

								<Badge
									color="secondary"
									badgeContent={count}
									overlap="circular"
								>
									<Box
										flex
										align="center"
										justify="center"
									>
										<Icon
											url={category.iconUrl || "@liverego/icons/Other"}
											sx={{
												fontSize: "100px",
												fill: isHovering
													? theme.palette.primary.main
													: fillColor,
												maxWidth: "80%",
												maxHeight: "80%"
											}}
										/>
									</Box>
								</Badge>
							</Box>
						)}
					/>
				</Box>
			</Box>
			<Box align="center" width="100%">
				<Typography
					variant="caption"
					fontWeight="bold"
					textAlign="center"
				>
					{category.name}
				</Typography>
			</Box>
		</Box>
	);
};