import { gql } from "@apollo/client";

export const OrderRequest_HeaderFragment = gql`
	fragment OrderRequest_HeaderFragment on OrderRequest {
		id
		createdAt
		updatedAt
		productCount
		requestedDate
		location
		cancelled
		converted
		unit
		room
		isBulkPickup
	}
`;