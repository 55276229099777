import { gql } from "../../../__generated__";

export const ReportOriginStopConfirmed = gql(/* GraphQL */`
	mutation ReportOriginStopConfirmed(
		$stopId: String!
		$productId: String!
		$media: [CreateProductMediaDTO!]
	) {
		ReportOriginStopConfirmed(
			stopId: $stopId,
			productId: $productId
			media: $media
		) {
			id
		}
	}
`);

export const ReportDestinationStopConfirmed = gql(/* GraphQL */`
	mutation ReportDestinationStopConfirmed(
		$stopId: String!
		$productId: String!
		$media: [CreateProductMediaDTO!]
	) {
		ReportDestinationStopConfirmed(
			stopId: $stopId,
			productId: $productId,
			media: $media
		) {
			id
		}
	}
`);