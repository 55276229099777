import { gql } from "../../../__generated__";

export const RecordLeaderboardScore = gql(/* GraphQL */ `
	mutation RecordLeaderboardScore($firstName: String!, $lastName: String!, $username: String!, $emailAddress: String!, $score: Float!, $gameType: GameType!) {
		RecordLeaderboardScore(firstName: $firstName, lastName: $lastName, username: $username, emailAddress: $emailAddress, score: $score, gameType: $gameType) {
			username
			score
			createdAt
		}
	}
`);