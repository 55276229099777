import { CopyAll } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FragmentType } from "../../graphql";
import { Pickup_HeaderFragmentFragment } from "../../graphql/__generated__/graphql";
import { useClipboard } from "../../hooks";
import { BasicListItem } from "../List";


export const PickupIdListItem: React.FC<{
	loading: boolean;
	pickup: FragmentType<Pickup_HeaderFragmentFragment>;
}> = ({ pickup, loading }) => {
	const { copy } = useClipboard();

	return (
		<BasicListItem
			label="Pickup Id"
			loading={loading}
			value={pickup?.id}
			action={(
				<IconButton onClick={() => copy(pickup?.id || "")}>
					<CopyAll />
				</IconButton>
			)} />
	);
};
