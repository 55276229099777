import { Box, Image, Text } from "grommet";
import React, { useMemo } from "react";
import { ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { formatNumber } from "../../../helpers";
import { useWasteScenario } from "../hooks";
import { useWindowDimensions } from "../../../hooks";

export interface WasteComparisonProps {
	image: string;
	subject: string;
	unitLabel: string;
	unitSubject: string;
	poundsPerUnit: number;
}

export const WasteComparison: React.FC<WasteComparisonProps> = (props) => {
	const scenario = useWasteScenario();

	const footprint = useMemo(() => {
		return scenario.totalWastePounds - scenario.totalWasteReducedPounds;
	}, [ scenario ]);

	const count = useMemo(() => {
		return footprint / props.poundsPerUnit;
	}, [ footprint, props.poundsPerUnit ]);

	const { size } = useWindowDimensions();

	return (
		<ListItemButton
			divider
		>
			<Box direction="row" align="center" flex>
				<ListItemIcon>
					<Image
						src={props.image}
						width="28px" />
				</ListItemIcon>
				<ListItemText>
					<Text>
						the equivalent weight of <Text weight="bold">{formatNumber(count, 2)}</Text> {props.subject}
					</Text>
				</ListItemText>
				{size !== "small" && (
					<Box align="end" style={{ minWidth: "90px" }}>
						<Typography fontWeight="bold">
							{props.unitLabel}
						</Typography>
						<Typography>
							{props.unitSubject}
						</Typography>
					</Box>
				)}
			</Box>
		</ListItemButton>
	);
};
