import { useLazyQuery } from "@apollo/client";
import { useMemo, useEffect } from "react";
import { FetchAddressStopDetails } from "../graphql";

export function useLazyAddressWithStopDetails(addressId: string) {
	const [ fetch, { data, loading, error } ] = useLazyQuery(FetchAddressStopDetails);

	const address = useMemo(() => {
		return data?.FetchAddress ?? null;
	}, [ data ]);

	useEffect(() => {
		if(addressId) {
			fetch({ variables: { addressId } }).catch(err => {
				console.error("Failed to fetch address", err);
			});
		}
	}, [ addressId ]);

	return {
		data,
		address,
		loading,
		error
	};
}