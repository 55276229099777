import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import React, { Fragment, ReactNode, useEffect } from "react";
import { CreatePaymentMethodIntent } from "../../../graphql";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import { DialogWithClose } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCreatePaymentMethodConfig, setCreatePaymentMethodConfig } from "../../../store/payment";
import { CreatePaymentMethodForm } from "./CreatePaymentMethodForm";

export const StripeProvider: React.FC<{ children: ReactNode; }> = (props) => {
	const [ params ] = useSearchParams();
	const dispatch = useAppDispatch();
	const [ isPaymentMethodModalOpen, setIsPaymentMethodModalOpen ] = React.useState(false);
	const paymentMethodConfig = useAppSelector(selectCreatePaymentMethodConfig);

	useEffect(() => {
		const paymentMethodCallback = params.get("payment_method_callback");
		if(paymentMethodCallback) {
			setIsPaymentMethodModalOpen(true);
			const updatedParams = new URLSearchParams(params);
			updatedParams.delete("payment_method_callback");
			window.history.replaceState({}, "", `${window.location.pathname}?${updatedParams}`);
		}
	}, [ params ]);

	useEffect(() => {
		if(paymentMethodConfig) {
			const { clientSecret, publicKey, isModalVisible } = paymentMethodConfig;
			if(clientSecret && publicKey && isModalVisible) {
				setIsPaymentMethodModalOpen(true);
			}
		}
	}, [ paymentMethodConfig ]);

	function handleCloseDialog(): void {
		dispatch(setCreatePaymentMethodConfig({
			clientSecret: "",
			publicKey: "",
			isModalVisible: false
		}));
		setIsPaymentMethodModalOpen(false);
	}

	return (
		<Fragment>
			{isPaymentMethodModalOpen && (
				<CreatePaymentMethodDialog
					onClose={handleCloseDialog}
					publicKey={paymentMethodConfig?.publicKey || ""}
					clientSecret={paymentMethodConfig?.clientSecret || ""}
				/>
			)}
			{props.children}
		</Fragment>
	);
};

export const CreatePaymentMethodDialog: React.FC<{
	onClose(): void;
	publicKey: string;
	clientSecret: string;
}> = ({ onClose, publicKey, clientSecret }) => {
	return (
		<DialogWithClose
			title="Create Payment Method"
			onClose={onClose}
			content={(
				<CreatePaymentMethodForm
					publicKey={publicKey}
					clientSecret={clientSecret}
				/>
			)}
		/>
	);
};

export const CreatePaymentMethodButton: React.FC<{
	label: string;
	icon?: React.ReactNode;
} & ButtonProps> = ({ label, icon, ...rest }) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [
		createIntent,
		{ loading: isCreatingIntent }
	] = useMutation(CreatePaymentMethodIntent);

	function handleClick(): void {
		createIntent().then(res => {
			const { clientSecret, publicKey } = res?.data?.CreatePaymentMethodIntent ?? {};
			if(clientSecret && publicKey) {
				dispatch(setCreatePaymentMethodConfig({
					clientSecret,
					publicKey,
					isModalVisible: true
				}));
			}
		}).catch(err => {
			snack.enqueueSnackbar("We ran into an issue loading your information. Please try again.", {
				variant: "error"
			});
			console.error("Failed to create payment method intent", err);
		});
	}

	return (
		<StripeProvider>
			<LoadingButton
				{...rest}
				startIcon={icon}
				onClick={handleClick}
				loading={isCreatingIntent}
			>
				{label}
			</LoadingButton>
		</StripeProvider>
	);
};