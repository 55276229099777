import { StepLabel, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useWindowDimensions } from "../../../../hooks";

export const OnboardingStepLabel: React.FC<{
	label: string;
	description: string;
}> = ({ label, description }) => {
	const { width } = useWindowDimensions();

	const shouldHideDescription = useMemo(() => {
		return width < 900;
	}, [ width ]);

	return (
		<StepLabel>
			<Typography fontWeight="bold">
				{label}
			</Typography>
			{!shouldHideDescription && (
				<Typography variant="body2">
					{description}
				</Typography>
			)}
		</StepLabel>
	);
};