import { Box, Grid, Heading } from "grommet";
import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { ActionItemListItem, Pagination, ViewContainer } from "../../../../components";
import { useFullScreen, useInstance, usePageTitle } from "../../../../hooks";
import { ActiveOrderSummaryList, BeginCheckoutButton } from "../../components";
import { useUser } from "../../../../auth";
import { useDashboardTasks } from "../../hooks";

export const SchedulePickupActionComponent: React.FC = () => {
	const { instance } = useInstance();
	const scheduleMessage = useMemo(() => {
		return [
			"Give your furniture a second chance! Donate home furnishings and building materials to",
			(instance?.name || "your local non-profits.")
		].join(" ");
	}, [ instance ]);
	return (
		<Box gap="medium">
			<Heading level="3" margin="none">
				Ready to schedule a pickup?
			</Heading>
			<Box gap="medium" margin="medium">
				<Typography>
					{scheduleMessage}
				</Typography>
				<Box flex margin="small">
					<Box align="center">
						<BeginCheckoutButton
							label="Schedule Pickup"
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export const DonationCenterDashboard: React.FC = () => {
	usePageTitle("Dashboard");
	const { user } = useUser();
	const { instance } = useInstance();
	const fullScreen = useFullScreen();
	const dashboardTasks = useDashboardTasks();

	return (
		<ViewContainer>
			<Box gap="small">
				<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="small">
					<Box gap="small" flex>
						<Heading level={3} margin="none">
							Your Action Items
						</Heading>
						{dashboardTasks.length === 0 && (
							<Box align="center" justify="center" height="small">
								<Typography fontWeight="bold" fontSize="large">
									Nothing to do!
								</Typography>
							</Box>
						)}
						{dashboardTasks.length > 0 && (
							<Pagination
								pageSize={5}
							>
								{dashboardTasks.map(task => (
									<ActionItemListItem
										{...task}
										key={task.id}
									/>
								))}
							</Pagination>
						)}
					</Box>
					<SchedulePickupActionComponent

					/>
				</Grid>
				<Box style={{ display: "block" }}>
					<ActiveOrderSummaryList
						userId={user?.id || ""}
						instanceId={instance?.id || ""}
					/>
					<Box height="20px" id="spacer">

					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};