import { Anchor, Box, Heading } from "grommet";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../../store";
import { UseFormReturn, useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { push } from "redux-first-history";
import { LoadingButton } from "@mui/lab";
import { BaseDialog } from "../../../../components";
import { useEffect, useMemo } from "react";
import { useRedirect } from "../../../../hooks";
import { Moment } from "moment-timezone";
import { BeginBuildingOnboarding, ConfirmBuildingOnboarding } from "../../../../graphql";
import { useBuilding } from "../../../admin/residence/hooks";
import { RegistrationFormValues, RegistrationForm, UsernameElement, UnitElement, FirstNameElement, LastNameElement, MoveOutDateElement, ConfirmationCodeElement } from "../../components";
import { useBeginOnboarding, useConfirmOnboarding } from "../../hooks";
import { useBuildings } from "../hooks";
import Fuse from "fuse.js";
import { isNotNull } from "../../../../helpers";
import { AutocompleteElement } from "react-hook-form-mui";
import { selectCheckoutState } from "../../../../store/checkout";

type ManagementCompanyOnboardingState = RegistrationFormValues<{
	unit: string;
	buildingId: string;
	buildingQuery: string;
	moveInDate: Moment | null;
	moveOutDate: Moment | null;
}>;

export function useFilterBuildings(query: string) {
	const buildings = useBuildings();
	const filtered = useMemo(() => {
		console.log("filtering buildings");
		if(!query || query.length < 3) return buildings;
		const mapped = buildings.map(b => ({
			id: b.id,
			name: b.name,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			///@ts-ignore
			addressLineOne: b.address.addressLineOne,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			///@ts-ignore
			city: b.address.city
		}));

		const fuse = new Fuse(mapped, {
			threshold: 0.3,
			includeScore: true,
			includeMatches: true,
			ignoreLocation: true,
			keys: [ "name", "addressLineOne", "city" ]
		});

		return fuse.search(query).map(r => r.item).map(b => buildings.find(bb => bb.id === b.id)).filter(isNotNull);
	}, [ buildings, query ]);

	return filtered;
}

export const BuildingLookupComponent: React.FC<{
	formContext: UseFormReturn<ManagementCompanyOnboardingState>;
}> = ({ formContext }) => {
	const { buildingQuery } = formContext.watch();

	const buildings = useFilterBuildings(buildingQuery);
	const options = useMemo(() => {
		return buildings.map(building => ({
			...building,
			label: building.name
		}));
	}, [ buildings, buildingQuery ]);

	useEffect(() => {
		console.log(buildings);
	}, [ buildings ]);

	return (
		<Box gap="medium">
			<Heading level="3" margin="none">
				Let's look up your building
			</Heading>
			<AutocompleteElement
				name="search"
				label="Building Search"
				textFieldProps={{
					helperText: "Search for your building by name, address, or city"
				}}
				autocompleteProps={{
					onInputChange: (event, value) => {
						formContext.setValue("buildingQuery", value);
					},
					onChange: (event, value) => {
						event.preventDefault();
						event.stopPropagation();

						if(value) {
							formContext.setValue("buildingId", value.id);
						}
					}
				}}
				options={options}
			/>
		</Box>
	);
};

export const ManagementCompanyOnboardingView: React.FC = () => {
	const dispatch = useAppDispatch();
	const { redirect } = useRedirect();
	const instance = useAppSelector(selectInstancePublic);
	const { referer } = useAppSelector(selectCheckoutState);

	const formContext = useForm<ManagementCompanyOnboardingState>({
		defaultValues: {
			unit: "",
			username: "",
			code: "",
			firstName: "",
			lastName: "",
			moveInDate: null,
			moveOutDate: null,
			buildingId: referer?.id || "",
			buildingQuery: ""
		}
	});

	const formValues = formContext.watch();
	const { building } = useBuilding(formValues.buildingId);

	const {
		wasCodeSent,
		canResendCode,
		resendCode,
		isSendingCode,
		begin,
	} = useBeginOnboarding(BeginBuildingOnboarding);

	const {
		wasConfirmSuccessful,
		accessToken,
		refreshToken,
		loading: confirmLoading,
		confirm
	} = useConfirmOnboarding(ConfirmBuildingOnboarding);

	function handleSubmit() {
		if(!instance) return;
		if(!building) return;
		const { unit, username, firstName, lastName, code, moveInDate, moveOutDate } = formValues;

		if(wasCodeSent) {
			confirm({
				instanceId: instance.id,
				buildingId: building.id,
				username,
				unit,
				code,
				firstName,
				lastName,
				moveInDate,
				moveOutDate,
			});
			return;
		}

		begin({
			instanceId: instance.id,
			buildingId: building.id,
			username,
			unit
		});
	}

	useEffect(() => {
		if(wasConfirmSuccessful && accessToken && refreshToken) {
			dispatch(push("/dashboard"));
			redirect("/dashboard", {
				schedule: "true"
			});
		}
	}, [ wasConfirmSuccessful, accessToken, refreshToken ]);

	return (
		<Box>
			<BaseDialog
				title="Registration"
				content={(
					<RegistrationForm
						formContext={formContext as unknown as UseFormReturn<RegistrationFormValues>}
						elementRenderInterupt={!formValues.buildingId && (
							<BuildingLookupComponent formContext={formContext} />
						)}
						elements={[
							<Typography>
								Enter your email address or phone number to get started.
							</Typography>,
							<UsernameElement
								disabled={wasCodeSent}
								username={formContext.watch("username")}
							/>,
							!wasCodeSent && <UnitElement
								unitPrefix={building?.unitPrefix || ""}
							/>,
							!wasCodeSent && <FirstNameElement />,
							!wasCodeSent && <LastNameElement />,
							!wasCodeSent && <MoveOutDateElement />,
							<ConfirmationCodeElement
								wasCodeSent={wasCodeSent}
								canResendCode={canResendCode}
								onResendCode={resendCode}
								username={formContext.watch("username")}
							/>
						].filter(Boolean)}
					/>
				)}
				actions={(
					<Box direction="row" justify="between">
						<Box align="start" justify="center">
							<Typography>
								<Anchor onClick={() => dispatch(push("/login"))}>Switch to login?</Anchor>
							</Typography>
						</Box>
						<Box align="end" justify="center">
							<LoadingButton
								color="primary"
								variant="contained"
								loading={isSendingCode || confirmLoading}
								onClick={formContext.handleSubmit(handleSubmit)}
							>
								Submit
							</LoadingButton>
						</Box>
					</Box>
				)}
			/>
		</Box>
	);
};