import { gql } from "@apollo/client";

export const Pickup_TrackingFragment = gql`
	fragment Pickup_TrackingFragment on Pickup {
		id
		started
		startedAt
		completed
		completedAt
		cancelled
		cancelledAt
		cancelledReason
	}
`;