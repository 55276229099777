import { Route, Routes } from "react-router-dom";
import { ScheduleLayout } from "./layout";
import { ScheduleController } from "./controller";
import AddressView from "./views/Address";
import ItemsView from "./views/Items";
import ReviewView from "./views/Contact";
import { usePageTitle } from "../../hooks";

export const ScheduleRouter: React.FC = () => {
	usePageTitle("Schedule Pickup");
	return (
		<Routes>
			<Route path="/" element={< ScheduleLayout />}>
				<Route path="" element={< ScheduleController />} />
				<Route path="address" element={< AddressView sessionId="" />} />
				<Route path="items" element={< ItemsView sessionId="" />} />
				<Route path="checkout" element={< ReviewView sessionId="" />} />
			</Route>
		</Routes>
	);
};