import { gql } from "@apollo/client";
import { Pickup_AssignmentFragment, Pickup_HeaderFragment, Pickup_TrackingFragment } from "../../movement";
import { Order_EstimateFragment, Order_HeaderFragment, Order_PickupFragment, Order_ProductsFragment } from "../fragments";
import { Order_AddressFragment } from "../fragments/AddressFragment";
import { AddressFragment } from "../../address";
import { OrderEstimate_HeaderFragment } from "../../payment";
import { ProductFragmentHelper } from "../../product/helpers";

export const OrderHeaderFragmentHelper = gql`
	${Order_HeaderFragment}
`;

export const OrderAddressFragmentHelper = gql`
	${AddressFragment}
	${Order_AddressFragment}
`;

export const OrderEstimateFragmentHelper = gql`
	${Order_EstimateFragment}
	${OrderEstimate_HeaderFragment}
`;

export const OrderPickupFragmentHelper = gql`
	${Order_PickupFragment}
	${Pickup_HeaderFragment}
	${Pickup_TrackingFragment}
	${Pickup_AssignmentFragment}
`;

export const OrderProductsFragmentHelper = gql`
	${ProductFragmentHelper}
	${Order_ProductsFragment}
`;