import moment from "moment";
import { ViewContainer } from "../../../../components";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect, useMemo } from "react";
import { OrderStatus } from "../../../../graphql/__generated__/graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FindOrdersByReferer } from "../../../../graphql";
import { useInstance } from "../../../../hooks";
import { FetchBuilding, FetchBuildingByInstance } from "../../../../graphql";
import { LinearProgress } from "@mui/material";

export const ElevatorSchedulingView: React.FC = (props) => {
	const { instance } = useInstance();

	const [
		fetchBuilding,
		{ data: building }
	] = useLazyQuery(FetchBuildingByInstance);

	const buildingData = useMemo(() => {
		return building?.FetchBuildingByInstance;
	}, [ building ]);

	useEffect(() => {
		if(instance) {
			fetchBuilding({
				variables: {
					instanceId: instance.id
				}
			}).catch(err => {
				console.error("Error fetching building by instance", err);
			});
		}
	}, [ instance ]);
	return (
		<ViewContainer>
			{!buildingData && (
				<LinearProgress />
			)}
			{buildingData && (
				<ElevatorCalendarComponent
					refererId={buildingData.id}
				/>
			)}
		</ViewContainer>
	);
};

export const ElevatorCalendarComponent: React.FC<{
	refererId: string;
}> = ({ refererId }) => {
	const { data, loading } = useQuery(FindOrdersByReferer, {
		variables: {
			refererId: refererId,
			status: { equals: OrderStatus.Submitted }
		}
	});

	const { data: buildingData, loading: buildingLoading } = useQuery(FetchBuilding, {
		variables: { buildingId: refererId }
	});

	const events = useMemo(() => {
		return (data?.FindOrdersByReferer || []).map(order => ({
			id: order.id,
			title: (buildingData?.FetchBuilding?.unitPrefix)
				? `${buildingData.FetchBuilding.unitPrefix} ${order.user.resident?.unit || ""}`
				: order.user.resident?.unit,
			allDay: false,
			start: (order.pickup?.scheduledDate)
				? moment.tz(order.pickup.scheduledDate, "YYYY-MM-DD", "UTC").toDate()
				: null,
			end: (order.pickup?.scheduledDate)
				? moment.tz(order.pickup.scheduledDate, "YYYY-MM-DD", "UTC").toDate()
				: null,
		}));
	}, [ data ]);

	return (
		<Calendar
			localizer={momentLocalizer(moment)}
			events={events}
			startAccessor="start"
			endAccessor="end"
			style={{ height: "100%" }}
			views={[ "month", "day" ]}
		/>
	);
};