import { Box, Heading } from "grommet";
import React, { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { DialogWithClose } from "../../../../components";
import { formatNumber } from "../../../../helpers";
import { selectCalculatorState, selectCalculatorSubmission } from "../../../../store/calculator";
import { useAppSelector } from "../../../../store";
import { WasteScenario } from "../../WasteScenario";
import { questions } from "../../static";
import { WasteFootprintResults } from "../WasteFootprintResults";
import { QuestionElement } from "./QuestionElement";
import { useSearchParams } from "react-router-dom";
import { useSubmission } from "../../hooks";
import { LoadingButton } from "@mui/lab";

export const QuestionController: React.FC = () => {
	const [ query ] = useSearchParams();
	const state = useAppSelector(selectCalculatorState);
	const scenario = useMemo(() => {
		return new WasteScenario(state);
	}, [ state ]);
	const submissionState = useAppSelector(selectCalculatorSubmission(scenario));

	const [ debug, setDebug ] = useState<boolean>(false);
	const [ showResults, setShowResults ] = useState(false);

	useEffect(() => {
		if(query.get("debug") === "true") {
			setDebug(true);
		}
	}, [ query ]);

	const { updateSubmission, isLoading } = useSubmission();

	function handleSubmission() {
		updateSubmission({
			...submissionState
		}).then(() => {
			setShowResults(true);
		});
	}

	return (
		<Box gap="medium">
			<Box>
				{questions.map((question) => (
					<QuestionElement
						key={question.id}
						question={question}
						displayWeightImpact={debug}
					/>
				))}
			</Box>
			<Box align="center">
				<LoadingButton
					color="primary"
					variant="contained"
					onClick={handleSubmission}
					loading={isLoading}
					style={{ margin: "10px" }}
				>
					See How You Compare to the Average American
				</LoadingButton>
			</Box>
			<Box height="10px">

			</Box>
			{showResults && (
				<DialogWithClose
					title="Your Results"
					content={(
						<WasteFootprintResults />
					)}
					disabledContentPadding
					onClose={() => {
						setShowResults(false);
					}}
					actions={(
						<Box direction="row" justify="between">
							<Box>
								<Heading level="3" margin="none">
									{formatNumber(scenario.totalWastePounds - scenario.totalWasteReducedPounds, 2)} lb(s)
								</Heading>
								<Typography variant="caption">
									Your Footprint
								</Typography>
							</Box>
							{scenario.totalWasteReducedPounds > 0 && (
								<Box>
									<Heading level="3" margin="none">
										- {formatNumber(scenario.totalWasteReducedPounds, 2)} lb(s)
									</Heading>	<Typography variant="caption" textAlign="end">
										- {formatNumber(scenario.totalWasteReducedPercentage, 2)}%
									</Typography>
								</Box>
							)}
						</Box>
					)} />
			)}
			{/* <Box>
                <pre>
                    {JSON.stringify(debug, null, 2)}
                </pre>
            </Box> */}
		</Box>
	);
};
