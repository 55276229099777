import { useEffect } from "react";
import React from "react";
import { InstanceFeature } from "../../../../graphql/__generated__/graphql";
import { useSearchParams } from "react-router-dom";
import { replace } from "redux-first-history";
import { useAppDispatch } from "../../../../store";
import { useFeature } from "../../../instance/hooks";
import { ScheduleQuoteBasedPickupController } from "./ScheduleQuoteBasedPickupController";
import { ScheduleUpfrontPickupController } from "./ScheduleUpfrontPickupController";

export const SchedulePickupController: React.FC<{
	orderId: string;
	forceOpen?: boolean;
	activatorButton: React.ReactNode;
}> = ({ orderId, activatorButton, forceOpen }) => {
	const dispatch = useAppDispatch();
	const [ query ] = useSearchParams();

	const [ expanded, setExpanded ] = React.useState<string>("date");
	const isUpfrontSchedulingEnabled = useFeature(InstanceFeature.OrderUpfrontScheduleEnabled);

	useEffect(() => {
		if(query.get("redirect_status")) {
			setExpanded("payment");
			const newQuery = new URLSearchParams(query);
			newQuery.delete("redirect_status");
			dispatch(replace(location.pathname + "?" + newQuery.toString()));
		}
	}, [ query ]);

	useEffect(() => {
		console.log("forceOpen", forceOpen);
	}, [ forceOpen ]);

	if(isUpfrontSchedulingEnabled) {
		return (
			<ScheduleUpfrontPickupController
				orderId={orderId}
				forceOpen={forceOpen}
				activatorButton={activatorButton}
				expanded={expanded}
				setExpanded={setExpanded}
			/>
		);
	}

	return (
		<ScheduleQuoteBasedPickupController
			orderId={orderId}
			forceOpen={forceOpen}
			activatorButton={activatorButton}
			expanded={expanded}
			setExpanded={setExpanded}
		/>
	);
};