import { gql } from "../../../__generated__";


export const FetchHaulerPickups = gql(/* GraphQL */ `
	query FetchHaulerPickups(
		$page: Int!
		$limit: Int!
		$haulerId: String!
	) {
		FetchHaulerPickups(
			page: $page
			limit: $limit
			haulerId: $haulerId
		) {
			data {
				id
				scheduledDate
				stopCount
				incomingProductCount
				market {
					id
					name
					code
				}
			}
			page
			limit
			total
			nextPage
			previousPage
			lastPage
		}
	}
`);
