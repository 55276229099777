import { gql } from "../__generated__";

export const CreateContact = gql(/* GraphQL */ `
	mutation CreateContact(
		$userId: String!
		$type: ContactType!
		$value: String!
		$isPrimary: Boolean
	) {
		CreateContact(
			userId: $userId,
			type: $type,
			value: $value, 
			isPrimary: $isPrimary
		) {
			id
		}
	}
`);

export const BeginVerifyContact = gql(/* GraphQL */ `
	mutation BeginVerifyContact(
		$contactId: String!
	) {
		BeginVerifyContact(contactId: $contactId) {
			wasSent
			wasFound
			message
			wasVerified
		}
	}
`);

export const VerifyContact = gql(/* GraphQL */ `
	mutation VerifyContact(
		$code: String!
		$contactId: String!
	) {
		VerifyContact(contactId: $contactId, code: $code) {
			id
		}
	}
`);

export const UpdatePrimaryContact = gql(/* GraphQL */ `
	mutation UpdatePrimaryContact(
		$userId: String!
		$contactId: String!
	) {
		UpdatePrimaryContact(userId: $userId, contactId: $contactId) {
			id
		}
	}
`);