import { SvgIcon, SvgIconProps } from "@mui/material";

	export const SleeperSofa: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1089.5 365.48h-59.219v-6.4727c0-48.57-39.512-88.078-88.074-88.078h-684.38c-48.57 0-88.078 39.516-88.078 88.078v6.4727h-59.211c-39.406 0-71.469 32.062-71.469 71.469v342.95c0 37.574 29.168 68.398 66.039 71.199v27.051c0 28.074 22.844 50.918 50.926 50.918h7.8008c28.078 0 50.926-22.844 50.926-50.918v-26.773h770.53v26.773c0 28.074 22.844 50.918 50.926 50.918h7.8008c28.074 0 50.918-22.844 50.918-50.918v-27.051c36.879-2.7891 66.047-33.613 66.047-71.199l-0.011719-342.96c-0.003906-39.402-32.062-71.465-71.473-71.465zm-104.5 300.23h-769.95c-13.703 0-24.848-11.145-24.848-24.848 0-13.703 11.145-24.848 24.848-24.848h769.95c13.695 0 24.848 11.145 24.848 24.848 0 13.703-11.152 24.848-24.848 24.848zm-762.74-306.7c0-19.621 15.961-35.578 35.578-35.578h684.38c19.613 0 35.574 15.961 35.574 35.578v204.51l-755.53-0.003907zm-130.68 420.89v-342.95c0-10.461 8.5117-18.969 18.969-18.969h59.211v160.32c-19.379 14.07-32.055 36.84-32.055 62.566 0 25.727 12.676 48.496 32.055 62.566v95.438h-59.211c-10.453 0.007812-18.969-8.5078-18.969-18.969zm72.266 111.67h-7.8008c-7.4023 0-13.426-6.0234-13.426-13.418v-26.773h34.641v26.773c0.003906 7.3945-6.0195 13.418-13.414 13.418zm58.418-173.36h755.53v80.664h-755.53zm821.75 173.36h-7.8008c-7.4023 0-13.426-6.0234-13.426-13.418v-26.773h34.641v26.773c0.011718 7.3945-6.0117 13.418-13.414 13.418zm64.465-111.67c0 10.461-8.5117 18.969-18.969 18.969h-59.219v-95.43c19.379-14.07 32.055-36.84 32.055-62.566s-12.676-48.504-32.055-62.574v-160.32h59.219c10.461 0 18.969 8.5117 18.969 18.969z"/>
</svg>
			</SvgIcon>
		);
	};