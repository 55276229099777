import { useQuery } from "@apollo/client";
import { FindOrdersByBuilding } from "../../../../graphql";

export function useActiveOrders(buildingId: string) {
	const { data, loading } = useQuery(FindOrdersByBuilding, {
		variables: {
			buildingId,
			cancelledAt: { isNull: true },
			completedAt: { isNull: true },
			submittedAt: { isNotNull: true }
		},
		skip: !buildingId
	});

	return {
		data,
		loading,
		orders: data?.FindOrders || []
	};
}
