import { Box, Grid } from "grommet";
import React from "react";
import { Button, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useProductCategoryIcon } from "../../../../hooks";
import { Cancel, CheckCircle, Error, ImageSearch } from "@mui/icons-material";
import { HaulerProductExceptionDialog, ProductMediaDialog } from "../product";
import moment from "moment";
import { useWindowDimensions } from "../../../../hooks";
import { Product_StopDetailedFragmentFragment } from "../../../../graphql/__generated__/graphql";
import { ConfirmDestinationStopButton } from "./ConfirmDestinationStopButton";

export const HaulerProductOutgoingStopListItem: React.FC<{
	stopId: string;
	product: Product_StopDetailedFragmentFragment;
	haulerId: string;
	movementId: string;
}> = ({ product, haulerId, movementId, stopId }) => {
	const { size } = useWindowDimensions();
	const icon = useProductCategoryIcon(product.type?.category?.name || "");

	return (
		<ListItemButton divider>
			<Box gap="small" width="100%">
				<Box direction="row" flex>
					<Box justify="center" align="center">
						<ListItemIcon>
							{icon}
						</ListItemIcon>
					</Box>
					<Box flex direction="row">
						<ListItemText
							primary={product.type?.name || "Unknown"}
							secondary={product.size?.name || "Unknown"} />
					</Box>
					<Box justify="center">
						<Box direction="row" gap="small">
							{product.totalQuantity >= 1 && (
								<Box align="center" justify="center">
									<Typography fontWeight="bold">
										(x{product.totalQuantity})
									</Typography>
								</Box>
							)}
							<ProductMediaDialog
								media={product.media}
								activatorButton={(
									<IconButton>
										<ImageSearch />
									</IconButton>
								)} />
						</Box>
					</Box>
				</Box>
				{product.destinationStopConfirmed && (
					<Box>
						<List>
							<ListItem>
								<ListItemIcon>
									<CheckCircle
										color="success" />
								</ListItemIcon>
								<ListItemText
									primary="Dropoff Confirmed"
									secondary={product.destinationStopConfirmed
										? `Confirmed on ${moment(product.destinationStopConfirmedAt).format("MM/DD/YYYY hh:mm A")}`
										: ""}
								/>
							</ListItem>
						</List>
					</Box>
				)}
				{(product.destinationStopDelayed && !product.destinationStopConfirmed) && (
					<Box>
						<List>
							<ListItem>
								<ListItemIcon>
									<Error
										color="warning"
									/>
								</ListItemIcon>
								<ListItemText
									primary="Dropoff Delayed"
									secondary={product.destinationStopDelayedAt
										? `Delayed until ${moment(product.destinationStopDelayedUntil).format("MM/DD/YYYY")}`
										: ""}
								/>
							</ListItem>
							<ListItemSecondaryAction>
								<Box direction="row" gap="small">
									<ConfirmDestinationStopButton
										stopId={stopId}
										productId={product.id}
										activatorButton={size === "small"
											? (
												<IconButton>
													<CheckCircle color="primary" />
												</IconButton>
											)
											: (
												<Button
													size="small"
													color="primary"
													variant="contained"
												>
													Confirm
												</Button>
											)}
									/>
									<HaulerProductExceptionDialog
										stopId={stopId}
										haulerId={haulerId}
										isOrigin={false}
										productId={product.id}
										movementId={movementId}
										activatorButton={size === "small"
											? (
												<IconButton>
													<Cancel color="error" />
												</IconButton>
											)
											: (
												<Button
													size="small"
													color="error"
													variant="contained"
												>
													Not Dropped Off
												</Button>
											)}
									/>
								</Box>
							</ListItemSecondaryAction>
						</List>
					</Box>
				)}
				{(!product.destinationStopConfirmed && !product.destinationStopDelayed) && (
					<Grid columns={{ count: size === "small" ? 1 : 2, size: "auto" }} gap={size === "small" ? "small" : "medium"}>
						<HaulerProductExceptionDialog
							stopId={stopId}
							haulerId={haulerId}
							isOrigin={false}
							productId={product.id}
							movementId={movementId}
						/>
						<ConfirmDestinationStopButton
							stopId={stopId}
							productId={product.id}
						/>
					</Grid>
				)}
			</Box>
		</ListItemButton>
	);
};
