import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Box } from "grommet";
import { useFullScreen } from "../hooks";
import { Fragment, ReactNode, createContext, createRef, useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import React from "react";
import { Close } from "@mui/icons-material";
import { noop } from "../helpers";

interface CustomDialogTitleProps {
	onClose?: () => void;
	title: string;
}

export const CustomDialogTitle: React.FC<CustomDialogTitleProps> = (props) => {
	return (
		<DialogTitle>
			<Box direction="row" justify="between">
				<Box align="center" justify="center">
					<Typography variant="h5">{props.title}</Typography>
				</Box>
				{props.onClose && (
					<IconButton color="primary" onClick={props.onClose}>
						<Close color="primary" />
					</IconButton>
				)}
			</Box>
		</DialogTitle>
	);
};

export const DialogActionsWithPadding: React.FC<{ children: ReactNode; }> = (props) => {
	return (
		<Box pad="small" flex>
			{props.children}
		</Box>
	);
};

export interface DialogWithCloseProps {
	onClose: () => void;
	title: string;
	content: React.ReactNode;
	actions?: React.ReactNode;
	hideTitle?: boolean;
	hideActions?: boolean;
	disabledContentPadding?: boolean;
	fullScreen?: boolean;
	formContainer?: {
		context: UseFormReturn<any>,
		onSubmit: () => void;
	};
}

export const DialogContext = createContext({
	onClose: () => {
		noop;
	}
});

export const DialogWithClose: React.FC<DialogWithCloseProps> = (props) => {
	const fullScreen = useFullScreen();

	const container = useCallback((children: ReactNode) => {
		if(props.formContainer) {
			return (
				<FormContainer
					context={props.formContainer.context}
					onSuccess={props.formContainer.onSubmit}
				>
					{children}
				</FormContainer>
			);
		}
		else {
			return (
				<Fragment>
					{children}
				</Fragment>
			);
		}
	}, [ props.formContainer ]);

	return (
		<DialogContext.Provider value={{ onClose: props.onClose }}>
			<Dialog open fullWidth fullScreen={fullScreen || props.fullScreen} onClick={(event) => event.stopPropagation()}>
				{container((
					<Fragment>
						{!props.hideTitle && <CustomDialogTitle onClose={props.onClose} title={props.title} />}
						<DialogContent dividers sx={{ padding: props.disabledContentPadding ? 0 : undefined }}>
							{props.content}
						</DialogContent>
						{!!props.actions && (
							<DialogActions>
								<DialogActionsWithPadding>
									{props.actions}
								</DialogActionsWithPadding>
							</DialogActions>
						)}
					</Fragment>
				))}
			</Dialog>
		</DialogContext.Provider>
	);
};

export const BaseDialog: React.FC<Omit<DialogWithCloseProps, "onClose" | "formContainer">> = (props) => {
	const fullScreen = useFullScreen();
	return (
		<Dialog open fullWidth fullScreen={fullScreen || props.fullScreen}>
			<CustomDialogTitle title={props.title} />
			<DialogContent dividers sx={{ padding: props.disabledContentPadding ? 0 : undefined }}>
				{props.content}
			</DialogContent>
			<DialogActions>
				<DialogActionsWithPadding>
					{props.actions}
				</DialogActionsWithPadding>
			</DialogActions>
		</Dialog>
	);
};

export const DialogWithActivatorButton: React.FC<{
	forceOpen?: boolean;
	fullScreen?: boolean;
	children: ReactNode;
	forceClose?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	activatorButton: ReactNode;
	activatorButtonIntercept?: () => boolean;
} & Omit<DialogWithCloseProps, "content" | "onClose">> = ({ onOpen, onClose, fullScreen, forceOpen, children, activatorButton, forceClose, activatorButtonIntercept, ...rest }) => {
	const [ open, setOpen ] = useState(forceOpen);
	const buttonRef = createRef<HTMLButtonElement>();

	useEffect(() => {
		if(forceClose) {
			setOpen(false);
			if(onClose) {
				onClose();
			}
		}
	}, [ forceClose ]);

	useEffect(() => {
		if(forceOpen) {
			setOpen(true);
		}
	}, [ forceOpen ]);

	useEffect(() => {
		if(open && onOpen) {
			onOpen();
		}
	}, [ open ]);

	return (
		<Fragment>
			{React.cloneElement(activatorButton as React.ReactElement, {
				ref: buttonRef,
				onClick: (event: React.MouseEvent<HTMLButtonElement>): void => {
					console.log("Activator button clicked");
					if(event) {
						event.preventDefault();
						event.stopPropagation();
					}

					const shouldOpen = activatorButtonIntercept ? activatorButtonIntercept() : true;
					setOpen(shouldOpen);
				},
				onSelect: (event: React.MouseEvent<HTMLButtonElement>): void => {
					console.log("Activator button selected");
					if(event) {
						event.preventDefault();
						event.stopPropagation();
					}

					const shouldOpen = activatorButtonIntercept ? activatorButtonIntercept() : true;
					setOpen(shouldOpen);
				}
			})}
			{open && (
				<DialogWithClose
					{...rest}
					onClose={() => {
						setOpen(false);
						if(onClose) {
							onClose();
						}
					}}
					content={children}
					fullScreen={fullScreen}
				/>
			)}
		</Fragment>
	);
};

export const ConfirmationDialog: React.FC<{
	onConfirm: () => void | Promise<void>;
	onCancel: () => void | Promise<void>;
	title: string;
	content: React.ReactNode;
	cancelButtonLabel?: string;
	confirmButtonLabel?: string;
}> = ({ onConfirm, onCancel, title, content, cancelButtonLabel, confirmButtonLabel }) => {
	return (
		<DialogWithClose
			title={title}
			content={(
				<Box>
					<Typography>{content}</Typography>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button
						variant="contained"
						color="error"
						onClick={onCancel}
					>
						{cancelButtonLabel || "Cancel"}
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={onConfirm}
					>
						{confirmButtonLabel || "Confirm"}
					</Button>
				</Box>
			)}
			onClose={onCancel}
		/>
	);
};