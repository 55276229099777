import { Box } from "grommet";
import { Button } from "@mui/material";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../store";
import { HowItWorkContainer, ReferViewContainer, ResidenceDetailsContainer } from "../components";
import { useFrequentlyAskedQuestions, useInstance, usePageTitle } from "../../../hooks";
import React, { useMemo } from "react";
import { FaqDialog } from "../../../components/faq/FaqDialog";

export const UniversityReferView: React.FC = () => {
	usePageTitle("University Welcome");
	const dispatch = useAppDispatch();

	const { instance } = useInstance();
	const partnerName = useMemo(() => {
		return instance?.name ?? "Your School";
	}, [ instance ]);

	const faqs = useFrequentlyAskedQuestions();

	return (
		<ReferViewContainer>
			<Box
				gap="medium"
				height="100%"
				overflow={{ vertical: "auto" }}
				style={{ maxWidth: "800px", display: "block" }}
			>
				<ResidenceDetailsContainer
					partnerName={partnerName}
				/>
				<HowItWorkContainer />
				<Box direction="row" align="end" justify="end" gap="small">
					{faqs.length > 0 && (
						<FaqDialog
							activatorButton={(
								<Button
									variant="outlined"
									color="primary"
								>
									FAQs
								</Button>
							)}
						/>
					)}
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							dispatch(push("/checkout"));
						}}
					>
						Get Started
					</Button>
				</Box>
			</Box>
		</ReferViewContainer>
	);
};