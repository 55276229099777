import { useQuery } from "@apollo/client";
import { FindMaterials } from "../../../../graphql";

export function useWasteAuditMaterials() {
	const { data, loading } = useQuery(FindMaterials, {
		variables: {
			tags: { arrayContains: [ "audit" ] }
		}
	});

	return {
		materials: data?.FindMaterials || [],
		loading
	};
}