import { Box, Heading } from "grommet";
import React from "react";
import { DialogWithActivatorButton } from "../../../../components";
import { Button, List } from "@mui/material";
import { StopIncomingItemsComponent } from "./StopIncomingItemsComponent";
import { StopPhoneNumberListItem } from "./StopPhoneNumberListItem";
import { StopAddressComponent } from "./StopAddressComponent";
import { StopParkingInstructionsComponent } from "./StopParkingInstructionsComponent";
import { StopInstructionsComponent } from "./StopPickupInstructionsComponent";
import { StopOutgoingItemsComponent } from "./StopOutgoingItemsComponent";
import { StopContactNameListItem } from "./StopContactNameListItem";
import { isOriginStop, isDestinationStop } from "../../../../helpers";
import { useStop } from "../../../../graphql";

export const StopContactInformationComponent: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	return (
		<List>
			<StopContactNameListItem
				stopId={stopId}
			/>
			{/* <StopEmailAddressListItem
				stopId={stopId}
			/> */}
			<StopPhoneNumberListItem
				stopId={stopId}
			/>
		</List>
	);
};

export const CurrentStopDialog: React.FC<{
	stopId: string;
	haulerId: string;
	movementId: string;
}> = ({ stopId, haulerId, movementId }) => {
	const { stop } = useStop(stopId);
	return (
		<DialogWithActivatorButton
			title="Current Stop"
			activatorButton={(
				<Button color="primary" variant="contained">
					View Current Stop
				</Button>
			)}
		>
			<Box gap="small">
				<Heading level="3" margin="none">
					Pickup Instructions
				</Heading>
				<List>
					<StopAddressComponent
						stopId={stopId}
					/>
					<StopInstructionsComponent
						hideIfNotProvided
						stopId={stopId}
						isOriginStop={!!stop && isOriginStop(stop)}
					/>
					<StopParkingInstructionsComponent
						stopId={stopId}
						hideIfNotProvided
					/>
				</List>
				<Heading level="3" margin="none">
					Contact Information
				</Heading>
				<StopContactInformationComponent
					stopId={stopId}
				/>
				{stop && isOriginStop(stop) && (
					<OriginStopPickupDetailsComponent
						stopId={stopId}
						haulerId={haulerId}
						movementId={movementId}
					/>
				)}
				{stop && isDestinationStop(stop) && (
					<DestinationStopDropoffDetailsComponent
						stopId={stopId}
						haulerId={haulerId}
						movementId={movementId}
					/>
				)}
			</Box>
		</DialogWithActivatorButton>
	);
};

export const OriginStopPickupDetailsComponent: React.FC<{
	stopId: string;
	haulerId: string;
	movementId: string;
}> = ({ stopId, haulerId, movementId }) => {
	return (
		<Box>
			<Heading level="3" margin="none">
				Items to Pickup
			</Heading>
			<StopIncomingItemsComponent
				stopId={stopId}
				haulerId={haulerId}
				movementId={movementId}
			/>
		</Box>
	);
};

export const DestinationStopDropoffDetailsComponent: React.FC<{
	stopId: string;
	haulerId: string;
	movementId: string;
}> = ({ stopId, haulerId, movementId }) => {
	return (
		<Box>
			<Heading level="3" margin="none">
				Items to Dropoff
			</Heading>
			<StopOutgoingItemsComponent
				stopId={stopId}
				haulerId={haulerId}
				movementId={movementId}
			/>
		</Box>
	);
};