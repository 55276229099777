import { gql } from "@apollo/client";

export const Order_QuotingFragment = gql`
	fragment Order_QuotingFragment on Order {
		id
		quotes {
			id
			...QuoteFragment
		}
	}
`;
