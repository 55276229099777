import { gql } from "../../../__generated__";

export const FetchProductRelationships = gql(/* GraphQL */ `
	query FetchProductRelationships(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...ProductRelationshipsFragment
		}
	}
`);