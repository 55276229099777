import { gql } from "@apollo/client";

export const Order_HeaderFragment = gql`
	fragment Order_HeaderFragment on Order {
		id
		number
		status
		createdAt
		updatedAt
		startedAt
		submittedAt
		completedAt
		cancelledAt
		productCount
		scheduled
		scheduledAt
		schedulePending
		isCustomerPlaced
	}
`;