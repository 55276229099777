import moment from "moment-timezone";
import { useMemo } from "react";
import { Pickup_HeaderFragmentFragment } from "../../graphql/__generated__/graphql";


export function useScheduledDate(pickup: Pickup_HeaderFragmentFragment | null, format?: string) {
	return useMemo(() => {
		if(!pickup?.scheduledDate) {
			return "Not Scheduled";
		}

		return moment(pickup.scheduledDate, "YYYY-MM-DD").format(format ?? "dddd, MMMM Do");
	}, [ pickup, format ]);
}
