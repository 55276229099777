import { useFragment } from "@apollo/client";
import { PaymentMethodFragmentFragment } from "../../__generated__/graphql";
import { PaymentMethodFragment } from "../payment/fragments/PaymentMethod";

export function usePaymentMethodFragment(paymentMethodId: string) {
	return useFragment<PaymentMethodFragmentFragment>({
		fragment: PaymentMethodFragment,
		fragmentName: "PaymentMethodFragment",
		from: {
			__typename: "PaymentMethod",
			id: paymentMethodId
		}
	});
}
