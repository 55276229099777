import { gql } from "@apollo/client";
import { Product_MediaFragment, Product_HeaderFragment, Product_ClassificationFragment, ProductFragment, ProductMedia_HeaderFragment } from "../fragments";
import { Size_DefaultFragment, Type_DefaultFragment } from "../../classification";

export const ProductClassificationFragmentHelper = gql`
	${Type_DefaultFragment}
	${Size_DefaultFragment}
	${Product_ClassificationFragment}
`;

export const ProductFragmentHelper = gql`
	${ProductFragment}
	${Product_MediaFragment}
	${ProductMedia_HeaderFragment}
	${Product_HeaderFragment}
	${ProductClassificationFragmentHelper}
`;