import { useQuery } from "@apollo/client";
import { FindItemTypes, FindPricedItemTypes } from "../graphql";
import { useEffect } from "react";
import { useFeature } from "../features/instance/hooks/useFeature";
import { InstanceFeature } from "../graphql/__generated__/graphql";
import { FetchPricedItemType } from "../graphql/documents/classification/queries/FetchPricedItemType";

export function useClassification(includeHidden = false) {
	const { data, error, loading } = useQuery(FindItemTypes, {
		variables: { includeHidden }
	});

	useEffect(() => {
		if(error) {
			console.error("Failed to load item types", error);
		}
	}, [ error ]);

	return {
		loading,
		data,
		types: data?.FindItemTypes ?? []
	};
}

export function usePricedTypes(
	partnerId: string,
	useCustomScheduleMargin?: boolean
) {
	const { data, error, loading } = useQuery(FindPricedItemTypes, {
		skip: !partnerId,
		variables: {
			partnerId,
			useCustomScheduleMargin
		}
	});

	useEffect(() => {
		if(error) {
			console.error("Failed to load priced item types", error);
		}
	}, [ error ]);

	return {
		loading,
		data,
		types: data?.FindItemTypes ?? []
	};
}

export function usePricedType(
	typeId: string,
	partnerId: string,
	useCustomScheduleMargin?: boolean
) {
	const { data, error, loading } = useQuery(FetchPricedItemType, {
		skip: !partnerId || !typeId,
		variables: {
			typeId,
			partnerId,
			useCustomScheduleMargin
		}
	});

	useEffect(() => {
		if(error) {
			console.error("Failed to load priced item type", error);
		}
	}, [ error ]);

	return {
		loading,
		data,
		type: data?.FetchItemType ?? null
	};

}

export function usePricedTypesIfEnabled(partnerId: string, useCustomScheduleMargin?: boolean) {
	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);

	const types = useClassification();
	const pricedTypes = usePricedTypes(partnerId, useCustomScheduleMargin);

	return (isUpfrontPricingEnabled)
		? pricedTypes
		: types;
}