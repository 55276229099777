import { gql } from "../../../__generated__";


export const CompleteStop = gql(/* GraphQL */ `
	mutation CompleteStop($stopId: String!) {
		CompleteStop(stopId: $stopId) {
			id
		}
	}
`);
