import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Oven: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m984.47 113.41h-44.527v-18.27c0-32.852-26.723-59.578-59.578-59.578h-149.75c-32.859 0-59.578 26.73-59.578 59.578v18.27h-142.07v-18.27c0-32.852-26.723-59.578-59.578-59.578h-149.75c-32.852 0-59.578 26.73-59.578 59.578v18.27h-44.535c-44.453 0-80.617 36.164-80.617 80.617v944.18c0 14.496 11.754 26.25 26.25 26.25h877.68c14.496 0 26.25-11.754 26.25-26.25l-0.003907-944.18c0.007813-44.441-36.156-80.609-80.609-80.609zm-275.94-18.27c0-12.18 9.9062-22.078 22.078-22.078h149.75c12.172 0 22.078 9.9062 22.078 22.078v18.27h-193.91zm-410.98 0c0-12.18 9.9062-22.078 22.078-22.078h149.75c12.172 0 22.078 9.9062 22.078 22.078v18.27h-193.91zm-82.027 70.77h768.95c15.504 0 28.117 12.613 28.117 28.117v153.59h-825.18v-153.6c0-15.5 12.613-28.109 28.117-28.109zm-28.117 946.04v-726.84h825.18v726.83h-825.18z" />
		<path
			d="m330.64 317.47c33.863 0 61.41-27.547 61.41-61.41 0-33.863-27.547-61.41-61.41-61.41s-61.41 27.547-61.41 61.41c-0.007813 33.863 27.547 61.41 61.41 61.41zm0-85.32c13.184 0 23.91 10.727 23.91 23.91s-10.727 23.91-23.91 23.91c-13.184 0-23.91-10.727-23.91-23.91-0.007813-13.188 10.719-23.91 23.91-23.91z" />
		<path
			d="m510.22 317.47c33.863 0 61.41-27.547 61.41-61.41 0-33.863-27.547-61.41-61.41-61.41s-61.41 27.547-61.41 61.41c-0.007813 33.863 27.539 61.41 61.41 61.41zm0-85.32c13.184 0 23.91 10.727 23.91 23.91s-10.727 23.91-23.91 23.91-23.91-10.727-23.91-23.91c-0.007813-13.188 10.715-23.91 23.91-23.91z" />
		<path
			d="m689.78 317.47c33.871 0 61.41-27.547 61.41-61.41 0-33.863-27.547-61.41-61.41-61.41s-61.41 27.547-61.41 61.41c0 33.863 27.551 61.41 61.41 61.41zm0-85.32c13.184 0 23.91 10.727 23.91 23.91s-10.734 23.91-23.91 23.91-23.91-10.727-23.91-23.91c0-13.188 10.727-23.91 23.91-23.91z" />
		<path
			d="m869.36 317.47c33.871 0 61.41-27.547 61.41-61.41 0-33.863-27.547-61.41-61.41-61.41s-61.41 27.547-61.41 61.41c0 33.863 27.547 61.41 61.41 61.41zm0-85.32c13.184 0 23.91 10.727 23.91 23.91s-10.734 23.91-23.91 23.91-23.91-10.727-23.91-23.91c0-13.188 10.727-23.91 23.91-23.91z" />
		<path
			d="m386.11 473.71h427.77c14.496 0 26.25-11.754 26.25-26.25s-11.754-26.25-26.25-26.25h-427.77c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25z" />
		<path
			d="m845.02 513.47h-490.68c-37.777 0-68.512 30.734-68.512 68.512v352.39c0 37.777 30.734 68.512 68.512 68.512h490.68c37.77 0 68.504-30.734 68.504-68.512l0.003907-352.39c0-37.777-30.734-68.504-68.508-68.504zm16.008 420.9c0 8.8281-7.1758 16.012-16.004 16.012l-490.68 0.003907c-8.8281 0-16.012-7.1836-16.012-16.012v-352.4c0-8.8281 7.1836-16.012 16.012-16.012h490.68c8.8281 0 16.004 7.1836 16.004 16.012z" />
	</g>
</svg>
			</SvgIcon>
		);
	};