import React from "react";
import { Route, Routes } from "react-router-dom";
import { OnboardingController } from "./controller";
import { usePageTitle } from "../../hooks";

export const OnboardingRouter: React.FC = () => {
	usePageTitle("Onboarding");
	return (
		<Routes>
			<Route path="" element={<OnboardingController />} />
		</Routes>
	);
};