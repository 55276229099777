import { gql } from "@apollo/client";

export const EstimatePreview_HeaderFragment = gql`
	fragment EstimatePreview_HeaderFragment on EstimatePreview {
		subtotalAmount
		discountAmount
		creditUsedAmount
		creditRemainingAmount
		taxAmount
		totalAmount
		discounts {
			amount
			description
		}
	}
`;