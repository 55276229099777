import { gql } from "@apollo/client";

export const Product_StopDetailedFragment = gql`
	fragment Product_StopDetailedFragment on Product {
		...Product_HeaderFragment
		...Product_MediaFragment
		...Product_InventoryFragment
		...Product_UserContactFragment
		...Product_ClassificationFragment
		...Product_StopConfirmationFragment
	}
`;

export const Stop_ProductsFragment = gql`
	fragment Stop_ProductsFragment on Stop {
		incomingCount
		outgoingCount
		incoming {
			...Product_StopDetailedFragment
		}
		outgoing {
			...Product_StopDetailedFragment
		}
	}
`;

//const Testing: DocumentType<typeof Product_StopDetailedFragment>;
//Testing;

