import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBuilding, useReferenceMedia, useWasteAudit, useWasteAuditMedia } from "../hooks";
import { useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { BasicListItem, DialogWithActivatorButton, HeadingWithIcon, Pagination, ViewContainer, VisuallyHiddenInput } from "../../../../components";
import { Box, Grid, GridExtendedProps, Heading, Image, Spinner } from "grommet";
import { AddAPhoto, Assessment, CloudUpload, Edit, PieChart } from "@mui/icons-material";
import { Button, Chip, Divider, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, MobileStepper, Typography, useTheme } from "@mui/material";
import moment from "moment-timezone";
import { fileToBase64, formatNumber } from "../../../../helpers";
import { WasteAuditUpdateDialog } from "../components";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectWasteAuditState, setAuditDetails, setCurrentStepIndex, setMediaId, WasteAuditState } from "../../../../store/audit";
import { FormContainer, SelectElement, TextFieldElement, useForm, UseFormReturn } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { useOnboardingMaterials } from "../../../onboarding/hooks";
import { useToggleForceClose } from "../../components";

export const ResposiveGrid: React.FC<{
	shrink?: boolean;
	children: React.ReactNode | React.ReactNode[];
} & GridExtendedProps> = ({ shrink, children, ...props }) => (
	<Grid
		gap={props.gap || "small"}
		columns={shrink
			? { count: 1, size: "auto" }
			: props.columns || { count: 2, size: "auto" }
		}
	>
		{children}
	</Grid>
);

export const AuditDetailsView: React.FC = () => {
	const { width } = useWindowDimensions();
	const auditId = useRequiredPathParam("auditId", "/admin/audits");
	const { audit, loading } = useWasteAudit(auditId);

	const lineItems = useMemo(() => {
		return audit?.lineItems || [];
	}, [ audit ]);

	const lineItemsByMaterial = useMemo(() => {
		const map = lineItems.reduce((acc, item) => {
			const { material, totalWeightGeneratedPounds } = item;
			const { id, name } = material;
			const existing = acc.get(id);
			if(existing) {
				existing.totalWeightGeneratedPounds += totalWeightGeneratedPounds;
				existing.lineItems.push(item);
			}
			else {
				acc.set(id, {
					id,
					name,
					totalWeightGeneratedPounds,
					lineItems: [ item ]
				});
			}
			return acc;
		}, new Map<string, { id: string; name: string; totalWeightGeneratedPounds: number; lineItems: typeof lineItems; }>());

		// return Array.from(map.values()).sort((a, b) => b.totalWeightGeneratedPounds - a.totalWeightGeneratedPounds);
		return [
			{ id: "1", name: "Food Waste", totalWeightGeneratedPounds: 24, lineItems: [ { totalWeightGeneratedPounds: 24 } ] },
			{ id: "2", name: "Plastic", totalWeightGeneratedPounds: 14, lineItems: [ { totalWeightGeneratedPounds: 14 } ] },
			{ id: "3", name: "Mixed Waste", totalWeightGeneratedPounds: 62, lineItems: [ { totalWeightGeneratedPounds: 62 } ] }
		];
	}, [ lineItems ]);

	// const totalWeightInLineItems = useMemo(() => {
	// 	return lineItems.reduce((acc, item) => acc + item.totalWeightGeneratedPounds, 0);
	// }, [ lineItems ]);

	const totalWeightInLineItems = useMemo(() => {
		return lineItemsByMaterial.reduce((acc, item) => acc + item.totalWeightGeneratedPounds, 0);
	}, [ lineItemsByMaterial ]);

	const getMaterialPercentage = useCallback((weight: number) => {
		return formatNumber((weight / totalWeightInLineItems) * 100, 2);
	}, [ totalWeightInLineItems ]);

	const theme = useTheme();

	return (
		<ViewContainer>
			<Box gap="small">
				<ResposiveGrid shrink={width < 1050} gap="medium">
					<Box gap="small">
						<Box direction="row" justify="between" height="40px">
							<HeadingWithIcon
								text={"Audit Details"}
								props={{
									level: "3",
									margin: "none",
								}}
								icon={<Assessment color="primary" />}
							/>
							<Button
								startIcon={(
									<Edit />
								)}
								size="small"
								variant="contained"
								style={{ visibility: "hidden" }}
							>
								Update
							</Button>
						</Box>
						{loading && (<LinearProgress />)}
						{!loading && (<Divider sx={{ borderColor: theme.palette.primary.main }} />)}
						<Pagination pageSize={10}>
							<BasicListItem
								loading={loading}
								label="Audit Name"
								value={audit?.name}
								action={(
									<WasteAuditUpdateDialog
										activatorButton={(
											<IconButton>
												<Edit />
											</IconButton>
										)}
										auditId={auditId}
									/>
								)}
							/>
							<BasicListItem
								loading={loading}
								label="Started Date"
								value={moment.tz(audit?.createdAt, "UTC").format("dddd MMMM Do, YYYY")}
							/>
							<BasicListItem
								loading={loading}
								label="Completed Date"
								value={audit?.completedAt
									? moment.tz(audit?.completedAt, "UTC").format("dddd MMMM Do, YYYY")
									: <Chip size="small" label="Under Review" color="info" />
								}
								action={!audit?.completed && (
									<Button
										size="small"
										color="primary"
										variant="contained"
									>
										Complete
									</Button>
								)}
							/>
						</Pagination>
					</Box>
					<Box gap="small">
						<Box direction="row" justify="between" height="40px">
							<HeadingWithIcon
								text={"Waste Composition"}
								props={{
									level: "3",
									margin: "none",
								}}
								icon={<PieChart color="primary" />}
							/>
							<WasteAuditUploadDialog
								auditId={auditId}
								activatorButton={(
									<Button
										startIcon={(
											<Edit />
										)}
										size="small"
										variant="contained"
									>
										Update
									</Button>
								)}
							/>
						</Box>
						{loading && (<LinearProgress />)}
						{!loading && (<Divider sx={{ borderColor: theme.palette.primary.main }} />)}
						<Pagination pageSize={5}>
							{lineItemsByMaterial.map(material => (
								<ListItemButton
									divider
									key={material.id}
									style={{ minHeight: "80px" }}
								>
									<ListItemIcon>
										<Assessment color="primary" />
									</ListItemIcon>
									<ListItemText>
										<Typography fontWeight="bold">
											{material.name}
										</Typography>
									</ListItemText>
									<ListItemSecondaryAction>
										<Box direction="row" align="center" gap="small">
											{/* <Typography fontWeight="bold" variant="body2">
												{formatNumber(material.totalWeightGeneratedPounds)} lb(s)
											</Typography> */}
											<Chip
												label={
													<Typography variant="caption">
														{getMaterialPercentage(material.totalWeightGeneratedPounds)}%
													</Typography>
												}
											/>
										</Box>
									</ListItemSecondaryAction>
								</ListItemButton>
							))}
						</Pagination>
					</Box>
				</ResposiveGrid>
			</Box>
		</ViewContainer>
	);
};

export const WasteAuditImageUploadStep: React.FC = () => {
	const dispatch = useAppDispatch();
	const { auditId, mediaId } = useAppSelector(selectWasteAuditState);

	const ref = React.createRef<HTMLInputElement>();
	const { media, loading } = useReferenceMedia(mediaId);
	const { upload, isUploading } = useWasteAuditMedia();


	function onFileUploaded(file: File): void {
		fileToBase64(file).then(base64Content => {
			return upload({
				name: file.name,
				content: base64Content,
				contentType: file.type,
				referenceId: auditId,
				context: ProductMediaContext.Default
			});
		}).then((data) => {
			const mediaId = data?.CreateReferenceMedia.id;
			mediaId && dispatch(setMediaId(mediaId));
		});
	}

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Upload Image(s)
				</Heading>
				<Typography variant="body2">
					Upload an image and our Waste Inspector tool with automatically analyze the waste composition of your image. Make sure the contents of the container are spread out (on something like a tarp) and visible in the image.
				</Typography>
			</Box>
			<Box gap="small">
				{!!media && (
					<Box
						height="small"
						background="light-1"
						align="center" justify="center"
					>
						<Image
							key={media.id}
							src={media.contentUrl}
							fit="contain"
						/>
					</Box>
				)}
				{!media && (
					<Box
						round
						height="small"
						background="light-1"
						align="center" justify="center"
					>
						<IconButton
							onClick={() => {
								ref.current?.click();
							}}
						>
							<AddAPhoto
								color="primary"
								fontSize="large"
							/>
						</IconButton>
					</Box>
				)}
				<LoadingButton
					color="primary"
					variant="contained"
					loading={isUploading}
					endIcon={<CloudUpload />}
					loadingPosition="end"
					onClick={(event) => {
						event.stopPropagation();
						ref.current?.click();
					}}
				>
					Upload Image
					<VisuallyHiddenInput
						ref={ref}
						type="file"
						accept=".png, .jpg, .jpeg"
						style={{ display: "none" }}
						onClick={(event) => {
							event.stopPropagation();
						}}
						onChange={(event) => {
							if(event.target.files) {
								for(const file of event.target.files) {
									onFileUploaded(file);
								}
							}
						}} />
				</LoadingButton>
			</Box>
		</Box>
	);
};

export const WasteAuditMaterialConfirmationStep: React.FC<{
	formContext: UseFormReturn<WasteAuditState>;
}> = ({ formContext }) => {
	const dispatch = useAppDispatch();
	const { mediaId } = useAppSelector(selectWasteAuditState);
	const { materials } = useOnboardingMaterials();
	const { media, loading: mediaLoading } = useReferenceMedia(mediaId);

	const materialsState = useMemo(() => {
		return [
			{
				id: "mixed-waste",
				name: "Mixed Waste",
				estimatedRatio: .62,
				estimatedWeightPounds: 0,
				estimatedVolumeCubicFeet: 0
			},
			{
				id: "food-waste",
				name: "Food Waste",
				estimatedRatio: .24,
				estimatedWeightPounds: 0,
				estimatedVolumeCubicFeet: 0
			},
			{
				id: "plastic",
				name: "Plastic",
				estimatedRatio: .14,
				estimatedWeightPounds: 0,
				estimatedVolumeCubicFeet: 0
			}
		].sort((a, b) => b.estimatedRatio - a.estimatedRatio);
	}, []);

	const [ detectionLoading, setDetectionLoading ] = useState(false);

	useEffect(() => {
		setDetectionLoading(true);
		setTimeout(() => {
			setDetectionLoading(false);
		}, 2000);
	}, [ media ]);

	const loading = useMemo(() => {
		return mediaLoading || detectionLoading;
	}, [ detectionLoading, mediaLoading ]);

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Material Composition
				</Heading>
				<Typography variant="body2">
					We've automatically analyzed your photo for the material composition present. This is still under review but your estimated results are below.
				</Typography>
			</Box>
			<List>
				{materialsState.map(material => (
					<ListItem
						key={material.id}
						divider
						style={{ minHeight: "100px" }}
					>
						<ListItemIcon>
							<Assessment color="primary" />
						</ListItemIcon>
						<ListItemText>
							<Typography fontWeight="bold">
								{material.name}
							</Typography>
						</ListItemText>
						<ListItemSecondaryAction>
							{loading && (
								<Spinner />
							)}
							{!loading && (
								<Typography>
									{formatNumber(material.estimatedRatio * 100, 2)}%
								</Typography>
							)}
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Box>
	);
};


export const WasteAuditMaterialCompositionStep: React.FC<{
	formContext: UseFormReturn<WasteAuditState>;
}> = ({ formContext }) => {
	const { materials } = useAppSelector(selectWasteAuditState);

	const [ expanded, setExpanded ] = useState("");

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Review
				</Heading>
				<Typography variant="body2">
					Submit your results for review by the Rego team who will review the waste audit results for accuracy.
				</Typography>
			</Box>
			{/* <List disablePadding>
				{materials.map(material => (
					<AccordionController
						key={material.id}
						name="material"
						isExpanded={material.id === expanded}
						onChange={(event, expanded) => {
							setExpanded(expanded ? material.id : "");
						}}
						summary={(
							<Box direction="row" align="center">
								<ListItemIcon>
									<Assessment color="primary" />
								</ListItemIcon>
								<Typography fontWeight="bold">
									{material.name}
								</Typography>
							</Box>
						)}
						details={(
							<Box>
								DETAILS
							</Box>
						)}
					/>
				))}
			</List> */}
		</Box>
	);
};

export const WasteAuditIntroductionStep: React.FC<{
	auditId: string;
	formContext: UseFormReturn<WasteAuditState>;
}> = ({ auditId, formContext }) => {
	const { audit } = useWasteAudit(auditId);
	const { building } = useBuilding(audit?.partner.id || "");

	const [ containerId, setContainerId ] = useState("");

	const containers = useMemo(() => {
		return building?.containers || [];
	}, [ building ]);

	const container = useMemo(() => {
		return containers.find(container => container.id === containerId);
	}, [ containerId, containers ]);

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Get Started
				</Heading>
				<Typography variant="body2">
					Let's get your waste audit started! Please select the container or bin you are collecting waste from.
				</Typography>
			</Box>
			<SelectElement
				label="Container"
				name="containerId"
				InputProps={{
					value: container?.name,
					onChange: (event) => {
						formContext.setValue("containerId", event.target.value);
						setContainerId(event.target.value);
					},
				}}
				options={containers.map(container => ({ id: container.id, label: container.name }))}
			/>
			{container && (
				<TextFieldElement
					label="Container Size"
					name="containerSize"
					InputProps={{
						value: container.volume,
						endAdornment: (
							<InputAdornment position="end">
								{container.volumeUnit}
							</InputAdornment>
						)
					}}
				/>
			)}
			{container && (
				<TextFieldElement
					label="Container Type"
					name="containerType"
					InputProps={{
						value: container.container.name
					}}
				/>
			)}
			{container && (
				<TextFieldElement
					label="Container Disposition"
					name="containerDisposition"
					InputProps={{
						value: container.disposition
					}}
				/>
			)}
			{/* <ToggleButtonGroupElement
				exclusive
				required
				validation={{
					validate: value => {
						if(value === null) {
							return "This field is required.";
						}
						return;
					}
				}}
				color="primary"
				name="containerDisposition"
				label="Container Type"
				helperText="What was supposed to be placed in this container?"
				options={options}
			/> */}
		</Box>
	);
};

export const WasteAuditUploadDialog: React.FC<{
	auditId: string;
	activatorButton: React.ReactNode;
}> = ({ auditId, activatorButton }) => {
	const totalSteps = 4;
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectWasteAuditState);

	const { audit } = useWasteAudit(auditId);

	useEffect(() => {
		if(audit) {
			dispatch(setAuditDetails({ id: audit.id, name: audit.name }));
		}
	}, [ audit, dispatch ]);

	const formContext = useForm({
		defaultValues: { ...state }
	});

	const hasNext = useMemo(() => {
		return state.currentStepIndex < totalSteps - 1;
	}, [ state.currentStepIndex ]);

	const hasBack = useMemo(() => {
		return state.currentStepIndex > 0;
	}, [ state.currentStepIndex ]);

	function handleBack(): void {
		if(!hasBack) return;
		dispatch(setCurrentStepIndex(state.currentStepIndex - 1));
	}

	const { forceClose, toggleForceClose } = useToggleForceClose();

	function handleSubmit(): void {
		toggleForceClose();
	}

	function handleNext(): void {
		if(!hasNext) {
			formContext.handleSubmit(handleSubmit)();
			return;
		}

		formContext.handleSubmit(() => {
			dispatch(setCurrentStepIndex(state.currentStepIndex + 1));
		})();
	}

	const content = useMemo(() => {
		switch(state.currentStepIndex) {
			case 0:
				return (
					<WasteAuditIntroductionStep
						auditId={auditId}
						formContext={formContext}
					/>
				);
			case 1:
				return (
					<WasteAuditImageUploadStep />
				);
			case 2:
				return (
					<WasteAuditMaterialConfirmationStep
						formContext={formContext}
					/>
				);
			case 3: {
				return (
					<WasteAuditMaterialCompositionStep
						formContext={formContext}
					/>
				);
			}
			default:
				return null;
		}
	}, [ auditId, formContext, state.currentStepIndex ]);

	return (
		<DialogWithActivatorButton
			title="Waste Audit"
			forceClose={forceClose}
			activatorButton={activatorButton}
			actions={(
				<MobileStepper
					steps={totalSteps}
					position="static"
					activeStep={state.currentStepIndex}
					backButton={(
						<Button variant="outlined" onClick={handleBack} disabled={!hasBack}>
							Back
						</Button>
					)}
					nextButton={(
						<Button variant="contained" onClick={handleNext}>
							{hasNext ? "Next" : "Submit"}
						</Button>
					)}
				/>
			)}
		>
			<FormContainer formContext={formContext}>
				{content}
			</FormContainer>
		</DialogWithActivatorButton>
	);
};