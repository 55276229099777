import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Box, Image, Text } from "grommet";
import { Close } from "@mui/icons-material";

export interface ImagePreviewModalProps {
	onClose: () => void;
	media: { name: string, contentUrl: string; };
}

export const ImagePreviewModal: React.FC<ImagePreviewModalProps> = (props) => {
	useEffect(() => {
		console.log("media", props.media);
	}, [ props.media ]);
	return (
		<Dialog open={true} fullScreen fullWidth>
			<DialogTitle>
				<Box direction="row" justify="between">
					<Box justify="center">

					</Box>
					<IconButton onClick={props.onClose}>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box pad="small" align="center" justify="center" gap="small">
					<Image
						fit="contain"
						src={props.media.contentUrl} />
					<Text>
						{props.media.name}
					</Text>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
