import { gql } from "@apollo/client";

export const Stop_OriginDestinationFragment = gql`
	fragment Stop_OriginDestinationFragment on Stop {
		address {
			...AddressFragment
		}
		outlet {
			...OutletFragment
		}
	}
`;
