import { Box, Heading } from "grommet";
import { List, Typography } from "@mui/material";
import React from "react";
import { PickupRouteListItem, PickupRouteListItemProps } from "./PickupRouteListItem";

interface PickupRouteContainerProps {
	pickupId: string;
	stops: PickupRouteListItemProps[];
}

export const PickupRouteContainer: React.FC<PickupRouteContainerProps> = (props) => {
	return (
		<Box gap="small">
			<Box>
				<Heading level="3" margin="none">
					Scheduled Stops
				</Heading>
				<Box direction="row" gap="small" margin={{ left: "xsmall" }}>
					<Typography variant="caption">
						{props.stops.length} stop(s)
					</Typography>
					<Typography variant="caption">
						|
					</Typography>
					<Typography variant="caption">
						{props.stops.reduce((acc, stop) => acc + stop.incomingCount, 0)} item(s)
					</Typography>
				</Box>
			</Box>
			<List>
				{props.stops.map(stop => (
					<PickupRouteListItem
						id={stop.id}
						key={stop.id}
						index={stop.index}
						outlet={stop.outlet}
						incomingCount={stop.incomingCount}
						outgoingCount={stop.outgoingCount}
						address={stop.address}
					/>
				))}
			</List>
		</Box>
	);
};
