import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Wardrobe: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m506.25 565.93c12.586 0 22.816-10.23 22.816-22.816s-10.23-22.816-22.816-22.816c-12.578 0-22.809 10.23-22.809 22.816 0 12.59 10.23 22.816 22.809 22.816z"/>
  <path d="m1000.9 85.613h-205.52c-40.621-32.309-91.078-50.004-143.18-50.004h-104.36c-52.102 0-102.56 17.684-143.18 50.004h-205.52c-14.496 0-26.25 11.754-26.25 26.25v105c0 14.496 11.754 26.25 26.25 26.25h21.141v824.72c0 14.496 11.754 26.25 26.25 26.25h45.781v51.547c0 10.359 8.3906 18.75 18.75 18.75h98.918c10.359 0 18.75-8.3906 18.75-18.75v-51.547h342.58v51.547c0 10.359 8.3906 18.75 18.75 18.75h98.918c10.359 0 18.75-8.3906 18.75-18.75v-51.547h45.781c14.496 0 26.25-11.754 26.25-26.25v-824.72h21.141c14.496 0 26.25-11.754 26.25-26.25v-105c0.007812-14.5-11.754-26.25-26.242-26.25zm-775.52 52.5h188.75c6.3281 0 12.441-2.2891 17.227-6.4414 32.309-28.086 73.664-43.559 116.48-43.559l52.184-0.003906h52.184c42.809 0 84.172 15.473 116.47 43.559 4.7773 4.1562 10.898 6.4414 17.227 6.4414h188.75v52.5h-21.141l-353.49 0.003906h-374.62v-52.5zm551.38 333.44 61.492 59.121h-122.98zm-18.75-33.988-102.3 98.348c-5.5117 5.3008-7.2617 13.426-4.4023 20.52 2.8633 7.0938 9.7422 11.746 17.391 11.746h216.11c7.6562 0 14.535-4.6484 17.391-11.746 2.8594-7.0938 1.1094-15.219-4.4023-20.52l-102.3-98.348v-28.199h131.74v433.75h-299.18v-433.75h129.96zm-182.46-194.45v600h-302.77v-600zm-184.32 883.77h-61.418v-32.797h61.418zm479 0h-61.418v-32.797h61.418zm-597.45-85.297v-145.98h654.47v145.98zm654.47-669.72h-131.74v-18.75c0-10.359-8.3906-18.75-18.75-18.75s-18.75 8.3906-18.75 18.75v18.75h-129.96v-128.75h299.2v128.75z"/>
  <path d="m682.5 949.85h-165c-10.359 0-18.75 8.3906-18.75 18.75 0 10.359 8.3906 18.75 18.75 18.75h165c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3984-18.75-18.75-18.75z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};