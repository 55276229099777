import React, { createContext, ReactNode, useContext, useState } from "react";
import { noop } from "../helpers";
import { ConfirmationDialog } from "../components";

interface ConfirmationDialogProps {
	title: string;
	content: string;
	onConfirm: () => void;
	onCancel: () => void;
	cancelButtonLabel: string;
	confirmButtonLabel: string;
}

interface DialogContextType {
	dialog: ConfirmationDialogProps;
	updateDialog: (dialog: ConfirmationDialogProps) => void;
}

export const DialogContext = createContext<DialogContextType>({
	dialog: {
		title: "",
		content: "",
		onConfirm: noop,
		onCancel: noop,
		cancelButtonLabel: "",
		confirmButtonLabel: ""
	},
	updateDialog: noop
});

export const DialogProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const [ context, setContext ] = useState({
		title: "",
		content: "",
		onConfirm: noop,
		onCancel: noop,
		cancelButtonLabel: "",
		confirmButtonLabel: ""
	});

	return (
		<DialogContext.Provider value={{
			dialog: context,
			updateDialog: setContext
		}}>
			{children}
			{context.title && context.content && (
				<ConfirmationDialog
					title={context.title}
					content={context.content}
					onConfirm={() => {
						context.onConfirm();
						setContext({
							title: "",
							content: "",
							onConfirm: noop,
							onCancel: noop,
							cancelButtonLabel: "",
							confirmButtonLabel: ""
						});
					}}
					onCancel={() => {
						context.onCancel();
						setContext({
							title: "",
							content: "",
							onConfirm: noop,
							onCancel: noop,
							cancelButtonLabel: "",
							confirmButtonLabel: ""
						});
					}}
					cancelButtonLabel={context.cancelButtonLabel}
					confirmButtonLabel={context.confirmButtonLabel}
				/>
			)}
		</DialogContext.Provider>
	);
};

export function useConfirmationDialog() {
	const { dialog, updateDialog } = useContext(DialogContext);

	return {
		showDialog: (props: Omit<ConfirmationDialogProps, "cancelButtonLabel" | "confirmButtonLabel"> & { cancelButtonLabel?: string, confirmButtonLabel?: string; }) => {
			updateDialog({
				title: props.title,
				content: props.content,
				onConfirm: props.onConfirm,
				onCancel: props.onCancel,
				cancelButtonLabel: props.cancelButtonLabel || "Cancel",
				confirmButtonLabel: props.confirmButtonLabel || "Confirm"
			});
		},
		hideDialog: () => {
			updateDialog({
				title: "",
				content: "",
				onConfirm: noop,
				onCancel: noop,
				cancelButtonLabel: "",
				confirmButtonLabel: ""
			});
		},
	};
}