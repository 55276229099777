import moment from "moment";
import { regions } from "./regions";
import random from "random";
import { buildings } from "./buildings";

export interface Order {
	date: Date;
	scheduledDate: Date;
	zipCode: string;
	weightDiverted: number;
	weightGenerated: number;
	isMultifamily?: boolean;
	building: string;
}

export const orders: Order[] = [];

const generateOrder = (date: Date, zipCode: string, zipCodeRandomFactor: number) => {
	const diversion = Math.min(
		random.normal(0.9, 0.5)() * (1 + zipCodeRandomFactor),
		95
	);
	const weightGenerated = random.normal(300, 30)();

	const weightDiverted = weightGenerated * diversion;

	const turnaroundTime = random.normal(7, 5)();

	const isMultifamily = Math.random() < 0.10;
	const building = (isMultifamily ? random.choice(buildings) : "") ?? "";

	return {
		date,
		scheduledDate: moment(date).add(turnaroundTime, "days").toDate(),
		zipCode,
		weightDiverted,
		weightGenerated,
		isMultifamily,
		building
	};
};

function randomInt(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

// Define a function to determine if an order is picked up on a given day
function isOrderPickedUp(): boolean {
	// Assume a 90% chance that an order is picked up on any given day
	return Math.random() < 0.9;
}

for(const region of regions) {
	const zipCode = region.zipCode;
	const population = region.population;
	const ordersPerDay = Math.floor(population / 10000 / 12); // Assume 1 order per 10000 people per day

	const startDate = moment().subtract(31 * 6, "days");
	const endDate = moment().add(14, "days");

	while(startDate.unix() < endDate.unix()) {
		if(isOrderPickedUp()) {
			const numOrders = randomInt(Math.min(ordersPerDay - 5, 0), ordersPerDay + 5); // Allow some variation in the number of orders
			for(let i = 0; i < numOrders; i++) {
				orders.push(generateOrder(startDate.toDate(), zipCode, random.normal(0, .1)()));
			}
		}

		startDate.add(1, "days");
	}
}