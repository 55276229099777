import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Chest: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1150 650c13.824 0 25-11.176 25-25v-500c0-13.824-11.176-25-25-25h-400v-50c0-13.824-11.176-25-25-25h-250c-13.824 0-25 11.176-25 25v50h-400c-13.824 0-25 11.176-25 25v500c0 13.824 11.176 25 25 25h75v25h-75c-13.824 0-25 11.176-25 25v450c0 13.824 11.176 25 25 25h1100c13.824 0 25-11.176 25-25v-450c0-13.824-11.176-25-25-25h-75v-25zm-650-575h200v25h-200zm625 650v400h-1050v-400h375v100c0 13.824 11.176 25 25 25h250c13.824 0 25-11.176 25-25v-100zm-950-50v-25h150v25zm200 0v-25h450v25zm325 50v75h-200v-75zm175-50v-25h150v25zm-800-75v-450h1050v450zm1e3 -425h-950c-13.824 0-25 11.176-25 25v350c0 13.824 11.176 25 25 25h950c13.824 0 25-11.176 25-25v-350c0-13.824-11.176-25-25-25zm-25 350h-900v-300h900z"/>
</svg>
			</SvgIcon>
		);
	};