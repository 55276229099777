export interface RecentItemRecord {
	id: string;
	timestamp: number;
}

export const UserPersonalizationService = {
	recentItemSavedLimit: 10,

	getItem(key: string): RecentItemRecord[] {
		try {
			const result = localStorage.getItem(key);
			if(result) {
				return (JSON.parse(result) || []) as RecentItemRecord[];
			}

			return [];
		}
		catch(e) {
			return [];
		}
	},

	recordItem(key: string, id: string): void {
		const items = this.getItem(key);
		if(items.some(o => o.id === id)) {
			return;
		}

		const sorted = items.sort((a, b) => b.timestamp - a.timestamp);
		const updated = [
			{ id, timestamp: Date.now() },
			...sorted.slice(0, this.recentItemSavedLimit - 1)
		];

		localStorage.setItem(key, JSON.stringify(updated));
	},

	removeItem(key: string, id: string): void {
		const items = this.getItem(key);
		if(!items.some(o => o.id === id)) {
			return;
		}

		const updated = items.filter(o => o.id !== id);
		localStorage.setItem(key, JSON.stringify(updated));
	},

	recordRecentOrder(orderId: string): void {
		this.recordItem("recentOrders", orderId);
	},

	recordSavedOrder(orderId: string): void {
		this.recordItem("savedOrders", orderId);
	},

	removeSavedOrder(orderId: string): void {
		this.removeItem("savedOrders", orderId);
	},

	getRecentOrders(): RecentItemRecord[] {
		return this.getItem("recentOrders");
	},

	getSavedOrders(): RecentItemRecord[] {
		return this.getItem("savedOrders");
	},

	recordRecentPurchase(purchaseId: string): void {
		this.recordItem("recentPurchases", purchaseId);
	},

	recordSavedPurchase(purchaseId: string): void {
		this.recordItem("savedPurchases", purchaseId);
	},

	getRecentPurchases(): RecentItemRecord[] {
		return this.getItem("recentPurchases");
	},

	getSavedPurchases(): RecentItemRecord[] {
		return this.getItem("savedPurchases");
	}
};