import { gql } from "@apollo/client";

export const PricingPolicy_HeaderFragment = gql(/* GraphQL */ `
	fragment PricingPolicy_HeaderFragment on PricingPolicy {
		id
		name
		type
		margin
		marginCustomSchedule
	}
`);