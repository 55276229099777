import { Box, Heading } from "grommet";
import { DayOfWeek, IHours } from "../../../graphql/__generated__/graphql";
import { keys } from "../../../helpers";
import { StoreHoursListItem } from "./StoreHoursListItem";


export const StoreHoursContainer: React.FC<{
	name: string;
	hours: IHours;
}> = ({ name, hours }) => {

	return (
		<Box>
			<Box align="center">
				<Heading margin="none" level="4">{name}</Heading>
			</Box>
			<Box gap="xsmall">
				{keys(hours).filter(key => key !== "__typename").map((key) => (
					<StoreHoursListItem
						key={key}
						day={key as DayOfWeek}
						open={hours[ key as DayOfWeek ].open || false}
						from={hours[ key as DayOfWeek ].from || 0}
						to={hours[ key as DayOfWeek ].to || 0} />
				))}
			</Box>
		</Box>
	);
};
