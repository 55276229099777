import { SvgIcon, SvgIconProps } from "@mui/material";

	export const FilingCabinet: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m954.36 42.246h-708.72c-14.496 0-26.25 11.754-26.25 26.25v969.74c0 14.496 11.754 26.25 26.25 26.25h46.539v67.012c0 14.496 11.754 26.25 26.25 26.25h563.14c14.496 0 26.25-11.754 26.25-26.25v-67.012h46.539c14.496 0 26.25-11.754 26.25-26.25l-0.003907-969.74c0.007813-14.496-11.75-26.25-26.242-26.25zm-99.043 1063h-510.64v-40.762h510.64zm72.793-93.266h-656.22l0.003906-917.24h656.21v917.24z"/>
  <path d="m342.45 532.26h515.09c14.496 0 26.25-11.754 26.25-26.25v-340.7c0-14.496-11.754-26.25-26.25-26.25h-515.09c-14.496 0-26.25 11.754-26.25 26.25v340.7c0 14.496 11.754 26.25 26.25 26.25zm26.25-340.7h462.59v288.2h-462.59z"/>
  <path d="m342.45 967.68h515.09c14.496 0 26.25-11.754 26.25-26.25v-340.7c0-14.496-11.754-26.25-26.25-26.25h-515.09c-14.496 0-26.25 11.754-26.25 26.25v340.7c0 14.496 11.754 26.25 26.25 26.25zm26.25-340.7h462.59v288.2h-462.59z"/>
  <path d="m539.06 360.19h121.88c10.359 0 18.75-8.3906 18.75-18.75v-77.969c0-10.359-8.3906-18.75-18.75-18.75h-121.88c-10.359 0-18.75 8.3906-18.75 18.75v77.969c0 10.359 8.3906 18.75 18.75 18.75zm18.75-77.969h84.367v40.469h-84.367z"/>
  <path d="m718.12 389.09h-236.25c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h236.25c10.359 0 18.75-8.3906 18.75-18.75s-8.3984-18.75-18.75-18.75z"/>
  <path d="m539.06 795.61h121.88c10.359 0 18.75-8.3906 18.75-18.75v-77.973c0-10.359-8.3906-18.75-18.75-18.75h-121.88c-10.359 0-18.75 8.3906-18.75 18.75v77.969c0 10.352 8.3906 18.754 18.75 18.754zm18.75-77.973h84.367v40.469l-84.367 0.003906z"/>
  <path d="m718.12 824.51h-236.25c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h236.25c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3984-18.75-18.75-18.75z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};