import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Bed: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="m1077.6 681.61c0-69.609-52.031-127.18-119.2-136.13 0.1875-2.1094 0.5625-4.2188 0.5625-6.3281v-196.4c0-33.844-25.922-57.891-77.062-71.438-185.06-49.078-372.89-49.078-558.19 0-51.141 13.547-77.062 37.594-77.062 71.438v196.4c0 1.875 0.32812 3.75 0.51562 5.625-69.844 6.4688-124.74 65.344-124.74 136.87 0 5.5781 0.46875 11.156 1.1719 16.688-0.70312 1.6406-1.1719 3.4219-1.1719 5.2969v248.58c0 7.3125 5.9531 13.266 13.266 13.266h82.922c7.3125 0 13.266-5.9531 13.266-13.266v-89.109h736.26v89.109c0 7.3125 5.9531 13.266 13.266 13.266h82.922c7.3125 0 13.266-5.9531 13.266-13.266v-248.63c0-1.875-0.42187-3.7031-1.1719-5.2969 0.70312-5.5312 1.1719-11.109 1.1719-16.688zm-858.98 155.02h-69.656v-119.72h902.11v119.72zm54.562-493.87c0-7.5938 0-30.609 57.328-45.797 180.79-47.859 364.03-47.859 544.6 0 57.328 15.188 57.328 38.25 57.328 45.797v196.4c0 1.6875-0.23437 3.3281-0.46875 4.9688h-28.266c4.125-7.3594 6.6562-15.75 6.6562-24.75v-21.188c0-28.266-23.016-51.281-51.281-51.281h-193.13c-28.266 0-51.281 23.016-51.281 51.281v21.188c0 9.0469 2.5312 17.391 6.6562 24.75h-40.266c4.125-7.3594 6.6562-15.75 6.6562-24.75v-21.188c0-28.266-23.016-51.281-51.281-51.281h-191.16c-28.266 0-51.281 23.016-51.281 51.281v21.188c0 9.0469 2.5312 17.391 6.6562 24.75h-26.953c-0.23438-1.6406-0.46875-3.2812-0.46875-4.9688v-196.4zm368.02 176.63v-21.188c0-13.641 11.109-24.75 24.75-24.75h193.13c13.641 0 24.75 11.109 24.75 24.75v21.188c0 13.641-11.109 24.75-24.75 24.75h-193.13c-13.641 0-24.75-11.109-24.75-24.75zm-320.68 0v-21.188c0-13.641 11.109-24.75 24.75-24.75h191.16c13.641 0 24.75 11.109 24.75 24.75v21.188c0 13.641-11.109 24.75-24.75 24.75h-191.16c-13.641 0-24.75-11.109-24.75-24.75zm-60.562 51.281h680.21c61.172 0 110.95 49.781 110.95 110.95 0 2.9062-0.14062 5.8594-0.375 8.7656h-901.4c-0.23437-2.9062-0.375-5.8125-0.375-8.7656 0-61.172 49.781-110.95 110.95-110.95zm-54.562 368.34h-56.391v-75.797h56.391zm845.72 0h-56.391v-75.797h56.391z" />
</svg>
			</SvgIcon>
		);
	};