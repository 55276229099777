import { gql } from "@apollo/client";


export const PricingPolicy_PartnersFragment = gql(/* GraphQL */ `
	fragment PricingPolicy_PartnersFragment on PricingPolicy {
		partners {
			id
			name
		}
	}
`);
