import { useQuery } from "@apollo/client";
import { Box, Heading } from "grommet";
import { FindHaulerPendingQuotes } from "../../../../graphql";
import { useEffect, useMemo } from "react";
import { Pagination } from "../../../../components";
import { HaulerPendingQuoteListItem } from "./HaulerPendingQuoteListItem";
import { LinearProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

interface HaulerPendingQuotesControllerProps {
	haulerId: string;
}

export const HaulerPendingQuotesController: React.FC<HaulerPendingQuotesControllerProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const { data, loading, error } = useQuery(FindHaulerPendingQuotes, {
		variables: { haulerId: props.haulerId }
	});

	const quotes = useMemo(() => {
		return data?.FindHaulerPendingQuotes ?? [];
	}, [ data ]);

	useEffect(() => {
		if(error) {
			console.error("Failed to fetch pending quotes: ", error);
			snack.enqueueSnackbar("We ran into an issue loading your information.", {
				variant: "error"
			});
		}
	}, [ error ]);

	return (
		<Box flex gap="small" margin={{ top: "small" }}>
			<Box>
				<Heading margin="none" level="3">
					Pending Quotes
				</Heading>
				<Typography fontSize="small">
					These are quotes that have been submitted but the customer has not yet accepted and scheduled.
				</Typography>
			</Box>
			{loading && (
				<LinearProgress />
			)}
			{quotes.length === 0 && (
				<Box flex align="center" justify="center">
					<Typography variant="body1">
						Nothing here yet
					</Typography>
				</Box>
			)}
			<Box flex>
				<Pagination
					pageSize={5}
				>
					{quotes.map((quote) => (
						<HaulerPendingQuoteListItem
							key={quote.id}
							quoteId={quote.id}
							marketCode={quote.order.market.code}
							productCount={quote.order.productCount}
							amount={quote.amount}
							customerNameFormatted={`${quote.order.user.firstName} ${quote.order.user.lastName.charAt(0).toUpperCase()}.`}
							onClick={() => {
								dispatch(push(`/hauler/orders/${quote.order.id}`));
							}}
						/>
					))}
				</Pagination>
			</Box>
		</Box>
	);
};
