import { gql } from "@apollo/client";
import { Building_HeaderFragmentFragment } from "../../../__generated__/graphql";

export const Building_HeaderFragment = gql`
	fragment Building_HeaderFragment on Building {
		id
		name
		unitPrefix
		roomDivided
		__typename
	}
`;

export function isBuildingFragment(value: { [ key: string ]: unknown; }): value is Building_HeaderFragmentFragment {
	return value.__typename === "Building";
}