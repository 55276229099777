import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ArmoireAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g fill-rule="evenodd">
		<path d="m750 500v150h-50v-150z" />
		<path
			d="m175 100c0-13.809 11.191-25 25-25h400c13.805 0 25 11.191 25 25v1e3c0 13.805-11.195 25-25 25h-400c-13.809 0-25-11.195-25-25zm50 25v950h350v-950z" />
		<path
			d="m575 100c0-13.809 11.195-25 25-25h400c13.805 0 25 11.191 25 25v1e3c0 13.805-11.195 25-25 25h-400c-13.805 0-25-11.195-25-25zm50 25v950h350v-950z" />
		<path d="m500 500v150h-50v-150z" />
		<path d="m1100 125h-1e3v-50h1e3z" />
		<path d="m1100 1125h-1e3v-50h1e3z" />
	</g>
</svg>
			</SvgIcon>
		);
	};