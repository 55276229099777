import { gql } from "../../../__generated__";

export const FindPendingProductListingByInstance = gql(/* GraphQL */ `
	query FindPendingProductListingByInstance($instanceId: String!) {
		ListProductsPendingForSale(instanceId: $instanceId) {
			...ProductFragment
			...ProductListingFragment
			listing {
				...ListingApprovalFragment
			}
		}
 	}
`);
