import React, { ReactNode, useEffect } from "react";
import { Box } from "grommet";
import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { FetchAdminProduct, FetchProductV1, UpdateProductType } from "../../../../../graphql";
import { DialogWithActivatorButton, ItemSizeElement, ItemTypeElement } from "../../../../../components";
import { FormContainer, useForm } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";

export const UpdateProductTypeDialog: React.FC<{
	productId: string;
	type: { id: string; name: string; } | null;
	size: { id: string; name: string; } | null;
	activationButton: ReactNode;
	refetch?: InternalRefetchQueriesInclude;
}> = ({ productId, size, type, activationButton: button, refetch }) => {
	const snack = useSnackbar();

	const [ forceClose, setForceClose ] = React.useState(false);
	function close(): void {
		setForceClose(true);
		setTimeout(() => {
			setForceClose(false);
		}, 10);
	}

	const formContext = useForm({
		defaultValues: {
			type: type,
			size: size
		}
	});

	const watchedType = formContext.watch("type");
	useEffect(() => {
		if(watchedType && watchedType.id !== type?.id) {
			formContext.setValue("size", null);
		}
	}, [ watchedType ]);

	const formValues = formContext.watch();

	const [
		updateProduct, { loading: updatingProduct }
	] = useMutation(UpdateProductType, {
		refetchQueries: [
			FetchProductV1,
			FetchAdminProduct,
			...refetch ?? []
		]
	});

	function handleSave(): void {
		const typeId = formContext.getValues("type")?.id;
		const sizeId = formContext.getValues("size")?.id;

		if(!typeId) return;

		updateProduct({
			variables: {
				typeId,
				sizeId,
				productId
			}
		}).then(() => {
			close();
		}).catch(err => {
			console.error(err);
			snack.enqueueSnackbar("We ran into an issue saving your information.", { variant: "error" });
		});
	}

	return (
		<DialogWithActivatorButton
			title="Update Product"
			activatorButton={button}
			forceClose={forceClose}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="contained"
						onClick={() => close}
					>
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						onClick={formContext.handleSubmit(handleSave)}
						loading={updatingProduct}
					>
						Save
					</LoadingButton>
				</Box>
			)}
		>
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<ItemTypeElement
						isLoading={false}
						type={formValues.type}
					/>
					<ItemSizeElement
						isLoading={false}
						type={formValues.type}
						size={formValues.size}
					/>
				</Box>
			</FormContainer>
		</DialogWithActivatorButton>
	);
};
