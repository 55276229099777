import { gql } from "../../../__generated__";

export const CreateBuilding = gql(/* GraphQL */ `
	mutation CreateBuilding(
		$address: CreateAddressInputDTO!
		$instanceId: String!
		$name: String!
	) {
		CreateBuilding(
			address: $address
			instanceId: $instanceId
			name: $name
		) {
			id
		}
	}
`);