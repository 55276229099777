import { gql } from "@apollo/client";

export const Pickup_InvoiceFragment = gql`
	fragment Pickup_InvoiceFragment on Pickup {
		id
		invoice {
			id
			amount
		}
	}
`;