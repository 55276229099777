import { useMemo } from "react";
import { selectCalculatorState } from "../../../store/calculator";
import { useAppSelector } from "../../../store";
import { WasteScenario } from "../WasteScenario";


export function useWasteScenario() {
	const state = useAppSelector(selectCalculatorState);
	return useMemo(() => {
		return new WasteScenario(state);
	}, [ state ]);
}
