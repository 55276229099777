import { gql } from "../../../__generated__";

export const FetchProductV1 = gql(/* GraphQL */ `
	query FetchProductV1(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...ProductFragment
		}
	}
`);

export const FetchProduct = gql(/* GraphQL */ `
	query FetchProduct(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...Product_HeaderFragment
			...Product_InventoryFragment
			...Product_ClassificationFragment
		}
	}
`);

export const FetchProductMedia = gql(/* GraphQL */ `
	query FetchProductMedia(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...Product_HeaderFragment
			...Product_MediaFragment
		}
	}
`);

export const FetchProductForUser = gql(/* GraphQL */ `
	query FetchProductForUser(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...Product_HeaderFragment
			...Product_MediaFragment
			...Product_InventoryFragment
			...Product_ClassificationFragment
		}
	}
`);

export const FetchProductForAdmin = gql(/* GraphQL */ `
	query FetchProductForAdmin(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...Product_HeaderFragment
		}
	}
`);

export const FetchProductForHauler = gql(/* GraphQL */ `
	query FetchProductForHauler(
		$productId: String!
	) {
		FetchProduct(
			productId: $productId
		) {
			...Product_HeaderFragment
		}
	}
`);