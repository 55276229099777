import { Box } from "grommet";
import { Avatar, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useMuiTheme } from "../../../../provider";
import { useWindowDimensions } from "../../../../hooks";

export interface PickupRouteListItemProps {
	id: string;
	index: number;
	incomingCount: number;
	outgoingCount: number;
	outlet: {
		id: string;
		name: string;
	} | null;
	address: {
		addressLineOne: string;
		addressLineTwo: string;
		city: string;
		state: string;
		zipCode: string;
	};
}

export const PickupRouteListItem: React.FC<PickupRouteListItemProps> = (props) => {
	const brandColor = useMuiTheme().palette.primary;
	const { size } = useWindowDimensions();

	const addressLine = useMemo(() => {
		if(props.outlet) {
			return props.outlet.name;
		}

		if(!props.address.addressLineTwo) {
			return props.address.addressLineOne;
		}

		return `${props.address.addressLineOne} (Unit ${props.address.addressLineTwo})`;
	}, [ props.address ]);

	return (
		<ListItemButton divider>
			<ListItemAvatar>
				<Avatar sx={{ backgroundColor: brandColor.main }}>
					<Typography>
						{props.index + 1}
					</Typography>
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={(
					<Typography
						className="text-max-1-line"
						style={{
							maxWidth: size === "small" ? "180px" : undefined
						}}
					>
						{addressLine}
					</Typography>
				)}
				secondary={size === "small"
					? `${props.address.city}, ${props.address.state}`
					: `${props.address.city}, ${props.address.state} ${props.address.zipCode}`} />
			<ListItemSecondaryAction>
				<Box>
					<Typography fontWeight="bold" variant="caption">
						Pickup: {props.incomingCount} item(s)
					</Typography>
					<Typography fontWeight="bold" variant="caption">
						Dropoff: {props.outgoingCount} item(s)
					</Typography>
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
