import { gql } from "@apollo/client";

export const Order_UserResidentFragment = gql`
	fragment Order_UserResidentFragment on Order {
		user {
			...User_HeaderFragment
			...User_ResidentFragment
		}
	}
`;
