import { Box, Heading } from "grommet";
import { LinearProgress } from "@mui/material";
import React from "react";
import { useHauler } from "../../hooks";
import { HaulerUserListItem } from "./HaulerUserListItem";
import { Pagination } from "../../../../components";

export const HaulerUsersList: React.FC = () => {
	const { hauler, loading } = useHauler();

	return (
		<Box gap="small">
			<Heading margin="none" level="3">
				Users
			</Heading>
			{loading && (
				<LinearProgress />
			)}
			<Pagination pageSize={4}>
				{(hauler?.users || []).map((user) => (
					<HaulerUserListItem
						key={user.id}
						role={user.role}
						firstName={user.user.firstName}
						lastName={user.user.lastName}
						primaryEmail={user.user.primaryEmailAddress} />
				))}
			</Pagination>
		</Box>
	);
};