
import React, { useMemo } from "react";
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	TimeScale
} from "chart.js";
import { Chart } from "react-chartjs-2";
import moment from "moment";
import "chartjs-adapter-moment";
import { Box, BoxProps, Heading } from "grommet";
import { Button } from "@mui/material";
import { useWindowDimensions } from "../../hooks";
import { FilterWasteDashboardDialog, WasteReportingHook, useFilteredDate } from "../../features/admin/common/views/WasteDiversionDashboard";
import { FilterAlt, ImportExport } from "@mui/icons-material";
import { selectIsPartyMode, useAppDispatch, useAppSelector } from "../../store";
import { selectTimePeriodFilter, selectTimePeriodGroup, setTimePeriodGroup } from "../../store/reporting";

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	TimeScale
);

interface WasteDiversionChartProps {
	isMultifamily: boolean;
	report: WasteReportingHook;
}

export const WasteDiversionChart: React.FC<WasteDiversionChartProps> = (props) => {
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);

	const lineItems = useMemo(() => {
		return props.report.lineItems ?? [];
	}, [ props ]);

	const { startingDate, endingDate } = useFilteredDate();
	const labels = useMemo(() => {
		switch(timePeriodGroup) {
			case "HOUR": {
				return [
					"12:00 AM",
					"01:00 AM",
					"02:00 AM",
					"03:00 AM",
					"04:00 AM",
					"05:00 AM",
					"06:00 AM",
					"07:00 AM",
					"08:00 AM",
					"09:00 AM",
					"10:00 AM",
					"11:00 AM",
					"12:00 PM",
					"01:00 PM",
					"02:00 PM",
					"03:00 PM",
					"04:00 PM",
					"05:00 PM",
					"06:00 PM",
					"07:00 PM",
					"08:00 PM",
					"09:00 PM",
					"10:00 PM",
					"11:00 PM"
				];
			}
			case "DAY": {
				const days = [];
				const start = moment(startingDate);
				const end = moment(endingDate);

				while(start.unix() <= end.unix()) {
					days.push(start.format("MMM DD"));
					start.add(1, "day");
				}

				return days;
			}
			case "DAY_OF_WEEK": {
				return [
					"Sunday",
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday"
				];
			}
			case "MONTH": {
				const months = [];
				const start = moment(startingDate);
				const end = moment(endingDate);

				while(start.unix() <= end.unix()) {
					months.push(start.format("MMM YY"));
					start.add(1, "month");
				}

				return months;
			}
			case "QUARTER": {
				const quarters = [];
				const start = moment(startingDate);
				const end = moment(endingDate);

				while(start.unix() <= end.unix()) {
					quarters.push("Q" + start.format("Q YY"));
					start.add(3, "months");
				}

				return quarters;
			}
			case "WEEK": {
				const weeks = [];
				const start = moment(startingDate).endOf("week");
				const end = moment(endingDate).endOf("week");

				while(start.unix() < end.unix()) {
					weeks.push(start.format("MMM DD"));
					start.add(1, "week");
				}

				return weeks;
			}
		}
	}, [ startingDate, endingDate, timePeriodGroup ]);

	const dataset = useMemo(() => {
		switch(timePeriodGroup) {
			case "HOUR": {
				const periods = labels ?? [];

				return periods.map(period => {
					const periodTimestamp = moment(period, "hh:mm A");

					return lineItems.filter(item => {
						const date = moment(item.date);
						return date.hour() === periodTimestamp.hour();
					});
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: reduced.weightGenerated
							? Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2))
							: 0,
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "DAY": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a, "MMM DD").unix() - moment(b, "MMM DD").unix();
				}).map(period => {
					const date = moment(period, "MMM DD");
					const filtered = lineItems.filter(item => {
						return moment(item.date).format("YYYY-MM-DD") === date.format("YYYY-MM-DD");
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "DAY_OF_WEEK": {
				const periods = labels ?? [];

				return periods.map((period, index) => {
					const filtered = lineItems.filter(order => {
						return moment(order.date).day() === index;
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "MONTH": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a, "MMM YY").unix() - moment(b, "MMM YY").unix();
				}).map(period => {
					const date = moment(period, "MMM YY");
					const filtered = lineItems.filter(order => {
						return moment(order.date).month() === date.month() && moment(order.date).year() === date.year();
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "QUARTER": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a.substring(1), "Q YY").unix() - moment(b.substring(1), "Q YY").unix();
				}).map(period => {
					const date = moment(period.substring(1), "Q YY");
					const filtered = lineItems.filter(order => {
						return moment(order.date).quarter() === date.quarter() && moment(order.date).year() === date.year();
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
			case "WEEK": {
				const periods = labels ?? [];

				return periods.sort((a, b) => {
					return moment(a, "MMM DD").unix() - moment(b, "MMM DD").unix();
				}).map(period => {
					const date = moment(period, "MMM DD");
					const filtered = lineItems.filter(order => {
						return moment(order.date).format("YYYY-MM-DD") >= date.startOf("week").format("YYYY-MM-DD") && moment(order.date).format("YYYY-MM-DD") <= date.endOf("week").format("YYYY-MM-DD");
					});
					return filtered;
				}).map(filtered => {
					const reduced = filtered.reduce((acc, order) => {
						acc.weightDiverted += order.totalWeightDivertedPounds;
						acc.weightGenerated += order.totalWeightGeneratedPounds;

						return acc;
					}, { weightDiverted: 0, weightGenerated: 0 });

					return {
						diversionRate: Number(((reduced.weightDiverted / reduced.weightGenerated) * 100).toFixed(2)),
						weightDiverted: reduced.weightDiverted,
						weightGenerated: reduced.weightGenerated
					};
				});
			}
		}
	}, [ timePeriodGroup, lineItems, labels ]);

	const isPartyMode = useAppSelector(selectIsPartyMode);

	//background-image: linear-gradient(to right, #780206 0%, #061161 51%, #780206 100%);

	const data = useMemo(() => {
		return {
			labels,
			datasets: [
				{
					type: "bar" as const,
					label: "Waste Generated",
					backgroundColor: isPartyMode ? "#07294d" : "#263D0B",
					data: dataset?.map(dataset => dataset.weightGenerated) ?? [],
					order: 1
				},
				{
					type: "bar" as const,
					label: "Waste Diverted",
					backgroundColor: isPartyMode ? "#ffc600" : "#76A96C",
					data: dataset?.map(dataset => dataset.weightDiverted) ?? [],
					order: 1
				},
				{
					type: "line" as const,
					label: "Diversion Rate",
					borderColor: "rgb(53, 162, 235)",
					backgroundColor: "rgb(53, 162, 235)",
					data: dataset?.map(dataset => dataset.diversionRate) ?? [],
					yAxisID: "y1",
				}
			],
		};

	}, [ timePeriodFilter, dataset, isPartyMode ]);

	return (
		<Chart
			type="bar" data={data}
			options={{
				responsive: true,
				scales: {
					y: {
						type: "linear",
						title: {
							display: true,
							text: "Weight (lbs)",
							font: {
								size: 20,
								weight: "bold"
							}
						},
						display: true,
						position: "left"
					},
					y1: {
						type: "linear",
						max: 100,
						min: 0,
						title: {
							display: true,
							text: "Diversion Rate (%)",
							font: {
								size: 20,
								weight: "bold"
							}
						},
						display: true,
						position: "right",
						grid: {
							drawOnChartArea: false, // only want the grid lines for one axis to show up
						}
					}
				}
			}}
		/>
	);
};

interface ChartContainerProps {
	report: WasteReportingHook;
	isMultifamily: boolean;
}

export const ChartContainer: React.FC<ChartContainerProps> = (props) => {
	const { sizeIndex } = useWindowDimensions();

	return (
		<Box
			flex
			gap="small"
			direction={sizeIndex <= 2 ? "column" : "row"}
		>
			<WasteDiversionChart
				report={props.report}
				isMultifamily={props.isMultifamily}
			/>
			<ChartTimePeriodContainer />
		</Box>
	);
};

export const ChartTimePeriodContainer: React.FC = () => {
	const dispatch = useAppDispatch();
	const timePeriodGroup = useAppSelector(selectTimePeriodGroup);
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);

	const { sizeIndex } = useWindowDimensions();
	const isPartyMode = useAppSelector(selectIsPartyMode);

	const boxProps = useMemo(() => {
		let props: BoxProps;

		if(sizeIndex <= 2) {
			props = {
				gap: "small",
				direction: "row"
			};
		}
		else {
			props = {
				gap: "small",
				width: "100%"
			};
		}

		return props;
	}, [ sizeIndex ]);

	return (
		<Box align="center" flex direction={sizeIndex <= 2 ? "row" : "column"}>
			<Box {...boxProps} >
				{sizeIndex > 2 && (
					<Heading margin="none" level="4" textAlign="center">
						Time Period
					</Heading>
				)}
				{[ "TODAY", "YESTERDAY" ].includes(timePeriodFilter ?? "") && (
					<Button
						color="primary"
						onClick={() => {
							dispatch(setTimePeriodGroup("HOUR"));
						}}
						className={isPartyMode ? "btn-grad" : undefined}
						variant={timePeriodGroup === "HOUR" ? "contained" : "outlined"}
					>
						Hourly
					</Button>
				)}
				{![ "LAST_YEAR", "YTD" ].includes(timePeriodFilter ?? "") && (
					<Button
						color="primary"
						onClick={() => {
							dispatch(setTimePeriodGroup("DAY"));
						}}
						className={isPartyMode ? "btn-grad" : undefined}
						variant={timePeriodGroup === "DAY" ? "contained" : "outlined"}
					>
						Daily
					</Button>
				)}
				{![ "LAST_YEAR", "YTD" ].includes(timePeriodFilter ?? "") && (
					<Button
						color="primary"
						onClick={() => {
							dispatch(setTimePeriodGroup("WEEK"));
						}}
						className={isPartyMode ? "btn-grad" : undefined}
						variant={timePeriodGroup === "WEEK" ? "contained" : "outlined"}
					>
						Weekly
					</Button>
				)}
				<Button
					color="primary"
					onClick={() => {
						dispatch(setTimePeriodGroup("MONTH"));
					}}
					className={isPartyMode ? "btn-grad" : undefined}
					variant={timePeriodGroup === "MONTH" ? "contained" : "outlined"}
				>
					Monthly
				</Button>
				<Button
					color="primary"
					onClick={() => {
						dispatch(setTimePeriodGroup("QUARTER"));
					}}
					className={isPartyMode ? "btn-grad" : undefined}
					variant={timePeriodGroup === "QUARTER" ? "contained" : "outlined"}
				>
					Quarterly
				</Button>
			</Box>
			<Box {...boxProps} justify="end" flex>
				<FilterWasteDashboardDialog
					activatorButton={(
						<Button
							color="primary"
							variant="contained"
							startIcon={<FilterAlt />}
							className={isPartyMode ? "btn-grad" : undefined}
						>
							Filter
						</Button>
					)}
				/>
				<Button
					color="primary"
					variant="contained"
					startIcon={<ImportExport />}
					className={isPartyMode ? "btn-grad" : undefined}
				>
					Export
				</Button>
			</Box>
		</Box>
	);
};