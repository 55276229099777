import { DocumentType, gql } from "../__generated__";

export const BeginLogin = gql(`
	mutation BeginLogin($username: String!, $instanceId: String!) {
		BeginLogin(username: $username, instanceId: $instanceId) {
			wasSent
			wasFound
			message
		}
	}
`);

export const ConfirmLogin = gql(`
	mutation Login($username: String!, $code: String!, $instanceId: String!) {
		Login(username: $username, code: $code, instanceId: $instanceId) {
			accessToken
			refreshToken
			expiresIn
			expiresAt
		}
	}
`);

export const FetchSelf = gql(`
	query FetchSelf {
		FetchSelf {
			...User_AuthenticationFragment
			id
			firstName
			lastName
			primaryPhoneNumber
			primaryEmailAddress
			paymentMethods {
				...PaymentMethodFragment
			}
			contacts {
				...Contact_HeaderFragment
			}
			addresses {
				...AddressFragment
				createdAt
				updatedAt
			}
			credits {
				id
				isActive
				isExpired
				effectiveDate
				expirationDate
				originalAmount
				remainingAmount
			}
			availableCredit
		}
	}
`);

export type UserGql = DocumentType<typeof FetchSelf>[ "FetchSelf" ];
export type UserContactGql = UserGql[ "contacts" ][ 0 ];
export type UserAddressGql = UserGql[ "addresses" ][ 0 ];
export type UserPaymentMethodGql = UserGql[ "paymentMethods" ][ 0 ];

export const RefreshLogin = gql(`
	mutation RefreshLogin($userId: String!, $accessToken: String!, $refreshToken: String!) {
		RefreshLogin(userId: $userId, accessToken: $accessToken, refreshToken: $refreshToken) {
			accessToken
			refreshToken
			expiresIn
			expiresAt
		}
	}
`);

export const Authorize = gql(/* GraphQL */`	
	query Authorize(
		$instanceId: String!
	) {
		Authorize(
			instanceId: $instanceId
		) {
			isGlobalAdmin
			isInstanceAdmin
			isInstanceMember
			isInstanceManager
		}
	}
`);

export const AuthorizePartner = gql(/* GraphQL */`
	query AuthorizePartner(
		$partnerId: String!
		$instanceId: String!
	) {
		AuthorizePartner(
			partnerId: $partnerId
			instanceId: $instanceId
		) {
			isGlobalAdmin
			isInstanceAdmin
			isInstanceMember
			isInstanceManager
			isPartnerAdmin
			isPartnerMember
			isPartnerReportingMember
		}
	}
`);

export type AuthorizeGql = DocumentType<typeof Authorize>[ "Authorize" ];
export type AuthorizePartnerGql = DocumentType<typeof AuthorizePartner>[ "AuthorizePartner" ];