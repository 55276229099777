import { AutocompleteElement } from "react-hook-form-mui";
import { useClassification } from "../../hooks";
import { useMemo } from "react";
import { Spinner } from "grommet";

interface ItemTypeElementType {
	id: string;
	name: string;
}

export const ItemTypeElement: React.FC<{
	isLoading?: boolean;
	includeHidden?: boolean;
	onChange?: (event: React.SyntheticEvent, value: ItemTypeElementType | null) => void;
	type?: ItemTypeElementType | null;
}> = ({ isLoading, type, onChange, includeHidden = false }) => {
	const { types, loading } = useClassification(includeHidden);

	const options = useMemo(() => {
		return types.map(t => ({ id: t.id, label: t.name, category: t.category })).sort((a, b) => {
			if(a.category.name === b.category.name) {
				return a.label.localeCompare(b.label);
			}
			return a.category.name.localeCompare(b.category.name);
		});
	}, [ types ]);

	return (
		<AutocompleteElement
			label="Item Type"
			name="type"
			required
			loading={loading}
			loadingIndicator={(<Spinner />)}
			options={options}
			autocompleteProps={{
				value: onChange ? type : undefined,
				onChange: onChange,
				groupBy: (option) => {
					return option.category.name;
				},
				getOptionLabel: (option) => {
					return option.label ?? option.name;
				}
			}}
		/>
	);
};