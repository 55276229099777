import { gql } from "@apollo/client";


export const Hauler_RequestsFragment = gql`
	fragment Hauler_RequestsFragment on Hauler {
		pendingRequests {
			id
			pickup {
				...Pickup_CountFragment
				...Pickup_HeaderFragment
			}
			amountOffered
		}
	}
`;
