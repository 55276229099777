import { Box } from "grommet";

export const ViewContainer: React.FC<{
	backgroundColor?: string;
	disableMargin?: boolean;
	children: React.ReactNode;
}> = (props) => {
	return (
		<Box height="100%" style={{ backgroundColor: props.backgroundColor || undefined }}>
			<Box height="100%" id="view-container-parent">
				<Box margin={props.disableMargin ? undefined : { left: "medium", right: "medium", top: "medium", bottom: "medium" }} flex id="view-container-child">
					{props.children}
				</Box>
			</Box>
		</Box>
	);
};