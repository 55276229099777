import { CalculatorState } from "../../store/calculator";
import { WasteScenario } from "./WasteScenario";

export enum QuestionType {
	CHOICE = "CHOICE",
	NUMBER = "NUMBER",
	TEXT = "TEXT"
}

export interface ChoiceOptionType {
	label: string;
	value: string | boolean;
}

export interface ChoiceResponseType {
	required?: boolean;
	options: ChoiceOptionType[];
}

export interface NumberResponseType {
	min?: number;
	max?: number;
	required?: boolean;
}

export interface TextResponseType {
	regex?: RegExp;
	required?: boolean;
}

export type AnswerType<T extends QuestionType> = T extends QuestionType.CHOICE
	? ChoiceResponseType
	: T extends QuestionType.NUMBER
	? NumberResponseType
	: T extends QuestionType.TEXT
	? TextResponseType
	: never;

export interface Question<T extends QuestionType> {
	id: keyof CalculatorState;
	text: string;
	type: T;
	icon: React.ReactNode;
	response: AnswerType<T>;
	pledgeKey?: keyof CalculatorState;
	weightImpactKey?: keyof WasteScenario;
	weightReductionKey?: keyof WasteScenario;
}

export enum Frequency {
	NEVER = "0",
	RARELY = "1 - 3",
	SOMETIMES = "4 - 6",
	OFTEN = "7 +"
}

export type TimePeriod = "daily" | "weekly" | "monthly" | "yearly";
