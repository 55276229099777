import { Box } from "grommet";
import React, { useMemo } from "react";
import { SelectionState } from "../types";
import { useClassification } from "../../../hooks";
import { DialogWithClose, Step, StepController } from "../../../components";
import { keys } from "../../../helpers";

export const ItemDetailsDialog: React.FC<{
	onClose: () => void;
	onSubmit: () => void;
	state: SelectionState;
}> = (props) => {
	const { types } = useClassification();
	const items = useMemo(() => {
		return keys(props.state).flatMap(key => {
			const type = types.find(type => type.id === key);
			if(!type) throw new Error(`could not find type with id ${key}`);

			const record = props.state[ key ];
			if(record.sizes?.length > 0) {
				return record.sizes.map((sizeRecord) => {
					const size = type.sizes.find(size => size.id === sizeRecord.name);
					return {
						type,
						size: size || null,
						media: [],
						quantity: sizeRecord.quantity,
						requiresDisassembly: false,
						requiresDisassemblyQuantity: 0
					};
				});
			}
			else {
				return {
					type,
					size: null,
					media: [],
					quantity: record.quantity,
					requiresDisassembly: false,
					requiresDisassemblyQuantity: 0
				};
			}
		});
	}, [ props, types ]);

	return (
		<DialogWithClose
			title="Item Details"
			onClose={props.onClose}
			actions={(
				<Box>

				</Box>
			)}
			content={(
				<Box>
					<StepController name="item-details">
						{items.map((item, index) => (
							<Step stepName=" ">
								<Box>
									{item.type.name}
								</Box>
							</Step>
						))}
					</StepController>
				</Box>
			)} />
	);
};
