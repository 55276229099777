import React from "react";
import { WasteContainerDetailsStep, WasteContainerMaterialStep, WasteContainerSizeStep } from "./steps";
import { WasteContainerState } from "../../../types";
import { UseFormReturn } from "react-hook-form";

export const WasteContainerDialogContent: React.FC<{
	formContext: UseFormReturn<WasteContainerState>;
}> = ({ formContext }) => {
	const {
		materialId,
		extendedMaterialId,
		currentStepIndex
	} = formContext.watch();

	switch(currentStepIndex) {
		case 0:
			return (
				<WasteContainerDetailsStep
					formContext={formContext}
				/>
			);
		case 1:
			return (
				<WasteContainerSizeStep
					formContext={formContext}
				/>
			);
		case 2:
			return (
				<WasteContainerMaterialStep
					materialId={materialId}
					extendedMaterialId={extendedMaterialId}
					setExtendedMaterialId={(materialId) => {
						return formContext.setValue("extendedMaterialId", materialId);
					}}
				/>
			);
		default:
			return null;
	}
};