import { TextFieldElement } from "react-hook-form-mui";

export const FirstNameElement: React.FC = () => {
	return (
		<TextFieldElement
			name="firstName"
			label="First Name"
			validation={{
				required: "Please enter your first name."
			}}
		/>
	);
};

