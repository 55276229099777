import { SvgIcon, SvgIconProps } from "@mui/material";

	export const KitchenCabinets: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1075 150h-950c-6.6289 0-12.988 2.6328-17.676 7.3242-4.6914 4.6875-7.3242 11.047-7.3242 17.676v150c0 6.6289 2.6328 12.988 7.3242 17.676 4.6875 4.6914 11.047 7.3242 17.676 7.3242h950c6.6289 0 12.988-2.6328 17.676-7.3242 4.6914-4.6875 7.3242-11.047 7.3242-17.676v-150c0-6.6289-2.6328-12.988-7.3242-17.676-4.6875-4.6914-11.047-7.3242-17.676-7.3242zm-925 50h250v100h-250zm300 0h300v100h-300zm600 100h-250v-100h250z"/>
  <path d="m1075 600h-950c-6.6289 0-12.988 2.6328-17.676 7.3242-4.6914 4.6875-7.3242 11.047-7.3242 17.676v400c0 6.6289 2.6328 12.988 7.3242 17.676 4.6875 4.6914 11.047 7.3242 17.676 7.3242h950c6.6289 0 12.988-2.6328 17.676-7.3242 4.6914-4.6875 7.3242-11.047 7.3242-17.676v-400c0-6.6289-2.6328-12.988-7.3242-17.676-4.6875-4.6914-11.047-7.3242-17.676-7.3242zm-925 50h200v350h-200zm250 0h400v350h-400zm650 350h-200v-350h200z"/>
  <path d="m925 850c6.6289 0 12.988-2.6328 17.676-7.3242 4.6914-4.6875 7.3242-11.047 7.3242-17.676v-50c0-8.9297-4.7656-17.184-12.5-21.652-7.7344-4.4648-17.266-4.4648-25 0-7.7344 4.4688-12.5 12.723-12.5 21.652v50c0 6.6289 2.6328 12.988 7.3242 17.676 4.6875 4.6914 11.047 7.3242 17.676 7.3242z"/>
  <path d="m275 750c-6.6289 0-12.988 2.6328-17.676 7.3242-4.6914 4.6875-7.3242 11.047-7.3242 17.676v50c0 8.9297 4.7656 17.184 12.5 21.652 7.7344 4.4648 17.266 4.4648 25 0 7.7344-4.4688 12.5-12.723 12.5-21.652v-50c0-6.6289-2.6328-12.988-7.3242-17.676-4.6875-4.6914-11.047-7.3242-17.676-7.3242z"/>
  <path d="m725 700h-250c-6.6289 0-12.988 2.6328-17.676 7.3242-4.6914 4.6875-7.3242 11.047-7.3242 17.676v200c0 6.6289 2.6328 12.988 7.3242 17.676 4.6875 4.6914 11.047 7.3242 17.676 7.3242h250c6.6289 0 12.988-2.6328 17.676-7.3242 4.6914-4.6875 7.3242-11.047 7.3242-17.676v-200c0-6.6289-2.6328-12.988-7.3242-17.676-4.6875-4.6914-11.047-7.3242-17.676-7.3242zm-25 200h-200v-150h200z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};