import { SvgIcon, SvgIconProps } from "@mui/material";

	export const BookshelfAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1187.5 1187.5h-1175v-1175h1175zm-1125-50h1075v-1075h-1075z"/>
  <path d="m37.5 387.5h1125v50h-1125z"/>
  <path d="m37.5 762.5h1125v50h-1125z"/>
  <path d="m725 787.5h50v375h-50z"/>
  <path d="m425 37.5h50v375h-50z"/>
  <path d="m575 212.5h50v200h-50z"/>
  <path d="m675 212.5h50v200h-50z"/>
  <path d="m575 587.5h50v200h-50z"/>
  <path d="m837.5 587.5h50v200h-50z"/>
  <path d="m987.81 786.68-51.762-193.18 48.297-12.941 51.762 193.18z"/>
  <path d="m475 587.5h50v200h-50z"/>
  <path d="m375 587.5h50v200h-50z"/>
  <path d="m275 587.5h50v200h-50z"/>
  <path d="m475 962.5h50v200h-50z"/>
  <path d="m275 962.5h50v200h-50z"/>
  <path d="m775 212.5h50v200h-50z"/>
  <path d="m875 212.5h50v200h-50z"/>
  <path d="m1025.5 411.53-51.762-193.18 48.297-12.941 51.762 193.18z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};