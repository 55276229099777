import { gql } from "../../../__generated__";

export const FindEligibleHaulersByOrder = gql(/* GraphQL */ `
	query FindEligibleHaulersByOrder(
		$orderId: String!
	) {
		FindEligibleHaulers(
			orderId: $orderId
		) {
			id
			name
		}
	}
`);

export const FindEligibleHaulersByMarket = gql(/* GraphQL */ `
	query FindEligibleHaulersByMarket(
		$marketId: String!
	) {
		FindEligibleHaulers(
			marketId: $marketId
		) {
			id
			name
		}
	}
`);