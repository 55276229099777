import React, { useMemo } from "react";
import { SelectElement } from "react-hook-form-mui";
import { useWasteAuditMaterials } from "../../hooks";

export const MaterialElement: React.FC<{
	includeExtended?: boolean;
}> = ({ includeExtended }) => {
	const { materials } = useWasteAuditMaterials();

	const materialOptions = useMemo(() => {
		return Array.from(materials).filter(m => !m.tags.includes("extended") || (includeExtended)).sort((a, b) => a.name.localeCompare(b.name)).map(material => ({
			id: material.id,
			label: material.name,
			tags: material.tags
		}));
	}, [ includeExtended, materials ]);

	return (
		<SelectElement
			name="materialId"
			label="Material Type"
			helperText="Select the material type of this container"
			options={materialOptions}
		/>
	);
};