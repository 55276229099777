import { Fragment, useEffect } from "react";
import { useRedirect } from "../hooks";
import { useUser } from "../auth";
import EventEmitter from "events";

const events = new EventEmitter();

export const AlreadyLoggedInGuard: React.FC<{
	fallbackUrl: string;
	skipOnLocalhost?: boolean;
	children: React.ReactNode;
}> = ({ fallbackUrl, children, skipOnLocalhost }) => {
	const shouldSkip = skipOnLocalhost && window.location.hostname === "localhost";
	const { user } = useUser();
	const { redirect } = useRedirect();


	useEffect(() => {
		events.once("redirect", () => {
			!shouldSkip && redirect(fallbackUrl);
		});
	}, []);

	useEffect(() => {
		if(user) {
			events.emit("redirect");
		}
	}, [ user ]);

	return (
		<Fragment>
			{children}
		</Fragment>
	);
};