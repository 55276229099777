import { Box } from "grommet";
import React from "react";
import { WasteContainerState } from "../../../types";
import { UseFormReturn } from "react-hook-form";
import { VolumeElement, VolumeUnitElement, CollectionFrequencyElement } from "../../form";
import { MaterialElement } from "../../form/MaterialElement";
import { TextFieldElement } from "react-hook-form-mui";
import { WasteContainerType } from "../../../../../../graphql/__generated__/graphql";
import { useWasteContainerAssignment } from "../../../hooks";
import { IconButton, InputAdornment } from "@mui/material";
import { Icon } from "@liverego/icons";
import { Autorenew } from "@mui/icons-material";
import { MaterialDispositionElement } from "../../form/MaterialDispositionElement";

export const ContainerDetailsTab: React.FC<{
	assignmentId: string;
	formContext: UseFormReturn<WasteContainerState>;
}> = ({ assignmentId, formContext }) => {
	const { assignment } = useWasteContainerAssignment(assignmentId);

	const {
		volumeUnit
	} = formContext.watch();

	return (
		<Box gap="medium">
			<VolumeElement
				volumeUnit={volumeUnit}
			/>
			<VolumeUnitElement

			/>
			<ContainerTypeElement
				name={assignment?.container.name || ""}
				iconUrl={assignment?.container.iconUrl || ""}
				type={assignment?.container.type || WasteContainerType.DumpsterFrontLoad}
			/>
			<MaterialElement
				includeExtended
			/>
			<MaterialDispositionElement

			/>
			<CollectionFrequencyElement
				formContext={formContext}
			/>
		</Box>
	);
};

export const ContainerTypeElement: React.FC<{
	name: string;
	iconUrl: string;
	type: WasteContainerType;
}> = ({ name, iconUrl, type }) => {
	return (
		<TextFieldElement
			name="container"
			label="Container Type"
			InputProps={{
				value: name,
				startAdornment: (
					<InputAdornment position="start">
						<Icon
							url={iconUrl}
						/>
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						<IconButton>
							<Autorenew />
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	);
};