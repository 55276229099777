import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Stool: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="m803.3 42.602h-406.59c-65.559 0-118.9 53.332-118.9 118.9v70.004c0 40.801 33.195 73.996 73.996 73.996h53.242v39.578c0 36.758 29.902 66.668 66.668 66.668h59.961v370.95l-114.29-0.003906c-14.496 0-26.25 11.754-26.25 26.25s11.754 26.25 26.25 26.25h114.29v102.9c-23.203 3.9219-42.832 21.344-50.477 45.816l-4.9336 15.797c-1.582 5.0781-5.2188 8.6172-8.8438 8.6172h-54.227c-48.57 0-88.078 43.027-88.078 95.918v26.902c0 14.496 11.754 26.25 26.25 26.25h497.27c14.496 0 26.25-11.754 26.25-26.25v-26.902c0-52.891-39.516-95.918-88.078-95.918h-54.227c-3.6133 0-7.2539-3.5391-8.8359-8.6094l-4.9414-15.809c-7.6484-24.465-27.27-41.895-50.477-45.816v-102.9h114.29c14.496 0 26.25-11.754 26.25-26.25s-11.754-26.25-26.25-26.25l-114.29-0.003906v-370.95h59.969c6.0391 0 11.871-0.87891 17.438-2.3906l61.883 69.84c4.9805 5.625 12.129 8.8438 19.641 8.8438h39.676c14.496 0 26.25-11.754 26.25-26.25s-11.754-26.25-26.25-26.25h-27.863l-52.125-58.816c5.1016-9.4219 8.0039-20.191 8.0039-31.637v-39.578h53.25c40.793 0 73.988-33.195 73.988-73.996v-70.004c0-65.562-53.332-118.89-118.89-118.89zm-406.59 52.5h406.59c24.988 0 46.785 13.891 58.109 34.352l-522.81-0.003906c11.328-20.461 33.121-34.348 58.109-34.348zm276.93 920.28c8.4961 27.188 32.184 45.457 58.941 45.457h54.227c19.621 0 35.578 19.477 35.578 43.418v0.65234h-444.77v-0.65234c0-23.941 15.961-43.418 35.578-43.418h54.227c26.754 0 50.445-18.27 58.949-45.465l4.9336-15.801c1.8008-5.7812 6.0586-9.8164 10.336-9.8164h116.71c4.2734 0 8.5352 4.0352 10.336 9.8164zm-57.812-78.125h-31.648v-102.05h31.648zm-31.648-154.56v-39.801h31.648v39.801zm31.648-77.293h-31.648v-293.66h31.648zm126.63-360.32c0 7.8164-6.3516 14.168-14.16 14.168h-256.58c-7.8086 0-14.168-6.3516-14.168-14.168v-39.578h284.9l0.003907 39.578zm105.75-92.078h-496.39c-11.852 0-21.496-9.6445-21.496-21.496v-64.551h539.38v64.551c-0.007812 11.852-9.6445 21.496-21.492 21.496z" />
</svg>
			</SvgIcon>
		);
	};