import { useQuery } from "@apollo/client";
import { FetchConfig } from "./queries";

export function useConfig() {
	const { data, loading } = useQuery(FetchConfig);

	return {
		loading,
		...data?.FetchConfig
	};
}