import { gql } from "../../../__generated__";

export const CreateOrderProduct = gql(/* GraphQL */ `
	mutation CreateOrderProduct(
		$orderId: String!
		$typeId: String!
		$sizeId: String
		$intent: ProductIntent!
		$quantity: Int!
		$quantityDisassembly: Int!
		$media: [CreateProductMediaDTO!]
	) {
		CreateOrderProduct(
			orderId: $orderId
			typeId: $typeId
			sizeId: $sizeId
			intent: $intent
			media: $media
			quantity: $quantity
			quantityDisassembly: $quantityDisassembly
		) {
			id
		}
	}
`);