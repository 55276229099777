import { useInstance } from "../../../../hooks";
import { Moment } from "moment-timezone";
import { useQuery } from "@apollo/client";
import { FetchDonationCenterDashboard } from "../../../../graphql";

export function useDonationCenterDashboard(from: Moment, to: Moment) {
	const { instance } = useInstance();

	const { data, loading } = useQuery(FetchDonationCenterDashboard, {
		variables: {
			instanceId: instance?.id || "",
			from,
			to
		},
		skip: !instance
	});

	return {
		loading,
		data: data?.FetchDonationReporting ?? null
	};
}
