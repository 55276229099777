import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Appliances: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m54 684v504h492v-1176h-492zm456 468h-420v-432h420zm-420-1104h420v636h-420z"/>
  <path d="m186.01 756h-60.012v36h24.012l-0.011719 132h-24v36h60z"/>
  <path d="m126 468h24.012l-0.011719 144h-24v36h60l0.011719-216h-60.012z"/>
  <path d="m582 480v708h564v-708zm528 36v131.99h-491.99v-131.99zm-491.99 636v-468.01h491.99v468.01z"/>
  <path d="m864.01 738c-95.953 0-174 78.059-174 174s78.047 174 174 174c67.621 0 126.21-38.855 154.99-95.34 0.37109-0.12109 0.75781-0.16797 1.1289-0.27734l-0.40625-1.1992c11.594-23.293 18.289-49.441 18.289-77.184-0.003906-95.941-78.074-174-174-174zm128.46 221.11c-25.969 0-47.113-21.145-47.113-47.113 0-25.98 21.145-47.113 47.113-47.113 0.37109 0 0.74219 0.097656 1.1172 0.097656 5.3516 14.699 8.4258 30.492 8.4258 47.016s-3.0703 32.316-8.4258 47.016c-0.375 0-0.74609 0.097656-1.1172 0.097656zm-68.207 0.23828c-14.41 18.312-36.59 29.352-60.254 29.352-42.301 0-76.727-34.402-76.727-76.715s34.414-76.715 76.727-76.715c23.699 0 45.828 11.051 60.254 29.363-9.3594 13.453-14.891 29.746-14.891 47.34-0.003906 17.59 5.5156 33.922 14.891 47.375zm-60.254 90.648c-76.105 0-138-61.895-138-138s61.895-138 138-138c45.684 0 86.125 22.402 111.25 56.699-8.8086 1.8594-17.062 5.1836-24.613 9.5898-21.191-25.57-52.801-40.992-86.652-40.992-62.16 0-112.73 50.555-112.73 112.71 0 62.16 50.566 112.71 112.73 112.71 33.805 0 65.461-15.422 86.664-40.992 7.5352 4.4023 15.793 7.7148 24.602 9.5742-25.117 34.289-65.57 56.691-111.25 56.691z"/>
  <path d="m714.01 540h36v36h-36z"/>
  <path d="m666.01 540h36v36h-36z"/>
  <path d="m714.01 587.99h36v36h-36z"/>
  <path d="m666.01 587.99h36v36h-36z"/>
  <path d="m1026 540h35.988v36h-35.988z"/>
  <path d="m978.01 540h35.988v36h-35.988z"/>
  <path d="m1026 587.99h35.988v36h-35.988z"/>
  <path d="m978.01 587.99h35.988v36h-35.988z"/>
  <path d="m954.01 540h-180.01v84h180.01zm-36 48h-108.01v-12h108.01z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};