import { SvgIcon, SvgIconProps } from "@mui/material";

export const PatioFurniture: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path d="m1133.7 835.09v-285.57c0-19.664-13.801-36.449-32.125-39.051-21.988-3.6133-43.352 14.914-42.875 37.125v275h-201.38c-19.887 0-36.324 15.539-37.438 35.375l-14.977 263.73c-0.375 6.6641 4.5391 12.438 11.176 13.137l24.863 2.6133c6.3359 0.72656 12.238-3.6016 13.551-9.8867l45.125-217.45h155.65l45.125 217.45c1.2891 6.3008 7.2617 10.586 13.551 9.8867 0 0 24.863-2.6133 24.863-2.6133 6.625-0.69922 11.551-6.4766 11.176-13.137zm-11.125 276.3-44.898-216.35c-1.2109-5.8008-6.3242-9.9609-12.238-9.9609h-175.99c-5.9258 0-11.039 4.1641-12.238 9.9609l-44.898 216.35-1.7734-0.1875 14.301-251.82c0.375-6.6133 5.8633-11.789 12.488-11.789h213.88c6.9141 0 12.5-5.5859 12.5-12.5v-287.5c-0.17578-7.4375 7.0234-13.602 14.363-12.363 6.6484 0.9375 10.637 8.0117 10.637 14.301l0.023438 286.27 15.625 275.41-1.7734 0.1875z" />
					<path d="m342.7 822.59h-201.39v-275c-1.75-50.113-74.273-49.438-75 1.9258l0.011719 284.86-16.312 287.3c-0.375 6.6641 4.5391 12.438 11.176 13.137l24.863 2.6133c6.3516 0.76172 12.238-3.6016 13.539-9.8867l45.137-217.45h155.65l45.137 217.45c1.2109 5.8516 6.375 9.9609 12.227 9.9609 0.4375 0 0.875-0.023438 1.3125-0.074219l24.863-2.6133c6.6367-0.69922 11.551-6.4766 11.176-13.137l-14.977-263.73c-1.125-19.836-17.574-35.375-37.438-35.375zm25.012 288.8-44.898-216.35c-1.1992-5.8008-6.3125-9.9609-12.238-9.9609h-176c-5.9258 0-11.039 4.1641-12.238 9.9609l-44.898 216.35-1.7734-0.1875 15.664-276.12v-285.57c0-6.2891 3.9766-13.352 10.613-14.301 7.3516-1.2617 14.551 4.9141 14.387 12.363v287.5c0 6.9141 5.6016 12.5 12.5 12.5h213.88c6.625 0 12.102 5.1758 12.477 11.789l14.312 251.82-1.7734 0.1875z" />
					<path d="m662.5 1062.5h-25v-325h243.75c24.125 0 43.75-19.625 43.75-43.75s-19.625-43.75-43.75-43.75h-268.75v-262.5h100c20.512 0 38.602-10.074 50-25.387 11.398 15.312 29.488 25.387 50 25.387h225c34.461 0 62.5-28.039 62.5-62.5v-25c-0.39844-4.7109-2.7266-9.3633-7.2617-11.352l-487.5-225c-3.3242-1.5391-7.1484-1.5391-10.477 0l-487.5 225c-1.3516 0.625-2.5391 1.4766-3.5508 2.4766-2.3125 2.3984-3.3984 5.6133-3.7109 8.875v25c0 34.461 28.039 62.5 62.5 62.5h225c20.512 0 38.602-10.074 50-25.387 11.398 15.312 29.488 25.387 50 25.387h100v262.5h-268.75c-24.125 0-43.75 19.625-43.75 43.75s19.625 43.75 43.75 43.75h243.75v325h-25c-34.461 0-62.5 28.039-62.5 62.5 0 6.9141 5.6016 12.5 12.5 12.5h225c6.9141 0 12.5-5.5859 12.5-12.5 0-34.461-28.039-62.5-62.5-62.5zm75.551-775h-276.1l138.05-191.15zm336.95 37.5c0 20.676-16.824 37.5-37.5 37.5h-225c-20.676 0-37.5-16.824-37.5-37.5v-12.5h300zm-306.11-37.5-130.85-181.18 392.54 181.18h-261.7zm-206.94-181.18-130.85 181.18h-261.68l392.54-181.18zm-174.45 256.18h-225c-20.676 0-37.5-16.824-37.5-37.5v-12.5h300v12.5c0 20.676-16.824 37.5-37.5 37.5zm62.5-37.5v-12.5h300v12.5c0 20.676-16.824 37.5-37.5 37.5h-225c-20.676 0-37.5-16.824-37.5-37.5zm-150 368.75c0-10.336 8.4141-18.75 18.75-18.75h562.5c10.336 0 18.75 8.4141 18.75 18.75s-8.4141 18.75-18.75 18.75h-256.25-306.25c-10.336 0-18.75-8.4141-18.75-18.75zm287.5 43.75h25v325h-25zm-85.363 375c5.1641-14.551 19.062-25 35.363-25h125c16.301 0 30.199 10.449 35.363 25z" />
				</g>
			</svg>
		</SvgIcon>
	);
};