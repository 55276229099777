import React from "react";
import moment from "moment-timezone";
import { BasicListItem } from "../../../../../components";
import { useHeaderScopedOrder } from "../../../../../graphql";

export const OrderSubmittedAtListItem: React.FC<{
	orderId: string;
}> = ({ orderId }) => {
	const { order, loading } = useHeaderScopedOrder(orderId);

	return (
		<BasicListItem
			label="Submitted At"
			loading={loading}
			value={moment.tz(order?.submittedAt ?? "", "UTC").tz("America/New_York").format("dddd, MMMM Do, hh:mm a")}
		/>
	);
};