import { gql } from "../../../__generated__";

export const FindBrands = gql(/* GraphQL */ `
	query FindBrands {
		FindBrands {
			id
			name
		}
	}
`);
