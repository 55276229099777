import React from "react";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import { AcceptHaulerPickupRequest, FetchHaulerPickup, FetchUserHauler } from "../../../../graphql";
import { useSnackbar } from "notistack";

export const AcceptHaulerPickupRequestButton: React.FC<{
	requestId: string;
}> = ({ requestId }) => {
	const snack = useSnackbar();

	const [
		accept, { loading: isAccepting }
	] = useMutation(AcceptHaulerPickupRequest, {
		variables: { requestId },
		refetchQueries: [ FetchUserHauler, FetchHaulerPickup ]
	});

	return (
		<LoadingButton
			color="success"
			variant="contained"
			loading={isAccepting}
			onClick={() => {
				accept().catch(err => {
					console.error("Failed to accept pickup request", err);
					snack.enqueueSnackbar(
						"We ran into an issue submitting your request.",
						{ variant: "error" }
					);
				});
			}}
		>
			Accept Pickup
		</LoadingButton>
	);
};
