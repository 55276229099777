import { gql } from "../../../__generated__";

export const FindProductsBySeller = gql(/* GraphQL */ `
	query FindProductsBySeller(
		$sellerId: String!
	) {
		FindProducts(
			seller: { id: { equals: $sellerId } }
		) {
			...ProductFragment
			...ProductListingFragment
		}
	}
`);