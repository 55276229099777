import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBuilding, useReferenceMedia, useWasteAudit, useWasteAuditMedia } from "../hooks";
import { useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { AccordionController, BasicListItem, DialogWithActivatorButton, HeadingWithIcon, Pagination, ViewContainer, VisuallyHiddenInput } from "../../../../components";
import { Box, Grid, GridExtendedProps, Heading, Image, Spinner } from "grommet";
import { AddAPhoto, Assessment, Close, CloudUpload, Delete, Edit, SettingsSuggest, Visibility } from "@mui/icons-material";
import { Button, Divider, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, MobileStepper, Typography, useTheme } from "@mui/material";
import { fileToBase64, formatNumber } from "../../../../helpers";
import { CreateWasteContainerAssignmentDialog } from "../components";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ContainerDisposition, removeMaterialIdentification, selectWasteAuditState, setAuditDetails, setContainerDetails, setCurrentStepIndex, setMediaId, updateMaterialIdentification, WasteAuditState } from "../../../../store/audit";
import { AutocompleteElement, FormContainer, TextFieldElement, ToggleButtonGroupElement, useForm, UseFormReturn } from "react-hook-form-mui";
import { LoadingButton } from "@mui/lab";
import { ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { useOnboardingMaterials } from "../../../onboarding/hooks";
import { Icon } from "@liverego/icons";
import { volumeUnitEnumToFriendly } from "../helpers";
import { WasteContainerDetailsDialog } from "../components/assignment/WasteContainerAssignmentDialog";

export const ResposiveGrid: React.FC<{
	shrink?: boolean;
	children: React.ReactNode | React.ReactNode[];
} & GridExtendedProps> = ({ shrink, children, ...props }) => (
	<Grid
		gap={props.gap || "small"}
		columns={shrink
			? { count: 1, size: "auto" }
			: props.columns || { count: 2, size: "auto" }
		}
	>
		{children}
	</Grid>
);

export const LocationDetailsView: React.FC = () => {
	const { size, width } = useWindowDimensions();
	const locationId = useRequiredPathParam("locationId", "/admin/locations");
	const { building, loading } = useBuilding(locationId);

	const containers = useMemo(() => {
		return building?.containers || [];
	}, [ building ]);

	const theme = useTheme();
	const [ viewContainerAssignment, setViewContainerAssignment ] = useState("");

	const [ recommendations, setRecommendations ] = useState([
		{ id: "1", name: "Cardboard Recycling", description: "It looks like you have cardboard present in your landfill waste stream. Cardboard can take up a considerable amount of space and lead to over spend. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		{ id: "2", name: "Reduce Waste from Move Out", description: "Implement a move-in/move-out recycling and donation program to minimize waste generated from discarded furniture, electronics, and packing materials. This will significantly reduce landfill waste and help manage bulky item disposal." },
		{ id: "3", name: "Achieve LEED Credits by Enhancing Resident Engagement", description: "Encourage residents to actively participate in recycling and composting programs through educational campaigns and incentives. High resident participation can boost recycling rates, contributing to LEED certification under waste management categories​." }
		// { id: "2", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "3", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "4", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "5", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "6", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
	]);

	function dismissRecommendation(id: string): void {
		setRecommendations(recommendations.filter(r => r.id !== id));
	}

	const [ isAdvisorLoading, setIsAdvisorLoading ] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsAdvisorLoading(false);
		}, 1500);
	}, []);

	return (
		<ViewContainer>
			{viewContainerAssignment && (
				<WasteContainerDetailsDialog
					onClose={() => {
						setViewContainerAssignment("");
					}}
					assignmentId={viewContainerAssignment}
				/>
			)}
			<Box gap="medium" style={{ display: "block" }} height="100%">
				<ResposiveGrid shrink={width < 1050} gap="medium">
					<Box gap="small">
						<HeadingWithIcon
							text={"Location Details"}
							props={{
								level: "3",
								margin: "none"
							}}
							containerProps={{
								style: { minHeight: "30px" }
							}}
							icon={<Assessment color="primary" />}
						/>
						{loading && (<LinearProgress />)}
						{!loading && (<Divider sx={{ borderColor: theme.palette.primary.main }} />)}
						<Pagination pageSize={10}>
							<BasicListItem
								loading={loading}
								label="Location Name"
								value={building?.name}
							/>
							<BasicListItem
								loading={loading}
								label="Location Address"
								value={building?.address?.addressLineOne}
							/>
						</Pagination>
					</Box>
					<Box gap="small">
						<HeadingWithIcon
							text={"Rego Advisor"}
							props={{
								level: "3",
								margin: "none"
							}}
							containerProps={{
								style: { minHeight: "30px" }
							}}
							icon={<SettingsSuggest color="primary" />}
						/>
						{isAdvisorLoading && (<LinearProgress />)}
						{!isAdvisorLoading && (<Divider sx={{ borderColor: theme.palette.primary.main }} />)}
						{recommendations.length === 0 && (
							<Box align="center" justify="center" margin={{ top: "medium" }}>
								<Typography>
									no recommendations available
								</Typography>
							</Box>
						)}
						{isAdvisorLoading && (
							<Box height="small" align="center" justify="center">
								<Spinner size="medium" />
							</Box>
						)}
						{!isAdvisorLoading && (
							<Pagination pageSize={3}>
								{recommendations.map((recommendation) => (
									<ListItemButton key={recommendation.id} divider style={{ height: "80px" }}>
										<ListItemText>
											<Typography fontWeight="bold" variant="body2" className="text-max-1-line">
												{recommendation.name}
											</Typography>
											<Typography
												variant="caption"
												sx={{ maxWidth: "80%" }}
												className="text-max-2-lines"
											>
												{recommendation.description}
											</Typography>
										</ListItemText>
										<ListItemSecondaryAction>
											<Box justify="end" direction="row" gap="xsmall">
												<IconButton color="primary">
													<Visibility />
												</IconButton>
												<IconButton
													color="error"
													onClick={() => {
														dismissRecommendation(recommendation.id);
													}}
												>
													<Close />
												</IconButton>
											</Box>
										</ListItemSecondaryAction>
									</ListItemButton>
								))}
								{/* {lineItemsByMaterial.map(material => (
								<ListItemButton
									divider
									key={material.id}
									style={{ minHeight: "80px" }}
								>
									<ListItemIcon>
										<Assessment color="primary" />
									</ListItemIcon>
									<ListItemText>
										<Typography fontWeight="bold">
											{material.name}
										</Typography>
									</ListItemText>
									<ListItemSecondaryAction>
										<Box direction="row" align="center" gap="small">
											<Typography fontWeight="bold" variant="body2">
												{formatNumber(material.totalWeightGeneratedPounds)} lb(s)
											</Typography>
											<Chip
												label={
													<Typography variant="caption">
														{getMaterialPercentage(material.totalWeightGeneratedPounds)}%
													</Typography>
												}
											/>
										</Box>
									</ListItemSecondaryAction>
								</ListItemButton>
							))} */}
							</Pagination>
						)}
					</Box>
				</ResposiveGrid>
				<Box gap="medium">
					<Box gap="small">
						<Box direction="row" justify="between" height="40px">
							<HeadingWithIcon
								text={"Assigned Containers"}
								props={{
									level: "3",
									margin: "none",
								}}
								icon={<Delete color="primary" />}
							/>
							<CreateWasteContainerAssignmentDialog
								activatorButton={(
									<Button
										startIcon={size !== "small" && (
											<Edit />
										)}
										size="small"
										variant="contained"
									>
										Add Container
									</Button>
								)}
							/>
						</Box>
						<Divider sx={{ borderColor: theme.palette.primary.main }} />
					</Box>
					<Box
						width="100%"
						gap="medium"
						direction="row"
						overflow={{ horizontal: "scroll" }}
						style={{ minHeight: "225px" }}
					>
						{containers.map(container => (
							<Box
								round="small"
								pad="small"
								hoverIndicator
								onClick={() => {
									setViewContainerAssignment(container.id);
								}}
								key={container.id}
								elevation="small"
								border={{ color: "light-4" }}
								style={{ minWidth: "300px", height: "200px" }}
							>
								<Box gap="small">
									<Box direction="row" justify="between" flex gap="small">
										<Box align="center" justify="center">
											<Icon
												url={container.container.iconUrl}
												fill={theme.palette.primary.main}
												fontSize="large"
											/>
										</Box>
										<Box flex align="end">
											<Typography fontWeight="bold" className="text-max-1-line">
												{container.name}
											</Typography>
											<Typography variant="caption">
												{container.location} {container.averageFillRate && ` | ${formatNumber(container.averageFillRate * 100, true)}% Avg Full`}
											</Typography>
										</Box>
									</Box>
									<Box gap="small">
										<Box>
											<Typography variant="caption">
												Material
											</Typography>
											<Typography fontWeight="bold" className="text-max-1-line">
												{container.material.name}
											</Typography>
										</Box>
										<Box direction="row" justify="between">
											<Box>
												<Typography variant="caption">
													Volume
												</Typography>
												<Typography fontWeight="bold" className="text-max-1-line">
													{container.volume} {volumeUnitEnumToFriendly(container.volumeUnit)}
												</Typography>
											</Box>
											<Box align="end">
												<Typography variant="caption">
													Weight (Monthly)
												</Typography>
												<Typography fontWeight="bold" className="text-max-1-line">
													{formatNumber(container.monthlyCollectionWeight, 0)} lb(s)
												</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		</ViewContainer>
	);
};

export const WasteAuditImageUploadStep: React.FC = () => {
	const dispatch = useAppDispatch();
	const { auditId, mediaId } = useAppSelector(selectWasteAuditState);

	const ref = React.createRef<HTMLInputElement>();
	const { media, loading } = useReferenceMedia(mediaId);
	const { upload, isUploading } = useWasteAuditMedia();


	function onFileUploaded(file: File): void {
		fileToBase64(file).then(base64Content => {
			return upload({
				name: file.name,
				content: base64Content,
				contentType: file.type,
				referenceId: auditId,
				context: ProductMediaContext.Default
			});
		}).then((data) => {
			const mediaId = data?.CreateReferenceMedia.id;
			mediaId && dispatch(setMediaId(mediaId));
		});
	}

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Upload Image(s)
				</Heading>
				<Typography variant="body2">
					Upload an image and our Waste Inspector tool with automatically analyze the waste composition of your image. Make sure the contents of the container are spread out (on something like a tarp) and visible in the image.
				</Typography>
			</Box>
			<Box gap="small">
				{!!media && (
					<Box
						height="small"
						background="light-1"
						align="center" justify="center"
					>
						<Image
							key={media.id}
							src={media.contentUrl}
							fit="contain"
						/>
					</Box>
				)}
				{!media && (
					<Box
						round
						height="small"
						background="light-1"
						align="center" justify="center"
					>
						<IconButton
							onClick={() => {
								ref.current?.click();
							}}
						>
							<AddAPhoto
								color="primary"
								fontSize="large"
							/>
						</IconButton>
					</Box>
				)}
				<LoadingButton
					color="primary"
					variant="contained"
					loading={isUploading}
					endIcon={<CloudUpload />}
					loadingPosition="end"
					onClick={(event) => {
						event.stopPropagation();
						ref.current?.click();
					}}
				>
					Upload Image
					<VisuallyHiddenInput
						ref={ref}
						type="file"
						accept=".png, .jpg, .jpeg"
						style={{ display: "none" }}
						onClick={(event) => {
							event.stopPropagation();
						}}
						onChange={(event) => {
							if(event.target.files) {
								for(const file of event.target.files) {
									onFileUploaded(file);
								}
							}
						}} />
				</LoadingButton>
			</Box>
		</Box>
	);
};

export const WasteAuditMaterialConfirmationStep: React.FC<{
	formContext: UseFormReturn<WasteAuditState>;
}> = ({ formContext }) => {
	const dispatch = useAppDispatch();
	const { materials: materialsState, mediaId } = useAppSelector(selectWasteAuditState);
	const { materials } = useOnboardingMaterials();
	const { media, loading: mediaLoading } = useReferenceMedia(mediaId);

	function upsertMaterials(selectedMaterials: { id: string; }[]) {
		const missing = selectedMaterials.filter(material => !materialsState.find(m => m.id === material.id));
		const removed = materialsState.filter(material => !selectedMaterials.find(m => m.id === material.id));

		for(const material of missing) {
			const found = materials.find(m => m.id === material.id);
			if(!found) continue;

			const existing = materialsState.find(m => m.id === material.id);

			dispatch(updateMaterialIdentification({
				id: material.id,
				name: found.name,
				estimatedRatio: existing?.estimatedRatio || 0,
				estimatatedWeightPounds: existing?.estimatatedWeightPounds || 0,
				estimatedVolumeCubicFeet: existing?.estimatedVolumeCubicFeet || 0,
			}));
		}

		for(const material of removed) {
			dispatch(removeMaterialIdentification(material.id));
		}
	}

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		///@ts-ignore
		formContext.setValue("materials", Array.from(materialsState).sort((a, b) => a.name.localeCompare(b.name)).map(material => ({ id: material.id, label: material.name })));
	}, [ formContext, materialsState ]);

	const [ detectionLoading, setDetectionLoading ] = useState(false);

	useEffect(() => {
		setDetectionLoading(true);
		setTimeout(() => {
			setDetectionLoading(false);
		}, 2000);
	}, [ media ]);

	const loading = useMemo(() => {
		return mediaLoading || detectionLoading;
	}, [ detectionLoading, mediaLoading ]);

	const materialFormContext = useForm({
		defaultValues: materialsState.reduce((acc, material) => {
			acc[ material.id ] = material.estimatatedWeightPounds;
			return acc;
		}, {} as Record<string, number>)
	});

	const handleBlur = useCallback((materialId: string) => {
		materialFormContext.handleSubmit(() => {
			console.log("SUBMIT");
		})();

		const value = materialFormContext.getValues(materialId);
		if(!isNaN(Number(value))) {
			materialFormContext.setValue(materialId, formatNumber(Number(value), 2) as unknown as number);
		}
	}, [ materialFormContext ]);

	function handleMaterialChanged(materialId: string, weight: string): void {
		materialFormContext.setValue(materialId, weight as unknown as number);
		const material = materialsState.find(m => m.id === materialId);
		if(!material) {
			const material = materials.find(m => m.id === materialId);
			dispatch(updateMaterialIdentification({
				id: materialId,
				name: material?.name || "Unknown",
				estimatedRatio: 0,
				estimatedVolumeCubicFeet: 0,
				estimatatedWeightPounds: isNaN(Number(weight)) ? 0 : Number(weight)
			}));

			return;
		}

		dispatch(updateMaterialIdentification({
			...material,
			estimatatedWeightPounds: isNaN(Number(weight)) ? 0 : Number(weight)
		}));
	}

	const fetchRatio = useCallback((materialId: string) => {
		const values = materialFormContext.getValues();
		const totalWeight = Object.values(values).reduce((acc, value) => acc + (isNaN(Number(value)) ? 0 : Number(value)), 0);
		const weight = values[ materialId ];

		if(totalWeight === 0) {
			return 0;
		}

		if(isNaN(Number(weight))) {
			return 0;
		}

		return (weight / totalWeight) * 100;
	}, [ materialFormContext ]);

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Confirm Materials
				</Heading>
				<Typography variant="body2">
					We've automatically added some materials based on the image you uploaded. Please confirm the materials that are present in the image. If something is missing, you can go back and add another image or add the material manually.
				</Typography>
			</Box>
			<AutocompleteElement
				multiple
				required
				loading={loading}
				showCheckbox
				name="materials"
				label="Add Materials(s)"
				options={Array.from(materials).sort((a, b) => a.name.localeCompare(b.name)).map(m => ({ id: m.id, label: m.name }))}
				autocompleteProps={{
					disabled: loading,
					onChange: (event, value) => {
						upsertMaterials(value);
					}
				}}
			/>
			<FormContainer formContext={materialFormContext}>
				<List>
					{Array.from(materialsState).sort((a, b) => a.name.localeCompare(b.name)).map(material => (
						<ListItem
							key={material.id}
							divider
							style={{ minHeight: "100px" }}
						>
							<ListItemIcon>
								<Assessment color="primary" />
							</ListItemIcon>
							<ListItemText>
								<Typography fontWeight="bold">
									{material.name}
								</Typography>
							</ListItemText>
							<ListItemSecondaryAction>
								<Box direction="row" align="center" gap="small">
									<TextFieldElement
										size="small"
										required
										fullWidth
										name={material.id}
										label="Total Weight lb(s)"
										validation={{
											validate: value => {
												if(!value && value !== 0) {
													return "This field is required.";
												}
												if(isNaN(Number(value))) {
													return "Please enter a valid number";
												}
											}
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{formatNumber(fetchRatio(material.id), 2)}%
												</InputAdornment>
											),
											onBlur: () => {
												handleBlur(material.id);
											},
											onChange: (event) => {
												handleMaterialChanged(material.id, event.target.value);
											}
										}}
									/>
								</Box>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</FormContainer>
		</Box>
	);
};


export const WasteAuditMaterialCompositionStep: React.FC<{
	formContext: UseFormReturn<WasteAuditState>;
}> = ({ formContext }) => {
	const { materials } = useAppSelector(selectWasteAuditState);

	const [ expanded, setExpanded ] = useState("");

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Material Composition
				</Heading>
				<Typography variant="body2">
					Let's finish up by identifying the weight and composition of your selected materials. We've filled in some information based on the image you uploaded, but feel free to adjust as needed.
				</Typography>
			</Box>
			<List disablePadding>
				{materials.map(material => (
					<AccordionController
						key={material.id}
						name="material"
						isExpanded={material.id === expanded}
						onChange={(event, expanded) => {
							setExpanded(expanded ? material.id : "");
						}}
						summary={(
							<Box direction="row" align="center">
								<ListItemIcon>
									<Assessment color="primary" />
								</ListItemIcon>
								<Typography fontWeight="bold">
									{material.name}
								</Typography>
							</Box>
						)}
						details={(
							<Box>
								DETAILS
							</Box>
						)}
					/>
				))}
			</List>
		</Box>
	);
};

export const WasteAuditIntroductionStep: React.FC = () => {
	const options = useMemo(() => {
		return [
			{ id: String(ContainerDisposition.LANDFILL), label: "Landfill" },
			{ id: String(ContainerDisposition.RECYCLE), label: "Recycle" },
			{ id: String(ContainerDisposition.COMPOST), label: "Compost" }
		];
	}, []);

	return (
		<Box gap="medium">
			<Box gap="small">
				<Heading margin="none" level="3">
					Get Started
				</Heading>
				<Typography variant="body2">
					Let's get your waste audit started! Please provide some basic information about the container or bin you are collecting waste from.
				</Typography>
			</Box>
			<TextFieldElement
				required
				fullWidth
				name="containerName"
				label="Container / Bin"
				helperText="A name or label for the container or bin you are collecting waste from."
			/>
			<ToggleButtonGroupElement
				exclusive
				required
				validation={{
					validate: value => {
						if(value === null) {
							return "This field is required.";
						}
						return;
					}
				}}
				color="primary"
				name="containerDisposition"
				label="Container Type"
				helperText="What was supposed to be placed in this container?"
				options={options}
			/>
		</Box>
	);
};

export const WasteAuditUploadDialog: React.FC<{
	auditId: string;
	activatorButton: React.ReactNode;
}> = ({ auditId, activatorButton }) => {
	const totalSteps = 4;
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectWasteAuditState);

	const { audit } = useWasteAudit(auditId);

	useEffect(() => {
		if(audit) {
			dispatch(setAuditDetails({ id: audit.id, name: audit.name }));
		}
	}, [ audit, dispatch ]);

	const formContext = useForm({
		defaultValues: { ...state }
	});

	const {
		containerName,
		containerDisposition
	} = formContext.watch();

	useEffect(() => {
		dispatch(setContainerDetails({ containerName, containerDisposition }));
	}, [ containerName, containerDisposition, dispatch ]);

	const hasNext = useMemo(() => {
		return state.currentStepIndex < totalSteps - 1;
	}, [ state.currentStepIndex ]);

	const hasBack = useMemo(() => {
		return state.currentStepIndex > 0;
	}, [ state.currentStepIndex ]);

	function handleBack(): void {
		if(!hasBack) return;
		dispatch(setCurrentStepIndex(state.currentStepIndex - 1));
	}

	function handleSubmit(): void {
		//
	}

	function handleNext(): void {
		if(!hasNext) {
			formContext.handleSubmit(handleSubmit)();
			return;
		}

		formContext.handleSubmit(() => {
			dispatch(setCurrentStepIndex(state.currentStepIndex + 1));
		})();
	}

	const content = useMemo(() => {
		switch(state.currentStepIndex) {
			case 0:
				return (
					<WasteAuditIntroductionStep />
				);
			case 1:
				return (
					<WasteAuditImageUploadStep />
				);
			case 2:
				return (
					<WasteAuditMaterialConfirmationStep
						formContext={formContext}
					/>
				);
			case 3: {
				return (
					<WasteAuditMaterialCompositionStep
						formContext={formContext}
					/>
				);
			}
			default:
				return null;
		}
	}, [ formContext, state.currentStepIndex ]);

	return (
		<DialogWithActivatorButton
			title="Waste Audit"
			activatorButton={activatorButton}
			actions={(
				<MobileStepper
					steps={totalSteps}
					position="static"
					activeStep={state.currentStepIndex}
					backButton={(
						<Button variant="outlined" onClick={handleBack} disabled={!hasBack}>
							Back
						</Button>
					)}
					nextButton={(
						<Button variant="contained" onClick={handleNext}>
							{hasNext ? "Next" : "Submit"}
						</Button>
					)}
				/>
			)}
		>
			<FormContainer formContext={formContext}>
				{content}
			</FormContainer>
		</DialogWithActivatorButton>
	);
};