import { SvgIcon, SvgIconProps } from "@mui/material";

	export const BathroomSink: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1003.6 360.37v-37.922c0-18.797-15.281-34.078-34.078-34.078h-96.141v-150.52c0-3.9375-2.5781-7.4531-6.3281-8.625l-252.47-77.812c-2.7188-0.84375-5.7188-0.32812-7.9688 1.3594-2.2969 1.6875-3.6562 4.3594-3.6562 7.2656v53.344c0 3.6562 2.2031 6.9375 5.5781 8.2969l182.68 75.516v91.172h-560.86c-18.797 0-34.078 15.281-34.078 34.078v37.922c0 42.516 22.547 79.734 56.203 100.73h-47.203c-4.9688 0-9 4.0312-9 9v493.82c0 4.9688 4.0312 9 9 9h34.594v167.06c0 4.9688 4.0312 9 9 9h78.141c4.9688 0 9-4.0312 9-9v-40.359c0-18.562 17.297-33.656 38.625-33.656h450.71c21.281 0 38.625 15.094 38.625 33.656v40.359c0 4.9688 4.0312 9 9 9h78.141c4.9688 0 9-4.0312 9-9v-167.06h34.594c4.9688 0 9-4.0312 9-9v-493.82c0-4.9688-4.0312-9-9-9h-47.203c33.656-20.953 56.203-58.219 56.203-100.73zm-199.97-177.47-182.68-75.516v-35.156l234.47 72.281v143.9h-46.219v-97.172c0-3.6562-2.2031-6.9375-5.5781-8.2969zm-589.31 296.21h376.64v475.82h-376.64zm727.74 651.89h-60.141v-31.359c0-28.5-25.406-51.656-56.625-51.656l-450.66-0.046875c-31.219 0-56.625 23.203-56.625 51.656v31.359h-60.141v-158.06h684.14v158.06zm43.594-651.89v475.82h-376.64v-475.82zm-100.73-18h-569.86c-55.547 0-100.73-45.188-100.73-100.73v-37.922c0-8.8594 7.2188-16.078 16.078-16.078h739.22c8.8594 0 16.078 7.2188 16.078 16.078v37.922c0 55.547-45.188 100.73-100.73 100.73z"/>
  <path d="m674.68 836.76c4.9688 0 9-4.0312 9-9v-209.29c0-4.9688-4.0312-9-9-9s-9 4.0312-9 9v209.29c0 4.9688 4.0312 9 9 9z"/>
  <path d="m525.32 836.76c4.9688 0 9-4.0312 9-9v-209.29c0-4.9688-4.0312-9-9-9s-9 4.0312-9 9v209.29c0 4.9688 4.0312 9 9 9z"/>
  <path d="m945 369.98c-4.875-0.79688-9.5156 2.5312-10.312 7.4531-4.7344 29.203-36.047 37.078-37.359 37.406-4.8281 1.125-7.8281 5.9531-6.7031 10.781 0.98438 4.1719 4.6875 6.9375 8.7656 6.9375 0.65625 0 1.3594-0.09375 2.0625-0.23438 1.8281-0.42188 44.344-10.781 51.047-52.031 0.79688-4.9219-2.5312-9.5156-7.4531-10.312z"/>
  <path d="m263.9 915.42v-49.031c0-4.9688-4.0312-9-9-9s-9 4.0312-9 9v49.031c0 4.9688 4.0312 9 9 9s9-4.0312 9-9z"/>
  <path d="m254.9 842.26c4.9688 0 9-4.0312 9-9v-1.5c0-4.9688-4.0312-9-9-9s-9 4.0312-9 9v1.5c0 4.9688 4.0312 9 9 9z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};