import { Box, Spinner, Text } from "grommet";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import { Divider, InputAdornment } from "@mui/material";
import { useStorePartner } from "../../hooks/useStorePartner";

export const StoreSelectorComponent: React.FC = (props) => {
	const { partners, selectedPartner, setSelectedPartner, isLoading } = useStorePartner();

	function handleSelectPartner(partnerId: string): void {
		const found = partners.find((partner) => partner.id === partnerId);
		if(found) {
			setSelectedPartner(found);
		}
	}

	if(partners.length <= 1) {
		return <></>;
	}

	return (
		<Box gap="medium" flex>
			<Box margin="small" gap="medium" flex justify="end">
				<Divider />
				<Text>
					Showing data for: <Text weight="bold">{selectedPartner?.name ?? ""}</Text>
				</Text>
				<FormContainer>
					<Box>
						<SelectElement
							name="selectedPartner"
							label="Select Store"
							options={partners.map((partner) => ({ label: partner.name, id: partner.id }))}
							onChange={(event) => handleSelectPartner(event)}
							InputProps={{
								endAdornment: isLoading && (
									<InputAdornment position="end">
										<Spinner />
									</InputAdornment>
								)
							}}
						/>
					</Box>
				</FormContainer>
			</Box>
		</Box>
	);
};
