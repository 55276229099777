import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Lamp: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m941.96 391c0.058594-2.6875-0.88672-5.3867-2.9414-7.4414l-247.22-246.77 30.637-30.695-37.262-37.246-30.66 30.719-35.855-35.797c-3.9844-3.9609-10.43-3.9609-14.387 0.023438-3.9844 3.9727-3.9844 10.414 0.011718 14.398l35.844 35.785-10.824 10.836-53.902-53.82-307.21 236.58 216.3 215.93 236-307.63-53.902-53.82 10.824-10.848 242.72 242.29-216.8 589.01h-121.81v51.828h-323.48v104.86h632.48v-104.86h-75.574v-51.828h-89.902l216.35-587.73c0.44141-1.2383 0.60938-2.5234 0.57422-3.7695zm-256.77-293.33 8.4609 8.4492-16.262 16.281-8.4727-8.4492zm8.2812 119.99-211.02 275.05-183.46-183.16 274.67-211.51zm-41.281-69.996-8.4727-8.4609 10.824-10.836 8.4609 8.4609zm217.95 907.03v64.141h-591.75v-64.141zm-75.566-20.363h-192.7v-31.477h192.7z"/>
</svg>
			</SvgIcon>
		);
	};