import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Microwave: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m1074.4 191.41h-948.72c-47.566 0-86.266 38.699-86.266 86.266v565.81c0 43.738 32.754 79.891 75 85.441v29.672c0 27.57 22.426 49.996 49.996 49.996h135.27c27.562 0 49.996-22.426 49.996-49.996v-28.852h500.74v28.852c0 27.57 22.426 49.996 49.996 49.996h135.27c27.57 0 49.996-22.426 49.996-49.996v-29.672c42.246-5.5508 75-41.707 75-85.441l-0.003907-565.81c-0.007812-47.562-38.707-86.266-86.273-86.266zm-777.23 764.69h-130.25v-26.348h130.26v26.348zm736 0h-130.26v-26.348h130.26zm75-112.61c0 18.613-15.148 33.766-33.766 33.766h-948.72c-18.613 0-33.766-15.148-33.766-33.766v-565.81c0-18.613 15.148-33.766 33.766-33.766h948.73c18.613 0 33.766 15.148 33.766 33.766v565.81z" />
		<path
			d="m625.16 290.58h-428.55c-37.816 0-68.586 30.773-68.586 68.586v402.3c0 37.816 30.773 68.586 68.586 68.586h428.55c37.824 0 68.594-30.773 68.594-68.586v-402.3c-0.007812-37.82-30.773-68.586-68.594-68.586zm16.086 372.84c-45.906-11.73-80.004-53.324-80.004-102.83 0-49.508 34.098-91.105 80.004-102.84zm-16.086 114.13h-428.55c-8.8711 0-16.086-7.2148-16.086-16.086v-402.3c0-8.8711 7.2148-16.086 16.086-16.086h428.55c8.8711 0 16.094 7.2148 16.094 16.086v60.18c-66.758 12.383-117.5 70.949-117.5 141.23 0 70.281 50.746 128.86 117.5 141.24v59.648c0 8.8711-7.2227 16.086-16.086 16.086z" />
		<path
			d="m1026.3 276.74h-226.93c-28.453 0-51.609 23.152-51.609 51.609v52.801c0 28.453 23.152 51.609 51.609 51.609h226.93c28.453 0 51.609-23.152 51.609-51.609v-52.801c-0.003906-28.457-23.156-51.609-51.609-51.609zm14.105 104.41c0 7.7773-6.3281 14.109-14.109 14.109h-226.93c-7.7773 0-14.109-6.3281-14.109-14.109v-52.801c0-7.7773 6.3281-14.109 14.109-14.109h226.93c7.7773 0 14.109 6.3281 14.109 14.109z" />
		<path
			d="m825.29 724.96h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3945-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m942.23 724.96h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3945-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m1059.2 724.96h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3906-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m825.29 600.27h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3945-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m942.23 600.27h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3945-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m1059.2 600.27h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3906-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m825.29 475.58h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3945-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m942.23 475.58h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3945-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
		<path
			d="m1059.2 475.58h-58.746c-10.359 0-18.75 8.3906-18.75 18.75v58.746c0 10.359 8.3906 18.75 18.75 18.75h58.746c10.359 0 18.75-8.3906 18.75-18.75v-58.746c0-10.355-8.3906-18.75-18.75-18.75zm-18.75 58.746h-21.246v-21.246h21.246z" />
	</g>
</svg>
			</SvgIcon>
		);
	};