import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Refrigerator: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m425.71 151.3c-10.359 0-18.75 8.3906-18.75 18.75v115.45c0 10.359 8.3906 18.75 18.75 18.75s18.75-8.3906 18.75-18.75v-115.45c0-10.355-8.3906-18.75-18.75-18.75z" />
		<path
			d="m425.71 874.03c10.359 0 18.75-8.3906 18.75-18.75v-253.99c0-10.359-8.3906-18.75-18.75-18.75s-18.75 8.3906-18.75 18.75v253.98c0 10.359 8.3945 18.758 18.75 18.758z" />
		<path
			d="m811.38 39.023h-422.76c-46.41 0-84.156 37.754-84.156 84.156v885.21c0 32.762 18.855 61.141 46.254 75.039v25.859c0 28.508 23.199 51.703 51.703 51.703h1.0781c28.508 0 51.703-23.199 51.703-51.703v-16.734h289.59v16.734c0 28.508 23.199 51.703 51.703 51.703h1.0859c28.508 0 51.703-23.199 51.703-51.703v-25.859c27.398-13.898 46.254-42.27 46.254-75.039l0.007813-618.11v-267.1c-0.007813-46.41-37.758-84.156-84.168-84.156zm-454.42 84.156c0-17.461 14.203-31.656 31.656-31.656h422.76c17.453 0 31.656 14.203 31.656 31.656v240.85h-486.07zm486.08 885.21c0 17.453-14.203 31.656-31.656 31.656h-422.76c-17.453 0-31.656-14.203-31.656-31.656l-0.003906-591.86h486.07zm-425.34 100.89c0 7.8281-6.375 14.203-14.203 14.203h-1.0859c-7.8281 0-14.203-6.375-14.203-14.203v-16.754c0.13672 0 0.26953 0.023437 0.40625 0.023437h29.086l-0.003906 16.73zm394.08 0c0 7.8281-6.375 14.203-14.203 14.203h-1.082c-7.8281 0-14.203-6.375-14.203-14.203v-16.734h29.086c0.13672 0 0.26953-0.023437 0.40625-0.023437z" />
	</g>
</svg>
			</SvgIcon>
		);
	};