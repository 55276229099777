import { gql } from "../../../__generated__";

export const CreateProductFragmentMedia = gql(/* GraphQL */ `
	mutation CreateProductFragmentMedia(
		$fragmentId: String!
		$content: String!
		$contentType: String!
		$name: String!
		$context: ProductMediaContext!
	) {
		CreateProductFragmentMedia(
			fragmentId: $fragmentId
			content: $content
			contentType: $contentType
			name: $name
			context: $context
		) {
			id
		}
	}
`);
