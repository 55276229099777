import React from "react";
import { HeaderError } from "./Header";
import { Box, Heading } from "grommet";
import { defaultThemeState } from "../provider";
import { Button, Typography } from "@mui/material";

interface ErrorBoundaryProps {
	children: React.ReactNode;
}

interface ErrorBoundaryState {
	error: any;
	errorInfo: any;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error: unknown, errorInfo: unknown) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		const { innerWidth: width, innerHeight: height } = window;
		// eslint-disable-next-line no-constant-condition
		if(this.state.errorInfo) {
			// Error path
			return (
				<Box height={`${height}px`} width={`${width}px`}>
					<HeaderError theme={defaultThemeState} />
					<Box height="medium" align="center" justify="center" gap="medium">
						<Heading level="2" margin="none" color={defaultThemeState.primaryColor}>
							Oops... Something went wrong.
						</Heading>
						<Box style={{ maxWidth: "80%" }}>
							<Typography fontSize="large" fontWeight="bold">
								We ran into an issue. Please try refreshing the page or contact support if the problem persists.
							</Typography>
						</Box>
						<Button
							variant="contained"
							onClick={() => window.location.reload()}
							sx={{ backgroundColor: defaultThemeState.primaryColor }}
						>
							Reload Page
						</Button>
					</Box>
				</Box>
			);
		}

		// Normally, just render children
		return this.props.children;
	}
}