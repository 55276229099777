import { HaulerPickupsTabController } from "../components/pickup";
import { useHauler } from "../hooks";
import { ViewContainer } from "../../../components";

export const HaulerPickupsView: React.FC = () => {
	const { hauler } = useHauler();

	return (
		<ViewContainer>
			{hauler && (
				<HaulerPickupsTabController
					haulerId={hauler.id}
				/>
			)}
		</ViewContainer>
	);
};