import { useForm } from "react-hook-form-mui";
import { DialogWithActivatorButton } from "../../../../components";
import { useMutation } from "@apollo/client";
import { ProductIntent, ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { CreateOrderProduct, FetchOrderUnified, useUserScopedOrder } from "../../../../graphql";
import { Typography } from "@mui/material";
import { Box, Heading } from "grommet";
import { fileToBase64, formatCurrency, isNotNull } from "../../../../helpers";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { UpsertOrderProductForm } from "./UpsertOrderProductForm";
import { useCreateProductEstimatePreview } from "../../hooks/useCreateProductEstimatePreview";
import { useEffect, useState } from "react";
import { AddProductBlockedDialog } from "./AddProductBlockedDialog";

export interface UpsertOrderProductFormValues {
	intent: ProductIntent;
	quantity: number;
	quantityDisassembly: number;
	type: { id: string; name: string; } | null;
	size: { id: string; name: string; } | null;
	media: { name: string; contentUrl: string; file?: File; }[];
}

export const CreateOrderProductDialog: React.FC<{
	orderId: string;
	activatorButton: React.ReactNode;
}> = ({ orderId, activatorButton }) => {
	const snack = useSnackbar();
	const formContext = useForm<UpsertOrderProductFormValues>({
		defaultValues: {
			intent: ProductIntent.Donation,
			type: null,
			size: null,
			quantity: 1,
			quantityDisassembly: 0,
			media: []
		}
	});

	const formValues = formContext.watch();
	const { order } = useUserScopedOrder(orderId);
	const { estimate, loading } = useCreateProductEstimatePreview(orderId, formContext);

	const [
		createProduct,
		{ loading: creatingProduct }
	] = useMutation(CreateOrderProduct, {
		refetchQueries: [
			FetchOrderUnified
		]
	});

	const [ forceClose, setForceClose ] = useState(false);
	useEffect(() => {
		if(forceClose) {
			setTimeout(() => {
				setForceClose(false);
			}, 10);
		}
	}, [ forceClose ]);

	function handleSubmit(): void {
		const media = formValues.media;
		if(media.length === 0) {
			snack.enqueueSnackbar("Please upload an image for your item", {
				variant: "error"
			});
			return;
		}

		Promise.all(media.filter(m => m.file).map(m => m.file).filter(isNotNull).map(file => {
			return fileToBase64(file).then(content => {
				return {
					content,
					isPrimary: false,
					name: file.name,
					contentType: file.type,
					description: "",
					context: ProductMediaContext.Front
				};
			});
		})).then(media => {
			createProduct({
				variables: {
					orderId,
					intent: formValues.intent,
					typeId: formValues.type?.id || "",
					sizeId: formValues.size?.id,
					quantity: formValues.quantity,
					quantityDisassembly: formValues.quantityDisassembly,
					media
				}
			}).then(() => {
				snack.enqueueSnackbar("Item successfully added", {
					variant: "success"
				});
				formContext.reset();
				setForceClose(true);
			}).catch(err => {
				snack.enqueueSnackbar("We ran into an issue saving your information", {
					variant: "error"
				});
				console.error("Failed to add product", err);
			});
		});
	}

	if(order?.schedulePending) {
		return (
			<AddProductBlockedDialog
				activatorButton={activatorButton}
			/>
		);
	}

	return (
		<DialogWithActivatorButton
			title="Add Item"
			forceClose={forceClose}
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="between">
					<Box gap="small" justify="center">
						{estimate && (
							<Box gap="xsmall">
								<Heading level="3" margin="none">
									Total: {formatCurrency(estimate.totalAmount)}
								</Heading>
								<Typography variant="caption">
									This is your new total price
								</Typography>
							</Box>
						)}
					</Box>
					<Box justify="center">
						<LoadingButton
							color="primary"
							variant="contained"
							loading={loading || creatingProduct}
							onClick={formContext.handleSubmit(handleSubmit)}
						>
							Submit
						</LoadingButton>
					</Box>
				</Box>
			)}
		>
			<UpsertOrderProductForm
				loading={loading || creatingProduct}
				onSubmit={(values) => {
					//
				}}
				formContext={formContext}
			/>
		</DialogWithActivatorButton>
	);
};