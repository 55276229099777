import React from "react";
import { TextFieldElement } from "react-hook-form-mui";

export const StreetAddressElement: React.FC = () => {
	return (
		<TextFieldElement
			fullWidth
			required
			id="address-element"
			name="addressLineOne"
			label="Business Address"
		/>
	);
};