import { SidebarLayout, useSidebarShrink } from "../../components";
import { HaulerSidebar } from "./components";

export const HaulerLayout: React.FC = () => {
	const shrink = useSidebarShrink();
	return (
		<SidebarLayout
			sidebar={(
				<HaulerSidebar
					shrink={shrink}
				/>
			)}
		/>
	);
};