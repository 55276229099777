import React from "react";
import { resolveFromUrl } from "@liverego/icons";
import { useTheme } from "@mui/material";
import { useMemo } from "react";

export const ProductIcon: React.FC<{
	iconUrl: string;
	fill?: string;
	fontSize?: string;
}> = ({ iconUrl, fontSize, fill }) => {
	const theme = useTheme();

	const Icon = useMemo(() => {
		return resolveFromUrl(iconUrl);
	}, [ iconUrl ]);

	if(!Icon) return null;

	return (
		<Icon
			sx={{
				fontSize: fontSize || "48px",
				fill: fill || theme.palette.primary.main
			}}
		/>
	);
};