import React from "react";
import { HeadingWithIcon, ViewContainer } from "../../../../components";
import { Assessment } from "@mui/icons-material";
import { Box } from "grommet";
import { LinearProgress } from "@mui/material";

export const CertificationsView: React.FC = () => {
	const loading = true;

	return (
		<ViewContainer>
			<Box gap="small"
				style={{ minHeight: "32px" }}
			>
				<HeadingWithIcon
					text="Certifications"
					props={{
						level: "3",
						margin: "none"
					}}
					icon={< Assessment color="primary" />}
				/>
				{loading && (
					<LinearProgress />
				)}
			</Box>
		</ViewContainer>
	);
};