import { gql } from "../__generated__";

export const FetchBuilding = gql(/* GraphQL */`
	query FetchBuilding($buildingId: String!) {
		FetchBuilding(buildingId: $buildingId) {
			...Building_HeaderFragment
			...Building_AddressFragment
			...Building_ScheduledPickupsFragment
		}
	}
`);

export const FetchBuildingManagementCompany = gql(/* GraphQL */`
	query FetchBuildingManagementCompany($buildingId: String!) {
		FetchBuilding(buildingId: $buildingId) {
			...Building_HeaderFragment
			...Building_AddressFragment
			...Building_ReferralsFragment
			...Building_ScheduledPickupsFragment
		}
	}
`);

export const FetchBuildingByInstance = gql(/* GraphQL */`
	query FetchBuildingByInstance($instanceId: String!) {
		FetchBuildingByInstance(instanceId: $instanceId) {
			...Building_HeaderFragment
			...Building_AddressFragment
			...Building_ScheduledPickupsFragment
		}
	}
`);

export const FetchBuildingByResident = gql(/* GraphQL */`
	query FetchBuildingByResident(
		$instanceId: String!
	) {
		FetchBuildingByResident(
			instanceId: $instanceId
		) {
			...Building_HeaderFragment
			...Building_AddressFragment
			...Building_ScheduledPickupsFragment
		}
	}
`);

export const FetchBuildingByInstanceUnauthenticated = gql(/* GraphQL */`
	query FetchBuildingByInstanceUnauthenticated($instanceId: String!) {
		FetchBuildingByInstance(instanceId: $instanceId) {
			id
			name
			unitPrefix
		}
	}
`);

export const FetchResidentByBuilding = gql(/* GraphQL */`
	query FetchResidentByBuilding($userId: String!, $buildingId: String!) {
		FetchResidentByBuilding(userId: $userId, buildingId: $buildingId) {
			id
			building {
				...Building_HeaderFragment
				...Building_AddressFragment
			}
			unit
			moveOutDate
			registered
			registeredAt
		}
	}
`);

export const FindOrdersByBuilding = gql(/* GraphQL */`
	query FindOrdersByBuilding(
		$buildingId: String!,
		$completedAt: iDateFilters
		$submittedAt: iDateFilters
		$scheduledAt: iDateFilters
		$cancelledAt: iDateFilters
		$status: iStringFilters
		$pickup: FindPickupDTO
	) {
		FindOrders(
			status: $status
			pickup: $pickup
			submittedAt: $submittedAt
			scheduledAt: $scheduledAt
			cancelledAt: $cancelledAt
			completedAt: $completedAt
			referer: { id: { equals: $buildingId } }
		) {
			...Order_ExpandedFragment
			...Order_UserResidentFragment
		}
	}
`);