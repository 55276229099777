import { useWindowDimensions } from "../../../../hooks";
import { ViewContainer } from "../../../../components";
import { Box, Grid } from "grommet";
import { ActiveOrdersContainer, UpcomingPickupsContainer } from "../components";
import { useEffect, useMemo } from "react";
import { push } from "redux-first-history";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../../store";

export const ResidenceAdminDashboardView: React.FC = () => {
	const instancePublic = useAppSelector(selectInstancePublic);
	const isCoxEnterprises = useMemo(() => {
		return instancePublic?.name === "Cox Enterprises";
	}, [ instancePublic ]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if(isCoxEnterprises) {
			dispatch(push("/admin/waste-reporting"));
		}
	}, [ isCoxEnterprises ]);

	const { size } = useWindowDimensions();

	const container = useMemo(() => {
		return size === "xlarge" ? (
			<Grid columns={[ "2/3", "1/3" ]} gap="small" height="100%">
				<ActiveOrdersContainer />
				<UpcomingPickupsContainer />
			</Grid>
		)
			: (
				<Box style={{ display: "block" }} height="100%" gap="small">
					<ActiveOrdersContainer />
					<UpcomingPickupsContainer />
				</Box>
			);
	}, [ size ]);

	return (
		<ViewContainer>
			{container}
		</ViewContainer>
	);
};