import { SvgIcon, SvgIconProps } from "@mui/material";

	export const CeilingLight: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m994.35 1060.7v-24.602c-6.2656-100.33-50.59-194.48-123.92-263.24-73.328-68.758-170.14-106.93-270.66-106.74h23.211v-400.85c2.8359-2.9336 5.1875-6.3047 6.9609-9.9805l72.418-208.9c4.668-10.133 3.8555-21.941-2.1602-31.336-6.0117-9.3945-16.395-15.082-27.551-15.086h-144.6c-11.078-0.078125-21.457 5.4141-27.621 14.621-5.957 8.6484-7.2617 19.68-3.4805 29.48l71.488 211.22v1.8555c1.8203 3.9648 4.4258 7.5156 7.6602 10.445v398.53h23.211c-100.2 0.27344-196.55 38.664-269.48 107.38-72.934 68.711-117 162.6-123.24 262.61l-1.3945 24.602h281.08c-1.5977 7.6328-2.375 15.414-2.3203 23.211 0 41.461 22.121 79.773 58.027 100.51 35.906 20.73 80.148 20.73 116.05 0 35.906-20.734 58.027-59.047 58.027-100.51 0.054687-7.7969-0.72266-15.578-2.3203-23.211zm-447.74-1014.3h106.54l-53.383 155.28zm53.156 1107.2c-18.469 0-36.18-7.3359-49.238-20.395s-20.395-30.77-20.395-49.238c-0.050781-7.9297 1.3633-15.797 4.1758-23.211h130.91c2.8125 7.4141 4.2266 15.281 4.1758 23.211 0 18.469-7.3359 36.18-20.395 49.238s-30.77 20.395-49.234 20.395zm0-139.27h-344.45c11.824-82.992 53.195-158.93 116.52-213.86 63.32-54.938 144.34-85.184 228.16-85.184s164.84 30.246 228.16 85.184c63.32 54.934 104.69 130.87 116.52 213.86z"/>
</svg>
			</SvgIcon>
		);
	};