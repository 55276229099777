import { Box, Heading } from "grommet";
import { Pagination, ViewContainer } from "../../../../components";
import { useBuildings } from "../hooks";
import { BuildingListItem } from "../components";
import { useWindowDimensions } from "../../../../hooks";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useMemo } from "react";
import Fuse from "fuse.js";

export const BuildingsView: React.FC = () => {
	const buildings = useBuildings();
	const { size } = useWindowDimensions();

	const formContext = useForm({
		defaultValues: { search: "" }
	});

	const searchTerm = formContext.watch("search");

	const filtered = useMemo(() => {
		const searchTerm = formContext.getValues("search");
		if(!searchTerm || searchTerm.length < 3) return buildings;

		const fuse = new Fuse(buildings, {
			threshold: 0.3,
			includeMatches: true,
			includeScore: true,
			keys: [
				"name",
				"address.addressLineOne",
				"address.city",
				"address.state"
			]
		});

		return fuse.search(searchTerm).map(result => result.item);
	}, [ buildings, searchTerm ]);

	return (
		<ViewContainer>
			<Box flex gap="medium">
				<Box
					gap="medium"
					justify={size === "small" ? "start" : "between"}
					direction={size === "small" ? "column" : "row"}
				>
					<Box justify="center">
						<Heading level="3" margin={size === "small" ? "small" : "none"}>
							Buildings / Properties
						</Heading>
					</Box>
					<Box justify="center">
						<FormContainer formContext={formContext}>
							<TextFieldElement
								name="search"
								label="Search"
								size={size === "small" ? undefined : "small"}
								fullWidth={size === "small"}
							/>
						</FormContainer>
					</Box>
				</Box>
				<Pagination pageSize={5}>
					{filtered.map((building) => (
						<BuildingListItem
							buildingId={building.id}
							key={building.id}
						/>
					))}
				</Pagination>
			</Box>
		</ViewContainer>
	);
};

