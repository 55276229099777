import { Box, Spinner } from "grommet";
import { Chip, IconButton, ListItemButton, ListItemSecondaryAction, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { UpdateProductOutletDialog } from "./UpdateProductOutletDialog";
import { useProduct } from "../../../hooks";
import { ProductListItemIcon, ProductListItemQuantity, ProductListItemText } from "../../../../../components";
import { useWindowDimensions } from "../../../../../hooks";

export const ProductDispositionChip: React.FC<{
	productId: string;
	allowQuickEditDisposition: boolean;
}> = ({ productId, allowQuickEditDisposition }) => {
	const { product, loading } = useProduct(productId);

	return (
		<Chip
			deleteIcon={loading
				? <Spinner />
				: allowQuickEditDisposition ? (
					<UpdateProductOutletDialog
						productId={productId}
						outlet={product?.outlet ?? null}
						fallbackOutlet={product?.fallbackOutlet ?? null}
						activationButton={(
							<IconButton>
								<Edit sx={{ fontSize: "16px" }} />
							</IconButton>
						)}
					/>
				) : undefined}
			onDelete={allowQuickEditDisposition ? (event) => {
				event.stopPropagation();
			} : undefined}
			color="secondary"
			label={<Typography color="accent1" fontWeight="bold" variant="caption">{product?.disposition || "NOT ASSIGNED"}</Typography>}
		/>
	);
};

interface AdminProductSummaryListItemProps {
	productId: string;
	onSelect: () => void;
	allowDelete?: boolean;
	enableAIDetection?: boolean;
	allowQuickEditDisposition?: boolean;
}

export const AdminProductSummaryListItem: React.FC<AdminProductSummaryListItemProps> = (props) => {
	const { size } = useWindowDimensions();
	const { product, loading } = useProduct(props.productId);

	// const { bestDetection, detections, loading: loadingDetections } = useProductAI(
	// 	props.enableAIDetection ? props.productId : ""
	// );

	return (
		<ListItemButton divider onClick={(event) => {
			props.onSelect();
		}}>
			<Box flex gap="small">
				<Box direction="row" align="center" gap="small">
					<ProductListItemIcon
						loading={loading}
						product={product || undefined}
					/>
					<ProductListItemText
						loading={loading}
						product={product || undefined}
					/>
					<ListItemSecondaryAction onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
					}}>
						<Box direction="row" gap="small">
							{size !== "small" && (
								<Box align="center" justify="center" direction="row" gap="small">
									<ProductDispositionChip
										productId={props.productId}
										allowQuickEditDisposition={props.allowQuickEditDisposition || false}
									/>
								</Box>
							)}
							<ProductListItemQuantity
								loading={loading}
								product={product || undefined}
							/>
							<IconButton
								color="error"
								style={{ padding: "0px" }}
								disabled={!props.allowDelete}
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
								}}
							>
								<Delete />
							</IconButton>
						</Box>
					</ListItemSecondaryAction>
				</Box>
				{size === "small" && (
					<Box direction="row" justify="start" align="center" gap="small">
						<ProductDispositionChip
							productId={props.productId}
							allowQuickEditDisposition={props.allowQuickEditDisposition || false}
						/>
						{product?.requiresDisassembly && (
							<Chip
								color="warning"
								label={<Typography fontWeight="bold" variant="caption">{"Disassembly".toUpperCase()}</Typography>}
							/>
						)}
					</Box>
				)}
			</Box>
		</ListItemButton>
	);
};
