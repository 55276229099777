import { QueryResult, useQuery } from "@apollo/client";
import { FetchCheckoutSessionQuery, FetchCheckoutSessionQueryVariables } from "../graphql/__generated__/graphql";
import { FetchCheckoutSession } from "../graphql";

export function wrapSessionQuery(result: QueryResult<FetchCheckoutSessionQuery, FetchCheckoutSessionQueryVariables>) {
	return {
		...result,
		referer: result.data?.FetchCheckoutSession?.referer ?? null,
		pickup: result.data?.FetchCheckoutSession?.pickup ?? null,
		session: result.data?.FetchCheckoutSession ?? null,
		address: result.data?.FetchCheckoutSession?.address ?? null,
		products: result.data?.FetchCheckoutSession?.products ?? [],
		estimate: result.data?.FetchCheckoutSession?.estimate ?? null,
		paymentMethod: result.data?.FetchCheckoutSession?.paymentMethod ?? null,
		useCustomScheduleMargin: !result.data?.FetchCheckoutSession?.pickup && !!result.data?.FetchCheckoutSession?.cutoffDate
	};
}

export function useSession(sessionId: string) {
	return wrapSessionQuery(useQuery(FetchCheckoutSession, { variables: { sessionId: sessionId } }));
}
