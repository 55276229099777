import React, { Fragment } from "react";
import { TextFieldElement, UseFormReturn } from "react-hook-form-mui";
import { useBusinessType } from "../../hooks";
import { InputAdornment } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import { BusinessTypeDialog } from "../BusinessTypeDialog";
import { OnboardingFormContext } from "../../types";

export const OrganizationTypeElement: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
	readonly?: boolean;
}> = ({ formContext, readonly }) => {
	const { types, businessType } = useBusinessType();
	const [ isBusinessTypeDialogOpen, setIsBusinessTypeDialogOpen ] = React.useState(false);

	return (
		<Fragment>
			{isBusinessTypeDialogOpen && (
				<BusinessTypeDialog
					open={isBusinessTypeDialogOpen}
					onClose={() => setIsBusinessTypeDialogOpen(false)}
					onSelect={(type) => formContext.setValue("businessType", type)}
				/>
			)}
			<TextFieldElement
				fullWidth
				required
				name="businessType"
				label="Organization Type"
				onKeyDown={() => {
					if(readonly) return;
					if(isBusinessTypeDialogOpen) {
						return;
					}

					setIsBusinessTypeDialogOpen(true);
				}}
				onClick={() => !readonly && setIsBusinessTypeDialogOpen(true)}
				InputLabelProps={{
					shrink: !!businessType
				}}
				InputProps={{
					readOnly: readonly,
					endAdornment: (
						<InputAdornment position="end">
							<Autorenew />
						</InputAdornment>
					),
					value: types.find((type) => type.id === businessType)?.label
				}}
			/>
		</Fragment>
	);
};
