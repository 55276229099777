import React from "react";
import { Groups, Logout, Payment, Person } from "@mui/icons-material";
import { ListItemProps, Sidebar, SidebarListItem } from "../../../components";
import { useAppDispatch } from "../../../store";
import { push } from "redux-first-history";
import { AuthService } from "../../../auth";

export const PersonalInformationListItem: React.FC<ListItemProps> = (props) => {
	return (
		<SidebarListItem
			text="Personal Details"
			icon={<Person />}
			path={props.absolutePath}
		/>
	);
};

export const PaymentMethodsListItem: React.FC<ListItemProps> = (props) => {
	return (
		<SidebarListItem
			text="Payment Methods"
			icon={<Payment />}
			path="/profile/payment-methods"
		/>
	);
};

export const MembershipsAccountsListItem: React.FC<ListItemProps> = (props) => {
	return (
		<SidebarListItem
			text="Memberships"
			icon={<Groups />}
			path="/profile/memberships"
		/>
	);
};

export const LogoutListItem: React.FC<ListItemProps> = (props) => {
	const dispatch = useAppDispatch();
	function handleLogout(): void {
		AuthService.logout();
		dispatch(push("/login"));
		window.location.reload();
	}

	return (
		<SidebarListItem
			text="Logout"
			icon={<Logout />}
			onClick={handleLogout}
			selected={false}
			path="/logout"
		/>
	);
};

export const UserProfileSidebar: React.FC = () => {
	return (
		<Sidebar
			hideAvatar
			primaryListItems={[
				<PersonalInformationListItem
					key="personal-information"
					absolutePath="/profile/personal-information"
				/>,
				<PaymentMethodsListItem
					key="payment-methods"
					absolutePath="/profile/payment-methods"
				/>
			]}
			secondaryListItems={[
				<LogoutListItem
					key="logout"
					absolutePath="/logout"
				/>
			]}
		/>
	);
};