import { useQuery } from "@apollo/client";
import { FetchPartnerUsers } from "../graphql";

export function usePartnerUsers(partnerId: string) {
	const { data, loading, error } = useQuery(FetchPartnerUsers, {
		skip: !partnerId,
		variables: { partnerId },
	});

	return {
		users: data?.FetchPartnerUsers || [],
		loading,
		error,
	};
}