import React from "react";
import { DialogWithActivatorButton, ProductMediaUploadElement } from "../../../../components";

export const StopConfirmationMediaDialog: React.FC<{
	loading: boolean;
	media: {
		name: string;
		contentUrl: string;
		file?: File | undefined;
	}[];
	onFileUploaded: (file: File) => void;
	activatorButton: React.ReactNode;
	action: React.ReactNode;
}> = ({ loading, media, activatorButton, onFileUploaded, action }) => {
	return (
		<DialogWithActivatorButton
			title="Item Confirmation"
			activatorButton={activatorButton}
			actions={action}
		>
			<ProductMediaUploadElement
				media={media}
				loading={loading}
				onFileUploaded={onFileUploaded}
			/>
		</DialogWithActivatorButton>
	);
};
