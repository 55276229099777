import { gql } from "../../../__generated__";

export const CreateWasteTicket = gql(/* GraphQL */ `
	mutation CreateWasteTicket(
		$date: DateTime!
		$partnerId: String!
		$instanceId: String!
		$ticketNumber: String!
		$media: [CreateMediaDTO!]!
	) {
		CreateWasteTicket(
			date: $date
			partnerId: $partnerId
			instanceId: $instanceId
			ticketNumber: $ticketNumber
			media: $media
		) {
			...WasteTicket_HeaderFragment
		}
	}
`);