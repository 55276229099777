import { Box, Grid, Heading } from "grommet";
import { BasicListItem, Pagination, ViewContainer } from "../../../../components";
import { useClipboard, useFullScreen, useRequiredPathParam } from "../../../../hooks";
import { FetchUserAdmin, FindOrdersV2 } from "../../../../graphql";
import { useQuery } from "@apollo/client";
import { IconButton, LinearProgress } from "@mui/material";
import { AttachEmail, CopyAll, Email, PhoneIphoneSharp, Sms } from "@mui/icons-material";
import { InstancePreviewListItem } from "./Instances";
import { formatPhoneNumber } from "../../../../helpers";
import moment from "moment";
import { OrderSummaryListItem } from "../../../dashboard/components";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../../store";
import { useMemo } from "react";

export function useUserAdmin(userId: string) {
	const { data, error, loading } = useQuery(FetchUserAdmin, {
		skip: !userId,
		variables: { userId }
	});

	return {
		user: data?.FetchUser || null,
		error,
		loading
	};
}

export function useUserOrders(userId: string) {
	const cutoff = useMemo(() => {
		return moment().subtract(1, "year").toISOString();
	}, []);

	const { data, error, loading } = useQuery(FindOrdersV2, {
		skip: !userId,
		variables: {
			user: { id: { equals: userId } },
			createdAt: { isAfter: cutoff }
		}
	});

	return {
		orders: data?.FindOrders || [],
		error,
		loading
	};
}

export const UserDetailsView: React.FC = () => {
	const dispatch = useAppDispatch();
	const fullScreen = useFullScreen();
	const { copy } = useClipboard();
	const userId = useRequiredPathParam("userId", "/admin/dashboard");

	const { user, loading } = useUserAdmin(userId);
	const { orders, loading: ordersLoading } = useUserOrders(userId);

	return (
		<ViewContainer>
			<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="medium">
				<Box gap="medium" flex>
					<Box>
						<Heading level="3" margin="none">
							User Details
						</Heading>
						{loading && (
							<LinearProgress />
						)}
					</Box>
					<Pagination pageSize={10}>
						<BasicListItem
							label="User Id"
							value={user?.id}
							action={(
								<IconButton onClick={() => copy(user?.id || "")} >
									<CopyAll />
								</IconButton>
							)}
						/>
						<BasicListItem
							label="Full Name"
							value={user?.fullName}
						/>
						<BasicListItem
							icon={<Email />}
							label="Primary Email Address"
							value={user?.primaryEmailAddress || "Unknown"}
							action={(
								<Box direction="row" gap="xsmall">
									{user?.primaryEmailAddress && (
										<IconButton onClick={() => user.primaryEmailAddress && copy(user.primaryEmailAddress)}>
											<CopyAll />
										</IconButton>
									)}
									{user?.primaryEmailAddress && (
										<IconButton href={`mailto:${user.primaryEmailAddress}`}>
											<AttachEmail />
										</IconButton>
									)}
								</Box>
							)}
						/>
						<BasicListItem
							icon={<PhoneIphoneSharp />}
							label="Primary Phone Number"
							value={user?.primaryPhoneNumber && formatPhoneNumber(user.primaryPhoneNumber) || "Unknown"}
							action={(
								<Box direction="row" gap="xsmall">
									{user?.primaryEmailAddress && (
										<IconButton onClick={() => user.primaryPhoneNumber && copy(user.primaryPhoneNumber)}>
											<CopyAll />
										</IconButton>
									)}
									{user?.primaryEmailAddress && (
										<IconButton href={`sms://+${user.primaryPhoneNumber}`}>
											<Sms />
										</IconButton>
									)}
								</Box>
							)}
						/>
					</Pagination>
				</Box>
				<Box gap="medium" flex>
					<Box>
						<Heading level="3" margin="none">
							User Memberships
						</Heading>
						{loading && (
							<LinearProgress />
						)}
					</Box>
					<Pagination pageSize={3}>
						{(user?.memberships || []).map(member => (
							<InstancePreviewListItem
								key={member.id}
								instance={member.instance}
							/>
						))}
					</Pagination>
				</Box>
			</Grid>
			<Box style={{ display: "block", minHeight: "auto" }}>
				<Box>
					<Heading level="3" margin="none">
						User Orders
					</Heading>
					{ordersLoading && (
						<LinearProgress />
					)}
				</Box>
				{orders.length > 0 && (
					<Pagination pageSize={4}>
						{orders.map(order => (
							<OrderSummaryListItem
								orderId={order.id}
								onClick={() => {
									dispatch(push("/admin/orders/" + order.id));
								}}
							/>
						))}
					</Pagination>
				)}
				{orders.length === 0 && (
					<Box height="xsmall" align="center" justify="center">
						<Heading level="4" margin="none">
							No Orders Found
						</Heading>
					</Box>
				)}
			</Box>
		</ViewContainer>
	);
};