import { FragmentType } from "../../graphql";
import { Pickup_HeaderFragmentFragment } from "../../graphql/__generated__/graphql";
import { useScheduledDate } from "../../hooks";
import { BasicListItem } from "../List";


export const PickupScheduledDateListItem: React.FC<{
	loading: boolean;
	pickup: FragmentType<Pickup_HeaderFragmentFragment>;
}> = ({ loading, pickup }) => {
	const scheduledDate = useScheduledDate(pickup);

	return (
		<BasicListItem
			label="Scheduled Date"
			loading={loading}
			value={scheduledDate} />
	);
};
