import { Fragment, ReactNode, useCallback, useEffect, useMemo } from "react";
import { useResident } from "../../../auth";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useInstance } from "../../../hooks";
import { DialogWithClose, Pagination, SplashScreen } from "../../../components";
import { Box, Heading } from "grommet";
import { List, Typography } from "@mui/material";
import { SelectRefererListItem } from "../../dashboard/components";
import { selectCheckoutState, setCustomScheduleEnabled, setReferer, setSessionId, setUpfrontPricingEnabled, setUpfrontScheduleEnabled, upsertCheckoutSession } from "../../../store/checkout";
import { InstanceFeature, InstanceType } from "../../../graphql/__generated__/graphql";
import { PageNotAvailableView } from "../../../views";
import { noop } from "../../../helpers";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import Fuse from "fuse.js";
import { useFeature } from "../../instance";
import { useSyncState } from "../../../hooks/useSyncState";
import { v4 as uuid } from "uuid";

export function useCheckoutSessionSettings() {
	const dispatch = useAppDispatch();

	const isCustomScheduleEnabled = useFeature(InstanceFeature.OrderCustomScheduleEnabled);
	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);
	const isUpfrontSchedulingEnabled = useFeature(InstanceFeature.OrderUpfrontScheduleEnabled);

	useEffect(() => {
		dispatch(setCustomScheduleEnabled(isCustomScheduleEnabled));
	}, [ isCustomScheduleEnabled ]);

	useEffect(() => {
		dispatch(setUpfrontPricingEnabled(isUpfrontPricingEnabled));
	}, [ isUpfrontPricingEnabled ]);

	useEffect(() => {
		dispatch(setUpfrontScheduleEnabled(isUpfrontSchedulingEnabled));
	}, [ isUpfrontSchedulingEnabled ]);
}

export const CheckoutSessionController: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	useCheckoutSessionSettings();
	const { instance } = useInstance();
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectCheckoutState);

	const { state: stateFromStorage } = useSyncState(
		"checkout-session",
		state
	);

	useEffect(() => {
		if(!state.sessionId) {
			dispatch(setSessionId(uuid()));
		}
	}, [ state ]);

	useEffect(() => {
		if(stateFromStorage) {
			dispatch(upsertCheckoutSession(stateFromStorage));
		}
	}, [ stateFromStorage ]);

	useEffect(() => {
		//Refresh Referer
		if(!state.referer) return;

		const refererFromInstance = instance?.partners.find(partner => partner.id === state.referer?.id);
		if(!refererFromInstance) {
			console.debug("Referer not found in instance, clearing referer");
			dispatch(setReferer(null));
		}
		else {
			dispatch(setReferer(refererFromInstance));
		}
	}, [ state, instance ]);

	return (
		<Fragment>
			{!state.referer && (
				<CheckoutRefererController />
			)}
			{children}
		</Fragment>
	);
};

export const SelectRefererDialog: React.FC<{
	onClose(): void;
	onSelect(refererId: string): void;
}> = ({ onClose, onSelect }) => {
	const { referers, loading } = useReferers();

	return (
		<DialogWithClose
			onClose={onClose}
			title="Start Your Order"
			content={(
				<SplashScreen visible={loading}>
					<Box gap="small">
						<Heading level="3" margin="none">
							Select Your Location
						</Heading>
						<List>
							{referers.map(referer => (
								<SelectRefererListItem
									key={referer.id}
									refererId={referer?.id || ""}
									onClick={() => onSelect(referer.id)}
								/>
							))}
						</List>
					</Box>
				</SplashScreen>
			)}
		/>
	);
};

export const SelectResidenceRefererDialog: React.FC<{
	onClose(): void;
	onSelect(refererId: string): void;
}> = ({ onClose, onSelect }) => {
	return <></>;
};

export const SelectManagementCompanyRefererDialog: React.FC<{
	onClose(): void;
	onSelect(refererId: string): void;
}> = ({ onClose, onSelect }) => {
	const formContext = useForm({
		defaultValues: {
			search: ""
		}
	});

	const { building } = useResident();
	useEffect(() => {
		if(building) {
			onSelect(building.id);
		}
	}, [ building ]);

	const { search, loading, buildings } = useBuildingSearch(formContext);

	return (
		<DialogWithClose
			onClose={onClose}
			title="Start Your Order"
			content={(
				<SplashScreen visible={loading}>
					<Box gap="small">
						<Heading level="3" margin="none">
							Lets Find Your Building
						</Heading>
						<FormContainer formContext={formContext}>
							<TextFieldElement
								name="search"
								fullWidth
								label="Search"
								helperText="Search by address or building name"
							/>
						</FormContainer>
						{(!search || search.length < 3) && (
							<Box align="center" justify="center" margin={{ top: "medium" }}>
								<Typography fontWeight="bold">
									start typing to see results
								</Typography>
							</Box>
						)}
						{search && search.length >= 3 && buildings.length === 0 && (
							<Box align="center" justify="center" margin={{ top: "medium" }}>
								<Typography fontWeight="bold">
									no results found
								</Typography>
							</Box>

						)}
						<Pagination pageSize={5}>
							{buildings.map(referer => (
								<SelectRefererListItem
									key={referer.id}
									refererId={referer?.id || ""}
									onClick={() => onSelect(referer.id)}
								/>
							))}
						</Pagination>
					</Box>
				</SplashScreen>
			)}
		/>
	);
};

export function useBuildingSearch(
	formContext: ReturnType<typeof useForm<{ search: string; }>>
) {
	const { referers, loading } = useReferers();
	const { search } = formContext.watch();
	const buildings = useMemo(() => {
		if(!search || search.length < 3) return [];

		const fuse = new Fuse(referers, {
			threshold: 0.3,
			includeMatches: true,
			includeScore: true,
			keys: [
				"name",
				"address.addressLineOne",
				"address.city",
				"address.state"
			]
		});

		return fuse.search(search).map(result => result.item);
	}, [ search, referers ]);

	return {
		search,
		loading,
		buildings
	};
}

export const CheckoutRefererController: React.FC = () => {
	const dispatch = useAppDispatch();
	const { instance } = useInstance();

	const { referers } = useReferers();

	useEffect(() => {
		if(referers.length === 1) {
			dispatch(setReferer(referers[ 0 ]));
		}
	}, [ referers ]);

	const handleSelect = useCallback((refererId: string) => {
		const found = referers.find(referer => referer.id === refererId);
		if(!found) return;
		dispatch(setReferer(found));
	}, [ referers ]);

	switch(instance?.type) {
		case InstanceType.Residence: {
			return <SelectResidenceRefererDialog
				onClose={noop}
				onSelect={handleSelect}
			/>;
		}
		case InstanceType.ManagementCompany: {
			return <SelectManagementCompanyRefererDialog
				onClose={noop}
				onSelect={handleSelect}
			/>;
		}
		case InstanceType.University:
		case InstanceType.DonationCenter:
		case InstanceType.DisposalCenter: {
			return <SelectRefererDialog
				onClose={noop}
				onSelect={handleSelect}
			/>;
		}
		default:
		case InstanceType.Admin:
		case InstanceType.Event:
		case InstanceType.Hauler:
		case InstanceType.Retail:
		case InstanceType.Onboarding:
		case InstanceType.Municipality: {
			return <PageNotAvailableView

			/>;
		}
	}
};

export function useReferers() {
	const { instance, loading } = useInstance();
	const { referer } = useAppSelector(selectCheckoutState);

	const referers = useMemo(() => {
		return (instance?.partners || []).map(partner => {
			switch(partner.__typename) {
				case "Building": {
					return {
						...partner,
						address: partner.address,
						scheduledPickups: partner.scheduledPickups
					};
				}
				case "DisposalOutlet": {
					return {
						...partner,
						address: null,
						scheduledPickups: []
					};
				}
				case "Hauler": {
					return {
						...partner,
						address: null,
						scheduledPickups: []
					};
				}
				case "RecyclingOutlet": {
					return {
						...partner,
						address: null,
						scheduledPickups: []
					};
				}
				case "ReferralPartner": {
					return {
						...partner,
						address: null,
						scheduledPickups: []
					};
				}
				case "ReuseOutlet": {
					return {
						...partner,
						address: partner.address,
						scheduledPickups: []
					};
				}
				case "StorePartner": {
					return {
						...partner,
						address: null,
						scheduledPickups: []
					};
				}
			}
		});
	}, [ instance ]);

	return {
		referer,
		loading,
		referers
	};
}