import { useTheme } from "@mui/material";
import { selectInstancePublic, useAppSelector } from "../store";

export function usePalette() {
	return useTheme().palette;
}

export function usePrimaryColor() {
	return usePalette().primary.main;
}

export function useHeaderColor() {
	const instance = useAppSelector(selectInstancePublic);
	return instance?.customizations?.headerColor || undefined;
}