import React, { useMemo } from "react";
import { Fragment } from "react/jsx-runtime";
import { SidebarRouter, SidebarRouteItem, FaqListItem } from "../../../components";
import { isNotNull } from "../../../helpers";
import { useFrequentlyAskedQuestions } from "../../../hooks";

export function useDashboardRouter(
	...params: ConstructorParameters<typeof SidebarRouter>
) {
	const faqs = useFrequentlyAskedQuestions();

	return useMemo(() => {
		return new SidebarRouter(
			[
				...params[ 0 ],
				faqs.length > 0 ? new SidebarRouteItem(
					"/faqs",
					<Fragment />,
					<FaqListItem
						key="faqs"
						absolutePath="/dashboard/faqs"
					/>,
					true
				) : null
			].filter(isNotNull),
			params[ 1 ],
			params[ 2 ],
			params[ 3 ],
			params[ 4 ]
		);
	}, [ faqs, params ]);
}