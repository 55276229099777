import React, { useMemo } from "react";
import { Typography, ListItemButton, ListItemIcon, ListItemText, ListItemSecondaryAction, Chip, useTheme, List, Checkbox } from "@mui/material";
import { Box, Heading } from "grommet";
import { useWindowDimensions } from "../../../../../../../hooks";
import { useWasteAuditMaterials } from "../../../../hooks";
import { MaterialElement } from "../../../form/MaterialElement";
import { MaterialDispositionElement } from "../../../form/MaterialDispositionElement";

export const WasteContainerMaterialStep: React.FC<{
	materialId: string;
	extendedMaterialId: string;
	setExtendedMaterialId: (materialId: string) => void;
}> = ({ materialId, extendedMaterialId, setExtendedMaterialId }) => {
	const theme = useTheme();
	const { size } = useWindowDimensions();
	const { materials } = useWasteAuditMaterials();

	const materialOptions = useMemo(() => {
		return Array.from(materials).filter(m => !m.tags.includes("extended")).sort((a, b) => a.name.localeCompare(b.name)).map(material => ({
			id: material.id,
			label: material.name,
			tags: material.tags
		}));
	}, [ materials ]);

	const extendedMaterialOptions = useMemo(() => {
		const material = materials.find(m => m.id === materialId);

		if(!materialId || !material) return [];

		return Array.from(materials)
			.filter(m => m.groupName === material.groupName)
			.sort((a, b) => a.name.localeCompare(b.name))
			.map(material => ({
				id: material.id,
				tags: material.tags,
				label: material.name
			}));
	}, [ materialId, materials ]);

	return (
		<Box gap="medium">
			<Box>
				<Heading level="3" margin="none">
					Container Material
				</Heading>
				<Typography variant="caption">
					Give us some information about the material type collected in this container
				</Typography>
			</Box>
			<MaterialElement />
			<MaterialDispositionElement />
			{extendedMaterialOptions.length > 1 && (
				<Box>
					<Box>
						<Heading level="3" margin="none">
							Specific Material Selection
						</Heading>
						<Typography variant="caption">
							Have a more specific material type? Select from the options below. Select the <em style={{ color: theme.palette.success.main }}>Standard</em> option if you are unsure.
						</Typography>
					</Box>
					<List>
						{extendedMaterialOptions.map(material => (
							<ListItemButton
								divider
								key={material.id}
								selected={extendedMaterialId === material.id}
								onClick={() => setExtendedMaterialId(material.id)}
							>
								{size !== "small" && (
									<ListItemIcon>
										<Checkbox
											checked={extendedMaterialId === material.id}
										/>

									</ListItemIcon>
								)}
								<ListItemText>
									<Typography fontWeight="bold">
										{material.label}
									</Typography>
								</ListItemText>
								<ListItemSecondaryAction>
									{material.tags.includes("extended") && (
										<Chip
											variant="outlined"
											label="Specific"
											color="info"
										/>
									)}
									{!material.tags.includes("extended") && (
										<Chip
											label="Standard"
											color="success"
											variant="outlined"
										/>
									)}
								</ListItemSecondaryAction>
							</ListItemButton>
						))}
					</List>
				</Box>
			)}
		</Box>
	);
};