import { gql } from "../../../__generated__";

export const UpdateWasteAudit = gql(/* GraphQL */ `
	mutation UpdateWasteAudit(
		$name: String!
		$auditId: String!
	) {
		UpdateWasteAudit(
			name: $name
			auditId: $auditId
		) {
			id
		}
	}
`);