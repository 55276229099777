import { gql } from "../../../__generated__";

export const FetchOrderRelationships = gql(/* GraphQL */ `
	query FetchOrderRelationships(
		$orderId: String!
	) {
		FetchOrder(
			orderId: $orderId
		) {
			...Order_SourceFragment
		}
	}
`);