import { Pagination } from "@mui/material";
import { PaginationContext } from "./PaginationProvider";
import { useContext } from "react";
import { useWindowDimensions } from "../../hooks";

interface PaginationControlsProps {
	count: number;
}

export const PaginationControls: React.FC<PaginationControlsProps> = (props) => {
	const { size } = useWindowDimensions();
	const { page, pageSize, setPage } = useContext(PaginationContext);

	const hasMultiplePages = props.count > pageSize;

	if(!hasMultiplePages) {
		return null;
	}

	return (
		<Pagination
			page={page}
			size="large"
			shape="rounded"
			variant="outlined"
			boundaryCount={1}
			siblingCount={size === "small" ? 0 : 1}
			onChange={(event, value) => setPage(value)}
			count={Math.ceil(props.count / pageSize)}
		/>
	);
};