import React from "react";
import { SplashScreen } from "../../../components";
import { useHauler } from "../hooks";
import { PageNotAvailableView } from "../../../views";

export const HaulerGuard: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { hauler, loading } = useHauler();

	if(!loading && !hauler) {
		return (
			<PageNotAvailableView />
		);
	}

	return (
		<SplashScreen visible={loading}>
			{children}
		</SplashScreen>
	);
};