import { Anchor, AnchorExtendedProps } from "grommet";
import { useAppDispatch } from "../store";
import { push } from "redux-first-history";
import { PathRouteProps, Route } from "react-router-dom";
import { useEffect } from "react";

export const Link: React.FC<{ path: string; } & AnchorExtendedProps> = (props) => {
	const dispatch = useAppDispatch();

	return (
		<Anchor
			{...props}
			onClick={() => {
				dispatch(push(props.path));
			}}
		>
			{props.children}
		</Anchor>
	);
};

interface RedirectProps {
	to: string;
}

export const Redirect: React.FC<RedirectProps> = (props) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(push(props.to));
	}, []);
	return (
		<></>
	);
};

interface RedirectRouteProps extends PathRouteProps {
	to: string;
}

export const RedirectRoute: React.FC<RedirectRouteProps> = (props) => (
	<Route
		{...props}
		element={<Redirect to={props.to} />}
	/>
);