import { gql } from "@apollo/client";

export const Product_HeaderFragment = gql`
	fragment Product_HeaderFragment on Product {
		id
		createdAt
		updatedAt
		intent
		disposition
	}
`;