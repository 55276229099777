import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Carousel, Heading, Image, Spinner } from "grommet";
import { DialogWithActivatorButton, DialogWithClose, Pagination, ViewContainer } from "../../../../components";
import { usePendingWasteTickets } from "../hooks";
import { Button, Divider, IconButton, LinearProgress, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useWasteTicket } from "../../common";
import { formatNumber, noop } from "../../../../helpers";
import moment from "moment-timezone";
import { useToggleForceClose } from "../../components";
import { DatePickerElement, FormContainer, SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { LocalizationProvider } from "../../../../provider";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import { FindWasteTickets, ProcessWasteTicket, RemoveWasteTicket } from "../../../../graphql";
import { WasteTicketLineItemInputDto, WeightUnit } from "../../../../graphql/__generated__/graphql";
import { useSnackbar } from "notistack";
import { Cancel } from "@mui/icons-material";
import { useMaterials } from "../../../product/hooks/useMaterials";
import { Edit } from "grommet-icons";

export const WasteTicketsView = () => {
	const { tickets, loading } = usePendingWasteTickets();

	function resolvePrimaryText(ticket: { processedAt?: string | null, createdAt: string; }) {
		if(!ticket.processedAt) {
			return `Started processing on ${moment.tz(ticket.createdAt, "UTC").tz(moment.tz.guess()).format("dddd MMMM Do")} at ${moment.tz(ticket.createdAt, "UTC").tz(moment.tz.guess()).format("hh:mm a")}`;
		}
		else {
			return `Processed on ${moment.tz(ticket.processedAt, "UTC").tz(moment.tz.guess()).format("dddd MMMM Do")}`;
		}
	}

	return (
		<ViewContainer>
			<Box gap="medium" flex>
				<Box gap="small">
					<Heading level="3" margin="none">
						Pending Waste Tickets
					</Heading>
					{loading && (
						<LinearProgress />
					)}
				</Box>
				<Pagination pageSize={5}>
					{tickets.map(ticket => (
						<ProcessWasteTicketDialog
							key={ticket.id}
							ticketId={ticket.id}
							activatorButton={(
								<ListItemButton
									divider
									onClick={noop}
									key={ticket.id}
								>
									<ListItemText>
										<Box>
											<Typography fontWeight="bold">
												{resolvePrimaryText(ticket)}
											</Typography>
											<Typography variant="caption">
												Click for more details
											</Typography>
										</Box>
									</ListItemText>
								</ListItemButton>
							)}
						/>
					))}
				</Pagination>
			</Box>
		</ViewContainer>
	);
};

export const ProcessWasteTicketDialog: React.FC<{
	ticketId: string;
	activatorButton: React.ReactNode;
}> = ({ ticketId, activatorButton }) => {
	const { forceClose, toggleForceClose } = useToggleForceClose();

	const { wasteTicket, loading } = useWasteTicket(ticketId);

	const formContext = useForm({
		defaultValues: {
			date: wasteTicket?.date
				? moment(wasteTicket.date, "YYYY-MM-DD")
				: null,
			ticketNumber: wasteTicket?.ticketNumber
		}
	});

	useEffect(() => {
		if(wasteTicket) {
			formContext.reset({
				date: wasteTicket?.date
					? moment(wasteTicket.date, "YYYY-MM-DD")
					: null,
				ticketNumber: wasteTicket.ticketNumber
			});
		}
	}, [ formContext, wasteTicket ]);

	const [ isLineItemDialogOpen, setIsLineItemDialogOpen ] = React.useState(false);
	const [ updatingLineItemByMaterialId, setUpdatingLineItemByMaterialId ] = React.useState<string>("");

	const [ lineItems, setLineItems ] = React.useState<WasteTicketLineItemInputDto[]>([]);

	const [
		process,
		{ loading: isProcessing }
	] = useMutation(ProcessWasteTicket, {
		refetchQueries: [ FindWasteTickets ]
	});

	const snack = useSnackbar();

	function handleSubmit() {
		const { date } = formContext.getValues();
		if(!date) {
			snack.enqueueSnackbar("Please select a date", {
				variant: "error"
			});
			return;
		}

		if(lineItems.length === 0) {
			snack.enqueueSnackbar("Please add at least one line item", {
				variant: "error"
			});
			return;
		}

		process({
			variables: {
				date: date.toDate(),
				ticketNumber: formContext.getValues().ticketNumber || "",
				source: { weight: 0, weightUnit: WeightUnit.Pounds },
				lineItems,
				wasteTicketId: ticketId
			}
		}).catch(err => {
			console.error("Failed to process waste ticket", err);
			snack.enqueueSnackbar("Error processing waste ticket", {
				variant: "error"
			});
		});
	}

	const [
		remove,
		{ loading: isRemoving }
	] = useMutation(RemoveWasteTicket, {
		refetchQueries: [ FindWasteTickets ]
	});

	function handleRemove() {
		if(!wasteTicket) {
			return;
		}

		remove({
			variables: {
				ticketId: wasteTicket.id
			}
		}).catch(err => {
			console.error("Failed to delete waste ticket", err);
			snack.enqueueSnackbar("Error deleting waste ticket", {
				variant: "error"
			});
		});
	}

	const updateLineItem = useCallback((lineItem: WasteTicketLineItemInputDto) => {
		setLineItems([
			...lineItems.filter(item => item.materialId !== lineItem.materialId),
			lineItem
		]);
	}, [ lineItems ]);

	return (
		<LocalizationProvider>
			{isLineItemDialogOpen && (
				<LineItemDialog
					onClose={() => {
						setIsLineItemDialogOpen(false);
						setUpdatingLineItemByMaterialId("");
					}}
					onSubmit={updateLineItem}
					lineItem={lineItems.find(item => item.materialId === updatingLineItemByMaterialId) || undefined}
				/>
			)}
			<DialogWithActivatorButton
				title="Process Waste Ticket"
				activatorButton={activatorButton}
				forceClose={forceClose}
				actions={(
					<Box direction="row" justify="between">
						<Button
							color="error"
							variant="outlined"
							onClick={toggleForceClose}
						>
							Close
						</Button>
						<LoadingButton
							color="primary"
							variant="contained"
							loading={isProcessing || isRemoving}
							onClick={formContext.handleSubmit(handleSubmit)}
						>
							Process
						</LoadingButton>
					</Box>
				)}
			>
				{loading ? (
					<Box height="small">
						<Spinner />
					</Box>
				)
					: (
						<FormContainer formContext={formContext}>
							<Box gap="medium">
								<Box height="small">
									<Carousel>
										{(wasteTicket?.media || []).map(media => (
											<Image
												key={media.id}
												fit="contain"
												src={media.contentUrl}
											/>
										))}

									</Carousel>
								</Box>
								<DatePickerElement
									required
									name="date"
									label="Document Date"
									readOnly={wasteTicket?.processed}
								/>
								<TextFieldElement
									required
									name="ticketNumber"
									label="Ticket Number"
									InputProps={{
										readOnly: wasteTicket?.processed
									}}
								/>
								<Box direction="row" justify="between">
									<Box justify="center">
										<Heading
											margin="none"
											level="3"
										>
											Line Item(s)
										</Heading>
									</Box>
									<Box>
										<Button
											color="primary"
											variant="outlined"
											onClick={() => setIsLineItemDialogOpen(true)}
										>
											Add Line Item
										</Button>
									</Box>
								</Box>
								{lineItems.length === 0 && (
									<Box align="center">
										<Typography>
											no line items added
										</Typography>
									</Box>

								)}
								{lineItems.map(lineItem => (
									<LineItemListItem
										key={lineItem.materialId}
										lineItem={lineItem}
										onSelect={materialId => {
											setUpdatingLineItemByMaterialId(materialId);
											setIsLineItemDialogOpen(true);
										}}
									/>
								))}
								<Box align="start" flex justify="end">
									<LoadingButton
										startIcon={(
											<Cancel />
										)}
										color="error"
										variant="outlined"
										loading={isRemoving}
										onClick={handleRemove}
									>
										Delete
									</LoadingButton>
								</Box>
							</Box>
						</FormContainer>
					)}
			</DialogWithActivatorButton>
		</LocalizationProvider>
	);
};

export const LineItemDialog: React.FC<{
	onClose: () => void;
	lineItem?: Partial<WasteTicketLineItemInputDto>;
	onSubmit: (lineItem: WasteTicketLineItemInputDto) => void;
}> = ({ lineItem, onClose, onSubmit }) => {
	const snack = useSnackbar();
	const materials = useMaterials();

	const formContext = useForm({
		defaultValues: {
			materialId: lineItem?.materialId,
			date: lineItem?.date ? moment(lineItem.date, "YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
			totalWeightCompostedPounds: lineItem?.totalWeightCompostedPounds || 0,
			totalWeightDivertedPounds: lineItem?.totalWeightDivertedPounds || 0,
			totalWeightDonatedPounds: lineItem?.totalWeightDonatedPounds || 0,
			totalWeightGeneratedPounds: lineItem?.totalWeightGeneratedPounds || 0,
			totalWeightIncineratedPounds: lineItem?.totalWeightIncineratedPounds || 0,
			totalWeightLandfilledPounds: lineItem?.totalWeightLandfilledPounds || 0,
			totalWeightRecycledPounds: lineItem?.totalWeightRecycledPounds || 0,
			totalWeightReusedPounds: lineItem?.totalWeightReusedPounds || 0
		}
	});

	const {
		totalWeightReusedPounds,
		totalWeightDonatedPounds,
		totalWeightRecycledPounds,
		totalWeightCompostedPounds,
		totalWeightDivertedPounds,
		totalWeightLandfilledPounds,
		totalWeightIncineratedPounds
	} = formContext.watch();

	useEffect(() => {
		const sum = totalWeightReusedPounds + totalWeightDonatedPounds + totalWeightRecycledPounds + totalWeightCompostedPounds + totalWeightLandfilledPounds + totalWeightIncineratedPounds;
		formContext.setValue("totalWeightDivertedPounds", sum);
	}, [ totalWeightReusedPounds, totalWeightDonatedPounds, totalWeightRecycledPounds, totalWeightCompostedPounds, totalWeightLandfilledPounds, totalWeightIncineratedPounds, formContext ]);

	useEffect(() => {
		const sum = totalWeightDivertedPounds + totalWeightLandfilledPounds + totalWeightIncineratedPounds;
		formContext.setValue("totalWeightGeneratedPounds", sum);
	}, [ formContext, totalWeightDivertedPounds, totalWeightIncineratedPounds, totalWeightLandfilledPounds ]);

	function handleSubmit() {
		const values = formContext.getValues();
		if(!values.materialId) {
			snack.enqueueSnackbar("Please select a material", {
				variant: "error"
			});
			return;
		}

		const lineItem: WasteTicketLineItemInputDto = {
			date: moment().format("YYYY-MM-DD"),
			materialId: values.materialId,
			totalWeightCompostedPounds: values.totalWeightCompostedPounds || 0,
			totalWeightDivertedPounds: values.totalWeightDivertedPounds || 0,
			totalWeightDonatedPounds: values.totalWeightDonatedPounds || 0,
			totalWeightRecycledPounds: values.totalWeightRecycledPounds || 0,
			totalWeightReusedPounds: values.totalWeightReusedPounds || 0,
			totalWeightIncineratedPounds: values.totalWeightIncineratedPounds || 0,
			totalWeightLandfilledPounds: values.totalWeightLandfilledPounds || 0,
			totalWeightGeneratedPounds: values.totalWeightGeneratedPounds || 0
		};

		onSubmit(lineItem);
		onClose();
	}

	return (
		<DialogWithClose
			title="Add Line Item"
			onClose={onClose}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Close
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={false}
						onClick={formContext.handleSubmit(handleSubmit)}
					>
						Save
					</LoadingButton>
				</Box>
			)}
			content={(
				<FormContainer formContext={formContext}>
					<Box gap="medium">
						<SelectElement
							required
							name="materialId"
							label="Material"
							options={materials.map(material => ({
								id: material.id,
								label: material.name
							}))}
						/>
						<TextFieldElement
							required
							name="totalWeightCompostedPounds"
							label="Total Weight Composted (lbs)"
							type="number"
							inputMode="numeric"
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
						<TextFieldElement
							required
							name="totalWeightDonatedPounds"
							label="Total Weight Donated (lbs)"
							type="number"
							inputMode="numeric"
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
						<TextFieldElement
							required
							name="totalWeightRecycledPounds"
							label="Total Weight Recycled (lbs)"
							type="number"
							inputMode="numeric"
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
						<TextFieldElement
							required
							name="totalWeightReusedPounds"
							label="Total Weight Reused (lbs)"
							type="number"
							inputMode="numeric"
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
						<Divider />
						<TextFieldElement
							required
							name="totalWeightLandfilledPounds"
							label="Total Weight Landfilled (lbs)"
							type="number"
							inputMode="numeric"
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
						<TextFieldElement
							required
							name="totalWeightIncineratedPounds"
							label="Total Weight Incinerated (lbs)"
							type="number"
							inputMode="numeric"
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
						<Divider />
						<TextFieldElement
							required
							name="totalWeightDivertedPounds"
							label="Total Weight Diverted (lbs)"
							type="number"
							inputMode="numeric"
							InputProps={{
								readOnly: true
							}}
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
						<TextFieldElement
							required
							name="totalWeightGeneratedPounds"
							label="Total Weight Generated (lbs)"
							type="number"
							inputMode="numeric"
							InputProps={{
								readOnly: true
							}}
							validation={{
								validate: value => {
									if(!value) return;

									if(value && isNaN(Number(value))) {
										return "Please enter a valid number";
									}
								}
							}}
						/>
					</Box>
				</FormContainer>
			)}
		/>
	);
};

export const LineItemListItem: React.FC<{
	lineItem: WasteTicketLineItemInputDto;
	onSelect: (materialId: string) => void;
}> = ({ lineItem, onSelect }) => {
	const materials = useMaterials();
	const material = useMemo(() => {
		return materials.find(material => material.id === lineItem.materialId);
	}, [ materials, lineItem ]);

	const totalWeightGenerated = useMemo(() => {
		return (lineItem.totalWeightGeneratedPounds)
			? `${formatNumber(lineItem.totalWeightDivertedPounds)} lb(s) Diverted | ${formatNumber(lineItem.totalWeightGeneratedPounds)} lbs(s) Generated`
			: "0 lbs(s)";
	}, [ lineItem ]);

	return (
		<ListItemButton
			divider
			onClick={() => onSelect(lineItem.materialId)}
		>
			<ListItemText>
				<Typography fontWeight="bold">
					{material?.name}
				</Typography>
				<Typography variant="body2">
					{totalWeightGenerated}
				</Typography>
			</ListItemText>
			<ListItemSecondaryAction>
				<IconButton onClick={() => onSelect(lineItem.materialId)}>
					<Edit />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};