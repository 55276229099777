import { InputAdornment } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import { ContactType } from "../../../../graphql/__generated__/graphql";
import { Email, PhoneIphoneSharp } from "@mui/icons-material";
import { Fragment, useCallback, useMemo } from "react";
import { isPhoneNumber, isEmailAddress } from "../../../../helpers";

export const UsernameElement: React.FC<{
	forceType?: ContactType;
	username: string;
	helperText?: string;
	disabled?: boolean;
}> = (props) => {
	const type = useMemo(() => {
		if(props.forceType) {
			return props.forceType;
		}

		if(isPhoneNumber(props.username)) {
			return ContactType.Phone;
		}

		if(isEmailAddress(props.username)) {
			return ContactType.Email;
		}

		return undefined;
	}, [ props.username, props.forceType ]);

	const elementLabel = useMemo(() => {
		switch(type) {
			case ContactType.Email:
				return "Email Address";
			case ContactType.Phone:
				return "Phone Number";
			default:
				return "Email Address or Phone Number";
		}
	}, [ type ]);

	const isValidUsername = useCallback((value: unknown): string | undefined => {
		if(!value) {
			return "This field is required.";
		}

		if(!type) {
			return "Please enter a valid username.";
		}

		if(type === ContactType.Email && !isEmailAddress(String(value))) {
			return "Please enter a valid email address.";
		}

		if(type === ContactType.Phone && !isPhoneNumber(String(value))) {
			return "Please enter a valid phone number.";
		}
	}, [ type, props.username ]);

	return (
		<Fragment>
			<TextFieldElement
				id="id-from-element"
				name="username"
				label={elementLabel}
				helperText={props.helperText}
				InputProps={{
					readOnly: props.disabled,
					startAdornment: (
						<InputAdornment position="start">
							{type === ContactType.Email && (
								<Email color="primary" />
							)}
							{type === ContactType.Phone && (
								<PhoneIphoneSharp color="primary" />
							)}
						</InputAdornment>
					)
				}}
				validation={{
					validate: (value: unknown) => {
						return isValidUsername(value);
					},
					required: "This field is required."
				}}
			/>
		</Fragment>
	);
};
