import { gql } from "@apollo/client";


export const Quote_PickupOptionFragment = gql`
	fragment Quote_PickupOptionFragment on Quote {
		pickupOptions {
			date
			windows { from to label }
		}
	}
`;
