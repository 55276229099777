import { useEffect, useMemo, useState } from "react";
import { useConfig } from "../graphql";
import moment from "moment-timezone";

export interface SimpleAddress {
	addressLineOne: string;
	addressLineTwo?: string;
	city: string;
	state: string;
	zipCode: string;
	lat: string;
	lng: string;
	timezone: string;
}

export interface AddressLike extends SimpleAddress {
	id?: string;
	room?: string;
	floor?: number;
	hasParking?: boolean;
	hasElevator?: boolean;
	instructions?: string;
	instructionsParking?: string;
}

export function useGoogle(requestLocation?: boolean) {
	const { googleApiKey } = useConfig();
	const [ isLoaded, setLoaded ] = useState(false);
	const [ currentLocation, setCurrentLocation ] = useState<google.maps.LatLng | null>(null);

	useEffect(() => {
		if(!window.google && googleApiKey) {
			const script = document.createElement("script");
			const body = document.getElementsByTagName("body")[ 0 ];
			script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
			body.appendChild(script);
			script.addEventListener("load", () => {
				setLoaded(true);
			});
		}

		if(window.google) {
			setLoaded(true);
		}
	}, [ googleApiKey ]);

	useEffect(() => {
		if(navigator.geolocation && window.google && requestLocation && !currentLocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				const pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				setCurrentLocation(new google.maps.LatLng(pos[ "lat" ], pos[ "lng" ]));
			});
		}
	}, [ requestLocation, currentLocation ]);

	return {
		loaded: isLoaded,
		google: window.google,
		currentLocation
	};
}

export function useAutocomplete(field: HTMLInputElement, callback: (address: AddressLike) => void) {
	const { google } = useGoogle();
	const [ address, setAddress ] = useState<AddressLike | null>(null);

	const autocomplete = useMemo(() => {
		if(google) {
			return new google.maps.places.Autocomplete(field, {
				componentRestrictions: { country: [ "us" ] },
				fields: [ "address_components", "geometry" ],
				types: [ "address" ]
			});
		}
	}, [ field, google ]);

	useEffect(() => {
		if(autocomplete) {
			autocomplete.addListener("place_changed", () => {
				const place = autocomplete.getPlace();
				const parsed = parseAddress(place);
				callback(parsed);
				setAddress(parsed);
			});
		}
	}, [ autocomplete ]);

	return {
		address,
		autocomplete
	};
}

function extractLatLong(place: google.maps.places.PlaceResult) {
	return {
		lat: String(place.geometry?.location?.lat() ?? ""),
		lng: String(place.geometry?.location?.lng() ?? "")
	};
}

function parseAddress(result: google.maps.places.PlaceResult): AddressLike {
	let addressLineOne = "";
	let city = "";
	let state = "";
	let zipCode = "";

	for(const component of result.address_components as google.maps.GeocoderAddressComponent[]) {
		const componentType = component.types[ 0 ];

		switch(componentType) {
			case "street_number": {
				addressLineOne = `${component.long_name} ${addressLineOne}`;
				break;
			}

			case "route": {
				addressLineOne += component.short_name;
				break;
			}

			case "postal_code": {
				zipCode = `${component.long_name}${zipCode}`;
				break;
			}

			case "postal_code_suffix": {
				//zipCode = `${zipCode}-${component.long_name}`;
				break;
			}

			case "locality":
				city = component.long_name;
				break;

			case "administrative_area_level_1": {
				state = component.short_name;
				break;
			}
		}
	}

	return {
		addressLineOne,
		city,
		state,
		zipCode,
		timezone: moment.tz.guess(),
		...extractLatLong(result)
	};
}