import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { OnboardingFormContext } from "../types";

export function useSampleData(formContext: UseFormReturn<OnboardingFormContext>) {
	const { primaryColor, secondaryColor, accentColor } = formContext.watch();

	const data = useMemo(() => {
		return {
			labels: [ "Q1", "Q2", "Q3", "Q4" ],
			datasets: [
				{
					type: "bar" as const,
					label: "Waste Generated",
					backgroundColor: primaryColor,
					data: [ 5000, 7000, 6000, 8000 ],
					order: 1
				},
				{
					type: "bar" as const,
					label: "Waste Diverted",
					backgroundColor: secondaryColor,
					data: [ 4500, 6000, 3000, 7500 ],
					order: 1
				},
				{
					type: "line" as const,
					label: "Diversion Rate",
					borderColor: accentColor,
					backgroundColor: accentColor,
					data: [ (4500 / 5000) * 100, (6000 / 7000) * 100, (3000 / 6000) * 100, (7500 / 8000) * 100 ],
					yAxisID: "y1",
				}
			],
		};

	}, [ accentColor, primaryColor, secondaryColor ]);

	return data;
}