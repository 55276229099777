import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Grill: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m914.04 416.15c20.387-6.8359 36.277-22.992 42.773-43.488 6.4961-20.492 2.8125-42.859-9.9102-60.188-12.727-17.328-32.965-27.535-54.461-27.473h-584.95c-21.5-0.0625-41.734 10.145-54.461 27.473-12.727 17.328-16.41 39.695-9.9141 60.188 6.4961 20.496 22.391 36.652 42.773 43.488 2.8633 48.582 16.949 95.836 41.152 138.06 24.207 42.219 57.871 78.25 98.348 105.27l-109.49 401.33c-3.6016 13.531-0.71484 27.969 7.8125 39.074 8.5312 11.109 21.734 17.625 35.738 17.637h43.441-0.003906c9.8633-0.039063 19.438-3.3086 27.262-9.3125 7.8242-6.0078 13.461-14.41 16.047-23.926l52.273-191.76h202.99l19.5 71.566c-18.18 26.273-24.348 59.031-16.969 90.117 7.3828 31.086 27.613 57.574 55.664 72.871 28.047 15.301 61.273 17.965 91.406 7.3398 30.129-10.629 54.328-33.551 66.57-63.062 12.246-29.512 11.383-62.832-2.375-91.668-13.754-28.836-39.109-50.477-69.75-59.527l-70.934-260.64c40.477-27.023 74.137-63.062 98.332-105.29 24.199-42.227 38.281-89.488 41.133-138.07zm-606.55-86.148h585c8.0391 0 15.465 4.2891 19.484 11.25 4.0195 6.9609 4.0195 15.539 0 22.5-4.0195 6.9609-11.445 11.25-19.484 11.25h-585c-8.0391 0-15.465-4.2891-19.484-11.25-4.0195-6.9609-4.0195-15.539 0-22.5 4.0195-6.9609 11.445-11.25 19.484-11.25zm95.398 742.49-43.547 0.085938 106.54-390.48c12.645 6.0742 25.688 11.285 39.035 15.598zm107.84-225 38.043-139.49c33.875 6.0469 68.555 6.0469 102.43 0l38.004 139.54zm370.5 180c0.003906 17.902-7.1055 35.074-19.762 47.734s-29.824 19.773-47.73 19.777c-17.902 0.003906-35.07-7.1094-47.73-19.766-12.66-12.66-19.773-29.828-19.777-47.73 0-17.902 7.1133-35.074 19.77-47.73 12.66-12.66 29.828-19.773 47.73-19.773 17.895 0.019531 35.051 7.1367 47.707 19.785 12.652 12.652 19.773 29.809 19.793 47.703zm-83.316-110.89c-13.941 1.9648-27.383 6.5703-39.598 13.57l-63.254-232.41c13.344-4.3203 26.383-9.5312 39.027-15.602zm-197.91-249.1c-67.668-0.097656-132.83-25.578-182.62-71.406-49.785-45.828-80.562-108.67-86.25-176.09h537.67c-5.6875 67.414-36.457 130.24-86.227 176.07-49.77 45.824-114.92 71.316-182.57 71.43z"/>
  <path d="m582.46 231.6c5.0703 6.1562 13.02 9.168 20.898 7.9141 7.875-1.25 14.504-6.5781 17.414-14.004 2.9141-7.4258 1.6758-15.84-3.25-22.113-4.1211-5.7188-6.3398-12.59-6.3398-19.641 0-7.0547 2.2188-13.926 6.3398-19.645 10.508-13.742 16.203-30.559 16.203-47.855 0-17.301-5.6953-34.117-16.203-47.859-5.0391-6.2617-13.039-9.3594-20.98-8.1289-7.9453 1.2344-14.629 6.6094-17.535 14.105-2.9023 7.4961-1.5859 15.973 3.4531 22.238 4.1211 5.7188 6.3398 12.59 6.3398 19.645 0 7.0508-2.2188 13.922-6.3398 19.641-10.508 13.742-16.199 30.555-16.199 47.852s5.6914 34.109 16.199 47.852z"/>
  <path d="m773.7 231.6c5.0391 6.2695 13.039 9.375 20.988 8.1484 7.9453-1.2305 14.637-6.6055 17.547-14.102 2.9141-7.4961 1.6016-15.977-3.4336-22.25-4.125-5.7188-6.3398-12.59-6.3398-19.641 0-7.0547 2.2148-13.926 6.3398-19.645 10.5-13.746 16.191-30.562 16.191-47.855 0-17.297-5.6914-34.113-16.191-47.859-5.1016-6.0703-13.02-9.0117-20.844-7.7422-7.8281 1.2734-14.406 6.5664-17.328 13.938-2.918 7.3711-1.7422 15.734 3.0938 22.02 4.125 5.7188 6.3438 12.59 6.3438 19.645 0 7.0508-2.2188 13.922-6.3438 19.641-10.508 13.738-16.203 30.551-16.207 47.848s5.6836 34.113 16.184 47.855z"/>
  <path d="m391.2 231.6c5.1016 6.0703 13.02 9.0117 20.844 7.7422 7.8281-1.2734 14.406-6.5664 17.328-13.938 2.918-7.3711 1.7422-15.734-3.0938-22.02-4.125-5.7188-6.3438-12.59-6.3438-19.645 0-7.0508 2.2188-13.922 6.3438-19.641 10.508-13.742 16.199-30.559 16.199-47.859 0-17.297-5.6914-34.113-16.199-47.855-5.0742-6.1641-13.035-9.1836-20.918-7.9297-7.8867 1.2539-14.52 6.5898-17.43 14.023-2.9102 7.4375-1.668 15.855 3.2695 22.133 4.125 5.7188 6.3398 12.59 6.3398 19.645 0 7.0508-2.2148 13.922-6.3398 19.641-10.504 13.742-16.195 30.555-16.195 47.852s5.6914 34.109 16.195 47.852z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};