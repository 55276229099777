import { Box } from "grommet";
import { CountSelectorElement } from "./CountSelectorElement";
import { ItemNameElement } from "./ItemNameElement";
import { ItemPricingElement } from "./ItemPricingElement";
import { useSize } from "../hooks";
import { useIncrementProduct } from "./TypeSelectionListItem";
import { Icon } from "@liverego/icons";
import { ItemSelectionContainer } from "./ItemSelectionContainer";

export const SizeSelectionListItem: React.FC<{
	typeId: string;
	sizeId: string;
}> = ({ typeId, sizeId }) => {
	const { size, type, quantity, pickupFee, pickupFeeComparison } = useSize(typeId, sizeId);

	const {
		increment,
		decrement
	} = useIncrementProduct(typeId, sizeId);

	return (
		<ItemSelectionContainer
			icon={(
				<Icon
					url={size?.iconUrl || type?.iconUrl || type?.category?.iconUrl || "@liverego/icons/ArmChair"}
					sx={{ fontSize: "48px", visibility: "hidden" }}
				/>
			)}
		>
			<Box justify="center">
				<ItemNameElement name={size?.name || ""} />
				<ItemPricingElement
					totalCount={quantity}
					pickupFee={pickupFee}
					pickupFeeComparison={pickupFeeComparison}
				/>
			</Box>
			<Box flex align="end" justify="center">
				<CountSelectorElement
					count={quantity}
					increment={increment}
					decrement={decrement}
				/>
			</Box>
		</ItemSelectionContainer>
	);
};