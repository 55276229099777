import { Box, Grid } from "grommet";
import React from "react";
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useProductCategoryIcon } from "../../../../hooks";
import { CheckCircle, ImageSearch } from "@mui/icons-material";
import { HaulerProductExceptionDialog, ProductMediaDialog } from "../product";
import moment from "moment";
import { useWindowDimensions } from "../../../../hooks";
import { ConfirmOriginStopButton } from "./ConfirmOriginStopButton";
import { Product_StopDetailedFragmentFragment } from "../../../../graphql/__generated__/graphql";

export const HaulerProductIncomingStopListItem: React.FC<{
	stopId: string;
	product: Product_StopDetailedFragmentFragment;
	haulerId: string;
	movementId: string;
}> = ({ product, haulerId, movementId, stopId }) => {
	const { size } = useWindowDimensions();
	const icon = useProductCategoryIcon(product.type?.category?.name || "");
	return (
		<ListItemButton divider>
			<Box gap="small" width="100%">
				<Box direction="row" flex>
					<Box justify="center" align="center">
						<ListItemIcon>
							{icon}
						</ListItemIcon>
					</Box>
					<Box flex direction="row">
						<ListItemText
							primary={product.type?.name || "Unknown"}
							secondary={product.size?.name || "Unknown"} />
					</Box>
					<Box justify="center">
						<Box direction="row" gap="small">
							{product.totalQuantity >= 1 && (
								<Box align="center" justify="center">
									<Typography fontWeight="bold">
										(x{product.totalQuantity})
									</Typography>
								</Box>
							)}
							<ProductMediaDialog
								media={product.media}
								activatorButton={(
									<IconButton>
										<ImageSearch />
									</IconButton>
								)} />
						</Box>
					</Box>
				</Box>
				{product.originStopConfirmed && (
					<Box>
						<List>
							<ListItem>
								<ListItemIcon>
									<CheckCircle
										color="success" />
								</ListItemIcon>
								<ListItemText
									primary="Pickup Confirmed"
									secondary={product.originStopConfirmedAt
										? `Confirmed on ${moment(product.originStopConfirmedAt).format("MM/DD/YYYY hh:mm A")}`
										: ""} />
							</ListItem>
						</List>
					</Box>
				)}
				{!product.originStopConfirmed && (
					<Grid columns={{ count: size === "small" ? 1 : 2, size: "auto" }} gap={size === "small" ? "small" : "medium"}>
						<HaulerProductExceptionDialog
							stopId={stopId}
							haulerId={haulerId}
							isOrigin={true}
							productId={product.id}
							movementId={movementId} />
						<ConfirmOriginStopButton
							stopId={stopId}
							productId={product.id} />
					</Grid>
				)}
			</Box>
		</ListItemButton>
	);
};
