import { gql } from "../../../__generated__";

export const BeginOnboarding = gql(`
	mutation BeginOnboarding(
		$instanceId: String!, 
		$username: String!
	) {
		BeginOnboarding(
			instanceId: $instanceId, 
			username: $username
		) {
			exists
			message
			hasError
			wasCodeSent
		}
	}
`);

export const BeginBuildingOnboarding = gql(/* GraphQL */`
	mutation BeginBuildingOnboarding(
		$instanceId: String!
		$buildingId: String!
		$username: String!
		$unit: String!
	) {
		BeginBuildingOnboarding(
			instanceId: $instanceId 
			buildingId: $buildingId
			username: $username
			unit: $unit
		) {
			exists
			message
			hasError
			buildingId
			wasCodeSent
		}
	}
`);