import { AddShoppingCartOutlined, RemoveShoppingCartOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useCart } from "../../../cart/hooks/useCart";
import { IconButton, IconButtonProps } from "@mui/material";
import { useAppDispatch } from "../../../../store";
import { useUser } from "../../../../auth";
import { push } from "redux-first-history";
import { Spinner } from "grommet";

interface AddToCartIconProps {
	productId: string;
	sx: IconButtonProps[ "sx" ];
}

export const AddToCartIcon: React.FC<AddToCartIconProps> = (props) => {
	const { user } = useUser();
	const dispatch = useAppDispatch();
	const { isInCart, addToCart, removeFromCart, isAdding, isRemoving } = useCart();

	const inCart = useMemo(() => {
		return isInCart(props.productId);
	}, [ isInCart, props.productId ]);

	function handleClick(inner: any) {
		if(!user) {
			dispatch(push(`/login?redirect=${window.location.pathname}`));
		}
		return inner;
	}

	if(isAdding || isRemoving) {
		return (
			<IconButton
				sx={props.sx}
			>
				<Spinner />
			</IconButton>
		);
	}

	return (inCart)
		? (
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					handleClick(removeFromCart(props.productId));
				}}
				sx={props.sx}
			>
				<RemoveShoppingCartOutlined />
			</IconButton>
		)
		: (
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					handleClick(addToCart(props.productId));
				}}
				sx={props.sx}
			>
				<AddShoppingCartOutlined />
			</IconButton>
		);
};
