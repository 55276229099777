import { ListItemSkeleton } from "../../../../components";
import { useBuilding } from "../hooks";
import { Badge, IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Apartment, LocalShipping } from "@mui/icons-material";


export const BuildingListItem: React.FC<{ buildingId: string; }> = ({ buildingId }) => {
	const { building } = useBuilding(buildingId);

	return (
		<ListItemButton divider>
			<ListItemIcon>
				<Apartment />
			</ListItemIcon>
			<ListItemText>
				<ListItemSkeleton
					loading={!building}
					content={(
						<Typography fontWeight="bold">
							{building?.name}
						</Typography>
					)} />
				<ListItemSkeleton
					loading={!building}
					content={(
						<Typography variant="body2">
							{building?.units.length} Unit(s)
						</Typography>
					)} />
			</ListItemText>
			<ListItemSecondaryAction>
				{(building?.referrals || []).length > 0 && (
					<Badge
						color="secondary"
						overlap="circular"
						badgeContent={building?.referrals.length}>
						<IconButton>
							<LocalShipping color="primary" />
						</IconButton>
					</Badge>
				)}
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
