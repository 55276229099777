import { FragmentType } from "../../graphql";
import { Pickup_HeaderFragmentFragment } from "../../graphql/__generated__/graphql";
import { useScheduledWindow } from "../../hooks";
import { BasicListItem } from "../List";


export const PickupScheduledWindowListItem: React.FC<{
	loading: boolean;
	pickup: FragmentType<Pickup_HeaderFragmentFragment>;
}> = ({ loading, pickup }) => {
	const scheduledWindow = useScheduledWindow(pickup);

	return (
		<BasicListItem
			label="Scheduled Window"
			loading={loading}
			value={scheduledWindow} />
	);
};
