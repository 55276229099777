import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

export interface StepState {
	[ key: string ]: {
		id: string;
		index: number;
		total: number;
		current: number;
		createdAt: number;
		lastChangedAt: number;
	};
}

const initialState: StepState = {

};

export const stepSlice = createSlice({
	name: "steps",
	initialState,
	reducers: {
		updateStep(state, action: PayloadAction<{ name: string; total: number; current: number; }>) {
			const { name, total, current } = action.payload;
			const now = new Date().getTime();

			const nextIndex = Object.keys(state).reduce((acc, key) => {
				if(state[ key ].index > acc) return state[ key ].index;
				return acc;
			}, 0) + 1;

			if(state[ name ]) {
				state[ name ].total = total;
				state[ name ].current = current;
				state[ name ].lastChangedAt = now;
			}
			else {
				state[ name ] = {
					total,
					current,
					createdAt: now,
					lastChangedAt: now,
					index: nextIndex,
					id: "i_" + nextIndex
				};
			}
		}
	}
});

export const {
	updateStep
} = stepSlice.actions;

export const selectSteps = (name: string) => (state: RootState) => state.steps[ name ] ?? { total: 0, current: 0 };

export default stepSlice.reducer;