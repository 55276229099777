import { Button, List, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Box, Page, PageContent, Stack, Text } from "grommet";
import { useInstance, useSession, useTimezone, useWindowDimensions } from "../../../hooks";
import { AddressDialog, BuildingAddressListItem, CreateAddressDTO, Pagination, PaymentMethodsList, ProductCreationListItem, StandardAddressListItem, ViewContainer, useSteps } from "../../../components";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import { AssignCheckoutSessionPaymentMethod, ConvertCheckoutSession, FetchCheckoutSession, FindActiveOrders, UpdateAddress } from "../../../graphql";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "../../../store";
import { push } from "redux-first-history";
import { formatCurrency } from "../../../helpers";
import { useResident } from "../../../auth";
import { InstanceFeature, InstanceType } from "../../../graphql/__generated__/graphql";
import moment from "moment";
import { Circle, CreditCard, RemoveCircle } from "@mui/icons-material";
import { useFeature } from "../../instance/hooks/useFeature";
import { CreatePaymentMethodButton } from "../../payments";

export const ReviewView: React.FC<{ sessionId: string; }> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { session, products } = useSession(props.sessionId);
	const { force, prev } = useSteps("schedule");

	const [
		convertCheckoutSessionMutation,
		{ loading: convertCheckoutSessionLoading, error: convertCheckoutSessionError, data }
	] = useMutation(ConvertCheckoutSession, {
		refetchQueries: [
			FetchCheckoutSession,
			FindActiveOrders
		]
	});

	useEffect(() => {
		if(convertCheckoutSessionError) {
			snack.enqueueSnackbar(
				"We ran into an issue submitting your order",
				{ variant: "error" }
			);
		}
	}, [ convertCheckoutSessionError, snack ]);

	useEffect(() => {
		if(data?.ConvertCheckoutSession.id) {
			snack.enqueueSnackbar(
				"Your pickup request has been submitted! You'll receive a confirmation email shortly.",
				{ variant: "success" }
			);
			dispatch(push("/dashboard"));
			window.location.reload();
		}
	}, [ data, dispatch ]);

	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);
	const isPaymentMethodRequired = useMemo(() => {
		return (isUpfrontPricingEnabled && (session?.estimate?.totalAmount || 0 > 0));
	}, [ isUpfrontPricingEnabled, session ]);

	function handleSubmit() {
		if(!session) return;

		if(isPaymentMethodRequired && !session.paymentMethod) {
			snack.enqueueSnackbar(
				"Please select a payment method before submitting your order",
				{ variant: "warning" }
			);
			return;
		}

		convertCheckoutSessionMutation({
			variables: {
				sessionId: session.id
			}
		}).catch(err => {
			console.error("Failed to submit session", err);
		});
	}

	const { size } = useWindowDimensions();
	const { instance } = useInstance();

	return (
		<Page kind={size === "small" ? undefined : "narrow"}>
			<PageContent pad={size === "small" ? "none" : undefined}>
				<ViewContainer>
					<ReviewController sessionId={props.sessionId} />
				</ViewContainer>
			</PageContent>
		</Page>
	);
};

export const ReviewController: React.FC<{
	sessionId: string;
}> = ({ sessionId }) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { session, products } = useSession(sessionId);
	const { force, prev } = useSteps("schedule");

	const [
		convertCheckoutSessionMutation,
		{ loading: convertCheckoutSessionLoading, error: convertCheckoutSessionError, data }
	] = useMutation(ConvertCheckoutSession, {
		refetchQueries: [
			FetchCheckoutSession,
			FindActiveOrders
		]
	});

	useEffect(() => {
		if(convertCheckoutSessionError) {
			snack.enqueueSnackbar(
				"We ran into an issue submitting your order",
				{ variant: "error" }
			);
		}
	}, [ convertCheckoutSessionError, snack ]);

	useEffect(() => {
		if(data?.ConvertCheckoutSession.id) {
			snack.enqueueSnackbar(
				"Your pickup request has been submitted! You'll receive a confirmation email shortly.",
				{ variant: "success" }
			);
			dispatch(push("/dashboard"));
			window.location.reload();
		}
	}, [ data, dispatch ]);

	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);
	const isPaymentMethodRequired = useMemo(() => {
		return (isUpfrontPricingEnabled && (session?.estimate?.totalAmount || 0 > 0));
	}, [ isUpfrontPricingEnabled, session ]);

	function handleSubmit() {
		if(!session) return;

		if(isPaymentMethodRequired && !session.paymentMethod) {
			snack.enqueueSnackbar(
				"Please select a payment method before submitting your order",
				{ variant: "warning" }
			);
			return;
		}

		convertCheckoutSessionMutation({
			variables: {
				sessionId: session.id
			}
		}).catch(err => {
			console.error("Failed to submit session", err);
		});
	}

	const { instance } = useInstance();
	return (
		<Box margin="small" gap="medium">
			<Box gap="small">
				<Text size="large" weight="bold">Order Review</Text>
				<Text>Review your order details before submitting.</Text>
			</Box>
			<Box gap="medium">
				<ReviewPickupAddressContainer
					sessionId={sessionId}
				/>
				<Typography fontWeight="bold">
					Your Items
				</Typography>
				<Pagination pageSize={5}>
					{products.map((product) => (
						<ProductCreationListItem
							key={product.id}
							product={product}
							onSelect={() => {
								if(instance?.type === InstanceType.Residence) {
									force(0);
									return;
								}

								force(1);
							}}
							sessionId={sessionId}
						/>
					))}
				</Pagination>
			</Box>
			<ReviewCheckoutSessionPickupContainer
				sessionId={sessionId}
			/>
			{isUpfrontPricingEnabled && (
				<ReviewCheckoutSessionEstimateContainer
					sessionId={sessionId}
				/>
			)}
			{(isUpfrontPricingEnabled && (session?.estimate?.totalAmount || 0 > 0)) && (
				<ReviewPaymentMethodContainer
					sessionId={sessionId}
				/>
			)}
			<Box direction="row" justify="between">
				<Button onClick={() => prev()} color="error" variant="outlined">
					Back
				</Button>
				<LoadingButton
					loading={convertCheckoutSessionLoading}
					variant="contained"
					color="primary"
					onClick={handleSubmit}
				>
					Submit
				</LoadingButton>
			</Box>
		</Box>
	);
};

export const CheckoutListItem: React.FC<{
	title: string;
	subtitle: string;
	details: string | ReactNode;
	onClick?: () => void;
}> = ({ title, subtitle, details, onClick }) => {
	return (
		<List disablePadding>
			<ListItemButton selected divider onClick={onClick}>
				<ListItemText>
					<Box gap="xsmall">
						<Typography fontWeight="bold">
							{title}
						</Typography>
						<Typography variant="caption">
							{subtitle}
						</Typography>
					</Box>
				</ListItemText>
				<ListItemSecondaryAction>
					{typeof details === "string" && (
						<Typography fontWeight="bold">
							{details}
						</Typography>
					)}
					{typeof details !== "string" && details}
				</ListItemSecondaryAction>
			</ListItemButton>
		</List>
	);
};

export const ReviewCheckoutSessionPickupContainer: React.FC<{
	sessionId: string;
}> = ({ sessionId }) => {
	const { size } = useWindowDimensions();
	const { force } = useSteps("schedule");
	const timezone = useTimezone();
	const { pickup, session } = useSession(sessionId);

	function getDaysUntilPickupMessage(date: string): string {
		const diff = moment.tz(date, "YYYY-MM-DD", timezone).diff(moment().startOf("day"), "days");
		if(diff === 0) return "Today";
		if(diff === 1) return "Tomorrow";
		return `In ${diff} days`;
	}

	const defaultFormat = useMemo(() => {
		switch(size) {
			case "small": {
				return "ddd, MMM Do";
			}
			default: {
				return "dddd, MMMM Do";
			}
		}
	}, [ size ]);

	const scheduledDateFormatted = useMemo(() => {
		return (pickup)
			? moment(pickup.scheduledDate, "YYYY-MM-DD").format(defaultFormat)
			: (session?.cutoffDate)
				? moment(session.cutoffDate, "YYYY-MM-DD").format(defaultFormat)
				: "";
	}, [ pickup ]);

	const daysFromNow = useMemo(() => {
		return (pickup && pickup.scheduledDate)
			? getDaysUntilPickupMessage(pickup.scheduledDate)
			: (session?.cutoffDate)
				? getDaysUntilPickupMessage(session.cutoffDate)
				: "";
	}, [ pickup ]);

	return (
		<Fragment>
			{(pickup || session?.cutoffDate) && (
				<Box gap="medium">
					<Typography fontWeight="bold">
						Pickup Details
					</Typography>
					<CheckoutListItem
						title="Pickup Date"
						subtitle={daysFromNow}
						details={scheduledDateFormatted}
						onClick={() => {
							force(1);
						}}
					/>
				</Box>
			)}
		</Fragment>
	);
};

export const ReviewCheckoutSessionEstimateContainer: React.FC<{
	sessionId: string;
}> = ({ sessionId }) => {
	const { products, estimate } = useSession(sessionId);

	const subtotalAmount = useMemo(() => {
		return estimate?.subtotalAmount ?? 0;
	}, [ estimate ]);

	const strikethroughSubtotal = useMemo(() => {
		const strikethroughAmount = subtotalAmount * (1 + .40);
		return strikethroughAmount + (5 - (strikethroughAmount % 5));
	}, [ estimate ]);

	return (
		<Box gap="medium">
			<Typography fontWeight="bold">
				Pickup Quote
			</Typography>
			<Box gap="small">
				<CheckoutListItem
					title="Subtotal"
					subtitle={`${products.reduce((acc, product) => product.quantity + acc, 0)} item(s)`}
					details={(
						<Box gap="xsmall">
							<Typography color="error" style={{ textDecoration: "line-through" }} fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
								{formatCurrency(strikethroughSubtotal ?? 0)}
							</Typography>
							<Typography sx={{ width: "80px", textAlign: "end" }}>
								{formatCurrency(estimate?.subtotalAmount ?? 0)}
							</Typography>
						</Box>
					)}
				/>
				{(estimate?.discountAmount || 0) > 0 && (
					<CheckoutListItem
						title="Discounts"
						subtitle="Description Here"
						details={(
							<Box direction="row" gap="small">
								<RemoveCircle />
								<Typography fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
									{formatCurrency(estimate?.discountAmount ?? 0)}
								</Typography>
							</Box>
						)}
					/>
				)}
				{(estimate?.creditUsedAmount || 0) > 0 && (
					<CheckoutListItem
						title="Credits Used"
						subtitle={`Remaining: ${formatCurrency(estimate?.creditRemainingAmount ?? 0)}`}
						details={(
							<Box direction="row" gap="small">
								<RemoveCircle />
								<Typography fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
									{formatCurrency(estimate?.creditUsedAmount ?? 0)}
								</Typography>
							</Box>
						)}
					/>
				)}
				<CheckoutListItem
					title="Total Amount"
					subtitle={`Taxes: ${formatCurrency(estimate?.taxAmount ?? 0)}`}
					details={(
						<Box direction="row" gap="small">
							<Stack>
								<Circle />
								<Box align="center" justify="center">
									<Typography fontSize="large" fontWeight="bold" color="white">
										=
									</Typography>
								</Box>
							</Stack>
							<Typography fontWeight="bold" sx={{ width: "80px", textAlign: "end" }}>
								{formatCurrency(estimate?.totalAmount ?? 0)}
							</Typography>
						</Box>
					)}
				/>
			</Box>
		</Box>
	);
};

export const ReviewPaymentMethodContainer: React.FC<{
	sessionId: string;
}> = ({ sessionId }) => {
	const snack = useSnackbar();
	const [ isSelectingId, setIsSelectingId ] = useState("");
	const { estimate, paymentMethod } = useSession(sessionId);

	const [
		assignPaymentMethod,
		{ loading: assignPaymentMethodLoading, error: assignPaymentMethodError }
	] = useMutation(AssignCheckoutSessionPaymentMethod, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	function handleSelectPaymentMethod(id: string): void {
		setIsSelectingId(id);
		assignPaymentMethod({
			variables: {
				sessionId,
				paymentMethodId: id
			}
		}).catch(err => {
			snack.enqueueSnackbar(
				"We ran into an issue saving your information",
				{ variant: "error" }
			);
			console.error("Failed to assign payment method", err);
		}).finally(() => {
			setIsSelectingId("");
		});
	}

	return (
		<Fragment>
			{(estimate && estimate.totalAmount > 0) && (
				<Box gap="medium">
					<Box>
						<Typography fontWeight="bold">
							Payment Method
						</Typography>
						<Typography variant="caption">
							Select your preferred payment method for this pickup. Your card will be charged {formatCurrency(estimate.totalAmount)} once your pickup is completed.
						</Typography>
					</Box>
					<PaymentMethodsList
						showCheckboxSelection
						isSelectingId={isSelectingId}
						selectedId={paymentMethod?.id ?? ""}
						onSelect={(id) => {
							handleSelectPaymentMethod(id);
						}}
					/>
					<Box align="center">
						<CreatePaymentMethodButton
							label="Add Payment Method"
							icon={<CreditCard />}
							variant="outlined"
						/>
					</Box>
				</Box>
			)}
		</Fragment>
	);
};

export const ReviewPickupAddressContainer: React.FC<{
	sessionId: string;
}> = (props) => {
	const { instance } = useInstance();
	const { building } = useResident();
	const { address } = useSession(props.sessionId);
	const [ isUpdatingAddress, setIsUpdatingAddress ] = useState(false);

	const addressListItem = useMemo(() => {
		if(!address) return null;

		switch(instance?.type) {
			case InstanceType.Residence: {
				return (
					<BuildingAddressListItem
						onEdit={() => {
							setIsUpdatingAddress(true);
						}}
						onSelect={() => {
							setIsUpdatingAddress(true);
						}}
						onDelete={() => {
							//
						}}
						isLoading={false}
						selected={true}
						buildingName={building?.name ?? ""}
						unitPrefix={building?.unitPrefix ?? "Unit"}
						unit={address?.addressLineTwo ?? ""}
						addressId={address?.id ?? ""}
					/>
				);
			}
			default: {
				return (
					<StandardAddressListItem
						address={address}
						onEdit={() => {
							setIsUpdatingAddress(true);
						}}
						onSelect={() => {
							setIsUpdatingAddress(true);
						}}
						onDelete={() => {
							//
						}}
						isLoading={false}
						selected={true}
					/>
				);
			}

		}
	}, [ instance, building, address, isUpdatingAddress ]);

	const [
		updateAddress,
		{ loading: isUpdatingAddressLoading, error: isUpdatingAddressError }
	] = useMutation(UpdateAddress, { refetchQueries: [ FetchCheckoutSession ] });

	function handleUpdateAddress(address: CreateAddressDTO & { id: string; }): void {
		updateAddress({
			variables: {
				addressLineOne: address.addressLineOne,
				addressLineTwo: address.addressLineTwo,
				city: address.city,
				state: address.state,
				zipCode: address.zipCode,
				instructions: address.instructions,
				instructionsParking: address.instructionsParking,
				floor: isNaN(address.floor) ? 1 : Number(address.floor),
				hasParking: address.hasParking,
				hasElevator: address.hasElevator,
				timezone: moment.tz.guess(),
				lat: address.lat,
				lng: address.lng,
				addressId: address.id
			}
		}).then(() => {
			setIsUpdatingAddress(false);
		}).catch((err) => {
			console.error("Failed to update address", err);
		});
	}

	return (
		<Box gap="medium">
			{isUpdatingAddress && (
				<AddressDialog
					isResidenceAddress={!!building}
					address={address ?? undefined}
					onClose={() => setIsUpdatingAddress(false)}
					isCreating={false}
					isUpdating={isUpdatingAddressLoading}
					createAddress={(address) => {
						return;
					}}
					updateAddress={(address) => {
						handleUpdateAddress({
							...address,
							id: address.id ?? ""
						});
					}}
				/>
			)}
			<Typography fontWeight="bold">
				Pickup Address
			</Typography>
			{address && (
				<>
					{addressListItem}
				</>
			)}
		</Box>
	);
};