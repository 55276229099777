import { gql } from "@apollo/client";

export const Stop_TrackingFragment = gql`
	fragment Stop_TrackingFragment on Stop {
		started
		startedAt
		arriving
		arrivingAt
		canComplete
		completed
		completedAt
		skipped
		skippedAt
		skippedReason
		isOptional
	}
`;