import { useEffect } from "react";
import { useSnackbar } from "notistack";

export function useOnboardingNotifications(
	hasError: boolean,
	exists: boolean,
	message: string
) {
	const snack = useSnackbar();

	useEffect(() => {
		if(exists) {
			//TODO: notify
			console.debug("useOnboardingNotifications: attempted to register existing user");
		}
	}, [ exists ]);

	useEffect(() => {
		if(hasError && message) {
			snack.enqueueSnackbar(message, {
				variant: "error"
			});
		}
	}, [ hasError, message ]);
}
