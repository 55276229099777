import { useQuery } from "@apollo/client";
import { MaterialGql, FindMaterials } from "../../../graphql";
import { useEffect, useState } from "react";

export function useMaterials() {
	const { data, error } = useQuery(FindMaterials);
	const [ materials, setMaterials ] = useState<MaterialGql[]>([]);

	useEffect(() => {
		if(data) {
			setMaterials([ ...data.FindMaterials ].sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)));
		}
	}, [ data ]);

	useEffect(() => {
		if(error) {
			console.error("Failed to list materials", error);
		}
	}, [ error ]);

	return materials;
}