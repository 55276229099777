import { Route, Routes } from "react-router";
import { WasteFootprintCalculator } from "./views";
import { useFeature } from "../instance/hooks";
import { InstanceFeature } from "../../graphql/__generated__/graphql";
import { PageNotAvailableView } from "../../views";
import { usePageTitle } from "../../hooks";

export const CalculatorRouter = () => {
	usePageTitle("Waste Footprint Calculator");
	const isCalculatorEnabled = useFeature(InstanceFeature.WasteCalculatorEnabled);

	if(!isCalculatorEnabled) {
		return (
			<PageNotAvailableView />
		);
	}

	return (
		<Routes>
			<Route path="/" element={
				<WasteFootprintCalculator />
			} />
		</Routes>
	);
};