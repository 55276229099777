import React, { useMemo } from "react";
import { BasicListItem } from "../../../../components";
import { isDestinationStop, isOriginStop } from "../../../../helpers";
import { useStop } from "../../../../graphql";

export const StopContactNameListItem: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	const { stop, loading } = useStop(stopId);

	const contactName = useMemo(() => {
		if(stop && isOriginStop(stop)) {
			return stop.incoming[ 0 ].user.fullName;
		}

		if(stop && isDestinationStop(stop)) {
			const outlet = stop.outlet;
			return outlet?.name;
		}

		return "";
	}, [ stop ]);

	return (
		<BasicListItem
			label="Contact Name"
			loading={loading}
			value={contactName ?? "Unknown"}
		/>
	);
};
