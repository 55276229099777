import React, { ReactNode, useMemo } from "react";
import { useInstance } from "../useInstance";
import { Text } from "grommet";
import { Bold } from "../../components";
import { Typography } from "@mui/material";

export function useCustomizer() {
	const { instance } = useInstance();

	return {

	};
}

interface ReferContent {
	welcomeContent: ReactNode;
	disableHeaderContent?: boolean;
	callToActionLabel: ReactNode;
	callToActionContent: ReactNode;
	stepOneContent: ReactNode;
	stepTwoContent: ReactNode;
	stepThreeContent: ReactNode;
}

export function useReferCustomizer() {
	const { instance } = useInstance();

	const welcomeContent = useMemo(() => {
		if(instance?.name === "Drexel University") {
			return (
				<Text>
					<Bold size="large">Drexel University</Bold> has partnered with <Bold size="large">Rego</Bold> and <Bold size="large">Habitat ReStore</Bold> to help you {(<Bold size="large">donate</Bold>)} your furniture, clothing, and more!
				</Text>
			);
		}

		return "";
	}, [ instance ]);

	const disableHeaderContent = useMemo(() => {
		if(instance?.name === "Drexel University") {
			return true;
		}

		return false;
	}, [ instance ]);

	const stepOneContent = useMemo(() => {
		if(instance?.name === "Drexel University") {
			return (
				<Text>
					<Text weight="bold">Select the item(s) you'd like to donate</Text> (like furniture, clothing, electronics, etc.)
				</Text>
			);
		}

		return "";
	}, [ instance ]);

	const stepTwoContent = useMemo(() => {
		if(instance?.name === "Drexel University") {
			return (
				<Text>
					<Text weight="bold">Select your preferred pickup date</Text> (we'll let you know when to bring everything outside)
				</Text>
			);
		}

		return "";
	}, [ instance ]);

	const stepThreeContent = useMemo(() => {
		if(instance?.name === "Drexel University") {
			return (
				<Text>
					<Text weight="bold">Be on the lookout for additional instructions in your email and text messages after you submit your request!</Text>
				</Text>
			);
		}

		return "";
	}, [ instance ]);

	return {
		welcomeContent,
		disableHeaderContent,
		callToActionLabel: "",
		callToActionContent: "",
		stepOneContent,
		stepTwoContent,
		stepThreeContent
	} as ReferContent;
}

type CheckoutStepContent = {
	heading: ReactNode;
	subheading: ReactNode;
	[ key: string ]: { heading: ReactNode; subheading: ReactNode; } | ReactNode;
};

interface CheckoutContent {
	items: CheckoutStepContent;
	address: CheckoutStepContent;
	review: CheckoutStepContent;
	schedule: CheckoutStepContent;
	contact: CheckoutStepContent;
}

export function useCheckoutCustomizer() {
	const { instance } = useInstance();

	const response = useMemo((): CheckoutContent => {
		return {
			items: {
				heading: "",
				subheading: ""
			},
			address: {
				heading: "",
				subheading: ""
			},
			contact: {
				heading: "",
				subheading: (instance?.name === "Drexel University")
					? "We ask for contact info so we can know who and where we’re picking up from, and how to get ahold of you just in case we need some more info"
					: ""
			},
			review: {
				heading: "",
				subheading: (instance?.name === "Drexel University")
					? (
						<Typography>
							Lets review everything. Note: If for some reason your furniture can’t be donated, we’ll follow up about next steps for free disposal.
						</Typography>
					)
					: ""
			},
			schedule: {
				heading: (instance?.name === "Drexel University")
					? "Pickup Window"
					: "",
				subheading: (instance?.name === "Drexel University")
					? "On the morning of your pick-up, please bring your items down to the curb by 11:00 AM (not the night before). We’ll send follow-up instructions in the event of inclement weather."
					: ""
			}
		};
	}, [ instance ]);

	return response;
}

export function useFrequentlyAskedQuestions(): DashboardFaqType[] {
	const { instance } = useInstance();

	const faqs = useMemo(() => {
		if(instance?.name === "Drexel University") {
			return [
				{
					question: "Is this really free?",
					answer: (
						<Typography>
							Yes! We think that it’s worth every effort to reduce unnecessary “waste” from our curbs, dumpsters, and landfills while benefiting local families in need.
						</Typography>
					)
				},
				{
					question: "Who benefits from the donated furniture?",
					answer: (
						<Typography>
							All furniture will be donated to Habitat for Humanity ReStore, who provides furniture to community members at a significant discount. Proceeds from their furniture sales go toward building new homes and home repairs for Philadelphia families in need.
						</Typography>
					)
				},
				{
					question: "Who can participate in the furniture donation drive?",
					answer: (
						<Typography>
							Any Drexel student living in off-campus housing in the Powelton area.
						</Typography>
					)
				},
				{
					question: "How do I take good photos of the furniture I want to donate?",
					answer: (
						<Typography>
							To take good photos, ensure that the furniture you wish to donate is taking up most of the photo’s frame. Please also ensure the furniture is not covered by anything and nothing is obscuring the photo.
						</Typography>
					)
				},
				{
					question: "Will I receive any confirmation if my item is accepted for donation?",
					answer: (
						<Typography>
							Yes, you should receive a confirmation text within 2 days of submitting your donation request.
						</Typography>
					)
				},
				{
					question: "What happens on pick-up day?",
					answer: (
						<Typography>
							Please bring your approved items to your curb by 11:00 AM on your selected pick-up day. The Habitat pick-up team will pick-up your approved items. Please note that if your items do not match the images you submitted, the pick-up team may not accept your items. You are responsible for bringing the item to the dumpster if your item needs to be disposed of.
						</Typography>
					)
				},
				{
					question: "Will I receive a donation receipt?",
					answer: (
						<Typography>
							Habitat for Humanity ReStore will provide a donation receipt for any items accepted on donation day. A donation receipt will not be provided for any items not physically accepted on pick-up day.
						</Typography>
					)
				},
				{
					question: "Can you disassemble?",
					answer: (
						<Typography>
							Disassembly will not be provided. If you choose to disassemble an item on your own to get it out of your home or apartment, please keep all of the needed parts (like screws, etc.) in a bag and taped to the largest piece of the furniture item disassembled so that the warehouse team at Habitat can easily reassemble your items.
						</Typography>
					)
				},
				{
					question: "Are there any items that cannot be donated?",
					answer: (
						<Typography>
							Mattresses, broken items, large armoires, Ikea closets, everyday trash, liquids, paint & other chemicals, yard waste, medical waste, food waste, car parts, items with bodily fluids, items with bedbugs and other creepy crawlers.
						</Typography>
					)
				},
				{
					question: "What happens if my item is not accepted for donation?",
					answer: (
						<Typography>
							Not to worry, there are dumpsters placed around the Powelton area so you can responsibly dispose of your items at no cost. Please click here to see where the closest dumpster location to your home or apartment.
						</Typography>
					)
				},
				{
					question: "Can I change my order before my pick-up?",
					answer: (
						<Typography>
							Yes! You can manage your order on your dashboard, or contact us! (and actually speak to a human)
						</Typography>
					)
				},
				{
					question: "Can I change my order during the pick-up?",
					answer: (
						<Typography>
							Yes! You can easily add or remove items while the hauling team is on-site using your dashboard.
						</Typography>
					)
				},
				{
					question: "Can the pick-up team come inside?",
					answer: (
						<Typography>
							The pick-up team cannot go inside of your building. Please place all the eligible items outside.
						</Typography>
					)
				}
			];
		}

		return [];
	}, [ instance ]);

	return faqs;
}

interface DashboardFaqType {
	question: string;
	answer: ReactNode;
}

export function useDashboardCustomizer() {
	const faqs = useFrequentlyAskedQuestions();

	return {
		faqs
	};
}