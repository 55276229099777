import { gql } from "@apollo/client";

export const User_HeaderFragment = gql`
	fragment User_HeaderFragment on User {
		id
		firstName
		lastName
		fullName
		avatar {
			id
			name
			contentUrl
		}
		createdAt
	}
`;