import { Box, Heading } from "grommet";
import { DialogWithActivatorButton } from "../../../../components";
import React from "react";
import { formatCurrency } from "../../../../helpers";
import { LoadingButton } from "@mui/lab";

export const SchedulePickupContainer: React.FC<{
	canSubmit: boolean;
	forceOpen?: boolean;
	forceClose?: boolean;
	isScheduling: boolean;
	isReschedule: boolean;
	handleSchedule(): void;
	totalAmount?: number;
	children: React.ReactNode;
	disableContentPadding?: boolean;
	activatorButton: React.ReactNode;
}> = ({ forceOpen, forceClose, isReschedule, canSubmit, isScheduling, handleSchedule, activatorButton, totalAmount, disableContentPadding, children }) => {

	return (
		<DialogWithActivatorButton
			forceOpen={forceOpen}
			forceClose={forceClose}
			disabledContentPadding={true}
			title={isReschedule ? "Reschedule Pickup" : "Schedule Pickup"}
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="between" align="center">
					{((totalAmount || 0) > 0) && (
						<Heading margin="none" level="3">
							Total: {formatCurrency(totalAmount || 0)}
						</Heading>
					)}
					<Box flex align="end">
						<LoadingButton
							color="primary"
							variant="contained"
							disabled={!canSubmit}
							loading={isScheduling}
							onClick={handleSchedule}
						>
							{isReschedule ? "Reschedule" : "Schedule"}
						</LoadingButton>
					</Box>
				</Box>
			)}
		>
			<Box>
				{children}
			</Box>
		</DialogWithActivatorButton>
	);
};
