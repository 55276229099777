import { useEffect, useMemo } from "react";
import { useLogin } from "../../../auth";
import { useInstance } from "../../../hooks";
import { useLazyQuery } from "@apollo/client";
import { FetchDashboardTasks } from "../../../graphql";
import { Task } from "../../task";

export function useDashboardTasks() {
	const { user } = useLogin();
	const { instance } = useInstance();

	const [
		fetch,
		{ data, error, loading },
	] = useLazyQuery(FetchDashboardTasks);

	useEffect(() => {
		if(instance && user) {
			fetch({
				variables: {
					userId: user.id,
					instanceId: instance.id,
				},
			});
		}
	}, [ user, instance ]);

	const tasks: Task[] = useMemo(() => {
		if(!data) return [];
		return (data.FindUserOrders ?? []).filter(order => order.schedulePending).map(order => ({
			id: order.id,
			title: "Schedule Pickup",
			label: "Your pickup is ready to be scheduled",
			icon: null,
			actionLabel: "Schedule Pickup",
			actionUrl: `/dashboard/orders/${order.id}/?schedule=true`,
			priority: 1
		}));
	}, [ data ]);

	return tasks;
}