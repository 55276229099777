import { useQuery } from "@apollo/client";
import { FindMaterials } from "../../../graphql";

export function useOnboardingMaterials() {
	const { data, loading } = useQuery(FindMaterials, {
		variables: {
			tags: { arrayContains: [ "onboarding" ] }
		}
	});

	return {
		materials: data?.FindMaterials || [],
		loading
	};
}