import { CallUsButton, DialogWithActivatorButton, TextUsButton } from "../../../../components";
import { IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Box } from "grommet";

export const RemoveProductDialog: React.FC<{
	productId: string;
}> = ({ productId }) => {
	return (
		<DialogWithActivatorButton
			title="Remove Item"
			activatorButton={(
				<IconButton
					style={{ zIndex: 10, padding: "0px" }}
					color="error"
					onClick={(event => event.stopPropagation())}
				>
					<Delete />
				</IconButton>
			)}
		>
			<Box gap="medium">
				<Typography fontSize="large">
					Need to remove something from your order? Get in touch with us to make changes to your order.
				</Typography>
				<Box gap="small" margin={{ horizontal: "large" }}>
					<CallUsButton />
					<TextUsButton />
				</Box>
			</Box>
		</DialogWithActivatorButton>
	);
};
