import React, { ReactNode, useEffect, useMemo } from "react";
import { selectForceEnableWhitelabel, selectInstancePublic, useAppDispatch, useAppSelector } from "../store";
import { ThemeState, selectTheme, setTheme } from "../store/theme";
import MuiLayout from "../theme/mui";
import { GrommetLayout } from "../theme";
import { ThemeType } from "grommet";
import { createTheme } from "@mui/material";

export let BRAND = "#5B8A7E";

export function useMuiTheme() {
	const branding = useBranding();

	const theme = useMemo(() => {
		return createTheme({
			palette: {
				mode: "light",
				primary: {
					main: branding.primaryColor
				},
				secondary: {
					main: branding.secondaryColor
				},
				action: {
					disabledBackground: ""
				},
				accent1: {
					main: branding.accentColor
				},
				accent2: {
					main: ""
				}
			},
			components: {
				MuiInputLabel: {
					styleOverrides: {
						asterisk: { visibility: "hidden" }
					}
				},
				MuiButton: {
					// variants: [ {
					// 	props: { color: "primary", variant: "contained" },
					// 	style: { backgroundColor: branding.secondaryColor, color: "black" }
					// } ]
					variants: [
						{
							props: { disabled: true },
							style: { color: "white" }
						}
					]
				}
			},
			typography: {
				fontSize: 16,
				fontFamily: "Roboto, sans-serif",
				fontWeightLight: 300,
				fontWeightBold: 600,
			}
		});
	}, [ branding ]);

	useEffect(() => {
		if(branding.primaryColor) {
			BRAND = branding.primaryColor;
		}
	}, [ branding ]);

	return theme;
}

export function useGrommetTheme() {
	const branding = useBranding();

	const theme = useMemo((): ThemeType => {
		return {
			global: {
				focus: {
					border: {
						color: "transparent"
					}
				},
				backgrounds: {
					default: {
						color: branding.headerColor
					}
				},
				font: {
					family: "Roboto, sans-serif;",
					size: "16px",
					weight: 300
				},
				colors: {
					brand: branding.primaryColor,
					"focus": branding.primaryColor,
					"accent-1": branding.secondaryColor,
					"accent-2": branding.accentColor,
					"accent-3": "#63635c",
					"dark-1": "#21211f",
					"light-1": "#f2f2f2",
					"light-2": "#ebebeb",
					//"light-2": "#dedede"
				},
				// focus: {
				// 	border: {
				// 		color: "accent-1"
				// 	}
				// }
			},
			button: {
				color: {
					dark: "white",
					light: "white"
				},
				border: {
					radius: "8px"
				},
				size: {
					small: {
						border: {
							radius: "8px"
						}
					},
					medium: {
						border: {
							radius: "8px"
						}
					},
					large: {
						border: {
							radius: "8px"
						}
					}
				}
			},
			heading: {
				color: "brand"
			},
			text: {

			},
			formField: {
				label: {
					weight: "bold"
				}
			}
		};
	}, [ branding ]);

	return theme;
}

export function useBranding() {
	const dispatch = useAppDispatch();
	const theme = useAppSelector(selectTheme);
	const instance = useAppSelector(selectInstancePublic);
	const forceEnableWhitelabel = useAppSelector(selectForceEnableWhitelabel);

	useEffect(() => {
		if(instance?.customizations && (instance.features.includes(InstanceFeature.WhitelabelEnabled) || forceEnableWhitelabel)) {
			const themeData = instance.customizations;
			dispatch(setTheme({
				primaryColor: themeData?.primaryColor || theme.primaryColor,
				secondaryColor: themeData?.secondaryColor || theme.secondaryColor,
				accentColor: themeData?.accentColor || theme.accentColor,
				headerColor: themeData?.headerColor || theme.headerColor,
				logoUrl: themeData?.logoUrl || theme.logoUrl,
				isCustom: true
			}));
		}
		else {
			dispatch(setTheme({
				...defaultThemeState
			}));
		}
	}, [ instance, forceEnableWhitelabel, theme, dispatch ]);

	return theme;
}

export function useUpdateTheme() {
	const dispatch = useAppDispatch();
	const theme = useAppSelector(selectTheme);
	const instance = useAppSelector(selectInstancePublic);

	return {
		setPreview(customizations: Partial<typeof theme>) {
			Object.keys(customizations).forEach(key => {
				const castedKey = key as keyof typeof customizations;
				if(!customizations[ castedKey ]) {
					delete customizations[ castedKey ];
				}
			});
			dispatch(setTheme({
				...theme,
				...customizations,
				isCustom: true
			}));
		},
		revertPreview() {
			if(instance?.customizations) {
				const themeData = instance.customizations;
				dispatch(setTheme({
					primaryColor: themeData?.primaryColor || theme.primaryColor,
					secondaryColor: themeData?.secondaryColor || theme.secondaryColor,
					accentColor: theme.accentColor,
					headerColor: themeData?.headerColor || theme.headerColor,
					logoUrl: themeData?.logoUrl || theme.logoUrl,
					isCustom: true
				}));
			}
		},
		reset() {
			if(instance?.customizations) {
				const themeData = instance.customizations;
				dispatch(setTheme({
					primaryColor: themeData?.primaryColor || theme.primaryColor,
					secondaryColor: themeData?.secondaryColor || theme.secondaryColor,
					accentColor: theme.accentColor,
					headerColor: themeData?.headerColor || theme.headerColor,
					logoUrl: themeData?.logoUrl || theme.logoUrl,
					isCustom: true
				}));
			}
		}
	};
}

export const ThemeProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
	const muiTheme = useMuiTheme();
	const grommetTheme = useGrommetTheme();

	return (
		<MuiLayout theme={muiTheme}>
			<GrommetLayout theme={grommetTheme}>
				{children}
			</GrommetLayout>
		</MuiLayout>
	);
};

const defaultPrimary = "#1e4c40";
const defaultSecondary = "#5b9a7b";
const defaultAccent = "#fff5f0";

const defaultTheme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: defaultPrimary
			// light: alpha(defaultPrimary, 0.5),
			// dark: alpha(defaultPrimary, 0.9),
			// contrastText: defaultAccent
		},
		secondary: {
			main: defaultSecondary,
			// light: alpha(defaultSecondary, 0.5),
			// dark: alpha(defaultSecondary, 0.9),
			// contrastText: "#000000"
		},
		action: {
			disabledBackground: ""
		},
		accent1: {
			main: defaultAccent
		},
		accent2: {
			main: "#ebcba3"
		}
	},
	components: {
		MuiInputLabel: {
			styleOverrides: {
				asterisk: { visibility: "hidden" }
			}
		},
		MuiButton: {
			// variants: [ {
			// 	props: { color: "primary", variant: "contained" },
			// 	style: { backgroundColor: branding.secondaryColor, color: "black" }
			// } ]
			variants: [
				{
					props: { disabled: true },
					style: { color: "white" }
				}
			]
		}
	},
	typography: {
		fontSize: 16,
		fontFamily: "Roboto, sans-serif",
		fontWeightLight: 300,
		fontWeightBold: 600,
	}
});

const defaultGrommetTheme: ThemeType = {
	global: {
		focus: {
			border: {
				color: "transparent"
			}
		},
		backgrounds: {
			default: {
				color: "white"
			}
		},
		font: {
			family: "Roboto, sans-serif;",
			size: "16px",
			weight: 300
		},
		colors: {
			brand: "#1e4c40",
			"focus": "#1e4c40",
			"accent-1": "#5b9a7b",
			"accent-2": "#ebcba3",
			"accent-3": "#fff5f0",
			"dark-1": "#21211f",
			"light-1": "#f2f2f2",
			"light-2": "#ebebeb",
		}
	},
	button: {
		color: {
			dark: "white",
			light: "white"
		},
		border: {
			radius: "8px"
		},
		size: {
			small: {
				border: {
					radius: "8px"
				}
			},
			medium: {
				border: {
					radius: "8px"
				}
			},
			large: {
				border: {
					radius: "8px"
				}
			}
		}
	},
	heading: {
		color: "brand"
	},
	text: {

	},
	formField: {
		label: {
			weight: "bold"
		}
	}
};

export const DefaultThemeProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const mui = useMuiTheme();
	const grommet = useGrommetTheme();
	const whitelabel = useFeature(InstanceFeature.WhitelabelEnabled);
	const forceEnableWhitelabel = useAppSelector(selectForceEnableWhitelabel);

	const muiTheme = useMemo(() => {
		if(whitelabel || forceEnableWhitelabel) {
			return mui;
		}

		return defaultTheme;
	}, [ whitelabel, mui, forceEnableWhitelabel ]);

	const grommetTheme = useMemo(() => {
		if(whitelabel || forceEnableWhitelabel) {
			return grommet;
		}

		return defaultGrommetTheme;
	}, [ whitelabel, grommet, forceEnableWhitelabel ]);

	return (
		<MuiLayout theme={muiTheme}>
			<GrommetLayout theme={grommetTheme}>
				{children}
			</GrommetLayout>
		</MuiLayout>
	);
};

import defaultRegoLogo from "../img/rego_cream_logo_centered.png";
import { InstanceFeature } from "../graphql/__generated__/graphql";
import { useFeature } from "../features/instance/hooks/useFeature";

export const defaultThemeState: ThemeState = {
	isCustom: false,
	primaryColor: "#1e4c40",
	secondaryColor: "#5b9a7b",
	accentColor: "#fff5f0",
	headerColor: "#1e4c40",
	logoUrl: defaultRegoLogo,
	personalizeHeader: false,
	personalizeDashboard: false,
	personalizeManagerDashboard: false
};