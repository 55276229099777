import { Button } from "@mui/material";
import { Box } from "grommet";
import { DialogWithClose } from "../../../../components/Dialog";
import { useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { FormContainer, SelectElement, useForm } from "react-hook-form-mui";
import { useCart } from "../../../cart/hooks/useCart";


export const ProductQuantityDialog: React.FC<{
	onClose(): void;
	productId: string;
	quantity: number;
	quantityAvailable: number;
}> = ({ productId, quantity, quantityAvailable, onClose }) => {
	const { updateProductQuantity } = useCart();
	const options = useMemo(() => {
		const options = [];
		for(let i = 1; i <= quantityAvailable; i++) {
			options.push({
				id: i,
				label: i
			});
		}
		return options;
	}, [ quantityAvailable ]);

	const formContext = useForm({
		defaultValues: {
			quantity
		}
	});

	function handleUpdateButton(): void {
		formContext.handleSubmit(handleSubmit)();
	}

	function handleSubmit(): void {
		const { quantity } = formContext.getValues();
		updateProductQuantity(productId, quantity);
		onClose();
	}

	return (
		<DialogWithClose
			title="Update Quantity"
			onClose={onClose}
			content={(
				<FormContainer formContext={formContext}>
					<Box>
						<SelectElement
							name="quantity"
							label="Quantity"
							options={options} />
					</Box>
				</FormContainer>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button variant="outlined" color="error" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={false}
						onClick={handleUpdateButton}
					>
						Update
					</LoadingButton>
				</Box>
			)} />
	);
};
