import { ListItemButton, ListItemText, Typography, ListItemSecondaryAction, IconButton, CircularProgress } from "@mui/material";
import { Box } from "grommet";
import { Edit } from "grommet-icons";
import { Address_HeaderFragmentFragment } from "../../graphql/__generated__/graphql";
import { WrapSkeleton } from "../Skeleton";

interface AddressListItemProps {
	selected: boolean;
	onEdit?: () => void;
	onSelect?: () => void;
	onDelete?: () => void;
	isLoading: boolean;
	addressId: string;
	primaryText: string;
	secondaryText: string;
}

export const AddressListItem: React.FC<AddressListItemProps> = (props) => {
	return (
		<ListItemButton key={props.addressId} divider selected={props.selected} onClick={props.onSelect}>
			<ListItemText>
				<Box gap="xsmall">
					<Typography
						fontSize="large"
						fontWeight="bold"
					>
						<WrapSkeleton maxWidth="200px" isLoading={!props.primaryText}>
							{props.primaryText}
						</WrapSkeleton>
					</Typography>
					<Typography fontSize="small">
						<WrapSkeleton maxWidth="200px" isLoading={!props.secondaryText}>
							{props.secondaryText}
						</WrapSkeleton>
					</Typography>

				</Box>
			</ListItemText>
			<ListItemSecondaryAction>
				<IconButton onClick={(event) => {
					event.stopPropagation();
					props.onEdit && props.onEdit();
				}}>
					{props.isLoading
						? (
							<CircularProgress />
						)
						: (
							<Edit style={{ visibility: !props.onDelete ? "hidden" : undefined }} />
						)}
				</IconButton>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

export const StandardAddressListItem: React.FC<{
	address: Address_HeaderFragmentFragment;
	selected: boolean;
	onSelect(): void;
	onEdit?: () => void;
	onDelete?(): void;
	isLoading: boolean;
}> = (props) => {
	return (
		<AddressListItem
			primaryText={props.address.addressLineOne}
			secondaryText={`${props.address.city}, ${props.address.state}`}
			addressId={props.address.id}
			selected={props.selected}
			onSelect={props.onSelect}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
			isLoading={props.isLoading}
		/>
	);
};

export const BuildingAddressListItem: React.FC<{
	unit: string;
	unitPrefix: string;
	addressId: string;
	buildingName: string;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	selected: boolean;
	isLoading: boolean;
}> = (props) => {
	return (
		<AddressListItem
			primaryText={props.buildingName}
			secondaryText={`${props.unitPrefix} ${props.unit}`}
			addressId={props.addressId}
			selected={props.selected}
			onSelect={props.onSelect}
			onEdit={props.onEdit}
			onDelete={props.onDelete}
			isLoading={props.isLoading}
		/>
	);
};