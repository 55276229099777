import React from "react";
import { Box } from "grommet";
import { HeadingWithIcon, Pagination, ViewContainer } from "../../../../components";
import { useBuildings } from "../hooks";
import { useWindowDimensions } from "../../../../hooks";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useMemo } from "react";
import Fuse from "fuse.js";
import { AddBusiness, Apartment } from "@mui/icons-material";
import { CreatePropertyDialog, PropertyDetailsDialog, PropertyListItem } from "../components";
import { Button, LinearProgress, Typography } from "@mui/material";
import { useAuthorize } from "../../../../auth";

export const PropertiesView: React.FC = () => {
	const { buildings, loading } = useBuildings();
	const { size } = useWindowDimensions();

	const formContext = useForm({
		defaultValues: { search: "" }
	});

	const searchTerm = formContext.watch("search");

	const filtered = useMemo(() => {
		if(!searchTerm || searchTerm.length < 3) return buildings;

		const fuse = new Fuse(buildings, {
			threshold: 0.3,
			includeMatches: true,
			includeScore: true,
			keys: [
				"name",
				"address.addressLineOne",
				"address.city",
				"address.state"
			]
		});

		return fuse.search(searchTerm).map(result => result.item);
	}, [ buildings, searchTerm ]);

	const [ isCreatingBuilding, setIsCreatingBuilding ] = React.useState(false);
	const [ selectedBuilding, setSelectedBuilding ] = React.useState<string | null>(null);

	const { isAuthorized: canCreateLocation } = useAuthorize(result => result.isGlobalAdmin || result.isInstanceAdmin);

	return (
		<ViewContainer>
			{selectedBuilding && (
				<PropertyDetailsDialog
					buildingId={selectedBuilding}
					onClose={() => setSelectedBuilding(null)}
				/>
			)}
			{isCreatingBuilding && (
				<CreatePropertyDialog onClose={() => setIsCreatingBuilding(false)} />
			)}
			<Box gap="medium">
				{loading && (
					<LinearProgress />
				)}
				<Box
					gap="medium"
					justify={size === "small" ? "start" : "between"}
					direction={size === "small" ? "column" : "row"}
				>
					<Box
						direction="row"
						align="center"
						justify="between"
						style={{ minHeight: "40px" }}>
						<HeadingWithIcon
							text="Locations"
							props={{
								level: "3",
								margin: "none"
							}}
							icon={< Apartment color="primary" />}
						/>
						{size === "small" && canCreateLocation && (
							<Button
								startIcon={(
									<AddBusiness />
								)}
								variant="contained"
								onClick={() => setIsCreatingBuilding(true)}
							>
								Add Location
							</Button>
						)}
					</Box>
					<Box
						gap="small"
						align={size === "small" ? undefined : "center"}
						justify={size === "small" ? "center" : undefined}
						style={{
							minHeight: size === "small" ? "60px" : undefined
						}}
						direction={size === "small" ? "column" : "row"}
						margin={{ bottom: size === "small" ? "medium" : undefined }}
					>
						<FormContainer formContext={formContext}>
							<TextFieldElement
								name="search"
								label="Search"
								size={size === "small" ? undefined : "small"}
								fullWidth={size === "small"}
							/>
						</FormContainer>
						{size !== "small" && canCreateLocation && (
							<Button
								startIcon={(
									<AddBusiness />
								)}
								variant="contained"
								onClick={() => setIsCreatingBuilding(true)}
							>
								Add Location
							</Button>
						)}
					</Box>
				</Box>
				{searchTerm && filtered.length === 0 && (
					<Box height="small" align="center" justify="center">
						<Typography>
							no results found
						</Typography>
					</Box>
				)}
				<Pagination pageSize={5}>
					{filtered.map((building, i) => (
						<PropertyListItem
							key={building.id + i}
							buildingId={building.id}
							onClick={() => setSelectedBuilding(building.id)}
						/>
					))}
				</Pagination>
			</Box>
		</ViewContainer>
	);
};