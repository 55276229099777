import { gql } from "@apollo/client";


export const WasteTicket_SourceFragment = gql`
	fragment WasteTicket_SourceFragment on WasteTicket {
		id
		source { 
			weight 
			weightUnit 
			volume 
			volumeUnit 
		}
		partner { 
			id 
			name
		}
	}
`;
