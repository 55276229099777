import { Box, Heading } from "grommet";
import { useInstance } from "../../../../../hooks";
import { Pagination } from "../../../../../components";
import React from "react";
import { LinearProgress } from "@mui/material";
import { useActiveOrders } from "../../../hooks";
import { DonationCenterOrderListItem } from "./DonationCenterOrderListItem";

export const DonationCenterActiveOrdersComponent: React.FC = (props) => {
	const { instance } = useInstance();

	const {
		orders, loading
	} = useActiveOrders(instance?.id || "");

	return (
		<Box flex gap="medium">
			<Box gap="xsmall">
				<Heading level="3" margin="none">
					Active Pickups
				</Heading>
				{loading && (
					<LinearProgress />
				)}
			</Box>
			<Box flex>
				<Pagination pageSize={5}>
					{orders.map((order) => (
						<DonationCenterOrderListItem
							order={order}
							key={order.id}
						/>
					))}
				</Pagination>
			</Box>
		</Box>
	);
};
