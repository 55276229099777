import { gql } from "../../../__generated__";

export const FetchDonationReporting = gql(/* GraphQL */ `
	query FetchDonationReporting(
		$instanceId: String!
		$from: DateTime!
		$to: DateTime!
	) {
		FetchDonationReporting(
			instanceId: $instanceId
			from: $from
			to: $to
		) {
			periodEnd
			periodBegin
			totalDonations
			totalItemsDonated
		}
	}
`);

export const FetchDonationCenterDashboard = gql(/* GraphQL */ `
	query FetchDonationReporting(
		$instanceId: String!
		$from: DateTime!
		$to: DateTime!
	) {
		FetchDonationReporting(
			instanceId: $instanceId
			from: $from
			to: $to
		) {
			periodEnd
			periodBegin
			totalDonations
			totalItemsDonated
		}
	}
`);