import { gql } from "@apollo/client";

export const Instance_CustomizationsFragment = gql(`
	fragment Instance_CustomizationsFragment on Instance {
		customizations {
			primaryColor
			secondaryColor
			accentColor
			headerColor
			logoUrl
			resourcesUrl
		}
	}
`);