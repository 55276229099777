import { gql } from "../../../__generated__";

export const UpsertWasteCalculatorResponse = gql(/* GraphQL */ `
	mutation UpsertWasteCalculatorResponse(
		$submissionId: String!
		$response: WasteCalculatorResponseInput!
	) {
		UpsertWasteCalculatorResponse(submissionId: $submissionId, dto: $response) {
			id
		}
	}
`);