import { SvgIcon, SvgIconProps } from "@mui/material";

	export const KitchenCabinetsAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1104.2 301.71h14.398c16.629 0 30-13.543 30-30v-67.543c0-16.629-13.371-30-30-30h-1037.1c-16.457 0-30 13.371-30 30v67.543c0 16.457 13.543 30 30 30h14.57v546.52h-9.9414c-16.457 0-30 13.543-30 30v33.258c0 16.457 13.543 30 30 30h27.941l15.086 74.055c1.1992 6 6.5117 10.285 12.516 10.285h45.258c6 0 11.312-4.2852 12.516-10.285l15.086-74.055h335.48l15.086 74.055c1.1953 6 6.3398 10.285 12.512 10.285h45.086c6.1719 0 11.484-4.2852 12.684-10.285l15.086-74.055 335.32-0.003906 15.086 74.055c1.1992 6.0039 6.5156 10.289 12.684 10.289h45.086c6.1719 0 11.484-4.2852 12.684-10.285l15.086-74.055h27.77c16.629 0 30-13.543 30-30v-33.258c0-16.457-13.371-30-30-30h-9.9414c0.003906-132.43 0.003906-417.51 0.003906-546.52zm-25.715 128.74h-465.43v-128.74h465.43zm-710.91 417.77v-392.06h219.77v392.06zm-245.66-392.06h219.77v392.06h-219.77zm491.14 0h219.77v392.06h-219.77zm-535.88-184.46v-67.543c0-2.3984 2.0586-4.2852 4.2852-4.2852h1037.1c2.3984 0 4.2852 1.8867 4.2852 4.2852v67.543c0 2.3984-1.8867 4.2852-4.2852 4.2852h-1037.1c-2.2305 0-4.2852-2.0586-4.2852-4.2852zm510.17 30v128.74h-465.6v-128.74zm-411.09 698.4h-23.996l-12-58.629h48zm435.95 0h-24.172l-11.828-58.629h48zm435.94 0h-24.172l-11.828-58.629h47.828zm70.285-121.88v33.258c0 2.3984-1.8867 4.2852-4.2852 4.2852h-1028.1c-2.2305 0-4.2852-1.8867-4.2852-4.2852v-33.258c0-2.3984 2.0586-4.2852 4.2852-4.2852h1028.1c2.4023-0.003906 4.2852 1.8828 4.2852 4.2852zm-259.71-30v-392.06h219.77v392.06z"/>
  <path d="m1005.4 501.6h-73.715c-12.172 0-21.941 9.7734-21.941 21.773 0 12.172 9.7734 21.941 21.941 21.941h73.715c12 0 21.941-9.9414 21.941-21.941 0-12.004-9.9414-21.773-21.941-21.773zm0 30.855h-73.715c-4.9727 0-9.0859-4.1133-9.0859-9.0859s4.1133-8.9141 9.0859-8.9141h73.715c4.9727 0 9.0859 3.9414 9.0859 8.9141s-4.1172 9.0859-9.0859 9.0859z"/>
  <path d="m759.94 501.6h-73.887c-12 0-21.773 9.7734-21.773 21.773 0 12.172 9.7734 21.941 21.773 21.941h73.887c12 0 21.77-9.9414 21.77-21.941 0.003906-12.004-9.7695-21.773-21.77-21.773zm0 30.855h-73.887c-4.9727 0-8.9141-4.1133-8.9141-9.0859s3.9414-8.9141 8.9141-8.9141h73.887c4.9727 0 8.9141 3.9414 8.9141 8.9141s-3.9414 9.0859-8.9141 9.0859z"/>
  <path d="m514.29 501.6h-73.715c-12.172 0-21.945 9.7734-21.945 21.773 0 12.172 9.7734 21.941 21.945 21.941l73.715-0.003906c12 0 21.941-9.9414 21.941-21.941s-9.9414-21.77-21.941-21.77zm0 30.855h-73.715c-4.9727 0-9.0859-4.1133-9.0859-9.0859s4.1133-8.9141 9.0859-8.9141h73.715c4.9727 0 9.0859 3.9414 9.0859 8.9141s-4.1133 9.0859-9.0859 9.0859z"/>
  <path d="m268.8 501.6h-73.887c-12 0-21.773 9.7734-21.773 21.773 0 12.172 9.7734 21.941 21.773 21.941h73.887c12 0 21.773-9.9414 21.773-21.941-0.003907-12.004-9.7734-21.773-21.773-21.773zm0 30.855h-73.887c-4.9727 0-8.9141-4.1133-8.9141-9.0859s3.9453-8.9141 8.9141-8.9141h73.887c4.9727 0 8.9141 3.9414 8.9141 8.9141s-3.9453 9.0859-8.9141 9.0859z"/>
  <path d="m301.71 387.94h105.77c12 0 21.77-9.7734 21.77-21.941 0-12-9.7734-21.773-21.77-21.773h-105.77c-12 0-21.941 9.7734-21.941 21.773 0 12.172 9.9414 21.941 21.941 21.941zm0-30.855h105.77c4.9727 0 8.9141 3.9414 8.9141 8.9141s-3.9414 9.0859-8.9141 9.0859h-105.77c-4.9727 0-9.0859-4.1133-9.0859-9.0859s4.1133-8.9141 9.0859-8.9141z"/>
  <path d="m793.03 387.94h105.6c12.172 0 21.945-9.7734 21.945-21.941 0-12-9.7734-21.773-21.945-21.773h-105.6c-12.172 0-21.945 9.7734-21.945 21.773 0.003907 12.172 9.7734 21.941 21.945 21.941zm0-30.855h105.6c4.9727 0 9.0859 3.9414 9.0859 8.9141s-4.1133 9.0859-9.0859 9.0859h-105.6c-4.9727 0-9.0859-4.1133-9.0859-9.0859s4.1133-8.9141 9.0859-8.9141z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};