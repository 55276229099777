import { Box, Text } from "grommet";
import { Badge, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store";
import { Calculate, CircleNotifications, Dashboard, Download, Elevator, ExpandLess, ExpandMore, HelpCenter, LocalShipping, Loyalty, Person, PriceCheck, Receipt, Recycling, Settings, ShoppingCart, Store, Tune } from "@mui/icons-material";
import { push } from "redux-first-history";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { selectTheme } from "../../store/theme";
import { CustomizationsDialog } from "../../features/admin/components/instance";
import { useLocation } from "react-router";
import { useSidebarShrink } from "./SidebarLayout";
import { useInstance, usePrimaryColor } from "../../hooks";
import { FaqDialog } from "../faq/FaqDialog";

export interface SidebarListItemProps {
	text: string;
	path: string;
	icon: React.ReactNode;
	selected?: boolean;
	onClick?: () => void;
	notificationCount?: number;
	indent?: boolean;
	collapse?: boolean;
	isExpanded?: boolean;
	isExpandable?: boolean;
}

export const SidebarListItem: React.FC<SidebarListItemProps> = (props) => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const shrink = useSidebarShrink();
	const collapse = useAppSelector(selectTheme).collapseSidebar;

	const handleClick = useCallback(() => {
		if(props.onClick) {
			props.onClick();
			return;
		}

		if(props.path) {
			dispatch(push(props.path));
		}
	}, [ dispatch, props ]);

	const selected = useMemo(() => {
		return props.selected ?? location.pathname === props.path;
	}, [ props.selected, props.path, location.pathname ]);

	const BRAND = usePrimaryColor();

	if(shrink) {
		return (
			<Box align="center" justify="center">
				<Badge badgeContent={props.notificationCount} color="primary">
					<IconButton
						onClick={handleClick}
						color={selected ? "primary" : undefined}
						style={{ borderRadius: "50%", borderColor: BRAND }}
					>
						{props.icon}
					</IconButton>
				</Badge>
			</Box>
		);
	}

	if(collapse) {
		return (
			<Badge
				overlap="circular"
				// badgeContent={
				// 	<Typography variant="caption">
				// 		Dashboard
				// 	</Typography>
				// }
				anchorOrigin={{
					horizontal: "right",
					vertical: "bottom"
				}}
			>
				<Badge badgeContent={props.notificationCount} color="primary">
					<IconButton
						onClick={handleClick}
						color={selected ? "primary" : undefined}
						style={{ borderRadius: "50%", borderColor: BRAND }}
					>
						{props.icon}
					</IconButton>
				</Badge>
			</Badge>
		);
	}

	return (
		<ListItemButton selected={selected} onClick={handleClick} sx={{ pl: props.indent ? 4 : undefined }}>
			<ListItemIcon>
				{props.icon}
			</ListItemIcon>
			<ListItemText>
				<Text weight="bold">
					{props.text}
				</Text>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small">
					{!!props.notificationCount && (
						<Box align="center" justify="center">
							<Badge badgeContent={props.notificationCount} color="primary">
								<CircleNotifications />
							</Badge>
						</Box>
					)}
					{(props.isExpandable && !props.notificationCount) && (
						<Box>
							<IconButton>
								{props.isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
							</IconButton>
						</Box>
					)}
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

export interface ListItemProps {
	absolutePath: string;
}

export interface TemplateListItemProps extends ListItemProps {
	onClick?: () => void;
	notificationCount?: number;
}

export const DashboardListItem: React.FC<TemplateListItemProps> = (props) => {
	return (
		<SidebarListItem
			key="dashboard"
			text="Dashboard"
			icon={<Dashboard fontSize="small" />}
			path={props.absolutePath}
			notificationCount={props.notificationCount}
		/>
	);
};

export const OrdersListItem: React.FC<ListItemProps & { title?: string; }> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="orders"
			text={props.title || "Orders"}
			icon={<Receipt fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const DonorsListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="donors"
			text="Donors"
			icon={<Person fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};


export const PurchasesListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="purchases"
			text="Purchases"
			icon={<ShoppingCart fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const PricingPoliciesListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="pricing-policies"
			text="Pricing Policies"
			icon={<PriceCheck fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const InstancesListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="instances"
			text="Instances"
			icon={<Settings fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const ConfigurationListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="configuration"
			text="Configuration"
			icon={<Settings fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const FaqListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<FaqDialog
			key="faq"
			activatorButton={(
				<Box>
					<SidebarListItem
						text="FAQs"
						key="faq"
						icon={<HelpCenter fontSize="small" />}
						path=""
						selected={location.pathname.includes(props.absolutePath)}
					/>
				</Box>
			)}
		/>
	);
};

export const StoreMultilevelListItem: React.FC<ListItemProps> = (props) => {
	const shrink = useSidebarShrink();
	const location = useLocation();
	const selected = useMemo(() => {
		return location.pathname.includes("/store");
	}, [ location ]);

	return (
		<Fragment key="store">
			<SidebarListItem
				text="Store"
				key="store"
				icon={<Store fontSize="small" />}
				path={"/admin/store"}
				selected={false}
			/>
			<Collapse in={selected} timeout="auto" unmountOnExit>
				<List component="div" disablePadding style={{ display: shrink ? "flex" : undefined, flexDirection: shrink ? "row" : undefined }}>
					<SidebarListItem
						indent
						text="Dashboard"
						key="store-dashboard"
						icon={<Dashboard fontSize="small" />}
						path={"/admin/store/dashboard"}
						selected={location.pathname.includes("/store/dashboard")}
					/>
					<SidebarListItem
						indent
						text="Settings"
						key="store-settings"
						icon={<Settings fontSize="small" />}
						path={"/admin/store/settings"}
						selected={location.pathname.includes("/store/settings")}
					/>
					<SidebarListItem
						indent
						text="Products"
						key="store-products"
						icon={<Loyalty fontSize="small" />}
						path={"/admin/store/products"}
						selected={location.pathname.includes("/store/products")}
					/>
					<SidebarListItem
						indent
						text="Purchases"
						key="store-purchases"
						icon={<ShoppingCart fontSize="small" />}
						path={"/admin/store/purchases"}
						selected={location.pathname.includes("/store/purchases")}
					/>
				</List>
			</Collapse>
		</Fragment>
	);
};

export const ResidentsListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			text="Residents"
			key="residents"
			icon={<Person fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const ElevatorSchedulingListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="elevator-scheduling"
			text="Elevator Scheduling"
			icon={<Elevator fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const PickupsListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="pickups"
			text="Pickups"
			icon={<LocalShipping fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const DeliveriesListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="deliveries"
			text="Deliveries"
			icon={<LocalShipping fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const StoreListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="store"
			text="Store"
			icon={<Store fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const SettingsListItem: React.FC<ListItemProps> = (props) => {
	const location = useLocation();
	return (
		<SidebarListItem
			key="settings"
			text="Settings"
			icon={<Settings fontSize="small" />}
			path={props.absolutePath}
			selected={location.pathname.includes(props.absolutePath)}
		/>
	);
};

export const CustomizationsListItem: React.FC = () => {
	const theme = useAppSelector(selectTheme);
	const [ isUpdatingCustomizations, setIsUpdatingCustomizations ] = useState(false);

	return (
		<Fragment key="customizations">
			{isUpdatingCustomizations && (
				<CustomizationsDialog
					primaryColor={theme.primaryColor}
					secondaryColor={theme.secondaryColor}
					headerColor={theme.headerColor}
					logoUrl={theme.logoUrl}
					onClose={() => setIsUpdatingCustomizations(false)} />
			)}
			<SidebarListItem
				text="Customizations"
				icon={<Tune fontSize="small" />}
				onClick={() => setIsUpdatingCustomizations(true)}
				selected={false}
				path=""
			/>
		</Fragment>
	);
};

export const WasteReportingListItem: React.FC<TemplateListItemProps> = (props) => {
	const shrink = useSidebarShrink();
	const location = useLocation();
	const { instance } = useInstance();
	const selected = useMemo(() => {
		return location.pathname.includes(props.absolutePath);
	}, [ location.pathname, props.absolutePath ]);

	const enableCalculator = useMemo(() => {
		return instance?.name === "JLL";
	}, [ instance ]);

	return (
		<Fragment>
			<SidebarListItem
				text="Reporting"
				key="reporting"
				icon={<Recycling fontSize="small" />}
				path={props.absolutePath}
				selected={false}
				isExpandable
				isExpanded={location.pathname.includes("/waste-reporting")}
			/>
			<Collapse in={selected} timeout="auto" unmountOnExit>
				<List component="div" disablePadding style={{ display: shrink ? "flex" : undefined, flexDirection: shrink ? "row" : undefined }}>
					<SidebarListItem
						indent
						key="reporting-dashboard"
						text="Dashboard"
						icon={<Dashboard fontSize="small" />}
						path={"/admin/waste-reporting/dashboard"}
						selected={location.pathname.includes("/waste-reporting/dashboard")}
					/>
					{/* <SidebarListItem
						indent
						text="Share"
						icon={<Share fontSize="small" />}
						path={"/admin/waste-reporting/share"}
						selected={location.pathname.includes("/waste-reporting/share")}
					/> */}
					{/* <SidebarListItem
						indent
						key="reporting-export"
						text="Export"
						icon={<Download fontSize="small" />}
						path={"/admin/waste-reporting/download"}
						selected={location.pathname.includes("/waste-reporting/download")}
					/> */}
					<SidebarListItem
						indent
						key="reporting-upload"
						text="Upload"
						icon={<Receipt fontSize="small" />}
						path={"/admin/waste-reporting/upload"}
						selected={location.pathname.includes("/waste-reporting/upload")}
					/>
					{instance?.customizations?.resourcesUrl && (
						<SidebarListItem
							indent
							key="reporting-resources"
							text="Resources"
							icon={<Download fontSize="small" />}
							path={instance.customizations.resourcesUrl}
							selected={false}
							onClick={() => {
								window.open(instance.customizations.resourcesUrl || "", "_blank");
							}}
						/>
					)}
					{enableCalculator && (
						<SidebarListItem
							indent
							key="reporting-calculator"
							text="Calculator"
							icon={<Calculate fontSize="small" />}
							path={"/admin/waste-reporting/calculator"}
							onClick={() => {
								window.open("https://demo.liverego.com", "_blank");
							}}
							selected={location.pathname.includes("/waste-reporting/calculator")}
						/>
					)}
				</List>
			</Collapse>
		</Fragment>
	);
};