import { Chip, ListItemButton, ListItemText, Typography } from "@mui/material";
import { OrderStatus } from "../../../../graphql/__generated__/graphql";
import { Box } from "grommet";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { formatCurrency } from "../../../../helpers";
import { useUserScopedOrder } from "../../../../graphql";
import { useMemo } from "react";
import { useWindowDimensions } from "../../../../hooks";
import moment from "moment-timezone";

export const OrderSummaryListItem: React.FC<{
	orderId: string;
	selected?: boolean;
	onClick: () => void;
}> = (props) => {
	const { size } = useWindowDimensions();
	const { order } = useUserScopedOrder(props.orderId);

	const shortText = useMemo(() => {
		if(order?.status === OrderStatus.Submitted && order?.schedulePending && !order?.scheduled) {
			return "Ready to Schedule";
		}

		if(order?.status === OrderStatus.Submitted && !order?.scheduled) {
			return "Submitted";
		}

		if(order?.status === OrderStatus.Submitted && !!order?.scheduled) {
			return "Scheduled";
		}

		if(order?.status === OrderStatus.Completed) {
			return "Completed";
		}

		if(order?.status === OrderStatus.Cancelled) {
			return "Cancelled";
		}

		if(order?.status === OrderStatus.Pending) {
			return "Pending";
		}
	}, [ order ]);

	const primaryText = useMemo(() => {
		if(order?.completedAt) {
			return `Completed on ${moment(order.completedAt, "YYYY-MM-DD").format("MMM Do, YYYY")}`;
		}

		if(order?.cancelledAt) {
			return `Cancelled on ${moment(order.cancelledAt, "YYYY-MM-DD").format("MMM Do, YYYY")}`;
		}

		if(!order?.pickup?.scheduledDate) {
			if(!order?.schedulePending) {
				return "Your order is currently being processed and will be ready to schedule soon";
			}
			else {
				return "Your order is ready to be scheduled";
			}
		}

		return `Scheduled for ${moment(order.pickup.scheduledDate, "YYYY-MM-DD").format("MMM Do, YYYY")}`;
	}, [ order ]);

	return (
		<ListItemButton
			divider
			onClick={props.onClick}
			selected={props.selected}
		>
			<Box gap="medium" width="100%">
				<Box direction="row" flex>
					<ListItemText
						primary={size === "small"
							? shortText
							: primaryText
						}
						secondary="Click to view more details"
					/>
					<Box justify="center">
						<Box direction="row" gap="small">
							{(!!order?.acceptedEstimate && order.acceptedEstimate.totalAmount > 0 && size !== "small") && (
								<Box align="center" justify="center">
									<Typography fontWeight="bold">
										{formatCurrency(order.acceptedEstimate.totalAmount)}
									</Typography>
								</Box>
							)}
							{(order?.status === OrderStatus.Submitted && !order?.scheduled) && (
								<Chip
									color="warning"
									label="Submitted"
									icon={<CheckCircle />}
								/>
							)}
							{(order?.status === OrderStatus.Submitted && !!order?.scheduled) && (
								<Chip
									color="success"
									label="Scheduled"
									icon={<CheckCircle />}
								/>
							)}
							{order?.status === OrderStatus.Completed && (
								<Chip
									color="success"
									label="Completed"
									icon={<CheckCircle />}
								/>
							)}
							{order?.status === OrderStatus.Cancelled && (
								<Chip
									color="warning"
									label="Cancelled"
									icon={<Cancel />}
								/>
							)}
						</Box>
					</Box>
				</Box>
				{size === "small" && (
					<Box>
						<Typography>
							{primaryText}
						</Typography>
					</Box>
				)}
			</Box>
		</ListItemButton>
	);
};
