import { Grommet, ThemeType } from "grommet";

export function GrommetLayout({ children, theme }: { children: React.ReactNode; theme: ThemeType; }) {
	return (
		<Grommet theme={theme} full>
			{children}
		</Grommet>
	);
}

export default GrommetLayout;