import { gql } from "@apollo/client";

export const WasteContainerInspection_HeaderFragment = gql`
	fragment WasteContainerInspection_HeaderFragment on WasteContainerInspection {
		id
		scheduled
		scheduledAt
		scheduledFor
		inspected
		inspectedAt
		missed
		processed
		fillRateDecimal
	}
`;

