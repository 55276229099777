import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Easel: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m243.79 243.66c-4.9688 0-9.7969 1.9688-13.312 5.4844-3.5625 3.5625-5.5312 8.3438-5.4844 13.359v525.14-0.046876c0.09375 10.312 8.4844 18.609 18.797 18.562h93.562c10.312 0.046876 18.703-8.25 18.844-18.562 0-4.9688-1.9688-9.7969-5.4844-13.312-3.5625-3.5156-8.3438-5.5312-13.359-5.4844h-74.719v-487.5h674.9v487.5h-75.047c-5.0156-0.046876-9.7969 1.9688-13.312 5.4844-3.5625 3.5156-5.5312 8.3438-5.5312 13.312 0.14062 10.312 8.5312 18.609 18.844 18.562h93.844c10.219-0.09375 18.422-8.3438 18.562-18.562v-525.14 0.046875c0.046874-10.312-8.25-18.75-18.562-18.844z"/>
  <path d="m600.1 150.1c-10.312-0.046875-18.75 8.25-18.844 18.562v93.844c0 4.9688 1.9688 9.7969 5.4844 13.312 3.5625 3.5156 8.3438 5.5312 13.359 5.4844 10.266-0.09375 18.562-8.5312 18.516-18.797v-93.844c-0.09375-10.219-8.3438-18.469-18.516-18.562z"/>
  <path d="m777.24 806.72c-4.8281-1.1719-9.8906-0.375-14.109 2.2031-4.3125 2.5781-7.4531 6.7969-8.6719 11.672-1.1719 4.9219-0.375 10.078 2.2969 14.344l127.36 206.26c5.4375 8.7656 16.922 11.484 25.734 6.0938 8.7656-5.4375 11.484-16.969 6.0938-25.734l-127.08-206.26c-2.5781-4.3125-6.75-7.4062-11.625-8.5781z"/>
  <path d="m600.1 806.16c-5.0156 0-9.7969 1.9688-13.359 5.4844-3.5156 3.5625-5.4844 8.3438-5.4844 13.359v206.26-0.046875c0 5.0156 1.9688 9.7969 5.4844 13.359 3.5625 3.5156 8.3438 5.4844 13.359 5.4844 10.266-0.14062 18.562-8.5312 18.516-18.844v-206.26 0.046875c0.046875-10.312-8.25-18.75-18.516-18.844z"/>
  <path d="m421.26 806.72c-4.8281 1.125-9 4.125-11.625 8.2969l-125.68 203.72c-5.4375 8.8125-2.6719 20.344 6.0938 25.781 4.2188 2.6719 9.375 3.5156 14.25 2.3906 4.9219-1.125 9.1406-4.2188 11.766-8.4844l125.39-203.48c2.6719-4.2188 3.5625-9.375 2.3906-14.297-1.125-4.875-4.2188-9.0938-8.4844-11.719-4.2188-2.5781-9.3281-3.375-14.109-2.2031z"/>
  <path d="m337.36 731.16c-10.312 0.09375-18.562 8.4844-18.516 18.797v75.047c-0.046875 10.266 8.2031 18.703 18.516 18.797h525.14c5.0156 0.046875 9.7969-1.9688 13.312-5.4844 3.5625-3.5156 5.5312-8.3438 5.5312-13.312v-75.047c0-4.9688-1.9688-9.7969-5.5312-13.312-3.5156-3.5625-8.2969-5.5312-13.312-5.4844zm18.844 37.641h487.5v37.359h-487.5z"/>
  <path d="m511.78 221.81c-10.312-0.046875-18.703 8.2031-18.844 18.516 0 5.0156 1.9688 9.7969 5.4844 13.359 3.5625 3.5156 8.3438 5.4844 13.359 5.4844h176.63-0.046875c10.312-0.14062 18.609-8.5312 18.562-18.844-0.09375-10.172-8.3438-18.422-18.562-18.516z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};