import { Box } from "grommet";
import { Typography, useTheme } from "@mui/material";
import { WrapSkeleton } from "../../../components";
import { useMemo } from "react";
import { formatCurrency } from "../../../helpers";


export const ItemPricingElement: React.FC<{
	totalCount: number;
	pickupFee: number;
	pickupFeeComparison: number;
}> = ({ totalCount, pickupFee, pickupFeeComparison }) => {
	const theme = useTheme();

	const totalPickupFee = useMemo(() => {
		return pickupFee * totalCount;
	}, [ pickupFee, totalCount ]);

	const totalPickupFeeComparison = useMemo(() => {
		return pickupFeeComparison * totalCount;
	}, [ pickupFeeComparison, totalCount ]);

	return (
		<WrapSkeleton disableMargin isLoading={false}>
			{totalCount > 0 && totalPickupFee > 0
				? (
					<Box direction="row" gap="small">
						<Typography
							color={theme.palette.success.main}
							variant="caption"
						>
							{formatCurrency(totalPickupFee)}
						</Typography>
						<Typography
							variant="caption"
							style={{ textDecoration: "line-through" }}
						>
							{formatCurrency(totalPickupFeeComparison)}
						</Typography>
					</Box>
				)
				: (
					<Typography />
				)}
		</WrapSkeleton>
	);
};
