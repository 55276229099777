import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Cupboard: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m600 360h-520v360h520zm40 0h520v360h-520zm360 400h-760v80h-120v-80h-80v-440h1160v440h-80v80h-120zm40 0h40v40h-40zm-840 0h-40v40h40zm320-280h40v120h-40zm160 0h40v120h-40z" fill-rule="evenodd"/>
</svg>
			</SvgIcon>
		);
	};