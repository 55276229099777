import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FetchProductRelationships } from "../../../graphql";

export function useProductRelationships(productId: string) {
	const snack = useSnackbar();

	const [
		fetchProduct, { data, loading }
	] = useLazyQuery(FetchProductRelationships);

	useEffect(() => {
		if(productId) {
			fetchProduct({
				variables: { productId }
			}).catch(err => {
				console.error("Failed to fetch product relationships", err);
				snack.enqueueSnackbar("Failed to fetch product relationships", { variant: "error" });
			});
		}
	}, [ productId ]);

	return {
		loading,
		productId,
		userId: data?.FetchProduct?.user?.id ?? null,
		orderId: data?.FetchProduct?.order?.id ?? null,
		outletId: data?.FetchProduct?.outlet?.id ?? null,
	};

}
