import { useQuery } from "@apollo/client";
import { FetchBuildingByInstance, FetchBuildingByInstanceUnauthenticated } from "../graphql";
import { useInstance } from ".";
import { useBuilding } from "../features/admin/residence/hooks/useBuilding";
import { selectInstancePublic, useAppSelector } from "../store";

export function useInstanceBuilding() {
	const { instance } = useInstance();

	const { data } = useQuery(FetchBuildingByInstance, {
		variables: {
			instanceId: instance?.id || ""
		},
		skip: !instance
	});

	return useBuilding(data?.FetchBuildingByInstance?.id || "");
}

export function useInstanceBuildingPublic() {
	const instance = useAppSelector(selectInstancePublic);

	const { data, loading } = useQuery(FetchBuildingByInstanceUnauthenticated, {
		variables: {
			instanceId: instance?.id || ""
		},
		skip: !instance
	});

	return {
		data,
		loading,
		building: data?.FetchBuildingByInstance || null
	};
}