import { gql } from "../../../__generated__";

export const FindCompletedOrders = gql(/* GraphQL */ `
	query FindCompletedOrders(
		$userId: String!
		$instanceId: String!
		$completedAtAfter: DateTime
	) {
		FindCompletedOrders(
			userId: $userId
			instanceId: $instanceId
			completedAtAfter: $completedAtAfter
		) {
			...Order_UserCombinedFragment
		}
	}
`);