import React, { ReactNode, useEffect, useState } from "react";
import { selectInstanceId, setInstancePublic, setInstanceId, useAppDispatch, useAppSelector } from "../store";
import { useLazyQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import { SplashScreen } from "../components";
import { FetchInstanceByDomain, FetchInstancePublic } from "../graphql";

export const InstanceProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
	const dispatch = useAppDispatch();
	const [ params ] = useSearchParams();
	const instanceId = useAppSelector(selectInstanceId);
	const [ wasLoaded, setWasLoaded ] = useState(false);
	const [ execute, { data, loading, error } ] = useLazyQuery(FetchInstancePublic);

	const [
		fetchWithDomainQuery,
		{ data: domainData, loading: domainLoading, error: domainError }
	] = useLazyQuery(FetchInstanceByDomain);

	useEffect(() => {
		if(domainData?.FetchInstanceByDomain?.id) {
			dispatch(setInstancePublic(domainData.FetchInstanceByDomain));
			dispatch(setInstanceId(domainData.FetchInstanceByDomain.id));
		}
	}, [ domainData, dispatch ]);

	function fetchWithDomain() {
		console.log("fetching instance by domain");
		fetchWithDomainQuery().catch(err => {
			console.error("Failed during instance domain fetch", err);
		}).finally(() => {
			setWasLoaded(true);
		});
	}

	useEffect(() => {
		const instanceIdFromQuery = params.get("iid") || "";
		if(instanceIdFromQuery) {
			dispatch(setInstanceId(instanceIdFromQuery));
		}

		if(!instanceIdFromQuery && !instanceId) {
			fetchWithDomain();
		}
	}, [ dispatch, instanceId, params ]);

	useEffect(() => {
		execute({
			variables: { instanceId }
		}).finally(() => {
			setWasLoaded(true);
		});
	}, [ execute, instanceId ]);

	useEffect(() => {
		if(data) {
			console.debug(`setting instance [${data.FetchInstance.id}]`);
			dispatch(setInstanceId(data.FetchInstance.id));
			dispatch(setInstancePublic(data.FetchInstance));
		}
	}, [ data, dispatch ]);

	return (
		<SplashScreen visible={!wasLoaded || loading} delayRender>
			{children}
		</SplashScreen>
	);
};