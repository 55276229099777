import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Speaker: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m383.27 174.81c12.578 0 22.809-10.23 22.809-22.809s-10.23-22.816-22.809-22.816c-12.586 0-22.816 10.23-22.816 22.816 0 12.578 10.23 22.809 22.816 22.809z" />
		<path
			d="m816.73 129.2c-12.586 0-22.816 10.23-22.816 22.816 0 12.578 10.23 22.809 22.816 22.809 12.578 0 22.809-10.23 22.809-22.809 0-12.586-10.23-22.816-22.809-22.816z" />
		<path
			d="m816.73 953.66c-12.586 0-22.816 10.23-22.816 22.809s10.23 22.809 22.816 22.809c12.578 0 22.809-10.23 22.809-22.809 0-12.582-10.23-22.809-22.809-22.809z" />
		<path
			d="m383.27 953.66c-12.586 0-22.816 10.23-22.816 22.809s10.23 22.809 22.816 22.809c12.578 0 22.809-10.23 22.809-22.809-0.003906-12.582-10.23-22.809-22.809-22.809z" />
		<path
			d="m600 528.98c-114.74 0-208.09 93.344-208.09 208.09 0 114.74 93.352 208.09 208.09 208.09s208.09-93.344 208.09-208.09-93.344-208.09-208.09-208.09zm0 363.68c-85.785 0-155.59-69.801-155.59-155.59s69.801-155.59 155.59-155.59 155.59 69.801 155.59 155.59c0 85.785-69.793 155.59-155.59 155.59z" />
		<path
			d="m600 667.26c-38.496 0-69.816 31.32-69.816 69.816s31.32 69.816 69.816 69.816c38.488 0 69.809-31.32 69.809-69.816 0-38.5-31.32-69.816-69.809-69.816zm0 102.12c-17.82 0-32.316-14.496-32.316-32.316 0-17.82 14.496-32.316 32.316-32.316 17.812 0 32.309 14.496 32.309 32.316 0 17.82-14.496 32.316-32.309 32.316z" />
		<path
			d="m600 473.55c82.238 0 149.14-66.906 149.14-149.14s-66.906-149.14-149.14-149.14-149.14 66.906-149.14 149.14c0 82.234 66.914 149.14 149.14 149.14zm0-245.79c53.289 0 96.645 43.352 96.645 96.645s-43.348 96.645-96.645 96.645-96.645-43.352-96.645-96.645c0-53.297 43.355-96.645 96.645-96.645z" />
		<path
			d="m600 377.66c29.363 0 53.258-23.895 53.258-53.258 0-29.371-23.895-53.266-53.258-53.266-29.371 0-53.266 23.895-53.266 53.266 0.007813 29.367 23.895 53.258 53.266 53.258zm0-69.016c8.6836 0 15.758 7.0742 15.758 15.766 0 8.6914-7.0664 15.75-15.758 15.75s-15.766-7.0664-15.766-15.758c0-8.6953 7.0742-15.758 15.766-15.758z" />
		<path
			d="m834.26 39.238h-468.52c-51.008 0-92.504 41.496-92.504 92.504v864.98c0 41.023 26.863 75.848 63.906 87.93v26.641c0 27.277 22.184 49.461 49.461 49.461h26.594c27.277 0 49.461-22.184 49.461-49.461v-22.066h274.68v22.066c0 27.277 22.184 49.461 49.461 49.461h26.602c27.27 0 49.453-22.184 49.453-49.461v-26.641c37.043-12.082 63.906-46.906 63.906-87.93l0.007813-864.98c0-51.008-41.496-92.508-92.504-92.508zm-409.1 1072.1c0 6.5938-5.3711 11.961-11.961 11.961h-26.594c-6.5938 0-11.961-5.3711-11.961-11.961v-22.066h50.512l-0.003906 22.066zm388.25 11.965h-26.602c-6.5938 0-11.961-5.3711-11.961-11.961v-22.066h50.512v22.066c-0.003907 6.5898-5.3594 11.961-11.949 11.961zm60.859-126.54c0 22.059-17.949 40.004-40.004 40.004h-468.52c-22.059 0-40.004-17.949-40.004-40.004v-864.98c0-22.059 17.949-40.004 40.004-40.004h468.52c22.059 0 40.004 17.949 40.004 40.004z" />
	</g>
</svg>
			</SvgIcon>
		);
	};