import { useEffect } from "react";

export function usePageTitle(title: string) {
	useEffect(() => {
		document.title = `Rego | ${title}`;

		return () => {
			document.title = "Rego";
		};
	}, [ title ]);
}