import { Box, Grid } from "grommet";
import React, { Fragment, useMemo, useState } from "react";
import { Question, QuestionType } from "../../types";
import { Typography } from "@mui/material";
import { AccordionController } from "../../../../components";
import { FormContainer } from "react-hook-form-mui";
import { formatNumber } from "../../../../helpers";
import { useWindowDimensions } from "../../../../hooks";
import { useQuestion, useWasteScenario } from "../../hooks";
import { QuestionResponseElement } from "./QuestionResponseElement";

interface QuestionElementProps<T extends QuestionType> {
	isExpanded?: boolean;
	question: Question<T>;
	displayWeightImpact?: boolean;
}

export const QuestionElement = <T extends QuestionType>(props: QuestionElementProps<T>) => {
	const [ isExpanded, setIsExpanded ] = useState(props.isExpanded ?? false);

	const {
		value, onChange
	} = useQuestion(props.question);

	const scenario = useWasteScenario();

	const weightImpact = useMemo(() => {
		if(props.question.weightImpactKey) {
			const value = typeof scenario[ props.question.weightImpactKey ] === "object"
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				///@ts-expect-error
				? scenario[ props.question.weightImpactKey ].impact
				: scenario[ props.question.weightImpactKey ];

			return scenario.toHousehold(value) || 0;
		}

		return 0;
	}, [ props.question, scenario ]);

	const { size } = useWindowDimensions();

	return (
		<FormContainer>
			<AccordionController
				summaryProps={{ sx: { padding: "6px" } }}
				name={props.question.id}
				summary={(
					<Fragment>
						<Grid columns={size === "small" ? [ "auto", "flex" ] : [ "auto", "flex", "auto" ]} gap="medium" fill="horizontal">
							<Box justify="center" width="30px" id="icon-container">
								{props.question.icon}
							</Box>
							<Box justify="center">
								<Typography>
									{props.question.text}
								</Typography>
							</Box>
							{(size !== "small" && props.displayWeightImpact) && (
								<Box justify="center">
									<Box width="110px">
										{weightImpact >= 0
											? (
												<Typography color="accent-1" textAlign="end">
													+ {formatNumber(weightImpact, 2)} lb(s)
												</Typography>
											)
											: (
												<Typography color="accent-1" textAlign="end">
													- {formatNumber(Math.abs(weightImpact), 2)} lb(s)
												</Typography>
											)}
									</Box>
								</Box>
							)}
						</Grid>
						{(size === "small" && props.displayWeightImpact) && (
							<Box height="20px">
								<Box
									style={{
										position: "absolute",
										right: "6px",
										bottom: "6px"
									}}
								>
									<Box width="110px">
										{weightImpact >= 0
											? (
												<Typography color="accent-1" textAlign="end">
													+ {formatNumber(weightImpact, 2)} lb(s)
												</Typography>
											)
											: (
												<Typography color="accent-1" textAlign="end">
													- {formatNumber(Math.abs(weightImpact), 2)} lb(s)
												</Typography>
											)}
									</Box>
								</Box>
							</Box>
						)}
					</Fragment>
				)}
				details={(
					<QuestionResponseElement
						question={props.question}
						value={value}
						onChange={onChange} />
				)}
				onChange={() => {
					setIsExpanded((prev) => !prev);
				}}
				isExpanded={isExpanded} />
		</FormContainer>
	);
};
