import React from "react";
import { IconButton } from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { useClipboard } from "../../../../../hooks/useClipboard";
import { BasicListItem } from "../../../../../components";
import { useHeaderScopedOrder } from "../../../../../graphql";

export const OrderIdListItem: React.FC<{
	orderId: string;
}> = ({ orderId }) => {
	const { copy } = useClipboard();
	const { loading } = useHeaderScopedOrder(orderId);

	return (
		<BasicListItem
			label="Order Id"
			loading={loading}
			value={orderId}
			action={<IconButton
				color="primary"
				onClick={() => copy(orderId)}
			>
				<CopyAll />
			</IconButton>}
		/>
	);
};