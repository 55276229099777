import React from "react";
import { ListItemSkeleton } from "../../../../../components";
import { useBuilding } from "../../hooks";
import { ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Apartment } from "@mui/icons-material";
import { useWindowDimensions } from "../../../../../hooks";
import { Box } from "grommet";
import { formatNumber } from "../../../../../helpers";

export const PropertyListItem: React.FC<{
	buildingId: string;
	hideSecondary?: boolean;
	onClick?: () => void;
}> = ({ buildingId, hideSecondary, onClick }) => {
	const { size } = useWindowDimensions();
	const { building } = useBuilding(buildingId);

	return (
		<ListItemButton divider onClick={onClick}>
			{size !== "small" && (
				<ListItemIcon>
					<Apartment />
				</ListItemIcon>
			)}
			<ListItemText>
				<ListItemSkeleton
					loading={!building}
					content={(
						<Typography fontWeight="bold">
							{building?.name}
						</Typography>
					)} />
				{!hideSecondary && (
					<ListItemSkeleton
						loading={!building}
						content={(
							<Typography variant="body2">
								{building?.containers.length} container(s) tracked
							</Typography>
						)}
					/>
				)}
			</ListItemText>
			<ListItemSecondaryAction>
				{!!building?.monthlyCollectionWeight && (
					<Box align="end">
						<Typography fontWeight="bold">
							{formatNumber(building.monthlyCollectionWeight, true)}
						</Typography>
						<Typography variant="caption">
							lb(s) / month
						</Typography>
					</Box>
				)}
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
