import { gql } from "../../../__generated__";

export const CreateOrderRequest = gql(/* GraphQL */ `
	mutation CreateOrderRequest(
		$unit: String
		$room: String
		$isBulkPickup: Boolean
		$media: [CreateMediaDTO!]!
		$userId: String!
		$refererId: String!
		$productCount: Int!
		$requestedDate: DateTime!
		$location: PickupLocation!
		$promotionCode: String
	) {
		CreateOrderRequest(
			unit: $unit
			room: $room
			media: $media
			userId: $userId
			refererId: $refererId
			productCount: $productCount
			isBulkPickup: $isBulkPickup
			requestedDate: $requestedDate
			location: $location
			promotionCode: $promotionCode
		) {
			id
		}
	}
`);