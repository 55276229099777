import { gql } from "../../../__generated__";

export const UpdatePricingPolicy = gql(/* GraphQL */ `
	mutation UpdatePricingPolicy(
		$policyId: String!
		$name: String
		$type: PricingPolicyType
		$margin: Float
		$pricing: [ ItemPricingModelDTOUnion! ]
	) {
		UpdatePricingPolicy(
			policyId: $policyId
			name: $name
			type: $type
			margin: $margin
			pricing: $pricing
		) {
			id
		}
	}
`);