import { gql } from "@apollo/client";


export const Hauler_UsersFragment = gql`
	fragment Hauler_UsersFragment on Hauler {
		users {
			id
			role
			user {
				id
				firstName
				lastName
				primaryEmailAddress
			}
		}
	}
`;
