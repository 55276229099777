import { SvgIcon, SvgIconProps } from "@mui/material";

	export const ArmChair: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m975 468.75c-6.3945 0-12.617 0.61719-18.75 1.5v-282.75c0-51.695-42.055-93.75-93.75-93.75h-525c-51.695 0-93.75 42.055-93.75 93.75v282.75c-6.1484-0.88281-12.375-1.5-18.75-1.5-72.375 0-131.25 58.875-131.25 131.25 0 50.867 29.645 96.918 75 118.46v331.54c0 10.367 8.3984 18.75 18.75 18.75h37.5c-10.352 0-18.75 8.3828-18.75 18.75s8.3984 18.75 18.75 18.75h75c10.352 0 18.75-8.3828 18.75-18.75s-8.3984-18.75-18.75-18.75h37.5c10.352 0 18.75-8.3828 18.75-18.75v-18.75h487.5v18.75c0 10.367 8.3828 18.75 18.75 18.75h37.5c-10.367 0-18.75 8.3828-18.75 18.75s8.3828 18.75 18.75 18.75h75c10.367 0 18.75-8.3828 18.75-18.75s-8.3828-18.75-18.75-18.75h37.5c10.367 0 18.75-8.3828 18.75-18.75v-331.54c45.355-21.543 75-67.594 75-118.46 0-72.375-58.895-131.25-131.25-131.25zm-693.75-281.25c0-31.012 25.238-56.25 56.25-56.25h525c31.012 0 56.25 25.238 56.25 56.25v294.09c-44.25 21.113-75 66.188-75 118.41v93.75h-487.5v-93.75c0-52.219-30.73-97.273-75-118.41zm75 543.75h487.5v187.5h-487.5zm-37.5 300h-112.5v-325.29c0-7.9492-5.0078-15.02-12.488-17.664-37.387-13.254-62.512-48.75-62.512-88.293 0-51.695 42.055-93.75 93.75-93.75s93.75 42.055 93.75 93.75zm37.5-37.5v-37.5h487.5v37.5zm649.99-305.46c-7.4805 2.6641-12.488 9.7305-12.488 17.664v325.29h-112.5v-431.25c0-51.695 42.055-93.75 93.75-93.75s93.75 42.055 93.75 93.75c0 39.543-25.125 75.039-62.512 88.293z"/>
  <path d="m436.74 313.26c3.6562 3.6562 8.457 5.4922 13.258 5.4922s9.6016-1.8359 13.258-5.4922l5.4922-5.4961 5.4922 5.4922c3.6562 3.6602 8.457 5.4961 13.258 5.4961s9.6016-1.8359 13.258-5.4922c7.332-7.332 7.332-19.18 0-26.512l-5.4961-5.4961 5.4922-5.4922c7.332-7.332 7.332-19.18 0-26.512-7.332-7.332-19.18-7.332-26.512 0l-5.4922 5.4922-5.4922-5.4922c-7.332-7.332-19.18-7.332-26.512 0-7.332 7.332-7.332 19.18 0 26.512l5.4922 5.4922-5.4922 5.4922c-7.332 7.332-7.332 19.184-0.003906 26.516z"/>
  <path d="m699.24 313.26c3.6562 3.6562 8.457 5.4922 13.258 5.4922s9.6016-1.8359 13.258-5.4922l5.4922-5.4961 5.4922 5.4922c3.6562 3.6602 8.457 5.4961 13.258 5.4961s9.6016-1.8359 13.258-5.4922c7.332-7.332 7.332-19.18 0-26.512l-5.4961-5.4961 5.4922-5.4922c7.332-7.332 7.332-19.18 0-26.512-7.332-7.332-19.18-7.332-26.512 0l-5.4922 5.4922-5.4922-5.4922c-7.332-7.332-19.18-7.332-26.512 0-7.332 7.332-7.332 19.18 0 26.512l5.4922 5.4922-5.4922 5.4922c-7.332 7.332-7.332 19.184-0.003906 26.516z"/>
  <path d="m436.74 500.76c3.6562 3.6562 8.457 5.4922 13.258 5.4922s9.6016-1.8359 13.258-5.4922l5.4922-5.4961 5.4922 5.4922c3.6562 3.6602 8.457 5.4961 13.258 5.4961s9.6016-1.8359 13.258-5.4922c7.332-7.332 7.332-19.18 0-26.512l-5.4961-5.4961 5.4922-5.4922c7.332-7.332 7.332-19.18 0-26.512-7.332-7.332-19.18-7.332-26.512 0l-5.4922 5.4922-5.4922-5.4922c-7.332-7.332-19.18-7.332-26.512 0-7.332 7.332-7.332 19.18 0 26.512l5.4922 5.4922-5.4922 5.4922c-7.332 7.332-7.332 19.184-0.003906 26.516z"/>
  <path d="m699.24 500.76c3.6562 3.6562 8.457 5.4922 13.258 5.4922s9.6016-1.8359 13.258-5.4922l5.4922-5.4961 5.4922 5.4922c3.6562 3.6602 8.457 5.4961 13.258 5.4961s9.6016-1.8359 13.258-5.4922c7.332-7.332 7.332-19.18 0-26.512l-5.4961-5.4961 5.4922-5.4922c7.332-7.332 7.332-19.18 0-26.512-7.332-7.332-19.18-7.332-26.512 0l-5.4922 5.4922-5.4922-5.4922c-7.332-7.332-19.18-7.332-26.512 0-7.332 7.332-7.332 19.18 0 26.512l5.4922 5.4922-5.4922 5.4922c-7.332 7.332-7.332 19.184-0.003906 26.516z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};