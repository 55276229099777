import { Skeleton, SkeletonTypeMap } from "@mui/material";
import { Box, FormField, FormFieldExtendedProps, Spinner, TextInput, TextInputProps } from "grommet";
import { Fragment, ReactNode } from "react";

export const WrapSkeleton: React.FC<{
	isLoading: boolean,
	children: ReactNode; variant?: SkeletonTypeMap[ "props" ][ "variant" ];
	fillHeight?: boolean;
	maxWidth?: string;
	disableMargin?: boolean;
}> = (props) => {
	return (props.isLoading)
		? (
			<Box
				flex
				style={{ maxWidth: props.maxWidth }}
				margin={props.disableMargin ? undefined : "xsmall"}>
				<Skeleton width="100%" animation="wave" variant={props.variant ?? "rounded"} height={props.fillHeight ? "100%" : undefined} />
			</Box>
		)
		: (
			<Fragment>
				{props.children}
			</Fragment>
		);
};

interface WrapSkeletonFormFieldProps extends FormFieldExtendedProps {
	value?: string;
	label: string;
	isLoading: boolean;
	inputProps?: TextInputProps;
	specialFieldType?: "COPY" | "ACTION";
	icon?: JSX.Element;
	onAction?(): void;
}

export const WrapSkeletonFormField: React.FC<WrapSkeletonFormFieldProps> = (props) => {
	return (
		<FormField
			{...props}
		>
			{props.isLoading && (
				<Box pad="xsmall">
					<Skeleton style={{ minHeight: "30px" }} />
				</Box>
			)}
			{!props.isLoading && (
				<TextInput
					{...props.inputProps}
					name={props.name ?? props.inputProps?.name}
					value={props.value}
				/>
			)}
		</FormField>
	);
};

interface SkeletonFormFieldProps extends FormFieldExtendedProps {
	isLoading: boolean;
}

export const SkeletonFormField: React.FC<SkeletonFormFieldProps> = (props) => {
	return (
		<FormField
			{...props}
		>
			{props.isLoading
				? (
					<Box pad="small">
						<Skeleton width="100%" animation="wave" variant={"rounded"} />
					</Box>
				)
				: props.children
			}
		</FormField>
	);
};

export const LoadingTextInput: React.FC<TextInputProps & { isLoading: boolean; }> = (props) => {
	return (
		<TextInput
			{...props}
			icon={props.isLoading ? <Spinner /> : undefined}
		/>
	);
};