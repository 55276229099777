import { Box } from "grommet";
import { DayOfWeek } from "../../../graphql/__generated__/graphql";
import { Typography } from "@mui/material";
import { formatWindow, toProperCase } from "../../../helpers";

export const StoreHoursListItem: React.FC<{
	day: DayOfWeek;
	open: boolean;
	from: string | number;
	to: string | number;
}> = ({ day, open, from, to }) => {
	return (
		<Box direction="row" justify="between">
			<Typography fontWeight="bold">
				{toProperCase(day)}
			</Typography>
			<Typography textAlign="end">
				{open ? formatWindow(from, to) : "Closed"}
			</Typography>
		</Box>
	);
};