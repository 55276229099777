import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

interface CreatePaymentMethodConfig {
	publicKey: string;
	clientSecret: string;
	isModalVisible: boolean;
}

export interface PaymentState {
	createPaymentMethodConfig: CreatePaymentMethodConfig | null;
}

const initialState: PaymentState = {
	createPaymentMethodConfig: null
};

export const paymentSlice = createSlice({
	name: "payment",
	initialState,
	reducers: {
		setCreatePaymentMethodConfig(state, action: PayloadAction<CreatePaymentMethodConfig | null>) {
			state.createPaymentMethodConfig = action.payload;
		}
	}
});

export const {
	setCreatePaymentMethodConfig
} = paymentSlice.actions;

export const selectCreatePaymentMethodConfig = (state: RootState) => state.payment.createPaymentMethodConfig;

export default paymentSlice.reducer;