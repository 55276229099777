import { gql } from "../../../__generated__";

export const FindPricingPolicies = gql(/* GraphQL */ `
	query FindPricingPolicies {
		FindPricingPolicies {
			...PricingPolicy_HeaderFragment
			...PricingPolicy_PricingFragment
			...PricingPolicy_PartnersFragment
		}
	}
`);