import React, { useEffect, useMemo } from "react";
import { Route, Routes } from "react-router";
import { useAdminIncorrectLoginInterrupt, useLoginGuard } from "../../../auth";
import { InstanceFeature, InstanceType } from "../../../graphql/__generated__/graphql";
import { useInstance, usePageTitle } from "../../../hooks";
import { DonationCenterDashboard, OrderDetailsView, PurchasesView } from "../views";
import { OrdersListItem, PurchasesListItem, SidebarRouteItem, SidebarRouter } from "../../../components";
import { DashboardListItemWithTasks } from "../components";
import { PageNotAvailableView } from "../../../views";
import { isNotNull } from "../../../helpers";
import { useFeature } from "../../instance/hooks/useFeature";
import { DonationCenterRouter } from "./DonationCenter";
import { ResidenceRouter } from "./Residence";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../store";
import { push } from "redux-first-history";
import { ManagementCompanyRouter } from "./ManagementCompany";
import { UniversityRouter } from "./University";

export const NotAvailableRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="*" element={<PageNotAvailableView />} />
		</Routes>
	);
};

export const GlobalAdminRouter: React.FC = () => {
	return (
		<NotAvailableRouter />
	);
};

export const DisposalCenterRouter: React.FC = () => {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);

	return new SidebarRouter(
		[
			new SidebarRouteItem(
				"/",
				<DonationCenterDashboard />,
				<DashboardListItemWithTasks
					key="dashboard"
				/>,
				true
			),
			new SidebarRouteItem(
				"/orders",
				<OrderDetailsView />,
				<OrdersListItem
					key="orders"
					absolutePath="/dashboard/orders"
				/>,
				true
			),
			isSalesEnabled ? new SidebarRouteItem(
				"/purchases",
				<PurchasesView />,
				<PurchasesListItem
					key="purchases"
					absolutePath="/dashboard/purchases"
				/>,
				true
			) : null
		].filter(isNotNull),
		"Disposal Center",
		[],
		[],
		true
	).toRouter();
};

export const DashboardBaseRouter: React.FC = () => {
	useAdminIncorrectLoginInterrupt();

	/**
	 * Need to make instance features handle more feature toggles
	 * 
	 * For now, just going to hardcode
	 */
	const instancePublic = useAppSelector(selectInstancePublic);
	const isCoxEnterprises = useMemo(() => {
		return instancePublic?.name === "Cox Enterprises";
	}, [ instancePublic ]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if(isCoxEnterprises) {
			dispatch(push("/admin/dashboard"));
		}
	}, [ isCoxEnterprises ]);

	useLoginGuard();
	usePageTitle("Dashboard");
	const { instance } = useInstance();

	const router = useMemo(() => {
		if(!instance) return (
			<NotAvailableRouter />
		);

		switch(instance.type) {
			case InstanceType.Admin: {
				return (
					<NotAvailableRouter />
				);
			}
			case InstanceType.DisposalCenter: {
				return (
					<DisposalCenterRouter />
				);
			}
			case InstanceType.DonationCenter: {
				return (
					<DonationCenterRouter />
				);
			}
			case InstanceType.University: {
				return (
					<UniversityRouter />
				);
			}
			case InstanceType.Residence: {
				return (
					<ResidenceRouter />
				);
			}
			case InstanceType.Hauler: {
				// return {
				// 	controller: <HaulerController />,
				// 	routes: disposalCenterRoutes
				// };
				return new SidebarRouter(
					[
						new SidebarRouteItem(
							"/",
							<DonationCenterDashboard />,
							<DashboardListItemWithTasks />,
							true
						),
						new SidebarRouteItem(
							"/orders",
							<DonationCenterDashboard />,
							<OrdersListItem absolutePath="/dashboard/orders" />,
							true
						)
					],
					"Hauler",
					[],
					[],
					true
				).toRouter();
			}
			case InstanceType.ManagementCompany: {
				return (
					<ManagementCompanyRouter />
				);
			}
			case InstanceType.Municipality: {
				// return {
				// 	controller: <MunicipalityController />,
				// 	routes: disposalCenterRoutes
				// };
				return new SidebarRouter(
					[
						new SidebarRouteItem(
							"/",
							<DonationCenterDashboard />,
							<DashboardListItemWithTasks />,
							true
						),
						new SidebarRouteItem(
							"/orders",
							<DonationCenterDashboard />,
							<OrdersListItem absolutePath="/dashboard/orders" />,
							true
						)
					],
					"Disposal Center",
					[],
					[],
					true
				).toRouter();
			}
			default: {
				return null;
			}
		}
	}, [ instance ]);

	return router;
};