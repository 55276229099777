import { gql } from "@apollo/client";

export const Type_DefaultFragment = gql`
	fragment Type_DefaultFragment on ItemType {
		id
		name
		category {
			id
			name
			tags
			iconUrl
		}
		canDisassemble
		assumptions {
			weightLowPounds
			weightHighPounds
		}
		tags
		keywords
		iconUrl
		index
	}
`;