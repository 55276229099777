import { gql } from "@apollo/client";


export const Quote_ChangeFragment = gql`
	fragment Quote_ChangeFragment on Quote {
		changes {
			id
			amount
			reason
		}
		amountOriginal
		amountAdditional
	}
`;
