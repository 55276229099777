import { gql } from "../__generated__";

export const DashboardQuery = gql(/* GraphQL */ `
	query DashboardQuery(
		$userId: String!
		$submittedAt: iDateFilters
	) {
		FindActionItems {
			name
			description
			completeUrl
		}
		FindOrders(
			user:{ id: { equals: $userId }}
			submittedAt: $submittedAt
		) {
			id
			createdAt
			submittedAt
			completedAt
			products {
				id
				type {
					id
					name
				}
				size {
					id
					name
				}
			}
		}
	}
`);

export const FetchDashboardTasks = gql(/* GraphQL */ `
	query FetchDashboardTasks(
		$userId: String!
		$instanceId: String!
	) {
		FindUserOrders(
			userId: $userId
			instance: { id: { equals: $instanceId } }
			schedulePending: { equals: true }
		) {
			id
			scheduled
			scheduledAt
			submittedAt
			schedulePending
			referer { id, name }
		}
	}
`);

export const FetchDashboardData = gql(/* GraphQL */ `
	query FetchDashboardData(
		$userId: String!
		$instanceId: String!
	) {
		FindUserOrders(
			userId: $userId
			instance: { id: { equals: $instanceId } }
			schedulePending: { equals: true }
		) {
			id
			scheduled
			scheduledAt
			submittedAt
			schedulePending
			referer { id, name }
		}
	}
`);