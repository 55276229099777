import { useMutation } from "@apollo/client";
import { Box } from "grommet";
import { CreateHaulerQuote, FetchOrderForHauler, FindHaulerPendingQuoteRequests, FindHaulerPendingQuotes, UpdateHaulerQuote } from "../../../../graphql";
import { InputAdornment, Typography, useTheme } from "@mui/material";
import { DialogWithClose } from "../../../../components";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DayOfWeek, PickupDateOptions } from "../../../../graphql/__generated__/graphql";
import moment, { Moment } from "moment";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { LocalizationProvider } from "../../../../provider";
import { useHauler } from "../../hooks";
import { Circle } from "@mui/icons-material";

interface UpsertHaulerQuoteDialogProps {
	onClose: () => void;
	requestId: string;
	quoteId?: string;
	amount?: number;
	pickupOptions: PickupDateOptions[];
}

export const UpsertHaulerQuoteDialog: React.FC<UpsertHaulerQuoteDialogProps> = (props) => {
	const snack = useSnackbar();
	const isUpdate = useMemo(() => !!props.amount, [ props.amount ]);

	const { hauler } = useHauler();

	const [
		createQuote, { loading: isCreatingQuote }
	] = useMutation(CreateHaulerQuote, {
		refetchQueries: [
			FindHaulerPendingQuoteRequests,
			FindHaulerPendingQuotes,
			FetchOrderForHauler
		]
	});

	const [
		updateQuote, { loading: isUpdatingQuote }
	] = useMutation(UpdateHaulerQuote, {
		refetchQueries: [
			FindHaulerPendingQuoteRequests,
			FindHaulerPendingQuotes,
			FetchOrderForHauler
		]
	});

	const formContext = useForm({
		defaultValues: { amount: props.amount, reason: "" }
	});

	const formValues = formContext.watch();

	const [ { active, excluded }, setCalendarState ] = useState<{
		active: Moment[];
		excluded: Moment[];
	}>({
		active: [],
		excluded: []
	});

	function handleCreateQuote(
		requestId: string,
		amount: number,
		pickupOptions: PickupDateOptions[]
	) {
		createQuote({
			variables: {
				requestId,
				amount,
				pickupOptions
			}
		}).then(() => {
			props.onClose();
		}).catch(err => {
			console.error("Failed to create quote: ", err);
			snack.enqueueSnackbar("We ran into an issue processing your request. Please try again,", {
				variant: "error"
			});
		});
	}

	function handleUpdateQuote(
		quoteId: string,
		amount: number,
		reason: string,
		pickupOptions: PickupDateOptions[]
	) {
		updateQuote({
			variables: {
				quoteId,
				amount,
				reason,
				pickupOptions
			}
		}).then(() => {
			props.onClose();
		}).catch(err => {
			console.error("Failed to update quote: ", err);
			snack.enqueueSnackbar("We ran into an issue processing your request. Please try again,", {
				variant: "error"
			});
		});
	}

	function handleSubmit() {
		if(active.length === 0) {
			return snack.enqueueSnackbar("You must select at least one pickup date", {
				variant: "error"
			});
		}

		if(isUpdate && props.quoteId && formValues.amount) {
			return handleUpdateQuote(
				props.quoteId,
				Number(formValues.amount),
				formValues.reason,
				active.map(date => ({
					date: date.format("YYYY-MM-DD"),
					windows: (hauler?.permittedWindows || []).map(window => ({
						from: window.from,
						to: window.to,
						label: window.label
					}))
				}))
			);
		}

		if(!isUpdate && formValues.amount) {
			return handleCreateQuote(
				props.requestId,
				Number(formValues.amount),
				active.map(date => ({
					date: date.format("YYYY-MM-DD"),
					windows: (hauler?.permittedWindows || []).map(window => ({
						from: window.from,
						to: window.to,
						label: window.label
					}))
				}))
			);
		}
	}

	useEffect(() => {
		if(props.pickupOptions.length > 0) {
			return setCalendarState({
				active: props.pickupOptions.map(d => moment(d.date)),
				excluded: []
			});
		}
		if(!hauler) return;

		const permittedDates = hauler.permittedDays || [];

		const cursor = moment().add(1, "day");
		const included: Moment[] = [];
		while(cursor.isSameOrBefore(moment().add(14, "days"), "day")) {
			if(permittedDates.includes(cursor.format("dddd").toUpperCase() as DayOfWeek)) {
				included.push(moment(cursor));
			}
			cursor.add(1, "day");
		}

		setCalendarState({
			active: included,
			excluded: []
		});
	}, [ hauler, props.pickupOptions ]);

	function handleUpdateDate(date: Moment | null) {
		if(!date) return;
		if(active.some(d => d.isSame(date, "day"))) {
			return setCalendarState({
				active: [
					...active.filter(d => !d.isSame(date, "day"))
				],
				excluded: [
					...excluded.filter(d => !d.isSame(date, "day")),
					date
				]
			});
		}
		if(excluded.some(d => d.isSame(date, "day"))) {
			return setCalendarState({
				active: [
					...active.filter(d => !d.isSame(date, "day")),
					date
				],
				excluded: [
					...excluded.filter(d => !d.isSame(date, "day"))
				]
			});
		}

		return setCalendarState({
			active: [
				...active,
				date
			],
			excluded: [
				...excluded
			]
		});
	}

	const shouldDisable = useCallback((day: Moment) => {
		if(day.isSameOrBefore(moment(), "day")) return true;
		if(day.isSameOrAfter(moment().add(14, "days"), "day")) return true;

		const permittedDays = hauler?.permittedDays;
		if(permittedDays) {
			if(!permittedDays.includes(day.format("dddd").toUpperCase() as DayOfWeek)) return true;
		}


		return false;
	}, [ hauler ]);

	return (
		<FormContainer formContext={formContext} onSuccess={handleSubmit}>
			<DialogWithClose
				title={isUpdate ? "Update Quote" : "Create Quote"}
				onClose={props.onClose}
				content={(
					<Box gap="medium" flex>
						<TextFieldElement
							required
							name="amount"
							label="Amount"
							validation={{
								min: { value: 1, message: "Amount must be greater than $0" },
								validate: (value) => {
									return isNaN(value) ? "Amount must be a valid number" : undefined;
								}
							}}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>
							}}
						/>
						{isUpdate && (
							<TextFieldElement
								required
								name="reason"
								label="Reason"
							/>
						)}
						<LocalizationProvider>
							<DateCalendar
								disablePast
								disabled={isCreatingQuote || isUpdatingQuote}
								value={null}
								showDaysOutsideCurrentMonth
								onChange={handleUpdateDate}
								shouldDisableDate={shouldDisable}
								slots={{
									day: CustomCalendarDay as any
								}}
								slotProps={{
									day: {
										active,
										excluded
									} as any
								}}
							/>
						</LocalizationProvider>
						<Box gap="small" flex>
							<Typography>
								Click the dates to select the available pickup dates for this quote. Click again to toggle between available and unavailable.
							</Typography>
							<Box direction="row" gap="small">
								<Circle
									color="success"
								/>
								<Typography>
									=
								</Typography>
								<Typography>
									Available for Scheduling
								</Typography>
							</Box>
							<Box direction="row" gap="small">
								<Circle
									color="error"
								/>
								<Typography>
									=
								</Typography>
								<Typography>
									Unavailable for Scheduling
								</Typography>
							</Box>
						</Box>
					</Box>
				)}
				actions={(
					<Box align="end">
						<LoadingButton
							color="primary"
							variant="contained"
							loading={isCreatingQuote || isUpdatingQuote}
							onClick={formContext.handleSubmit(handleSubmit)}>
							Submit
						</LoadingButton>
					</Box>
				)} />
		</FormContainer>
	);
};


function CustomCalendarDay(props: PickersDayProps<Moment> & {
	active: Moment[];
	excluded: Moment[];
}) {
	const mui = useTheme();
	const { day, disabled, active, excluded } = props;

	return (
		<PickersDay
			{...props}
			style={{
				backgroundColor: (active.some(d => d.isSame(day, "day")) && !disabled)
					? mui.palette.success.main
					: (excluded.some(d => d.isSame(day, "day")) && !disabled)
						? mui.palette.error.main
						: undefined,
			}}
		/>
	);
}