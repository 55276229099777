import { gql } from "../../../__generated__";

export const FindHaulerQuoteRequests = gql( /* GraphQL */ `
	query FindHaulerQuoteRequests(
		$orderId: String!, 
		$haulerId: String!
	) {
		FindHaulerQuoteRequests(orderId: $orderId, haulerId: $haulerId) {
			id
			createdAt
		}
	}
`);