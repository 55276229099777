import { useMutation, useQuery } from "@apollo/client";
import { FetchWasteCalculatorSubmission, UpsertWasteCalculatorResponse, UpsertWasteCalculatorSubmission } from "../../../graphql/documents/calculator";
import { WasteCalculatorResponseInput, WasteCalculatorSubmissionDto } from "../../../graphql/__generated__/graphql";
import { useEffect, useState } from "react";
import { selectInstancePublic, useAppSelector } from "../../../store";
import { useSnackbar } from "notistack";

export function useSubmission() {
	const snack = useSnackbar();
	const instance = useAppSelector(selectInstancePublic);
	const [ isUpdatingResponseId, setIsUpdatingResponseId ] = useState("");

	const { data: submissionData, loading: isLoadingSubmission } = useQuery(FetchWasteCalculatorSubmission, {
		skip: !localStorage.getItem("submissionId"),
		variables: {
			submissionId: localStorage.getItem("submissionId") || ""
		}
	});

	const [
		upsertSubmission,
		{ data: submissionResponse, loading: isSubmitting }
	] = useMutation(UpsertWasteCalculatorSubmission, {
		refetchQueries: [ FetchWasteCalculatorSubmission ]
	});

	useEffect(() => {
		if(submissionResponse) {
			localStorage.setItem("submissionId", submissionResponse.UpsertWasteCalculatorSubmission.id);
		}
	}, [ submissionResponse ]);

	async function handleSubmission(submission: WasteCalculatorSubmissionDto) {
		if(!instance) return;
		const submissionId = localStorage.getItem("submissionId") || "";

		return upsertSubmission({
			variables: {
				instanceId: instance.id,
				submission: {
					...submission,
					submissionId
				}
			}
		}).catch(err => {
			console.error("Failed to submit waste calculator", err);
			snack.enqueueSnackbar("We ran into an issue saving your information.", {
				variant: "error"
			});
		});
	}

	const [
		upsertResponse,
		{ loading: isUpdatingResponse }
	] = useMutation(UpsertWasteCalculatorResponse, {
		refetchQueries: [ FetchWasteCalculatorSubmission ]
	});

	async function handleResponse(response: WasteCalculatorResponseInput) {
		const submissionId = localStorage.getItem("submissionId") || "";
		if(!submissionId) return;

		setIsUpdatingResponseId(response.id);
		return upsertResponse({
			variables: {
				submissionId,
				response
			}
		}).catch(err => {
			console.error("Failed to submit waste calculator response", err);
			snack.enqueueSnackbar("We ran into an issue saving your information.", {
				variant: "error"
			});
		}).finally(() => {
			setIsUpdatingResponseId("");
		});
	}

	return {
		isSubmitting,
		isUpdatingResponse,
		isLoadingSubmission,
		isUpdatingResponseId,
		isLoading: isLoadingSubmission || isSubmitting || isUpdatingResponse,
		updateResponse: handleResponse,
		updateSubmission: handleSubmission,
		submission: submissionData?.FetchWasteCalculatorSubmission || null,
	};
}