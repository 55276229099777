import { gql } from "../../../__generated__";

export const ConvertOrderRequest = gql(/* GraphQL */ `
	mutation ConvertOrderRequest(
		$requestId: String!
		$products: [OrderRequestProductDTO!]!
	) {
		ConvertOrderRequest(
			requestId: $requestId
			products: $products
		) {
			id
		}
	}
`);