import { CreditCard } from "@mui/icons-material";
import { Box, Grid } from "grommet";
import { FetchOrderUnified, UpdateOrderPaymentMethod, UserPaymentMethodGql } from "../../../../graphql";
import { PaymentMethodsList, usePaymentMethod } from "../../../../components";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { CreatePaymentMethodButton } from "../../../payments";
import { Typography } from "@mui/material";

interface PaymentMethodAccordionSummaryProps {
	paymentMethod?: UserPaymentMethodGql;
}

export const PaymentMethodAccordionSummary: React.FC<PaymentMethodAccordionSummaryProps> = (props) => {
	const { formatted } = usePaymentMethod(props.paymentMethod);

	return (
		<Grid columns={[ "auto", "small", "flex" ]} gap="small">
			<Box align="center" justify="center">
				<CreditCard />
			</Box>
			<Box justify="center">
				<Typography fontWeight="bold">
					Payment Method
				</Typography>
			</Box>
			<Box justify="center">
				{formatted && (
					<Typography>
						{formatted}
					</Typography>
				)}
				{!formatted && (
					<Typography color="error">
						Not Selected
					</Typography>
				)}
			</Box>
		</Grid>
	);
};

interface PaymentMethodAccordionDetailsProps {
	orderId: string;
	paymentMethodId: string;
	onPaymentMethodSelected: (paymentMethodId: string) => void;
}

export const PaymentMethodAccordionDetails: React.FC<PaymentMethodAccordionDetailsProps> = (props) => {
	const [ isSelecting, setIsSelecting ] = useState("");

	const [
		updatePaymentMethod,
		{ loading: updatePaymentMethodLoading }
	] = useMutation(UpdateOrderPaymentMethod, {
		refetchQueries: [ FetchOrderUnified ]
	});

	function handleUpdatePaymentMethod(paymentMethodId: string): void {
		setIsSelecting(paymentMethodId);
		updatePaymentMethod({
			variables: {
				orderId: props.orderId,
				paymentMethodId
			}
		}).then(() => {
			props.onPaymentMethodSelected(paymentMethodId);
		}).finally(() => {
			setIsSelecting("");
		});
	}

	return (
		<Box margin="small" gap="medium">
			<Typography>
				Select a payment method to use for this order or add a new one
			</Typography>
			<PaymentMethodsList
				showCheckboxSelection
				selectedId={props.paymentMethodId}
				isSelectingId={isSelecting}
				onSelect={handleUpdatePaymentMethod}
			/>
			<Box align="end">
				<CreatePaymentMethodButton
					startIcon={<CreditCard />}
					color="primary"
					variant="contained"
					disabled={updatePaymentMethodLoading}
					label="Add Payment Method"
				/>
			</Box>
		</Box>
	);
};