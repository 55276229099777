import { useState } from "react";
import { SelectContactDialog } from "./SelectContactDialog";
import { ContactFormDialog } from "./ContactFormDialog";
import { ContactType } from "../../../../graphql/__generated__/graphql";

interface ContactFormControllerProps {
	onClose(): void;
	type: ContactType;
	kind: "CREATE" | "SELECT";
}

export const ContactFormController: React.FC<ContactFormControllerProps> = (props) => {
	const [ kind, setKind ] = useState<"CREATE" | "SELECT">(props.kind);

	switch(kind) {
		case "CREATE": {
			return (
				<ContactFormDialog
					type={props.type}
					onClose={props.onClose}
				/>
			);
		}
		case "SELECT": {
			return (
				<SelectContactDialog
					type={props.type}
					onClose={props.onClose}
					switchToCreate={() => {
						setKind("CREATE");
					}}
				/>
			);
		}
	}
};
