import React from "react";
import { Box, Heading } from "grommet";
import { UseFormReturn } from "react-hook-form";
import { useSampleData } from "../hooks";
import { OnboardingFormContext } from "../types";

import { Chart } from "react-chartjs-2";
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	TimeScale
} from "chart.js";
import { Typography } from "@mui/material";

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	TimeScale
);


export const CustomizationsPreviewContainer: React.FC<{
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ formContext }) => {
	const data = useSampleData(formContext);

	return (
		<Box gap="small" height="medium">
			<Box>
				<Heading level="3" margin="none">
					Preview
				</Heading>
				<Typography variant="body2">
					Preview how your reports will look with your selected customizations.
				</Typography>
			</Box>
			<Box align="center" justify="center">
				<Chart
					type="bar" data={data}
					options={{
						responsive: true,
						scales: {
							y: {
								type: "linear",
								title: {
									display: true,
									text: "Weight (lbs)",
									font: {
										size: 20,
										weight: "bold"
									}
								},
								display: true,
								position: "left"
							},
							y1: {
								type: "linear",
								max: 100,
								min: 0,
								title: {
									display: true,
									text: "Diversion Rate (%)",
									font: {
										size: 20,
										weight: "bold"
									}
								},
								display: true,
								position: "right",
								grid: {
									drawOnChartArea: false, // only want the grid lines for one axis to show up
								}
							}
						}
					}}
				/>
			</Box>
		</Box>
	);
};