import React, { useMemo } from "react";
import { AddressDetailsDialog, BasicListItem } from "../../../../../components";
import { IconButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useAdminScopedOrder } from "../../../../../graphql";
import { formatAddress } from "../../../../../helpers";

export const OrderAddressListItem: React.FC<{
	orderId: string;
}> = ({ orderId }) => {
	const { loading, order } = useAdminScopedOrder(orderId);

	const formattedAddress = useMemo(() => {
		const address = order?.address;
		if(!address) return "Unknown";
		return formatAddress(address);
	}, [ order ]);

	return (
		<BasicListItem
			label="Address"
			loading={loading}
			value={formattedAddress || "Unknown"}
			action={(
				<AddressDetailsDialog
					address={{
						...order?.address,
					}}
					activatorButton={(
						<IconButton color="primary">
							<Visibility />
						</IconButton>
					)}
				/>
			)}
		/>
	);
};