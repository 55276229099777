import { Box } from "grommet";
import { Fragment, useState } from "react";
import { Typography } from "@mui/material";
import { CarouselItem } from "./CarouselItem";
import { ProductImageDialog } from "./ProductImageDialog";

export interface ProductMediaCarouselProps {
	height?: string;
	media: { name: string, contentUrl: string; file?: File; isPrimary?: boolean; }[];
}

export const ProductMediaCarousel: React.FC<ProductMediaCarouselProps> = ({ media, height }) => {
	const [ showDialog, setShowDialog ] = useState(false);

	return (
		<Fragment>
			{showDialog && (
				<ProductImageDialog
					onClose={() => setShowDialog(false)}
					media={media}
				/>
			)}
			{media.length === 0 ? (
				<Box align="center" justify="center" flex>
					<Typography fontWeight="bold">
						No Images Uploaded
					</Typography>
				</Box>
			) : media.map((item) => (
				<CarouselItem
					height={height}
					key={item.name}
					name={item.name}
					contentUrl={item.contentUrl}
					file={item.file} />
			))}
		</Fragment>
	);
};