import { Box } from "grommet";
import { Button, Checkbox, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectFilters, setConditionFilter } from "../../../../store/shop";
import { labeledConditions } from "../../../product/hooks/useConditions";
import { ProductCondition } from "../../../../graphql/__generated__/graphql";
import { ActiveFilterItemProps, FilterItem } from "./FilterItem";
import { isNotNull } from "../../../../helpers";

export const ConditionFilterItem: React.FC<ActiveFilterItemProps> = (props) => {
	const dispatch = useAppDispatch();
	const { conditionFilter } = useAppSelector(selectFilters);

	const activeConditions = labeledConditions
		.filter(c => c.id !== ProductCondition.Unknown)
		.sort((a, b) => a.index - b.index);

	const conditionFilterValue = useMemo(() => {
		if(!conditionFilter) return "";
		if(conditionFilter.length > 3) return `${conditionFilter.length} selected`;
		return conditionFilter.map((condition) => {
			return activeConditions.find((labeledCondition) => {
				return labeledCondition.id === condition;
			})?.label;
		}).filter(Boolean).join(", ");
	}, [ conditionFilter, activeConditions ]);

	const selectedValues = useMemo(() => {
		if(!conditionFilter) return [];
		return conditionFilter.map((condition) => {
			return activeConditions.find((labeledCondition) => {
				return labeledCondition.id === condition;
			});
		}).filter(isNotNull);
	}, [ conditionFilter, activeConditions ]);

	function toggleCondition(condition: ProductCondition) {
		if(conditionFilter?.includes(condition)) {
			dispatch(setConditionFilter([ ...conditionFilter.filter(c => c !== condition) ]));
		}
		else {
			dispatch(setConditionFilter([ ...conditionFilter ?? [], condition ]));
		}
	}

	return (
		<FilterItem
			title="Condition"
			value={conditionFilterValue}
			details={(
				<Box>
					<List>
						{activeConditions.map(condition => (
							<ListItemButton
								key={condition.id}
								onClick={() => toggleCondition(condition.id)}
							>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={selectedValues.some((value) => value.id === condition.id)}
										tabIndex={-1}
										disableRipple />
								</ListItemIcon>
								<ListItemText primary={condition.label} />
							</ListItemButton>
						))}
					</List>
					<Box pad="small" align="start">
						<Button
							color="error"
							onClick={() => dispatch(setConditionFilter(null))}
						>
							Clear
						</Button>
					</Box>
				</Box>
			)}
			expanded={props.active}
			toggle={props.setActive} />
	);
};
