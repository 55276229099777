import React from "react";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";
import { useActiveOrders } from "../../../hooks";
import { useInstance } from "../../../../../hooks";
import { OrdersDataTable } from "./OrdersDataTable";
import { defaultColumns } from "./columns";
import { TabListItem } from "../../../../../components";
import { GridColDef } from "@mui/x-data-grid";

export const ActiveOrdersDataTable: React.FC<{
	columns?: GridColDef[];
}> = ({ columns }) => {
	const dispatch = useAppDispatch();
	const { instance } = useInstance();
	const { orders, loading } = useActiveOrders(
		instance?.id ?? ""
	);

	return (
		<OrdersDataTable
			loading={loading}
			columns={columns || defaultColumns}
			orders={orders}
			onRowSelect={(order) => {
				dispatch(push(`/admin/orders/${order.id}`));
			}}
		/>
	);
};

export const ActiveOrdersTabFactory = (columns?: GridColDef[]): TabListItem => ({
	label: "Active",
	value: "active",
	component: (
		<ActiveOrdersDataTable
			columns={columns}
		/>
	)
});