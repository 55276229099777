import React, { useCallback, useEffect } from "react";
import { Button } from "@mui/material";
import { Box, Page, PageContent, Text } from "grommet";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { StepControls } from "@components/Steps";
import { ProductCreationListItem } from "../../../components/product/ProductCreationListItem";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { DeleteProductFragment, FetchCheckoutSession, ProductFragmentGql } from "../../../graphql";
import { ItemDialogController, Pagination } from "../../../components";
import { usePricedTypes, useSession } from "../../../hooks";

export default function ItemsView(props: { sessionId: string; }) {
	const snack = useSnackbar();
	const { session, products, loading } = useSession(props.sessionId);
	const { types } = usePricedTypes(
		session?.referer?.id ?? "",
		(!!session?.cutoffDate && !session.pickup)
	);

	const [ isDialogOpen, setIsDialogOpen ] = useState(false);
	const [ selectedProduct, setSelectedProduct ] = useState<ProductFragmentGql | null>(null);

	const [ deletingFragmentId, setDeletingFragmentId ] = useState<string>("");

	const [
		deleteProductMutation,
		{ loading: deleteProductLoading, error: deleteProductError }
	] = useMutation(DeleteProductFragment, { refetchQueries: [ FetchCheckoutSession ] });

	function deleteProduct(fragmentId: string) {
		setDeletingFragmentId(fragmentId);
		deleteProductMutation({
			variables: {
				fragmentId
			}
		});
	}

	useEffect(() => {
		if(deleteProductError) {
			snack.enqueueSnackbar(
				"We ran into an issue deleting your item",
				{ variant: "error" }
			);
		}
	}, [ deleteProductError, snack ]);

	const calculateItemFee = useCallback((product: ProductFragmentGql) => {
		let totalFee = 0;

		if(product.type) {
			const productType = product.type;
			const productSize = product.size;
			const item = types.find(i => i.name === productType.name);

			const size = item?.sizes.find(s => s.name === productSize?.name);

			if(item) {
				totalFee += item.pickupFee * product.quantity;

				if(product.quantityDisassembly > 0 && item.disassemblyFee) {
					totalFee += item.disassemblyFee * product.quantityDisassembly;
				}
			}

			if(size && size.pickupFee) {
				totalFee += size.pickupFee * product.quantity;
			}
		}

		return totalFee;
	}, [ types ]);

	return (
		<Page kind="narrow">
			<PageContent>
				{isDialogOpen && (
					<ItemDialogController
						sessionId={props.sessionId}
						product={selectedProduct ?? undefined}
						onClose={() => {
							setIsDialogOpen(false);
							setSelectedProduct(null);
						}}
					/>
				)}
				<Box flex gap="medium">
					<Box gap="small">
						<Text weight="bold" size="large">Your Items</Text>
						<Text>Select your items and upload images to continue.</Text>
					</Box>
					{products.length === 0 && (
						<Box pad="medium" align="center" justify="center">
							<Text size="large">Add an item to continue</Text>
						</Box>
					)}
					<Pagination pageSize={5}>
						{products.map((product) => (
							<ProductCreationListItem
								key={product.id}
								product={product}
								onDelete={() => {
									deleteProduct(product.id);
								}}
								onSelect={() => {
									setSelectedProduct(product);
									setIsDialogOpen(true);
								}}
								sessionId={props.sessionId}
								isDeleting={deleteProductLoading && deletingFragmentId === product.id}
							/>
						))}
					</Pagination>
					<StepControls
						name="schedule"
						isLoading={loading || deleteProductLoading}
						nextButtonLabel="Continue"
						canProceed={products.length > 0}
						additionalButtons={(
							<Button
								variant="outlined"
								color="primary"
								startIcon={<Add />}
								onClick={() => {
									setIsDialogOpen(true);
								}}
							>
								Add Item
							</Button>
						)}
					/>
				</Box>
			</PageContent>
		</Page>
	);
}