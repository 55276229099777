import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Decor: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m1125.1 1075.7h-1049c-6.7344 0-12.195-5.457-12.195-12.195v-97.234c0-6.7383 5.4609-12.195 12.195-12.195h1049c6.7383 0 12.195 5.457 12.195 12.195v97.234c0 6.7344-5.457 12.195-12.195 12.195zm-1036.8-24.391h1024.6v-72.844h-1024.6z" />
		<path
			d="m1020.3 978.46c-6.7383 0-12.195-5.457-12.195-12.195v-188.58c0-6.7383 5.457-12.195 12.195-12.195 6.7383 0 12.195 5.457 12.195 12.195v188.58c0 6.7344-5.457 12.195-12.195 12.195zm-176.79 0c-6.7383 0-12.195-5.457-12.195-12.195v-188.58c0-6.7383 5.457-12.195 12.195-12.195 6.7383 0 12.195 5.457 12.195 12.195v188.58c0 6.7344-5.4609 12.195-12.195 12.195z" />
		<path
			d="m1045.3 789.88h-226.88c-6.7383 0-12.195-5.457-12.195-12.195v-63.355c0-6.7383 5.457-12.195 12.195-12.195h226.88c6.7383 0 12.195 5.457 12.195 12.195v63.355c0 6.7344-5.457 12.195-12.195 12.195zm-214.69-24.391h202.49v-38.965h-202.49z" />
		<path
			d="m931.89 726.52c-6.7383 0-12.195-5.457-12.195-12.195v-256.35c0-6.7344 5.457-12.195 12.195-12.195s12.195 5.4609 12.195 12.195v256.35c-0.003906 6.7344-5.4609 12.195-12.195 12.195z" />
		<path
			d="m886.52 587.98c-8.5469 0-17.484-1.1758-26.531-3.6016-33.555-8.9922-57.23-32.113-60.32-58.906-0.35547-3.082 0.47266-6.1797 2.3203-8.6641 16.074-21.66 48.141-29.844 81.695-20.852 33.555 8.9922 57.238 32.113 60.328 58.906 0.35547 3.0781-0.47266 6.1797-2.3203 8.6641-11.746 15.824-32.02 24.453-55.172 24.453zm-61.629-60.391c4.8359 16.543 23.605 28.461 41.414 33.23 17.801 4.7734 40.023 3.8359 52.484-8.0742-4.8359-16.543-23.609-28.461-41.414-33.23-17.809-4.7695-40.027-3.832-52.484 8.0742z" />
		<path
			d="m977.27 679.95c-23.152 0-43.426-8.6289-55.168-24.445-1.8477-2.4922-2.6797-5.5859-2.3203-8.668 3.0938-26.793 26.773-49.918 60.328-58.906 33.559-8.9883 65.621-0.80859 81.695 20.855 1.8477 2.4883 2.6797 5.5859 2.3203 8.6641-3.0938 26.793-26.773 49.918-60.324 58.906-9.043 2.418-17.984 3.5938-26.531 3.5938zm-32.266-35.23c12.457 11.91 34.68 12.848 52.48 8.0742 17.809-4.7695 36.578-16.691 41.414-33.23-12.457-11.906-34.672-12.84-52.48-8.0742-17.805 4.7656-36.578 16.688-41.414 33.23z" />
		<path
			d="m714.4 978.46c-6.7383 0-12.195-5.457-12.195-12.195v-31.676c0-10.742-8.7383-19.48-19.48-19.48h-109.02c-10.742 0-19.48 8.7383-19.48 19.48v31.676c0 6.7383-5.4609 12.195-12.195 12.195s-12.195-5.457-12.195-12.195l0.003907-31.676c0-24.191 19.68-43.871 43.871-43.871h109.02c24.191 0 43.871 19.68 43.871 43.871v31.676c-0.003906 6.7344-5.4609 12.195-12.199 12.195z" />
		<path
			d="m804.27 395.77h-352.11c-3.7266 0-7.25-1.7031-9.5625-4.625-2.3125-2.9258-3.1602-6.7422-2.3047-10.371l57.488-243.46c1.3008-5.5039 6.2109-9.3945 11.867-9.3945h237.14c5.6523 0 10.566 3.8867 11.867 9.3945l57.484 243.46c0.85938 3.625 0.007813 7.4453-2.3047 10.371-2.3125 2.918-5.8359 4.625-9.5625 4.625zm-336.7-24.391h321.29l-51.727-219.07h-217.84z" />
		<path
			d="m660.26 915.1c-6.7383 0-12.195-5.457-12.195-12.195v-519.33c0-6.7344 5.457-12.195 12.195-12.195 6.7383 0 12.195 5.4609 12.195 12.195v519.34c0 6.7344-5.457 12.191-12.195 12.191zm-64.086 0c-6.7344 0-12.195-5.457-12.195-12.195v-519.33c0-6.7344 5.4609-12.195 12.195-12.195 6.7383 0 12.195 5.4609 12.195 12.195v519.34c0 6.7344-5.4609 12.191-12.195 12.191z" />
		<path
			d="m215.31 978.46c-2.5547 0-5.1328-0.80078-7.3359-2.457-77.875-58.738-59.652-99.957-40.359-143.6 16.094-36.406 34.336-77.672 3.4766-144.79-1.7344-3.7773-1.4297-8.1797 0.8125-11.676 2.2422-3.5 6.1133-5.6133 10.266-5.6133h218.51c4.1562 0 8.0273 2.1133 10.266 5.6133 2.2422 3.5 2.5469 7.8984 0.8125 11.676-30.855 67.121-12.617 108.38 3.4766 144.79 19.293 43.641 37.516 84.859-40.359 143.6-5.3789 4.0547-13.023 2.9844-17.078-2.3945-4.0547-5.3789-2.9844-13.023 2.3945-17.082 62.434-47.09 51.258-72.375 32.738-114.26-15.949-36.082-35.496-80.309-10.523-147.55l-181.96-0.003906c24.973 67.242 5.4258 111.47-10.523 147.55-18.516 41.891-29.695 67.172 32.738 114.26 5.3789 4.0547 6.4492 11.703 2.3945 17.082-2.4023 3.1797-6.0547 4.8555-9.75 4.8555z" />
		<path
			d="m444.66 581.77h-295.4c-3.7266 0-7.25-1.7031-9.5625-4.625-2.3125-2.9258-3.1602-6.7422-2.3047-10.371l48.227-204.24c1.3008-5.5039 6.2109-9.3945 11.867-9.3945h198.94c5.6562 0 10.566 3.8867 11.867 9.3945l48.227 204.24c0.85547 3.625 0.007813 7.4453-2.3047 10.371-2.3086 2.9219-5.832 4.625-9.5586 4.625zm-279.99-24.391h264.57l-42.469-179.86h-179.64z" />
		<path
			d="m323.48 694.71c-6.7344 0-12.195-5.457-12.195-12.195v-112.95c0-6.7344 5.4609-12.195 12.195-12.195 6.7344 0 12.195 5.4609 12.195 12.195v112.95c0 6.7383-5.4609 12.195-12.195 12.195zm-64.086 0c-6.7344 0-12.195-5.457-12.195-12.195v-112.95c0-6.7344 5.4609-12.195 12.195-12.195s12.195 5.4609 12.195 12.195v112.95c0 6.7383-5.4609 12.195-12.195 12.195z" />
		<path
			d="m239.75 978.46c-1.4375 0-2.8984-0.25391-4.3281-0.79688-6.2969-2.3906-9.4648-9.4336-7.0703-15.73 31.438-82.801 25.949-152.41 15.809-196.22-11.031-47.664-29.812-76.113-30-76.395-3.7383-5.5898-2.25-13.16 3.3359-16.914 5.5859-3.7461 13.141-2.2812 16.902 3.3008 0.85547 1.2695 21.094 31.668 33.25 83.328 11.145 47.352 17.328 122.49-16.492 211.56-1.8516 4.8711-6.4883 7.8672-11.406 7.8672z" />
		<path
			d="m343.12 978.46c-4.918 0.003907-9.5547-2.9961-11.402-7.8711-33.82-89.07-27.637-164.21-16.492-211.56 12.16-51.66 32.395-82.062 33.25-83.328 3.7617-5.5859 11.344-7.0625 16.93-3.2969 5.5781 3.7617 7.0547 11.328 3.3047 16.91-0.21484 0.32422-18.977 28.766-30 76.395-10.141 43.816-15.633 113.42 15.809 196.22 2.3906 6.2969-0.77734 13.34-7.0703 15.73-1.4258 0.54688-2.8906 0.80078-4.3281 0.80078z" />
	</g>
</svg>
			</SvgIcon>
		);
	};