import { SvgIcon, SvgIconProps } from "@mui/material";

export const Bathroom: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
				<g fillRule="evenodd">
					<path d="m410.94 656.22h-178.39c-45.516 0-82.543-37.027-82.543-82.543v-50.652c0-5.1797 4.1953-9.375 9.375-9.375h324.73c5.1797 0 9.375 4.1953 9.375 9.375v50.652c0 45.512-37.027 82.543-82.543 82.543zm-242.19-123.82v41.281c0 35.176 28.617 63.793 63.793 63.793h178.39c35.176 0 63.793-28.617 63.793-63.793v-41.277h-305.98z" />
					<path d="m377.59 1035.9h-111.7c-2.5156 0-4.9336-1.0117-6.6953-2.8086-1.7617-1.7969-2.7305-4.2305-2.6797-6.75l7.4453-379.69c0.10156-5.1758 4.293-9.3359 9.5586-9.1875 5.1797 0.10156 9.293 4.3789 9.1914 9.5547l-7.2617 370.13h92.578l-7.2617-370.13c-0.10156-5.1758 4.0156-9.4531 9.1914-9.5547 5.1367-0.18359 9.457 4.0156 9.5586 9.1914l7.4453 379.69c0.046874 2.5156-0.91406 4.9492-2.6797 6.75-1.7656 1.8008-4.168 2.8086-6.6875 2.8086z" />
					<path d="m484.11 583.05h-324.73c-5.1797 0-9.375-4.1953-9.375-9.375 0-5.1797 4.1953-9.375 9.375-9.375h324.73c5.1797 0 9.375 4.1953 9.375 9.375 0 5.1797-4.1953 9.375-9.375 9.375z" />
					<path d="m223.49 532.4c-5.1797 0-9.375-4.1953-9.375-9.375v-41.969c0-5.1797 4.1953-9.375 9.375-9.375 5.1797 0 9.375 4.1953 9.375 9.375v41.969c0 5.1797-4.1953 9.375-9.375 9.375z" />
					<path d="m246.51 490.43h-46.039c-5.1797 0-9.375-4.1953-9.375-9.375 0-5.1797 4.1953-9.375 9.375-9.375h46.039c5.1797 0 9.375 4.1953 9.375 9.375 0 5.1797-4.1953 9.375-9.375 9.375z" />
					<path d="m419.99 532.4c-5.1797 0-9.375-4.1953-9.375-9.375v-41.969c0-5.1797 4.1953-9.375 9.375-9.375 5.1797 0 9.375 4.1953 9.375 9.375v41.969c-0.003907 5.1797-4.1992 9.375-9.375 9.375z" />
					<path d="m443.01 490.43h-46.039c-5.1797 0-9.375-4.1953-9.375-9.375 0-5.1797 4.1953-9.375 9.375-9.375h46.039c5.1797 0 9.375 4.1953 9.375 9.375 0 5.1797-4.1953 9.375-9.375 9.375z" />
					<path d="m1040.6 1035.9h-297.58c-5.1797 0-9.375-4.1953-9.375-9.375v-734.62c0-5.1797 4.1953-9.375 9.375-9.375h297.59c5.1797 0 9.375 4.1953 9.375 9.375v734.62c-0.003906 5.1797-4.1953 9.375-9.3789 9.375zm-288.21-18.75h278.84v-715.88h-278.84z" />
					<path d="m1040.6 965.5h-297.58c-5.1797 0-9.375-4.1953-9.375-9.375s4.1953-9.375 9.375-9.375h297.59c5.1797 0 9.375 4.1953 9.375 9.375s-4.1953 9.375-9.3789 9.375z" />
					<path d="m979.98 965.5c-5.1797 0-9.375-4.1953-9.375-9.375v-547.12c0-5.1797 4.1953-9.375 9.375-9.375 5.1797 0 9.375 4.1953 9.375 9.375v547.13c0 5.1758-4.1953 9.3711-9.375 9.3711z" />
					<path d="m889.24 458.41c-3.6016 0-7.0352-2.0859-8.5859-5.5938-2.0859-4.7383 0.058594-10.27 4.793-12.359l90.746-40.031c4.7344-2.0898 10.273 0.054687 12.363 4.793 2.0859 4.7383-0.058594 10.27-4.793 12.359l-90.746 40.031c-1.2266 0.54297-2.5156 0.80078-3.7773 0.80078z" />
					<path d="m914.6 493.1c-2.8906 0-5.7422-1.332-7.5781-3.8438l-50.711-69.391c-3.0547-4.1797-2.1406-10.047 2.0391-13.102 4.1797-3.0547 10.043-2.1445 13.102 2.0391l50.711 69.395c3.0547 4.1797 2.1406 10.047-2.0391 13.102-1.6641 1.2188-3.6016 1.8047-5.5234 1.8047z" />
					<path d="m791.08 808.79c-5.1797 0-9.375-4.1953-9.375-9.375v-258.55c0-5.1797 4.1953-9.375 9.375-9.375 5.1797 0 9.375 4.1953 9.375 9.375v258.55c0 5.1758-4.1953 9.375-9.375 9.375z" />
					<path d="m791.08 596.52h-48.043c-5.1797 0-9.375-4.1953-9.375-9.375 0-5.1797 4.1953-9.375 9.375-9.375h48.043c5.1797 0 9.375 4.1953 9.375 9.375 0 5.1797-4.1953 9.375-9.375 9.375z" />
					<path d="m791.08 762.5h-48.043c-5.1797 0-9.375-4.1953-9.375-9.375s4.1953-9.375 9.375-9.375h48.043c5.1797 0 9.375 4.1953 9.375 9.375s-4.1953 9.375-9.375 9.375z" />
					<path d="m423.83 439.06h-204.17c-5.1797 0-9.375-4.1953-9.375-9.375v-256.21c0-5.1797 4.1953-9.375 9.375-9.375h204.17c5.1797 0 9.375 4.1953 9.375 9.375v256.21c0 5.1797-4.1992 9.375-9.375 9.375zm-194.8-18.75h185.42v-237.47h-185.42z" />
					<path d="m391.8 406.54h-140.12c-5.1797 0-9.375-4.1953-9.375-9.375v-191.16c0-5.1797 4.1953-9.375 9.375-9.375h140.12c5.1797 0 9.375 4.1953 9.375 9.375v191.16c0 5.1758-4.1992 9.375-9.375 9.375zm-130.75-18.75h121.37v-172.41h-121.37z" />
					<path d="m692.39 391.02h-37.367c-5.1797 0-9.375-4.1953-9.375-9.375 0-5.1797 4.1953-9.375 9.375-9.375h27.992v-23.953h-27.992c-5.1797 0-9.375-4.1953-9.375-9.375 0-5.1797 4.1953-9.375 9.375-9.375h37.367c5.1797 0 9.375 4.1953 9.375 9.375v42.703c0 5.1797-4.1953 9.3789-9.375 9.3789zm-162.8 0h-37.367c-5.1797 0-9.375-4.1953-9.375-9.375v-42.703c0-5.1797 4.1953-9.375 9.375-9.375h37.367c5.1797 0 9.375 4.1953 9.375 9.375 0 5.1797-4.1953 9.375-9.375 9.375h-27.992v23.953h27.992c5.1797 0 9.375 4.1953 9.375 9.375 0 5.1797-4.1992 9.3711-9.375 9.3711z" />
					<path d="m625.67 548.48h-66.727c-21.359 0-38.73-17.375-38.73-38.73v-170.81c0-5.1797 4.1953-9.375 9.375-9.375h125.44c5.1797 0 9.375 4.1953 9.375 9.375v170.81c0 21.355-17.371 38.73-38.73 38.73zm-86.707-200.17v161.44c0 11.02 8.9648 19.984 19.984 19.984h66.727c11.02 0 19.984-8.9648 19.984-19.984v-161.44z" />
					<path d="m571.73 916.17h-4.8867c-57.719 0-104.68-46.957-104.68-104.68 0-5.1797 4.1953-9.375 9.375-9.375h195.49c5.1797 0 9.375 4.1953 9.375 9.375-0.003906 57.719-46.961 104.68-104.68 104.68zm-90.309-95.301c4.6836 42.988 41.207 76.555 85.422 76.555h4.8867c44.211 0 80.73-33.566 85.418-76.555z" />
					<path d="m637.48 1035.9h-136.38c-2.8164 0-5.4805-1.2656-7.2617-3.4453-1.7812-2.1797-2.4883-5.043-1.9258-7.8047l26.191-128.39c1.0352-5.0742 5.9922-8.3516 11.059-7.3125 5.0742 1.0352 8.3477 5.9883 7.3125 11.059l-23.898 117.14h113.42l-23.895-117.14c-1.0352-5.0742 2.2383-10.023 7.3125-11.059 5.0781-1.0312 10.023 2.2383 11.059 7.3125l26.191 128.39c0.5625 2.7578-0.14844 5.625-1.9258 7.8008-1.7812 2.1797-4.4453 3.4453-7.2617 3.4453z" />
					<path d="m596.92 820.87c-2.9531 0-5.8633-1.3906-7.6875-3.9961-2.9688-4.2422-1.9375-10.09 2.3008-13.059 18.086-12.668 29.77-39.523 29.77-68.414 0-41.801-23.332-75.812-52.012-75.812-28.68 0-52.012 34.012-52.012 75.812 0 28.895 11.688 55.75 29.77 68.414 4.2422 2.9727 5.2695 8.8164 2.3008 13.059-2.9727 4.2383-8.8164 5.2695-13.059 2.3008-23.297-16.316-37.766-48.414-37.766-83.773 0-52.141 31.742-94.559 70.758-94.559 39.02 0 70.762 42.418 70.762 94.559 0 35.355-14.469 67.457-37.766 83.773-1.6328 1.1484-3.5078 1.6953-5.3672 1.6953z" />
					<path d="m499.71 820.87c-3.2031 0-6.3242-1.6445-8.0742-4.5977-13.48-22.754-20.605-50.09-20.605-79.047 0-70.996 44.082-128.75 98.266-128.75 54.184 0 98.266 57.758 98.266 128.75 0 28.961-7.125 56.297-20.605 79.047-2.6406 4.4492-8.3906 5.9219-12.848 3.2852-4.4531-2.6406-5.9258-8.3906-3.2852-12.848 11.602-19.574 17.992-44.254 17.992-69.488 0-60.66-35.668-110.01-79.516-110.01-43.844 0-79.516 49.348-79.516 110.01 0 25.23 6.3906 49.91 17.992 69.488 2.6406 4.457 1.168 10.207-3.2852 12.848-1.5 0.88672-3.1484 1.3125-4.7734 1.3125z" />
				</g>
			</svg>
		</SvgIcon>
	);
};