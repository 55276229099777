import { gql } from "../../../__generated__";

export const FetchTodaysPickups = gql(/* GraphQL */`
	query FetchTodaysPickups(
		$haulerId: String!
		$startOfDayBoundary: DateTime!
		$endOfDayBoundary: DateTime!
	) {
		FindPickups(
			scheduledDate: {
				isBefore: $endOfDayBoundary
				isAfter: $startOfDayBoundary
			}
			assignedTo: { id: { equals: $haulerId } }
		) {
			...Pickup_HeaderFragment
			...Pickup_CountFragment
			...Pickup_TrackingFragment
			...Pickup_InvoiceFragment
			...Pickup_StopsFragment
			...Pickup_ActiveStopFragment
		}
	}
`);