import React, { useEffect } from "react";
import { InputAdornment } from "@mui/material";
import { TextFieldElement, UseFormReturn } from "react-hook-form-mui";
import { OnboardingFormContext } from "../../types";
import { formatSubdomain } from "../../helpers";
import { useLazyQuery } from "@apollo/client";
import { IsExistingSubdomain } from "../../../../graphql";
import { noop } from "../../../../helpers";

export const DomainNameElement: React.FC<{
	readonly?: boolean;
	fullWidth?: boolean;
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ readonly, formContext, fullWidth }) => {
	const [ isExistingSubdomain, setIsExistingSubdomain ] = React.useState(false);
	const { businessName, subdomain } = formContext.watch();

	const [
		validate,
		{ data, loading }
	] = useLazyQuery(IsExistingSubdomain, {
		variables: { subdomain },
		fetchPolicy: "no-cache"
	});

	function validateSubdomain() {
		validate({ variables: { subdomain } });
	}

	useEffect(() => {
		if(businessName && !subdomain) {
			formContext.setValue("subdomain", formatSubdomain(businessName));
		}
	}, []);

	useEffect(() => {
		if(data?.IsExistingSubdomain) {
			setIsExistingSubdomain(true);
		}
		else {
			setIsExistingSubdomain(false);
		}
	}, [ data, formContext ]);

	useEffect(() => {
		formContext.handleSubmit(noop);
	}, [ formContext, isExistingSubdomain ]);

	return (
		<TextFieldElement
			required
			fullWidth={fullWidth}
			name="subdomain"
			label="Domain Name"
			helperText="The url you will use to access your dashboard"
			InputProps={{
				readOnly: readonly,
				onBlur: validateSubdomain,
				endAdornment: (
					<InputAdornment position="end">
						.liverego.com
					</InputAdornment>
				)
			}}
			validation={{
				validate: (value: any) => {
					if(!value) {
						return "This field is required";
					}
					if(isExistingSubdomain) {
						return "This domain name is already taken";
					}
					if(value && !/^[a-z0-9-]+$/.test(value)) {
						return "Please enter a valid domain name";
					}
					else if(value && value.length > (63 - ".liverego.com".length)) {
						return "Domain name is too long";
					}
				}
			}}
		/>
	);
};