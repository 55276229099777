import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Stairs: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m310.69 799.92h111.73v-105.61c0-1.043 0.14453-2.0391 0.30078-3.0352-0.16797-0.99609-0.30078-1.9922-0.30078-3.0352 0-9.9375 8.0625-18 18-18h111.71v-105.62c0-1.043 0.14453-2.0508 0.30078-3.0352-0.16797-0.99609-0.30078-2.0039-0.30078-3.0352 0-9.9492 8.0625-18 18-18h111.72v-105.62c0-1.043 0.13281-2.0508 0.30078-3.0352-0.17969-0.99609-0.30078-2.0039-0.30078-3.0352 0-9.9492 8.0625-18 18-18h106.59v-113.79c0-1.043 0.14453-2.0391 0.30078-3.0352-0.16797-0.99609-0.30078-2.0039-0.30078-3.0352 0-9.9492 8.0625-18 18-18h124.59c9.9375 0 18 8.0508 18 18 0 9.9492-8.0625 18-18 18h-106.59v112.66c0 1.2344-0.13281 2.4375-0.35938 3.6016 0.23828 1.1641 0.35938 2.3633 0.35938 3.6016 0 9.9492-8.0625 18-18 18h-106.59v112.67c0 9.9492-8.0625 18-18 18-1.9453 0-3.7812-0.38281-5.543-0.96094l-106.18-0.007813v112.64c0 9.9492-8.0625 18-18 18-1.9219 0-3.7422-0.37109-5.4727-0.9375h-106.23v112.67c0 9.9492-8.0625 18-18 18-1.9805 0-3.8398-0.39453-5.6289-0.98438h-106.11v112.69c0 9.9492-8.0625 18-18 18s-18-8.0508-18-18v-124.62c0-1.043 0.14453-2.0508 0.30078-3.0469-0.16797-0.99609-0.30078-2.0039-0.30078-3.0469 0.011718-9.9375 8.0625-18 18.012-18z"/>
  <path d="m238.27 682.36 443.71-443.7c7.0312-7.0312 18.422-7.0312 25.453 0 7.0312 7.0195 7.0312 18.434 0 25.453l-443.7 443.7c-3.5156 3.5156-8.125 5.2695-12.73 5.2695-4.6094 0-9.2148-1.7539-12.73-5.2695-7.0195-7.0312-7.0195-18.434 0-25.453z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};