import { gql } from "../../../__generated__";

export const FetchWasteTicket = gql(/* GraphQL */ `
	query FetchWasteTicket($wasteTicketId: String!) {
		FetchWasteTicket(wasteTicketId: $wasteTicketId) {
			...WasteTicket_MediaFragment
			...WasteTicket_HeaderFragment
			...WasteTicket_SourceFragment
			...WasteTicket_LineItemsFragment
		}
	}	
`);