import { Box, Heading, Page, PageContent, Text } from "grommet";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AssignCheckoutSessionPickup, FetchCheckoutSession, UpdateCheckoutSessionMoveoutDetails } from "../../../graphql";
import { AvailablePickupListItem, StepControls } from "../../../components";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Button, List, Typography } from "@mui/material";
import { useSession } from "../../../hooks";
import { DateCalendar, PickersCalendarHeader } from "@mui/x-date-pickers";
import moment, { Moment } from "moment-timezone";
import { CheckboxElement, FormContainer, useForm } from "react-hook-form-mui";
import { LocalizationProvider } from "../../../provider";

export const ScheduleLaterDateView: React.FC<{
	sessionId: string;
}> = ({ sessionId }) => {
	const { session } = useSession(sessionId);

	const [
		update,
		{ loading: isUpdating, error: updateError }
	] = useMutation(UpdateCheckoutSessionMoveoutDetails, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	const formContext = useForm({
		defaultValues: {
			hasCutoffDate: !!session?.cutoffDate,
			cutoffDate: session?.cutoffDate || undefined
		}
	});

	useEffect(() => {
		if(session) {
			formContext.setValue("hasCutoffDate", !!session.cutoffDate);
			formContext.setValue("cutoffDate", session.cutoffDate || undefined);
		}
	}, [ session ]);

	const formValues = formContext.watch();

	function handleNoCutoffDate() {
		update({
			variables: {
				sessionId: sessionId,
				cutoffDate: null
			}
		}).catch(err => {
			console.error(err);
		});
	}

	useEffect(() => {
		if(!formValues.hasCutoffDate) {
			handleNoCutoffDate();
		}
	}, [ formValues.hasCutoffDate ]);

	function handleCutoffDateSelected(date: Moment | null) {
		update({
			variables: {
				sessionId: sessionId,
				cutoffDate: date?.format("YYYY-MM-DD") || null
			}
		}).catch(err => {
			console.error(err);
		});
	}

	return (
		<Page kind="narrow">
			<PageContent>
				<Box gap="medium" flex>
					<FormContainer formContext={formContext}>
						<CheckboxElement
							name="hasCutoffDate"
							label={(
								<Text weight="bold">
									Do you need everything out by a certain date?
								</Text>
							)}
						/>
					</FormContainer>
					{formContext.watch("hasCutoffDate") && (
						<LocalizationProvider>
							<DateCalendar
								disablePast
								shouldDisableDate={(date) => {
									return date.isSameOrBefore(moment(), "day");
								}}
								value={formContext.watch("cutoffDate")
									? moment(formContext.watch("cutoffDate"))
									: undefined
								}
								showDaysOutsideCurrentMonth
								onChange={(value) => {
									formContext.setValue("cutoffDate", value?.format("YYYY-MM-DD") || undefined);
									handleCutoffDateSelected(value);
								}}
							/>
						</LocalizationProvider>
					)}
					<Box flex justify="end">
						<StepControls
							name="schedule"
							isLoading={isUpdating}
							nextButtonLabel="Continue"
							canProceed={formValues.hasCutoffDate ? !!formValues.cutoffDate : true}
						/>
					</Box>
				</Box>
			</PageContent>
		</Page>
	);
};

export default function ScheduleUpfrontPickupView(props: { sessionId: string; }) {
	const snack = useSnackbar();
	const { pickup, referer, session } = useSession(props.sessionId);

	const [
		assignCheckoutSessionPickup,
		{ loading: isAssigningPickup, error: assigningPickupError }
	] = useMutation(AssignCheckoutSessionPickup, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	const [
		updateCutoffDate,
		{ loading: isUpdatingCutoffDate, error: updateCutoffDateError }
	] = useMutation(UpdateCheckoutSessionMoveoutDetails, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	useEffect(() => {
		if(assigningPickupError) {
			snack.enqueueSnackbar(
				"We ran into an issue saving your selection.",
				{ variant: "error" }
			);
		}
	}, [ snack, assigningPickupError ]);

	const assignPickup = useCallback((pickupId: string) => {
		return assignCheckoutSessionPickup({
			variables: {
				sessionId: props.sessionId,
				pickupId: pickupId
			}
		});
	}, [ assignCheckoutSessionPickup, props.sessionId ]);

	const [ assigningPickupId, setAssigningPickupId ] = useState<string>("");

	useEffect(() => {
		if(assigningPickupId) {
			assignPickup(assigningPickupId).finally(() => {
				setAssigningPickupId("");
			});
		}
	}, [ assigningPickupId, assignPickup ]);

	const [ selectedDate, setSelectedDate ] = useState<Moment>(
		session?.cutoffDate
			? moment(session.cutoffDate, "YYYY-MM-DD")
			: pickup?.scheduledDate
				? moment(pickup.scheduledDate)
				: moment().add(1, "day")
	);

	useEffect(() => {
		if(pickup) {
			setSelectedDate(moment(pickup.scheduledDate));
		}
	}, [ pickup ]);

	const pickups = useMemo(() => {
		return Array.from(referer?.scheduledPickups ?? []).sort((a, b) => {
			if(!a.scheduledDate) return -1;
			if(!b.scheduledDate) return 1;
			return moment(a.scheduledDate).unix() - moment(b.scheduledDate).unix();
		});
	}, [ referer ]);

	const handleUpdateCutoffDate = useCallback((date: Moment | null) => {
		const foundPickup = pickups.find(p => moment(p.scheduledDate).isSame(moment(date), "day"));
		if(foundPickup) {
			return assignCheckoutSessionPickup({
				variables: {
					sessionId: props.sessionId,
					pickupId: foundPickup.id,
				}
			}).catch(err => {
				console.error("Failed to update cutoff date", err);
				snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", { variant: "error" });
			});
		}
		return updateCutoffDate({
			variables: {
				sessionId: props.sessionId,
				cutoffDate: date?.format("YYYY-MM-DD") || null
			}
		}).catch(err => {
			console.error("Failed to update cutoff date", err);
			snack.enqueueSnackbar("We ran into an issue saving your information. Please try again.", { variant: "error" });
		});
	}, [ updateCutoffDate, props.sessionId, pickups, assignCheckoutSessionPickup ]);

	const scheduledPickups = useMemo(() => {
		if(selectedDate) {
			return pickups.filter(p => p.scheduledDate).filter(p => moment(p.scheduledDate).month() === selectedDate.month());
		}

		return pickups;
	}, [ referer, pickups, selectedDate ]);

	useEffect(() => {
		if(scheduledPickups.length === 0) {
			if(pickups.some(p => moment(p.scheduledDate).month() === moment(selectedDate).add(1, "month").month())) {
				setSelectedDate(moment(selectedDate).add(1, "month"));
			}
		}
	}, [ scheduledPickups ]);

	const [ isCustomDateSelection, setIsCustomDateSelection ] = useState<boolean>(
		!pickup && !!session?.cutoffDate
	);

	return (
		<Page kind="narrow" flex id="page">
			<PageContent flex id="page-content">
				{isCustomDateSelection && (
					<Box>
						<Text size="large" weight="bold">Pickup Date</Text>
						<Text>Select a pickup date to continue.</Text>
						<LocalizationProvider>
							<DateCalendar
								disablePast
								shouldDisableDate={(date) => {
									return date.isSameOrBefore(moment(), "day");
								}}
								value={selectedDate}
								showDaysOutsideCurrentMonth
								onChange={(value) => {
									if(value) {
										setSelectedDate(value);
									}
									handleUpdateCutoffDate(value);
								}}
							/>
						</LocalizationProvider>
					</Box>
				)}
				{!isCustomDateSelection && (
					<Box margin="small" gap="medium" flex>
						<Box gap="small">
							<Text size="large" weight="bold">Pickup Date</Text>
							<Text>Select a pickup date to continue.</Text>
							<LocalizationProvider>
								<PickersCalendarHeader
									view="day"
									views={[ "month" ]}
									currentMonth={selectedDate}
									onMonthChange={(value: Moment) => setSelectedDate(value)}
									reduceAnimations={false}
									timezone={moment.tz.guess()}
									minDate={moment().startOf("day")}
									maxDate={moment().add(3, "months").endOf("day")}
								/>
							</LocalizationProvider>
							<Box flex overflow={{ vertical: "auto" }} style={{ maxHeight: "300px" }}>
								{scheduledPickups.length === 0 && (
									<Box align="center">
										<Typography>
											No available pickup dates for this month.
										</Typography>
									</Box>
								)}
								{scheduledPickups.length > 0 && (
									<List>
										{scheduledPickups.map((item, index) => (
											<AvailablePickupListItem
												key={item.id}
												pickupId={item.id}
												pickupDate={item.scheduledDate || ""}
												onSelect={() => {
													setAssigningPickupId(item.id);
												}}
												selected={item.id === pickup?.id}
												loading={item.id === assigningPickupId}
											/>
										))}
									</List>
								)}
							</Box>
						</Box>
						<Box>
							<Heading level="3" margin="none">
								None of these dates work for you?
							</Heading>
							<Typography>
								Schedule an express pickup on your preferred date.
							</Typography>
						</Box>
						<Box align="center" gap="xsmall">
							<Button
								color="primary"
								variant="contained"
								onClick={() => setIsCustomDateSelection(true)}
							>
								Schedule Express Pickup
							</Button>
							<Typography variant="caption">
								additional fees may apply
							</Typography>
						</Box>
					</Box>
				)}
				<Box flex >
					<StepControls
						name="schedule"
						isLoading={isAssigningPickup || isUpdatingCutoffDate}
						nextButtonLabel="Continue"
						canProceed={!!pickup || (isCustomDateSelection && !!session?.cutoffDate)}
					/>
				</Box>
			</PageContent>
		</Page>
	);
}