import { Box, Carousel, Grid, Heading, Image } from "grommet";
import { BasicListItem, CarouselItem, DialogWithClose, ItemSizeElement, ItemTypeElement, ViewContainer } from "../../../../components";
import { useClassification, useFullScreen, useRequiredPathParam } from "../../../../hooks";
import { useMutation } from "@apollo/client";
import { ConvertOrderRequest, FetchAdminTasks, FetchOrderRequest } from "../../../../graphql";
import { Button, Checkbox, Divider, List, Typography } from "@mui/material";
import { ProductDisposition } from "../../../../graphql/__generated__/graphql";
import { useEffect, useMemo, useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../../store";
import { OutletElement } from "../../components";
import { RefererListItem, PickupLocationListItem } from "../components";
import { useOrderRequest } from "../hooks";

export const OrderRequestDetailsView: React.FC = () => {
	const isFullScreen = useFullScreen();
	const requestId = useRequiredPathParam("requestId", "/admin/dashboard");

	const [ isConverting, setIsConverting ] = useState(false);
	const { request, loading } = useOrderRequest(requestId);

	return (
		<ViewContainer>
			<Box style={{ display: "block" }} height="100%" overflow={{ vertical: "scroll" }}>
				<Grid
					gap="medium"
					height={isFullScreen ? undefined : "100%"}
					columns={{ count: isFullScreen ? 1 : 2, size: "auto" }}
				>
					<Box height="100%">
						<Heading level="3" margin="none">
							Request Details
						</Heading>
						<List>
							<BasicListItem
								label="Request Id"
								value={request?.id || "Unknown"}
								loading={loading}
							/>
							<BasicListItem
								label="Requested Date"
								value={request?.requestedDate
									? moment(request.requestedDate, "YYYY-MM-DD").format("MMMM DD, YYYY")
									: "Unknown"
								}
								loading={loading}
							/>
							<RefererListItem
								requestId={requestId}
								loading={loading}
							/>
							<PickupLocationListItem
								requestId={requestId}
								loading={loading}
							/>
							{/* <LocationDetailsListItem
							location={request?.location || PickupLocation.Default}
							locationDetails={request?.locationDetails || ""}
							loading={loading}
						/> */}
						</List>
						<Box flex justify="end" gap="medium" style={{ minHeight: "68px" }}>
							{!isFullScreen && (
								<Divider />
							)}
							<Box align="start">
								<Button
									color="primary"
									variant="contained"
									onClick={() => setIsConverting(true)}
								>
									Convert to Order
								</Button>
							</Box>
						</Box>
					</Box>
					<Box height="100%">
						<Heading level="3" margin="none">
							Request Images
						</Heading>
						<Box flex>
							<Carousel wrap>
								{(request?.media || []).map((media, index) => (
									<CarouselItem
										key={index}
										name={media.name}
										contentUrl={media.contentUrl}
									/>
								))}
							</Carousel>
						</Box>
					</Box>
				</Grid>
				{isConverting && (
					<ConvertOrderRequestDialog
						requestId={requestId}
						onClose={() => setIsConverting(false)}
					/>
				)}
			</Box>
		</ViewContainer>
	);
};

interface ConvertOrderRequestProduct {
	id: string;
	quantity: number;
	quantityDisassembly: number;
	outlet?: {
		id: string;
		name: string;
		disposition: ProductDisposition;
		label: string;
	};
	fallbackOutlet?: {
		id: string;
		name: string;
		disposition: ProductDisposition;
		label: string;
	};
	type?: { id: string, name: string; };
	size?: { id: string, name: string; };
	media: { id: string, contentUrl: string; }[];
}

interface ConvertOrderRequestState {
	index: number;
	products: ConvertOrderRequestProduct[];
}

export const ConvertOrderRequestDialog: React.FC<{
	requestId: string;
	onClose: () => void;
}> = ({ requestId, onClose }) => {
	const { request } = useOrderRequest(requestId);

	const productCount = useMemo(() => request?.productCount || 0, [ request ]);

	const [ state, setState ] = useState<ConvertOrderRequestState>({
		index: 0,
		products: []
	});

	useEffect(() => {
		if(!request) return;

		const products: ConvertOrderRequestProduct[] = [];

		if(request.isBulkPickup) {
			products.push({
				id: "request-id-index-0",
				media: [],
				quantity: 1,
				quantityDisassembly: 0,
				outlet: undefined,
				fallbackOutlet: undefined,
			});

			setState({
				index: 0,
				products
			});

			return;
		}

		for(let i = 0; i < request.productCount; i++) {
			products.push({
				id: `request-id-index-${i}`,
				media: [],
				quantity: 1,
				quantityDisassembly: 0,
				outlet: undefined,
				fallbackOutlet: undefined,
			});
		}

		setState({
			index: 0,
			products
		});
	}, [ request ]);

	const { types } = useClassification(request?.isBulkPickup || false);
	const snack = useSnackbar();

	function validateProductAtIndex(index: number): boolean {
		const currentProduct = state.products[ index ];
		if(!currentProduct) return false;

		if(currentProduct.quantity <= 0) {
			snack.enqueueSnackbar("Quantity must be greater than 0", { variant: "error" });
			return false;
		}

		if(currentProduct.quantityDisassembly > currentProduct.quantity) {
			snack.enqueueSnackbar("Quantity disassembly must be less than or equal to quantity", { variant: "error" });
			return false;
		}

		if(!currentProduct.type) {
			snack.enqueueSnackbar("Please select a type for the item", { variant: "error" });
			return false;
		}

		if(!currentProduct.outlet) {
			snack.enqueueSnackbar("Please select an outlet for the item", { variant: "error" });
			return false;
		}

		if(!currentProduct.fallbackOutlet && currentProduct.outlet.disposition === ProductDisposition.Donation) {
			snack.enqueueSnackbar("Please select a fallback outlet for the item", { variant: "error" });
			return false;
		}

		const type = types.find((type) => type.id === currentProduct.type?.id);
		if(!type) return false;
		if(type.sizes.length > 0 && !currentProduct.size) {
			snack.enqueueSnackbar("Please select a size for the item", { variant: "error" });
			return false;
		}

		if(currentProduct.media.length === 0) {
			snack.enqueueSnackbar(
				"Please select at least one image for the item",
				{ variant: "error" }
			);
			return false;
		}

		return true;
	}

	function handleNext() {
		if(!validateProductAtIndex(state.index)) return;

		setState({
			index: state.index + 1,
			products: state.products
		});
	}

	const dispatch = useAppDispatch();

	const [
		convert,
		{ loading: converting }
	] = useMutation(ConvertOrderRequest, {
		refetchQueries: [ FetchOrderRequest, FetchAdminTasks ]
	});

	function handleConvert() {
		if(!validateProductAtIndex(state.index)) return;

		convert({
			variables: {
				requestId,
				products: state.products.map((product) => ({
					typeId: product.type?.id || "",
					sizeId: product.size?.id || "",
					media: product.media.map((m) => m.id),
					quantity: product.quantity,
					quantityDisassembly: product.quantityDisassembly,
					outletId: product.outlet?.id,
					fallbackOutletId: product.fallbackOutlet?.id
				}))
			}
		}).then((res) => {
			snack.enqueueSnackbar("Order request converted successfully", { variant: "success" });

			if(res.data?.ConvertOrderRequest.id) {
				dispatch(push(`/admin/orders/${res.data.ConvertOrderRequest.id}`));
			}
			onClose();
		}).catch(err => {
			console.error("Failed to convert order request", err);
			snack.enqueueSnackbar("Failed to convert order request", { variant: "error" });
		});
	}

	return (
		<DialogWithClose
			title="Convert to Order"
			onClose={onClose}
			content={(
				<ProcessItemContent
					index={state.index}
					media={request?.media || []}
					isBulkPickup={request?.isBulkPickup || false}
					marketId={request?.referer?.market?.id || ""}
					totalCount={request?.productCount || 0}
					state={state.products[ state.index ]}
					onUpdate={(id, product) => {
						const products = state.products.map((p) => {
							if(p.id === id) {
								return product;
							}
							return p;
						});

						setState({
							index: state.index,
							products
						});
					}}
				/>
			)}
			actions={(
				<Box direction="row" justify="between">
					{state.index === 0 && (
						<Button
							color="error"
							variant="outlined"
							onClick={onClose}
						>
							Cancel
						</Button>
					)}
					{state.index > 0 && (
						<Button
							color="secondary"
							variant="outlined"
							onClick={() => setState({
								index: state.index - 1,
								products: state.products
							})}
						>
							Previous
						</Button>
					)}
					{state.index < productCount - 1 && (
						<Button
							color="primary"
							variant="outlined"
							onClick={handleNext}
						>
							Next
						</Button>
					)}
					{(state.index === productCount - 1 || request?.isBulkPickup) && (
						<LoadingButton
							color="primary"
							variant="contained"
							onClick={handleConvert}
							loading={converting}

						>
							Convert
						</LoadingButton>
					)}
				</Box>
			)}
		/>
	);
};

export const ProcessItemContent: React.FC<{
	index: number;
	marketId: string;
	totalCount: number;
	isBulkPickup: boolean;
	state: ConvertOrderRequestProduct;
	media: { id: string, contentUrl: string; }[];
	onUpdate(id: string, product: ConvertOrderRequestProduct): void;
}> = ({ index, totalCount, onUpdate, state, media, marketId, isBulkPickup }) => {
	const { types } = useClassification(true);

	const filteredTypes = useMemo(() => {
		return (isBulkPickup)
			//FIXME: shouldn't use a magic string
			? types.filter((type) => type.tags.includes("cleanout"))
			: types;
	}, [ types, isBulkPickup ]);

	const selectedType = useMemo(() => {
		if(!state) {
			return null;
		}

		return filteredTypes.find((type) => type.id === state?.type?.id) || null;
	}, [ state, filteredTypes ]);

	return (
		<FormContainer shouldFocusError>
			<Box gap="medium">
				<Heading level="3" margin="none">
					{isBulkPickup
						? "Item Details (Bulk Pickup)"
						: `Item ${index + 1} of ${totalCount}`}
				</Heading>
				<ItemTypeElement
					type={selectedType}
					onChange={(event, type) => {
						onUpdate(state.id, {
							...state,
							type: type || undefined
						});
					}}
					includeHidden={isBulkPickup}
				/>
				{(selectedType && selectedType.sizes.length > 0) && (
					<ItemSizeElement
						type={selectedType}
						size={state?.size || null}
						onChange={(event, size) => {
							onUpdate(state.id, {
								...state,
								size: size || undefined
							});
						}}
						includeHidden={isBulkPickup}
					/>
				)}
				<TextFieldElement
					required
					name="quantity"
					label="Quantity"
					type="number"
					InputProps={{
						value: state?.quantity || 1,
						onChange: (event) => {
							onUpdate(state.id, {
								...state,
								quantity: parseInt(event.target.value) || 1
							});
						},
						readOnly: isBulkPickup
					}}
				/>
				{selectedType?.canDisassemble === true && (
					<TextFieldElement
						name="quantityDisassembly"
						label="Quantity Disassembly"
						type="number"
						InputProps={{
							value: state?.quantityDisassembly || 0,
							onChange: (event) => {
								onUpdate(state.id, {
									...state,
									quantityDisassembly: parseInt(event.target.value) || 0
								});
							},
							readOnly: isBulkPickup
						}}
					/>
				)}
				<OutletElement
					marketId={marketId}
					value={state?.outlet}
					onChange={(event, outlet) => {
						onUpdate(state.id, {
							...state,
							outlet: outlet || undefined
						});
					}}
				/>
				{state?.outlet?.disposition === ProductDisposition.Donation && (
					<OutletElement
						marketId={marketId}
						isFallback
						value={state?.fallbackOutlet}
						onChange={(event, outlet) => {
							onUpdate(state.id, {
								...state,
								fallbackOutlet: outlet || undefined
							});
						}}
					/>
				)}
				<Typography>
					Select Image(s)
				</Typography>
				<Box gap="small" direction="row">
					{media.map((media) => (
						<Box key={media.id}>
							<Box
								height="100px"
								width="100px"
								key={media.id}
								background="light-2"
								hoverIndicator
								onClick={(() => {
									if(!state) return;
									const isSelected = state?.media?.find((m) => m.id === media.id);

									if(isSelected) {
										onUpdate(state.id, {
											...state,
											media: state?.media?.filter((m) => m.id !== media.id)
										});
									}
									else {
										onUpdate(state.id, {
											...state,
											media: [
												...(state?.media || []),
												media
											]
										});
									}
								})}
								elevation={state?.media?.find((m) => m.id === media.id) ? "medium" : "none"}
								border={state?.media?.find((m) => m.id === media.id) ? { color: "brand", size: "small" } : undefined}
							>
								<Image
									fit="contain"
									src={media.contentUrl}
								/>
							</Box>
							<Box align="center" justify="center">
								<Checkbox
									onClick={(event) => {
										event.stopPropagation();
										onUpdate(state.id, {
											...state,
											media: [
												...(state?.media || []),
												media
											]
										});
									}}
									checked={state?.media?.find((m) => m.id === media.id) ? true : false}
								/>
							</Box>
						</Box>
					))}
				</Box>
			</Box>
		</FormContainer>
	);
};