import { gql } from "../../../__generated__";

export const DeletePaymentMethod = gql(/* GraphQL */`
	mutation DeletePaymentMethod(
		$paymentMethodId: String!
	) {
		DeletePaymentMethod(
			paymentMethodId: $paymentMethodId
		) {
			id
		}
	}
`);