import { gql } from "../../../__generated__";

export const InvitePartnerUser = gql(/* GraphQL */ `
	mutation InvitePartnerUser(
		$firstName: String!
		$lastName: String!
		$contact: String!
		$role: PartnerRole!
		$partnerId: String!
		$instanceId: String!
		$instanceRole: String
	) {
		InvitePartnerUser(
			firstName: $firstName
			lastName: $lastName
			contact: $contact
			role: $role
			partnerId: $partnerId
			instanceId: $instanceId
			instanceRole: $instanceRole
		) {
			id
		}
	}
`);