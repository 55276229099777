import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { PickupLike, ProductLike, RefererLike } from "../features/checkout/types";
import { AddressLike } from "../hooks";
import { loadFromStorage } from "../hooks/useSyncState";
import { PickupWindow } from "../graphql/__generated__/graphql";

export interface CheckoutProduct extends ProductLike {
	reviewed: boolean;
	media: string[];
}

export interface CheckoutState {
	unit: string;
	room: string;
	sessionId: string;
	address: AddressLike | null;
	referer: RefererLike | null;
	products: CheckoutProduct[];
	pickup: PickupLike | null;
	cutoffDate: string;
	scheduledDate: string;
	scheduledWindow: PickupWindow | null;
	paymentMethod: string | null;
	isUploadingMedia: boolean;
	productSearchTerm: string;
	isCustomSchedule: boolean;
	isCustomScheduleEnabled: boolean;
	isUpfrontPricingEnabled: boolean;
	isUpfrontScheduleEnabled: boolean;
}

const initialState: CheckoutState = loadFromStorage("checkout-session", {
	unit: "",
	room: "",
	sessionId: "",
	address: null,
	products: [],
	referer: null,
	pickup: null,
	cutoffDate: "",
	scheduledDate: "",
	scheduledWindow: null,
	paymentMethod: null,
	isUploadingMedia: false,
	productSearchTerm: "",
	isCustomSchedule: false,
	isCustomScheduleEnabled: false,
	isUpfrontPricingEnabled: false,
	isUpfrontScheduleEnabled: false
});

type ProductClassification = { typeId: string, sizeId: string; };

export function filterByClassification(classification: ProductClassification, invert?: boolean) {
	return (product: ProductLike) => {
		const result = (product.type && product.type.id) === classification.typeId && (!classification.sizeId || (product.size && product.size.id === classification.sizeId));

		return (invert)
			? !result
			: result;
	};
}

export const checkoutSlice = createSlice({
	name: "checkout",
	initialState,
	reducers: {
		setSessionId: (state, action: PayloadAction<string>) => {
			state.sessionId = action.payload;
		},
		setProducts: (state, action: PayloadAction<CheckoutProduct[]>) => {
			state.products = [
				...action.payload
			];
		},
		insertProduct: (state, action: PayloadAction<CheckoutProduct>) => {
			state.products = [
				...state.products,
				action.payload
			];
		},
		updateProduct: (state, action: PayloadAction<CheckoutProduct>) => {
			const index = state.products.findIndex(item => item.id === action.payload.id);

			if(index !== -1) {
				state.products = [
					...state.products.slice(0, index),
					action.payload,
					...state.products.slice(index + 1)
				];
			}
		},
		incrementProduct: (state, action: PayloadAction<ProductClassification>) => {
			const index = state.products.findIndex(filterByClassification(action.payload));

			if(index !== -1) {
				state.products = [
					...state.products.slice(0, index),
					{
						...state.products[ index ],
						quantity: state.products[ index ].quantity + 1
					},
					...state.products.slice(index + 1)
				];
			}
		},
		decrementProduct: (state, action: PayloadAction<ProductClassification>) => {
			const index = state.products.findIndex(filterByClassification(action.payload));

			if(index !== -1 && state.products[ index ]?.quantity > 0) {
				state.products = [
					...state.products.slice(0, index),
					{
						...state.products[ index ],
						quantity: Math.max(0, state.products[ index ].quantity - 1)
					},
					...state.products.slice(index + 1)
				];
			}
		},
		removeProduct: (state, action: PayloadAction<ProductClassification>) => {
			state.products = [
				...state.products.filter(filterByClassification(action.payload, true))
			];
		},
		setPickup: (state, action: PayloadAction<PickupLike | null>) => {
			state.pickup = action.payload;
		},
		setReferer: (state, action: PayloadAction<RefererLike | null>) => {
			state.referer = action.payload;
		},
		setAddress(state, action: PayloadAction<AddressLike | null>) {
			state.address = action.payload;
			state.room = action.payload?.room || state.room;
			state.unit = action.payload?.addressLineTwo || state.unit;
		},
		setScheduledDate: (state, action: PayloadAction<string>) => {
			state.scheduledDate = action.payload;
		},
		setCutoffDate: (state, action: PayloadAction<string>) => {
			state.cutoffDate = action.payload;
		},
		setScheduledWindow: (state, action: PayloadAction<PickupWindow>) => {
			state.scheduledWindow = action.payload;
		},
		setCustomSchedule: (state, action: PayloadAction<boolean>) => {
			state.isCustomSchedule = action.payload;
		},
		setCustomScheduleEnabled: (state, action: PayloadAction<boolean>) => {
			state.isCustomScheduleEnabled = action.payload;
		},
		setUpfrontPricingEnabled: (state, action: PayloadAction<boolean>) => {
			state.isUpfrontPricingEnabled = action.payload;
		},
		setUpfrontScheduleEnabled: (state, action: PayloadAction<boolean>) => {
			state.isUpfrontScheduleEnabled = action.payload;
		},
		upsertCheckoutSession(state, action: PayloadAction<Partial<CheckoutState>>) {
			state = {
				...state,
				...action.payload
			};
		},
		setProductSearchTerm(state, action: PayloadAction<string>) {
			state.productSearchTerm = action.payload;
		},
		setPaymentMethod(state, action: PayloadAction<string>) {
			state.paymentMethod = action.payload;
		}
	}
});

export const {
	setSessionId,
	setProducts,
	setReferer,
	setPickup,
	setAddress,
	setCutoffDate,
	insertProduct,
	removeProduct,
	updateProduct,
	incrementProduct,
	decrementProduct,
	setCustomSchedule,
	setPaymentMethod,
	setScheduledWindow,
	setCustomScheduleEnabled,
	setScheduledDate,
	setUpfrontPricingEnabled,
	setUpfrontScheduleEnabled,
	setProductSearchTerm,
	upsertCheckoutSession
} = checkoutSlice.actions;

export const selectCheckoutState = (state: RootState): CheckoutState => state.checkout;

export default checkoutSlice.reducer;

export const findProductInState = ({ typeId, sizeId }: ProductClassification) => (state: RootState) => {
	return state.checkout.products.find(filterByClassification({ typeId, sizeId }));
};

export const selectProductSearchTerm = (state: RootState) => state.checkout.productSearchTerm;