import { ReactNode } from "react";
import { Navigate, RouteProps } from "react-router";

export abstract class SidebarRoute {
	abstract toRouteProps(): RouteProps;
}

export class SidebarRouteItem extends SidebarRoute {
	constructor(
		protected readonly relativePath: string,
		protected readonly view: ReactNode,
		protected readonly listItem: ReactNode,
		public readonly isPrimary: boolean
	) {
		super();
	}

	toRouteProps(): RouteProps {
		return {
			path: this.relativePath,
			element: this.view
		};
	}

	getListItem() {
		return this.listItem;
	}
}

export class SidebarRedirectRouteItem extends SidebarRoute {
	constructor(
		protected readonly path: string,
		protected readonly to: string,
	) {
		super();
	}

	toRouteProps(): RouteProps {
		return {
			path: this.path,
			element: <Navigate to={this.to} />
		};
	}
}