import { Box, Grid, Heading, Spinner } from "grommet";
import { useForm } from "react-hook-form";
import { useLogin } from "../../../auth";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { usePageTitle, useWindowDimensions } from "../../../hooks";
import { formatPhoneNumber } from "../../../helpers";
import { SplashScreen } from "../../../components";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { ContactType } from "../../../graphql/__generated__/graphql";
import { Autorenew, Email, PhoneIphone, Save } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { ContactFormController, ContactListItem } from "../components";
import { LoadingButton } from "@mui/lab";

export const PersonalInformationView: React.FC = (props) => {
	usePageTitle("Personal Information");
	const { user, loading } = useLogin();

	const formContext = useForm({
		defaultValues: {
			firstName: user?.firstName ?? "",
			lastName: user?.lastName ?? "",
			primaryPhoneNumber: user?.primaryPhoneNumber ?? "",
			primaryEmailAddress: user?.primaryEmailAddress ?? "",
		}
	});

	useEffect(() => {
		if(user) {
			formContext.reset({
				firstName: user.firstName,
				lastName: user.lastName,
				primaryPhoneNumber: user.primaryPhoneNumber,
				primaryEmailAddress: user.primaryEmailAddress,
			});
		}
	}, [ user ]);

	const formValues = formContext.watch();

	const { size } = useWindowDimensions();

	const columns = useMemo(() => {
		switch(size) {
			case "xlarge": {
				return 2;
			}
			default: {
				return 1;
			}
		}
	}, [ size ]);

	const [ hasChanges, setHasChanges ] = useState(false);
	const [ isUpdating, setIsUpdating ] = useState(false);
	const [ updateContactType, setUpdateContactType ] = useState<ContactType | null>(null);

	function handleUpdate(): void {
		setIsUpdating(true);
		setTimeout(() => {
			setIsUpdating(false);
			setHasChanges(false);
		}, 1000);
	}

	useEffect(() => {
		setHasChanges(true);
	}, [ formValues ]);

	return (
		<SplashScreen visible={!user}>
			{updateContactType && (
				<ContactFormController
					type={updateContactType}
					kind="SELECT"
					onClose={() => setUpdateContactType(null)}
				/>
			)}
			<Box pad="small">
				<FormContainer formContext={formContext}>
					<Grid columns={{ count: columns, size: "auto" }} gap="medium">
						<Box gap="medium">
							<Box
								gap="medium"
								align="center"
								justify="between"
								direction={size === "small" ? "column" : "row"}
							>
								<Box>
									<Heading level="3" margin="none">Personal Details</Heading>
								</Box>
								{hasChanges && (
									<Box>
										<LoadingButton
											size="small"
											color="success"
											variant="outlined"
											loading={isUpdating}
											loadingPosition="end"
											endIcon={<Save />}
											onClick={handleUpdate}
										>
											Save Changes
										</LoadingButton>
									</Box>
								)}
							</Box>
							<Box gap="medium">
								<TextFieldElement
									label="First Name"
									name="firstName"
									disabled={isUpdating}
									InputProps={{
										endAdornment: (isUpdating && (
											<InputAdornment position="end">
												<Spinner />
											</InputAdornment>
										))
									}}
								/>
								<TextFieldElement
									label="Last Name"
									name="lastName"
									disabled={isUpdating}
									InputProps={{
										endAdornment: (isUpdating && (
											<InputAdornment position="end">
												<Spinner />
											</InputAdornment>
										))
									}}
								/>
								<TextFieldElement
									label="Primary Phone Number"
									name="primaryPhoneNumber"
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												<PhoneIphone />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={() => setUpdateContactType(ContactType.Phone)}>
													<Autorenew />
												</IconButton>
											</InputAdornment>
										),
										value: formValues.primaryPhoneNumber ? formatPhoneNumber(formValues.primaryPhoneNumber) : ""
									}}
								/>
								<TextFieldElement
									label="Primary Email Address"
									name="primaryEmailAddress"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Email />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={() => setUpdateContactType(ContactType.Email)}>
													<Autorenew />
												</IconButton>
											</InputAdornment>
										),
										readOnly: true
									}}
								/>
							</Box>
						</Box>
						<Box gap="medium" margin={columns === 1 ? { bottom: "medium" } : undefined}>
							<Box
								gap="medium"
								align="center"
								justify="between"
								direction={size === "small" ? "column" : "row"}
							>
								<Box>
									<Heading level="3" margin="none">Contact Details</Heading>
								</Box>
								<Box direction="row" gap="small">
									<Box>
										<Button
											size="small"
											color="success"
											variant="outlined"
											startIcon={<PhoneIphone />}
											onClick={() => {
												setUpdateContactType(ContactType.Phone);
											}}
										>
											NEW
										</Button>
									</Box>
									<Box>
										<Button
											size="small"
											color="success"
											variant="outlined"
											startIcon={<Email />}
											onClick={() => {
												setUpdateContactType(ContactType.Email);
											}}
										>
											NEW
										</Button>
									</Box>
								</Box>
							</Box>
							<Box gap="medium">
								{(user?.contacts ?? []).map(contact => (
									<ContactListItem
										key={contact.id}
										value={contact.value}
										type={contact.type}
										isPrimary={contact.isPrimary}
										isVerified={contact.isVerified}
										isSelected={false}
										isSelecting={false}
										onSelect={() => { /* */ }}
									/>
								))}
							</Box>
						</Box>
					</Grid>
				</FormContainer>
			</Box>
		</SplashScreen>
	);
};