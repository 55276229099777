import { SvgIcon, SvgIconProps } from "@mui/material";

	export const UtilityCabinet: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1137.5 1045h-1075c-27.5 0-50-22.5-50-50v-932.5c0-27.5 22.5-50 50-50h1077.5c27.5 0 50 22.5 50 50v935c-2.5 27.5-25 47.5-52.5 47.5zm-1027.5-97.5h980v-837.5h-980z"/>
  <path d="m600 1045c-27.5 0-50-22.5-50-50v-932.5c0-27.5 22.5-50 50-50s50 22.5 50 50v935c0 27.5-22.5 47.5-50 47.5z"/>
  <path d="m437.5 600c-27.5 0-50-22.5-50-50v-45c0-27.5 22.5-50 50-50s50 22.5 50 50v45c-2.5 27.5-22.5 50-50 50z"/>
  <path d="m762.5 600c-27.5 0-50-22.5-50-50v-45c0-27.5 22.5-50 50-50s50 22.5 50 50v45c0 27.5-22.5 50-50 50z"/>
  <path d="m195 1187.5c-27.5 0-50-22.5-50-50v-142.5c0-27.5 22.5-50 50-50s50 22.5 50 50v142.5c0 27.5-22.5 50-50 50z"/>
  <path d="m1005 1187.5c-27.5 0-50-22.5-50-50v-142.5c0-27.5 22.5-50 50-50s50 22.5 50 50v142.5c-2.5 27.5-25 50-50 50z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};