import { LinearProgress } from "@mui/material";
import { Box, Heading } from "grommet";
import { HaulerPickupRequestListItem } from "..";
import { useMemo } from "react";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";
import { useMutation } from "@apollo/client";
import { AcceptHaulerPickupRequest, FetchUserHauler, RejectHaulerPickupRequest } from "../../../../graphql";
import { useHauler } from "../../hooks";
import { Pagination } from "../../../../components";

export const HaulerPickupRequestsController: React.FC = () => {
	const dispatch = useAppDispatch();
	const { loading, hauler } = useHauler();

	const requests = useMemo(() => {
		return hauler?.pendingRequests ?? [];
	}, [ hauler ]);

	const [
		acceptPickupRequest,
		{ data: acceptPickupRequestData, loading: acceptPickupRequestLoading, error: acceptPickupRequestError }
	] = useMutation(AcceptHaulerPickupRequest, { refetchQueries: [ FetchUserHauler ] });

	const [
		rejectPickupRequest,
		{ data: rejectPickupRequestData, loading: rejectPickupRequestLoading, error: rejectPickupRequestError }
	] = useMutation(RejectHaulerPickupRequest, { refetchQueries: [ FetchUserHauler ] });


	return (
		<Box flex margin="small">
			<Heading margin="none" level="3">
				Pickup Requests
			</Heading>
			{loading && (
				<LinearProgress />
			)}
			{requests.length === 0 && (
				<Box align="center" justify="center">
					<Heading level="4">No pending requests</Heading>
				</Box>
			)}
			{requests.length > 0 && (
				<Box flex>
					<Pagination pageSize={5}>
						{requests.map((request) => (
							<HaulerPickupRequestListItem
								key={request.id}
								onAccept={() => {
									acceptPickupRequest({
										variables: {
											requestId: request.id
										}
									});
								}}
								isAccepting={acceptPickupRequestLoading}
								onReject={() => {
									rejectPickupRequest({
										variables: {
											requestId: request.id
										}
									});
								}}
								isRejecting={rejectPickupRequestLoading}
								onSelect={() => {
									dispatch(push(`/hauler/pickups/${request.pickup.id}`));
								}}
								pickupId={request.pickup.id}
								scheduledDate={request.pickup.scheduledDate ?? ""}
								scheduledWindow={request.pickup.window}
								stopCount={request.pickup.stopCount}
								productCount={request.pickup.incomingProductCount}
								marketCode={request.pickup.market.code}
								amountOffered={request.amountOffered || 0}
							/>
						))}
					</Pagination>
				</Box>
			)}
		</Box>
	);
};