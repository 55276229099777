import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { CreateOrderProductDialog } from "./CreateOrderProductDialog";
import { useUnifiedOrder } from "../../../../graphql";


export const CreateOrderProductButton: React.FC<{
	orderId: string;
}> = ({ orderId }) => {
	const { isEditable, loading } = useUnifiedOrder(orderId, [ "user" ]);

	return (
		<CreateOrderProductDialog
			orderId={orderId}
			activatorButton={(
				<LoadingButton
					size="small"
					color="primary"
					variant="contained"
					startIcon={<Add />}
					loading={loading}
					disabled={!isEditable}
				>
					Add Item
				</LoadingButton>
			)} />
	);
};
