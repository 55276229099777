import { Box, Grid, Heading } from "grommet";
import { useEffect } from "react";
import { useUnifiedOrder } from "../../../../graphql";
import { usePageTitle, useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { LinearProgress } from "@mui/material";
import { CreateOrderProductButton, OrderDetails, RemoveProductDialog } from "../../components";
import { useSnackbar } from "notistack";
import { Pagination, ProductListItem } from "../../../../components";

export const OrderDetailsView: React.FC = () => {
	usePageTitle("Dashboard");
	const snack = useSnackbar();
	const { size } = useWindowDimensions();
	const orderId = useRequiredPathParam("orderId", "/dashboard/orders");
	const { order, error, loading } = useUnifiedOrder(orderId, [ "user" ]);

	useEffect(() => {
		if(error) {
			snack.enqueueSnackbar("We ran into an issue loading your information", {
				variant: "error"
			});
		}
	}, [ error ]);

	return (
		<Box margin="medium" gap="small">
			{loading && (
				<LinearProgress />
			)}
			<Grid columns={{ count: size === "xlarge" ? 2 : 1, size: "auto" }} gap="small">
				<Box gap="small">
					<Box height="40px" justify="center">
						<Heading level="3" margin="none">
							Order Details
						</Heading>
					</Box>
					<OrderDetails
						orderId={orderId}
					/>
				</Box>
				<Box gap="small">
					<Box direction="row" justify="between" align="center" height="40px">
						<Heading level="3" margin="none">
							Order Items
						</Heading>
						<Box align="end" justify="center">
							<CreateOrderProductButton
								orderId={orderId}
							/>
						</Box>
					</Box>
					<UserDashboardOrderProducts
						orderId={orderId || ""}
					/>
				</Box>
			</Grid>
		</Box>
	);
};

interface UserDashboardOrderProductsProps {
	orderId: string;
}

export const UserDashboardOrderProducts: React.FC<UserDashboardOrderProductsProps> = (props) => {
	const { order, isEditable } = useUnifiedOrder(props.orderId, [ "user" ]);

	return (
		<Box flex>
			<Pagination pageSize={4}>
				{(order?.products ?? []).map((product) => (
					<ProductListItem
						key={product.id}
						productId={product.id}
					>
						{isEditable && (
							<RemoveProductDialog
								productId={product.id}
							/>
						)}
					</ProductListItem>
				))}
			</Pagination>
		</Box>
	);
};