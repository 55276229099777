import moment from "moment";
import { useMemo } from "react";
import { orders } from "../data";
import { filterByTimePeriod } from "../helpers";
import { useAppSelector } from "../../store";
import { selectBuildingFilter, selectTimePeriodCustom, selectTimePeriodFilter, selectZipCodeFilter } from "../../store/reporting";

export const useWasteDiversion = (isMultifamily?: boolean) => {
	const zipCodeFilter = useAppSelector(selectZipCodeFilter);
	const buildingFilter = useAppSelector(selectBuildingFilter);
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);
	const timePeriodCustom = useAppSelector(selectTimePeriodCustom);

	const filteredOrders: typeof orders = useMemo(() => {
		const multifamilyFilter = (isMultifamily && buildingFilter.length > 0) ?
			orders.filter(order => order.isMultifamily && buildingFilter.includes(order.building))
			: orders;

		const zipCodeFilterResult = zipCodeFilter.length > 0
			? multifamilyFilter.filter(order => zipCodeFilter.includes(order.zipCode))
			: multifamilyFilter;

		return filterByTimePeriod(zipCodeFilterResult, timePeriodFilter, timePeriodCustom);
	}, [ timePeriodFilter, zipCodeFilter, buildingFilter, isMultifamily ]);

	const wasteDiverted = useMemo(() => {
		return filteredOrders.reduce((acc, order) => {
			return acc + order.weightDiverted;
		}, 0);
	}, [ filteredOrders ]);

	const wasteGenerated = useMemo(() => {
		return filteredOrders.reduce((acc, order) => {
			return acc + order.weightGenerated;
		}, 0);
	}, [ filteredOrders ]);

	const wasteLandfilled = useMemo(() => {
		return wasteGenerated - wasteDiverted;
	}, [ wasteDiverted, wasteGenerated ]);

	const diversionRate = useMemo(() => {
		return ((wasteDiverted / wasteGenerated) * 100).toFixed(2);
	}, [ wasteDiverted, wasteGenerated ]);

	const emissionsReduction = useMemo(() => {
		const kgCo2ePerPound = 1.445;
		return wasteDiverted * kgCo2ePerPound;
	}, [ wasteDiverted ]);

	const averageTurnaroundTime = useMemo(() => {
		const totalTurnaroundTime = filteredOrders.reduce((acc, order) => {
			return acc + moment(order.scheduledDate).diff(moment(order.date), "days");
		}, 0);

		return totalTurnaroundTime / filteredOrders.length;
	}, [ filteredOrders ]);

	return {
		diversionRate,
		wasteGenerated,
		wasteDiverted,
		wasteLandfilled,
		filteredOrders,
		emissionsReduction,
		averageTurnaroundTime
	};
};