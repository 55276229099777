import React from "react";
import { useAdminScopedOrder } from "../../../../../graphql";
import { InstanceLinksDialog } from "../InstanceLinksDialog";

export const OrderLinksDialog: React.FC<{
	orderId: string;
	activatorButton: React.ReactNode;
}> = ({ orderId, activatorButton }) => {
	const { order } = useAdminScopedOrder(orderId);

	return (
		<InstanceLinksDialog
			domain={order?.instance?.domain || ""}
			activatorButton={activatorButton}
		/>
	);
};