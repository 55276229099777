import { SvgIcon, SvgIconProps } from "@mui/material";

	export const FloorLamp: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="m856.88 1129.4-1.4102-6.3008c-12.277-54.996-63.023-94.914-120.68-94.914l-82.125 0.003906v-574.17h178.6c8.0625 0 15.684-3.7031 20.656-10.051 4.9727-6.3438 6.7578-14.625 4.8438-22.453l-89.016-362.91c-2.8789-11.738-13.402-19.996-25.492-19.996h-141.66-1.1914-141.66c-12.09 0-22.621 8.2578-25.492 19.996l-89.023 362.91c-1.9219 7.8281-0.13672 16.109 4.8438 22.453s12.594 10.051 20.656 10.051h63.059v109.97c-18.008 7.4023-30.727 25.117-30.727 45.766 0 27.277 22.191 49.469 49.477 49.469 27.277 0 49.469-22.191 49.469-49.469 0-20.648-12.719-38.355-30.719-45.758v-109.98h81.613v574.18l-85.707 0.003907c-57.652 0-108.4 39.914-120.67 94.898l-1.4102 6.3164c-1.7383 7.7773 0.14062 15.922 5.1289 22.141 4.9805 6.2266 12.516 9.8477 20.484 9.8477h462.55c7.9727 0 15.504-3.6133 20.484-9.8477 4.9844-6.2227 6.8672-14.367 5.1211-22.152zm-406.34-507.68c-6.6094 0-11.977-5.3711-11.977-11.969 0-6.6094 5.3711-11.977 11.977-11.977 6.6016 0 11.969 5.3711 11.969 11.977-0.003906 6.6016-5.3672 11.969-11.969 11.969zm27.797-530.61h121.06 1.1914 121.07l76.141 310.41h-395.62zm110.07 362.91h26.766v574.18h-26.766zm-180.66 654.87c13.078-17.211 34.281-28.199 57.449-28.199h269.61c23.168 0 44.363 10.988 57.449 28.199z" />
</svg>
			</SvgIcon>
		);
	};