import { gql } from "../../../__generated__";

export const AssignWasteContainer = gql(/* GraphQL */ `
	mutation AssignWasteContainer(
		$name: String!
		$location: String!
		$volume: Float!
		$volumeUnit: WasteContainerVolumeUnit!
		$collectionFrequency: CollectionFrequencyInput!
		$partnerId: String!
		$materialId: String!
		$containerId: String!
		$disposition: ProductDisposition!
	) {
		AssignWasteContainer(
			name: $name
			location: $location
			volume: $volume
			volumeUnit: $volumeUnit
			collectionFrequency: $collectionFrequency
			partnerId: $partnerId
			materialId: $materialId
			containerId: $containerId
			disposition: $disposition
		) {
			id
		}
	}
`);	