import { DashboardListItem } from "../../../../../components";
import { useAdminTasks } from "../../../hooks";

export const GlobalAdminDashboardListItem: React.FC = () => {
	const { tasks } = useAdminTasks();

	return (
		<DashboardListItem
			notificationCount={tasks?.length ?? 0}
			absolutePath={"/admin/dashboard"}
		/>
	);
};