import { gql } from "../../../__generated__";

export const AssignPricingPolicy = gql(/* GraphQL */ `
	mutation AssignPricingPolicy(
		$partnerId: String!
		$policyId: String!
	) {
		AssignPricingPolicy(
			partnerId: $partnerId
			policyId: $policyId
		) {
			id
		}
	}
`);