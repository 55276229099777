import { Chip } from "@mui/material";
import { OrderStatus } from "../../../../graphql/__generated__/graphql";
import { useMemo } from "react";
import { Cancel, ChangeCircle, CheckCircle } from "@mui/icons-material";
import { useAdminScopedOrder } from "../../../../graphql";

export function useOrderStatusChip(orderId: string) {
	const { order } = useAdminScopedOrder(orderId);

	return useMemo(() => {
		if(!order) return null;

		switch(order.status) {
			case OrderStatus.Cancelled: {
				return (
					<Chip
						color="error"
						label={"Cancelled"}
						icon={<Cancel />} />
				);
			}
			case OrderStatus.Pending: {
				return (
					<Chip
						color="warning"
						label={"Pending"}
						icon={<CheckCircle />} />
				);
			}
			case OrderStatus.InProgress: {
				return (
					<Chip
						color="warning"
						label={"In Progress"}
						icon={<ChangeCircle />} />
				);
			}
			case OrderStatus.Submitted: {
				if(order.schedulePending && !order.scheduled) {
					return (
						<Chip
							color="warning"
							label={"Ready to Schedule"}
							icon={<CheckCircle />} />
					);
				}

				if(order.scheduled) {
					return (
						<Chip
							color="success"
							label={"Scheduled"}
							icon={<CheckCircle />} />
					);
				}

				return (
					<Chip
						color="warning"
						label={"Submitted"}
						icon={<CheckCircle />} />
				);
			}
			case OrderStatus.Completed: {
				return (
					<Chip
						color="success"
						label={"Completed"}
						icon={<CheckCircle />} />
				);
			}
		}
	}, [ order ]);
}
