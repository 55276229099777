import { gql } from "@apollo/client";

export const ProductFragment = gql`
	fragment ProductFragment on Product {
		...Product_MediaFragment
		...Product_HeaderFragment
		...Product_ClassificationFragment
		outlet { 
			id 
			name 
			disposition
		}
		fallbackOutlet {
			id
			name
			disposition
		}
		requiresDisassembly
		requiresDisassemblyCount
	}
`;