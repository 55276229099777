import React from "react";
import { Box } from "grommet";
import { useAppDispatch } from "../../../../../store";
import { push } from "redux-first-history";
import { useRecentOrders } from "../../hooks/useRecentOrders";
import { OrdersDataTable } from "./OrdersDataTable";
import { defaultColumns } from "./columns";
import { TabListItem } from "../../../../../components";
import { GridColDef } from "@mui/x-data-grid";

export const RecentlyViewedOrdersDataTable: React.FC<{
	columns?: GridColDef[];
}> = ({ columns }) => {
	const dispatch = useAppDispatch();
	const { orders, loading } = useRecentOrders();

	return (
		<Box height="100%">
			<OrdersDataTable
				loading={loading}
				columns={columns || defaultColumns}
				orders={orders}
				onRowSelect={(order) => {
					dispatch(push(`/admin/orders/${order.id}`));
				}} />
		</Box>
	);
};

export const RecentlyViewedOrdersTabFactory = (columns?: GridColDef[]): TabListItem => ({
	label: "Recently Viewed",
	value: "recently-viewed",
	component: (
		<RecentlyViewedOrdersDataTable
			columns={columns}
		/>
	)
});