import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Nightstand: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m1053.6 37.117h-907.25c-38.488 0-69.801 31.312-69.801 69.801s31.312 69.801 69.801 69.801h26.301v959.92c0 14.496 11.754 26.25 26.25 26.25h88.051c13.441 0 24.711-10.156 26.109-23.527l54.758-525.23h464.32l54.758 525.23c1.4023 13.363 12.66 23.527 26.109 23.527h88.051c14.496 0 26.25-11.754 26.25-26.25l-0.003906-548.76v-411.16h26.297c38.488 0 69.809-31.312 69.809-69.801 0-38.492-31.32-69.805-69.812-69.805zm-790.31 1073.3h-38.137v-496.25h89.879zm711.52 0h-38.145l-51.734-496.25h89.879zm0-548.75h-749.66v-384.91h749.66v384.91zm78.793-437.41h-907.25c-9.5391 0-17.301-7.7617-17.301-17.301s7.7617-17.301 17.301-17.301h907.25c9.5391 0 17.309 7.7617 17.309 17.301s-7.7617 17.301-17.312 17.301z" />
		<path
			d="m295.23 519.13h609.55c14.496 0 26.25-11.754 26.25-26.25v-247.41c0-14.496-11.754-26.25-26.25-26.25h-609.55c-14.496 0-26.25 11.754-26.25 26.25v247.41c0 14.496 11.746 26.25 26.25 26.25zm26.25-247.41h557.05v194.91h-557.05z" />
		<path
			d="m515.77 346.36c-12.578 0-22.816 10.23-22.816 22.809 0 12.586 10.23 22.816 22.816 22.816 12.586 0 22.816-10.23 22.816-22.816 0-12.578-10.23-22.809-22.816-22.809z" />
		<path
			d="m703.27 350.42h-121.88c-10.359 0-18.75 8.3906-18.75 18.75s8.3906 18.75 18.75 18.75h121.88c10.359 0 18.75-8.3906 18.75-18.75 0-10.355-8.3906-18.75-18.75-18.75z" />
	</g>
</svg>
			</SvgIcon>
		);
	};