import React from "react";
import { History } from "history";
import { Router } from "react-router-dom";

export interface HistoryRouterProps {
	basename?: string;
	children?: React.ReactNode;
	history: History;
}

export function HistoryRouter({ basename, children, history }: HistoryRouterProps) {
	const [ state, setState ] = React.useState({
		action: history.action,
		location: history.location,
	});

	React.useLayoutEffect(() => {
		history.listen((update) => {
			setState({
				action: update.action,
				location: update.location
			});
		});
	}, [ history ]);

	return (
		<Router
			basename={basename}
			location={state.location}
			navigationType={state.action}
			navigator={history}
		>
			{children}
		</Router>
	);
}