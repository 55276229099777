import moment from "moment-timezone";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface LocalizationProviderProps {
	children: React.ReactNode;
}

export const LocalizationProvider: React.FC<LocalizationProviderProps> = (props) => {
	return (
		<MuiLocalizationProvider
			adapterLocale="en"
			dateAdapter={AdapterMoment}
			dateLibInstance={moment}
		>
			{props.children}
		</MuiLocalizationProvider>
	);
};