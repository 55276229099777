import { Box, Heading } from "grommet";
import { AccordionController, BuildingListItem, CustomGrid, DialogWithActivatorButton, ResidentMoveOutDateListItem, ResidentUnitListItem, ViewContainer } from "../../../../components";
import { Sizes, useInstance, useWindowDimensions } from "../../../../hooks";
import { Button, ButtonProps, LinearProgress, List, Typography } from "@mui/material";
import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react";
import { useResident } from "../../../../auth";
import { useActiveOrders } from "../../hooks";
import { OrderSummaryList, UpcomingPickupsList } from "../../components";
import { push, replace } from "redux-first-history";
import { useAppDispatch } from "../../../../store";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";

export const ScheduleNowButton: React.FC<{
	label?: string;
	refererInterrupt?: React.ReactNode;
	variant?: ButtonProps[ "variant" ];
	color?: ButtonProps[ "color" ];
	size?: ButtonProps[ "size" ];
	listenForScheduleTrigger?: boolean;
}> = (props) => {
	const dispatch = useAppDispatch();

	function handleRedirect(): void {
		dispatch(push("/checkout"));
	}

	const [ query ] = useSearchParams();

	useEffect(() => {
		if(query.get("schedule") && props.listenForScheduleTrigger) {
			const newQuery = new URLSearchParams(query);
			newQuery.delete("schedule");
			dispatch(replace(location.pathname + "?" + newQuery.toString()));
			handleRedirect();
		}
	}, [ query ]);

	return (
		<LoadingButton
			size={props.size ?? undefined}
			color={props.color ?? "primary"}
			variant={props.variant ?? "contained"}
			onClick={handleRedirect}
			loading={false}
		>
			{props.label ?? "Schedule Now"}
		</LoadingButton>
	);
};

export const AvailablePickupDatesContainer: React.FC = () => {
	const { size } = useWindowDimensions();

	return (
		<Box gap="small" flex height={size === "small" ? undefined : "medium"}>
			{size !== "small" && (
				<Box direction="row" justify="between">
					<Box justify="center">
						<Heading level="3" margin="none">
							Available Pickup Dates
						</Heading>
					</Box>
					<Box align="center">
						<ScheduleNowButton
							variant="outlined"
							size="small"
						/>
					</Box>
				</Box>
			)}
			{size !== "small" && (
				<UpcomingPickupsList

				/>
			)}
			<Box align="center" justify="center" flex>
				<ScheduleNowButton
					listenForScheduleTrigger
					label="Schedule Your Pickup"
				/>
				<Typography variant="caption">
					express pickups available
				</Typography>
			</Box>
		</Box>
	);
};

export const ResidenceDashboardView: React.FC = () => {
	const { instance } = useInstance();
	const { user, building, resident } = useResident();

	return (
		<ViewContainer>
			<Box gap="medium" height="100%" style={{ display: "block" }}>
				<CustomGrid gap="medium" breakpoint={Sizes.XLARGE}>
					<Box gap="small">
						<Box direction="row" justify="between">
							<Box justify="center">
								<Heading level="3" margin="none">
									Building Details
								</Heading>
							</Box>
							<Box align="center" style={{ visibility: "hidden" }}>
								<Button size="small" variant="contained" color="primary">
									Schedule Now
								</Button>
							</Box>
						</Box>
						<BuildingDetails

						/>
					</Box>
					<AvailablePickupDatesContainer

					/>
				</CustomGrid>
				<Box gap="small" style={{ display: "block" }}>
					<Heading level="3" margin="none">
						Your Scheduled Pickups
					</Heading>
					{(user && instance) && (
						<ScheduledPickupsList
							userId={user.id}
							instanceId={instance.id}
						/>
					)}
				</Box>
			</Box>
		</ViewContainer>
	);
};

export const BuildingDetails: React.FC = (props) => {
	const { instance } = useInstance();
	const { resident, building, loading } = useResident();

	return (
		<Box gap="small">
			{loading && <LinearProgress />}
			<List>
				<BuildingListItem
					loading={loading}
					buildingName={building?.name || ""}
					logoUrl={instance?.customizations?.logoUrl || ""}
				/>
				<ResidentUnitListItem
					loading={loading}
					unit={resident?.unit || ""}
					unitPrefix={building?.unitPrefix || ""}
				/>
				{!!resident?.moveOutDate && (
					<ResidentMoveOutDateListItem
						moveOutDate={resident?.moveOutDate || null}
					/>
				)}
			</List>
			{/* <Box align="center">
				<Typography>
					Need some more help?
				</Typography>
				<ResidenceFrequentlyAskedQuestionsDialog
					activatorButton={(
						<Anchor
							label="View our FAQs"
						/>
					)}
				/>
			</Box> */}
		</Box>
	);
};

export const ScheduledPickupsList: React.FC<{
	userId: string;
	instanceId: string;
}> = ({ userId, instanceId }) => {
	const { orders, loading } = useActiveOrders(userId, instanceId);

	return (
		<Fragment>
			<OrderSummaryList
				loading={loading}
				orders={orders.map((order) => order.id)}
			/>
			{orders.length === 0 && (
				<Box pad="small" align="center" justify="center" margin={{ bottom: "medium" }}>
					<Typography>
						No pickups found
					</Typography>
				</Box>
			)}
		</Fragment>
	);
};

export const ResidenceFrequentlyAskedQuestionsDialog: React.FC<{
	activatorButton: ReactElement;
}> = ({ activatorButton }) => {
	return (
		<DialogWithActivatorButton
			title="FAQs"
			activatorButton={activatorButton}
		>
			<Box>
				{faqs.map((faq, index) => (
					<FAQItem
						key={index}
						question={faq.question}
						answer={faq.answer}
					/>
				))}
			</Box>
		</DialogWithActivatorButton>
	);
};

interface FAQ {
	question: string;
	answer: ReactNode;
}

export const FAQItem: React.FC<FAQ> = ({ question, answer }) => {
	const [ isExpanded, setIsExpanded ] = useState(false);

	return (
		<AccordionController
			name={question}
			isExpanded={isExpanded}
			onChange={() => {
				setIsExpanded(!isExpanded);
			}}
			summary={(
				<Typography>
					{question}
				</Typography>
			)}
			details={answer}
		/>
	);
};

const faqs: FAQ[] = [
	{
		question: "How do I make changes to my order?",
		answer: (
			<Box>
				<Typography>

				</Typography>
			</Box>
		)
	},
	{
		question: "Will my item(s) be donated?",
		answer: (
			<Box>
				<Typography>
					Since opening, we’ve helped to donate over 120,000 pounds of furniture to local non-profit organizations.
				</Typography>
				<Typography>
					Although we can’t guarantee your items will be accepted by a donation center, we work closely with many local non-profit organizations needing furniture, clothing, and other supplies.
				</Typography>
				<Typography>
					As a social impact organization, we make every effort to donate your items. We even built an artificial intelligence model that determines if and where an item can potentially get donated which helps provide us a highly accurate prediction if a non-profit will accept an item.
				</Typography>
				<Typography>
					When a non-profit in our network accepts your item for donation, we can work with them to obtain your donation receipt, which will be uploaded to your dashboard or emailed to you.
				</Typography>
				<Typography>
					If a non-profit organization outside of our network accepts your item, we’ll still work to obtain your donation receipt, but we cannot guarantee it. Sometimes non-profit organizations require the donor to be on-site at the time of donation to receive a receipt.
				</Typography>
				<Typography>
					If a non-profit does not accept your item, you can ask us why; however, in many cases, a non-profit will not provide a reason. It typically comes down to the condition of the item, the real-time supply needs of the non-profit, available warehouse/retail/storage space of the non-profit, and timing.
				</Typography>
			</Box>
		)
	}
];