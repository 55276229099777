import { Box } from "grommet";
import { Button } from "@mui/material";
import React from "react";
import { DialogWithClose } from "..";
import { FormContainer, SliderElement, useForm } from "react-hook-form-mui";

interface CreatePickupWindowDialogProps {
	onClose: () => void;
	onSubmit: (from: number, to: number, label: string) => void;
	from?: number;
	to?: number;
	label?: string;
}

export const CreatePickupWindowDialog: React.FC<CreatePickupWindowDialogProps> = (props) => {
	function padTime(time: number): string {
		return time.toString().padStart(2, "0");
	}

	function get24HourTimeLabel(value: number): string {
		switch(value) {
			case 0: {
				return "12:00 AM";
			}
			case 12: {
				return "12:00 PM";
			}
			default: {
				return `${padTime(value % 12)}:00 ${value < 12 ? "AM" : "PM"}`;
			}
		}
	}
	function formatLabel(from: number, to: number): string {
		return `${get24HourTimeLabel(from)} - ${get24HourTimeLabel(to)}`;
	}

	const formContext = useForm({
		defaultValues: {
			fromHours: (props.from ?? 0) % 12,
			fromTimeOfDay: (props.from ?? 0) < 12 ? "AM" : "PM",
			toHours: (props.to ?? 0) % 12,
			toTimeOfDay: (props.to ?? 0) < 12 ? "AM" : "PM",
			label: props.label ?? formatLabel(props.from ?? 0, props.to ?? 0),
			slider: [ 12, 16 ]
		}
	});

	function handleSubmit() {
		const [ from, to ] = formContext.getValues().slider;

		props.onSubmit(
			from,
			to,
			formatLabel(from, to)
		);

		props.onClose();
	}

	return (
		<FormContainer formContext={formContext} onSuccess={handleSubmit}>
			<DialogWithClose
				onClose={props.onClose}
				title="Pickup Window"
				content={(
					<Box gap="medium">
						<Box margin={{ top: "xsmall" }}>

						</Box>
						<Box margin={{ horizontal: "large" }}>
							<SliderElement
								name="slider"
								min={6}
								max={23}
								step={1}
								valueLabelDisplay="on"
								required
								valueLabelFormat={(value) => {
									if(value === 0) {
										return "12 AM";
									}
									else if(value === 12) {
										return "12 PM";
									}
									else if(value < 12) {
										return `${value} AM`;
									}
									else {
										return `${value - 12} PM`;
									}
								}} />
						</Box>
					</Box>
				)}
				actions={(
					<Box align="end">
						<Button color="primary" variant="contained" onClick={formContext.handleSubmit(handleSubmit)}>
							Save
						</Button>
					</Box>
				)} />
		</FormContainer>
	);
};
