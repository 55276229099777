import { Divider } from "@mui/material";
import { Box, Heading } from "grommet";
import { ReactNode } from "react";
import { useWindowDimensions } from "../../../../hooks";

export const ViewActionBar: React.FC<{
	title: string;
	children?: ReactNode[] | ReactNode;
}> = ({ title, children }) => {
	const { size } = useWindowDimensions();

	return (
		<Box style={{ minHeight: "60px" }} gap="medium">
			<Box gap="xsmall">
				<Heading level={3} margin="none">
					{title}
				</Heading>
				<Divider />
			</Box>
			<Box align={size === "small" ? undefined : "center"} direction={size === "small" ? "column" : "row"} gap="small" flex>
				{children}
			</Box>
		</Box>
	);
};