import { useQuery } from "@apollo/client";
import { FetchStop, FetchStopDetailed } from "../queries";

export function useStop(stopId: string) {
	const { data, loading, error } = useQuery(FetchStopDetailed, {
		skip: !stopId,
		variables: {
			stopId
		}
	});

	return {
		stop: data?.FetchStop,
		loading,
		error
	};
}

export function useStopSimple(stopId: string) {
	const { data, loading, error } = useQuery(FetchStop, {
		skip: !stopId,
		variables: {
			stopId
		}
	});

	return {
		stop: data?.FetchStop,
		loading,
		error
	};
}