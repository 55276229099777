import { BuildingAddressListItem } from "../../../components";
import { useResident } from "../../../auth";
import { useCheckoutSessionAddress } from "../hooks";


export const CheckoutAddressResidentSummary: React.FC = () => {
	const { address } = useCheckoutSessionAddress();
	const { resident, building, loading } = useResident();

	return (
		<BuildingAddressListItem
			selected
			isLoading={loading}
			addressId={address?.id || ""}
			unit={resident?.unit || ""}
			unitPrefix={building?.unitPrefix || ""}
			buildingName={building?.name || ""}
		/>
	);
};
