import { DocumentType, gql } from "../__generated__";
import { CreateCheckoutSessionProductMutationVariables } from "../__generated__/graphql";

export const UpsertCheckoutSession = gql(/* GraphQL */`
	mutation UpsertCheckoutSession(
		$userId: String!
		$refererId: String!
		$instanceId: String!
	) {
		UpsertCheckoutSession(
			userId: $userId
			refererId: $refererId
			instanceId: $instanceId
		) {
			id
		}
	}
`);

export const CreateCheckoutSessionAddress = gql(/* GraphQL */`
	mutation CreateCheckoutSessionAddress(
		$userId: String!
		$sessionId: String!
		$addressLineOne: String!
		$addressLineTwo: String
		$city: String!
		$state: String!
		$zipCode: String!
		$instructions: String
		$instructionsParking: String
		$floor: Int!
		$hasParking: Boolean!
		$hasElevator: Boolean!
		$lat: String!
		$lng: String!
		$timezone: String!
	) {
		CreateCheckoutSessionAddress(
			userId: $userId
			sessionId: $sessionId
			addressLineOne: $addressLineOne
			addressLineTwo: $addressLineTwo
			city: $city
			state: $state
			zipCode: $zipCode
			instructions: $instructions
			instructionsParking: $instructionsParking
			floor: $floor
			hasParking: $hasParking
			hasElevator: $hasElevator
			lat: $lat
			lng: $lng
			timezone: $timezone
		) {
			id
		}
	}
`);

export const UpdateCheckoutSessionMoveoutDetails = gql(/* GraphQL */`
	mutation UpdateCheckoutSessionMoveoutDetails(
		$sessionId: String!
		$cutoffDate: DateTime
	) {
		UpdateCheckoutSessionMoveoutDetails(
			sessionId: $sessionId
			cutoffDate: $cutoffDate
		) {
			id
		}
	}
`);

export const AssignCheckoutSessionPickup = gql(/* GraphQL */`
	mutation AssignCheckoutSessionPickup(
		$sessionId: String!
		$pickupId: String!
	) {
		AssignCheckoutSessionPickup(
			sessionId: $sessionId
			pickupId: $pickupId
		) {
			id
		}
	}
`);

export const AssignCheckoutSessionAddress = gql(/* GraphQL */`
	mutation AssignCheckoutSessionAddress(
		$sessionId: String!
		$addressId: String!
	) {
		AssignCheckoutSessionAddress(
			sessionId: $sessionId
			addressId: $addressId
		) {
			id
		}
	}
`);

export const CreateCheckoutSessionProduct = gql(/* GraphQL */`
	mutation CreateCheckoutSessionProduct(
		$sessionId: String!
		$typeId: String!
		$sizeId: String!
		$intent: ProductIntent!
		$quantity: Int!
		$quantityDisassembly: Int!
		$condition: ProductCondition!
		$listingTitle: String
		$listingDescription: String
		$listingPrice: Float
		$listingPriceCompare: Float
		$allowPickup: Boolean
		$brand: String
		$materials: [String!]
		$media: [CreateProductMediaDTO!]
	) {
		CreateCheckoutSessionProduct(
			sessionId: $sessionId
			typeId: $typeId
			sizeId: $sizeId
			intent: $intent
			quantity: $quantity
			quantityDisassembly: $quantityDisassembly
			condition: $condition
			listingTitle: $listingTitle
			listingDescription: $listingDescription
			listingPrice: $listingPrice
			listingPriceCompare: $listingPriceCompare
			allowPickup: $allowPickup
			brand: $brand
			materials: $materials
			media: $media
		) {
			id
		}
	}
`);

export type CreateProductFragmentDTO = CreateCheckoutSessionProductMutationVariables;

export const ConvertCheckoutSession = gql(/* GraphQL */`
	mutation ConvertCheckoutSession(
		$sessionId: String!
	) {
		ConvertCheckoutSession(
			sessionId: $sessionId
		) {
			id
		}
	}
`);

export const FetchCheckoutSession = gql(/* GraphQL */`
	query FetchCheckoutSession($sessionId: String!) {
		FetchCheckoutSession(sessionId: $sessionId) {
			id
			cancelled
			converted
			cutoffDate
			pickup {
				id
				scheduledDate
			}
			address {
				id
				addressLineOne
				addressLineTwo
				city
				state
				zipCode
				instructions
				instructionsParking
				floor
				hasParking
				hasElevator
				createdAt
				updatedAt
				...Address_GeolocationFragment
			}
			products {
				id
				quantity
				quantityDisassembly
				type {
					id
					name
					canDisassemble
					category {
						id
						name
					}
				}
				size {
					id
					name
				}
				media {
					id
					name
					context
					contentUrl
					isPrimary
					description
				}
				intent
				brand {
					id
					name
				}
				brandOther
				materials {
					id
					name
				}
				condition
				allowPickup
				listingTitle
				listingDescription
				listingPrice
				listingPriceCompare
			}
			referer {
				id
				name
				scheduledPickups {
					id
					scheduled
					scheduledDate
					completed
				}
			}
			estimate {
				subtotalAmount
				discountAmount
				discounts { description amount}
				creditUsedAmount
				creditRemainingAmount
				taxAmount
				totalAmount
			}
			paymentMethod {
				...PaymentMethodFragment
			}
		}
	}
`);

export type CheckoutSessionGql = DocumentType<typeof FetchCheckoutSession>[ "FetchCheckoutSession" ];
export type ProductFragmentGql = DocumentType<typeof FetchCheckoutSession>[ "FetchCheckoutSession" ][ "products" ][ 0 ];
export type ProductFragmentMediaGql = DocumentType<typeof FetchCheckoutSession>[ "FetchCheckoutSession" ][ "products" ][ 0 ][ "media" ][ 0 ];

export const FetchCheckoutSessionEstimate = gql(/* GraphQL */`
	query FetchCheckoutSessionEstimate(
		$sessionId: String!
	) {
		FetchCheckoutSessionEstimate(
			sessionId: $sessionId
		) {
			subtotalAmount
			discountAmount
			discounts { description amount}
			creditUsedAmount
			creditRemainingAmount
			taxAmount
			totalAmount
		}
	}
`);

export const AssignCheckoutSessionPaymentMethod = gql(/* GraphQL */`
	mutation AssignCheckoutSessionPaymentMethod(
		$sessionId: String!
		$paymentMethodId: String!
	) {
		AssignCheckoutSessionPaymentMethod(
			sessionId: $sessionId
			paymentMethodId: $paymentMethodId
		) {
			id
		}
	}
`);