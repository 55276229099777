import { gql } from "@apollo/client";

export const Product_InventoryFragment = gql`
	fragment Product_InventoryFragment on Product {
		id
		totalQuantity
		inventory {
			quantity
		}
	}
`;