import React from "react";
import { List } from "@mui/material";
import { OrderSubmittedByListItem } from "./OrderSubmittedByListItem";
import { OrderSubmittedAtListItem } from "./OrderSubmittedAtListItem";
import { OrderIdListItem } from "./OrderIdListItem";
import { OrderRefererListItem } from "./OrderRefererListItem";
import { OrderAddressListItem } from "./OrderAddressListItem";

export const OrderDetailsComponent: React.FC<{
	orderId: string;
	enabledAdminActions?: boolean;
}> = ({ orderId, enabledAdminActions }) => {
	return (
		<List>
			<OrderIdListItem
				orderId={orderId}
			/>
			<OrderSubmittedAtListItem
				orderId={orderId}
			/>
			<OrderSubmittedByListItem
				orderId={orderId}
				enabledAdminActions={enabledAdminActions}
			/>
			<OrderRefererListItem
				orderId={orderId}
			/>
			<OrderAddressListItem
				orderId={orderId}
			/>
		</List>
	);
};