import { useSnackbar } from "notistack";
import { usePricedTypesIfEnabled, useSession } from "../../hooks";
import { useMutation } from "@apollo/client";
import { CreateCheckoutSessionProduct, CreateProductFragmentDTO, CreateProductFragmentMedia, FetchCheckoutSession, ProductFragmentGql, UpdateProductFragment, UpdateProductFragmentDTO, isPricedItemSize, isPricedItemType } from "../../graphql";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormContainer, useForm } from "react-hook-form-mui";
import { InstanceFeature, ProductCondition, ProductIntent, ProductMediaContext } from "../../graphql/__generated__/graphql";
import { Box, Grid, Text } from "grommet";
import { Close, Save } from "@mui/icons-material";
import { ProductDetailsFormElement } from "./ProductDetailsFormElement";
import { LoadingButton } from "@mui/lab";
import { ProductImageUploadFormElement } from "./ProductImageUploadFormElement";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFeature } from "../../features/instance/hooks/useFeature";
import { useAppSelector } from "../../store";
import { selectDefaultIntent } from "../../store/schedule";
import { ProductListingFormElement } from "./ProductListingFormElement";
import { LabeledCondition, resolveLabeledCondition } from "../../features/product/hooks/useConditions";
import { formatCurrency } from "../../helpers";

export interface CreateProductRequiredValues {
	type: { id: string, name: string; canDisassemble: boolean; } | null;
	size: { id: string, name: string; } | null;
	quantity: number;
	quantityDisassembly: number;
	media: Array<CreateMediaDTO>;
	intent: ProductIntent;
	listingTitle: string;
	listingDescription: string;
	listingPrice: number;
	listingPriceCompare: number;
	brand: string;
	materials: Array<string>;
	allowPickup: boolean;
	condition: LabeledCondition | null;
}

export interface CreateMediaDTO {
	file: File;
	name: string;
	contentType: string;
	base64Content: string;
	context: ProductMediaContext;
}

interface ItemDialogControllerProps {
	onClose: () => void;
	product?: ProductFragmentGql;
	sessionId: string;
}

export function useCheckoutIntents(): Array<{ id: ProductIntent, label: string; }> {
	const allowP2PSales = useFeature(InstanceFeature.SalesP2PEnabled);
	const allowRemoval = useFeature(InstanceFeature.RemovalEnabled);
	const allowDonation = useFeature(InstanceFeature.DonationEnabled);

	const availableIntents = useMemo(() => {
		const intents = [] as Array<{ id: ProductIntent, label: string; }>;

		if(allowP2PSales) {
			intents.push({
				id: ProductIntent.Resale,
				label: "Sell"
			});
		}

		if(allowDonation) {
			intents.push({
				id: ProductIntent.Donation,
				label: "Donate"
			});
		}

		if(allowRemoval) {
			intents.push({
				id: ProductIntent.Disposal,
				label: "Junk Removal"
			});
		}

		intents.push({
			id: ProductIntent.Resale,
			label: "Sell"
		});

		return intents;
	}, [ allowDonation, allowP2PSales, allowRemoval, ]);

	return availableIntents;
}

export const ItemDialogController: React.FC<ItemDialogControllerProps> = (props) => {
	const snack = useSnackbar();
	const { session } = useSession(props.sessionId);

	const [ mediaQueue, setMediaQueue ] = useState<Array<CreateMediaDTO & { createdAt: number; }>>([]);

	const [
		createProductMutation,
		{ loading: isCreatingProduct }
	] = useMutation(CreateCheckoutSessionProduct, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	const [
		updateProductMutation,
		{ loading: isUpdatingProduct }
	] = useMutation(UpdateProductFragment, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	const [
		createProductFragmentMediaMutation,
		{ loading: isCreatingProductMedia }
	] = useMutation(CreateProductFragmentMedia, {
		refetchQueries: [ FetchCheckoutSession ]
	});

	const handleCreateProductMedia = useCallback((dto: CreateMediaDTO, productId?: string) => {
		if(!props.product?.id && !productId) {
			setMediaQueue([ ...mediaQueue, { ...dto, createdAt: Date.now() } ]);
			return Promise.resolve(true);
		}

		return createProductFragmentMediaMutation({
			variables: {
				fragmentId: productId || props.product?.id || "",
				content: dto.base64Content,
				contentType: dto.contentType,
				name: dto.name,
				context: dto.context
			}
		}).then(res => {
			if(res.data?.CreateProductFragmentMedia) {
				return true;
			}

			return false;
		}).catch(err => {
			console.error("Failed to upload media", err);
			snack.enqueueSnackbar(
				"We ran into an issue uploading your image. Please try again.",
				{ variant: "error" }
			);

			return false;
		});
	}, [ props.product, mediaQueue, createProductFragmentMediaMutation, snack ]);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const availableIntents = useCheckoutIntents();
	const defaultIntent = useAppSelector(selectDefaultIntent);

	const useFormReturn = useForm<CreateProductRequiredValues>({
		defaultValues: {
			type: props.product?.type || null,
			size: props.product?.size || null,
			quantity: props.product?.quantity ?? 1,
			quantityDisassembly: props.product?.quantityDisassembly ?? 0,
			media: props.product?.media ?? [],
			intent: ProductIntent.Donation,
			// intent: props.product?.intent ?? availableIntents.find(i => i.id === defaultIntent)?.id,
			listingTitle: props.product?.listingTitle ?? "",
			listingDescription: props.product?.listingDescription ?? "",
			listingPrice: props.product?.listingPrice ?? 0,
			listingPriceCompare: props.product?.listingPriceCompare ?? 0,
			brand: props.product?.brand?.name || props.product?.brandOther || "",
			materials: props.product?.materials?.map(m => m.name) ?? [],
			allowPickup: props.product?.allowPickup ?? true,
			condition: props.product?.condition
				? resolveLabeledCondition(props.product.condition)
				: null
		}
	});

	const formValues = useFormReturn.watch();

	useEffect(() => {
		if(props.product) {
			useFormReturn.setValue("intent", props.product.intent);
		}
	}, [ props.product, useFormReturn ]);

	const media = useMemo(() => {
		if(props.product) {
			return session?.products.find(p => p.id === props.product?.id)?.media ?? [];
		}
		else {
			return mediaQueue.sort((a, b) => b.createdAt - a.createdAt).map(item => {
				return {
					id: "",
					name: item.name,
					context: item.context,
					contentType: item.contentType,
					contentUrl: item.base64Content,
					isPrimary: false,
					description: "",
					__typename: "ProductFragmentMedia"
				};
			}) as ProductFragmentGql[ "media" ];
		}
	}, [ props.product, mediaQueue, session ]);

	const salesRequiresFullImage = useFeature(InstanceFeature.SalesFullImageEnabled);
	const donationRequiresFullImage = useFeature(InstanceFeature.DonationFullImageEnabled);

	const fullImageRequired = useMemo(() => {
		switch(formValues.intent) {
			case ProductIntent.Resale: {
				return salesRequiresFullImage;
			}
			case ProductIntent.Donation: {
				return donationRequiresFullImage;
			}
			default: {
				return false;
			}
		}
	}, [ formValues.intent, salesRequiresFullImage, donationRequiresFullImage ]);

	function handleSaveProduct(): void {
		if(!formValues.type) return;
		if(!formValues.quantity) return;

		if(!fullImageRequired && media.length === 0) {
			snack.enqueueSnackbar(
				"Please upload an image of your item",
				{ variant: "error" }
			);
			return;
		}

		if(fullImageRequired) {
			if(!media.find(m => m.context === ProductMediaContext.Front)) {
				snack.enqueueSnackbar(
					"Please upload an image of the front of your item",
					{ variant: "error" }
				);
				return;
			}

			if(!media.find(m => m.context === ProductMediaContext.Back)) {
				snack.enqueueSnackbar(
					"Please upload an image of the back of your item",
					{ variant: "error" }
				);
				return;
			}

			if(!media.find(m => m.context === ProductMediaContext.LeftSide)) {
				snack.enqueueSnackbar(
					"Please upload an image of the left side of your item",
					{ variant: "error" }
				);
				return;
			}

			if(!media.find(m => m.context === ProductMediaContext.RightSide)) {
				snack.enqueueSnackbar(
					"Please upload an image of the right side of your item",
					{ variant: "error" }
				);
				return;
			}
		}

		if(props.product?.id) {
			const dto: UpdateProductFragmentDTO = {
				fragmentId: props.product.id,
				typeId: formValues.type.id,
				sizeId: formValues.size?.id,
				intent: formValues.intent,
				quantity: formValues.quantity,
				quantityDisassembly: formValues.quantityDisassembly,
				listingTitle: formValues.listingTitle,
				listingDescription: formValues.listingDescription,
				listingPrice: formValues.listingPrice,
				listingPriceCompare: formValues.listingPriceCompare,
				brand: formValues.brand,
				materials: formValues.materials,
				allowPickup: formValues.allowPickup ?? false,
				condition: formValues.condition?.id
			};

			updateProductMutation({
				variables: { ...dto }
			}).then(res => {
				if(res?.data?.UpdateProductFragment) {
					props.onClose();
				}
			}).catch(err => {
				console.error("Failed to update product fragment", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", { variant: "error" });
			});
		}
		else {
			const dto: CreateProductFragmentDTO = {
				sessionId: session?.id ?? "",
				typeId: formValues.type?.id ?? "",
				sizeId: formValues.size?.id ?? "",
				intent: formValues.intent ?? defaultIntent ?? ProductIntent.Donation,
				quantity: formValues.quantity ?? 1,
				quantityDisassembly: formValues.quantityDisassembly ?? 0,
				listingTitle: formValues.listingTitle ?? "",
				listingDescription: formValues.listingDescription ?? "",
				listingPrice: formValues.listingPrice ?? 0,
				listingPriceCompare: formValues.listingPriceCompare ?? 0,
				brand: formValues.brand ?? "",
				materials: formValues.materials ?? [],
				allowPickup: formValues.allowPickup ?? false,
				condition: formValues.condition?.id ?? ProductCondition.Unknown
			};

			createProductMutation({
				variables: { ...dto }
			}).then(res => {
				if(!res?.data?.CreateCheckoutSessionProduct) {
					throw new Error("Failed to create product");
				}

				const product = res.data.CreateCheckoutSessionProduct;
				return Promise.all(mediaQueue.map(item => {
					return handleCreateProductMedia(item, product.id);
				}));
			}).then((res) => {
				props.onClose();
			}).catch(err => {
				console.error("Failed to create product fragment", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", { variant: "error" });
			});
		}
	}

	useEffect(() => {
		if(availableIntents.length > 0 && defaultIntent) {
			const intent = availableIntents.find(i => i.id === defaultIntent);
			if(intent) {
				useFormReturn.setValue("intent", intent.id);
			}
		}
	}, [ availableIntents, defaultIntent, useFormReturn ]);

	return (
		<Dialog
			open={true}
			fullWidth
			fullScreen={fullScreen}
		>
			<FormContainer formContext={useFormReturn} onSuccess={(data) => handleSaveProduct()}>
				<DialogTitle>
					<Box direction="row" justify="between">
						<Box justify="center">
							<Text color="brand" weight="bold" size="large">{props.product ? "Update Item" : "Add Item"}</Text>
						</Box>
						<IconButton onClick={props.onClose}>
							<Close />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					{/* <Box margin="small" gap="small">
						<Typography fontWeight="bold">
							What do you want to do with this item?
						</Typography>
						<ToggleButtonGroup
							size="large"
							color="primary"
							exclusive
							value={formValues.intent}
							onChange={(event, select) => useFormReturn.setValue("intent", select)}
						>
							{availableIntents.map(intent => (
								<ToggleButton
									key={intent.id}
									value={intent.id}
								>
									{intent.label}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Box> */}
					<ProductDetailsFormElement
						intent={formValues.intent ?? null}
						type={formValues.type ?? null}
						size={formValues.size ?? null}
						quantity={formValues.quantity}
						quantityDisassembly={formValues.quantityDisassembly}
					/>
					{formValues.intent === ProductIntent.Resale && (
						<ProductListingFormElement
							price={formValues.listingPrice}
							priceCompare={formValues.listingPriceCompare}
							condition={formValues.condition}
							allowPickup={formValues.allowPickup ?? false}
							setBrand={(brand) => useFormReturn.setValue("brand", brand)}
							setPrice={(price) => useFormReturn.setValue("listingPrice", price)}
							toggleAllowPickup={() => useFormReturn.setValue("allowPickup", !formValues.allowPickup)}
						/>
					)}
					<ProductImageUploadFormElement
						media={media}
						fullImageEnabled={fullImageRequired}
						setMedia={(media) => handleCreateProductMedia(media)}
						isUploading={isCreatingProductMedia}
					/>
					<ItemPickupQuoteContainer
						refererId={session?.referer?.id ?? ""}
						useCustomScheduleMargin={!!session?.cutoffDate && !session.pickup}
						quantity={formValues.quantity}
						quantityDisassembly={formValues.quantityDisassembly}
						type={formValues.type}
						size={formValues.size}
					/>
				</DialogContent>
				<DialogActions>
					<Box align="end" margin="small">
						<LoadingButton
							type="submit"
							variant="contained"
							color="primary"
							endIcon={<Save />}
							loadingPosition="end"
							loading={isCreatingProduct || isUpdatingProduct || isCreatingProductMedia}
						>
							Continue
						</LoadingButton>
					</Box>
				</DialogActions>
			</FormContainer>
		</Dialog >
	);
};

export function useItemPickupFee(
	refererId: string,
	useCustomScheduleMargin: boolean,
	quantity: number,
	typeId: string,
	sizeId?: string,
): number {
	const { types } = usePricedTypesIfEnabled(refererId, useCustomScheduleMargin);
	return useMemo(() => {
		let totalFee = 0;
		const foundType = types.find(t => t.id === typeId);
		if(foundType && isPricedItemType(foundType)) {
			totalFee += (foundType.pickupFee || 0) * quantity;
		}

		const foundSize = foundType?.sizes.find(s => s.id === sizeId);
		if(foundSize && isPricedItemSize(foundSize)) {
			totalFee += (foundSize.pickupFee || 0) * quantity;
		}

		return totalFee;
	}, [ types, typeId, sizeId, quantity ]);
}

export function useItemDisassemblyFee(
	refererId: string,
	useCustomScheduleMargin: boolean,
	quantity: number,
	typeId: string,
): number {
	const { types } = usePricedTypesIfEnabled(refererId, useCustomScheduleMargin);
	return useMemo(() => {
		let totalFee = 0;
		const foundType = types.find(t => t.id === typeId);
		if(foundType && isPricedItemType(foundType)) {
			totalFee += (foundType.disassemblyFee || 0) * quantity;
		}

		return totalFee;
	}, [ types, typeId, quantity ]);
}

export const ItemPickupQuoteContainer: React.FC<{
	refererId: string;
	useCustomScheduleMargin: boolean,
	quantity: number;
	quantityDisassembly: number;
	type: { id: string, name: string, canDisassemble: boolean; } | null;
	size: { id: string, name: string; } | null;
}> = ({ refererId, type, size, quantity, quantityDisassembly, useCustomScheduleMargin }) => {
	const isUpfrontPricingEnabled = useFeature(InstanceFeature.OrderUpfrontPricingEnabled);

	const pickupFee = useItemPickupFee(
		refererId,
		useCustomScheduleMargin,
		quantity,
		type?.id ?? "",
		size?.id ?? undefined
	);

	const strikethroughPickupFee = useMemo(() => {
		if(!pickupFee || pickupFee === 0) return 0;

		const adjusted = Math.round((1 + 0.40) * pickupFee);
		const difference = adjusted % 5;
		return (adjusted) + (5 - difference);
	}, [ pickupFee ]);

	const disassemblyFee = useItemDisassemblyFee(
		refererId,
		useCustomScheduleMargin,
		quantityDisassembly,
		type?.id ?? ""
	);

	const totalFee = useMemo(() => {
		return pickupFee + disassemblyFee;
	}, [ pickupFee, disassemblyFee ]);

	if(!isUpfrontPricingEnabled || totalFee <= 0) {
		return <></>;
	}

	return (
		<Box margin="small" gap="medium">
			<Box gap="small">
				<Typography fontSize="large" fontWeight="bold">
					Pickup Quote
				</Typography>
				<Divider />
			</Box>
			<Box gap="small">
				<Grid columns={[ "2/3", "1/3" ]}>
					<Typography fontWeight="bold">
						Pickup Fee:
					</Typography>
					<Box align="end">
						<Typography color="error" style={{ textDecoration: "line-through" }}>
							{formatCurrency(strikethroughPickupFee)}
						</Typography>
					</Box>
				</Grid>
				<Grid columns={[ "2/3", "1/3" ]}>
					<Typography fontWeight="bold">
						Resident Discount:
					</Typography>
					<Box align="end">
						<Typography fontWeight="bold">
							{formatCurrency(strikethroughPickupFee - pickupFee)}
						</Typography>
					</Box>
				</Grid>
				{disassemblyFee > 0 && (
					<Grid columns={[ "2/3", "1/3" ]}>
						<Typography fontWeight="bold">
							Disassembly Fee:
						</Typography>
						<Box align="end">
							<Typography>
								{formatCurrency(disassemblyFee)}
							</Typography>
						</Box>
					</Grid>
				)}
				<Grid columns={[ "2/3", "1/3" ]} margin={{ top: "medium" }}>
					<Typography fontWeight="bold">
						Total Fee:
					</Typography>
					<Box align="end">
						<Typography fontWeight="bold">
							{formatCurrency(totalFee)}
						</Typography>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
};