import { gql } from "../../../__generated__";

export const ScheduleBuildingPickup = gql(/* GraphQL */ `
	mutation ScheduleBuildingPickup(
		$buildingId: String!
		$unit: String!
		$cutoffDate: DateTime!
		$products: [CreateBuildingProductDTO!]!
	) {
		ScheduleBuildingPickup(
			buildingId: $buildingId
			unit: $unit
			cutoffDate: $cutoffDate
			products: $products
		) {
			id
		}
	}
`);