import { useResidentBuilding } from "../../../auth";
import { useMemo } from "react";

export function useBuildingPickups() {
	const { building, loading } = useResidentBuilding();
	const pickups = useMemo(() => {
		return building?.scheduledPickups || [];
	}, [ building ]);

	return {
		pickups,
		loading
	};
}
