import { gql } from "@apollo/client";

export const Pickup_AssignmentFragment = gql`
	fragment Pickup_AssignmentFragment on Pickup {
		assigned
		assignedAt
		assignedTo {
			id
			name
		}
	}
`;
