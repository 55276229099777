import { gql } from "../../__generated__";

export const FindInstanceDonors = gql(/* GraphQL */`
	query FindInstanceDonors($instanceId: String!) {
		FindInstanceUsers(instanceId: $instanceId) {
			id
			firstName
			lastName
			fullName
			primaryPhoneNumber
			primaryEmailAddress
		}
	}
`);