import { Box } from "grommet";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AccordionController } from "../../../../components";
import React from "react";
import { useUser } from "../../../../auth";
import moment from "moment-timezone";
import { DateCalendarAccordionSummary, ScheduledPickupsAccordionDetails } from "./DateCalendarAccordion";
import { PickupQuoteAccordionDetails, PickupQuoteAccordionSummary } from "./PickupQuoteAccordion";
import { PaymentMethodAccordionDetails, PaymentMethodAccordionSummary } from "./PaymentMethodAccordion";
import { PickupWindowAccordionSummary } from "./PickupWindowAccordion";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FetchEstimatePreviewOnSchedule, FetchOrderUnified, GenerateEstimateOnSchedule, ScheduleOrder, ScheduleOrderWithPickup, useUnifiedOrder } from "../../../../graphql";
import { useSnackbar } from "notistack";
import { SchedulePickupContainer } from "./SchedulePickupContainer";
import { useToggleForceClose } from "../../../admin/components";


export const ScheduleUpfrontPickupController: React.FC<{
	orderId: string;
	forceOpen?: boolean;
	activatorButton: React.ReactNode;
	expanded: string;
	setExpanded(panel: string): void;
}> = ({ orderId, activatorButton, expanded, setExpanded: setExpandedParent, forceOpen }) => {
	const snack = useSnackbar();
	const { order, pickup } = useUnifiedOrder(orderId, [ "scheduling" ]);

	const [ { scheduledDate, selectedPickupId, selectedWindow }, setState ] = useState({
		scheduledDate: pickup?.scheduledDate ? moment(pickup.scheduledDate, "YYYY-MM-DD") : null,
		selectedPickupId: pickup?.id || "",
		selectedWindow: pickup?.window || undefined
	});

	useEffect(() => {
		setState({
			scheduledDate: pickup?.scheduledDate ? moment(pickup.scheduledDate, "YYYY-MM-DD") : null,
			selectedPickupId: pickup?.id || "",
			selectedWindow: pickup?.window || undefined
		});
	}, [ pickup ]);

	const setExpanded = useCallback((panel: string) => {
		if(panel === expanded) {
			setExpandedParent("");
		}
		else {
			setExpandedParent(panel);
		}
	}, [ expanded, setExpandedParent ]);

	const [
		schedule, { loading: isScheduling }
	] = useMutation(ScheduleOrderWithPickup, {
		refetchQueries: [ FetchOrderUnified ]
	});

	const [
		scheduleCustom, { loading: isSchedulingCustom }
	] = useMutation(ScheduleOrder, {
		refetchQueries: [ FetchOrderUnified ]
	});

	const [
		fetchEstimate, { loading: isFetchingEstimate, data: estimateData }
	] = useLazyQuery(FetchEstimatePreviewOnSchedule, {
		fetchPolicy: "no-cache"
	});

	const estimate = useMemo(() => {
		return estimateData?.FetchEstimatePreviewOnSchedule || null;
	}, [ estimateData ]);

	const [
		generateEstimate, { loading: isGeneratingEstimate }
	] = useMutation(GenerateEstimateOnSchedule);

	useEffect(() => {
		if(!selectedPickupId && !scheduledDate) return;
		fetchEstimate({
			variables: {
				orderId: orderId,
				pickupId: selectedPickupId || null
			}
		});
	}, [ selectedPickupId, scheduledDate ]);

	const { user } = useUser();
	const paymentMethods = useMemo(() => {
		return user?.paymentMethods || [];
	}, [ user ]);

	const paymentMethod = useMemo(() => {
		return paymentMethods.find((method) => method.id === order?.paymentMethod?.id);
	}, [ order, user ]);

	const { forceClose, toggleForceClose } = useToggleForceClose();

	function handleSchedule(): void {
		if(!order) return;
		if(!selectedWindow) return;

		generateEstimate({
			variables: {
				orderId: order.id,
				pickupId: selectedPickupId || null
			}
		}).then((estimate) => {
			const estimateId = estimate.data?.GenerateEstimateOnSchedule?.id;
			if(!estimateId) {
				throw new Error("failed to generate estimate");
			}

			if(!selectedPickupId) {
				if(!scheduledDate) throw new Error("scheduledDate is required");
				scheduleCustom({
					variables: {
						orderId: order.id,
						estimateId,
						selectedDate: scheduledDate.toDate(),
						selectedWindow: {
							from: selectedWindow.from,
							to: selectedWindow.to,
							label: selectedWindow.label
						}
					}
				}).then(() => {
					toggleForceClose();
					snack.enqueueSnackbar("Your pickup has been scheduled.", { variant: "success" });
				});
			}
			else {
				schedule({
					variables: {
						orderId: order.id,
						estimateId,
						pickupId: selectedPickupId
					}
				}).then(() => {
					toggleForceClose();
					snack.enqueueSnackbar("Your pickup has been scheduled.", { variant: "success" });
				});
			}

		}).catch(err => {
			console.error(`failed to generate estimate for order [${orderId}]`, err);
			snack.enqueueSnackbar("We ran into an issue saving your information.", { variant: "error" });
		});
	}

	const canSubmit = useMemo(() => {
		if(isScheduling || isSchedulingCustom) return false;
		return !!scheduledDate && !!selectedWindow && ((estimate?.totalAmount || 0) <= 0 || !!paymentMethod);
	}, [ isScheduling, isSchedulingCustom, scheduledDate, selectedWindow, paymentMethod ]);

	return (
		<SchedulePickupContainer
			canSubmit={canSubmit}
			forceOpen={forceOpen}
			forceClose={forceClose}
			handleSchedule={handleSchedule}
			activatorButton={activatorButton}
			isReschedule={order?.pickup !== null}
			totalAmount={estimate?.totalAmount || 0}
			isScheduling={isScheduling || isSchedulingCustom || isFetchingEstimate || isGeneratingEstimate}
		>
			<Box>
				<AccordionController
					name="date"
					isExpanded={expanded === "date"}
					onChange={() => setExpanded("date")}
					summary={(
						<DateCalendarAccordionSummary
							scheduledDate={scheduledDate}
						/>
					)}
					details={(
						<ScheduledPickupsAccordionDetails
							pickupId={selectedPickupId}
							onPickupSelected={(pickupId, window, date) => {
								setState({
									scheduledDate: date,
									selectedPickupId: pickupId,
									selectedWindow: window
								});

								if(estimate && estimate.totalAmount > 0) {
									setExpanded("payment");
								}
							}}
							onCustomPickupSelected={(date) => {
								setState({
									scheduledDate: date,
									selectedPickupId: "",
									selectedWindow: selectedWindow
								});
								setExpanded("window");
							}}
							scheduledDate={scheduledDate} />
					)} />
				<AccordionController
					name="window"
					isExpanded={false}
					onChange={() => setExpanded("window")}
					disabled={true}
					summary={(
						<PickupWindowAccordionSummary
							scheduledDate={scheduledDate?.format("MM/DD/YYYY")}
							selectedWindow={selectedWindow} />
					)}
					details={(<></>)} />
				{estimate && estimate.totalAmount > 0 && (
					<AccordionController
						name="payment"
						isExpanded={expanded === "payment"}
						onChange={() => setExpanded("payment")}
						summary={(
							<PaymentMethodAccordionSummary
								paymentMethod={paymentMethod} />
						)}
						details={(
							<PaymentMethodAccordionDetails
								orderId={orderId}
								paymentMethodId={paymentMethod?.id || ""}
								onPaymentMethodSelected={() => {
									setExpanded("quote");
								}} />
						)} />
				)}
				{estimate && estimate.totalAmount > 0 && (
					<AccordionController
						name="quote"
						isExpanded={expanded === "quote"}
						onChange={() => setExpanded("quote")}
						summary={(
							<PickupQuoteAccordionSummary
								total={estimate?.totalAmount || 0} />
						)}
						details={(
							<PickupQuoteAccordionDetails
								tax={estimate?.taxAmount || 0}
								subtotal={estimate?.subtotalAmount || 0}
								discounts={(estimate?.discounts || []).map(discount => ({
									amount: discount.amount,
									label: discount.description
								}))}
								total={estimate?.totalAmount || 0}
								onClickConfirm={() => {
									setExpanded("");
								}} />
						)} />
				)}
			</Box>
		</SchedulePickupContainer>
	);
};
