import { useQuery } from "@apollo/client";
import { FetchOutlet } from "../queries";

export function useOutlet(outletId: string) {
	const { data, loading, error } = useQuery(FetchOutlet, {
		skip: !outletId,
		variables: {
			outletId
		}
	});

	return {
		loading,
		error,
		outlet: data?._FetchOutlet
	};
}