import { Fragment } from "react";
import { SidebarLayout, SidebarLayoutProps } from "../../components";

export const DashboardLayout: React.FC<{
	debugTitle: string;
} & SidebarLayoutProps> = (props) => {
	return (
		<Fragment>
			<SidebarLayout
				sidebar={props.sidebar}
			/>
			{/* <Box
				pad="small"
				style={{
					position: "absolute",
					bottom: 0,
					left: 0
				}}
			>
				<Typography>
					DEBUG: {props.debugTitle}
				</Typography>
			</Box> */}
		</Fragment>
	);
};