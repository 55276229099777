import { SvgIcon, SvgIconProps } from "@mui/material";

	export const BuffettTable: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m1163.6 77.352h-1127.1c-11.051 0-20 8.9492-20 20v912.15c0 11.051 8.9492 20 20 20h54.25v73.148c0 11.051 8.9492 20 20 20h978.6c11.051 0 20-9 20-20v-73.148h54.199c11.051 0 20-9 20-20v-912.15c0.050781-11.051-8.9492-20-19.949-20zm-20.051 177.6h-526.45v-137.6h526.45zm-566.45-137.6v137.6l-520.6-0.003906v-137.6zm-520.6 177.6h520.6v694.5h-520.6zm1012.9 787.65h-938.6v-53.102h938.55v53.102zm20-93.152h-472.25v-694.5h526.45v694.5z"/>
  <path d="m247.85 200.5h104.35c11.051 0 20-8.9492 20-20s-8.9492-20-20-20h-104.35c-11.051 0-20 8.9492-20 20s8.9492 20 20 20z"/>
  <path d="m847.85 200.5h104.3c11.051 0 20-8.9492 20-20s-9-20-20-20h-104.3c-11.051 0-20 8.9492-20 20s8.9492 20 20 20z"/>
  <path d="m497.3 718.85c11.051 0 20-9 20-20v-113.2c0-11.051-8.9492-20-20-20s-20 8.9492-20 20v113.2c0 11 9 20 20 20z"/>
  <path d="m694.75 565.65c-11.051 0-20 8.9492-20 20v113.2c0 11.051 9 20 20 20s20-9 20-20v-113.2c0-11.047-9-20-20-20z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};