import { push } from "redux-first-history";
import { useAppDispatch } from "../../../../../store";
import { AdminProductSummaryListItem } from "../product";
import { useMemo } from "react";
import { Pagination } from "../../../../../components";
import { useAdminScopedOrder } from "../../../../../graphql";

export const OrderProductsComponent: React.FC<{
	orderId: string;
	permitAI?: boolean;
	permitEdit: boolean;
}> = ({ orderId, permitAI, permitEdit }) => {
	const dispatch = useAppDispatch();
	const { order } = useAdminScopedOrder(orderId);

	const products = useMemo(() => {
		return order?.products ?? [];
	}, [ order ]);

	return (
		<Pagination pageSize={5}>
			{products.map((product) => (
				<AdminProductSummaryListItem
					key={product.id}
					onSelect={() => {
						dispatch(push(`/admin/products/${product.id}`));
					}}
					productId={product.id}
					enableAIDetection={permitAI}
					allowQuickEditDisposition={permitEdit}
				/>
			))}
		</Pagination>
	);
};