import { Box } from "grommet";
import React from "react";
import { IconButton, ListItemButton, ListItemSecondaryAction } from "@mui/material";
import { HourglassBottom, ImageSearch, Login, Logout } from "@mui/icons-material";
import { ProductMediaDialog } from "../../features/hauler/components/product";
import { Product_StopDetailedFragmentFragment } from "../../graphql/__generated__/graphql";
import { ProductListItemIcon, ProductListItemQuantity, ProductListItemText } from "..";

export const PickupProductListItem: React.FC<{
	loading: boolean;
	product: Product_StopDetailedFragmentFragment;
	additionalButton?: React.ReactNode;
}> = ({ loading, product, additionalButton: additionalButtons }) => {
	return (
		<ListItemButton divider style={{ minHeight: "80.8px" }}>
			<ProductListItemIcon
				loading={loading}
				product={product}
			/>
			<ProductListItemText
				loading={loading}
				product={product}
			/>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small">
					{product.originStopConfirmed && (
						<Box align="center" justify="center">
							<Login color="success" />
						</Box>
					)}
					{product.destinationStopConfirmed && (
						<Box align="center" justify="center">
							<Logout color="success" />
						</Box>
					)}
					{(!product.destinationStopConfirmed && product.destinationStopDelayed && (
						<Box align="center" justify="center">
							<HourglassBottom color="warning" />
						</Box>
					))}
					<ProductListItemQuantity
						loading={loading}
						product={product}
					/>
					<ProductMediaDialog
						media={product.media}
						activatorButton={(
							<IconButton>
								<ImageSearch />
							</IconButton>
						)}
						showContextInActions
					/>
					{additionalButtons}
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};