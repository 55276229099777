import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { FetchWasteAudit, FindWasteAudit, UpdateWasteAudit } from "../../../../../graphql";
import { useToggleForceClose } from "../../../components";
import { WasteAuditForm } from "./WasteAuditForm";
import { useSnackbar } from "notistack";
import { useWasteAudit } from "../../hooks";

export const WasteAuditUpdateDialog: React.FC<{
	auditId: string;
	activatorButton: React.ReactNode;
}> = ({ auditId, activatorButton }) => {
	const snack = useSnackbar();
	const { audit } = useWasteAudit(auditId);
	const { forceClose, toggleForceClose } = useToggleForceClose();
	const formContext = useForm({
		defaultValues: {
			name: audit?.name || "",
			partnerId: ""
		}
	});

	const [
		create,
		{ loading }
	] = useMutation(UpdateWasteAudit, {
		refetchQueries: [ FindWasteAudit, FetchWasteAudit ]
	});

	useEffect(() => {
		if(audit) {
			formContext.setValue("name", audit.name);
		}
	}, [ audit, formContext ]);

	function handleSubmit() {
		const { name } = formContext.getValues();
		if(!name || !auditId) return;

		create({
			variables: {
				name,
				auditId
			}
		}).then(() => {
			snack.enqueueSnackbar("Successfully updated waste audit", {
				variant: "success"
			});
			toggleForceClose();
		});
	}

	return (
		<WasteAuditForm
			buttonTitle="Update"
			title="Update Waste Audit"
			loading={loading}
			onSubmit={handleSubmit}
			forceClose={forceClose}
			toggleForceClose={toggleForceClose}
			activatorButton={activatorButton}
			formContext={formContext}
		/>
	);
};