import { gql } from "../../../__generated__";

export const FetchCheckoutEstimatePreview = gql(/* GraphQL */ `
	query FetchCheckoutEstimatePreview(
		$pickupId: String
		$scheduledDate: DateTime
		$refererId: String!
		$instanceId: String!
		$products: [PreviewProductPricingDTO!]!
	) {
		FetchCheckoutEstimatePreview(
			pickupId: $pickupId
			scheduledDate: $scheduledDate
			refererId: $refererId
			instanceId: $instanceId
			products: $products
		) {
			subtotalAmount
			discountAmount
			discounts {
				description
				amount
			}
			creditUsedAmount
			creditRemainingAmount
			taxAmount
			totalAmount
		}
	}
`);