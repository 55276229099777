import { useCallback, useMemo, useState } from "react";
import { useClassification } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../store";
import { findProductInState, selectCheckoutState, updateProduct } from "../../../store/checkout";
import { usePickupFee } from "./usePickupFee";
import { useType } from "./useType";
import { fileToBase64, isNotNull } from "../../../helpers";
import { ProductMediaContext } from "../../../graphql/__generated__/graphql";
import { MediaLikeWithUpload } from "../types";
import { useCheckoutMedia } from "./useCheckoutMedia";

export function useSize(typeId: string, sizeId: string) {
	const { type } = useType(typeId);
	const { pickupFee, disassemblyFee, pickupFeeComparison, totalFee, totalFeeComparison } = usePickupFee(typeId, sizeId);
	const { types, loading } = useClassification();
	const product = useAppSelector(findProductInState({ typeId, sizeId }));

	const size = useMemo(() => {
		if(!type) return null;
		return type.sizes.find(s => s.id === sizeId);
	}, [ sizeId, types ]);

	return {
		size,
		type,
		loading,
		totalFee,
		totalFeeComparison,
		pickupFee,
		disassemblyFee,
		pickupFeeComparison,
		quantity: product?.quantity || 0,
		quantityDisassembly: product?.quantityDisassembly || 0,
		pickupFeeSum: pickupFee * (product?.quantity || 0),
		disassemblyFeeSum: disassemblyFee * (product?.quantityDisassembly || 0),
		pickupFeeComparisonSum: pickupFeeComparison * (product?.quantity || 0)
	};
}

export function useProduct(
	typeId: string,
	sizeId: string
) {
	const [ isUploadingLocal, setUploading ] = useState(false);
	const dispatch = useAppDispatch();
	const { upload, isUploading } = useCheckoutMedia();
	const { sessionId } = useAppSelector(selectCheckoutState);
	const product = useAppSelector(findProductInState({ typeId, sizeId }));

	const uploadMedia = useCallback((media: File[]) => {
		if(!product || !sessionId) return;
		setUploading(true);
		Promise.all(
			media.map(file => {
				return fileToBase64(file).then((content): MediaLikeWithUpload => ({
					id: "temp",
					name: file.name,
					content,
					contentType: file.type,
					contentUrl: URL.createObjectURL(file),
					isPrimary: false,
					context: ProductMediaContext.Default
				}));
			})
		).then(withContent => {
			return Promise.all(
				withContent.map(toUpload => {
					return upload({
						name: toUpload.name,
						content: toUpload.content,
						contentType: toUpload.contentType,
						referenceId: sessionId,
						context: toUpload.context
					});
				})
			);
		}).then(media => {
			const uploaded = media.map(m => {
				if(!m) return null;
				return m.CreateReferenceMedia.id || null;
			}).filter(isNotNull);

			dispatch(updateProduct({
				...product,
				media: [
					...product.media,
					...uploaded
				]
			}));
		}).catch(err => {
			console.error("Failed to upload media", err);
		}).finally(() => {
			setUploading(false);
		});
	}, [ product ]);

	const assignExistingMedia = useCallback((media: MediaLikeWithUpload) => {
		if(!product) return;
		dispatch(updateProduct({
			...product,
			media: [
				...product.media,
				media.id
			]
		}));
	}, [ product ]);

	return {
		product,
		isUploading: isUploadingLocal || isUploading,
		uploadMedia,
		assignExistingMedia,
	};
}