import { Box, Image } from "grommet";
import { useMemo } from "react";

export const CarouselItem: React.FC<{
	name: string;
	contentUrl: string;
	file?: File;
	height?: string;
	onClick?: () => void;
}> = ({ name, contentUrl, file, height, onClick }) => {
	const content = useMemo(() => {
		if(file) {
			return URL.createObjectURL(file);
		}

		return contentUrl;
	}, [ contentUrl, file ]);
	return (
		<Box flex pad="medium" justify="center">
			<Image
				key={name}
				src={content}
				fit="contain"
				onClick={onClick}
				height={height || "300px"}
			/>
		</Box>
	);
};