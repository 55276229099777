import { SvgIcon, SvgIconProps } from "@mui/material";

	export const DrawerUnit: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m182.73 423.8v734.2h101.87l25.117-74.016h580.56l25.117 74.016h101.87v-1010.3h33.805l-0.003906-105.72h-902.14v105.72h33.805l-0.003906 276.09zm76.059 698.2h-40.066v-38.016h52.969zm722.47 0h-40.066l-12.898-38.016h52.969zm0-74.879h-762.53v-275.22h241.49v40.188c0 24 19.523 43.512 43.512 43.512h192.54c23.988 0 43.512-19.512 43.512-43.512v-40.188h241.49l0.003906 275.22zm-485.04-275.22h207.56v40.188c0 4.1406-3.3711 7.5117-7.5117 7.5117l-192.54 0.003906c-4.1406 0-7.5117-3.3711-7.5117-7.5117zm485.04-36h-762.53v-276.09h241.49v40.188c0 24 19.523 43.512 43.512 43.512h192.54c23.988 0 43.512-19.512 43.512-43.512v-40.188h241.49l0.003906 276.09zm-485.04-276.09h207.56v40.188c0 4.1406-3.3711 7.5117-7.5117 7.5117h-192.54c-4.1406 0-7.5117-3.3711-7.5117-7.5117zm485.04-36h-762.53v-276.09h241.49v40.188c0 23.988 19.523 43.5 43.512 43.5h192.54c23.988 0 43.512-19.512 43.512-43.5l0.003906-40.188h241.49v276.09zm-485.04-276.09h207.56v40.188c0 4.1406-3.3711 7.5-7.5117 7.5h-192.54c-4.1406 0-7.5117-3.3711-7.5117-7.5zm-311.3-69.719h830.16v33.719h-830.16z"/>
</svg>
			</SvgIcon>
		);
	};