import { Box, Grid, Heading } from "grommet";
import { HaulerPickupConfig, HaulerUsersList } from "../components";
import { Divider, IconButton, LinearProgress, List, Typography } from "@mui/material";
import { BasicListItem, ViewContainer } from "../../../components";
import { CopyAll } from "@mui/icons-material";
import { useClipboard, useWindowDimensions } from "../../../hooks";
import { useHauler } from "../hooks";

export const HaulerSettings: React.FC = () => {
	const { copy } = useClipboard();
	const { hauler, loading } = useHauler();
	const { sizeIndex } = useWindowDimensions();

	return (
		<ViewContainer>
			<Box gap="medium">
				<Grid columns={{ count: sizeIndex <= 1 ? 1 : 2, size: "auto" }} gap="small">
					<Box>
						<Box gap="small">
							<Box>
								<Heading level="3" margin="none">
									Account Details
								</Heading>
								{(loading) && (
									<LinearProgress />
								)}
							</Box>
							<List>
								<BasicListItem
									label="Account ID"
									loading={loading}
									value={(
										<Typography className="text-max-1-line" variant="body2" color="text.secondary">
											{hauler?.id ?? ""}
										</Typography>
									)}
									action={(
										<IconButton onClick={() => copy(hauler?.id || "")}>
											<CopyAll />
										</IconButton>
									)}
								/>
								<BasicListItem
									label="Account Name"
									loading={loading}
									value={hauler?.name ?? ""}
								/>
							</List>
						</Box>
					</Box>
					<HaulerUsersList />
				</Grid>
				<HaulerPickupConfig

				/>
				<Divider />
			</Box>
		</ViewContainer>
	);
};