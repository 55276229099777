import { Chip, ListItemButton, Typography } from "@mui/material";
import { ProductFragment, Product_ClassificationFragmentFragment, Product_HeaderFragmentFragment, Product_InventoryFragmentFragment } from "../../graphql/__generated__/graphql";
import { FetchProduct, FragmentType } from "../../graphql";
import { Box, Spinner } from "grommet";
import { ProductListItemIcon } from "./ProductListItemIcon";
import { ProductListItemText } from "./ProductListItemText";
import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { useWindowDimensions } from "../../hooks";
import { ProductListItemQuantity } from "./ProductListItemQuantity";

export type ProductListItemProductFragment =
	FragmentType<Product_HeaderFragmentFragment> &
	FragmentType<Product_InventoryFragmentFragment> &
	FragmentType<Product_ClassificationFragmentFragment> &
	FragmentType<ProductFragment>;

export const ProductListItemV1: React.FC<{
	loading?: boolean;
	onSelect?: () => void;
	children?: React.ReactNode;
	product: ProductListItemProductFragment;
}> = ({ product, onSelect, children, loading }) => {
	const { size } = useWindowDimensions();

	return (
		<ListItemButton divider onClick={onSelect} style={{ minHeight: "80px" }}>
			<Box flex gap="small">
				<Box direction="row" align="center" flex gap="small">
					<ProductListItemIcon loading={loading} product={product} />
					<ProductListItemText loading={loading} product={product} />
					<Box direction="row" flex justify="end" gap="small">
						{(product?.requiresDisassembly && size !== "small") && (
							<Box align="center" justify="center">
								<Chip
									color="warning"
									label={<Typography fontWeight="bold" variant="caption">{"Disassembly".toUpperCase()}</Typography>}
								/>
							</Box>
						)}
						<Box justify="center">
							<ProductListItemQuantity
								product={product}
							/>
						</Box>
						<Box>
							{children}
						</Box>
					</Box>
				</Box>
				{size === "small" && (
					<Box justify="start" gap="small" direction="row">
						{product?.intent && (
							<Chip
								color="secondary"
								label={<Typography fontWeight="bold" variant="caption">{product.intent}</Typography>}
							/>
						)}
						{(product?.requiresDisassembly) && (
							<Box align="center" justify="center">
								<Chip
									color="warning"
									label={<Typography fontWeight="bold" variant="caption">{"Disassembly".toUpperCase()}</Typography>}
								/>
							</Box>
						)}
					</Box>
				)}
			</Box>
		</ListItemButton>
	);
};

export const ProductListItem: React.FC<{
	productId: string;
	onSelect?: () => void;
	children?: React.ReactNode;
}> = ({ productId, onSelect, children }) => {
	const { size } = useWindowDimensions();
	const { data, loading } = useQuery(FetchProduct, {
		skip: !productId,
		variables: { productId }
	});

	const product = useMemo(() => {
		return data?.FetchProduct ?? undefined;
	}, [ data ]);

	return (
		<ListItemButton divider onClick={onSelect} style={{ minHeight: "80px" }}>
			<ProductListItemIcon product={product} />
			<Box>
				<ProductListItemText product={product} />
			</Box>
			<Box direction="row" gap="small" flex justify="end" align="center">
				{size !== "small" && (
					<Chip
						color="secondary"
						label={product?.disposition
							? <Typography fontWeight="bold" variant="caption">{product.disposition}</Typography>
							: (product?.intent)
								? <Typography fontWeight="bold" variant="caption">{product.intent}</Typography>
								: undefined
						}
					/>
				)}
				<ProductListItemQuantity
					product={product}
				/>
				<Box>
					{children}
				</Box>
				{loading && (
					<Box align="center" justify="center">
						<Spinner />
					</Box>
				)}
			</Box>
		</ListItemButton>
	);
};