import { Fragment, ReactNode } from "react";

export const Customized: React.FC<{
	/**
	 * Custom content to render.
	 */
	content: ReactNode;

	/**
	 * Fallback / default content to render if `content` is not provided.
	 */
	children: ReactNode;
}> = ({ content, children }) => {
	return (
		<Fragment>
			{content || children}
		</Fragment>
	);
};