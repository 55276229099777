import { gql } from "../../../__generated__";

export const FetchWasteCalculatorSubmission = gql(/* GraphQL */ `
	query FetchWasteCalculatorSubmission(
		$submissionId: String!
	) {
		FetchWasteCalculatorSubmission(submissionId: $submissionId) {
			id
			period
			totalWeightDivertedPounds
			totalWeightGeneratedPounds
			responses {
				id
				value
				pledge
			}
		}
	}
`);