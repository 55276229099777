import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { BeginOnboarding, ConfirmOnboarding } from "../../../graphql";
import { useUser } from "../../../auth";
import { useOnboardingNotifications } from "./useOnboardingNotifications";

export function useOnboarding() {
	const snack = useSnackbar();
	const { updateLogin } = useUser();

	const [
		beginOnboarding, { data: beginData, loading: beginLoading }
	] = useMutation(BeginOnboarding);

	const [
		confirmOnboarding, { data: confirmData, loading: confirmLoading }
	] = useMutation(ConfirmOnboarding);

	useEffect(() => {
		if(confirmData?.ConfirmOnboarding?.accessToken) {
			const { accessToken, refreshToken } = confirmData.ConfirmOnboarding;
			updateLogin(accessToken, refreshToken);
		}
	}, [ confirmData ]);

	const [ canResendCode, setCanResendCode ] = useState(false);
	useOnboardingNotifications(
		beginData?.BeginOnboarding?.hasError || false,
		beginData?.BeginOnboarding?.exists || false,
		beginData?.BeginOnboarding?.message || ""
	);

	useEffect(() => {
		if(beginData?.BeginOnboarding?.wasCodeSent) {
			const timeout = setTimeout(() => {
				setCanResendCode(true);
			}, 1000 * 10);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [ beginData?.BeginOnboarding?.wasCodeSent ]);

	function handleBeginOnboarding(variables: VariablesOf<typeof BeginOnboarding>) {
		beginOnboarding({
			variables: variables
		}).catch(err => {
			console.error("Error during begin building onboarding: ", err);
			snack.enqueueSnackbar("We ran into an issue submitting your information. Please try again.", {
				variant: "error"
			});
		});
	}

	function handleConfirmOnboarding(variables: VariablesOf<typeof ConfirmOnboarding>) {
		confirmOnboarding({
			variables: variables
		}).catch(err => {
			console.error("Error during confirm building onboarding: ", err);
			snack.enqueueSnackbar("We weren't able to confirm your account. Please confirm your code is correct and try again.", {
				variant: "error"
			});
		});
	}

	return {
		canResendCode,
		resendCode: handleBeginOnboarding,
		isConfirmingCode: confirmLoading,
		isSendingCode: beginLoading,
		message: beginData?.BeginOnboarding?.message || "",
		exists: beginData?.BeginOnboarding?.exists || false,
		hasError: beginData?.BeginOnboarding?.hasError || false,
		wasCodeSent: beginData?.BeginOnboarding?.wasCodeSent || false,
		wasConfirmSuccessful: !!confirmData?.ConfirmOnboarding?.accessToken || false,
		beginOnboarding: handleBeginOnboarding,
		confirmOnboarding: handleConfirmOnboarding,
		accessToken: confirmData?.ConfirmOnboarding?.accessToken || "",
		refreshToken: confirmData?.ConfirmOnboarding?.refreshToken || "",
	};
}