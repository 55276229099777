import { gql } from "../../../__generated__";

export const FetchOrderMany = gql(/* GraphQL */`
	query FetchOrderMany($orderIds: [String!]!) {
		FetchOrderMany(orderIds: $orderIds) {
			...Order_HeaderFragment
		}
	}
`);

export const FetchOrderExpandedMany = gql(/* GraphQL */`
	query FetchOrderExpandedMany($orderIds: [String!]!) {
		FetchOrderMany(orderIds: $orderIds) {
			...Order_ExpandedFragment
		}
	}
`);