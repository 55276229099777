import React from "react";
import { TextFieldElement } from "react-hook-form-mui";

export const OrganizationNameElement: React.FC<{
	readonly?: boolean;
}> = ({ readonly }) => {
	return (
		<TextFieldElement
			fullWidth
			required
			name="businessName"
			label="Organization Name"
			InputProps={{
				readOnly: readonly
			}}
		/>
	);
};
