import { Fragment, useMemo } from "react";
import { useLogin } from "../auth";
import { Avatar } from "@mui/material";
import { Person } from "@mui/icons-material";
import { usePrimaryColor } from "./useTheme";

export function useAvatar(
	width?: string,
	height?: string
) {
	const { user } = useLogin();
	const color = usePrimaryColor();

	return useMemo(() => {
		return (
			<Fragment>
				{user?.avatar
					? (
						<Avatar
							sx={{ height, width }}
							src={user.avatar?.contentUrl || undefined}
						/>
					)
					: (
						<Avatar
							sx={{ background: color, height, width }}
						>
							<Person />
						</Avatar>
					)}
			</Fragment>
		);
	}, [ user, color, width, height ]);
}