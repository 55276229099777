import React, { useMemo, useState } from "react";
import { FindWasteContainer } from "../../../../../graphql";
import { Typography, useTheme } from "@mui/material";
import { useWindowDimensions } from "../../../../../hooks";
import { Box } from "grommet";
import { Icon } from "@liverego/icons";
import { ResultOf } from "@graphql-typed-document-node/core";

export const WasteContainerListItem: React.FC<{
	selected?: boolean;
	onClick?: () => void;
	container: ResultOf<typeof FindWasteContainer>[ "FindWasteContainer" ][ 0 ];
}> = ({ selected, onClick, container }) => {
	const theme = useTheme();

	const fillColor = useMemo(() => {
		if(theme.palette.primary.main === theme.palette.accent1.main) {
			return "white";
		}

		return theme.palette.primary.main;
	}, [ theme ]);

	const { size } = useWindowDimensions();
	const [ isHovering, setIsHovering ] = useState(false);

	return (
		<Box width="100%" gap="small" flex>
			<Box align="center">
				<Box
					width="124px"
					align="center"
					justify="center"
					style={{ position: "relative" }}
				>
					<Box
						round
						pad="small"
						elevation="small"
						background={"accent-2"}
						align="center"
						justify="center"
						hoverIndicator
						onClick={(() => {
							if(onClick) {
								onClick();
							}
						})}
						onMouseOver={() => {
							setIsHovering(true);
						}}
						onMouseLeave={() => {
							setIsHovering(false);
						}}
						style={{
							border: selected ? `2px solid ${theme.palette.primary.main}` : undefined,
						}}
					>

						<Box
							flex
							align="center"
							justify="center"
						>
							<Icon
								url={container.iconUrl || "@liverego/icons/Other"}
								sx={{
									fontSize: size === "small" ? "60px" : "100px",
									fill: isHovering
										? theme.palette.secondary.main
										: fillColor,
									maxWidth: "80%",
									maxHeight: "80%"
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box align="center" width="100%" flex justify="end">
				<Typography
					variant="caption"
					fontWeight="bold"
					textAlign="center"
				>
					{container.name}
				</Typography>
			</Box>
		</Box>
	);
};