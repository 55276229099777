import { Routes, Route, Navigate } from "react-router";
import { DashboardLayout } from "../../features/dashboard/layout";
import { SidebarRouteItem } from "./SidebarRouteItem";
import { Sidebar } from "./Sidebar";
import React, { ReactNode } from "react";

export class SidebarRouter {
	constructor(
		private readonly routes: SidebarRouteItem[],
		private readonly debugTitle?: string,
		private readonly redirects?: { from: string, to: string; }[],
		protected readonly nestedRoutes: { path: string, element: ReactNode; }[] = [],
		protected readonly hideAvatar?: boolean
	) { }

	toRouter() {
		return (
			<Routes>
				<Route path="/" element={this.toController()}>
					{this.routes.flatMap(route => (
						<Route key={route.toRouteProps().path} {...route.toRouteProps()} />
					))}
					{this.nestedRoutes.flatMap(route => (
						<Route key={`nested-${route.path}`} {...route} />
					))}
					{this.redirects?.flatMap(redirect => (
						<Route key={`redirect-${redirect.from}`} path={redirect.from} element={<Navigate replace to={redirect.to} />} />
					))}
				</Route>
			</Routes>
		);
	}

	toController() {
		return (
			<DashboardLayout
				sidebar={this.toSidebar()}
				debugTitle={this.debugTitle || ""}
			/>
		);
	}

	toSidebar() {
		return (
			<Sidebar
				hideAvatar={this.hideAvatar}
				primaryListItems={this.routes.filter(route => route.isPrimary).map(route => route.getListItem())}
				secondaryListItems={this.routes.filter(route => !route.isPrimary).map(route => route.getListItem())}
			/>
		);
	}
}