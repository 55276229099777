import { gql } from "@apollo/client";

export const Stop_PreviewFragment = gql`
	fragment Stop_PreviewFragment on Stop {
		...Stop_HeaderFragment
		...Stop_TrackingFragment
		incomingCount
		outgoingCount
		address{
			...AddressFragment
		}
		outlet {
			...OutletFragment
		}
	}
`;