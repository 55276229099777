import { SvgIcon, SvgIconProps } from "@mui/material";

	export const LoungeChair: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="m1014 570h-42c-10.801 0-21.602 3.6016-31.199 9.6016-3.6016 2.3984-7.1992 6-10.801 9.6016l-16.801-414c-2.3984-56.398-49.199-100.8-105.6-100.8l-410.4-0.003906c-56.398 0-103.2 44.398-105.6 100.8l-19.199 414c-3.6016-3.6016-7.1992-7.1992-10.801-10.801-9.6016-6-20.398-9.6016-31.199-9.6016h-43.199c-31.199 0-56.398 25.199-56.398 56.398v3.6016l44.398 240c12 63.602 60 112.8 121.2 128.4l-34.801 106.8c-1.1992 4.8008-1.1992 10.801 2.3984 15.602 3.6016 4.8008 8.3984 7.1992 13.199 7.1992h33.602c6 0 12-3.6016 14.398-9.6016l57.602-114h432l57.602 114c2.3984 6 8.3984 9.6016 14.398 9.6016h33.602c4.8008 0 10.801-2.3984 13.199-7.1992 3.6016-4.8008 3.6016-9.6016 2.3984-15.602l-32.395-108c61.199-14.398 109.2-63.602 121.2-128.4l44.398-240v-3.6016c1.1992-28.801-24-54-55.199-54zm-414 277.2h-228c-26.398 0-50.398-19.199-55.199-45.602-3.6016-21.602-8.3984-44.398-12-67.199 0-2.3984-1.1992-3.6016-1.1992-6 13.199-9.6016 30-15.602 48-15.602h495.6c16.801 0 33.602 6 46.801 15.602 0 2.3984-1.1992 3.6016-1.1992 6-3.6016 21.602-7.1992 44.398-12 67.199-4.8008 26.398-27.602 45.602-55.199 45.602zm-276-669.6c1.1992-38.398 33.602-68.398 72-68.398h410.4c38.398 0 70.801 30 72 68.398l21.602 514.8c-15.602-8.3984-33.602-12-51.602-12h-496.8c-18 0-36 4.8008-51.602 12zm-24 913.2 30-90h15.602zm570-90 30 90-45.602-90zm122.4-138c-10.801 61.199-64.801 105.6-126 105.6h-39.602l-226.8-0.003906h-266.4c-62.398 0-115.2-44.398-126-105.6l-44.398-237.6c1.1992-12 10.801-20.398 22.801-20.398l42 0.003906c4.8008 0 8.3984 1.1992 12 3.6016 4.8008 3.6016 8.3984 8.3984 9.6016 13.199 8.3984 39.602 15.602 79.199 21.602 118.8 3.6016 21.602 7.1992 44.398 12 67.199 8.3984 42 44.398 73.199 87.602 73.199h456c43.199 0 80.398-31.199 87.602-73.199 3.6016-22.801 8.3984-45.602 12-67.199 1.1992-4.8008 1.1992-9.6016 2.3984-14.398v-1.1992c6-33.602 12-68.398 19.199-103.2 1.1992-6 4.8008-10.801 9.6016-13.199 3.6016-2.3984 8.3984-3.6016 12-3.6016h43.199c12 0 21.602 9.6016 22.801 20.398z" />
</svg>
			</SvgIcon>
		);
	};