import { AutocompleteElement } from "react-hook-form-mui";
import { useClassification } from "../../hooks";
import { useMemo } from "react";
import { Spinner } from "grommet";

interface ItemTypeLike {
	id: string;
	name: string;
}

export const ItemSizeElement: React.FC<{
	isLoading?: boolean;
	includeHidden?: boolean;
	type: ItemTypeLike | null;
	size: ItemTypeLike | null;
	onChange?: (event: React.SyntheticEvent, value: ItemTypeLike | null) => void;
}> = ({ type, size, onChange, includeHidden }) => {
	const { types, loading } = useClassification(includeHidden);

	const sizeOptions = useMemo(() => {
		return Array.from([ ...types.find(t => t.id === type?.id)?.sizes ?? [] ]).sort((a, b) => a.name.localeCompare(b.name));
	}, [ type, types ]);

	if(sizeOptions.length === 0) return null;

	return (
		<AutocompleteElement
			label="Item Size"
			name="size"
			required
			loading={loading}
			loadingIndicator={(<Spinner />)}
			options={sizeOptions.map((size) => ({ id: size.id, label: size.name }))}
			autocompleteProps={{
				value: onChange ? size : undefined,
				onChange: onChange,
				getOptionLabel: (option) => option.label ?? option.name,
			}}
		/>
	);
};
