import { useMemo } from "react";
import { TabController, ViewContainer } from "../../../../components";
import { ActiveOrdersTabFactory, CompletedOrdersTabFactory, defaultColumns, RecentlyViewedOrdersTabFactory, SavedOrdersTabFactory } from "../../common";
import { InstanceType, Order_ExpandedFragmentFragment } from "../../../../graphql/__generated__/graphql";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

export const GlobalAdminOrdersView: React.FC = () => {
	const columns = useMemo((): GridColDef[] => {
		return defaultColumns.map(column => {
			if(column.field === "referer") {
				return {
					...column,
					field: "referer",
					headerName: "Referer",
					valueGetter: (params: GridValueGetterParams) => {
						const order = params.row as Order_ExpandedFragmentFragment;
						const refererName = order.referer?.name;

						if(order.instance.type === InstanceType.ManagementCompany) {
							return `${refererName} (${order.instance.name})`;
						}

						return order.referer?.name ?? "";
					}
				};
			}

			return column;
		});
	}, [ defaultColumns ]);

	return (
		<ViewContainer>
			<TabController
				defaultValue="active"
				tabs={[
					ActiveOrdersTabFactory(columns),
					CompletedOrdersTabFactory(columns),
					RecentlyViewedOrdersTabFactory(columns),
					SavedOrdersTabFactory(columns)
				]}
			/>
		</ViewContainer>
	);
};