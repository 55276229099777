import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Futon: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
	xmlns="http://www.w3.org/2000/svg">
	<g>
		<path
			d="m441.3 408.23c0 12.602-10.199 22.801-22.801 22.801s-22.801-10.199-22.801-22.801c0-12.602 10.199-22.801 22.801-22.801s22.801 10.199 22.801 22.801z" />
		<path
			d="m258.98 408.23c0 12.602-10.199 22.801-22.801 22.801-12.602 0-22.801-10.199-22.801-22.801 0-12.602 10.199-22.801 22.801-22.801 12.598 0 22.801 10.199 22.801 22.801z" />
		<path
			d="m988.27 408.23c0 12.602-10.273 22.801-22.875 22.801-12.523 0-22.801-10.199-22.801-22.801 0-12.602 10.273-22.801 22.801-22.801 12.602 0 22.875 10.199 22.875 22.801z" />
		<path
			d="m805.95 408.23c0 12.602-10.273 22.801-22.801 22.801-12.602 0-22.875-10.199-22.875-22.801 0-12.602 10.273-22.801 22.875-22.801 12.527 0 22.801 10.199 22.801 22.801z" />
		<path
			d="m1164.3 636.9v-372.38c0-59.926-48.824-108.75-108.75-108.75h-911.48c-59.926 0-108.75 48.824-108.75 108.75v537.15c0 59.398 48.375 107.77 107.77 107.77h17.25l-36.898 99.375c-5.0234 13.574 1.875 28.727 15.449 33.75 3.0742 1.125 6.1484 1.6484 9.1484 1.6484 10.648 0 20.699-6.5234 24.602-17.102l43.648-117.68h766.57l43.648 117.68c3.8984 10.574 13.949 17.102 24.602 17.102 3 0 6.1484-0.52344 9.1484-1.6484 13.574-5.0234 20.477-20.176 15.449-33.75l-36.898-99.375h17.699c59.398 0 107.77-48.375 107.77-107.77v-33.523c0.14844-1.1992 0.375-2.3242 0.375-3.6016l0.007812-120.6c0-2.3984-0.22656-4.7227-0.375-7.0508zm-1076.5 7.0508c0-24.898 20.25-45.074 45.148-45.074h126.38c24.824 0 45.074 20.176 45.074 45.074v40.199h-161.32c-20.176 0-39.074 5.6992-55.273 15.449zm1024 157.73c0 30.449-24.824 55.273-55.273 55.273h-913.43c-30.449 0-55.273-24.824-55.273-55.273v-9.75c0-30.449 24.824-55.273 55.273-55.273h913.43c30.449 0 55.273 24.824 55.273 55.273zm0-102.07c-16.199-9.75-35.102-15.449-55.273-15.449h-160.95v-40.199c0-24.898 20.25-45.074 45.074-45.074h126.38c23.023 0 42.074 17.324 44.773 39.676zm0-142.28c-13.426-6.9766-28.648-10.949-44.773-10.949h-126.38c-53.773 0-97.574 43.727-97.574 97.574v40.199h-486.15v-40.199c0-53.852-43.801-97.574-97.574-97.574h-126.38c-16.273 0-31.648 4.0508-45.148 11.102l-0.003906-292.95c0-31.051 25.273-56.25 56.25-56.25h911.48c30.977 0 56.25 25.199 56.25 56.25z" />
		<path
			d="m623.62 408.23c0 12.602-10.273 22.801-22.801 22.801-12.602 0-22.801-10.199-22.801-22.801 0-12.602 10.199-22.801 22.801-22.801 12.527 0 22.801 10.199 22.801 22.801z" />
	</g>
</svg>
			</SvgIcon>
		);
	};