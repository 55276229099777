import { gql } from "@apollo/client";

export const Order_UserFragment = gql`
	fragment Order_UserFragment on Order {
		id
		...Order_HeaderFragment
		...Order_PickupFragment
		...Order_AddressFragment
		...Order_QuotingFragment
		...Order_EstimateFragment
		...Order_ProductsFragment
		...Order_CancellationDetailsFragment
	}
`;