import { FormContainer, TextFieldElement, UseFormReturn } from "react-hook-form-mui";
import { ItemSizeElement, ItemTypeElement } from "../../../../components";
import { Box } from "grommet";
import { UpsertOrderProductFormValues } from "./CreateOrderProductDialog";
import { ProductMediaUploadElement } from "../../../../components/product/ProductMediaUploadElement";


export const UpsertOrderProductForm: React.FC<{
	loading: boolean;
	onSubmit: (values: UpsertOrderProductFormValues) => void;
	formContext: UseFormReturn<UpsertOrderProductFormValues>;
}> = ({ loading, formContext }) => {
	return (
		<FormContainer formContext={formContext}>
			<Box gap="medium">
				<Box gap="medium">
					{[
						<ItemTypeElement
							isLoading={loading}
							type={formContext.watch("type")} />,
						<ItemSizeElement
							isLoading={loading}
							type={formContext.watch("type")}
							size={formContext.watch("size")} />
					].filter(v => !!v)}
				</Box>
				<TextFieldElement
					name="quantity"
					inputMode="numeric"
					label="Quantity"
					type="number"
					required
					validation={{
						min: {
							value: 1,
							message: "Quantity must be at least 1"
						}
					}} />
				<ProductMediaUploadElement
					loading={loading}
					media={formContext.watch("media")}
					onFileUploaded={(file) => {
						formContext.setValue("media", [
							...formContext.watch("media"),
							{
								name: file.name,
								contentUrl: URL.createObjectURL(file),
								file
							}
						]);
					}} />
			</Box>
		</FormContainer>
	);
};
