import { CalculatorState } from "../../store/calculator";
import { Frequency } from "./types";

export class WasteScenario {
	constructor(
		protected dto: CalculatorState
	) { }

	static dailyWastePerPersonPounds = 4.9;

	toPeriod(value: number) {
		switch(this.dto.period) {
			case "daily": {
				return value;
			}
			case "weekly": {
				return value * 7;
			}
			case "monthly": {
				return value * 30;
			}
			case "yearly": {
				return value * 365;
			}
			default: {
				return value;
			}
		}
	}

	toHousehold(value: number) {
		return value * this.dto.householdSize;
	}

	get totalWastePounds() {
		return this.toPeriod(
			this.dailyWastePerHouseholdPounds
		);
	}

	get totalWasteReducedPounds(): number {
		return this.toPeriod(
			this.toHousehold(this.dailyWasteReductionFromPledgesPounds)
		);
	}

	get totalWasteReducedPercentage(): number {
		return (this.totalWasteReducedPounds / this.totalWastePounds) * 100;
	}

	get dailyWastePerPersonPounds() {
		return WasteScenario.dailyWastePerPersonPounds + this.dailyWasteImpactFromResponsesPounds;
	}

	get dailyWastePerPersonBaselinePounds() {
		return WasteScenario.dailyWastePerPersonPounds;
	}

	get dailyWastePerHouseholdPounds() {
		return this.toHousehold(this.dailyWastePerPersonPounds);
	}

	get dailyWastePerHouseholdBaselinePounds() {
		return this.toHousehold(WasteScenario.dailyWastePerPersonPounds);
	}

	get dailyWasteImpactFromResponsesPounds(): number {
		return [
			this.dailyImpactFromClothingWastePounds.impact,
			this.dailyImpactFromFoodWastePounds.impact,
			this.dailyImpactFromPlasticBagsPounds.impact,
			this.dailyImpactFromTakeoutPounds.impact,
			this.dailyImpactFromFurnitureWastePounds.impact,
			this.dailyImpactFromPlasticBottlesPounds.impact,
			this.dailyImpactFromAluminumPounds.impact,
			this.dailyImpactFromGlassPounds.impact,
			this.dailyImpactFromPaperCardboardPounds.impact
		].reduce((acc, value) => acc + value, 0);
	}

	get dailyImpactFromClothingWastePounds(): { impact: number; expected: number; } {
		const poundsPerYear = 81.5;
		const expectedAmountPerDay = poundsPerYear / 365;

		//Expected Range
		return {
			expected: expectedAmountPerDay,
			impact: 0
		};
	}

	get dailyImpactFromFoodWastePounds(): { impact: number; expected: number; } {
		//Daily Food Waste Per Person
		const rate = 0.216;
		const expectedAmountPerDay = rate;

		switch(this.dto.responseDisposeFoodWasteFrequency) {
			case "compost": {
				return {
					expected: expectedAmountPerDay,
					impact: -expectedAmountPerDay
				};
			}
			case Frequency.NEVER: {
				return {
					expected: expectedAmountPerDay,
					impact: -expectedAmountPerDay
				};
			}
			case Frequency.RARELY: {
				return {
					expected: expectedAmountPerDay,
					impact: 0
				};
			}
			case Frequency.SOMETIMES: {
				return {
					expected: expectedAmountPerDay,
					impact: (5 - 1) * rate
				};
			}
			case Frequency.OFTEN: {
				return {
					expected: expectedAmountPerDay,
					impact: (8 - 1) * rate
				};
			}
			default: {
				return {
					expected: expectedAmountPerDay,
					impact: 0
				};
			}
		}
	}

	get dailyImpactFromPlasticBagsPounds(): { impact: number; expected: number; } {
		const gramsPerBag = 6;
		const bagsPerDay = 1;

		const poundsPerBag = gramsPerBag / 453.59237;

		//365 bags per person per year
		const expectedAmountPerDay = poundsPerBag * bagsPerDay;

		switch(this.dto.responsePlasticBagFrequency) {
			case Frequency.NEVER: {
				return {
					expected: expectedAmountPerDay,
					impact: -expectedAmountPerDay
				};
			}
			case Frequency.RARELY: {
				return {
					expected: expectedAmountPerDay,
					impact: 0
				};
			}
			case Frequency.SOMETIMES: {
				return {
					expected: expectedAmountPerDay,
					impact: (5 - bagsPerDay) * poundsPerBag
				};
			}
			case Frequency.OFTEN: {
				return {
					expected: expectedAmountPerDay,
					impact: (8 - bagsPerDay) * poundsPerBag
				};
			}
			default: {
				return {
					expected: expectedAmountPerDay,
					impact: 0
				};
			}
		}
	}

	get dailyImpactFromTakeoutPounds(): { impact: number; expected: number; } {
		const wastePerTakeoutMeal = 0.50;
		const expectedPerWeek = 2;

		//60% of americans order takeout at least 1 per week
		const expectedAmount = expectedPerWeek * wastePerTakeoutMeal;

		switch(this.dto.responseTakeoutFrequency) {
			case Frequency.NEVER: {
				return {
					expected: expectedAmount,
					impact: -expectedAmount
				};
			}
			case Frequency.RARELY: {
				//Expected Range
				return {
					expected: expectedAmount,
					impact: 0
				};
			}
			case Frequency.SOMETIMES: {
				return {
					expected: expectedAmount,
					impact: (5 - expectedPerWeek) * wastePerTakeoutMeal
				};
			}
			case Frequency.OFTEN: {
				return {
					expected: expectedAmount,
					impact: (8 - expectedPerWeek) * wastePerTakeoutMeal
				};
			}
			default: {
				return {
					expected: expectedAmount,
					impact: 0
				};
			}
		}
	}

	get dailyImpactFromFurnitureWastePounds(): { impact: number; expected: number; } {
		const rate = 19.5;
		const expectedAmountPerDay = WasteScenario.dailyWastePerPersonPounds * (rate / 100);

		//Expected Range
		return {
			expected: expectedAmountPerDay,
			impact: 0
		};
	}

	get dailyImpactFromPlasticBottlesPounds(): { impact: number; expected: number; } {
		const bottlesPerYear = 156;
		const bottlesPerDay = bottlesPerYear / 365;

		const gramsPerBottle = 10;
		const poundsPerBottle = gramsPerBottle / 453.59237;

		const expectedAmountPerDay = poundsPerBottle * bottlesPerDay;

		switch(this.dto.responsePlasticBottleFrequency) {
			case Frequency.NEVER: {
				return {
					expected: expectedAmountPerDay,
					impact: -expectedAmountPerDay
				};
			}
			case Frequency.RARELY: {
				return {
					expected: expectedAmountPerDay,
					impact: 0
				};
			}
			case Frequency.SOMETIMES: {
				return {
					expected: expectedAmountPerDay,
					impact: ((5 / 7)) * poundsPerBottle
				};
			}
			case Frequency.OFTEN: {
				return {
					expected: expectedAmountPerDay,
					impact: ((8 / 7)) * poundsPerBottle
				};
			}
			default: {
				return {
					expected: expectedAmountPerDay,
					impact: 0
				};
			}
		}
	}

	get dailyImpactFromAluminumPounds(): { impact: number; expected: number; } {
		const rate = 1.3;
		const expectedAmountPerDay = WasteScenario.dailyWastePerPersonPounds * (rate / 100);

		//Expected Range
		return {
			expected: expectedAmountPerDay,
			impact: 0
		};
	}

	get dailyImpactFromGlassPounds(): { impact: number; expected: number; } {
		const rate = 4.2;
		const expectedAmountPerDay = WasteScenario.dailyWastePerPersonPounds * (rate / 100);

		//Expected Range
		return {
			expected: expectedAmountPerDay,
			impact: 0
		};
	}

	get dailyImpactFromPaperCardboardPounds(): { impact: number; expected: number; } {
		const rate = 23.1;
		const expectedAmountPerDay = WasteScenario.dailyWastePerPersonPounds * (rate / 100);

		//Expected Range
		return {
			expected: expectedAmountPerDay,
			impact: 0
		};
	}

	get dailyWasteReductionFromFoodWastePounds(): number {
		const { expected, impact } = this.dailyImpactFromFoodWastePounds;

		//Assume all food waste is reduced / composted
		return expected + impact;
	}

	get dailyWasteReductionFromPlasticBagsPounds(): number {
		const { expected, impact } = this.dailyImpactFromPlasticBagsPounds;

		//Assume all plastic bags are eliminated
		return expected + impact;
	}

	get dailyWasteReductionFromPlasticBottlesPounds(): number {
		const { expected, impact } = this.dailyImpactFromPlasticBottlesPounds;

		//Assume all plastic bottles are eliminated
		return expected + impact;
	}

	get dailyWasteReductionFromTakeoutPounds(): number {
		const { expected, impact } = this.dailyImpactFromTakeoutPounds;

		//Assume 1/3 of weight is eliminated
		return (expected + impact) * (1 / 3);
	}

	get dailyWasteReductionFromFurnitureReusePounds(): number {
		const { expected, impact } = this.dailyImpactFromFurnitureWastePounds;

		//Assume all furniture is reused
		return expected + impact;
	}

	get dailyWasteReductionFromClothingReusePounds(): number {
		const { expected, impact } = this.dailyImpactFromClothingWastePounds;

		//Assume all clothing is reused / recycled
		return expected + impact;
	}

	get dailyWasteReductionFromPaperCardboardPounds(): number {
		const { expected, impact } = this.dailyImpactFromPaperCardboardPounds;

		//68.3% of paper / cardboard is recycled
		return (expected + impact) * 0.683;
	}

	get dailyWasteReductionFromGlassAluminumPounds(): number {
		const { expected: eGlass, impact: iGlass } = this.dailyImpactFromGlassPounds;
		const { expected: eAluminum, impact: iAluminum } = this.dailyImpactFromAluminumPounds;

		//31.3% of glass is recycled
		//https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/glass-material-specific-data

		const glassExpected = (eGlass + iGlass) * 0.313;

		//50.4% of aluminum is recycled
		//https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/aluminum-material-specific-data

		const aluminumExpected = (eAluminum + iAluminum) * 0.504;

		return glassExpected + aluminumExpected;
	}

	get dailyWasteReductionFromPledgesPounds(): number {
		//How much to add / subtract based on the pledges
		let differential = 0;

		if(this.dto.pledgeEliminatePlasticBags) {
			differential += this.dailyWasteReductionFromPlasticBagsPounds;
		}

		if(this.dto.pledgeCompostReduceFoodWaste) {
			differential += this.dailyWasteReductionFromFoodWastePounds;
		}

		if(this.dto.pledgeReduceTakeoutWaste) {
			differential += this.dailyWasteReductionFromTakeoutPounds;
		}

		if(this.dto.pledgeFurnitureReuse) {
			differential += this.dailyWasteReductionFromFurnitureReusePounds;
		}

		if(this.dto.pledgeEliminatePlasticBottles) {
			differential += this.dailyWasteReductionFromPlasticBottlesPounds;
		}

		if(this.dto.pledgeReduceClothingWaste) {
			differential += this.dailyWasteReductionFromClothingReusePounds;
		}

		if(this.dto.pledgeRecycleGlassAluminum) {
			differential += this.dailyWasteReductionFromGlassAluminumPounds;
		}

		if(this.dto.pledgeReducePaperCardboardWaste) {
			differential += this.dailyWasteReductionFromPaperCardboardPounds;
		}

		return differential;
	}
}
