import { StepDefinition } from "./types";

export class OnboardingStep {
	constructor(
		protected readonly definition: StepDefinition
	) { }

	get id() {
		return this.definition.id;
	}

	get label() {
		return this.definition.label;
	}

	get description() {
		return this.definition.description;
	}

	get helperTitle() {
		return this.definition.helperTitle;
	}

	get helperText() {
		return this.definition.helperText;
	}
}