import { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { Authorize } from "../../graphql";
import { useInstance } from "../../hooks";
import { DocumentType } from "../../graphql";
import { useConfirmationDialog } from "../../provider";
import EventEmitter from "events";
import { useAppDispatch } from "../../store";
import { noop } from "../../helpers";
import { push } from "redux-first-history";
import { InstanceType } from "../../graphql/__generated__/graphql";

export function useAuthorize(
	resolver: (result: DocumentType<typeof Authorize>[ "Authorize" ]) => boolean
) {
	const { instance, loading: instanceLoading } = useInstance();
	const [
		fetchAuthorize, { data, loading: authorizeLoading }
	] = useLazyQuery(Authorize);

	useEffect(() => {
		if(instance) {
			fetchAuthorize({
				variables: {
					instanceId: instance.id
				}
			}).catch(err => {
				console.error("Failed to fetch instance authorization", err);
			});
		}
	}, [ instance ]);

	const isAuthorized = useMemo(() => {
		if(!data) {
			return false;
		}

		return resolver(data.Authorize);
	}, [ data, resolver ]);

	return {
		isAuthorized,
		roles: data?.Authorize || {
			isGlobalAdmin: false,
			isInstanceAdmin: false,
			isInstanceMember: false,
			isInstanceManager: false
		},
		loading: instanceLoading || authorizeLoading
	};
}

const adminInterruptEvent = new EventEmitter();

export function useAdminIncorrectLoginInterrupt() {
	const dispatch = useAppDispatch();
	const { instance } = useInstance();
	const { showDialog } = useConfirmationDialog();
	const { roles } = useAuthorize(({ isGlobalAdmin }) => isGlobalAdmin);

	useEffect(() => {
		if(!instance) return;

		if(instance.type === InstanceType.Reporting) {
			dispatch(push("/admin"));
			return;
		}

		if(roles.isGlobalAdmin || roles.isInstanceAdmin || roles.isInstanceManager) {
			adminInterruptEvent.emit("interrupt");
		}
	}, [ roles, instance, dispatch ]);

	useEffect(() => {
		adminInterruptEvent.once("interrupt", () => {
			showDialog({
				title: "Switch to Admin?",
				content: "Looks like you're trying to access a user page while logged in as an admin. Would you like to switch to the admin view?",
				onConfirm: () => {
					dispatch(push("/admin"));
				},
				onCancel: noop,
				cancelButtonLabel: "Stay",
				confirmButtonLabel: "Switch"
			});
		});

		return () => {
			adminInterruptEvent.removeAllListeners();
		};
	}, []);
}