import { useQuery } from "@apollo/client";
import { FetchAdminProduct } from "../../../graphql";
import { useMemo } from "react";

export function useProduct(productId: string) {
	const { data, loading, error } = useQuery(FetchAdminProduct, {
		variables: { productId },
		skip: !productId
	});

	const product = useMemo(() => {
		return data?.FetchProduct ?? null;
	}, [ data ]);

	return {
		error,
		product,
		loading
	};
}