import { SvgIcon, SvgIconProps } from "@mui/material";

export const Drink: React.FC<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path d="m431.25 656.25h65.625c34.473 0.074219 68.004 11.254 95.625 31.875 21.125 15.77 46.766 24.316 73.125 24.375h65.625v-112.5h-300z" />
					<path d="m393.75 764.32v-89.324h-268.03c15.551 50.91 15.109 105.37-1.2578 156.02l-1.0117 3.0547c-7.25 22.18-10.949 45.367-10.949 68.699v184.73c0 9.9453 3.9492 19.484 10.984 26.516 7.0312 7.0352 16.57 10.984 26.516 10.984h18.75c9.9453 0 19.484-3.9492 26.516-10.984 7.0352-7.0312 10.984-16.57 10.984-26.516 0-6.6992 3.5742-12.887 9.375-16.238 5.8008-3.3477 12.949-3.3477 18.75 0 5.8008 3.3516 9.375 9.5391 9.375 16.238 0 13.398 7.1484 25.777 18.75 32.477 11.602 6.6992 25.898 6.6992 37.5 0 11.602-6.6992 18.75-19.078 18.75-32.477 0-6.6992 3.5742-12.887 9.375-16.238 5.8008-3.3477 12.949-3.3477 18.75 0 5.8008 3.3516 9.375 9.5391 9.375 16.238 0 9.9453 3.9492 19.484 10.984 26.516 7.0312 7.0352 16.57 10.984 26.516 10.984h18.75c6.5938-0.019531 13.062-1.7852 18.75-5.1172v-196.71c-0.027344-24.66-5.7734-48.977-16.781-71.043-13.59-27.277-20.684-57.332-20.719-87.809z" />
					<path d="m397.5 367.5c-38.824-51.992-59.867-115.11-60-180v-37.5h-112.5v37.5c-0.13281 64.891-21.176 128.01-60 180-10.445 14.059-19.449 29.137-26.867 45h286.23c-7.418-15.863-16.422-30.941-26.867-45z" />
					<path d="m393.75 637.5v-56.25c0-4.9727 1.9766-9.7422 5.4922-13.258 3.5156-3.5156 8.2852-5.4922 13.258-5.4922h37.5v-37.5c-0.089844-25.414-3.8789-50.68-11.25-75h-315c-7.3711 24.32-11.16 49.586-11.25 75v73.633c0.039062 13.035 1.2305 26.043 3.5625 38.867z" />
					<path d="m206.25 75h150v37.5h-150z" />
					<path d="m487.5 1087.5c-6.4023-0.0625-12.746-1.2305-18.75-3.4492v40.949h225v-40.949c-6.0039 2.2188-12.348 3.3867-18.75 3.4492z" />
					<path d="m768.75 764.32c-0.007812 30.469-7.0664 60.523-20.625 87.809-7.5039 15.059-12.57 31.219-15 47.867h354.38v-225h-318.75z" />
					<path d="m731.25 1012.5v37.5h356.25v-112.5h-356.25z" />
					<path d="m731.25 1087.5v37.5h322.86l9.375-37.5z" />
					<path d="m1055.5 482.01c-3.5156-3.5156-5.4922-8.2852-5.4922-13.258v-18.75h-300v18.75c0 4.9727-1.9766 9.7422-5.4922 13.258l-32.008 32.004v48.488h37.5c4.9727 0 9.7422 1.9766 13.258 5.4922 3.5156 3.5156 5.4922 8.2852 5.4922 13.258v56.25h318.75v-123.49z" />
					<path d="m731.25 750h-65.625c-34.473-0.074219-68.004-11.254-95.625-31.875-21.125-15.77-46.766-24.316-73.125-24.375h-65.625v70.574c0.027344 24.66 5.7734 48.977 16.781 71.043 13.59 27.277 20.684 57.332 20.719 87.809v108.07c0 4.9727 1.9766 9.7422 5.4922 13.258 3.5156 3.5156 8.2852 5.4922 13.258 5.4922h187.5c4.9727 0 9.7422-1.9766 13.258-5.4922 3.5156-3.5156 5.4922-8.2852 5.4922-13.258v-108.07c0.007812-30.469 7.0664-60.523 20.625-87.809 11.043-22.059 16.816-46.375 16.875-71.043zm-206.25 56.25h-37.5v-37.5h37.5zm75 150h-37.5v-37.5h37.5zm75-131.25h-37.5v-37.5h37.5z" />
				</g>
			</svg>
		</SvgIcon>
	);
};