import { gql } from "../../../__generated__";

export const FetchConfig = gql(/* GraphQL */ `
	query FetchConfig {
		FetchConfig {
			host
			environment
			googleApiKey
		}
	}
`);