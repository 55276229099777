import { gql } from "../../../__generated__";

export const FetchCheckoutSessionV2 = gql(/* GraphQL */ `
	query FetchCheckoutSessionV2(
		$sessionId: String!
	) {
		FetchCheckoutSession(
			sessionId: $sessionId
		) {
			id
			...CheckoutSession_CombinedFragment
		}
	}
`);