import { useSnackbar } from "notistack";
import { DialogWithActivatorButton } from "../../../../../components";
import { WasteAuditBetaSignup, WasteAuditBetaStatus } from "../../../../../graphql";
import { useInstance } from "../../../../../hooks";
import { useToggleForceClose } from "../../../components";
import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Box } from "grommet";
import { Alert, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export function useWastwAuditBeta() {
	const snack = useSnackbar();
	const { instance } = useInstance();

	const { data, loading: statusLoading } = useQuery(WasteAuditBetaStatus, {
		skip: !instance,
		variables: { instanceId: instance?.id || "" },
	});

	const [
		signup,
		{ loading: signupLoading },
	] = useMutation(WasteAuditBetaSignup, {
		refetchQueries: [ WasteAuditBetaStatus ]
	});

	function handleSignup() {
		signup({
			variables: { instanceId: instance?.id || "" },
		}).catch(err => {
			console.error("failed during waste audit beta signup", err);
			snack.enqueueSnackbar("We ran into an issue submitting your request. Please try again later.", {
				variant: "error"
			});
		});
	}

	return {
		signup: handleSignup,
		loading: statusLoading || signupLoading,
		isActive: data?.WasteAuditBetaStatus || false,
	};
}

export const WasteAuditBetaDialog: React.FC<{
	activatorButton: React.ReactNode;
}> = ({ activatorButton }) => {
	const { isActive, signup, loading } = useWastwAuditBeta();
	const { forceClose, toggleForceClose } = useToggleForceClose();

	return (
		<DialogWithActivatorButton
			forceClose={forceClose}
			title="Waste Inspector Beta"
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="warning"
						variant="outlined"
						onClick={toggleForceClose}
					>
						Close
					</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={loading}
						disabled={isActive}
						onClick={signup}
					>
						Submit
					</LoadingButton>
				</Box>
			)}
		>
			<Box gap="medium">
				{isActive && (
					<Alert
						severity="success"
					>
						You're on the list! We'll reach out to you when we're ready to onboard you.
					</Alert>
				)}
				<Typography>
					Our AI powered platform can help you get started with your waste audit. Sign up for our private beta below and we'll reach out to you when we're ready to onboard you.
				</Typography>
				<LoadingButton
					fullWidth
					loading={loading}
					color="primary"
					variant="contained"
					onClick={signup}
					disabled={isActive}
				>
					Sign Up
				</LoadingButton>
			</Box>
		</DialogWithActivatorButton>
	);
};