import React, { useEffect, useMemo } from "react";
import { DashboardListItem, DialogWithActivatorButton, ListItemProps, Sidebar, SidebarListItem } from "../../../components";
import { AddTask, LocalShipping, RequestQuote, Settings, Sync } from "@mui/icons-material";
import { useHauler, useHaulerTasks } from "../hooks";
import { useLazyQuery } from "@apollo/client";
import { FindHaulerPendingQuoteRequests } from "../../../graphql";
import { Box } from "grommet";
import { selectSelectedHaulerId, setSelectedHaulerId, useAppDispatch, useAppSelector } from "../../../store";
import { Button, IconButton, List, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useToggleForceClose } from "../../admin/components";

export const PickupsListItem: React.FC<ListItemProps> = () => {
	const { hauler } = useHauler();

	return (
		<SidebarListItem
			text="Pickups"
			icon={<LocalShipping />}
			path="/hauler/pickups"
			notificationCount={hauler?.pendingRequests.length || 0}
		/>
	);
};

export const HaulerSettingsListItem: React.FC<ListItemProps> = () => {
	return (
		<SidebarListItem
			text="Settings"
			icon={<Settings />}
			path="/hauler/settings"
		/>
	);
};

export const HaulerSwitchAccountListItem: React.FC<ListItemProps> = () => {
	const { haulers } = useHauler();
	const dispatch = useAppDispatch();
	const selectedHauler = useAppSelector(selectSelectedHaulerId);
	const { forceClose, toggleForceClose } = useToggleForceClose();

	return (
		<DialogWithActivatorButton
			title="Switch Account"
			forceClose={forceClose}
			activatorButton={(
				<SidebarListItem
					text="Switch Account"
					icon={<Sync />}
					path="/hauler/"
				/>
			)}
			actions={(
				<Box align="start">
					<Button variant="outlined" color="error" onClick={toggleForceClose}>
						Close
					</Button>
				</Box>
			)}
		>
			<Box>
				<List>
					{haulers.map(h => (
						<ListItemButton
							divider
							key={h.id}
							onClick={() => {
								dispatch(setSelectedHaulerId(h.id));
								toggleForceClose();
							}}
							selected={selectedHauler === h.id}
						>
							<ListItemText>
								<Typography fontWeight="bold">
									{h.name}
								</Typography>
								<Typography variant="body2">
									Click to switch to this account
								</Typography>
							</ListItemText>
							<ListItemSecondaryAction>
								<IconButton>
									<Sync />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItemButton>
					))}
				</List>
			</Box>
		</DialogWithActivatorButton>
	);
};

export const HaulerQuotesListItem: React.FC<ListItemProps> = () => {
	const { hauler } = useHauler();

	const [
		fetchPendingQuotes,
		{ data, error }
	] = useLazyQuery(FindHaulerPendingQuoteRequests);

	useEffect(() => {
		if(hauler) {
			fetchPendingQuotes({
				variables: {
					haulerId: hauler.id
				}
			});
		}
	}, [ fetchPendingQuotes, hauler ]);

	useEffect(() => {
		if(error) {
			console.error("Failed to fetch hauler pending quotes for hauler: ", hauler?.id, error);
		}
	}, [ error, hauler ]);

	const pendingQuotes = useMemo(() => data?.FindHaulerPendingQuoteRequests ?? [], [ data ]);

	return (
		<SidebarListItem
			text="Quotes"
			icon={<RequestQuote />}
			path="/hauler/quotes"
			notificationCount={pendingQuotes.length}
		/>
	);
};

export const HaulerActionsListItem: React.FC<ListItemProps> = () => {
	const { hauler } = useHauler();
	const { tasks } = useHaulerTasks(hauler?.id ?? "");

	return (
		<SidebarListItem
			text="Action Items"
			icon={<AddTask />}
			path="/hauler/actions"
			notificationCount={tasks.length}
		/>
	);
};

export const HaulerSidebar: React.FC<{ shrink: boolean; }> = (props) => {
	const { haulers } = useHauler();

	const primaryItems = [
		<DashboardListItem
			key="dashboard"
			absolutePath="/hauler/dashboard"
		/>,
		<PickupsListItem
			key="pickups"
			absolutePath="/hauler/pickups"
		/>,
		<HaulerQuotesListItem
			key="quotes"
			absolutePath="/hauler/quotes"
		/>,
		<HaulerActionsListItem
			key="actions"
			absolutePath="/hauler/actions"
		/>
		// <SidebarListItem
		// 	text="Pricing"
		// 	icon={<PriceChange />}
		// 	path="/hauler/pricing"
		// />
	].filter(Boolean);

	const secondaryItems = [
		<HaulerSettingsListItem
			key="settings"
			absolutePath="/hauler/settings"
		/>,
		haulers.length > 1
			? (
				<HaulerSwitchAccountListItem
					absolutePath="/hauler/"
				/>
			)
			: null
	].filter(Boolean);

	return (
		<Sidebar
			primaryListItems={primaryItems}
			secondaryListItems={secondaryItems}
		/>
	);
};