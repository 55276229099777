import { Box } from "grommet";
import React, { useMemo } from "react";
import { BasicListItem } from "../../../../components";
import { IconButton, Typography } from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { useClipboard } from "../../../../hooks";
import { Home } from "grommet-icons";
import { useStop } from "../../../../graphql";

export const StopAddressComponent: React.FC<{
	stopId: string;
}> = ({ stopId }) => {
	const { copy } = useClipboard();
	const { stop, loading } = useStop(stopId);

	const address = useMemo(() => {
		if(!stop) return "";
		return [
			stop.address?.addressLineOne,
			[ stop?.address?.city, stop?.address?.state ].filter((line) => !!line).join(" "),
			stop.address?.zipCode
		].join(" ");
	}, [ stop ]);

	const addressLineOne = useMemo(() => {
		return stop?.address?.addressLineOne ?? "";
	}, [ stop ]);

	const addressLineTwo = useMemo(() => {
		return `${stop?.address?.city ?? ""}, ${stop?.address?.state ?? ""} ${stop?.address?.zipCode ?? ""}`;
	}, [ stop ]);

	return (
		<BasicListItem
			label="Pickup Address"
			value={(
				<Box>
					<Typography variant="body2" color="text.secondary">
						{addressLineOne}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{addressLineTwo}
					</Typography>
				</Box>
			)}
			loading={loading}
			icon={<Home />}
			action={(
				<IconButton onClick={() => copy(address)}>
					<CopyAll />
				</IconButton>
			)} />
	);
};
