import React, { Fragment, useState } from "react";
import { TextFieldElement, UseFormReturn } from "react-hook-form-mui";
import { OnboardingFormContext } from "../types";
import { Button, InputAdornment } from "@mui/material";
import { Autorenew, Circle } from "@mui/icons-material";
import { HexColorPicker } from "react-colorful";
import { DialogWithClose } from "../../../components";
import { Box } from "grommet";
import { useWindowDimensions } from "../../../hooks";
import { useDebouncyEffect } from "use-debouncy";

export const ColorPicker: React.FC<{
	color: string;
	onChange: (color: string) => void;
}> = ({ color, onChange }) => {
	return (
		<HexColorPicker
			color={color}
			onChange={onChange}
		/>
	);
};

export const ColorPickerDialog: React.FC<{
	color: string;
	onClose: () => void;
	onChange: (color: string) => void;
}> = ({ onClose, color, onChange }) => {
	const { size } = useWindowDimensions();
	const [ value, setValue ] = useState(color);

	useDebouncyEffect(() => onChange(value), 100, [ value ]);

	return (
		<DialogWithClose
			onClose={onClose}
			title="Select a color"
			content={(
				<Box direction={size === "small" ? "column" : "row"} gap="medium">
					<ColorPicker
						color={color}
						onChange={setValue}
					/>
					<TextFieldElement
						fullWidth
						name={"color"}
						label={"HEX Color"}
						helperText={"Enter a HEX color code if you have a specific color in mind (ex. #FFFFFF)"}
						InputProps={{
							value: color,
							onChange: (e) => setValue(e.target.value),
							startAdornment: (
								<InputAdornment position="start">
									<Circle
										sx={{ color: color }}
									/>
								</InputAdornment>
							)
						}}
					/>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button
						color="error"
						variant="outlined"
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={onClose}
					>
						Save
					</Button>
				</Box>
			)}
		/>
	);
};

export const HexColorPickerElement: React.FC<{
	label: string;
	helperText: string;
	name: keyof OnboardingFormContext & string;
	formContext: UseFormReturn<OnboardingFormContext>;
}> = ({ name, label, helperText, formContext }) => {
	const [ isUpdatingColor, setIsUpdatingColor ] = useState(false);
	const value = formContext.watch(name) as string;

	return (
		<Fragment>
			{isUpdatingColor && (
				<ColorPickerDialog
					color={value}
					onClose={() => setIsUpdatingColor(false)}
					onChange={(color) => formContext.setValue(name, color)}
				/>
			)}
			<TextFieldElement
				fullWidth
				name={name}
				label={label}
				helperText={helperText}
				InputProps={{
					onClick: () => setIsUpdatingColor(true),
					startAdornment: (
						<InputAdornment position="start">
							<Circle
								sx={{ color: value }}
							/>
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							<Autorenew
								onClick={() => setIsUpdatingColor(true)}
							/>
						</InputAdornment>
					)
				}}
			/>
		</Fragment>
	);
};