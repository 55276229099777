import { DirectionsRenderer, DirectionsService, GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Box } from "grommet";
import React, { useCallback, useMemo, useState } from "react";
import { config } from "../config";
import "./map.css";

interface MapProps {
	markers?: { lat: number, lng: number; }[];
	directionsResult?: google.maps.DirectionsResult | null;
	directionsRequest?: google.maps.DirectionsRequest | null;
	directionsRequestCallback?: (
		result: google.maps.DirectionsResult | null,
		status: google.maps.DirectionsStatus
	) => void;
}

export const Map: React.FC<MapProps> = (props) => {
	const mapComponentId = "google-map-container";
	const { isLoaded } = useJsApiLoader({
		id: mapComponentId,
		googleMapsApiKey: config.googleApiKey
	});

	const [ map, setMap ] = React.useState<google.maps.Map | null>(null);

	const onLoad = useCallback((map: google.maps.Map) => {
		if(!map) {
			console.debug("Map not loaded yet!");
			return;
		}

		// This is just an example of getting and using the map instance!!! dont just blindly copy!
		const bounds = new window.google.maps.LatLngBounds(center);

		/**
		 * https://stackoverflow.com/a/5345708
		 */
		// Don't zoom in too far on only one marker
		if(bounds.getNorthEast().equals(bounds.getSouthWest())) {
			const extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
			const extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
			bounds.extend(extendPoint1);
			bounds.extend(extendPoint2);
		}

		map.fitBounds(bounds);

		setMap(map);
	}, []);

	const onUnmount = React.useCallback((map: google.maps.Map) => {
		setMap(null);
	}, []);


	const center = {
		lat: 39.952584,
		lng: -75.165221
	};

	const containerStyle = useMemo(() => {
		return {
			width: "100%",
			height: "100%"
		};
	}, []);

	const memoizedDirectionService = useMemo(() => {
		if(!props.directionsRequest || !props.directionsRequestCallback) {
			return null;
		}

		return (
			<DirectionsService
				options={props.directionsRequest}
				callback={props.directionsRequestCallback}
			/>
		);

	}, [ props.directionsRequest ]);

	return isLoaded ? (
		<Box flex style={{ position: "relative", width: "100%" }}>
			<GoogleMap
				mapContainerClassName="google-map-container"
				mapContainerStyle={containerStyle}
				center={center}
				zoom={10}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{
					fullscreenControl: false,
					streetViewControl: false
				}}
			>
				{ /* Child components, such as markers, info windows, etc. */}
				{(props.markers || []).map((marker) => {
					return (
						<Marker
							key={`${marker.lat}-${marker.lng}`}
							position={{
								lat: marker.lat,
								lng: marker.lng
							}}
						/>
					);
				})}
				{memoizedDirectionService && (
					<>
						{memoizedDirectionService}
					</>
				)}
				{props.directionsResult && (
					<DirectionsRenderer
						options={{
							directions: props.directionsResult
						}}
					/>
				)}
			</GoogleMap>
		</Box>
	) : <></>;
};

interface DirectionsMapProps {
	stops: {
		name?: string;
		index: number;
		addressLineOne: string;
		addressLineTwo: string;
		city: string;
		state: string;
		zipCode: string;
	}[];
}

export const DirectionsMap: React.FC<DirectionsMapProps> = (props) => {
	const [ response, setResponse ] = useState<google.maps.DirectionsResult | null>(
		null
	);

	const directionsResult = useMemo(() => {
		return {
			directions: response,
		};
	}, [ response ]);

	const directionsCallback = useCallback(
		(
			result: google.maps.DirectionsResult | null,
			status: google.maps.DirectionsStatus
		) => {
			if(result !== null) {
				if(status === "OK") {
					setResponse(result);
				}
				else {
					console.error("response: ", result);
				}
			}
		},
		[]
	);

	function toGoogleAddress(address: string, city: string, state: string, zipCode: string) {
		return `${address}, ${city}, ${state} ${zipCode}`;
	}

	const directionsRequest = useMemo(() => {
		if(!props.stops || props.stops.length <= 1) {
			return null;
		}

		const stops = props.stops.sort((a, b) => a.index - b.index);

		const origin = stops[ 0 ];
		const destination = stops[ stops.length - 1 ];

		return {
			travelMode: "DRIVING" as google.maps.TravelMode,
			origin: toGoogleAddress(origin.addressLineOne, origin.city, origin.state, origin.zipCode),
			destination: toGoogleAddress(destination.addressLineOne, destination.city, destination.state, destination.zipCode),
			waypoints: stops.slice(1, stops.length - 1).map(stop => ({
				location: toGoogleAddress(stop.addressLineOne, stop.city, stop.state, stop.zipCode)
			}))
		};
	}, [ props.stops ]);

	return (
		<Map
			directionsRequest={directionsRequest || undefined}
			directionsResult={directionsResult.directions || undefined}
			directionsRequestCallback={directionsRequest ? directionsCallback : undefined}
		/>
	);
};