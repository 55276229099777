import { Box, Spinner } from "grommet";
import { useMemo } from "react";
import { IconButton, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import moment from "moment-timezone";
import { Cancel, CheckCircle } from "@mui/icons-material";
import React from "react";
import { formatCurrency } from "../../../../helpers";
import { useWindowDimensions } from "../../../../hooks";
import { PickupWindow } from "../../../../graphql/__generated__/graphql";

interface HaulerPickupRequestListItemProps {
	onAccept?: () => void;
	isAccepting?: boolean;
	onReject?: () => void;
	isRejecting?: boolean;
	onSelect: () => void;
	pickupId: string;
	scheduledDate: string;
	scheduledWindow?: PickupWindow;
	stopCount: number;
	productCount: number;
	marketCode: string;
	amountOffered: number;
}

export const HaulerPickupRequestListItem: React.FC<HaulerPickupRequestListItemProps> = (props) => {
	const { size } = useWindowDimensions();
	const formattedDate = useMemo(() => {
		const format = (size === "small") ? "ddd, MMM Do" : "dddd, MMMM Do";
		const datePart = moment.tz(props.scheduledDate, "YYYY-MM-DD", "America/New_York").format(format);

		if(props.scheduledWindow && size !== "small") {
			return `${datePart} (${props.scheduledWindow.label})`;
		}

		return datePart;
	}, [ props.scheduledDate, size ]);
	return (
		<ListItemButton divider onClick={props.stopCount ? props.onSelect : undefined}>
			<ListItemText>
				<Box gap="xsmall">
					<Typography fontSize="18px" fontWeight="bold">{formattedDate}</Typography>
					<Box direction="row" gap="small">
						<Typography variant="caption">{props.stopCount} stop(s)</Typography>
						<Typography variant="caption">|</Typography>
						<Typography variant="caption">{props.productCount} item(s)</Typography>
					</Box>
				</Box>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small">
					<Box align="center" justify="center">
						{!!props.amountOffered && (
							<Typography fontWeight="bold">
								{formatCurrency(props.amountOffered)}
							</Typography>
						)}
					</Box>
					{/* <Box align="center" justify="center">
						<Chip
							color="secondary"
							label={<Typography fontWeight="bold" variant="caption">{props.marketCode}</Typography>} />
					</Box> */}
					<Box direction="row">
						<Box align="center" justify="center">
							{props.onAccept && (
								<IconButton
									color="success"
									onClick={(event) => {
										event.preventDefault();
										props.onAccept && props.onAccept();
									}}
									disabled={props.isAccepting || props.isRejecting}
								>
									{(props.isAccepting)
										? (
											<Spinner />
										)
										: (
											<CheckCircle />
										)}
								</IconButton>
							)}
						</Box>
						<Box align="center" justify="center">
							{props.onReject && (
								<IconButton
									color="error"
									onClick={(event) => {
										event.preventDefault();
										props.onReject && props.onReject();
									}}
									disabled={props.isAccepting || props.isRejecting}
								>
									{(props.isRejecting)
										? (
											<Spinner />
										)
										: (
											<Cancel />
										)}
								</IconButton>
							)}
						</Box>
					</Box>

				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};
