import { gql } from "@apollo/client";


export const Stop_CombinedFragment = gql`
	fragment Stop_CombinedFragment on Stop {
		...Stop_HeaderFragment
		...Stop_TrackingFragment
		...Stop_OriginDestinationFragment
		...Stop_ProductsFragment
	}
`;
