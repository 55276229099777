import React from "react";
import { Box, Heading } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { FetchUserHauler, UpdateHauler } from "../../../../graphql";
import { useHauler } from "../../hooks";
import { AutocompleteElement, FormContainer, useForm } from "react-hook-form-mui";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { DayOfWeek, PickupWindow } from "../../../../graphql/__generated__/graphql";
import { toProperCase } from "../../../../helpers";
import { Save } from "@mui/icons-material";
import { useWindowDimensions } from "../../../../hooks";
import { CreatePickupWindowDialog } from "../../../../components";

export const HaulerPickupConfig: React.FC = () => {
	const snack = useSnackbar();
	const { hauler, loading } = useHauler();

	const [ isCreatingWindow, setIsCreatingWindow ] = useState<boolean | PickupWindow>(false);

	const formContext = useForm({
		defaultValues: {
			permittedDays: (hauler?.permittedDays || []).map(day => ({
				id: day,
				label: toProperCase(day)
			})),
			permittedWindows: hauler?.permittedWindows || []
		}
	});

	const formValues = formContext.watch();

	const [
		updateHauler, { loading: updateLoading, error: updateError }
	] = useMutation(UpdateHauler, { refetchQueries: [ FetchUserHauler ] });

	function handleSubmit() {
		if(!hauler) return;

		updateHauler({
			variables: {
				haulerId: hauler.id,
				permittedDays: formValues.permittedDays.map(day => day.id),
				permittedWindows: formValues.permittedWindows
			}
		});
	}

	useEffect(() => {
		if(hauler) {
			formContext.reset({
				permittedDays: (hauler?.permittedDays || []).map(day => ({
					id: day,
					label: toProperCase(day)
				})),
				permittedWindows: (hauler?.permittedWindows || []).map(window => ({
					from: window.from,
					to: window.to,
					label: window.label
				}))
			});
		}
	}, [ hauler ]);

	useEffect(() => {
		if(updateError) {
			snack.enqueueSnackbar(
				"We ran into an issue updating your information.",
				{ variant: "error" }
			);
		}
	}, [ updateError ]);

	const dayOfWeekOptions = useMemo(() => {
		const orderedDays = [
			DayOfWeek.Sunday,
			DayOfWeek.Monday,
			DayOfWeek.Tuesday,
			DayOfWeek.Wednesday,
			DayOfWeek.Thursday,
			DayOfWeek.Friday,
			DayOfWeek.Saturday
		];

		return (Object.values(DayOfWeek) as DayOfWeek[]).map(day => ({
			id: day,
			label: toProperCase(day)
		})).sort((a, b) => {
			return orderedDays.indexOf(a.id) - orderedDays.indexOf(b.id);
		});
	}, []);

	const { size } = useWindowDimensions();

	return (
		<FormContainer formContext={formContext} onSuccess={handleSubmit}>
			{isCreatingWindow && (
				<CreatePickupWindowDialog
					onClose={() => setIsCreatingWindow(false)}
					onSubmit={(from, to, label) => {
						formContext.setValue("permittedWindows", [
							...formContext.getValues().permittedWindows,
							{
								from,
								to,
								label
							}
						]);
						setIsCreatingWindow(false);
					}} />
			)}
			<Box gap="medium" flex>
				<Heading level="3" margin="none">
					Pickup Configuration
				</Heading>
				<AutocompleteElement
					multiple
					options={dayOfWeekOptions}
					name="permittedDays"
					label="Accepted Days"
					autocompleteProps={{
						getOptionLabel(option) {
							return toProperCase(option.label);
						},
						limitTags: size === "small" ? 2 : undefined
					}}
					textFieldProps={{
						helperText: "These are the days customers are permitted to schedule on"
					}} />
				<Box gap="small">
					<AutocompleteElement
						multiple
						options={[]}
						name="permittedWindows"
						label="Accepted Windows"
						textFieldProps={{
							helperText: "These are the pickup windows customers are permitted to schedule on"
						}}
						autocompleteProps={{
							limitTags: size === "small" ? 1 : undefined
							// onSelect: (event) => {
							// 	event.preventDefault();
							// 	setIsCreatingWindow(true);
							// }
						}} />
				</Box>
				<Box flex direction="row" justify="between">
					<Button variant="contained" color="primary" onClick={() => setIsCreatingWindow(true)}>
						Add Window
					</Button>
					<LoadingButton
						startIcon={<Save />}
						variant="contained" loading={updateLoading || loading} type="submit" loadingPosition="start">
						Save
					</LoadingButton>
				</Box>
			</Box>
		</FormContainer>
	);
};
