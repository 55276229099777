import React from "react";
import { Visibility } from "@mui/icons-material";
import { BasicListItem } from "../../../../../components";
import { useAdminScopedOrder } from "../../../../../graphql";
import { UserDetailsDialog } from "../../../common";
import { IconButton } from "@mui/material";
import { useMemo } from "react";

export const OrderSubmittedByListItem: React.FC<{
	orderId: string;
	enabledAdminActions?: boolean;
}> = ({ orderId, enabledAdminActions }) => {
	const { order, loading } = useAdminScopedOrder(orderId);

	const name = useMemo(() => order?.user?.fullName, [ order ]);
	const userId = useMemo(() => order?.user?.id, [ order ]);

	return (
		<BasicListItem
			label="Submitted By"
			loading={loading}
			value={name || ""}
			action={(
				<UserDetailsDialog
					userId={userId}
					activatorButton={(
						<IconButton color="primary">
							<Visibility />
						</IconButton>
					)}
					permitViewMore={enabledAdminActions}
				/>
			)}
		/>
	);
};