import { gql } from "../../../__generated__";

export const AssignWasteAudit = gql(/* GraphQL */ `
	mutation AssignWasteAudit(
		$auditId: String!
		$assignmentId: String!
	) {
		AssignWasteAudit(
			auditId: $auditId
			assignmentId: $assignmentId
		) {
			id
		}
	}
`);

export const UnassignWasteAudit = gql(/* GraphQL */ `
	mutation UnassignWasteAudit(
		$auditId: String!
		$assignmentId: String!
	) {
		UnassignWasteAudit(
			auditId: $auditId
			assignmentId: $assignmentId
		) {
			id
		}
	}
`);