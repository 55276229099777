import { useEffect } from "react";
import { selectWasLoginAttempted, useAppDispatch, useAppSelector } from "../../store";
import { push } from "redux-first-history";
import { useSearchParams } from "react-router-dom";
import { useUser } from "./useUser";
import { enumerateQuery } from "../../helpers";


export function useLoginGuard(override?: boolean) {
	const { user } = useUser();
	const dispatch = useAppDispatch();
	const [ params ] = useSearchParams();
	const wasLoginAttempted = useAppSelector(selectWasLoginAttempted);

	useEffect(() => {
		if(!wasLoginAttempted) {
			console.debug("In login guard ... before login attempt");
			return;
		}

		if(!user && window.location.pathname !== "/login") {
			if(override) {
				console.debug("user is not logged in ... but override is enabled");
				return;
			}

			console.debug("user is not logged in ... redirecting to login page");
			const path = window.location.pathname;

			console.debug("updating redirect path to: ", path);
			params.set("redirect", window.location.pathname);

			console.debug(`redirecting to login page with redirect path: ${path}`, enumerateQuery(params));
			dispatch(push(`/login?${params.toString()}`));
		}
	}, [ user, params, wasLoginAttempted, dispatch ]);

	return;
}
