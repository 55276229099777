import { gql } from "../../../__generated__";

export const FindInstances = gql(/* GraphQL */ `
	query FindInstances(
		$name: iStringFilters
		$domain: iStringFilters
	) {
		FindInstances(
			name: $name
			domain: $domain
		) {
			...Instance_HeaderFragment
		}
	}
`);