import { useMemo } from "react";
import { useLoginGuard } from "../../auth";
import { SplashScreen, Step, StepController } from "../../components";
import { useAppSelector } from "../../store";
import { selectCheckoutSession } from "../../store/schedule";
import { useSearchParams } from "react-router-dom";
import { ReviewView } from "./views";
import AddressView from "./views/Address";
import ContactView from "./views/Contact";
import ItemsView from "./views/Items";
import ScheduleUpfrontPickupView, { ScheduleLaterDateView } from "./views/Schedule";
import { useInstance } from "../../hooks";
import { InstanceFeature, InstanceType } from "../../graphql/__generated__/graphql";
import { useFeature } from "../instance/hooks/useFeature";

export function ScheduleController() {
	useLoginGuard();
	const { instance } = useInstance();

	const [ params ] = useSearchParams();
	const session = useAppSelector(selectCheckoutSession);

	const sessionId = useMemo(() => {
		if(session) return session.id;
		if(params.get("sid")) return params.get("sid") as string;
		return "";
	}, [ params, session ]);

	if(!instance) return (
		<SplashScreen visible>
			<>
			</>
		</SplashScreen>
	);

	switch(instance.type) {
		case InstanceType.Residence: {
			return (
				<ResidenceStepController />
			);
		}
		default: {
			return (
				<StepController name="schedule">
					<Step stepName="Address">
						<AddressView sessionId={sessionId} />
					</Step>
					<Step stepName="Items">
						<ItemsView sessionId={sessionId} />
					</Step>
					<Step stepName="Schedule">
						<ScheduleViewController sessionId={sessionId} />
					</Step>
					<Step stepName="Contact">
						<ContactView sessionId={sessionId} />
					</Step>
					<Step stepName="Review">
						<ReviewView sessionId={sessionId} />
					</Step>
				</StepController>
			);
		}
	}
}

export const ResidenceStepController: React.FC = () => {
	const [ params ] = useSearchParams();
	const session = useAppSelector(selectCheckoutSession);

	const sessionId = useMemo(() => {
		if(session) return session.id;
		if(params.get("sid")) return params.get("sid") as string;
		return "";
	}, [ params, session ]);

	return (
		<StepController name="schedule">
			<Step stepName="Items">
				<ItemsView sessionId={sessionId} />
			</Step>
			<Step stepName="Schedule">
				<ScheduleUpfrontPickupView sessionId={sessionId} />
			</Step>
			<Step stepName="Contact">
				<ContactView sessionId={sessionId} />
			</Step>
			<Step stepName="Review">
				<ReviewView sessionId={sessionId} />
			</Step>
		</StepController>
	);
};

export const ScheduleViewController: React.FC<{
	sessionId: string;
}> = ({ sessionId }) => {
	const isUpfrontSchedulingEnabled = useFeature(InstanceFeature.OrderUpfrontScheduleEnabled);

	return (isUpfrontSchedulingEnabled)
		? <ScheduleUpfrontPickupView sessionId={sessionId} />
		: <ScheduleLaterDateView sessionId={sessionId} />;
};
