import { useQuery } from "@apollo/client";
import { useInstance } from "../../../hooks";
import { AuthorizePartner } from "../../../graphql";

export function usePartnerRoles(
	partnerId: string
) {
	const { instance } = useInstance();

	const { data, loading } = useQuery(AuthorizePartner, {
		skip: !partnerId || !instance?.id,
		variables: { partnerId, instanceId: instance?.id || "" },
	});

	return {
		loading,
		roles: data?.AuthorizePartner || {
			isGlobalAdmin: false,
			isInstanceAdmin: false,
			isInstanceManager: false,
			isInstanceMember: false,
			isPartnerAdmin: false,
			isPartnerMember: false,
			isPartnerReportingMember: false,
		}
	};
}