import { Anchor, Box } from "grommet";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../store";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { push } from "redux-first-history";
import { LoadingButton } from "@mui/lab";
import { BaseDialog } from "../../../components";
import { useEffect } from "react";
import { useRedirect } from "../../../hooks";
import { ConfirmationCodeElement, FirstNameElement, LastNameElement, RegistrationForm, RegistrationFormValues, UsernameElement } from "../components";
import { useOnboarding } from "../hooks";

export const DefaultOnboarding: React.FC<{
	hideLogo?: boolean;
}> = ({ hideLogo }) => {
	const dispatch = useAppDispatch();
	const { redirect } = useRedirect();
	const instance = useAppSelector(selectInstancePublic);
	const formContext = useForm<RegistrationFormValues>({
		defaultValues: {
			username: "",
			code: "",
			firstName: "",
			lastName: ""
		}
	});

	const {
		wasCodeSent,
		canResendCode,
		resendCode,
		isSendingCode,
		beginOnboarding,
		confirmOnboarding,
		wasConfirmSuccessful,
		accessToken,
		refreshToken
	} = useOnboarding();

	function handleResendCode() {
		const username = formContext.watch("username");

		if(username) {
			resendCode({
				instanceId: instance?.id || "",
				username
			});
		}
	}

	function handleSubmit() {
		if(!instance) return;

		const { username, firstName, lastName, code } = formContext.getValues();

		if(wasCodeSent) {
			confirmOnboarding({
				instanceId: instance.id || "",
				username,
				firstName,
				lastName,
				code
			});

			return;
		}

		beginOnboarding({
			instanceId: instance?.id || "",
			username
		});
	}

	useEffect(() => {
		if(wasConfirmSuccessful && accessToken && refreshToken) {
			dispatch(push("/dashboard"));
			redirect("/dashboard", {
				schedule: "true"
			});
		}
	}, [ wasConfirmSuccessful, accessToken, refreshToken ]);

	return (
		<Box>
			<BaseDialog
				title="Registration"
				content={(
					<RegistrationForm
						hideLogo={hideLogo}
						formContext={formContext}
						elements={[
							<Typography>
								Enter your email address or phone number to get started.
							</Typography>,
							<UsernameElement
								disabled={wasCodeSent}
								username={formContext.watch("username")}
							/>,
							!wasCodeSent && <FirstNameElement />,
							!wasCodeSent && <LastNameElement />,
							<ConfirmationCodeElement
								wasCodeSent={wasCodeSent}
								canResendCode={canResendCode}
								onResendCode={handleResendCode}
								username={formContext.watch("username")}
							/>
						].filter(Boolean)}
					/>
				)}
				actions={(
					<Box direction="row" justify="between">
						<Box align="start" justify="center">
							<Typography>
								Already have an account? <Anchor onClick={() => dispatch(push("/login"))}>Login</Anchor>
							</Typography>
						</Box>
						<Box align="end" justify="center">
							<LoadingButton
								color="primary"
								variant="contained"
								loading={isSendingCode}
								onClick={formContext.handleSubmit(handleSubmit)}
							>
								Submit
							</LoadingButton>
						</Box>
					</Box>
				)}
			/>
		</Box>
	);
};

// export const DefaultRegistrationForm: React.FC<{

// }> = ({ }) => {

// }