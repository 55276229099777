import React from "react";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { FetchHaulerActionItems, FetchStop, FetchStopDetailed, ReportOriginStopConfirmed } from "../../../../graphql";
import { useForm } from "react-hook-form";
import { fileToBase64, isNotNull } from "../../../../helpers";
import { ProductMediaContext } from "../../../../graphql/__generated__/graphql";
import { Box } from "grommet";
import { Button } from "@mui/material";
import { StopConfirmationMediaDialog } from "./StopConfirmationMediaDialog";

export const ConfirmOriginStopButton: React.FC<{
	stopId: string;
	productId: string;
}> = ({ stopId, productId }) => {
	const snack = useSnackbar();

	const [
		confirm, { loading }
	] = useMutation(ReportOriginStopConfirmed, {
		refetchQueries: [ FetchStop, FetchStopDetailed, FetchHaulerActionItems ]
	});

	const formContext = useForm<{
		media: { name: string; contentUrl: string; file?: File; }[];
	}>({
		defaultValues: {
			media: []
		}
	});

	function handleSubmit(): void {
		const media = formContext.getValues("media");
		Promise.all(media.filter(m => m.file).map(m => m.file).filter(isNotNull).map(file => {
			return fileToBase64(file).then(content => {
				return {
					content,
					isPrimary: false,
					name: file.name,
					contentType: file.type,
					description: "",
					context: ProductMediaContext.PickupConfirmation
				};
			});
		})).then(media => {
			confirm({
				variables: {
					stopId,
					productId,
					media
				}
			}).catch(err => {
				console.error("Failed to confirm origin stop", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", {
					variant: "error"
				});
			});
		});
	}

	return (
		<StopConfirmationMediaDialog
			loading={loading}
			media={formContext.watch("media")}
			onFileUploaded={(file) => {
				formContext.setValue("media", [
					...formContext.watch("media"),
					{
						name: file.name,
						contentUrl: URL.createObjectURL(file),
						file
					}
				]);
			}}
			activatorButton={(
				<LoadingButton
					color="primary"
					loading={loading}
					variant="contained"
					onClick={handleSubmit}
				>
					Confirm
				</LoadingButton>
			)}
			action={(
				<Box direction="row" justify="end">
					<Button
						color="primary"
						variant="contained"
						onClick={handleSubmit}
						disabled={formContext.watch("media").length === 0}
					>
						Submit
					</Button>
				</Box>
			)}
		/>
	);
};