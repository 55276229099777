import { useMemo } from "react";
import { useUTM } from "../../../hooks";
import { Tracking } from "../../../store/onboarding";
import { useSearchParams } from "react-router-dom";
import { InstanceOnboardingIntent } from "../../../graphql/__generated__/graphql";

export function useTracking(): Tracking {
	const utm = useUTM();
	const [ params ] = useSearchParams();

	const intent = useMemo(() => {
		const value = params.get("intent");
		switch(value) {
			case InstanceOnboardingIntent.Audit: {
				return InstanceOnboardingIntent.Audit;
			}
			case InstanceOnboardingIntent.Trial: {
				return InstanceOnboardingIntent.Trial;
			}
			case InstanceOnboardingIntent.Reporting: {
				return InstanceOnboardingIntent.Reporting;
			}
			default: {
				return InstanceOnboardingIntent.Unknown;
			}
		}
	}, [ params ]);

	return {
		utm,
		intent
	};
}