import { gql } from "../../../__generated__";

export const FetchScheduledPickups = gql(/* GraphQL */`
	query FetchScheduledPickups(
		$haulerId: String!
		$pastCutoffDate: DateTime!
		$futureCutoffDate: DateTime!
	) {
		FindPickups(
			scheduledDate: { 
				isBefore: $futureCutoffDate
				isAfter: $pastCutoffDate
			}
			cancelled: { equals: false }
			completed: { equals: false }
			assignedTo: { id: { equals: $haulerId } }
		) {
			...Pickup_HeaderFragment
			...Pickup_CountFragment
			...Pickup_TrackingFragment
			...Pickup_InvoiceFragment
			...Pickup_StopsFragment
			...Pickup_ActiveStopFragment
		}
	}
`);