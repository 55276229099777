import React from "react";
import { CreatePricingPolicyButton, Pagination, PricingPolicyListItem, ViewContainer } from "../../../../components";
import { LinearProgress } from "@mui/material";
import { Box, Heading } from "grommet";
import { useQuery } from "@apollo/client";
import { FindPricingPolicies } from "../../../../graphql";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";

export const PricingPoliciesView: React.FC = () => {
	const dispatch = useAppDispatch();
	const { data, loading } = useQuery(FindPricingPolicies);

	return (
		<ViewContainer>
			<Box gap="medium">
				<Box gap="xsmall">
					<Box direction="row" justify="between">
						<Heading margin="none" level="2">
							Pricing Policies
						</Heading>
						<Box justify="center">
							<CreatePricingPolicyButton />
						</Box>
					</Box>
					{loading && (
						<LinearProgress />
					)}
				</Box>
				<Box>
					<Pagination pageSize={8}>
						{data?.FindPricingPolicies.map(p => (
							<PricingPolicyListItem
								key={p.id}
								name={p.name}
								type={p.type}
								margin={p.margin}
								onClick={() => {
									dispatch(push(`/admin/pricing-policies/${p.id}`));
								}}
							/>
						)) || []}
					</Pagination>
				</Box>
			</Box>
		</ViewContainer>
	);
};