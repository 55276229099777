import { Box, Carousel, Grid, Heading, Image, Stack } from "grommet";
import { createRef, Fragment, useCallback, useEffect, useMemo } from "react";
import { Button, Dialog, LinearProgress } from "@mui/material";
import { useFullScreen, useRequiredPathParam } from "../../../../hooks";
import { useSnackbar } from "notistack";
import { AdminProductDetailsContainer, ViewActionBar } from "../../components";
import { ViewContainer } from "../../../../components";
import React from "react";
import { ProductRelationshipButton } from "../../components/common/product/ProductRelationshipButton";
import { useProduct } from "../../hooks";
import { Detection, useDetection, useProductAI } from "../../../../app/tensorflow/useCoco";

export const ProductDetails: React.FC<{
	permitAI?: boolean;
	permitEdit: boolean;
}> = ({ permitAI, permitEdit }) => {
	const snack = useSnackbar();

	const productId = useRequiredPathParam(
		"productId",
		"/admin"
	);

	const { product, loading, error } = useProduct(productId || "");

	const { element, bestDetection } = useProductAI(
		permitAI ? productId : ""
	);

	useEffect(() => {
		if(error) {
			snack.enqueueSnackbar("Error loading product", { variant: "error" });
		}
	}, [ snack, error ]);

	const fullScreen = useFullScreen();

	return (
		<ViewContainer>
			{permitAI && element}
			<Box gap="medium" flex>
				<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="medium">
					<Box gap="small">
						<Heading level={3} margin="none">
							Item Details
						</Heading>
						{(!product || loading) && (
							<LinearProgress />
						)}
						{product && (
							<AdminProductDetailsContainer
								permitEdit={permitEdit}
								productId={product?.id ?? ""}
								bestDetection={bestDetection || undefined}
							/>
						)}
					</Box>
					<Box gap="small">
						<Heading level={3} margin="none">
							Item Images
						</Heading>
						<Box height="medium" align="center" justify="center" pad={{ vertical: "small" }}>
							<Carousel fill>
								{(product?.media ?? []).map(media => (
									<Fragment key={media.id}>
										{permitAI
											? (
												<AIDetectionImage
													id={media.id}
													name={media.name}
													key={media.id}
													contentUrl={media.contentUrl}
												/>
											)
											: (
												<Image
													fit="contain"
													key={media.id}
													src={media.contentUrl}
												/>
											)}
									</Fragment>
								))}
							</Carousel>
						</Box>
					</Box>
				</Grid>
				{permitEdit && (
					<Grid columns={{ count: fullScreen ? 1 : 2, size: "auto" }} gap="medium">
						<ViewActionBar
							title="Product Actions"
						>
							<Button
								size="small"
								color="error"
								variant="contained"
							>
								Remove
							</Button>
						</ViewActionBar>
						<ViewActionBar
							title="Product Links"
						>
							{product && (
								<ProductRelationshipButton
									productId={product.id}
									label="Order"
									resolvePath={(relationships) => {
										if(relationships.orderId) {
											return `/admin/orders/${relationships.orderId}`;
										}
										return null;
									}}
								/>
							)}
							{product && (
								<ProductRelationshipButton
									productId={product.id}
									label="Customer"
									resolvePath={(relationships) => {
										if(relationships.userId) {
											return `/admin/customers/${relationships.userId}`;
										}
										return null;
									}}
								/>
							)}
						</ViewActionBar>
					</Grid>
				)}
			</Box>
		</ViewContainer>
	);
};

export const AIDetectionImage: React.FC<{
	id: string;
	name: string;
	contentUrl: string;
}> = ({ id, name, contentUrl }) => {
	const ref = createRef<HTMLImageElement>();
	const canvasRef = createRef<HTMLCanvasElement>();
	const [ detections, setDetection ] = React.useState<Detection[]>([]);

	const { detect, isReady } = useDetection();

	useEffect(() => {
		detections.forEach(detection => {
			if(detection.score > 0.5) {
				draw(detection);
			}
		});
	}, [ detections ]);

	const draw = useCallback((detection: Detection) => {
		console.log("DRAWING DETECTION", detection);
		const ctx = canvasRef.current?.getContext("2d") ?? null;
		console.log("CTX", ctx);
		if(!ctx) {
			return;
		}

		ctx.strokeStyle = "blue";
		ctx.font = "18px Arial";

		// Draw rectangles and text
		ctx.beginPath();
		ctx.fillStyle = "blue";

		const { x, y, width, height } = detection;
		ctx.rect(x, y, width, height);
		ctx.stroke();
	}, [ canvasRef ]);

	const [ { height, width }, setDimensions ] = React.useState({ height: 0, width: 0 });

	useEffect(() => {
		if(ref.current && isReady) {
			setDimensions({ height: ref.current.clientHeight, width: ref.current.clientWidth });
			console.log("SETTING DIMENSIONS", { height: ref.current.clientHeight, width: ref.current.clientWidth });
			detect(ref.current, { id, name, contentUrl }).then(detections => {
				console.log("DETECTIONS FROM IMAGE COMPONENT", detections);
				setDetection(detections);
			}).catch(err => {
				console.error("Failed to detect", err);
			});
		}
	}, [ isReady ]);

	const src = useMemo(() => {
		const [ path, query ] = contentUrl.split("?");
		return path + "?" + query + "&t=" + Date.now() + "&r=" + Math.random();
	}, [ contentUrl ]);

	const [ clicked, setClicked ] = React.useState(false);

	return (
		<Fragment>
			{clicked && (
				<TestDialog
					id={id}
					name={name}
					contentUrl={contentUrl}
				/>
			)}
			<Stack fill id="stack-container" onClick={() => setClicked(true)}>
				<Box align="center" height="100%" width="100%">
					<Image
						ref={ref}
						src={src}
						fit="contain"
						crossOrigin="anonymous"
					/>
				</Box>
				<Box height="100%" width="100%" align="center">
					<canvas
						ref={canvasRef}
						style={{
							textAlign: "center",
							zIndex: 10,
							width,
							height
						}}
					/>
				</Box>
			</Stack>
		</Fragment>
	);
};

export const TestDialog: React.FC<{
	id: string;
	name: string;
	contentUrl: string;
}> = ({ id, name, contentUrl }) => {
	return (
		<Dialog fullScreen fullWidth open={true}>
			<AIDetectionImage
				id={id}
				name={name}
				contentUrl={contentUrl}
			/>
		</Dialog>
	);
};