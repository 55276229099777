import { gql } from "@apollo/client";

export const OrderRequest_MediaFragment = gql`
	fragment OrderRequest_MediaFragment on OrderRequest {
		media {
			id
			name
			contentUrl
		}
	}
`;