import { ListItemButton, ListItemText, Typography, ListItemSecondaryAction, IconButton, CircularProgress, Chip, ListItemIcon } from "@mui/material";
import { Box, Spinner } from "grommet";
import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import { CalendarMonth, Event, ScheduleSend } from "@mui/icons-material";
import { useTimezone, useWindowDimensions } from "../../hooks";

interface PickupListItemProps {
	selected: boolean;
	onSelect(): void;
	isLoading: boolean;
	id: string;
	date: string;
	remainingSlots?: number;
}

export const PickupListItem: React.FC<PickupListItemProps> = (props) => {
	const { size } = useWindowDimensions();

	const formatted = useMemo(() => {
		const format = size === "small"
			? "MMM Do (ddd)"
			: "MMMM Do (dddd)";
		return moment.tz(props.date, "YYYY-MM-DD", "UTC").hours(12).tz("America/New_York").format(format);
	}, [ props.date, size ]);

	return (
		<ListItemButton key={props.id} divider selected={props.selected} onClick={props.onSelect}>
			<ListItemIcon>
				<CalendarMonth />
			</ListItemIcon>
			<ListItemText
				primary={(
					<Typography fontSize="large" fontWeight="bold">{formatted}</Typography>
				)}
				secondary={!!props.remainingSlots && (
					<Typography variant="caption">
						{props.remainingSlots} slot(s) remaining
					</Typography>
				)}
			/>
			<ListItemSecondaryAction>
				{props.isLoading && (
					<IconButton onClick={(event) => {
						event.stopPropagation();
					}}>
						<CircularProgress />
					</IconButton>
				)}

			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

interface ScheduledPickupListItemProps {
	onSelect: () => void;
	pickupId: string;
	scheduledDate?: string;
	stopCount: number;
	productCount: number;
	marketCode: string;
}

export const ScheduledPickupListItem: React.FC<ScheduledPickupListItemProps> = (props) => {
	const formattedDate = useMemo(() => {
		if(!props.scheduledDate) return "";
		return moment.tz(props.scheduledDate, "YYYY-MM-DD", "America/New_York").format("dddd, MMMM Do YYYY");
	}, [ props.scheduledDate ]);

	return (
		<ListItemButton divider onClick={props.onSelect}>
			<ListItemText>
				<Box gap="xsmall">
					<Typography fontWeight="bold" fontSize="large">{formattedDate}</Typography>
					<Box direction="row" gap="small">
						<Typography variant="caption">{props.stopCount} stop(s)</Typography>
						<Typography variant="caption">|</Typography>
						<Typography variant="caption">{props.productCount} item(s)</Typography>
					</Box>
				</Box>
			</ListItemText>
			<ListItemSecondaryAction>
				<Box direction="row" gap="small">
					<Box align="center" justify="center">
						<Chip
							color="secondary"
							label={<Typography fontWeight="bold" variant="caption">{props.marketCode}</Typography>}
						/>
					</Box>
				</Box>
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};

export const AvailablePickupListItem: React.FC<{
	pickupId: string;
	pickupDate: string;
	loading?: boolean;
	selected?: boolean;
	hideIcon?: boolean;
	onSelect?: () => void;
	minHeight?: string;
}> = (props) => {
	const timezone = useTimezone();
	const { size } = useWindowDimensions();

	function getDaysUntilPickupMessage(date: string): string {
		const diff = moment.tz(date, "YYYY-MM-DD", timezone).diff(moment().startOf("day"), "days");
		if(diff === 0) return "Today";
		if(diff === 1) return "Tomorrow";
		return `In ${diff} days`;
	}

	const formatPickupDate = useCallback((date: string) => {
		switch(size) {
			case "small": {
				return moment.tz(date, "YYYY-MM-DD", timezone).format("ddd, MMM Do");
			}
			default: {
				return moment.tz(date, "YYYY-MM-DD", timezone).format("dddd, MMMM Do");
			}
		}
	}, [ size ]);

	return (
		<ListItemButton
			divider
			key={props.pickupId}
			onClick={props.onSelect}
			selected={props.selected}
			style={{ minHeight: props.minHeight || "78.3px" }}
		>
			<ListItemIcon>
				<Event />
			</ListItemIcon>
			<ListItemText
				primary={props.pickupDate && formatPickupDate(props.pickupDate)}
				secondary={props.pickupDate && getDaysUntilPickupMessage(props.pickupDate)}
			/>
			<ListItemSecondaryAction>
				{props.loading
					? (
						<Spinner />
					)
					: (props.hideIcon) ? <> </> : (
						<IconButton color="primary">
							<ScheduleSend />
						</IconButton>
					)}
			</ListItemSecondaryAction>
		</ListItemButton>
	);
};