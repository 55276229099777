
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { defaultThemeState } from "../provider";

export interface ThemeState {
	primaryColor: string;
	secondaryColor: string;
	accentColor: string;
	headerColor: string;
	logoUrl: string;
	isCustom: boolean;
	collapseSidebar?: boolean;
	personalizeHeader?: boolean;
	personalizeDashboard?: boolean;
	personalizeManagerDashboard?: boolean;
}

const initialState: ThemeState = {
	...defaultThemeState
};

export const themeSlice = createSlice({
	name: "theme",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setTheme(state, action: PayloadAction<ThemeState>) {
			state.primaryColor = action.payload.primaryColor;
			state.secondaryColor = action.payload.secondaryColor;
			state.accentColor = action.payload.accentColor;
			state.headerColor = action.payload.headerColor;
			state.logoUrl = action.payload.logoUrl;
			state.isCustom = action.payload.isCustom;
			state.collapseSidebar = action.payload.collapseSidebar;
		}
	}
});

export const {
	setTheme
} = themeSlice.actions;

export const selectTheme = (state: RootState): ThemeState => state.theme;

export default themeSlice.reducer;