import { gql } from "../../../__generated__";

export const FetchBuildingReporting = gql(/* GraphQL */ `
	query FetchBuildingReporting(
		$buildingId: String!
	) {
		FetchBuilding(
			buildingId: $buildingId
		) {
			...Building_WasteContainerFragment
		}
	}
`);