import { UserProfileSidebar } from "./components/UserProfileSidebar";
import { SidebarLayout } from "../../components";

export const UserProfileLayout: React.FC = () => {
	return (
		<SidebarLayout
			sidebar={(
				<UserProfileSidebar />
			)}
		/>
	);
};