import { Box, Heading, Text } from "grommet";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useActiveOrders } from "../../hooks";
import { OrderSummaryList } from "./OrderSummaryList";
import { LinearProgress } from "@mui/material";

export const ActiveOrderSummaryList: React.FC<{
	userId: string;
	instanceId: string;
}> = ({ userId, instanceId }) => {
	const snack = useSnackbar();

	const {
		loading, orders, error
	} = useActiveOrders(userId, instanceId);

	useEffect(() => {
		if(error) {
			snack.enqueueSnackbar("We ran into an issue loading your information", {
				variant: "error"
			});
		}
	}, [ error ]);

	return (
		<Box gap="medium" flex>
			<Box gap="small">
				<Box>
					<Heading level="3" margin="none">
						Your Active Pickups
					</Heading>
					{loading && (
						<LinearProgress />
					)}
				</Box>
			</Box>
			{(orders.length === 0 && !loading) && (
				<Box align="center" justify="center">
					<Text>
						nothing here yet
					</Text>
				</Box>
			)}
			<OrderSummaryList
				loading={loading}
				orders={orders.map((order) => order.id)}
			/>
		</Box>
	);
};
