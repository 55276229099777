import { SvgIcon, SvgIconProps } from "@mui/material";

	export const Freezer: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <g>
  <path d="m857.16 1134.9h-514.31c-33.656-0.046875-60.891-27.328-60.938-60.984v-1000.4c0-33.656 27.281-60.938 60.938-60.984h514.31c33.656 0.046875 60.938 27.328 60.938 60.984v1000.4c-0.046875 33.656-27.281 60.938-60.938 60.984zm-514.31-1097.4c-19.828 0.046875-35.906 16.125-35.953 36v1000.4c0.046874 19.875 16.125 35.953 35.953 36h514.31c19.828-0.046875 35.906-16.125 35.953-36v-1000.4c-0.046875-19.875-16.125-35.953-35.953-36z"/>
  <path d="m905.63 747.71h-611.26c-6.8906 0-12.469-5.5781-12.469-12.469 0-6.9375 5.5781-12.516 12.469-12.516h611.26c6.8906 0 12.469 5.5781 12.469 12.516 0 6.8906-5.5781 12.469-12.469 12.469z"/>
  <path d="m424.45 1187.5h-48.234c-14.953 0-27.094-12.141-27.094-27.094v-37.969c0-3.3281 1.3125-6.5156 3.6562-8.8594s5.5312-3.6562 8.8125-3.6562h77.484c3.3281 0 6.5156 1.3125 8.8594 3.6562s3.6562 5.5312 3.6562 8.8594v37.969c0 14.953-12.141 27.094-27.141 27.094zm-50.344-52.594v25.5c0 1.1719 0.9375 2.1094 2.1094 2.1094h48.234c1.1719 0 2.1562-0.9375 2.1562-2.1094v-25.5z"/>
  <path d="m823.78 1187.5h-48.234c-15 0-27.141-12.141-27.141-27.094v-37.969c0-3.3281 1.3125-6.5156 3.6562-8.8594s5.5312-3.6562 8.8594-3.6562h77.484c3.2812 0 6.4688 1.3125 8.8125 3.6562s3.6562 5.5312 3.6562 8.8594v37.969c0 14.953-12.141 27.094-27.094 27.094zm-50.344-52.594v25.453l-0.046875 0.046875c0 1.1719 0.98438 2.1094 2.1562 2.1094h48.234c1.1719 0 2.1094-0.9375 2.1094-2.1094v-25.5z"/>
  <path d="m675.42 272.34h-150.84c-17.203 0-31.172-13.969-31.172-31.172v-82.125c0-17.156 13.969-31.125 31.172-31.125h150.84c17.203 0 31.172 13.969 31.172 31.125v82.125c0 17.203-13.969 31.172-31.172 31.172zm-150.84-119.44c-3.4219 0-6.1875 2.7656-6.1875 6.1406v82.125c0 3.4219 2.7656 6.1875 6.1875 6.1875h150.84c3.4219 0 6.1875-2.7656 6.1875-6.1875v-82.125c0-3.375-2.7656-6.1406-6.1875-6.1406z"/>
  <path d="m367.64 679.69c-3.2812 0-6.4688-1.3125-8.8125-3.6562s-3.6562-5.5312-3.6562-8.8125v-221.34c0-6.8906 5.5781-12.469 12.469-12.469 6.9375 0 12.516 5.5781 12.516 12.469v221.34c0 3.2812-1.3125 6.4688-3.6562 8.8125s-5.5312 3.6562-8.8594 3.6562z"/>
  <path d="m367.64 971.29c-3.2812 0-6.4688-1.3125-8.8125-3.6562s-3.6562-5.5312-3.6562-8.8594v-136.4c0-6.8906 5.5781-12.469 12.469-12.469 6.9375 0 12.516 5.5781 12.516 12.469v136.4c0 3.3281-1.3125 6.5156-3.6562 8.8594s-5.5312 3.6562-8.8594 3.6562z"/>
 </g>
</svg>
			</SvgIcon>
		);
	};