import { Tab } from "@mui/material";
import { useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import { HaulerPendingQuoteRequestsController } from "./HaulerPendingQuoteRequestsController";
import { HaulerPendingQuotesController } from "./HaulerPendingQuotesController";

export const HaulerQuotesTabController: React.FC<{ haulerId: string; }> = (props) => {
	const [ value, setValue ] = useState("1");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<TabContext value={value}>
			<TabList onChange={handleChange}>
				<Tab value="1" label="Requests" />
				<Tab value="2" label="Submitted / Pending" />
			</TabList>
			{(value === "1") && (
				<HaulerPendingQuoteRequestsController haulerId={props.haulerId} />
			)}
			{(value === "2") && (
				<HaulerPendingQuotesController haulerId={props.haulerId} />
			)}
		</TabContext>
	);
};
