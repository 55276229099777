import { Box, Heading } from "grommet";
import { ViewContainer } from "../components";

export const PageNotAvailableView: React.FC = () => {
	return (
		<ViewContainer>
			<Box flex align="center" justify="center">
				<Heading level="2">
					This page is not available
				</Heading>
			</Box>
		</ViewContainer>
	);
};