import { Anchor, Box, Grid, Heading, Image } from "grommet";
import { ReuseOutlet_AddressFragmentFragment, ReuseOutlet_HeaderFragmentFragment, ReuseOutlet_HoursFragmentFragment } from "../../../graphql/__generated__/graphql";
import { useMemo } from "react";
import { push } from "redux-first-history";
import { selectInstancePublic, useAppDispatch, useAppSelector } from "../../../store";
import { isReuseOutletFragment } from "../../../graphql";
import { useFullScreen, usePageTitle } from "../../../hooks";
import couch from "../../../img/couch.png";
import { Button } from "@mui/material";
import { StoreHoursContainer } from "../components/StoreHoursContainer";
import { AddressDetailsDialog } from "../../../components";

export const DonationCenterReferView: React.FC = () => {
	usePageTitle("Start Donation");
	const instance = useAppSelector(selectInstancePublic);
	const dispatch = useAppDispatch();

	function handleClick() {
		dispatch(push("/checkout"));
	}

	const partners = useMemo(() => {
		return (instance?.partners?.filter(isReuseOutletFragment) || []);
	}, [ instance?.partners ]);

	const fullScreen = useFullScreen();

	const terms = useMemo(() => {
		return "";
	}, []);

	const policy = useMemo(() => {
		return "";
	}, []);

	const referralMessage = useMemo(() => {
		return (partners[ 0 ] as ReuseOutlet_HeaderFragmentFragment).referralMessage || `Give your furniture a second chance! Donate home furnishings and building materials to ${instance?.name || ""}.`;
	}, []);

	return (
		<Box
			id="container"
			height="100%"
			style={{ display: "block" }}
			overflow={{ vertical: "auto" }}
		>
			<Grid
				pad="none"
				width="100%"
				gap="medium"
				columns={{ count: fullScreen ? 1 : 2, size: "auto" }}
			>
				<Box gap="small" background={{ image: `url("${couch}")`, size: "cover" }} style={{ minHeight: 300 }}>
					<Box flex="grow" align="center" justify="center" gap="medium " margin="large">
						<Box>
							<Heading
								color="white"
								level="3"
							>
								{referralMessage}
							</Heading>
						</Box>
						<Box margin="medium">
							<Button
								size="large"
								color="secondary"
								variant="contained"
								onClick={handleClick}
							>
								Start Donation
							</Button>
						</Box>
					</Box>
					<Box>
						<Box background={{ color: "brand", opacity: "strong" }}>
							<Box direction="row" justify="between" align="center">
								<Box margin="small">
									{(terms || policy) && (
										<Anchor
											color="white"
											label="Terms & Conditions"
											onClick={() => {
												//
											}} />
									)}
								</Box>
								<Box margin="small">
									{(partners?.[ 0 ] as ReuseOutlet_AddressFragmentFragment)?.address && (
										<AddressDetailsDialog
											address={(partners?.[ 0 ] as ReuseOutlet_AddressFragmentFragment).address || undefined}
											activatorButton={(
												<Anchor
													color="white"
													label="Store Address"
													onClick={() => {
														//
													}} />
											)} />
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box gap="small" flex margin="medium">
					<Box align="center">
						<Box width="medium">
							<Image
								src={instance?.customizations?.logoUrl || ""} />
						</Box>
					</Box>
					<Box align="center">
						<Heading level="3" margin="none">
							Donation Hours
						</Heading>
					</Box>
					{partners?.map(partner => (
						<StoreHoursContainer
							key={partner.id}
							name={partner.name}
							hours={(partner as ReuseOutlet_HoursFragmentFragment).hours} />
					))}
				</Box>
			</Grid>
		</Box>
	);
};