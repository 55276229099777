import React, { ReactNode } from "react";
import { Box, Heading } from "grommet";
import { Typography } from "@mui/material";

export const CheckoutTitleContainer: React.FC<{
	heading: ReactNode;
	subheading: ReactNode;
	sideContent?: React.ReactNode;
}> = ({ heading, subheading, sideContent }) => {
	return (
		<Box direction="row" justify="between" gap="medium">
			<Box gap="small" style={{ display: "block" }}>
				{typeof heading === "string" ? (
					<Heading level="3" margin="none">
						{heading}
					</Heading>
				)
					: heading
				}
				{typeof subheading === "string" ? (
					<Typography>
						{subheading}
					</Typography>
				)
					: subheading
				}
			</Box>
			{sideContent}
		</Box>
	);
};