import { SvgIcon, SvgIconProps } from "@mui/material";

	export const BarstoolAlt: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m922.68 1102.1-126.6-853.68v-57.602h7.3203c38.52 0 69.961-31.441 69.961-69.961 0-38.52-31.441-69.961-69.961-69.961h-406.8c-38.52 0-69.961 31.441-69.961 69.961 0 38.52 31.441 69.961 69.961 69.961h7.3203v57.602l-126.6 853.8c-3.2383 22.32 12.238 43.199 34.559 46.441 2.0391 0.23828 3.9609 0.48047 6 0.48047 20.039 0 37.559-14.641 40.441-35.039l77.398-522.48h123.12v516.48c0 22.559 18.359 41.039 41.039 41.039 22.559 0 41.039-18.359 41.039-41.039l0.003906-516.48h123.12l77.398 522.36c3 20.398 20.52 35.039 40.559 35.039 1.9219 0 3.9609-0.12109 6-0.48047 10.801-1.5586 20.398-7.3203 26.879-16.078 6.6016-8.6406 9.3594-19.441 7.8008-30.363zm-569.4-981.12c0-23.879 19.441-43.32 43.32-43.32h406.8c23.879 0 43.32 19.441 43.32 43.32 0 23.879-19.441 43.32-43.32 43.32h-406.8c-23.883 0-43.32-19.441-43.32-43.32zm416.28 69.961v55.078h-339.12v-55.078zm-128.52 81.598h75.84l35.16 237.12h-111zm-82.078 0v237.12h-111l35.16-237.12zm-226.92 837.6c-1.1992 7.8008-8.5195 13.32-16.32 12.121-7.8008-1.1992-13.32-8.3984-12.121-16.32l123.6-833.52h29.039zm107.76-545.16 4.3203-28.801h114.96v28.801zm160.2 557.52c-7.9219 0-14.398-6.4805-14.398-14.398v-835.56h28.801v835.56c-0.003906 7.9219-6.4805 14.402-14.402 14.402zm41.039-557.52v-28.801h114.96l4.3203 28.801zm252.6 551.76c-2.2812 3.1211-5.6406 5.0391-9.4805 5.6406-7.8008 1.0781-15.121-4.3203-16.32-12.121l-124.2-837.6h29.039l123.6 833.52c0.59766 3.6055-0.35938 7.4453-2.6406 10.562z"/>
</svg>
			</SvgIcon>
		);
	};