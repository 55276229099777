import { SvgIcon, SvgIconProps } from "@mui/material";

	export const SofaLoveseat: React.FC<SvgIconProps> = (props) => {
		return (
			<SvgIcon {...props}>
				<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1082.4 372h-6.4805v-50.52c0-48.238-46.32-87.359-103.2-87.359h-745.44c-56.879 0-103.2 39.238-103.2 87.359l-0.003906 50.52h-6.4766c-38.402 0-69.602 31.199-69.602 69.602v341.04c0 38.398 31.199 69.602 69.602 69.602h78.719l-17.641 65.762c-2.5195 9.2383-1.1992 18.961 3.6016 27.238 4.8008 8.2812 12.48 14.281 21.84 16.68l10.199 2.7617c3.1211 0.83984 6.2383 1.1992 9.3594 1.1992 12 0 23.398-6 30.121-16.559l62.398-97.078h567.84l62.398 97.078c6.7188 10.441 18.121 16.559 30.121 16.559 3.1211 0 6.2383-0.35937 9.3594-1.1992l10.199-2.7617c19.078-5.1602 30.48-24.84 25.441-43.922l-17.641-65.762h78.719c38.398 0 69.602-31.199 69.602-69.602v-341.04c-0.23828-38.402-31.438-69.602-69.84-69.602zm-931.44-50.52c0-33.359 34.199-60.48 76.32-60.48h745.56c42.121 0 76.32 27.121 76.32 60.48v50.52h-10.32c-38.398 0-69.602 31.199-69.602 69.602v88.078h-297.6c-30 0-56.398 16.922-71.52 42.359-15.121-25.441-41.52-42.359-71.52-42.359h-297.6l0.003906-88.078c0-38.398-31.199-69.602-69.602-69.602h-10.32v-50.52zm818.16 235.2v115.08l-355.68 0.003907v-50.16c0-35.879 26.039-65.039 58.078-65.039l297.6-0.003906zm-382.56 64.922v50.039h-355.68v-115.08h297.6c32.039 0.11719 58.078 29.277 58.078 65.039zm-355.44 313.2c-2.1602 3.3594-6 4.9219-9.8398 3.8398l-10.199-2.7617c-2.2812-0.60156-4.1992-2.1602-5.3984-4.1992-1.1992-2.0391-1.5586-4.5586-0.96094-6.8398l19.441-72.719h59.879zm757.8 1.0781-10.199 2.7617c-3.9609 1.0781-7.6797-0.35938-9.8398-3.8398l-53.039-82.559h60l19.441 72.719c1.3164 4.8008-1.5664 9.7188-6.3633 10.918zm136.2-153.24c0 23.52-19.078 42.602-42.602 42.602l-964.92-0.003907c-23.52 0-42.602-19.078-42.602-42.602v-341.04c0-23.52 19.078-42.602 42.602-42.602h43.801c23.52 0 42.602 19.078 42.602 42.602v243.48c0 7.4414 6 13.441 13.441 13.441h765.12c7.4414 0 13.441-6 13.441-13.441l-0.007812-243.48c0-23.52 19.078-42.602 42.602-42.602h43.801c23.52 0 42.602 19.078 42.602 42.602l-0.003906 341.04z"/>
</svg>
			</SvgIcon>
		);
	};