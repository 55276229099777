import { SidebarRouter, SidebarRouteItem, DashboardListItem, OrdersListItem, StoreMultilevelListItem } from "../../../components";
import { InstanceFeature } from "../../../graphql/__generated__/graphql";
import { isNotNull } from "../../../helpers";
import { useFeature } from "../../instance/hooks/useFeature";
import { useAdminStoreRoutes } from "../donation/router";
import { OrdersView, StoreSettingsView } from "../common";
import { DonationCenterDashboard } from "../donation";

export const DisposalCenterRouter: React.FC = () => {
	return useDisposalCenterRouter().toRouter();
};

export function useDisposalCenterRouter() {
	const isSalesEnabled = useFeature(InstanceFeature.SalesEnabled);
	const isDisposalEnabled = useFeature(InstanceFeature.RemovalEnabled);

	const storeRoutes = useAdminStoreRoutes();

	return new SidebarRouter([
		new SidebarRouteItem(
			"/",
			<DonationCenterDashboard />,
			<DashboardListItem
				absolutePath="/admin/dashboard"
			/>,
			true
		),
		isDisposalEnabled ? new SidebarRouteItem(
			"/orders",
			<OrdersView />,
			<OrdersListItem absolutePath="/admin/orders" />,
			true
		) : null,
		isSalesEnabled ? new SidebarRouteItem(
			"/store/*",
			<StoreSettingsView />,
			<StoreMultilevelListItem absolutePath="" />,
			true
		) : null
	].filter(isNotNull), "Disposal Center", [

	], [
		...storeRoutes
	]);
}