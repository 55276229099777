import { Box } from "grommet";
import React, { useMemo } from "react";
import { BasicListItem, PickupIdListItem, PickupScheduledDateListItem, PickupScheduledWindowListItem } from "../../../../components";
import { List } from "@mui/material";
import { useIsDayOfPickup, useIsPickupStarted, useWindowDimensions } from "../../../../hooks";
import { canStopComplete, formatCurrency } from "../../../../helpers";
import { AcceptHaulerPickupRequestButton } from "./AcceptHaulerPickupRequestButton";
import { RejectHaulerPickupRequestButton } from "./RejectHaulerPickupRequestButton";
import { BeginPickupButton } from "./BeginPickupButton";
import { CurrentStopDialog } from "../stop";
import { Hauler_CombinedFragmentFragment, Pickup_ActiveStopFragmentFragment, Pickup_HeaderFragmentFragment, Pickup_InvoiceFragmentFragment, Pickup_StopsFragmentFragment, Pickup_TrackingFragmentFragment } from "../../../../graphql/__generated__/graphql";
import { FragmentType } from "../../../../graphql";
import { CompleteStopButton } from "./CompleteStopButton";

export const HaulerPickupDetailsComponent: React.FC<{
	loading: boolean;
	hauler: FragmentType<Hauler_CombinedFragmentFragment> | null;
	pickup: FragmentType<
		Pickup_StopsFragmentFragment &
		Pickup_HeaderFragmentFragment &
		Pickup_TrackingFragmentFragment &
		Pickup_InvoiceFragmentFragment &
		Pickup_ActiveStopFragmentFragment
	>;
}> = ({ loading, pickup, hauler }) => {
	const { size } = useWindowDimensions();

	const pendingRequest = useMemo(() => {
		return hauler?.pendingRequests?.find((request) => request.pickup.id === pickup?.id) ?? null;
	}, [ hauler, pickup ]);

	const isDayOfPickup = useIsDayOfPickup(pickup);
	const isPickupStarted = useIsPickupStarted(pickup);

	return (
		<Box flex>
			<List>
				<PickupIdListItem
					pickup={pickup}
					loading={loading}
				/>
				<PickupScheduledDateListItem
					pickup={pickup}
					loading={loading}
				/>
				<PickupScheduledWindowListItem
					pickup={pickup}
					loading={loading}
				/>
				<BasicListItem
					label="Payout Amount"
					loading={loading}
					value={pickup?.invoice?.amount
						? formatCurrency(pickup.invoice.amount)
						: pendingRequest?.amountOffered
							? formatCurrency(pendingRequest.amountOffered)
							: "Not Quoted"
					}
				/>
			</List>
			<Box flex justify="end" margin="small">
				{(isDayOfPickup && !isPickupStarted) && (
					<BeginPickupButton
						pickupId={pickup?.id ?? ""}
					/>
				)}
				{(!!pendingRequest && !!hauler) && (
					<Box direction={size === "small" ? "column" : "row"} justify="center" gap="small">
						<RejectHaulerPickupRequestButton
							requestId={pendingRequest.id}
						/>
						<AcceptHaulerPickupRequestButton
							requestId={pendingRequest.id}
						/>
					</Box>
				)}
				<Box gap="small">
					{!!pickup?.activeStop && (
						<CurrentStopDialog
							stopId={pickup.activeStop.id}
							haulerId={hauler?.id ?? ""}
							movementId={pickup.id}
						/>
					)}
					{(!!pickup?.activeStop && canStopComplete(pickup.activeStop) && !pickup.stops.every(s => s.completed)) && (
						<CompleteStopButton
							stop={pickup.activeStop}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};